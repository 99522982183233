import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { values, vehicles, setFieldValue } = rest;

  useEffect(() => {
    if (values.vehicle_id) {
      const { vehicle_id: vehicleId } = values.vehicle_id;
      const vehicle = vehicles.find(
        (vehicle) => vehicle.vehicle_id == vehicleId
      );
      setFieldValue("economic_number", vehicle.economic_number);
      setFieldValue("enrollment", vehicle.enrollment ?? "");
      setFieldValue("vehicle_type_id", vehicle.vehicle_type_id.toString());
      setFieldValue("has_odometer", Boolean(vehicle.has_odometer));
    }
  }, [values.vehicle_id]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  associations: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  setFieldValue: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
