import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";
import { actionClearVehicleTypeAxles } from "store/actions/vehicle/type/axle/clearVehicleTypeAxles";

import { useLang } from "hooks/lang";

import {
  CREATE_VEHICLE_TYPE_AXLE,
  UPDATE_VEHICLE_TYPE_AXLE,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeVehicleTypeAxle({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    permits,
    vehicleTypeAxles,
    getVehicleTypeAxles,
    clearVehicleTypeAxles,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [vehicleTypeAxleId, setVehicleTypeAxleId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleTypeAxles({ vehicleTypeId: id, page: 1 });
      } catch (error) {
        actionSetNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearVehicleTypeAxles();
    };
  }, []);

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setVehicleTypeAxleId(null);
  }

  function handleEdit(id) {
    setVehicleTypeAxleId(id);
    setOpenForm(true);
  }

  async function onChangePage(page) {
    try {
      await getVehicleTypeAxles({ vehicleTypeId: id, page });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      vehicleTypeId={id}
      vehicleTypeAxleId={vehicleTypeAxleId}
      vehicleTypeAxles={vehicleTypeAxles}
      updateVehicleTypeAxlePermit={permits.includes(UPDATE_VEHICLE_TYPE_AXLE)}
      createVehicleTypeAxlePermit={permits.includes(CREATE_VEHICLE_TYPE_AXLE)}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      onChangePage={onChangePage}
      handleEdit={handleEdit}
    />
  );
}

HomeVehicleTypeAxle.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  filter: state.Vehicle.Type.Axle.Axle.filter,
  vehicleTypeAxles: state.Vehicle.Type.Axle.Axle.vehicle_type_axles,
});
const mapDispatchToProps = (dispatch) => ({
  clearVehicleTypeAxles: actionClearVehicleTypeAxles(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeVehicleTypeAxle);
