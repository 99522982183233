export default {
  name: {
    retirement_donation: "retirement by sale/donation",
    kinked_distorted_bead: "Kinked distorted bead",
    burned_bead: "Burned bead",
    petro_lubricant_damage: "Petro lubricant damage",
    damage_induced_sidewall_separation: "damage induced sidewall separation",
    circumferential_fatigue_rupture_zipper:
      "Circumferential fatigue rupture zipper",
    impact_breaks: "Impact breaks",
    impact_break: "Impact break",
    improper_nail_hole_repair: "Improper nail hole repair",
    lug_base_cracking: "Lug base cracking",
    shoulder_scrubbing_scuffing: "Shoulder scrubbing scuffing",
    high_inflation_pressure: "High inflation pressure",
    weathering: "Weathering",
    brake_skid_damage: "Brake skid damage",
    run_flat: "Run flat",
    ceded_to_another_company: "Ceded to another company",
    crystallized_bead: "Crystallized bead",
    torn_bead: "Torn bead",
    cuts_snags: "Cuts snags",
    sidewall_abrasion_scuff_damage: "Sidewall abrasion scuff damage",
    vehicle_equipment_damage: "Vehicle equipment damage",
    continuous_damages: "Continuous damages",
    discard_in_journey: "Discard in journey",
    tread_chunking: "Tread chunking",
    shoulder_chunking: "Shoulder chunking",
    excessive_wear: "Excessive wear",
    pulled_loose_cords: "Pulled loose cords",
    erratic_depression_wear: "Erratic depression wear",
    excessive_retread: "Excessive retread",
    end_of_useful_life: "End of useful life",
    inner_liner_bubbles_blisters_and_separations:
      "Inner liner bubbles blisters and separations",
    object_trapped_in_duals: "Object trapped in duals",
    crystallized_tread: "Crystallized tread",
    penetrations_and_road_hazards: "Penetrations and road hazards",
    lost_in_journey_in_an_accident: "Lost in journey in an accident",
    dictamen: "Dictamen",
    reinforce_chafer_separation: "Reinforce chafer separation",
    bad_spot_repair: "Bad spot repair",
    stole: "Stole",
    belt_lift_separation: "Belt lift separation",
    failed_repair_from_underinflation: "Failed repair from underinflation",
    retread_reject: "Retread reject",
  },
  description: {
    retirement_donation_appearance: "Company donated/sold the tire",
    kinked_distorted_bead_appearance:
      "Distortion in local areas at the bead seat (heel)",
    burned_bead_appearance: "Excessive heat in the brow (heel) area",
    petro_lubricant_damage_appearance:
      "The rubber shows a spongy roughness, wrinkling or cracking condition in the bead (heel) area",
    damage_induced_sidewall_separation_appearance:
      "This is usually detected by cracks or bumps, damage to the brow (heel)",
    circumferential_fatigue_rupture_zipper_appearance:
      "The appearance of the side of the rubber can show a problem in its structure (or a break) due to lack of pressure or overload",
    impact_breaks_appearance:
      "Impact of the wheel casing with objects, stones, curbs or speed bumps. This usually happens when driving at excessive speed or at a bad angle",
    pulled_loose_cords_appearance:
      "Caused by rapid acceleration, braking, fast turning and other bad driving habits.",
    impact_break_appearance:
      "Damage to the casing (the tire cover) after the tire comes into contact with certain obstacles",
    improper_nail_hole_repair_appearance:
      "Poor maintenance of tire performance",
    lug_base_cracking_appearance:
      "They may have a cosmetic nature and are not dangerous. However, if they break through the outer surface of the rubber and penetrate deeper, the tire may be rendered useless.",
    shoulder_scrubbing_scuffing_appearance:
      "Rim with shoulder wear, due to improper seating between rim and rim",
    high_inflation_pressure_appearance:
      "Inflation in tires with a pressure that exceeds the recommended PSI",
    weathering_appearance:
      "When the tread has more contact with the ground, it will heat up sooner and as a consequence it will wear out faster",
    brake_skid_damage_appearance: "Severe brake tire wear",
    run_flat_appearance:
      "Deflated tires, fails to maintain the desired trajectory and tends to lose the outward line",
    ceded_to_another_company_appearance:
      "The tire became the property of another company.",
    crystallized_bead_appearance:
      "The face of the tire becomes hard and now works as a totally rigid body instead of being elastic and adapting to the pavement guaranteeing good grip.",
    torn_bead_appearance: "Damaged eyebrow on rim",
    cuts_snags_appearance:
      "Cut on the rims caused by sharp stones, pieces of glass, nails, screws, etc",
    sidewall_abrasion_scuff_damage_appearance:
      "Abrasion marks on the rims. The damage may extend to the deck",
    vehicle_equipment_damage_appearance: "Vehicle equipment damage appearance",
    continuous_damages_appearance:
      "The tire has suffered a lot of damage during its life cycle or suffered several damages in a short period of time.",
    discard_in_journey_appearance: "The tire was lost during a trip.",
    tread_chunking_appearance:
      "Tread slips make a vehicle difficult or impossible to control",
    shoulder_chunking_appearance:
      "The shoulders of the tire wear faster than the central part of the tread caused by low inflation pressure",
    excessive_wear_appearance:
      "Caused by incorrect air pressure, poorly balanced tires and incorrect wheel alignment",
    erratic_depression_wear_appearance:
      "Tire wear caused by incorrect air pressure, poorly balanced tires and incorrect wheel alignment",
    excessive_retread_appearance:
      "The damage policy allowed for a tire or helmet has been exceeded.",
    end_of_useful_life_appearance: "The tire has finished its useful life.",
    inner_liner_bubbles_blisters_and_separations_appearance:
      "Inflation air infiltration between the rubber and the casing results in bulges in the sidewall area.",
    object_trapped_in_duals_appearance:
      "Damage to tires caused by any sharp/sharp object found on the road",
    crystallized_tread_appearance:
      "The tread of the tire becomes hard and now works as a totally rigid body instead of being elastic and adapting to the pavement guaranteeing good grip.",
    penetrations_and_road_hazards_appearance:
      "Penetrations and appearance of dangers on the road",
    lost_in_journey_in_an_accident_appearance:
      "The tire was lost during a trip due to an accident.",
    dictamen_appearance: "The tire was removed by order of a superior.",
    reinforce_chafer_separation_appearance:
      "Reinforce chafer separation appearance",
    bad_spot_repair_appearance: "Bad spot repair appearance",
    stole_appearance: "The tire was stolen.",
    belt_lift_separation_appearance: "Belt lift clearance appearance",
    failed_repair_from_underinflation_appearance:
      "Repair failed due to appearance of underinflation",
    retread_reject_appearance: "The tire was removed by order of a superior.",
  },
};
