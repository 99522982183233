import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";

import Page from "./page";

export function TireDialog({ open, tires, ...rest }) {
  const t = useLang();

  return <Page {...rest} t={t} open={open} tires={tires} />;
}

TireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleReviewId: PropTypes.number,
  tires: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDialog);
