import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetChecklistHistory } from "store/actions/checklist/history/getChecklistHistory";
import { actionClearChecklistHistory } from "store/actions/checklist/history/clearChecklistHistory";

import { useLang } from "hooks/lang";

import Page from "./page";
import { actionGetCommissionedVehicle } from "store/actions/commissioned/vehicle/getCommissionedVehicle";

function ChecklistHistory({ ...rest }) {
  const t = useLang();
  const {
    checklists,
    setNotification,
    getChecklistHistory,
    clearChecklistHistory,
    getCommissionedVehicle,
  } = rest;
  const { id: commissionedVehicleId } = useParams();
  const [economicNumber, setEconomicNumber] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [checklistReview, setChecklistReview] = useState("{}");
  const [checklist, setChecklist] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCommissionedVehicle(commissionedVehicleId);
        setEconomicNumber(response.economic_number);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        await getChecklistHistory({ commissionedVehicleId });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearChecklistHistory();
    };
  }, []);

  function handleOpenDialog(checklistReview, checklist) {
    setChecklistReview(checklistReview);
    setChecklist(checklist);
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    setChecklistReview("{}");
    setChecklist({});
  }

  async function handleChangePage(page) {
    try {
      await getChecklistHistory({ commissionedVehicleId, page });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      checklists={checklists}
      economicNumber={economicNumber}
      openDialog={openDialog}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
      checklistReview={checklistReview}
      checklist={checklist}
      handleChangePage={handleChangePage}
    />
  );
}

ChecklistHistory.propTypes = {};

const mapStateToProps = (state) => ({
  checklists: state.ChecklistHistory.checklists,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getChecklistHistory: actionGetChecklistHistory(dispatch),
  clearChecklistHistory: actionClearChecklistHistory(dispatch),
  getCommissionedVehicle: actionGetCommissionedVehicle(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistHistory);
