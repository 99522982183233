const messages = {
  MD200: "The company was successfully eliminated",
};

const errors = {
  S500: "Oops, there was a server error. Try again later.",
  BSTR422: "The field does not contain between {min} and {max} characters.",
  MIN412: "The field must be greater than {min}.",
  EC405: "The email or password are incorrect.",
  RQR422: "This field is required.",
  TI401: "The session is invalid",
  MST412: "The field must contain at least {min} characters.",
  RF422: "The RFC is invalid.",
  IMG422: "The field must be an image",
  MIM120: "It must be a file of type {values}.",
  MAX412: "The field must be less than {max}.",
  CM404: "The company was not found.",
  SB404: "The subsidiary was not found.",
  SD200: "The subsidiary was eliminated.",
  UNQ425: "The supplied value is already in use.",
  TA401: "The session does not exist",
  TE401: "La sesión ha caducado",
  PUS403: "You don't have permits within the work area.",
  NWL404: "The warehouse does not have an assigned subsidiary.",
  NTW404: "The tire does not have an assigned warehouse.",
  NTY404: "The tire cycle was not found.",
  NDL404: "The driver does not have an assigned subsidiary.",
  NVL404: "The vehicle does not have an assigned subsidiary.",
  NLD404: "The vehicle does not have an assigned division.",
  NGL404: "The vehicle does not have an assigned gps.",
  VD200: "The vehicle was successfully deleted.",
  TVE200: "Model variation was successfully enabled.",
  TVD200: "Model variation was successfully disabled.",
};

export default {
  ...messages,
  ...errors,
  INTERNAL: "Oops, there was an error. Try again later.",
};
