import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleAlert } from "store/actions/vehicle/alert/getVehicleAlert";
import { actionClearVehicleAlert } from "store/actions/vehicle/alert/clearVehicleAlert";
import { actionUpdateVehicleAlert } from "store/actions/vehicle/alert/updateVehicleAlert";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function UpdateCommentForm({
  open,
  onClose,
  alertId,
  vehicleId,
  ...rest
}) {
  const t = useLang();
  const {
    alert,
    setNotification,
    getVehicleAlert,
    clearVehicleAlert,
    updateVehicleAlert,
  } = rest;

  const initialDefaultValues = {
    comment: "",
  };
  const {
    initialValues,
    initialErrors,
    // resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleAlert(vehicleId, alertId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (alertId && open) {
      fetchData();
    }

    return () => {
      clearVehicleAlert();
    };
  }, [alertId, open]);

  useEffect(() => {
    setInitialValues({
      comment: alert.comment || "",
    });
  }, [alert, alertId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = values;
      await updateVehicleAlert(vehicleId, alertId, fields);
      setNotification({
        message: t("_messages.updated.alert"),
      });
      setSubmitting(false);
      resetForm();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }
  return (
    <Page
      t={t}
      open={open}
      alert={alert}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UpdateCommentForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  alertId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  alert: state.Vehicle.Alert.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleAlert: actionGetVehicleAlert(dispatch),
  clearVehicleAlert: actionClearVehicleAlert(dispatch),
  updateVehicleAlert: actionUpdateVehicleAlert(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCommentForm);
