import { DELETE_PROVIDER } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteProvider = (dispatch) => async (providerId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `provider/${providerId}`,
    });
    dispatch({
      type: DELETE_PROVIDER,
      payload: providerId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
