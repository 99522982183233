import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "./page";

function Tabs({ title, className, optionsAppBar, tabLinks, children }) {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const onlyLinkTabs = tabLinks.map((i) => i.to);

  useEffect(() => {
    if (tabLinks.length > 0) {
      const index = onlyLinkTabs.indexOf(history.location.pathname);
      if (index !== -1) {
        setTabIndex(index);
      }
    }
  }, [tabLinks, history.location]);

  function handleChangeTab(event, newValue) {
    setTabIndex(newValue);
    history.push(onlyLinkTabs[newValue]);
  }

  return (
    <Page
      title={title}
      tabLinks={tabLinks}
      className={className}
      optionsAppBar={optionsAppBar}
      tabIndex={tabIndex}
      onChangeTab={handleChangeTab}
    >
      {children}
    </Page>
  );
}

Tabs.propTypes = {
  children: PropTypes.node,
  tabLinks: PropTypes.arrayOf(
    PropTypes.shape({
      hidden: PropTypes.bool,
      disabled: PropTypes.bool,
      tooltip: PropTypes.string,
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  optionsAppBar: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default Tabs;
