import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";
import { DELETE_LINK_TIRE_WITH_SUBSIDIARY } from "store/actions";

export const actionDeleteLinkTireWithSubsidiary =
  (dispatch) =>
  async ({ subsidiaryId, tireId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "DELETE",
        path: `subsidiary/${subsidiaryId}/tire/${tireId}`,
      });

      dispatch({
        type: DELETE_LINK_TIRE_WITH_SUBSIDIARY,
        payload: tireId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
