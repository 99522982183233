import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function TireRepairHistoryTab() {
  return <Page />;
}

TireRepairHistoryTab.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TireRepairHistoryTab);
