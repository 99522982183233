import { SET_BRAND_APPROVAL_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetBrandApprovalStatus =
  (dispatch) => async (brandId, approved) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `brand/${brandId}/approved`,
        data: { approved },
      });

      dispatch({
        type: SET_BRAND_APPROVAL_STATUS,
        payload: brandId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
