import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionLogout } from "store/actions/account/logout";

import Page from "./page";

export const NAMESPACE = "components/organisms/account/WorkAreaDialog";

export function WorkAreaDialog({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [open, setOpen] = useState(false);
  const { account, workArea, logout, setNotification } = rest;

  async function handleLogout() {
    try {
      await logout();
    } catch (error) {
      setNotification(error, true);
    }
  }

  useEffect(() => {
    if (!account) {
      setOpen(false);
      return;
    }

    setOpen(!workArea.key);
  }, [account, workArea]);

  return <Page t={t} open={open} handleLogout={handleLogout} />;
}

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  logout: actionLogout(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkAreaDialog);
