import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionClearProviders } from "store/actions/provider/clearProviders";
import { actionSetProviderFilters } from "store/actions/provider/setProviderFilters";
import { actionClearProviderFilters } from "store/actions/provider/clearProviderFilters";
import { actionDeleteProvider } from "store/actions/provider/deleteProvider";
import { actionSetProviderStatus } from "store/actions/provider/setProviderStatus";
import { actionGetProvider } from "store/actions/provider/getProvider";
import { actionAddProvider } from "store/actions/provider/addProvider";
import { actionUpdateProvider } from "store/actions/provider/updateProvider";

import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionSetNotification } from "store/actions/general/notification";

import {
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeProvider({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    account,
    provider,
    providers,
    updateProvider,
    deleteProvider,
    setProviderStatus,
    getProvider,
    addProvider,
    getProviders,
    clearProviders,
    setProviderFilters,
    clearProviderFilters,
    getSubsidiaries,
    setNotification,
  } = rest;

  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  return (
    <Page
      addProvider={addProvider}
      createProviderPermit={permits.includes(CREATE_PROVIDER)}
      clearProviderFilters={clearProviderFilters}
      clearProviders={clearProviders}
      deleteProviderPermit={permits.includes(DELETE_PROVIDER)}
      deleteProvider={deleteProvider}
      filter={filter}
      getProvider={getProvider}
      getProviders={getProviders}
      provider={provider}
      providers={providers}
      setProviderFilters={setProviderFilters}
      setProviderStatus={setProviderStatus}
      t={t}
      updateProviderPermit={permits.includes(UPDATE_PROVIDER)}
      updateProvider={updateProvider}
      subsidiaries={subsidiaries}
    />
  );
}

HomeProvider.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  providers: state.Provider.providers,
  filter: state.Provider.filter_provider,
  provider: state.Provider.provider,
  account: state.Account.account,
});

const mapDispatchToProps = (dispatch) => ({
  getProviders: actionGetProviders(dispatch),
  clearProviders: actionClearProviders(dispatch),
  setProviderFilters: actionSetProviderFilters(dispatch),
  clearProviderFilters: actionClearProviderFilters(dispatch),
  deleteProvider: actionDeleteProvider(dispatch),
  setProviderStatus: actionSetProviderStatus(dispatch),
  getProvider: actionGetProvider(dispatch),
  addProvider: actionAddProvider(dispatch),
  updateProvider: actionUpdateProvider(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeProvider);
