import { http } from "store/actions/http";
import { GET_TIRE_MODEL_VARIATION } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireModelVariation =
  (dispatch) => async (tireModelId, tireModelVariationId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `tire/model/${tireModelId}/variation/${tireModelVariationId}`,
      });

      dispatch({
        type: GET_TIRE_MODEL_VARIATION,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
