import { GET_TIRE_MODEL_VARIATIONS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireModelVariations =
  (dispatch) =>
  async (
    { tireModelId, approved, status, sizes, page, scope, order = "DESC" },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `tire/model/${tireModelId}/variation`,
        params: {
          approved,
          status,
          sizes,
          page,
          scope,
          order,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRE_MODEL_VARIATIONS,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
