export default {
  link_with_company: "Company user link",
  link_with_user: "User company link",
  buttons: {
    unlink_company: "Unlink company",
    view_company: "View company",
    unlink_user: "Unlink user",
    view_user: "View user",
  },
  permits: {
    delete_assign: {
      role_to_user_company:
        "No permissions to delete user role assignments in companies",
    },
  },
};
