export default {
  title: "New User",
  title_update: "Update user",
  form: {
    labels: {
      password_confirm: "Confirm password",
      change_password: "Change password to sing in",
    },
    preferred_language: {
      label: "Preferred language",
      options: {
        es: "Spanish",
        en: "English",
      },
    },
    permits: {
      delete: "No permissions to delete users",
      update: "No permissions to update users",
    },
  },
  messages: {
    delete: "Are you sure to delete this user?",
    user_created: "Done, user has been successfully created",
    user_updated: "Done, user has been successfully updated",
  },
};
