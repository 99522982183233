export default {
  labels: {
    status: "Status",
  },
  disabled_provider: "Disabled provider",
  permits: {
    change_status: "No permissions to {action} providers",
  },
  messages: {
    enable: "Are you sure to enable the provider?",
    disable: "Are you sure to disable the provider?",
  },
};
