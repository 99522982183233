export default {
  travel: "Travel",
  form: {
    previous_travel: "Previous travel",
  },
  messages: {
    travel_created: "Done, the travel has been successfully created.",
    travel_updated: "Done, the travel has been successfully updated",
  },
};
