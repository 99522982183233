import {
  GET_DEPTH_TOLERANCE_POLICY,
  GET_DEPTH_TOLERANCE_POLICIES,
  CLEAR_DEPTH_TOLERANCE_POLICIES,
  CLEAR_DEPTH_TOLERANCE_POLICY,
  UPDATE_DEPTH_TOLERANCE_POLICY,
} from "store/actions";

const initialState = {
  depth_tolerance_policy: {
    depth_tolerance_policy_id: 0,
    subsidiary_id: 0,
    tire_application_id: "",
    good_condition: 0,
    scheduled_withdrawal: 0,
    critical_withdrawal: 0,
    maximum_number_patches: 0,
    critical_number_patches: 0,
    company: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },
  policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DEPTH_TOLERANCE_POLICY:
      return { ...state };

    case GET_DEPTH_TOLERANCE_POLICY:
      return {
        ...state,
        depth_tolerance_policy: action.payload,
      };
    case CLEAR_DEPTH_TOLERANCE_POLICY:
      return {
        ...state,
        policy: initialState.depth_tolerance_policy,
      };
    case GET_DEPTH_TOLERANCE_POLICIES:
      return {
        ...state,
        policies: action.payload,
      };

    case CLEAR_DEPTH_TOLERANCE_POLICIES:
      return {
        ...state,
        policies: initialState.policies,
      };

    default:
      return state;
  }
}
