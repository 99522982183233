import {
  GET_RETIREMENT_CAUSES,
  CLEAR_RETIREMENT_CAUSES,
  REGISTER_RETIREMENT_CAUSE,
  GET_RETIREMENT_CAUSE,
  CLEAR_RETIREMENT_CAUSE,
  SET_RETIREMENT_CAUSE_FILTERS,
  CLEAR_RETIREMENT_CAUSE_FILTERS,
  UPDATE_RETIREMENT_CAUSE,
  SET_RETIREMENT_CAUSE_STATUS,
  DELETE_RETIREMENT_CAUSE,
} from "store/actions";

const initialState = {
  retirement_cause: {
    retirement_cause_id: 0,
    name: "",
    description: "",
    status: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  retirement_causes: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RETIREMENT_CAUSE:
    case UPDATE_RETIREMENT_CAUSE:
    case REGISTER_RETIREMENT_CAUSE:
      return {
        ...state,
        retirement_cause: action.payload,
      };

    case GET_RETIREMENT_CAUSES:
      return {
        ...state,
        retirement_causes: action.payload,
      };

    case CLEAR_RETIREMENT_CAUSES:
      return {
        ...state,
        retirement_causes: initialState.retirement_causes,
      };

    case CLEAR_RETIREMENT_CAUSE:
      return {
        ...state,
        retirement_cause: initialState.retirement_cause,
      };

    case SET_RETIREMENT_CAUSE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_RETIREMENT_CAUSE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case SET_RETIREMENT_CAUSE_STATUS:
      return {
        ...state,
        retirement_causes: {
          ...state.retirement_causes,
          data: state.retirement_causes.data.map((retirementCause) =>
            retirementCause.retirement_cause_id !== action.payload
              ? retirementCause
              : { ...retirementCause, status: !retirementCause.status }
          ),
        },
      };

    case DELETE_RETIREMENT_CAUSE:
      return {
        ...state,
        retirement_causes: {
          ...state.retirement_causes,
          data: state.retirement_causes.data.filter(
            (retirementCause) =>
              retirementCause.retirement_cause_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
