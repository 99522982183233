import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";
import "./semaforos.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import FilterDamagesWearsForm from "components/organisms/report/FilterDamagesWearsForm";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  dataChart,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
}) {
  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.damages_wears")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="container-fluid pdl100 sidebar_font">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">
              {t("_labels.damages_wears_report.report_damage_wear")}
            </h1>
            <h5 className="sinmp">
              {t("_labels.damages_wears_report.shows_tire_model")}
            </h5>
            {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")},{" "}
                  {t("_labels.location.options.warehouse")}{" "}
                  {t("_general.text.and")} {t("_labels.location.options.pile")}
                </small>
              </div>
            )}
            <hr />
          </div>
        </div>
        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-4">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        {data.damageStatistics && data.damageStatistics.length > 0 && (
          <>
            <div className="row pt-4">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <h4 className="text-center">
                    {t(
                      "_labels.damages_wears_report.damage_counters"
                    ).toUpperCase()}
                  </h4>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-1">
                      <thead className="thead">
                        <tr
                          style={{
                            backgroundColor: "dimgrey",
                            color: "white",
                            height: 50,
                            paddingTop: 10,
                          }}
                        >
                          <th className="text-uppercase">
                            {t("_labels.damage.plural")}
                          </th>
                          <th className="text-center text-uppercase">
                            {t("_labels.damages_wears.incidents")}
                          </th>
                          {data.providers &&
                            data.providers.map((provider, index) => (
                              <th key={index} className="text-center">
                                {provider}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.damageStatistics.map((damage) => (
                          <tr key={damage.damage}>
                            <td className="text-uppercase align-middle">
                              {t(`_damage.name.${damage.damage}`)}
                            </td>
                            <td className="text-center align-middle">
                              <b>{damage.statistics}</b> (
                              {percentFormatter.format(damage.percent)}%)
                            </td>
                            {data.providers.map((provider) => {
                              const foundProvider = damage.providers.find(
                                (p) => p.provider == provider
                              );

                              return foundProvider ? (
                                <td
                                  key={provider}
                                  className="text-center align-middle"
                                >
                                  <b>{foundProvider.statistics}</b> (
                                  {percentFormatter.format(
                                    foundProvider.percent
                                  )}
                                  %)
                                </td>
                              ) : (
                                <td
                                  key={provider}
                                  className="text-center align-middle"
                                >
                                  -
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {dataChart.damageStatistics &&
          Object.keys(dataChart.damageStatistics).length > 0 && (
            <div className="row pt-1 pb-3">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <div className="card">
                    <div className="charts">
                      <div className="bigchart">
                        <DoughnutChart
                          data={dataChart.damageStatistics}
                          title={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {data.wearStatistics && data.wearStatistics.length > 0 && (
          <>
            <div className="row pt-4">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <h4 className="text-center">
                    {t(
                      "_labels.damages_wears_report.wear_counters"
                    ).toUpperCase()}
                  </h4>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped mb-1">
                      <thead className="thead">
                        <tr
                          style={{
                            backgroundColor: "dimgrey",
                            color: "white",
                            height: 50,
                            paddingTop: 10,
                          }}
                        >
                          <th className="text-uppercase">
                            {t("_labels.wear.plural")}
                          </th>
                          <th className="text-center text-uppercase">
                            {t("_labels.damages_wears.incidents")}
                          </th>
                          {data.providers &&
                            data.providers.map((provider, index) => (
                              <th key={index} className="text-center">
                                {provider}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {data.wearStatistics.map((wear) => (
                          <tr key={wear.wear}>
                            <td className="text-uppercase align-middle">
                              {t(`_damage.name.${wear.wear}`)}
                            </td>
                            <td className="text-center align-middle">
                              <b>{wear.statistics}</b> (
                              {percentFormatter.format(wear.percent)}%)
                            </td>
                            {data.providers.map((provider) => {
                              const foundProvider = wear.providers.find(
                                (p) => p.provider == provider
                              );

                              return foundProvider ? (
                                <td
                                  key={provider}
                                  className="text-center align-middle"
                                >
                                  <b>{foundProvider.statistics}</b> (
                                  {percentFormatter.format(
                                    foundProvider.percent
                                  )}
                                  %)
                                </td>
                              ) : (
                                <td
                                  key={provider}
                                  className="text-center align-middle"
                                >
                                  -
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {dataChart.wearStatistics &&
          Object.keys(dataChart.wearStatistics).length > 0 && (
            <div className="row pt-1 pb-3">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <div className="card">
                    <div className="charts">
                      <div className="bigchart">
                        <DoughnutChart
                          data={dataChart.wearStatistics}
                          title={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {data.damage && Object.keys(data.damage).length > 0 && (
          <div className="row pt-4">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t("_labels.damage.plural")}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data.damage).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "damage"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="damage"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "damage"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="damage"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "damage"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="damage"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="div box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_names`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "damage"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>
                                                  {corporate[1][company][
                                                    subsidiary
                                                  ][division]["damages"].map(
                                                    (damage) => (
                                                      <Fragment
                                                        key={damage.damage}
                                                      >
                                                        <tr
                                                          data-type="damage"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_names`
                                                          )}
                                                          className="name box collapse"
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${damage.damage}_models`,
                                                              `${company}_${subsidiary}_${division}_${damage.damage}`,
                                                              "damage"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left text-uppercase">
                                                            {t(
                                                              `_damage.name.${damage.damage}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3" />
                                                          <td className="text-right col3" />
                                                          <td className="text-right col4" />
                                                          <td className="text-right col5">
                                                            {
                                                              damage[
                                                                "statistics"
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-right col6">
                                                            {percentFormatter.format(
                                                              damage["percent"]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {damage.models.map(
                                                          (model) => (
                                                            <Fragment
                                                              key={model.model}
                                                            >
                                                              <tr
                                                                data-type="damage"
                                                                data-id={removeCharacters(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${damage.damage}_models`
                                                                )}
                                                                className={`box collapse model`}
                                                                onClick={() =>
                                                                  toggleTable(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${damage.damage}_${model.model}_tires`,
                                                                    `${company}_${subsidiary}_${division}_${damage.damage}_${model.model}`,
                                                                    "damage"
                                                                  )
                                                                }
                                                              >
                                                                <td
                                                                  className="text-left"
                                                                  style={{
                                                                    paddingTop: 27,
                                                                  }}
                                                                >
                                                                  {model.model}
                                                                </td>
                                                                <td className="text-right col1">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col2">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col3">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col4">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col5">
                                                                  <small>
                                                                    {t(
                                                                      "_general.tires"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {
                                                                    model[
                                                                      "statistics"
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-right col6">
                                                                  <small>
                                                                    {t(
                                                                      "_labels.damages_wears.percent"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {percentFormatter.format(
                                                                    model[
                                                                      "percent"
                                                                    ]
                                                                  )}
                                                                  %
                                                                </td>
                                                              </tr>

                                                              {model.tires.map(
                                                                (
                                                                  tire,
                                                                  index
                                                                ) => (
                                                                  <Fragment
                                                                    key={`${
                                                                      tire[
                                                                        "code"
                                                                      ] ??
                                                                      tire[
                                                                        "device_code"
                                                                      ]
                                                                    }_${index}`}
                                                                  >
                                                                    <tr
                                                                      data-type="damage"
                                                                      data-id={removeCharacters(
                                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_${damage.damage}_${model.model}_tires`
                                                                      )}
                                                                      className="bg-white box collapse"
                                                                    >
                                                                      <td
                                                                        className="text-left"
                                                                        style={{
                                                                          paddingTop: 27,
                                                                        }}
                                                                      >
                                                                        {tire[
                                                                          "code"
                                                                        ] ||
                                                                          tire[
                                                                            "device_code"
                                                                          ]}
                                                                      </td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.attribution"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {t(
                                                                          `_labels.attribution.options.${tire[
                                                                            "attribution"
                                                                          ].toLowerCase()}`
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.area"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {t(
                                                                          `_labels.area.options.${tire[
                                                                            "area"
                                                                          ].toLowerCase()}`
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.provider"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {tire[
                                                                          "provider_name"
                                                                        ] ||
                                                                          "-"}
                                                                      </td>
                                                                    </tr>
                                                                  </Fragment>
                                                                )
                                                              )}
                                                            </Fragment>
                                                          )
                                                        )}
                                                      </Fragment>
                                                    )
                                                  )}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {dataChart.damage && Object.keys(dataChart.damage).length > 0 && (
          <>
            {Object.keys(dataChart.damage).map((corporate, index) => (
              <div className="row pt-1 pb-3" key={index}>
                <div className="col-md-12">
                  <div className="container-fluid px-4">
                    <div className="card">
                      {Object.keys(dataChart.damage).length > 1 && (
                        <div className="card-header">{corporate}</div>
                      )}
                      {Object.keys(dataChart.damage[corporate]).map(
                        (company) => (
                          <Fragment key={company}>
                            {Object.keys(
                              dataChart.damage[corporate][company]
                            ).map((subsidiary) => (
                              <div key={subsidiary} className="charts">
                                {Object.keys(
                                  dataChart.damage[corporate][company][
                                    subsidiary
                                  ]
                                ).map((division) => (
                                  <div key={division} className="bigchartdw">
                                    <DoughnutChart
                                      data={
                                        dataChart.damage[corporate][company][
                                          subsidiary
                                        ][division]
                                      }
                                      title={`${subsidiary} | ${division}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </Fragment>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {data.wear && Object.keys(data.wear).length > 0 && (
          <div className="row pt-4">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t("_labels.wear.plural")}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data.wear).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "wear"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="wear"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "wear"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="wear"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "wear"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>
                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="wear"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="div box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_names`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "wear"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division.includes(
                                                        "NO DIVI"
                                                      )
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {corporate[1][company][
                                                    subsidiary
                                                  ][division]["wears"].map(
                                                    (wear) => (
                                                      <Fragment key={wear.wear}>
                                                        <tr
                                                          data-type="wear"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_names`
                                                          )}
                                                          className="name box collapse"
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${wear.wear}_models`,
                                                              `${company}_${subsidiary}_${division}_${wear.wear}`,
                                                              "wear"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left text-uppercase">
                                                            {t(
                                                              `_damage.name.${wear.wear}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3" />
                                                          <td className="text-right col3" />
                                                          <td className="text-right col4" />
                                                          <td className="text-right col5">
                                                            {wear["statistics"]}
                                                          </td>
                                                          <td className="text-right col6">
                                                            {percentFormatter.format(
                                                              wear["percent"]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {wear.models.map(
                                                          (model) => (
                                                            <Fragment
                                                              key={model.model}
                                                            >
                                                              <tr
                                                                data-type="wear"
                                                                data-id={removeCharacters(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${wear.wear}_models`
                                                                )}
                                                                className={`box collapse model`}
                                                                onClick={() =>
                                                                  toggleTable(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${wear.wear}_${model.model}_tires`,
                                                                    `${company}_${subsidiary}_${division}_${wear.wear}_${model.model}`,
                                                                    "wear"
                                                                  )
                                                                }
                                                              >
                                                                <td
                                                                  className="text-left"
                                                                  style={{
                                                                    paddingTop: 27,
                                                                  }}
                                                                >
                                                                  {model.model}
                                                                </td>
                                                                <td className="text-right col1">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col2">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col3">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col4">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col5">
                                                                  <small>
                                                                    {t(
                                                                      "_general.tires"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {
                                                                    model[
                                                                      "statistics"
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-right col6">
                                                                  <small>
                                                                    {t(
                                                                      "_labels.damages_wears.percent"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {percentFormatter.format(
                                                                    model[
                                                                      "percent"
                                                                    ]
                                                                  )}
                                                                  %
                                                                </td>
                                                              </tr>

                                                              {model.tires.map(
                                                                (
                                                                  tire,
                                                                  index
                                                                ) => (
                                                                  <Fragment
                                                                    key={`${
                                                                      tire[
                                                                        "code"
                                                                      ] ??
                                                                      tire[
                                                                        "device_code"
                                                                      ]
                                                                    }_${index}`}
                                                                  >
                                                                    <tr
                                                                      data-type="wear"
                                                                      data-id={removeCharacters(
                                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_${wear.wear}_${model.model}_tires`
                                                                      )}
                                                                      className="bg-white box collapse"
                                                                    >
                                                                      <td
                                                                        className="text-left"
                                                                        style={{
                                                                          paddingTop: 27,
                                                                        }}
                                                                      >
                                                                        {tire[
                                                                          "code"
                                                                        ] ||
                                                                          tire[
                                                                            "device_code"
                                                                          ]}
                                                                      </td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5"></td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.attribution"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {t(
                                                                          `_labels.attribution.options.${tire[
                                                                            "attribution"
                                                                          ].toLowerCase()}`
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.area"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {
                                                                          tire[
                                                                            "area"
                                                                          ]
                                                                        }
                                                                      </td>
                                                                      <td className="text-right text-muted col5">
                                                                        <small>
                                                                          {t(
                                                                            "_labels.damages_wears.provider"
                                                                          )}
                                                                        </small>
                                                                        <br />
                                                                        {tire[
                                                                          "provider_name"
                                                                        ] ||
                                                                          "-"}
                                                                      </td>
                                                                    </tr>
                                                                  </Fragment>
                                                                )
                                                              )}
                                                            </Fragment>
                                                          )
                                                        )}
                                                      </Fragment>
                                                    )
                                                  )}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {dataChart.wear && Object.keys(dataChart.wear).length > 0 && (
          <>
            {Object.keys(dataChart.wear).map((corporate, index) => (
              <div className="row pt-1 pb-3" key={index}>
                <div className="col-md-12">
                  <div className="container-fluid px-4">
                    <div className="card">
                      {Object.keys(dataChart.wear).length > 1 && (
                        <div className="card-header">{corporate}</div>
                      )}
                      {Object.keys(dataChart.wear[corporate]).map((company) => (
                        <Fragment key={company}>
                          {Object.keys(dataChart.wear[corporate][company]).map(
                            (subsidiary) => (
                              <div key={subsidiary} className="charts">
                                {Object.keys(
                                  dataChart.wear[corporate][company][subsidiary]
                                ).map((division) => (
                                  <div key={division} className="bigchartdw">
                                    <DoughnutChart
                                      data={
                                        dataChart.wear[corporate][company][
                                          subsidiary
                                        ][division]
                                      }
                                      title={`${subsidiary} | ${division}`}
                                    />
                                  </div>
                                ))}
                              </div>
                            )
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <FilterDamagesWearsForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  dataChart: PropTypes.object,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string, replacer = "") {
  const res = string.replaceAll(/\+/g, "plus");
  return res.replaceAll(/[,()|-\s\.\/#]/g, replacer);
}
