import {
  GET_ALERTS_OF_TIRE,
  CLEAR_ALERTS_OF_TIRE,
  GET_TIRE_ALERT,
  CLEAR_TIRE_ALERT,
  UPDATE_TIRE_ALERT,
  SET_ALERTS_OF_TIRE_FILTERS,
  CLEAR_ALERTS_OF_TIRE_FILTERS,
} from "store/actions";

const initialState = {
  alerts: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  alert: {
    alert: {
      details: "",
      priority: "HALF",
    },
    comment: "",
    created_at: "0001-01-01T00:00:00.000Z",
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALERTS_OF_TIRE:
      return {
        ...state,
        alerts: action.payload,
      };

    case CLEAR_ALERTS_OF_TIRE:
      return {
        ...state,
        alerts: initialState.alerts,
      };

    case UPDATE_TIRE_ALERT:
    case GET_TIRE_ALERT:
      return {
        ...state,
        alert: action.payload,
      };

    case CLEAR_TIRE_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case SET_ALERTS_OF_TIRE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_ALERTS_OF_TIRE_FILTERS:
      return {
        ...state,
        filter: initialState.filter,
      };
    default:
      return state;
  }
}
