import {
  GET_VEHICLE_AXLES,
  CLEAR_VEHICLE_AXLES,
  MOUNT_TIRE,
  TIRE_ROTATION,
  SINGLE_ROTATION,
} from "store/actions";

const initialState = {
  vehicle_axles: {
    vehicle_id: 0,
    economic_number: "",
    vehicle_type: {
      structure_type: "",
    },
    vehicle_type_axle: [],
    subsidiary: {
      policy: {
        send_pressure_setting: 0,
      },
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_AXLES:
      return {
        ...state,
        vehicle_axles: action.payload,
      };

    case CLEAR_VEHICLE_AXLES:
      return {
        ...state,
        vehicle_axles: { ...initialState.vehicle_axles },
      };

    case MOUNT_TIRE:
      return {
        ...state,
      };

    case TIRE_ROTATION:
      return {
        ...state,
      };

    case SINGLE_ROTATION:
      return {
        ...state,
      };

    default:
      return state;
  }
}
