import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.w100, css.ml8)}
      >
        {t("_labels.date.range")}
      </Typography>
      <Field
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="month"
        component={DateTimeField}
        label={t("_labels.date.from")}
        className={classnames(css.medium_field)}
        required={!!values.date_to}
      />

      <Field
        name="date_to"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="month"
        component={DateTimeField}
        label={t("_labels.date.to")}
        className={classnames(css.medium_field)}
        required={!!values.date_from}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
