import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModel } from "store/actions/tire/model/getTireModel";
import { actionClearTireModel } from "store/actions/tire/model/clearTireModel";

import Page from "./page";

export function TireModelInfoTab({ ...rest }) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { getTireModel, clearTireModel, setNotification, tireModel } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModel(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearTireModel();
    };
  }, [id]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Page
      open={open}
      tireModelId={id}
      tireModel={tireModel}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
}

TireModelInfoTab.propTypes = {};

const mapStateToProps = (state) => ({
  tireModel: state.Tire.Model.Model.model,
});

const mapDispatchToProps = (dispatch) => ({
  getTireModel: actionGetTireModel(dispatch),
  clearTireModel: actionClearTireModel(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireModelInfoTab);
