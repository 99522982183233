import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
// import VisibilityIcon from "@material-ui/icons/Visibility";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
// import { Link } from "react-router-dom";

// const formatter = new Intl.NumberFormat("en", {
//   maximumFractionDigits: 2,
//   minimumFractionDigits: 0,
// });

// const costKmFormatter = new Intl.NumberFormat("en", {
//   maximumFractionDigits: 4,
//   minimumFractionDigits: 4,
// });

function Page({ t, open, onClose, data, division }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <div className={classnames(css.title, css.pr8)}>
            <span>{division}</span>
            <span>
              {t("_general.vehicles")}
              {`: ${data.length}`}
            </span>
          </div>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <td className="text-center">
                    {t("_labels.economic_number")}
                  </td>
                  <td className="text-center">
                    {t("_labels.structure_type.label.singular")}
                  </td>
                  <td className="text-center">
                    {t("_labels.vehicle_type.label")}
                  </td>
                  <td className="text-center">{t("_labels.review_date")}</td>
                </tr>
              </thead>
              <tbody>
                {data.map((vehicle, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {vehicle["economic_number"]}
                    </td>
                    <td className="text-center">
                      {t(
                        `_labels.structure_type.options.${vehicle[
                          "structure_type"
                        ].toLowerCase()}`
                      )}
                    </td>
                    <td className="text-center">{vehicle["type"]}</td>
                    <td className="text-center">
                      {moment(vehicle.vehicle_review_date).format("LL h:mm a")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  division: PropTypes.string,
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
