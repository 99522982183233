import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  isUpdate,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
  ...rest
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>{isUpdate ? t("update_title") : t("title")}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => (
              <Form isUpdate={isUpdate} onClose={onClose} {...p} {...rest} />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  tireBrands: PropTypes.array.isRequired,
  tireUseTypes: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Page;
