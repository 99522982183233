import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetReportWarehouse } from "store/actions/reports/warehouse/getWarehouse";
import { actionClearReportWarehouseFilters } from "store/actions/reports/warehouse/clearWarehouseFilters";
import { actionGenerateExcel } from "store/actions/reports/warehouse/generateExcel";
import { useLang } from "hooks/lang";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { actionSetReportWarehouseFilters } from "store/actions/reports/warehouse/setWarehouseFilters";
import { actionSetSelectedReportWarehouseFilters } from "store/actions/reports/warehouse/setSelectedWarehouseFilters";

function Warehouse({ ...rest }) {
  const t = useLang();

  const {
    filter,
    selectedFilter,
    setNotification,
    getWarehouse,
    clearWarehouseFilters,
    generateExcel,
    setReportWarehouseFilters,
    setSelectedReportWarehouseFilters,
    account,
    workArea,
  } = rest;
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getWarehouse({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    };

    setReportWarehouseFilters(filter);
    setSelectedReportWarehouseFilters(selectedFilters);

    return () => {
      clearWarehouseFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      data={data}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
    />
  );
}
Warehouse.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.Warehouse.filter,
  selectedFilter: state.Reports.Warehouse.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getWarehouse: actionGetReportWarehouse(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearWarehouseFilters: actionClearReportWarehouseFilters(dispatch),
  generateExcel: actionGenerateExcel(dispatch),
  setReportWarehouseFilters: actionSetReportWarehouseFilters(dispatch),
  setSelectedReportWarehouseFilters:
    actionSetSelectedReportWarehouseFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
