import {
  GET_TIRE_WEAR,
  CLEAR_TIRE_WEAR,
  GET_TIRE_WEARS,
  CLEAR_TIRE_WEARS,
  DELETE_TIRE_WEAR,
  REGISTER_TIRE_WEAR,
  SET_TIRE_WEAR_FILTERS,
  CLEAR_TIRE_WEAR_FILTERS,
} from "store/actions";

const initialState = {
  wear: {
    wear: { name: "" },
    tire: { code: "" },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  wears: [],
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_TIRE_WEAR:
    case GET_TIRE_WEAR:
      return {
        ...state,
        wear: action.payload,
      };

    case CLEAR_TIRE_WEAR:
      return {
        ...state,
        wear: initialState.wear,
      };

    case GET_TIRE_WEARS:
      return {
        ...state,
        wears: action.payload,
      };

    case CLEAR_TIRE_WEARS:
      return {
        ...state,
        wears: initialState.wears,
      };

    case DELETE_TIRE_WEAR:
      return {
        ...state,
        wears: {
          ...state.wears,
          data: state.wears.data.filter(
            (wear) => wear.tire_wear_id !== action.payload
          ),
        },
      };

    case SET_TIRE_WEAR_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_TIRE_WEAR_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
