import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function DoughnutChart({ data, title, tooltipOptions, legendPosition }) {
  return (
    <Page
      options={{
        responsive: true,
        maintainAspectRatio: false,
        fill: true,
        locale: "en",
        plugins: {
          title: {
            display: title ? true : false,
            text: title || "",
            font: {
              size: 14,
            },
          },
          legend: {
            position: legendPosition || "bottom",
          },
          ...tooltipOptions,
        },
      }}
      data={data}
    />
  );
}

DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string,
  displayTitle: PropTypes.bool,
  tooltipOptions: PropTypes.object,
  legendPosition: PropTypes.string,
};

export default DoughnutChart;
