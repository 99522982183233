import { NOTIFICATION, CLOSE_NOTIFICATION } from "store/actions";

export const actionSetNotification =
  (dispatch) =>
  (args, isError = false) => {
    if (isError) {
      console.error(args);
    }

    const { code, message, onAction, textAction, hideDuration } = args;
    dispatch({
      type: NOTIFICATION,
      payload: {
        id: new Date().getTime(),
        code,
        message,
        onAction,
        textAction,
        hideDuration: !isError ? hideDuration : 12000,
      },
    });
  };

export const actionCloseNotification = (dispatch) => (id) => {
  dispatch({
    type: CLOSE_NOTIFICATION,
    payload: id,
  });
};
