import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import GpsTabs from "components/organisms/gps/GpsTabs";
import GpsGeofenceFilterForm from "components/organisms/gps/GpsGeofenceFilterForm";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterListIcon from "@material-ui/icons/FilterList";
import GpsGeofenceCard from "components/molecules/gps/GpsGeofenceCard";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";
import InfoGpsGeofenceDialog from "components/organisms/gps/InfoGpsGeofenceDialog";

function Page({
  t,
  gpsGeofenceList,
  gpsId,
  gpsGeofenceId,
  handleChangePage,
  filterOpen,
  openDialog,
  showGpsGeofencePermit,
  handleOpenFilter,
  handleCloseFilter,
  handleOpenDialog,
  handleCloseDialog,
}) {
  return (
    <>
      <GpsTabs
        className={classnames({
          [css.empty]: gpsGeofenceList.data.length === 0,
        })}
        optionsAppBar={
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        }
      >
        {gpsGeofenceList.data.length > 0 ? (
          <>
            <div className={classnames(css.container)}>
              {gpsGeofenceList.data.map((gpsGeofence, index) => {
                return (
                  <GpsGeofenceCard
                    t={t}
                    key={index}
                    title={gpsGeofence.date}
                    description={gpsGeofence.odometer}
                    status={gpsGeofence.status}
                    className={css.card}
                    tooltipPermit={
                      !showGpsGeofencePermit ? t("_permits.show.gps") : ""
                    }
                    handleClick={() => {
                      handleOpenDialog(gpsGeofence.gps_geofence_id);
                    }}
                  />
                );
              })}
            </div>
            <div className={classnames(css.pagination)}>
              <Pagination
                page={gpsGeofenceList.current_page}
                totalPages={gpsGeofenceList.last_page}
                onChangePage={handleChangePage}
              />
            </div>
          </>
        ) : (
          <EmptyListIcon text={t("_general.no_gps_geofence")} />
        )}
      </GpsTabs>

      <GpsGeofenceFilterForm open={filterOpen} onClose={handleCloseFilter} />

      <InfoGpsGeofenceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        gpsId={gpsId}
        gpsGeofenceId={gpsGeofenceId}
      />
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  gpsGeofenceList: PropTypes.object.isRequired,
  showGpsGeofencePermit: PropTypes.bool.isRequired,
  gpsId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gpsGeofenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChangePage: PropTypes.func.isRequired,
  filterOpen: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default Page;
