import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function LabelColorViewer({ ...props }) {
  return <Page {...props}></Page>;
}

LabelColorViewer.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  labelRef: PropTypes.object,
  className: PropTypes.string,
};

export default LabelColorViewer;
