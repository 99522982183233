import { ADD_CHECKLIST } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddChecklist = (dispatch) => async (fields) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: "POST",
      path: "checklist",
      data: fields,
    });

    dispatch({
      type: ADD_CHECKLIST,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
