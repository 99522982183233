import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { Formik } from "formik";

import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";

import HomeTemplate from "components/templates/general/Home";

import Form from "./Form";
import css from "./index.module.scss";

function Page({
  t,
  initialValues,
  initialErrors,
  handleSubmit,
  handleValidate,
}) {
  return (
    <HomeTemplate>
      <Paper className={classnames(css.form)}>
        <Typography variant="h5">{t("title")}</Typography>
        <Typography gutterBottom variant="body2" color="textSecondary">
          {t("sub_title")}
        </Typography>
        <Formik
          initialErrors={initialErrors}
          initialValues={initialValues}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {(p) => <Form {...p} />}
        </Formik>
      </Paper>
    </HomeTemplate>
  );
}

Page.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Page;
