export default {
  last_travel: "Último recorrido",
  no_travel: "No existe registro de ningún recorrido",
  permits: {
    delete: "Sin permisos para eliminar recorridos de vehículos",
    show: {
      last_vehicle_travel:
        "Sin permisos para ver últimos recorridos de vehículos",
    },
  },
};
