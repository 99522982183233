import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDepthPolicies } from "store/actions/subsidiary/policy/depthPolicyByAxle/getDepthPolicies";
import { actionClearDepthPolicies } from "store/actions/subsidiary/policy/depthPolicyByAxle/clearDepthPolicies";
import { actionSetDepthPolicyFilters } from "store/actions/subsidiary/policy/depthPolicyByAxle/setDepthPolicyFilters";
import { actionClearDepthPolicyFilters } from "store/actions/subsidiary/policy/depthPolicyByAxle/clearDepthPolicyFilters";
import { actionDeleteDepthPolicy } from "store/actions/subsidiary/policy/depthPolicyByAxle/deleteDepthPolicy";

import {
  UPDATE_VEHICLE_DEPTH_POLICY,
  DELETE_VEHICLE_DEPTH_POLICY,
  CREATE_VEHICLE_DEPTH_POLICY,
} from "store/actions/account/permits";

import Page from "./page";

export function DepthPolicyTab({ ...rest }) {
  const t = useLang();
  const { id: subsidiaryId } = useParams();
  const {
    permits,
    filter,
    depthPolicies,
    getDepthPolicies,
    clearDepthPolicies,
    setDepthPolicyFilters,
    clearDepthPolicyFilters,
    deleteDepthPolicy,
    setNotification,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [vehicleId, setVehicleId] = useState(0);
  const [policyId, setPolicyId] = useState(null);
  const [openMultipleForm, setOpenMultipleForm] = useState(false);
  const [vehicleIds, setVehicleIds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getDepthPolicies({ page: 1, subsidiaryId, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearDepthPolicies();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearDepthPolicyFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getDepthPolicies({ page, subsidiaryId, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(vehicleId) {
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setPolicyId(null);
    setOpenForm(false);
    setVehicleId(0);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setDepthPolicyFilters({ search });
  }

  function handleEdit(vehicleId, policyId) {
    setPolicyId(policyId);
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  async function onDeleteDepthPolicy(subsidiaryId, vehicleId, depthPolicyId) {
    try {
      await deleteDepthPolicy(subsidiaryId, vehicleId, depthPolicyId);
      setNotification({
        message: t("_messages.deleted.depth_policy"),
      });
      clearDepthPolicyFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(subsidiaryId, vehicleId, depthPolicyId) {
    setNotification({
      onAction: () => {
        onDeleteDepthPolicy(subsidiaryId, vehicleId, depthPolicyId);
      },
      message: t("_messages.delete.depth_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenMultiple(vehicleIds) {
    setVehicleIds(vehicleIds);
    setOpenMultipleForm(true);
  }

  function handleCloseMultiple() {
    setOpenMultipleForm(false);
  }

  return (
    <Page
      t={t}
      subsidiaryId={subsidiaryId}
      depthPoliciesData={depthPolicies}
      openForm={openForm}
      openFilter={openFilter}
      openMultiple={openMultipleForm}
      search={filter.search}
      vehicleId={vehicleId}
      policyId={policyId}
      vehicleIds={vehicleIds}
      updateVehicleDepthPolicyPermit={permits.includes(
        UPDATE_VEHICLE_DEPTH_POLICY
      )}
      deleteVehicleDepthPolicyPermit={permits.includes(
        DELETE_VEHICLE_DEPTH_POLICY
      )}
      createVehicleDepthPolicyPermit={permits.includes(
        CREATE_VEHICLE_DEPTH_POLICY
      )}
      handleChangePage={handleChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleSearch={handleSearch}
      handleOpenMultiple={handleOpenMultiple}
      handleCloseMultiple={handleCloseMultiple}
    />
  );
}

DepthPolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  depthPolicies: state.Subsidiary.DepthPolicy.depth_policies,
  filter: state.Subsidiary.DepthPolicy.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getDepthPolicies: actionGetDepthPolicies(dispatch),
  clearDepthPolicies: actionClearDepthPolicies(dispatch),
  deleteDepthPolicy: actionDeleteDepthPolicy(dispatch),
  setDepthPolicyFilters: actionSetDepthPolicyFilters(dispatch),
  clearDepthPolicyFilters: actionClearDepthPolicyFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthPolicyTab);
