import { UPDATE_USER } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateUser = (dispatch) => async (fields, userId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    for (const key in fields) {
      if (fields[key] === "") {
        delete fields[key];
      }
    }
    const response = await http({
      method: "PUT",
      path: `user/${userId}`,
      data: fields,
    });

    dispatch({
      type: UPDATE_USER,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
