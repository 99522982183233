export default {
  title: "New rfid",
  update_title: "Update rfid",
  messages: {
    rfid_created: "Done, the rfid has been successfully created",
    rfid_updated: "Done, the rfid has been successfully updated",
    rfid_deleted: "Done, the rfid has been successfully deleted",
    delete: "Are you sure to delete the rfid?",
  },
  form: {
    permits: {
      update: "No permissions to update rfids",
      delete: "No permissions to delete rfids",
    },
  },
};
