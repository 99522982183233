import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { values, setNotification, getCompanies, setCompanies } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
            corporates: values.corporate_id,
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.corporate_id) {
      fetchData();
    }
  }, [values.corporate_id]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCompanies: actionGetCompanies(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
