export default {
  title: "New Provider",
  update_title: "Update provider",
  form: {
    permits: {
      update: "No permissions to update providers",
      delete: "No permissions to delete providers",
    },
  },
  messages: {
    delete: "Are you sure to delete this provider?",
    provider_created: "Done, provider has been successfully created",
    provider_updated: "Done, the provider was updated successfully",
  },
};
