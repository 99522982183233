import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import css from "./index.module.scss";

function Page({
  t,
  tireModels,
  tireModelVariations,
  isValid,
  isSubmitting,
  onClose,
  handleClean,
  handleSubmit,
  initialValues,
  values,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        id="tire_model"
        name="tire_model"
        autoHighlight
        disableClearable={initialValues.tire_model !== null}
        blurOnSelect
        component={Autocomplete}
        options={tireModels}
        getOptionLabel={(option) => (option ? `${option.name}` : "")}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("tire_model", value)}
        value={values.tire_model}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames({
          "MuiFormControl-root": true,
          [css.field]: true,
        })}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="tire_model_variation"
        className={classnames({
          [css.field]: true,
        })}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.tire_model_variation_field.default")}
        defaultValue={
          tireModelVariations.some(
            (tireModelVariation) =>
              tireModelVariation.tire_model_variation_id ===
              values.tire_model_variation
          )
            ? null
            : values.tire_model_variation
        }
        component={SelectField}
        label={t("_labels.tire_model_variation_field.label")}
      >
        {tireModelVariations.map((tireModelVariation, index) => {
          return (
            <MenuItem
              key={index}
              value={tireModelVariation.tire_model_variation_id.toString()}
              disabled={
                !tireModelVariation.status || !tireModelVariation.approved
              }
            >
              {`${tireModelVariation.tire_size.size} ${tireModelVariation.number_layers}`}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
