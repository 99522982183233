import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  vehicleType,
  vehiclesTypes,
  createVehicleTypePermit,
  updateVehicleTypePermit,
  deleteVehicleTypePermit,
  filter,
  t,
  getVehicleType,
  addVehicleType,
  updateVehicleType,
  clearVehicleTypeFilters,
  setVehicleTypeFilters,
  deleteVehicleType,
  setVehicleTypeStatus,
  getVehiclesTypes,
  clearVehiclesTypes,
}) {
  return (
    <Home
      item={vehicleType}
      name="vehicle_type"
      filter={filter}
      createTooltipPermit={
        !createVehicleTypePermit ? t("_permits.create.vehicle_type") : ""
      }
      currentPage={vehiclesTypes.current_page}
      deleteConfirmationMessage={t("_messages.delete.vehicle_type")}
      disableConfirmationMessage={t(
        "_messages.change_status.vehicle_type.disable"
      )}
      enableConfirmationMessage={t(
        "_messages.change_status.vehicle_type.enable"
      )}
      rows={vehiclesTypes.data.map((vehicleType) => {
        return {
          id: vehicleType.vehicle_type_id,
          key: vehicleType.vehicle_type_id,
          status: {
            status: vehicleType.status,
            tooltipPermit: !updateVehicleTypePermit
              ? t("_permits.update.vehicle_type")
              : "",
          },
          name: vehicleType.name,
          structure_type: t(
            `_labels.structure_type.options.${vehicleType.structure_type.toLowerCase()}`
          ),
          description: vehicleType.description,
          axle: vehicleType.vehicle_type_axle_count,
          view: {
            to: `/vehicle/type/${vehicleType.vehicle_type_id}`,
            tooltipPermit: !updateVehicleTypePermit
              ? t("_permits.update.vehicle_type")
              : "",
          },
          edit: {
            tooltipPermit: !updateVehicleTypePermit
              ? t("_permits.update.vehicle_type")
              : "",
          },
          delete: {
            tooltipPermit: !deleteVehicleTypePermit
              ? t("_permits.delete.vehicle_type")
              : "",
          },
        };
      })}
      title={t("_general.vehicles_types")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.name.singular") },
        {
          id: "structure_type",
          label: t("_labels.structure_type.label.singular"),
        },
        { id: "description", label: t("_labels.description") },
        { id: "axle", label: t("_labels.axle.plural") },
        { id: "actions", label: "" },
      ]}
      lastPage={vehiclesTypes.last_page}
      noItemsText={t("_general.no_vehicles_types")}
      initialDefaultValues={{
        name: "",
        status: "",
        structure_type: "",
        description: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "structure_type",
          required: true,
          component: "select",
          label: t("_labels.structure_type.label.singular"),
          disabled: "isUpdate",
          selectItems: [
            {
              value: "TRUCK_TRACTOR",
              name: t("_labels.structure_type.options.truck_tractor"),
            },
            { value: "TRUCK", name: t("_labels.structure_type.options.truck") },
            { value: "BOX", name: t("_labels.structure_type.options.box") },
            { value: "DOLLY", name: t("_labels.structure_type.options.dolly") },
            { value: "VAN", name: t("_labels.structure_type.options.van") },
          ],
        },
        {
          name: "description",
          required: false,
          fullWidth: true,
          rows: 4,
          multiline: true,
          label: t("_labels.description"),
        },
      ]}
      createFormTitle={t("_titles.new.vehicle_type")}
      updateFormTitle={t("_titles.update.vehicle_type")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.vehicle_type"),
        },
        date: true,
        structure_type: true,
        axle_number: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
        structure_type: [],
        axle_number: "",
      }}
      createdMessage={t("_messages.created.vehicle_type")}
      updatedMessage={t("_messages.updated.vehicle_type")}
      getItemAction={getVehicleType}
      setFiltersAction={setVehicleTypeFilters}
      clearFiltersAction={clearVehicleTypeFilters}
      deleteAction={deleteVehicleType}
      setStatusAction={setVehicleTypeStatus}
      getItemsAction={getVehiclesTypes}
      clearItemsAction={clearVehiclesTypes}
      addAction={addVehicleType}
      updateAction={updateVehicleType}
    />
  );
}

Page.propTypes = {
  vehicleType: PropTypes.object.isRequired,
  vehiclesTypes: PropTypes.object.isRequired,
  createVehicleTypePermit: PropTypes.bool.isRequired,
  updateVehicleTypePermit: PropTypes.bool.isRequired,
  deleteVehicleTypePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getVehicleType: PropTypes.func.isRequired,
  addVehicleType: PropTypes.func.isRequired,
  updateVehicleType: PropTypes.func.isRequired,
  clearVehicleTypeFilters: PropTypes.func.isRequired,
  setVehicleTypeFilters: PropTypes.func.isRequired,
  deleteVehicleType: PropTypes.func.isRequired,
  setVehicleTypeStatus: PropTypes.func.isRequired,
  getVehiclesTypes: PropTypes.func.isRequired,
  clearVehiclesTypes: PropTypes.func.isRequired,
};

export default Page;
