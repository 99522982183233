import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionClearDrivers } from "store/actions/driver/clearDrivers";
import { actionSetDriverFilters } from "store/actions/driver/setDriverFilters";
import { actionClearDriverFilters } from "store/actions/driver/clearDriverFilters";
import { actionDeleteDriver } from "store/actions/driver/deleteDriver";
import { actionSetDriverStatus } from "store/actions/driver/setDriverStatus";
import { actionGetDriver } from "store/actions/driver/getDriver";
import { actionAddDriver } from "store/actions/driver/addDriver";
import { actionUpdateDriver } from "store/actions/driver/updateDriver";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import {
  CREATE_DRIVER,
  UPDATE_DRIVER,
  DELETE_DRIVER,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeDriver({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    driver,
    drivers,
    account,
    deleteDriver,
    setDriverStatus,
    getDriver,
    addDriver,
    updateDriver,
    getDrivers,
    getSubsidiaries,
    setNotification,
    clearDrivers,
    setDriverFilters,
    clearDriverFilters,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  return (
    <Page
      drivers={drivers}
      createDriverPermit={permits.includes(CREATE_DRIVER)}
      updateDriverPermit={permits.includes(UPDATE_DRIVER)}
      deleteDriverPermit={permits.includes(DELETE_DRIVER)}
      driver={driver}
      filter={filter}
      subsidiaries={subsidiaries}
      t={t}
      getDriver={getDriver}
      addDriver={addDriver}
      updateDriver={updateDriver}
      clearDriverFilters={clearDriverFilters}
      setDriverFilters={setDriverFilters}
      deleteDriver={deleteDriver}
      setDriverStatus={setDriverStatus}
      getDrivers={getDrivers}
      clearDrivers={clearDrivers}
    />
  );
}

HomeDriver.propTypes = {};

const mapStateToProps = (state) => ({
  drivers: state.Driver.Driver.drivers,
  driver: state.Driver.Driver.driver,
  filter: state.Driver.Driver.filter_driver,
  permits: state.Account.permits,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  getDrivers: actionGetDrivers(dispatch),
  clearDrivers: actionClearDrivers(dispatch),
  setNotification: actionSetNotification(dispatch),
  setDriverFilters: actionSetDriverFilters(dispatch),
  clearDriverFilters: actionClearDriverFilters(dispatch),
  deleteDriver: actionDeleteDriver(dispatch),
  setDriverStatus: actionSetDriverStatus(dispatch),
  getDriver: actionGetDriver(dispatch),
  addDriver: actionAddDriver(dispatch),
  updateDriver: actionUpdateDriver(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeDriver);
