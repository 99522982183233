import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchInput from "components/molecules/general/SearchInput";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import AddIcon from "@material-ui/icons/Add";

import AssociationTabs from "components/organisms/association/AssociationTabs";
import TableMultiple from "components/organisms/general/TableMultiple";
import Pagination from "components/molecules/general/Pagination";

import DepthPolicyForm from "components/organisms/association/DepthPolicyForm";
import DepthPolicyFilterForm from "components/organisms/association/DepthPolicyFilterForm";
import UpdateDepthPolicyForm from "components/organisms/association/UpdateDepthPolicyForm";
import MultiDepthPolicyForm from "components/organisms/association/MultiDepthPolicyForm";

import css from "./index.module.scss";

function Page({
  t,
  policies,
  search,
  vehicle,
  depthPolicy,
  openForm,
  openUpdateForm,
  openFilter,
  updateVehicleDepthPolicyPermit,
  deleteVehicleDepthPolicyPermit,
  createVehicleDepthPolicyPermit,
  handleSearch,
  handleEdit,
  handleCloseUpdateForm,
  handleOpenForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  handleChangePage,
  openMultiple,
  vehicles,
  handleOpenMultiple,
  handleCloseMultiple,
}) {
  return (
    <AssociationTabs
      className={classnames({
        [css.empty]: policies.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            onClick={handleOpenFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ?? ""} onChange={handleSearch} />
        </>
      }
    >
      {policies.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableMultiple
              headers={[
                { label: t("_general.vehicles"), colspan: 4 },
                { label: t("_labels.axle.plural"), colspan: 5 },
              ]}
              rows={policies.data.map((vehicle) => ({
                id: vehicle.commissioned_vehicle_id,
                enrollment: vehicle?.enrollment,
                economic_number: vehicle?.economic_number,
                vehicle_type_name: vehicle?.vehicle_type.name,
                vehicle_type: vehicle.vehicle_type,
                add: (
                  <Button
                    variant="icon"
                    color="secondary"
                    tooltip={
                      !createVehicleDepthPolicyPermit
                        ? t("_permits.create.depth_policy")
                        : ""
                    }
                    disabled={
                      !createVehicleDepthPolicyPermit ||
                      checkIfCanCreatePolicy(vehicle)
                    }
                    onClick={() => handleOpenForm(vehicle)}
                  >
                    <AddIcon />
                  </Button>
                ),
                multiple:
                  vehicle.commissioned_vehicle_depth_policy?.length > 0
                    ? vehicle.commissioned_vehicle_depth_policy.map(
                        (policy) => ({
                          id: policy.commissioned_depth_policy_id,
                          axle: t(
                            `_labels.axle_field.options.${policy.axle_type.toLowerCase()}`
                          ),
                          good_depth: `${policy.good_depth} mm`,
                          scheduled_withdrawal: `${policy.scheduled_withdrawal} mm`,
                          critical_withdrawal: `${policy.critical_withdrawal} mm`,
                          edit: {
                            tooltipPermit: !updateVehicleDepthPolicyPermit
                              ? t("_permits.update.depth_policy")
                              : "",
                            onEdit: () => handleEdit(vehicle, policy),
                          },
                          delete: {
                            tooltipPermit: !deleteVehicleDepthPolicyPermit
                              ? t("_permits.delete.depth_policy")
                              : "",
                            onDelete: () =>
                              handleDelete(
                                vehicle.commissioned_vehicle_id,
                                policy.commissioned_depth_policy_id
                              ),
                          },
                        })
                      )
                    : [
                        {
                          id: "",
                          axle: "",
                          recommended_depth: "",
                          tolerance: "",
                        },
                      ],
              }))}
              propertys={[
                "economic_number",
                "enrollment",
                "vehicle_type_name",
                "add",
                "multiple",
              ]}
              nestedPropertys={[
                "axle",
                "good_depth",
                "scheduled_withdrawal",
                "critical_withdrawal",
              ]}
              nestedHeaders={[
                t("_labels.economic_number"),
                t("_labels.auto_enrollment"),
                t("_labels.vehicle_type.label"),
                "",
                t("_labels.axle.singular"),
                t("_labels.good_condition"),
                t("_labels.scheduled_withdrawal"),
                t("_labels.critical_withdrawal"),
                "",
              ]}
              multipleProperty={"multiple"}
              selectable={true}
              selectAction={handleOpenMultiple}
              selectActionButton={t("_general.set")}
              objectSelection={true}
              totalRows={policies.total}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={policies.current_page}
              totalPages={policies.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_depth_policies")} />
      )}

      <DepthPolicyForm
        open={openForm}
        onClose={handleCloseForm}
        vehicle={vehicle}
      />

      <UpdateDepthPolicyForm
        open={openUpdateForm}
        onClose={handleCloseUpdateForm}
        vehicle={vehicle}
        depthPolicy={depthPolicy}
      />

      <MultiDepthPolicyForm
        open={openMultiple}
        onClose={handleCloseMultiple}
        vehicles={vehicles}
      />

      <DepthPolicyFilterForm open={openFilter} onClose={handleCloseFilter} />
    </AssociationTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  policies: PropTypes.object.isRequired,
  search: PropTypes.string,
  openForm: PropTypes.bool.isRequired,
  openUpdateForm: PropTypes.bool.isRequired,
  openVehicleTypeForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleOpenVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  updateVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  deleteVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  createVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  vehicle: PropTypes.object,
  depthPolicy: PropTypes.object,
  openMultiple: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCloseUpdateForm: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenMultiple: PropTypes.func.isRequired,
  handleCloseMultiple: PropTypes.func.isRequired,
};

export default Page;

function checkIfCanCreatePolicy(vehicle) {
  const validAxles = Object.keys(
    vehicle.vehicle_type.vehicle_type_axle.reduce((acc, val) => {
      if (!acc[val.axle_type]) {
        acc[val.axle_type] = true;
      }

      return acc;
    }, {})
  );

  const currentPolicies = Object.keys(
    vehicle.commissioned_vehicle_depth_policy.reduce((acc, val) => {
      if (!acc[val.axle_type]) {
        acc[val.axle_type] = true;
      }

      return acc;
    }, {})
  );

  return validAxles.every((axle) => currentPolicies.includes(axle));
}
