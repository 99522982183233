import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionSetPotentialSavingsFilters } from "store/actions/reports/potential_savings/setPotentialSavingsFilters";
import { actionClearPotentialSavingsFilters } from "store/actions/reports/potential_savings/clearPotentialSavingsFilters";
import { actionSetSelectedPotentialSavingsFilters } from "store/actions/reports/potential_savings/setSelectedPotentialSavingsFilters";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

export function FilterPotentialSavingsForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setPotentialSavingsFilters,
    // clearPotentialSavingsFilters,
    setSelectedFilters,
    getVehicles,
    getCorporates,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      date_from: "",
      date_to: "",
      economic_number: "",
      month: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);

        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));

        data = await getVehicles(
          {
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        setVehicles(data.sort(alphabeticalSort("economic_number")));

        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && !loaded) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.company_id ? filter.company_id.split(",") : [],
        subsidiary_id: filter.subsidiary_id
          ? filter.subsidiary_id.split(",")
          : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        month: filter.month,
        economic_number: filter.economic_number || "",
      });
    } else {
      resetFormik();
    }
  }, [open, loaded]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.company_id = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.company_id = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiary_id = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiary_id = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.economic_number) {
        params.economic_number = values.economic_number;
        const selectedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id == values.economic_number
        );
        setSelectedFilters({
          economic_number: selectedVehicle.economic_number,
        });
      }

      if (values.month) {
        params.month = values.month;
        params.date_from = moment(values.month)
          .startOf("month")
          .format("YYYY-MM-DD");
        params.date_to = moment(values.month)
          .endOf("month")
          .format("YYYY-MM-DD");
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
          month: values.month,
        });
      }

      await setPotentialSavingsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);

    const lastMonth = moment().subtract(1, "months");
    const startDate = lastMonth.startOf("month").format("YYYY-MM-DD");
    const endDate = lastMonth.endOf("month").format("YYYY-MM-DD");
    const month = lastMonth.startOf("month").format("YYYY-MM");

    const filters = {
      date_from: startDate,
      date_to: endDate,
      month,
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries[0]?.subsidiary_id?.toString(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries[0]?.subsidiary?.name,
      date_from: startDate,
      date_to: endDate,
      month,
    };
    setPotentialSavingsFilters(filters);
    setSelectedFilters(selectedFilters);
    // clearPotentialSavingsFilters();
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      vehicles={vehicles}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      t={t}
    />
  );
}

FilterPotentialSavingsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.PotentialSavings.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setPotentialSavingsFilters: actionSetPotentialSavingsFilters(dispatch),
  clearPotentialSavingsFilters: actionClearPotentialSavingsFilters(dispatch),
  setSelectedFilters: actionSetSelectedPotentialSavingsFilters(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  getCorporates: actionGetCorporates(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPotentialSavingsForm);
