import { http } from "store/actions/http";
import { GET_TIRE_MODEL } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireModel = (dispatch) => async (tireModelId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `tire/model/${tireModelId}`,
    });

    dispatch({
      type: GET_TIRE_MODEL,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
