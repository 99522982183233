import HomeUser from "./HomeUser";
import UserInfoTab from "./UserInfoTab";
import UserCorporateTab from "./UserCorporateTab";
import UserCompanyTab from "./UserCompanyTab";

export default {
  home_user: HomeUser,
  user_info_tab: UserInfoTab,
  user_corporate_tab: UserCorporateTab,
  user_company_tab: UserCompanyTab,
};
