import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import Page from "./page";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import moment from "moment-timezone";

export const NAMESPACE = "pages/review/HomeReview";

function CostsTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [cpkData, setCpkData] = useState({});
  const [costData, setCostData] = useState({});
  const [kmData, setKmData] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);

  const { showProgress, setNotification, currentTab } = rest;

  useEffect(() => {
    let newSubsidiaries = [];
    if (cpkData && Object.keys(cpkData).length > 0) {
      newSubsidiaries = Object.keys(cpkData);
    }
    newSubsidiaries = [...new Set(newSubsidiaries)];
    setSubsidiaries(newSubsidiaries);
  }, [cpkData]);

  useEffect(() => {
    async function getCpkData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/statistics/cpk`,
          params: {
            level: currentTab === "SUBSIDIARY" ? "subsidiary" : "company",
          },
        });
        const newCpkData = {};
        const newCostData = {};
        const newKmData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformData(subsidiaryValues);
          newCpkData[subsidiary] = {
            datasets: [
              getDataset(
                transformedData["CPK"],
                t("_titles.graphs.cost_km.main"),
                false,
                currentTab === "SUBSIDIARY" ? subsidiary : undefined
              ),
            ],
          };
          newCostData[subsidiary] = {
            datasets: [
              getDataset(
                transformedData["cost"],
                t("_titles.graphs.cost_km.cost"),
                false,
                currentTab === "SUBSIDIARY" ? subsidiary : undefined
              ),
            ],
          };
          newKmData[subsidiary] = {
            datasets: [
              getDataset(
                transformedData["mileage"],
                t("_titles.graphs.cost_km.km"),
                false,
                currentTab === "SUBSIDIARY" ? subsidiary : undefined
              ),
            ],
          };
        }
        setCpkData(newCpkData);
        setCostData(newCostData);
        setKmData(newKmData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getCpkData();
  }, [currentTab]);

  return (
    <Page
      t={t}
      subsidiaries={subsidiaries}
      cpkData={cpkData}
      costData={costData}
      kmData={kmData}
    />
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CostsTab);

function transformData(subsidiaryObject) {
  let newData = {
    CPK: [],
    mileage: [],
    cost: [],
  };

  for (const [date, data] of Object.entries(subsidiaryObject)) {
    newData["CPK"].push({
      fecha: date,
      total: data["cpk"],
    });
    newData["cost"].push({
      fecha: date,
      total: data["sum_cost"],
    });
    newData["mileage"].push({
      fecha: date,
      total: data["sum_mileage"],
    });
  }
  for (const value of Object.values(newData)) {
    value.sort((a, b) => {
      return new Date(a.fecha) - new Date(b.fecha);
    });
  }
  return newData;
}

function getDataset(data, label, disablePoints = false, subsidiary) {
  const colors = [
    "rgba(255, 99, 132, 1.0)",
    "rgba(54, 162, 235, 1.0)",
    "rgba(255, 206, 86, 1.0)",
    "rgba(75, 192, 192, 1.0)",
    "rgba(153, 102, 255, 1.0)",
    "rgba(255, 159, 64, 1.0)",
    "rgba(100, 159, 64, 1.0)",
    "rgba(255, 58, 86, 1.0)",
  ];
  return {
    label: label,
    data: data.map((el) => ({
      ...el,
      fecha: moment(el.fecha).format("YYYY-MM"),
      subsidiary,
    })),
    yAxisID: "y",
    fill: false,
    pointWidth: 2,
    pointRadius: disablePoints ? 0 : 2,
    pointHoverRadius: 4,
    borderColor: colors,
    backgroundColor: colors,
    tension: 0.1,
    pointBackgroundColor: "rgb(50, 50, 50, 0.8)",
    pointBorderColor: "rgb(50, 50, 50, 0.8)",
  };
}
