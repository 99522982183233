import React from "react";
import classnames from "classnames";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import VehicleHistoryTabs from "components/organisms/vehicle/VehicleHistoryTabs";

function Page() {
  return (
    <VehicleHistoryTabs
      optionsAppBar={
        <>
          <Button className={classnames("bw")} variant="icon">
            <VisibilityIcon />
          </Button>
          <Button className={classnames("bw")} variant="icon">
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    ></VehicleHistoryTabs>
  );
}

Page.propTypes = {};

export default Page;
