import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { http } from "store/actions/http";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";
import { useLang } from "hooks/lang";

import Page from "./page";

function VehiclesTable({ ...rest }) {
  const t = useLang();
  const { data, filter, selectedFilter, setNotification, showProgress } = rest;

  const [vehicles, setVehicles] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [visibleStructure, setVisibleStructure] = useState(0);
  const [divisionData, setDivisionData] = useState([]);

  const [divisionVehicles, setDivisionVehicles] = useState([]);
  const [tires, setTires] = useState([]);
  const [division, setDivision] = useState("");
  const [depth, setDepth] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);

  useEffect(() => {
    setVehicles(getVehicles(data));
    setSummaryData(getSummaryData(data));
    setDivisionData(getDivisionData(data));
  }, [data]);

  function handleChange(index) {
    setVisibleStructure(index);
  }

  async function handleClickUnits(division) {
    const params = {
      company_name: selectedFilter.companies,
      corporate_name: selectedFilter.corporate,
      division_name: division,
    };

    if (filter.only_empty_vehicles) {
      params.only_empty_vehicles = filter.only_empty_vehicles;
    }

    if (division != "total") {
      const progress = showProgress();
      try {
        const response = await http({
          path: "report/fleet/semaphore/vehicles",
          method: "GET",
          params,
        });

        setDivision(division);
        setDivisionVehicles(response);
        setOpenDialog(true);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
  }

  async function handleClickDepth(division, depth) {
    const params = {
      company_name: selectedFilter.companies,
      corporate_name: selectedFilter.corporate,
      division_name: division,
      depth_condition: depth,
    };

    if (division != "total") {
      const progress = showProgress();
      try {
        const response = await http({
          path: "report/fleet/semaphore/tires",
          method: "GET",
          params,
        });

        setDivision(division);
        setDepth(depth);
        setTires(response);
        setOpenTireDialog(true);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
  }

  return (
    <Page
      t={t}
      vehicles={vehicles}
      summaryData={summaryData}
      divisionData={divisionData}
      visibleStructure={visibleStructure}
      handleChange={handleChange}
      handleClickUnits={handleClickUnits}
      setOpenDialog={setOpenDialog}
      divisionVehicles={divisionVehicles}
      division={division}
      openDialog={openDialog}
      handleClickDepth={handleClickDepth}
      depth={depth}
      tires={tires}
      openTireDialog={openTireDialog}
      setOpenTireDialog={setOpenTireDialog}
    />
  );
}

VehiclesTable.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedFilter: state.Reports.Semaphore.selected_filter,
  filter: state.Reports.Semaphore.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesTable);

function getVehicles(data) {
  const result = [];

  Object.keys(data).forEach((corporate) => {
    Object.keys(data[corporate])
      .filter((key) => typeof data[corporate][key] === "object")
      .forEach((company) => {
        Object.keys(data[corporate][company])
          .filter((key) => typeof data[corporate][company][key] === "object")
          .forEach((division) => {
            Object.keys(data[corporate][company][division])
              .filter(
                (key) =>
                  typeof data[corporate][company][division][key] === "object"
              )
              .forEach((structure) => {
                let index = result.findIndex(
                  (el) => el.structure === structure
                );
                if (index < 0) {
                  result.push({ structure, max: 0, vehicles: [] });
                }

                index = result.findIndex((el) => el.structure === structure);

                Object.entries(data[corporate][company][division][structure])
                  .filter(
                    ([key]) =>
                      typeof data[corporate][company][division][structure][
                        key
                      ] === "object"
                  )
                  .forEach(([vehicle, data]) => {
                    const positions = Object.entries(data)
                      .filter(([, value]) => typeof value === "object")
                      .map(([pos, obj]) => ({
                        pos,
                        ...obj,
                        condition: obj.tire_condition_id
                          ? obj.tire_condition_id.includes("ORIGINAL")
                            ? "O"
                            : "R"
                          : "",
                      }));

                    const stats = Object.entries(data).filter(
                      ([, value]) => typeof value !== "object"
                    );

                    const foundVehicle = result[index].vehicles.findIndex(
                      (el) => el.vehicle == vehicle
                    );

                    if (foundVehicle < 0) {
                      result[index].vehicles.push({
                        vehicle,
                        odometer: data.odometer,
                        positions,
                        stats,
                      });
                    }
                  });
              });
          });
      });
  });

  result.forEach((structure, index) => {
    const max = [];
    structure.vehicles.sort().forEach((vehicle) => {
      const maxValue = Math.max(...vehicle.positions.map((o) => o.pos), 0);
      max.push(maxValue);
    });

    result[index].max = Math.max(...max);
  });
  return result;
}

function getSummaryData(data) {
  const summary = [];

  Object.keys(data).forEach((corporate) => {
    Object.keys(data[corporate])
      .filter((key) => typeof data[corporate][key] === "object")
      .forEach((company) => {
        Object.keys(data[corporate][company])
          .filter((key) => typeof data[corporate][company][key] === "object")
          .forEach((division) => {
            Object.keys(data[corporate][company][division])
              .filter(
                (key) =>
                  typeof data[corporate][company][division][key] === "object"
              )
              .forEach((structure) => {
                let index = summary.findIndex(
                  (el) => el.structure === structure
                );
                const {
                  original_tires: originals,
                  revitalized_tires: revitalized,
                  good,
                  scheduled,
                  critical,
                } = data[corporate][company][division][structure];
                if (index < 0) {
                  summary.push({
                    structure,
                    originals,
                    revitalized,
                    good,
                    scheduled,
                    critical,
                    total: good + scheduled + critical,
                  });
                } else {
                  summary[index].originals += originals;
                  summary[index].revitalized += revitalized;
                  summary[index].good += good;
                  summary[index].scheduled += scheduled;
                  summary[index].critical += critical;
                  summary[index].total += good + critical + scheduled;
                }
              });
          });
      });
  });

  return summary;
}

function getDivisionData(data) {
  const summary = [];

  Object.keys(data).forEach((corporate) => {
    Object.keys(data[corporate])
      .filter((key) => typeof data[corporate][key] === "object")
      .forEach((company) => {
        Object.keys(data[corporate][company])
          .filter((key) => typeof data[corporate][company][key] === "object")
          .forEach((division) => {
            const {
              original_tires: originals,
              revitalized_tires: revitalized,
              good,
              scheduled,
              critical,
              vehicles,
              good_percentage: goodPercent,
              scheduled_percentage: scheduledPercent,
              critical_percentage: criticalPercent,
            } = data[corporate][company][division];

            summary.push({
              division,
              originals,
              revitalized,
              good,
              scheduled,
              critical,
              total: good + scheduled + critical,
              vehicles,
              good_percentage: goodPercent,
              scheduled_percentage: scheduledPercent,
              critical_percentage: criticalPercent,
            });
          });
      });
  });

  const res = summary.reduce(
    (acc, division) => {
      acc.originals += division.originals;
      acc.revitalized += division.revitalized;
      acc.good += division.good;
      acc.scheduled += division.scheduled;
      acc.critical += division.critical;
      acc.vehicles += division.vehicles;
      acc.total += division.total;
      return acc;
    },
    {
      division: "total",
      originals: 0,
      revitalized: 0,
      good: 0,
      scheduled: 0,
      critical: 0,
      vehicles: 0,
      total: 0,
    }
  );

  res.good_percentage = res.total > 0 ? (res.good / res.total) * 100 : 0;
  res.scheduled_percentage =
    res.total > 0 ? (res.scheduled / res.total) * 100 : 0;
  res.critical_percentage =
    res.total > 0 ? (res.critical / res.total) * 100 : 0;
  summary.push(res);
  // console.log(summary);

  return summary;
}
