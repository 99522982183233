export function arrayJoin(array, separator, extra) {
  if (array.length >= 2) {
    return `${array.slice(0, -1).join(separator)}${extra}${array.slice(-1)}`;
  }
  return array.join(separator);
}

export function parseFieldsError(errors, translate) {
  const parse = {};
  for (const key of Object.keys(errors)) {
    const error = errors[key];
    const errorOnlyMessages = [];
    for (const index in error) {
      let { code, message, args } = error[index];
      if (translate(`_api.${code}`) !== `api.${code}`) {
        message = translate(`_api.${code}`, args);
      }

      errorOnlyMessages.push(message);
    }

    if (key.includes(".")) {
      const [object, position, innerKey] = key.split(".");
      if (!parse[object]) {
        parse[object] = [];
      }

      const res = {
        [innerKey]: arrayJoin(
          errorOnlyMessages,
          ", ",
          ` ${translate("_general.text.and")} `
        ),
      };
      parse[object].splice(position, 0, res);
    } else {
      parse[key] = arrayJoin(
        errorOnlyMessages,
        ", ",
        ` ${translate("_general.text.and")} `
      );
    }
  }

  return parse;
}

export function isJson(str) {
  try {
    if (str === null || str === undefined) {
      throw new Error("The value is null or undefined");
    }
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function camelToSnakeCase(str) {
  str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  if (str[0] === "_") {
    str = str.substring(1);
  }

  return str;
}
