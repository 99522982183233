import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import { useLang } from "hooks/lang";

import Page from "./page";

function TireFleetTab({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState([]);

  const { /* account, workArea, */ showProgress, setNotification, currentTab } =
    rest;

  useEffect(() => {
    async function fetchData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/dashboard/tire_fleet`,
          params: {
            level: currentTab.toLowerCase(),
          },
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    fetchData();
  }, [currentTab]);

  const total = data.find((item) => item.movement === "TOTAL");
  const pageKey = data.length > 0 ? data[0].key : "";

  const realData = data.filter((item) => item.movement !== "TOTAL");
  return (
    <Page
      t={t}
      currentTab={currentTab}
      total={total}
      pageKey={pageKey}
      data={realData}
    />
  );
}

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireFleetTab);
