import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import RadioInput from "components/atoms/general/RadioInput";
import RadioField from "components/molecules/general/RadioField";
import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import CheckboxField from "components/molecules/general/CheckboxField";

import css from "./index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

function Page({
  t,
  allFieldsFullWidth,
  values,
  fields,
  isValid,
  alerts,
  corporates,
  companies,
  tireSizes,
  tireApplicationTypes,
  subsidiaries,
  divisions,
  vehicleBrands,
  vehicleTypes,
  drivers,
  engineBrands,
  brands,
  transmissionBrands,
  isSubmitting,
  onClose,
  handleClean,
  handleSubmit,
  setFieldValue,
  initialValues,
}) {
  const NO_DIVISION = {
    name: t("_labels.no_division"),
    status: 1,
    division_id: "NO_DIVISION",
    subsidiary: {
      name: "",
    },
  };
  return (
    <Form onSubmit={handleSubmit}>
      {fields.status && (
        <>
          <Typography
            variant="overline"
            color="textSecondary"
            display="block"
            className={classnames(css.ml8, css.mr8)}
          >
            {fields.status.title}
          </Typography>
          <Field
            fullWidth
            required
            name="status"
            component={RadioField}
            classNameControl={classnames(css.field)}
          >
            <RadioInput value="1">
              {t("_general.enabled.plural.male")}
            </RadioInput>
            <RadioInput value="0">
              {t("_general.disabled.plural.male")}
            </RadioInput>
          </Field>
        </>
      )}

      {fields.with_alerts && (
        <Field
          fullWidth
          required
          name="with_alerts"
          component={RadioField}
          classNameControl={classnames(css.field)}
        >
          <RadioInput value="1">{t("_labels.with_alerts")}</RadioInput>
          <RadioInput value="0">{t("_labels.without_alerts")}</RadioInput>
        </Field>
      )}

      {fields.alert_code && (
        <Field
          fullWidth
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="alert_code"
          className={classnames(css.field)}
          component={SelectField}
          label={t("_labels.alerts")}
        >
          {alerts
            .filter((alert) => alert.alert_type == "MOUNT")
            .map((alert) => (
              <MenuItem value={alert.code} key={alert.alert_id}>
                {alert.colloquial_name}
              </MenuItem>
            ))}
        </Field>
      )}

      {fields.alert_tire_code && (
        <Field
          fullWidth
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="alert_tire_code"
          className={classnames(css.field)}
          component={SelectField}
          label={t("_labels.withdrawal_alert")}
        >
          {alerts
            .filter((alert) => ["CR-T", "PR-T"].includes(alert.code))
            .map((alert) => (
              <MenuItem value={alert.code} key={alert.alert_id}>
                {alert.colloquial_name}
              </MenuItem>
            ))}
        </Field>
      )}

      {fields.approved && (
        <>
          <Typography
            variant="overline"
            color="textSecondary"
            display="block"
            className={classnames(css.ml8, css.mr8)}
          >
            {t("_general.approval_status")}
          </Typography>
          <Field
            fullWidth
            name="approved"
            component={RadioField}
            classNameControl={classnames(css.field)}
          >
            <RadioInput value="1">
              {"tireModel" in fields || fields.revitalized_tire_model_id
                ? t("_general.approved.singular.male")
                : t("_general.approved.singular.female")}
            </RadioInput>
            <RadioInput value="0">
              {"tireModel" in fields || fields.revitalized_tire_model_id
                ? t("_general.not_approved.singular.male")
                : t("_general.not_approved.singular.female")}
            </RadioInput>
          </Field>
        </>
      )}

      {fields.tireModel && (
        <>
          <Field
            className={classnames(css.field)}
            variant="filled"
            margin="normal"
            name="tire_model_id"
            component={TextField}
            label="Id"
          />
        </>
      )}

      {fields.date && (
        <>
          <Typography
            display="block"
            variant="overline"
            color="textSecondary"
            className={classnames(css.ml8, css.mr8)}
          >
            {t("_labels.date.range")}
          </Typography>

          <Field
            fullWidth
            type="date"
            name="date_from"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.field)}
            component={DateTimeField}
            required={!!values.date_to}
            label={t("_labels.date.from")}
          />

          <Field
            fullWidth
            name="date_to"
            margin="normal"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            className={classnames(css.field)}
            component={DateTimeField}
            label={t("_labels.date.to")}
            required={!!values.date_from}
          />
        </>
      )}

      {fields.date_review && (
        <>
          <Typography
            display="block"
            variant="overline"
            color="textSecondary"
            className={classnames(css.ml8, css.mr8)}
          >
            {t("_labels.date.range")}
          </Typography>

          <Field
            fullWidth
            type="date"
            name="date_review_from"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.field)}
            component={DateTimeField}
            required={!!values.date_review_to}
            label={t("_labels.date.from")}
          />

          <Field
            fullWidth
            name="date_review_to"
            margin="normal"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            className={classnames(css.field)}
            component={DateTimeField}
            label={t("_labels.date.to")}
            required={!!values.date_review_from}
          />
        </>
      )}

      {fields.review_type && (
        <Field
          fullWidth
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="review_type"
          className={classnames(css.field)}
          component={SelectField}
          helperText={t("_labels.alert_type.helper_text")}
          label={t("_labels.review_type.label")}
        >
          <MenuItem value="COMPLETE">
            {t("_labels.review_type.options.complete")}
          </MenuItem>
          <MenuItem value="PRESSURE">
            {t("_labels.review_type.options.pressure")}
          </MenuItem>
          <MenuItem value="IDENTIFY">
            {t("_labels.review_type.options.identify")}
          </MenuItem>
          <MenuItem value="DAMAGE AND WEAR">
            {t("_labels.review_type.options.damage_and_wear")}
          </MenuItem>
          <MenuItem value="MOUNT/DISMOUNT">
            {t("_labels.review_type.options.mount_dismount")}
          </MenuItem>
          <MenuItem value="ROTATION">
            {t("_labels.review_type.options.rotation")}
          </MenuItem>
          <MenuItem value="INITIAL">
            {t("_labels.review_type.options.initial")}
          </MenuItem>
        </Field>
      )}

      {fields.structure_type && (
        <Field
          fullWidth
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="structure_type"
          className={classnames(css.field)}
          component={SelectField}
          helperText={t("_labels.can_select_many")}
          label={t("_labels.structure_type.label.singular")}
        >
          <MenuItem value="TRUCK_TRACTOR">
            {t("_general.structure_types.truck_tractor")}
          </MenuItem>
          <MenuItem value="TRUCK">
            {t("_general.structure_types.truck")}
          </MenuItem>
          <MenuItem value="BOX">{t("_general.structure_types.box")}</MenuItem>
          <MenuItem value="DOLLY">
            {t("_general.structure_types.dolly")}
          </MenuItem>
          <MenuItem value="VAN">{t("_general.structure_types.van")}</MenuItem>
        </Field>
      )}

      {fields.brand_type && (
        <Field
          fullWidth
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="brand_type"
          className={classnames(css.field)}
          component={SelectField}
          helperText={t("_labels.brand_type.helper_text")}
          label={t("_labels.brand_type.label.plural")}
        >
          <MenuItem value="VEHICLE">
            {t("_labels.brand_type.options.vehicle")}
          </MenuItem>
          <MenuItem value="TIRE">
            {t("_labels.brand_type.options.tire")}
          </MenuItem>
          <MenuItem value="ENGINE_TRANSMISSION">
            {t("_labels.brand_type.options.engine_transmission")}
          </MenuItem>
          <MenuItem value="VEHICLE_ENGINE">
            {t("_labels.brand_type.options.vehicle_engine")}
          </MenuItem>
          <MenuItem value="RETREAD">
            {t("_labels.brand_type.options.retread")}
          </MenuItem>
        </Field>
      )}

      {fields.corporates && (
        <Field
          fullWidth
          multiple
          id="corporates"
          name="corporates"
          autoHighlight
          disableClearable={initialValues.corporates.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={corporates}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.corporate_id}` === `${value.corporate_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "corporates",
              value.map((c) => `${c.corporate_id}`)
            )
          }
          value={corporates.filter((c) =>
            values.corporates.includes(`${c.corporate_id}`)
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.corporate.plural")}
              helperText={t("_labels.can_select_many")}
            />
          )}
        />
      )}

      {fields.companies && (
        <Field
          fullWidth
          multiple
          id="companies"
          name="companies"
          autoHighlight
          disableClearable={initialValues.companies.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={companies}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.company_id}` === `${value.company_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "companies",
              value.map((c) => `${c.company_id}`)
            )
          }
          value={companies.filter((c) =>
            values.companies.includes(`${c.company_id}`)
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.company.plural")}
              helperText={t("_labels.can_select_many")}
            />
          )}
        />
      )}

      {fields.subsidiaries && (
        <Field
          fullWidth
          multiple
          id="subsidiaries"
          name="subsidiaries"
          autoHighlight
          disableClearable={initialValues.subsidiaries.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={subsidiaries}
          getOptionLabel={(option) => option.name}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.subsidiary_id}` === `${value.subsidiary_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "subsidiaries",
              value.map((s) => `${s.subsidiary_id}`)
            )
          }
          value={subsidiaries.filter((s) =>
            values.subsidiaries.includes(`${s.subsidiary_id}`)
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.subsidiary.plural")}
              helperText={t("_labels.can_select_many")}
            />
          )}
        />
      )}

      {fields.divisions && (
        <Field
          fullWidth
          multiple
          id="divisions"
          name="divisions"
          autoHighlight
          disableClearable={initialValues.divisions.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={divisions.concat([NO_DIVISION])}
          getOptionLabel={(option) =>
            `${option.name} - ${option.subsidiary.name}`
          }
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.division_id}` === `${value.division_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "divisions",
              value.map((d) => `${d.division_id}`)
            )
          }
          value={divisions
            .concat([NO_DIVISION])
            .filter((s) => values.divisions.includes(`${s.division_id}`))}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.division.plural")}
              helperText={t("_labels.can_select_many")}
            />
          )}
        />
      )}

      {fields.subsidiary_id && (
        <Field
          className={classnames(css.medium_field)}
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="subsidiary_id"
          component={SelectField}
          label={t("_labels.subsidiary.singular")}
        >
          {subsidiaries.map((subsidiary, index) => {
            return (
              <MenuItem
                key={index}
                value={subsidiary.subsidiary_id.toString()}
                disabled={!subsidiary.status}
              >
                {subsidiary.name}
              </MenuItem>
            );
          })}
        </Field>
      )}

      {fields.vehicle_type_id && (
        <Field
          id="vehicle_type_id"
          name="vehicle_type_id"
          autoHighlight
          disableClearable={initialValues.vehicle_type_id !== null}
          blurOnSelect
          component={Autocomplete}
          options={vehicleTypes}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return (
              value &&
              `${option.vehicle_type_id}` === `${value.vehicle_type_id}`
            );
          }}
          onChange={(_, value) =>
            setFieldValue("vehicle_type_id", `${value.vehicle_type_id}`)
          }
          value={vehicleTypes.find(
            (v) => `${v.vehicle_type_id}` === `${values.vehicle_type_id}`
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", {
            [css.medium_field]: !allFieldsFullWidth,
            [css.field]: allFieldsFullWidth,
          })}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              label={t("_labels.vehicle_type.label")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.vehicle_brand_id && (
        <Field
          id="vehicle_brand_id"
          name="vehicle_brand_id"
          autoHighlight
          disableClearable={initialValues.vehicle_brand_id !== null}
          blurOnSelect
          component={Autocomplete}
          options={vehicleBrands}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return value && `${option.brand_id}` === `${value.brand_id}`;
          }}
          onChange={(_, value) =>
            setFieldValue("vehicle_brand_id", `${value.brand_id}`)
          }
          value={vehicleBrands.find(
            (b) => `${b.brand_id}` === `${values.vehicle_brand_id}`
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", {
            [css.medium_field]: !allFieldsFullWidth,
            [css.field]: allFieldsFullWidth,
          })}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              label={t("_labels.vehicle_brand.label")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.type_of_route && (
        <Field
          className={classnames(css.medium_field)}
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="type_of_route"
          component={SelectField}
          label={t("_labels.type_of_route.label")}
        >
          <MenuItem value={"FOREIGN"}>
            {t("_labels.type_of_route.options.foreign")}
          </MenuItem>

          <MenuItem value={"LOCAL"}>
            {t("_labels.type_of_route.options.local")}
          </MenuItem>
        </Field>
      )}

      {fields.has_odometer && (
        <>
          <Typography
            variant="overline"
            color="textSecondary"
            display="block"
            className={classnames(css.ml8, css.mr8)}
          >
            {fields.has_odometer.title}
          </Typography>
          <Field
            fullWidth
            required
            name="has_odometer"
            component={RadioField}
            classNameControl={classnames(css.field)}
          >
            <RadioInput value="1">{t("_labels.with_odometer")}</RadioInput>
            <RadioInput value="0">{t("_labels.without_odometer")}</RadioInput>
          </Field>
        </>
      )}

      {fields.with_link_driver && (
        <Field
          fullWidth
          name="with_link_driver"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          className={classnames(css.field)}
          component={CheckboxField}
          label={t("_labels.with_link_driver")}
        />
      )}

      {fields.driver_id && (
        <Field
          id="driver_id"
          name="driver_id"
          autoHighlight
          disableClearable={initialValues.driver_id !== null}
          blurOnSelect
          component={Autocomplete}
          options={drivers}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return value && `${option.driver_id}` === `${value.driver_id}`;
          }}
          onChange={(_, value) =>
            setFieldValue("driver_id", `${value.driver_id}`)
          }
          value={drivers.find(
            (b) => `${b.driver_id}` === `${values.driver_id}`
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", {
            [css.medium_field]: !allFieldsFullWidth,
            [css.field]: allFieldsFullWidth,
          })}
          renderInput={(params) => (
            <TextField2
              {...params}
              label={t("_labels.driver.singular")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.enrollment && (
        <Field
          name="enrollment"
          variant="filled"
          className={classnames("MuiFormControl-root", css.medium_field)}
          component={TextField}
          label={t("_labels.auto_enrollment")}
        />
      )}

      {fields.vehicle_engine_brand_id && (
        <Field
          id="vehicle_engine_brand_id"
          name="vehicle_engine_brand_id"
          autoHighlight
          disableClearable={initialValues.vehicle_engine_brand_id !== null}
          blurOnSelect
          component={Autocomplete}
          options={engineBrands}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return value && `${option.brand_id}` === `${value.brand_id}`;
          }}
          onChange={(_, value) =>
            setFieldValue("vehicle_engine_brand_id", `${value.brand_id}`)
          }
          value={engineBrands.find(
            (b) => `${b.brand_id}` === `${values.vehicle_engine_brand_id}`
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.medium_field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              label={t("_labels.engine_brand.label")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.engine_transmission_brand_id && (
        <Field
          id="engine_transmission_brand_id"
          name="engine_transmission_brand_id"
          autoHighlight
          disableClearable={initialValues.engine_transmission_brand_id !== null}
          blurOnSelect
          component={Autocomplete}
          options={transmissionBrands}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return value && `${option.brand_id}` === `${value.brand_id}`;
          }}
          onChange={(_, value) =>
            setFieldValue("engine_transmission_brand_id", `${value.brand_id}`)
          }
          value={transmissionBrands.find(
            (b) => `${b.brand_id}` === `${values.engine_transmission_brand_id}`
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.medium_field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              label={t("_labels.transmission_brand.label")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.cylinder_capacity && (
        <Field
          name="cylinder_capacity"
          variant="filled"
          className={classnames(css.medium_field)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                className={classnames(css.adornments)}
                position="end"
              >
                cm<sup>3</sup>
              </InputAdornment>
            ),
          }}
          component={TextField}
          label={t("_labels.cylinder")}
        />
      )}

      {fields.transmission_model && (
        <Field
          name="transmission_model"
          variant="filled"
          className={classnames(css.medium_field)}
          component={TextField}
          label={t("_labels.transmission_model")}
        />
      )}

      {fields.transmission_speeds && (
        <Field
          name="transmission_speeds"
          variant="filled"
          className={classnames(css.medium_field)}
          component={TextField}
          label={t("_labels.transmission_speeds")}
        />
      )}

      {fields.vehicle_model && (
        <Field
          name="vehicle_model"
          variant="filled"
          className={classnames(css.medium_field)}
          component={TextField}
          label={t("_labels.vehicle_model")}
        />
      )}

      {fields.vehicle_year && (
        <Field
          name="vehicle_year"
          variant="filled"
          className={classnames(css.medium_field)}
          component={TextField}
          label={t("_labels.vehicle_year")}
        />
      )}

      {fields.brands && (
        <Field
          id="brands"
          name="brands"
          autoHighlight
          disableClearable={initialValues.brands !== null}
          blurOnSelect
          component={Autocomplete}
          options={brands}
          getOptionLabel={(option) => (option ? option.name : "")}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => {
            return value && `${option.brand_id}` === `${value.brand_id}`;
          }}
          onChange={(_, value) => setFieldValue("brands", `${value.brand_id}`)}
          value={brands.find((b) => `${b.brand_id}` === `${values.brands}`)}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              label={t("_labels.brand_field.label")}
              variant="filled"
            />
          )}
        />
      )}

      {fields.tire_size && (
        <Field
          fullWidth
          multiple
          id="tire_size"
          name="tire_size"
          autoHighlight
          disableClearable={initialValues.tire_size.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={tireSizes}
          getOptionLabel={(option) => option.size}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.tire_size_id}` === `${value.tire_size_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "tire_size",
              value.map((t) => `${t.tire_size_id}`)
            )
          }
          value={tireSizes.filter((t) =>
            values.tire_size.includes(`${t.tire_size_id}`)
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.tire_size.label.plural")}
              helperText={t("_labels.can_select_many_feminine")}
            />
          )}
        />
      )}

      {fields.tire_application && (
        <Field
          fullWidth
          multiple
          id="tire_application"
          name="tire_application"
          autoHighlight
          disableClearable={initialValues.tire_application.length > 0}
          blurOnSelect
          component={Autocomplete}
          options={tireApplicationTypes}
          getOptionLabel={(option) =>
            t(
              `_labels.tire_application.options.${option.tire_application_id.toLowerCase()}`
            )
          }
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) =>
            `${option.tire_application_id}` === `${value.tire_application_id}`
          }
          onChange={(_, value) =>
            setFieldValue(
              "tire_application",
              value.map((s) => `${s.tire_application_id}`)
            )
          }
          value={tireApplicationTypes.filter((c) =>
            values.tire_application.includes(`${c.tire_application_id}`)
          )}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.field)}
          renderInput={(params) => (
            <TextField2
              {...params}
              margin="normal"
              variant="filled"
              label={t("_labels.tire_application.label.plural")}
              helperText={t("_labels.can_select_many_feminine")}
            />
          )}
        />
      )}

      {fields.vehicle_type_axle_count && (
        <Field
          name="vehicle_type_axle_count"
          variant="filled"
          type="number"
          margin="normal"
          className={classnames(css.field)}
          component={TextField}
          label={t("_labels.vehicle_type_axle_count")}
        />
      )}

      {fields.depth && (
        <Field
          name="depth"
          variant="filled"
          type="number"
          margin="normal"
          className={classnames(css.field)}
          component={TextField}
          label={t("_labels.tire_model_variation.depth")}
        />
      )}

      {fields.maximum_pressure && (
        <Field
          name="maximum_pressure"
          variant="filled"
          type="number"
          margin="normal"
          className={classnames(css.field)}
          component={TextField}
          label={t("_labels.tire_model_variation.maximum_pressure")}
        />
      )}

      {fields.number_layers && (
        <Field
          name="number_layers"
          variant="filled"
          type="number"
          margin="normal"
          className={classnames(css.field)}
          component={TextField}
          label={t("_labels.tire_model_variation.number_layers")}
        />
      )}

      {fields.revitalized_tire_model_id && (
        <Field
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          margin="normal"
          name="revitalized_tire_model_id"
          className={classnames(css.field)}
          component={TextField}
          label={"id"}
        />
      )}

      {fields.axle_number && (
        <Field
          name="axle_number"
          variant="filled"
          type="number"
          margin="normal"
          className={classnames(css.field)}
          component={TextField}
          label={t("_labels.axle_number")}
        />
      )}

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting && !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  engineBrands: PropTypes.array.isRequired,
  transmissionBrands: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,

  allFieldsFullWidth: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default Page;
