import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";
import PasswordField from "components/molecules/general/PasswordField";
import CheckboxField from "components/molecules/general/CheckboxField";
import FieldsList from "components/organisms/general/FieldsList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import css from "./index.module.scss";

function Page({
  t,
  fields,
  values,
  initialValues,
  isValid,
  isUpdate,
  isSubmitting,
  onClose,
  handleSubmit,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <div className={classnames(css.container_box)}>
        {fields.map((field) => {
          let menuProps = null;
          let defaultValue = null;
          let defaultText = null;
          let onChange = null;
          let inputProps = null;
          if (field.component === "select") {
            menuProps = !!field.selectItems
              ? {
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  },
                }
              : null;
            defaultValue = {
              defaultValue: !!field.selectItems
                ? field.selectItems.some(
                    (item) => item.value === values[field.name]
                  )
                  ? null
                  : values[field.name]
                : null,
            };
            defaultText = {
              defaultText: !!field.defaultText ? field.defaultText : null,
            };

            if (field.multiple) {
              defaultValue = null;
            }

            if (typeof field.onChange === "function") {
              onChange = { onChange: field.onChange };
            }
          }

          if (field.inputAdornment) {
            inputProps = {
              InputProps: {
                endAdornment: (
                  <InputAdornment
                    className={classnames(css.adornments)}
                    position="end"
                  >
                    {field.inputAdornment}
                  </InputAdornment>
                ),
              },
            };
          }

          if (field.inputProps) {
            if (inputProps && inputProps.InputProps) {
              inputProps = {
                InputProps: {
                  ...inputProps.InputProps,
                  ...field.inputProps,
                },
              };
            } else {
              inputProps = {
                InputProps: {
                  ...field.inputProps,
                },
              };
            }
          }

          if (field.fields) {
            if (field.onlyAdd && isUpdate) {
              return;
            }
            return (
              <FieldsList
                key={field.name}
                field={field}
                values={values}
                {...menuProps}
                {...inputProps}
                {...defaultValue}
                {...defaultText}
                {...onChange}
              />
            );
          }

          let helperText = null;

          if (field.helperText) {
            helperText = { helperText: field.helperText };
          }

          if (field.disabledInput && !values[field.disabledInput]) {
            return;
          }

          if (field.name === "odometer" && isUpdate) {
            return;
          }

          if (field.component == "autocomplete") {
            return (
              <Field
                key={field.name}
                id={field.name}
                name={field.name}
                autoHighlight
                disableClearable={initialValues[field.name] !== null}
                blurOnSelect
                component={Autocomplete}
                options={field.options}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
                onChange={(_, value) => setFieldValue(field.name, value)}
                value={values[field.name] == "" ? null : values[field.name]}
                noOptionsText={t("_labels.autocomplete.no_options")}
                openText={t("_labels.autocomplete.open")}
                clearText={t("_labels.autocomplete.clear")}
                closeText={t("_labels.autocomplete.close")}
                className={classnames("MuiFormControl-root", css.medium_field)}
                renderInput={(params) => (
                  <TextField2
                    {...params}
                    required={field.required}
                    label={field.label}
                    variant="filled"
                  />
                )}
              />
            );
          }
          return (
            <React.Fragment key={field.name}>
              <Field
                {...menuProps}
                {...inputProps}
                {...defaultValue}
                {...defaultText}
                {...onChange}
                {...helperText}
                autoComplete={field.autocomplete}
                margin="normal"
                variant="filled"
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label}
                accept={field.accept}
                multiple={field.multiple}
                rows={field.rows}
                multiline={field.multiline}
                disabled={
                  field.disabled === "isUpdate" ? isUpdate : field.disabled
                }
                placeholder={field.placeholder}
                required={
                  typeof field.required === "function"
                    ? field.required(values[field.requiredInput])
                    : field.required === "isUpdate"
                    ? !isUpdate
                    : field.required
                }
                className={classnames({
                  [css.full_width]: field.fullWidth,
                  [css.small_field]: field.smallWidth,
                  [css.medium_field]: field.mediumWidth,
                  [css.field]:
                    !field.fullWidth && !field.smallWidth && !field.mediumWidth,
                })}
                component={
                  field.component === "select"
                    ? SelectField
                    : field.component === "file"
                    ? FileDragAndDropField
                    : field.component === "password"
                    ? PasswordField
                    : field.component === "checkbox"
                    ? CheckboxField
                    : TextField
                }
              >
                {field.selectItems &&
                  field.selectItems.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.value}
                        disabled={item.disabled}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Field>
            </React.Fragment>
          );
        })}
      </div>

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
