import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterListIcon from "@material-ui/icons/FilterList";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import Button from "components/molecules/general/Button";
import Typography from "@material-ui/core/Typography";

import FilterAlertsOfVehicleForm from "components/organisms/vehicle/alert/FilterAlertsOfVehicleForm";
import UpdateCommentForm from "components/organisms/vehicle/alert/UpdateCommentForm";

import css from "./index.module.scss";

function Page({
  t,
  alerts,
  alertId,
  vehicleId,
  deleteVehicleAlertPermit,
  openFilter,
  onChangePage,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  openForm,
  handleOpenForm,
  handleCloseForm,
  vehicle,
}) {
  return (
    <ContainerTemplate
      title={t("_general.alerts")}
      className={classnames({
        [css.empty]: alerts.data.length === 0,
      })}
      options={
        <>
          <Button
            className={classnames("bw", css.filter)}
            variant="icon"
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      {!!vehicle.vehicle_id && (
        <Typography noWrap variant="h6" component="h6" className={css.title}>
          {t("_general.vehicle")} {vehicle.economic_number}
        </Typography>
      )}
      {alerts.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={alerts.data.map((alert) => ({
                id: alert.alert_id,
                alert_code: alert.alert.colloquial_name
                  ? t(`_alerts.colloquial_name.${alert.alert.colloquial_name}`)
                  : "",
                priority: t(
                  `_labels.priority.options.${alert.alert.priority.toLowerCase()}`
                ),
                ranking_alert: t(
                  `_labels.ranking_alert.options.${alert.alert.ranking_alert.toLowerCase()}`
                ),
                suggestion: alert.alert.suggestion
                  ? t(`_alerts.suggestion.${alert.alert.suggestion}`)
                  : "",
                created_at:
                  moment(alert.alert.created_at).format("LL") +
                  " " +
                  moment(alert.alert.created_at).format("h:mm a"),
                redirect: {
                  label: <ChatBubbleOutlineIcon />,
                  size: "small",
                  handleClick: () => handleOpenForm(alert.alert_id),
                },
                delete: {
                  tooltipPermit: !deleteVehicleAlertPermit
                    ? t("_permits.delete.alert")
                    : "",
                },
                color:
                  alert.alert.priority === "HIGH"
                    ? "red"
                    : alert.alert.priority === "HALF"
                    ? "yellow"
                    : null,
              }))}
              headers={[
                { id: "alert_code", label: t("_labels.alert.label") },
                { id: "priority", label: t("_labels.priority.label") },
                {
                  id: "ranking_alert",
                  label: t("_labels.ranking_alert.label"),
                },
                { id: "suggestion", label: t("_labels.alert.suggestion") },
                { id: "created_at", label: t("_labels.created_at") },
                { id: "actions", label: "" },
              ]}
              onDelete={handleDelete}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={alerts.current_page}
              totalPages={alerts.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_alerts")} />
      )}
      <UpdateCommentForm
        open={openForm}
        onClose={handleCloseForm}
        alertId={alertId}
        vehicleId={vehicleId}
      />
      {
        <FilterAlertsOfVehicleForm
          open={openFilter}
          onClose={handleCloseFilter}
        />
      }
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  alerts: PropTypes.object.isRequired,
  vehicleId: PropTypes.string.isRequired,
  alertId: PropTypes.number,
  deleteVehicleAlertPermit: PropTypes.bool.isRequired,
  deleteVehicleAlert: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  openForm: PropTypes.bool,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired,
};

export default Page;
