import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";

import TableToolbar from "components/molecules/general/TableToolbar";

import Button from "components/molecules/general/Button";
import css from "./index.module.scss";

function Page({
  selectable,
  selected,
  headers,
  rows,
  propertys,
  nestedPropertys,
  nestedHeaders,
  multipleProperty,
  selectActionButton,
  handleSelect,
  handleAllSelect,
  isSelected,
  selectAction,
  totalRows,
  objectSelection,
}) {
  return (
    <div className={classnames(css.w100)}>
      <Paper className={classnames(css.w100)}>
        {selectable && selected.length > 0 && (
          <TableToolbar
            selected={selected}
            onDelete={selectAction}
            selectActionButton={selectActionButton}
          />
        )}
        <TableContainer>
          <Table
            className={classnames(css.table, css.table_bordered)}
            size="medium"
          >
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell className={classnames(css.head)}></TableCell>
                )}
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    className={classnames(css.head)}
                    colSpan={header.colspan ?? 1}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {selectable && (
                  <TableCell padding="none" className={classnames(css.head)}>
                    <Checkbox
                      className={classnames(css.text_white)}
                      indeterminate={
                        selected.length > 0 && selected.length < totalRows
                      }
                      checked={
                        selected.length === totalRows ||
                        rows.every((row) =>
                          objectSelection
                            ? selected.find((el) => el.id === row.id)
                            : selected.includes(row.id)
                        )
                      }
                      onChange={handleAllSelect}
                    />
                  </TableCell>
                )}
                {nestedHeaders.map((header, index) => (
                  <TableCell
                    key={[header, index].join("_")}
                    className={classnames(css.head)}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow>
                    {selectable && (
                      <TableCell
                        padding="none"
                        className={classnames(css.column)}
                        rowSpan={row[multipleProperty].length + 1}
                      >
                        <Checkbox
                          checked={isSelected(row.id)}
                          onClick={() =>
                            handleSelect(objectSelection ? row : row.id)
                          }
                        />
                      </TableCell>
                    )}
                    {propertys
                      .filter((prop) => prop !== multipleProperty)
                      .map((prop, index) => (
                        <TableCell
                          key={[row.id, index].join("_")}
                          className={classnames(css.column)}
                          rowSpan={row[multipleProperty].length + 1}
                        >
                          {row[prop]}
                        </TableCell>
                      ))}
                  </TableRow>

                  {row[multipleProperty].map((innerRow, index) => (
                    <TableRow key={index}>
                      {nestedPropertys.map((prop, index) => (
                        <TableCell
                          key={`${prop}_${index}`}
                          className={classnames(css.column)}
                        >
                          {innerRow[prop]}
                        </TableCell>
                      ))}
                      {nestedHeaders.includes("") && (
                        <TableCell className={classnames(css.column)}>
                          {innerRow.edit && (
                            <Button
                              variant="icon"
                              style={{ color: "#ffa100" }}
                              className={classnames({
                                [css.disabled]: !!innerRow.edit.tooltipPermit,
                              })}
                              onClick={innerRow.edit.onEdit}
                              disabled={!!innerRow.edit.tooltipPermit}
                              tooltip={innerRow.edit.tooltipPermit}
                            >
                              <EditIcon />
                            </Button>
                          )}

                          {innerRow.delete && (
                            <Button
                              variant="icon"
                              style={{ color: "#ea3c3c" }}
                              className={classnames({
                                [css.disabled]: !!innerRow.delete.tooltipPermit,
                              })}
                              onClick={innerRow.delete.onDelete}
                              disabled={!!innerRow.delete.tooltipPermit}
                              tooltip={innerRow.delete.tooltipPermit}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

Page.propTypes = {
  selectable: PropTypes.bool,
  selected: PropTypes.array,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  propertys: PropTypes.array.isRequired,
  nestedPropertys: PropTypes.array.isRequired,
  nestedHeaders: PropTypes.array.isRequired,
  multipleProperty: PropTypes.string.isRequired,
  selectActionButton: PropTypes.node,
  handleAllSelect: PropTypes.func,
  handleSelect: PropTypes.func,
  isSelected: PropTypes.func,
  selectAction: PropTypes.func,
  totalRows: PropTypes.number,
  objectSelection: PropTypes.bool,
};

export default Page;
