export default {
  new_wear: "Nuevo desgaste",
  update_wear: "Actualizar desgaste",
  form: {
    permits: {
      update: "Sin permisos para actualizar desgastes",
      delete: "Sin permisos para eliminar desgastes",
    },
  },
  messages: {
    wear_created: "Listo, se creo un nuevo desgaste con éxito",
    wear_updated: "Listo, se actualizo el desgaste con éxito",
    wear_deleted: "Listo, se eliminó el desgaste con éxito",
    delete: "¿Estas seguro de eliminar el desgaste?",
  },
};
