export default {
  not_any_warehouse: "No se encuentra en ningún almacén",
  buttons: {
    take_out: "Sacar",
    view_warehouse: "Ver almacén",
  },
  permits: {
    show: {
      tire_warehouse: "Sin permisos para ver almacenes enlazados a neumáticos",
    },
  },
};
