import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { fieldToTextField } from "formik-material-ui";

import Page from "./page";

function ColorInputField({ className, form, field, ...props }) {
  const label = useRef();
  const rest = fieldToTextField({ form, field, ...props });

  useEffect(() => {
    label.current.style.color = field.value;
  }, [field.value]);

  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <Page
      labelRef={label}
      name={field.name}
      value={field.value}
      className={className}
      handleChange={handleChange}
      {...rest}
    ></Page>
  );
}

ColorInputField.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default ColorInputField;
