export default {
  last_travel: "Last traveled",
  no_travel: "There is no record of any travel",
  permits: {
    delete: "No permissions to delete vehicle travels",
    show: {
      last_vehicle_travel: "No permission to view last vehicle traveled",
    },
  },
};
