import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

function Page({ t, open, onClose, tires }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {tires.length
              ? `: ${tires.length}`
              : `: ${Object.keys(tires).length}`}
          </span>
          {!Array.isArray(tires) && (
            <span className="float-right">{`${t(
              "_general.alerts"
            )}: ${Object.values(tires).reduce(
              (acc, val) => acc + val.length,
              0
            )}`}</span>
          )}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <TableContainer>
            <Table className="m-0 p-0">
              <TableHead
                style={{ backgroundColor: "lightgray", color: "black" }}
              >
                <TableRow>
                  <TableCell className="sFS16">{t("_labels.code")}</TableCell>
                  <TableCell className="sFS16">Rfid</TableCell>
                  <TableCell className="sFS16">
                    {t("_labels.vehicle_type_axle_position.label")}
                  </TableCell>
                  <TableCell className="sFS16">{t("_labels.detail")}</TableCell>
                  <TableCell className="sFS16">
                    {t("_labels.pressure")} (psi)
                  </TableCell>
                  <TableCell className="sFS16">
                    {t("_labels.travel.singular")} (km)
                  </TableCell>
                  <TableCell className="sFS16">
                    {t("_labels.mileage")} (km)
                  </TableCell>
                  <TableCell className="sFS16">
                    {t("_labels.depth")} (mm)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tires.map((tireReview) => (
                  <TableRow key={tireReview.tire_review_id}>
                    <TableCell className="text-muted sFS16">
                      <Link
                        href={`/tire/${tireReview.tire_id}/history`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tireReview.code || "-"}
                      </Link>
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      <Link
                        href={`/tire/${tireReview.tire_id}/history`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tireReview.device_code || "-"}
                      </Link>
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {tireReview?.real_position ?? ""}
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {tireReview.comment || ""}
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {formatter.format(tireReview.pressure)}
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {kmFormatter.format(tireReview.last_travel)}
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {kmFormatter.format(tireReview.accumulated_mileage)}
                    </TableCell>
                    <TableCell className="text-muted sFS16">
                      {formatter.format(tireReview.depth)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
