export default {
  axle_information: "Axle information",
  axle_position: "Axle position",
  tire_position: "Tire position",
  axle_applications: "Application of tires allowed on the axle",
  actions: "Actions",
  permits: {
    show_link: {
      tire_with_vehicle: "No permissions to view vehicle tire links",
    },
    delete_link: {
      tire_with_vehicle: "No permissions to remove tires from vehicles",
    },
  },
  buttons: {
    wear: "Wear",
    mount: "Mount",
    dismount: "Dismount",
    repair: "Repair",
    revitalize: "Revitalize",
    discard: "Discard",
    tire_mismatch: "Tire mismatch?",
  },
  messages: {
    delete_link_confirmation: "Are you sure to remove the tire?",
  },
};
