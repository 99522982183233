import React from "react";
import PropTypes from "prop-types";
import { fieldToTextField } from "formik-material-ui";

import Page from "./page";

function RadioField({ children, form, field, ...props }) {
  const rest = fieldToTextField({ form, field, ...props });
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
  };

  return (
    <Page value={field.value} handleChange={handleChange} {...rest}>
      {children}
    </Page>
  );
}

RadioField.propTypes = {
  required: PropTypes.bool,
  children: PropTypes.node,
  form: PropTypes.object,
  field: PropTypes.object,
  classNameControl: PropTypes.string,
};

export default RadioField;
