import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function VehicleAlertTab() {
  return <Page />;
}

VehicleAlertTab.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleAlertTab);
