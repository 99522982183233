export default {
  subsidiary: "Subsidiary",
  link_subsidiary: "Link subsidiary",
  subsidiary_name: "Subsidiary name",
  unassigned_subsidiary: "Unassigned subsidiary",
  permits: {
    delete_link: {
      vehicle_with_subsidiary:
        "No permissions to unlink subsidiaries to vehicles",
    },
    show: {
      vehicle_with_subsidiary:
        "No permissions to view subsidiaries linked to vehicles",
    },
    list: {
      subsidiary: "No permissions to list subsidiaries",
    },
  },
  messages: {
    unlink_subsidiary_confirmation: "Are you sure to unlink the subsidiary?",
    vehicle_with_subsidiary_link_deleted:
      "Done, the subsidiary was successfully unlinked from the vehicle",
  },
  form: {
    select_a_subsidiary: "Select a subsidiary",
  },
};
