import { http } from "store/actions/http";
import { GET_PURCHASE_REPORT } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetPurchaseReport =
  (dispatch) =>
  async ({
    movement = "BOTH",
    depth_condition: depthCondition,
    projection_day: projectionDay,
    subsidiaries,
    companies,
    corporate_id: corporateId,
    warehouses,
    vehicles,
    date_from: dateFrom,
    date_to: dateTo,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/purchase`,
        params: {
          movement,
          subsidiaries,
          companies,
          corporate_id: corporateId,
          warehouses,
          vehicles,
          date_from: dateFrom,
          date_to: dateTo,
          depth_condition: depthCondition,
          projection_day: projectionDay,
        },
      });

      dispatch({
        type: GET_PURCHASE_REPORT,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
