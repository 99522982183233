import React from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPotentialSavingsReport } from "store/actions/reports/potential_savings/getPotentialSavingsReport";

import Page from "./page";
import PropTypes from "prop-types";

function Pressures({ data }) {
  const t = useLang();

  return (
    <Page
      t={t}
      pressureTable={getPressureTable(data)}
      lostsData={getLostsData(data)}
    />
  );
}
Pressures.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.PotentialSavings.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReport: actionGetPotentialSavingsReport(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pressures);

function getPressureTable(data) {
  if (data && data.pressure_table && data.pressure_table.pressure_table) {
    const result = structuredClone(data.pressure_table.pressure_table);

    Object.keys(result).forEach((size) => {
      Object.keys(result[size]).forEach((reccomendedPressure) => {
        Object.keys(result[size][reccomendedPressure]).forEach((tolerance) => {
          const array = Object.entries(
            result[size][reccomendedPressure][tolerance]
          ).sort(sortBy);
          result[size][reccomendedPressure][tolerance] = array;
        });
      });
    });

    return result;
  }
  return {};
}

function sortBy(a, b) {
  if (isNaN(a[0])) {
    return 1;
  }

  if (isNaN(b[0])) {
    return -1;
  }

  if (Number(a[0]) < Number(b[0])) {
    return 1;
  }

  if (Number(a[0]) > Number(b[0])) {
    return -1;
  }

  return 0;
}

function getLostsData(data) {
  if (data && data.pressure_table && data.pressure_table.pressure_wear_list) {
    return data.pressure_table.pressure_wear_list;
  }
  return {};
}
