import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import css from "./index.module.scss";

function Page({ t, isValid, rfids, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        required
        fullWidth
        variant="filled"
        name="rfid_id"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        className={classnames(css.mb8)}
        label={t("_general.rfid")}
      >
        {rfids.map((rfid, index) => {
          return (
            <MenuItem
              key={index}
              disabled={!rfid.status}
              value={rfid.rfid_id.toString()}
            >
              {rfid.device_code}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button
            type="button"
            color="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.link")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  rfids: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
