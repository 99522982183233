import {
  REGISTER_TIRE_SIZE,
  GET_TIRE_SIZES,
  CLEAR_TIRE_SIZES,
  SET_TIRE_SIZE_FILTERS,
  CLEAR_TIRE_SIZE_FILTERS,
  GET_TIRE_SIZE,
  CLEAR_TIRE_SIZE,
  SET_TIRE_SIZE_APPROVAL_STATUS,
  SET_TIRE_SIZE_STATUS,
  DELETE_TIRE_SIZE,
} from "store/actions";

const initialState = {
  size: {
    size: "",
    status: 0,
    approved: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  tire_sizes: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_tire_size: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_TIRE_SIZE:
    case GET_TIRE_SIZE:
      return {
        ...state,
        size: action.payload,
      };

    case GET_TIRE_SIZES:
      return {
        ...state,
        tire_sizes: action.payload,
      };

    case CLEAR_TIRE_SIZES:
      return {
        ...state,
        tire_sizes: { ...initialState.tire_sizes },
      };

    case SET_TIRE_SIZE_FILTERS:
      const filter = {
        ...state.filter_tire_size,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_tire_size: { ...filter },
      };

    case CLEAR_TIRE_SIZE_FILTERS:
      return {
        ...state,
        filter_tire_size: { search: state.filter_tire_size.search },
      };

    case CLEAR_TIRE_SIZE:
      return {
        ...state,
        size: { ...initialState.size },
      };

    case SET_TIRE_SIZE_STATUS:
      return {
        ...state,
        tire_sizes: {
          ...state.tire_sizes,
          data: state.tire_sizes.data.map((size) =>
            size.tire_size_id !== action.payload
              ? size
              : { ...size, status: !size.status }
          ),
        },
      };

    case SET_TIRE_SIZE_APPROVAL_STATUS:
      return {
        ...state,
        tire_sizes: {
          ...state.tire_sizes,
          data: state.tire_sizes.data.map((size) =>
            size.tire_size_id !== action.payload
              ? size
              : { ...size, approved: !size.approved }
          ),
        },
      };

    case DELETE_TIRE_SIZE:
      return {
        ...state,
        tire_sizes: {
          ...state.tire_sizes,
          data: state.tire_sizes.data.filter(
            (tire) => tire.tire_size_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
