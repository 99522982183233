export default {
  damage_name: "Nombre del daño",
  messages: {
    created: "Listo, se registró un nuevo daño al neumático con éxito",
    updated: "Listo, se actualizó un nuevo daño al neumático con éxito",
    delete_confirmation: "¿Estas seguro de eliminar el daño del neumático?",
    deleted: "Listo, se eliminó el daño al neumático con éxito",
  },
  permits: {
    delete: "Sin permisos para eliminar daños de neumáticos",
  },
};
