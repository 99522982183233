export default {
  confirm: "Confirmar",
  delete: {
    corporate: "¿Estas seguro de eliminar el corporativo?",
    brand: "¿Estas seguro de eliminar la marca?",
    size: "¿Estas seguro de eliminar la medida?",
    company: "¿Estas seguro de eliminar la empresa?",
    division: "¿Estas seguro de eliminar la división",
    subsidiary: "¿Estas seguro de eliminar la sucursal?",
    association: "¿Estas seguro de eliminar el transportista?",
    provider: "¿Estas seguro de eliminar el proveedor?",
    wear: "¿Estas seguro de eliminar el desgaste?",
    driver: "¿Estas seguro de eliminar al conductor?",
    warehouse: "¿Estas seguro de eliminar el almacén?",
    retirement_cause: "¿Estas seguro de eliminar la causa de retiro?",
    gps: "¿Estas seguro de eliminar el gps?",
    damage: "¿Estas seguro de eliminar el tipo de daño?",
    alert: "¿Estas seguro de eliminar la alerta?",
    rfid: "¿Estas seguro de eliminar el rfid?",
    vehicle_type: "¿Estas seguro de eliminar el tipo de vehículo?",
    pressure_policy: "¿Estas seguro de eliminar la política de presión?",
    depth_policy: "¿Estas seguro de eliminar la política de profundidad?",
    link_tire_with_warehouse:
      "¿Estas seguro de sacar el neumático del almacén?",
    link_user_with_corporate:
      "¿Estas seguro de desvincular el usuario del corporativo?",
    link_user_with_company:
      "¿Estas seguro de desvincular el usuario de la empresa?",
    link_user_with_subsidiary:
      "¿Estas seguro de desvincular el usuario de la sucursal?",
    link_subsidiary: "¿Estas seguro de desvincular la sucursal?",
    link_vehicle: "¿Estas seguro de desvincular el vehículo?",
    link_driver: "¿Estas seguro de desvincular el conductor?",
    link_warehouse: "¿Estas seguro de desvincular el almacén?",
    link_tire: "¿Estas seguro de desvincular el neumático?",
    link_vehicle: "¿Estas seguro de desvincular el vehículo?",
    tire_wear: "¿Estas seguro de eliminar el desgaste del neumático?",
    user: "¿Estas seguro de eliminar el usuario?",
    user_role: "¿Estas seguro de eliminar el rol de usuario?",
    tire_model: "¿Estas seguro de eliminar el modelo de neumático?",
    revitalized_tire_model:
      "¿Estas seguro de eliminar el modelo de neumático revitalizado?",
    vehicle: "¿Estas seguro de eliminar el vehículo?",
    condition_policy: "¿Estas seguro de eliminar la política de condición?",
    tire: "¿Estas seguro de eliminar el neumático?",
    model_pressure_policy:
      "¿Estas seguro de eliminar la política de presión por modelo?",
    helmet_value: "¿Estas seguro de eliminar el valor del casco?",
    vehicle_review: "¿Estas seguro de eliminar la revisión de vehículo?",
    concept: "¿Estas seguro de eliminar el concepto?",
    block: "¿Estas seguro de eliminar el bloque?",
    checklist: "¿Estas seguro de eliminar el checklist?",
    tpms: "¿Estas seguro de eliminar el sensor tpms?",
  },
  deleted: {
    link_tire_with_warehouse:
      "Listo, el neumático fue sacado del almacén con éxito",
    link_user_with_corporate: "Listo, se desvinculó el usuario del corporativo",
    link_user_with_company: "Listo, se desvinculó el usuario de la empresa",
    link_user_with_subsidiary: "Listo, se desvinculó el usuario de la sucursal",
    link_vehicle: "Listo, se desvinculó el vehículo",
    link_driver: "Listo, el conductor fue desvinculado con éxito",
    link_warehouse: "Listo, el almacén fue desvinculado con éxito",
    link_tire: "Listo, el neumático fue desvinculado con éxito",
    tire_wear: "Listo, se eliminó el desgaste al neumático con éxito",
    link_subsidiary: "Listo, la sucursal fue desvinculada con éxito",
    condition_policy: "Listo, la política de condición fue eliminada",
    pressure_policy: "Listo, se eliminó la la política de presión con éxito",
    depth_policy: "Listo, se eliminó la la política de profundidad con éxito",
    model_pressure_policy:
      "Listo, se eliminó la la política de presión por modelo con éxito",
    helmet_value: "Listo, se eliminó el valor del casco con éxito",
    vehicle_review: "Listo, la revisión de vehículo fue eliminada con éxito",
    tpms: "Listo, se eliminó el sensor tpms con éxito",
  },
  update: {
    same_vehicle: "El vehículo es el mismo que el anterior",
    vehicle_review_policy_denied:
      "Las políticas de la sucursal no te permiten editar una revisión",
    tire_review_policy_denied:
      "Las políticas de la sucursal no te permiten editar una revisión de neumático",
    vehicle_review:
      "¿Estas seguro de actualizar la revisión con el kilometraje {odometer}?",
  },
  updated: {
    user_roles: "Listo, los roles se actualizaron con éxito",
    tire_wear: "Listo, se actualizó el desgaste del neumático con éxito",
    vehicle_changed: "Listo, el vehículo ha sido cambiado con éxito",
    corporate: "Listo, se actualizó el corporativo con éxito",
    brand: "Listo, se actualizó la marca con éxito",
    size: "Listo, se actualizó la medida con éxito",
    company: "Listo, se actualizó la empresa con éxito",
    division: "Listo, se actualizó la división con éxito",
    subsidiary: "Listo, se actualizó la sucursal con éxito",
    association: "Listo, se actualizó el transportista con éxito",
    provider: "Listo, se actualizó el proveedor con éxito",
    wear: "Listo, se actualizó el desgaste con éxito",
    driver: "Listo, se actualizó el conductor con éxito",
    user: "Listo, se actualizó el usuario con éxito",
    user_role: "Listo, se actualizó el rol de usuario con éxito",
    warehouse: "Listo, se actualizó el almacén con éxito",
    retirement_cause: "Listo, se actualizó la causa de retiro con éxito",
    gps: "Listo, se actualizó el gps con éxito",
    damage: "Listo, se actualizó el tipo de daño con éxito",
    revitalized_tire_model:
      "Listo, se actualizó el modelo de neumático revitalizado con éxito",
    alert: "Listo, se actualizó la alerta con éxito",
    rfid: "Listo, se actualizó el rfid con éxito",
    vehicle_type: "Listo, se actualizó el tipo de vehículo con éxito",
    pressure_policy: "Listo, se actualizó la política de presión con éxito",
    depth_policy: "Listo, se actualizó la política de profundidad con éxito",
    company_policy: "Listo, las políticas se actualizaron con éxito",
    tire_model: "Listo, se actualizó el modelo de neumático con éxito",
    vehicle: "Listo, se actualizó el vehículo con éxito",
    policy: "Listo, se actualizó la política de condición",
    tire: "Listo, se actualizó el neumático con éxito",
    model_pressure_policy:
      "Listo, se actualizó la política de presión por modelo con éxito",
    tire_pressure: "Listo, se actualizaron las presiones con éxito",
    tire_review: "Listo, actualizó la revisión de neumático con éxito",
    vehicle_review: "Listo, se actualizó la revisión de vehículo con éxito",
    helmet_value: "Listo, se actualizó el valor del casco con éxito",
    concept: "Listo, el concepto se actualizó con éxito",
    block: "Listo, se actualizó el bloque con éxito",
    checklist: "Listo, se actualizó el checklist con éxito",
    tpms: "Listo, se actualizó el sensor tpms",
    temperature_policy:
      "Listo, se actualizó la política de temperatura con éxito",
  },
  created: {
    alert: "Listo, se creó una nueva Alerta con éxito",
    corporate: "Listo, se creó un nuevo Corporativo con éxito",
    brand: "Listo, se creó una nueva Marca con éxito",
    size: "Listo, se creó una nueva Medida con éxito",
    company: "Listo, se creó una nueva Empresa con éxito",
    subsidiary: "Listo, se creó una nueva Sucursal con éxito",
    association: "Listo, se creó un nuevo Transportista con éxito",
    provider: "Listo, se creó una nuevo Proveedor con éxito",
    division: "Listo, se creó una nueva División con éxito",
    driver: "Listo, se creó un nuevo conductor con éxito",
    damage: "Listo, se creó un nuevo Tipo de daño con éxito",
    user: "Listo, se creó un nuevo usuario con éxito",
    user_role: "Listo, se asignó un nuevo rol al usuario con éxito",
    revitalized_tire_model:
      "Listo, se creó un nuevo modelo de neumático revitalizado con éxito",
    alert: "Listo, se creo una nueva Alerta con éxito",
    wear: "Listo, se creó un nuevo desgaste con éxito",
    retirement_cause: "Listo se creó una nueva causa de retiro con éxito",
    rfid: "Listo, se creó un nuevo rfid con éxito",
    warehouse: "Listo, se creó un nuevo almacén con éxito",
    gps: "Listo, se creó un nuevo gps con éxito",
    tire_model: "Listo, se creó un nuevo modelo de neumático con éxito",
    vehicle_type: "Listo, se creó un nuevo tipo de vehículo con éxito",
    pressure_policy: "Listo, se creó una nueva política de presión con éxito",
    depth_policy: "Listo, se creó una nueva política de profundidad con éxito",
    vehicle: "Listo, se creó un nuevo Vehículo con éxito",
    policy: "Listo, se creó una nueva política de condición",
    tire: "Listo, se creó un nuevo neumático con éxito",
    tires: "Listo, se crearon los neumáticos con éxito",
    vehicle_review: "Listo, se creó una nueva revisión de vehículo",
    model_pressure_policy:
      "Listo, se creó una nueva política de presión por modelo con éxito",
    tire_damage: "Listo, se registraron los daños al neumático con éxito",
    tire_wear: "Listo, se registraron los desgastes al neumático con éxito",
    tire_review: "Listo, se creó una nueva revisión de neumático con éxito",
    helmet_value: "Listo, se registro el valor del casco con éxito",
    concept: "Listo, se creó un nuevo concepto con éxito",
    block: "Listo, se creó un nuevo bloque con éxito",
    checklist: "Listo, se creó un nuevo checklist con éxito",
    notification: "Listo, se creó una nueva notificación con éxito",
    tpms: "Listo, se creó un nuevo sensor tpms",
  },
  change_status: {
    corporate: {
      enable: "¿Estas seguro de habilitar el corporativo?",
      disable: "¿Estas seguro de deshabilitar el corporativo?",
    },
    brand: {
      enable: "¿Estas seguro de habilitar la marca?",
      disable: "¿Estas seguro de deshabilitar la marca?",
    },
    size: {
      enable: "¿Estas seguro de habilitar la medida?",
      disable: "¿Estas seguro de deshabilitar la medida?",
    },
    company: {
      enable: "¿Estas seguro de habilitar la empresa?",
      disable: "¿Estas seguro de deshabilitar la empresa?",
    },

    division: {
      enable: "¿Estas seguro de habilitar la división?",
      disable: "¿EStas seguro de deshabilitar la división?",
    },
    subsidiary: {
      enable: "¿Estas seguro de habilitar la sucursal?",
      disable: "¿Estas seguro de deshabilitar la sucursal?",
    },
    association: {
      enable: "¿Estas seguro de habilitar el transportista?",
      disable: "¿Estas seguro de deshabilitar el transportista?",
    },
    provider: {
      enable: "¿Estas seguro de habilitar el proveedor?",
      disable: "¿Estas seguro de deshabilitar el proveedor?",
    },
    wear: {
      enable: "¿Estas seguro de habilitar el desgaste?",
      disable: "¿EStas seguro de deshabilitar el desgaste?",
    },
    driver: {
      enable: "¿Estas seguro de habilitar al conductor?",
      disable: "¿Estas seguro de deshabilitar al conductor?",
    },
    user: {
      enable: "¿Estas seguro de habilitar el usuario?",
      disable: "¿Estas seguro de deshabilitar el usuario?",
    },
    warehouse: {
      enable: "¿Estas seguro de habilitar el almacén?",
      disable: "¿Estas seguro de deshabilitar el almacén?",
    },
    retirement_cause: {
      enable: "¿Estas seguro de habilitar la causa de retiro?",
      disable: "¿Estas seguro de deshabilitar la causa de retiro?",
    },
    gps: {
      enable: "¿Estas seguro de habilitar el gps?",
      disable: "¿Estas seguro de deshabilitar el gps?",
    },
    damage: {
      enable: "¿Estas seguro de habilitar el tipo de daño?",
      disable: "¿Estas seguro de deshabilitar el tipo de daño?",
    },
    revitalized_tire_model: {
      enable: "¿Estas seguro de habilitar el modelo de neumático revitalizado?",
      disable:
        "¿Estas seguro de deshabilitar el modelo de neumático revitalizado?",
    },
    alert: {
      enable: "¿Estas seguro de habilitar la alerta?",
      disable: "¿Estas seguro de deshabilitar la alerta?",
    },
    rfid: {
      enable: "¿Estas seguro de habilitar el rfid?",
      disable: "¿Estas seguro de deshabilitar el rfid?",
    },
    tire_model: {
      enable: "¿Estas seguro de habilitar el modelo de neumático?",
      disable: "¿Estas seguro de deshabilitar el modelo de neumático?",
    },
    vehicle_type: {
      enable: "¿Estas seguro de habilitar el tipo de vehículo?",
      disable: "¿Estas seguro de deshabilitar el tipo de vehículo?",
    },
    vehicle: {
      enable: "¿Estas seguro de habilitar el vehículo?",
      disable: "¿Estas seguro de deshabilitar el vehículo?",
    },
    tire: {
      enable: "¿Estas seguro de habilitar el neumático?",
      disable: "¿Estas seguro de deshabilitar el neumático?",
    },
    tpms: {
      enable: "¿Estas seguro de habilitar el sensor tpms?",
      disable: "¿Estas seguro de deshabilitar el sensor tpms?",
    },
  },
  change_approved: {
    brand: {
      approve: "¿Estas seguro de aprobar la marca?",
      disapprove: "¿Estas seguro de desaprobar la marca?",
    },
    model: {
      approve: "¿Estas seguro de aprobar el modelo?",
      disapprove: "¿Estas seguro de desaprobar el modelo?",
    },
    size: {
      approve: "¿Estas seguro de aprobar la medida?",
      disapprove: "¿Estas seguro de desaprobar la medida?",
    },
  },
  reset: {
    odometer: "¿Estas seguro de reiniciar el odómetro?",
    difference: "¿Estas seguro de reiniciar la diferencia del gps?",
  },
  sent: {
    revitalization: "Listo, se envió el neumático a revitalización",
    repair: "Listo, se envió el neumático a reparación",
    pile: "Listo, se envió el neumático a pila de desecho",
    warehouse: "Listo, se envió el neumático al almacén",
    multiple_to_warehouse: "Listo, se enviarón los neumáticos al almacén",
  },
  linked: {
    tire_with_rfid: "Listo, se vinculó el rfid al neumático con éxito",
    gps_with_vehicle: "Listo, se vinculó el gps al vehículo con éxito",
    vehicle_with_driver: "Listo, se vinculó el conductor al vehículo con éxito",
    tpms_with_tire: "Listo, es vinculó el sensor tpms al neumático con éxito",
  },
  mounted: "Listo, se montó el neumático con éxito",
  finalize: "¿Estas seguro de finalizar la revisión?",
  finalized: "Listo, se ha finalizado la inspección con éxito",
  physical_difference:
    "¿Estas seguro de reportar que el neumático no coincide?",
  pressure_adjustment: "¿Estas seguro de hacer el ajuste de presiones?",
  pressure_adjusted: "Listo, las presiones se ajustaron con éxito",
  identification_completed:
    "Listo, se completó la identificación de los neumáticos con éxito",
  rotate:
    "Rotar el neumático de la posición {position} con el de la posición {position2}",
  single_rotate:
    "Cambiar el neumático de la posición {position} a la posición {position2}",
  cycle:
    "Ya no es posible renovar el neumático, ya se cumplieron los ciclos definidos en la política",
  cancel_movement: "¿Estas seguro de cancelar el movimiento actual?",
  notice_cancel_movement:
    "Se cancela el movimiento actual y el neumático pasará al almacén de movimientos cancelados",
  out_of_time_review:
    "Han pasado 24 hrs o más desde la última revisión (de rotaciones o movimientos), para efectuar movimientos debe registrar una nueva",
  travel_confirm:
    "El recorrido del vehículo será de {value} km, ¿Estas seguro de continuar?",
  vehicle_not_found: "El vehículo {vehicle} no fue encontrado",
  vehicle_in_process:
    "El vehículo {vehicle} tiene una inspección en proceso y debe ser finalizada, para habilitar montaje",
  copied_to_clipboard: "Copiado en el portapapeles",
};
