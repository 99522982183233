import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionClearVehicleHistory } from "store/actions/vehicle/review/clearVehicleHistory.js";
import { actionGetVehicleHistory } from "store/actions/vehicle/review/getVehiclesHistory.js";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetVehicleReviewFilters } from "store/actions/vehicle/review/setVehicleReviewFilters";
import { actionClearVehicleReviewFilters } from "store/actions/vehicle/review/clearVehicleReviewFilters";

import Page from "./page";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment-timezone";
import ReviewHistoryPdf from "./PDF/Document";

export function ReviewHistory({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDamageDialog, setOpenDamageDialog] = useState(false);
  const [damages, setDamages] = useState([]);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [reviewAlerts, setReviewAlerts] = useState([]);
  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();

  const {
    filter,
    clearVehicleHistory,
    getVehicleReviewHistory,
    setVehicleReviewFilters,
    clearVehicleReviewFilters,
    vehicleReview,
    setNotification,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleReviewHistory(id, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();

    return () => {
      clearVehicleHistory();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearVehicleReviewFilters();
    };
  }, []);

  useEffect(() => {
    if (isSavingPdf && !pdfInstance.loading) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "RevisionVehículo.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  async function onChangePage(page) {
    try {
      await getVehicleReviewHistory(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleOpenDamageDialog(damages) {
    setDamages(damages);
    setOpenDamageDialog(true);
  }

  function handleCloseDamageDialog() {
    setOpenDamageDialog(false);
    setDamages([]);
  }

  function handleOpenAlertDialog(alerts) {
    setReviewAlerts(alerts);
    setOpenAlertDialog(true);
  }
  function handleCloseAlertDialog() {
    setOpenAlertDialog(false);
    setReviewAlerts([]);
  }

  function savePdf() {
    setIsSavingPdf(true);
    // Se genera un UUID para forzar que el pdf se descargue de nuevo,
    // aunque su contenido sea idéntico al anterior
    const uuid =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    const pdfData = {
      uuid: uuid,
      date: moment().tz("America/Mexico_City").format("DD/MM/YYYY hh:mm:ss a"),
      vehicleInspection: vehicleReview.data[0],
      tireInspections: vehicleReview.data[0].tire_review,
    };
    updatePdfInstance(<ReviewHistoryPdf {...pdfData} uuid={uuid} />);
  }

  return (
    <Page
      t={t}
      vehicleId={id}
      vehicleReview={getSortedByPosition(vehicleReview)}
      clearVehicleHistory={clearVehicleHistory}
      onChangePage={onChangePage}
      filter={filter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      setVehicleReviewFilters={setVehicleReviewFilters}
      clearVehicleReviewFilters={clearVehicleReviewFilters}
      openDamageDialog={openDamageDialog}
      damages={damages}
      handleOpenDamageDialog={handleOpenDamageDialog}
      handleCloseDamageDialog={handleCloseDamageDialog}
      reviewAlerts={reviewAlerts}
      openAlertDialog={openAlertDialog}
      handleOpenAlertDialog={handleOpenAlertDialog}
      handleCloseAlertDialog={handleCloseAlertDialog}
      savePdf={savePdf}
    />
  );
}

ReviewHistory.propTypes = {};

const mapStateToProps = (state) => ({
  vehicleReview: state.Vehicle.History.vehicle_review,
  filter: state.Vehicle.History.filter,
});

const mapDispatchToProps = (dispatch) => ({
  clearVehicleHistory: actionClearVehicleHistory(dispatch),
  getVehicleReviewHistory: actionGetVehicleHistory(dispatch),
  setVehicleReviewFilters: actionSetVehicleReviewFilters(dispatch),
  clearVehicleReviewFilters: actionClearVehicleReviewFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewHistory);

function getSortedByPosition(vehicleReview) {
  if (vehicleReview.data.length > 0) {
    vehicleReview.data[0].tire_review.sort((a, b) => {
      if (
        a.movement_tire.vehicle_tire.length > 0 &&
        b.movement_tire.vehicle_tire.length > 0
      ) {
        if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] >
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return 1;
        } else if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] <
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return -1;
        }
      }

      return 0;
    });
  }
  return vehicleReview;
}
