export default {
  subsidiary: "Subsidiary",
  permits: {
    unlink: {
      tire_subsidiary: "No permissions to unlink subsidiary from tire",
    },
    show: {
      tire_subsidiary: "No permissions to view subsidiary linked to tire",
    },
  },
  messages: {
    unlinked_tire_subsidiary:
      "Done, the subsidiary has been unlinked from the tire",
  },
};
