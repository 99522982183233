export default {
  logout: "Logout",
  review: "Reviews",
  vehicle_review: "Vehicle Review",
  driver: "Drivers",
  brand: "Brands",
  corporate: "Corporates",
  company: "Companies",
  subsidiary: "Subsidiaries",
  warehouse: "Warehouses",
  damage_type: "Damage types",
  user: "Users",
  wear_type: "Wear types",
  provider: "Providers",
  vehicle: "Vehicles",
  mounting: "Mounting",
  vehicle_type: "Vehicles types",
  tire_model: "Original Models",
  tire_size: "Sizes",
  tires: "Tires",
  division: "Divisions",
  retirement_causes: "Retirement causes",
  gps: "Gps",
  alert: "Alert Codes",
  revitalized_tire_model: "Revitalized models",
  labels: {
    vehicles: "Vehicles",
    admin: "Administration",
    areas: "Areas",
    control: "Control",
    catalogs: "Catalogs",
  },
};
