import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, FieldArray } from "formik";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { TextField } from "formik-material-ui";
import SelectField from "components/molecules/general/SelectField";
import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";
import PasswordField from "components/molecules/general/PasswordField";
import CheckboxField from "components/molecules/general/CheckboxField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  newField,
  field,
  values,
  menuProps,
  defaultText,
  defaultValue,
  onChange,
}) {
  return (
    <Fragment>
      <div className={classnames(css.full_width)}>
        <Typography variant="subtitle1" color="textPrimary">
          {field.title}
        </Typography>
      </div>
      <FieldArray
        name={field.name}
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {values[field.name].map((fieldInput, index) => (
              <Fragment key={index}>
                <div className={classnames(css.full_width, css.dynamic)}>
                  {field.fields.map((input) => (
                    <Field
                      {...menuProps}
                      {...defaultValue}
                      {...defaultText}
                      {...onChange}
                      autoComplete={input.autocomplete}
                      margin="normal"
                      variant="filled"
                      key={[field.name, index, input.name].join("_")}
                      type={input.type}
                      name={`${field.name}[${index}].${input.name}`}
                      label={input.label}
                      accept={input.accept}
                      multiple={input.multiple}
                      disabled={
                        input.disabled === "isUpdate"
                          ? isUpdate
                          : input.disabled
                      }
                      placeholder={input.placeholder}
                      required={
                        typeof input.required === "function"
                          ? input.required(values[input.requiredInput])
                          : input.required === "isUpdate"
                          ? !isUpdate
                          : input.required
                      }
                      className={classnames(
                        input.fullWidth ? css.full_width : css.field
                      )}
                      component={
                        input.component === "select"
                          ? SelectField
                          : input.component === "file"
                          ? FileDragAndDropField
                          : input.component === "password"
                          ? PasswordField
                          : input.component === "checkbox"
                          ? CheckboxField
                          : TextField
                      }
                    >
                      {input.selectItems &&
                        input.selectItems.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item.value}
                              disabled={item.disabled}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Field>
                  ))}

                  <Button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                    color="secondary"
                    size="small"
                    variant="fab"
                    disabled={values[field.name].length <= 1}
                    className={classnames(css.remove_button)}
                  >
                    <RemoveIcon />
                  </Button>
                </div>
                <Divider className={classnames(css.full_width, css.m0)} />
              </Fragment>
            ))}

            <div className={classnames(css.full_width, css.add_button)}>
              <Button
                type="button"
                onClick={() => arrayHelpers.push(newField)}
                color="secondary"
                size="small"
                variant="fab"
              >
                <AddIcon />
              </Button>
            </div>
          </>
        )}
      />
    </Fragment>
  );
}

Page.propTypes = {
  newField: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  menuProps: PropTypes.object,
  defaultText: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.object,
};

export default Page;
