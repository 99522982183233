import { http } from "store/actions/http";
import { DELETE_CONDITION_POLICY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteConditionPolicy =
  (dispatch) => async (subsidiaryId, vehicleId, vehicleConditionPolicyId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: `DELETE`,
        path: `subsidiary/${subsidiaryId}/condition/policy/vehicle/${vehicleId}/axle/${vehicleConditionPolicyId}`,
      });
      dispatch({
        type: DELETE_CONDITION_POLICY,
        payload: vehicleConditionPolicyId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
