export default {
  title: "New Cycle",
  update_title: "Update cycle",
  form: {
    condition_field: {
      value: "Select a condition",
      label: "Condition",
    },
    provider_field: {
      value: "Select a provider",
      label: "Provider",
    },
    brand_field: {
      value: "Select a brand",
      label: "Brand",
    },
    tire_size_field: {
      label: "Size/layers",
      value: "Select a size/layers",
    },
    permits: {
      update: "No permissions to update cycles tires",
    },
  },
  messages: {
    tire_cycle_created: "Done, cycle has been successfully created",
    tire_cycle_updated: "Done, cycle has been successfully updated",
  },
};
