import { http } from "store/actions/http";
import { GET_RENEWABILITY_INDEX_REPORT } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetRenewabilityIndexReport =
  (dispatch) =>
  async (
    {
      page,
      subsidiary_id: subsidiaryId,
      company_id: companyId,
      corporate_id: corporateId,
      models,
      brands,
      sizes,
      sort_by: sortBy,
      order,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/renewability/index`,
        params: {
          page,
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          models,
          brands,
          sizes,
          sort_by: sortBy,
          order,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_RENEWABILITY_INDEX_REPORT,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
