import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { values, getTireModels, getTireModelVariations, setNotification } =
    rest;
  const [tireModels, setTireModels] = useState([]);
  const [tireModelVariations, setTireModelVariations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getTireModels(
          {
            scope: "tire_model_id,tire_model.name,tire_model.status",
          },
          false
        );
        setTireModels(dataTireModels.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireModelVariations(
          {
            tireModelId: values.tire_model_id,
            scope:
              "tire_model_variation_id,status,tire_size.size,number_layers",
          },
          false
        );
        setTireModelVariations(data);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && values.tire_model_id) {
      fetchData();
    }
  }, [values.tire_model_id]);

  return (
    <Page
      tireModels={tireModels}
      tireModelVariations={tireModelVariations}
      t={t}
      {...rest}
    />
  );
}

Form.propTypes = {
  values: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
