import React from "react";
import PropTypes from "prop-types";

import WarehouseTireDialog from "components/molecules/warehouse/tire/WarehouseTireDialog";

function Page({
  t,
  open,
  warehouseTire,
  viewButtonName,
  showTirePermit,
  showWarehousePermit,
  deleteLinkTireWithWarehousePermit,
  onClose,
  handleView,
  handleUnlink,
}) {
  return (
    <WarehouseTireDialog
      open={open}
      isHistory={false}
      warehouseTire={warehouseTire}
      viewButtonName={viewButtonName}
      showTooltip={
        !showTirePermit
          ? t("_permits.show.tire")
          : !showWarehousePermit
          ? t("_permits.show.warehouse")
          : ""
      }
      unlinkTooltip={
        !deleteLinkTireWithWarehousePermit
          ? t("_permits.delete_link.tire_with_warehouse")
          : ""
      }
      onClose={onClose}
      handleUnlink={handleUnlink}
      handleView={handleView}
    />
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showTirePermit: PropTypes.bool.isRequired,
  showWarehousePermit: PropTypes.bool.isRequired,
  viewButtonName: PropTypes.string.isRequired,
  deleteLinkTireWithWarehousePermit: PropTypes.bool.isRequired,
  warehouseTire: PropTypes.shape({
    updated_at: PropTypes.string,
    warehouse: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    created_at: PropTypes.string.isRequired,
    tire: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
    updated_by: PropTypes.shape({
      last_name_2: PropTypes.string,
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
    }),
    created_by: PropTypes.shape({
      last_name_2: PropTypes.string,
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
  handleUnlink: PropTypes.func.isRequired,
};

export default Page;
