import { findLineByLeastSquares } from "./trendLine";
import moment from "moment-timezone";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
});

const colors = [
  // "#8ecae6",
  "#023047",
  // "#219ebc",
  // "#ffb703",
  // "#fb8500",
  // "#283618",
  // "#ff006e",
  // "#8338ec",
  // "#370617",
  "#9d0208",
  // "#f28482",
  // "#e09f3e",
  // "#432818",
  "#4d194d",
  "#606c38",
  "#40916c",
  "#40E0D0",
  "#7FFFD4",
  "#BDB76B",
  "#FFA07A",
];

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  locale: "en",
  scales: {
    y: {
      suggestedMin: 0,
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  parsing: {
    xAxisKey: "fecha",
    yAxisKey: "cantidad",
  },
  plugins: {
    title: {
      display: true,
      padding: 16,
      font: {
        size: 16,
        family: "Poppins",
      },
      color: "#6c757d",
    },
    legend: {
      position: "bottom",
    },
    datalabels: {
      display: function (context) {
        return context.dataset.data[context.dataIndex].cantidad > 0;
      },
      font: {
        weight: "bold",
      },
      align: "center",
      anchor: "center",
    },
    chartAreaBackgroundPlugin: {
      backgroundColor: "rgba(80, 90, 90, 0.2)",
    },
  },
};

export function getMonthlyCharts(data, billData, t) {
  const charts = [];

  const getters = [
    getTotalDispose,
    getTotalKmDispose,
    getBrandAnalysis,
    getRemainderDepth,
    getCostByDisposal,
    getDisposalPercentage,
    getTireEquivalence,
  ];

  const billGetters = [getBillByPieces, getBillByCost, getDisposalSavings];

  getters.forEach((getter) => {
    const chart = getter(data, t);
    if (Object.keys(chart).length > 0) {
      charts.push(chart);
    }
  });

  billGetters.forEach((getter) => {
    const chart = getter(billData, t);
    if (Object.keys(chart).length > 0) {
      charts.push(chart);
    }
  });

  return charts;
}

function getTotalDispose(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD", "TOTAL"];

    datasets.forEach((dataset) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 18,
        // minBarLength: 12,
        borderColor:
          dataset == "ORIGINAL"
            ? "#2e75b5"
            : dataset == "RETREAD"
            ? "#c00000"
            : "#757070",
        backgroundColor:
          dataset == "ORIGINAL"
            ? "#2e75b588"
            : dataset == "RETREAD"
            ? "#c0000088"
            : "#75707088",
        order: 0,
        datalabels: {
          color: "white",
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
          font: {
            size: 11,
          },
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label] ? conditions[label]["elements"] : null;
        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const total = result.datasets.find((el) => el.condition == "TOTAL");
    const linearRegression = findLineByLeastSquares(
      total.data.map((value, index) => index),
      total.data.map((el) => el.cantidad)
    );

    result.datasets.push({
      type: "line",
      label: t("_labels.trend"),
      data: [
        {
          fecha: total.data[0].fecha,
          cantidad: Math.round(linearRegression[1][0]),
        },
        {
          fecha: total.data[total.data.length - 1].fecha,
          cantidad: Math.round(
            linearRegression[1][linearRegression[1].length - 1]
          ),
        },
      ],
      borderColor: "#444",
      backgroundColor: "transparent",
      borderDash: [7, 4],
      pointBackgroundColor: "#333",
      pointRadius: 1,
      tension: 0.1,
      datalabels: {
        display: false,
      },
      order: 1,
    });

    const options = {
      ...defaultOptions,
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t("_labels.disposal_chart.titles.total_dispose").toUpperCase(),
        },
      },
    };
    return { type: "bar", options, data: result };
  }

  return {};
}

function getTotalKmDispose(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);
    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["TOTAL"];

    const formatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: 0,
    });
    datasets.forEach((dataset) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        backgroundColor: "#6185c6",
        borderColor: "#6185c6",
        datalabels: {
          color: "#333",
          textShadowColor: "white",
          textShadowBlur: 4,
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            weight: "bold",
            size: 12,
          },
          align: "left",
          anchor: "start",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label] ? conditions[label]["km"] : 0;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      plugins: {
        ...defaultOptions.plugins,
        legend: { display: false },
        title: {
          ...defaultOptions.plugins.title,
          text: t("_labels.disposal_chart.titles.km_dispose").toUpperCase(),
        },
      },
    };
    return { type: "line", options, data: result };
  }

  return {};
}

function getBrandAnalysis(data, t) {
  if (data) {
    if (
      data.brands &&
      data.brands.length > 0 &&
      Object.keys(data.data).length > 0
    ) {
      const entries = Object.entries(data.data);

      let result = {
        datasets: [],
      };

      const datasets = [...data.brands, "OTHERS"];

      datasets.forEach((dataset, index) => {
        result.datasets.push({
          brand: dataset,
          label:
            dataset == "OTHERS"
              ? t("_labels.disposal_chart.others").toUpperCase()
              : dataset,
          data: [],
          barThickness: 40,
          // minBarLength: 12,
          backgroundColor: `${colors[index]}aa`,
          borderColor: colors[index],
          datalabels: {
            color: "white",
            textShadowBlur: 5,
            textStrokeColor: "white",
            textShadowColor: "black",
            formatter: (value) => {
              return formatter.format(value.cantidad);
            },
            font: {
              weight: "bold",
            },
            align: "center",
            anchor: "center",
          },
        });
      });

      entries.forEach(([month, info]) => {
        const { BRANDS: brands } = info;

        datasets.forEach((label) => {
          const found = result.datasets.find((el) => el.brand == label);
          const value = brands[label] ? brands[label]["elements"] : null;

          found.data.push({
            cantidad: value,
            fecha: moment(month).format("MMM YYYY"),
          });
        });
      });

      const options = {
        ...defaultOptions,
        scales: {
          ...defaultOptions.scales,
          y: {
            stacked: true,
            suggestedMin: 0,
          },
          x: {
            ...defaultOptions.scales.x,
            stacked: true,
          },
        },
        plugins: {
          ...defaultOptions.plugins,
          title: {
            ...defaultOptions.plugins.title,
            text: t(
              "_labels.disposal_chart.titles.brand_analysis"
            ).toUpperCase(),
          },
        },
      };
      return { type: "bar", options, data: result };
    }
  }

  return {};
}

function getRemainderDepth(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD"];
    const colors = ["#023047", "#219ebc"];

    datasets.forEach((dataset, index) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 40,
        borderColor: `${colors[index]}88`,
        backgroundColor: colors[index],
        order: 0,
        datalabels: {
          color: "white",
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            size: 11,
          },
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["remainder_depth"]
          : null;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        y: {
          stacked: true,
          suggestedMin: 0,
        },
        x: {
          ...defaultOptions.scales.x,
          stacked: true,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t(
            "_labels.disposal_chart.titles.remainder_depth"
          ).toUpperCase(),
        },
      },
    };
    return { type: "bar", options, data: result };
  }

  return {};
}

function getCostByDisposal(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD"];
    const colors = ["#023047", "#219ebc"];
    const formatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: 0,
    });
    datasets.forEach((dataset, index) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 52,
        borderColor: `${colors[index]}88`,
        backgroundColor: colors[index],
        order: 0,
        datalabels: {
          color: "white",
          display: (context) => {
            return context.dataset.data[context.dataIndex].cantidad >= 100;
          },
          formatter: (value) => {
            return `$${formatter.format(value.cantidad)}`;
          },
          font: {
            size: 11,
          },
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["loss_per_disposal"]
          : null;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        y: {
          stacked: true,
          suggestedMin: 0,
        },
        x: {
          ...defaultOptions.scales.x,
          stacked: true,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t(
            "_labels.disposal_chart.titles.cost_by_disposal"
          ).toUpperCase(),
        },
      },
    };
    return { type: "bar", options, data: result };
  }

  return {};
}

function getDisposalPercentage(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);
    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["TOTAL"];

    const formatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
    });
    datasets.forEach((dataset) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        backgroundColor: "#6185c6",
        borderColor: "#6185c6",
        barThickness: 48,
        datalabels: {
          color: "white",
          formatter: (value) => {
            return `${formatter.format(value.cantidad)}%`;
          },
          font: {
            size: 11,
          },
          align: "center",
          anchor: "center",
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
        order: 1,
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["percentage_over_fleet"]
          : 0;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const total = result.datasets.find((el) => el.condition == "TOTAL");
    const linearRegression = findLineByLeastSquares(
      total.data.map((value, index) => index),
      total.data.map((el) => el.cantidad)
    );

    result.datasets.push({
      type: "line",
      label: t("_labels.trend"),
      data: [
        {
          fecha: total.data[0].fecha,
          cantidad: linearRegression[1][0].toFixed(2),
        },
        {
          fecha: total.data[total.data.length - 1].fecha,
          cantidad:
            linearRegression[1][linearRegression[1].length - 1].toFixed(2),
        },
      ],
      borderColor: "#444",
      backgroundColor: "transparent",
      borderDash: [7, 4],
      pointBackgroundColor: "#333",
      pointRadius: 1,
      tension: 0.1,
      datalabels: {
        display: false,
      },
      order: 0,
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        x: {
          ...defaultOptions.scales.x,
          stacked: false,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        legend: {
          display: false,
        },
        title: {
          ...defaultOptions.plugins.title,
          text: t(
            "_labels.disposal_chart.titles.disposal_percentage"
          ).toUpperCase(),
        },
      },
    };

    return { type: "bar", options, data: result };
  }

  return {};
}

function getTireEquivalence(data, t) {
  if (data && data.data) {
    const entries = Object.entries(data.data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD"];
    const colors = ["#023047", "#219ebc"];

    datasets.forEach((dataset, index) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 48,
        // minBarLength: 12,
        borderColor: `${colors[index]}88`,
        backgroundColor: colors[index],
        order: 0,
        datalabels: {
          color: "white",
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            size: 11,
          },
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["tire_equivalence"] == 0
            ? null
            : conditions[label]["tire_equivalence"]
          : null;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        y: {
          stacked: true,
          suggestedMin: 0,
        },
        x: {
          ...defaultOptions.scales.x,
          stacked: true,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t(
            "_labels.disposal_chart.titles.tire_equivalence"
          ).toUpperCase(),
        },
      },
    };

    return { type: "bar", options, data: result };
  }

  return {};
}

function getBillByPieces(data, t) {
  if (data) {
    const entries = Object.entries(data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD"];
    const colors = ["#023047", "#219ebc"];

    datasets.forEach((dataset, index) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 28,
        borderColor: `${colors[index]}`,
        backgroundColor: colors[index],
        datalabels: {
          color: "#333",
          // borderRadius: 4,
          // backgroundColor: (context) => context.dataset.backgroundColor,
          textShadowColor: "white",
          textShadowBlur: 4,
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            weight: "bold",
            size: 12,
          },
          align: "top",
          anchor: "start",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label] ? conditions[label]["elements"] : 0;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const lines = [];
    for (let index = 0; index < result.datasets.length; index++) {
      const linearRegression = findLineByLeastSquares(
        result.datasets[index].data.map((value, index) => index),
        result.datasets[index].data.map((el) => el.cantidad)
      );

      lines.push({
        type: "line",
        label: `${t("_labels.trend")} ${result.datasets[index].label}`,
        data: [
          {
            fecha: result.datasets[index].data[0].fecha,
            cantidad: Math.round(linearRegression[1][0]),
          },
          {
            fecha:
              result.datasets[index].data[
                result.datasets[index].data.length - 1
              ].fecha,
            cantidad: Math.round(
              linearRegression[1][linearRegression[1].length - 1]
            ),
          },
        ],
        borderColor: `${result.datasets[index].borderColor}aa`,
        backgroundColor: "transparent",
        borderDash: [7, 4],
        pointBackgroundColor: "#333",
        pointRadius: 1,
        tension: 0.1,
        datalabels: {
          display: false,
        },
        order: 1,
      });
    }

    result.datasets = result.datasets.concat(lines);

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t("_labels.disposal_chart.titles.bill_by_pieces").toUpperCase(),
        },
      },
    };
    return { type: "line", options, data: result };
  }

  return {};
}

function getBillByCost(data, t) {
  if (data) {
    const entries = Object.entries(data);

    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["ORIGINAL", "RETREAD"];
    const colors = ["#023047", "#219ebc"];

    const formatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "MXN",
      currencyDisplay: "narrowSymbol",
    });
    datasets.forEach((dataset, index) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        barThickness: 52,
        // minBarLength: 12,
        borderColor: `${colors[index]}`,
        backgroundColor: colors[index],
        datalabels: {
          color: "white",
          // backgroundColor: `${colors[index]}77`,
          textShadowColor: "white",
          textShadowBlur: 4,
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            size: 10,
          },
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["investment"]
          : null;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        y: {
          stacked: true,
          suggestedMin: 0,
        },
        x: {
          ...defaultOptions.scales.x,
          stacked: true,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t("_labels.disposal_chart.titles.bill_by_cost").toUpperCase(),
        },
      },
    };
    return { type: "bar", options, data: result };
  }

  return {};
}

function getDisposalSavings(data, t) {
  if (data) {
    const entries = Object.entries(data);
    if (Object.keys(entries).length == 0) {
      return {};
    }

    let result = {
      datasets: [],
    };

    const datasets = ["TOTAL"];

    const formatter = new Intl.NumberFormat("en", {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "MXN",
      currencyDisplay: "narrowSymbol",
    });
    datasets.forEach((dataset) => {
      result.datasets.push({
        condition: dataset,
        label: t(
          `_labels.disposal_chart.${dataset.toLowerCase()}`
        ).toUpperCase(),
        data: [],
        backgroundColor: "#6185c6",
        borderColor: "#6185c6",
        barThickness: 52,
        // minBarLength: 12,
        datalabels: {
          color: "white",
          formatter: (value) => {
            return formatter.format(value.cantidad);
          },
          font: {
            size: 10,
          },
          align: "center",
          anchor: "center",
          textShadowBlur: 4,
          textStrokeColor: "white",
          textShadowColor: "black",
        },
        order: 1,
      });
    });

    entries.forEach(([month, info]) => {
      const { CONDITION: conditions } = info;

      datasets.forEach((label) => {
        const found = result.datasets.find((el) => el.condition == label);
        const value = conditions[label]
          ? conditions[label]["savings_per_revitalized"] > 0
            ? conditions[label]["savings_per_revitalized"]
            : null
          : null;

        found.data.push({
          cantidad: value,
          fecha: moment(month).format("MMM YYYY"),
        });
      });
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        x: {
          ...defaultOptions.scales.x,
          stacked: false,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        legend: {
          display: false,
        },
        title: {
          ...defaultOptions.plugins.title,
          text: t(
            "_labels.disposal_chart.titles.disposal_savings"
          ).toUpperCase(),
        },
      },
    };

    return { type: "bar", options, data: result };
  }

  return {};
}

export function getYearlyCharts(data, billData, t) {
  const charts = [];

  const getters = [
    getDisposalYearlyByDivision,
    getDisposalYearlyByCycle,
    getYearlyRemainderDepth,
    getYearlyRemainderDepth,
    getYearlyRetirementCauses,
  ];
  const billGetters = [getYearlyBillByCost];

  getters.forEach((getter, index) => {
    const chart = index == 3 ? getter(data, t, true) : getter(data, t);
    if (Object.keys(chart).length > 0) {
      charts.push(chart);
    }
  });

  billGetters.forEach((getter) => {
    const chart = getter(billData, t);
    if (Object.keys(chart).length > 0) {
      charts.push(chart);
    }
  });

  return charts;
}

function getDisposalYearlyByDivision(data, t) {
  if (Object.keys(data).length > 0) {
    if (Object.keys(data.DIVISION).length > 0) {
      const result = {
        datasets: [],
      };

      const entries = Object.entries(data.DIVISION);

      result.datasets = entries.map(([year, divisions], index) => {
        const dataset = {
          label: year,
          data: [],
          maxBarThickness: 70,
          backgroundColor: `${colors[index]}aa`,
          borderColor: colors[index],
          datalabels: {
            color: "white",
            textShadowBlur: 5,
            textStrokeColor: "white",
            textShadowColor: "black",
            formatter: (value) => {
              return formatter.format(value.cantidad);
            },
            font: {
              weight: "bold",
            },
            align: "center",
            anchor: "center",
          },
        };

        const data = Object.entries(divisions).map(([division, info]) => {
          const { TOTAL: total } = info;
          const label =
            division == "NO DIVISION"
              ? t("_labels.no_division").toUpperCase()
              : division.toUpperCase();

          return {
            fecha: label,
            cantidad: total.elements <= 0 ? null : total.elements,
          };
        });

        dataset.data = data;

        return dataset;
      });

      const options = {
        ...defaultOptions,
        scales: {
          ...defaultOptions.scales,
          y: {
            stacked: true,
            suggestedMin: 0,
          },
          x: {
            ...defaultOptions.scales.x,
            stacked: true,
            ticks: {
              maxRotation: 0,
              autoSkip: false,
            },
          },
        },
        plugins: {
          ...defaultOptions.plugins,
          title: {
            ...defaultOptions.plugins.title,
            text: t(
              "_labels.disposal_chart.titles.yearly_by_division"
            ).toUpperCase(),
          },
        },
      };

      return { type: "bar", options, data: result };
    }
  }

  return {};
}

function getDisposalYearlyByCycle(data, t) {
  if (Object.keys(data).length > 0) {
    if (Object.keys(data.CYCLE).length > 0) {
      const result = {
        datasets: [],
      };

      const entries = Object.entries(data.CYCLE);

      result.datasets = entries.map(([year, cycles], index) => {
        const dataset = {
          label: year,
          data: [],
          maxBarThickness: 70,
          backgroundColor: `${colors[index]}aa`,
          borderColor: colors[index],
          datalabels: {
            color: "white",
            textShadowBlur: 5,
            textStrokeColor: "white",
            textShadowColor: "black",
            formatter: (value) => {
              return formatter.format(value.cantidad);
            },
            font: {
              weight: "bold",
            },
            align: "center",
            anchor: "center",
          },
        };

        const data = Object.entries(cycles).map(([cycle, info]) => {
          const { elements } = info;
          return {
            fecha: t(
              `_labels.disposal_chart.${cycle.toLowerCase()}`
            ).toUpperCase(),
            cantidad: elements <= 0 ? null : elements,
          };
        });

        dataset.data = data;

        return dataset;
      });

      const options = {
        ...defaultOptions,
        scales: {
          ...defaultOptions.scales,
          y: {
            stacked: true,
            suggestedMin: 0,
          },
          x: {
            ...defaultOptions.scales.x,
            stacked: true,
          },
        },
        plugins: {
          ...defaultOptions.plugins,
          title: {
            ...defaultOptions.plugins.title,
            text: t(
              "_labels.disposal_chart.titles.yearly_by_cycle"
            ).toUpperCase(),
          },
        },
      };

      return { type: "bar", options, data: result };
    }
  }

  return {};
}

function getYearlyRemainderDepth(data, t, cost = false) {
  if (Object.keys(data).length > 0) {
    if (Object.keys(data.REMAINDER_DEPTH).length > 0) {
      const result = {
        datasets: [],
      };

      const entries = Object.entries(data.REMAINDER_DEPTH);
      const formatter = new Intl.NumberFormat("en", {
        maximumFractionDigits: cost ? 0 : 2,
      });

      result.datasets = entries.map(([year, conditions], index) => {
        const dataset = {
          label: year,
          data: [],
          maxBarThickness: 80,
          backgroundColor: `${colors[index]}aa`,
          borderColor: colors[index],
          datalabels: {
            color: "white",
            textShadowBlur: 5,
            textStrokeColor: "white",
            textShadowColor: "black",
            display: function (context) {
              return context.dataset.data[context.dataIndex].cantidad > 0;
            },
            formatter: (value) => {
              return `${cost ? "$" : ""}${formatter.format(value.cantidad)}`;
            },
            font: {
              weight: "bold",
            },
            align: "center",
            anchor: "center",
          },
        };

        const data = Object.entries(conditions).map(([condition, info]) => {
          const {
            [!cost ? "remainder_depth" : "loss_per_disposal"]: remainderDepth,
          } = info;
          return {
            fecha: t(
              `_labels.disposal_chart.${condition.toLowerCase()}`
            ).toUpperCase(),
            cantidad: remainderDepth <= 0 ? null : remainderDepth,
          };
        });

        dataset.data = data;

        return dataset;
      });

      const options = {
        ...defaultOptions,
        scales: {
          ...defaultOptions.scales,
          y: {
            stacked: true,
            suggestedMin: 0,
          },
          x: {
            ...defaultOptions.scales.x,
            stacked: true,
          },
        },
        plugins: {
          ...defaultOptions.plugins,
          title: {
            ...defaultOptions.plugins.title,
            text: !cost
              ? t(
                  "_labels.disposal_chart.titles.yearly_remainder_depth"
                ).toUpperCase()
              : t("_labels.disposal_chart.titles.yearly_cost").toUpperCase(),
          },
        },
      };

      return { type: "bar", options, data: result };
    }
  }

  return {};
}

function getYearlyRetirementCauses(data, t) {
  if (Object.keys(data).length > 0) {
    if (Object.keys(data.RETIREMENT_CAUSE).length > 0) {
      const result = {
        datasets: [],
      };

      const entries = Object.entries(data.RETIREMENT_CAUSE);

      result.datasets = entries.map(([year, causes], index) => {
        const dataset = {
          label: year,
          data: [],
          maxBarThickness: 65,
          backgroundColor: `${colors[index]}aa`,
          borderColor: colors[index],
          datalabels: {
            color: "white",
            textShadowBlur: 5,
            textStrokeColor: "white",
            textShadowColor: "black",
            display: function (context) {
              return context.dataset.data[context.dataIndex].cantidad > 0;
            },
            formatter: (value) => {
              return formatter.format(value.cantidad);
            },
            font: {
              weight: "bold",
            },
            align: "center",
            anchor: "center",
          },
        };

        const data = Object.entries(causes).map(([cause, info]) => {
          const { elements } = info;

          let label = t(
            `_labels.disposal_chart.causes.${cause.toLowerCase()}`
          ).toUpperCase();
          // if (label.length > 10) {
          //   label = [[label.substring(0, 10), label.substring(10)]];
          // }
          return { fecha: label, cantidad: elements };
        });

        dataset.data = data;

        return dataset;
      });

      const options = {
        ...defaultOptions,
        scales: {
          ...defaultOptions.scales,
          y: {
            stacked: true,
            suggestedMin: 0,
          },
          x: {
            ...defaultOptions.scales.x,
            stacked: true,
            ticks: {
              maxRotation: 0,
              autoSkip: false,
            },
          },
        },
        plugins: {
          ...defaultOptions.plugins,
          title: {
            ...defaultOptions.plugins.title,
            text: t(
              "_labels.disposal_chart.titles.yearly_retirement"
            ).toUpperCase(),
          },
        },
      };

      return { type: "bar", options, data: result };
    }
  }

  return {};
}

function getYearlyBillByCost(data, t) {
  if (Object.keys(data).length > 0) {
    const result = {
      datasets: [],
    };

    const entries = Object.entries(data);

    const formatter = new Intl.NumberFormat("en", { maximumFractionDigits: 0 });
    result.datasets = entries.map(([year, conditions], index) => {
      const dataset = {
        label: year,
        data: [],
        maxBarThickness: 80,
        backgroundColor: `${colors[index]}aa`,
        borderColor: colors[index],
        datalabels: {
          color: "white",
          textShadowBlur: 5,
          textStrokeColor: "white",
          textShadowColor: "black",
          display: function (context) {
            return context.dataset.data[context.dataIndex].cantidad > 0;
          },
          formatter: (value) => {
            return `$${formatter.format(value.cantidad)}`;
          },
          font: {
            weight: "bold",
          },
          align: "center",
          anchor: "center",
        },
      };

      const data = Object.entries(conditions).map(([condition, info]) => {
        const { investment } = info;
        return {
          fecha: t(
            `_labels.disposal_chart.${condition.toLowerCase()}`
          ).toUpperCase(),
          cantidad: investment <= 0 ? null : investment,
        };
      });

      dataset.data = data;

      return dataset;
    });

    const options = {
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        y: {
          stacked: true,
          suggestedMin: 0,
        },
        x: {
          ...defaultOptions.scales.x,
          stacked: true,
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        title: {
          ...defaultOptions.plugins.title,
          text: t("_labels.disposal_chart.titles.yearly_bill").toUpperCase(),
        },
      },
    };

    return { type: "bar", options, data: result };
  }

  return {};
}
