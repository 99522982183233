import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateLastMovementExcel =
  (dispatch) =>
  async ({
    subsidiaries,
    companies,
    corporate_id: corporateId,
    date_from: dateFrom,
    date_to: dateTo,
    brands,
    models,
    sizes,
    search,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "report/last/movement/excel",
        params: {
          subsidiaries,
          companies,
          corporate_id: corporateId,
          date_from: dateFrom,
          date_to: dateTo,
          brands,
          models,
          sizes,
          search,
        },
        download: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
