import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddTirePressureReview } from "store/actions/vehicle/review/addTirePressureReview";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionAdjustPressures } from "store/actions/vehicle/review/adjustPressures";
import { actionGetLastVehicleReview } from "store/actions/vehicle/review/getLastVehicleReview";
import { actionClearLastVehicleReview } from "store/actions/vehicle/review/clearLastVehicleReview";
import { actionAddVehicleReview } from "store/actions/vehicle/review/addVehicleReview";
import { actionGetGpsOdometer } from "store/actions/vehicle/gps/getGpsOdometer";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function TirePressureForm({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    review,
    vehicleTypeAxle,
    axleTires,
    setNotification,
    addTirePressureReview,
    getVehicleAxles,
    adjustPressures,
    sendPressureSettingPolicy,
    getLastVehicleReview,
    clearLastVehicleReview,
    addVehicleReview,
    getGpsOdometer,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      odometer: "",
      date: "",
      observation: "",
      review: axleTires.map((axleTire) => ({
        movement_tire_id: axleTire.vehicle_tire.movement_tire.movement_tire_id,
        pressure: "",
      })),
    },
  });

  const [isRequired, setIsRequired] = useState(true);
  const [hasGpsOdometer, setHasGpsOdometer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        await getLastVehicleReview(vehicleId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (vehicleId) {
      fetchData();
    }

    return () => {
      clearLastVehicleReview();
    };
  }, []);

  useEffect(() => {
    setInitialValues({
      date: "",
      odometer: parseFloat(review.odometer.toFixed(2)),
      observation: "",
      review: axleTires.map((axleTire) => ({
        movement_tire_id: axleTire.vehicle_tire.movement_tire.movement_tire_id,
        pressure: "",
      })),
    });
  }, [review.odometer]);

  useEffect(() => {
    if (review.vehicle_review_id) {
      if (review.vehicle.gps_vehicle.length > 0) {
        async function setGpsOdometer() {
          try {
            setIsLoading(true);
            const { gps_id: gpsId, vehicle_id: vehicleId } =
              review.vehicle.gps_vehicle[0];
            const response = await getGpsOdometer(gpsId, vehicleId);

            if (response.odometer) {
              setHasGpsOdometer(true);
              setInitialValues({
                date: "",
                odometer: parseFloat(response.odometer.toFixed(2)),
                observation: "",
                review: axleTires.map((axleTire) => ({
                  movement_tire_id:
                    axleTire.vehicle_tire.movement_tire.movement_tire_id,
                  pressure: "",
                })),
              });
            }
          } catch (error) {
            setNotification(error, true);
          } finally {
            setIsLoading(false);
          }
        }

        setGpsOdometer();
      }
    }
  }, [review]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        review: values.review.filter((tire) => tire.pressure !== ""),
      };
      const vehicleReviewFields = {
        odometer: values.odometer,
        date: values.date,
        review_type: "PRESSURE",
      };

      if (!isRequired) {
        vehicleReviewFields.observation = "Ajuste de presiones";
      }

      await addVehicleReview(vehicleId, vehicleReviewFields);
      if (isRequired) {
        await addTirePressureReview(vehicleId, fields);
        setNotification({
          message: t("_messages.updated.tire_pressure"),
        });
      } else {
        await onAction();
      }

      resetForm();
      setIsRequired(true);
      await getVehicleAxles(vehicleId);
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  async function onAction() {
    try {
      await adjustPressures(vehicleId);
      setNotification({
        message: t("_messages.pressure_adjusted"),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }
  function handlePressureAdjustment() {
    setNotification({
      message: t("_messages.pressure_adjustment"),
      textAction: t("_buttons.confirm"),
      onAction,
    });
  }

  return (
    <Page
      t={t}
      review={{
        ...review,
        odometer: `${review.odometer.toFixed(2)} km`,
        created_by: t("_labels.actioned_by", {
          person: `${review.created_by.name} ${review.created_by.last_name_1} ${
            review.created_by.last_name_2 || ""
          }`,
          date: moment(review.date).format("LL"),
          time: moment(review.date).format("h:mm a"),
        }),
      }}
      vehicleTypeAxle={vehicleTypeAxle}
      axleTires={axleTires}
      hasGpsOdometer={hasGpsOdometer}
      isLoading={isLoading}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      handlePressureAdjustment={handlePressureAdjustment}
      sendPressureSettingPolicy={sendPressureSettingPolicy}
      getRecommendedPressure={getRecommendedPressure}
      isRequired={isRequired}
      setIsRequired={setIsRequired}
    />
  );
}

TirePressureForm.propTypes = {
  axleTires: PropTypes.array.isRequired,
  sendPressureSettingPolicy: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  review: state.Vehicle.Review.vehicle_review,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  addTirePressureReview: actionAddTirePressureReview(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  adjustPressures: actionAdjustPressures(dispatch),
  getLastVehicleReview: actionGetLastVehicleReview(dispatch),
  clearLastVehicleReview: actionClearLastVehicleReview(dispatch),
  addVehicleReview: actionAddVehicleReview(dispatch),
  getGpsOdometer: actionGetGpsOdometer(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TirePressureForm);

function getRecommendedPressure(vehicleTypeAxle, axleTire) {
  const { axle_type: axleType } = vehicleTypeAxle.find(
    (axle) => axle.vehicle_type_axle_id === axleTire.vehicle_type_axle_id
  );

  if (
    axleType &&
    axleTire.vehicle_tire.vehicle?.vehicle_pressure_policy &&
    axleTire.vehicle_tire.vehicle.vehicle_pressure_policy.find(
      (policy) => policy.axle_type === axleType
    )
  ) {
    const pressure = axleTire.vehicle_tire.vehicle.vehicle_pressure_policy.find(
      (policy) => policy.axle_type === axleType
    ).recommended_pressure;
    return pressure;
  }

  if (
    axleTire.vehicle_tire.movement_tire.tire_cycle.variation
      .tire_model_variation_policy
  ) {
    return axleTire.vehicle_tire.movement_tire.tire_cycle.variation
      .tire_model_variation_policy.recommended_pressure;
  }

  return axleTire.vehicle_tire.movement_tire.tire_cycle.variation
    .recommended_pressure;
}
