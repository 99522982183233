import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionReturnFromRepair =
  (dispatch) =>
  async ({ fields, movementTireId, tireRepairId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `tire/return/${movementTireId}/repair/${tireRepairId}`,
        data: fields,
        dataWithFiles: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
