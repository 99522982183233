import components from "./components";
import pages from "./pages";
import api from "./api";
import alerts from "./alerts";
import messages from "./messages";
import errors from "./errors";
import general from "./general";
import labels from "./labels";
import permits from "./permits";
import buttons from "./buttons";
import titles from "./titles";
import damage from "./damage";
import retirement from "./retirement";
import alert from "./alert";

export default {
  components,
  pages,
  api,
  alerts,
  messages,
  errors,
  general,
  labels,
  permits,
  buttons,
  titles,
  damage,
  retirement,
  alert,
};
