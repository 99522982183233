import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

export function Page({
  t,
  values,
  commissionedDrivers,
  handleSubmit,
  isSubmitting,
  isValid,
  setFieldValue,
  onClose,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        id="commissioned_driver_id"
        name="commissioned_driver_id"
        autoHighlight
        blurOnSelect
        component={Autocomplete}
        options={commissionedDrivers}
        getOptionLabel={(option) => option.name}
        onChange={(_, value) => setFieldValue("commissioned_driver_id", value)}
        value={values.commissioned_driver_id}
        noOptionsText={t("_labels.autocomplete.no_options")}
        getOptionSelected={(option, value) => {
          return (
            !!value &&
            option.commissioned_driver_id == value.commissioned_driver_id
          );
        }}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={t("_labels.driver.singular")}
            variant="filled"
          />
        )}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.link")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object,
  commissionedDrivers: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
