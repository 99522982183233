export default {
  title: "New vehicle type axle",
  update_title: "Update axle",
  form: {
    position: {
      default: "Select a position",
    },
    tire_quantity: {
      default: "Select a tire quantity",
    },
    permits: {
      create: {
        vehicle_type_axle: "No permissions to create axles of a vehicle type",
      },
      update: {
        vehicle_type_axle: "No permissions to update axles of a vehicle type",
      },
    },
  },
  messages: {
    vehicle_type_axles_created:
      "Done, the vehicle type axles have been successfully created",
    vehicle_type_axle_updated:
      "Done, the vehicle type axle has been successfully updated",
  },
};
