import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Typography from "@material-ui/core/Typography";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ApartmentIcon from "@material-ui/icons/Apartment";
import StraightenIcon from "@material-ui/icons/Straighten";
import StreetviewIcon from "@material-ui/icons/Streetview";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import HomeIcon from "@material-ui/icons/Home";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import TrafficRoundedIcon from "@material-ui/icons/TrafficRounded";
import ErrorIcon from "@material-ui/icons/Error";
import BuildIcon from "@material-ui/icons/Build";
import SpeedIcon from "@material-ui/icons/Speed";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DescriptionIcon from "@material-ui/icons/Description";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import TimelineIcon from "@material-ui/icons/Timeline";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import MemoryIcon from "@material-ui/icons/Memory";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";

import RetirementCauseIcon from "components/atoms/general/RetirementCauseIcon";
import MountIcon from "components/atoms/general/MountIcon";
import TireIcon from "components/atoms/general/TireIcon";
import TireRevitalizedIcon from "components/atoms/general/TireRevitalizedIcon";
import WarningIcon from "@material-ui/icons/Warning";
import HelpIcon from "@material-ui/icons/Help";

import {
  LIST_ASSOCIATION,
  LIST_BLOCK,
  LIST_COMPANY,
  LIST_CONCEPT,
  LIST_DAMAGE,
  LIST_DIVISION,
  LIST_DRIVER,
  LIST_GPS,
  LIST_PROVIDER,
  LIST_RETIREMENT_CAUSE,
  LIST_REVITALIZED_TIRE_MODEL,
  LIST_RFID,
  LIST_SUBSIDIARY,
  LIST_TIRE,
  LIST_USER,
  LIST_VEHICLE,
  LIST_WAREHOUSE,
  LIST_WEAR,
  SHOW_DYNAMIC_COST_REPORT,
  SHOW_KM_PROJECTION_REPORT,
  SHOW_LAST_MOVEMENT_REPORT,
  SHOW_PERFORMANCE_COST,
  SHOW_PURCHASE_REPORT,
  SHOW_RENEWABILITY_INDEX,
  SHOW_REVIEW_PERFORMANCE_REPORT,
  SHOW_TIRE_DAMAGE_WEAR_REPORT,
  SHOW_TIRE_MOUNT_REPORT,
  SHOW_TIRE_PILE_REPORT,
  SHOW_TIRE_STATISTICS,
  SHOW_TIRE_SUMMARY,
  SHOW_TIRE_WAREHOUSE_REPORT,
  SHOW_USER_REVIEW_REPORT,
  LIST_CHECK_LIST,
  SHOW_VEHICLE,
  LIST_TPGPS,
} from "store/actions/account/permits";

import Button from "components/molecules/general/Button";
import ListItem from "components/organisms/general/ListItem";
import WorkArea from "components/organisms/account/WorkArea";
import UpdateProfileForm from "components/organisms/account/UpdateProfileForm";

import css from "./index.module.scss";
import { getFavoriteIcon } from "utils/favorites";
import NotificationForm from "components/organisms/notification/NotificationForm";

const useStyles = makeStyles({
  paper: {
    overflowY: "hidden",
    maxWidth: "305px",
  },
});

function Page({
  t,
  open,
  openVehiclesSubmenu,
  openAdminSubmenu,
  openAreasSubmenu,
  openControlSubmenu,
  openReportsSubmenu,
  openFavoritesSubmenu,
  openSemaphoreSubmenu,
  openPileMenu,
  favorites,
  policy,
  email,
  permits,
  onClose,
  fullName,
  handleLogout,
  showProfileForm,
  handleProfileForm,
  handleToggleVehiclesSubmenu,
  handleToggleAdminSubmenu,
  handleToggleAreasSubmenu,
  handleToggleControlSubmenu,
  handleToggleReportsSubmenu,
  handleToggleFavoritesSubmenu,
  handleToggleSemaphoreSubmenu,
  togglePileMenu,
  openSummaryMenu,
  toggleSummaryMenu,
  openReviewPerformanceMenu,
  toggleReviewPerformanceMenu,
  openNotificationForm,
  handleOpenNotificationForm,
  handleCloseNotificationForm,
}) {
  const classes = useStyles();
  return (
    <>
      <Drawer open={open} onClose={onClose} classes={classes}>
        <div className={classnames(css.box_profile)}>
          <div className={classnames(css.profile_info)}>
            <Typography noWrap variant="h6">
              {fullName}
            </Typography>
            <Typography noWrap variant="body1" color="textSecondary">
              {email}
            </Typography>
          </div>
          <Button
            onClick={() => handleProfileForm(true)}
            variant="icon"
            className={classnames(css.ml8)}
          >
            <EditIcon />
          </Button>
        </div>
        <div className={classnames(css.box)}>
          <div className={classnames(css.box__items)}>
            <List disablePadding>
              <ListItem
                name={t("_general.home")}
                to="/review"
                classNameText={classnames(css.bold)}
              >
                <HomeIcon />
              </ListItem>

              <ListItem
                name={t("_general.tutorials")}
                to="/tutorials"
                classNameText={classnames(css.bold)}
              >
                <HelpIcon />
              </ListItem>
              <ListItem
                name={t("_general.alerts_panel")}
                to="/alert/home"
                classNameText={classnames(css.bold)}
              >
                <ErrorIcon />
              </ListItem>

              <ListItem
                name={t("_titles.new.notification")}
                onClick={handleOpenNotificationForm}
                classNameText={classnames(css.bold)}
                isRootRequired
              >
                <NotificationsActiveRoundedIcon />
              </ListItem>
              <ListItem
                name={t("_general.notifications")}
                to="/notifications"
                classNameText={classnames(css.bold)}
              >
                <NotificationsRoundedIcon />
              </ListItem>

              {[
                SHOW_TIRE_STATISTICS,
                SHOW_PERFORMANCE_COST,
                SHOW_TIRE_SUMMARY,
                SHOW_TIRE_PILE_REPORT,
                SHOW_DYNAMIC_COST_REPORT,
                SHOW_RENEWABILITY_INDEX,
                SHOW_REVIEW_PERFORMANCE_REPORT,
                SHOW_TIRE_DAMAGE_WEAR_REPORT,
                SHOW_TIRE_MOUNT_REPORT,
                SHOW_TIRE_WAREHOUSE_REPORT,
                SHOW_USER_REVIEW_REPORT,
                SHOW_LAST_MOVEMENT_REPORT,
              ].some((permit) => permits.includes(permit)) &&
                favorites.length > 0 && (
                  <>
                    <ListItem
                      name={t("_general.favorite_reports")}
                      onClick={handleToggleFavoritesSubmenu}
                      endIcon={
                        openFavoritesSubmenu ? <ExpandLess /> : <ExpandMore />
                      }
                      classNameText={classnames(css.bold)}
                    >
                      <EqualizerIcon />
                    </ListItem>
                    <Collapse
                      in={openFavoritesSubmenu}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List disablePadding>
                        {favorites.map((favorite) => {
                          return (
                            <ListItem
                              key={
                                favorite.favorite_report.favorite_report_name
                              }
                              name={t(
                                `_labels.favorites.${favorite.favorite_report.favorite_report_name}`
                              )}
                              to={favorite.favorite_report.favorite_report_url}
                              requiredPermits={[]}
                              classNameIcon={classnames(css.pl16)}
                              classNameText={classnames(css.pl8)}
                              favoriteName={
                                favorite.favorite_report.favorite_report_name
                              }
                            >
                              {getFavoriteIcon(
                                favorite.favorite_report.favorite_report_name
                              )}
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                )}

              {[
                SHOW_TIRE_STATISTICS,
                SHOW_PERFORMANCE_COST,
                SHOW_TIRE_SUMMARY,
                SHOW_TIRE_PILE_REPORT,
                SHOW_DYNAMIC_COST_REPORT,
                SHOW_RENEWABILITY_INDEX,
                SHOW_REVIEW_PERFORMANCE_REPORT,
                SHOW_TIRE_DAMAGE_WEAR_REPORT,
                SHOW_TIRE_MOUNT_REPORT,
                SHOW_TIRE_WAREHOUSE_REPORT,
                SHOW_USER_REVIEW_REPORT,
                SHOW_LAST_MOVEMENT_REPORT,
              ].some((permit) => permits.includes(permit)) && (
                <>
                  <ListItem
                    name={t("_general.reports")}
                    onClick={handleToggleReportsSubmenu}
                    endIcon={
                      openReportsSubmenu ? <ExpandLess /> : <ExpandMore />
                    }
                    classNameText={classnames(css.bold)}
                  >
                    <EqualizerIcon />
                  </ListItem>
                  <Collapse
                    in={openReportsSubmenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      <ListItem
                        name={
                          t("_labels.location.options.pile")[0].toUpperCase() +
                          t("_labels.location.options.pile")
                            .slice(1)
                            .toLowerCase()
                        }
                        onClick={togglePileMenu}
                        endIcon={openPileMenu ? <ExpandLess /> : <ExpandMore />}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8, css.bold)}
                      >
                        <EqualizerIcon />
                      </ListItem>
                      <Collapse in={openPileMenu} timeout="auto" unmountOnExit>
                        <List disablePadding>
                          <ListItem
                            name={t("_titles.pile_report")}
                            to="/reports/pile"
                            requiredPermits={[SHOW_TIRE_PILE_REPORT]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_tire_pile_depth"}
                          >
                            <ViewAgendaIcon />
                          </ListItem>
                          <ListItem
                            name={t("_titles.km_pile_report")}
                            to="/reports/km/pile"
                            requiredPermits={[SHOW_TIRE_PILE_REPORT]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_tire_pile_travel"}
                          >
                            <SpeedIcon />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        name={t("_titles.semaphore_reports")}
                        onClick={handleToggleSemaphoreSubmenu}
                        endIcon={
                          openSemaphoreSubmenu ? <ExpandLess /> : <ExpandMore />
                        }
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8, css.bold)}
                      >
                        <TrafficRoundedIcon />
                      </ListItem>
                      <Collapse
                        in={openSemaphoreSubmenu}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding>
                          <ListItem
                            name={t("_titles.semaphore_report")}
                            to="/report/vehicle/semaphore"
                            requiredPermits={[]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_vehicle_semaphore"}
                          >
                            <TrafficRoundedIcon />
                          </ListItem>
                          <ListItem
                            name={t("_titles.fleet_semaphore_report")}
                            to="/report/vehicle/fleet_semaphore"
                            requiredPermits={[]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_vehicle_fleet_semaphore"}
                          >
                            <TrafficRoundedIcon />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        name={t("_titles.summary_report")}
                        onClick={toggleSummaryMenu}
                        endIcon={
                          openSummaryMenu ? <ExpandLess /> : <ExpandMore />
                        }
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8, css.bold)}
                      >
                        <EqualizerIcon />
                      </ListItem>
                      <Collapse
                        in={openSummaryMenu}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding>
                          <ListItem
                            name={t("_titles.historical_summary")}
                            to="/reports/summary"
                            requiredPermits={[SHOW_TIRE_SUMMARY]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_tire_summary"}
                          >
                            <ViewAgendaIcon />
                          </ListItem>
                          <ListItem
                            name={t("_titles.current_summary")}
                            to="/reports/current/summary"
                            requiredPermits={[SHOW_TIRE_SUMMARY]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_tire_summary_current"}
                          >
                            <ViewAgendaIcon />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        name={t("_titles.review_performance_report")}
                        onClick={toggleReviewPerformanceMenu}
                        endIcon={
                          openReviewPerformanceMenu ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        }
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8, css.bold)}
                      >
                        <EqualizerIcon />
                      </ListItem>
                      <Collapse
                        in={openReviewPerformanceMenu}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List disablePadding>
                          <ListItem
                            name={t("_titles.review_performance_report")}
                            to="/reports/review/performance"
                            requiredPermits={[SHOW_REVIEW_PERFORMANCE_REPORT]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_review_performance"}
                          >
                            <AutorenewIcon />
                          </ListItem>
                          <ListItem
                            name={t(
                              "_titles.review_performance_company_report"
                            )}
                            to="/reports/review/performance/company"
                            requiredPermits={[SHOW_REVIEW_PERFORMANCE_REPORT]}
                            classNameIcon={classnames(css.pl32)}
                            classNameText={classnames(css.pl16)}
                            favoriteName={"report_review_performance_company"}
                          >
                            <AutorenewIcon />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem
                        name={t("_titles.tire_stats_report")}
                        to="/reports/tire/stats"
                        requiredPermits={[SHOW_TIRE_STATISTICS]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_statistics"}
                      >
                        <TireIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.tire_stats_report_historic")}
                        to="/reports/tire/stats/historic"
                        requiredPermits={[SHOW_TIRE_STATISTICS]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_statistics_historic"}
                      >
                        <TireIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.best_performance_report")}
                        to="/reports/tire/best/performance"
                        requiredPermits={[SHOW_PERFORMANCE_COST]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_km_performance"}
                      >
                        <TrendingUpIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.completed_cycles_report")}
                        to="/reports/completed/cycles"
                        requiredPermits={[SHOW_PERFORMANCE_COST]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_completed_cycles"}
                      >
                        <DoneAllRoundedIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.scheduled_withdrawal_report")}
                        to="/reports/scheduled/withdrawal"
                        requiredPermits={[]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"scheduled_withdrawal"}
                      >
                        <WarningIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.km_projection_report")}
                        to="/reports/km/projection"
                        requiredPermits={[SHOW_KM_PROJECTION_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_km_projection"}
                      >
                        <TrendingUpIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.dynamic_cost_report")}
                        to="/reports/dynamic/cost"
                        requiredPermits={[SHOW_DYNAMIC_COST_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_km_dynamic_cost"}
                      >
                        <MonetizationOnOutlinedIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.renewability_index_report")}
                        to="/reports/renewability/index"
                        requiredPermits={[SHOW_RENEWABILITY_INDEX]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_renewability_index"}
                      >
                        <AutorenewIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.potential_savings_report")}
                        to="/reports/potential/savings"
                        requiredPermits={[SHOW_TIRE_STATISTICS]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_po_savings"}
                      >
                        <AutorenewIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.damages_wears")}
                        to="/report/damage/wear"
                        requiredPermits={[SHOW_TIRE_DAMAGE_WEAR_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_damage_wear"}
                      >
                        <ViewAgendaIcon />
                      </ListItem>
                      {/*
                      <ListItem
                        name={t("_titles.mount_report")}
                        to="/report/tires/mount"
                        requiredPermits={[SHOW_TIRE_MOUNT_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <MountIcon />
                      </ListItem>
                      */}
                      <ListItem
                        name={t("_titles.report_warehouse")}
                        to="/report/warehouse"
                        requiredPermits={[SHOW_TIRE_WAREHOUSE_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_warehouse"}
                      >
                        <ViewAgendaIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.user_review_report")}
                        to="/report/user/review"
                        requiredPermits={[SHOW_USER_REVIEW_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_user_review"}
                      >
                        <GroupAddIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.purchase_report")}
                        to="/report/purchase"
                        requiredPermits={[SHOW_PURCHASE_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_purchase"}
                      >
                        <ShoppingCartIcon />
                      </ListItem>
                      <ListItem
                        name={t("_labels.report_last_movement.label")}
                        to="/report/last/movement"
                        requiredPermits={[SHOW_LAST_MOVEMENT_REPORT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_last_movement"}
                      >
                        <TrendingDownIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.tire_revitalized_report")}
                        to="/report/tire/revitalized"
                        requiredPermits={[]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_revitalized"}
                      >
                        <TireRevitalizedIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.tire_repair_report")}
                        to="/report/tire/repair"
                        requiredPermits={[]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_repair"}
                      >
                        <BuildIcon />
                      </ListItem>

                      <ListItem
                        name={t("_titles.summary_mount_report")}
                        to="/report/mount/summary"
                        requiredPermits={[]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_tire_mount"}
                      >
                        <TireRevitalizedIcon />
                      </ListItem>
                      <ListItem
                        name={t("_titles.summary_warehouse_report")}
                        to="/report/warehouse/summary"
                        requiredPermits={[]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                        favoriteName={"report_summary_warehouse"}
                      >
                        <ViewAgendaIcon />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}

              <ListItem
                name={t("_general.disposal")}
                to="/disposal"
                requiredPermits={[SHOW_TIRE_PILE_REPORT]}
                classNameText={classnames(css.bold)}
              >
                <TimelineIcon />
              </ListItem>

              <ListItem
                name={t("_general.vehicles")}
                onClick={handleToggleVehiclesSubmenu}
                endIcon={openVehiclesSubmenu ? <ExpandLess /> : <ExpandMore />}
                classNameText={classnames(css.bold)}
              >
                <DriveEtaIcon />
              </ListItem>
              <Collapse in={openVehiclesSubmenu} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    name={t("tires")}
                    to="/tire"
                    requiredPermits={[LIST_TIRE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <TireIcon />
                  </ListItem>
                  <ListItem
                    name={t("vehicle")}
                    to="/vehicle"
                    requiredPermits={[LIST_VEHICLE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <DriveEtaIcon />
                  </ListItem>
                  <ListItem
                    name={t("vehicle_review")}
                    to="/vehicle/review"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                    requiredPermits={[SHOW_VEHICLE]}
                  >
                    <AssignmentIcon />
                  </ListItem>
                  <ListItem
                    name={t("mounting")}
                    to="/mount"
                    requiredPermits={[LIST_VEHICLE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <MountIcon />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                name={t("labels.admin")}
                onClick={handleToggleAdminSubmenu}
                endIcon={openAdminSubmenu ? <ExpandLess /> : <ExpandMore />}
                classNameText={classnames(css.bold)}
              >
                <DashboardIcon />
              </ListItem>
              <Collapse in={openAdminSubmenu} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    name={t("warehouse")}
                    to="/warehouse"
                    requiredPermits={[LIST_WAREHOUSE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <WidgetsIcon />
                  </ListItem>
                  <ListItem
                    name={t("driver")}
                    to="/driver"
                    requiredPermits={[LIST_DRIVER]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <StreetviewIcon />
                  </ListItem>

                  {Boolean(policy?.use_checklist) && (
                    <>
                      <ListItem
                        name={t("_general.associations")}
                        to="/association"
                        requiredPermits={[LIST_ASSOCIATION]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <AccountBalanceIcon />
                      </ListItem>
                      <ListItem
                        name={t("_general.commissioned_drivers")}
                        to="/association/driver"
                        requiredPermits={[LIST_DRIVER]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <StreetviewIcon />
                      </ListItem>

                      <ListItem
                        name={t("_general.concepts")}
                        to="/concept"
                        requiredPermits={[LIST_CONCEPT]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <DescriptionIcon />
                      </ListItem>
                      <ListItem
                        name={t("_general.blocks")}
                        to="/block"
                        requiredPermits={[LIST_BLOCK]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <ViewDayIcon />
                      </ListItem>
                      <ListItem
                        name={t("_general.checklist")}
                        to="/checklist"
                        requiredPermits={[LIST_CHECK_LIST]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <AssignmentTurnedInIcon />
                      </ListItem>
                      <ListItem
                        name={t("_general.commissioned_vehicles")}
                        to="/association/vehicle"
                        requiredPermits={[LIST_VEHICLE]}
                        classNameIcon={classnames(css.pl16)}
                        classNameText={classnames(css.pl8)}
                      >
                        <LocalShippingIcon />
                      </ListItem>
                    </>
                  )}
                  <ListItem
                    name={t("provider")}
                    to="/provider"
                    requiredPermits={[LIST_PROVIDER]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <PeopleOutlineIcon />
                  </ListItem>
                  <ListItem
                    name={t("user")}
                    to="/user"
                    requiredPermits={[LIST_USER]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <PeopleIcon />
                  </ListItem>
                  <ListItem
                    name={t("gps")}
                    to="/gps"
                    requiredPermits={[LIST_GPS]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <GpsFixedIcon />
                  </ListItem>

                  <ListItem
                    name={t("_general.rfid")}
                    to="/rfid"
                    requiredPermits={[LIST_RFID]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <GpsFixedIcon />
                  </ListItem>
                  {Boolean(policy?.use_tpms) && (
                    <ListItem
                      name={t("_general.tpms")}
                      to="/tpms"
                      requiredPermits={[LIST_TPGPS]}
                      classNameIcon={classnames(css.pl16)}
                      classNameText={classnames(css.pl8)}
                    >
                      <MemoryIcon />
                    </ListItem>
                  )}
                </List>
              </Collapse>

              <ListItem
                name={t("labels.areas")}
                onClick={handleToggleAreasSubmenu}
                endIcon={openAreasSubmenu ? <ExpandLess /> : <ExpandMore />}
                classNameText={classnames(css.bold)}
              >
                <HomeWorkIcon />
              </ListItem>
              <Collapse in={openAreasSubmenu} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    name={t("corporate")}
                    to="/corporate"
                    isRootRequired
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <AccountBalanceIcon />
                  </ListItem>
                  <ListItem
                    name={t("company")}
                    to="/company"
                    requiredPermits={[LIST_COMPANY]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <AccountBalanceIcon />
                  </ListItem>
                  <ListItem
                    name={t("subsidiary")}
                    to="/subsidiary"
                    requiredPermits={[LIST_SUBSIDIARY]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <AccountBalanceIcon />
                  </ListItem>
                  <ListItem
                    name={t("division")}
                    to="/division"
                    requiredPermits={[LIST_DIVISION]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <AccountBalanceIcon />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                name={t("labels.catalogs")}
                onClick={handleToggleControlSubmenu}
                endIcon={openControlSubmenu ? <ExpandLess /> : <ExpandMore />}
                classNameText={classnames(css.bold)}
              >
                <SettingsIcon />
              </ListItem>
              <Collapse in={openControlSubmenu} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem
                    name={t("vehicle_type")}
                    to="/vehicle/type"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <DirectionsCarIcon />
                  </ListItem>
                  <ListItem
                    name={t("brand")}
                    to="/brand"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <ApartmentIcon />
                  </ListItem>
                  <ListItem
                    name={t("tire_size")}
                    to="/tire/size"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <StraightenIcon />
                  </ListItem>
                  <ListItem
                    name={t("tire_model")}
                    to="/tire/model"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <TireIcon />
                  </ListItem>
                  <ListItem
                    name={t("revitalized_tire_model")}
                    to="/revitalized/tire/model"
                    requiredPermits={[LIST_REVITALIZED_TIRE_MODEL]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <TireIcon />
                  </ListItem>
                  <ListItem
                    name={t("alert")}
                    to="/alert"
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <ReportProblemIcon />
                  </ListItem>
                  <ListItem
                    name={t("wear_type")}
                    requiredPermits={[LIST_WEAR]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                    to="/wear"
                  >
                    <BrokenImageIcon />
                  </ListItem>
                  <ListItem
                    name={t("damage_type")}
                    to="/damage"
                    requiredPermits={[LIST_DAMAGE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <BrokenImageIcon />
                  </ListItem>

                  <ListItem
                    name={t("retirement_causes")}
                    to="/retirement/cause"
                    requiredPermits={[LIST_RETIREMENT_CAUSE]}
                    classNameIcon={classnames(css.pl16)}
                    classNameText={classnames(css.pl8)}
                  >
                    <RetirementCauseIcon />
                  </ListItem>
                </List>
              </Collapse>

              <ListItem
                name={t("_general.support")}
                onClick={() => {
                  const a = document.createElement("a");
                  const href =
                    "https://apps.setenal.mx/osticket/upload/login.php";
                  a.href = href;
                  a.target = "_blank";
                  a.rel = "noopener noreferrer";
                  a.click();
                  a.remove();
                  URL.revokeObjectURL(href);
                }}
                classNameText={classnames(css.bold)}
              >
                <ContactSupportIcon />
              </ListItem>

              <ListItem
                onClick={handleLogout}
                name={t("logout")}
                classNameText={classnames(css.bold)}
              >
                <ExitToAppIcon />
              </ListItem>
            </List>
          </div>
          <div>
            <div className={classnames(css.box__work_area)}>
              <WorkArea />
            </div>
          </div>
        </div>
      </Drawer>

      {showProfileForm && (
        <UpdateProfileForm
          open={showProfileForm}
          onClose={() => handleProfileForm(false)}
        />
      )}

      {openNotificationForm && (
        <NotificationForm
          open={openNotificationForm}
          onClose={handleCloseNotificationForm}
        />
      )}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  favorites: PropTypes.array.isRequired,
  policy: PropTypes.object,
  email: PropTypes.string.isRequired,
  permits: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  showProfileForm: PropTypes.bool.isRequired,
  handleProfileForm: PropTypes.func.isRequired,
  openVehiclesSubmenu: PropTypes.bool.isRequired,
  openAdminSubmenu: PropTypes.bool.isRequired,
  openAreasSubmenu: PropTypes.bool.isRequired,
  openControlSubmenu: PropTypes.bool.isRequired,
  openReportsSubmenu: PropTypes.bool.isRequired,
  openFavoritesSubmenu: PropTypes.bool.isRequired,
  openPileMenu: PropTypes.bool.isRequired,
  handleToggleVehiclesSubmenu: PropTypes.func.isRequired,
  handleToggleAdminSubmenu: PropTypes.func.isRequired,
  handleToggleAreasSubmenu: PropTypes.func.isRequired,
  handleToggleControlSubmenu: PropTypes.func.isRequired,
  handleToggleReportsSubmenu: PropTypes.func.isRequired,
  handleToggleFavoritesSubmenu: PropTypes.func.isRequired,
  togglePileMenu: PropTypes.func.isRequired,
  handleToggleSemaphoreSubmenu: PropTypes.func.isRequired,
  openSemaphoreSubmenu: PropTypes.bool.isRequired,
  openSummaryMenu: PropTypes.bool.isRequired,
  toggleSummaryMenu: PropTypes.func.isRequired,
  openReviewPerformanceMenu: PropTypes.bool.isRequired,
  toggleReviewPerformanceMenu: PropTypes.func.isRequired,
  openNotificationForm: PropTypes.bool,
  handleOpenNotificationForm: PropTypes.func,
  handleCloseNotificationForm: PropTypes.func,
};

export default Page;
