export default {
  disabled_model: "Disabled model",
  model_brand: "Model brand",
  buttons: {
    view_technical_sheet: "View technical sheet",
  },
  messages: {
    approve: "Are you sure to approve tire model?",
    disapprove: "Are you sure to disapprove tire model?",
    disable: "Are you sure to disable the model?",
    enable: "Are you sure to enable the model?",
  },
  permits: {
    change_approval_status: "No permissions to {action} tires models",
    change_status: "No permissions to {action} tire model",
  },
};
