import { SET_DIVISION_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetDivisionStatus =
  (dispatch) => async (divisionId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `division/${divisionId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_DIVISION_STATUS,
        payload: divisionId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
