import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDamages } from "store/actions/damage/getDamages";
import { actionGetWears } from "store/actions/wear/getWears";
import { actionAddTireDamage } from "store/actions/tire/damage/addTireDamage";
import { actionAddTireWear } from "store/actions/tire/wear/addTireWear";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";

import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function DamagesAndWearForm({ tire, ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    open,
    onClose,
    vehicleTire,
    setNotification,
    getDamages,
    getWears,
    isDamages,
    addTireDamage,
    addTireWear,
    getVehicleAxles,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      damages: [
        {
          damage_id: null,
          comment: "",
          image: null,
        },
      ],
      wears: [
        {
          wear_id: null,
          comment: "",
          image: null,
        },
      ],
    },
  });

  const [damages, setDamages] = useState([]);
  const [wears, setWears] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (isDamages) {
          data = await getDamages(
            {
              scope: "damage_id,name,status,frequency",
            },
            false
          );

          setDamages(data.sort((a, b) => b["frequency"] - a["frequency"]));
        } else {
          data = await getWears(
            {
              scope: "wear_id,name,status,frequency",
            },
            false
          );

          setWears(data.sort((a, b) => b["frequency"] - a["frequency"]));
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (isDamages) {
        setInitialValues({
          damages: [
            {
              damage_id: null,
              comment: "",
              image: null,
            },
          ],
        });
      }
    } else {
      setInitialValues({
        wears: [
          {
            wear_id: null,
            comment: "",
            image: null,
          },
        ],
      });
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        ...values,
      };

      const movementTireId = tire
        ? tire.cycle.movement_tire.movement_tire_id
        : vehicleTire.cycle.movement_tire.movement_tire_id;

      if (isDamages) {
        fields.damages = values.damages.map((damage) => {
          return !!damage.image
            ? { ...damage, damage_id: damage.damage_id.damage_id }
            : {
                damage_id: damage.damage_id.damage_id,
                comment: damage.comment,
              };
        });
        await addTireDamage(movementTireId, fields, true);
      } else {
        fields.wears = values.wears.map((wear) => {
          return !!wear.image
            ? { ...wear, wear_id: wear.wear_id.wear_id }
            : { wear_id: wear.wear_id.wear_id, comment: wear.comment };
        });
        await addTireWear(movementTireId, fields, true);
      }

      setNotification({
        message: isDamages
          ? t("_messages.created.tire_damage")
          : t("_messages.created.tire_wear"),
      });
      setSubmitting(false);
      onClose();

      if (vehicleId) {
        getVehicleAxles(vehicleId);
      }
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicleTire={tire && Object.keys(tire).length > 1 ? tire : vehicleTire}
      damages={damages}
      wears={wears}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

DamagesAndWearForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDamages: PropTypes.bool.isRequired,
  tire: PropTypes.object,
};

DamagesAndWearForm.defaultProps = {};

const mapStateToProps = (state) => ({
  vehicleTire: state.Vehicle.Tire.vehicle_tire,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDamages: actionGetDamages(dispatch),
  getWears: actionGetWears(dispatch),
  addTireDamage: actionAddTireDamage(dispatch),
  addTireWear: actionAddTireWear(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DamagesAndWearForm);
