import React from "react";
import PropTypes from "prop-types";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "../index.css";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import ContainerTemplate from "components/templates/general/Container";
// import Button from "components/molecules/general/Button";
// import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const currencyFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const numberOfTires = (() => {
  const array = [];
  for (let index = 0; index < 20; index++) {
    array.push(index + 1);
  }
  return array;
})();

function Page({
  t,
  semaphoreData,
  summaryDepthData,
  summaryDepthSizeData,
  potentialSavingsData,
}) {
  if (
    Object.keys(semaphoreData).length > 0 &&
    Object.keys(summaryDepthData).length > 0 &&
    Object.keys(summaryDepthSizeData).length > 0 &&
    Object.keys(potentialSavingsData).length > 0
  ) {
    return (
      <>
        <div className="row justify-content-md-end">
          <div className="col-md-12">
            <div
              className="table-responsive"
              style={{
                maxHeight: "65vh",
              }}
            >
              <table className="table table-hover text-center sFS12 semaphore">
                <thead className="sbgazul">
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    >
                      {t("_labels.vehicle.singular")}
                    </td>
                    <td
                      colSpan={20}
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    >
                      {t("_labels.vehicle_type_axle_position.label")}{" "}
                    </td>
                    <td
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    >
                      {t("_labels.type")}
                    </td>
                    <td
                      className="text-left"
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    >
                      {t("_labels.size")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        top: "43px",
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    />
                    {numberOfTires.map((number) => (
                      <td
                        key={number}
                        style={{
                          position: "sticky",
                          top: "43px",
                          zIndex: 5,
                          backgroundColor: "#4d7aff",
                          color: "white",
                        }}
                      >
                        {number}
                      </td>
                    ))}
                    <td
                      style={{
                        position: "sticky",
                        top: "43px",
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    />
                    <td
                      style={{
                        position: "sticky",
                        top: "43px",
                        zIndex: 5,
                        backgroundColor: "#4d7aff",
                        color: "white",
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(semaphoreData).map((structure) =>
                    Object.keys(semaphoreData[structure]).map((vehicle) =>
                      Object.keys(semaphoreData[structure][vehicle])
                        .sort()
                        .map((size) => (
                          <tr key={`${structure}_${vehicle}_${size}`}>
                            <td>{vehicle}</td>
                            {numberOfTires.map((position) =>
                              semaphoreData[structure][vehicle][size][
                                position
                              ] ? (
                                <td
                                  key={position}
                                  className={`${
                                    semaphoreData[structure][vehicle][size][
                                      position
                                    ]["depth_condition"] === "GOOD CONDITION"
                                      ? "sbgverde"
                                      : semaphoreData[structure][vehicle][size][
                                          position
                                        ]["depth_condition"] ===
                                        "SCHEDULED WITHDRAWAL"
                                      ? "sbgamarillo"
                                      : "sbgred"
                                  }`}
                                >
                                  {formatter.format(
                                    semaphoreData[structure][vehicle][size][
                                      position
                                    ]["depth"]
                                  )}
                                </td>
                              ) : (
                                <td key={position}></td>
                              )
                            )}
                            <td>
                              {Object.values(
                                semaphoreData[structure][vehicle][size]
                              ).length > 0
                                ? Object.values(
                                    semaphoreData[structure][vehicle][size]
                                  )[0]["type"]
                                : structure}
                            </td>
                            <td className="text-left">{size}</td>
                          </tr>
                        ))
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row pt-1 pb-1">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-hover text-center sFS12 ">
                <thead>
                  <tr>
                    <td
                      colSpan={17}
                      className="sbngris"
                      style={{
                        width: "60%",
                      }}
                    ></td>
                    <td colSpan={2} className="sbgazul">
                      {t("_labels.originals")}
                    </td>
                    <td colSpan={2} className="sbgazul">
                      {t("_labels.vitalized")}
                    </td>
                    <td colSpan={2} className="sbgazul">
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={17}>{t("_labels.tires_good_condition")}</td>
                    <td colSpan={2} className="sbgverde">
                      {formatter.format(
                        summaryDepthData["GOOD CONDITION"]?.["ORIGINAL"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgverde">
                      {formatter.format(
                        summaryDepthData["GOOD CONDITION"]?.["RETREAD"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgverde">
                      {formatter.format(
                        (summaryDepthData["GOOD CONDITION"]?.["ORIGINAL"]
                          ?.elements ?? 0) +
                          (summaryDepthData["GOOD CONDITION"]?.["RETREAD"]
                            ?.elements ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={17}>{t("_labels.tires_to_be_renewed")}</td>
                    <td colSpan={2} className="sbgamarillo">
                      {formatter.format(
                        summaryDepthData["SCHEDULED WITHDRAWAL"]?.["ORIGINAL"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgamarillo">
                      {formatter.format(
                        summaryDepthData["SCHEDULED WITHDRAWAL"]?.["RETREAD"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgamarillo">
                      {formatter.format(
                        (summaryDepthData["SCHEDULED WITHDRAWAL"]?.["ORIGINAL"]
                          ?.elements ?? 0) +
                          (summaryDepthData["SCHEDULED WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={17}>{t("_labels.tires_to_renew")}</td>
                    <td colSpan={2} className="sbgred">
                      {formatter.format(
                        summaryDepthData["CRITICAL WITHDRAWAL"]?.["ORIGINAL"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgred">
                      {formatter.format(
                        summaryDepthData["CRITICAL WITHDRAWAL"]?.["RETREAD"]
                          ?.elements ?? 0
                      )}
                    </td>
                    <td colSpan={2} className="sbgred">
                      {formatter.format(
                        (summaryDepthData["CRITICAL WITHDRAWAL"]?.["ORIGINAL"]
                          ?.elements ?? 0) +
                          (summaryDepthData["CRITICAL WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={17}>{t("_labels.total_tires_checked")}</td>
                    <td colSpan={2}>
                      {formatter.format(
                        (summaryDepthData["GOOD CONDITION"]?.["ORIGINAL"]
                          ?.elements ?? 0) +
                          (summaryDepthData["SCHEDULED WITHDRAWAL"]?.[
                            "ORIGINAL"
                          ]?.elements ?? 0) +
                          (summaryDepthData["CRITICAL WITHDRAWAL"]?.["ORIGINAL"]
                            ?.elements ?? 0)
                      )}
                    </td>
                    <td colSpan={2}>
                      {formatter.format(
                        (summaryDepthData["GOOD CONDITION"]?.["RETREAD"]
                          ?.elements ?? 0) +
                          (summaryDepthData["SCHEDULED WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0) +
                          (summaryDepthData["CRITICAL WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0)
                      )}
                    </td>
                    <td colSpan={2}>
                      {formatter.format(
                        (summaryDepthData["GOOD CONDITION"]?.["ORIGINAL"]
                          ?.elements ?? 0) +
                          (summaryDepthData["GOOD CONDITION"]?.["RETREAD"]
                            ?.elements ?? 0) +
                          (summaryDepthData["SCHEDULED WITHDRAWAL"]?.[
                            "ORIGINAL"
                          ]?.elements ?? 0) +
                          (summaryDepthData["SCHEDULED WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0) +
                          (summaryDepthData["CRITICAL WITHDRAWAL"]?.["ORIGINAL"]
                            ?.elements ?? 0) +
                          (summaryDepthData["CRITICAL WITHDRAWAL"]?.["RETREAD"]
                            ?.elements ?? 0)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {Object.keys(summaryDepthSizeData).map((size) => (
          <div className="row" key={size}>
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-hover text-center sFS12 ">
                  <thead>
                    <tr>
                      <th
                        colSpan={7}
                        className="sbngris"
                        style={{
                          width: "40%",
                        }}
                      >
                        {t("_labels.size")}: {size}
                      </th>
                      <td colSpan={4} className="sbgazul">
                        {t("_labels.quantity_originals")}
                      </td>
                      <td colSpan={4} className="sbgazul">
                        {t("_labels.quantity_revitalized")}
                      </td>
                      <td colSpan={4} className="sbgazul">
                        {t("_labels.total_number_tires")}
                      </td>
                      <td colSpan={4} className="sbgazul">
                        Total
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        {t("_labels.investment_original_tires")}
                      </td>
                      <td colSpan={4}>
                        {formatter.format(
                          (summaryDepthSizeData[size]["ORIGINAL_USED"]
                            ?.elements ?? 0) +
                            (summaryDepthSizeData[size]["ORIGINAL_NEW"]
                              ?.elements ?? 0)
                        )}
                      </td>
                      <td colSpan={4}>
                        {formatter.format(
                          (summaryDepthSizeData[size]["RETREAD_NEW"]
                            ?.elements ?? 0) +
                            (summaryDepthSizeData[size]["RETREAD_USED"]
                              ?.elements ?? 0)
                        )}
                      </td>
                      <td colSpan={4}>
                        {formatter.format(
                          summaryDepthSizeData[size]?.elements ?? 0
                        )}
                      </td>
                      <td colSpan={4}>
                        $
                        {currencyFormatter.format(
                          summaryDepthSizeData[size]?.price ?? 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>
                        {t("_labels.investment_renewed_tires")}
                      </td>
                      <td colSpan={4}>0</td>
                      <td colSpan={4}>
                        {formatter.format(
                          summaryDepthSizeData[size]?.elements ?? 0
                        )}
                      </td>
                      <td colSpan={4}>
                        {formatter.format(
                          summaryDepthSizeData[size]?.elements ?? 0
                        )}
                      </td>
                      <td colSpan={4}>
                        $
                        {currencyFormatter.format(
                          summaryDepthSizeData[size]?.price_revitalized ?? 0
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7}>{t("_labels.wear_loss")}</td>
                      <td colSpan={4}></td>
                      <td colSpan={4}></td>
                      <td colSpan={4}></td>
                      <td colSpan={4}>
                        $
                        {currencyFormatter.format(
                          summaryDepthSizeData[size]?.savings ?? 0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
        <div className="row pt-2" id={"purchases"}>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-hover text-center sFS12 ">
                <thead>
                  <tr>
                    <th colSpan={23} className="sbgazul">
                      {t("_labels.potential_savings")}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={9} className="sbngris"></th>
                    <th colSpan={7} className="sbngris">
                      {t("_labels.quantity")}
                    </th>
                    <th colSpan={7} className="sbngris">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={9}>
                      {t("_labels.investment_original_tires")}
                    </td>
                    <td colSpan={7}>
                      {formatter.format(potentialSavingsData.original_elements)}
                    </td>
                    <td colSpan={7}>
                      ${currencyFormatter.format(potentialSavingsData.price)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>{t("_labels.investment_renewed_tires")}</td>
                    <td colSpan={7}>
                      {formatter.format(
                        potentialSavingsData.revitalized_elements
                      )}
                    </td>
                    <td colSpan={7}>
                      $
                      {currencyFormatter.format(
                        potentialSavingsData.price_revitalized
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}></td>
                    <td colSpan={7}></td>
                    <td colSpan={7}>
                      ${currencyFormatter.format(potentialSavingsData.savings)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 sFS14">
            {t("_labels.relative_cost_text")}
          </div>
          <div className="col-md-12 text-right sFS12 pdb10 pdt10">
            {t("_labels.copyright")}
          </div>
        </div>
      </>
    );
  }

  return null;
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  semaphoreData: PropTypes.object.isRequired,
  summaryDepthData: PropTypes.object.isRequired,
  summaryDepthSizeData: PropTypes.object.isRequired,
  potentialSavingsData: PropTypes.object.isRequired,
};

export default Page;
