import { actionShowProgress } from "../general/progress";
import { GET_CHECKLIST } from "store/actions";
import { http } from "store/actions/http";

export const actionGetChecklist = (dispatch) => async (checklistId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `checklist/${checklistId}`,
    });

    dispatch({
      type: GET_CHECKLIST,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
