import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  warehouse,
  warehouses,
  subsidiaries,
  countries,
  states,
  provinces,
  createWarehousePermit,
  updateWarehousePermit,
  deleteWarehousePermit,
  filter,
  t,
  getWarehouse,
  addWarehouse,
  updateWarehouse,
  clearWarehouseFilters,
  setWarehouseFilters,
  deleteWarehouse,
  setWarehouseStatus,
  getWarehouses,
  clearWarehouses,
  handleChangeCountry,
  handleChangeState,
}) {
  return (
    <Home
      item={warehouse}
      name="warehouse"
      filter={filter}
      createTooltipPermit={
        !createWarehousePermit ? t("_permits.create.warehouse") : ""
      }
      currentPage={warehouses.current_page}
      deleteConfirmationMessage={t("_messages.delete.warehouse")}
      disableConfirmationMessage={t(
        "_messages.change_status.warehouse.disable"
      )}
      enableConfirmationMessage={t("_messages.change_status.warehouse.enable")}
      rows={warehouses.data.map((warehouse) => {
        return {
          id: warehouse.warehouse_id,
          key: warehouse.warehouse_id,
          status: {
            status: warehouse.status,
            tooltipPermit: !updateWarehousePermit
              ? t("_permits.update.warehouse")
              : "",
          },
          subsidiary: warehouse.subsidiary.name,
          name: warehouse.name,
          edit: {
            tooltipPermit: !updateWarehousePermit
              ? t("_permits.update.warehouse")
              : "",
          },
          delete: {
            tooltipPermit: !deleteWarehousePermit
              ? t("_permits.delete.warehouse")
              : "",
          },
        };
      })}
      title={t("_general.warehouses")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "actions", label: "" },
      ]}
      lastPage={warehouses.last_page}
      noItemsText={t("_general.no_warehouses")}
      initialDefaultValues={{
        name: "",
        status: "",
        subsidiary_id: "",
        direction_1: "",
        external_number: "",
        internal_number: "",
        direction_2: "",
        postal_code: "",
        province: "",
        state: "",
        country: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
        {
          name: "direction_1",
          required: true,
          label: t("_labels.direction1"),
        },
        {
          name: "external_number",
          required: true,
          label: t("_labels.external_number"),
        },
        {
          name: "internal_number",
          required: false,
          label: t("_labels.internal_number"),
        },
        {
          name: "direction_2",
          required: true,
          label: t("_labels.direction2"),
        },
        {
          name: "postal_code",
          required: true,
          label: t("_labels.postal_code"),
        },
        {
          name: "country",
          required: true,
          component: "select",
          label: t("_labels.country"),
          selectItems: countries.length
            ? countries.map((country) => ({
                value: country,
                name: country,
              }))
            : [],
          defaultText: t("_labels.country_field.value"),
          handleChange: handleChangeCountry,
        },
        {
          name: "state",
          required: true,
          component: "select",
          label: t("_labels.state"),
          selectItems: states.length
            ? states.map((state) => ({
                value: state,
                name: state,
              }))
            : [],
          defaultText: t("_labels.state_field.value"),
          handleChange: handleChangeState,
        },
        {
          id: "province",
          name: "province",
          label: t("_labels.province"),
          required: true,
          component: "autocomplete",
          options: provinces.length ? provinces : [],
        },
      ]}
      createFormTitle={t("_titles.new.warehouse")}
      updateFormTitle={t("_titles.update.warehouse")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.warehouse"),
        },
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
      }}
      createdMessage={t("_messages.created.warehouse")}
      updatedMessage={t("_messages.updated.warehouse")}
      getItemAction={getWarehouse}
      setFiltersAction={setWarehouseFilters}
      clearFiltersAction={clearWarehouseFilters}
      deleteAction={deleteWarehouse}
      setStatusAction={setWarehouseStatus}
      getItemsAction={getWarehouses}
      clearItemsAction={clearWarehouses}
      addAction={addWarehouse}
      updateAction={updateWarehouse}
    />
  );
}

Page.propTypes = {
  warehouse: PropTypes.object.isRequired,
  warehouses: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  createWarehousePermit: PropTypes.bool.isRequired,
  updateWarehousePermit: PropTypes.bool.isRequired,
  deleteWarehousePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getWarehouse: PropTypes.func.isRequired,
  addWarehouse: PropTypes.func.isRequired,
  updateWarehouse: PropTypes.func.isRequired,
  clearWarehouseFilters: PropTypes.func.isRequired,
  setWarehouseFilters: PropTypes.func.isRequired,
  deleteWarehouse: PropTypes.func.isRequired,
  setWarehouseStatus: PropTypes.func.isRequired,
  getWarehouses: PropTypes.func.isRequired,
  clearWarehouses: PropTypes.func.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
};

export default Page;
