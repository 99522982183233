import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGenerateTirePileExcel } from "store/actions/reports/pile/generateTirePileExcel";
import { actionGetKmPileReport } from "store/actions/reports/km_pile/getKmPileReport";
import { actionGetReportKmPileHistory } from "store/actions/reports/km_pile/getReportKmPileHistory";
import { actionClearKmPileFilters } from "store/actions/reports/km_pile/clearKmPileFilters";
import { actionSetKmPileFilters } from "store/actions/reports/km_pile/setKmPileFilters";
import { actionSetSelectedKmPileFilters } from "store/actions/reports/km_pile/setSelectedKmPileFilters";

import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";

function KmPile({ ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    selectedFilter,
    setNotification,
    getTirePileReport,
    getReportPileHistory,
    clearPileFilters,
    setPileFilters,
    setSelectedFilters,
    generateTirePileExcel,
  } = rest;
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState("General");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [openCauseDialog, setOpenCauseDialog] = useState(false);
  const [params, setParams] = useState({});
  const [historic, setHistoric] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);

  const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
  const policy = subsidiaries[0]?.subsidiary.policy;
  const policyNumberCycle = policy?.number_cycle ?? 0;

  let numberCycle = "G";
  switch (currentTab) {
    case "General":
      numberCycle = "G";
      break;
    case "Revitalized":
      numberCycle = "R";
      break;
    case "Original":
      numberCycle = "0";
      break;
    case "1":
      numberCycle = "1";
      break;
    case "2":
      numberCycle = "2";
      break;
    case "3":
      numberCycle = "3";
      break;
    case "4":
      numberCycle = "4";
      break;
    default:
      break;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTirePileReport({
          ...filter,
          number_cycle: numberCycle,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter, currentTab]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getReportPileHistory({
          ...filter,
        });
        setHistoric(res);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    };
    setPileFilters(filter);
    setSelectedFilters(selectedFilters);
    return () => {
      clearPileFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleViewTires({ ...params }) {
    setParams({ ...filter, ...params, number_cycle: numberCycle });
    setOpenTireDialog(true);
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setParams({});
  }

  async function handleGenerateTirePileExcel() {
    try {
      await generateTirePileExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleViewCauses({ ...params }) {
    setParams({ ...filter, ...params, number_cycle: numberCycle });
    setOpenCauseDialog(true);
  }

  function handleCloseCauseDialog() {
    setOpenCauseDialog(false);
    setParams({});
  }

  function handleOpenHistory() {
    setOpenHistory(true);
  }

  function handleCloseHistory() {
    setOpenHistory(false);
  }

  return (
    <Page
      t={t}
      filter={filter}
      historic={getHistoricChart(t, historic)}
      selectedFilter={selectedFilter}
      generalData={getGeneralData(data)}
      originalData={getOriginalData(data)}
      revitalizedData={getRevitalizedData(data)}
      firstCycleData={getFirstCycleData(data)}
      secondCycleData={getSecondCycleData(data)}
      thirdCycleData={getThirdCycleData(data)}
      fourthCycleData={getFourthCycleData(data)}
      policyNumberCycle={policyNumberCycle}
      openFilter={openFilter}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      corporates={getCorporates(data)}
      openTireDialog={openTireDialog}
      params={params}
      handleViewTires={handleViewTires}
      handleCloseTireDialog={handleCloseTireDialog}
      handleGenerateTirePileExcel={handleGenerateTirePileExcel}
      openHistory={openHistory}
      handleOpenHistory={handleOpenHistory}
      handleCloseHistory={handleCloseHistory}
      openCauseDialog={openCauseDialog}
      handleViewCauses={handleViewCauses}
      handleCloseCauseDialog={handleCloseCauseDialog}
    />
  );
}
KmPile.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.KmPile.filter,
  selectedFilter: state.Reports.KmPile.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTirePileReport: actionGetKmPileReport(dispatch),
  clearPileFilters: actionClearKmPileFilters(dispatch),
  setPileFilters: actionSetKmPileFilters(dispatch),
  setSelectedFilters: actionSetSelectedKmPileFilters(dispatch),
  generateTirePileExcel: actionGenerateTirePileExcel(dispatch),
  getReportPileHistory: actionGetReportKmPileHistory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KmPile);

function getHistoricChart(t, data) {
  return {
    datasets: [
      {
        label: t("_labels.tire_quantity"),
        data: data.map((el) => ({
          ...el,
          fecha: moment(el.fecha).format("YYYY-MM"),
        })),
        fill: false,
        pointWidth: 2,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgb(75, 192, 192)",
        // tension: 0.1
      },
    ],
  };
}
function getGeneralData(data) {
  if (data.general && Object.keys(data.general).length > 0) {
    return data.general;
  }

  return {};
}
function getOriginalData(data) {
  if (data.origin && Object.keys(data.origin).length > 0) {
    return data.origin;
  }

  return {};
}
function getRevitalizedData(data) {
  if (data.revitalized && Object.keys(data.revitalized).length > 0) {
    return data.revitalized;
  }

  return {};
}
function getFirstCycleData(data) {
  if (data[1] && Object.keys(data[1]).length > 0) {
    return data[1];
  }

  return {};
}
function getSecondCycleData(data) {
  if (data[2] && Object.keys(data[2]).length > 0) {
    return data[2];
  }

  return {};
}
function getThirdCycleData(data) {
  if (data[3] && Object.keys(data[3]).length > 0) {
    return data[3];
  }

  return {};
}
function getFourthCycleData(data) {
  if (data[4] && Object.keys(data[4]).length > 0) {
    return data[4];
  }

  return {};
}

function getCorporates(data) {
  let max = data.general ? Object.keys(data.general).length : 0;

  if (data.origin && max < Object.keys(data.origin).length) {
    return Object.keys(data.origin);
  }
  if (data.revitalized && max < Object.keys(data.revitalized).length) {
    return Object.keys(data.revitalized);
  }
  if (data[1] && max < Object.keys(data[1]).length) {
    return Object.keys(data[1]);
  }
  if (data[2] && max < Object.keys(data[2]).length) {
    return Object.keys(data[2]);
  }
  if (data[3] && max < Object.keys(data[3]).length) {
    return Object.keys(data[3]);
  }
  if (data[4] && max < Object.keys(data[4]).length) {
    return Object.keys(data[4]);
  }
  return data.general ? Object.keys(data.general) : [];
}
