import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import ContainerTemplate from "components/templates/general/Container";
import ExportableChart from "./ExportableChart";

import ExportPdfButton from "./ExportPdfButton";
import FilterDisposalForm from "components/organisms/disposal/FilterDisposalForm";
import css from "./index.module.scss";

function Page({
  t,
  monthly,
  data,
  toggleMonthly,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  filter,
  selectedFilter,
}) {
  return (
    <ContainerTemplate
      title={t("_general.disposal")}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      <div
        className={classnames({
          ["container-fluid mt-3 px-0"]: true,
          [css.font]: true,
        })}
      >
        {Object.keys(filter).length > 0 && (
          <div className="d-flex justify-content-start align-items-center px-3 mb-2 w-100">
            {filter.date_from && (
              <div
                className="rounded shadow ml-2 py-2 px-3"
                style={{
                  background:
                    "linear-gradient(90deg, hsla(198, 68%, 77%, 0.75) 0%, hsla(0, 0%, 99%, 1) 100%)",
                }}
              >
                <label
                  className="m-0 font-weight-bold"
                  style={{ fontSize: 12 }}
                >
                  {t("_labels.date.from")}
                </label>
                <p className="m-0" style={{ fontSize: 14 }}>
                  {moment(filter.date_from).format("LL")}
                </p>
              </div>
            )}

            {filter.date_to && (
              <div
                className="rounded shadow ml-2 py-2 px-3"
                style={{
                  background:
                    "linear-gradient(90deg, hsla(198, 68%, 77%, 0.75) 0%, hsla(0, 0%, 99%, 1) 100%)",
                }}
              >
                <label
                  className="m-0 font-weight-bold"
                  style={{ fontSize: 12 }}
                >
                  {t("_labels.date.to")}
                </label>
                <p className="m-0" style={{ fontSize: 14 }}>
                  {moment(filter.date_to).format("LL")}
                </p>
              </div>
            )}
            {filter.companies && (
              <div
                className="rounded shadow ml-2 py-2 px-3"
                style={{
                  background:
                    "linear-gradient(90deg, hsla(198, 68%, 77%, 0.75) 0%, hsla(0, 0%, 99%, 1) 100%)",
                  minWidth: "175px",
                }}
              >
                <label
                  className="m-0 font-weight-bold"
                  style={{ fontSize: 12 }}
                >
                  {selectedFilter.companies.label}
                </label>
                <p className="m-0" style={{ fontSize: 14 }}>
                  {selectedFilter.companies.value}
                </p>
              </div>
            )}
            {selectedFilter?.subsidiaries?.value && (
              <div
                className="rounded shadow ml-2 py-2 px-3"
                style={{
                  background:
                    "linear-gradient(90deg, hsla(198, 68%, 77%, 0.75) 0%, hsla(0, 0%, 99%, 1) 100%)",
                  minWidth: "175px",
                }}
              >
                <label
                  className="m-0 font-weight-bold"
                  style={{ fontSize: 12 }}
                >
                  {selectedFilter.subsidiaries.label}
                </label>
                <p className="m-0" style={{ fontSize: 14 }}>
                  {selectedFilter.subsidiaries.value}
                </p>
              </div>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center mb-2 px-4">
          {data.length > 0 && (
            <>
              <div
                role="group"
                className={classnames({
                  ["btn-group"]: true,
                  [css.monthlyGroup]: true,
                })}
              >
                <button
                  type="button"
                  className={`btn btn-outline-dark pr-4 ${
                    monthly ? "active" : ""
                  }`}
                  onClick={() => toggleMonthly(true)}
                >
                  {t("_labels.disposal_chart.monthly")}
                </button>
                <button
                  type="button"
                  className={`btn btn-outline-dark ${!monthly ? "active" : ""}`}
                  onClick={() => toggleMonthly(false)}
                >
                  {t("_labels.disposal_chart.yearly")}
                </button>
              </div>
              <div>
                <ExportPdfButton />
              </div>
            </>
          )}
        </div>
        <div className="px-4 d-flex justify-content-center">
          <p className="m-0 text-wrap">
            {monthly
              ? t("_labels.disposal_chart.monthly_limit")
              : t("_labels.disposal_chart.yearly_limit")}
          </p>
        </div>

        {data.map((chart, index) => (
          <div key={index} className="exportable-chart">
            <ExportableChart {...chart} />
          </div>
        ))}
      </div>

      <FilterDisposalForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  monthly: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  toggleMonthly: PropTypes.func,
  openFilter: PropTypes.bool,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  filter: PropTypes.object,
  selectedFilter: PropTypes.object,
};

export default Page;
