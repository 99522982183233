import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ContainerTemplate from "components/templates/general/Container";
import SearchInput from "components/molecules/general/SearchInput";

import VehicleTypePreviewPanel from "components/organisms/vehicle/type/VehicleTypePreviewPanel";
import TireMountForm from "components/organisms/vehicle/tire/TireMountForm";
import VehicleMountedTireDialog from "components/organisms/vehicle/tire/VehicleMountedTireDialog";
import TireSendForm from "components/organisms/tire/TireSendForm";
import LastVehicleReviewPanel from "components/organisms/vehicle/review/LastVehicleReviewPanel";
import TireRotationForm from "components/organisms/vehicle/tire/TireRotationForm";
import UpdateVehicleReviewForm from "components/organisms/vehicle/review/UpdateVehicleReviewForm";

import css from "./index.module.scss";

function Page({
  t,
  to,
  movementTireId,
  vehicle,
  axleTireInfo,
  openSendForm,
  openMountForm,
  openMountedDialog,
  handleClickTire,
  handleCloseMountForm,
  handleCloseMountedDialog,
  handleOpenSendForm,
  handleCloseSendForm,
  openRotationForm,
  handleRotation,
  handleCloseRotationForm,
  origin,
  destination,
  isValidReview,
  openVehicleReviewForm,
  handleOpenVehicleReviewForm,
  handleCloseVehicleReviewForm,
  // review,
  handleSearch,
  search,
}) {
  return (
    <ContainerTemplate
      className={classnames(css.container)}
      title={`${t("_labels.economic_number")} ${vehicle.economic_number}`}
      options={
        <SearchInput search={search ? search : ""} onChange={handleSearch} />
      }
    >
      <div className={classnames(css.wrap)}>
        <div className={classnames(css.w50)}>
          {vehicle.vehicle_type.structure_type && (
            <VehicleTypePreviewPanel
              className={classnames(css.preview)}
              axles={vehicle.vehicle_type_axle.map((axle) => ({
                ...axle,
                tire_quantity: axle.vehicle_type_axle_tire.length,
              }))}
              structureType={vehicle.vehicle_type.structure_type}
              title={vehicle.vehicle_type.name || null}
              onClickTire={handleClickTire}
              onRotateTire={handleRotation}
            />
          )}
        </div>

        <div className={classnames(css.w50)}>
          {vehicle.vehicle_id !== 0 && (
            <LastVehicleReviewPanel
              vehicleId={vehicle.vehicle_id}
              handleOpenVehicleReviewForm={handleOpenVehicleReviewForm}
            />
          )}
        </div>
      </div>

      {isValidReview && (
        <TireMountForm
          axleTireInfo={axleTireInfo}
          open={openMountForm}
          onClose={handleCloseMountForm}
        />
      )}

      <VehicleMountedTireDialog
        vehicleTireId={
          axleTireInfo.vehicle_tire?.length > 0
            ? axleTireInfo.vehicle_tire[0].vehicle_tire_id
            : null
        }
        open={openMountedDialog}
        onClose={handleCloseMountedDialog}
        onSend={handleOpenSendForm}
      />

      <TireSendForm
        movementTireId={movementTireId}
        to={to}
        movement="MOUNT"
        open={openSendForm}
        onClose={handleCloseSendForm}
      />

      <TireRotationForm
        origin={origin}
        destination={destination}
        open={openRotationForm}
        onClose={handleCloseRotationForm}
      />

      <UpdateVehicleReviewForm
        open={openVehicleReviewForm}
        onClose={handleCloseVehicleReviewForm}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
  movementTireId: PropTypes.number,
  openSendForm: PropTypes.bool.isRequired,
  openMountForm: PropTypes.bool.isRequired,
  openMountedDialog: PropTypes.bool.isRequired,
  openRotationForm: PropTypes.bool.isRequired,
  vehicle: PropTypes.object.isRequired,
  axleTireInfo: PropTypes.object,
  origin: PropTypes.object,
  destination: PropTypes.object,
  handleClickTire: PropTypes.func.isRequired,
  handleCloseMountForm: PropTypes.func.isRequired,
  handleCloseMountedDialog: PropTypes.func.isRequired,
  handleOpenSendForm: PropTypes.func.isRequired,
  handleCloseSendForm: PropTypes.func.isRequired,
  handleRotation: PropTypes.func.isRequired,
  handleCloseRotationForm: PropTypes.func.isRequired,
  isValidReview: PropTypes.bool.isRequired,
  openVehicleReviewForm: PropTypes.bool.isRequired,
  handleOpenVehicleReviewForm: PropTypes.func.isRequired,
  handleCloseVehicleReviewForm: PropTypes.func.isRequired,
  review: PropTypes.object,
  handleSearch: PropTypes.func,
  search: PropTypes.string,
};

export default Page;
