import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAlertReport } from "store/actions/reports/alert_report/getAlertReport";
import { actionSetAlertReportFilters } from "store/actions/reports/alert_report/setAlertReportFilters";
import { actionClearAlertReportFilters } from "store/actions/reports/alert_report/clearAlertReportFilters";
import { actionShowProgress } from "store/actions/general/progress";
import { http } from "store/actions/http";

import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";
import { actionSetSelectedAlertReportFilters } from "store/actions/reports/alert_report/setSelectedAlertReportFilters";
import { arrayJoin } from "utils/parse";

function AlertReport({ ...rest }) {
  const t = useLang();
  const {
    account,
    workArea,
    filter,
    selectedFilter,
    setNotification,
    getAlertReport,
    setAlertReportFilters,
    clearAlertReportFilters,
    setSelectedFilters,
    showProgress,
  } = rest;

  const [tireData, setTireData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [mountData, setMountData] = useState([]);
  const [damagesData, setDamagesData] = useState([]);
  const [wearData, setWearData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [detailsData, setDetailsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [visibleOption, setVisibleOption] = useState("tire");
  const [showDetails, setShowDetails] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [totalAlerts, setTotalAlerts] = useState({
    tires: 0,
    mounts: 0,
    vehicles: 0,
    damages: 0,
    wears: 0,
  });
  const tableRef = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAlertReport({ ...filter });
        const [tiresAlerts, totalTires] = getData(t, response, "Tires");
        const [vehiclesAlerts, totalVehicles] = getData(t, response, "Vehicle");
        const [mountsAlerts, totalMounts] = getData(t, response, "Mounts");
        const [damageAlerts, totalDamages] = getData(t, response, "Damages");
        const [wearAlerts, totalWears] = getData(t, response, "Wear");
        setTireData(tiresAlerts);
        setVehicleData(vehiclesAlerts);
        setMountData(mountsAlerts);
        setDamagesData(damageAlerts);
        setWearData(wearAlerts);
        setTotalAlerts({
          tires: totalTires,
          mounts: totalMounts,
          vehicles: totalVehicles,
          damages: totalDamages,
          wears: totalWears,
        });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      setShowDetails(false);
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: arrayJoin(
        companies.map((company) => company.company.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
      subsidiaries: arrayJoin(
        subsidiaries.map((subsidiary) => subsidiary.subsidiary.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
    };
    setAlertReportFilters(filter);
    setSelectedFilters(selectedFilters);
    return () => {
      clearAlertReportFilters();
    };
  }, []);

  async function handleClickAlert(alert) {
    const progress = showProgress();

    const { corporate_id: corporate, companies, subsidiaries } = filter;
    const params = {
      corporate_id: corporate,
      companies,
      subsidiaries,
    };

    if (filter.date_to && filter.date_from) {
      params.date_to = filter.date_to;
      params.date_from = filter.date_from;
    }

    try {
      let response = await http({
        method: "GET",
        path: `report/alert/history/${
          alert["alert_id"] ?? alert["damage_id"] ?? alert["wear_id"]
        }/${visibleOption}`,
        params: {
          ...params,
        },
      });

      setChartData(
        getHistoricChart(response, {
          ...alert,
          colloquial_name: alert["colloquial_name"]
            ? t(`_alert.colloquial_name.${alert["colloquial_name"]}`)
            : t(`_damage.name.${alert["name"]}`),
        })
      );

      response = await http({
        method: "GET",
        path: `report/alert/details/${
          alert["alert_id"] ?? alert["damage_id"] ?? alert["wear_id"]
        }/${visibleOption}`,
        params: {
          ...params,
        },
      });

      setDetailsData(response);
      setSelectedOption(alert);
      setShowDetails(true);

      if (tableRef.current) {
        tableRef.current.scroll({ behavior: "smooth", top: 0 });
      }
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  function setVisible(type) {
    setVisibleOption(type);
    setShowDetails(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }
  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      tireData={tireData}
      vehicleData={vehicleData}
      mountData={mountData}
      damagesData={damagesData}
      wearData={wearData}
      totalAlerts={totalAlerts}
      chartData={chartData}
      detailsData={detailsData}
      handleClickAlert={handleClickAlert}
      selectedOption={selectedOption}
      tableRef={tableRef}
      visibleOption={visibleOption}
      setVisible={setVisible}
      showDetails={showDetails}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}

AlertReport.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.Alert.filter,
  selectedFilter: state.Reports.Alert.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  showProgress: actionShowProgress(dispatch),
  setNotification: actionSetNotification(dispatch),
  getAlertReport: actionGetAlertReport(dispatch),
  setAlertReportFilters: actionSetAlertReportFilters(dispatch),
  clearAlertReportFilters: actionClearAlertReportFilters(dispatch),
  setSelectedFilters: actionSetSelectedAlertReportFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertReport);

function getData(t, data, key) {
  let result = [];
  const orderArray = ["HIGH", "HALF", "LOW"];
  let totalResult = 0;
  if (Object.keys(data[key]).length > 0) {
    totalResult = Object.entries(data[key]).reduce(
      (acc, [, alert]) => acc + alert["statistics"],
      0
    );

    const array = Object.entries(data[key]).reduce((acc, entry) => {
      const [, alert] = entry;
      const foundArray = acc.find(
        ([key]) => key === (alert["ranking_alert"] ?? alert["attribution"])
      );
      if (foundArray) {
        foundArray[1].push(entry);
      } else {
        acc.push([alert["ranking_alert"] ?? alert["attribution"], [entry]]);
      }
      return acc;
    }, []);

    array.forEach((entry) => {
      const [, alerts] = entry;
      alerts.sort((a, b) => {
        return (
          orderArray.indexOf(a[1]["priority"]) -
          orderArray.indexOf(b[1]["priority"])
        );
      });
    });

    result = array.sort((a, b) => {
      if (
        t(`_labels.ranking_alert.options.${a[0]}`) >
        t(`_labels.ranking_alert.options.${b[0]}`)
      ) {
        return 1;
      } else if (
        t(`_labels.ranking_alert.options.${b[0]}`) >
        t(`_labels.ranking_alert.options.${a[0]}`)
      ) {
        return -1;
      }
      return 0;
    });
  }

  return [result, totalResult];
}

function getHistoricChart(data, alert) {
  const colors = {
    high: "rgba(255, 0, 0, 0.85)",
    low: "rgba(0, 128, 40, 0.85)",
    half: "rgba(250, 175, 0, 0.85)",
  };

  return {
    datasets: [
      {
        label: alert["colloquial_name"],
        data: data.map((el) => ({
          ...el,
          fecha: moment(el.date).format("YYYY-MM"),
        })),
        fill: false,
        pointWidth: 2,
        borderColor: alert["priority"]
          ? colors[alert["priority"].toLowerCase()]
          : colors.half,
        backgroundColor: alert["priority"]
          ? colors[alert["priority"].toLowerCase()]
          : colors.half,
        tension: 0.1,
      },
    ],
  };
}
