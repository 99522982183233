import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";

import VehicleTypeAxleInfoCard from "components/molecules/vehicle/type/axle/VehicleTypeAxleInfoCard";

import css from "./index.module.scss";

function Page({ t, axles }) {
  return (
    <>
      <Card className={classnames(css.card)} elevation={3}>
        <CardContent className={classnames(css.content)}>
          <Typography
            className={classnames(css.mb8)}
            variant="h6"
            color="textPrimary"
          >
            {t("_labels.axle.plural")}
          </Typography>
          <div className={classnames(css.flex, css.content_info)}>
            {axles.map((axle, index) => {
              return (
                <VehicleTypeAxleInfoCard
                  key={index}
                  color={axle.color}
                  position={axle.position}
                  axleNumber={axle.axle_number}
                  tireApplication={axle.tire_application}
                  tireQuantityCount={axle.tire_quantity}
                />
              );
            })}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  axles: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      axle_number: PropTypes.number.isRequired,
      tire_application: PropTypes.array.isRequired,
      tire_quantity: PropTypes.number.isRequired,
    })
  ),
};

export default Page;
