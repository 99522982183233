import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  initialValues,
  setFieldValue,
  movements,
  conditions,
  applications,
  corporates,
  companies,
  subsidiaries,
  isPile,
  brands,
  models,
  sizes,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.w100, css.ml8)}
      >
        {t("_labels.date.range")}
      </Typography>
      <Field
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.from")}
        className={classnames(css.medium_field)}
        required={!!values.date_to}
      />

      <Field
        name="date_to"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.to")}
        className={classnames(css.medium_field)}
        required={!!values.date_from}
      />

      {!isPile && (
        <Field
          fullWidth
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          name="movement"
          component={SelectField}
          label={t("_labels.location.label")}
          className={classnames(css.field)}
        >
          {movements.map((movement, index) => {
            return (
              <MenuItem key={index} value={movement.value.toString()}>
                {movement.label}
              </MenuItem>
            );
          })}
        </Field>
      )}

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="corporate_id"
        component={SelectField}
        label={t("_labels.corporate_field.singular.label")}
        defaultText={t("_labels.corporate_field.singular.value")}
        className={classnames(css.field)}
      >
        {corporates.map((corporate, index) => {
          return (
            <MenuItem
              key={index}
              value={corporate.corporate_id.toString()}
              disabled={!corporate.status}
            >
              {corporate.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="company_id"
        component={SelectField}
        label={t("_labels.company_field.singular.label")}
        defaultText={t("_labels.company_field.singular.value")}
        className={classnames(css.field)}
      >
        {companies.map((company, index) => {
          return (
            <MenuItem
              key={index}
              value={company.company_id.toString()}
              disabled={!company.status}
            >
              {company.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiary_id"
        component={SelectField}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        className={classnames(css.field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="brands"
        name="brands"
        multiple
        autoHighlight
        disableClearable={initialValues.brands.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("brands", value)}
        value={values.brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="models"
        name="models"
        multiple
        autoHighlight
        disableClearable={initialValues.models.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={models}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("models", value)}
        value={values.models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="sizes"
        name="sizes"
        multiple
        autoHighlight
        disableClearable={initialValues.sizes.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={sizes}
        getOptionLabel={(option) => option.size}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_size_id === value.tire_size_id
        }
        onChange={(_, value) => setFieldValue("sizes", value)}
        value={values.sizes}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2 {...params} label={t("_labels.size")} variant="filled" />
        )}
      />

      <Field
        fullWidth
        variant="filled"
        name="min_travel"
        component={TextField}
        type="number"
        label={t("_labels.min_km")}
        className={classnames(css.medium_field)}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        fullWidth
        variant="filled"
        name="max_travel"
        component={TextField}
        type="number"
        label={t("_labels.max_km")}
        className={classnames(css.medium_field)}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        multiple //
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_condition"
        component={SelectField}
        label={t("_labels.condition.label")}
        defaultText={t("_labels.tire_condition.default")}
        className={classnames(css.medium_field)}
      >
        {conditions.map((condition, index) => {
          return (
            <MenuItem key={index} value={condition.value.toString()}>
              {condition.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        multiple //
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_application"
        component={SelectField}
        label={t("_labels.tire_application.label.singular")}
        defaultText={t("_labels.tire_application.default.singular")}
        className={classnames(css.medium_field)}
      >
        {applications.map((application, index) => {
          return (
            <MenuItem key={index} value={application.value.toString()}>
              {application.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        margin="normal"
        name="price"
        component={TextField}
        className={classnames(css.medium_field)}
        label={t("_labels.price")}
      />

      <Field
        variant="filled"
        margin="normal"
        name="helmet_value"
        component={TextField}
        className={classnames(css.medium_field)}
        label={t("_labels.helmet_value")}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  movements: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  isPile: PropTypes.bool,
  brands: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  sizes: PropTypes.array.isRequired,
};

export default Page;
