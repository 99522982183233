import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE =
  "src/components/organisms/vehicle/type/VehicleTypePreviewPanel";

export function VehicleTypePreviewPanel({ ...rest }) {
  const t = useLang(NAMESPACE);

  return <Page t={t} {...rest} />;
}

VehicleTypePreviewPanel.propTypes = {
  title: PropTypes.string,
  structureType: PropTypes.string.isRequired,
  axles: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleTypePreviewPanel);
