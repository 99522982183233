import {
  GET_VEHICLE_TIRE,
  CLEAR_VEHICLE_TIRE,
  GET_VEHICLE_TIRES,
  CLEAR_VEHICLE_TIRES,
  SET_TIRE_MOUNT_FILTERS,
  CLEAR_TIRE_MOUNT_FILTERS,
} from "store/actions";

const initialState = {
  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  vehicle_tire: {
    cycle: {
      code: "",
      revitalized: null,
      tire_condition_id: "ORIGINAL_NEW",
      number_cycle: 0,
      variation: {
        tire_model: {
          name: "",
          brand: {
            name: "",
          },
        },
        tire_size: {
          size: "",
        },
        tire_model_variation_use: [
          {
            tire_use_id: "",
          },
        ],
        tire_application_id: "",
      },
      movement_tire: {
        vehicle_tire: [
          {
            vehicle_type_axle_tire: {
              position: "",
              vehicle_type_axle: {
                axle_number: "",
                position: "",
                tire_application: [
                  {
                    tire_application_id: "",
                  },
                ],
              },
            },
          },
        ],
        tire_review: [
          {
            pressure: 0,
          },
        ],
      },
    },
    subsidiary: {
      name: "",
      policy: {},
    },
  },
  origin: null,
  filter: {
    location: "WAREHOUSE",
    status: 1,
    page: 1,
    per_page: 9,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_TIRE:
      return {
        ...state,
        vehicle_tire: action.payload,
      };

    case CLEAR_VEHICLE_TIRE:
      return {
        ...state,
        vehicle_tire: { ...initialState.vehicle_tire },
      };

    case GET_VEHICLE_TIRES:
      return {
        ...state,
        tires: action.payload,
      };

    case CLEAR_VEHICLE_TIRES:
      return {
        ...state,
        tires: { ...initialState.tires },
      };

    case "SET_ORIGIN":
      return {
        ...state,
        origin: action.payload,
      };

    case SET_TIRE_MOUNT_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_TIRE_MOUNT_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter, search: state.filter.search },
      };

    default:
      return state;
  }
}
