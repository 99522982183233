import {
  GET_SUBSIDIARY_WAREHOUSES_HISTORY,
  CLEAR_SUBSIDIARY_WAREHOUSES_HISTORY,
} from "store/actions";

const initialState = {
  subsidiary_warehouses: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARY_WAREHOUSES_HISTORY:
      return {
        ...state,
        subsidiary_warehouses: action.payload,
      };

    case CLEAR_SUBSIDIARY_WAREHOUSES_HISTORY:
      return {
        ...state,
        subsidiary_warehouses: initialState.subsidiary_warehouses,
      };

    default:
      return state;
  }
}
