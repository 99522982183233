import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCommissionedDrivers } from "store/actions/commissioned/driver/getCommissionedDrivers";
import { actionClearCommissionedDrivers } from "store/actions/commissioned/driver/clearCommissionedDrivers";
import { actionSetCommissionedDriverStatus } from "store/actions/commissioned/driver/setCommissionedDriverStatus";
import { actionDeleteCommissionedDriver } from "store/actions/commissioned/driver/deleteCommissionedDriver";
import { actionSetCommissionedDriverFilters } from "store/actions/commissioned/driver/setCommissionedDriverFilters";
import { actionClearCommissionedDriverFilters } from "store/actions/commissioned/driver/clearCommissionedDriverFilters";

import { useLang } from "hooks/lang";
import Page from "./page";

function HomeCommissionedDriver({ ...rest }) {
  const t = useLang();
  const {
    drivers,
    filter,
    setNotification,
    getCommissionedDrivers,
    clearCommissionedDrivers,
    setCommissionedDriverStatus,
    deleteCommissionedDriver,
    setCommissionedDriverFilters,
    clearCommissionedDriverFilters,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [commissionedDriverId, setCommissionedDriverId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getCommissionedDrivers({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearCommissionedDrivers();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [drivers.data]);

  useEffect(() => {
    return () => {
      clearCommissionedDriverFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getCommissionedDrivers({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(commissionedDriverId, status) {
    setNotification({
      message: status
        ? t("_messages.change_status.driver.disable")
        : t("_messages.change_status.driver.enable"),
      onAction: () => onChangeStatus(commissionedDriverId, !status),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onChangeStatus(commissionedDriverId, status) {
    try {
      const response = await setCommissionedDriverStatus(
        commissionedDriverId,
        status
      );
      setNotification({ message: response.message });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(commissionedDriverId) {
    setNotification({
      message: t("_messages.delete.driver"),
      onAction: () => onDelete(commissionedDriverId),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onDelete(commissionedDriverId) {
    try {
      const response = await deleteCommissionedDriver(commissionedDriverId);
      setNotification({ message: response.message });
      clearCommissionedDriverFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setCommissionedDriverId(null);
  }

  function handleEdit(commissionedDriverId) {
    setCommissionedDriverId(commissionedDriverId);
    setOpenForm(true);
  }

  function handleSearch(search) {
    setCommissionedDriverFilters({ search });
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      drivers={drivers}
      search={filter.search}
      commissionedDriverId={commissionedDriverId}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleChangeStatus={handleChangeStatus}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleSearch={handleSearch}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}

const mapStateToProps = (state) => ({
  drivers: state.Commissioned.Driver.drivers,
  filter: state.Commissioned.Driver.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCommissionedDrivers: actionGetCommissionedDrivers(dispatch),
  clearCommissionedDrivers: actionClearCommissionedDrivers(dispatch),
  setCommissionedDriverStatus: actionSetCommissionedDriverStatus(dispatch),
  deleteCommissionedDriver: actionDeleteCommissionedDriver(dispatch),
  setCommissionedDriverFilters: actionSetCommissionedDriverFilters(dispatch),
  clearCommissionedDriverFilters:
    actionClearCommissionedDriverFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeCommissionedDriver);
