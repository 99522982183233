import React from "react";
import PropTypes from "prop-types";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "../index.css";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

function Page({ data, selectKey, t }) {
  if (Object.keys(data).length > 0) {
    return (
      <>
        <div className="row mb-4">
          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>
                  {t("_labels.potential_savings_analysis.potential_losses")}
                </h4>
                <h6
                  style={{
                    color: "#888",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.potential_losses_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-right">{t("_labels.tire.plural")}</th>
                      <th className="text-right">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right">
                        <a
                          href={"#losses"}
                          onClick={() => {
                            selectKey("pressure");
                          }}
                          style={{
                            color: "lightblue",
                          }}
                        >
                          {t(
                            "_labels.potential_savings_analysis.pressure_losses"
                          )}
                        </a>
                      </td>
                      <td className="text-right fw700">
                        {data.pressure_loss.elements}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        ${formatter.format(data.pressure_loss.pressure_loss)}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        <BootstrapTooltip
                          title="Representa el costo los neumaticos en retiro critico que no han sido enviadas a renovar"
                          placement="right"
                        >
                          <a
                            href={"#purchases"}
                            onClick={() => {
                              selectKey("semaphore");
                            }}
                            style={{
                              color: "lightblue",
                            }}
                          >
                            {t(
                              "_labels.potential_savings_analysis.purchase_losses"
                            )}
                          </a>
                        </BootstrapTooltip>
                      </td>
                      <td className="text-right fw700">
                        {data.revitalized_depth_loss.elements}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.revitalized_depth_loss?.original_investment ?? 0
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.month_losses")}
                      </td>
                      <td className="text-right fw700"></td>
                      <td className="text-right fw700 sfcred">
                        ${formatter.format(data.loss_of_month)} MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>{t("_labels.potential_savings_analysis.real_savings")}</h4>
                <h6
                  style={{
                    color: "#888",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.real_savings_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-right">{t("_labels.tire.plural")}</th>
                      <th className="text-right">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.pressure_savings"
                        )}
                      </td>
                      <td></td>
                      <td className="text-right fw700 sfcverde ">
                        ${formatter.format(data.loss_savings.pressure_saving)}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.revitalized_savings"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {
                          data.tires_purchased.condition.revitalized
                            .number_cycle_elements
                        }
                      </td>
                      <td className="text-right fw700 sfcverde ">
                        $
                        {formatter.format(
                          data.tires_purchased.condition.revitalized
                            .savings_per_revitalized
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right">
                        {t("_labels.potential_savings_analysis.month_savings")}
                      </td>
                      <td></td>
                      <td className="text-right fw700 sfcverde">
                        ${formatter.format(data.savings_of_month)} MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-12 mb-4">
            <h3
              style={{
                textAlign: "center",
              }}
            >
              {t("_labels.potential_savings_analysis.fleet_condition")}
            </h3>
          </div>
          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>
                  <a href={"#Vehicles"}>{t("_labels.vehicle.plural")}</a>
                </h4>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.fleet")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.vehicles.fleet_elements}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.inspections")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.vehicles.vehicles_current_review}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.inspections_over_fleet"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.vehicles.vehicles_current_percentage_over_fleet
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>
                  <a href={"#Tires"}>{t("_labels.tire.plural")}</a>
                </h4>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.fleet")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.tires.fleet_elements}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.inspections")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.tires.tires_current_review}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.inspections_over_fleet"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.tires.tires_current_percentage_over_fleet
                        )}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-12 mb-4 mt-4">
            <h3
              style={{
                textAlign: "center",
              }}
            >
              {t("_labels.potential_savings_analysis.potential_savings_losses")}
            </h3>
          </div>

          <div className="col-md-4 mb-4">
            <Card>
              <CardContent>
                <h4>
                  <a href={"#Pressure"}>{t("_labels.pressures")}</a>
                </h4>
                <h6
                  style={{
                    color: "#888",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.pressures_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.elements")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.pressure_loss.elements}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.percentage_over_fleet"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(data.pressure_loss.percentage)}%
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.losses")}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        ${formatter.format(data.pressure_loss.pressure_loss)}{" "}
                        MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card>
              <CardContent>
                <h4>
                  <a href={"#Depth"}>{t("_labels.depth")}</a>
                </h4>

                <h6
                  style={{
                    color: "#888",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.depth_new_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.original_tires")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.original_depth_loss?.elements ?? 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.percentage_over_fleet"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.original_depth_loss?.percentage ?? 0
                        )}{" "}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.original_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.original_depth_loss?.original_investment ?? 0
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-4 mb-4">
            <Card>
              <CardContent>
                <h6
                  style={{
                    color: "#888",
                    marginTop: "20px",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.depth_revitalized_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.critical_tires")}
                      </td>
                      <td className="text-right fw700 ">
                        {data.revitalized_depth_loss?.elements ?? 0}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.percentage_over_fleet"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.revitalized_depth_loss?.percentage ?? 0
                        )}{" "}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.original_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.revitalized_depth_loss?.original_investment ?? 0
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.revitalized_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.revitalized_depth_loss?.revitalized_investment ??
                            0
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.potential_savings"
                        )}
                      </td>
                      <td className="text-right fw700 sfcverde ">
                        $
                        {formatter.format(
                          data.revitalized_depth_loss?.potential_savings ?? 0
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-12 mb-4 mt-4">
            <h3
              style={{
                textAlign: "center",
              }}
            >
              {t("_labels.potential_savings_analysis.real_savings_losses")}
            </h3>
          </div>

          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>{t("_labels.potential_savings_analysis.real_original")}</h4>
                <h6
                  style={{
                    color: "#888",
                    marginTop: "20px",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.real_original_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.real_original_elements"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {
                          data.tires_purchased.condition.original
                            .number_cycle_elements
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.acquisition_percentage"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.tires_purchased.condition.original
                            .number_cycle_percentage
                        )}
                        {"%"}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.original_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.tires_purchased.condition.original
                            .original_investment
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>

          <div className="col-md-6 mb-4">
            <Card>
              <CardContent>
                <h4>
                  {t("_labels.potential_savings_analysis.real_revitalized")}
                </h4>
                <h6
                  style={{
                    color: "#888",
                    marginTop: "20px",
                  }}
                >
                  {t(
                    "_labels.potential_savings_analysis.real_revitalized_description"
                  )}
                </h6>
                <hr />

                <table className="table sFS14">
                  <tbody>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.sent_revitalized_elements"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {data.tires_send_revitalization}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.real_revitalized_elements"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {
                          data.tires_purchased.condition.revitalized
                            .number_cycle_elements
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.acquisition_percentage"
                        )}
                      </td>
                      <td className="text-right fw700 ">
                        {formatter.format(
                          data.tires_purchased.condition.revitalized
                            .number_cycle_percentage
                        )}
                        {"%"}
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.original_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.tires_purchased.condition.revitalized
                            .original_investment
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t(
                          "_labels.potential_savings_analysis.revitalized_investment"
                        )}
                      </td>
                      <td className="text-right fw700 sfcred ">
                        $
                        {formatter.format(
                          data.tires_purchased.condition.revitalized
                            .revitalized_investment
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="sbnegro80 text-right ">
                        {t("_labels.potential_savings_analysis.savings")}
                      </td>
                      <td className="text-right fw700 sfcverde ">
                        $
                        {formatter.format(
                          data.tires_purchased.condition.revitalized
                            .savings_per_revitalized
                        )}{" "}
                        MXN
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    );
  }

  return null;
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  currentMonth: PropTypes.string.isRequired,
  lastMonth: PropTypes.string.isRequired,
  selectKey: PropTypes.func.isRequired,
};

export default Page;
