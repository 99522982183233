import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetConcepts } from "store/actions/concept/getConcepts";
import { actionClearConcepts } from "store/actions/concept/clearConcepts";
import { actionSetConceptFilters } from "store/actions/concept/setConceptFilters";
import { actionClearConceptFilters } from "store/actions/concept/clearConceptFilters";
import { actionDeleteConcept } from "store/actions/concept/deleteConcept";

import {
  CREATE_CONCEPT,
  DELETE_CONCEPT,
  UPDATE_CONCEPT,
} from "store/actions/account/permits";
import Page from "./page";

function HomeConcept({ ...rest }) {
  const t = useLang();

  const {
    filter,
    concepts,
    permits,
    setNotification,
    getConcepts,
    clearConcepts,
    setConceptFilters,
    clearConceptFilters,
    deleteConcept,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [conceptId, setConceptId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getConcepts({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearConcepts();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearConceptFilters();
    };
  }, []);

  function handleOpenForm(conceptId) {
    if (typeof conceptId === "number") {
      setConceptId(conceptId);
    }
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setConceptId(null);
  }

  async function handleChangePage(page) {
    setConceptFilters({ ...filter, page });
  }

  function handleSearch(search) {
    setConceptFilters({ ...filter, search, page: null });
  }

  function handleDelete(conceptId) {
    setNotification({
      message: t("_messages.delete.concept"),
      onAction: () => onAction(conceptId),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onAction(conceptId) {
    try {
      const response = await deleteConcept(conceptId);
      setNotification({ message: response.message });
      getConcepts({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      search={filter.search}
      concepts={concepts}
      conceptId={conceptId}
      openForm={openForm}
      handleSearch={handleSearch}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleChangePage={handleChangePage}
      handleDelete={handleDelete}
      createConceptPermit={permits.includes(CREATE_CONCEPT)}
      updateConceptPermit={permits.includes(UPDATE_CONCEPT)}
      deleteConceptPermit={permits.includes(DELETE_CONCEPT)}
    />
  );
}

const mapStateToProps = (state) => ({
  concepts: state.Concept.concepts,
  filter: state.Concept.filter,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getConcepts: actionGetConcepts(dispatch),
  clearConcepts: actionClearConcepts(dispatch),
  setConceptFilters: actionSetConceptFilters(dispatch),
  clearConceptFilters: actionClearConceptFilters(dispatch),
  deleteConcept: actionDeleteConcept(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeConcept);
