import React from "react";
import Page from "./page";

import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

export const NAMESPACE = "components/molecules/gps/GpsGeofenceCard";

function GpsGeofenceCard({
  title,
  description,
  status,
  className,
  tooltipPermit,
  handleClick,
}) {
  const t = useLang(NAMESPACE);
  return (
    <Page
      t={t}
      title={`${moment(title).format("LL")} ${t("_labels.time_at")} ${moment(
        title
      ).format("hh:mm a")}`}
      description={description}
      status={status}
      className={className}
      tooltipPermit={tooltipPermit}
      handleClick={handleClick}
    />
  );
}

GpsGeofenceCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  description: PropTypes.number.isRequired,
  tooltipPermit: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default GpsGeofenceCard;
