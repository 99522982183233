import {
  GET_VEHICLE_HISTORY,
  CLEAR_VEHICLE_HISTORY,
  SET_VEHICLE_REVIEW_FILTERS,
  CLEAR_VEHICLE_REVIEW_FILTERS,
} from "store/actions";

const initialState = {
  vehicle_review: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_HISTORY:
      return {
        ...state,
        vehicle_review: action.payload,
      };

    case CLEAR_VEHICLE_HISTORY:
      return {
        ...state,
        vehicle_review: { ...initialState.vehicle_review },
      };

    case SET_VEHICLE_REVIEW_FILTERS:
      const filter = {
        ...state.filter_vehicle,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_VEHICLE_REVIEW_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
      };
    default:
      return state;
  }
}
