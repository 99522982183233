export default {
  title: "Linked vehicles",
  form: {
    vehicle_division: "Only subsidiary vehicles are displayed",
  },
  permits: {
    list_vehicle: "No permission to list vehicles to divisions",
    unlink_division_vehicle: "No permissions to unlink vehicles from divisions",
  },
  messages: {
    delete: "Are you sure to unlink vehicle?",
    delete_division_vehicle:
      "Done, the vehicle has been unlinked from the division",
    linked_division_vehicle:
      "Done, the vehicle was successfully linked to the division",
  },
};
