import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { actionSetNotification } from "store/actions/general/notification";
import { actionUpdateVehicleReview } from "store/actions/vehicle/review/updateVehicleReview";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

function VehicleReviewForm({ ...rest }) {
  const t = useLang();
  const { open, onClose, review, updateVehicleReview, setNotification } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      date: "",
      odometer: "",
      observation: "",
    },
  });

  useEffect(() => {
    if (open && review.vehicle_review_id) {
      setInitialValues({
        date: moment(review.date).format("YYYY-MM-DD"),
        odometer: review.odometer,
        observation: review.observation || "",
      });
    }
  }, [open, review]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        date: values.date,
        odometer: values.odometer,
      };

      if (values.comment) {
        fields.comment = values.comment;
      }

      await updateVehicleReview(review.vehicle_id, fields);
      setNotification({
        message: t("_messages.updated.vehicle_review"),
      });

      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

VehicleReviewForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

VehicleReviewForm.defaultProps = {};

const mapStateToProps = (state) => ({
  review: state.Vehicle.Review.vehicle_review,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  updateVehicleReview: actionUpdateVehicleReview(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleReviewForm);
