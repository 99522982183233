export default {
  title: "Nuevo gps",
  update_title: "Actualizar gps",
  messages: {
    gps_created: "Listo, se creo un nuevo gps con éxito",
    gps_updated: "Listo, se actualizó el gps con éxito",
    gps_deleted: "Listo, se eliminó el gps con éxito",
    delete: "¿Estas seguro de eliminar el gps?",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar gps",
      delete: "Sin permisos para eliminar gps",
    },
  },
};
