import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddTire } from "store/actions/tire/addTire";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTire } from "store/actions/tire/getTire";
import { actionUpdateTire } from "store/actions/tire/updateTire";
import { actionGetTires } from "store/actions/tire/getTires";
import { actionGetRfids } from "store/actions/rfid/getRfids";
import { actionClearTire } from "store/actions/tire/clearTire";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";

import { LIST_SUBSIDIARY } from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function TireForm({
  open,
  onClose,
  tireId,
  onOpenSimilarForm,
  setIsRegisterForm,
  ...rest
}) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(tireId ? true : false);
  const {
    account,
    filter,
    permits,
    tire,
    setNotification,
    addTire,
    updateTire,
    getTire,
    getSubsidiaries,
    getBrands,
    getTires,
    getRfids,
    getTireModels,
    getRevitalizedTireModels,
  } = rest;

  const initialDefaultValues = {
    code: "",
    device_code: null,
    subsidiary_id: "",
    warehouse_id: "",
    provider_id: "",
    brand_id: null,
    tire_model_id: null,
    tire_model_variation_id: "",
    price: "",
    expected_durability: "",
    dot: "",
    invoice_folio: "",
    invoice_date: "",
    similar_tires: 1,
    is_refection: false,
    cap_and_casing: false,
    revitalized_brand_id: null,
    revitalized_tire_model_id: null,
    price_revitalized: "",
  };
  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tireModels, setTireModels] = useState([]);
  const [revitalizedModels, setRevitalizedModels] = useState([]);
  const [rfids, setRfids] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (permits.includes(LIST_SUBSIDIARY)) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));

        data = await getBrands(
          {
            scope: "brand_type,brand_id,name,status,approved",
            brandType: "TIRE,RETREAD",
          },
          false
        );
        setBrands(data.sort(alphabeticalSort("name")));

        data = await getTireModels(
          {
            scope:
              "tire_model_id,tire_model.brand_id,tire_model.name,tire_model.status,tire_model.approved",
          },
          false
        );

        const uniqueModels = data.reduce((result, model) => {
          if (!result.find((el) => el.tire_model_id == model.tire_model_id)) {
            result.push(model);
          }
          return result;
        }, []);

        setTireModels(
          uniqueModels
            .map((model) => ({
              tire_model_id: model.tire_model_id,
              brand_id: model.tire_model.brand_id,
              name: model.tire_model.name,
              status: model.tire_model.status,
              approved: model.tire_model.approved,
            }))
            .sort(alphabeticalSort("name"))
        );

        data = await getRevitalizedTireModels(
          { scope: "brand_id,revitalized_tire_model_id,name,status,approved" },
          false
        );
        setRevitalizedModels(data.sort(alphabeticalSort("name")));

        const params = {
          scope: "rfid_id,device_code,status",
        };

        if (!tireId) {
          params.withLinkTire = 0;
        }
        data = await getRfids(params, false);
        setRfids(data.sort(alphabeticalSort("device_code")));
        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    } else {
      setLoaded(false);
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open && tireId && tire.tire_id && loaded) {
      setInitialValues({
        code: tire.code || "",
        device_code:
          tire.rfid_tire.length > 0
            ? rfids.find((rfid) => rfid.rfid_id == tire.rfid_tire[0].rfid_id)
            : null,
        subsidiary_id: tire.subsidiary_id.toString(),
        warehouse_id:
          tire.cycle.movement_tire.movement == "WAREHOUSE"
            ? tire.cycle.movement_tire.warehouse_tire[0].warehouse_id.toString()
            : "",
        provider_id: tire.cycle.provider_id.toString(),
        brand_id: brands.find(
          (brand) => brand.brand_id == tire.cycle.variation.tire_model.brand_id
        ),
        tire_model_id: tireModels.find(
          (model) => model.tire_model_id == tire.cycle.variation.tire_model_id
        ),
        tire_model_variation_id: tire.cycle.tire_model_variation_id.toString(),
        price: tire.cycle.price,
        expected_durability: tire.cycle.expected_durability || "",
        dot: tire.dot || "",
        invoice_folio: tire.cycle.invoice_folio || "",
        invoice_date: tire.cycle.invoice_date || "",
        similar_tires: "",
        is_refection: Boolean(tire.cycle.is_refection),
        cap_and_casing: Boolean(tire.cap_and_casing),
        revitalized_brand_id: tire.cycle.revitalized
          ? brands.find(
              (brand) => brand.brand_id == tire.cycle.revitalized.brand_id
            )
          : null,
        revitalized_tire_model_id: tire.cycle.revitalized_tire_model_id
          ? revitalizedModels.find(
              (model) =>
                model.revitalized_tire_model_id ==
                tire.cycle.revitalized_tire_model_id
            )
          : null,
        price_revitalized: tire.cycle.price_revitalized || "",
      });
    } else {
      setInitialValues(initialDefaultValues);
    }
  }, [loaded, tire, tireId]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (tireId && open) {
      fetchData();
    }
  }, [tireId, open]);

  useEffect(() => {
    setIsUpdate(tireId ? true : false);
  }, [tireId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      code: values.code,
      subsidiary_id: values.subsidiary_id,
      warehouse_id: values.warehouse_id,
      provider_id: values.provider_id,
      tire_model_variation_id: values.tire_model_variation_id,
      price: values.price,
      expected_durability: values.expected_durability,
      dot: values.dot,
      invoice_date: values.invoice_date,
      invoice_folio: values.invoice_folio,
    };

    if (values.code) {
      fields.code = values.code;
    }

    if (values.device_code) {
      fields.device_code = values.device_code.rfid_id;
    }

    let sendRequest = false;
    try {
      if (!isUpdate) {
        if (values.similar_tires > 1) {
          onClose();
          setIsRegisterForm(true);
          onOpenSimilarForm(values.similar_tires, fields);
        } else {
          await addTire(fields);
          sendRequest = true;
          resetForm();
          setNotification({
            message: t("_messages.created.tire"),
          });
        }
      } else {
        fields.is_refection = values.is_refection;
        fields.cap_and_casing = values.cap_and_casing;

        if (tire.cycle.tire_condition_id.includes("RETREAD")) {
          if (values.revitalized_tire_model_id) {
            fields.revitalized_tire_model_id =
              values.revitalized_tire_model_id.revitalized_tire_model_id;
          }

          if (values.price_revitalized) {
            fields.price_revitalized = values.price_revitalized;
          }

          delete fields.tire_model_variation_id;
          delete fields.price;
        }

        await updateTire(fields, tireId);
        sendRequest = true;
        setNotification({
          message: t("_messages.updated.tire"),
        });
      }

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    if (sendRequest) {
      try {
        await getTires({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);

    if (!!values.dot && (values.dot.length < 2 || values.dot.length > 15)) {
      errors.dot = t("_errors.dot");
    }

    return errors;
  }

  return (
    <Page
      t={t}
      open={open}
      tire={tire}
      subsidiaries={subsidiaries}
      brands={brands}
      revitalizedModels={revitalizedModels}
      rfids={rfids}
      tireModels={tireModels}
      setTireModels={setTireModels}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
    />
  );
}

TireForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onOpenSimilarForm: PropTypes.func.isRequired,
  setIsRegisterForm: PropTypes.func,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  account: state.Account.account,
  tire: state.Tire.Tire.tire,
  filter: state.Tire.Tire.filter_tire,
});

const mapDispatchToProps = (dispatch) => ({
  addTire: actionAddTire(dispatch),
  updateTire: actionUpdateTire(dispatch),
  getTire: actionGetTire(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getBrands: actionGetBrands(dispatch),
  getRfids: actionGetRfids(dispatch),
  getTires: actionGetTires(dispatch),
  clearTire: actionClearTire(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireForm);
