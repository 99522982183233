import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form, FieldArray } from "formik";

import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";

import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({ t, values, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Typography
        align="left"
        display="block"
        variant="overline"
        color="textSecondary"
        className={classnames(css.full_width, css.subtitle)}
      >
        {t("_labels.axle.singular")}
      </Typography>
      <FieldArray
        name="axle_policy"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {values.axle_policy.map((_, index) => (
              <div key={`axle_${index}`} className={classnames(css.field_wrap)}>
                <div className={classnames(css.horizontal)}>
                  <Field
                    fullWidth
                    required
                    variant="filled"
                    name={`axle_policy[${index}].axle_type`}
                    className={classnames(css.field)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    component={SelectField}
                    label={t("_labels.axle_field.label")}
                  >
                    <MenuItem value="DIRECTIONAL">
                      {t("_labels.axle_field.options.directional")}
                    </MenuItem>
                    <MenuItem value="TRACTION">
                      {t("_labels.axle_field.options.traction")}
                    </MenuItem>
                    <MenuItem value="TRAILER">
                      {t("_labels.axle_field.options.trailer")}
                    </MenuItem>
                    <MenuItem value="REFECTION">
                      {t("_labels.axle_field.options.refection")}
                    </MenuItem>
                  </Field>
                  {/* <Field
                    required
                    variant="filled"
                    margin="normal"
                    name={`axle_policy[${index}].good_depth`}
                    component={TextField}
                    type="number"
                    className={classnames(css.field)}
                    label={t("_labels.good_condition")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classnames(css.adornment)}
                          position="start"
                        >
                          mm
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    name={`axle_policy[${index}].scheduled_withdrawal`}
                    component={TextField}
                    type="number"
                    className={classnames(css.field)}
                    label={t("_labels.scheduled_withdrawal")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classnames(css.adornment)}
                          position="start"
                        >
                          mm
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    name={`axle_policy[${index}].critical_withdrawal`}
                    component={TextField}
                    type="number"
                    className={classnames(css.field)}
                    label={t("_labels.critical_withdrawal")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classnames(css.adornment)}
                          position="start"
                        >
                          mm
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className={classnames(css.w100)}>
                  <Divider className={classnames(css.full_width, css.m0)} />
                </div>
              </div>
            ))}

            <div className={classnames(css.full_width, css.buttons)}>
              <Button
                type="button"
                onClick={() => arrayHelpers.pop()}
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
                disabled={values.axle_policy.length <= 1}
              >
                <RemoveIcon />
              </Button>
              <Button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    axle_type: "",
                    good_depth: "",
                    scheduled_withdrawal: "",
                    critical_withdrawal: "",
                  })
                }
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
              >
                <AddIcon />
              </Button>
            </div>
          </>
        )}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
