import { GET_DAMAGE } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetDamage = (dispatch) => async (damageId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `damage/${damageId}`,
    });

    dispatch({
      type: GET_DAMAGE,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
