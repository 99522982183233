import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TableHead from "components/molecules/general/TableHead";
import TableToolbar from "components/molecules/general/TableToolbar";

import css from "./index.module.scss";

function Page({
  headers,
  rows,
  propertys,
  totalRows,
  size,
  selectable,
  selected,
  isSelected,
  sticky,
  handleClick,
  handleSelectAllClick,
  onDelete,
}) {
  return (
    <div className={classnames(css.w100)}>
      <Paper className={classnames(css.w100)}>
        {selectable && selected.length > 0 && (
          <TableToolbar selected={selected} onDelete={onDelete} />
        )}
        <TableContainer
          className={classnames({
            [css.sticky]: sticky,
          })}
        >
          <Table
            className={classnames(css.table)}
            aria-labelledby="tableTitle"
            size={size || "medium"}
            aria-label="enhanced table"
            stickyHeader={sticky}
          >
            <TableHead
              headers={headers}
              selectable={selectable}
              selected={selected}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rows={rows.map((row) => row.key)}
              totalRows={totalRows}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.key);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className={classnames({
                      [css.clickable]: !!row.onRowClick,
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      !!row.onRowClick && row.onRowClick();
                    }}
                  >
                    {selectable && (
                      <TableCell
                        padding="checkbox"
                        className={classnames(css.column)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={() => handleClick(row.key)}
                        />
                      </TableCell>
                    )}
                    {propertys.map((prop, index) => {
                      return (
                        <TableCell
                          padding={size ? "none" : "default"}
                          key={index}
                          className={classnames(css.column, {
                            [css.alert_code]: prop === "alert_code",
                            [css.red]:
                              prop === "alert_code" && row.color === "red",
                            [css.yellow]:
                              prop === "alert_code" && row.color === "yellow",
                            [css.selected]: row.bgColor === "yellow",
                          })}
                        >
                          {prop === "actions" ? (
                            <div className={classnames(css.flex)}>
                              {row[prop]?.toString().length > 0
                                ? row[prop]
                                : "-"}
                            </div>
                          ) : (
                            <>
                              {row[prop]?.toString().length > 0
                                ? row[prop]
                                : "-"}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

Page.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  propertys: PropTypes.array.isRequired,
  selectable: PropTypes.bool.isRequired,
  sticky: PropTypes.bool,
  selected: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  totalRows: PropTypes.number,
  onDelete: PropTypes.func,
};

export default Page;
