import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

// import $ from "resources/scripts/jquery-3.0.0.min.js";
import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";
import ContainerTemplate from "components/templates/general/Container";
import FilterRenewabilityIndexForm from "components/organisms/report/FilterRenewabilityIndexForm";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const indexFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

function Page({
  t,
  data,
  filter,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleSort,
  onChangePage,
  handleGenerateRenewabilityIndexExcel,
}) {
  return (
    <ContainerTemplate
      title={t("_titles.renewability_index_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).filter(
          (key) => !["sort_by", "order"].includes(key)
        ).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">Corporativo:</td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">Empresa:</td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">Sucursal:</td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">Marca:</td>
                      <td>{selectedFilter.brand}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">Modelo:</td>
                      <td>{selectedFilter.model}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">Medida:</td>
                      <td>{selectedFilter.size}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">Reporte índices de renovabilidad</h1>
            <h5 className="sinmp">
              Índices de renovabilidad (total de revitalizados / total de
              neumáticos) de modelos de neumáticos.
            </h5>
            {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")} {t("_general.text.and")}{" "}
                  {t("_labels.location.options.warehouse")}
                </small>
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="row pb-2">
          <div className="col-md-12">
            <div className="container-fluid">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateRenewabilityIndexExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              {data.data.length > 0 && (
                <div className="row">
                  <div className="container-fluid">
                    <h4
                      className="text-center"
                      style={{
                        backgroundColor: "#002849",
                        color: "white",
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      Índice de renovabilidad por modelo
                    </h4>
                    <div className="table-responsive">
                      <table className="table mb-5 text-center">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "dimgrey",
                              color: "white",
                              height: 50,
                              paddingTop: 10,
                            }}
                          >
                            <th scope="col" className="text-left">
                              Marca
                            </th>
                            <th scope="col" className="text-left">
                              Modelo
                            </th>
                            <th scope="col" className="text-left">
                              Medida
                            </th>
                            <th scope="col" className="text-center">
                              <span
                                className="sortable"
                                onClick={() => handleSort("original")}
                              >
                                {filter.sort_by === "original" &&
                                  (filter.order === "ASC" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  ))}
                                Neumáticos originales
                              </span>
                            </th>
                            <th scope="col" className="text-center">
                              <span
                                className="sortable"
                                onClick={() => handleSort("revitalized")}
                              >
                                {filter.sort_by === "revitalized" &&
                                  (filter.order === "ASC" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  ))}
                                Neumáticos revitalizados
                              </span>
                            </th>
                            <th scope="col" className="text-center">
                              Total de revitalizados
                            </th>
                            <th scope="col" className="text-center">
                              <span
                                className="sortable"
                                onClick={() => handleSort("index")}
                              >
                                {filter.sort_by === "index" &&
                                  (filter.order === "ASC" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  ))}
                                Índice de renovabilidad
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.data.map((row, index) => (
                            <tr key={index}>
                              <td className="text-left">{row.brand}</td>
                              <td className="text-left">{row.model}</td>
                              <td className="text-left">{row.size}</td>
                              <td className="text-center">
                                {formatter.format(row.original)}
                              </td>
                              <td className="text-center">
                                {formatter.format(row.revitalized)}
                              </td>
                              <td className="text-center">
                                {formatter.format(row.total_retreads ?? 0)}
                              </td>
                              <td className="text-center">
                                {indexFormatter.format(row.index)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Pagination
                        page={data.current_page}
                        totalPages={data.last_page}
                        onChangePage={onChangePage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FilterRenewabilityIndexForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleGenerateRenewabilityIndexExcel: PropTypes.func.isRequired,
};

export default Page;
