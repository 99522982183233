import React from "react";
import PropTypes from "prop-types";

import Tabs from "components/molecules/general/Tabs";

function Page({ title, optionsAppBar, tabLinks, className, children }) {
  return (
    <Tabs
      title={title}
      optionsAppBar={optionsAppBar}
      className={className}
      tabLinks={tabLinks}
    >
      {children}
    </Tabs>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  tabLinks: PropTypes.array.isRequired,
  optionsAppBar: PropTypes.node,
};
export default Page;
