import { UPDATE_CONDITION_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateConditionPolicy =
  (dispatch) =>
  async (fields, subsidiaryId, vehicleId, vehicleConditionPolicyId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `subsidiary/${subsidiaryId}/condition/policy/vehicle/${vehicleId}/axle/${vehicleConditionPolicyId}`,
        data: fields,
      });

      dispatch({
        type: UPDATE_CONDITION_POLICY,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
