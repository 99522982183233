import { GET_VEHICLE_ALERTS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehicleAlerts =
  (dispatch) =>
  async (
    {
      vehicle_brand_id: vehicleBrandId,
      vehicle_type_id: vehicleTypeId,
      vehicle_id: vehicleId,
      driver_id: driverId,
      page,
      order = "DESC",
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `alert/vehicles`,
        params: {
          vehicle_brand_id: vehicleBrandId,
          vehicle_type_id: vehicleTypeId,
          vehicle_id: vehicleId,
          driver_id: driverId,
          page,
          order,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLE_ALERTS,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
