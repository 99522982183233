import {
  CLEAR_TEMPERATURE_POLICIES,
  CLEAR_TEMPERATURE_POLICY,
  GET_TEMPERATURE_POLICIES,
  GET_TEMPERATURE_POLICY,
  UPDATE_TEMPERATURE_POLICY,
} from "store/actions";

const initialState = {
  policy: {
    temperature_policy_id: 0,
  },
  policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEMPERATURE_POLICIES:
      return {
        ...state,
        policies: action.payload,
      };

    case CLEAR_TEMPERATURE_POLICIES:
      return {
        ...state,
        policies: { ...initialState.policies },
      };

    case GET_TEMPERATURE_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

    case CLEAR_TEMPERATURE_POLICY:
      return {
        ...state,
        policy: { ...initialState.policy },
      };

    case UPDATE_TEMPERATURE_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

    default:
      return state;
  }
}
