import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionSetRenewabilityIndexFilters } from "store/actions/reports/renewability_index/setTireRenewabilityIndexFilters";
import { actionClearRenewabilityIndexFilters } from "store/actions/reports/renewability_index/clearRenewabilityIndexFilters";
import { actionSetSelectedRenewabilityIndexFilters } from "store/actions/reports/renewability_index/setSelectedRenewabilityFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";

export function FilterRenewabilityIndexForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    setNotification,
    setRenewabilityIndexFilters,
    clearRenewabilityIndexFilters,
    getCorporates,
    getBrands,
    getSizes,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      brands: "",
      models: "",
      sizes: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));

        data = await getBrands(
          {
            scope: "brand_id,name,status",
            brandType: "TIRE",
          },
          false
        );
        setBrands(data.sort(alphabeticalSort("name")));

        data = await getSizes(
          {
            scope: "tire_size_id,size,status",
          },
          false
        );
        setSizes(data.sort(alphabeticalSort("size")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.company_id ? filter.company_id.split(",") : [],
        subsidiary_id: filter.subsidiary_id
          ? filter.subsidiary_id.split(",")
          : [],
        brands: filter.brands || "",
        models: filter.models || "",
        sizes: filter.sizes || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.company_id = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.company_id = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiary_id = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiary_id = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.brands) {
        params.brands = values.brands;
        const brand = brands.find((brand) => brand.brand_id == values.brands);
        setSelectedFilters({ brand: brand.name });
      }

      if (values.sizes) {
        params.sizes = values.sizes;
        const size = sizes.find((size) => size.tire_size_id == values.sizes);
        setSelectedFilters({ size: size.size });
      }

      if (values.models) {
        params.models = values.models;
        const model = models.find(
          (model) => model.tire_model_id == values.models
        );
        setSelectedFilters({ model: model.name });
      }

      await setRenewabilityIndexFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    clearRenewabilityIndexFilters();
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      brands={brands}
      models={models}
      sizes={sizes}
      setModels={setModels}
      t={t}
    />
  );
}

FilterRenewabilityIndexForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.RenewabilityIndex.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setRenewabilityIndexFilters: actionSetRenewabilityIndexFilters(dispatch),
  clearRenewabilityIndexFilters: actionClearRenewabilityIndexFilters(dispatch),
  setSelectedFilters: actionSetSelectedRenewabilityIndexFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  getBrands: actionGetBrands(dispatch),
  getSizes: actionGetTireSizes(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterRenewabilityIndexForm);
