import React, { useState } from "react";
import PropTypes from "prop-types";
import { fieldToTextField } from "formik-material-ui";

import Page from "./page";

function CheckboxField({
  children,
  className,
  name,
  label,
  tooltip,
  form,
  field,
  ...props
}) {
  const rest = fieldToTextField({ form, field, ...props });
  const [value, setValue] = useState(field.value);
  const handleChange = () => {
    if (!field.value) {
      setValue(true);
      form.setFieldValue(field.name, true);
    } else {
      setValue(false);
      form.setFieldValue(field.name, false);
    }
  };

  return (
    <Page
      name={name}
      value={value}
      label={label}
      className={className}
      tooltip={tooltip}
      handleChange={handleChange}
      {...rest}
    >
      {children}
    </Page>
  );
}

CheckboxField.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  helperText: PropTypes.string,
  changePassword: PropTypes.bool,
  className: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
};

export default CheckboxField;
