import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Typography from "@material-ui/core/Typography";

// import VisibilityIcon from "@material-ui/icons/Visibility";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
// import { Link } from "react-router-dom";

// const formatter = new Intl.NumberFormat("en", {
//   maximumFractionDigits: 2,
//   minimumFractionDigits: 0,
// });

// const costKmFormatter = new Intl.NumberFormat("en", {
//   maximumFractionDigits: 4,
//   minimumFractionDigits: 4,
// });

function Page({ t, open, onClose, data, division, depth }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <div className={classnames(css.title, css.pr8)}>
            <span>{division}</span>
            <span>
              {t("_general.tires")}
              {`: ${data.length}`}
            </span>
          </div>
        </DialogTitle>

        <DialogContent className={classnames(css.box)}>
          <Typography
            noWrap
            variant="subtitle1"
            className={classnames({
              [css.rojo]: depth.includes("CRITICAL"),
              [css.amarillo]: depth.includes("SCHEDUL"),
              [css.verde]: depth.includes("GOOD"),
            })}
          >
            {t(
              `_labels.summary_report.depth_condition.${removeCharacters(
                depth,
                "_"
              ).toLowerCase()}`
            )}
          </Typography>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <td className="text-center">{t("_labels.code")}</td>
                  <td className="text-center">{t("_labels.model")}</td>
                  <td className="text-center">{t("_labels.size")}</td>
                  <td className="text-center">{t("_labels.location.label")}</td>
                  <td className="text-center">
                    {t("_labels.vehicle_type_axle_position.label")}
                  </td>
                  <td className="text-center">
                    {t("_labels.vehicle_type_axle_position.options.refection")}
                  </td>
                  <td className="text-center">{t("_labels.depth")}</td>
                  <td className="text-center">{t("_labels.review_date")}</td>
                </tr>
              </thead>
              <tbody>
                {data.map((tire, index) => (
                  <tr key={index}>
                    <td className="text-center">{tire["code"]}</td>
                    <td className="text-center">{tire["model"]}</td>
                    <td className="text-center">{tire["size"]}</td>
                    <td className="text-center">{tire["location"] || "-"}</td>
                    <td className="text-center">
                      {tire["real_position"] || "-"}
                    </td>
                    <td className="text-center">
                      {tire["is_refection"]
                        ? t("_labels.yes")
                        : t("_labels.no")}
                    </td>
                    <td className="text-center">{`${tire["depth"]} mm`}</td>
                    <td className="text-center">
                      {moment(tire["tire_review_date"]).format("LL")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  division: PropTypes.string,
  depth: PropTypes.string,
  data: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string, replacer = "") {
  return string.replaceAll(/[,\s\.\/]/g, replacer);
}
