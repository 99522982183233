import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Typography from "@material-ui/core/Typography";
import TireTabs from "components/organisms/tire/TireTabs";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import TableResponsive from "components/organisms/general/TableResponsive";
import ImageDialog from "components/organisms/general/ImageDialog";

import css from "./index.module.scss";

function Page({
  t,
  wears,
  image,
  imageDialogTitle,
  openImage,
  handleOpenImage,
  handleCloseImage,
}) {
  return (
    <TireTabs
      className={classnames({
        [css.empty]: wears.length === 0,
      })}
    >
      {wears.length > 0 ? (
        <>
          <Typography variant="h5" className={classnames(css.title)}>
            {t("_general.wear_list")}
          </Typography>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={wears.map((wear) => ({
                key: wear.tire_wear_id,
                name: t(`_damage.name.${wear.wear.name}`),
                comment: wear.comment,
                actioned_by: t("_labels.actioned_by", {
                  person: `${wear.created_by.name} ${
                    wear.created_by.last_name_1
                  } ${wear.created_by.last_name_2 || ""}`,
                  date: moment(wear.created_at).format("LL"),
                  time: moment(wear.created_at).format("h:mm:a"),
                }),
                image: {
                  tooltipPermit: !wear.image ? t("_general.no_image") : "",
                  imageUrl: wear.image,
                  title: t(`_damage.name.${wear.wear.name}`),
                },
              }))}
              headers={[
                { id: "name", label: t("_labels.name.singular") },
                { id: "comment", label: t("_labels.comment.singular") },
                { id: "actioned_by", label: t("_labels.registered_by") },
                { id: "actions", label: "" },
              ]}
              onImage={handleOpenImage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_wears")} />
      )}

      <ImageDialog
        open={openImage}
        onClose={handleCloseImage}
        title={imageDialogTitle}
        image={image}
      />
    </TireTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  wears: PropTypes.array.isRequired,
  imageDialogTitle: PropTypes.string,
  image: PropTypes.string,
  openImage: PropTypes.bool.isRequired,
  handleOpenImage: PropTypes.func.isRequired,
  handleCloseImage: PropTypes.func.isRequired,
};

export default Page;
