import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import RadioInput from "components/atoms/general/RadioInput";
import SelectField from "components/molecules/general/SelectField";
import RadioField from "components/molecules/general/RadioField";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  values,
  alerts,
  subsidiaries,
  brands,
  tireModels,
  tireModelVariations,
  tireSizes,
  revitalizedBrands,
  revitalizedModels,
  providers,
  warehouses,
  vehicles,
  onClose,
  isSubmitting,
  isValid,
  setFieldValue,
  initialValues,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.field)}
      >
        {t("_labels.tire_status")}
      </Typography>
      <Field
        required
        name="status"
        component={RadioField}
        classNameControl={classnames(css.ml8, css.mr8)}
      >
        <RadioInput value="enabled">
          {t("_general.enabled.plural.male")}
        </RadioInput>
        <RadioInput value="disabled">
          {t("_general.disabled.plural.male")}
        </RadioInput>
      </Field>

      <Field
        required
        name="with_alerts"
        component={RadioField}
        classNameControl={classnames(css.ml8, css.mr8)}
      >
        <RadioInput value="1">{t("_labels.with_alerts")}</RadioInput>
        <RadioInput value="0">{t("_labels.without_alerts")}</RadioInput>
      </Field>

      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.field)}
      >
        {t("_labels.date.range")}
      </Typography>
      <Field
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.from")}
        className={classnames(css.medium_field)}
        required={!!values.date_to}
      />

      <Field
        name="date_to"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.to")}
        className={classnames(css.medium_field)}
        required={!!values.date_from}
      />

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        multiple
        name="alert_code"
        component={SelectField}
        label={t("_labels.alerts")}
        className={classnames(css.field)}
      >
        {alerts.map((alert, index) => {
          return (
            <MenuItem key={index} value={alert.code}>
              {alert.colloquial_name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="brands"
        name="brands"
        multiple
        autoHighlight
        disableClearable={initialValues.brands.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("brands", value)}
        value={values.brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand_field.label")}
            variant="filled"
            helperText={t("_labels.can_select_many_feminine")}
          />
        )}
      />

      <Field
        id="models"
        name="models"
        autoHighlight
        disableClearable={initialValues.models !== null}
        blurOnSelect
        component={Autocomplete}
        options={tireModels}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("models", value)}
        value={values.models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.tire_model_variation_field.default")}
        defaultValue={
          tireModelVariations.some(
            (tireModelVariation) =>
              tireModelVariation.tire_model_variation_id ===
              values.tire_model_variation_id
          )
            ? null
            : values.tire_model_variation_id
        }
        name="model_variations"
        component={SelectField}
        label={t("_labels.tire_model_variation_field.label")}
        helperText={t("_labels.tire_model_variation_field.multiple")}
        className={classnames(css.field)}
      >
        {tireModelVariations.map((variation, index) => {
          return (
            <MenuItem
              key={index}
              value={variation.tire_model_variation_id.toString()}
              disabled={!variation.status || !variation.approved}
            >
              {`${variation.tire_size.size} ${variation.number_layers}`}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="revitalized_brands"
        name="revitalized_brands"
        autoHighlight
        disableClearable={initialValues.revitalized_brands !== null}
        blurOnSelect
        component={Autocomplete}
        options={revitalizedBrands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("revitalized_brands", value)}
        value={values.revitalized_brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.revitalized_brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="revitalized_models"
        name="revitalized_models"
        autoHighlight
        disableClearable={initialValues.revitalized_models !== null}
        blurOnSelect
        component={Autocomplete}
        options={revitalizedModels}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          option.revitalized_tire_model_id === value.revitalized_tire_model_id
        }
        onChange={(_, value) => setFieldValue("revitalized_models", value)}
        value={values.revitalized_models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.revitalized_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        variant="filled"
        name="invoice_folio"
        component={TextField}
        label={t("_labels.invoice_folio.label")}
        className={classnames(css.medium_field)}
      />

      <Field
        name="invoice_date"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.invoice_date")}
        className={classnames(css.medium_field)}
      />

      <Field
        name="DOT_initial"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.dot_initial")}
        className={classnames(css.medium_field)}
      />

      <Field
        name="DOT_final"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.dot_final")}
        className={classnames(css.medium_field)}
      />

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_size"
        component={SelectField}
        label={t("_labels.size")}
        className={classnames(css.medium_field)}
      >
        {tireSizes.map((size, index) => {
          return (
            <MenuItem
              key={index}
              value={size.tire_size_id.toString()}
              disabled={!size.status || !size.approved}
            >
              {size.size}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="providers"
        component={SelectField}
        label={t("_labels.provider")}
        className={classnames(css.medium_field)}
      >
        {providers.map((provider, index) => {
          return (
            <MenuItem
              key={index}
              value={provider.provider_id.toString()}
              disabled={!provider.status}
            >
              {provider.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiaries"
        multiple
        component={SelectField}
        label={t("_labels.subsidiary.singular")}
        helperText={t("_labels.tire_model_variation_field.multiple")}
        className={classnames(css.medium_field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="warehouses"
        component={SelectField}
        label={t("_labels.warehouse.singular")}
        className={classnames(css.medium_field)}
      >
        {warehouses.map((warehouse, index) => {
          return (
            <MenuItem
              key={index}
              value={warehouse.warehouse_id.toString()}
              disabled={!warehouse.status}
            >
              {warehouse.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="vehicle"
        name="vehicle"
        autoHighlight
        disableClearable={initialValues.vehicle !== null}
        blurOnSelect
        component={Autocomplete}
        options={vehicles}
        getOptionLabel={(option) => option.economic_number}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          option.vehicle_id === value.vehicle_id
        }
        onChange={(_, value) => setFieldValue("vehicle", value)}
        value={values.vehicle}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t(`_labels.vehicle.singular`)}
            variant="filled"
          />
        )}
      />

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="condition"
        component={SelectField}
        label={t("_labels.condition")}
        className={classnames(css.medium_field)}
      >
        <MenuItem value="ORIGINAL_NEW">
          {t("_labels.tire_condition.options.original_new")}
        </MenuItem>
        <MenuItem value="ORIGINAL_USED">
          {t("_labels.tire_condition.options.original_used")}
        </MenuItem>
        <MenuItem value="RETREAD_NEW">
          {t("_labels.tire_condition.options.retread_new")}
        </MenuItem>
        <MenuItem value="RETREAD_USED">
          {t("_labels.tire_condition.options.retread_used")}
        </MenuItem>
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="location"
        component={SelectField}
        label={t("_labels.location.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="WAREHOUSE">
          {t("_labels.location.options.warehouse")}
        </MenuItem>
        <MenuItem value="MOUNT">{t("_labels.location.options.mount")}</MenuItem>
        <MenuItem value="REVITALIZATION">
          {t("_labels.location.options.revitalization")}
        </MenuItem>
        <MenuItem value="REPAIR">
          {t("_labels.location.options.repair")}
        </MenuItem>
        <MenuItem value="PILE">{t("_labels.location.options.pile")}</MenuItem>
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  alerts: PropTypes.array.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default Page;
