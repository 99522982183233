import policy from "./policy";
import CompanyForm from "./CompanyForm";
import FilterCompanyForm from "./FilterCompanyForm";
import InfoCompanyCard from "./InfoCompanyCard";
import LinkCompanySubsidiary from "./LinkCompanySubsidiary";
import LinkCompanyUser from "./LinkCompanyUser";
import InfoCompanyTolerancePolicyDialog from "./InfoCompanyTolerancePolicyDialog";

export default {
  policy,
  company_form: CompanyForm,
  filter_company_form: FilterCompanyForm,
  info_company_card: InfoCompanyCard,
  link_company_subsidiary: LinkCompanySubsidiary,
  link_company_user: LinkCompanyUser,
  info_company_tolerance_policy_dialog: InfoCompanyTolerancePolicyDialog,
};
