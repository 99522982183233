import React, { useEffect } from "react";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";

import Page from "./page";

export function Form({
  setTireModels,
  setTireModelVariations,
  setRevitalizedModels,
  ...rest
}) {
  const {
    values,
    getTireModels,
    setNotification,
    getTireModelVariations,
    getRevitalizedTireModels,
  } = rest;

  const t = useLang();

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getTireModels(
          {
            brands: values.brands.join(),
            scope: "tire_model_id,name,status",
          },
          false
        );
        setTireModels(dataTireModels.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.brands.length > 0) {
      fetchData();
    }
  }, [values.brands]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModelVariations = [];
        dataTireModelVariations = await getTireModelVariations(
          {
            tireModelId: values.models,
            scope:
              "tire_model_variation_id,tire_size.tire_size_id,tire_size.size,tire_size.status,status,number_layers",
          },
          false
        );

        setTireModelVariations(dataTireModelVariations);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.models) {
      fetchData();
    }
  }, [values.models]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getRevitalizedTireModels(
          {
            brands: values.revitalized_brand,
            scope: "revitalized_tire_model_id,name,status",
          },
          false
        );
        setRevitalizedModels(dataTireModels.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.revitalized_brand) {
      fetchData();
    }
  }, [values.revitalized_brand]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setTireModels: PropTypes.func.isRequired,
  setTireModelVariations: PropTypes.func.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  setRevitalizedModels: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  getTireModels: actionGetTireModels(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
