import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import ColorInputField from "components/molecules/general/ColorInputField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  isUpdate,
  isSubmitting,
  tireApplicationTypes,
  updateVehicleTypeAxlePermit,
  createVehicleTypeAxlePermit,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        name="position"
        component={SelectField}
        disabled={isUpdate && !updateVehicleTypeAxlePermit}
        label={t("_labels.vehicle_type_axle_position.label")}
        className={classnames(css.position)}
        defaultText={t("position.default")}
      >
        <MenuItem value="FRONTAL">
          {t("_labels.vehicle_type_axle_position.options.frontal")}
        </MenuItem>
        <MenuItem value="CENTRAL">
          {t("_labels.vehicle_type_axle_position.options.central")}
        </MenuItem>
        <MenuItem value="REAR">
          {t("_labels.vehicle_type_axle_position.options.rear")}
        </MenuItem>
        <MenuItem value="REFECTION">
          {t("_labels.vehicle_type_axle_position.options.refection")}
        </MenuItem>
      </Field>

      <Field
        required
        variant="filled"
        disabled={isUpdate}
        name="tire_quantity"
        component={SelectField}
        label={t("_labels.tire_quantity")}
        className={classnames(css.tire_quantity)}
        defaultText={t("tire_quantity.default")}
      >
        <MenuItem value="1" disabled={values.position !== "REFECTION"}>
          1
        </MenuItem>
        <MenuItem value="2">2</MenuItem>
        <MenuItem value="4">4</MenuItem>
      </Field>

      <div className={classnames(css.color_div)}>
        <Field
          required
          name="color"
          component={ColorInputField}
          className={classnames(css.color)}
          disabled={isUpdate && !updateVehicleTypeAxlePermit}
        />
      </div>

      <Field
        required
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.tire_application.label.plural")}
        name="tire_application"
        disabled={isUpdate && !updateVehicleTypeAxlePermit}
        defaultText={t("_labels.tire_application.default.plural")}
        className={classnames(css.tire_application)}
        helperText={t("_labels.tire_application.helper_text")}
        defaultValue={
          tireApplicationTypes.some(
            (type) => type.tire_application_id === values.tire_application_id
          )
            ? null
            : values.tire_application_id
        }
      >
        {tireApplicationTypes.map((type, index) => {
          return (
            <MenuItem
              key={index}
              value={type.tire_application_id}
              disabled={!type.status}
            >
              {t(
                `_labels.tire_application.options.${type.tire_application_id.toLowerCase()}`
              )}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        required
        variant="filled"
        name="axle_type"
        component={SelectField}
        disabled={isUpdate && !updateVehicleTypeAxlePermit}
        label={t("_labels.axle_field.label")}
        className={classnames(css.tire_application)}
        defaultText={t("_labels.axle_field.default")}
      >
        <MenuItem value="REFECTION">
          {t("_labels.axle_field.options.refection")}
        </MenuItem>
        <MenuItem value="DIRECTIONAL">
          {t("_labels.axle_field.options.directional")}
        </MenuItem>
        <MenuItem value="TRACTION">
          {t("_labels.axle_field.options.traction")}
        </MenuItem>
        <MenuItem value="TRAILER">
          {t("_labels.axle_field.options.trailer")}
        </MenuItem>
      </Field>

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={
            isSubmitting ||
            !isValid ||
            !createVehicleTypeAxlePermit ||
            (isUpdate && !updateVehicleTypeAxlePermit)
          }
          tooltip={
            !createVehicleTypeAxlePermit
              ? t("permits.create.vehicle_type_axle")
              : isUpdate && !updateVehicleTypeAxlePermit
              ? t("permits.update.vehicle_type_axle")
              : ""
          }
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  updateVehicleTypeAxlePermit: PropTypes.bool.isRequired,
  createVehicleTypeAxlePermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
