import { http } from "store/actions/http";
import { DELETE_VEHICLE_ALERT } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteVehicleAlert =
  (dispatch) => async (vehicleId, alertId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: `DELETE`,
        path: `vehicle/${vehicleId}/alert/${alertId}`,
      });

      dispatch({
        type: DELETE_VEHICLE_ALERT,
        payload: alertId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
