import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";

export default function Page({
  t,
  initialErrors,
  initialValues,
  handleValidate,
  handleSubmit,
  confirmSubmit,
  formRef,
  updateVehicleReviewPermit,
}) {
  return (
    <Formik
      enableReinitialize
      initialErrors={initialErrors}
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={confirmSubmit}
      innerRef={formRef}
    >
      {(p) => (
        <FormWrap
          t={t}
          handleSubmit={handleSubmit}
          updateVehicleReviewPermit={updateVehicleReviewPermit}
          {...p}
        />
      )}
    </Formik>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  formRef: PropTypes.any,
  updateVehicleReviewPermit: PropTypes.bool.isRequired,
};

function FormWrap({
  t,
  isSubmitting,
  isValid,
  handleSubmit,
  errors,
  updateVehicleReviewPermit,
}) {
  return (
    <Form
      onSubmit={handleSubmit}
      className={`d-flex justify-content-start ${
        errors.odometer ? "align-items-baseline" : "align-items-center"
      }`}
      style={{ gap: "4px" }}
    >
      <div>
        <Field
          required
          name="odometer"
          type="number"
          min={0}
          className={`form-control form-control-sm ${
            errors.odometer ? "is-invalid" : ""
          }`}
          disabled={!updateVehicleReviewPermit}
        />
        {errors.odometer && (
          <div className="invalid-feedback">{errors.odometer}</div>
        )}
      </div>

      {updateVehicleReviewPermit ? (
        <button
          type="submit"
          className="btn btn-sm btn-warning"
          style={{ minWidth: "fit-content" }}
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.edit")}
        </button>
      ) : (
        <BootstrapTooltip
          title={t("_permits.update.vehicle_review")}
          placement="left"
        >
          <div>
            <button
              type="button"
              className="btn btn-sm btn-warning"
              style={{ minWidth: "fit-content", pointerEvents: "none" }}
              disabled
            >
              {t("_buttons.edit")}
            </button>
          </div>
        </BootstrapTooltip>
      )}
    </Form>
  );
}

FormWrap.propTypes = {
  t: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateVehicleReviewPermit: PropTypes.bool.isRequired,
};
