import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ContainerTemplate from "components/templates/general/Container";
import SearchInput from "components/molecules/general/SearchInput";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import Pagination from "components/molecules/general/Pagination";
import ConceptForm from "components/organisms/concept/ConceptForm";

import { TYPE_VALUES } from "utils/concept";

import css from "./index.module.scss";

function Page({
  t,
  search,
  concepts,
  conceptId,
  openForm,
  handleOpenForm,
  handleCloseForm,
  handleChangePage,
  handleSearch,
  handleDelete,
  createConceptPermit,
  updateConceptPermit,
  deleteConceptPermit,
}) {
  return (
    <ContainerTemplate
      title={t("_general.concepts")}
      className={classnames({
        [css.empty]: concepts.data.length === 0,
      })}
      options={<SearchInput search={search ?? ""} onChange={handleSearch} />}
    >
      {concepts.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th>{t("_labels.name.singular")}</th>
                    <th>{t("_labels.type")}</th>
                    <th>{t("_labels.description")}</th>
                    <th>{t("_labels.is_critical")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {concepts.data.map((concept) => (
                    <tr key={concept.concept_id}>
                      <td>{concept.name}</td>
                      <td>
                        {t(
                          TYPE_VALUES.find(
                            (el) => el.value == concept.type_value
                          ).label
                        )}
                      </td>
                      <td>{concept.description}</td>
                      <td>
                        {concept.critical ? t("_labels.yes") : t("_labels.no")}
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-start"
                          style={{ gap: "6px" }}
                        >
                          <Tooltip
                            title={
                              !updateConceptPermit
                                ? t("_permits.update.concept")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateConceptPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleOpenForm(concept.concept_id)
                                }
                                disabled={!updateConceptPermit}
                              >
                                <EditIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>

                          <Tooltip
                            title={
                              !deleteConceptPermit
                                ? t("_permits.delete.concept")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-danger d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !deleteConceptPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() => handleDelete(concept.concept_id)}
                                disabled={!deleteConceptPermit}
                              >
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={concepts.current_page}
              totalPages={concepts.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_concepts")} />
      )}

      <FixedButton
        color="primary"
        onClick={handleOpenForm}
        disabled={!createConceptPermit}
        tooltip={!createConceptPermit ? t("_permits.create.concept") : ""}
      />

      <ConceptForm
        open={openForm}
        onClose={handleCloseForm}
        conceptId={conceptId}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  concepts: PropTypes.object.isRequired,
  conceptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  openForm: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  createConceptPermit: PropTypes.bool,
  updateConceptPermit: PropTypes.bool,
  deleteConceptPermit: PropTypes.bool,
};

export default Page;
