import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { actionGetVehiclesMount } from "store/actions/vehicle/getVehiclesMount";
import { actionClearVehiclesMount } from "store/actions/vehicle/clearVehiclesMount";
import { actionSetVehicleFiltersMount } from "store/actions/vehicle/setVehicleFiltersMount";
import { actionClearVehicleFiltersMount } from "store/actions/vehicle/clearVehicleFiltersMount";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehiclesInProcess } from "store/actions/vehicle/getVehiclesInProcess";

import { useLang } from "hooks/lang";

import Page from "./page";

export function HomeMount({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const {
    vehicles,
    filter,
    setVehicleStatus,
    getVehicles,
    clearVehicles,
    setVehicleFilters,
    clearVehicleFilters,
    setNotification,
    getVehiclesInProcess,
  } = rest;

  const [openFilter, setOpenFilter] = useState(false);
  const [vehiclesInProcess, setVehiclesInProcess] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehiclesInProcess({ page: 1, ...filter });
        setVehiclesInProcess(response.total);
        await getVehicles({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearVehicles();
    };
  }, [filter]);

  async function onChangePage(page) {
    try {
      await getVehicles({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [vehicles.current_page]);

  useEffect(() => {
    return () => {
      clearVehicleFilters();
    };
  }, []);

  function handleSearch(search) {
    try {
      setVehicleFilters({ search });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleFilterInProcess() {
    try {
      if (!filter.in_process) {
        setVehicleFilters({ in_process: 1 });
      } else {
        setVehicleFilters({ in_process: undefined });
      }
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDialogFilter() {
    setOpenFilter(true);
  }
  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function onRedirect(vehicleId) {
    history.push(`/vehicle/${vehicleId}/mount`);
  }
  return (
    <Page
      t={t}
      vehicles={vehicles}
      vehiclesInProcess={vehiclesInProcess}
      filter={filter}
      search={filter.search}
      handleSearch={handleSearch}
      openFilter={openFilter}
      handleCloseFilter={handleCloseFilter}
      handleDialogFilter={handleDialogFilter}
      onChangePage={onChangePage}
      clearFiltersAction={clearVehicleFilters}
      setFiltersAction={setVehicleFilters}
      setVehicleStatus={setVehicleStatus}
      onRedirect={onRedirect}
      handleFilterInProcess={handleFilterInProcess}
    />
  );
}

HomeMount.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehicles: state.Vehicle.VehicleMount.vehicles,
  filter: state.Vehicle.VehicleMount.filter_vehicle,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicles: actionGetVehiclesMount(dispatch),
  clearVehicles: actionClearVehiclesMount(dispatch),
  setVehicleFilters: actionSetVehicleFiltersMount(dispatch),
  clearVehicleFilters: actionClearVehicleFiltersMount(dispatch),
  setNotification: actionSetNotification(dispatch),
  getVehiclesInProcess: actionGetVehiclesInProcess(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeMount);
