import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  origin,
  destination,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>{t("_titles.rotate")}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Typography
            variant="body1"
            display="block"
            className={classnames(css.mr8, css.ml8)}
          >
            {origin && destination
              ? destination?.vehicle_tire.length > 0
                ? t("_messages.rotate", {
                    position: origin.position,
                    position2: destination.position,
                  })
                : t("_messages.single_rotate", {
                    position: origin.position,
                    position2: destination.position,
                  })
              : ""}
          </Typography>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                origin={origin}
                destination={destination}
                onClose={onClose}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  origin: PropTypes.object,
  destination: PropTypes.object,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
