import React from "react";

import PropTypes from "prop-types";

import classnames from "classnames";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ size, page, color, totalPages, handleChangePage }) {
  return (
    <Pagination
      showFirstButton
      showLastButton
      hidePrevButton
      hideNextButton
      color={color || "secondary"}
      count={totalPages}
      page={page}
      size={size}
      className={classnames(css.pagination)}
      onChange={(event, page) => handleChangePage(page)}
      renderItem={(item) =>
        item.type === "page" && item.selected ? (
          <Button
            variant="fab"
            size={item.size}
            color={item.color}
            disabled={item.disabled}
            onClick={item.onClick}
            className={classnames(css.item_fab)}
          >
            {item.page}
          </Button>
        ) : (
          <PaginationItem {...item} className={classnames(css.item)} />
        )
      }
    />
  );
}

Page.propTypes = {
  size: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  color: PropTypes.string,
  handleChangePage: PropTypes.func.isRequired,
};

export default Page;
