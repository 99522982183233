import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import Page from "./page";

export function Form({ name, dirty, setFieldValue, ...rest }) {
  const t = useLang();
  const { values, fields, isUpdate } = rest;

  if (name === "subsidiary" || name === "warehouse" || name === "association") {
    useEffect(() => {
      const form = { setFieldValue };
      fields
        .find((field) => field.name === "country")
        .handleChange(form, values.country, values.state);
      fields
        .find((field) => field.name === "state")
        .handleChange(form, values.state, values.country, values.province);
      if (!isUpdate) {
        setFieldValue("province", "");
      } else {
        if (dirty) {
          setFieldValue("province", "");
        }
      }
    }, [values.country, values.state]);
  }
  return <Page {...rest} setFieldValue={setFieldValue} name={name} t={t} />;
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
