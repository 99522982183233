import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";

function Page({
  t,
  open,
  vehicles,
  isUpdate,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <DialogMediaQuery maxWidth="md" size="xs" open={open} onClose={onClose}>
      <DialogTitle>
        {isUpdate ? t("_titles.update.policy") : t("_titles.new.policy")}
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={handleValidate}
        >
          {(p) => (
            <Form
              onClose={onClose}
              vehicles={vehicles}
              isUpdate={isUpdate}
              {...p}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
