import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchInput from "components/molecules/general/SearchInput";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import AddIcon from "@material-ui/icons/Add";

import AssociationTabs from "components/organisms/association/AssociationTabs";
import TableMultiple from "components/organisms/general/TableMultiple";
import Pagination from "components/molecules/general/Pagination";

import PressurePolicyForm from "components/organisms/association/PressurePolicyForm";
import PressurePolicyFilterForm from "components/organisms/association/PressurePolicyFilterForm";
import UpdatePressurePolicyForm from "components/organisms/association/UpdatePressurePolicyForm";
import MultiPressurePolicyForm from "components/organisms/association/MultiPressurePolicyForm";
import VehicleTypePressurePolicyForm from "components/organisms/association/VehicleTypePressurePolicyForm";

import css from "./index.module.scss";

function Page({
  t,
  policies,
  search,
  vehicle,
  pressurePolicy,
  openForm,
  openUpdateForm,
  openVehicleTypeForm,
  openFilter,
  updateVehiclePressurePolicyPermit,
  deleteVehiclePressurePolicyPermit,
  createVehiclePressurePolicyPermit,
  handleSearch,
  handleEdit,
  handleCloseUpdateForm,
  handleOpenForm,
  handleOpenVehicleTypeForm,
  handleCloseVehicleTypeForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  handleChangePage,
  openMultiple,
  vehicles,
  handleOpenMultiple,
  handleCloseMultiple,
}) {
  return (
    <AssociationTabs
      className={classnames({
        [css.empty]: policies.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            onClick={handleOpenFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ?? ""} onChange={handleSearch} />
        </>
      }
    >
      {policies.data.length > 0 ? (
        <>
          <div className={classnames(css.button)}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpenVehicleTypeForm}
            >
              {t("_buttons.register_by_vehicle_type")}
            </Button>
          </div>
          <div className={classnames(css.container)}>
            <TableMultiple
              headers={[
                { label: t("_general.vehicles"), colspan: 4 },
                { label: t("_labels.axle.plural"), colspan: 4 },
              ]}
              rows={policies.data.map((vehicle) => ({
                id: vehicle.commissioned_vehicle_id,
                enrollment: vehicle?.enrollment,
                economic_number: vehicle?.economic_number,
                vehicle_type_name: vehicle?.vehicle_type.name,
                vehicle_type: vehicle.vehicle_type,
                add: (
                  <Button
                    variant="icon"
                    color="secondary"
                    tooltip={
                      !createVehiclePressurePolicyPermit
                        ? t("_permits.create.pressure_policy")
                        : ""
                    }
                    disabled={
                      !createVehiclePressurePolicyPermit ||
                      checkIfCanCreatePolicy(vehicle)
                    }
                    onClick={() => handleOpenForm(vehicle)}
                  >
                    <AddIcon />
                  </Button>
                ),
                multiple:
                  vehicle.commissioned_vehicle_pressure_policy?.length > 0
                    ? vehicle.commissioned_vehicle_pressure_policy.map(
                        (policy) => ({
                          id: policy.commissioned_pressure_policy_id,
                          axle: t(
                            `_labels.axle_field.options.${policy.axle_type.toLowerCase()}`
                          ),
                          recommended_pressure: `${policy.recommended_pressure} psi`,
                          tolerance: `${policy.tolerance}%`,
                          edit: {
                            tooltipPermit: !updateVehiclePressurePolicyPermit
                              ? t("_permits.update.pressure_policy")
                              : "",
                            onEdit: () => handleEdit(vehicle, policy),
                          },
                          delete: {
                            tooltipPermit: !deleteVehiclePressurePolicyPermit
                              ? t("_permits.delete.pressure_policy")
                              : "",
                            onDelete: () =>
                              handleDelete(
                                vehicle.commissioned_vehicle_id,
                                policy.commissioned_pressure_policy_id
                              ),
                          },
                        })
                      )
                    : [
                        {
                          id: "",
                          axle: "",
                          recommended_pressure: "",
                          tolerance: "",
                        },
                      ],
              }))}
              propertys={[
                "economic_number",
                "enrollment",
                "vehicle_type_name",
                "add",
                "multiple",
              ]}
              nestedPropertys={["axle", "recommended_pressure", "tolerance"]}
              nestedHeaders={[
                t("_labels.economic_number"),
                t("_labels.auto_enrollment"),
                t("_labels.vehicle_type.label"),
                "",
                t("_labels.axle.plural"),
                t("_labels.tire_model_variation.recommended_pressure"),
                t("_labels.tire_model_variation.tolerance"),
                "",
              ]}
              multipleProperty={"multiple"}
              selectable={true}
              selectAction={handleOpenMultiple}
              selectActionButton={t("_general.set")}
              objectSelection={true}
              totalRows={policies.total}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={policies.current_page}
              totalPages={policies.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_pressure_policies")} />
      )}

      <PressurePolicyForm
        open={openForm}
        onClose={handleCloseForm}
        vehicle={vehicle}
      />

      <UpdatePressurePolicyForm
        open={openUpdateForm}
        onClose={handleCloseUpdateForm}
        vehicle={vehicle}
        pressurePolicy={pressurePolicy}
      />

      <MultiPressurePolicyForm
        open={openMultiple}
        onClose={handleCloseMultiple}
        vehicles={vehicles}
      />

      <VehicleTypePressurePolicyForm
        open={openVehicleTypeForm}
        onClose={handleCloseVehicleTypeForm}
      />
      <PressurePolicyFilterForm open={openFilter} onClose={handleCloseFilter} />
    </AssociationTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  policies: PropTypes.object.isRequired,
  search: PropTypes.string,
  openForm: PropTypes.bool.isRequired,
  openUpdateForm: PropTypes.bool.isRequired,
  openVehicleTypeForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleOpenVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  updateVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  deleteVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  createVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  vehicle: PropTypes.object,
  pressurePolicy: PropTypes.object,
  openMultiple: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleCloseUpdateForm: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenMultiple: PropTypes.func.isRequired,
  handleCloseMultiple: PropTypes.func.isRequired,
};

export default Page;

function checkIfCanCreatePolicy(vehicle) {
  const validAxles = Object.keys(
    vehicle.vehicle_type.vehicle_type_axle.reduce((acc, val) => {
      if (!acc[val.axle_type]) {
        acc[val.axle_type] = true;
      }

      return acc;
    }, {})
  );

  const currentPolicies = Object.keys(
    vehicle.commissioned_vehicle_pressure_policy.reduce((acc, val) => {
      if (!acc[val.axle_type]) {
        acc[val.axle_type] = true;
      }

      return acc;
    }, {})
  );

  return validAxles.every((axle) => currentPolicies.includes(axle));
}
