import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Link from "@material-ui/core/Link";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import ContainerTemplate from "components/templates/general/Container";
import DamageDialog from "components/organisms/tire/damage/DamageDialog";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";

import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});
function Page({
  t,
  movementHistory,
  onChangePage,
  damages,
  openDamageDialog,
  handleOpenDamageDialog,
  handleCloseDamageDialog,
}) {
  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: movementHistory.data.length === 0,
      })}
      title={`${t("_labels.movement_sheet")}`}
    >
      {movementHistory.data.length > 0 ? (
        <>
          <div
            className={classnames(css.container)}
            style={{ fontFamily: "roboto" }}
          >
            <div className="table-responsive">
              <table className="table text-center">
                <thead style={{ backgroundColor: "#4eacdf", color: "white" }}>
                  <tr>
                    <th colSpan={7}>{`${t("_labels.vehicle.singular")} ${
                      movementHistory.data[0].vehicle.economic_number
                    }`}</th>
                  </tr>
                  <tr>
                    <th>{t("_labels.review_type.label")}</th>
                    <th>{t("_labels.status")}</th>
                    <th>{t("_labels.date.label")}</th>
                    <th>{t("_general.vehicle_km")}</th>
                    <th>{t("_labels.user.singular")}</th>
                    <th>{t("_labels.comment.singular")}</th>
                    <th>{t("_general.tire_reviews")}</th>
                  </tr>
                </thead>
                <tbody>
                  {movementHistory.data.map((review) => (
                    <tr
                      key={review.vehicle_review_id}
                      className="table-bordered"
                    >
                      <td className={classnames(css.table_cell)}>
                        {t(
                          `_labels.review_type.options.${review.review_type
                            .toLowerCase()
                            .replace(/\s|\//g, "_")}`
                        )}
                      </td>
                      <td
                        className={classnames({
                          [css.table_cell]: true,
                          [css.error]: !review.end_time,
                        })}
                      >
                        {review.end_time
                          ? t("_labels.finalized")
                          : t("_labels.inspection_in_process")}
                      </td>
                      <td className={classnames(css.table_cell)}>
                        {moment(review.date).format("LL hh:mm:ss a")}
                      </td>
                      <td className={classnames(css.table_cell)}>
                        {formatter.format(review.odometer)} km
                      </td>
                      <td className={classnames(css.table_cell)}>{`${
                        review.created_by.name
                      } ${review.created_by.last_name_1} ${
                        review.created_by.last_name_2 || ""
                      }`}</td>
                      <td className={classnames(css.table_cell)}>
                        {review.observation}
                      </td>
                      <td className={classnames(css.table_cell, "p-0")}>
                        <table className="table table-striped m-0 p-0">
                          <thead className="thead-dark">
                            <tr>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.code")}
                              </th>
                              <th className={classnames(css.table_cell)}>
                                Rfid
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.vehicle_type_axle_position.label")}
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.detail")}
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.pressure")} (psi)
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.travel.singular")} (km)
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.mileage")} (km)
                              </th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.depth")} (mm)
                              </th>
                              <th className={classnames(css.table_cell)}></th>
                              <th className={classnames(css.table_cell)}>
                                {t("_labels.user.singular")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {review.movements.map((tireReview) => (
                              <tr key={tireReview.tire_review_id}>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  <Link
                                    href={`/tire/${tireReview?.movement_tire.tire_cycle.tire_id}/history`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {tireReview?.movement_tire.tire_cycle.tire
                                      .code || "-"}
                                  </Link>
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  <Link
                                    href={`/tire/${tireReview?.movement_tire.tire_cycle.tire_id}/history`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {tireReview?.movement_tire.tire_cycle.tire
                                      .device_code || "-"}
                                  </Link>
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {tireReview?.movement_tire.vehicle_tire
                                    .length > 0
                                    ? tireReview?.movement_tire.vehicle_tire[0]
                                        .vehicle_type_axle_tire.position
                                    : ""}
                                </td>
                                <td className={classnames(css.table_cell)}>
                                  {tireReview.comment || ""}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {formatter.format(tireReview.pressure)}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {kmFormatter.format(tireReview.last_travel)}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {kmFormatter.format(
                                    tireReview.accumulated_mileage
                                  )}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {formatter.format(
                                    getMinDepth(
                                      tireReview.tire_review_depth_line
                                    )
                                  )}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {tireReview.tire_damage.length > 0 && (
                                    <button
                                      className={classnames(
                                        css.btn,
                                        "btn-sm",
                                        "btn-danger"
                                      )}
                                      onClick={() =>
                                        handleOpenDamageDialog(
                                          tireReview.tire_damage
                                        )
                                      }
                                    >
                                      <ReportProblemRoundedIcon
                                        fontSize="small"
                                        className="mr-1"
                                      />
                                      {t("_labels.damage.plural")}
                                    </button>
                                  )}
                                </td>
                                <td
                                  className={classnames(
                                    css.table_cell,
                                    "align-middle"
                                  )}
                                >
                                  {`${tireReview.created_by.name} ${
                                    tireReview.created_by.last_name_1
                                  } ${tireReview.created_by.last_name_2 || ""}`}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={movementHistory.current_page}
              totalPages={movementHistory.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <DamageDialog
        open={openDamageDialog}
        onClose={handleCloseDamageDialog}
        damages={damages}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  movementHistory: PropTypes.object.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  filter: PropTypes.object,
  onChangePage: PropTypes.func.isRequired,
  setVehicleMovementHistoryFilters: PropTypes.func.isRequired,
  clearVehicleMovementHistoryFilters: PropTypes.func.isRequired,
  openDamageDialog: PropTypes.bool.isRequired,
  damages: PropTypes.array.isRequired,
  handleOpenDamageDialog: PropTypes.func.isRequired,
  handleCloseDamageDialog: PropTypes.func.isRequired,
};

export default Page;
