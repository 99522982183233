import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaryWarehouses } from "store/actions/subsidiary/warehouse/getSubsidiaryWarehouses";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/warehouse/LinkTireWarehouseForm/Form";

export function Form({ subsidiaryId, ...rest }) {
  const t = useLang(NAMESPACE);
  const [warehouses, setWarehouses] = useState([]);
  const { setNotification, getSubsidiaryWarehouses } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getSubsidiaryWarehouses(
          {
            subsidiaryId,
            scope: "warehouse_id,warehouse.name,warehouse.status",
          },
          false
        );

        setWarehouses(
          data
            .map((warehouse) => ({
              name: warehouse.warehouse.name,
              status: warehouse.warehouse.status,
              warehouse_id: warehouse.warehouse_id,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (subsidiaryId) {
      fetchData();
    }
  }, []);

  return <Page {...rest} t={t} warehouses={warehouses} />;
}

Form.propTypes = {
  subsidiaryId: PropTypes.number,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSubsidiaryWarehouses: actionGetSubsidiaryWarehouses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
