export default {
  title: {
    subsidiary: "Vínculo de sucursal con almacén",
    warehouse: "Vínculo de almacén con sucursal",
  },
  subsidiary_name: "Nombre de la sucursal",
  warehouse_name: "Nombre del almacén",
  buttons: {
    unlink_warehouse: "Desvincular almacén",
    view_warehouse: "Ver almacén",
  },
};
