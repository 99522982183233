import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import { useLang } from "hooks/lang";

export const NAMESPACE = "pages/review/HomeReview";

import Page from "./page";

function PileTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [pileData, setPileData] = useState({});
  const [pileDataCost, setPileDataCost] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);

  const { showProgress, setNotification, currentTab } = rest;

  useEffect(() => {
    let newSubsidiaries = [];
    if (pileData && Object.keys(pileData).length > 0) {
      newSubsidiaries = Object.keys(pileData);
    }
    if (pileDataCost && Object.keys(pileDataCost).length > 0) {
      newSubsidiaries = [...Object.keys(pileDataCost), ...newSubsidiaries];
    }
    newSubsidiaries = [...new Set(newSubsidiaries)];
    setSubsidiaries(newSubsidiaries);
  }, [pileData, pileDataCost]);

  useEffect(() => {
    async function getPileData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/pile/depth/cost`,
          params: {
            group_by: currentTab,
          },
        });
        const newData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformData(subsidiaryValues);
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData)) {
            datasets.push(
              getDataset(
                values,
                t(
                  `_labels.attribution.options.${key
                    .replaceAll(" ", "_")
                    .toLowerCase()}`
                ),
                false,
                key,
                currentTab == "SUBSIDIARY" ? subsidiary : undefined
              )
            );
          }
          newData[subsidiary] = {
            datasets: datasets,
          };
        }
        setPileData(newData);
        const newCostData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformData(subsidiaryValues, "total_cpd");
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData)) {
            datasets.push(
              getDataset(
                values,
                t(
                  `_labels.attribution.options.${key
                    .replaceAll(" ", "_")
                    .toLowerCase()}`
                ),
                false,
                key,
                subsidiary
              )
            );
          }
          newCostData[subsidiary] = {
            datasets: datasets,
          };
        }
        setPileDataCost(newCostData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getPileData();
  }, [currentTab]);

  return (
    <Page
      t={t}
      pileData={pileData}
      pileDataCost={pileDataCost}
      subsidiaries={subsidiaries}
    />
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PileTab);

function transformData(subsidiaryObject, valueName = "statistics") {
  const newData = {};
  newData["total"] = {};
  const attributions = new Set();
  for (const values of Object.values(subsidiaryObject)) {
    for (const key of Object.keys(values)) {
      attributions.add(key);
    }
  }
  const totals = {};
  let totalArray = [];
  for (const attribution of attributions) {
    newData[attribution] = [];
    for (const [date, values] of Object.entries(subsidiaryObject)) {
      if (attribution in values) {
        newData[attribution].push({
          fecha: date,
          total: values[attribution][valueName],
        });
        if (totals[date]) {
          totals[date] += values[attribution][valueName];
        } else {
          totals[date] = values[attribution][valueName];
        }
      }
    }
    totalArray = [];
    for (const [date, value] of Object.entries(totals)) {
      totalArray.push({
        fecha: date,
        total: value,
      });
    }
    newData[attribution] = newData[attribution].sort((a, b) => {
      return new Date(a.fecha) - new Date(b.fecha);
    });
  }
  newData["total"] = totalArray;
  newData["total"] = newData["total"].sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  return newData;
}

function getDataset(data, label, disablePoints = false, key, subsidiary) {
  const colors = [
    "rgba(255, 99, 132, 1.0)",
    "rgba(54, 162, 235, 1.0)",
    "rgba(255, 206, 86, 1.0)",
    "rgba(75, 192, 192, 1.0)",
    "rgba(153, 102, 255, 1.0)",
    "rgba(255, 159, 64, 1.0)",
    "rgba(100, 159, 64, 1.0)",
    "rgba(255, 58, 86, 1.0)",
  ];
  return {
    label: label,
    data: data.map((el) => ({
      ...el,
      fecha: moment(el.fecha).format("YYYY-MM"),
      key,
      subsidiary,
    })),
    yAxisID: "y",
    fill: false,
    pointWidth: 2,
    pointRadius: disablePoints ? 0 : 2,
    pointHoverRadius: 4,
    borderColor: colors,
    backgroundColor: colors,
    tension: 0.1,
    pointBackgroundColor: "rgb(50, 50, 50, 0.8)",
    pointBorderColor: "rgb(50, 50, 50, 0.8)",
  };
}
