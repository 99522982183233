export default {
  disabled_division: "Division deshabilitada",
  subsidiary: "Sucursal",
  permits: {
    change_status: "Sin permisos para {action} división",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar la división?",
    enable: "¿Estas seguro de habilitar la división?",
  },
};
