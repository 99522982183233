import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetGpsOdometer = (dispatch) => async (gpsId, vehicleId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `gps/${gpsId}/odometer/${vehicleId}`,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
