import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionCloseNotification } from "store/actions/general/notification";

import Page from "./page";

export const NAMESPACE = "components/organisms/general/Notification";

function Notification({
  id,
  message,
  code,
  onAction,
  textAction,
  hideDuration,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const [open, setOpen] = useState(true);
  const [msg, setMsg] = useState(null);
  const { closeNotification } = rest;

  useEffect(() => {
    setMsg(message);
  }, [message]);

  useEffect(() => {
    if (code) {
      if (t(`_api.${code}`) !== `api.${code}`) {
        setMsg(t(`_api.${code}`));
      }
    }
  }, [code]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    closeNotification(id);
  }

  function handleAction() {
    onAction();
    handleClose();
  }

  if (msg) {
    return (
      <Page
        open={open}
        hideDuration={hideDuration}
        message={msg}
        code={code}
        handleClose={handleClose}
        onAction={onAction}
        textAction={textAction}
        handleAction={handleAction}
      />
    );
  }

  return <></>;
}

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  onAction: PropTypes.func,
  message: PropTypes.string.isRequired,
  code: PropTypes.string,
  textAction: PropTypes.string,
  hideDuration: PropTypes.number,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  closeNotification: actionCloseNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
