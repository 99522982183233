import atoms from "./atoms";
import molecules from "./molecules";
import organisms from "./organisms";
import templates from "./templates";

export default {
  atoms,
  molecules,
  organisms,
  templates,
};
