export default {
  damages: "Daños",
  wears: "Desgastes",
  retirement_causes: "Causas de retiro",
  permits: {
    list: {
      tire_damage: "Sin permisos para listar daños de neumáticos",
      tire_wear: "Sin permisos para listar desgastes de neumáticos",
    },
  },
};
