import { http } from "store/actions/http";
import { DELETE_LINK_TIRE_WITH_WAREHOUSE } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteLinkTireWithWarehouse =
  (dispatch) =>
  async ({ warehouseId, tireId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "DELETE",
        path: `warehouse/${warehouseId}/tire/${tireId}`,
      });

      dispatch({
        type: DELETE_LINK_TIRE_WITH_WAREHOUSE,
        payload: tireId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
