import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(250,255,250, 0.8)",
    color: "#333333",
    fontWeight: "normal",
    fontSize: "14px",
    minWidth: 240,
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  arrow: {
    color: "rgba(250,255,250, 0.8)",
  },
}));

const useHideStyles = makeStyles(() => ({
  tooltip: {
    visibility: "hidden",
  },
  arrow: {
    visibility: "hidden",
  },
}));

function Page({ t, tire, children, hidden, placement }) {
  const classes = !!hidden ? useHideStyles() : useStyles();
  return (
    <Tooltip
      disableHoverListener={!!hidden}
      classes={classes}
      title={
        <div className={classnames(css.info_tire)}>
          {tire.vehicle_tire_id ? (
            <>
              {tire.movement_tire.tire_cycle.tire.code && (
                <span>
                  <b>{t("_labels.code")}:</b>{" "}
                  {tire.movement_tire.tire_cycle.tire.code}
                </span>
              )}
              <span>
                <b>{t("_labels.dot")}:</b>{" "}
                {tire.movement_tire.tire_cycle.tire.dot || "-"}
              </span>
              {tire.movement_tire.tire_cycle.tire.device_code && (
                <span>
                  <b>{t("_labels.device_code")}:</b>{" "}
                  {tire.movement_tire.tire_cycle.tire.device_code}
                </span>
              )}
              <span>
                <b>{t("_labels.brand")}:</b>{" "}
                {tire.movement_tire.tire_cycle.variation.tire_model.brand.name}
              </span>
              <span>
                <b>{t("_labels.tire_model_field.label")}:</b>{" "}
                {tire.movement_tire.tire_cycle.variation.tire_model.name}
              </span>
              {tire.movement_tire.tire_cycle.revitalized && (
                <>
                  <span>
                    <b>{t("_labels.revitalized_brand_field.label")}:</b>{" "}
                    {tire.movement_tire.tire_cycle.revitalized.brand.name}
                  </span>
                  <span>
                    <b>{t("_labels.revitalized_tire_model_field.label")}:</b>{" "}
                    {tire.movement_tire.tire_cycle.revitalized.name}
                  </span>
                </>
              )}
              <span>
                <b>{t("_labels.size")}:</b>{" "}
                {tire.movement_tire.tire_cycle.variation.tire_size.size}
              </span>
              <span>
                <b>{t("_labels.layer")}:</b>{" "}
                {tire.movement_tire.tire_cycle.variation.number_layers}
              </span>
              <span>
                <b>{t("_labels.condition")}:</b>{" "}
                {t(
                  `_labels.tire_condition.options.${tire.movement_tire.tire_cycle.tire_condition_id.toLowerCase()}`
                )}
              </span>
              <span>
                <b>{t("_labels.number_patches")}:</b>{" "}
                {tire.movement_tire.tire_cycle.number_patch}
              </span>

              {tire.movement_tire.tire_review.length > 0 && (
                <>
                  <br />
                  <span>
                    <b>{t("_labels.last_review_data")}</b>
                  </span>
                  <span>
                    <b>{t("_labels.review_date")}:</b>
                    {` ${moment(
                      tire.movement_tire.tire_review[
                        tire.movement_tire.tire_review.length - 1
                      ].date
                    ).format("L")}`}
                  </span>
                  <span>
                    <b>{t("_labels.mileage")}:</b>
                    {` ${tire.movement_tire.tire_cycle.tire_travel} km`}
                  </span>
                  {tire.movement_tire.tire_review[
                    tire.movement_tire.tire_review.length - 1
                  ].tire_review_depth_line && (
                    <span>
                      <b>{t("_labels.depth")}:</b>
                      {` ${getMinDepth(
                        tire.movement_tire.tire_review[
                          tire.movement_tire.tire_review.length - 1
                        ].tire_review_depth_line
                      )} mm`}
                    </span>
                  )}

                  <span>
                    <b>{t("_labels.pressure")}:</b>
                    {` ${
                      tire.movement_tire.tire_review[
                        tire.movement_tire.tire_review.length - 1
                      ].pressure
                    } psi`}
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              {tire.code && (
                <span>
                  <b>{t("_labels.code")}:</b> {tire.code}
                </span>
              )}

              <span>
                <b>{t("_labels.dot")}:</b> {tire.dot || "-"}
              </span>

              {tire.device_code && (
                <span>
                  <b>{t("_labels.device_code")}:</b> {tire.device_code}
                </span>
              )}
              <span>
                <b>{t("_labels.brand")}:</b>{" "}
                {tire.cycle.variation.tire_model.brand.name}
              </span>
              <span>
                <b>{t("_labels.tire_model_field.label")}:</b>{" "}
                {tire.cycle.variation.tire_model.name}
              </span>
              {tire.cycle.revitalized && (
                <>
                  <span>
                    <b>{t("_labels.revitalized_brand_field.label")}:</b>{" "}
                    {tire.cycle.revitalized.brand.name}
                  </span>
                  <span>
                    <b>{t("_labels.revitalized_tire_model_field.label")}:</b>{" "}
                    {tire.cycle.revitalized.name}
                  </span>
                </>
              )}
              <span>
                <b>{t("_labels.size")}:</b>{" "}
                {tire.cycle.variation.tire_size.size}
              </span>
              <span>
                <b>{t("_labels.layer")}:</b>{" "}
                {tire.cycle.variation.number_layers}
              </span>
              <span>
                <b>{t("_labels.condition")}:</b>{" "}
                {t(
                  `_labels.tire_condition.options.${tire.cycle.tire_condition_id.toLowerCase()}`
                )}
              </span>

              {tire.cycle.movement_tire.warehouse_tire.length > 0 && (
                <span>
                  <b>{t("_labels.warehouse.singular")}:</b>{" "}
                  {tire.cycle.movement_tire.warehouse_tire[0].warehouse.name}
                </span>
              )}
              <span>
                <b>{t("_labels.number_patches")}:</b> {tire.cycle.number_patch}
              </span>

              {tire.cycle.movement_tire.tire_review.length > 0 && (
                <>
                  <br />
                  <span>
                    <b>{t("_labels.last_review_data")}</b>
                  </span>
                  <span>
                    <b>{t("_labels.review_date")}:</b>
                    {` ${moment(
                      tire.cycle.movement_tire.tire_review[
                        tire.cycle.movement_tire.tire_review.length - 1
                      ].date
                    ).format("L")}`}
                  </span>
                  <span>
                    <b>{t("_labels.mileage")}:</b>
                    {` ${tire.cycle.tire_travel} km`}
                  </span>
                  {tire.cycle.movement_tire.tire_review[
                    tire.cycle.movement_tire.tire_review.length - 1
                  ].tire_review_depth_line && (
                    <span>
                      <b>{t("_labels.depth")}:</b>
                      {` ${getMinDepth(
                        tire.cycle.movement_tire.tire_review[
                          tire.cycle.movement_tire.tire_review.length - 1
                        ].tire_review_depth_line
                      )} mm`}
                    </span>
                  )}
                  {tire.cycle.movement_tire.movement !== "WAREHOUSE" && (
                    <span>
                      <b>{t("_labels.pressure")}:</b>
                      {` ${
                        tire.cycle.movement_tire.tire_review[
                          tire.cycle.movement_tire.tire_review.length - 1
                        ].pressure
                      } psi`}
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>
      }
      placement={placement || "right"}
    >
      {children}
    </Tooltip>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tire: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  placement: PropTypes.string,
};

export default Page;
