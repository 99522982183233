import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import Page from "./page";
import { alphabeticalSort } from "utils/sort";

export function DepthTireDialog({ data, ...rest }) {
  const t = useLang();

  const sortedData = getSortedData(data, t);
  return <Page {...rest} t={t} data={sortedData} />;
}

DepthTireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  division: PropTypes.string.isRequired,
  depth: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DepthTireDialog);

function getSortedData(data) {
  return data.sort(alphabeticalSort("code"));
}
