import { SET_COMMISSIONED_DRIVER_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetCommissionedDriverStatus =
  (dispatch) => async (commissionedDriverId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `commissioned/${commissionedDriverId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_COMMISSIONED_DRIVER_STATUS,
        payload: commissionedDriverId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
