import React from "react";
import PropTypes from "prop-types";
// import FilterListIcon from "@material-ui/icons/FilterList";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

// import Button from "components/molecules/general/Button";
// import SearchInput from "components/molecules/general/SearchInput";
import ContainerTemplate from "components/templates/general/Container";
import Table from "./Table";
import tiresIcon from "resources/img/icons/rueda.png";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  style: "currency",
  currency: "MXN",
  currencyDisplay: "narrowSymbol",
});

function Page({
  t,
  vehicle,
  tires,
  handleSort,
  sortBy,
  sortOrder,
  handleGenerateExcel,
}) {
  return (
    <ContainerTemplate
      title={`${t("_labels.vehicle.singular")} ${
        vehicle.economic_number ?? ""
      }`}
      /* options={
    <>
      <Button className="bw mr-1" variant="icon">
        <FilterListIcon />
      </Button>
      <SearchInput search={""} onChange={() => { }} />
    </>
  } */
    >
      <button
        className="mx-5 mt-3 mb-1 btn btn-primary w-auto"
        style={{ fontFamily: "Poppins" }}
        disabled={tires.length == 0}
        onClick={handleGenerateExcel}
      >
        EXCEL
      </button>
      <div className="sidebar_font card mx-5 mt-1 shadow-sm">
        <div className="card-header sMarcoInfoFondoAzul">
          <h4 className="m-0">
            <img src={tiresIcon} width={36} className="mr-2" />
            {t("_labels.historic_installations")}
          </h4>
        </div>
        <div className="card-body">
          {tires.length > 0 ? (
            <>
              <div className="h4">
                <span className="mr-3">
                  {`${tires.length} ${t("_general.tires")}`}
                </span>
                <span>
                  {formatter.format(
                    tires.reduce((acc, val) => acc + val.current_cost, 0)
                  )}
                </span>
              </div>
              <Table
                t={t}
                tires={tires}
                handleSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
                formatter={formatter}
              />
            </>
          ) : (
            <EmptyListIcon text={t("_general.no_tires")} />
          )}
        </div>
      </div>
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func,
  vehicle: PropTypes.object,
  tires: PropTypes.array,
  handleSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  handleGenerateExcel: PropTypes.func,
};

export default Page;
