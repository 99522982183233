import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionSetTireHistoryFilters } from "store/actions/tire/setTireHistoryFilters";
import { actionClearTireHistoryFilters } from "store/actions/tire/clearTireHistoryFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterTireHistoryForm({ open, onClose, ...rest }) {
  const t = useLang();

  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const {
    filter,
    setNotification,
    setTireHistoryFilters,
    clearTireHistoryFilters,
    getProviders,
    getWarehouses,
    getVehicles,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      movement: "",
      number_cycle: "",
      tire_condition_id: "",
      warehouses: "",
      providers: "",
      vehicles: "",
      date_from: "",
      date_to: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        movement: filter.movement || "",
        number_cycle: filter.number_cycle || "",
        tire_condition_id: filter.tire_condition_id || "",
        warehouses: filter.warehouses || "",
        providers: filter.providers || "",
        vehicles: filter.vehicles || "",
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataProviders = [];
        dataProviders = await getProviders(
          {
            scope: "provider_id,name,status",
          },
          false
        );

        let dataWarehouses = [];
        dataWarehouses = await getWarehouses(
          {
            scope: "warehouse_id,name,status",
          },
          false
        );

        let dataVehicles = [];
        dataVehicles = await getVehicles(
          {
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        setProviders(dataProviders.sort(alphabeticalSort("name")));
        setWarehouses(dataWarehouses.sort(alphabeticalSort("name")));
        setVehicles(dataVehicles.sort(alphabeticalSort("economic_number")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  function handleClean() {
    clearTireHistoryFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.movement !== "") {
        params.movement = values.movement;
      }

      if (values.number_cycle !== "") {
        params.number_cycle = values.number_cycle;
      }

      if (values.tire_condition_id !== "") {
        params.tire_condition_id = values.tire_condition_id;
      }

      if (values.warehouses !== "") {
        params.warehouses = values.warehouses;
      }

      if (values.providers !== "") {
        params.providers = values.providers;
      }

      if (values.vehicles !== "") {
        params.vehicles = values.vehicles;
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      await setTireHistoryFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      providers={providers}
      warehouses={warehouses}
      vehicles={vehicles}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterTireHistoryForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Tire.History.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getProviders: actionGetProviders(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  setTireHistoryFilters: actionSetTireHistoryFilters(dispatch),
  clearTireHistoryFilters: actionClearTireHistoryFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTireHistoryForm);
