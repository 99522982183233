import React from "react";
import { useLang } from "hooks/lang";

import PropTypes from "prop-types";

import Page from "./page";

export const NAMESPACE = "pages/account/Login/Form";

function Form(props) {
  const t = useLang(NAMESPACE);
  return <Page {...props} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default Form;
