import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionSetDisposalFilters } from "store/actions/disposal/setDisposalFilters";
import { actionSetSelectedDisposalFilters } from "store/actions/disposal/setSelectedDisposalFilters";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { alphabeticalSort } from "utils/sort";
import { arrayJoin } from "utils/parse";

import Page from "./page";

export function FilterDisposalForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setDisposalFilters,
    getSubsidiaries,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      subsidiaries: [],
      date_from: "",
      date_to: "",
    },
  });

  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getSubsidiaries(
          { scope: "subsidiary_id,name,status", status: 1 },
          false
        );
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      const companies = getCompanyViaWorkArea(account, workArea);

      params.companies = companies.map((company) => company.company_id).join();
      setSelectedFilters({
        companies: {
          label: t("_labels.company_field.singular.label"),
          value: companies.map((company) => company.company.name).join(),
        },
      });

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiaries.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: {
            label: t("_labels.subsidiary_field.plural.label"),
            value: arrayJoin(
              subsidiary.map((subsidiary) => subsidiary.name),
              " , ",
              ` ${t("_general.text.and")} `
            ),
          },
        });
      } else {
        params.subsidiaries = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      await setDisposalFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    setDisposalFilters({
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    });

    setSelectedFilters({
      companies: {
        label: t("_labels.company_field.singular.label"),
        value: companies.map((company) => company.company.name).join(),
      },
      subsidiaries: {
        label: t("_labels.subsidiary_field.plural.label"),
        value: arrayJoin(
          subsidiaries.map((subsidiary) => subsidiary.subsidiary.name),
          " , ",
          ` ${t("_general.text.and")} `
        ),
      },
    });
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      subsidiaries={subsidiaries}
      t={t}
    />
  );
}

FilterDisposalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Disposal.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setDisposalFilters: actionSetDisposalFilters(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setSelectedFilters: actionSetSelectedDisposalFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterDisposalForm);
