import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionAddTpms } from "store/actions/tpms/addTpms";
import { actionGetTpmsSensor } from "store/actions/tpms/getTpmsSensor";
import { actionClearTpmsSensor } from "store/actions/tpms/clearTpmsSensor";
import { actionUpdateTpms } from "store/actions/tpms/updateTpms";
import { actionClearTpmsFilters } from "store/actions/tpms/clearTpmsFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function TpmsForm({ open, onClose, tpmsId, ...rest }) {
  const t = useLang();
  const {
    sensor,
    setNotification,
    getSubsidiaries,
    addTpms,
    clearTpmsFilters,
    getTpmsSensor,
    clearTpmsSensor,
    updateTpms,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      device_code: "",
      subsidiary_id: "",
    },
  });

  const [subsidiaries, setSubsidiaries] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            scope: "subsidiary_id,name",
            status: 1,
          },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTpmsSensor(tpmsId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && tpmsId) {
      fetchData();
    }

    return () => {
      clearTpmsSensor();
    };
  }, [open, tpmsId]);

  useEffect(() => {
    if (open && tpmsId && !!sensor.tpgps_id) {
      setInitialValues({
        device_code: sensor.device_code,
        subsidiary_id: sensor.subsidiary_id.toString(),
      });
    }
  }, [open, tpmsId, sensor]);

  useEffect(() => {
    if (open && tpmsId) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      ...values,
    };

    try {
      if (isUpdate) {
        await updateTpms(fields, tpmsId);
        setNotification({
          message: t("_messages.updated.tpms"),
        });
      } else {
        await addTpms(fields);
        setNotification({
          message: t("_messages.created.tpms"),
        });
      }
      resetForm();
      setSubmitting(false);
      onClose();
      clearTpmsFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      subsidiaries={subsidiaries}
    />
  );
}

TpmsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  tpmsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  sensor: state.Tpms.sensor,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  addTpms: actionAddTpms(dispatch),
  clearTpmsFilters: actionClearTpmsFilters(dispatch),
  getTpmsSensor: actionGetTpmsSensor(dispatch),
  clearTpmsSensor: actionClearTpmsSensor(dispatch),
  updateTpms: actionUpdateTpms(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TpmsForm);
