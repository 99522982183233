import { http } from "store/actions/http";
import { GET_REVIEW_PERFORMANCE_REPORT } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetReviewPerformanceReport =
  (dispatch) =>
  async (
    {
      date_from: dateFrom,
      date_to: dateTo,
      corporate_id: corporateId,
      companies,
      subsidiaries,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/review/performances`,
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          corporate_id: corporateId,
          companies,
          subsidiaries,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_REVIEW_PERFORMANCE_REPORT,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
