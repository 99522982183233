import React from "react";
import PropTypes from "prop-types";
import { fieldToTextField } from "formik-material-ui";

import Page from "./page";

function SelectField({
  size,
  MenuProps,
  onChange,
  children,
  className,
  multiple,
  form,
  field,
  defaultValue,
  defaultText,
  ...props
}) {
  const rest = fieldToTextField({ form, field, ...props });
  const handleChange = (event) => {
    form.setFieldValue(field.name, event.target.value);
    if (onChange) {
      onChange(form, event.target.value);
    }
  };

  return (
    <Page
      size={size}
      name={field.name}
      value={field.value}
      handleChange={handleChange}
      MenuProps={MenuProps}
      className={className}
      multiple={multiple}
      defaultValue={defaultValue ? defaultValue : ""}
      defaultText={defaultText}
      {...rest}
    >
      {children}
    </Page>
  );
}

SelectField.propTypes = {
  MenuProps: PropTypes.object,
  size: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  defaultValue: PropTypes.string,
  defaultText: PropTypes.string,
};

export default SelectField;
