import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import VehicleTypeTabs from "components/organisms/vehicle/type/VehicleTypeTabs";
import VehicleTypeForm from "components/organisms/vehicle/type/VehicleTypeForm";
import VehicleTypePreviewPanel from "components/organisms/vehicle/type/VehicleTypePreviewPanel";

import css from "./index.module.scss";

function Page({ vehicleTypeId, open, vehicleType, axles, handleClose }) {
  return (
    <VehicleTypeTabs className={classnames(css.container)}>
      <div className={css.links}>
        <VehicleTypePreviewPanel
          className={css.links__link}
          structureType={vehicleType.structure_type}
          axles={axles}
        />
      </div>
      <VehicleTypeForm
        onClose={handleClose}
        open={open}
        vehicleTypeId={vehicleTypeId}
      />
    </VehicleTypeTabs>
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  axles: PropTypes.array.isRequired,
  vehicleType: PropTypes.object.isRequired,
  vehicleTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Page;
