import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import { useLang } from "hooks/lang";

export const NAMESPACE = "pages/review/HomeReview";
import Page from "./page";
import { getSubsidiaryViaWorkArea } from "utils/workArea";

function SummaryTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [summaryData, setSummaryData] = useState([]);

  const { account, workArea, showProgress, setNotification, currentTab } = rest;

  useEffect(() => {
    async function getSummaryData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/summary/dashboard`,
          params: {
            level: currentTab.toLowerCase(),
          },
        });
        setSummaryData(response);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getSummaryData();
  }, [currentTab]);

  function handleClick(type, condition) {
    const [subsidiary] = getSubsidiaryViaWorkArea(account, workArea);
    const querySubsidiary =
      currentTab == "SUBSIDIARY"
        ? `&subsidiary=${subsidiary.subsidiary_id}`
        : "";
    const queryCondition = `${type}=${condition}`;
    window.open(
      `/reports/current/summary?${queryCondition}${querySubsidiary}`,
      "_blank"
    );
  }

  return (
    <Page
      t={t}
      currentTab={currentTab}
      data={summaryData}
      handleClick={handleClick}
    />
  );
}

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTab);
