export default {
  filter: {
    status: {
      corporate: "Corporate status",
      brand: "Brand status",
      size: "Size status",
      company: "Company status",
      division: "Division status",
      subsidiary: "Subsidiary status",
      association: "Association status",
      provider: "Provider status",
      wear: "Wear status",
      driver: "Driver status",
      user: "User status",
      warehouse: "Warehouse status",
      retirement_cause: "Retirement cause status",
      gps: "Gps status",
      damage: "Damage status",
      revitalized_tire_model: "Revitalized tire model status",
      alert: "Alert status",
      rfid: "Rfid status",
      tire_model: "Tire model status",
      vehicle_type: "Vehicle type status",
      vehicle: "Vehicle status",
      tpms: "Tpms sensor status",
    },
  },
  update: {
    corporate: "Update Corporate",
    brand: "Update Brand",
    company: "Update Company",
    subsidiary: "Update Subsidiary",
    association: "Update Association",
    size: "Update Size",
    company: "Update Company",
    division: "Update Division",
    tire_wear: "Update tire wear",
    user_subsidiary: "User to subsidiary link",
    provider: "Update Provider",
    wear: "Update Wear",
    provider: "Update provider",
    driver: "Update Driver",
    user: "Update user",
    user_role: "Update user role",
    warehouse: "Update Warehouse",
    retirement_cause: "Update Retirement cause",
    gps: "Update Gps",
    damage: "Update damage",
    revitalized_tire_model: "Update Revitalized tire model",
    alert: "Update alert",
    rfid: "Update Rfid",
    vehicle_type: "Update Vehicle type",
    company_policy: "Update Policies",
    tire_model: "Update Tire model",
    vehicle: "Update vehicle",
    policy: "Update policy",
    tire: "Update tire",
    tire_review: "Update last tire review",
    vehicle_review: "Update last vehicle review",
    helmet_value: "Update helmet value",
    checklist: "Update checklist",
    concept: "Update concept",
    block: "Update block",
    tpms_sensor: "Update tpms sensor",
  },
  link: {
    tire_with_division: "Link tire with division",
    tire_with_rfid: "Link tire with rfid",
    division: "Link division",
    vehicle_with_gps: "Link gps with vehicle",
    vehicle_with_driver: "Link vehicle with driver",
    tpms_with_tire: "Link tpms sensor with tire",
    tpms_with_vehicle: "Link TPMS sensors",
  },
  new: {
    corporate: "New Corporate",
    brand: "New Brand",
    size: "New Size",
    company: "New Company",
    division: "New Division",
    subsidiary: "New Subsidiary",
    association: "New Association",
    alert: "New Alert",
    provider: "New provider",
    wear: "New Wear",
    driver: "New Driver",
    user: "New user",
    user_role: "New user role",
    warehouse: "New Warehouse",
    retirement_cause: "New Retirement cause",
    gps: "New Gps",
    damage: "New damage",
    revitalized_tire_model: "New Revitalized tire model",
    alert: "New alert",
    rfid: "New Rfid",
    tire_model: "New Tire model",
    vehicle_type: "New Vehicle type",
    vehicle: "New vehicle",
    policy: "New policy",
    tire: "New tire",
    tire_review: "New tire review",
    helmet_value: "New helmet value",
    checklist: "New checklist",
    concept: "New concept",
    block: "New block",
    notification: "New notification",
    tpms_sensor: "New tpms sensor",
  },
  show: {
    alert: "Alert",
    link: {
      gps_with_vehicle: "Vehicle link with gps",
      gps_vehicle_link: "Gps vehicle link",
      user_subsidiary: {
        with_user: "User subsidiary link",
        with_subsidiary: "Subsidiary user link",
      },
      subsidiary_driver: {
        subsidiary: "Driver subsidiary link",
        driver: "Subsidiary driver link",
      },
    },
    tire: "Tire Information",
    inspection: "Inspection",
    checklist: "Checklist information",
  },
  history: {
    tire: {
      warehouse: "No warehouse history",
    },
  },
  warehouse_tire: "stored tire",
  register: {
    vehicles: "Register vehicles by excel",
    tires: "Register tires by excel",
    rfids: "Register rfids by excel",
    tpms: "Register tpms by excel",
    pile: "Register tires directly to scrap",
  },
  policy: {
    no_condition_policy: "No condition policies",
  },
  send: {
    revitalization: "Send to Retread",
    repair: "Send to Repair",
    pile: "Send to Pile",
    warehouse: "Send to Warehouse",
    multiple_to_warehouse: "Send Tires to Warehouse",
  },
  mount: "Mount tire in position {position}",
  vehicle_inspection: "Inspection of vehicle",
  vehicle_pressure_inspection: "Pressure review of vehicle",
  vehicle_identify_inspection: "Identify inspection of vehicle",
  mount_alerts: "Mount alerts",
  move: {
    vehicle: "Move vehicle",
    tire: "Move tire",
  },
  tire_performance: "Tire performance",
  tire_stats_report: "Tire stats",
  tire_stats_report_historic: "Tire stats (Historic)",
  pile_stats_report: "Tire pile stats",
  best_performance_report: "Best tires by performance",
  summary_report: "Summary",
  damages_wears: "Damages and Wears",
  dynamic_cost_report: "Cost per model",
  tire_alerts: "Tire alerts",
  pile_summary_report: "Pile summary",
  pile_performance_report: "Pile performance",
  pile_report: "Mm pile report",
  semaphore_reports: "Semaphore",
  renewability_index_report: "Renewability index",
  review_performance_report: "Review Performance",
  mount_report: "Mount tires report",
  report_warehouse: "Warehouse Tires",
  potential_savings_report: "Potential losses",
  user_review_report: "User review report",
  km_projection_report: "Km Projection Report",
  purchase_report: "Purchase report",
  km_pile_report: "Km pile report",
  rotate: "Rotate tires",

  vehicle_summary:
    "information on inspected and total fleet vehicles by vehicle type",
  quantity_vehicle: "Number of vehicles verified current report",
  quantity_tire: "Number of tires verified current report",
  total_vehicles: "Total vehicles in the fleet",
  total_tires: "Total tires in the fleet",
  tire_info:
    "Information on total tires in the fleet and those inspected, by type of application and by location",
  tires_by_application: "Tires by application",
  tires_by_location: "Tires by location",
  historic: "Historic",
  semaphore_report: "Reviewed tire history",
  fleet_semaphore_report: "Fleet mounted tires",
  tire_revitalized_report: "Tire retread report",
  summary_warehouse_report: "Dismounted tires report",
  summary_mount_report: "Mounted tires report",
  tire_repair_report: "Tire repair report",

  graphs: {
    pile_by_responsible: "Pile by Reason",
    pile_by_responsible_cost: "Pile Cost by Reason",
    original_vs_renewed: "Tire Purchases (Original vs Renewed)",
    vehicle_historic: "Vehicle Inspections",
    review_performance: "Review Performance",
    yearly_savings: "Savings per year",
    cost_km: {
      main: "Cost per km",
      cost: "Cost",
      km: "Kilometers",
    },
  },
  retread_rejection: "Send to warehouse by retread rejection",
  historical_summary: "Historical Summary",
  current_summary: "Current Summary",
  review_performance_company_report: "Review performance by company",
  completed_cycles_report: "Completed cycles report",
  bulk_pile: "Bulk Send to Scrap",
  tpms_alerts: "Tpms alerts",
  scheduled_withdrawal_report: "Scheduled Withdrawal Report",
};
