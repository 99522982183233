import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/core/styles";

import Router from "components/organisms/general/Router";
import Notification from "components/organisms/general/Notification";
import Progress from "components/molecules/general/Progress";
import WorkAreaDialog from "components/organisms/account/WorkAreaDialog";
import NotificationDialog from "components/organisms/notification/NotificationDialog";

import { setLanguage } from "resources/lang";

import { MuiTheme } from "config/theme";

import { actionGetProfile } from "store/actions/account/profile";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetRedirect } from "store/actions/general/redirect";
import { actionLogout } from "store/actions/account/logout";
import { actionRefreshToken } from "store/actions/account/refreshToken";
import { actionSetIntervalRefresh } from "store/actions/account/setIntervalRefresh";
import { actionGetUserPermits } from "store/actions/account/getUserPermits";
import { actionSetWorkArea } from "store/actions/account/setWorkArea";

function Application({ ...rest }) {
  const {
    account,
    workArea,
    isAuthenticate,
    notifications,
    progress,
    setWorkArea,
    setRedirect,
    setNotification,
    showProgress,
    getProfile,
    getUserPermits,
    language,
    intervalRefresh,
    logout,
    refreshToken,
    setIntervalRefresh,
    userNotifications,
  } = rest;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLanguage(language);
  }, [language]);

  useEffect(() => {
    async function fetchData() {
      try {
        await refreshToken();
      } catch (error) {
        logout();
        setRedirect("/");
        setNotification(error, true);
      }
    }
    if (isAuthenticate && !intervalRefresh) {
      setIntervalRefresh(setInterval(fetchData, 2400000));
    }
  }, [isAuthenticate, intervalRefresh]);

  useEffect(() => {
    if (account && account.need_change_password) {
      setRedirect("/change/password");
    }
  }, [account]);

  useEffect(() => {
    async function fetchData() {
      const id = showProgress();
      try {
        await getUserPermits();
      } catch (error) {
        setWorkArea({});
        setNotification(error, true);
      }
      showProgress(id);
      setLoading(false);
    }

    if (account) {
      if (isAuthenticate && workArea.key) {
        fetchData();
        return;
      }

      setLoading(false);
    }

    if (!isAuthenticate) {
      setLoading(false);
    }
  }, [isAuthenticate, workArea, account]);

  useEffect(() => {
    async function fetchData() {
      const id = showProgress();

      try {
        await getProfile();
      } catch (error) {
        logout();
        setRedirect("/");
        setNotification(error, true);
      }

      showProgress(id);
    }
    if (isAuthenticate) {
      fetchData();
    }
  }, [isAuthenticate]);

  const [isTranslatorActive, setTranslatorActive] = useState(false);

  useEffect(() => {
    // Cambios en el atributo lang
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "lang") {
          setTranslatorActive(true);
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true });

    // Limpiar el observador cuando el componente se desmonta
    return () => {
      observer.disconnect();
    };
  }, []);

  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    setOpenNotifications(userNotifications.length > 0);
  }, [userNotifications]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <StylesProvider injectFirst>
        <CssBaseline />
        {isTranslatorActive && (
          <div
            className="h2 text-white"
            style={{
              height: "250px",
              zIndex: 999999,
              position: "fixed",
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.83)",
              display: "flex",
              paddingTop: 24,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              fontFamily: "Poppins",
            }}
          >
            <div>
              <p className="h4">
                Para poder usar el sistema se debe deshabilitar el traductor.
                Abajo hay un ejemplo de los pasos que se deben seguir
              </p>
            </div>
            <div className="pb-1 d-flex flex-column justify-content-center align-items-center">
              <p className="h5">1.- Se debe deshabilitar la traducción</p>
              <div style={{ zIndex: 999999, width: 400 }}>
                <img
                  src="/trad.png"
                  className="img-fluid rounded"
                  alt="Traducción"
                ></img>
              </div>
            </div>

            <div className="pt-4 d-flex flex-column justify-content-center align-items-center">
              <p className="h5">
                2.- También se debe elegir la opción nunca traducir este sitio
              </p>
              <div style={{ zIndex: 999999, width: 400 }}>
                <img
                  src="/traducción.png"
                  className="img-fluid rounded"
                  alt="Imagén de referencia"
                ></img>
              </div>
            </div>

            <div className="pt-4">
              <p className="m-0 p-0 h5">
                Por último se debe actualizar la página{" "}
                <code className="ml-2 p-1 border rounded bg-dark font-weight-bold">
                  ctrl + F5
                </code>
              </p>
            </div>
          </div>
        )}

        {!loading && <Router />}

        {progress.length == 0 && (
          <NotificationDialog
            open={openNotifications}
            onClose={() => setOpenNotifications(false)}
          />
        )}

        {progress.map((item) => (
          <Progress key={item} show={true} />
        ))}

        <div className="notifications">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              id={notification.id}
              code={notification.code}
              message={notification.message}
              onAction={notification.onAction}
              textAction={notification.textAction}
              hideDuration={notification.hideDuration}
            />
          ))}
        </div>
        <WorkAreaDialog />
      </StylesProvider>
    </ThemeProvider>
  );
}

Application.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  redirect: state.General.redirect,
  notifications: state.General.notifications,
  progress: state.General.progress,
  isAuthenticate: state.Account.isAuthenticate,
  language: state.General.language,
  intervalRefresh: state.Account.intervalRefresh,
  workArea: state.Account.workArea,
  userNotifications: state.UserNotifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  setRedirect: actionSetRedirect(dispatch),
  setNotification: actionSetNotification(dispatch),
  getProfile: actionGetProfile(dispatch),
  getUserPermits: actionGetUserPermits(dispatch),
  showProgress: actionShowProgress(dispatch),
  logout: actionLogout(dispatch),
  refreshToken: actionRefreshToken(dispatch),
  setIntervalRefresh: actionSetIntervalRefresh(dispatch),
  setWorkArea: actionSetWorkArea(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
