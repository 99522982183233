export default {
  disabled_tire: "Disabled tire",
  permits: {
    change_status: "No permissions to {action} tires",
  },
  messages: {
    disable: "Are you sure to disable the tire?",
    enable: "Are you sure to enable the tire?",
  },
};
