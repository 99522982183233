export default {
  condition: "Condición",
  provider: "Proveedor",
  model: "Modelo",
  size_no_layers: "Medida / No. de capas",
  permits: {
    show: {
      tire_cycle_history:
        "Sin permisos para ver historiales de ciclos de neumáticos",
    },
  },
};
