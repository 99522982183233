import {
  GET_ALERTS_OF_MOUNT,
  CLEAR_ALERTS_OF_MOUNT,
  GET_ALERT_OF_MOUNT,
  CLEAR_ALERT_OF_MOUNT,
  UPDATE_ALERT_OF_MOUNT,
  SET_ALERTS_OF_MOUNT_FILTERS,
  CLEAR_ALERTS_OF_MOUNT_FILTERS,
} from "store/actions";

const initialState = {
  alerts: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  alert: {
    alert: {
      details: "",
      priority: "HALF",
    },
    comment: "",
    created_at: "0001-01-01T00:00:00.000Z",
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALERTS_OF_MOUNT:
      return {
        ...state,
        alerts: action.payload,
      };

    case CLEAR_ALERTS_OF_MOUNT:
      return {
        ...state,
        alerts: { ...initialState.alerts },
      };

    case UPDATE_ALERT_OF_MOUNT:
    case GET_ALERT_OF_MOUNT:
      return {
        ...state,
        alert: action.payload,
      };

    case CLEAR_ALERT_OF_MOUNT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case SET_ALERTS_OF_MOUNT_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_ALERTS_OF_MOUNT_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
      };

    default:
      return state;
  }
}
