import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  corporate,
  corporates,
  isRoot,
  filter,
  t,
  getCorporate,
  addCorporate,
  updateCorporate,
  clearCorporateFilters,
  setCorporateFilters,
  deleteCorporate,
  setStatusCorporate,
  getCorporates,
  clearCorporates,
}) {
  return (
    <Home
      item={corporate}
      name="corporate"
      filter={filter}
      createTooltipPermit={!isRoot ? t("_permits.root") : ""}
      currentPage={corporates.current_page}
      deleteConfirmationMessage={t("_messages.delete.corporate")}
      disableConfirmationMessage={t(
        "_messages.change_status.corporate.disable"
      )}
      enableConfirmationMessage={t("_messages.change_status.corporate.enable")}
      rows={corporates.data.map((corporate) => {
        return {
          id: corporate.corporate_id,
          key: corporate.corporate_id,
          status: {
            status: corporate.status,
            tooltipPermit: !isRoot ? t("_permits.root") : "",
          },
          name: corporate.name,
          social_reason: corporate.social_reason,
          rfc: corporate.rfc,
          edit: {
            tooltipPermit: !isRoot ? t("_permits.root") : "",
          },
          delete: {
            tooltipPermit: !isRoot ? t("_permits.root") : "",
          },
        };
      })}
      title={t("_general.corporates")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.corporate_name") },
        { id: "social_reason", label: t("_labels.social_reason") },
        { id: "rfc", label: t("_labels.rfc") },
        { id: "actions", label: "" },
      ]}
      lastPage={corporates.last_page}
      noItemsText={t("_general.no_corporates")}
      initialDefaultValues={{
        name: "",
        social_reason: "",
        rfc: "",
        tire_fee: "",
        fee_currency_type: "MXN",
        logo: null,
      }}
      fields={[
        {
          name: "logo",
          accept: "image/*",
          placeholder: t("_labels.placeholder.image"),
          component: "file",
          fullWidth: true,
          required: false,
        },
        {
          name: "name",
          fullWidth: true,
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "social_reason",
          required: true,
          label: t("_labels.social_reason"),
        },
        {
          name: "rfc",
          required: true,
          label: t("_labels.rfc"),
        },
        {
          name: "tire_fee",
          required: true,
          type: "number",
          label: t("_labels.tire_fee"),
        },
        {
          name: "fee_currency_type",
          required: true,
          component: "select",
          label: t("_labels.fee_field.label"),
          selectItems: [
            { value: "MXN", name: t("_labels.fee_field.options.mxn") },
            { value: "USD", name: t("_labels.fee_field.options.usd") },
            { value: "EUR", name: t("_labels.fee_field.options.eur") },
          ],
        },
      ]}
      createFormTitle={t("_titles.new.corporate")}
      updateFormTitle={t("_titles.update.corporate")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.corporate"),
        },
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.corporate")}
      updatedMessage={t("_messages.updated.corporate")}
      getItemAction={getCorporate}
      setFiltersAction={setCorporateFilters}
      clearFiltersAction={clearCorporateFilters}
      deleteAction={deleteCorporate}
      setStatusAction={setStatusCorporate}
      getItemsAction={getCorporates}
      clearItemsAction={clearCorporates}
      addAction={addCorporate}
      updateAction={updateCorporate}
    />
  );
}

Page.propTypes = {
  corporate: PropTypes.object.isRequired,
  corporates: PropTypes.object.isRequired,
  isRoot: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getCorporate: PropTypes.func.isRequired,
  addCorporate: PropTypes.func.isRequired,
  updateCorporate: PropTypes.func.isRequired,
  clearCorporateFilters: PropTypes.func.isRequired,
  setCorporateFilters: PropTypes.func.isRequired,
  deleteCorporate: PropTypes.func.isRequired,
  setStatusCorporate: PropTypes.func.isRequired,
  getCorporates: PropTypes.func.isRequired,
  clearCorporates: PropTypes.func.isRequired,
};

export default Page;
