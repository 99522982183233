import {
  GET_CONCEPTS,
  CLEAR_CONCEPTS,
  GET_CONCEPT,
  CLEAR_CONCEPT,
  SET_CONCEPT_FILTERS,
  CLEAR_CONCEPT_FILTERS,
} from "store/actions";

const initialState = {
  concepts: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  concept: {
    concept_id: 0,
    name: "",
    description: "",
    critical: false,
    type_value: "",
    use: "",
    ranking_value: "",
    sensor_check: false,
    image: null,
  },
  filter: {
    page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONCEPT:
      return {
        ...state,
        concept: action.payload,
      };

    case CLEAR_CONCEPT:
      return {
        ...state,
        concept: { ...initialState.concept },
      };

    case GET_CONCEPTS:
      return {
        ...state,
        concepts: action.payload,
      };

    case CLEAR_CONCEPTS:
      return {
        ...state,
        concepts: { ...initialState.concepts },
      };

    case SET_CONCEPT_FILTERS:
      const newFilter = {
        ...state.filter,
        ...action.payload,
      };

      for (const key in newFilter) {
        if (
          newFilter[key] === "" ||
          newFilter[key] === null ||
          newFilter[key] === undefined
        ) {
          delete newFilter[key];
        }
      }

      return {
        ...state,
        filter: newFilter,
      };

    case CLEAR_CONCEPT_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
      };

    default:
      return state;
  }
}
