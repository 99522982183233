import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";

import FilterListIcon from "@material-ui/icons/FilterList";
import Typography from "@material-ui/core/Typography";

import Button from "components/molecules/general/Button";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import TireTabs from "components/organisms/tire/TireTabs";
import TableResponsive from "components/organisms/general/TableResponsive";
import FilterTireHistoryForm from "components/organisms/tire/FilterTireHistoryForm";

import css from "./index.module.scss";
import moment from "moment-timezone";
import { LOCATIONS, getMinDepth } from "utils/tire";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  tire,
  tires,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <TireTabs
      className={classnames({
        [css.empty]: tires.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      <div className={classnames(css.title)}>
        <div className={classnames(css.horizontal)}>
          <Typography noWrap variant="h6">
            {`${t("_labels.code")}: ${tire.code}`}
          </Typography>
          {tire.device_code && (
            <Typography noWrap variant="h6">
              {`${t("_general.rfid")}: ${tire.device_code}`}
            </Typography>
          )}
        </div>
        <div className={classnames(css.horizontal)}>
          <Typography
            noWrap
            variant="overline"
            className={classnames(css.subtitle)}
          >
            {t("_labels.original_info")}
          </Typography>
        </div>
        <div className={classnames(css.horizontal)}>
          <Typography noWrap variant="h6" className={classnames(css.subtitle)}>
            {tire.cycle.variation.tire_model.brand.name}
          </Typography>
          <Typography noWrap variant="h6" className={classnames(css.subtitle)}>
            {tire.cycle.variation.tire_model.name}
          </Typography>
        </div>
      </div>
      {tires.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={tires.map((tire) => ({
                id: tire.movement_tire_id,
                key: tire.movement_tire_id,
                location: getLocation(t, tire.movement_tire),
                position: getPosition(tire.movement_tire),
                cycle: tire.movement_tire?.tire_cycle?.number_cycle ?? "-",
                km: `${formatter.format(tire.accumulated_mileage)} km`,
                pressure: tire.pressure ? `${tire.pressure} psi` : "",
                depth: tire.tire_review_depth_line
                  ? `${getMinDepth(tire.tire_review_depth_line)} mm`
                  : "",
                number_patch:
                  tire.movement_tire?.tire_cycle?.number_patch ?? "-",
                date: moment(tire.date).format("LL"),
                created_at: `${moment(tire.created_at).format("LL")} ${t(
                  "_labels.time_at"
                )} ${moment(tire.created_at).format("hh:mm:s a")}`,
              }))}
              headers={[
                {
                  id: "location",
                  label: t("_labels.location.label"),
                },
                {
                  id: "position",
                  label: t("_labels.vehicle_type_axle_position.label"),
                },
                {
                  id: "cycle",
                  label: t("_labels.number_cycle"),
                },
                {
                  id: "km",
                  label: t("_labels.mileage"),
                },
                {
                  id: "pressure",
                  label: t("_labels.pressure"),
                },
                {
                  id: "depth",
                  label: t("_labels.depth"),
                },
                {
                  id: "number_patch",
                  label: t("_labels.number_patches"),
                },
                {
                  id: "date",
                  label: t("_labels.inspection_date"),
                },
                {
                  id: "created_at",
                  label: t("_labels.created_at"),
                },
              ]}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterTireHistoryForm open={openFilter} onClose={handleCloseFilter} />
    </TireTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.array.isRequired,
  tire: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default Page;

function getLocation(t, movementTire) {
  if (movementTire.vehicle_tire.length > 0) {
    return movementTire.vehicle_tire[0]?.vehicle?.economic_number;
  }

  if (movementTire.all_warehouse_tire.length > 0) {
    return movementTire.all_warehouse_tire[0]?.warehouse?.name;
  }

  if (movementTire.movement == LOCATIONS.REVITALIZATION) {
    return t("_labels.location.options.revitalization");
  }

  if (movementTire.movement == LOCATIONS.REPAIR) {
    return t("_labels.location.options.repair");
  }

  if (movementTire.movement == LOCATIONS.PILE) {
    return t("_labels.location.options.pile");
  }

  return "N/A";
}

function getPosition(movementTire) {
  if (movementTire.vehicle_tire.length > 0) {
    return movementTire.vehicle_tire[0]?.vehicle_type_axle_tire?.position;
  }

  return "-";
}
