import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import CheckboxField from "components/molecules/general/CheckboxField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import Button from "components/molecules/general/Button";

import { REVIEW_TYPES } from "utils/review";
import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  initialValues,
  setFieldValue,
  corporates,
  companies,
  subsidiaries,
  movements,
  depthConditions,
  brands,
  models,
  sizes,
  revitalizedBrands,
  revitalizedModels,
  isPileStats,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      {initialValues.date_from != undefined && (
        <>
          <Typography
            variant="overline"
            color="textSecondary"
            display="block"
            className={classnames(css.w100, css.ml8)}
          >
            {t("_labels.date.range")}
          </Typography>
          <Field
            name="date_from"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            component={DateTimeField}
            label={t("_labels.date.from")}
            className={classnames(css.medium_field)}
            required={!!values.date_to}
          />
        </>
      )}

      {initialValues.date_to != undefined && (
        <Field
          name="date_to"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          component={DateTimeField}
          label={t("_labels.date.to")}
          className={classnames(css.medium_field)}
          required={!!values.date_from}
        />
      )}

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="corporate_id"
        component={SelectField}
        label={t("_labels.corporate_field.singular.label")}
        defaultText={t("_labels.corporate_field.singular.value")}
        className={classnames(css.field)}
      >
        {corporates.map((corporate, index) => {
          return (
            <MenuItem
              key={index}
              value={corporate.corporate_id.toString()}
              disabled={!corporate.status}
            >
              {corporate.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="company_id"
        component={SelectField}
        label={t("_labels.company_field.singular.label")}
        defaultText={t("_labels.company_field.singular.value")}
        className={classnames(css.field)}
      >
        {companies.map((company, index) => {
          return (
            <MenuItem
              key={index}
              value={company.company_id.toString()}
              disabled={!company.status}
            >
              {company.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiary_id"
        component={SelectField}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        className={classnames(css.field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      {!isPileStats && (
        <Field
          fullWidth
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          name="movement"
          component={SelectField}
          label={t("_labels.location.label")}
          className={classnames(css.field)}
        >
          {movements.map((movement, index) => {
            return (
              <MenuItem key={index} value={movement.value.toString()}>
                {movement.label}
              </MenuItem>
            );
          })}
        </Field>
      )}

      <Field
        id="brands"
        name="brands"
        multiple
        autoHighlight
        disableClearable={initialValues.brands.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("brands", value)}
        value={values.brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="models"
        name="models"
        multiple
        autoHighlight
        disableClearable={initialValues.models.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={models}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("models", value)}
        value={values.models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="sizes"
        name="sizes"
        multiple
        autoHighlight
        disableClearable={initialValues.sizes.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={sizes}
        getOptionLabel={(option) => option.size}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_size_id === value.tire_size_id
        }
        onChange={(_, value) => setFieldValue("sizes", value)}
        value={values.sizes}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2 {...params} label={t("_labels.size")} variant="filled" />
        )}
      />

      <Field
        id="revitalized_brands"
        name="revitalized_brands"
        multiple
        autoHighlight
        disableClearable={initialValues.revitalized_brands.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={revitalizedBrands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("revitalized_brands", value)}
        value={values.revitalized_brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.revitalized_brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="revitalized_models"
        name="revitalized_models"
        multiple
        autoHighlight
        disableClearable={initialValues.revitalized_models.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={revitalizedModels}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.revitalized_tire_model_id === value.revitalized_tire_model_id
        }
        onChange={(_, value) => setFieldValue("revitalized_models", value)}
        value={values.revitalized_models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.revitalized_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        required={!!values.depth_max}
        size="small"
        variant="filled"
        margin="normal"
        name="depth_min"
        type="number"
        className={classnames(css.medium_field)}
        label={t("_labels.depth_min")}
        component={TextField}
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="end"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <Field
        required={!!values.depth_min}
        size="small"
        variant="filled"
        margin="normal"
        name="depth_max"
        type="number"
        className={classnames(css.medium_field)}
        label={t("_labels.depth_max")}
        component={TextField}
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="end"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="depth_condition"
        component={SelectField}
        label={t("_labels.depth_rating")}
        className={classnames(css.field)}
      >
        {depthConditions.map((condition, index) => {
          return (
            <MenuItem key={index} value={condition.value}>
              {condition.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="activity"
        component={SelectField}
        label={t("_labels.activity.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="default">
          {t("_labels.activity.options.not_rule_out")}
        </MenuItem>
        <MenuItem value={"GENERAL"}>
          {t("_labels.activity.options.general")}
        </MenuItem>
        <MenuItem value={"GENERAL,MOUNT"}>
          {t("_labels.activity.options.general_and_mount")}
        </MenuItem>
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="review_type"
        component={SelectField}
        label={t("_labels.review_type.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="default">{t("_labels.no_option")}</MenuItem>
        {REVIEW_TYPES.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {t(type.label)}
          </MenuItem>
        ))}
      </Field>

      <Field
        fullWidth
        variant="filled"
        name="number_cycle"
        type="number"
        component={TextField}
        label={t("_labels.number_cycle")}
        className={classnames(css.field)}
        InputProps={{
          inputProps: {
            max: 5,
            min: 0,
          },
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="with_refection"
        component={CheckboxField}
        className={classnames(css.field)}
        label={t("_labels.show_refection")}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  depthConditions: PropTypes.array.isRequired,
  movements: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array,
  revitalizedModels: PropTypes.array,
  sizes: PropTypes.array.isRequired,
  isPileStats: PropTypes.bool,
};

export default Page;
