export default {
  logout: "Cerrar sesión",
  review: "Revisiones",
  vehicle_review: "Revisiones de vehículo",
  driver: "Conductores",
  brand: "Marcas",
  corporate: "Corporativos",
  company: "Empresas",
  subsidiary: "Sucursales",
  warehouse: "Almacenes",
  damage_type: "Tipos de daños",
  user: "Usuarios",
  wear_type: "Tipos de desgastes",
  provider: "Proveedores",
  vehicle: "Vehículos",
  mounting: "Montaje",
  vehicle_type: "Tipos de vehículos",
  tire_model: "Modelos Originales",
  tire_size: "Medidas",
  tires: "Neumáticos",
  division: "Divisiones",
  retirement_causes: "Causas de retiro",
  gps: "Gps",
  alert: "Códigos de alertas",
  revitalized_tire_model: "Modelos Revitalizados",
  labels: {
    vehicles: "Vehículos",
    admin: "Administración",
    areas: "Áreas",
    control: "Control",
    catalogs: "Catálogos",
  },
};
