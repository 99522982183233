import { USER_TOKEN } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetLogin =
  (dispatch) =>
  async ({ email, password }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "POST",
        path: "auth/login",
        data: { email, password },
      });

      dispatch({
        type: USER_TOKEN,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
