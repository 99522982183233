import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Page from "./Page";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetLastMovement } from "store/actions/reports/last_movement/getLastMovement";
import { actionClearLastMovementReportFilters } from "store/actions/reports/last_movement/clearLastMovementReportFilters";
import { actionGenerateLastMovementExcel } from "store/actions/reports/last_movement/generateLastMovementExcel";
import { useLang } from "hooks/lang";
import moment from "moment-timezone";
import { actionSetSelectedLastMovementReportFilters } from "store/actions/reports/last_movement/setSelectedLastMovementReportFilters";
import { actionSetLastMovementReportFilters } from "store/actions/reports/last_movement/setLastMovementReportFilters";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

function LastMovement({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const {
    filter,
    selectedFilter,
    setNotification,
    getLastMovement,
    clearLastMovementReportFilters,
    generateLastMovementExcel,
    setSelectedFilters,
    setLastMovementReportFilters,
    account,
    workArea,
  } = rest;
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getLastMovement({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };
    setLastMovementReportFilters(filter);
    setSelectedFilters(selectedFilters);
    return () => {
      clearLastMovementReportFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateLastMovementExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSearch(search) {
    if (search === "") {
      setLastMovementReportFilters({ ...filter, search: undefined });
    } else {
      setLastMovementReportFilters({ ...filter, search });
    }
  }

  return (
    <Page
      t={t}
      data={data}
      filter={filter}
      selectedFilter={selectedFilter}
      search={filter.search}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
      handleSearch={handleSearch}
    />
  );
}

const mapStateToProps = (state) => ({
  filter: state.Reports.LastMovement.filter,
  selectedFilter: state.Reports.LastMovement.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getLastMovement: actionGetLastMovement(dispatch),
  clearLastMovementReportFilters:
    actionClearLastMovementReportFilters(dispatch),
  generateLastMovementExcel: actionGenerateLastMovementExcel(dispatch),
  setSelectedFilters: actionSetSelectedLastMovementReportFilters(dispatch),
  setLastMovementReportFilters: actionSetLastMovementReportFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastMovement);
