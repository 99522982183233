import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDepthTolerancePolicies } from "store/actions/subsidiary/policy/ToleranceDepthApplication/getDepthTolerancePolicies";

import { actionGetDepthTolerancePolicy } from "store/actions/subsidiary/policy/ToleranceDepthApplication/getDepthTolerancePolicy";
import { actionUpdateDepthTolerancePolicy } from "store/actions/subsidiary/policy/ToleranceDepthApplication/updateDepthTolerancePolicy";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/policy/depth_tolerance/DepthToleranceForm";

export function DepthToleranceForm({
  open,
  subsidiaryId,
  depthTolerancePolicyId,
  onClose,
  ...rest
}) {
  const t = useLang(NAMESPACE);

  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const {
    policy,
    getTireApplicationTypes,
    getDepthTolerancePolicies,
    setNotification,
    getDepthTolerancePolicy,
    updateDepthTolerancePolicy,
  } = rest;

  const initialDefaultValues = {
    tire_application_id: "",
    good_condition: "",
    scheduled_withdrawal: "",
    critical_withdrawal: "",
    critical_number_patches: "",
    maximum_number_patches: "",
  };
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: initialDefaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );
        setTireApplicationTypes(
          data.sort(alphabeticalSort("tire_application_id"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getDepthTolerancePolicy(subsidiaryId, depthTolerancePolicyId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (depthTolerancePolicyId && open) {
      fetchData();
    }
  }, [depthTolerancePolicyId, open]);

  useEffect(() => {
    if (depthTolerancePolicyId && policy.depth_tolerance_policy_id) {
      setInitialValues({
        tire_application_id: policy.tire_application_id,
        good_condition: policy.good_condition,
        scheduled_withdrawal: policy.scheduled_withdrawal,
        critical_withdrawal: policy.critical_withdrawal,
        critical_number_patches: policy.critical_number_patches ?? "",
        maximum_number_patches: policy.maximum_number_patches ?? "",
      });
    } else {
      setInitialValues(initialDefaultValues);
    }
  }, [policy, depthTolerancePolicyId]);

  useEffect(() => {
    setIsUpdate(depthTolerancePolicyId ? true : false);
  }, [depthTolerancePolicyId]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    const fields = {
      tire_application_id: values.tire_application_id,
      good_condition: values.scheduled_withdrawal + 3,
      scheduled_withdrawal: values.scheduled_withdrawal,
      critical_withdrawal: values.critical_withdrawal,
      critical_number_patches: values.critical_number_patches,
      maximum_number_patches: values.maximum_number_patches,
    };

    try {
      await updateDepthTolerancePolicy(
        fields,
        subsidiaryId,
        depthTolerancePolicyId
      );
      setNotification({
        message: t("messages.depth_tolerance_policy_updated"),
      });

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    try {
      await getDepthTolerancePolicies(subsidiaryId);
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      tireApplicationTypes={tireApplicationTypes}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

DepthToleranceForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  depthTolerancePolicyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const mapStateToProps = (state) => ({
  policy: state.Subsidiary.depthTolerancePolicy.Policy.depth_tolerance_policy,
});

const mapDispatchToProps = (dispatch) => ({
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  setNotification: actionSetNotification(dispatch),
  getDepthTolerancePolicies: actionGetDepthTolerancePolicies(dispatch),
  getDepthTolerancePolicy: actionGetDepthTolerancePolicy(dispatch),
  updateDepthTolerancePolicy: actionUpdateDepthTolerancePolicy(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthToleranceForm);
