import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "../index.css";
import TableForm from "../TableForm";

function Page({
  t,
  size,
  data,
  application,
  initialValues,
  handleFormikValidate,
  original,
  setSummaryData,
  isEditable,
  handleToggleEditable,
  actualPurchase,
  replacementData,
  formikRef,
}) {
  return (
    <div className={`table-responsive ${application} box collapse`}>
      <table className="table table-hover sFS12">
        <thead>
          <tr
            style={{
              backgroundColor: isEditable ? "#002849" : "#1c7600",
              color: "white",
            }}
          >
            <th
              colSpan={isEditable ? 12 : 11}
              className="text-center align-middle"
            >
              {size}
            </th>
            {!isEditable && (
              <th className="text-center align-middle">
                <CheckRoundedIcon fontSize="small" />
              </th>
            )}
          </tr>
          <tr style={{ backgroundColor: "dimgrey", color: "white" }}>
            <td width={90} className="text-center">
              {t("_labels.ranking")}
            </td>
            <td width={180} className="text-center">
              {t(
                `_labels.tire_application.options.${application.toLowerCase()}`
              )}
            </td>
            <td className="text-center">{t("_labels.quantity")}</td>
            <td
              width={100}
              className="text-center"
              style={{ textWrap: "nowrap" }}
            >
              {t("_labels.km_projected")}
            </td>
            <td width={90} className="text-center">
              {t("_labels.cost_km")}
            </td>
            <td width={100} className="text-center">
              {t("_labels.layer")}
            </td>
            <td width={100} className="text-center">
              {t("_labels.required")}
            </td>
            <td width={100} className="text-center">
              {t("_labels.stock")}
            </td>
            <td width={130} className="text-center">
              {t("_labels.to_buy")}
            </td>
            <td className="text-center">{t("_labels.last_purchase_date")}</td>
            <td width={160} className="text-center">
              {t("_labels.last_purchase_price")}
            </td>
            <td className="text-center">Total</td>
          </tr>
        </thead>
        <tbody>
          <Formik
            initialValues={initialValues}
            validate={handleFormikValidate}
            onSubmit={(e) => {
              e.preventDefault();
            }}
            innerRef={formikRef}
          >
            {(p) => (
              <TableForm
                data={data}
                initialValues={initialValues}
                application={application}
                original={original}
                setSummaryData={setSummaryData}
                isEditable={isEditable}
                handleToggleEditable={handleToggleEditable}
                actualPurchase={actualPurchase}
                replacementData={replacementData}
                {...p}
              />
            )}
          </Formik>
        </tbody>
      </table>
    </div>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
  size: PropTypes.string,
  actualPurchase: PropTypes.object,
  replacementData: PropTypes.object,
  application: PropTypes.string,
  initialValues: PropTypes.object,
  handleFormikValidate: PropTypes.func,
  original: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  setSummaryData: PropTypes.func.isRequired,
  handleToggleEditable: PropTypes.func.isRequired,
  formikRef: PropTypes.object.isRequired,
};

export default Page;
