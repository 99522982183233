import { combineReducers } from "redux";

import Tire from "./tire";
import Warehouse from "./warehouse";
import Subsidiary from "./subsidiary";

export default combineReducers({
  Tire,
  Warehouse,
  Subsidiary,
});
