import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Formik } from "formik";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";

import css from "./index.module.scss";

function Page({
  open,
  name,
  title,
  fields,
  isUpdate,
  updateTitle,
  initialValues,
  initialErrors,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>{isUpdate ? updateTitle : title}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => (
              <Form
                {...p}
                name={name}
                fields={fields}
                isUpdate={isUpdate}
                onClose={onClose}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  updateTitle: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
