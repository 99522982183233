import React from "react";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "pages/general/NotFound";

function NotFound() {
  const t = useLang(NAMESPACE);

  return <Page t={t} />;
}

export default NotFound;
