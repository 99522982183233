import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionChangePassword } from "store/actions/account/changePassword";
import { actionSetNotification } from "store/actions/general/notification";
import { actionUpdatePassword } from "store/actions/account/updatePassword";

import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE = "pages/account/ChangePassword";

export function ChangePassword({ ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const { token } = useParams();
  const showLogin = token && true;
  const { account, changePassword, updatePassword, setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      password: "",
      confirm_password: "",
    },
  });

  useEffect(() => {
    if ((!account && !token) || (account && !account.need_change_password)) {
      history.replace("/");
    }
  }, []);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      let response;
      if (token) {
        response = await changePassword({
          password: values.password,
          token: token,
        });
        setNotification({
          message: response.message,
          code: response.code,
        });
      } else {
        response = await updatePassword({
          password: values.password,
        });
        setNotification({
          message: t("messages.password_updated"),
        });
      }

      resetForm();
      setSubmitting(false);
      history.replace("/");
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);

    if (
      values.confirm_password.length >= values.password.length &&
      values.password !== values.confirm_password
    ) {
      errors.password = t("_errors.different_passwords");
    } else if (values.password !== values.confirm_password) {
      errors.confirm_password = t("_errors.different_passwords");
    }

    return errors;
  }

  return (
    <Page
      t={t}
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
      showLogin={showLogin}
    />
  );
}

ChangePassword.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: actionChangePassword(dispatch),
  updatePassword: actionUpdatePassword(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
