import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
// import moment from "moment-timezone";

import Page from "./page";

export function AlertsDialog({ open, alerts, ...rest }) {
  const t = useLang();

  return <Page {...rest} t={t} open={open} alerts={alerts} />;
}

AlertsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  alerts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsDialog);
