import {
  REGISTER_BLOCK,
  GET_BLOCKS,
  CLEAR_BLOCKS,
  GET_BLOCK,
  CLEAR_BLOCK,
  DELETE_BLOCK,
  SET_BLOCK_FILTERS,
  CLEAR_BLOCK_FILTERS,
} from "store/actions";

const initialState = {
  block: {
    name: "",
    description: "",
  },
  blocks: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_block: {
    page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_BLOCK:
    case GET_BLOCK:
      return {
        ...state,
        block: action.payload,
      };

    case GET_BLOCKS:
      return {
        ...state,
        blocks: action.payload,
      };

    case CLEAR_BLOCKS:
      return {
        ...state,
        blocks: { ...initialState.blocks },
      };

    case CLEAR_BLOCK:
      return {
        ...state,
        block: { ...initialState.block },
      };

    case DELETE_BLOCK:
      return {
        ...state,
        blocks: {
          ...state.blocks,
          data: state.blocks.data.filter(
            (block) => block.block_id !== action.payload
          ),
        },
      };

    case SET_BLOCK_FILTERS:
      const filter = {
        ...state.filter_block,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_block: { ...filter },
      };

    case CLEAR_BLOCK_FILTERS:
      return {
        ...state,
        filter_block: { search: state.filter_block.search },
      };
    default:
      return state;
  }
}
