import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetConcept } from "store/actions/concept/getConcept";
import { actionClearConcept } from "store/actions/concept/clearConcept";
import { actionAddConcept } from "store/actions/concept/addConcept";
import { actionUpdateConcept } from "store/actions/concept/updateConcept";
import { actionGetConcepts } from "store/actions/concept/getConcepts";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { TYPE_VALUES } from "utils/concept";

import Page from "./page";

export function ConceptForm({ open, onClose, conceptId, ...rest }) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(conceptId ? true : false);
  const {
    concept,
    filter,
    setNotification,
    getConcept,
    clearConcept,
    addConcept,
    updateConcept,
    getConcepts,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      name: "",
      type_value: "",
      ranking_value: "",
      use: "",
      description: "",
      sensor_check: false,
      critical: false,
      image: null,
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getConcept(conceptId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && conceptId) {
      fetchData();
    }

    if (!open) {
      resetFormik();
    }

    return () => {
      clearConcept();
    };
  }, [open]);

  useEffect(() => {
    if (concept && concept.concept_id) {
      setInitialValues({
        name: concept.name,
        description: concept.description,
        type_value: concept.type_value,
        critical: Boolean(concept.critical),
        ranking_value: concept.ranking_value ?? "",
        use: concept.use,
        sensor_check: Boolean(concept.sensor_check),
        image: concept.image
          ? `${process.env.REACT_APP_IMAGES_URL}${concept.image}`
          : null,
      });
    }
  }, [concept]);

  useEffect(() => {
    setIsUpdate(conceptId ? true : false);
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = { ...values };
      fields.critical = Number(values.critical);
      fields.sensor_check = Number(values.sensor_check);

      if (values.image == null) {
        delete fields.image;
      }

      if (values.ranking_value == "") {
        delete fields.ranking_value;
      }

      if (isUpdate) {
        await updateConcept(fields, conceptId);
        setNotification({
          message: t("_messages.updated.concept"),
        });
      } else {
        await addConcept(fields);
        setNotification({
          message: t("_messages.created.concept"),
        });
      }
      setSubmitting(false);
      resetForm();
      onClose();
      getConcepts({ ...filter });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      typeValues={TYPE_VALUES}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

ConceptForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  conceptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  concept: state.Concept.concept,
  filter: state.Concept.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getConcept: actionGetConcept(dispatch),
  clearConcept: actionClearConcept(dispatch),
  addConcept: actionAddConcept(dispatch),
  updateConcept: actionUpdateConcept(dispatch),
  getConcepts: actionGetConcepts(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConceptForm);
