import {
  REGISTER_DAMAGE,
  UPDATE_DAMAGE,
  GET_DAMAGES,
  CLEAR_DAMAGES,
  GET_DAMAGE,
  CLEAR_DAMAGE,
  SET_DAMAGE_FILTERS,
  CLEAR_DAMAGE_FILTERS,
  SET_DAMAGE_STATUS,
  DELETE_DAMAGE,
} from "store/actions";

const initialState = {
  damage: {
    damage_id: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    name: "",
    status: 0,
    appearance: "",
    area: "",
    probable_causes: "",
    lock_cycles: 0,
    damage_category: "",
    image: null,
  },
  damages: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_damage: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_DAMAGE:
    case UPDATE_DAMAGE:
    case GET_DAMAGE:
      return {
        ...state,
        damage: action.payload,
      };
    case GET_DAMAGES:
      return {
        ...state,
        damages: action.payload,
      };
    case CLEAR_DAMAGES:
      return {
        ...state,
        damages: initialState.damages,
      };
    case CLEAR_DAMAGE:
      return {
        ...state,
        damage: initialState.damage,
      };

    case SET_DAMAGE_FILTERS:
      const filter = {
        ...state.filter_damage,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_damage: { ...filter },
      };
    case CLEAR_DAMAGE_FILTERS:
      return {
        ...state,
        filter_damage: { search: state.filter_damage.search },
      };
    case SET_DAMAGE_STATUS:
      return {
        ...state,
        damages: {
          ...state.damages,
          data: state.damages.data.map((damage) =>
            damage.damage_id !== action.payload
              ? damage
              : { ...damage, status: !damage.status }
          ),
        },
      };

    case DELETE_DAMAGE:
      return {
        ...state,
        damages: {
          ...state.damages,
          data: state.damages.data.filter(
            (damage) => damage.damage_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
