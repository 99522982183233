import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

function Page(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path d="M12 17.5h6q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075Q18.65 14.5 18 14.5h-6q-.65 0-1.075.425Q10.5 15.35 10.5 16q0 .65.425 1.075.425.425 1.075.425Zm0 8h6q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075Q18.65 22.5 18 22.5h-6q-.65 0-1.075.425Q10.5 23.35 10.5 24q0 .65.425 1.075.425.425 1.075.425Zm0 8h6q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075Q18.65 30.5 18 30.5h-6q-.65 0-1.075.425Q10.5 31.35 10.5 32q0 .65.425 1.075.425.425 1.075.425Zm17.1-4.6q.2 0 .375-.075t.325-.225l7.4-7.45q.5-.5.525-1.05.025-.55-.475-1.05-.45-.45-1.075-.45t-1.075.45l-6 6-1.8-1.75q-.45-.45-1.05-.45-.6 0-1.05.45-.45.45-.45 1.05 0 .6.45 1.05l3.2 3.2q.15.15.325.225.175.075.375.075ZM6.6 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V9q0-1.2.9-2.1.9-.9 2.1-.9h34.8q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Z" />
    </SvgIcon>
  );
}

export default Page;
