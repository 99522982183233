import React from "react";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

export const NAMESPACE = "components/molecules/general/LinkedItemPanel";

import Page from "./page";

function LinkedItemPanel(props) {
  const t = useLang(NAMESPACE);

  return <Page {...props} t={t} />;
}

LinkedItemPanel.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  viewButtonDisabled: PropTypes.bool,
  viewButtonTooltip: PropTypes.string,
  actionButtonDisabled: PropTypes.bool,
  actionButtonTooltip: PropTypes.string,
  historyTooltipPermit: PropTypes.string,
  actionButtonName: PropTypes.string,
  onHistory: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAction: PropTypes.func,
};

export default LinkedItemPanel;
