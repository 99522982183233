import {
  GET_SUBSIDIARY_DRIVER,
  CLEAR_SUBSIDIARY_DRIVER,
  UPDATE_DRIVER,
  SET_DRIVER_STATUS,
  GET_SUBSIDIARY_DRIVERS,
  CLEAR_SUBSIDIARY_DRIVERS,
  ASSIGN_DRIVER_TO_SUBSIDIARY,
  DELETE_LINK_DRIVER_WITH_SUBSIDIARY,
  SET_SUBSIDIARY_DRIVERS_FILTERS,
  CLEAR_SUBSIDIARY_DRIVERS_FILTERS,
} from "store/actions";

const initialState = {
  subsidiary_driver: {
    subsidiary: {
      name: "",
    },
    driver: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },

  subsidiary_drivers: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARY_DRIVER:
      return {
        ...state,
        subsidiary_driver: action.payload,
      };

    case CLEAR_SUBSIDIARY_DRIVER:
      return {
        ...state,
        subsidiary_driver: { ...initialState.subsidiary_driver },
      };

    case GET_SUBSIDIARY_DRIVERS:
      return {
        ...state,
        subsidiary_drivers: action.payload,
      };

    case CLEAR_SUBSIDIARY_DRIVERS:
      return {
        ...state,
        subsidiary_drivers: { ...initialState.subsidiary_drivers },
      };

    case ASSIGN_DRIVER_TO_SUBSIDIARY:
      return {
        ...state,
        subsidiary_drivers: {
          ...state.subsidiary_drivers,
          data: [action.payload, ...state.subsidiary_drivers.data],
        },
      };

    case UPDATE_DRIVER: {
      return {
        ...state,
        subsidiary_drivers: {
          ...state.subsidiary_drivers,
          data: state.subsidiary_drivers.data.map((driver) =>
            driver.driver_id !== action.payload.driver_id
              ? driver
              : {
                  ...driver,
                  driver: {
                    ...driver.driver,
                    name: action.payload.name,
                    status: action.payload.status,
                  },
                }
          ),
        },
      };
    }

    case SET_DRIVER_STATUS: {
      return {
        ...state,
        subsidiary_drivers: {
          ...state.subsidiary_drivers,
          data: state.subsidiary_drivers.data.map((driver) =>
            driver.driver_id !== action.payload
              ? driver
              : {
                  ...driver,
                  driver: {
                    ...driver.driver,
                    status: !driver.driver.status,
                  },
                }
          ),
        },
      };
    }

    case DELETE_LINK_DRIVER_WITH_SUBSIDIARY:
      return {
        ...state,
        subsidiary_drivers: {
          ...state.subsidiary_drivers,
          data: state.subsidiary_drivers.data.filter(
            (driver) => driver.driver_id !== action.payload
          ),
        },
      };

    case SET_SUBSIDIARY_DRIVERS_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_SUBSIDIARY_DRIVERS_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
