export default {
  disabled_retirement_cause: "Causa de retiro deshabilitada",
  permits: {
    change_status: "Sin permisos para {action} causas de retiro",
  },
  messages: {
    enable: "¿Estas seguro de habilitar la causa de retiro?",
    disable: "¿Estas seguro de deshabilitar la causa de retiro?",
  },
};
