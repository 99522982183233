import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";
import CheckboxField from "components/molecules/general/CheckboxField";

import css from "./index.module.scss";
import { REVIEW_TYPES } from "utils/review";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  movements,
  pressureConditions,
  depthConditions,
  corporates,
  companies,
  subsidiaries,
  isPile,
  current,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      {!current && (
        <>
          <Typography
            variant="overline"
            color="textSecondary"
            display="block"
            className={classnames(css.w100, css.ml8)}
          >
            {t("_labels.date.range")}
          </Typography>
          <Field
            name="date_from"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            component={DateTimeField}
            label={t("_labels.date.from")}
            className={classnames(css.medium_field)}
            required={!!values.date_to}
          />

          <Field
            name="date_to"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            component={DateTimeField}
            label={t("_labels.date.to")}
            className={classnames(css.medium_field)}
            required={!!values.date_from}
          />
        </>
      )}

      {!isPile && (
        <Field
          fullWidth
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          name="movement"
          component={SelectField}
          label={t("_labels.location.label")}
          className={classnames(css.field)}
        >
          {movements.map((movement, index) => {
            return (
              <MenuItem key={index} value={movement.value.toString()}>
                {movement.label}
              </MenuItem>
            );
          })}
        </Field>
      )}

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="corporate_id"
        component={SelectField}
        label={t("_labels.corporate_field.singular.label")}
        defaultText={t("_labels.corporate_field.singular.value")}
        className={classnames(css.field)}
      >
        {corporates.map((corporate, index) => {
          return (
            <MenuItem
              key={index}
              value={corporate.corporate_id.toString()}
              disabled={!corporate.status}
            >
              {corporate.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="company_id"
        component={SelectField}
        label={t("_labels.company_field.singular.label")}
        defaultText={t("_labels.company_field.singular.value")}
        className={classnames(css.field)}
      >
        {companies.map((company, index) => {
          return (
            <MenuItem
              key={index}
              value={company.company_id.toString()}
              disabled={!company.status}
            >
              {company.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiary_id"
        component={SelectField}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        className={classnames(css.field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="pressure_condition"
        component={SelectField}
        label={t("_labels.pressure_rating")}
        className={classnames(css.field)}
      >
        {pressureConditions.map((condition, index) => {
          return (
            <MenuItem key={index} value={condition.value}>
              {condition.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="depth_condition"
        component={SelectField}
        label={t("_labels.depth_rating")}
        className={classnames(css.field)}
      >
        {depthConditions.map((condition, index) => {
          return (
            <MenuItem key={index} value={condition.value}>
              {condition.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="activity"
        component={SelectField}
        label={t("_labels.activity.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="default">
          {t("_labels.activity.options.not_rule_out")}
        </MenuItem>
        <MenuItem value={"GENERAL"}>
          {t("_labels.activity.options.general")}
        </MenuItem>
        <MenuItem value={"GENERAL,MOUNT"}>
          {t("_labels.activity.options.general_and_mount")}
        </MenuItem>
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="review_type"
        component={SelectField}
        label={t("_labels.review_type.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="default">{t("_labels.no_option")}</MenuItem>
        {REVIEW_TYPES.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {t(type.label)}
          </MenuItem>
        ))}
      </Field>

      <Field
        fullWidth
        variant="filled"
        name="number_cycle"
        type="number"
        component={TextField}
        label={t("_labels.number_cycle")}
        className={classnames(css.field)}
        InputProps={{
          inputProps: {
            max: 5,
            min: 0,
          },
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="with_refection"
        component={CheckboxField}
        className={classnames(css.field)}
        label={t("_labels.show_refection")}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  movements: PropTypes.array.isRequired,
  depthConditions: PropTypes.array.isRequired,
  pressureConditions: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  isPile: PropTypes.bool,
  current: PropTypes.bool,
};

export default Page;
