export default {
  name: {
    unclassifiable_condition: "Condición inclasificable",
    retread_reject: "Rechazo de revitalizado",
    valve_damaged: "Válvula dañada",
    tread_depth: "Bajo milimetraje",
    generic_damage: "Daño genérico",
    alternate_lug_wear: "Desgaste alternado de tacos. (Bloques).",
    bad_bead_repair: "Reparación inadecuada de la ceja. (Talón).",
    bad_spot_repair: "Mala reparación de un punto en particular.",
    bead_area_flow_crack: "Grieta en el área de la ceja. (Talón).",
    bead_damage_from_curbing: "Ceja (Talón) dañada por rebordes (Aceras).",
    bead_deformation: "deformación de la ceja (Talón).",
    belt_lift_separation: "Cinturón (Estabilizador) levantado/separación.",
    belt_separation_repair_related:
      "Separación del Cinturón (Estabilizador) Por Reparación.",
    bias_repair_in_radial_tire:
      "Reparación de llanta convencional a llanta radial",
    bond_line_porosity: "Porosidad por deficiente vulcanizado.",
    brake_skid_damage: "Daño por bloqueo (patinazo) de frenos",
    brake_skid_flat_spot_wear:
      "Desgaste en punto especifico por bloqueo de frenos tracción",
    brake_skid_flat_spot_wear_trailer:
      "Desgaste en punto especifico por bloqueo de frenos remolque",
    branding_damage: "Daño por el marcado (quemado)",
    bridged_repair: "reparación de puentes",
    buckled_tread: "Banda de rodamiento deformada",
    burned_bead: "Cejas (talones) quemadas",
    chain_damage: "Daños en las cadena",
    chemical_damage: "Daños por agentes Químicos",
    chipping_flaking_chunking_tread:
      "Daño de rodamiento desbastada/laminado/desprendida",
    circumferential_fatigue_rupture_zipper:
      "Ruptura circunferencial por fatiga (En forma de cierre).",
    cupping_scallop_wear: "Desgaste tipo ahuecado/superficies con cavidades",
    cuts_snags: "cortes y rasgaduras.",
    damage_induced_sidewall_separation:
      "Separación en el costado( pared) inducida por daños",
    defense_groove_tearing: "Desgarramiento en la ranura defensiva.",
    delamination: "Exfoliación",
    diagonal_cracking: "Agrietamiento diagonal",
    diagonal_wear: "Desgaste diagonal en eje direccional",
    diagonal_wear_trailer: "Desgaste diagonal en eje de remolque",
    dynamometer_type_damage: "Daño de tipo dinamómetro",
    eccentric_out_of_round_wear: "desgaste excéntrico/fuera de redondez",
    electrical_discharge: "Descarga eléctrica",
    erosion_river_channel_wear:
      "Desgaste tipo rivera/canal/erosión direccional",
    erosion_river_channel_wear_trailer:
      "Desgaste tipo rivera/canal/erosión remolque",
    erratic_depression_wear: "Desgaste por depresión intermitentes direccional",
    erratic_depression_wear_trailer:
      "Desgaste por depresión intermitentes remolque",
    excessive_wear: "Desgaste excesivo",
    failed_inner_liner_repair: "falla en la reparación del sellador.",
    failed_repair_from_underinflation:
      "reparación fallida por rodado con presión insuficiente",
    failed_repair_injury_not_removed:
      "Falla en la reparación --lesión no removida",
    feather_wear: "desgaste tipo contrapelo (plumaje).",
    foreign_object_inner_liner_damage_in_tubeless_tire:
      "Daños en el revestimiento interior  por material extraño en llantas",
    forklift_damage: "Daño por uñas del montacargas/cortes piso",
    forklift_damage_cuts_snags: "Daño por uñas del montacargas/cortes costado",
    full_shoulder_wear: "Desgaste en toda la costilla del hombro",
    groove_cracking: "Grietas en las ranuras",
    heel_toe_wear: "desgaste de la punta/talón",
    impact_break: "rotura por impacto costado",
    impact_breaks: "roturas por impacto corona",
    improper_nail_hole_repair:
      "reparación inapropiada de un orificio por clavo",
    improper_tread_width: "Ancho incorrecto de banda de rodamiento",
    improperly_aligned_repair: "reparación inadecuadamente alineada",
    inner_liner_bubbles_blisters_and_separations:
      "burbujas,ampollas y separaciones en el revestimiento interior",
    inner_liner_cracking: "grietas en el revestimiento interior",
    kinked_distorted_bead: "cejas (talones) torcido/distorsionado",
    lug_base_cracking: "agrietamiento en la base de los tacos (Bloques)",
    lug_base_cracking_tearing:
      "agrietamiento en la base de los tacos (Bloques) desgarro",
    missed_puncture: "Ponchadura",
    multiple_flat_spotting_wear: "Desgaste en multiples zonas planas/lunares.",
    on_the_wheel_repair: "Reparación sobre la rueda",
    one_sided_wear: "desgaste unilateral direccional",
    one_sided_wear_trailer: "desgaste unilateral remolque",
    open_inner_liner_splice: "Union abierta del revestimiento interior",
    open_sidewall_splice: "Union abierta del costado",
    open_tread_splice: "Union abierta de la banda de rodamiento",
    overall_fast_wear: "Desgaste rápido generalizado direccional",
    overall_fast_wear_drive: "Desgaste rápido generalizado tracción",
    penetrating_objects: "Penetración de objetos",
    penetrations_and_road_hazards:
      "penetraciones daños por riesgos en el camino",
    petro_lubricant_damage: "Daño por petroleo/lubricante",
    petroleum_product_damage: "daños por derivados del petroleo",
    pinch_shock: "Pellizcamiento por golpe",
    pulled_loose_cords: "Cuerdas(telas) sueltas/arrancadas",
    radial_split: "Rajadura radial",
    rapid_shoulder_wear_both_shoulders: "Desgaste rápido en ambos hombros",
    rapid_shoulder_wear_one_shoulder: "Desgaste rápido en un hombros",
    regrooving_damage: "Daño ocasionado por re-ranurado(regrooving)",
    reinforce_chafer_separation: "reforzar la separación del refuerzo/chafer",
    repair_related_bulge: "Protuberancia por reparación inadecuada direccional",
    rib_depression_punch_wear:
      "Depresión en costillas/tipo sacabocados direccional",
    rib_depression_punch_wear_trailer:
      "Depresión en costillas/tipo sacabocados remolque",
    rib_tearing: "desgarro de costillas",
    run_flat: "Rodada con presión insuficiente interior",
    run_flat: "Rodada con presión insuficiente cualquiera",
    shoulder_scrubbing_scuffing: "hombro esmerilado/raspado",
    shoulder_step_chamfer_wear:
      "Desgaste escalonado/achaflanado en el hombro direccional",
    shoulder_step_chamfer_wear_drive:
      "Desgaste escalonado/achaflanado en el hombro tracción",
    sidewall_abrasion_scuff_damage:
      "Daño en el costado(pared) por abrasion/rozamiento",
    sidewall_bumps_blisters: "Protuberancias en el costado (ampollas)",
    sidewall_penetration: "penetración en el costado",
    sidewall_separation: "separación del costado",
    skive_failure: "Falla en el raspado",
    spin_damage: "daños por patinamiento",
    spot_repair_should_be_a_section_repair:
      "Reparación en la zona dañada debería ser una sección",
    spread_damaged_cord: "cuerda(tela) abierta/dañada",
    stone_drilling: "perforación por piedras",
    tearing_mount_dismount_damage: "Desgarramiento daños al montar/desmontar",
    torn_bead: "Cejas rasgadas",
    tread_chunking: "desprendimiento de la banda de rodamiento",
    tread_chunking_at_splice:
      "desprendimiento de la banda de rodamiento en la union",
    tread_edge_lifting: "Levantamiento en la orilla de la banda de rodamiento",
    tread_lift_separation: "Banda de rodamiento levantada/separación",
    tread_separation: "separación de la banda de rodamiento",
    tread_separation_repair_related:
      "Separación de la banda de rodamiento por reparación",
    tread_surface_porosity:
      "porosidad de la superficie de la banda de rodamiento",
    unfilled_nail_hole_repair:
      "reparación de perforación de clavos sin rellenar",
    vehicle_damage: "daños ocasionados por el vehículo",
    vehicle_equipment_damage: "daños con el vehículo/equipo",
    weathering: "alteración por agentes atmosféricos",
    wild_wire: "alambres caminantes",
    wing_lift: "Aleta levantada.",
  },
  appearance: {
    generic_damage_appearance: "Daño genérico",
    tread_depth_appearance: "Profundidad de la banda de rodadura",
    torn_bead_appearance:
      "El hule (goma) de la punta de la ceja (talón) se rasga o corta exponiendo el alambre o las cuerdas",
    kinked_distorted_bead_appearance:
      "Distorsión en áreas locales en el asiento de la ceja (talón)",
    bead_deformation_appearance:
      "Hendidura circunferencial en el área de la ceja (talón) de la llanta (neumático)",
    burned_bead_appearance:
      "Superficie áspera, quebradiza, deformada, dura y/o descolorada en el área de la ceja (talón)",
    reinforce_chafer_separation_appearance:
      "Grietas circunferenciales arriba del área del reborde de la ceja (talón) con exposición de material de cuerdas",
    petro_lubricant_damage_appearance:
      "El hule (goma) muestra una condición de rugosidad, arrugamiento o abultamiento esponjoso en el área de la ceja (talón). En etapas posteriores la ceja (talón) puede aparecer seca o quebradiza. Puede haber olor a petróleo",
    bead_damage_from_curbing_appearance:
      "Ondulación localizada en el área de la pestaña de la ceja (talón) sin exposición de cuerda (tela). También se pueden ver rasguños,abrasiones y desgarramiento en la parte inferior del costado (pared)",
    bead_area_flow_crack_appearance:
      "Grieta circunferencial arriba de la ceja (talón) sin evidencia de alambre expuesto",
    spread_damaged_cord_appearance:
      "Abultamiento radial en forma de lápiz en el costado (pared) de una llanta (neumático) radial",
    cuts_snags_appearance:
      "raspadura, rasgadura o cortes en el costado (pared)",
    sidewall_separation_appearance:
      "Abultamiento de forma irregular en el área media/superior del costado (pared); que puede progresar a una separación  completa del hule (goma) del costado (pared) de la carcasa exponiendo las cuerdas (telas) de la capa. No hay reparaciones, rupturas o pinchaduras evidentes en la carcasa",
    chain_damage_appearance:
      "Numerosas marcas (como marcas de viruela) alrededor de la llanta (neumático) en el área media a superior del y en la corona de la llanta (neumático)",
    vehicle_equipment_damage_appearance:
      "Rozamiento o cortes uniformes en alguna porción principal de la superficie externa de la llanta (neumático), que muchas veces se extienden 360°",
    run_flat_appearance_any_area:
      "Deterioro avanzado de la llanta (neumático) radial que afecta a los 360 en el costado (pared) de la misma",
    damage_induced_sidewall_separation_appearance:
      "Abultamiento de forma irregular en el área media/superior del costado (pared); que puede progresar a una separación completa del hule (goma) del costado (pared) de la carcasa ",
    sidewall_abrasion_scuff_damage_appearance:
      "Abrasión en grandes áreas del costado (pared), muchas veces 360° alrededor de la llanta (neumático)",
    weathering_appearance:
      "Numerosas grietas pequeñas en la superficie del hule (goma), la mayoría de las veces 360°  alrededor de la llanta (neumático)",
    impact_break_appearance:
      "Ruptura en el costado (pared) a través de la carcasa. Esta condición por lo general se deteriora rápidamente hasta tener una apariencia de llanta (neumático) radial rodada baja",
    branding_damage_appearance:
      "Grietas por esfuerzo que se extienden desde los número y letras. Marcados por el usuario en el costado (pared); hasta las cuerdas de la carcasa después de las fabricación",
    diagonal_cracking_appearance:
      "Grieta diagonal, o grietas en la parte superior del costado (pared) en llantas (neumáticos) en ejes de tracción y remolques",
    petroleum_product_damage_appearance:
      "Las paredes laterales aparecen hinchadas, blandas y esponjosas. En casos extremos, las paredes laterales pueden estar onduladas o distorsionadas. Puede ser evidente el olor a petróleo.",
    forklift_damage_appearance:
      "Corte similar al daño por impacto en una llanta (neumático) nueva",
    circumferential_fatigue_rupture_zipper_appearance:
      "Una rotura circunferencial entre la parte media y superior del costado (pared) que deja al descubierto una línea uniforme de cuerdas (tela) rotas",
    open_sidewall_splice_appearance:
      "Abertura regular de la capa superior del hule (goma) del costado (pared) que puede aparecer de forma radial o diagonal. La apariencia es similar a la de un corte, sin embargo, la abertura se extiende en un ángulo agudo hacia adentro del hule (goma) en el costado (pared). No hay cuerdas (telas) expuestas.",
    sidewall_bumps_blisters_appearance:
      "Áreas pequeñas, elevadas y dispersas en la parte superior del costado (pared) que pueden sentirse al tocar y frotar  la carcasa. En fases posteriores, el costado (pared) puede aparecer ampollado y puede provocar una rotura del mismo",
    sidewall_penetration_appearance:
      "Cualquier daño causado por un objeto que atraviese el costado (pared) de la llanta (neumático)",
    radial_split_appearance:
      "Una rotura vertical a través del costado (pared) que se produce entre dos cables del cuerpo de cuerdas (telas), que no rompe los cables.",
    penetrations_and_road_hazards_appearance:
      "Evidencia de un pinchazo o daño por un objeto extraño a través de la zona de la corona; puede dar lugar a una separación.",
    vehicle_damage_appearance:
      "Corte en la banda de rodamiento o el hombro, normalmente 360˚ alrededor de la llanta (neumático)",
    forklift_damage_cuts_snags_appearance:
      "Cortes individuales o múltiples, generalmente en las ranuras de la banda de rodamiento en una zona localizada de la corona.",
    belt_lift_separation_appearance:
      "Un abultamiento o separación en la zona superior del costado (pared). El palpado revelará los cinturones (estabilizadores) a veces están acompañados de puntos planos (gastados) en la banda de rodamiento",
    tread_lift_separation_appearance:
      "La protuberancia en el hombro o en el área de banda de rodamiento puede ir acompañada de una separación a traves del fondo de la ranura de la misma. Normalmente se produce un desgaste localizado en la banda de rodamiento por encima de la zona separada. Puede provocar la pérdida de una sección de la misma. El paquete de cinturón (estabilizador) esta intacto",
    brake_skid_damage_appearance:
      "Zona localizada de desgaste excesivo en banda de rodamiento del patinaje de la misma con la superficie del camino; el daño puede extenderse hasta la carcasa.",
    tread_chunking_appearance:
      "Secciones de la banda de rodamiento con arrancamiento",
    lug_base_cracking_appearance:
      "Una o más grietas en el hule (goma) de la banda de rodamiento situadas entre la base de las barras salientes en la zona del hombro.",
    wild_wire_appearance:
      "Alambre que sobresale a través de la superficie exterior o interior de la llanta (neumático)",
    impact_breaks_appearance:
      "Rotura localizada a traves del hule (goma) de la banda de rodamiento y la carcasa",
    chipping_flaking_chunking_tread_appearance:
      "Superficie de la banda de rodamiento áspera y desgastada, con numerosas escamas pequeñas o trozos desprendidos",
    stone_drilling_appearance:
      "Daños causados por piedras atrapadas en la banda de rodamiento que penetran en la base de la banda de rodamiento y se extienden a los cinturones (estabilizadores).",
    regrooving_damage_appearance:
      "Cinturones (estabilizadores) expuestos en la base del re-ranurado",
    dynamometer_type_damage_appearance:
      "Zona abultada y esponjosa oculta en el interior de la costilla/barra central que se extiende hasta 360˚ alrededor de la llanta (neumático).  Puede causar una zona de desgaste plano o puede aparecer como un cavidad localizada.",
    chemical_damage_appearance:
      "Zona de hule (goma) ampollada, esponjosa o deteriorada en el área de la banda de rodamiento.",
    excessive_wear_appearance:
      "Llanta (neumático) desgastada hasta el punto de exponer los cinturones (estabilizadores)",
    rib_tearing_appearance:
      "Desgarro en la base de las ranuras principales de la banda de rodamiento, generalmente en el hombro; no hay separación entre la banda de rodamiento y la carcasa.",
    defense_groove_tearing_appearance:
      "Desgarro en la base de la ranura defensiva (ranura de desacoplamiento, ranura de liberación de esfuerzo, ranura del hombro).",
    groove_cracking_appearance: "Áreas agrietadas en la base de ranuras",
    spin_damage_appearance:
      "Cortes o líneas circunferenciales en la banda de rodamiento",
    penetrating_objects_appearance:
      "Objeto extraño que sobresale a través de la superficie interior de la llanta (neumático)",
    open_inner_liner_splice_appearance:
      "Separación en la unión revestimiento interior",
    inner_liner_bubbles_blisters_and_separations_appearance:
      "Burbuja o ampolla en el interior de la llanta (neumático)",
    inner_liner_cracking_appearance:
      "Una o más grietas en el revestimiento interior en lugares distintos de la unión del revestimiento interior.",
    pulled_loose_cords_appearance:
      "Depresión o elevación en la superficie del interior de la llanta (neumático) a lo largo del recorrido de la cuerda (tela) radial",
    tearing_mount_dismount_damage_appearance:
      "Desgarro o pérdida de adherencia del material del revestimiento interior dentro de la zona del talón de la ceja (talón).",
    foreign_object_inner_liner_damage_in_tubeless_tire_appearance:
      "Una serie de cortes aleatorios o marcas de abrasión alrededor de la circunferencia del interior de la llanta (neumático), sin razón aparente.",
    run_flat_appearance:
      "Decoloración, ampollas y/o separaciones en el revestimiento interior",
    pinch_shock_appearance:
      "Una arruga o ruptura horizontal en forma de media luna o una rotura en el revestimiento interior en la zona del costado (pared). También puede aparecer un pequeño bulto en la zona dañada.",
    impact_break_appearance:
      "Grieta o rotura en la superficie interior de la llanta (neumático). Puede ir acompañada de cuerdas (telas) jaladas o sueltas.",
    electrical_discharge_appearance:
      "Grietas orientadas al azar en la llanta (neumático), a veces asociadas a agujeros o pinchazos sin explicación lógica",
    bad_spot_repair_appearance:
      "El hule (goma) de relleno de la reparación o está ausente o suelto en el área del costado (pared) o de la banda de rodamiento y a veces exhibe grietas.",
    spot_repair_should_be_a_section_repair_appearance:
      "El hule (goma) de relleno de la reparación está ausente o suelto del punto de reparación en el costado (pared). Puede haber cuerdas (telas) expuestas. Hay una distorsión o separación evidente dentro de la llanta (neumático)",
    improper_nail_hole_repair_appearance:
      "Interior: Una grieta o unión que se extiende desde la unidad de reparación (parche). La unidad de reparación puede estar hendida, agrietada o suelta. Puede haber algunos signos de generación de calor tales como hule (goma) grumoso o pegajoso. Exterior: La separación de los componentes de la llanta (neumático) puede ser evidente en las etapas avanzadas.",
    improperly_aligned_repair_appearance:
      "Interior: La unidad de reparación puede tener hoyos, estar levantada o agrietada. Las flechas de alineación de la unidad de reparación no apuntan en la dirección correcta. Exterior: Los componentes de los neumáticos pueden estar separados.",
    unfilled_nail_hole_repair_appearance:
      "Interior: La unidad de reparación tiene hoyos o grietas. Exterior: El óxido es evidente en el agujero sin rellenar.",
    bridged_repair_appearance:
      "Interior: La unidad de reparación está suelta, partida o desgarrada en la zona del hombro del neumático. Bajo la unidad de reparación puede ser evidente una protuberancia, un área pulida o pegajosa causada por el calor excesivo. El material de relleno también puede estar agrietado. En el exterior: Puede ser evidente una protuberancia en el flanco y el material de relleno puede estar agrietado. Los componentes del neumático pueden estar separados.",
    on_the_wheel_repair_appearance:
      "Interior: Se utiliza un cordón recubierto de goma o un tapón de goma curado para rellenar el agujero sin que la unidad de reparación cubra la lesión. Pueden ser evidentes las grietas o la separación alrededor del agujero. También pueden ser evidentes otros daños por objetos penetrantes. Exterior: La banda de rodadura o los componentes del neumático pueden estar separados.",
    bad_bead_repair_appearance:
      "Interior: El revestimiento interior puede estar separado y la zona del talón puede mostrar tejido o acero expuesto, grietas, goma suelta y/o un contorno del talón inadecuado. Exterior: La separación de los componentes del neumático puede ser evidente.",
    failed_repair_injury_not_removed_appearance:
      "Interior: Hay una grieta o hendidura debajo de la unidad de reparación. La unidad de reparación también puede estar suelta o faltar. Exterior: Los componentes del neumático pueden estar separados dejando cordones oxidados y sueltos",
    bias_repair_in_radial_tire_appearance:
      "Interior: La unidad de reparación está agrietada o distorsionada. Las unidades de reparación de neumáticos diagonales pueden ser redondas o en forma de 'X' y no están etiquetadas como 'Radial' (todas las unidades de reparación de neumáticos radiales reforzados están marcadas como 'Radial'): Los componentes del neumático pueden estar separados.",
    bond_line_porosity_appearance:
      "Grandes secciones, si no todas, de la banda de rodadura se han separado de la carcasa; la porosidad es evidente. Esto aparece como una superficie esponjosa que a menudo es pegajosa.",
    tread_separation_appearance:
      "Una porción de goma de la banda de rodadura solamente, situada en cualquier zona del neumático, se levanta y se separa de la superficie pulida del cuerpo del neumático.",
    tread_chunking_at_splice_appearance:
      "Faltan porciones de la banda de rodadura en la zona del empalme de la banda de rodadura en un recauchutado pre curado.",
    tread_separation_repair_related_appearance:
      "Una parte de la banda de rodadura está separada de la superficie pulida. Es evidente una reparación en la zona de la banda de rodadura separada.",
    belt_separation_repair_related_appearance:
      "Exterior: La banda de rodadura y uno o más cinturones están sueltos o faltan en una parte o en todo el neumático. Se aprecia una reparación o una rozadura en la zona de la separación. Interior: La unidad de reparación puede estar hundida, con hoyuelos o agrietada.",
    missed_puncture_appearance:
      "Una parte de la banda de rodadura se separa de la carcasa en la línea de amortiguación en la zona del pinchazo no reparado. Esto suele ocurrir poco después de recauchutado.",
    tread_edge_lifting_appearance:
      "La banda de rodadura está desgastada en exceso en uno de los hombros del neumático; el borde de la banda de rodadura está suelto y presenta más desgaste en la zona de separación.",
    failed_inner_liner_repair_appearance:
      "Interior: Una grieta se extiende desde la goma de reparación. Pueden aparecer marcas de pulido visible alrededor de la goma de reparación. La zona reparada puede recubrirse con sellador de revestimiento. En el exterior: Los componentes del neumático pueden estar separados.",
    lug_base_cracking_tearing_appearance:
      "El agrietamiento es evidente en la base de los tacos de la banda de rodadura. Algunos tacos pueden ser arrancados de la banda de rodamiento en casos severos.",
    improper_tread_width_appearance:
      "Aparece una grieta en el borde de la banda de rodadura que puede extenderse hasta la correa. El borde de la banda de rodadura puede levantarse de la carcasa.",
    open_tread_splice_appearance:
      "Se aprecia un espacio entre los extremos de la banda de rodadura.",
    skive_failure_appearance:
      "Una protuberancia o un desgaste excesivo en la zona de la banda de rodadura, o una zona en la que falta la banda de rodadura en un patín.",
    repair_related_bulge_appearance:
      "Un abultamiento excesivo en el flanco medio/superior que puede progresar hasta una separación completa de la goma del flanco de la carcasa. Una ligera protuberancia asociada a una reparación que puede identificarse con una etiqueta triangular azul es normal, ya que los cables se reposicionan tras la reparación. La protuberancia no debe superar los 3/8in.",
    buckled_tread_appearance:
      "Las ondulaciones son evidentes en la superficie de la banda de rodadura y en el interior de un recauchutado curado en molde.",
    delamination_appearance:
      "Evidencia de capas de goma en la banda de rodadura.",
    tread_surface_porosity_appearance:
      "Un aspecto esponjoso en la superficie de la banda de rodadura. Pueden faltar partes de la banda de rodadura.",
    wing_lift_appearance:
      "La goma del hombro lateral del recauchutado está suelta",
    failed_repair_from_underinflation_appearance:
      "Grietas en la unidad de reparación, grietas que salen de la unidad de reparación, bordes sueltos bajo la unidad de reparación, superficie pegajosa alrededor de la zona reparada o bajo la unidad de reparación.",
    shoulder_step_chamfer_wear_appearance:
      "Desgaste uniforme de la banda de rodadura en el centro con escalones desgastados en los hombros. Esto se limita a la parte exterior de la costilla del hombro. Puede ser más ancho en un hombro que en el otro.",
    full_shoulder_wear_appearance:
      "Desgaste excesivo que se extiende a lo largo de toda la costilla del hombro hasta un surco importante de la banda de rodadura, generalmente en un solo lado del neumático.",
    feather_wear_appearance:
      "Las nervaduras de la banda de rodadura están desgastadas de manera que un lado de la nervadura es más alto que el otro, lo que da lugar a escalones en la cara de la banda de rodadura. Por lo general, todas las nervaduras presentan este desgaste.",
    erosion_river_channel_wear_appearance:
      "El desgaste circunferencial a lo largo de los bordes de las costillas junto a los surcos principales de la banda de rodadura puede tener un aspecto ondulado y variar en anchura alrededor del neumático.",
    cupping_scallop_wear_appearance:
      "Zonas localizadas de desgaste rápido que crean un aspecto festoneado alrededor del neumático. Aparece alrededor del neumático en las costillas del hombro. Puede progresar a las costillas adyacentes.",
    one_sided_wear_appearance:
      "Desgaste excesivo en un lado del neumático que se extiende desde el hombro hacia el centro de la banda de rodadura.",
    diagonal_wear_appearance:
      "Puntos planos localizados que se desgastan en diagonal a través de la banda de rodadura, a menudo repitiéndose alrededor de la circunferencia de la banda de rodadura.",
    eccentric_out_of_round_wear_appearance:
      "Diferencia considerable en la profundidad de la banda de rodadura entre 90° y 180° de distancia.",
    overall_fast_wear_appearance:
      "Buen patrón de desgaste, pero con una tasa de desgaste rápida.",
    rib_depression_punch_wear_appearance:
      "Una o más costillas interiores desgastadas por debajo del nivel de las costillas adyacentes circunferencialmente alrededor del neumático.",
    erratic_depression_wear_appearance:
      "Desgaste aleatorio y errático en la circunferencia del neumático.",
    shoulder_step_chamfer_wear_drive_appearance:
      "Neumático desgastado en el borde del hombro. Se limita a la parte exterior del hombro. Puede ser más ancho en un hombro que en el otro. Esta condición suele aparecer primero en el hombro interior de la doble cara interna del eje motor delantero.",
    heel_toe_wear_appearance:
      "Cada taco alrededor del neumático desgastado de arriba a abajo desde el borde delantero hasta el trasero.",
    alternate_lug_wear_appearance:
      "Tacos alternos desgastados a diferentes profundidades de la banda de rodadura alrededor de la circunferencia del neumático. Puede ser cada dos tacos, cada tres, etc... o una combinación de ellos.",
    brake_skid_flat_spot_wear_appearance:
      "Punto plano en la superficie de la banda de rodadura. La textura de la superficie puede mostrar marcas de abrasión por el deslizamiento de la banda de rodadura sobre la superficie de la carretera, pero la superficie puede haberse desgastado desde entonces. A menudo se presenta en más de una posición del neumático en un eje.",
    overall_fast_wear_drive_appearance:
      "Patrón de desgaste uniforme, pero con una tasa de desgaste rápida.",
    brake_skid_flat_spot_wear_trailer_appearance:
      "Mancha localizada de desgaste excesivo en la cara de la banda de rodadura. La textura de la superficie puede mostrar marcas de abrasión circunferencial por el deslizamiento de la banda de rodadura sobre la superficie de la carretera, pero la superficie puede haberse desgastado desde entonces. Suele aparecer en ambos neumáticos de un conjunto doble.",
    diagonal_wear_trailer_appearance:
      "Puntos planos localizados desgastados en diagonal a través de la banda de rodadura en ángulos de aproximadamente 25 ̊-35 ̊ que se repiten a menudo alrededor de la circunferencia de la banda de rodadura.",
    multiple_flat_spotting_wear_appearance:
      "Numerosas zonas desgastadas en la circunferencia o en la banda de rodadura.",
    rapid_shoulder_wear_one_shoulder_appearance:
      "Neumático desgastado en el borde de un hombro que a veces se extiende a las costillas internas. Puede progresar a un borrado diagonal.",
    shoulder_scrubbing_scuffing_appearance:
      "Abrasión en el flanco superior del neumático cerca del borde de la banda de rodadura. Puede ir acompañada de grietas en la zona del hombro de la banda de rodadura.",
    rapid_shoulder_wear_both_shoulders_appearance:
      "Desgaste completo de las costillas en ambos hombros.",
    erratic_depression_wear_trailer_appearance:
      "Desgaste aleatorio y errático en la circunferencia del neumático.",
    one_sided_wear_trailer_appearance:
      "Desgaste excesivo en un lado del neumático.",
    erosion_river_channel_wear_trailer_appearance:
      "Desgaste localizado a lo largo de los bordes interiores de las nervaduras de la banda de rodadura, que pueden tener un aspecto ondulado y variar en anchura alrededor del neumático.",
    rib_depression_punch_wear_trailer_appearance:
      "Una o más costillas interiores desgastadas por debajo del nivel de la costilla adyacente alrededor de la circunferencia del neumático.",
  },
  probable_causes: {
    run_flat_probable_causes_any_area:
      "Pérdida de presión de inflado. El diagnóstico de la causa de la pérdida de inflado se hace difícil o imposible a medida que esta condición progresa.",
    torn_bead_probable_causes:
      "Pobres técnicas de montaje/desmontaje, con herramientas no adecuadas para llantas (neumáticos) y/o pobre lubricación, espátula dañada, inadecuados procedimientos en el manejo de la llanta (neumático)",
    kinked_distorted_bead_probable_causes:
      "Uso inadecuado de herramientas o técnicas incorrectas utilizadas durante el montaje de la llanta (neumático);daños durante embarque/manejo de la llanta (neumático)",
    bead_deformation_probable_causes:
      "Rines oxidados, lubricación inadecuada de ceja (talón)/Rim (Aro). y rines dañados o doblados como resultado de un asentamiento inadecuado de la ceja (talón). Calor excesivo en el área de la ceja (talón)",
    burned_bead_probable_causes:
      "Exposición excesiva al calor ocasionada por frenado rudo y frecuente;ajuste inadecuado de frenos;sistema de frenos defectuoso; flujo de aire insuficiente alrededor de los frenos",
    reinforce_chafer_separation_probable_causes:
      "Asentamiento inadecuado de la ceja (talón), tamaño incorrecto del rim (aro), condición de sobrecarga, insuficiente presión de inflado, impacto o posible condición de fabricación",
    petro_lubricant_damage_probable_causes:
      "Uso de productos derivados del petróleo tales como lubricantes, es decir, aceite, diesel, combustible y anticongelante",
    bead_damage_from_curbing_probable_causes:
      "Golpe con rebordes (banquetas, aceras, cordones, etc.)",
    bead_area_flow_crack_probable_causes:
      "Flujo inadecuado del hule (goma) mediante el proceso de manufactura",
    spread_damaged_cord_probable_causes:
      "Esparcimiento más amplio de lo normal. Un neumático reparado puede mostrar esta condición poco después de ser reparado, o la condición puede surgir en algún lugar a lo largo de la carcasa donde ha sido dañada - no necesariamente en el área de reparación.",
    cuts_snags_probable_causes:
      "Daño atribuible al camino, golpe con las banquetas (aceras, cordones), daño por equipo, rieles de lavado, rieles de fosa, vandalismo, etc.",
    sidewall_separation_probable_causes:
      "Pérdida de adherencia entre el hule (goma) del costado y la capa del cuerpo.",
    chain_damage_probable_causes:
      "Abrasión por cadena que puede deberse a cadenas sueltas o de tamaño inadecuado o al uso prolongado de la cadena en superficies secas.",
    vehicle_equipment_damage_probable_causes:
      "Contacto con las partes rígidas  del vehículo, como pernos sueltos, abrazaderas de muelles deslizados, pernos de sujeción, defensas sueltas, salpicaduras y molduras de casas rodantes, etc. También puede ser causado por objetos que queden atrapados entre los duales",
    damage_induced_sidewall_separation_probable_causes:
      "Una pinchadura, impacto, daño al revestimiento interior o a la ceja (talón)",
    sidewall_abrasion_scuff_damage_probable_causes:
      "Frotamientos contra rebordes (banquetas,aceras, cordones) o rieles guía. Con frecuencia se ve en servicios de entrega en la ciudad",
    weathering_probable_causes:
      "Exposición de la superficie del hule (goma) a los elementos ambientales. Agravada por períodos largos de estacionamiento y altas concentraciones de ozono",
    impact_break_probable_causes:
      "Ocasionadas por un impacto repentino con algún elemento del camino o un bache.Agravada por sobre inflado",
    branding_damage_probable_causes:
      "Ocasionado por un marcado muy profundo, en el lugar equivocado del costado (pared) de la llanta (neumático). a una temperatura muy alta, o en un ángulo muy agudo",
    diagonal_cracking_probable_causes:
      "Frecuentemente puede ser el resultado de transferencia de torsión. Agravada por baja presión de aire. Esta condición no debe con fundirse con cortes ocasionados por los filos de las banquetas (aceras, cordones), daños atribuibles al camino, etc.",
    petroleum_product_damage_probable_causes:
      "Aceite, diesel, anticongelante o químicos",
    forklift_damage_probable_causes:
      "Ocasionado durante el embarque y manejo a cargo del montacargas",
    circumferential_fatigue_rupture_zipper_probable_causes:
      "Inflado con poco aire que produce fatiga a las cuerdas (tela) de la carcasa, muchas veces como resultado de una penetración",
    open_sidewall_splice_probable_causes: "Proceso de manufactura",
    sidewall_bumps_blisters_probable_causes:
      "Deterioro de los componentes internos con el paso del tiempo. Fatiga de la carcasa. Acelerado por sobrecarga/baja presión de inflado",
    sidewall_penetration_probable_causes:
      "Basura en el camino y, ocasionalmente, vandalismo con un instrumento puntiagudo.",
    radial_split_probable_causes:
      "Impacto severo. Esta situación puede ocurrir en condiciones de sobre inflado y/o sobrecarga",
    penetrations_and_road_hazards_probable_causes:
      "Daño por objetos extraños en el camino",
    vehicle_damage_probable_causes:
      "Contacto con las partes rígidas del vehículo, como los guardabarros, las molduras de casas rodantes, defensas, etc.",
    forklift_damage_cuts_snags_probable_causes:
      "Ocasionado por uñas del montacargas o un equipo similar",
    belt_lift_separation_probable_causes:
      "Pérdida de adherencia dentro de la llanta (neumático) en el borde del paquete de cinturones (estabilizadores). Puede estar asociada a una penetración u otros daños. Puede agravarse por el uso de ejes extendidos o por arrastrar la llanta  (neumático) lateralmente.",
    tread_lift_separation_probable_causes:
      "Pérdida de adherencia entre el hule (goma) de la banda de rodamiento y el cinturón estabilizador  de la llanta (neumático). Puede ser causada por penetración en la banda de rodamiento.",
    brake_skid_damage_probable_causes:
      "El patinaje por frenada brusca  suele producirse en las llantas (neumáticos) del remolque y tracción. Se agrava con frenos nuevos (de alta fricción, no desgastados), sistema de frenos no balanceado, uso agresivo de los frenos, abuso del conductor y vehículos sin carga.",
    tread_chunking_probable_causes:
      "Ocasionado por el rodamiento de la llanta (neumático) contra rebotes (banquetas, aceros y cordones), rieles o impactos localizados severos. Agravado por neumáticos calientes, ejes extendidos, vueltas muy cerradas y utilización fuera de  carretera.",
    lug_base_cracking_probable_causes:
      "Esta condición es causada por una combinación de torsión del eje de tracción, carga y calor. También puede deberse a la construcción de la llanta (neumático) o iniciarse por la perforación de piedras. Esta condición es agravada por presión insuficiente",
    wild_wire_probable_causes:
      "Alambres sueltos o separados de la ceja (talón) o del cuerpo de cuerdas (tela)(carcasa)",
    impact_breaks_probable_causes:
      "Impacto severo y concentrado con un objeto extraño o un bache. Agravado por el sobre inflado y la alta velocidad.",
    chipping_flaking_chunking_tread_probable_causes:
      "Operación de las llantas (neumáticos) con compuestos de hule (goma) de la banda de rodamiento para uso en caminos pavimentados sobre caminos de tierra o en superficies con grava; aplicación incorrecta de la llanta (neumático) a las condiciones de servicio. Agravado una elevada torsión, un inflado excesivo y giros cortos, especialmente en los llantas (neumáticos) de tracción.",
    stone_drilling_probable_causes:
      "Diseño de la banda de rodamiento y/o mala aplicación del neumático para las condiciones de servicio (caminos de grava/operación en minas).",
    regrooving_damage_probable_causes: "Re-ranurado demasiado profundo",
    dynamometer_type_damage_probable_causes:
      "La generación excesiva de calor en un dinamómetro, también en aplicaciones de alta velocidad, ligeramente cargadas y sobre infladas, utilizando llantas (neumáticos) con costillas/barras con gran profundidad de dibujo.",
    chemical_damage_probable_causes:
      "Exposición a productos químicos, generalmente de naturaleza solvente, que atacan el hule (goma) de la banda de rodamiento.",
    excessive_wear_probable_causes:
      "Demasiado tiempo en servicio o fallo en el sistema de frenos",
    rib_tearing_probable_causes:
      "Provocado por el rodamiento sobre banquetas y rieles , o por fuertes impactos localizados, agravados por ejes extendidos, neumáticos calientes y giros muy cerrados.",
    defense_groove_tearing_probable_causes:
      "Provocado por el rodamiento sobre banquetas y rieles,  o por fuertes impactos localizados,agravado por ejes extendidos, llantas (neumáticos) calientes y  giros muy cerrados.",
    groove_cracking_probable_causes:
      "Esta condición es causada por las altas fuerzas laterales aplicadas a una llanta (neumático) tipo costilla. También puede ser causada por el petróleo, la intemperie o los gases de escape en los neumáticos de tracción.",
    spin_damage_probable_causes:
      "Patinamiento de las llantas (neumáticos) de tracción sobre hielo, arena, grava, etc.",
    penetrating_objects_probable_causes:
      "Impacto con objetos peligrosos en el camino",
    open_inner_liner_splice_probable_causes:
      "Pérdida de adherencia en la unión del revestimiento interior debido a una flexión excesiva por falta de inflado; también puede estar relacionado con la fabricación.",
    inner_liner_bubbles_blisters_and_separations_probable_causes:
      "Pérdida de adherencia del material del revestimiento interior con la carcasa.",
    inner_liner_cracking_probable_causes:
      "Bajo inflado, acumulación excesiva de calor en el interior de la llanta (neumático). También puede estar relacionado con la fabricación de la llanta (neumático).",
    pulled_loose_cords_probable_causes:
      "Los impactos, las penetraciones, las malas reparaciones y el inflado insuficiente, lo cual daña el material de la carcasa.",
    tearing_mount_dismount_damage_probable_causes:
      "Malas técnicas de montaje/desmontaje o falta de lubricante.",
    foreign_object_inner_liner_damage_in_tubeless_tire_probable_causes:
      "Objeto extraño en la llanta (neumático).",
    run_flat_probable_causes:
      "Continuación del funcionamiento de la llanta (neumático) tras la pérdida de presión de inflado.",
    pinch_shock_probable_causes: "Impacto severo",
    impact_break_probable_causes:
      "Fuerza repentina y excesiva aplicada al exterior de la llanta (neumático) que excede los límites de resistencia del material. Puede ser causado por daños con el camino o por el abuso del conductor.",
    run_flat_probable_causes:
      "Pérdida de presión de inflado. El diagnóstico de la causa de la pérdida de inflado se hace difícil o imposible a medida que esta condición progresa.",
    electrical_discharge_probable_causes:
      "Contacto del vehículo con alambres eléctricos o relámpagos",
    bad_spot_repair_probable_causes:
      "Mano de obra deficiente. Área pulida contaminada, cementación inadecuada, material de relleno contaminado, tiempo o presión de curado inadecuados, eliminación de lesiones inadecuada, etc.",
    spot_repair_should_be_a_section_repair_probable_causes:
      "Mala mano de obra. No se eliminó toda la lesión. Se debería haber hecho una reparación completa de la sección forzada de la rienda",
    improper_nail_hole_repair_probable_causes:
      "Se utilizaron procedimientos de reparación de agujeros de clavos en lugar de procedimientos de reparación de secciones, lo que dio lugar a una eliminación inadecuada de las lesiones. Se utilizó una unidad de reparación de tamaño y tipo inadecuados.",
    improperly_aligned_repair_probable_causes:
      "Unidad de reparación mal instalada. (En este caso, las flechas de los cordones no apuntan a los cordones). Personal de reparación mal formado.",
    unfilled_nail_hole_repair_probable_causes:
      "La lesión no fue rellenada con un tapón o material de relleno de goma.",
    bridged_repair_probable_causes:
      "Colocación incorrecta de la unidad de reparación durante la instalación, lo que provocó que el aire quedara atrapado bajo la unidad de reparación. Es probable que los talones del neumático se hayan extendido durante la instalación de la unidad de reparación.",
    on_the_wheel_repair_probable_causes:
      "El neumático no fue desmontado ni inspeccionado y reparado adecuadamente. No se recomienda este tipo de reparación.",
    bad_bead_repair_probable_causes:
      "La lesión excede la limitación de la reparación, la mano de obra deficiente, el curado inadecuado, el material de relleno incorrecto o las técnicas de montaje/desmontaje deficientes.",
    failed_repair_injury_not_removed_probable_causes:
      "No se detectaron y eliminaron todas las lesiones o separaciones durante el proceso de reparación, una mala mano de obra.",
    bias_repair_in_radial_tire_probable_causes:
      "Se instaló una unidad de reparación incorrecta, personal mal formado.",
    bond_line_porosity_probable_causes:
      "Falta de condiciones adecuadas de curado, es decir, tiempo, temperatura y presión.",
    tread_separation_probable_causes:
      "Mano de obra y/o material defectuoso en el recauchutado, como una superficie pulida quemada o contaminada, goma amortiguadora o goma de la banda de rodadura vieja, condiciones de curado inadecuadas, omisión de un orificio de clavado o una reparación defectuosa.",
    tread_chunking_at_splice_probable_causes:
      "Mano de obra inadecuada o contaminación en el empalme.",
    tread_separation_repair_related_probable_causes:
      "Reparación defectuosa. El aire se filtró o quedó atrapado en la lesión y bajo la banda de rodadura, lo que acabó reduciendo la adherencia entre la banda de rodadura y el neumático y provocó una separación.",
    belt_separation_repair_related_probable_causes:
      "Una reparación defectuosa o un patín. El aire migró a través de la lesión causando la separación entre las correas.",
    missed_puncture_probable_causes:
      "Una penetración no detectada y no reparada.",
    tread_edge_lifting_probable_causes:
      "Causado por problemas relacionados con el curado, problemas de procesamiento del recauchutado, el hombro pulido más bajo en un lado o la posible separación del borde de la banda.",
    failed_inner_liner_repair_probable_causes:
      "Un intento de reparar las ampollas del revestimiento o un empalme abierto del revestimiento no logró sellar la cavidad de aire del neumático y el aire migró hacia el cuerpo del neumático causando la separación.",
    lug_base_cracking_tearing_probable_causes:
      "Se utilizó un diseño de banda de rodadura incorrecto para la operación (el eje único, la aplicación de un par de torsión elevado con un diseño de banda de rodadura de tracción profunda puede contribuir a esta condición), el compuesto de caucho, el exceso o la falta de sub gravedad, el exceso de curado.",
    improper_tread_width_probable_causes:
      "Un soporte inadecuado del paquete de cinturones durante el funcionamiento que crea un nuevo punto de flexión en el neumático. El resultado es la rotura de las correas o del caucho en la línea de unión del recauchutado y la carcasa.",
    open_tread_splice_probable_causes:
      "Mala mano de obra, mala adherencia del extremo de la banda de rodadura o la banda de rodadura se cortó demasiado corta",
    skive_failure_probable_causes:
      "Un relleno inadecuado de la banda de rodadura que atrapa el aire o permite que el aire se filtre bajo la banda de rodadura y da lugar a una pérdida localizada de la adherencia de la banda de rodadura. Puede producirse una separación de la banda de rodadura o de la carcasa si no se detecta en una fase temprana de la vida útil de la banda de rodadura.",
    repair_related_bulge_probable_causes:
      "La unidad de reparación es demasiado pequeña o está mal instalada.",
    buckled_tread_probable_causes:
      "El neumático recauchutado era demasiado grande para el molde en el que se curó, lo que provocó una distorsión en el neumático durante el proceso de curado.",
    delamination_probable_causes:
      "Exceso de lubricación del molde o un problema de composición del caucho, curado de la superficie del caucho de la banda de rodadura o mal ajuste del molde.",
    tread_surface_porosity_probable_causes:
      "Goma de la banda de rodadura insuficiente, curado inadecuado o mal ajuste del molde.",
    wing_lift_probable_causes:
      "Falta de pulido del hombro o mal ajuste del molde (curado del molde), mala construcción de la banda de rodadura, superficie pulida chamuscada o contaminada y/o radio de corona inadecuado.",
    failed_repair_from_underinflation_probable_causes:
      "Llevar el neumático poco inflado debido a un pinchazo, un corte, un vástago de válvula malo, la falta de presión de aire adecuada, etc.",
    shoulder_step_chamfer_wear_probable_causes:
      "Típico de los neumáticos radiales en operaciones de desgaste lento. Puede variar con el diseño de la banda de rodadura y la aplicación de servicio.",
    full_shoulder_wear_probable_causes:
      "Es el resultado de la fricción lateral y generalmente es causada por una condición inadecuada de la convergencia o una desalineación del eje motriz. En caso de convergencia, los hombros exteriores de ambos neumáticos de dirección se desgastan, mientras que en caso de convergencia, los hombros interiores de ambos neumáticos de dirección se desgastan. La desalineación del eje motriz desgasta el hombro interior de un neumático y el hombro exterior del otro neumático de dirección. Los componentes de la suspensión mal mantenidos (por ejemplo, varillas de torsión, muelles y casquillos de muelles) pueden producir los mismos resultados.",
    feather_wear_probable_causes:
      "Excesiva fricción de la fuerza lateral, resultante de condiciones severas de desalineación, tales como excesiva convergencia, desalineación severa del eje motriz, componentes de la suspensión desgastados, faltantes o dañados, barra de acoplamiento doblada u otra desalineación del chasis.",
    erosion_river_channel_wear_probable_causes:
      "Característica de la lenta tasa de desgaste de los neumáticos radiales en los ejes de rodadura libre. Puede variar con el diseño y la construcción de la banda de rodadura de cada neumático. Común en operaciones de transporte en línea en las que las cargas son ligeras y los giros son frecuentes.",
    cupping_scallop_wear_probable_causes:
      "Suele ser el resultado de una condición de desequilibrio de montaje moderado a severo, un montaje incorrecto de la llanta/rueda u otra falta de uniformidad de montaje. También puede deberse a la falta de control del amortiguador en algunos tipos de suspensión.",
    one_sided_wear_probable_causes:
      "Alineación incorrecta, pivotes desgastados, rodamientos de la rueda delantera sueltos, cargas excesivas en el eje.",
    diagonal_wear_probable_causes:
      "Desplazamiento y/o desequilibrio junto con una tasa de desgaste lenta. Puede desarrollarse a partir de un patinaje de los frenos, un desgaste puntual, un desgaste de los hombros u otras condiciones de desgaste avanzado. También puede ser causado por cojinetes de rueda sueltos y se agrava por la desalineación.",
    eccentric_out_of_round_wear_probable_causes:
      "Suele ser el resultado de una excesiva excentricidad radial u otra falta de uniformidad en el conjunto giratorio, por ejemplo, el buje, el círculo de espárragos, la rueda/llanta, el neumático y/o el asentamiento inadecuado del talón del neumático en la llanta. También puede ser causado por el arrastre o la distorsión de los tambores de freno y los tambores con una variación excesiva del grosor de las paredes. Puede ir acompañado de, o convertirse en, una queja de vibración",
    overall_fast_wear_probable_causes:
      "Puede ser causada por condiciones de servicio tales como terreno montañoso, frecuencia y severidad de los giros, superficies abrasivas de la carretera en combinación con las configuraciones de los vehículos y sus atributos tales como dirección asistida, cargas pesadas en los ejes, cortes altos de las ruedas, ejes retraídos, tractores de corta distancia entre ejes, camiones rectos de larga distancia entre ejes.",
    rib_depression_punch_wear_probable_causes:
      "Falta de control de los amortiguadores en algunos tipos de suspensión, presiones de inflado inadecuadas, cojinetes de las ruedas sueltos o desgastados, falta de uniformidad en el montaje, como por ejemplo un asiento inadecuado de los talones y un estado de desequilibrio, agravado por los trayectos en vacío a alta velocidad",
    erratic_depression_wear_probable_causes:
      "Falta de control de los amortiguadores en algunos tipos de suspensión. Cojinetes de rueda sueltos o desgastados, falta de uniformidad en el montaje, como por ejemplo, un asiento inadecuado de los talones y un estado de desequilibrio, agravado por los trayectos en vacío a alta velocidad.",
    shoulder_step_chamfer_wear_drive_probable_causes:
      "Típico de la construcción de los neumáticos radiales y de las tasas lentas de desgaste de la banda de rodadura. Puede variar con el diseño de la banda de rodadura y la aplicación de servicio. Puede verse agravado por la desviación del eje, las condiciones de la carretera y las piezas de la suspensión desgastadas o dañadas.",
    heel_toe_wear_probable_causes:
      "La mayoría de las veces se debe a presiones de inflado o diámetros de neumático desajustados en un conjunto doble y a ciertas condiciones como operaciones de P&D, terrenos montañosos, etc.",
    alternate_lug_wear_probable_causes:
      "Presiones de inflado o diámetros de neumáticos no coincidentes en un conjunto doble y ciertas condiciones como las operaciones de recogida y entrega y las variaciones de carga. Se acentúa por la lentitud del desgaste de los neumáticos y por componentes de la suspensión desgastados o mal mantenidos o por la desalineación de los ejes.",
    brake_skid_flat_spot_wear_probable_causes:
      "Agravado por frenos nuevos (de alta fricción, no desgastados), sistema de frenos desequilibrado, uso agresivo de los frenos y abuso del conductor. A veces se observa en los vehículos nuevos que se van de viaje.",
    overall_fast_wear_drive_probable_causes:
      "Pueden ser causados por condiciones de servicio tales como terreno montañoso, frecuencia y severidad de los giros, superficies abrasivas de la carretera y equipos tales como tracción de un solo eje, camiones rectos de larga distancia entre ejes, cargas pesadas por eje, motores de alta potencia o componentes del tren de transmisión desajustados.",
    brake_skid_flat_spot_wear_trailer_probable_causes:
      "El patinaje de los frenos se produce con mayor frecuencia en los neumáticos de la plataforma móvil, del remolque y de la unidad motriz. Se agrava cuando los frenos son nuevos (de alta fricción, no están desgastados), cuando el sistema de frenos está desequilibrado, cuando se utilizan los frenos de forma agresiva, cuando los frenos se congelan y cuando el conductor abusa de los frenos del remolque para detener el vehículo. A veces se ve en los vehículos nuevos que se van de viaje.",
    diagonal_wear_trailer_probable_causes:
      "Cojinetes de rueda defectuosos, convergencia, montaje incorrecto del conjunto de neumático y rueda en el remolque, dobles mal ajustados en cuanto a tamaño y/o presiones de inflado; puede comenzar como patinaje de los frenos. Agravado por los transportes de alta velocidad en vacío o con poca carga.",
    multiple_flat_spotting_wear_probable_causes:
      "Carga doble desigual debido a presiones de inflado o tamaños de neumáticos no coincidentes, rodamientos de rueda desgastados, desequilibrio, suspensiones neumáticas con amortiguadores en mal estado, abuso de los frenos del remolque por parte del conductor; agravado por los transportes en vacío a alta velocidad.",
    rapid_shoulder_wear_one_shoulder_probable_causes:
      "Excesiva convergencia, excesiva inclinación. Estas condiciones pueden ser creadas por un eje desalineado o doblado y también pueden ser causadas por rodamientos de rueda sueltos o desgastados.",
    shoulder_scrubbing_scuffing_probable_causes:
      "Raspado lateral excesivo de la banda de rodadura, especialmente en curvas cerradas cuando el neumático está caliente. Es más común en remolques con mucha carga y de eje extendido.",
    rapid_shoulder_wear_both_shoulders_probable_causes:
      "Frecuentemente se encuentra en los ejes de extensión como resultado de las condiciones normales de servicio. También se encuentran en los ejes de las carretillas como resultado de la acción de empuje y tracción de esa operación.",
    erratic_depression_wear_trailer_probable_causes:
      "Falta de control de los amortiguadores en algunos tipos de suspensión, tamaños de neumáticos y/o presiones de inflado desajustadas entre los dobles, rodamientos de rueda sueltos o desgastados, falta de uniformidad en el montaje, como el asentamiento inadecuado de los talones y la condición de desequilibrio, agravada por los transportes en vacío a alta velocidad.",
    one_sided_wear_trailer_probable_causes:
      "Excesiva convergencia, excesiva inclinación del eje, ejes no paralelos, ensamblaje no uniforme del neumático y la rueda causado por un asiento incorrecto del talón o una rueda doblada.",
    erosion_river_channel_wear_trailer_probable_causes:
      "Característica de la lenta tasa de desgaste de los neumáticos radiales en los ejes de rodadura libre. Puede variar con el diseño y la construcción de la banda de rodadura de cada neumático.",
    rib_depression_punch_wear_trailer_probable_causes:
      "Falta de control de los amortiguadores en algunos tipos de suspensión, tamaños de neumáticos y/o presiones de inflado inadecuadas entre los duales, rodamientos de rueda sueltos o desgastados, falta de uniformidad en el montaje, como el asentamiento inadecuado de los talones y la condición de desequilibrio, agravada por los transportes en vacío a alta velocidad.",
  },
  actions: {
    torn_bead_action_tire:
      "Si no hay evidencias de oxidación en el alambre, repare la llanta (neumático) y pónganla en servicio de nuevo. Si el alambre esta oxidado mellado, doblado, roto o separado consulte con su proveedor de llantas (neumáticos) o renovador para determinar la reparabilidad; en caso contrario deseche la llanta (neumático)",
    torn_bead_action_vehicle: "Ninguna",
    torn_bead_action_operations:
      "Revise los procedimiento de montaje/desmontaje y las herramientas. Asegúrese de que las cejas (talones) de las llantas (neumáticos) estén bien lubricadas. También revise los procedimientos en el manejo de llanta (neumático) nueva ",
    kinked_distorted_bead_action_tire: "Deseche la llanta (neumático)",
    kinked_distorted_bead_action_vehicle: "Ninguna",
    kinked_distorted_bead_action_operations:
      "Revise los procedimientos de montaje/desmontaje y las herramientas. Asegúrese de que las cejas (talones) de la llanta (neumático) estén bien lubricadas",
    bead_deformation_action_tire: "Deseche la llanta (neumático)",
    bead_deformation_action_vehicle: "Ninguna",
    bead_deformation_action_operations:
      "Revise los procedimiento de montaje/desmontaje y las herramientas. Asegúrese de que las cejas (talones) de la llanta (neumático) estén bien lubricadas",
    burned_bead_action_tire: "Deseche la llanta (neumático)",
    burned_bead_action_vehicle: "Ninguna",
    burned_bead_action_operations:
      "Determine la fuente del calor excesivo y corrija la condición de los frenos",
    reinforce_chafer_separation_action_tire:
      "Retírela de servicio y consulte al fabricante de la llanta (neumático)",
    reinforce_chafer_separation_action_vehicle: "Ninguna",
    reinforce_chafer_separation_action_operations:
      "Asegúrese de que el tamaño del rim (aro) sea el adecuado, y de que utilicen procedimientos de montaje y presiones de inflado adecuados",
    petro_lubricant_damage_action_tire: "Deseche la llanta (neumático)",
    petro_lubricant_damage_action_vehicle: "Ninguna",
    petro_lubricant_damage_action_operations:
      "Uso de lubricantes adecuados que no sean basados en petroleo",
    bead_damage_from_curbing_action_tire: "Retírela del servicio",
    bead_damage_from_curbing_action_vehicle: "Ninguna",
    bead_damage_from_curbing_action_operations:
      "Revise procedimientos de conducción del vehículo",
    bead_area_flow_crack_action_tire:
      "Retírela del servicio y consulte con su fabricante",
    bead_area_flow_crack_action_vehicle: "Ninguna",
    bead_area_flow_crack_action_operations: "Ninguna",
    spread_damaged_cord_action_tire:
      "Determine la causa del abultamiento. Los neumáticos reparados a menudo pueden volver al servicio en posiciones duales a menos que la altura del abultamiento exceda 9.5 mm. cuando se infla la llanta (neumático). Si la causa del abultamiento es no una reparación o un daño, póngase en contacto con el fabricante del neumático.",
    cuts_snags_action_tire:
      "Si los daños no han dejado al descubierto las cuerdas, el neumático puede utilizarse en posiciones duales. Si las cuerdas son visibles, repare el neumático si el daño a las cuerdas está dentro de los límites de reparación vuelva a ponerlo en servicio; de lo contrario, deseche el neumático.",
    sidewall_separation_action_tire:
      "Retire la llanta (neumático) de servicio y consulte a su fabricante",
    chain_damage_action_tire:
      "Ruede la llanta (neumático) en una posición dual al menos que el daño se extienda a las cuerdas de la carcasa. Si las cuerdas están visibles, consulte a su taller de reparación de llantas (neumáticos) sobre la posibilidad de reparación ",
    vehicle_equipment_damage_action_tire:
      "Ponga la llanta (neumático) de nuevo en servicio en una posición dual a menos que la abrasión se extienda a las cuerdas de la carcasa. Si las cuerdas están expuestas, consulte a su taller de reparación sobre la posibilidad de reparar",
    damage_induced_sidewall_separation_action_tire:
      "Deseche la llanta (neumático)",
    sidewall_abrasion_scuff_damage_action_tire:
      "Si las cuerdas están expuestas deseche la llanta (neumático). De lo contrario la llanta (neumático) puede ser puesta en servicio de nuevo. Si la apariencia de abrasión es considerable, monte el lado raspado al contrario de donde se pueda tener contacto con las banquetas (aceras, cordones)",
    weathering_action_tire:
      "Todas las llantas (neumáticos) pueden eventualmente llegar a mostrar esta condición en una etapa tardía de su vida de servicio. Tales llantas (neumáticos) pueden ser utilizados; en un eje de dirección si el daño es menor; en posiciones duales si esta condición es moderada.Agrietamiento severo puede requerir retirar la llanta (neumático) de servicio.",
    impact_break_action_tire:
      " Consulte a su taller de reparación sobre la posibilidad de reparar o deseche la llanta",
    branding_damage_action_tire:
      "Las llantas (neumáticos) que presenten grietas pueden ser puestas de nuevo en servicio. Dependiendo de las severidad y ubicación de la grieta consulte a su fabricante de llantas (neumáticos); si hay cuerdas expuestas, deseche la llanta (neumático).De lo contrario, consulte asu taller de reparación sobre las posibilidad de reparar",
    diagonal_cracking_action_tire:
      "Retire la llanta (neumático) de servicio y consulte a su fabricante",
    petroleum_product_damage_action_tire:
      "Si la contaminación es leve, devuelva el neumático al servicio. Si hay una diferencia de rigidez o distorsión de un costado (pared) en comparación con el otro, deseche el neumático",
    forklift_damage_action_tire:
      "No ponga la llanta (neumático) en servicio. Determine la responsabilidad por el daño",
    circumferential_fatigue_rupture_zipper_action_tire:
      "Deseche la llanta (neumático)",
    open_sidewall_splice_action_tire:
      "Retire la llanta (neumático) del servicio y consulte a su fabricante",
    sidewall_bumps_blisters_action_tire:
      "Retire la llanta (neumático) del servicio. Si esta condición surge durante el período de garantía, consulte al fabricante de la llanta (neumático). De lo contrario, deseche la llanta (neumático)",
    sidewall_penetration_action_tire:
      "Haga inspeccionar la llanta (neumático) a su taller de servicio para determinar si es reparable",
    radial_split_action_tire:
      "Lleve la llanta (neumático) a inspeccionar a su taller de servicio para determinar si es reparable",
    spread_damaged_cord_action_vehicle: "Ninguna",
    cuts_snags_action_vehicle:
      "Asegúrese de que el neumático no entre en contacto con la carrocería del vehículo.",
    sidewall_separation_action_vehicle: "Ninguna",
    chain_damage_action_vehicle: "Ninguna",
    vehicle_equipment_damage_action_vehicle:
      "Analice la causa de la condición y corrija",
    damage_induced_sidewall_separation_action_vehicle: "Ninguna",
    sidewall_abrasion_scuff_damage_action_vehicle: "Ninguna",
    weathering_action_vehicle: "Ninguna",
    impact_break_action_vehicle: "Ninguna",
    branding_damage_action_vehicle: "Ninguna",
    diagonal_cracking_action_vehicle: "Ninguna",
    petroleum_product_damage_action_vehicle:
      "Compruebe si el vehículo tiene fugas de fluidos",
    forklift_damage_action_vehicle: "Ninguna",
    circumferential_fatigue_rupture_zipper_action_vehicle: "Ninguna",
    open_sidewall_splice_action_vehicle: "Ninguna",
    sidewall_bumps_blisters_action_vehicle: "Ninguna",
    sidewall_penetration_action_vehicle: "Ninguna",
    radial_split_action_vehicle: "Ninguna",
    spread_damaged_cord_action_operations: "Ninguna",
    cuts_snags_action_operations:
      "Si se producen daños similares en varios neumáticos, investigue el funcionamiento del  vehículo para determinar la causa de los daños",
    sidewall_separation_action_operations: "Ninguna",
    chain_damage_action_operations:
      "Seleccione el tamaño de cadena adecuado. Corrija los procedimientos de instalación y aplique la retirada de la cadena cuando las condiciones lo justifiquen.",
    vehicle_equipment_damage_action_operations: "Ninguna",
    damage_induced_sidewall_separation_action_operations:
      "Determine la causa y tome la acción correctiva adecuada",
    sidewall_abrasion_scuff_damage_action_operations:
      " Revise los procedimientos de conducción del vehículo",
    weathering_action_operations:
      "Los materiales de protección contra daños por intemperie incluidos en la llanta (neumático) son más efectivos cuando la llanta (neumático) es utilizada. Por lo tanto, minimice el tie1npo que el vehículo pasa estacionado. Consulte a su fabricante si se esperan períodos largos de estacionamiento",
    impact_break_action_operations:
      " Revise los procedimientos de conducción y mantenimiento del vehículo",
    branding_damage_action_operations:
      "Revise los recomendaciones de marcado del fabricante de llantas (neumáticos)",
    diagonal_cracking_action_operations: "Ninguna",
    petroleum_product_damage_action_operations:
      "Tome precauciones especiales durante el abastecimiento de combustible para evitar derrames",
    forklift_damage_action_operations:
      "Inspeccione la llanta (neumático) al recibirla",
    circumferential_fatigue_rupture_zipper_action_operations:
      "Inspeccione rutinariamente todos las llantas (neumáticos) con presiones de inflado un 20% inferiores a la norma de inflado de su flotilla; inspeccione a fondo todos las llantas  (neumáticos) antes de repararlos y renovarlos; utilice siempre una jaula de seguridad durante el inflado. Consulte a su proveedor de  llantas (neumáticos) o renovador  para conocer los procedimientos de inspección adecuados.",
    open_sidewall_splice_action_operations: "Ninguna",
    sidewall_bumps_blisters_action_operations: "Ninguna",
    sidewall_penetration_action_operations: "Evite los riesgos en el camino",
    radial_split_action_operations:
      "Revise el programa de mantenimiento de inflado y revise la capacitación de los conductores del vehículo",
    penetrations_and_road_hazards_action_tire:
      "Determine la integridad de la carcasa. Consulte a su taller de servicio para determinar la posibilidad de reparación. Si el daño es excesivo y/o evidente, deseche la llanta (neumático)",
    vehicle_damage_action_tire:
      "Si los cortes no son más profundos que la base o la ranura de la banda de rodamiento, vuelva a la llanta (neumático) a una posición dual. Si los daños son más profundos, renueve o volver a poner la banda de rodamiento si es posible. Si la condición es más severa, deseche el neumático.",
    forklift_damage_cuts_snags_action_tire:
      "Si la llanta (neumático) es nueva, determine la responsabilidad por el daño antes de ponerla en servicio. Si la llanta (neumático) ha estado en servicio pero no hay acero expuesto, vuelva al servicio. Si el acero está expuesto, renueve o vuelva a montar",
    belt_lift_separation_action_tire:
      "Retire la llanta (neumático) del servicio y consulte a su fabricante",
    tread_lift_separation_action_tire:
      "Retire la llanta (neumático) del servicio. Consulte a su fabricante",
    brake_skid_damage_action_tire:
      "Si la condición no se extiende por debajo de 1.6 mm. de la banda de rodamiento,  se pueden recolocar los duales para colocar las zonas planas  a 180˚ uno del otro. Si es más grave, la llanta (neumático) puede ser reparada o renovada  si el daño no llega a los cinturones (estabilizadores). Si el daño llega a los cinturones (estabilizadores) de la llanta  (neumático), puede ser posible retirar el cinturón superior y/o recinturar la llanta (neumático); consulte a su renovador. Si los daños son excesivos, deseche el neumático.",
    tread_chunking_action_tire:
      "Retire la llanta (neumático) del servicio. Repare o renueve",
    lug_base_cracking_action_tire:
      "Si las grietas no se extienden hasta la línea de respaldo de renovado, vuelva al servicio en posición dual. Si las grietas son más profundas, consulte con el fabricante de la llanta (neumático)",
    wild_wire_action_tire: "Consulte con su fabricante de llantas (neumáticos)",
    impact_breaks_action_tire: "Deseche la llanta (neumático)",
    chipping_flaking_chunking_tread_action_tire:
      "Los neumáticos con pequeños desprendimientos y poco escamados pueden volver a  ponerse en servicio. Si el daño se extiende por debajo de 2/32in (1.6 mm), renueve la llanta (neumático). Si el acero es visible, consulte con su renovador la posibilidad de reparar y renovar",
    stone_drilling_action_tire:
      "Retire las piedras atoradas y vuelva a ponerlo en servicio. Si hay penetraciones en los cinturones (estabilizadores), consulte al renovador o al fabricante de la llanta (neumático). Si no se puede renovar o recinturar, deseche la llanta (neumático.)",
    regrooving_damage_action_tire:
      "Consulte a su renovador para la posibilidad de renovar",
    dynamometer_type_damage_action_tire:
      "Consulte a su renovador para la posibilidad de renovar",
    chemical_damage_action_tire:
      "Dependiendo de la extensión del daño químico, regrese al servicio en el remolque o renueve la llanta (neumático)",
    excessive_wear_action_tire:
      "Renueve y vuelva a montar de ser posible; de otro modo deseche la llanta (neumático)",
    rib_tearing_action_tire:
      "Si el acero es evidente, consulte al fabricante de la llanta (neumático). Si el acero no es evidente, renovar o volver a dar servicio en una posición dual.",
    defense_groove_tearing_action_tire:
      "Si el acero es evidente, consulte al fabricante de la llanta (neumático). Si la ruptura se extiende cerca del radio de raspado, consulte a su renovador sobre la posibilidad de renovar. En caso contrario, regrésela al servicio",
    groove_cracking_action_tire:
      "Si las grietas son superficiales, continúe con el neumático en servicio. Si las grietas son mayores de 2/32in (1.6 mm.) o extendiéndose hasta la carcasa, póngase en contacto con el fabricante de la llanta (neumático).",
    spin_damage_action_tire:
      "Vuelva a poner la llanta (neumático) en servicio si el daño no se extiende más allá de la base de las ranuras de la banda de rodamiento. Si los daños son más profundos, renovar o recinturar de ser posible. Si la condición es más severo, deseche la llanta (neumático).",
    penetrations_and_road_hazards_action_vehicle: "Ninguna",
    vehicle_damage_action_vehicle:
      "Analice la causa de la condición y corrija el problema",
    forklift_damage_cuts_snags_action_vehicle: "Ninguna",
    belt_lift_separation_action_vehicle: "Ninguna",
    tread_lift_separation_action_vehicle: "Ninguna",
    brake_skid_damage_action_vehicle:
      "Revise los materiales y el ajuste de los frenos",
    tread_chunking_action_vehicle: "Ninguna",
    lug_base_cracking_action_vehicle: "Ninguna",
    wild_wire_action_vehicle: "Ninguna",
    impact_breaks_action_vehicle: "Ninguna",
    chipping_flaking_chunking_tread_action_vehicle: "Ninguna",
    stone_drilling_action_vehicle: "Ninguna",
    regrooving_damage_action_vehicle: "Ninguna",
    dynamometer_type_damage_action_vehicle: "Ninguna",
    chemical_damage_action_vehicle: "Ninguna",
    excessive_wear_action_vehicle: "Ninguna",
    rib_tearing_action_vehicle: "Ninguna",
    defense_groove_tearing_action_vehicle: "Ninguna",
    groove_cracking_action_vehicle: "Revise la ruta del tubo de escape",
    spin_damage_action_vehicle: "Ninguna",
    penetrations_and_road_hazards_action_operations: "Ninguna",
    vehicle_damage_action_operations:
      "Revise los procedimientos de conducción del vehículo",
    forklift_damage_cuts_snags_action_operations:
      "Inspeccione las llantas (neumáticos) nuevas al recibirlas",
    belt_lift_separation_action_operations:
      "Use radios de giro lo más grande posible para evitar el arrastre de la llanta (neumático)",
    tread_lift_separation_action_operations: "Ninguna",
    brake_skid_damage_action_operations:
      "Revise los procedimiento de conducción del vehículo",
    tread_chunking_action_operations:
      "Revise la selección de llanta (neumático). Revise los procedimientos de conducción del vehículo",
    lug_base_cracking_action_operations: "Ninguna",
    wild_wire_action_operations: "Ninguna",
    impact_breaks_action_operations: "Ninguna",
    chipping_flaking_chunking_tread_action_operations:
      "Revise la selección de llantas (neumáticos).",
    stone_drilling_action_operations:
      "Revise la selección de llantas (neumáticos).",
    regrooving_damage_action_operations:
      "Revise la efectividad en costo del re-ranurado",
    dynamometer_type_damage_action_operations:
      "Revisar los procedimientos del dinamómetro. Revisar la presión de inflado para la carga o la selección de las llantas (neumáticos).",
    chemical_damage_action_operations: "Ninguna",
    excessive_wear_action_operations: "Ninguna",
    rib_tearing_action_operations:
      "Revise los procedimientos de conducción del vehículo",
    defense_groove_tearing_action_operations:
      "Revise los procedimientos de conducción del vehículo",
    groove_cracking_action_operations:
      "Revise los procedimientos de conducción del vehículo en vueltas muy cerradas",
    spin_damage_action_operations:
      "Revise los procedimientos de conducción del vehículo y/o selección de llantas (neumáticos) de tracción",
    penetrating_objects_action_tire:
      "Retirar el objeto extraño. Inspeccionar el grado de daño. Reparar si es admisible de acuerdo con las normas de límites de reparación, y restaurar el sello contra humedad y la integridad de la retención de aire. Si el daño es excesivo, desechar el neumático.",
    open_inner_liner_splice_action_tire:
      "Repare el revestimiento interior con hule (goma)  para asegurar la integridad de la retención de aire si las cuerdas (telas) de la carcasa no están expuestos. Si  las cuerdas (telas) de la carcasa  son visibles, póngase en contacto con el fabricante del neumático.",
    inner_liner_bubbles_blisters_and_separations_action_tire:
      "Retírelo del servicio y póngase en contacto con el fabricante de la llanta (neumático). Si las ampollas son pequeñas y escasas, el revestimiento interior puede repararse y el neumático puede volver a ponerse en servicio.",
    inner_liner_cracking_action_tire:
      "Retire el neumático del servicio y póngase en contacto con el fabricante del mismo.",
    pulled_loose_cords_action_tire: "Deseche la llanta (neumático)",
    tearing_mount_dismount_damage_action_tire:
      "Repare el revestimiento interior o deseche la llanta (neumático) si el daño es excesivo",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_tire:
      "Inspeccione el grado de daño, repare si es posible; de lo contrario, deseche la llanta (neumático).",
    run_flat_action_tire: "Deseche la llanta (neumático)",
    pinch_shock_action_tire:
      "Haga que su proveedor de reparación de neumáticos inspeccione la llanta (neumático) para determinar si es reparable.",
    impact_break_action_tire: "Deseche la llanta (neumático)",
    penetrating_objects_action_vehicle: "Ninguna",
    open_inner_liner_splice_action_vehicle: "Ninguna",
    inner_liner_bubbles_blisters_and_separations_action_vehicle: "Ninguna",
    inner_liner_cracking_action_vehicle: "Ninguna",
    pulled_loose_cords_action_vehicle: "Ninguna",
    tearing_mount_dismount_damage_action_vehicle: "Ninguna",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_vehicle:
      "Ninguna",
    run_flat_action_vehicle: "Ninguna",
    pinch_shock_action_vehicle:
      "Los impactos severos pueden causar que las pestañas del rin se doblen. Inspeccione las ruedas y vuelva a comprobar la alineación.",
    impact_break_action_vehicle: "Ninguna",
    penetrating_objects_action_operations: "Ninguna",
    open_inner_liner_splice_action_operations: "Ninguna",
    inner_liner_bubbles_blisters_and_separations_action_operations: "Ninguna",
    inner_liner_cracking_action_operations: "Ninguna",
    pulled_loose_cords_action_operations: "Ninguna",
    tearing_mount_dismount_damage_action_operations:
      "Revisar los procedimientos de montaje/desmontaje de las llantas (neumáticos) y el uso de herramientas para llantas (neumáticos).",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_operations:
      "Ninguna",
    run_flat_action_operations: "Ninguna",
    pinch_shock_action_operations:
      "Revise los procedimientos de conducción del vehículo y capacite a los conductores",
    impact_break_action_operations:
      "Revise los procedimientos de conducción del vehículo",
    run_flat_action_tire_any_area: "Deseche la llanta (neumático)",
    electrical_discharge_action_tire_any_area: "Deseche la llanta (neumático)",
    run_flat_action_vehicle_any_area: "Ninguna",
    electrical_discharge_action_vehicle_any_area: "Ninguna",
    run_flat_action_operations_any_area: "Ninguna",
    electrical_discharge_action_operations: "Ninguna",
    bad_spot_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar el neumático si es posible o deséchelo si no se puede reparar.",
    spot_repair_should_be_a_section_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar el neumático si es posible o deséchelo si no se puede reparar.",
    improper_nail_hole_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    improperly_aligned_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    unfilled_nail_hole_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    bridged_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    on_the_wheel_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    bad_bead_repair_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    failed_repair_injury_not_removed_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Desguace del neumático.",
    bias_repair_in_radial_tire_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía. Vuelva a reparar si es posible, de lo contrario deseche el neumático.",
    bad_spot_repair_action_vehicle: "Ninguno",
    spot_repair_should_be_a_section_repair_action_vehicle: "Ninguno",
    improper_nail_hole_repair_action_vehicle: "Ninguno",
    improperly_aligned_repair_action_vehicle: "Ninguno",
    unfilled_nail_hole_repair_action_vehicle: "Ninguno",
    bridged_repair_action_vehicle: "Ninguno",
    on_the_wheel_repair_action_vehicle: "Ninguno",
    bad_bead_repair_action_vehicle: "Ninguno",
    failed_repair_injury_not_removed_action_vehicle: "Ninguno",
    bias_repair_in_radial_tire_action_vehicle: "Ninguno",
    bad_spot_repair_action_operations: "Ninguno",
    spot_repair_should_be_a_section_repair_action_operations: "Ninguno",
    improper_nail_hole_repair_action_operations:
      "Revise las técnicas de reparación con su reparador.",
    improperly_aligned_repair_action_operations:
      "Revise las técnicas de reparación con su reparador.",
    unfilled_nail_hole_repair_action_operations:
      "Revisar las necesidades de reparación con los proveedores de servicios de carretera y supervisar el servicio de neumáticos en carretera.",
    bridged_repair_action_operations:
      "Revise las técnicas de reparación con su reparador.",
    on_the_wheel_repair_action_operations:
      "Revisar las necesidades de reparación con los proveedores de servicios de carretera y supervisar el servicio de neumáticos en carretera.",
    bad_bead_repair_action_operations:
      "Revisar los procedimientos de montaje/desmontaje para eliminar los daños en los cordones.",
    failed_repair_injury_not_removed_action_operations:
      "Revise las técnicas de reparación con su reparador.",
    bias_repair_in_radial_tire_action_operations:
      "Revise las técnicas de reparación con su reparador",
    bond_line_porosity_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Recauchutado y recauchutado.",
    tread_separation_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Vuelva a recauchutar el neumático.",
    tread_chunking_at_splice_action_tire:
      "Recubra la banda de rodadura en la posición deseada o consulte a su recauchutador para la reparación de las manchas de la banda de rodadura. Recauchutar de nuevo si es posible.",
    tread_separation_repair_related_action_tire:
      "Consulte a su recauchutador y/o reparador para un posible ajuste o reparación en garantía y vuelva a recauchutar el neumático.",
    belt_separation_repair_related_action_tire:
      "Consulte a su reparador y/o recauchutador para un posible ajuste de la garantía. Desguace del neumático.",
    missed_puncture_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Recauchutar de nuevo si es posible.",
    tread_edge_lifting_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Si la carcasa del neumático está en buen estado, recauchute el neumático. Si el cinturón se está separando de la carcasa, consulte al neumático original fabricante.",
    bond_line_porosity_action_vehicle: "Ninguno",
    tread_separation_action_vehicle: "Ninguno",
    tread_chunking_at_splice_action_vehicle: "Ninguno",
    tread_separation_repair_related_action_vehicle: "Ninguno",
    belt_separation_repair_related_action_vehicle: "Ninguno",
    missed_puncture_action_vehicle: "Ninguno",
    tread_edge_lifting_action_vehicle: "Ninguno",
    bond_line_porosity_action_operations: "Ninguno",
    tread_separation_action_operations: "Ninguno",
    tread_chunking_at_splice_action_operations: "Ninguno",
    tread_separation_repair_related_action_operations:
      "Consulte a su reparador para corregir el problema de reparación.",
    belt_separation_repair_related_action_operations: "Ninguno",
    missed_puncture_action_operations: "Ninguno",
    tread_edge_lifting_action_operations: "Ninguno",
    failed_inner_liner_repair_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Desguace del neumático.",
    lug_base_cracking_tearing_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Recauchutar si es posible.",
    improper_tread_width_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía.",
    open_tread_splice_action_tire:
      "El neumático puede agotar su vida útil y ser devuelto al servicio; o consultar a su recauchutador para un posible ajuste de la garantía y recauchutar de nuevo.",
    failed_inner_liner_repair_action_vehicle: "Ninguno",
    lug_base_cracking_tearing_action_vehicle: "Ninguno",
    improper_tread_width_action_vehicle: "Ninguno",
    open_tread_splice_action_vehicle: "Ninguno",
    failed_inner_liner_repair_action_operations: "Ninguno",
    lug_base_cracking_tearing_action_operations:
      "Revisar la selección del diseño de la banda de rodadura para su aplicación.",
    improper_tread_width_action_operations: "Ninguno",
    open_tread_splice_action_operations: "Ninguno",
    skive_failure_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Si está dentro de los límites de la reparación, repare el neumático de nuevo y recúbralo.",
    repair_related_bulge_action_tire:
      "Si el abultamiento supera los 3/8in, deseche el neumático.",
    buckled_tread_action_tire:
      "Consulte a su recauchutador/reparador para un posible ajuste de la garantía.",
    skive_failure_action_vehicle: "Ninguno",
    repair_related_bulge_action_vehicle: "Ninguno",
    buckled_tread_action_vehicle: "Ninguno",
    skive_failure_action_operations: "Ninguno",
    repair_related_bulge_action_operations:
      "Consulte a su reparador de neumáticos para corregir los problemas de reparación.",
    buckled_tread_action_operations: "Ninguno",
    delamination_action_tire:
      "La banda de rodadura puede agotarse si la condición no es grave, de lo contrario, consulte a su recauchutador para un posible ajuste de la garantía y recauchutar el neumático de nuevo.",
    tread_surface_porosity_action_tire:
      "La banda de rodadura puede agotarse si la adherencia de la banda de rodadura a la carcasa es adecuada, sin embargo, el desgaste de la banda de rodadura puede ser pobre. Si la adherencia es deficiente, consulte a su recauchutador sobre la posible garantía ajuste, y recauchutar el neumático de nuevo.",
    wing_lift_action_tire:
      "Consulte a su recauchutador para un posible ajuste de la garantía. Recauchutar de nuevo.",
    failed_repair_from_underinflation_action_tire:
      "Reparar de nuevo si es admisible según las normas de límites de reparación. Si el fallo no puede ser reparado de nuevo, deseche el neumático.",
    delamination_action_vehicle: "Ninguno",
    tread_surface_porosity_action_vehicle: "Ninguno",
    wing_lift_action_vehicle: "Ninguno",
    failed_repair_from_underinflation_action_vehicle: "Ninguno",
    delamination_action_operations: "Ninguno",
    tread_surface_porosity_action_operations: "Ninguno",
    wing_lift_action_operations: "Ninguno",
    failed_repair_from_underinflation_action_operations:
      "Revise los procedimientos de mantenimiento del inflado de los neumáticos y las condiciones de carga.",
    shoulder_step_chamfer_wear_action_tire:
      "La pérdida de kilometraje total será escasa o nula siempre que el diferencial de profundidad de la banda de rodadura no sea excesivo. Los neumáticos pueden permanecer en el eje de dirección.",
    full_shoulder_wear_action_tire:
      "Si el desgaste es severo, los neumáticos se pueden girar a otra posición o invertir la rueda.",
    feather_wear_action_tire:
      "Si el desgaste de las plumas es severo, los neumáticos se pueden rotar a otro eje para aprovechar al máximo la banda de rodadura restante.",
    erosion_river_channel_wear_action_tire:
      "El desgaste por erosión no debería ser motivo de preocupación.",
    cupping_scallop_wear_action_tire:
      "Si surgen quejas sobre la conducción, los neumáticos pueden ser rotados hacia el eje motriz.",
    one_sided_wear_action_tire:
      "Dependiendo de la severidad del desgaste, los neumáticos se pueden rotar al eje motriz o, si están desgastados a la profundidad mínima de la banda de rodadura en el hombro, se pueden enviar para un posible recauchutado.",
    diagonal_wear_action_tire:
      "El neumático puede ser girado a la posición dual de conducción exterior con el cambio de rotación del neumático. Si el desgaste es excesivo, envíe el neumático a un recauchutador para obtener más opciones.",
    eccentric_out_of_round_wear_action_tire:
      "El neumático puede ser girado hacia el doble del remolque. Si el desgaste es excesivo en el lado desgastado, envíelo al recauchutador para obtener más opciones.",
    overall_fast_wear_action_tire: "Ninguno",
    rib_depression_punch_wear_action_tire:
      "Si no está excesivamente desgastado, siga funcionando. Si el desgaste es excesivo, recauchutar.",
    erratic_depression_wear_action_tire:
      "Si no está excesivamente desgastado, siga funcionando. Si el desgaste es excesivo, recauchutar.",
    shoulder_step_chamfer_wear_action_vehicle: "Ninguno",
    full_shoulder_wear_action_vehicle:
      "Diagnostique la desalineación, verifique los componentes de la suspensión y corrija según sea necesario.",
    feather_wear_action_vehicle:
      "Diagnosticar y corregir la condición de desalineación según sea necesario. Si el desgaste de las plumas en ambas direcciones neumáticos está en la misma dirección, se indica una desalineación del eje motriz o de 114 otro chasis. Si las plumas de los neumáticos de dirección están en direcciones opuestas, se indica una condición de convergencia.",
    erosion_river_channel_wear_action_vehicle: "Ninguno",
    cupping_scallop_wear_action_vehicle:
      "Diagnosticar la condición de desequilibrio, es decir, la rueda, la llanta, el cubo, el tambor de freno. Corrija lo que sea necesario.",
    one_sided_wear_action_vehicle:
      "Diagnostique el problema mecánico y corríjalo.",
    diagonal_wear_action_vehicle: "Diagnosticar la causa y corregirla.",
    eccentric_out_of_round_wear_action_vehicle:
      "Diagnosticar los componentes/piezas problemáticas. Sustituir o corregir según sea necesario.",
    overall_fast_wear_action_vehicle:
      "Consulte a los fabricantes de vehículos y de neumáticos cuando especifique el equipo o sustituya los neumáticos.",
    rib_depression_punch_wear_action_vehicle:
      "Diagnosticar el problema mecánico y corregirlo.",
    erratic_depression_wear_action_vehicle:
      "Diagnosticar el problema mecánico y corregirlo.",
    shoulder_step_chamfer_wear_action_operations: "Ninguno",
    full_shoulder_wear_action_operations: "Ninguno",
    feather_wear_action_operations: "Ninguno",
    erosion_river_channel_wear_action_operations: "Ninguno",
    cupping_scallop_wear_action_operations: "Ninguno",
    one_sided_wear_action_operations: "Ninguno",
    diagonal_wear_action_operations: "Ninguno",
    eccentric_out_of_round_wear_action_operations: "Ninguno",
    overall_fast_wear_action_operations: "Ninguno",
    rib_depression_punch_wear_action_operations: "Ninguno",
    erratic_depression_wear_action_operations: "Ninguno",
    shoulder_step_chamfer_wear_drive_action_tire:
      "Considere la posibilidad de rotar el neumático entre los ejes motrices. No afecta negativamente a la vida útil de la banda de rodadura.",
    heel_toe_wear_action_tire:
      "Se agota en el eje motriz. Si es severo, cambie la dirección de rotación hasta que la banda de rodadura esté desgastada hasta el punto de recauchutado.",
    alternate_lug_wear_action_tire:
      "Continuar con el desgaste a menos que las variaciones de la profundidad de la banda de rodadura se vuelvan severas. Gire a una posición de desgaste más rápida, es decir, a un tractor de un solo tornillo o a un eje motriz trasero.",
    brake_skid_flat_spot_wear_action_tire:
      "Si la condición no es excesiva, los duales pueden ser re emparejados para colocar los puntos planos a 180 de cada uno. Si es más grave, el neumático puede ser reparado o recauchutado si el daño no llega a los cinturones. Si el daño por deslizamiento llega a los cinturones del neumático, puede ser posible quitar el cinturón superior y/o rebelar el cinturón y luego recauchutar el neumático. Consulte a su recauchutador. Si el daño es excesivo, deseche el neumático.",
    overall_fast_wear_drive_action_tire:
      "Continuar con la marcha y recauchutar. Si la condición continúa, consulte al fabricante de neumáticos.",
    shoulder_step_chamfer_wear_drive_action_vehicle:
      "Inspeccione si las piezas de la suspensión están desgastadas o dañadas.",
    heel_toe_wear_action_vehicle:
      "Revisar las prácticas de mantenimiento de los neumáticos y la selección del diseño de la banda de rodamiento.",
    alternate_lug_wear_action_vehicle:
      "Compruebe si los componentes están desgastados o dañados y la desalineación del eje.",
    brake_skid_flat_spot_wear_action_vehicle:
      "Comprobar el material de los frenos y el equilibrio de los mismos.",
    overall_fast_wear_drive_action_vehicle:
      "Revise la selección del diseño de los neumáticos. Adapte cuidadosamente el equipo a los requisitos de servicio. Consulte al fabricante del vehículo y del neumático cuando especifique el equipo o sustituya los neumáticos.",
    shoulder_step_chamfer_wear_drive_action_operations: "Ninguno",
    heel_toe_wear_action_operations: "Ninguno",
    alternate_lug_wear_action_operations:
      "Revisar las prácticas de mantenimiento de los neumáticos y la selección del diseño de la banda de rodadura.",
    brake_skid_flat_spot_wear_action_operations:
      "Revisar el programa de formación de conductores.",
    overall_fast_wear_drive_action_operations: "Ninguno",
    brake_skid_flat_spot_wear_trailer_action_tire:
      "Si la condición no es excesiva, los duales pueden ser re emparejados para colocar los puntos planos a 180 ̊ uno del otro. Si es más grave, el neumático puede ser reparado o recauchutado si el daño no llega a los cinturones. Si el daño por deslizamiento llega a los cinturones del neumático, puede ser posible retirar el cinturón superior y/o recauchutar el neumático. Consulte a su recauchutador. Si el daño es excesivo, deseche el neumático.",
    diagonal_wear_trailer_action_tire:
      "Invierta el sentido de giro del neumático. Si el desgaste es excesivo, rectificar o recauchutar.",
    multiple_flat_spotting_wear_action_tire:
      "Si no está excesivamente desgastado, deje los neumáticos fuera de servicio. Si la banda de rodadura restante en los puntos desgastados es de 2/32in o menos, recauchute el neumático.",
    rapid_shoulder_wear_one_shoulder_action_tire:
      "Cambie el sentido de giro del neumático. Si el desgaste de los hombros es severo, quitar y recauchutar.",
    shoulder_scrubbing_scuffing_action_tire:
      "Mueva los hombros afectados a posiciones de fregado bajo. Si es grave, puede ser necesario desmontar el neumático. Inspeccione la capacidad de servicio en otro eje o recúbralo si es posible.",
    rapid_shoulder_wear_both_shoulders_action_tire:
      "Si el desgaste de los hombros es severo, retire y recauchute.",
    erratic_depression_wear_trailer_action_tire:
      "Si no está excesivamente desgastado, siga funcionando. Si el desgaste es excesivo, recauchutar.",
    one_sided_wear_trailer_action_tire:
      "Cambiar la posición del neumático en el remolque. Si es excesivo, recauchutar.",
    erosion_river_channel_wear_trailer_action_tire:
      "El desgaste por erosión no debe ser motivo de preocupación.",
    rib_depression_punch_wear_trailer_action_tire:
      "Si no está excesivamente desgastado, siga funcionando. Si el desgaste es excesivo, recauchutar.",
    brake_skid_flat_spot_wear_trailer_action_vehicle:
      "Comprobar el material de los frenos y el equilibrio de los mismos.",
    diagonal_wear_trailer_action_vehicle: "Diagnosticar la causa y corregirla.",
    multiple_flat_spotting_wear_action_vehicle:
      "Diagnosticar la causa y corregirla.",
    rapid_shoulder_wear_one_shoulder_action_vehicle:
      "Diagnosticar la desalineación y/o el estado mecánico y corregirlo.",
    shoulder_scrubbing_scuffing_action_vehicle: "Ninguno",
    rapid_shoulder_wear_both_shoulders_action_vehicle: "Ninguno",
    erratic_depression_wear_trailer_action_vehicle:
      "Diagnosticar el problema mecánico y corregirlo.",
    one_sided_wear_trailer_action_vehicle: "Aislar y corregir la causa.",
    erosion_river_channel_wear_trailer_action_vehicle: "Ninguno",
    rib_depression_punch_wear_trailer_action_vehicle:
      "Diagnosticar el problema mecánico y corregirlo.",
    brake_skid_flat_spot_wear_trailer_action_operations:
      "Revisar el programa de formación de conductores",
    diagonal_wear_trailer_action_operations: "Ninguno",
    multiple_flat_spotting_wear_action_operations:
      "Consultar al conductor y revisar las prácticas de mantenimiento.",
    rapid_shoulder_wear_one_shoulder_action_operations: "Ninguno",
    shoulder_scrubbing_scuffing_action_operations: "Ninguno",
    rapid_shoulder_wear_both_shoulders_action_operations:
      "Revise la aplicación del neumático con el fabricante del mismo.",
    erratic_depression_wear_trailer_action_operations: "Ninguno",
    one_sided_wear_trailer_action_operations: "Ninguno",
    erosion_river_channel_wear_trailer_action_operations: "Ninguno",
    rib_depression_punch_wear_trailer_action_operations: "Ninguno",
  },
};
