import {
  REGISTER_DRIVER,
  GET_DRIVERS,
  CLEAR_DRIVERS,
  SET_DRIVER_STATUS,
  UPDATE_DRIVER,
  SET_DRIVER_FILTERS,
  CLEAR_DRIVER_FILTERS,
  GET_DRIVER,
  CLEAR_DRIVER,
  DELETE_DRIVER,
} from "store/actions";

const initialState = {
  driver: {
    driver_id: 0,
    name: "",
    subsidiary_id: 0,
    subsidiary: {
      name: "",
    },
    status: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  drivers: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_driver: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_DRIVER:
    case GET_DRIVER:
    case UPDATE_DRIVER:
      return {
        ...state,
        driver: action.payload,
      };

    case CLEAR_DRIVER:
      return {
        ...state,
        driver: { ...initialState.driver },
      };

    case GET_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };

    case SET_DRIVER_FILTERS:
      const filter = {
        ...state.filter_driver,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_driver: { ...filter },
      };

    case CLEAR_DRIVER_FILTERS:
      return {
        ...state,
        filter_driver: { search: state.filter_driver.search },
      };

    case CLEAR_DRIVERS:
      return {
        ...state,
        drivers: { ...initialState.drivers },
      };

    case SET_DRIVER_STATUS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: state.drivers.data.map((driver) =>
            driver.driver_id !== action.payload
              ? driver
              : { ...driver, status: !driver.status }
          ),
        },
      };

    case DELETE_DRIVER:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: state.drivers.data.filter(
            (driver) => driver.driver_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
