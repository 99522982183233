import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionClearVehicleHistory } from "store/actions/vehicle/review/clearVehicleHistory.js";
import { actionGetVehicleHistory } from "store/actions/vehicle/review/getVehiclesHistory.js";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetVehicleReviewFilters } from "store/actions/vehicle/review/setVehicleReviewFilters";
import { actionClearVehicleReviewFilters } from "store/actions/vehicle/review/clearVehicleReviewFilters";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";

import Page from "./page";
import { usePDF } from "@react-pdf/renderer";
import ReviewHistoryPdf from "./PDF/Document";
import { getSubsidiaryViaWorkArea } from "utils/workArea";

export function ReviewHistory({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDamageDialog, setOpenDamageDialog] = useState(false);
  const [damages, setDamages] = useState([]);
  const [tires, setTires] = useState([]);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [reviewAlerts, setReviewAlerts] = useState([]);
  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();

  const {
    filter,
    clearVehicleHistory,
    getVehicleReviewHistory,
    setVehicleReviewFilters,
    clearVehicleReviewFilters,
    vehicleReview,
    setNotification,
    getVehicleTypeAxles,
    vehicleTypeAxles,
    account,
    workArea,
  } = rest;

  const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
  const subsidiary = subsidiaries.find(
    (s) => s.subsidiary_id === vehicleReview?.data?.[0]?.vehicle?.subsidiary_id
  );

  const companyName = `${subsidiary?.subsidiary.company.name ?? ""} - ${
    subsidiary?.subsidiary.name ?? ""
  }`;

  const totalTires = vehicleTypeAxles.data.reduce(
    (total, axle) => total + axle.tire_quantity_count,
    0
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleReviewHistory(id, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();

    return () => {
      clearVehicleHistory();
    };
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleTypeAxles({
          vehicleTypeId: vehicleReview.data[0].vehicle.vehicle_type_id,
          status_axle: 1,
        });
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (vehicleReview.data.length > 0) {
      fetchData();
    }
  }, [vehicleReview.data?.[0]?.vehicle.vehicle_type_id]);

  useEffect(() => {
    return () => {
      clearVehicleReviewFilters();
    };
  }, []);

  useEffect(() => {
    const tires = getTires(vehicleReview.data);
    setTires(tires);
  }, [vehicleReview.data, totalTires]);

  useEffect(() => {
    if (isSavingPdf && !pdfInstance.loading) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "RevisionVehículo.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  function getTire(position) {
    return vehicleReview.data[0].tire_review.find(
      (tire) => tire.real_position === position
    );
  }

  function isRefection(position) {
    const refectionAxle = vehicleTypeAxles.data.find(
      (axle) => axle.position === "REFECTION"
    );
    if (refectionAxle) {
      return position > totalTires - refectionAxle.tire_quantity_count;
    }
    return false;
  }

  function getDualDifference(position) {
    const tire = getTire(position);
    if (tire) {
      let dualTire;
      if (position % 2 === 0) {
        dualTire = getTire(position - 1);
      } else {
        dualTire = getTire(position + 1);
      }
      if (dualTire) {
        const dualDifference =
          tire.tire_review_depth_line.min_depth -
          dualTire.tire_review_depth_line.min_depth;
        return dualDifference > 0 ? 0 : Math.abs(dualDifference);
      }
    }
    return undefined;
  }

  function getWearPercentage(tireReview) {
    if (tireReview.tire_review_depth_line.remainder_depth <= 0) {
      return 100;
    }
    const depthLimit =
      tireReview.tire_review_depth_line.min_depth -
      tireReview.tire_review_depth_line.remainder_depth;
    return (
      (tireReview.tire_review_depth_line.wear * 100) /
      (tireReview.tire_review_depth_line.depth_variation - depthLimit)
    );
  }

  function getTires(data) {
    const review = data[0];
    const tires = [];
    if (!review) {
      return [];
    }
    for (let i = 0; i < totalTires; i++) {
      const tireReview = review.tire_review.find(
        (tire) => tire.real_position === i + 1
      );
      const vehicleTire = review.vehicle.vehicle_tire.find(
        (vt) => vt.vehicle_type_axle_tire.position === i + 1
      );
      if (tireReview) {
        const dualDifference = getDualDifference(i + 1);
        const wearPercentage = getWearPercentage(tireReview);
        const tireProps = {
          tireId: tireReview.movement_tire.tire_cycle.tire_id,
          economicNumber: tireReview.movement_tire.tire_cycle.tire.code,
          deviceCode: tireReview.movement_tire.tire_cycle.tire.device_code,
          position: i + 1,
          createdAt: tireReview.movement_tire.created_at,
          brand: tireReview.movement_tire.brand_name,
          model: tireReview.movement_tire.tire_model,
          size: tireReview.movement_tire.tire_size,
          depthVariation: tireReview.tire_review_depth_line.depth_variation,
          depthCondition: tireReview.tire_review_depth_line.depth_condition,
          depth: tireReview.tire_review_depth_line.min_depth,
          dualDifference: dualDifference,
          accumulatedMileage: tireReview.accumulated_mileage,
          monthsActive: Math.floor(tireReview.days_in_service / 30),
          monthlyWear: tireReview.tire_review_depth_line.monthly_wear,
          wearPercentage: wearPercentage,
          pressure: tireReview.pressure,
          pressureCondition: tireReview.pressure_condition,
          projection: tireReview.tire_review_depth_line.projection,
          hasAlerts:
            tireReview.alert_tire.length > 0 ||
            tireReview.alert_vehicle_tire.length > 0,
          handleOpenAlertDialog: () =>
            handleOpenAlertDialog(
              [...tireReview.alert_tire].concat(tireReview.alert_vehicle_tire)
            ),
        };
        tires.push(tireProps);
      } else {
        if (vehicleTire) {
          const tireProps = {
            tireId: vehicleTire.movement_tire.tire_cycle.tire_id,
            economicNumber: vehicleTire.movement_tire.tire_cycle.tire.code,
            deviceCode: vehicleTire.movement_tire.tire_cycle.tire.device_code,
            position: i + 1,
            createdAt: vehicleTire.movement_tire.created_at,
            brand: vehicleTire.movement_tire.brand_name,
            model: vehicleTire.movement_tire.tire_model,
            size: vehicleTire.movement_tire.tire_size,
          };
          tires.push(tireProps);
        } else {
          const tireProps = {
            economicNumber: "S/N",
            position: isRefection(i + 1) ? "R" : `${i + 1}`,
          };
          tires.push(tireProps);
        }
      }
    }
    return tires;
  }

  async function onChangePage(page) {
    try {
      await getVehicleReviewHistory(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleOpenDamageDialog(damages) {
    setDamages(damages);
    setOpenDamageDialog(true);
  }

  function handleCloseDamageDialog() {
    setOpenDamageDialog(false);
    setDamages([]);
  }

  function handleOpenAlertDialog(alerts) {
    setReviewAlerts(alerts);
    setOpenAlertDialog(true);
  }
  function handleCloseAlertDialog() {
    setOpenAlertDialog(false);
    setReviewAlerts([]);
  }

  function savePdf() {
    const review = vehicleReview.data[0];
    if (!review) {
      return;
    }
    setIsSavingPdf(true);
    // Se genera un UUID para forzar que el pdf se descargue de nuevo,
    // aunque su contenido sea idéntico al anterior
    const uuid =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    const pdfData = {
      uuid: uuid,
      vehicleInspection: vehicleReview.data[0],
      vehicleTypeAxles: vehicleTypeAxles.data.map((a) => ({
        position: a.position,
        tireCount: a.tire_quantity_count,
      })),
      reviewInfo: {
        companyName: companyName,
        economicNumber: review.vehicle.economic_number,
        reviewType: review.review_type,
        isFinished: !!review.end_time,
        date: review.date,
        vehicleKm: review.odometer,
        userName: `${review.created_by.name} ${review.created_by.last_name_1} ${
          review.created_by.last_name_2 || ""
        }`,
        driverName: review.vehicle.vehicle_driver?.name ?? "-",
        comment: review.observation ?? "-",
      },
      tires: tires,
    };
    updatePdfInstance(<ReviewHistoryPdf {...pdfData} uuid={uuid} />);
  }

  return (
    <Page
      t={t}
      vehicleId={id}
      vehicleReview={getSortedByPosition(vehicleReview)}
      vehicleTypeAxles={vehicleTypeAxles.data.map((a) => ({
        position: a.position,
        tireCount: a.tire_quantity_count,
      }))}
      clearVehicleHistory={clearVehicleHistory}
      onChangePage={onChangePage}
      filter={filter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      setVehicleReviewFilters={setVehicleReviewFilters}
      clearVehicleReviewFilters={clearVehicleReviewFilters}
      openDamageDialog={openDamageDialog}
      damages={damages}
      handleOpenDamageDialog={handleOpenDamageDialog}
      handleCloseDamageDialog={handleCloseDamageDialog}
      reviewAlerts={reviewAlerts}
      openAlertDialog={openAlertDialog}
      handleCloseAlertDialog={handleCloseAlertDialog}
      savePdf={savePdf}
      companyName={companyName}
      tires={tires}
    />
  );
}

ReviewHistory.propTypes = {};

const mapStateToProps = (state) => ({
  vehicleReview: state.Vehicle.History.vehicle_review,
  filter: state.Vehicle.History.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
  vehicleTypeAxles: state.Vehicle.Type.Axle.Axle.vehicle_type_axles,
});

const mapDispatchToProps = (dispatch) => ({
  clearVehicleHistory: actionClearVehicleHistory(dispatch),
  getVehicleReviewHistory: actionGetVehicleHistory(dispatch),
  setVehicleReviewFilters: actionSetVehicleReviewFilters(dispatch),
  clearVehicleReviewFilters: actionClearVehicleReviewFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewHistory);

function getSortedByPosition(vehicleReview) {
  if (vehicleReview.data.length > 0) {
    vehicleReview.data[0].tire_review.sort((a, b) => {
      if (
        a.movement_tire.vehicle_tire.length > 0 &&
        b.movement_tire.vehicle_tire.length > 0
      ) {
        if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] >
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return 1;
        } else if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] <
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return -1;
        }
      }

      return 0;
    });
  }
  return vehicleReview;
}
