import React, { Fragment } from "react";
import PropTypes from "prop-types";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "../index.css";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

// const decimalFormatter = new Intl.NumberFormat("en", {
//   maximumFractionDigits: 6,
//   minimumFractionDigits: 2,
// });
function Page({ pressureTable, lostsData, t }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {Object.keys(pressureTable)
            .sort()
            .map((size) =>
              Object.keys(pressureTable[size]).map((pressure) =>
                Object.keys(pressureTable[size][pressure]).map(
                  (tolerance, index) => (
                    <Fragment key={index}>
                      <h4 className="text-center">
                        {t("_labels.size")}: {size}{" "}
                        {t("_labels.with_pressure").toLowerCase()}: {pressure}
                        {" Tolerancia"}: {tolerance}
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-hover text-center sFS12">
                          <thead className="sbgazul">
                            <tr>
                              <td />
                              <td colSpan={2}>
                                {t("_labels.quantity").toUpperCase()}
                              </td>
                              <td colSpan={2}>
                                {t("_labels.price").toUpperCase()}
                              </td>
                              <td colSpan={2}>
                                {t("_labels.percent_of_loss").toUpperCase()}
                              </td>
                            </tr>
                            <tr className="text-uppercase">
                              <td>{t("_labels.pressure")}</td>
                              <td>ORIGINAL</td>
                              <td>{t("_labels.renovated")}</td>
                              <td className="text-right">ORIGINAL</td>
                              <td className="text-right">
                                {t("_labels.renovated")}
                              </td>
                              <td className="text-right">ORIGINAL</td>
                              <td className="text-right">
                                {t("_labels.renovated")}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr><td><code>{JSON.stringify(pressureTable[size][pressure], null, 2)}</code></td></tr> */}
                            {/* <tr><td><code>{JSON.stringify(pressureTable[size][pressure][tolerance], null, 2)}</code></td></tr> */}
                            {pressureTable[size][pressure][tolerance].map(
                              ([key, row], index) => (
                                <tr key={index}>
                                  <td>
                                    {isNaN(key) ? key : formatter.format(key)}
                                  </td>
                                  <td>
                                    {formatter.format(row["ORIGINAL_TIRES"])}
                                  </td>
                                  <td>
                                    {formatter.format(row["RETREAD_TIRES"])}
                                  </td>
                                  <td className="text-right">
                                    ${formatter.format(row["ORIGINAL_PRICE"])}
                                  </td>
                                  <td className="text-right">
                                    ${formatter.format(row["RETREAD_PRICE"])}
                                  </td>
                                  <td className="text-right">
                                    ${formatter.format(row["ORIGINAL_LOST"])}
                                  </td>
                                  <td className="text-right">
                                    ${formatter.format(row["RETREAD_LOST"])}
                                  </td>
                                </tr>
                              )
                            )}
                            {pressureTable[size][pressure][tolerance]
                              .filter(([key]) => key === "PSI INSUF.")
                              .map(([, value], index) => (
                                <tr key={index}>
                                  <td />
                                  <td />
                                  <td />
                                  <td className="text-right" />
                                  <td className="text-right" />
                                  <td className="text-right">
                                    {formatter.format(value["%_ORIGINAL"])}%
                                  </td>
                                  <td className="text-right">
                                    {formatter.format(value["%_RETREAD"])}%
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Fragment>
                  )
                )
              )
            )}
        </div>

        {Object.keys(lostsData).length > 1 && (
          <div className="col-md-12" id={"losses"}>
            <div className="table-responsive">
              <table className="table table-hover text-center sFS12">
                <thead className="sbgazul">
                  <tr className="sbngris">
                    <th colSpan={7} className="h5">
                      {t("_labels.pressure_loss")}
                    </th>
                  </tr>
                  <tr>
                    <td>{t("_labels.size")}</td>
                    <td>{t("_labels.quantity_originals")}</td>
                    <td>{t("_labels.quantity_revitalized")}</td>
                    <td className="text-right">{t("_labels.original_cost")}</td>
                    <td className="text-right">{t("_labels.rev_cost")}</td>
                    <td className="text-right">
                      {t("_labels.original_loss_percentage")}
                    </td>
                    <td className="text-right">
                      {t("_labels.revitalized_loss_percentage")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(lostsData)
                    .filter((key) => key !== "total")
                    .filter((key) => typeof lostsData[key] === "object")
                    .map((key) => (
                      <tr key={key}>
                        <td>{getSizeKey(key)}</td>
                        <td>
                          {formatter.format(lostsData[key]["ORIGINAL_TIRES"])}
                        </td>
                        <td>
                          {formatter.format(lostsData[key]["RETREAD_TIRES"])}
                        </td>
                        <td className="text-right">
                          ${formatter.format(lostsData[key]["ORIGINAL_LOST"])}
                        </td>
                        <td className="text-right">
                          ${formatter.format(lostsData[key]["RETREAD_LOST"])}
                        </td>
                        <td className="text-right">
                          {formatter.format(lostsData[key]["%_ORIGINAL"])}%
                        </td>
                        <td className="text-right">
                          {formatter.format(lostsData[key]["%_RETREAD"])}%
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <th colSpan={7} className="text-center sbngris">
                      <span className={"mr-4"}>
                        Neumáticos: {formatter.format(lostsData["elements"])}
                      </span>
                      <span>
                        Total: ${formatter.format(lostsData["total"])}
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-12 text-right sFS12 pdb10 pdt10">
          {t("_labels.copyright")}
        </div>
      </div>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  pressureTable: PropTypes.object.isRequired,
  lostsData: PropTypes.object.isRequired,
};

export default Page;

function getSizeKey(key) {
  const [size, rest] = key.split("@");

  const [recommendedPressure, tolerance] = rest.split("°");
  return `${size} presión: ${recommendedPressure} tolerancia: ${tolerance}`;
}
