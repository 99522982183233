import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Typography from "@material-ui/core/Typography";
import emptyFolder from "resources/images/emptyFolder.svg";

import css from "./index.module.scss";

function Page({ text, width }) {
  return (
    <div className={classnames(css.container)}>
      <img src={emptyFolder} alt={text} width={width} />
      <Typography
        variant="h6"
        color="textSecondary"
        className={classnames(css.text)}
      >
        {text}
      </Typography>
    </div>
  );
}

Page.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default Page;
