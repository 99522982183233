import { GET_PROVIDERS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetProviders =
  (dispatch) =>
  async (
    {
      search,
      status,
      page,
      order = "DESC",
      scope,
      subsidiaries,
      date_from: dateFrom,
      date_to: dateTo,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `provider`,
        params: {
          search,
          status,
          page,
          order,
          scope,
          subsidiaries,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_PROVIDERS,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
