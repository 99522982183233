import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSummaryReport } from "store/actions/reports/summary/getSummaryReport";
import { actionClearTireSummaryFilters } from "store/actions/reports/summary/clearTireSummaryFilters";
import { actionGenerateSummaryExcel } from "store/actions/reports/summary/generateSummaryExcel";
import { actionSetTireSummaryFilters } from "store/actions/reports/summary/setTireSummaryFilters";
import { actionSetSelectedSummaryFilters } from "store/actions/reports/summary/setSelectedTireSummaryFilters";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import Page from "./page";

const depthOrder = ["Buen estado", "Retiro programado", "Retiro crítico"];
const pressureOrder = ["Sin presión", "Normal", "Alta", "Baja"];

const paths = {
  pressure: "report/summary/pressure",
  depth: "report/summary/depth",
};
function SummaryReport({ current, ...rest }) {
  const t = useLang();
  const queryParams = useLocation();

  const {
    filter,
    account,
    workArea,
    selectedFilter,
    setNotification,
    getSummaryReport,
    clearTireSummaryFilters,
    generateSummaryExcel,
    setTireSummaryFilters,
    setSelectedTireSummaryFilters,
  } = rest;
  const [data, setData] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [params, setParams] = useState({});

  const [section, setSection] = useState("pressure");
  useEffect(() => {
    async function fetchData() {
      try {
        const filters = { current: Number(Boolean(current)), ...filter };
        const response = await getSummaryReport(paths[section], filters);
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter, section]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
      movement: "MOUNT",
      current: 0,
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
      movement: t("_labels.pile_summary_report.movements.options.mount"),
    };

    const params = new URLSearchParams(queryParams.search);
    if (params.size > 0) {
      params.forEach((value, key) => {
        filter[key] = value;
        selectedFilters[key] = t(
          `_labels.summary_report.pressure_condition.${value
            .toLowerCase()
            .replaceAll(/\s/g, "_")}`
        );
        if (key.includes("depth")) {
          selectedFilters[key] = t(
            `_labels.${value.toLowerCase().replaceAll(/\s/g, "_")}`
          );
          setSection("depth");
        }

        if (value.includes("NO PRESSURE")) {
          filter["movement"] = "WAREHOUSE";
          selectedFilters["movement"] = t(
            "_labels.pile_summary_report.movements.options.warehouse"
          );
        } else {
          filter["movement"] = "BOTH";
          selectedFilters["movement"] = t(
            "_labels.report_summary.location.both"
          );
        }
      });

      if (!params.has("subsidiary")) {
        delete filter.subsidiary_id;
        delete selectedFilters.subsidiaries;
      }
    }

    if (current) {
      filter.current = 1;
      delete filter.date_from;
      delete filter.date_to;
      delete selectedFilters.date_from;
      delete selectedFilters.date_to;
    }

    setTireSummaryFilters(filter);
    setSelectedTireSummaryFilters(selectedFilters);

    return () => {
      clearTireSummaryFilters();
    };
  }, []);

  function handleViewAlerts(alerts) {
    setAlerts(alerts);
    setOpenDialog(true);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleViewTires({ ...params }) {
    setParams({ current: Number(Boolean(current)), ...filter, ...params });
    setOpenTireDialog(true);
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setParams({});
  }

  async function handleGenerateSummaryExcel() {
    try {
      await generateSummaryExcel({
        current: Number(Boolean(current)),
        ...filter,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeSection(section) {
    setSection(section);
  }

  return (
    <Page
      t={t}
      current={current}
      data={getData(data)}
      dataChart={getDataChart(t, data)}
      alerts={alerts}
      openDialog={openDialog}
      params={params}
      openTireDialog={openTireDialog}
      handleViewAlerts={handleViewAlerts}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleViewTires={handleViewTires}
      handleCloseTireDialog={handleCloseTireDialog}
      handleGenerateSummaryExcel={handleGenerateSummaryExcel}
      section={section}
      handleChangeSection={handleChangeSection}
    />
  );
}
SummaryReport.propTypes = {
  current: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.Summary.filter,
  selectedFilter: state.Reports.Summary.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getSummaryReport: actionGetSummaryReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearTireSummaryFilters: actionClearTireSummaryFilters(dispatch),
  generateSummaryExcel: actionGenerateSummaryExcel(dispatch),
  setTireSummaryFilters: actionSetTireSummaryFilters(dispatch),
  setSelectedTireSummaryFilters: actionSetSelectedSummaryFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryReport);

function getData(data) {
  const order = ["NO PRESSURE", "NORMAL", "HIGH", "LOW"];
  const depthOrder = [
    "GOOD CONDITION",
    "SCHEDULED WITHDRAWAL",
    "CRITICAL WITHDRAWAL",
  ];

  const result = { ...data };
  if (result.pressure) {
    Object.keys(result.pressure)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(result.pressure[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            Object.keys(result.pressure[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(result.pressure[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    let aux = [];
                    Object.keys(
                      result.pressure[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        aux.push(condition);
                      });
                    result.pressure[corporate][company][subsidiary][division][
                      "labels"
                    ] = aux.sort((a, b) => order.indexOf(a) - order.indexOf(b));
                  });
              });
          });
      });
  }

  if (result.depth) {
    Object.keys(result.depth)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(result.depth[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            Object.keys(result.depth[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(result.depth[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    let aux = [];
                    Object.keys(
                      result.depth[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        aux.push(condition);
                      });
                    result.depth[corporate][company][subsidiary][division][
                      "labels"
                    ] = aux.sort(
                      (a, b) => depthOrder.indexOf(a) - depthOrder.indexOf(b)
                    );
                  });
              });
          });
      });
  }

  return result;
}

function getDataChart(t, data) {
  const result = {
    pressure: {},
    depth: {},
    alert: {},
  };
  if (data.pressure && Object.keys(data.pressure).length > 0) {
    const pressure = {};
    Object.keys(data.pressure)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.pressure[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            pressure[corporate] = {};
            Object.keys(data.pressure[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.pressure[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.pressure[corporate][company][subsidiary][division]
                    )
                      .filter(
                        (key) =>
                          !["statistics", "percent", "labels"].includes(key)
                      )
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.summary_report.pressure_condition.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );

                        const aux = {
                          percent:
                            data.pressure[corporate][company][subsidiary][
                              division
                            ][condition]["percent"],
                          value:
                            data.pressure[corporate][company][subsidiary][
                              division
                            ][condition]["statistics"],
                        };

                        info.push(aux);
                        if (condition.includes("LOW")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("HIGH")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else if (condition.includes("NO PRE")) {
                          colors.push("rgb(125, 125, 125)");
                          borderColors.push("rgb(40, 40, 40)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedLabels,
                      sortedInfo,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({
                      chart,
                      info,
                      colors,
                      borderColors,
                      priorityArray: pressureOrder,
                    });
                    chart.labels = [...sortedLabels];
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    pressure[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.pressure = pressure;
  }

  if (data.depth && Object.keys(data.depth).length > 0) {
    const depth = {};
    Object.keys(data.depth)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.depth[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            depth[corporate] = {};
            Object.keys(data.depth[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.depth[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.depth[corporate][company][subsidiary][division]
                    )
                      .filter(
                        (key) =>
                          !["statistics", "percent", "labels"].includes(key)
                      )
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.summary_report.depth_condition.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );

                        const aux = {
                          percent:
                            data.depth[corporate][company][subsidiary][
                              division
                            ][condition]["percent"],
                          value:
                            data.depth[corporate][company][subsidiary][
                              division
                            ][condition]["statistics"],
                        };
                        info.push(aux);
                        if (condition.includes("CRITICAL")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("SCHEDULED")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedLabels,
                      sortedInfo,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({
                      chart,
                      info,
                      colors,
                      borderColors,
                      priorityArray: depthOrder,
                    });
                    chart.labels = sortedLabels;
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    depth[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.depth = depth;
  }

  if (data.alert && Object.keys(data.alert).length > 0) {
    const alert = {};
    Object.keys(data.alert)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.alert[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            alert[corporate] = {};
            Object.keys(data.alert[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.alert[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.alert[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.priority.options.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );

                        const aux = {
                          percent:
                            data.alert[corporate][company][subsidiary][
                              division
                            ][condition]["percent"],
                          value:
                            data.alert[corporate][company][subsidiary][
                              division
                            ][condition]["statistics"],
                        };
                        info.push(aux);
                        if (condition.includes("HIGH")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("LOW")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedLabels,
                      sortedInfo,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({ chart, info, colors, borderColors });
                    chart.labels = sortedLabels;
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    alert[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.alert = alert;
  }
  return result;
}

function getSortedInfo({ chart, info, colors, borderColors, priorityArray }) {
  const auxArray = [];
  info.forEach((element, index) =>
    auxArray.push({
      value: element,
      label: chart.labels[index],
      color: colors[index],
      borderColor: borderColors[index],
    })
  );

  if (!priorityArray) {
    auxArray.sort(alphabeticalSort("label"));
  } else {
    auxArray.sort(
      (a, b) => priorityArray.indexOf(a.label) - priorityArray.indexOf(b.label)
    );
  }
  const sortedInfo = [];
  const sortedLabels = [];
  const sortedColors = [];
  const sortedBorderColors = [];
  auxArray.forEach((element) => {
    sortedInfo.push(element.value);
    sortedLabels.push(element.label);
    sortedColors.push(element.color);
    sortedBorderColors.push(element.borderColor);
  });

  return { sortedInfo, sortedLabels, sortedColors, sortedBorderColors };
}
