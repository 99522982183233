import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Axle from "components/molecules/general/vehicle_preview/Axle";
import RefectionAxle from "components/molecules/general/vehicle_preview/RefectionAxle";

import Van from "resources/images/vehicle/type/van.png";

import css from "./index.module.scss";

function Page({ axles, size, onClickTire, onRotateTire }) {
  return (
    <div
      className={classnames(css.mb64, css.container, {
        [css.sm]: size === "sm",
      })}
    >
      <img src={Van} className={classnames(css.image)} />
      <div className={classnames(css.axles, css.axles_frontal)}>
        {axles
          .filter((i) => i.position.toUpperCase() === "FRONTAL")
          .map((i, index) => (
            <Axle
              key={index}
              color={i.color}
              tireQuantity={i.tire_quantity}
              axleTires={i.vehicle_type_axle_tire}
              className={css.axle}
              onClickTire={onClickTire}
              onRotateTire={onRotateTire}
            />
          ))}
      </div>

      <div className={classnames(css.axles, css.axles_central)}>
        {axles
          .filter((i) => i.position.toUpperCase() === "CENTRAL")
          .map((i, index) => (
            <Axle
              key={index}
              color={i.color}
              tireQuantity={i.tire_quantity}
              axleTires={i.vehicle_type_axle_tire}
              className={css.axle}
              onClickTire={onClickTire}
              onRotateTire={onRotateTire}
            />
          ))}
      </div>

      <div className={classnames(css.axles, css.axles_rear)}>
        {axles
          .filter((i) => i.position.toUpperCase() === "REAR")
          .map((i, index) => (
            <Axle
              key={index}
              color={i.color}
              tireQuantity={i.tire_quantity}
              axleTires={i.vehicle_type_axle_tire}
              className={css.axle}
              onClickTire={onClickTire}
              onRotateTire={onRotateTire}
            />
          ))}
      </div>

      <div className={classnames(css.axles, css.axles_refection)}>
        {axles
          .filter((i) => i.position.toUpperCase() === "REFECTION")
          .map((i, index) => (
            <RefectionAxle
              key={index}
              color={i.color}
              tireQuantity={i.tire_quantity}
              axleTires={i.vehicle_type_axle_tire}
              className={css.axle}
              onClickTire={onClickTire}
              onRotateTire={onRotateTire}
            />
          ))}
      </div>
    </div>
  );
}

Page.propTypes = {
  size: PropTypes.string,
  axles: PropTypes.array,
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

export default Page;
