import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import SearchInput from "components/molecules/general/SearchInput";
import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import TpmsForm from "components/organisms/tpms/TpmsForm";
import TpmsRegisterExcelForm from "components/organisms/tpms/TpmsRegisterExcelForm";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import LinkTireForm from "components/organisms/tpms/LinkTireForm";

import css from "./index.module.scss";
import FilterTpmsForm from "components/organisms/tpms/FilterTpmsForm";

function Page({
  t,
  tpms,
  search,
  tpmsId,
  openForm,
  openExcelForm,
  openLinkForm,
  openFilter,
  handleSearch,
  handleOpenForm,
  handleCloseForm,
  handleEdit,
  handleDelete,
  handleChangeStatus,
  handleOpenExcelForm,
  handleCloseExcelForm,
  handleOpenLinkForm,
  handleCloseLinkForm,
  handleOpenFilter,
  handleCloseFilter,
  handleChangePage,
  handleUnlink,
  createTpmsPermit,
  updateTpmsPermit,
  deleteTpmsPermit,
  linkTpmsPermit,
}) {
  return (
    <ContainerTemplate
      title={t("_general.tpms")}
      className={classnames({
        [css.empty]: tpms.data.length === 0,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ?? ""} onChange={handleSearch} />
        </>
      }
    >
      <Button
        disabled={!createTpmsPermit}
        tooltip={!createTpmsPermit ? t("_permits.create.tpms") : ""}
        color="secondary"
        variant="contained"
        onClick={handleOpenExcelForm}
        className={classnames(css.excel_btn)}
      >
        {t("_buttons.register_by_excel")}
      </Button>

      {tpms.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={tpms.data.map((sensor) => ({
                id: sensor.tpgps_id,
                key: sensor.tpgps_id,
                status: {
                  status: sensor.status,
                  tooltipPermit: !updateTpmsPermit
                    ? t("_permits.update.tpms")
                    : "",
                },
                subsidiary: sensor.subsidiary.name,
                device_code: sensor.device_code || "",
                tire: sensor.tire.length > 0 ? sensor.tire[0].tire.code : "",
                edit: {
                  tooltipPermit: !updateTpmsPermit
                    ? t("_permits.update.tpms")
                    : "",
                },
                delete: {
                  tooltipPermit: !deleteTpmsPermit
                    ? t("_permits.delete.tpms")
                    : "",
                },
                other: {
                  tooltip:
                    sensor.tire.length > 0
                      ? !deleteTpmsPermit
                        ? t("_permits.delete.tpms")
                        : ""
                      : !linkTpmsPermit
                      ? t("_permits.link.tpms_with_tire")
                      : "",
                  disabled:
                    sensor.tire.length > 0
                      ? !deleteTpmsPermit
                        ? true
                        : false
                      : !linkTpmsPermit,
                  label:
                    sensor.tire.length > 0 ? <LinkOffIcon /> : <LinkIcon />,
                  variant: "icon",
                  size: "medium",
                  handleClick:
                    sensor.tire.length > 0
                      ? () => handleUnlink(sensor.tpgps_id)
                      : () => handleOpenLinkForm(sensor.tpgps_id),
                },
              }))}
              headers={[
                { id: "status", label: t("_labels.status") },
                { id: "subsidiary", label: t("_labels.subsidiary.singular") },
                {
                  id: "device_code",
                  label: t("_labels.sensor"),
                },
                {
                  id: "tire",
                  label: t("_general.tire"),
                },
                { id: "actions", label: "" },
              ]}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onChangeStatus={handleChangeStatus}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={tpms.current_page}
              totalPages={tpms.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tpms")} />
      )}

      <FixedButton
        disabled={!createTpmsPermit}
        tooltip={!createTpmsPermit ? t("_permits.create.tpms") : ""}
        onClick={handleOpenForm}
      />

      <TpmsForm open={openForm} onClose={handleCloseForm} tpmsId={tpmsId} />

      <TpmsRegisterExcelForm
        open={openExcelForm}
        onClose={handleCloseExcelForm}
      />

      <LinkTireForm
        open={openLinkForm}
        onClose={handleCloseLinkForm}
        id={tpmsId}
      />

      <FilterTpmsForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tpms: PropTypes.object.isRequired,
  search: PropTypes.string,
  tpmsId: PropTypes.number,
  openForm: PropTypes.bool.isRequired,
  openExcelForm: PropTypes.bool.isRequired,
  openLinkForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenExcelForm: PropTypes.func.isRequired,
  handleCloseExcelForm: PropTypes.func.isRequired,
  handleOpenLinkForm: PropTypes.func.isRequired,
  handleCloseLinkForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleUnlink: PropTypes.func.isRequired,
  createTpmsPermit: PropTypes.bool.isRequired,
  updateTpmsPermit: PropTypes.bool.isRequired,
  deleteTpmsPermit: PropTypes.bool.isRequired,
  linkTpmsPermit: PropTypes.bool.isRequired,
};

export default Page;
