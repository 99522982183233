import React from "react";
import PropTypes from "prop-types";
import Home from "pages/general/Home";
import css from "./index.module.scss";
function Page({
  t,
  filter,
  tireModel,
  tireModels,
  tireApplicationTypes,
  tireSizes,
  createTireModelPermit,
  updateTireModelPermit,
  deleteTireModelPermit,
  getTireModel,
  getTireModels,
  addTireModel,
  updateTireModel,
  deleteTireModel,
  setTireModelFilters,
  setTireModelStatus,
  setTireModelApprovalStatus,
  clearTireModelFilters,
  clearTireModels,
  brands,
  copyToClipboard,
}) {
  return (
    <Home
      item={tireModel}
      name="tire_model"
      filter={filter}
      createTooltipPermit={
        !createTireModelPermit ? t("_permits.create.tire_model") : ""
      }
      currentPage={tireModels.current_page}
      deleteConfirmationMessage={t("_messages.delete.tire_model")}
      disableConfirmationMessage={t(
        "_messages.change_status.tire_model.disable"
      )}
      enableConfirmationMessage={t("_messages.change_status.tire_model.enable")}
      rows={tireModels.data.map((tireModel) => {
        return {
          id: tireModel.tire_model_variation_id,
          tire_model_id: (
            <p
              className={css.copyable}
              onClick={() => copyToClipboard(tireModel.tire_model_variation_id)}
            >
              {tireModel.tire_model_variation_id}
            </p>
          ),
          key: tireModel.tire_model_variation_id,
          status: {
            status: tireModel.status,
            tooltipPermit: !updateTireModelPermit
              ? t("_permits.update.tire_model")
              : "",
          },
          approved: {
            approved: tireModel.approved,
            tooltipPermit: !updateTireModelPermit
              ? t("_permits.update.tire_model")
              : "",
          },
          name: tireModel.tire_model.name,
          brand: tireModel.tire_model.brand.name,
          depth: `${tireModel.depth} mm`,
          tolerance: `${tireModel.tolerance}%`,
          number_layers: tireModel.number_layers,
          maximum_pressure: `${tireModel.maximum_pressure} psi`,
          recommended_pressure: `${tireModel.recommended_pressure} psi`,
          size: tireModel.tire_size.size,
          application: t(
            `_labels.tire_application.options.${tireModel.tire_application_id.toLowerCase()}`
          ),
          created_by: `${tireModel.created_by.name} ${
            tireModel.created_by.last_name_1
          } ${tireModel.created_by.last_name_2 ?? ""}`.trim(),
          data_sheet: {
            data_sheet: tireModel.tire_model.data_sheet,
            tooltipPermit: tireModel.tire_model.data_sheet
              ? ""
              : t("_labels.data_sheet.no"),
          },
          edit: {
            tooltipPermit: !updateTireModelPermit
              ? t("_permits.update.tire_model")
              : "",
          },
          delete: {
            tooltipPermit: !deleteTireModelPermit
              ? t("_permits.delete.tire_model")
              : "",
          },
        };
      })}
      title={t("_general.tire_models")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "approved", label: t("_labels.approved") },
        { id: "tire_model_id", label: "Id" },
        { id: "name", label: t("_labels.name.singular") },
        { id: "brand", label: t("_labels.brand") },
        { id: "size", label: t("_labels.size") },
        { id: "created_by", label: t("_labels.registered_by") },
        { id: "application", label: t("_labels.tire_model.application") },
        { id: "depth", label: t("_labels.tire_model_variation.depth") },
        {
          id: "maximum_pressure",
          label: t("_labels.tire_model_variation.maximum_pressure"),
        },
        {
          id: "recommended_pressure",
          label: t("_labels.tire_model_variation.recommended_pressure"),
        },
        { id: "tolerance", label: t("_labels.tire_model_variation.tolerance") },
        {
          id: "number_layers",
          label: t("_labels.tire_model_variation.number_layers"),
        },
        { id: "actions", label: "" },
      ]}
      lastPage={tireModels.last_page}
      noItemsText={t("_general.no_tire_models")}
      initialDefaultValues={{
        tire_model_id: "",
        name: "",
        brand_id: "",
        depth: "",
        tolerance: "",
        number_layers: "",
        maximum_pressure: "",
        recommended_pressure: "",
        tire_size_id: "",
        tire_application_id: "",
        tire_model_variation_use: [],
        helmet_value_original: "",
        helmet_value_revitalized: "",
        data_sheet: null,
      }}
      fields={[
        {
          name: "data_sheet",
          required: true,
          label: t("_labels.data_sheet.label"),
          accept: "image/*,application/pdf",
          placeholder: t("_labels.data_sheet.placeholder"),
          component: "file",
          fullWidth: true,
        },
        {
          name: "name",
          required: true,
          label: t("_labels.tire_model.model_name"),
        },

        {
          name: "tire_size_id",
          required: true,
          component: "select",
          label: t("_labels.size"),
          selectItems: tireSizes.map((tireSize) => ({
            value: tireSize.tire_size_id.toString(),
            name: tireSize.size,
            disabled: !tireSize.status || !tireSize.approved,
          })),
        },
        {
          name: "brand_id",
          required: true,
          component: "select",
          label: t("_labels.brand"),
          selectItems: brands.map((brand) => ({
            value: brand.brand_id.toString(),
            name: brand.name,
            disabled: !brand.status || !brand.approved,
          })),
        },
        {
          name: "tire_application_id",
          required: true,
          label: t("_labels.tire_application.label.singular"),
          component: "select",
          selectItems: tireApplicationTypes.map((application) => ({
            value: application.tire_application_id.toString(),
            name: t(
              `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
            ),
            disabled: !application.status,
          })),
        },
        {
          name: "number_layers",
          required: true,
          label: t("_labels.tire_model_variation.number_layers"),
          type: "number",
        },

        {
          name: "depth",
          label: t("_labels.tire_model_variation.depth"),
          required: true,
          type: "number",
          inputAdornment: "mm",
        },
        {
          name: "maximum_pressure",
          required: true,
          label: t("_labels.tire_model_variation.maximum_pressure"),
          type: "number",
          inputAdornment: "psi",
        },
        {
          name: "recommended_pressure",
          required: true,
          label: t("_labels.tire_model_variation.recommended_pressure"),
          type: "number",
          inputAdornment: "psi",
        },

        {
          name: "tolerance",
          required: true,
          label: t("_labels.tire_model_variation.tolerance"),
          type: "number",
          inputAdornment: "%",
        },

        {
          name: "helmet_value_original",
          required: true,
          label: t("_labels.helmet_value"),
          type: "number",
        },
        {
          name: "helmet_value_revitalized",
          required: true,
          label: t("_labels.helmet_value_revitalized"),
          type: "number",
        },
        {
          name: "tire_model_variation_use",
          required: true,
          multiple: true,
          label: t("_labels.tire_model.use"),
          component: "select",
          selectItems: [
            {
              value: "LONG_DISTANCE",
              name: t("_labels.tire_use.options.long_distance"),
            },
            {
              value: "MIXED",
              name: t("_labels.tire_use.options.mixed"),
            },
            {
              value: "REGIONAL",
              name: t("_labels.tire_use.options.regional"),
            },
            {
              value: "URBAN",
              name: t("_labels.tire_use.options.urban"),
            },
          ],
        },
      ]}
      createFormTitle={t("_titles.new.tire_model")}
      updateFormTitle={t("_titles.update.tire_model")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.tire_model"),
        },
        approved: true,
        tireModel: true,
        brands: true,
        tire_size: true,
        tire_application: true,
        depth: true,
        maximum_pressure: true,
        number_layers: true,
      }}
      filterInitialDefaultValues={{
        tire_model_id: "",
        status: "",
        approved: "",
        brands: "",
        tire_size: [],
        tire_application: [],
        depth: "",
        maximum_pressure: "",
        number_layers: "",
      }}
      createdMessage={t("_messages.created.tire_model")}
      updatedMessage={t("_messages.updated.tire_model")}
      approveConfirmationMessage={t("_messages.change_approved.model.approve")}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.model.disapprove"
      )}
      getItemAction={getTireModel}
      setFiltersAction={setTireModelFilters}
      clearFiltersAction={clearTireModelFilters}
      deleteAction={deleteTireModel}
      setStatusAction={setTireModelStatus}
      setApprovedAction={setTireModelApprovalStatus}
      getItemsAction={getTireModels}
      clearItemsAction={clearTireModels}
      addAction={addTireModel}
      updateAction={updateTireModel}
    />
  );
}

Page.propTypes = {
  brands: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  tireModel: PropTypes.object.isRequired,
  tireModels: PropTypes.object.isRequired,
  createTireModelPermit: PropTypes.bool.isRequired,
  updateTireModelPermit: PropTypes.bool.isRequired,
  deleteTireModelPermit: PropTypes.bool.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  getTireModel: PropTypes.func.isRequired,
  getTireModels: PropTypes.func.isRequired,
  addTireModel: PropTypes.func.isRequired,
  updateTireModel: PropTypes.func.isRequired,
  deleteTireModel: PropTypes.func.isRequired,
  setTireModelFilters: PropTypes.func.isRequired,
  setTireModelStatus: PropTypes.func.isRequired,
  setTireModelApprovalStatus: PropTypes.func.isRequired,
  clearTireModelFilters: PropTypes.func.isRequired,
  clearTireModels: PropTypes.func.isRequired,
  copyToClipboard: PropTypes.func.isRequired,
};
export default Page;
