import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE =
  "components/molecules/vehicle/travel/VehicleTravelHistoryCard";

function VehicleTravelHistoryCard({ date, odometer, ...rest }) {
  const t = useLang(NAMESPACE);

  return (
    <Page
      {...rest}
      t={t}
      title={`${odometer} km`}
      description={`${moment(date).format("LL")} ${t(
        "_labels.time_at"
      )} ${moment(date).format("h:mm a")}`}
    />
  );
}

VehicleTravelHistoryCard.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  odometer: PropTypes.number.isRequired,
};

export default VehicleTravelHistoryCard;
