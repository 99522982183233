import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Typography from "@material-ui/core/Typography";

import tire180 from "resources/images/vehicle/tire180.svg";

import css from "./index.module.scss";

function Page({ t, message, structureType, children, className }) {
  if (!structureType) {
    return (
      <div className={classnames(className, css.container)}>
        <img src={tire180} />
        <Typography
          variant="h6"
          color="textSecondary"
          className={classnames(css.text)}
        >
          {message || t("no_structure")}
        </Typography>
      </div>
    );
  }

  return <div className={classnames(css.container)}>{children}</div>;
}

Page.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  structureType: PropTypes.string,
  children: PropTypes.node,
};

export default Page;
