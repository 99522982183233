import vehicle from "./vehicle";
import GpsForm from "./GpsForm";
import GpsTabs from "./GpsTabs";
import InfoGpsCard from "./InfoGpsCard";
import FilterGpsForm from "./FilterGpsForm";
import InfoGpsGeofenceDialog from "./InfoGpsGeofenceDialog";

export default {
  vehicle,
  gps_form: GpsForm,
  gps_tabs: GpsTabs,
  info_gps_card: InfoGpsCard,
  filter_gps_form: FilterGpsForm,
  info_gps_geofence_dialog: InfoGpsGeofenceDialog,
};
