import {
  GET_TIRE_DIVISION,
  CLEAR_TIRE_DIVISION,
  ASSIGN_TIRE_TO_DIVISION,
} from "store/actions";

const initialState = {
  division: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_TIRE_TO_DIVISION:
    case GET_TIRE_DIVISION:
      return {
        ...state,
        division: action.payload,
      };

    case CLEAR_TIRE_DIVISION:
      return {
        ...state,
        division: initialState.division,
      };

    default:
      return state;
  }
}
