import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Page from "./page";

function ContainerTemplate({
  title,
  tabs,
  children,
  options,
  className,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const { account } = rest;

  return (
    <Page
      open={open}
      handleOpen={(status) => setOpen(status)}
      title={title}
      options={options}
      account={account}
      tabs={tabs}
      className={className}
    >
      {children}
    </Page>
  );
}

ContainerTemplate.propTypes = {
  options: PropTypes.node,
  children: PropTypes.node,
  tabs: PropTypes.node,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  const account = state.Account.account;
  return { account: account !== null };
};
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTemplate);
