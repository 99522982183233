import React from "react";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";
import Page from "./page";

function InfoTireTooltip({ ...props }) {
  const t = useLang();

  return <Page t={t} {...props} />;
}

InfoTireTooltip.propTypes = {
  tire: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  placement: PropTypes.string,
};

export default InfoTireTooltip;
