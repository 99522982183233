import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import ContainerTemplate from "components/templates/general/Container";
import Collapse from "@material-ui/core/Collapse";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";

function Page({ t, videos }) {
  const [show, setShow] = useState(
    Object.keys(videos)
      .sort()
      .reduce((acc, val) => {
        acc[val] = false;
        return acc;
      }, {})
  );

  function toggleCategory(category) {
    setShow({ ...show, [category]: !show[category] });
  }

  return (
    <ContainerTemplate title={t("_general.tutorials")}>
      <div className="sidebar_font px-4 mt-4 pt-2 container-fluid">
        <p className="h3 mb-3">{t("_labels.tutorials_desc")}</p>
        {Object.keys(videos)
          .sort()
          .map((category) => (
            <Fragment key={category}>
              <p
                className="bg-dark text-white h5 py-2 px-3 rounded pointer"
                onClick={() => toggleCategory(category)}
              >
                {t(`_general.${category.toLowerCase()}`)}{" "}
                {show[category] ? "🔼" : "🔽"}
              </p>
              <Collapse in={show[category]} timeout={500} key={category}>
                <div className="my-2 list-group">
                  {Object.keys(videos[category]).map((lang) => {
                    const list = videos[category][lang].map((video) => (
                      <a
                        key={video.id}
                        href={`tutorials/${video.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="pl-5 list-group-item list-group-item-action"
                      >
                        <BootstrapTooltip
                          title={t("_labels.see_tutorial")}
                          placement="left"
                        >
                          <span>{t(video.name)}</span>
                        </BootstrapTooltip>
                      </a>
                    ));

                    return (
                      <Fragment key={`${category}_${lang}`}>
                        <button className="list-group-item list-group-item-action disabled bg-secondary text-white">
                          {t(`_general.languages.${lang}`)}
                        </button>
                        {list}
                      </Fragment>
                    );
                  })}
                </div>
              </Collapse>
            </Fragment>
          ))}
      </div>
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func,
  videos: PropTypes.object,
};

export default Page;
