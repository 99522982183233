import model from "./model";
import size from "./size";
import damage from "./damage";
import wear from "./wear";
import HomeTire from "./HomeTire";

export default {
  model,
  size,
  damage,
  wear,
  home_tire: HomeTire,
};
