import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";

function Page({ t, open, model, onClose }) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{model.name}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              fullWidth
              gutterBottom
              text={model.brand.name}
              label={t("_labels.brand")}
            />
          </div>

          <LabelAndText
            fullWidth
            gutterBottom
            text={model.tire_model_use}
            label={t("_labels.tire_model.use")}
          />

          <LabelAndText
            fullWidth
            gutterBottom
            text={model.tire_model_application}
            label={t("_labels.tire_model.application")}
          />
        </div>
        <div className={classnames(css.action)}>
          <Button onClick={onClose} color="secondary">
            {t("_buttons.close")}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tire_model_use: PropTypes.string.isRequired,
    tire_model_application: PropTypes.string.isRequired,
    brand: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
