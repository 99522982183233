import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  corporates,
  companies,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.w100, css.ml8)}
      >
        {t("_labels.date.range")}
      </Typography>

      <div className={classnames(css.wrap)}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classnames(css.ml8)}
        >
          {t("_labels.tire_review_date")}
        </Typography>
        <Field
          name="date_from"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          component={DateTimeField}
          label={t("_labels.date.from")}
          className={classnames(css.medium_field)}
          required={!!values.date_to}
        />

        <Field
          name="date_to"
          variant="filled"
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          component={DateTimeField}
          label={t("_labels.date.to")}
          className={classnames(css.medium_field)}
          required={!!values.date_from}
        />
      </div>

      {false && (
        <>
          <Field
            fullWidth
            variant="filled"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            name="corporate_id"
            component={SelectField}
            label={t("_labels.corporate_field.singular.label")}
            defaultText={t("_labels.corporate_field.singular.value")}
            className={classnames(css.field)}
          >
            {corporates.map((corporate, index) => {
              return (
                <MenuItem
                  key={index}
                  value={corporate.corporate_id.toString()}
                  disabled={!corporate.status}
                >
                  {corporate.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            fullWidth
            multiple
            variant="filled"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            name="companies"
            component={SelectField}
            label={t("_labels.company_field.singular.label")}
            defaultText={t("_labels.company_field.singular.value")}
            className={classnames(css.field)}
          >
            {companies.map((company, index) => {
              return (
                <MenuItem
                  key={index}
                  value={company.company_id.toString()}
                  disabled={!company.status}
                >
                  {company.name}
                </MenuItem>
              );
            })}
          </Field>
        </>
      )}

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
};

export default Page;
