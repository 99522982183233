export default {
  messages: {
    disable: "¿Estas seguro de deshabilitar el almacén?",
    enable: "¿Estas seguro de habilitar el almacén?",
  },
  buttons: {
    disabled_warehouse: "Almacén deshabilitado",
  },
  permits: {
    change_status: "Sin permisos para {action} almacenes",
  },
};
