import { DELETE_VEHICLE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteVehicle = (dispatch) => async (vehicleId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `vehicle/${vehicleId}`,
    });

    dispatch({
      type: DELETE_VEHICLE,
      payload: vehicleId,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
