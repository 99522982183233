import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTirePileReport } from "store/actions/reports/pile/getTirePileReport";
import { actionGenerateTirePileExcel } from "store/actions/reports/pile/generateTirePileExcel";
import { actionClearPileFilters } from "store/actions/reports/pile/clearTirePileFilters";
import { actionSetPileFilters } from "store/actions/reports/pile/setTirePileFilters";
import { actionSetSelectedPileFilters } from "store/actions/reports/pile/setSelectedTirePileFilters";
import { actionGetReportPileHistory } from "store/actions/reports/pile/getReportPileHistory";

import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
function Pile({ ...rest }) {
  const t = useLang();
  const query = useLocation();
  const {
    filter,
    account,
    workArea,
    selectedFilter,
    setNotification,
    getTirePileReport,
    getReportPileHistory,
    clearPileFilters,
    setPileFilters,
    setSelectedFilters,
    generateTirePileExcel,
  } = rest;
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState("General");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [openCauseDialog, setOpenCauseDialog] = useState(false);
  const [params, setParams] = useState({});
  const [historic, setHistoric] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);

  const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
  const policy = subsidiaries[0]?.subsidiary.policy;
  const policyNumberCycle = policy?.number_cycle ?? 0;

  let numberCycle = "G";
  switch (currentTab) {
    case "General":
      numberCycle = "G";
      break;
    case "Revitalized":
      numberCycle = "R";
      break;
    case "Original":
      numberCycle = "0";
      break;
    case "1":
      numberCycle = "1";
      break;
    case "2":
      numberCycle = "2";
      break;
    case "3":
      numberCycle = "3";
      break;
    case "4":
      numberCycle = "4";
      break;
    default:
      break;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTirePileReport({
          ...filter,
          number_cycle: numberCycle,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter, currentTab]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getReportPileHistory({
          ...filter,
        });
        setHistoric(res);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    };

    const params = new URLSearchParams(query.search);
    if (params.has("date")) {
      const date = moment(params.get("date"));
      const startDate = date.startOf("month").format("YYYY-MM-DD");
      const endDate = date.endOf("month").format("YYYY-MM-DD");

      if (params.has("responsible")) {
        filter.responsible = params.get("responsible");
        selectedFilters.responsible = params.get("responsible");
      }

      const subsidiary = getSubsidiaries(account.subsidiaries)
        .filter(
          (subsidiary) =>
            subsidiary.company_id === subsidiaries[0].subsidiary.company_id
        )
        .find((subsidiary) => subsidiary.name === params.get("subsidiary"));

      filter.date_from = startDate;
      filter.date_to = endDate;
      selectedFilters.date_from = startDate;
      selectedFilters.date_to = endDate;
      if (subsidiary) {
        filter.subsidiary_id = subsidiary.subsidiary_id.toString();
        selectedFilters.subsidiaries = subsidiary.name;
      } else {
        delete filter.subsidiary_id;
        delete selectedFilters.subsidiaries;
      }

      setCurrentTab("General");
    }

    setPileFilters(filter);
    setSelectedFilters(selectedFilters);

    return () => {
      clearPileFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleViewTires({ ...params }) {
    setParams({ ...filter, ...params, number_cycle: numberCycle });
    setOpenTireDialog(true);
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setParams({});
  }

  async function handleGenerateTirePileExcel() {
    try {
      await generateTirePileExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleViewCauses({ ...params }) {
    setParams({ ...filter, ...params, number_cycle: numberCycle });
    setOpenCauseDialog(true);
  }

  function handleCloseCauseDialog() {
    setOpenCauseDialog(false);
    setParams({});
  }

  function handleOpenHistory() {
    setOpenHistory(true);
  }

  function handleCloseHistory() {
    setOpenHistory(false);
  }

  return (
    <Page
      t={t}
      filter={filter}
      historic={getHistoricChart(t, historic)}
      selectedFilter={selectedFilter}
      generalData={getGeneralData(data)}
      originalData={getOriginalData(data)}
      revitalizedData={getRevitalizedData(data)}
      firstCycleData={getFirstCycleData(data)}
      secondCycleData={getSecondCycleData(data)}
      thirdCycleData={getThirdCycleData(data)}
      fourthCycleData={getFourthCycleData(data)}
      policyNumberCycle={policyNumberCycle}
      chartData={getChartData(data)}
      openFilter={openFilter}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      corporates={getCorporates(data)}
      openTireDialog={openTireDialog}
      params={params}
      handleViewTires={handleViewTires}
      handleCloseTireDialog={handleCloseTireDialog}
      handleGenerateTirePileExcel={handleGenerateTirePileExcel}
      openHistory={openHistory}
      handleOpenHistory={handleOpenHistory}
      handleCloseHistory={handleCloseHistory}
      openCauseDialog={openCauseDialog}
      handleViewCauses={handleViewCauses}
      handleCloseCauseDialog={handleCloseCauseDialog}
    />
  );
}
Pile.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.Pile.filter,
  selectedFilter: state.Reports.Pile.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTirePileReport: actionGetTirePileReport(dispatch),
  clearPileFilters: actionClearPileFilters(dispatch),
  setPileFilters: actionSetPileFilters(dispatch),
  setSelectedFilters: actionSetSelectedPileFilters(dispatch),
  generateTirePileExcel: actionGenerateTirePileExcel(dispatch),
  getReportPileHistory: actionGetReportPileHistory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pile);

function getHistoricChart(t, data) {
  return {
    datasets: [
      {
        label: t("_labels.tire_quantity"),
        data: data.map((el) => ({
          ...el,
          fecha: moment(el.fecha).format("YYYY-MM"),
        })),
        fill: false,
        pointWidth: 2,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgb(75, 192, 192)",
        // tension: 0.1
      },
    ],
  };
}
function getGeneralData(data) {
  if (data.general && Object.keys(data.general).length > 0) {
    return data.general;
  }

  return {};
}
function getOriginalData(data) {
  if (data.origin && Object.keys(data.origin).length > 0) {
    return data.origin;
  }

  return {};
}
function getRevitalizedData(data) {
  if (data.revitalized && Object.keys(data.revitalized).length > 0) {
    return data.revitalized;
  }

  return {};
}
function getFirstCycleData(data) {
  if (data[1] && Object.keys(data[1]).length > 0) {
    return data[1];
  }

  return {};
}
function getSecondCycleData(data) {
  if (data[2] && Object.keys(data[2]).length > 0) {
    return data[2];
  }

  return {};
}
function getThirdCycleData(data) {
  if (data[3] && Object.keys(data[3]).length > 0) {
    return data[3];
  }

  return {};
}
function getFourthCycleData(data) {
  if (data[4] && Object.keys(data[4]).length > 0) {
    return data[4];
  }

  return {};
}

function getChartData(data) {
  return {};
  if (data.general && Object.keys(data.general).length > 0) {
    const newData = {};
    Object.keys(data.general).forEach((corporate) => {
      newData[corporate] = {};
      Object.keys(data.general[corporate])
        .filter(
          (key) =>
            typeof data.general[corporate][key] === "object" &&
            key !== "retirement"
        )
        .forEach((company) => {
          Object.keys(data.general[corporate][company])
            .filter(
              (key) =>
                typeof data.general[corporate][company][key] === "object" &&
                key !== "retirement"
            )
            .forEach((subsidiary) => {
              const dataChart = {
                tires: {
                  datasets: [
                    {
                      backgroundColor: [],
                      borderColor: [],
                      borderWidth: 1,
                      data: [],
                    },
                  ],
                  labels: [],
                },
                remainder_depth: {
                  datasets: [
                    {
                      backgroundColor: [],
                      borderColor: [],
                      borderWidth: 1,
                      data: [],
                    },
                  ],
                  labels: [],
                },
                utils_mm: {
                  datasets: [
                    {
                      backgroundColor: [],
                      borderColor: [],
                      borderWidth: 1,
                      data: [],
                    },
                  ],
                  labels: [],
                },
              };

              if (
                data.origin &&
                Object.keys(data.origin).length > 0 &&
                data.origin[corporate] &&
                data.origin[corporate][company] &&
                data.origin[corporate][company][subsidiary]
              ) {
                let [red, green, blue] = getRandomColor();
                dataChart.tires.datasets[0].data.push(
                  formatter.format(
                    (data.origin[corporate][company][subsidiary]["statistics"] *
                      100) /
                      data.general[corporate][company]["statistics"]
                  )
                );
                dataChart.tires.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.tires.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.tires.labels.push(`${subsidiary} Originales`);
                dataChart.tires.datasets = [...dataChart.tires.datasets];
                [red, green, blue] = getRandomColor();
                dataChart.remainder_depth.datasets[0].data.push(
                  formatter.format(
                    (data.origin[corporate][company][subsidiary][
                      "remainder_depth"
                    ] *
                      100) /
                      data.general[corporate][company]["remainder_depth"]
                  )
                );
                dataChart.remainder_depth.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.remainder_depth.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.remainder_depth.labels.push(
                  `${subsidiary} Originales`
                );

                [red, green, blue] = getRandomColor();
                dataChart.utils_mm.datasets[0].data.push(
                  formatter.format(
                    (data.origin[corporate][company][subsidiary][
                      "average_cost_utils_mm"
                    ] *
                      100) /
                      data.general[corporate][company]["average_cost_utils_mm"]
                  )
                );
                dataChart.utils_mm.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.utils_mm.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.utils_mm.labels.push(`${subsidiary} Originales`);

                newData[corporate][company] = { ...dataChart };
              }

              if (
                data[1] &&
                Object.keys(data[1]).length > 0 &&
                data[1][corporate] &&
                data[1][corporate][company] &&
                data[1][corporate][company][subsidiary]
              ) {
                let [red, green, blue] = getRandomColor();
                dataChart.tires.datasets[0].data.push(
                  formatter.format(
                    (data[1][corporate][company][subsidiary]["statistics"] *
                      100) /
                      data.general[corporate][company]["statistics"]
                  )
                );
                dataChart.tires.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.tires.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.tires.labels.push(`${subsidiary} Ciclo 1`);

                [red, green, blue] = getRandomColor();
                dataChart.remainder_depth.datasets[0].data.push(
                  formatter.format(
                    (data[1][corporate][company][subsidiary][
                      "remainder_depth"
                    ] *
                      100) /
                      data.general[corporate][company]["remainder_depth"]
                  )
                );
                dataChart.remainder_depth.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.remainder_depth.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.remainder_depth.labels.push(`${subsidiary} Ciclo 1`);

                [red, green, blue] = getRandomColor();
                dataChart.utils_mm.datasets[0].data.push(
                  formatter.format(
                    (data[1][corporate][company][subsidiary][
                      "average_cost_utils_mm"
                    ] *
                      100) /
                      data.general[corporate][company]["average_cost_utils_mm"]
                  )
                );
                dataChart.utils_mm.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.utils_mm.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.utils_mm.labels.push(`${subsidiary} Ciclo 1`);

                newData[corporate][company] = { ...dataChart };
              }

              if (
                data[2] &&
                Object.keys(data[2]).length > 0 &&
                data[2][corporate] &&
                data[2][corporate][company] &&
                data[2][corporate][company][subsidiary]
              ) {
                let [red, green, blue] = getRandomColor();
                dataChart.tires.datasets[0].data.push(
                  formatter.format(
                    (data[2][corporate][company][subsidiary]["statistics"] *
                      100) /
                      data.general[corporate][company]["statistics"]
                  )
                );
                dataChart.tires.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.tires.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.tires.labels.push(`${subsidiary} Ciclo 2`);

                [red, green, blue] = getRandomColor();
                dataChart.remainder_depth.datasets[0].data.push(
                  formatter.format(
                    (data[2][corporate][company][subsidiary][
                      "remainder_depth"
                    ] *
                      100) /
                      data.general[corporate][company]["remainder_depth"]
                  )
                );
                dataChart.remainder_depth.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.remainder_depth.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.remainder_depth.labels.push(`${subsidiary} Ciclo 2`);

                [red, green, blue] = getRandomColor();
                dataChart.utils_mm.datasets[0].data.push(
                  formatter.format(
                    (data[2][corporate][company][subsidiary][
                      "average_cost_utils_mm"
                    ] *
                      100) /
                      data.general[corporate][company]["average_cost_utils_mm"]
                  )
                );
                dataChart.utils_mm.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.utils_mm.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.utils_mm.labels.push(`${subsidiary} Ciclo 2`);

                newData[corporate][company] = { ...dataChart };
              }

              if (
                data[3] &&
                Object.keys(data[3]).length > 0 &&
                data[3][corporate] &&
                data[3][corporate][company] &&
                data[3][corporate][company][subsidiary]
              ) {
                let [red, green, blue] = getRandomColor();
                dataChart.tires.datasets[0].data.push(
                  formatter.format(
                    (data[3][corporate][company][subsidiary]["statistics"] *
                      100) /
                      data.general[corporate][company]["statistics"]
                  )
                );
                dataChart.tires.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.tires.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.tires.labels.push(`${subsidiary} Ciclo 3`);

                [red, green, blue] = getRandomColor();
                dataChart.remainder_depth.datasets[0].data.push(
                  formatter.format(
                    (data[3][corporate][company][subsidiary][
                      "remainder_depth"
                    ] *
                      100) /
                      data.general[corporate][company]["remainder_depth"]
                  )
                );
                dataChart.remainder_depth.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.remainder_depth.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.remainder_depth.labels.push(`${subsidiary} Ciclo 3`);

                [red, green, blue] = getRandomColor();
                dataChart.utils_mm.datasets[0].data.push(
                  formatter.format(
                    (data[3][corporate][company][subsidiary][
                      "average_cost_utils_mm"
                    ] *
                      100) /
                      data.general[corporate][company]["average_cost_utils_mm"]
                  )
                );
                dataChart.utils_mm.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.utils_mm.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.utils_mm.labels.push(`${subsidiary} Ciclo 3`);

                newData[corporate][company] = { ...dataChart };
              }

              if (
                data[4] &&
                Object.keys(data[4]).length > 0 &&
                data[4][corporate] &&
                data[4][corporate][company] &&
                data[4][corporate][company][subsidiary]
              ) {
                let [red, green, blue] = getRandomColor();
                dataChart.tires.datasets[0].data.push(
                  formatter.format(
                    (data[4][corporate][company][subsidiary]["statistics"] *
                      100) /
                      data.general[corporate][company]["statistics"]
                  )
                );
                dataChart.tires.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.tires.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.tires.labels.push(`${subsidiary} Ciclo 4`);

                [red, green, blue] = getRandomColor();
                dataChart.remainder_depth.datasets[0].data.push(
                  formatter.format(
                    (data[4][corporate][company][subsidiary][
                      "remainder_depth"
                    ] *
                      100) /
                      data.general[corporate][company]["remainder_depth"]
                  )
                );
                dataChart.remainder_depth.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.remainder_depth.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.remainder_depth.labels.push(`${subsidiary} Ciclo 4`);

                [red, green, blue] = getRandomColor();
                dataChart.utils_mm.datasets[0].data.push(
                  formatter.format(
                    (data[4][corporate][company][subsidiary][
                      "average_cost_utils_mm"
                    ] *
                      100) /
                      data.general[corporate][company]["average_cost_utils_mm"]
                  )
                );
                dataChart.utils_mm.datasets[0].backgroundColor.push(
                  `rgba(${red}, ${green}, ${blue},0.5)`
                );
                dataChart.utils_mm.datasets[0].borderColor.push(
                  `rgba(${red}, ${green}, ${blue}, 1)`
                );
                dataChart.utils_mm.labels.push(`${subsidiary} Ciclo 4`);

                newData[corporate][company] = { ...dataChart };
              }
            });
        });
    });
    return newData;
  }

  return {};
}

function getRandomColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return [red, green, blue];
}

function getCorporates(data) {
  let max = data.general ? Object.keys(data.general).length : 0;

  if (data.origin && max < Object.keys(data.origin).length) {
    return Object.keys(data.origin);
  }
  if (data.revitalized && max < Object.keys(data.revitalized).length) {
    return Object.keys(data.revitalized);
  }
  if (data[1] && max < Object.keys(data[1]).length) {
    return Object.keys(data[1]);
  }
  if (data[2] && max < Object.keys(data[2]).length) {
    return Object.keys(data[2]);
  }
  if (data[3] && max < Object.keys(data[3]).length) {
    return Object.keys(data[3]);
  }
  if (data[4] && max < Object.keys(data[4]).length) {
    return Object.keys(data[4]);
  }
  return data.general ? Object.keys(data.general) : [];
}

function getSubsidiaries(assignedSubsidiaries) {
  return assignedSubsidiaries.map(
    (assignedSubsidiary) => assignedSubsidiary.subsidiary
  );
}
