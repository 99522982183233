import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetTireAlertsFilters } from "store/actions/alert/tires/setTireAlertsFilters";
import { actionClearTireAlertsFilters } from "store/actions/alert/tires/clearTireAlertsFilters";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";

import { LIST_SUBSIDIARY } from "store/actions/account/permits";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterTireAlertsForm({ open, onClose, ...rest }) {
  const t = useLang();
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tireModels, setTireModels] = useState([]);
  const [tireModelVariations, setTireModelVariations] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [revitalizedBrands, setRevitalizedBrands] = useState([]);
  const [revitalizedModels, setRevitalizedModels] = useState([]);
  const {
    filter,
    account,
    permits,
    getSubsidiaries,
    getBrands,
    setNotification,
    setTireAlertsFilters,
    clearTireAlertsFilters,
    getTireSizes,
    getProviders,
    getWarehouses,
    getVehicles,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      status: "",
      brands: [],
      models: "",
      model_variations: [],
      invoice_folio: "",
      invoice_date: "",
      DOT_initial: "",
      DOT_final: "",
      tire_size: "",
      providers: "",
      subsidiaries: "",
      warehouses: "",
      vehicle: "",
      condition: "",
      location: "",
      date_from: "",
      date_to: "",
      revitalized_brand: "",
      revitalized_model: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        status:
          filter.status !== undefined
            ? filter.status
              ? "enabled"
              : "disabled"
            : "",
        brands: filter.brands ? filter.brands.split(",") : [],
        models: filter.models || "",
        model_variations: filter.model_variations
          ? filter.model_variations.split(",")
          : [],
        invoice_folio: filter.invoice_folio || "",
        invoice_date: filter.invoice_date || "",
        DOT_initial: filter.DOT_initial || "",
        DOT_final: filter.DOT_final || "",
        tire_size: filter.tire_size || "",
        providers: filter.providers || "",
        subsidiaries: filter.subsidiaries || "",
        warehouses: filter.warehouses || "",
        vehicle: filter.vehicles || "",
        condition: filter.condition || "",
        location: filter.location || "",
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        revitalized_brand: filter.revitalized_brand || "",
        revitalized_model: filter.revitalized_model || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (permits.includes(LIST_SUBSIDIARY)) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            tradename: subsidiary.subsidiary.tradename,
            status: subsidiary.subsidiary.status,
          }));
        }

        let dataBrands = [];
        dataBrands = await getBrands(
          { brandType: "TIRE", scope: "brand_id,name,status" },
          false
        );

        let dataTireSizes = [];
        dataTireSizes = await getTireSizes(
          {
            scope: "tire_size_id,size,status",
          },
          false
        );

        let dataProviders = [];
        dataProviders = await getProviders(
          {
            scope: "provider_id,name,status,subsidiary.name",
          },
          false
        );

        let dataWarehouses = [];
        dataWarehouses = await getWarehouses(
          {
            scope: "warehouse_id,name,status",
          },
          false
        );

        let dataVehicles = [];
        dataVehicles = await getVehicles(
          {
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        let dataRevitalizedBrands = [];
        dataRevitalizedBrands = await getBrands(
          { brandType: "RETREAD", scope: "brand_id,name,status" },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
        setBrands(dataBrands.sort(alphabeticalSort("name")));
        setTireSizes(dataTireSizes.sort(alphabeticalSort("size")));
        setProviders(dataProviders.sort(alphabeticalSort("name")));
        setWarehouses(dataWarehouses.sort(alphabeticalSort("name")));
        setVehicles(dataVehicles.sort(alphabeticalSort("economic_number")));
        setRevitalizedBrands(
          dataRevitalizedBrands.sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  function handleClean() {
    clearTireAlertsFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.status !== "") {
        params.status = values.status === "enabled" ? 1 : 0;
      }

      if (values.subsidiaries !== "") {
        params.subsidiaries = values.subsidiaries;
      }

      if (values.brands.length > 0) {
        params.brands = values.brands.join();
      }

      if (values.models !== "") {
        params.models = values.models;
      }

      if (values.model_variations.length > 0) {
        params.model_variations = values.model_variations.join();
      }

      if (values.invoice_folio) {
        params.invoice_folio = values.invoice_folio;
      }

      if (values.invoice_date) {
        params.invoice_folio = values.invoice_folio;
      }

      if (values.DOT_initial) {
        params.DOT_initial = values.DOT_initial;
      }

      if (values.DOT_final) {
        params.DOT_final = values.DOT_final;
      }

      if (values.tire_size) {
        params.tire_size = values.tire_size;
      }

      if (values.providers) {
        params.providers = values.providers;
      }

      if (values.warehouses) {
        params.warehouses = values.warehouses;
      }

      if (values.vehicle) {
        params.vehicles = values.vehicle;
      }

      if (values.condition) {
        params.condition = values.condition;
      }

      if (values.location) {
        params.location = values.location;
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      if (values.revitalized_brand) {
        params.revitalized_brand = values.revitalized_brand;
      }

      if (values.revitalized_model) {
        params.revitalized_model = values.revitalized_model;
      }

      await setTireAlertsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      subsidiaries={subsidiaries}
      brands={brands}
      revitalizedBrands={revitalizedBrands}
      tireModels={tireModels}
      tireModelVariations={tireModelVariations}
      revitalizedModels={revitalizedModels}
      tireSizes={tireSizes}
      providers={providers}
      warehouses={warehouses}
      vehicles={vehicles}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      setTireModels={setTireModels}
      setTireModelVariations={setTireModelVariations}
      setRevitalizedModels={setRevitalizedModels}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterTireAlertsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Alert.TireAlerts.filter,
  account: state.Account.account,
  permits: state.Account.permits,
});

const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setTireAlertsFilters: actionSetTireAlertsFilters(dispatch),
  clearTireAlertsFilters: actionClearTireAlertsFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
  getProviders: actionGetProviders(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
  getVehicles: actionGetVehicles(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTireAlertsForm);
