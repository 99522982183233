import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  associations,
  isUpdate,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="name"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.name.special")}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="driver_code"
        component={TextField}
        className={classnames(css.medium_field)}
        label={t("_labels.driver_code")}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="association_id"
        className={classnames(css.medium_field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_general.association")}
      >
        {associations.map((association, index) => {
          return (
            <MenuItem
              key={index}
              value={association.association_id.toString()}
              disabled={!association.status}
            >
              {association.name}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  associations: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
