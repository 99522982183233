import { SET_DRIVER_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetDriverStatus = (dispatch) => async (driverId, status) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "PUT",
      path: `driver/${driverId}/status`,
      data: { status },
    });

    dispatch({
      type: SET_DRIVER_STATUS,
      payload: driverId,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
