import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  title,
  status,
  gender,
  className,
  description,
  tooltipPermit,
  onClick,
}) {
  return (
    <Card className={classnames(css.card, className)}>
      <CardContent>
        <Typography noWrap variant="h6" component="h6">
          {title}
        </Typography>

        <Typography noWrap variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          tooltip={tooltipPermit}
          disabled={!!tooltipPermit}
          onClick={onClick}
        >
          {t("_buttons.view")}
        </Button>

        <Chip
          className={classnames(css.card_state)}
          size="small"
          label={
            status
              ? t(`_general.enabled.singular.${gender}`)
              : t(`_general.disabled.singular.${gender}`)
          }
          variant="outlined"
        />
      </CardActions>
    </Card>
  );
}

Page.propTypes = {
  gender: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  tooltipPermit: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  onClick: PropTypes.func.isRequired,
};

Page.defaultProps = {
  gender: "male",
};

export default Page;
