import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ setFieldValue, ...rest }) {
  const t = useLang();
  const { values, setNotification, getSubsidiaries, getWarehouses } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            companies: values.company_id,
            scope: "subsidiary_id,name,status",
          },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.company_id) {
      fetchData();
      setFieldValue("subsidiary_id", "");
      setFieldValue("warehouse_id", "");
    }
  }, [values.company_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getWarehouses(
          {
            subsidiaries: values.subsidiary_id,
            scope: "warehouse_id,name,status",
            with_where_has: 1,
          },
          false
        );

        setWarehouses(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.subsidiary_id) {
      fetchData();
      setFieldValue("warehouse_id", "");
    }
  }, [values.subsidiary_id]);

  return (
    <Page {...rest} t={t} subsidiaries={subsidiaries} warehouses={warehouses} />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
