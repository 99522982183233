import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    values,
    setNotification,
    getCompanies,
    getSubsidiaries,

    getProviders,
    getWarehouses,

    getTireModels,
    getTireModelVariations,
    getRevitalizedTireModels,

    setSubsidiaries,
    setCompanies,
    setWarehouses,
    setProviders,
    setFieldValue,
    setTireModels,
    setTireModelVariations,
    setRevitalizedModels,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
            corporates: values.corporate_id,
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.corporate_id) {
      fetchData();
    }
  }, [values.corporate_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            scope: "subsidiary_id,name,status",
            companies: values.company_id.join(),
          },
          false
        );
        setSubsidiaries(data.sort(alphabeticalSort("name")));
        if (data.length === 0) {
          setFieldValue("subsidiary_id", data);
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.company_id.length > 0) {
      fetchData();
    }
  }, [values.company_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getProviders(
          {
            subsidiaries: values.subsidiary_id.join(),
            scope: "provider_id,name,status",
          },
          false
        );

        setProviders(data.sort(alphabeticalSort("name")));
        data = await getWarehouses(
          {
            subsidiaries: values.subsidiary_id.join(),
            scope: "warehouse_id,name,status",
          },
          false
        );
        setWarehouses(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.subsidiary_id.length > 0) {
      fetchData();
    }
  }, [values.subsidiary_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getTireModels(
          {
            brands: values.brands.map((b) => b.brand_id.toString()).join(),
            scope:
              "tire_model_id,tire_model.name,tire_model.status,tire_model.approved",
          },
          false
        );
        const uniqueModels = dataTireModels.reduce((result, model) => {
          if (!result.find((el) => el.tire_model_id == model.tire_model_id)) {
            result.push(model);
          }
          return result;
        }, []);
        setTireModels(
          uniqueModels
            .map((model) => ({
              tire_model_id: model.tire_model_id,
              name: model.tire_model.name,
              status: model.tire_model.status,
              approved: model.tire_model.approved,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.brands.length > 0) {
      fetchData();
    }
  }, [values.brands]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModelVariations = [];
        dataTireModelVariations = await getTireModelVariations(
          {
            tireModelId: values.models.tire_model_id,
            scope:
              "tire_model_variation_id,tire_size.tire_size_id,tire_size.size,tire_size.status,status,approved,number_layers",
          },
          false
        );
        setTireModelVariations(dataTireModelVariations);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.models) {
      fetchData();
    }
  }, [values.models]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getRevitalizedTireModels(
          {
            brands: values.revitalized_brands,
            scope: "revitalized_tire_model_id,name,status",
          },
          false
        );
        setRevitalizedModels(dataTireModels.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.revitalized_brands) {
      fetchData();
    }
  }, [values.revitalized_brands]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
  setProviders: PropTypes.func.isRequired,
  setWarehouses: PropTypes.func.isRequired,
  setSubsidiaries: PropTypes.func.isRequired,

  brands: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  setRevitalizedModels: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  getTireModels: actionGetTireModels(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getProviders: actionGetProviders(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
