import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";
import { GET_TIRE_PILE_CAUSE_DETAILS } from "store/actions";

export const actionGetDetailsRetirementCause =
  (dispatch) =>
  async ({
    subsidiary_id: subsidiaryId,
    company_id: companyId,
    corporate_id: corporateId,
    date_from: dateFrom,
    date_to: dateTo,
    tire_condition: tireCondition,
    helmet_value: helmetValue,
    number_cycle: numberCycle,
    model_variation: modelVariation,
    attribution,
    retirement_cause_name: retirementCause,
    price,
    corporate_name: corporateName,
    company_name: companyName,
    subsidiary_name: subsidiaryName,
    division_name: divisionName,
    tire_application_id: application,
    brands,
    models,
    sizes,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `report/pile/depth/details/retirement/causes`,
        params: {
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          date_from: dateFrom,
          date_to: dateTo,
          tire_condition: tireCondition,
          helmet_value: helmetValue,
          number_cycle: numberCycle,
          model: modelVariation,
          attribution,
          retirement_cause_name: retirementCause,
          price,
          corporate_name: corporateName,
          company_name: companyName,
          subsidiary_name: subsidiaryName,
          division_name: divisionName,
          tire_application_id: application,
          brands,
          models,
          sizes,
        },
      });

      dispatch({
        type: GET_TIRE_PILE_CAUSE_DETAILS,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
