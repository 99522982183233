import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import DeleteIcon from "@material-ui/icons/Cancel";
import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionDeleteVehicleFull } from "store/actions/commissioned/vehicle_full/deleteVehicleFull";
import { actionGetVehicleFull } from "store/actions/commissioned/vehicle_full/getVehicleFull";
import { actionGetCommissionedVehicles } from "store/actions/commissioned/vehicle/getCommissionedVehicles";

import css from "./index.module.scss";

function VehicleFullDialog({ ...rest }) {
  const t = useLang();
  const {
    filter,
    open,
    onClose,
    commissionedVehicleId,
    getVehicleFull,
    deleteVehicleFull,
    getCommissionedVehicles,
    setNotification,
  } = rest;

  const [vehicleFull, setVehicleFull] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicleFull(commissionedVehicleId);
        setVehicleFull(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && commissionedVehicleId) {
      fetchData();
    }
  }, [open, commissionedVehicleId]);

  async function deleteFull(commissionedVehicleFullId) {
    try {
      const response = await deleteVehicleFull(commissionedVehicleFullId);

      setNotification({
        message: response.message,
      });
      onClose();
      getCommissionedVehicles({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function confirmDelete(commissionedVehicleFullId) {
    setNotification({
      message: "¿Estas seguro de desvincular el remolque de este vehículo?",
      onAction: () => deleteFull(commissionedVehicleFullId),
      textAction: t("_buttons.confirm"),
    });
  }

  return (
    <DialogMediaQuery size={"sm"} open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle className={classnames(css.title)}>
        {"Remolques vinculados al vehículo"}{" "}
        {vehicleFull[0]?.commissioned_vehicle_head?.economic_number}
      </DialogTitle>
      <DialogContent className={classnames(css.content)}>
        <div className={classnames(css.flex, css.content_info)}>
          {vehicleFull.map((vehicle) => (
            <div
              className={classnames(css.horizontal)}
              key={vehicle.commissioned_vehicle_full_id}
            >
              <LabelAndText
                text={vehicle.position.toString()}
                label={"Posición"}
                className={css.w100}
              />
              <LabelAndText
                text={vehicle.commissioned_vehicle.economic_number}
                label={t("_labels.vehicle.singular")}
                className={css.w100}
              />
              <Button
                variant="icon"
                color="primary"
                onClick={() =>
                  confirmDelete(vehicle.commissioned_vehicle_full_id)
                }
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {t("_buttons.close")}
        </Button>
      </DialogActions>
    </DialogMediaQuery>
  );
}

VehicleFullDialog.propTypes = {
  commissionedVehicleId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filter: state.Commissioned.Vehicle.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCommissionedVehicles: actionGetCommissionedVehicles(dispatch),
  deleteVehicleFull: actionDeleteVehicleFull(dispatch),
  getVehicleFull: actionGetVehicleFull(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(VehicleFullDialog);
