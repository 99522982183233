import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionAddHelmetValue } from "store/actions/subsidiary/helmet_value/addHelmetValue";
import { actionUpdateHelmetValue } from "store/actions/subsidiary/helmet_value/updateHelmetValue";
import { actionGetHelmetValue } from "store/actions/subsidiary/helmet_value/getHelmetValue";
import { actionGetHelmetValues } from "store/actions/subsidiary/helmet_value/getHelmetValues";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function HelmetValueForm({
  open,
  onClose,
  subsidiaryId,
  helmetValueId,
  ...rest
}) {
  const t = useLang();
  const {
    getTireModels,
    helmetValue,
    getHelmetValue,
    updateHelmetValue,
    addHelmetValue,
    getHelmetValues,
    // clearPressurePoliciesFilters,
    setNotification,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      tire_model_id: null,
      tire_model_variation_id: "",
      helmet_value_original: "",
      helmet_value_revitalized: "",
    },
  });

  const [isUpdate, setIsUpdate] = useState(helmetValueId ? true : false);
  const [tireModels, setTireModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireModels(
          {
            scope:
              "tire_model_id,tire_model.brand_id,tire_model.name,tire_model.status,tire_model.approved",
          },
          false
        );

        const uniqueModels = data.reduce((result, model) => {
          if (!result.find((el) => el.tire_model_id == model.tire_model_id)) {
            result.push(model);
          }
          return result;
        }, []);

        setTireModels(
          uniqueModels
            .map((model) => ({
              tire_model_id: model.tire_model_id,
              brand_id: model.tire_model.brand_id,
              name: model.tire_model.name,
              status: model.tire_model.status,
              approved: model.tire_model.approved,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getHelmetValue(subsidiaryId, helmetValueId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (helmetValueId && open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [helmetValueId, open]);

  useEffect(() => {
    if (helmetValueId && helmetValue.tire_model_variation_helmet_id) {
      setInitialValues({
        tire_model_id: helmetValue.tire_model_variation.tire_model,
        tire_model_variation_id: helmetValue.tire_model_variation_id.toString(),
        helmet_value_original: helmetValue.helmet_value_original,
        helmet_value_revitalized: helmetValue.helmet_value_revitalized,
      });
    }
  }, [helmetValue, helmetValueId]);

  useEffect(() => {
    setIsUpdate(helmetValueId ? true : false);
  }, [helmetValueId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      ...values,
      tire_model_id: values.tire_model_id.tire_model_id,
    };

    try {
      if (isUpdate) {
        await updateHelmetValue(subsidiaryId, helmetValueId, fields);
        setNotification({
          message: t("_messages.updated.helmet_value"),
        });
      } else {
        await addHelmetValue(subsidiaryId, fields);
        resetForm();
        setNotification({
          message: t("_messages.created.helmet_value"),
        });
      }

      setSubmitting(false);
      onClose();
      getHelmetValues(subsidiaryId, {});
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      isUpdate={isUpdate}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      tireModels={tireModels}
    />
  );
}

HelmetValueForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helmetValueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  helmetValue: state.Subsidiary.HelmetValue.helmet_value,
});

const mapDispatchToProps = (dispatch) => ({
  getTireModels: actionGetTireModels(dispatch),
  addHelmetValue: actionAddHelmetValue(dispatch),
  getHelmetValue: actionGetHelmetValue(dispatch),
  updateHelmetValue: actionUpdateHelmetValue(dispatch),
  getHelmetValues: actionGetHelmetValues(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelmetValueForm);
