import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import Button from "components/molecules/general/Button";

import PasswordField from "components/molecules/general/PasswordField";

import css from "./index.module.scss";

function ChangePasswordForm({
  t,
  handleSubmit,
  isSubmitting,
  isValid,
  showLogin,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        fullWidth
        required
        component={PasswordField}
        variant="filled"
        margin="normal"
        name="password"
        type="password"
        label={t("labels.password")}
      />
      <Field
        fullWidth
        required
        component={PasswordField}
        variant="filled"
        margin="normal"
        name="confirm_password"
        type="password"
        label={t("labels.confirm_password")}
      />

      <div className={classnames(css.action)}>
        {showLogin && (
          <Link to="/" className={classnames(css.login)}>
            <Button
              component="button"
              variant="contained"
              color="secondary"
              className={classnames(css.button)}
            >
              {t("back_button")}
            </Button>
          </Link>
        )}

        <Button
          disabled={isSubmitting || !isValid}
          type="submit"
          variant="contained"
          color="secondary"
          className={classnames(css.button)}
          style={{
            flex: 1,
          }}
        >
          {t("enter_button")}
        </Button>
      </div>
    </Form>
  );
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool,
};

export default ChangePasswordForm;
