import { combineReducers } from "redux";

import Role from "./role";
import History from "./history";
import Corporate from "./corporate";

export default combineReducers({
  Role,
  History,
  Corporate,
});
