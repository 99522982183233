export default {
  name: {
    singular: "Nombre",
    special: "Nombre(s)",
  },
  id: "ID",
  status: "Estatus",
  last_name_1: "Apellido paterno",
  last_name_2: "Apellido materno",
  email: "Correo electrónico",
  password: "Contraseña",
  corporate_name: "Nombre del corporativo",
  tradename: "Nombre de la empresa",
  warehouse_name: "Nombre del almacén",
  driver_name: "Nombre del conductor",
  wear_name: "Nombre del desgaste",
  damage_name: "Nombre del daño",
  tire_code: "Código del neumático",
  title_tire_subsidiary: "Vínculo de neumático con sucursal",
  revitalized_tire_model_name: "Modelo",
  depth: "Profundidad",
  dual_difference: "Diferencia dual",
  current_depth: "Huella actual",
  remainder_depth: "Profundidad remanente",
  application: "Aplicación",
  image: "imagen",
  social_reason: "Razón social",
  rfc: "RFC",
  tire_fee: "Cuota por neumático",
  probable_causes: "Causas probables",
  action_tire: "Neumático de acción",
  action_vehicle: "Vehículo de acción",
  operation: "Operación",
  total: "Total",
  projection: "Proyección de KM",

  fee_field: {
    label: "Tipo de moneda de la cuota",
    options: {
      mxn: "Pesos Mexicanos (MXN)",
      usd: "Dolar estadounidense (USD)",
      eur: "Moneda Europea (EUR)",
    },
  },
  direction: "Dirección",
  direction1: "Calle",
  direction2: "Colonia",
  province: "Provincia o municipio",

  internal_number: "Numero Interno",
  external_number: "Numero Externo",
  address: "Domicilio",
  postal_code: "Código postal",
  code: "Código",
  burn_code: "Código de quemado",
  helmet_value: "Valor del casco",
  helmet_value_revitalized: "Valor del casco revitalizado",
  created_by: "Creado por",
  updated_by: "Actualizado por",
  created_at: "Fecha de registro",
  done_by: "Realizada por",
  registered_by: "Registrado por",
  assigned_by: "Asignado por",
  actioned_by: "{person} el {date} a las {time}",
  time_at: "a las",
  edited_by: "Editada por",

  search: "Buscar...",
  observation: "Observación",
  expected_durability: "Durabilidad esperada",
  price: "Precio",
  retread_price: "Precio revitalizado",
  original_price: "Precio original",
  axle_number: "Numero de eje",
  comment: {
    singular: "Comentario",
    plural: "Comentarios",
  },
  drop_image: "Suelte la imagen aquí o haga clic para seleccionarla",

  country: "País",
  state: "Estado",
  province: "Provincia",
  corporate: {
    singular: "Corporativo",
    plural: "Corporativos",
  },
  company: {
    singular: "Empresa",
    plural: "Empresas",
  },
  user: {
    singular: "Usuario",
    plural: "Usuarios",
  },
  subsidiary: {
    singular: "Sucursal",
    plural: "Sucursales",
  },
  tire: {
    singular: "Neumático",
    plural: "Neumáticos",
  },
  vehicle: {
    singular: "Vehículo",
    plural: "Vehículos",
    in_process: "Vehículos en proceso",
  },
  axle: {
    singular: "Eje",
    plural: "Ejes",
  },
  driver: {
    singular: "Conductor",
    plural: "Conductores",
  },
  warehouse: {
    singular: "Almacén",
    plural: "Almacenes",
    default: "Selecciona un almacén",
  },
  division: {
    singular: "División",
    plural: "Divisiones",
    default: "Selecciona una división",
  },
  tolerance_policy: {
    singular: "Política de Tolerancia",
    plural: "Políticas de Tolerancia",
  },
  inspection: {
    singular: "Inspección",
    plural: "Inspecciones",
  },
  travel: {
    singular: "Recorrido",
    plural: "Recorridos",
  },
  mounted_tire: {
    singular: "Neumático montado",
    plural: "Neumáticos montados",
  },
  review: {
    singular: "Revisión",
    plural: "Revisiones",
  },
  movement_sheet: "Hoja de movimientos",
  gps: "Gps",
  imei: "Imei",
  device_name: "Nombre del dispositivo",
  size: "Medida",
  layer: "Capas",
  tolerance: "Tolerancia",
  brand: "Marca",
  transmission_brand: {
    label: "Marca de la transmisión",
    default: "Selecciona una marca de transmisión",
  },
  transmission_model: "Modelo de la transmisión",
  transmission_speeds: "Velocidades de la transmisión",
  engine_brand: {
    label: "Marca del motor",
    default: "Selecciona una marca de motor",
  },
  cylinder: "Cilindraje",
  vehicle_type: {
    label: "Tipo de vehículo",
    default: "Selecciona un tipo de vehículo",
  },
  vehicle_brand: {
    label: "Marca del vehículo",
    default: "Selecciona una marca de vehículo",
  },
  vehicle_model: "Modelo del vehículo",
  vehicle_year: "Año del vehículo",
  auto_enrollment: "Matrícula del vehículo",

  information: "Información",
  can_select_many: "Se pueden seleccionar varios",
  can_select_many_feminine: "Se pueden seleccionar varias",

  has_odometer: {
    label: "Cuenta con odómetro o hubodómetro",
    simple: "Tiene odómetro",
    helper_text:
      "Revisiones hechas en vehículos sin odómetro/hubodómetro NO se consideran para reporte de rendimiento.",
  },

  address_value:
    "{direction1} {external_number}{internal_number}{direction2}, C.P {postal_code}",

  subsidiary_name: "Nombre de la sucursal",
  unassigned_subsidiary: "Sucursal no asignada",
  unassigned_division: "Sin división vinculada",

  yes: "Si",
  no: "No",
  description: "Descripción",
  tire_quantity: "Cantidad de neumáticos",

  economic_number: "Número económico",
  geofence_status: "Estatus de la geocerca",
  inside: "Adentro",
  outside: "Afuera",

  date: {
    label: "Fecha",
    range: "Rango de fechas",
    from: "Desde",
    to: "Hasta",
  },
  captured_by: "Capturada por",
  inspection_date: "Fecha de inspección",
  mount_date: "Fecha de instalación",
  odometer: "Odómetro",
  with_odometer: "Con odómetro",
  without_odometer: "Sin odómetro",
  select_a_driver: "Selecciona un conductor",
  select_a_vehicle: "Selecciona un vehículo",

  placeholder: {
    image: "Suelte la imagen o haga clic para seleccionarla",
  },

  company_policies: {
    general_policies: "Políticas generales",
    number_cycle: "Número máximo de ciclos",
    tire_rotation: "Política de rotación",
    inspection: "Política de revisiones",
    days: "días",
    tolerance_inflation_pressure: "Tolerancia para presión de inflado",
    directional_tire_rotation: "Política de rotación direccionales",
    tolerance_mating_pressure: "Tolerancia para presión (emparejamiento)",
    helmet_policy: "Política de cascos",
    pressure_type_axle: "Presión recomendada por tipo eje",
    tire_condition_axle_type: "Condición de neumáticos por tipo de eje",
    show_alerts_different_assignment: "Mostrar alertas de diferente asignación",
    notify_inappropriate_pressures:
      "Notificar a operador sobre presiones inadecuadas",
    tolerance_directional_mating_depth:
      "Tolerancia para profundidad (direccionales)",
    mating_tolerance_directional_mating_depth:
      "Emparejamiento Tolerancia para profundidad (direccionales)",
    tolerance_mating_depth: "Tolerancia para profundidad",
    mating_tolerance_mating_depth: "Emparejamiento Tolerancia para profundidad",
    send_pressure_setting: "Enviar ajuste de presiones",
    operator_edit: "Operador puede editar revisiones",
    refection_review: "Revisar neumáticos de refacción",
    use_checklist: "Uso de checklist",
    use_tpms: "Uso de TPMS",
    no_tire: "TPMS sin gestión de neumáticos",
  },
  depth_tolerance_policies: {
    general_policies: "Políticas Generales",
    tire_application_id: "Aplicación de Neumático",
    good_condition: "Buena Condición",
    scheduled_withdrawal: "Retiro Programado",
    critical_withdrawal: "Retiro Critico",
    maximum_number_patches: "Máximo Numero de parches",
    critical_number_patches: "Numero de parches critico",
  },
  country_field: {
    label: "País",
    value: "Selecciona un país",
  },

  tire_model_field: {
    label: "Modelo",
    value: "Seleccione un modelo",
    helper_text: "Se pueden seleccionar varios",
  },

  state_field: {
    label: "Estado",
    value: "Selecciona un estado",
  },

  province_field: {
    label: "Provincia",
    value: "Selecciona una provincia",
  },

  corporate_field: {
    singular: {
      label: "Corporativo",
      value: "Selecciona un corporativo",
    },
    plural: {
      label: "Corporativos",
      helper_text: "Se pueden seleccionar varios corporativos",
    },
  },

  company_field: {
    singular: {
      label: "Empresa",
      value: "Selecciona una empresa",
    },
    plural: {
      label: "Empresas",
      helper_text: "Se pueden seleccionar varias empresas",
    },
  },

  subsidiary_field: {
    singular: {
      label: "Sucursal",
      value: "Selecciona una sucursal",
    },
    plural: {
      label: "Sucursales",
      helper_text: "Se pueden seleccionar varias sucursales",
    },
  },

  language_field: {
    label: "Idioma",
    options: { es: "Español", en: "Ingles" },
  },

  tire_model: {
    model_name: "Nombre del modelo",
    use: "Uso",
    application: "Aplicación",
  },

  type_of_route: {
    label: "Tipo de ruta",
    default: "Selecciona un tipo de ruta",
    options: {
      local: "Local",
      foreign: "Foránea",
    },
  },

  tire_model_variation: {
    label: "Variación del modelo de neumático",
    depth: "Profundidad",
    maximum_pressure: "Presión máxima",
    recommended_pressure: "Presión recomendada",
    tolerance: "Tolerancia de presión",
    number_layers: "Número de capas",
  },

  condition: "Condición",

  tire_condition: {
    default: "Selecciona una condición",
    options: {
      renovated_new: "Renovada nueva",
      renovated_used: "Renovada usada",
      original: "Original",
      revitalized: "Revitalizada",
      both: "Ambas",
      original_new: "Original Nueva",
      original_used: "Original Usada",
      retread_new: "Renovada Nueva",
      retread_used: "Renovada Usada",
      renewed: "Renovada",
      total: "Total",
    },
  },

  tire_application: {
    label: {
      singular: "Aplicación del neumático",
      plural: "Aplicaciones de los neumáticos",
    },
    default: {
      singular: "Selecciona la aplicación",
      plural: "Selecciona las aplicaciones",
    },
    helper_text: "Se pueden seleccionar varias aplicaciones",
    options: {
      directional: "Direccional",
      trailer: "Remolque",
      all_position: "Toda posición",
      traction: "Tracción",
    },
  },

  tire_size: {
    label: {
      singular: "Medida del neumático",
      plural: "Medidas de los neumáticos",
    },
    default: {
      singular: "Selecciona la medida",
      plural: "Selecciona las medidas",
    },
    helper_text: "Se pueden seleccionar varias medidas",
  },

  tire_repair: {
    not_repair: "No se encuentra en reparación",
  },

  brand_type: {
    label: {
      singular: "Tipo de marca",
      plural: "Tipos de marcas",
    },
    default: {
      singular: "Selecciona un tipo marca",
      plural: "Selecciona los tipos de marcas",
    },
    helper_text: "Se pueden seleccionar varios tipos marcas",
    options: {
      vehicle: "Vehículo",
      tire: "Neumático",
      engine_transmission: "Transmisión del motor",
      vehicle_engine: "Motor del vehículo",
      retread: "Revitalizado",
    },
  },

  tire_use: {
    options: {
      long_distance: "Larga distancia",
      mixed: "Mixto",
      regional: "Regional",
      urban: "Urbano",
    },
  },

  vehicle_type_axle_position: {
    label: "Posición",
    options: {
      frontal: "Frontal",
      central: "Central",
      rear: "Trasera",
      refection: "Refacción",
    },
  },

  revitalized_tire_model_application: {
    label: "Aplicación",
    default: "Selecciona una Aplicación",
    options: {
      directional: "Direccional",
      all_position: "Toda posición",
    },
  },
  structure_type: {
    label: {
      singular: "Tipo de estructura",
      plural: "Tipos de estructuras",
    },
    default: {
      singular: "Select a Tipo de estructura",
      plural: "Select the Tipos de estructuras",
    },
    helper_text: "Se pueden seleccionar varios tipos de estructuras",
    options: {
      truck_tractor: "Tracto Camión",
      truck: "Camión",
      box: "Caja",
      dolly: "Dolly",
      van: "Camioneta",
    },
  },

  maximum_number_patches: "Máximo número de parches",
  critical_number_patches: "Número crítico de parches",
  scheduled_withdrawal: "Retiro programado",
  critical_withdrawal: "Retiro crítico",
  good_condition: "Buen estado",

  tire_status: "Estatus del neumático",

  preview: "Vista previa",

  provider: "Proveedor",

  invoice_date: "Fecha de factura",
  invoice_folio: "Folio de factura",

  repair_detail: "Detalle de reparación",

  device_code: "Código del dispositivo",
  not_rfid: "Sin rfid vinculado",

  appearance: "Apariencia",
  action_tire: "Acción neumático",
  probable_cause: "Causa probable",
  action_vehicle: "Acción vehículo",
  action_operation: "Acción operaciones",
  lock_cycle: "Bloquear nuevos ciclos de revitalización",
  axle_field: {
    label: "Tipo de eje",
    default: "Selecciona un eje",
    options: {
      directional: "Direccional",
      traction: "Tracción",
      trailer: "Remolque",
      refection: "Refacción",
    },
  },
  area: {
    label: "Área",
    default: "Selecciona un área",
    options: {
      sidewall: "Costado",
      bead: "Ceja",
      tread: "Corona",
      inside: "Interior",
      any: "Cualquiera",
      crown: "Corona",
      wheel: "Rueda",
    },
  },
  category: {
    label: "Categoría",
    default: "Selecciona una categoría",
    options: {
      not_avoidable: "No evitables",
      avoidable: "Evitables",
      helmet_failure: "Falla de casco",
      operation: "Operación",
      retread_repair: "Renovado/Reparación",
      road_risk: "Riesgos del camino",
      directional: "Direccional",
      traction: "Tracción",
      trailer: "Remolque",
    },
  },
  lock_cycles: "Ciclos de bloqueo",
  is_root: "Es root",

  approved: "Aprobado",
  selected: {
    singular: {
      male: "Seleccionado",
      female: "Seleccionada",
    },
    plural: {
      male: "Seleccionados",
      female: "Seleccionadas",
    },
  },
  division_name: "Nombre de la división",
  association_name: "Nombre del transportista",
  association: "Transportista",
  vehicle_status: "Estatus del vehículo",
  subsidiary_status: "Estatus de la sucursal",
  alert: {
    label: "Alerta",
    code: "Código de alerta",
    suggestion: "Sugerencia",
    details: "Detalles",
  },
  priority: {
    label: "Prioridad",
    default: "Selecciona una prioridad",
    helper_text: "Se pueden seleccionar varias",
    options: {
      high: "Alta",
      half: "Media",
      low: "Baja",
    },
  },
  alert_type: {
    label: "Tipo de Alerta",
    default: "Selecciona un Tipo de Alerta",
    helper_text: "Se pueden seleccionar varias",
    options: {
      vehicle: "Vehículo",
      tire: "Neumático",
      mount: "Montaje",
    },
  },
  ranking_alert: {
    label: "Clasificación de Alerta",
    default: "Selecciona una Clasificación de Alerta",
    options: {
      pressure: "Presión",
      depth: "Profundidad",
      condition: "Condición",
      application: "Aplicación",
      projection: "Proyección",
      patches: "Parches",
      retirement: "Retiro",
      model: "Modelo",
      size: "Medida",
      review: "Revisión",
      assignment: "Asignación",
      identification: "Identificación",
      temperature: "Temperatura",
    },
  },
  wheel: {
    label: "Tipo de rin",
    default: "Selecciona un tipo de rin",
    options: {
      steel: "Acero",
      aluminum: "Aluminio",
      both: "Aluminio y acero",
    },
  },
  alerts: "Alertas",
  alert_status: "Estatus de las alertas",
  disabled_alert: "Alerta deshabilitada",
  roles: "Roles",
  password_confirm: "Confirmar contraseña",
  change_password: "Cambiar contraseña al iniciar sesión",
  color: "Color",
  with_link_driver: "Mostrar vehículos sin conductor",
  excel_placeholder:
    "Suelte el archivo de excel aquí o haga clic para seleccionarlo",
  model: "Modelo",
  models: "Modelos",
  cycles: "Ciclos",
  condition: "Condición",
  location: {
    label: "Ubicación",
    options: {
      warehouse: "ALMACÉN",
      mount: "MONTADA",
      revitalization: "REVITALIZACIÓN",
      pile: "PILA",
      repair: "REPARACIÓN",
      initial: "INICIAL",
    },
  },
  roles_field: {
    default: "Selecciona un rol",
  },
  rfid_field: {
    label: "RFID",
    default: "Selecciona un rfid",
  },
  provider_field: {
    label: "Proveedor",
    default: "Selecciona un proveedor",
  },
  warehouse_field: {
    label: "Almacén",
    default: "Selecciona un almacén",
  },
  brand_field: {
    label: "Marca",
    default: "Selecciona una marca",
    multiple: "Se pueden seleccionar varias marcas",
  },
  tire_model_variation_field: {
    label: "Medida/Capas",
    default: "Selecciona una medida/capas",
    multiple: "Se pueden seleccionar varias",
  },
  dot: "DOT",
  similar_tires: "¿Neumáticos similares a registrar?",
  dot_initial: "DOT inicial",
  dot_final: "DOT final",
  spare: "Es refacción",
  cap_and_casing: "Es vitacasco",
  revitalized_tire_model_field: {
    label: "Modelo revitalizado",
  },
  mileage: "Kilometraje",
  tire_days_service: "Días en servicio",
  tire_months_active: "Meses activo",
  review_date: "Fecha de revisión",
  number_cycle: "Número de ciclo",
  pressure: "Presión",
  not_warehouse: "Sin almacén",
  not_vehicle: "Sin vehículo",
  wear_field: {
    default: "Selecciona un desgaste",
  },
  wear: {
    plural: "Desgastes",
    singular: "Desgaste",
    month: "Desgaste mensual",
  },
  damage_field: {
    default: "Selecciona un daño",
  },
  damage: {
    plural: "Daños",
    singular: "Daño",
  },
  retirement_cause_field: {
    label: "Causa de retiro",
    default: "Selecciona una causa de retiro",
  },
  cost_dispose_helmet: "Costo por desechar el casco",
  surcharge: "Recargos al chofer",
  surcharge_item: {
    label: "Objeto de recargo",
    default: "Selecciona un objeto de recargo",
    options: {
      operation: "Operación",
    },
  },
  revitalized_brand_field: {
    label: "Marca revitalizada",
    default: "Selecciona una marca",
  },
  revitalized_model_field: {
    label: "Modelo revitalizado",
    default: "Selecciona un modelo",
  },
  tire_actions: "Acciones de neumático",
  data_sheet: {
    label: "Ficha técnica",
    placeholder:
      "Suelte el documento de la ficha técnica aquí o haga clic para seleccionarla",
    no: "Sin ficha técnica",
  },
  select_a_tire: "Selecciona un neumático",
  traveled_km: "km recorridos",
  number_patches: "Número de parches",
  last_review_data: "Datos de última de revisión",
  axle_information: "Información del eje",
  axle_position: "Posición del eje",
  tire_position: "Posición del neumático",
  axle_applications: "Aplicación de los neumáticos permitidas en el eje",
  with_review: "Con revisión",
  last_review: "Última inspección",
  new_review: "Nueva inspección",
  start_inspection: "Iniciar inspección",
  inspection_in_process: "Inspección en proceso",
  review_type: {
    label: "Tipo de revisión",
    options: {
      reset: "Reinicio de odómetro",
      complete: "Completa",
      damage_and_wear: "Daños y desgastes",
      rotation: "Rotaciones",
      mount_dismount: "Movimientos",
      pressure: "Presión",
      identify: "Identificación",
      initial: "Inicial",
      partial: "Parcial",
    },
  },
  depths: "Profundidades",
  depth_external: "Externa",
  depth_middle: "Media",
  depth_internal: "Interna",
  line: "Línea",
  reviewed: "Revisado",
  not_reviewed: "Aún no revisado",
  correct_tire: {
    label: "¿Neumático correcto?",
    yes: "Correcto",
    no: "No correcto",
  },
  all_tires: "Se deben indicar todos los neumáticos",
  depth_min: "Profundidad mín.",
  depth_max: "Profundidad máx.",
  original_info: "Datos originales",

  tire_stats_report: {
    options: {
      brand_name: "Marcas",
      model_name: "Modelos",
      model: "Modelos",
      depth: "Profundidades",
      size: "Medidas",
      number_cycle: "Ciclos",
    },
    headers: {
      brand_name: "Marca",
      size: "Medida",
      model_name: "Modelo",
      model: "Modelo",
      number_cycle: "Ciclo",
      depth: "Profundidad",
      number_of_tires: "No. neumáticos",
    },
  },
  cant_move_tire: "Solo los neumáticos almacenados se pueden trasladar",
  no_division: "Sin división",
  no_corporate: "Sin corporativo",
  summary_report: {
    percent: "Porcentaje",
    pressure_condition: {
      high: "Alta",
      very_high: "Muy alta",
      excessively_high: "Excesivamente alta",
      exaggerately_high: "Exageradamente alta",
      low: "Baja",
      very_low: "Muy baja",
      excessively_low: "Excesivamente baja",
      exaggerately_low: "Exageradamente baja",
      normal: "Normal",
      no_pressure: "Sin presión",
    },
    depth_condition: {
      good_condition: "Buen estado",
      critical_withdrawal: "Retiro crítico",
      scheduled_withdrawal: "Retiro programado",
    },
    report_show:
      "Este reporte muestra el estado actual del neumático (presión, profundidad, alertas).",
    report_desc_strong: "No se muestran todas las revisiones del neumático,",
    report_desc:
      "si no que solo se muestran datos de la última revisión del neumático.",
    disclaimers: {
      high_pressure:
        'Las clasificaciones de presión "Alta" y "Muy Alta" corresponden a la clasificación de presión "Alta" en el reporte de resumen.',
      low_pressure:
        'Las clasificaciones de presión "Baja" y "Muy Baja" corresponden a la clasificación de presión "Baja" en el reporte de resumen.',
      no_pressure:
        'Las clasificación de presión "Sin Presión" corresponde a los neumáticos que están almacenados.',
    },
  },
  damages_wears: {
    percent: "Porcentaje",
    area: "Area",
    frequency: "Frecuencia",
    attribution: "Atribución",
    provider: "Proveedor",
    incidents: "Incidencias",
  },
  report_warehouse: {
    percent: "Porcentaje",
    movement_date: "Fecha de almacenaje",
    model: "Modelo",
    size: "Medida",
    depth: "Profundidad de huella",
    depth_condition: {
      good_condition: "Buen estado",
      critical_withdrawal: "Retiro crítico",
      scheduled_withdrawal: "Retiro programado",
    },
    tire_condition: {
      original_new: "Original Nueva",
      original_used: "Original Usada",
      retread_new: "Renovada Nueva",
      retread_used: "Renovada Usada",
    },
    tire_application: {
      all_position: "Toda posición",
      directional: "Direccional",
      traction: "Tracción",
      trailer: "Remolque",
    },
  },
  link_message:
    "Solo se puede llenar un campo a la vez de estas opciones: Corporativo - Empresa - Sucursal",
  axles: {
    options: {
      frontal: "Frontal",
      central: "Central",
      rear: "Trasero",
      refection: "Refacción",
    },
  },
  autocomplete: {
    no_options: "Sin opciones",
    open: "Abrir",
    close: "Cerrar",
    clear: "Borrar",
  },
  tire_in_position: "Neumático en posición {position}:",
  reset_difference: "Reiniciar diferencia del gps",

  pressure_rating: "Clasificación de presión",
  depth_rating: "Clasificación de profundidad",
  see_alerts: "Ver Alertas",
  best_tire: "El mejor neumático",
  best_performance_desc:
    "Este reporte muestra cuáles son los mejores neumáticos, aquellos con el mejor rendimiento en función a su km",
  best_performance_desc_small:
    "Solo se toman en cuenta los neumáticos que tienen más de 50,000 km recorridos en su etapa actual (original o revitalizado)",
  mm_pile_desc:
    "Este reporte indica el mm restante de los neumáticos cuando son enviados a pila de desecho",
  km_pile_desc:
    "Este reporte muestra cual fue el rendimiento en km recorridos de los neumáticos durante su vida util",
  tire_cost: "Costo de neumáticos según su modelo Originales",
  actual_cost: "Costo actual por Km",
  proposal: "Propuesta",
  real_saving: "Ahorro real por Km",
  cost: "Precio",
  revitalized_cost: "Costo de neumáticos según su modelo Revitalizados",
  best_tire_original: "Mejores Neumáticos Originales",
  best_tire_revitalized: "Mejores Neumáticos Revitalizados",

  user_review_report: {
    review_report: "Reporte de revisiones",
    report_show:
      "Este reporte muestra todas las revisiones hechas por el usuario a un vehículo en el rango de fechas establecidas.",
    percent: "Porcentaje",
  },
  warehouse_report: {
    variation: "Variación",
    revitalized_brand: "Marca revitalizada",
    revitalized_model: "Modelo revitalizado",
    report: "Reporte de neumáticos en almacenes",
  },
  tires_mount_report: {
    report_of_tires: "Reporte de neumáticos instalados en vehículos", //"Report of tires installed in vehicles",
    position: "Posición",
    type: "Tipo",
  },
  damages_wears_report: {
    report_damage_wear: "Reporte de Daños y Desgastes a Modelos de Neumáticos",
    shows_tire_model:
      "Este reporte muestra modelos de neumáticos con porcentajes de daños y desgastes.",
    damage_counters: "Contadores de daños",
    wear_counters: "Contadores de desgastes",
  },
  dynamic_cost_report: {
    tabs: {
      general: "General",
      original: "Original",
      revitalized: "Revitalizados",
      cycle: "Ciclo",
    },
    cost_report: "Reporte de costos por modelo de neumático",
    this_report_shows:
      "Este reporte muestra el costo de un neumático de cierto modelo, además del costo por km, por mm y rendimiento km/mm.",
    only_tires:
      "Solo se toman en cuenta los neumáticos (Que no sean refacción) que tienen más de 1000 km recorridos en su ciclo actual (original o revitalizado)",
    tire_cost: "Costo de neumáticos",
    tires: "Llantas",
    cost_km: "Costo KM",
    cost_mm: "Costo MM",
    cost: "Costo",
    cost_of_tires: "Costo de neumáticos según sus modelos Originales",
    tire_cost_revitalized:
      "Costo de neumáticos según sus modelos revitalizados",
    cost_first_cycle: "Costo de neumáticos en su primer ciclo",
    cost_second_cycle: "Costo de neumáticos en su segundo ciclo",
    cost_third_cycle: "Costo de neumáticos en su tercer ciclo",
    cost_fourth_cycle: "Costo de neumáticos en su cuarto ciclo",
    originals: "Originales",
    options: {
      both: "Ambas",
      warehouse: "Almacenadas",
      mount: "Montadas",
    },
  },
  report_pile: {
    cycle: "Ciclo",
    tires: "Llantas",
    addition: "Suma mm",
    average: "Promedio mm útiles",
    utils_mm: "mm Útiles",
    cost_mm: "Costo promedio mm",
    cost_useful: "Costo promedio mm útiles",
    summary_cost_mm: "Suma costo mm útiles",
    cost: "Costo",
    cost_km: "Costo km",
    originals: "Originales",
    revitalized: "Revitalizados",
    number_of_tires: "Número de neumáticos",
  },
  pile_summary_report: {
    pressure_classification: "Clasificación de presión",
    depth_classification: "Clasificación de profundidad",
    alert_classification: "Clasificación de alertas",
    view_alerts: "Ver alertas",
    movements: {
      options: {
        both: "Ambas",
        warehouse: "Almacenadas",
        mount: "Montadas",
      },
    },
  },
  pile_performance_report: {
    show_report:
      "Este reporte muestra cual es el neumático con el mejor rendimiento en función a su km",
    paragraph_report:
      "Solo se toman en cuenta los neumáticos que tienen más de 100,000 km recorridos en su etapa actual (original o revitalizado)",
    cost_tire: "Costo de neumáticos según su modelo Originales",
    current_cost: "Costo actual por Km",
    proposal: "Propuesta",
    real_savings: "Ahorro real por Km",
    layers: "Capas",
    tires: "Llantas",
    cost_10_km: "Costo * 10k/km",
    tire_cost_revitalized: "Costo de neumáticos según su modelo Revitalizado",
  },
  pile_stats_report: {
    number_of_tires: "No. neumáticos",
    range_of_depths: "Rango de profundidades (mm)",
  },
  report_last_movement: {
    label: "Último Movimiento",
    last_position: "Última Posición",
    renovated_number: "Número renovado",
    type: "Tipo",
    shipping_date: "Fecha de envío a",
    depth_model: "Huella del modelo",
    depth_last_revision: "Profundidad de huella en ultima revisión",
    wasted_rubber: "Caucho desperdiciado",
    cost: "Costo - MM",
    disposal_loss: "Pérdida por desecho",
  },
  needs_report: "Reporte de necesidades",
  suggested: "Sugerido",
  quantity: "Cantidad",
  originals: "Originales",
  revitalized: "Revitalizados",
  calculated: "Calculado",
  original_cost: "Costo Originales",
  revitalized_cost: "Costo Revitalizados",
  ranking: "Posición",
  cost_km: "Costo por km",
  required: "Requeridos",
  stock: "Existencias",
  to_buy: "A comprar",
  last_purchase_price: "Precio ultima compra",
  last_purchase_date: "Fecha ultima compra",
  center: "Centro",
  start_date: "Fecha inicio",
  end_date: "Fecha fin",
  semaphore: "Semáforo",
  depth_semaphore: "Semáforo remanente",
  pressure_semaphore: "Semáforo de presión",
  tires_by_condition: "Neumáticos por condición",
  tires_by_application: "Neumáticos por aplicación",
  bad_mating: "Mal apareamiento",
  costs: "Costos por mm",
  pressures: "Presiones",
  analysis: "Análisis",
  summary: "Resumen",
  summary_desc:
    "Conoce los ahorros que se pueden generar al aplicar acciones correctivas adecuadas en PRESIONES y DESMONTAJES de llantas en estado de RETIRO CRÍTICO.",
  floor_depth: "Profundidad de piso",
  point_withdrawal: "Punto de retiro",
  air_pressure: "Presión de aire",
  copyright:
    "Derechos Reservados {year} SETENAL | Políticas de privacidad - Condiciones de uso",
  type: "Tipo",
  originals: "Originales",
  vitalized: "Vitalizadas",
  tires_good_condition: "Llantas en buen estado",
  tires_to_be_renewed: "Llantas próximas a renovar",
  tires_to_renew: "Llantas para renovar",
  total_tires_checked: "Total de llantas revisadas",
  quantity_originals: "Cantidad originales",
  quantity_revitalized: "Cantidad revitalizados",
  total_number_tires: "Cantidad total de neumáticos",
  investment_original_tires: "Inversión de llantas originales",
  investment_renewed_tires: "Inversión de llantas renovadas",
  wear_loss: "Perdida por desgastes",
  potential_savings: "Ahorros potenciales",
  quantity: "Cantidad",
  description_code: "Código de descripción",
  quantity_alerts: "Cantidad de alertas",
  costo: "Costo",
  sum_of_mm: "Suma de mm",
  average_mm: "Promedio de mm",
  cost_mm: "Costo de mm",
  area_of_opportunity: "% de flota inspeccionada",
  total_sample: "Total muestra",
  total_base: "Base total",
  potencial_savings_fleet: "Ahorro por flota de neumáticos",
  tires_fleet: "Cantidad de neumáticos revisados",
  savings_tires: "Ahorro por neumático",
  durability: "Durabilidad promedio por neumático",
  savings_durability: "Ahorro por km por neumático",
  estimated_fleet: "Total de neumáticos",
  savings_durability_fleet: "Ahorro por km de flota",
  potential_national_savings: "Ahorro potencial total",
  with_pressure: "Con presión",
  percent_of_loss: "% de perdida",
  renovated: "Renovada",
  pressure_loss: "Pérdida por presiones",
  original_cost: "Costo originales",
  rev_cost: "Costo revitalizadas",
  original_loss_percentage: "% de pérdida originales",
  revitalized_loss_percentage: "% de pérdida revitalizadas",
  average_price_per_tire:
    "Precio por llanta promedio (por modelo y cantidad de neumático)",
  report_summary: {
    location: {
      warehouse: "Almacenadas",
      mount: "Montadas",
      both: "Ambas (Almacenadas y Montadas)",
      pile: "Pila",
    },
    desc: "Este reporte muestra la última revisión de los neumáticos comprendida en el periodo de las fechas seleccionado",
  },
  quant: "cant",
  report_savings: {
    condition: {
      advertencia: "Advertencia",
      normal: "Normal",
    },
  },
  show_refection: "Descartar refacciones",
  with_mount_dismount: "No mostrar Montajes y Desmontajes",
  percent: "Porcentaje",
  incidents: "Incidencias",
  attribution: {
    options: {
      operator: "Operador",
      use_and_service: "Uso y servicio",
      operation: "Operación",
      factory: "Fabricante",
      maintenance: "Mantenimiento",
      retreader: "Renovador",
      total: "Total",
      unclassifiable: "Sin clasificación",
      none: "Sin clasificación",
      provider: "Proveedor",
    },
  },
  evidence: "Evidencia",
  original_tires: "Originales",
  revitalized_tires: "Revitalizadas",
  finalized: "Finalizada",
  semaphore_report: {
    fleet_report_desc:
      "Este reporte muestra los neumáticos instalados en todas las unidades",
    report_desc:
      "Este reporte muestra los neumáticos revisados de cada unidad en el periodo de tiempo seleccionado. Aunque la unidad tenga todos sus neumáticos instalados, sólo van a salir aquellos que se revisaron",
    total_amount: "Suma Total",
    unit: "Unidad",
    units: "Unidades",
    green_info: "Buena condición",
    yellow_info: "Retiro programado",
    red_info: "Retiro crítico",
    summary_depth_description: "Condición de Profundidad",
    summary_tire_description: "Condición de Neumático",
  },
  detail: "Detalle",
  no_results: "Sin resultados",
  date_send: "Fecha de envío",
  date_return: "Fecha de regreso",
  history: "Historial",
  select_alert: "Selecciona una alerta",
  repair_detail: "Detalle de reparación",
  repair_detail_name: {
    label: "Nombre de reparación",
    default: "Selecciona un nombre",
    options: {
      patch: "Parche",
      stuffed: "Relleno",
    },
  },
  tire_revitalized_report: {
    title:
      "Este reporte muestra la cantidad de neumáticos enviados a revitalizar por proveedor y el tiempo aprox. que tarda el proceso de revitalizado.",
    time: "Tiempo aprox.",
  },
  summary_warehouse_report: {
    title:
      "Este reporte muestra la cantidad de neumáticos desmontados por almacén",
  },
  summary_mount_report: {
    title:
      "Este reporte muestra la cantidad de neumáticos montados por tipo de vehículo",
    time: "Tiempo aprox.",
  },
  tire_repair_report: {
    title:
      "Este reporte muestra la cantidad de neumáticos por proveedor y el tiempo aprox. que tarda el proceso de reparación.",
    time: "Tiempo aprox.",
  },
  km_projection_report: {
    label: "Este reporte muestra la proyección de km por neumático",
    title: "Reporte de Proyección de Km",
    km_projection: "Proyección de Km",
    km_projection_cost: "Proyección Costo Km",
  },
  km_pile_report: {
    title: "Reporte de Km de pila",
    average: "Promedio km",
    cost_km: "Costo por Km",
    summary: "Suma de km",
  },
  alert_cause: "Causa",
  tire_review_date: "Fecha de revisión de neumáticos",
  trend: "Tendencia",
  last_sampling: "Último muestreo",
  current: "Actual",
  losses: "Pérdidas",
  savings: "Ahorros",
  original_investment: "Inversión de neumáticos originales",
  revitalized_investment: "Inversión de renovado de neumáticos",
  revitalized_saving: "Ahorro al renovar neumáticos",
  pressure_saving: "Ahorro por ajuste de presión",
  proposed_saving: "Ahorro propuesto de la muestra",
  dashboard: {
    performance: {
      number_damage: "Daños",
      punctures: "Ponchaduras",
      number_revitalizations: "Revitalizaciones",
      number_tires_pile: "Pila",
      number_tires_review: "Neumáticos Revisados",
      number_tires: "Neumáticos Totales",
      number_vehicles_review: "Vehículos Revisados",
      number_vehicles: "Vehículos Totales",
      number_review: "Revisiones",
    },
    savings: {
      pressure_savings: "Ahorros por Presión",
      revitalized_savings: "Ahorros por Revitalizado",
      total: "Ahorros Totales",
    },
  },
  save_favorite: "Marcar como favorito",
  remove_favorite: "Quitar de favoritos",
  favorites: {
    report_tire_pile_depth: "Pila de Desecho",
    report_tire_pile_travel: "Km de pila",
    report_tire_statistics: "Estadísticas de neumáticos",
    report_km_performance: "Mejor rendimiento",
    report_km_projection: "Proyección de km",
    report_tire_summary: "Resumen Histórico",
    report_tire_summary_current: "Resumen Actual",
    report_km_dynamic_cost: "Costo por modelo",
    report_renewability_index: "Índice de renovabilidad",
    report_review_performance: "Desempeño de neumáticos",
    report_tire_po_savings: "Perdidas potenciales",
    report_tire_damage_wear: "Daños y Desgastes",
    report_tire_warehouse: "Neumáticos almacenados",
    report_user_review: "Revisiones por usuario",
    report_purchase: "Compras sugeridas",
    report_last_movement: "Último movimiento",
    report_vehicle_semaphore: "Reporte semáforo",
    report_tire_revitalized: "Reporte de neumáticos revitalizados",
    report_tire_repair: "Reporte de neumáticos reparados",
    report_summary_mount: "Reporte de montajes realizados",
    report_summary_warehouse: "Reporte de neumáticos desmontados",
    report_tire_mount: "Montaje de neumáticos",
    report_tire_statistics_historic: "Estadísticas de neumáticos (Histórico)",
    report_review_performance_company: "Desempeño por empresa",
    report_completed_cycles: "Reporte de ciclos completados",
    report_vehicle_fleet_semaphore: "Neumáticos instalados en la flota",
  },
  potential_savings_report: {
    by_brand: "Por marca",
    by_model: "Por modelo",
    by_size: "Por medida",
    fleet: "Flota",
    fleet_percentage: "% de Flota",
    reviews: "Revisiones",
    reviews_percentage: "% de Revisiones",
    reviews_percentage_over_fleet: "% de Revisiones / Flota",
    reviewed_vehicles: "Vehículos Revisados",
    reviewed_vehicles_percentage: "% de Vehículos Revisados",
    reviewed_vehicles_percentage_over_fleet: "% de Vehículos Revisados / Flota",
    reviewed_tires: "Neumáticos Revisados",
    mounted: "Instalados",
    mounted_percentage: "% de Instalados",
    mounted_percentage_over_fleet: "% de Instalados / Flota",
    warehouse: "Almacenados",
    warehouse_percentage: "% de Almacenados",
    warehouse_percentage_over_fleet: "% de Almacenados / Flota",
  },
  potential_savings_analysis: {
    potential_losses: "Pérdidas Potenciales",
    potential_losses_description:
      "Se consideran todos los neumáticos fuera de su presión óptima y en retiro crítico",
    pressure_losses: "Pérdidas por presión incorrecta",
    purchase_losses: "Pérdidas por compra de neumáticos originales",
    month_losses: "Pérdidas del mes",
    real_savings: "Ahorros reales",
    real_savings_description:
      "Ahorros generados por corregir neumáticos fuera de su presión óptima y por revitalizado de neumáticos",
    pressure_savings: "Ahorros por presión",
    revitalized_savings: "Ahorros por revitalizado",
    month_savings: "Ahorros del mes",
    fleet_condition: "Condición de Flota",
    potential_savings_losses: "Ahorros y Pérdidas Potenciales",
    pressures_description:
      "Pérdidas por neumáticos con condiciones anormales de presión",
    depth_new_description:
      "Inversión en neumáticos que deben ser adquiridos originales. Porque si son revitalizados superarían la política de número de revitalizaciones de la empresa.",
    depth_revitalized_description:
      "Inversión en neumáticos que pueden ser revitalizados",
    real_savings_losses: "Ahorros y Pérdidas Reales",
    real_original: "Originales sin retiro oportuno",
    real_original_description:
      "Neumáticos que fueron adquiridos originales, que podían ser revitalizados",
    real_original_elements: "Neumáticos originales adquiridos",
    real_revitalized: "Revitalizados",
    real_revitalized_elements: "Neumáticos revitalizados adquiridos",
    real_revitalized_description: "Neumáticos que fueron revitalizados",
    acquisition_percentage: "Porcentaje de adquisición",

    fleet: "Flota",
    inspections: "Revisiones",
    inspections_over_fleet: "Revisiones sobre flota",
    elements: "Neumáticos",
    original_tires: "Neumáticos originales",
    critical_tires: "Neumáticos en retiro crítico",
    sent_revitalized_elements: "Neumáticos enviados",
    percentage: "Porcentaje",
    percentage_over_fleet: "Porcentaje sobre flota",
    losses: "Pérdidas",
    savings: "Ahorros",
    potential_savings: "Ahorros potenciales",
    original_investment: "Inversión originales",
    revitalized_investment: "Inversión revitalizados",
  },
  month: "Mes",
  potential_savings_desc:
    "Este reporte muestra la condición de la flota (vehículos/neumáticos) en un periodo mensual seleccionado (Si no se define un mes por defecto toma el mes anterior a la fecha actual). Este reporte NO MUESTRA LA INFORMACIÓN del mes en curso, para consultar esa información diríjase al Reporte de resumen. La principal intención de este reporte es mostrar el comportamiento de revisiones de la flota e indicar las pérdidas y ahorros generados mes con mes.",
  summary_report_link: "Ir al reporte de resumen",
  relative_cost_text:
    "*Si no se ha indicado algún precio de renovado el sistema determinará el precio aproximado de renovar el neumático",
  driver_code: "Clave",
  license: "Licencia",
  course: "Curso",
  duration: "Duración",
  months: "Meses",
  days: "Días",
  day: "Día",
  min_km: "Km mínimo",
  max_km: "Km máximo",
  actions: "Acciones",
  activity: {
    label: "Descartar revisiones",
    options: {
      not_rule_out: "Sin descartar",
      general: "Descartar montajes y desmontajes",
      general_and_mount: "Descartar desmontajes",
    },
  },
  no_option: "Sin opción",
  only_empty_vehicles: "Mostrar sólo vehículos sin neumáticos montados",
  type_value: {
    string: "Texto",
    integer: "Número entero",
    decimal: "Número decimal",
    boolean: "Check",
    date: "Fecha",
    numeric: "Numéro",
    pressure: "Presión",
    depth: "Profundidad",
  },
  is_critical: "Es crítico",
  sensor_check: "Requiere sensor",
  ranking_value: "Valor",
  use: "Uso",
  use_value: {
    general: "General",
    per_tire: "Por neumático",
  },
  general_data: "Datos generales",
  headboard: "Encabezado",
  body: "Cuerpo",
  disposal_chart: {
    retread: "Renovadas",
    original: "Originales",
    total: "Total",
    others: "Otras",
    "retread-1": "Primer renovado",
    "retread-2": "Segundo renovado",
    "retread-3": "Tercer renovado",
    "retread-4": "Cuarto renovado",
    monthly: "Mensual",
    yearly: "Anual",
    monthly_limit:
      "Solo se pueden mostrar máximo 24 meses hacia atrás partiendo de la fecha hasta o por defecto de la fecha actual.",
    yearly_limit:
      "Solo se pueden mostrar 3 años máximo partiendo hacia atrás de la fecha seleccionada hasta o por defecto de la fecha actual.",
    causes: {
      side_damaged_by_impact_or_break: "Costado dañado ruptura por impacto",
      crown_penetration: "Penetración corona",
      supplier_rejection: "Rechazo proveedor",
      cracking_on_shoulder: "Agrietamiento en hombro",
      belt_separation: "Separación de cinturones",
      end_of_useful_life: "Fin de vida útil",
      damaged_eyebrow: "Ceja dañada (rasgada/cortada)",
      unidentified: "No identificado",
    },
    titles: {
      yearly_bill: "Comparativo anual facturación $",
      yearly_retirement: "Principales causas de retiro anual",
      yearly_cost: "Acumulado anual costo del hule desechado",
      yearly_remainder_depth: "Análisis por hule no utilizado anual (mm)",
      yearly_by_cycle: "Acumulado anual por ciclo (piezas)",
      yearly_by_division: "Acumulado anual desecho por división (piezas)",
      disposal_savings: "Ahorro por costo de reposición",
      bill_by_cost: "Facturación por $",
      bill_by_pieces: "Facturación por piezas",
      tire_equivalence: "Equivalencia en llantas (piezas)",
      disposal_percentage: "Porcentaje mensual de desecho",
      cost_by_disposal: "Costo por hule desechado",
      remainder_depth: "Análisis por hule no utilizado (mm)",
      brand_analysis: "Análisis por marca (piezas)",
      km_dispose: "Km recorrido en desecho mensual",
      total_dispose: "Desecho total (piezas)",
    },
  },
  except_pile: "Descartar pila",
  discard: "Descartar",
  discard_all: "Descartar todas",
  title: "Título",
  sensor: "Sensor",
  with_alerts: "Con alertas",
  without_alerts: "Sin alertas",
  temperature: "Temperatura",
  km_day: "Km por día",
  projection_day: "Duración proyectada",
  withdrawal_alert: "Alertas de retiro",
  historic_installations: "Historial de neumáticos instalados",
  select_default: "Selecciona una opción",
  download: "Descargar",
  see_tutorial: "Ver tutorial",
  tutorials_desc:
    "Aquí encontrarás tutoriales de distintas categorías. Haz clic en una categoría para ver los tutoriales disponibles.",
  km_projected: "Km (Proyectado | Terminado)",
  tires_by_location: "Neumáticos por ubicación",
  tire_fleet: "Ubicación de flota",
};
