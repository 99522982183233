import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LabelAndText from "components/molecules/general/LabelAndText";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";
import Tooltip from "@material-ui/core/Tooltip";

import css from "./index.module.scss";

function Page({
  t,
  review,
  hasGpsOdometer,
  isLoading,
  initialErrors,
  initialValues,
  handleValidate,
  // handleSubmit,
  isInReviewPage,
  inProcess,
  onFinalize,
  createVehicleReviewPermit,
  updateVehicleReviewPermit,
  deleteVehicleReviewPermit,
  handleOpenVehicleReviewForm,
  isValidReview,
  confirmSubmit,
  onDeleteReview,
  formRef,
}) {
  return (
    <Card className={classnames(css.card)} elevation={3}>
      {!!review.vehicle_review_id && (
        <CardContent className={classnames(css.content)}>
          <Typography
            className={classnames(css.mb0)}
            variant="h6"
            color="textPrimary"
          >
            {t("_titles.show.inspection")}
          </Typography>
          <div className={classnames(css.flex, css.content_info)}>
            <div className={classnames(css.flex, css.w100)}>
              <Typography
                className={classnames(css.full_width)}
                variant="overline"
                color="textSecondary"
              >
                {t("_labels.last_review")}
              </Typography>
              <Button
                variant="icon"
                color="secondary"
                onClick={handleOpenVehicleReviewForm}
                tooltip={
                  !updateVehicleReviewPermit
                    ? t("_permits.update.vehicle_review")
                    : ""
                }
                disabled={!updateVehicleReviewPermit}
              >
                <EditIcon />
              </Button>

              {isInReviewPage && inProcess && (
                <Button
                  variant="icon"
                  color="secondary"
                  onClick={onDeleteReview}
                  tooltip={
                    !deleteVehicleReviewPermit
                      ? t("_permits.delete.vehicle_review")
                      : ""
                  }
                  disabled={!deleteVehicleReviewPermit}
                >
                  <DeleteIcon />
                </Button>
              )}
            </div>

            <Typography
              className={classnames(css.full_width, css.mt2)}
              variant="body1"
              color="textPrimary"
            >
              {`${t("_labels.inspection_date")}: ${moment(review.date).format(
                "LL"
              )}`}
            </Typography>

            {!isInReviewPage && !isValidReview ? (
              <Tooltip
                title={t("_messages.out_of_time_review")}
                placement={"top"}
              >
                <Typography
                  className={classnames(css.full_width, css.error)}
                  variant="body1"
                  color="textPrimary"
                >
                  {`${t("_labels.captured_by")} ${review.created_by}`}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                className={classnames(css.full_width)}
                variant="body1"
                color="textPrimary"
              >
                {`${t("_labels.captured_by")} ${review.created_by}`}
              </Typography>
            )}

            {review.updated_by && (
              <Typography
                className={classnames(css.full_width, css.mt2)}
                variant="body1"
                color="textPrimary"
              >
                {`${t("_labels.edited_by")} ${review.updated_by}`}
              </Typography>
            )}

            <div className={classnames(css.flex, css.w100)}>
              <LabelAndText
                className={classnames(css.full_width)}
                label={t("_labels.odometer")}
                text={review.odometer}
              />

              <LabelAndText
                className={classnames(css.full_width)}
                label={t("_labels.review_type.label")}
                text={t(
                  `_labels.review_type.options.${review.review_type
                    .toLowerCase()
                    .replace(/\s|\//g, "_")}`
                )}
              />
            </div>

            {inProcess ? (
              <>
                <Typography
                  className={classnames(css.full_width, css.fs16, css.error)}
                  variant="overline"
                  color="textPrimary"
                >
                  {t("_labels.inspection_in_process")}
                </Typography>
                <LabelAndText
                  className={classnames(css.full_width)}
                  label={t("_labels.review_type.label")}
                  text={
                    review.review_type === "COMPLETE"
                      ? t("_labels.review_type.options.complete")
                      : t("_labels.review_type.options.damage_and_wear")
                  }
                />
              </>
            ) : (
              <Typography
                className={classnames(css.full_width)}
                variant="overline"
                color="textSecondary"
              >
                {t("_labels.new_review")}
              </Typography>
            )}

            <div className={classnames(css.full_width)}>
              <Formik
                enableReinitialize
                initialErrors={initialErrors}
                initialValues={initialValues}
                validate={handleValidate}
                onSubmit={confirmSubmit}
                innerRef={formRef}
              >
                {({ isSubmitting, isValid, handleSubmit, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    {!inProcess && (
                      <>
                        <div className={classnames(css.full_width)}>
                          <Field
                            required
                            variant="filled"
                            name="date"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            component={DateTimeField}
                            label={t("_labels.date.label")}
                            className={classnames(css.medium_field, css.mr8)}
                            InputProps={{
                              inputProps: {
                                max: moment().format("YYYY-MM-DD"),
                              },
                            }}
                          />

                          <Field
                            required
                            variant="filled"
                            name="odometer"
                            component={TextField}
                            type="number"
                            className={classnames(css.medium_field)}
                            label={t("_labels.odometer")}
                            disabled={hasGpsOdometer}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  className={classnames(css.adornments)}
                                  position="start"
                                >
                                  km
                                </InputAdornment>
                              ),
                              inputProps: {
                                min: hasGpsOdometer
                                  ? initialValues.odometer
                                  : parseFloat(review.odometer),
                                step: "any",
                              },
                            }}
                          />
                        </div>
                        <div className={classnames(css.full_width)}>
                          <Field
                            fullWidth
                            multiline
                            rows={3}
                            className={classnames(css.field)}
                            variant="filled"
                            name="observation"
                            component={TextField}
                            label={t("_labels.observation")}
                          />
                        </div>
                      </>
                    )}

                    {isInReviewPage && !inProcess && (
                      <Typography
                        className={classnames(css.full_width)}
                        variant="overline"
                        color="textSecondary"
                      >
                        {t("_labels.start_inspection")}
                      </Typography>
                    )}
                    <div
                      className={classnames(css.actions, {
                        [css.end]: !isInReviewPage || inProcess,
                      })}
                    >
                      {isInReviewPage ? (
                        inProcess ? (
                          <>
                            <Button
                              type="button"
                              variant="contained"
                              color="secondary"
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                !updateVehicleReviewPermit
                              }
                              onClick={onFinalize}
                              tooltip={
                                !updateVehicleReviewPermit
                                  ? t("_permits.update.vehicle_review")
                                  : ""
                              }
                            >
                              {t("_buttons.finalize")}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                isLoading ||
                                !createVehicleReviewPermit
                              }
                              onClick={() => {
                                setFieldValue("review_type", "COMPLETE");
                              }}
                              tooltip={
                                !createVehicleReviewPermit
                                  ? t("_permits.create.vehicle_review")
                                  : ""
                              }
                            >
                              {t("_buttons.complete")}
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="secondary"
                              disabled={
                                isSubmitting ||
                                !isValid ||
                                isLoading ||
                                !createVehicleReviewPermit
                              }
                              onClick={() => {
                                setFieldValue("review_type", "DAMAGE AND WEAR");
                              }}
                              tooltip={
                                !createVehicleReviewPermit
                                  ? t("_permits.create.vehicle_review")
                                  : ""
                              }
                            >
                              {t("_buttons.damages_and_wear")}
                            </Button>
                          </>
                        )
                      ) : (
                        <>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={
                              isSubmitting ||
                              !isValid ||
                              isLoading ||
                              !createVehicleReviewPermit
                            }
                            onClick={() =>
                              setFieldValue("review_type", "ROTATION")
                            }
                            tooltip={
                              !createVehicleReviewPermit
                                ? t("_permits.create.vehicle_review")
                                : ""
                            }
                          >
                            {t("_buttons.rotation")}
                          </Button>
                          <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            disabled={
                              isSubmitting ||
                              !isValid ||
                              isLoading ||
                              !createVehicleReviewPermit
                            }
                            onClick={() =>
                              setFieldValue("review_type", "MOUNT/DISMOUNT")
                            }
                            tooltip={
                              !createVehicleReviewPermit
                                ? t("_permits.create.vehicle_review")
                                : ""
                            }
                          >
                            {t("_buttons.save")}
                          </Button>
                        </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasGpsOdometer: PropTypes.bool.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  isInReviewPage: PropTypes.bool,
  inProcess: PropTypes.bool,
  onFinalize: PropTypes.func,
  createVehicleReviewPermit: PropTypes.bool.isRequired,
  updateVehicleReviewPermit: PropTypes.bool.isRequired,
  deleteVehicleReviewPermit: PropTypes.bool.isRequired,
  handleOpenVehicleReviewForm: PropTypes.func,
  isValidReview: PropTypes.bool,
  formRef: PropTypes.object,
  confirmSubmit: PropTypes.func,
  onDeleteReview: PropTypes.func,
};

export default Page;
