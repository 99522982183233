import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

function Page({ t, open, onClose, vehicles, handleClickExcel }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.vehicles")}
            {vehicles.length
              ? `: ${vehicles.length}`
              : `: ${Object.keys(vehicles).length}`}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Button
            onClick={handleClickExcel}
            variant="contained"
            color="secondary"
            className={classnames(css.pb4)}
          >
            {t("_buttons.generate_excel")}
          </Button>
          <TableContainer>
            <Table className="m-0 p-0" style={{ minWidth: "500px" }}>
              <TableHead
                style={{ backgroundColor: "lightgray", color: "black" }}
              >
                <TableRow>
                  <TableCell className="sFS16">
                    {t("_labels.economic_number")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehicles.map((vehicle) => (
                  <TableRow key={vehicle.vehicle_id}>
                    <TableCell className="text-muted sFS16">
                      <Link
                        href={`/vehicle/review/${vehicle.vehicle_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {vehicle.economic_number}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClickExcel: PropTypes.func.isRequired,
};

export default Page;
