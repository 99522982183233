import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";

import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionGetDivisions } from "store/actions/division/getDivisions";
import { LIST_DRIVER } from "store/actions/account/permits";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";
import { actionGetAlerts } from "store/actions/alert/getAlerts";

import Page from "./page";

export function FilterForm({
  open,
  fields,
  filter,
  initialDefaultValues,
  onClose,
  setFiltersAction,
  clearFiltersAction,
  allFieldsFullWidth,
  ...rest
}) {
  const t = useLang();
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });
  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehiclesBrands] = useState([]);
  const [engineBrands, setEngineBrands] = useState([]);
  const [transmissionBrands, setTransmissionBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [tireModels, setTireModels] = useState([]);
  const [revitalizedModels, setRevitalizedTireModels] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const {
    account,
    permits,
    getCorporates,
    getCompanies,
    getSubsidiaries,
    getDivisions,
    getTireSizes,
    getTireApplicationTypes,
    getVehiclesTypes,
    getDrivers,
    getBrands,
    getTireModels,
    setNotification,
    getRevitalizedTireModels,
    setSelectedFilters,
    getAlerts,
  } = rest;

  useEffect(() => {
    if (open) {
      setInitialValues({
        tire_model_id: filter.tire_model_id || "",
        status: filter.status === undefined ? "" : filter.status.toString(),
        with_alerts:
          filter.with_alerts === undefined ? "" : filter.with_alerts.toString(),
        alert_code: filter.alert_code ? filter.alert_code.split(",") : [],
        alert_tire_code: filter.alert_tire_code
          ? filter.alert_tire_code.split(",")
          : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        date_review_from: filter.date_review_from || "",
        date_review_to: filter.date_review_to || "",
        depth: filter.depth || "",
        maximum_pressure: filter.maximum_pressure || "",
        number_layers: filter.number_layers || "",
        revitalized_tire_model_id: filter.revitalized_tire_model_id || "",
        approved:
          filter.approved === undefined ? "" : filter.approved.toString(),
        brand_type: filter.brandType ? filter.brandType.split(",") : [],
        brands: filter.brands ? filter.brands.toString() : "",
        tire_size: filter.tire_size ? filter.tire_size.split(",") : [],
        tire_application: filter.tire_application
          ? filter.tire_application.split(",")
          : [],
        corporates: filter.corporates ? filter.corporates.split(",") : [],
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        divisions: filter.divisions ? filter.divisions.split(",") : [],
        companies: filter.companies ? filter.companies.split(",") : [],
        vehicle_type_id: filter.vehicle_type_id || "",
        vehicle_brand_id: filter.vehicle_brand_id || "",
        type_of_route: filter.type_of_route || "",
        subsidiary_id: filter.subsidiary_id || "",
        has_odometer:
          filter.has_odometer === undefined
            ? ""
            : filter.has_odometer.toString(),
        with_link_driver: !!filter.with_link_driver,
        driver_id: filter.driver_id || "",
        enrollment: filter.enrollment || "",
        vehicle_engine_brand_id: filter.vehicle_engine_brand_id || "",
        engine_transmission_brand_id: filter.engine_transmission_brand_id || "",
        cylinder_capacity: filter.cylinder_capacity || "",
        transmission_model: filter.transmission_model || "",
        transmission_speeds: filter.transmission_speeds || "",
        vehicle_model: filter.vehicle_model || "",
        vehicle_year: filter.vehicle_year || "",
        review_type: filter.review_type ? filter.review_type.split(",") : [],
        vehicle_type_axle_count: filter.vehicle_type_axle_count || "",
        structure_type: filter.structure_type
          ? filter.structure_type.split(",")
          : [],
        axle_number: filter.axle_number || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getTireModels(
          {
            scope: "tire_model_variation_id,tire_model.name,tire_model.status",
          },
          false
        );
        setTireModels(
          dataTireModels
            .map((model) => ({
              tire_model_id: model.tire_model_variation_id,
              name: model.tire_model.name,
              status: model.tire_model.status,
            }))
            .sort(alphabeticalSort("tire_model_id"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.tireModel) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getCorporates(
            { scope: "corporate_id,name,status" },
            false
          );
        } else if (Array.isArray(account.corporates)) {
          data = account.corporates.map((corporate) => ({
            corporate_id: corporate.corporate.corporate_id,
            name: corporate.corporate.name,
            status: corporate.corporate.status,
          }));
        }
        setCorporates(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.corporates) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getTireSizes({ scope: "tire_size_id,size,status" }, false);

        setTireSizes(data.sort(alphabeticalSort("size")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.tire_size) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );

        setTireApplicationTypes(
          data.sort(alphabeticalSort("tire_application_id"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.tire_application) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getCompanies({ scope: "company_id,name,status" }, false);
        } else if (Array.isArray(account.companies)) {
          data = account.companies.map((company) => ({
            company_id: company.company.company_id,
            name: company.company.name,
            status: company.company.status,
          }));
        }
        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.companies) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && (fields.subsidiaries || fields.subsidiary_id)) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehiclesTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );

        setVehicleTypes(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.vehicle_type_id) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getRevitalizedTireModels(
          { scope: "revitalized_tire_model_id,status" },
          false
        );
        setRevitalizedTireModels(
          data.sort(alphabeticalSort("revitalized_tire_model_id"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.revitalized_tire_model_id) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getBrands(
          {
            scope: "brand_id,brand_type,name,status",
            brandType: "TIRE,VEHICLE,VEHICLE_ENGINE,ENGINE_TRANSMISSION",
          },
          false
        );

        if (fields.vehicle_brand_id) {
          setVehiclesBrands(
            data
              .filter((brand) => brand.brand_type === "VEHICLE")
              .sort(alphabeticalSort("name"))
          );
        }

        if (fields.vehicle_engine_brand_id) {
          setEngineBrands(
            data
              .filter((brand) => brand.brand_type === "VEHICLE_ENGINE")
              .sort(alphabeticalSort("name"))
          );
        }

        if (fields.engine_transmission_brand_id) {
          setTransmissionBrands(
            data
              .filter((brand) => brand.brand_type === "ENGINE_TRANSMISSION")
              .sort(alphabeticalSort("name"))
          );
        }

        if (fields.brands) {
          setBrands(
            data
              .filter((brand) => brand.brand_type === "TIRE")
              .sort(alphabeticalSort("name"))
          );
        }
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (
      open &&
      (fields.vehicle_brand_id ||
        fields.vehicle_engine_brand_id ||
        fields.engine_transmission_brand_id ||
        fields.brands)
    ) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        if (permits.includes(LIST_DRIVER)) {
          data = await getDrivers({ scope: "driver_id,name,status" }, false);

          data = data.map((driver) => {
            return {
              driver_id: driver.driver_id,
              name: `${driver.name}`.trim(),
              status: driver.status,
            };
          });
          setDrivers(data.sort(alphabeticalSort("name")));
        } else {
          setDrivers(data);
        }
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && fields.driver_id) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAlerts(
          {
            scope: "alert_id,colloquial_name,code,alert_type",
            alert_type: "MOUNT,TIRE",
          },
          false
        );

        setAlerts(
          response
            .map((alert) => ({
              ...alert,
              colloquial_name: t(
                `_alert.colloquial_name.${alert.colloquial_name.toLowerCase()}`
              ),
            }))
            .sort(alphabeticalSort("colloquial_name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && fields.alert_code) {
      fetchData();
    }
  }, [open]);

  function handleSelectedFilters(filter) {
    // Faltan:
    // brand_type
    // tire_size
    // tire_application
    // brands
    // corporates
    // companies
    // driver_id
    // review_type

    const humanReadableFilter = {
      status: {
        label: t("_labels.status"),
        value:
          `${filter.status}` === "1"
            ? t("_general.enabled.singular.male")
            : `${filter.status}` === "0"
            ? t("_general.disabled.singular.male")
            : undefined,
      },
      approved: {
        label: t("_labels.approved"),
        value:
          `${filter.approved}` === "1"
            ? t("_labels.yes")
            : `${filter.approved}` === "0"
            ? t("_labels.no")
            : undefined,
      },
      depth: {
        label: t("_labels.depth"),
        value: filter.depth,
      },
      maximum_pressure: {
        label: t("_labels.tire_model_variation.maximum_pressure"),
        value: filter.maximum_pressure,
      },
      date_from: {
        label: t("_labels.date.from"),
        value: filter.date_from,
      },
      date_to: {
        label: t("_labels.date.to"),
        value: filter.date_to,
      },
      number_layers: {
        label: t("_labels.tire_model_variation.number_layers"),
        value: filter.number_layers,
      },
      brand_type: {
        label: t("_labels.brand_type.label.singular"),
        value: filter.brand_type,
      },
      tire_size: {
        label: t("_labels.tire_size.label.singular"),
        value: filter.tire_size,
      },
      tire_application: {
        label: t("_labels.tire_application.label.singular"),
        value: filter.tire_application,
      },
      tireModel: {
        label: t("_labels.tire_model.model_name"),
        value: filter.tire_model_id,
      },
      brands: {
        label: t("_labels.brand_field.label"),
        value: filter.brands,
      },
      corporates: {
        label: t("_labels.corporate.plural"),
        value: filter.corporates,
      },
      subsidiaries: {
        label: t("_labels.subsidiary.plural"),
        value: filter.subsidiaries
          ?.split(",")
          .map(
            (subsidiary) =>
              subsidiaries.find((e) => `${e.subsidiary_id}` === `${subsidiary}`)
                ?.name
          )
          .join(", "),
      },
      divisions: {
        label: t("_labels.division.plural"),
        value: filter.divisions
          ?.split(",")
          .map((division) => {
            const d = divisions.find(
              (e) => `${e.division_id}` === `${division}`
            );

            if (division == "NO_DIVISION") {
              return t("_labels.no_division");
            }

            return `${d.name} - ${d.subsidiary.name}`;
          })
          .join(", "),
      },
      companies: {
        label: t("_labels.company.plural"),
        value: filter.companies,
      },
      subsidiary_id: {
        label: t("_labels.subsidiary.singular"),
        value: subsidiaries.find(
          (e) => `${e.subsidiary_id}` === `${filter.subsidiary_id}`
        )?.name,
      },
      vehicle_type_id: {
        label: t("_labels.vehicle_type.label"),
        value: vehicleTypes.find(
          (e) => `${e.vehicle_type_id}` === `${filter.vehicle_type_id}`
        )?.name,
      },
      revitalized_tire_model_id: {
        label: t("_labels.revitalized_tire_model_field.label"),
        value: filter.revitalized_tire_model_id,
      },
      vehicle_brand_id: {
        label: t("_labels.vehicle_brand.label"),
        value: vehicleBrands.find(
          (e) => `${e.brand_id}` === `${filter.vehicle_brand_id}`
        )?.name,
      },
      type_of_route: {
        label: t("_labels.type_of_route.label"),
        value:
          filter.type_of_route === "FOREIGN"
            ? t("_labels.type_of_route.options.foreign")
            : filter.type_of_route === "LOCAL"
            ? t("_labels.type_of_route.options.local")
            : undefined,
      },
      has_odometer: {
        label: t("_labels.has_odometer.simple"),
        value:
          `${filter.has_odometer}` === "1"
            ? t("_labels.yes")
            : `${filter.has_odometer}` === "0"
            ? t("_labels.no")
            : undefined,
      },
      with_link_driver: {
        label: t("_labels.with_link_driver"),
        value:
          `${filter.with_link_driver}` === "0"
            ? t("_labels.yes")
            : `${filter.with_link_driver}` === "1"
            ? t("_labels.no")
            : undefined,
      },
      driver_id: {
        label: t("_labels.driver.singular"),
        value: filter.driver_id,
      },
      enrollment: {
        label: t("_labels.auto_enrollment"),
        value: filter.enrollment,
      },
      vehicle_engine_brand_id: {
        label: t("_labels.engine_brand.label"),
        value: engineBrands.find(
          (e) => `${e.brand_id}` === `${filter.vehicle_engine_brand_id}`
        )?.name,
      },
      engine_transmission_brand_id: {
        label: t("_labels.transmission_brand.label"),
        value: transmissionBrands.find(
          (e) => `${e.brand_id}` === `${filter.engine_transmission_brand_id}`
        )?.name,
      },
      cylinder_capacity: {
        label: t("_labels.cylinder"),
        value: filter.cylinder_capacity,
      },
      transmission_model: {
        label: t("_labels.transmission_model"),
        value: filter.transmission_model,
      },
      transmission_speeds: {
        label: t("_labels.transmission_speeds"),
        value: filter.transmission_speeds,
      },
      vehicle_model: {
        label: t("_labels.vehicle_model"),
        value: filter.vehicle_model,
      },
      vehicle_year: {
        label: t("_labels.vehicle_year"),
        value: filter.vehicle_year,
      },
      review_type: {
        label: t("_labels.review_type.label"),
        value: filter.review_type,
      },
      vehicle_type_axle_count: {
        label: t("_labels.vehicle_type_axle_count"),
        value: filter.vehicle_type_axle_count,
      },
      structure_type: {
        label: t("_labels.structure_type.label.singular"),
        value: filter.structure_type,
      },
      axle_number: {
        label: t("_labels.axle_number"),
        value: filter.axle_number,
      },
    };
    if (setSelectedFilters != undefined) {
      setSelectedFilters(humanReadableFilter);
    }
  }

  function handleClean() {
    clearFiltersAction();
    handleSelectedFilters({});
    onClose();
  }

  function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (!!fields.status && values.status !== "") {
        params.status = parseInt(values.status);
      }

      if (!!fields.with_alerts && values.with_alerts !== "") {
        params.with_alerts = Number(values.with_alerts);
      }

      if (!!fields.alert_code) {
        params.alert_code =
          values.alert_code.length > 0 ? values.alert_code.join() : undefined;
      }

      if (!!fields.alert_tire_code) {
        params.alert_tire_code =
          values.alert_tire_code.length > 0
            ? values.alert_tire_code.join()
            : undefined;
      }

      if (!!fields.approved && values.approved !== "") {
        params.approved = parseInt(values.approved);
      }

      if (fields.date && values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      if (
        fields.date_review &&
        values.date_review_from &&
        values.date_review_to
      ) {
        params.date_review_from = values.date_review_from;
        params.date_review_to = values.date_review_to;
      }

      if (fields.depth && values.depth) {
        params.depth = values.depth;
      }
      if (fields.maximum_pressure && values.maximum_pressure) {
        params.maximum_pressure = values.maximum_pressure;
      }

      if (fields.number_layers && values.number_layers) {
        params.number_layers = values.number_layers;
      }

      if (fields.brand_type && values.brand_type.length > 0) {
        params.brandType = values.brand_type.join();
      }

      if (fields.tire_size && values.tire_size.length > 0) {
        params.tire_size = values.tire_size.join();
      }

      if (fields.tire_application && values.tire_application.length > 0) {
        params.tire_application = values.tire_application.join();
      }

      if (fields.tireModel && values.tire_model_id !== "") {
        params.tire_model_id = values.tire_model_id;
      }

      if (fields.brands) {
        params.brands = values.brands;
      }

      if (fields.corporates && values.corporates.length > 0) {
        params.corporates = values.corporates.join();
      }

      if (fields.subsidiaries && values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
      }
      if (fields.companies && values.companies.length > 0) {
        params.companies = values.companies.join();
      }
      if (fields.divisions && values.divisions.length > 0) {
        params.divisions = values.divisions.join();
      }

      if (fields.subsidiary_id && values.subsidiary_id !== "") {
        params.subsidiary_id = values.subsidiary_id;
      }

      if (fields.vehicle_type_id && values.vehicle_type_id !== "") {
        params.vehicle_type_id = values.vehicle_type_id;
      }

      if (
        fields.revitalized_tire_model_id &&
        values.revitalized_tire_model_id !== ""
      ) {
        params.revitalized_tire_model_id = values.revitalized_tire_model_id;
      }

      if (fields.vehicle_brand_id && values.vehicle_brand_id !== "") {
        params.vehicle_brand_id = values.vehicle_brand_id;
      }

      if (fields.type_of_route && values.type_of_route !== "") {
        params.type_of_route = values.type_of_route;
      }

      if (!!fields.has_odometer && values.has_odometer !== "") {
        params.has_odometer = parseInt(values.has_odometer);
      }

      if (fields.with_link_driver && values.with_link_driver) {
        params.with_link_driver = values.with_link_driver ? 0 : 1;
      }

      if (fields.driver_id && values.driver_id !== "") {
        params.driver_id = values.driver_id;
      }

      if (fields.enrollment && values.enrollment !== "") {
        params.enrollment = values.enrollment;
      }

      if (
        fields.vehicle_engine_brand_id &&
        values.vehicle_engine_brand_id !== ""
      ) {
        params.vehicle_engine_brand_id = values.vehicle_engine_brand_id;
      }

      if (
        fields.engine_transmission_brand_id &&
        values.engine_transmission_brand_id !== ""
      ) {
        params.engine_transmission_brand_id =
          values.engine_transmission_brand_id;
      }

      if (fields.cylinder_capacity && values.cylinder_capacity !== "") {
        params.cylinder_capacity = values.cylinder_capacity;
      }

      if (fields.transmission_model && values.transmission_model !== "") {
        params.transmission_model = values.transmission_model;
      }

      if (fields.transmission_speeds && values.transmission_speeds !== "") {
        params.transmission_speeds = values.transmission_speeds;
      }

      if (fields.vehicle_model && values.vehicle_model !== "") {
        params.vehicle_model = values.vehicle_model;
      }

      if (fields.vehicle_year && values.vehicle_year !== "") {
        params.vehicle_year = values.vehicle_year;
      }

      if (fields.review_type && values.review_type.length > 0) {
        params.review_type = values.review_type.join();
      }

      if (fields.vehicle_type_axle_count && values.vehicle_type_axle_count) {
        params.vehicle_type_axle_count = values.vehicle_type_axle_count;
      }

      if (fields.structure_type && values.structure_type.length > 0) {
        params.structure_type = values.structure_type.join();
      }

      if (fields.axle_number && values.axle_number) {
        params.axle_number = values.axle_number;
      }
      setFiltersAction(params);
      handleSelectedFilters(params);

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      corporates={corporates}
      subsidiaries={subsidiaries}
      companies={companies}
      divisions={divisions}
      tireSizes={tireSizes}
      tireApplicationTypes={tireApplicationTypes}
      tireModels={tireModels}
      vehicleTypes={vehicleTypes}
      vehicleBrands={vehicleBrands}
      brands={brands}
      drivers={drivers}
      engineBrands={engineBrands}
      transmissionBrands={transmissionBrands}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      fields={fields}
      allFieldsFullWidth={allFieldsFullWidth}
      revitalizedModels={revitalizedModels}
      t={t}
      alerts={alerts}
      getDivisions={getDivisions}
      setDivisions={setDivisions}
      setNotification={setNotification}
    />
  );
}

FilterForm.propTypes = {
  open: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  initialDefaultValues: PropTypes.object.isRequired,
  allFieldsFullWidth: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setFiltersAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  getCorporates: actionGetCorporates(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getDivisions: actionGetDivisions(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
  getBrands: actionGetBrands(dispatch),
  getDrivers: actionGetDrivers(dispatch),
  setNotification: actionSetNotification(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
  getAlerts: actionGetAlerts(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
