import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetReviewPerformanceReport } from "store/actions/reports/review_performance/getReviewPerformanceReport";
import { actionClearReviewPerformanceReport } from "store/actions/reports/review_performance/clearReviewPerformanceReport";
import { actionClearReviewPerformanceFilters } from "store/actions/reports/review_performance/clearReviewPerformanceFilters";
import { actionSetReviewPerformanceFilters } from "store/actions/reports/review_performance/setReviewPerformanceFilters";
import { actionGenerateReviewPerformanceExcel } from "store/actions/reports/review_performance/generateExcelReviewPerformance";
import { actionGetVehiclesNoReview } from "store/actions/reports/review_performance/getVehiclesNoReview";
import { actionClearVehiclesNoReview } from "store/actions/reports/review_performance/clearVehiclesNoReview";
import { actionSetSelectedReviewPerformanceFilters } from "store/actions/reports/review_performance/setSelectedReviewPerformanceFilters";

import { useLang } from "hooks/lang";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";

function ReviewPerformance({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const query = useLocation();
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedCreatedAt, setSelectedCreatedAt] = useState("");
  const {
    account,
    workArea,
    report,
    vehicles,
    filter,
    selectedFilter,
    setNotification,
    getReviewPerformanceReport,
    clearReviewPerformanceReport,
    setReviewPerformanceFilters,
    clearReviewPerformanceFilters,
    setSelectedFilters,
    generateExcel,
    clearVehiclesNoReview,
    getVehiclesNoReview,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getReviewPerformanceReport({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }

    return () => {
      clearReviewPerformanceReport();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [report.data]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilter = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };

    const params = new URLSearchParams(query.search);

    if (params.has("date")) {
      const date = moment(params.get("date"));
      const startDate = date.startOf("month").format("YYYY-MM-DD");
      const endDate = date.endOf("month").format("YYYY-MM-DD");

      filter.date_from = startDate;
      filter.date_to = endDate;
      selectedFilter.date_from = startDate;
      selectedFilter.date_to = endDate;

      if (params.has("subsidiary")) {
        const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
        const subsidiary = getSubsidiaries(account.subsidiaries)
          .filter(
            (subsidiary) =>
              subsidiary.company_id === subsidiaries[0].subsidiary.company_id
          )
          .find((subsidiary) => subsidiary.name === params.get("subsidiary"));

        if (subsidiary) {
          filter.subsidiaries = subsidiary.subsidiary_id.toString();
          selectedFilter.subsidiaries = subsidiary.name;
        }
      }
    }

    setReviewPerformanceFilters(filter);
    setSelectedFilters(selectedFilter);

    return () => {
      clearReviewPerformanceFilters();
      clearVehiclesNoReview();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateReviewPerformanceExcel() {
    try {
      await generateExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleClickVehicle(vehicle) {
    history.push(`/vehicleReview/${vehicle.vehicle_id}/history`);
  }

  async function handleClickVehiclesNoReview(id, subsidiaryId) {
    try {
      clearVehiclesNoReview();
      await getVehiclesNoReview({ ...filter }, id, subsidiaryId);
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      data={report}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      selectedVehiclesNoReview={vehicles}
      handleClickVehiclesNoReview={handleClickVehiclesNoReview}
      handleGenerateReviewPerformanceExcel={
        handleGenerateReviewPerformanceExcel
      }
      handleClickVehicle={handleClickVehicle}
      selectedCreatedAt={selectedCreatedAt}
      setSelectedCreatedAt={setSelectedCreatedAt}
    />
  );
}
ReviewPerformance.propTypes = {};

const mapStateToProps = (state) => ({
  report: state.Reports.ReviewPerformance.report,
  vehicles: state.Reports.ReviewPerformance.vehicles,
  filter: state.Reports.ReviewPerformance.filter,
  selectedFilter: state.Reports.ReviewPerformance.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReviewPerformanceReport: actionGetReviewPerformanceReport(dispatch),
  clearReviewPerformanceReport: actionClearReviewPerformanceReport(dispatch),
  clearReviewPerformanceFilters: actionClearReviewPerformanceFilters(dispatch),
  setReviewPerformanceFilters: actionSetReviewPerformanceFilters(dispatch),
  generateExcel: actionGenerateReviewPerformanceExcel(dispatch),
  getVehiclesNoReview: actionGetVehiclesNoReview(dispatch),
  clearVehiclesNoReview: actionClearVehiclesNoReview(dispatch),
  setSelectedFilters: actionSetSelectedReviewPerformanceFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPerformance);

function getSubsidiaries(assignedSubsidiaries) {
  return assignedSubsidiaries.map(
    (assignedSubsidiary) => assignedSubsidiary.subsidiary
  );
}
