import React from "react";
import classnames from "classnames";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import TireHistoryTabs from "components/organisms/tire/TireHistoryTabs";

function Page() {
  return (
    <TireHistoryTabs
      optionsAppBar={
        <>
          <Button className={classnames("bw")} variant="icon">
            <VisibilityIcon />
          </Button>
          <Button className={classnames("bw")} variant="icon">
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    ></TireHistoryTabs>
  );
}

Page.propTypes = {};

export default Page;
