import React from "react";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";
import Page from "./page";

function ImageDialog({ ...props }) {
  const t = useLang();
  return <Page t={t} {...props} />;
}

ImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ImageDialog;
