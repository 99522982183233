import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Formik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LinkedItemPanel from "components/molecules/general/LinkedItemPanel";

import Form from "./Form";

function Page({
  t,
  open,
  className,
  subsidiary,
  division,
  initialValues,
  initialErrors,
  showLinkTireWithDivisionPermit,
  linkTireWithDivisionPermit,
  handleLink,
  handleClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <LinkedItemPanel
        label={t("_labels.division_name")}
        title={t("_labels.division.singular")}
        className={classnames(className)}
        text={
          !showLinkTireWithDivisionPermit
            ? t("_permits.show.link.tire_with_division")
            : !division
            ? t("_labels.unassigned_division")
            : division.division.name
        }
        actionButtonName={
          !!division ? t("_buttons.change") : t("_buttons.link")
        }
        actionButtonTooltip={
          !division
            ? !linkTireWithDivisionPermit
              ? t("_permits.link.tire_with_division")
              : !subsidiary
              ? t("_labels.unassigned_subsidiary")
              : ""
            : ""
        }
        actionButtonDisabled={
          !showLinkTireWithDivisionPermit ||
          (!division && (!linkTireWithDivisionPermit || !subsidiary))
        }
        onView={() => {}}
        viewButtonDisabled={!division}
        onAction={!!division ? () => {} : handleLink}
      />

      <DialogMediaQuery
        size={"sm"}
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>{t("_titles.link.division")}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => (
              <Form {...p} subsidiary={subsidiary} onClose={handleClose} />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  division: PropTypes.object,
  subsidiary: PropTypes.object,
  open: PropTypes.bool.isRequired,
  showLinkTireWithDivisionPermit: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  linkTireWithDivisionPermit: PropTypes.bool.isRequired,
  handleLink: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
