import {
  GET_VEHICLE_GPS,
  CLEAR_VEHICLE_GPS,
  ASSIGN_VEHICLE_TO_GPS,
} from "store/actions";

const initialState = {
  gps: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_GPS:
    case ASSIGN_VEHICLE_TO_GPS:
      return {
        ...state,
        gps: action.payload,
      };

    case CLEAR_VEHICLE_GPS:
      return {
        ...state,
        gps: initialState.gps,
      };

    default:
      return state;
  }
}
