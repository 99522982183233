import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionSetNotification } from "store/actions/general/notification";
import { actionLinkCommissionedVehicleDriver } from "store/actions/commissioned/vehicle_driver/linkVehicleDriver";
import { actionGetCommissionedDrivers } from "store/actions/commissioned/driver/getCommissionedDrivers";
import { actionGetCommissionedVehicles } from "store/actions/commissioned/vehicle/getCommissionedVehicles";
import { actionUpdateCommissionedVehicleDriver } from "store/actions/commissioned/vehicle_driver/updateLinkVehicleDriver";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function LinkVehicleDriverForm({ ...rest }) {
  const t = useLang();

  const {
    open,
    filter,
    onClose,
    commissionedVehicle,
    commissionedDriver,
    setNotification,
    getCommissionedDrivers,
    getCommissionedVehicles,
    linkVehicleDriver,
    updateVehicleDriver,
  } = rest;
  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
    resetFormik,
  } = useFormik({
    initialValues: {
      commissioned_driver_id: null,
    },
  });

  const [commissionedDrivers, setCommissionedDrivers] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCommissionedDrivers(
          { status: 1, scope: "commissioned_driver_id,name" },
          false
        );

        setCommissionedDrivers(data.sort(alphabeticalSort("name")));
        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded && !!commissionedDriver) {
      setInitialValues({
        commissioned_driver_id: commissionedDrivers.find(
          (c) =>
            c.commissioned_driver_id ==
            commissionedDriver.commissioned_driver_id
        ),
      });
    }
  }, [open, loaded, commissionedDriver]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {};

      if (values.commissioned_driver_id) {
        fields.commissioned_driver_id =
          values.commissioned_driver_id.commissioned_driver_id;
      }

      if (!!commissionedVehicle) {
        fields.commissioned_vehicle_id =
          commissionedVehicle.commissioned_vehicle_id;
        await linkVehicleDriver(fields);

        setNotification({
          message: t("_messages.linked.vehicle_with_driver"),
        });
      } else {
        fields.commissioned_vehicle_id =
          commissionedDriver.commissioned_vehicle_id;
        await updateVehicleDriver(
          commissionedDriver.commissioned_vehicle_driver_id,
          fields
        );

        setNotification({
          message: t("_messages.updated.vehicle"),
        });
      }

      resetForm();
      setSubmitting(false);
      getCommissionedVehicles({ ...filter });
      onClose({ closeDialog: true });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  if (!open) {
    return null;
  }

  return (
    <Page
      t={t}
      open={open}
      commissionedVehicle={commissionedVehicle}
      commissionedDrivers={commissionedDrivers}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkVehicleDriverForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  commissionedVehicle: PropTypes.object,
  commissionedDriver: PropTypes.object,
};

const mapStateToProps = (state) => ({
  filter: state.Commissioned.Vehicle.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCommissionedDrivers: actionGetCommissionedDrivers(dispatch),
  getCommissionedVehicles: actionGetCommissionedVehicles(dispatch),
  linkVehicleDriver: actionLinkCommissionedVehicleDriver(dispatch),
  updateVehicleDriver: actionUpdateCommissionedVehicleDriver(dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkVehicleDriverForm);
