import React from "react";
import PropTypes from "prop-types";

import Table from "components/organisms/general/Table";
import List from "components/organisms/general/List";

function Page({ isMobile, rows, headers, ...rest }) {
  return (
    <>
      {!isMobile ? (
        <Table rows={rows} headers={headers} {...rest} />
      ) : (
        <List rows={rows} headers={headers} />
      )}
    </>
  );
}

Page.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  size: PropTypes.string,
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default Page;
