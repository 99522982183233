import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetAlertReport =
  (dispatch) =>
  async ({
    corporate_id: corporateId,
    companies,
    subsidiaries,
    date_from: dateFrom,
    date_to: dateTo,
    alertTypes,
    rankingAlert,
    priority,
    codes,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/alert`,
        params: {
          corporate_id: corporateId,
          companies,
          subsidiaries,
          date_from: dateFrom,
          date_to: dateTo,
          alert_types: alertTypes,
          ranking_alert: rankingAlert,
          priority,
          alert_codes: codes,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
