import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Field, Form } from "formik";

import { TextField } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";

import css from "./index.module.scss";

export function Page({
  t,
  values,
  isValid,
  isUpdate,
  tireBrands,
  tireUseTypes,
  isSubmitting,
  tireApplicationTypes,
  updateTireModelPermit,
  deleteTireModelPermit,
  onClose,
  handleSubmit,
  handleDelete,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        name="data_sheet"
        placeholder={t("placeholder")}
        component={FileDragAndDropField}
        className={classnames(css.drop)}
        disabled={isUpdate && !updateTireModelPermit}
      />
      <div className={classnames(css.container_box)}>
        <Field
          required
          fullWidth
          name="name"
          variant="filled"
          component={TextField}
          label={t("_labels.tire_model.model_name")}
          className={classnames(css.field)}
          disabled={isUpdate && !updateTireModelPermit}
        />

        <Field
          required
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          component={SelectField}
          label={t("_labels.brand")}
          name={"brand_id"}
          className={classnames(css.field)}
          defaultText={t("brand.default")}
          defaultValue={
            tireBrands.some((brand) => brand.brand_id === values.brand_id)
              ? null
              : values.brand_id
          }
          disabled={isUpdate && !updateTireModelPermit}
        >
          {tireBrands.map((brand, index) => {
            return (
              <MenuItem
                key={index}
                value={brand.brand_id.toString()}
                disabled={!brand.status}
              >
                {brand.name}
              </MenuItem>
            );
          })}
        </Field>

        <Field
          required
          multiple
          variant="filled"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          component={SelectField}
          name={"tire_model_application"}
          className={classnames(css.field)}
          label={t("_labels.tire_application.label.plural")}
          defaultText={t("_labels.tire_application.default.plural")}
          helperText={t("_labels.tire_application.helper_text")}
          disabled={isUpdate && !updateTireModelPermit}
        >
          {tireApplicationTypes.map((application, index) => {
            return (
              <MenuItem
                key={index}
                value={application.tire_application_id}
                disabled={!application.status}
              >
                {t(
                  `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
                )}
              </MenuItem>
            );
          })}
        </Field>
      </div>

      <Field
        required
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("use.label")}
        name={"tire_model_use"}
        className={classnames(css.normal_field)}
        helperText={t("use.helper_text")}
        disabled={isUpdate && !updateTireModelPermit}
      >
        {tireUseTypes.map((use, index) => {
          return (
            <MenuItem
              key={index}
              value={use.tire_use_id}
              disabled={!use.status}
            >
              {t(`_labels.tire_use.options.${use.tire_use_id.toLowerCase()}`)}
            </MenuItem>
          );
        })}
      </Field>

      <div className={classnames(css.action)}>
        <div className={classnames(css.delete)}>
          {isUpdate && (
            <Button
              color="secondary"
              type="button"
              disabled={!deleteTireModelPermit}
              tooltip={!deleteTireModelPermit ? t("permits.delete") : ""}
              onClick={handleDelete}
            >
              {t("_buttons.delete")}
            </Button>
          )}
        </div>
        <div className={classnames(css.buttons_right)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={
              isSubmitting || !isValid || (isUpdate && !updateTireModelPermit)
            }
            tooltip={
              isUpdate && !updateTireModelPermit ? t("permits.update") : ""
            }
          >
            {!isUpdate ? t("_buttons.save") : t("_buttons.update")}
          </Button>
        </div>
      </div>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  tireBrands: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  tireUseTypes: PropTypes.array.isRequired,
  updateTireModelPermit: PropTypes.bool.isRequired,
  deleteTireModelPermit: PropTypes.bool.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Page;
