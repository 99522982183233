import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ t, handleSubmit, isSubmitting, isValid }) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        fullWidth
        required
        component={TextField}
        variant="filled"
        margin="normal"
        name="email"
        type="email"
        label={t("_labels.email")}
      />

      <div className={classnames(css.action)}>
        <Button
          disabled={isSubmitting || !isValid}
          type="submit"
          variant="contained"
          color="secondary"
          className={classnames(css.button)}
          style={{
            flex: 1,
          }}
        >
          {t("enter_button")}
        </Button>
        <div className={classnames(css.login)}>
          <Link to="/">
            <Button
              component="button"
              variant="contained"
              color="secondary"
              className={classnames(css.button)}
            >
              {t("back_button")}
            </Button>
          </Link>
        </div>
      </div>
    </Form>
  );
}

Page.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default Page;
