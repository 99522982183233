import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchInput from "components/molecules/general/SearchInput";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import AddIcon from "@material-ui/icons/Add";

import PressurePolicyForm from "components/organisms/subsidiary/PressurePolicyForm";
import PressurePolicyVehicleTypeForm from "components/organisms/subsidiary/PressurePolicyVehicleTypeForm";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import TableMultiple from "components/organisms/general/TableMultiple";
import Pagination from "components/molecules/general/Pagination";

import PressurePolicyFilterForm from "components/organisms/subsidiary/PressurePolicyFilterForm";
import MultiplePressurePolicyForm from "components/organisms/subsidiary/MultiplePressurePolicyForm";

import css from "./index.module.scss";

function page({
  t,
  search,
  pressurePoliciesData,
  subsidiaryId,
  vehicleId,
  policyId,
  openForm,
  openVehicleTypeForm,
  openFilter,
  updateVehiclePressurePolicyPermit,
  deleteVehiclePressurePolicyPermit,
  createVehiclePressurePolicyPermit,
  filter,
  handleSearch,
  handleEdit,
  handleOpenForm,
  handleOpenVehicleTypeForm,
  handleCloseVehicleTypeForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  handleChangePage,
  openMultiple,
  vehicleIds,
  handleOpenMultiple,
  handleCloseMultiple,
}) {
  return (
    <SubsidiaryTabs
      className={classnames({
        [css.empty]: pressurePoliciesData.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            onClick={handleOpenFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
      filter={filter}
    >
      {pressurePoliciesData.data.length > 0 ? (
        <>
          <div className={classnames(css.button)}>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOpenVehicleTypeForm}
            >
              {t("_buttons.register_by_vehicle_type")}
            </Button>
          </div>
          <div className={classnames(css.container)}>
            <TableMultiple
              headers={[
                { label: t("_general.vehicles"), colspan: 6 },
                { label: t("_labels.axle.plural"), colspan: 4 },
              ]}
              rows={pressurePoliciesData.data.map((vehicle) => ({
                id: vehicle.vehicle_id,
                subsidiary: vehicle.subsidiary.name,
                division:
                  vehicle.division.length > 0 ? vehicle.division[0].name : "",
                brand: vehicle.vehicle_brand.name,
                economic_number: vehicle.economic_number,
                vehicle_type: vehicle.vehicle_type.name,
                add: (
                  <Button
                    variant="icon"
                    color="secondary"
                    tooltip={
                      !createVehiclePressurePolicyPermit
                        ? t("_permits.create.pressure_policy")
                        : ""
                    }
                    disabled={!createVehiclePressurePolicyPermit}
                    onClick={() => handleOpenForm(vehicle.vehicle_id)}
                  >
                    <AddIcon />
                  </Button>
                ),
                multiple:
                  vehicle.vehicle_pressure_policy.length > 0
                    ? vehicle.vehicle_pressure_policy.map((policy) => ({
                        id: policy.vehicle_pressure_policy_id,
                        axle: t(
                          `_labels.axle_field.options.${policy.axle_type.toLowerCase()}`
                        ),
                        recommended_pressure: `${policy.recommended_pressure} psi`,
                        tolerance: `${policy.tolerance}%`,
                        edit: {
                          tooltipPermit: !updateVehiclePressurePolicyPermit
                            ? t("_permits.update.pressure_policy")
                            : "",
                          onEdit: () =>
                            handleEdit(
                              vehicle.vehicle_id,
                              policy.vehicle_pressure_policy_id
                            ),
                        },
                        delete: {
                          tooltipPermit: !deleteVehiclePressurePolicyPermit
                            ? t("_permits.delete.pressure_policy")
                            : "",
                          onDelete: () =>
                            handleDelete(
                              subsidiaryId,
                              vehicle.vehicle_id,
                              policy.vehicle_pressure_policy_id
                            ),
                        },
                      }))
                    : [
                        {
                          id: "",
                          axle: "",
                          recommended_pressure: "",
                          tolerance: "",
                        },
                      ],
              }))}
              propertys={[
                "subsidiary",
                "division",
                "brand",
                "economic_number",
                "vehicle_type",
                "add",
                "multiple",
              ]}
              nestedPropertys={["axle", "recommended_pressure", "tolerance"]}
              nestedHeaders={[
                t("_labels.subsidiary.singular"),
                t("_labels.division.singular"),
                t("_labels.brand"),
                t("_labels.economic_number"),
                t("_labels.vehicle_type.label"),
                "",
                t("_labels.axle.plural"),
                t("_labels.tire_model_variation.recommended_pressure"),
                t("_labels.tire_model_variation.tolerance"),
                "",
              ]}
              multipleProperty={"multiple"}
              selectable={createVehiclePressurePolicyPermit}
              selectAction={handleOpenMultiple}
              selectActionButton={t("_general.set")}
              totalRows={pressurePoliciesData.total}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={pressurePoliciesData.current_page}
              totalPages={pressurePoliciesData.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_pressure_policies")} />
      )}

      <PressurePolicyForm
        open={openForm}
        onClose={handleCloseForm}
        vehicleId={vehicleId}
        pressurePolicyId={policyId}
        subsidiaryId={subsidiaryId}
      />

      <PressurePolicyVehicleTypeForm
        open={openVehicleTypeForm}
        onClose={handleCloseVehicleTypeForm}
        vehicleId={vehicleId}
        pressurePolicyId={policyId}
        subsidiaryId={subsidiaryId}
      />

      <PressurePolicyFilterForm open={openFilter} onClose={handleCloseFilter} />

      <MultiplePressurePolicyForm
        open={openMultiple}
        onClose={handleCloseMultiple}
        subsidiaryId={subsidiaryId}
        vehicleIds={vehicleIds}
      />
    </SubsidiaryTabs>
  );
}

page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  pressurePoliciesData: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired,
  openVehicleTypeForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleOpenVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseVehicleTypeForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  updateVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  deleteVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  createVehiclePressurePolicyPermit: PropTypes.bool.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openMultiple: PropTypes.bool.isRequired,
  vehicleIds: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenMultiple: PropTypes.func.isRequired,
  handleCloseMultiple: PropTypes.func.isRequired,
};

export default page;
