import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireReviewHistory } from "store/actions/tire/getTireReviewHistory";
import { actionClearTireReviewHistory } from "store/actions/tire/clearTireReviewHistory";
import { actionClearTireReviewHistoryFilters } from "store/actions/tire/clearTireReviewHistoryFilters";

import { useLang } from "hooks/lang";
import Page from "./page";

export function TireReviewHistory({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    tire,
    tires,
    filter,
    setNotification,
    getTireReviewHistory,
    clearTireReviewHistory,
    clearTireReviewHistoryFilters,
  } = rest;
  const [openFilter, setOpenFilter] = useState(false);

  const sortedTires = Array.isArray(tires)
    ? tires.sort((a, b) => {
        return new Date(b.tire_review_date) - new Date(a.tire_review_date);
      })
    : [];

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireReviewHistory(id, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTireReviewHistory();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireReviewHistoryFilters();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [tires.data]);

  async function onChangePage(page) {
    try {
      await getTireReviewHistory(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      tires={sortedTires}
      tire={tire}
      openFilter={openFilter}
      onChangePage={onChangePage}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}

TireReviewHistory.propTypes = {};

const mapStateToProps = (state) => ({
  tires: state.Tire.ReviewHistory.tires,
  filter: state.Tire.ReviewHistory.filter,
  tire: state.Tire.Tire.tire,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireReviewHistory: actionGetTireReviewHistory(dispatch),
  clearTireReviewHistory: actionClearTireReviewHistory(dispatch),
  clearTireReviewHistoryFilters: actionClearTireReviewHistoryFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireReviewHistory);
