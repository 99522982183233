import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "components/molecules/general/Button";

function Page({ t, selectActionButton, numSelected, classes, handleClick }) {
  return (
    <Toolbar
      className={classnames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <Typography
          className={classnames(classes.title)}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${numSelected} ${
            numSelected > 1
              ? t("_labels.selected.plural.male")
              : t("_labels.selected.singular.male")
          }`}
        </Typography>
      )}

      {numSelected > 0 &&
        (selectActionButton ? (
          <Button variant="contained" color="secondary" onClick={handleClick}>
            {selectActionButton}
          </Button>
        ) : (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={handleClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ))}
    </Toolbar>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  selectActionButton: PropTypes.node,
};

export default Page;
