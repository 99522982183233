import {
  SET_RENEWABILITY_INDEX_FILTERS,
  CLEAR_RENEWABILITY_INDEX_FILTERS,
  SET_SELECTED_RENEWABILITY_INDEX_FILTERS,
  GET_RENEWABILITY_INDEX_REPORT,
  CLEAR_RENEWABILITY_INDEX_REPORT,
} from "store/actions";

const initialState = {
  report: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {
    sort_by: "index",
    order: "DESC",
  },
  selected_filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RENEWABILITY_INDEX_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_RENEWABILITY_INDEX_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
        selected_filter: { ...initialState.selected_filter },
      };

    case SET_SELECTED_RENEWABILITY_INDEX_FILTERS:
      const selectedFilter = {
        ...state.selected_filter,
        ...action.payload,
      };

      for (let key in selectedFilter) {
        if (
          selectedFilter[key] === undefined ||
          selectedFilter[key] === null ||
          selectedFilter[key] === ""
        ) {
          delete selectedFilter[key];
        }
      }

      return {
        ...state,
        selected_filter: { ...selectedFilter },
      };

    case GET_RENEWABILITY_INDEX_REPORT:
      return {
        ...state,
        report: action.payload,
      };

    case CLEAR_RENEWABILITY_INDEX_REPORT:
      return {
        ...state,
        report: { ...initialState.report },
      };

    default:
      return state;
  }
}
