import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import DialogActions from "@material-ui/core/DialogActions";

import css from "./index.module.scss";

function Page({ t, open, gpsGeofence, onClose }) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{t("title")}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <div className={classnames(css.imei)}>
              <LabelAndText
                fullWidth
                gutterBottom
                text={gpsGeofence.imei}
                label={t("imei")}
              />
            </div>
            <div className={classnames(css.date)}>
              <LabelAndText
                fullWidth
                gutterBottom
                text={gpsGeofence.date}
                label={t("date")}
              />
            </div>
          </div>

          <div className={classnames(css.horizontal)}>
            <LabelAndText
              fullWidth
              gutterBottom
              text={`${gpsGeofence.odometer} km`}
              label={t("odometer")}
            />

            <LabelAndText
              fullWidth
              gutterBottom
              text={gpsGeofence.status ? t("inside") : t("outside")}
              label={t("status")}
            />

            <LabelAndText
              fullWidth
              gutterBottom
              text={gpsGeofence.area}
              label={t("area")}
            />
          </div>

          <LabelAndText
            fullWidth
            gutterBottom
            text={gpsGeofence.created_by}
            label={t("_labels.created_by")}
          />

          {gpsGeofence.updated_by && (
            <LabelAndText
              fullWidth
              gutterBottom
              text={gpsGeofence.updated_by}
              label={t("_labels.updated_by")}
            />
          )}
        </div>
        <DialogActions className={classnames(css.action)}>
          <Button color="secondary" onClick={onClose}>
            {t("_buttons.close")}
          </Button>
        </DialogActions>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  gpsGeofence: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
