import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireWarehousesHistory } from "store/actions/tire/warehouse/history/getTireWarehousesHistory";
import { actionClearTireWarehousesHistory } from "store/actions/tire/warehouse/history/clearTireWarehousesHistory";

import {
  SHOW_WAREHOUSE,
  LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE,
} from "store/actions/account/permits";

export const NAMESPACE = "pages/tire/history/history/TireWarehouseHistoryTab";

import Page from "./page";

export function TireWarehouseHistoryTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const [warehouseId, setWarehouseId] = useState(null);
  const [warehouseTireId, setWarehouseTireId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    tire,
    warehouse,
    permits,
    setNotification,
    getTireWarehousesHistory,
    clearTireWarehousesHistory,
  } = rest;
  const { id: tireId } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireWarehousesHistory({ tireId, page: 1 });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (permits.includes(LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE)) {
      fetchData();
    } else {
      setNotification({
        message: t("_permits.list_link.history.warehouse_with_tire"),
      });
    }

    return () => {
      clearTireWarehousesHistory();
    };
  }, [tireId]);

  function handleOpenDialog(warehouseId, warehouseTireId) {
    setWarehouseId(warehouseId);
    setWarehouseTireId(warehouseTireId);
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
    setWarehouseId(null);
    setWarehouseTireId(null);
  }

  function handleExitHistory() {
    history.push(`/tire/${tireId}`);
  }

  async function handleChangePage(page) {
    try {
      await getTireWarehousesHistory({ tireId, page });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      tireId={tire?.tire_id}
      warehouse={warehouse}
      dialogOpen={dialogOpen}
      warehouseId={warehouseId}
      warehouseTireId={warehouseTireId}
      showWarehousePermit={permits.includes(SHOW_WAREHOUSE)}
      handleChangePage={handleChangePage}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
      handleExitHistory={handleExitHistory}
    />
  );
}

TireWarehouseHistoryTab.propTypes = {
  tireId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  permits: state.Account.permits,
  warehouse: state.Tire.Warehouse.History.warehouses,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireWarehousesHistory: actionGetTireWarehousesHistory(dispatch),
  clearTireWarehousesHistory: actionClearTireWarehousesHistory(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TireWarehouseHistoryTab);
