export default {
  disabled_brand: "Disabled brand",
  messages: {
    approve: "Are you sure to approve brand?",
    disapprove: "Are you sure to disapprove brand?",
    enable: "Are you sure to enable brand?",
    disable: "Are you sure to disable brand?",
  },
  permits: {
    change_approval_status: "No permissions to {action} brands",
    change_status: "No permissions to {action} brands",
  },
};
