import { useTranslation } from "react-multi-lang";

import { camelToSnakeCase } from "utils/parse";

export function useLang(namespaces) {
  const t = useTranslation();
  if (namespaces) {
    if (namespaces.startsWith("/")) {
      namespaces = namespaces.substring(1);
    }

    if (!namespaces.endsWith("/")) {
      namespaces += "/";
    }

    namespaces = namespaces
      .split("/")
      .map((i) => camelToSnakeCase(i))
      .join(".");
  }

  return (lang, args) => {
    if (lang.startsWith("_")) {
      return t(lang.substring(1), args);
    }

    return t(`${namespaces}${lang}`, args);
  };
}
