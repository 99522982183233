import {
  REGISTER_COMPANY,
  CLEAR_COMPANIES,
  GET_COMPANIES,
  SET_COMPANY_FILTERS,
  CLEAR_COMPANY_FILTERS,
  GET_COMPANY,
  CLEAR_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  SET_STATUS_COMPANY,
} from "store/actions";

const initialState = {
  company: {
    company_id: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
    logo: null,
    name: "",
    social_reason: "",
    tire_fee: "",
    rfc: "",
    company: null,
    status: 0,
  },

  companies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_company: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_COMPANY:
    case GET_COMPANY:
    case UPDATE_COMPANY:
      return {
        ...state,
        company: action.payload,
      };

    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };

    case CLEAR_COMPANIES:
      return {
        ...state,
        companies: { ...initialState.companies },
      };

    case SET_COMPANY_FILTERS:
      const filter = {
        ...state.filter_company,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_company: { ...filter },
      };

    case CLEAR_COMPANY_FILTERS:
      return {
        ...state,
        filter_company: { search: state.filter_company.search },
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        company: { ...initialState.company },
      };
    case SET_STATUS_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          data: state.companies.data.map((company) =>
            company.company_id !== action.payload
              ? company
              : { ...company, status: !company.status }
          ),
        },
      };

    case DELETE_COMPANY:
      return {
        ...state,
        companies: {
          ...state.companies,
          data: state.companies.data.filter(
            (company) => company.company_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
