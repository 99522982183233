import { GET_VEHICLE_TYPE_AXLES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehicleTypeAxles =
  (dispatch) =>
  async (
    {
      vehicleTypeId,
      status_type: statusType,
      status_axle: statusAxle,
      tire_application_id: tireApplicationId,
      page,
      scope,
      order = "DESC",
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `vehicle/type/${vehicleTypeId}/axle`,
        params: {
          scope,
          page,
          order,
          status_type: statusType,
          status_axle: statusAxle,
          tire_application_id: tireApplicationId,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLE_TYPE_AXLES,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
