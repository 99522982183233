import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";
import { actionGetVehicleInstallations } from "store/actions/vehicle/installations/getVehicleInstallations";
import { actionGenerateInstallationsExcel } from "store/actions/vehicle/installations/generateInstallationsExcel";
import { alphabeticalSort } from "utils/sort";

import { useLang } from "hooks/lang";

import Page from "./page";

export function VehicleInstallations({ ...rest }) {
  const t = useLang();
  const {
    getVehicle,
    getVehicleInstallations,
    setNotification,
    generateExcel,
  } = rest;

  const { id: vehicleId } = useParams();
  const [vehicle, setVehicle] = useState({});
  const [tires, setTires] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicle(vehicleId, false);
        setVehicle(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicleInstallations(vehicleId);
        setTires(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  async function handleGenerateExcel() {
    try {
      await generateExcel(vehicleId);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSort(key) {
    const order = sortBy === key && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(key);
    setSortOrder(order);
  }

  const sortedTires = useMemo(() => {
    return [...tires].sort(alphabeticalSort(sortBy, sortOrder));
  }, [tires, sortBy, sortOrder]);

  return (
    <Page
      t={t}
      vehicle={vehicle}
      tires={sortedTires}
      handleSort={handleSort}
      sortBy={sortBy}
      sortOrder={sortOrder}
      handleGenerateExcel={handleGenerateExcel}
    />
  );
}

VehicleInstallations.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicle: actionGetVehicle(dispatch),
  getVehicleInstallations: actionGetVehicleInstallations(dispatch),
  generateExcel: actionGenerateInstallationsExcel(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleInstallations);
