import { GET_RETIREMENT_CAUSE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetRetirementCause =
  (dispatch) => async (retirementCauseId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `retirement/cause/${retirementCauseId}`,
      });

      dispatch({
        type: GET_RETIREMENT_CAUSE,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
