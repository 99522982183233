import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSummaryDetails } from "store/actions/reports/summary/getSummaryDetails";
import { actionClearSummaryDetails } from "store/actions/reports/summary/clearSummaryDetails";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

const paths = {
  pressure: "report/summary/details/pressure",
  depth: "report/summary/details/depth",
  alert: "report/summary/details/alert",
};
export function TireDialog({ open, params, ...rest }) {
  const t = useLang();
  const { tires, setNotification, getSummaryDetails, clearSummaryDetails } =
    rest;
  const { detail_type: detailType } = params;
  useEffect(() => {
    async function fetchData() {
      try {
        await getSummaryDetails(paths[detailType.toLowerCase()], params);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearSummaryDetails();
    };
  }, [open]);

  function handleClickTire(tireId) {
    window.open(`/tire/${tireId}/history`, "_blank");
  }

  return (
    <Page
      {...rest}
      t={t}
      open={open}
      tires={tires}
      sortedData={getSortedData(tires)}
      detailType={detailType}
      handleClickTire={handleClickTire}
    />
  );
}

TireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleViewAlerts: PropTypes.func,
};

const mapStateToProps = (state) => ({
  tires: state.Reports.Summary.tires,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSummaryDetails: actionGetSummaryDetails(dispatch),
  clearSummaryDetails: actionClearSummaryDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDialog);

function getSortedData(data) {
  if (Array.isArray(data)) {
    return data;
  }

  const res = Object.keys(data)
    .map((row) => data[row][0])
    .sort(alphabeticalSort("code"))
    .map((el) => el["movement_tire_id"]);
  return res;
}
