import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import AppBar from "components/molecules/general/AppBar";
import NavigationDrawer from "components/organisms/general/NavigationDrawer";

import css from "./index.module.scss";

function Page({
  title,
  open,
  children,
  handleOpen,
  options,
  account,
  className,
  tabs,
}) {
  return (
    <div className={classnames(css.container)}>
      <AppBar
        onClickMenu={() => handleOpen(true)}
        title={title}
        options={options}
      >
        {tabs}
      </AppBar>
      {account && (
        <NavigationDrawer open={open} onClose={() => handleOpen(false)} />
      )}
      <div className={classnames(css.main, className)}>{children}</div>
    </div>
  );
}

Page.propTypes = {
  options: PropTypes.node,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  account: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleOpen: PropTypes.func.isRequired,
  tabs: PropTypes.node,
  className: PropTypes.string,
};

export default Page;
