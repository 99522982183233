export default {
  disabled_vehicle: "Vehicle disabled",
  type_of_route: "Type of route",
  economic_number: "Economic number",
  enrollment: "Enrollment",
  has_odometer: {
    yes: "Yes",
    no: "No",
  },
  rove: "Traveled",
  buttons: {
    inspection: "Inspection",
  },
  permits: {
    change_status: "No permissions to {action} vehicle",
    list_link: {
      tire_with_vehicle: "No permissions to list vehicle mounted tires",
    },
  },
  messages: {
    disable: "Are you sure to disable the vehicle?",
    enable: "Are you sure to enable the vehicle?",
  },
};
