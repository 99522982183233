import React from "react";
import classnames from "classnames";

import PropTypes from "prop-types";

import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import FilterTireAlertsForm from "components/organisms/tire/alert/FilterTireAlertsForm";

import css from "./index.module.scss";
import { CONDITION_TYPES } from "utils/tire";

function Page({
  t,
  tires,
  openFilter,
  search,
  onChangePage,
  handleOpenFilter,
  handleCloseFilter,
  handleSearch,
  handleViewAlerts,
}) {
  return (
    <ContainerTemplate
      title={t("_general.tires")}
      className={classnames({
        [css.empty]: tires.data.length === 0,
      })}
      options={
        <>
          <Button
            className={classnames("bw", css.filter)}
            variant="icon"
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      {tires.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={tires.data.map((tire) => ({
                id: tire.alert_tire_id,
                key: tire.alert_tire_id,
                status: {
                  status: tire.status,
                  tooltipPermit: "",
                },
                subsidiary: tire.subsidiary_name,
                code: tire.code || "",
                rfid: tire.device_code || "",
                brand: tire.brand_name,
                location: tire.location,
                model: tire.model_name,
                condition: t(
                  CONDITION_TYPES.find(
                    (e) => e.value === tire.tire_condition_id
                  )?.label ??
                    `_labels.tire_condition.options.${tire.tire_condition_id}`
                ),
                redirect: {
                  handleClick: () => handleViewAlerts(tire.movement_tire_id),
                  label: <VisibilityIcon />,
                  variant: "icon",
                  size: "medium",
                },
              }))}
              headers={[
                { id: "status", label: t("_labels.status") },
                { id: "subsidiary", label: t("_labels.subsidiary.singular") },
                {
                  id: "code",
                  label: t("_labels.code"),
                },
                {
                  id: "rfid",
                  label: t("_general.rfid"),
                },
                {
                  id: "brand",
                  label: t("_labels.brand"),
                },
                {
                  id: "model",
                  label: t("_labels.model"),
                },
                {
                  id: "location",
                  label: t("_labels.location.label"),
                },
                {
                  id: "condition",
                  label: t("_labels.condition"),
                },
                {
                  id: "actions",
                  label: "",
                },
              ]}
              onChangeStatus={() => {}}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={tires.current_page}
              totalPages={tires.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterTireAlertsForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  search: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleViewAlerts: PropTypes.func.isRequired,
};

export default Page;
