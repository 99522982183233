import { PROGRESS } from "store/actions";

export const actionShowProgress = (dispatch) => (id) => {
  if (!id) {
    id = new Date().getTime();
  }
  dispatch({
    type: PROGRESS,
    payload: id,
  });

  return id;
};
