import React, { useState } from "react";
import { connect } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { actionShowProgress } from "store/actions/general/progress";
import { useLang } from "hooks/lang";

function ExportPdfButton({ ...rest }) {
  const t = useLang();
  const [loading, setLoading] = useState(false);
  const { showProgress, selectedFilter } = rest;

  const exportToPdf = async () => {
    const progress = showProgress();
    setLoading(true);
    const pdf = new jsPDF({
      orientation: "landscape",
    });

    const charts = document.querySelectorAll(".exportable-chart");
    let chartCount = 0;
    const filters = Object.keys(selectedFilter).filter((key) =>
      ["companies", "subsidiaries"].includes(key)
    );

    for (const chart of charts) {
      const canvas = await html2canvas(chart);
      const imgData = canvas.toDataURL("image/png");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pageWidth - 20; // Ajusta según necesidad
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      filters.forEach((filter, index) => {
        const { label, value } = selectedFilter[filter];
        const x = 10;
        const y = 14 + index * 7;
        pdf.text(`${label}: ${value}`, x, y);
      });

      const x = 10; // Margen desde el lado izquierdo
      const y = (pageHeight - imgHeight) / 2; // Centrado verticalmente

      pdf.addImage(imgData, "PNG", x, y, imgWidth, imgHeight + 12);
      chartCount++;

      if (chartCount < charts.length) {
        pdf.addPage();
      }
    }

    pdf.save(`${t("_general.disposal")}.pdf`);

    showProgress(progress);
    setLoading(false);
  };

  return (
    <button
      disabled={loading}
      type="button"
      className="w-auto btn btn-primary px-3 text-uppercase"
      onClick={exportToPdf}
    >
      {loading ? t("_buttons.loading_pdf") : t("_buttons.pdf")}
    </button>
  );
}

const mapStateToProps = (state) => ({
  selectedFilter: state.Disposal.selected_filter,
});
const mapDispatchToProps = (dispatch) => ({
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportPdfButton);
