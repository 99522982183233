import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  corporates,
  companies,
  subsidiaries,
  conditions,
  applications,
  initialValues,
  setFieldValue,

  brands,
  tireModels,
  tireModelVariations,
  revitalizedBrands,
  revitalizedModels,
  providers,
  warehouses,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.w100, css.ml8)}
      >
        {t("_labels.date.range")}
      </Typography>
      <Field
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.from")}
        className={classnames(css.medium_field)}
        required={!!values.date_to}
      />

      <Field
        name="date_to"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.to")}
        className={classnames(css.medium_field)}
        required={!!values.date_from}
      />

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="corporate_id"
        component={SelectField}
        label={t("_labels.corporate_field.singular.label")}
        defaultText={t("_labels.corporate_field.singular.value")}
        className={classnames(css.field)}
      >
        {corporates.map((corporate, index) => {
          return (
            <MenuItem
              key={index}
              value={corporate.corporate_id.toString()}
              disabled={!corporate.status}
            >
              {corporate.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="company_id"
        component={SelectField}
        label={t("_labels.company_field.singular.label")}
        defaultText={t("_labels.company_field.singular.value")}
        className={classnames(css.field)}
      >
        {companies.map((company, index) => {
          return (
            <MenuItem
              key={index}
              value={company.company_id.toString()}
              disabled={!company.status}
            >
              {company.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiary_id"
        component={SelectField}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        className={classnames(css.field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        multiple
        variant="filled"
        margin="normal"
        name="warehouse_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.warehouse.default")}
        defaultValue={
          warehouses.some(
            (warehouse) => warehouse.warehouse_id === values.warehouse_id
          )
            ? null
            : values.warehouse_id
        }
        component={SelectField}
        label={t("_labels.warehouse.singular")}
      >
        {warehouses.map((warehouse, index) => {
          return (
            <MenuItem
              key={index}
              value={warehouse.warehouse_id.toString()}
              disabled={!warehouse.status}
            >
              {warehouse.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        margin="normal"
        name="provider_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.provider.default")}
        defaultValue={
          providers.some(
            (provider) => provider.provider_id === values.provider_id
          )
            ? null
            : values.provider_id
        }
        component={SelectField}
        label={t("_labels.provider.singular")}
      >
        {providers.map((provider, index) => {
          return (
            <MenuItem
              key={index}
              value={provider.provider_id.toString()}
              disabled={!provider.status}
            >
              {provider.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="brands"
        name="brands"
        multiple
        autoHighlight
        disableClearable={initialValues.brands.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          option.brand_id === value.brand_id
        }
        onChange={(_, value) => setFieldValue("brands", value)}
        value={values.brands}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="models"
        name="models"
        autoHighlight
        disableClearable={initialValues.models !== null}
        blurOnSelect
        component={Autocomplete}
        options={tireModels}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("models", value)}
        value={values.models}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.medium_field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_model_variations"
        component={SelectField}
        label={t("_labels.tire_model_variation_field.label")}
        helperText={t("_labels.tire_model_variation_field.multiple")}
        className={classnames(css.field)}
      >
        {tireModelVariations.map((variation, index) => {
          return (
            <MenuItem
              key={index}
              value={variation.tire_model_variation_id.toString()}
              disabled={!variation.status || !variation.approved}
            >
              {`${variation.tire_size.size} ${variation.number_layers}`}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="revitalized_brands"
        component={SelectField}
        label={t("_labels.revitalized_brand_field.label")}
        className={classnames(css.medium_field)}
      >
        {revitalizedBrands.map((brand, index) => {
          return (
            <MenuItem
              key={index}
              value={brand.brand_id.toString()}
              disabled={!brand.status}
            >
              {brand.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="revitalized_models"
        component={SelectField}
        label={t("_labels.revitalized_model_field.label")}
        className={classnames(css.medium_field)}
      >
        {revitalizedModels.map((model, index) => {
          return (
            <MenuItem
              key={index}
              value={model.revitalized_tire_model_id.toString()}
              disabled={!model.status}
            >
              {model.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_condition"
        component={SelectField}
        label={t("_labels.condition.label")}
        defaultText={t("_labels.tire_condition.default")}
        className={classnames(css.medium_field)}
      >
        {conditions.map((condition, index) => {
          return (
            <MenuItem key={index} value={condition.value.toString()}>
              {condition.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_application"
        component={SelectField}
        label={t("_labels.tire_application.label.singular")}
        defaultText={t("_labels.tire_application.default.singular")}
        className={classnames(css.medium_field)}
      >
        {applications.map((application, index) => {
          return (
            <MenuItem key={index} value={application.value.toString()}>
              {application.label}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,

  brands: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
};

export default Page;
