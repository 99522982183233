import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import VirtualizedTable from "components/organisms/general/VirtualizedTable";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import { alphabeticalSort } from "utils/sort";

function Page({ t, open, onClose, tires }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {tires.length
              ? `: ${tires.length}`
              : `: ${Object.keys(tires).length}`}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            style={{
              backgroundColor: "lightgray",
              color: "black",
            }}
          >
            <table style={{ visibility: "collapse" }}>
              <tbody>
                <tr>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>

            <VirtualizedTable
              width={800}
              height={500}
              rows={tires.sort(alphabeticalSort("code")).map((tire) => ({
                code: tire["code"],
                model: tire["model"],
                size: tire["size"],
                brand: tire["brand_name"],
              }))}
              columns={[
                {
                  dataKey: "code",
                  label: t("_labels.code"),
                },
                {
                  dataKey: "brand",
                  label: t("_labels.brand"),
                },
                {
                  dataKey: "model",
                  label: t("_labels.model"),
                },
                {
                  dataKey: "size",
                  label: t("_labels.size"),
                },
              ]}
              rowRenderer={() => {}}
            />
          </div>
          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
