import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function VehicleDriverHistoryTab() {
  return <Page />;
}

VehicleDriverHistoryTab.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDriverHistoryTab);
