import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateRenewabilityIndexExcel =
  (dispatch) =>
  async ({
    page,
    subsidiary_id: subsidiaryId,
    company_id: companyId,
    corporate_id: corporateId,
    models,
    brands,
    sizes,
    sort_by: sortBy,
    order,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/renewability/index/excel`,
        params: {
          page,
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          models,
          brands,
          sizes,
          sort_by: sortBy,
          order,
        },
        download: true,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
