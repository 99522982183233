import { http } from "store/actions/http";
import { DELETE_DEPTH_POLICY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteDepthPolicy =
  (dispatch) => async (subsidiaryId, vehicleId, vehicleDepthPolicyId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: `DELETE`,
        path: `subsidiary/${subsidiaryId}/depth/policy/vehicle/${vehicleId}/axle/${vehicleDepthPolicyId}`,
      });
      dispatch({
        type: DELETE_DEPTH_POLICY,
        payload: vehicleDepthPolicyId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
