import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  subsidiary,
  subsidiaryActive,
  subsidiaries,
  companies,
  countries,
  states,
  provinces,
  createSubsidiaryPermit,
  updateSubsidiaryPermit,
  deleteSubsidiaryPermit,
  filter,
  t,
  getSubsidiary,
  addSubsidiary,
  updateSubsidiary,
  clearSubsidiaryFilters,
  setSubsidiaryFilters,
  deleteSubsidiary,
  setStatusSubsidiary,
  getSubsidiaries,
  clearSubsidiaries,
  handleChangeCountry,
  handleChangeState,
  handleViewPolicies,
  showSubsidiaryPermit,
}) {
  return (
    <Home
      item={subsidiary}
      name="subsidiary"
      filter={filter}
      createTooltipPermit={
        !createSubsidiaryPermit ? t("_permits.create.subsidiary") : ""
      }
      currentPage={subsidiaries.current_page}
      deleteConfirmationMessage={t("_messages.delete.subsidiary")}
      disableConfirmationMessage={t(
        "_messages.change_status.subsidiary.disable"
      )}
      enableConfirmationMessage={t("_messages.change_status.subsidiary.enable")}
      rows={subsidiaries.data.map((subsidiary) => {
        return {
          id: subsidiary.subsidiary_id,
          key: subsidiary.subsidiary_id,
          status: {
            status: subsidiary.status,
            tooltipPermit: !updateSubsidiaryPermit
              ? t("_permits.update.subsidiary")
              : "",
          },
          name: subsidiary.name,
          address: `${subsidiary.direction_1} #${subsidiary.external_number}${
            subsidiary.internal_number ? ` #${subsidiary.internal_number}` : ""
          }${subsidiary.direction_2 ? ` ${subsidiary.direction_2}` : ""}, ${
            subsidiary.province
          }, ${subsidiary.state}, ${subsidiary.country}`,
          redirect: {
            tooltipPermit:
              !showSubsidiaryPermit ||
              subsidiaryActive.subsidiary.subsidiary_id !=
                subsidiary.subsidiary_id
                ? t("_permits.show.policy")
                : "",
            label: t("_general.policies"),
            handleClick: () => handleViewPolicies(subsidiary.subsidiary_id),
          },
          edit: {
            tooltipPermit: !updateSubsidiaryPermit
              ? t("_permits.update.subsidiary")
              : "",
          },
          delete: {
            tooltipPermit: !deleteSubsidiaryPermit
              ? t("_permits.delete.subsidiary")
              : "",
          },
        };
      })}
      title={t("_general.subsidiaries")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.subsidiary_name") },
        { id: "address", label: t("_labels.address") },
        { id: "actions", label: "" },
      ]}
      lastPage={subsidiaries.last_page}
      noItemsText={t("_general.no_subsidiaries")}
      initialDefaultValues={{
        name: "",
        company_id: "",
        direction_1: "",
        external_number: "",
        internal_number: "",
        direction_2: "",
        postal_code: "",
        province: "",
        state: "",
        country: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "company_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.company_field.singular.label"),
          selectItems: companies.map((company) => ({
            value: company.company_id.toString(),
            name: company.name,
            disabled: !company.status,
          })),
        },
        {
          name: "direction_1",
          required: true,
          label: t("_labels.direction1"),
        },
        {
          name: "external_number",
          required: true,
          label: t("_labels.external_number"),
        },
        {
          name: "internal_number",
          label: t("_labels.internal_number"),
          required: false,
        },
        {
          name: "direction_2",
          required: true,
          label: t("_labels.direction2"),
        },
        {
          name: "postal_code",
          required: true,
          label: t("_labels.postal_code"),
        },
        {
          name: "country",
          required: true,
          component: "select",
          label: t("_labels.country"),
          selectItems: countries.length
            ? countries.map((country) => ({
                value: country,
                name: country,
              }))
            : [],
          defaultText: t("_labels.country_field.value"),
          handleChange: handleChangeCountry,
        },
        {
          name: "state",
          required: true,
          component: "select",
          label: t("_labels.state"),
          selectItems: states.length
            ? states.map((state) => ({
                value: state,
                name: state,
              }))
            : [],
          defaultText: t("_labels.state_field.value"),
          handleChange: handleChangeState,
        },
        {
          id: "province",
          name: "province",
          label: t("_labels.province"),
          required: true,
          component: "autocomplete",
          options: provinces.length ? provinces : [],
        },
      ]}
      createFormTitle={t("_titles.new.subsidiary")}
      updateFormTitle={t("_titles.update.subsidiary")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.subsidiary"),
        },
        date: true,
        companies: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        companies: [],
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.subsidiary")}
      updatedMessage={t("_messages.updated.subsidiary")}
      getItemAction={getSubsidiary}
      setFiltersAction={setSubsidiaryFilters}
      clearFiltersAction={clearSubsidiaryFilters}
      deleteAction={deleteSubsidiary}
      setStatusAction={setStatusSubsidiary}
      getItemsAction={getSubsidiaries}
      clearItemsAction={clearSubsidiaries}
      addAction={addSubsidiary}
      updateAction={updateSubsidiary}
    />
  );
}

Page.propTypes = {
  subsidiary: PropTypes.object.isRequired,
  subsidiaries: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  createSubsidiaryPermit: PropTypes.bool.isRequired,
  updateSubsidiaryPermit: PropTypes.bool.isRequired,
  deleteSubsidiaryPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getSubsidiary: PropTypes.func.isRequired,
  addSubsidiary: PropTypes.func.isRequired,
  updateSubsidiary: PropTypes.func.isRequired,
  clearSubsidiaryFilters: PropTypes.func.isRequired,
  setSubsidiaryFilters: PropTypes.func.isRequired,
  deleteSubsidiary: PropTypes.func.isRequired,
  setStatusSubsidiary: PropTypes.func.isRequired,
  getSubsidiaries: PropTypes.func.isRequired,
  clearSubsidiaries: PropTypes.func.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
  handleViewPolicies: PropTypes.func.isRequired,
  showSubsidiaryPermit: PropTypes.bool.isRequired,
  subsidiaryActive: PropTypes.object,
};

export default Page;
