import classnames from "classnames";
import css from "./index.module.scss";
import Link from "@material-ui/core/Link";
import moment from "moment-timezone";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function TirePerformanceTablePage(props) {
  const t = props.t;

  return (
    <div className="table-responsive">
      <table className="table text-center">
        <thead style={{ backgroundColor: "#0061a4", color: "white" }}>
          <tr>
            <th>{t("_general.tire_reviews")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-bordered">
            <td className={classnames(css.table_cell, "p-0")}>
              <table className="table table-striped m-0 p-0">
                <thead className="thead-dark">
                  <tr>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.code")}
                    </th>
                    <th className={classnames(css.table_cell)}>Rfid</th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.vehicle_type_axle_position.label")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.mount_date")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.brand")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.model")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.size")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.report_last_movement.depth_model")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.current_depth")} (mm)
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.dual_difference")} (mm)
                    </th>
                    <th className={classnames(css.table_cell)}>Km</th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.tire_months_active")}
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.wear.month")} (mm)
                    </th>
                    <th className={classnames(css.table_cell)}>% Desgastado</th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.pressure")} (psi)
                    </th>
                    <th className={classnames(css.table_cell)}>
                      {t("_labels.projection")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.tires.map((tire, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.tireId ? (
                            <Link
                              href={`/tire/${tire.tireId}/history`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {tire.economicNumber ?? "-"}
                            </Link>
                          ) : (
                            <span>{tire.economicNumber ?? "-"}</span>
                          )}

                          {tire.hasAlerts && (
                            <button
                              className="btn-sm btn btn-warning d-flex justify-content-between align-items-center"
                              style={{ width: "100%" }}
                              onClick={tire.handleOpenAlertDialog}
                            >
                              <WarningIcon fontSize="small" className="mr-1" />{" "}
                              {t("_general.alerts")}
                            </button>
                          )}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.tireId ? (
                            <Link
                              href={`/tire/${tire.tireId}/history`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {tire.deviceCode ?? "-"}
                            </Link>
                          ) : (
                            <span>{tire.deviceCode ?? "-"}</span>
                          )}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.position ?? "-"}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {tire.date ? moment(tire.date).format("LL") : "-"}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {tire.brand ?? "-"}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {tire.model ?? "-"}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {tire.size ?? "-"}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {tire.depthVariation ?? "-"}
                        </td>
                        <td
                          className={classnames(
                            css.table_cell,
                            "align-middle",
                            {
                              [css.critical]:
                                tire.depthCondition === "CRITICAL WITHDRAWAL",
                              [css.warning]:
                                tire.depthCondition === "SCHEDULED WITHDRAWAL",
                              [css.good]:
                                tire.depthCondition === "GOOD CONDITION",
                            }
                          )}
                        >
                          {tire.depth ? formatter.format(tire.depth) : "-"}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.dualDifference !== undefined
                            ? formatter.format(tire.dualDifference)
                            : "-"}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.accumulatedMileage !== undefined
                            ? kmFormatter.format(tire.accumulatedMileage)
                            : "-"}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.monthsActive !== undefined
                            ? tire.monthsActive
                            : "-"}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.monthlyWear !== undefined
                            ? formatter.format(tire.monthlyWear)
                            : "-"}
                        </td>
                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.wearPercentage !== undefined
                            ? `${percentFormatter.format(tire.wearPercentage)}%`
                            : "-"}
                        </td>
                        <td
                          className={classnames(
                            css.table_cell,
                            "align-middle",
                            {
                              [css.critical]:
                                tire.pressureCondition?.includes("LOW") ||
                                tire.pressureCondition?.includes("HIGH"),
                              [css.good]: tire?.pressureCondition === "NORMAL",
                            }
                          )}
                        >
                          {tire.pressure
                            ? formatter.format(tire.pressure)
                            : "-"}
                        </td>

                        <td
                          className={classnames(css.table_cell, "align-middle")}
                        >
                          {tire.projection
                            ? kmFormatter.format(tire.projection)
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

TirePerformanceTablePage.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.arrayOf(
    PropTypes.shape({
      tireId: PropTypes.number,
      economicNumber: PropTypes.string,
      deviceCode: PropTypes.string,
      position: PropTypes.number,
      createdAt: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      size: PropTypes.string,
      depthVariation: PropTypes.number,
      depthCondition: PropTypes.string,
      depth: PropTypes.number,
      dualDifference: PropTypes.number,
      accumulatedMileage: PropTypes.number,
      monthsActive: PropTypes.number,
      monthlyWear: PropTypes.number,
      wearPercentage: PropTypes.number,
      pressure: PropTypes.number,
      pressureCondition: PropTypes.string,
      projection: PropTypes.number,
      hasAlerts: PropTypes.bool,
      handleOpenAlertDialog: PropTypes.func,
    })
  ).isRequired,
};

export default TirePerformanceTablePage;
