import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionAddModelVariationPolicy } from "store/actions/subsidiary/model/addModelVariationPolicy";
import { actionUpdateModelVariationPolicy } from "store/actions/subsidiary/model/updateModelVariationPolicy";
import { actionGetModelVariationPolicy } from "store/actions/subsidiary/model/getModelVariationPolicy";
import { actionGetModelVariationPolicies } from "store/actions/subsidiary/model/getModelVariationPolicies";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function ModelVariationPolicyForm({
  open,
  onClose,
  subsidiaryId,
  tireModelVariationPolicyId,
  ...rest
}) {
  const t = useLang();
  const {
    modelVariationPolicy,
    getModelVariationPolicy,
    getModelVariationPolicies,
    updateModelVariationPolicy,
    addModelVariationPolicy,
    setNotification,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
  } = useFormik({
    initialValues: {
      tire_model_id: "",
      tire_model_variation_id: "",
      maximum_pressure: "",
      recommended_pressure: "",
      tolerance: "",
    },
  });

  const [isUpdate, setIsUpdate] = useState(
    tireModelVariationPolicyId ? true : false
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await getModelVariationPolicy(subsidiaryId, tireModelVariationPolicyId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (tireModelVariationPolicyId && open) {
      fetchData();
    }
  }, [tireModelVariationPolicyId, open]);

  useEffect(() => {
    if (
      tireModelVariationPolicyId &&
      modelVariationPolicy.tire_model_variation_policy_id
    ) {
      setInitialValues({
        tire_model_id:
          modelVariationPolicy.tire_model_variation.tire_model_id.toString(),
        tire_model_variation_id:
          modelVariationPolicy.tire_model_variation.tire_model_variation_id.toString(),
        maximum_pressure: modelVariationPolicy.maximum_pressure,
        recommended_pressure: modelVariationPolicy.recommended_pressure,
        tolerance: modelVariationPolicy.tolerance,
      });
    } else {
      setInitialValues({
        tire_model_id: "",
        tire_model_variation_id: "",
        maximum_pressure: "",
        recommended_pressure: "",
        tolerance: "",
      });
    }
  }, [modelVariationPolicy, tireModelVariationPolicyId]);

  useEffect(() => {
    setIsUpdate(tireModelVariationPolicyId ? true : false);
  }, [tireModelVariationPolicyId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      tire_model_id: values.tire_model_id,
      tire_model_variation_id: values.tire_model_variation_id,
      maximum_pressure: values.maximum_pressure,
      recommended_pressure: values.recommended_pressure,
      tolerance: values.tolerance,
    };

    try {
      if (isUpdate) {
        await updateModelVariationPolicy(
          fields,
          subsidiaryId,
          tireModelVariationPolicyId
        );
        setNotification({
          message: t("_messages.updated.model_pressure_policy"),
        });
      } else {
        await addModelVariationPolicy(subsidiaryId, fields);
        resetForm();
        setNotification({
          message: t("_messages.created.model_pressure_policy"),
        });
      }

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    try {
      await getModelVariationPolicies({ subsidiaryId });
    } catch (error) {
      setNotification(error);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      isUpdate={isUpdate}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

const mapStateToProps = (state) => ({
  modelVariationPolicy: state.Subsidiary.Model.model_variation_policy,
});

ModelVariationPolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tireModelVariationPolicyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const mapDispatchToProps = (dispatch) => ({
  addModelVariationPolicy: actionAddModelVariationPolicy(dispatch),
  updateModelVariationPolicy: actionUpdateModelVariationPolicy(dispatch),
  getModelVariationPolicy: actionGetModelVariationPolicy(dispatch),
  getModelVariationPolicies: actionGetModelVariationPolicies(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelVariationPolicyForm);
