export default {
  title: "Nuevo Proveedor",
  update_title: "Actualizar proveedor",
  form: {
    permits: {
      update: "Sin permisos para actualizar proveedores",
      delete: "Sin permisos para eliminar proveedores",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar el proveedor?",
    provider_created: "Listo, se creo un nuevo proveedor con éxito",
    provider_updated: "Listo, se actualizó el proveedor con éxito",
  },
};
