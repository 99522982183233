import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetBestPerformanceReport } from "store/actions/reports/best_performance/getBestPerformanceReport";
import { actionClearBestPerformanceFilters } from "store/actions/reports/best_performance/clearBestPerformanceFilters";

import { useLang } from "hooks/lang";

import Page from "./page";

function PilePerformance({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  const {
    filter,
    selectedFilter,
    setNotification,
    getBestPerformanceReport,
    clearBestPerformanceFilters,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBestPerformanceReport({
          movement: "PILE",
          ...filter,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    return () => {
      clearBestPerformanceFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      originalData={getOriginal(data)}
      revitalizedData={getRevitalized(data)}
      originalTierList={getOriginalTierList(data)}
      revitalizedTierList={getRevitalizedTierList(data)}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}
PilePerformance.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.BestPerformance.filter,
  selectedFilter: state.Reports.BestPerformance.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getBestPerformanceReport: actionGetBestPerformanceReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearBestPerformanceFilters: actionClearBestPerformanceFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PilePerformance);

function getOriginal(data) {
  if (data.origin) {
    const corporates = Object.entries(data.origin).filter(
      ([key]) => key !== "tier_list"
    );

    const result = corporates.reduce((acc, corporate) => {
      const [key, value] = corporate;
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(result).forEach((corporate) => {
      Object.keys(result[corporate])
        .filter((key) => typeof result[corporate][key] === "object")
        .forEach((company) => {
          Object.keys(result[corporate][company])
            .filter(
              (key) => typeof result[corporate][company][key] === "object"
            )
            .forEach((subsidiary) => {
              Object.keys(result[corporate][company][subsidiary])
                .filter(
                  (key) =>
                    typeof result[corporate][company][subsidiary][key] ===
                    "object"
                )
                .forEach((division) => {
                  Object.keys(result[corporate][company][subsidiary][division])
                    .filter(
                      (key) =>
                        typeof result[corporate][company][subsidiary][division][
                          key
                        ] === "object"
                    )
                    .forEach((application) => {
                      Object.keys(
                        result[corporate][company][subsidiary][division][
                          application
                        ]
                      )
                        .filter(
                          (key) =>
                            typeof result[corporate][company][subsidiary][
                              division
                            ][application][key] === "object"
                        )
                        .forEach((size) => {
                          let aux = [];
                          Object.keys(
                            result[corporate][company][subsidiary][division][
                              application
                            ][size]
                          )
                            .filter(
                              (key) =>
                                typeof result[corporate][company][subsidiary][
                                  division
                                ][application][size][key] === "object"
                            )
                            .forEach((model) => {
                              aux.push([
                                model,
                                result[corporate][company][subsidiary][
                                  division
                                ][application][size][model]["cpk"],
                              ]);
                            });
                          result[corporate][company][subsidiary][division][
                            application
                          ][size]["data"] = aux.sort((a, b) => a[1] - b[1]);
                        });
                    });
                });
            });
        });
    });
    return result;
  }

  return {};
}

function getRevitalized(data) {
  if (data.revitalized) {
    const corporates = Object.entries(data.revitalized).filter(
      ([key]) => key !== "tier_list"
    );

    const result = corporates.reduce((acc, corporate) => {
      const [key, value] = corporate;
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(result).forEach((corporate) => {
      Object.keys(result[corporate])
        .filter((key) => typeof result[corporate][key] === "object")
        .forEach((company) => {
          Object.keys(result[corporate][company])
            .filter(
              (key) => typeof result[corporate][company][key] === "object"
            )
            .forEach((subsidiary) => {
              Object.keys(result[corporate][company][subsidiary])
                .filter(
                  (key) =>
                    typeof result[corporate][company][subsidiary][key] ===
                    "object"
                )
                .forEach((division) => {
                  Object.keys(result[corporate][company][subsidiary][division])
                    .filter(
                      (key) =>
                        typeof result[corporate][company][subsidiary][division][
                          key
                        ] === "object"
                    )
                    .forEach((application) => {
                      Object.keys(
                        result[corporate][company][subsidiary][division][
                          application
                        ]
                      )
                        .filter(
                          (key) =>
                            typeof result[corporate][company][subsidiary][
                              division
                            ][application][key] === "object"
                        )
                        .forEach((size) => {
                          let aux = [];
                          Object.keys(
                            result[corporate][company][subsidiary][division][
                              application
                            ][size]
                          )
                            .filter(
                              (key) =>
                                typeof result[corporate][company][subsidiary][
                                  division
                                ][application][size][key] === "object"
                            )
                            .forEach((model) => {
                              aux.push([
                                model,
                                result[corporate][company][subsidiary][
                                  division
                                ][application][size][model]["cpk"],
                              ]);
                            });
                          result[corporate][company][subsidiary][division][
                            application
                          ][size]["data"] = aux.sort((a, b) => a[1] - b[1]);
                        });
                    });
                });
            });
        });
    });

    return result;
  }

  return {};
}

function getOriginalTierList(data) {
  if (data.origin) {
    return data.origin.tier_list;
  }

  return [];
}

function getRevitalizedTierList(data) {
  if (data.revitalized) {
    return data.revitalized.tier_list;
  }

  return [];
}
