import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import "./index.css";
import { alertCauseTranslation } from "utils/translation";

function Page({ t, alerts, open, onClose }) {
  return (
    <DialogMediaQuery
      maxWidth="md"
      open={open}
      onClose={onClose}
      className="alert_info"
    >
      <DialogTitle className="title_dialog">
        {t("_titles.tire_alerts")}: {alerts.length > 0 && alerts[0]["code"]}
      </DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className="table-responsive">
            <table id="table_alert" className="table">
              <thead>
                <tr>
                  <td>{t("_labels.code")}</td>
                  <td>{t("_labels.description")}</td>
                  <td>{t("_labels.date.label")}</td>
                </tr>
              </thead>
              <tbody>
                {alerts.map((alert, index) => (
                  <tr key={index}>
                    <td className="text-muted">{alert.code_alert || "-"}</td>
                    <td className="text-muted font-italic">
                      {alertCauseTranslation(t, alert.alert_cause)}
                    </td>
                    <td className="text-muted">
                      {alert.created_at
                        ? moment(alert.created_date).format("LL h:mm a")
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className={classnames(css.action)}>
          <Button onClick={onClose} color="secondary">
            {t("_buttons.close")}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  alerts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
