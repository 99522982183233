import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateSummaryMountExcel =
  (dispatch) =>
  async ({
    date_from: dateFrom,
    date_to: dateTo,
    corporate_id: corporateId,
    companies,
    subsidiaries,
    vehicle_types: vehicleTypes,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "report/mount/summary/excel",
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          corporate_id: corporateId,
          companies,
          subsidiaries,
          vehicle_types: vehicleTypes,
        },
        download: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
