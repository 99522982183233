import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import FilterWarehouseForm from "components/organisms/report/FilterWarehouseForm";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
}) {
  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.report_warehouse")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="container-fluid pdl100 sidebar_font">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}

                  {filter.warehouse_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.warehouse.singular")}:
                      </td>
                      <td>{selectedFilter.warehouse}</td>
                    </tr>
                  )}

                  {filter.provider_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.provider")}:
                      </td>
                      <td>{selectedFilter.provider}</td>
                    </tr>
                  )}

                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}

                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}

                  {filter.tire_model_variations && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.warehouse_report.variation")}:
                      </td>
                      <td>{selectedFilter.variations}</td>
                    </tr>
                  )}

                  {filter.revitalized_brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.warehouse_report.revitalized_brand")}:
                      </td>
                      <td>{selectedFilter.revitalized_brands}</td>
                    </tr>
                  )}

                  {filter.revitalized_models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.warehouse_report.revitalized_model")}:
                      </td>
                      <td>{selectedFilter.revitalized_models}</td>
                    </tr>
                  )}

                  {filter.tire_application && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.application")}:
                      </td>
                      <td>{selectedFilter.applications}</td>
                    </tr>
                  )}

                  {filter.tire_condition && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.condition")}:
                      </td>
                      <td>{selectedFilter.conditions}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {!filter.movement && (
          <div className="row pdt12">
            <div className="col-md-12 text-center">
              <hr />
              <h1 className="sinmp">{t("_labels.warehouse_report.report")}</h1>
              <small className="sinmp" style={{ color: "lightcoral" }}>
                {t("_labels.location.label")}:{" "}
                {t("_labels.location.options.warehouse")}
              </small>
              <hr />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid px-4">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>

        {data && Object.keys(data).length > 0 && (
          <div className="row pt-4">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t("_labels.warehouse.plural")}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "warehouse"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="warehouse"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "warehouse"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="warehouse"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_warehouses`,
                                                  `${company}_${subsidiary}`,
                                                  "warehouse"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((warehouse) => (
                                                <Fragment key={warehouse}>
                                                  <tr
                                                    data-type="warehouse"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_warehouses`
                                                    )}
                                                    className="warehouse box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${warehouse}_conditions`,
                                                        `${company}_${subsidiary}_${warehouse}`,
                                                        "warehouse"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {warehouse}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][warehouse][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][warehouse]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {Object.keys(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ][warehouse]
                                                  )
                                                    .filter(
                                                      (index) =>
                                                        ![
                                                          "statistics",
                                                          "percent",
                                                        ].includes(index)
                                                    )
                                                    .map((condition) => (
                                                      <Fragment key={condition}>
                                                        <tr
                                                          data-type="warehouse"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${warehouse}_conditions`
                                                          )}
                                                          className={`box collapse condition`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_detphs`,
                                                              `${company}_${subsidiary}_${warehouse}_${condition}`,
                                                              "warehouse"
                                                            )
                                                          }
                                                        >
                                                          <td
                                                            className="text-left"
                                                            style={{
                                                              paddingTop: 27,
                                                            }}
                                                          >
                                                            {t(
                                                              `_labels.report_warehouse.tire_condition.${removeCharacters(
                                                                condition,
                                                                "_"
                                                              ).toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col2">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col4">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_general.tires"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                warehouse
                                                              ][condition][
                                                                "statistics"
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-right col6">
                                                            <small>
                                                              {t(
                                                                "_labels.report_warehouse.percent"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {percentFormatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                warehouse
                                                              ][condition][
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][warehouse][
                                                            condition
                                                          ]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              ![
                                                                "statistics",
                                                                "percent",
                                                              ].includes(index)
                                                          )
                                                          .map((detph) => (
                                                            <Fragment
                                                              key={detph}
                                                            >
                                                              <tr
                                                                data-type="warehouse"
                                                                data-id={removeCharacters(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_detphs`
                                                                )}
                                                                className={`box collapse detph ${
                                                                  detph.includes(
                                                                    "SCHEDULED"
                                                                  )
                                                                    ? "set_sem_amarillo"
                                                                    : detph.includes(
                                                                        "CRITICAL"
                                                                      )
                                                                    ? "set_sem_rojo"
                                                                    : "set_sem_verde"
                                                                }`}
                                                                onClick={() =>
                                                                  toggleTable(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_${detph}_applications`,
                                                                    `${company}_${subsidiary}_${warehouse}_${condition}_${detph}`,
                                                                    "warehouse"
                                                                  )
                                                                }
                                                              >
                                                                <td
                                                                  className="text-left"
                                                                  style={{
                                                                    paddingTop: 27,
                                                                  }}
                                                                >
                                                                  <i
                                                                    className={`bx bxs-circle ${
                                                                      detph.includes(
                                                                        "SCHEDULED"
                                                                      )
                                                                        ? "set_sem_amarillo"
                                                                        : detph.includes(
                                                                            "CRITICAL"
                                                                          )
                                                                        ? "set_sem_rojo"
                                                                        : "set_sem_verde"
                                                                    }`}
                                                                  />
                                                                  &nbsp;
                                                                  {t(
                                                                    `_labels.report_warehouse.depth_condition.${removeCharacters(
                                                                      detph,
                                                                      "_"
                                                                    ).toLowerCase()}`
                                                                  )}
                                                                </td>
                                                                <td className="text-right col1">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col2">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col3">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col4">
                                                                  {" "}
                                                                </td>
                                                                <td className="text-right col5">
                                                                  <small>
                                                                    {t(
                                                                      "_general.tires"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {
                                                                    corporate[1][
                                                                      company
                                                                    ][
                                                                      subsidiary
                                                                    ][
                                                                      warehouse
                                                                    ][
                                                                      condition
                                                                    ][detph][
                                                                      "statistics"
                                                                    ]
                                                                  }
                                                                </td>
                                                                <td className="text-right col6">
                                                                  <small>
                                                                    {t(
                                                                      "_labels.report_warehouse.percent"
                                                                    )}
                                                                  </small>
                                                                  <br />
                                                                  {percentFormatter.format(
                                                                    corporate[1][
                                                                      company
                                                                    ][
                                                                      subsidiary
                                                                    ][
                                                                      warehouse
                                                                    ][
                                                                      condition
                                                                    ][detph][
                                                                      "percent"
                                                                    ]
                                                                  )}
                                                                  %
                                                                </td>
                                                              </tr>

                                                              {Object.keys(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  warehouse
                                                                ][condition][
                                                                  detph
                                                                ]
                                                              )
                                                                .filter(
                                                                  (index) =>
                                                                    ![
                                                                      "statistics",
                                                                      "percent",
                                                                    ].includes(
                                                                      index
                                                                    )
                                                                )
                                                                .map(
                                                                  (
                                                                    application
                                                                  ) => (
                                                                    <Fragment
                                                                      key={
                                                                        application
                                                                      }
                                                                    >
                                                                      <tr
                                                                        data-type="warehouse"
                                                                        data-id={removeCharacters(
                                                                          `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_${detph}_applications`
                                                                        )}
                                                                        className="application box collapse"
                                                                        onClick={() =>
                                                                          toggleTable(
                                                                            `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_${detph}_${application}_tires`,
                                                                            `${company}_${subsidiary}_${warehouse}_${condition}_${detph}_${application}`,
                                                                            "warehouse"
                                                                          )
                                                                        }
                                                                      >
                                                                        <td
                                                                          className="text-left"
                                                                          style={{
                                                                            paddingTop: 27,
                                                                          }}
                                                                        >
                                                                          {t(
                                                                            `_labels.report_warehouse.tire_application.${removeCharacters(
                                                                              application,
                                                                              "_"
                                                                            ).toLowerCase()}`
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right col1">
                                                                          {" "}
                                                                        </td>
                                                                        <td className="text-right col2">
                                                                          {" "}
                                                                        </td>
                                                                        <td className="text-right col3">
                                                                          {" "}
                                                                        </td>
                                                                        <td className="text-right col4">
                                                                          {" "}
                                                                        </td>
                                                                        <td className="text-right col5">
                                                                          <small>
                                                                            {t(
                                                                              "_general.tires"
                                                                            )}
                                                                          </small>
                                                                          <br />
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              warehouse
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              detph
                                                                            ][
                                                                              application
                                                                            ][
                                                                              "statistics"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right col6">
                                                                          <small>
                                                                            {t(
                                                                              "_labels.report_warehouse.percent"
                                                                            )}
                                                                          </small>
                                                                          <br />
                                                                          {percentFormatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              warehouse
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              detph
                                                                            ][
                                                                              application
                                                                            ][
                                                                              "percent"
                                                                            ]
                                                                          )}
                                                                          %
                                                                        </td>
                                                                      </tr>

                                                                      {Object.keys(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          warehouse
                                                                        ][
                                                                          condition
                                                                        ][
                                                                          detph
                                                                        ][
                                                                          application
                                                                        ]
                                                                      )
                                                                        .filter(
                                                                          (
                                                                            index
                                                                          ) =>
                                                                            ![
                                                                              "statistics",
                                                                              "percent",
                                                                            ].includes(
                                                                              index
                                                                            )
                                                                        )
                                                                        .map(
                                                                          (
                                                                            tire
                                                                          ) => (
                                                                            <Fragment
                                                                              key={
                                                                                tire
                                                                              }
                                                                            >
                                                                              <tr
                                                                                data-type="warehouse"
                                                                                data-id={removeCharacters(
                                                                                  `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_${detph}_${application}_tires`
                                                                                )}
                                                                                className="bg-white box collapse"
                                                                                onClick={() =>
                                                                                  toggleTable(
                                                                                    `${corporate[0]}_${company}_${subsidiary}_${warehouse}_${condition}_${detph}_${application}_${tire}_tires`,
                                                                                    `${company}_${subsidiary}_${warehouse}_${condition}_${detph}_${application}_${tire}`,
                                                                                    "warehouse"
                                                                                  )
                                                                                }
                                                                              >
                                                                                <td
                                                                                  className="text-left"
                                                                                  style={{
                                                                                    paddingTop: 27,
                                                                                  }}
                                                                                >
                                                                                  {corporate[1][
                                                                                    company
                                                                                  ][
                                                                                    subsidiary
                                                                                  ][
                                                                                    warehouse
                                                                                  ][
                                                                                    condition
                                                                                  ][
                                                                                    detph
                                                                                  ][
                                                                                    application
                                                                                  ][
                                                                                    tire
                                                                                  ][
                                                                                    "code"
                                                                                  ] ||
                                                                                    corporate[1][
                                                                                      company
                                                                                    ][
                                                                                      subsidiary
                                                                                    ][
                                                                                      warehouse
                                                                                    ][
                                                                                      condition
                                                                                    ][
                                                                                      detph
                                                                                    ][
                                                                                      application
                                                                                    ][
                                                                                      tire
                                                                                    ][
                                                                                      "device_code"
                                                                                    ]}
                                                                                </td>
                                                                                <td className="text-right col5">
                                                                                  <small className="text-muted">
                                                                                    {t(
                                                                                      "_labels.report_warehouse.movement_date"
                                                                                    )}
                                                                                  </small>
                                                                                  <br />
                                                                                  {
                                                                                    corporate[1][
                                                                                      company
                                                                                    ][
                                                                                      subsidiary
                                                                                    ][
                                                                                      warehouse
                                                                                    ][
                                                                                      condition
                                                                                    ][
                                                                                      detph
                                                                                    ][
                                                                                      application
                                                                                    ][
                                                                                      tire
                                                                                    ][
                                                                                      "movement_date"
                                                                                    ]
                                                                                  }
                                                                                </td>

                                                                                <td
                                                                                  colSpan={
                                                                                    3
                                                                                  }
                                                                                  className="text-right col3"
                                                                                >
                                                                                  <small className="text-muted">
                                                                                    {t(
                                                                                      "_labels.report_warehouse.model"
                                                                                    )}
                                                                                  </small>
                                                                                  <br />
                                                                                  {
                                                                                    corporate[1][
                                                                                      company
                                                                                    ][
                                                                                      subsidiary
                                                                                    ][
                                                                                      warehouse
                                                                                    ][
                                                                                      condition
                                                                                    ][
                                                                                      detph
                                                                                    ][
                                                                                      application
                                                                                    ][
                                                                                      tire
                                                                                    ][
                                                                                      "model"
                                                                                    ]
                                                                                  }
                                                                                </td>
                                                                                <td className="text-right col5">
                                                                                  <small className="text-muted">
                                                                                    {t(
                                                                                      "_labels.report_warehouse.size"
                                                                                    )}
                                                                                  </small>
                                                                                  <br />
                                                                                  {
                                                                                    corporate[1][
                                                                                      company
                                                                                    ][
                                                                                      subsidiary
                                                                                    ][
                                                                                      warehouse
                                                                                    ][
                                                                                      condition
                                                                                    ][
                                                                                      detph
                                                                                    ][
                                                                                      application
                                                                                    ][
                                                                                      tire
                                                                                    ][
                                                                                      "size"
                                                                                    ]
                                                                                  }
                                                                                </td>
                                                                                <td className="text-right col5">
                                                                                  <small className="text-muted">
                                                                                    {t(
                                                                                      "_labels.report_warehouse.depth"
                                                                                    )}
                                                                                  </small>
                                                                                  <br />
                                                                                  {
                                                                                    corporate[1][
                                                                                      company
                                                                                    ][
                                                                                      subsidiary
                                                                                    ][
                                                                                      warehouse
                                                                                    ][
                                                                                      condition
                                                                                    ][
                                                                                      detph
                                                                                    ][
                                                                                      application
                                                                                    ][
                                                                                      tire
                                                                                    ][
                                                                                      "depth"
                                                                                    ]
                                                                                  }
                                                                                </td>
                                                                              </tr>
                                                                            </Fragment>
                                                                          )
                                                                        )}
                                                                    </Fragment>
                                                                  )
                                                                )}
                                                            </Fragment>
                                                          ))}
                                                      </Fragment>
                                                    ))}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <FilterWarehouseForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string, replacer = "") {
  return string.replaceAll(/[,\s\.\/#]/g, replacer);
}
