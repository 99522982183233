import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionUpdateProfile } from "store/actions/account/updateProfile";
import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE = "components/organisms/account/UpdateProfileForm";

export function UpdateProfileForm({ open, onClose, ...rest }) {
  const t = useLang(NAMESPACE);
  const { account, updateProfile, setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      password: "",
      confirm_password: "",
      preferred_language: "",
    },
  });

  useEffect(() => {
    if (account) {
      setInitialValues({
        password: "",
        confirm_password: "",
        preferred_language: account.preferred_language,
      });
    }
  }, [account]);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  function handleValidate(values) {
    let errors = handleFormikValidate(values);

    if (
      values.password.trim() !== "" &&
      values.password !== values.confirm_password
    ) {
      errors.confirm_password = t("_errors.different_passwords");
    }
    return errors;
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        preferred_language: values.preferred_language,
      };

      if (values.password.trim() !== "") {
        fields.password = values.password;
      }

      await updateProfile(fields);
      setNotification({
        message: t("messages.profile_updated"),
      });
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
      open={open}
      onClose={onClose}
    />
  );
}

UpdateProfileForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({ account: state.Account.account });
const mapDispatchToProps = (dispatch) => ({
  updateProfile: actionUpdateProfile(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfileForm);
