export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE";
export const USER_LOGOUT = "USER_LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const WORK_AREA = "WORK_AREA";
export const WORK_AREA_NAME = "WORK_AREA_NAME";
export const WORK_AREA_ID = "WORK_AREA_ID";
export const SET_ROUTER = "SET_ROUTER";

export const GET_TIRE_ALERTS = "GET_TIRE_ALERTS";
export const CLEAR_TIRE_ALERTS = "CLEAR_TIRE_ALERTS";
export const SET_TIRE_ALERTS_FILTERS = "SET_TIRE_ALERTS_FILTERS";
export const CLEAR_TIRE_ALERTS_FILTERS = "CLEAR_TIRE_ALERTS_FILTERS";
export const GET_VEHICLE_ALERTS = "GET_VEHICLE_ALERTS";
export const CLEAR_VEHICLE_ALERTS = "CLEAR_VEHICLE_ALERTS";
export const CLEAR_VEHICLE_ALERTS_FILTERS = "CLEAR_VEHICLE_ALERTS_FILTERS";
export const SET_VEHICLE_ALERTS_FILTERS = "SET_VEHICLE_ALERTS_FILTERS";
export const GET_MOUNT_ALERTS = "GET_MOUNT_ALERTS";
export const CLEAR_MOUNT_ALERTS = "CLEAR_MOUNT_ALERTS";
export const CLEAR_MOUNT_ALERTS_FILTERS = "CLEAR_MOUNT_ALERTS_FILTERS";
export const SET_MOUNT_ALERTS_FILTERS = "SET_MOUNT_ALERTS_FILTERS";

export const DELETE_VEHICLE_ALERT = "DELETE_VEHICLE_ALERT";
export const UPDATE_VEHICLE_ALERT = "UPDATE_VEHICLE_ALERT";
export const GET_ALERTS_OF_VEHICLE = "GET_ALERTS_OF_VEHICLE";
export const GET_VEHICLE_ALERT = "GET_VEHICLE_ALERT";
export const CLEAR_VEHICLE_ALERT = "CLEAR_VEHICLE_ALERT";
export const CLEAR_ALERTS_OF_VEHICLE = "CLEAR_ALERTS_OF_VEHICLE";
export const SET_ALERTS_OF_VEHICLE_FILTERS = "SET_ALERTS_OF_VEHICLE_FILTERS";
export const CLEAR_ALERTS_OF_VEHICLE_FILTERS =
  "CLEAR_ALERTS_OF_VEHICLE_FILTERS";

export const REGISTER_USER = "REGISTER_USER";
export const GET_USERS = "GET_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const CLEAR_USER_FILTERS = "CLEAR_USER_FILTERS";
export const SET_USER_FILTERS = "SET_USER_FILTERS";
export const SET_ROOT_USER = "SET_ROOT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_USER_STATUS = "SET_USER_STATUS";
export const GET_USER_PERMITS = "GET_USER_PERMITS";
export const GET_USER_SUBSIDIARIES = "GET_USER_SUBSIDIARIES";
export const CLEAR_USER_SUBSIDIARIES = "CLEAR_USER_SUBSIDIARIES";
export const GET_USER_SUBSIDIARY = "GET_USER_SUBSIDIARY";
export const CLEAR_USER_SUBSIDIARY = "CLEAR_USER_SUBSIDIARY";
export const GET_USER_SUBSIDIARY_ROLE = "GET_USER_SUBSIDIARY_ROLE";
export const CLEAR_USER_SUBSIDIARY_ROLE = "CLEAR_USER_SUBSIDIARY_ROLE";
export const GET_USER_SUBSIDIARY_HISTORY_ROLE =
  "GET_USER_SUBSIDIARY_HISTORY_ROLE";
export const CLEAR_USER_SUBSIDIARY_HISTORY_ROLE =
  "CLEAR_USER_SUBSIDIARY_HISTORY_ROLE";
export const GET_USER_SUBSIDIARY_ROLES_HISTORY =
  "GET_USER_SUBSIDIARY_ROLES_HISTORY";
export const CLEAR_USER_SUBSIDIARY_ROLES_HISTORY =
  "CLEAR_USER_SUBSIDIARY_ROLES_HISTORY";

export const GET_USER_SUBSIDIARIES_HISTORY = "GET_USER_SUBSIDIARIES_HISTORY";
export const CLEAR_USER_SUBSIDIARIES_HISTORY =
  "CLEAR_USER_SUBSIDIARIES_HISTORY";
export const SET_USER_SUBSIDIARIES_FILTERS = "SET_USER_SUBSIDIARIES_FILTERS";
export const CLEAR_USER_SUBSIDIARIES_FILTERS =
  "CLEAR_USER_SUBSIDIARIES_FILTERS";
export const CLEAR_USER_SUBSIDIARIES_HISTORY_FILTERS =
  "CLEAR_USER_SUBSIDIARIES_HISTORY_FILTERS";
export const SET_USER_SUBSIDIARIES_HISTORY_FILTERS =
  "SET_USER_SUBSIDIARIES_HISTORY_FILTERS";

export const DELETE_USER_IN_SUBSIDIARY = "DELETE_USER_IN_SUBSIDIARY";

export const GET_USER_COMPANIES = "GET_USER_COMPANIES";
export const CLEAR_USER_COMPANIES = "CLEAR_USER_COMPANIES";
export const SET_USER_COMPANY_FILTERS = "SET_USER_COMPANY_FILTERS";
export const CLEAR_USER_COMPANY_FILTERS = "CLEAR_USER_COMPANY_FILTERS";
export const GET_USER_COMPANY = "GET_USER_COMPANY";
export const CLEAR_USER_COMPANY = "CLEAR_USER_COMPANY";
export const GET_USER_COMPANY_ROLE = "GET_USER_COMPANY_ROLE";
export const CLEAR_USER_COMPANY_ROLE = "CLEAR_USER_COMPANY_ROLE";
export const GET_USER_COMPANY_HISTORY_ROLE = "GET_USER_COMPANY_HISTORY_ROLE";
export const CLEAR_USER_COMPANY_HISTORY_ROLE =
  "CLEAR_USER_COMPANY_HISTORY_ROLE";
export const GET_USER_COMPANY_ROLES_HISTORY = "GET_USER_COMPANY_ROLES_HISTORY";
export const CLEAR_USER_COMPANY_ROLES_HISTORY =
  "CLEAR_USER_COMPANY_ROLES_HISTORY";
export const GET_USER_COMPANIES_HISTORY = "GET_USER_COMPANIES_HISTORY";
export const CLEAR_USER_COMPANIES_HISTORY = "CLEAR_USER_COMPANIES_HISTORY";
export const SET_USER_COMPANIES_HISTORY_FILTERS =
  "SET_USER_COMPANIES_HISTORY_FILTERS";
export const CLEAR_USER_COMPANIES_HISTORY_FILTERS =
  "CLEAR_USER_COMPANIES_HISTORY_FILTERS";
export const DELETE_USER_IN_COMPANY = "DELETE_USER_IN_COMPANY";

export const GET_USER_CORPORATES = "GET_USER_CORPORATES";
export const CLEAR_USER_CORPORATES = "CLEAR_USER_CORPORATES";
export const SET_USER_CORPORATES_HISTORY_FILTERS =
  "SET_USER_CORPORATES_HISTORY_FILTERS";
export const CLEAR_USER_CORPORATES_HISTORY_FILTERS =
  "CLEAR_USER_CORPORATES_HISTORY_FILTERS";
export const ASSIGN_USER_TO_CORPORATE = "ASSIGN_USER_TO_CORPORATE";
export const DELETE_USER_IN_CORPORATE = "DELETE_USER_IN_CORPORATE";
export const SET_USER_CORPORATE_FILTERS = "SET_USER_CORPORATE_FILTERS";
export const CLEAR_USER_CORPORATE_FILTERS = "CLEAR_USER_CORPORATE_FILTERS";
export const ASSIGN_USER_ROLE_IN_CORPORATE = "ASSIGN_USER_ROLE_IN_CORPORATE";
export const DELETE_USER_ROLE_IN_CORPORATE = "DELETE_USER_ROLE_IN_CORPORATE";
export const GET_USER_CORPORATE = "GET_USER_CORPORATE";
export const CLEAR_USER_CORPORATE = "CLEAR_USER_CORPORATE";
export const GET_USER_CORPORATE_HISTORY = "GET_USER_CORPORATE_HISTORY";
export const CLEAR_USER_CORPORATE_HISTORY = "CLEAR_USER_CORPORATE_HISTORY";
export const GET_USER_CORPORATE_ROLE = "GET_USER_CORPORATE_ROLE";
export const CLEAR_USER_CORPORATE_ROLE = "CLEAR_USER_CORPORATE_ROLE";
export const GET_USER_CORPORATE_HISTORY_ROLE =
  "GET_USER_CORPORATE_HISTORY_ROLE";
export const CLEAR_USER_CORPORATE_HISTORY_ROLE =
  "CLEAR_USER_CORPORATE_HISTORY_ROLE";
export const GET_USER_CORPORATE_ROLES_HISTORY =
  "GET_USER_CORPORATE_ROLES_HISTORY";
export const CLEAR_USER_CORPORATE_ROLES_HISTORY =
  "CLEAR_USER_CORPORATE_ROLES_HISTORY";
export const GET_USER_CORPORATES_HISTORY = "GET_USER_CORPORATES_HISTORY";
export const CLEAR_USER_CORPORATES_HISTORY = "CLEAR_USER_CORPORATES_HISTORY";
export const GET_CORPORATE_USERS_HISTORY = "GET_CORPORATE_USERS_HISTORY";
export const CLEAR_CORPORATE_USERS_HISTORY = "CLEAR_CORPORATE_USERS_HISTORY";

export const REGISTER_DAMAGE = "REGISTER_DAMAGE";
export const UPDATE_DAMAGE = "UPDATE_DAMAGE";
export const DELETE_DAMAGE = "DELETE_DAMAGE";
export const GET_DAMAGES = "GET_DAMAGES";
export const CLEAR_DAMAGES = "CLEAR_DAMAGES";
export const GET_DAMAGE = "GET_DAMAGE";
export const CLEAR_DAMAGE = "CLEAR_DAMAGE";
export const SET_DAMAGE_FILTERS = "SET_DAMAGE_FILTERS";
export const CLEAR_DAMAGE_FILTERS = "CLEAR_DAMAGE_FILTERS";
export const SET_DAMAGE_STATUS = "SET_DAMAGE_STATUS";
export const GET_DAMAGE_TIRES = "GET_DAMAGE_TIRES";
export const CLEAR_DAMAGE_TIRES = "CLEAR_DAMAGE_TIRES";
export const SET_DAMAGE_TIRE_FILTERS = "SET_DAMAGE_TIRE_FILTERS";
export const CLEAR_DAMAGE_TIRE_FILTERS = "CLEAR_DAMAGE_TIRE_FILTERS";

export const GET_WEAR = "GET_WEAR";
export const CLEAR_WEAR = "CLEAR_WEAR";
export const GET_WEARS = "GET_WEARS";
export const CLEAR_WEARS = "CLEAR_WEARS";
export const REGISTER_WEAR = "REGISTER_WEAR";
export const UPDATE_WEAR = "UPDATE_WEAR";
export const DELETE_WEAR = "DELETE_WEAR";
export const SET_WEAR_FILTERS = "SET_WEAR_FILTERS";
export const CLEAR_WEAR_FILTERS = "CLEAR_WEAR_FILTERS";
export const SET_WEAR_STATUS = "SET_WEAR_STATUS";

export const GET_WEAR_TIRES = "GET_WEAR_TIRES";
export const CLEAR_WEAR_TIRES = "CLEAR_WEAR_TIRES";
export const SET_WEAR_TIRE_FILTERS = "SET_WEAR_TIRE_FILTERS";
export const CLEAR_WEAR_TIRE_FILTERS = "CLEAR_WEAR_TIRE_FILTERS";

export const REGISTER_CORPORATE = "REGISTER_CORPORATE";
export const GET_CORPORATES = "GET_CORPORATES";
export const GET_CORPORATE = "GET_CORPORATE";
export const DELETE_CORPORATE = "DELETE_CORPORATE";
export const CLEAR_CORPORATES = "CLEAR_CORPORATES";
export const CLEAR_CORPORATE = "CLEAR_CORPORATE";
export const SET_CORPORATE_FILTERS = "SET_CORPORATE_FILTERS";
export const CLEAR_CORPORATE_FILTERS = "CLEAR_CORPORATE_FILTERS";
export const SET_STATUS_CORPORATE = "SET_STATUS_CORPORATE";
export const UPDATE_CORPORATE = "UPDATE_CORPORATE";
export const GET_CORPORATE_USERS = "GET_CORPORATE_USERS";
export const CLEAR_CORPORATE_USERS = "CLEAR_CORPORATE_USERS";

export const SET_CORPORATE_USERS_HISTORY_FILTERS =
  "SET_CORPORATE_USERS_HISTORY_FILTERS";
export const CLEAR_CORPORATE_USERS_HISTORY_FILTERS =
  "CLEAR_CORPORATE_USERS_HISTORY_FILTERS";
export const GET_CORPORATE_COMPANIES = "GET_CORPORATE_COMPANIES";
export const CLEAR_CORPORATE_COMPANIES = "CLEAR_CORPORATE_COMPANIES";
export const SET_CORPORATE_USERS_FILTERS = "SET_CORPORATE_USERS_FILTERS";
export const CLEAR_CORPORATE_USERS_FILTERS = "CLEAR_CORPORATE_USERS_FILTERS";

export const REGISTER_COMPANY = "REGISTER_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";
export const SET_COMPANY_FILTERS = "SET_COMPANY_FILTERS";
export const CLEAR_COMPANY_FILTERS = "CLEAR_COMPANY_FILTERS";
export const GET_COMPANY = "GET_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const SET_STATUS_COMPANY = "SET_STATUS_COMPANY";
export const GET_COMPANY_SUBSIDIARIES = "GET_COMPANY_SUBSIDIARIES";
export const CLEAR_COMPANY_SUBSIDIARIES = "CLEAR_COMPANY_SUBSIDIARIES";
export const GET_COMPANY_USERS = "GET_COMPANY_USERS";
export const CLEAR_COMPANY_USERS = "CLEAR_COMPANY_USERS";
export const SET_COMPANY_USER_FILTERS = "SET_COMPANY_USER_FILTERS";
export const CLEAR_COMPANY_USER_FILTERS = "CLEAR_COMPANY_USER_FILTERS";

export const GET_COMPANY_USERS_HISTORY = "GET_COMPANY_USERS_HISTORY";
export const CLEAR_COMPANY_USERS_HISTORY = "CLEAR_COMPANY_USERS_HISTORY";
export const SET_COMPANY_USERS_HISTORY_FILTERS =
  "SET_COMPANY_USERS_HISTORY_FILTERS";
export const CLEAR_COMPANY_USERS_HISTORY_FILTERS =
  "CLEAR_COMPANY_USERS_HISTORY_FILTERS";
export const GET_COMPANY_POLICY = "GET_COMPANY_POLICY";
export const CLEAR_COMPANY_POLICY = "CLEAR_COMPANY_POLICY";
export const UPDATE_COMPANY_POLICY = "UPDATE_COMPANY_POLICY";
export const NOTIFICATION = "NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const REGISTER_ASSOCIATION = "REGISTER_ASSOCIATION";
export const GET_ASSOCIATIONS = "GET_ASSOCIATIONS";
export const CLEAR_ASSOCIATIONS = "CLEAR_ASSOCIATIONS";
export const SET_ASSOCIATION_FILTERS = "SET_ASSOCIATION_FILTERS";
export const CLEAR_ASSOCIATION_FILTERS = "CLEAR_ASSOCIATION_FILTERS";
export const GET_ASSOCIATION = "GET_ASSOCIATION";
export const CLEAR_ASSOCIATION = "CLEAR_ASSOCIATION";
export const UPDATE_ASSOCIATION = "UPDATE_ASSOCIATION";
export const DELETE_ASSOCIATION = "DELETE_ASSOCIATION";
export const SET_STATUS_ASSOCIATION = "SET_STATUS_ASSOCIATION";

export const GET_ASSOCIATION_PRESSURE_POLICIES =
  "GET_ASSOCIATION_PRESSURE_POLICIES";
export const CLEAR_ASSOCIATION_PRESSURE_POLICIES =
  "CLEAR_ASSOCIATION_PRESSURE_POLICIES";
export const REGISTER_ASSOCIATION_PRESSURE_POLICY =
  "REGISTER_ASSOCIATION_PRESSURE_POLICY";
export const UPDATE_ASSOCIATION_PRESSURE_POLICY =
  "UPDATE_ASSOCIATION_PRESSURE_POLICY";
export const DELETE_ASSOCIATION_PRESSURE_POLICY =
  "UPDATE_ASSOCIATION_PRESSURE_POLICY";
export const SET_ASSOCIATION_PRESSURE_POLICIES_FILTERS =
  "SET_ASSOCIATION_PRESSURE_POLICIES_FILTERS";
export const CLEAR_ASSOCIATION_PRESSURE_POLICIES_FILTERS =
  "CLEAR_ASSOCIATION_PRESSURE_POLICIES_FILTERS";

export const GET_ASSOCIATION_DEPTH_POLICIES = "GET_ASSOCIATION_DEPTH_POLICIES";
export const CLEAR_ASSOCIATION_DEPTH_POLICIES =
  "CLEAR_ASSOCIATION_DEPTH_POLICIES";
export const REGISTER_ASSOCIATION_DEPTH_POLICY =
  "REGISTER_ASSOCIATION_DEPTH_POLICY";
export const UPDATE_ASSOCIATION_DEPTH_POLICY =
  "UPDATE_ASSOCIATION_DEPTH_POLICY";
export const DELETE_ASSOCIATION_DEPTH_POLICY =
  "UPDATE_ASSOCIATION_DEPTH_POLICY";
export const SET_ASSOCIATION_DEPTH_POLICIES_FILTERS =
  "SET_ASSOCIATION_DEPTH_POLICIES_FILTERS";
export const CLEAR_ASSOCIATION_DEPTH_POLICIES_FILTERS =
  "CLEAR_ASSOCIATION_DEPTH_POLICIES_FILTERS";

export const REGISTER_REVITALIZED_TIRE_MODEL =
  "REGISTER_REVITALIZED_TIRE_MODEL";
export const GET_REVITALIZED_TIRE_MODEL = "GET_REVITALIZED_TIRE_MODEL";
export const CLEAR_REVITALIZED_TIRE_MODEL = "CLEAR_REVITALIZED_TIRE_MODEL";
export const GET_REVITALIZED_TIRE_MODELS = "GET_REVITALIZED_TIRE_MODELS";
export const CLEAR_REVITALIZED_TIRE_MODELS = "CLEAR_REVITALIZED_TIRE_MODELS";
export const SET_REVITALIZED_TIRE_MODEL_FILTERS =
  "SET_REVITALIZED_TIRE_MODEL_FILTERS";
export const CLEAR_REVITALIZED_TIRE_MODEL_FILTERS =
  "CLEAR_REVITALIZED_TIRE_MODEL_FILTERS";
export const UPDATE_REVITALIZED_TIRE_MODEL = "UPDATE_REVITALIZED_TIRE_MODEL";
export const SET_REVITALIZED_TIRE_MODEL_STATUS =
  "SET_REVITALIZED_TIRE_MODEL_STATUS";
export const SET_REVITALIZED_TIRE_MODEL_APPROVAL_STATUS =
  "SET_REVITALIZED_TIRE_MODEL_APPROVAL_STATUS";
export const DELETE_REVITALIZED_TIRE_MODEL = "DELETE_REVITALIZED_TIRE_MODEL";

export const REGISTER_SUBSIDIARY = "REGISTER_SUBSIDIARY";
export const GET_SUBSIDIARIES = "GET_SUBSIDIARIES";
export const CLEAR_SUBSIDIARIES = "CLEAR_SUBSIDIARIES";
export const SET_SUBSIDIARY_FILTERS = "SET_SUBSIDIARY_FILTERS";
export const CLEAR_SUBSIDIARY_FILTERS = "CLEAR_SUBSIDIARY_FILTERS";
export const GET_SUBSIDIARY = "GET_SUBSIDIARY";
export const CLEAR_SUBSIDIARY = "CLEAR_SUBSIDIARY";
export const UPDATE_SUBSIDIARY = "UPDATE_SUBSIDIARY";
export const DELETE_SUBSIDIARY = "DELETE_SUBSIDIARY";
export const SET_STATUS_SUBSIDIARY = "SET_STATUS_SUBSIDIARY";
export const GET_SUBSIDIARY_USERS = "GET_SUBSIDIARY_USERS";
export const CLEAR_SUBSIDIARY_USERS = "CLEAR_SUBSIDIARY_USERS";
export const SET_SUBSIDIARY_USERS_FILTERS = "SET_SUBSIDIARY_USERS_FILTERS";
export const CLEAR_SUBSIDIARY_USERS_FILTERS = "CLEAR_SUBSIDIARY_USERS_FILTERS";
export const GET_SUBSIDIARY_DRIVER = "GET_SUBSIDIARY_DRIVER";
export const CLEAR_SUBSIDIARY_DRIVER = "CLEAR_SUBSIDIARY_DRIVER";
export const GET_SUBSIDIARY_DRIVERS = "GET_SUBSIDIARY_DRIVERS";
export const CLEAR_SUBSIDIARY_DRIVERS = "CLEAR_SUBSIDIARY_DRIVERS";
export const GET_SUBSIDIARY_DRIVERS_HISTORY = "GET_SUBSIDIARY_DRIVERS_HISTORY";
export const CLEAR_SUBSIDIARY_DRIVERS_HISTORY =
  "CLEAR_SUBSIDIARY_DRIVERS_HISTORY";
export const SET_SUBSIDIARY_DRIVERS_HISTORY_FILTERS =
  "SET_SUBSIDIARY_DRIVERS_HISTORY_FILTERS";
export const CLEAR_SUBSIDIARY_DRIVERS_HISTORY_FILTERS =
  "CLEAR_SUBSIDIARY_DRIVERS_HISTORY_FILTERS";

export const GET_SUBSIDIARY_DIVISIONS = "GET_SUBSIDIARY_DIVISIONS";
export const CLEAR_SUBSIDIARY_DIVISIONS = "CLEAR_SUBSIDIARY_DIVISIONS";
export const SET_SUBSIDIARY_DIVISION_FILTERS =
  "SET_SUBSIDIARY_DIVISION_FILTERS";
export const CLEAR_SUBSIDIARY_DIVISION_FILTERS =
  "CLEAR_SUBSIDIARY_DIVISION_FILTERS";
export const CLEAR_SUBSIDIARY_WAREHOUSE = "CLEAR_SUBSIDIARY_WAREHOUSE";
export const GET_SUBSIDIARY_WAREHOUSE = "GET_SUBSIDIARY_WAREHOUSE";
export const GET_SUBSIDIARY_WAREHOUSES = "GET_SUBSIDIARY_WAREHOUSES";
export const CLEAR_SUBSIDIARY_WAREHOUSES = "CLEAR_SUBSIDIARY_WAREHOUSES";
export const SET_SUBSIDIARY_WAREHOUSES_FILTERS =
  "SET_SUBSIDIARY_WAREHOUSES_FILTERS";
export const CLEAR_SUBSIDIARY_WAREHOUSES_FILTERS =
  "CLEAR_SUBSIDIARY_WAREHOUSES_FILTERS";
export const GET_SUBSIDIARY_WAREHOUSES_HISTORY =
  "GET_SUBSIDIARY_WAREHOUSES_HISTORY";
export const CLEAR_SUBSIDIARY_WAREHOUSES_HISTORY =
  "CLEAR_SUBSIDIARY_WAREHOUSES_HISTORY";
export const ASSIGN_WAREHOUSE_TO_SUBSIDIARY = "ASSIGN_WAREHOUSE_TO_SUBSIDIARY";
export const GET_SUBSIDIARY_TIRE = "GET_SUBSIDIARY_TIRE";
export const CLEAR_SUBSIDIARY_TIRE = "CLEAR_SUBSIDIARY_TIRE";
export const GET_SUBSIDIARY_TIRES = "GET_SUBSIDIARY_TIRES";
export const CLEAR_SUBSIDIARY_TIRES = "CLEAR_SUBSIDIARY_TIRES";
export const SET_SUBSIDIARY_TIRES_FILTER = "SET_SUBSIDIARY_TIRES_FILTER";
export const CLEAR_SUBSIDIARY_TIRES_FILTER = "CLEAR_SUBSIDIARY_TIRES_FILTER";
export const DELETE_LINK_TIRE_WITH_SUBSIDIARY =
  "DELETE_LINK_TIRE_WITH_SUBSIDIARY";

export const GET_SUBSIDIARY_VEHICLE = "GET_SUBSIDIARY_VEHICLE";
export const CLEAR_SUBSIDIARY_VEHICLE = "CLEAR_SUBSIDIARY_VEHICLE";

export const GET_SUBSIDIARY_VEHICLES = "GET_SUBSIDIARY_VEHICLES";
export const CLEAR_SUBSIDIARY_VEHICLES = "CLEAR_SUBSIDIARY_VEHICLES";
export const SET_SUBSIDIARY_VEHICLES_FILTERS =
  "SET_SUBSIDIARY_VEHICLES_FILTERS";
export const CLEAR_SUBSIDIARY_VEHICLES_FILTERS =
  "CLEAR_SUBSIDIARY_VEHICLES_FILTERS";
export const GET_SUBSIDIARY_VEHICLES_HISTORY =
  "GET_SUBSIDIARY_VEHICLES_HISTORY";
export const CLEAR_SUBSIDIARY_VEHICLES_HISTORY =
  "CLEAR_SUBSIDIARY_VEHICLES_HISTORY";
export const CREATE_VEHICLE_PRESSURE_POLICY = "CREATE_VEHICLE_PRESSURE_POLICY";
export const UPDATE_VEHICLE_PRESSURE_POLICY = "UPDATE_VEHICLE_PRESSURE_POLICY";
export const DELETE_VEHICLE_PRESSURE_POLICY = "DELETE_VEHICLE_PRESSURE_POLICY";
export const GET_VEHICLE_PRESSURE_POLICY = "GET_VEHICLE_PRESSURE_POLICY";
export const GET_VEHICLE_PRESSURE_POLICIES = "GET_VEHICLE_PRESSURE_POLICIES";
export const CLEAR_VEHICLE_PRESSURE_POLICIES =
  "CLEAR_VEHICLE_PRESSURE_POLICIES";
export const SET_VEHICLE_PRESSURE_POLICIES_FILTERS =
  "SET_VEHICLE_PRESSURE_POLICIES_FILTERS";
export const CLEAR_VEHICLE_PRESSURE_POLICIES_FILTERS =
  "CLEAR_VEHICLE_PRESSURE_POLICIES_FILTERS";

export const SET_SUBSIDIARY_DRIVERS_FILTERS = "SET_SUBSIDIARY_DRIVERS_FILTERS";
export const CLEAR_SUBSIDIARY_DRIVERS_FILTERS =
  "CLEAR_SUBSIDIARY_DRIVERS_FILTERS";
export const DELETE_LINK_VEHICLE_WITH_SUBSIDIARY =
  "DELETE_LINK_VEHICLE_WITH_SUBSIDIARY";
export const DELETE_LINK_VEHICLE_WITH_DIVISION =
  "DELETE_LINK_VEHICLE_WITH_DIVISION";

export const ASSIGN_DRIVER_TO_SUBSIDIARY = "ASSIGN_DRIVER_TO_SUBSIDIARY";
export const ASSIGN_TIRE_TO_SUBSIDIARY = "ASSIGN_TIRE_TO_SUBSIDIARY";
export const ASSIGN_VEHICLE_TO_SUBSIDIARY = "ASSIGN_VEHICLE_TO_SUBSIDIARY";

export const CLEAR_SUBSIDIARY_POLICY = "CLEAR_SUBSIDIARY_POLICY";
export const GET_SUBSIDIARY_POLICY = "GET_SUBSIDIARY_POLICY";
export const UPDATE_SUBSIDIARY_POLICY = "UPDATE_SUBSIDIARY_POLICY";

export const REGISTER_DIVISION = "REGISTER_DIVISION";
export const GET_DIVISIONS = "GET_DIVISIONS";
export const CLEAR_DIVISIONS = "CLEAR_DIVISIONS";
export const SET_DIVISION_FILTERS = "SET_DIVISION_FILTERS";
export const CLEAR_DIVISION_FILTERS = "CLEAR_DIVISION_FILTERS";
export const GET_DIVISION = "GET_DIVISION";
export const CLEAR_DIVISION = "CLEAR_DIVISION";
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const DELETE_DIVISION = "DELETE_DIVISION";
export const SET_DIVISION_STATUS = "SET_DIVISION_STATUS";

export const GET_DIVISION_TIRES = "GET_DIVISION_TIRES";
export const CLEAR_DIVISION_TIRES = "CLEAR_DIVISION_TIRES";
export const SET_DIVISION_TIRE_FILTERS = "SET_DIVISION_TIRE_FILTERS";
export const CLEAR_DIVISION_TIRE_FILTERS = "CLEAR_DIVISION_TIRE_FILTERS";

export const ASSIGN_TIRE_TO_DIVISION = "ASSIGN_TIRE_TO_DIVISION";
export const GET_DIVISION_TIRE = "GET_DIVISION_TIRE";
export const CLEAR_DIVISION_TIRE = "CLEAR_DIVISION_TIRE";
export const DELETE_LINK_TIRE_WITH_DIVISION = "DELETE_LINK_TIRE_WITH_DIVISION";
export const GET_DIVISION_TIRES_HISTORY = "GET_DIVISION_TIRES_HISTORY";
export const CLEAR_DIVISION_TIRES_HISTORY = "CLEAR_DIVISION_TIRES_HISTORY";
export const SET_DIVISION_TIRE_HISTORY_FILTERS =
  "SET_DIVISION_TIRE_HISTORY_FILTERS";
export const CLEAR_DIVISION_TIRE_HISTORY_FILTERS =
  "CLEAR_DIVISION_TIRE_HISTORY_FILTERS";

export const GET_TIRE_DIVISION = "GET_TIRE_DIVISION";
export const CLEAR_TIRE_DIVISION = "CLEAR_TIRE_DIVISION";

export const GET_DIVISION_VEHICLE = "GET_DIVISION_VEHICLE";
export const CLEAR_DIVISION_VEHICLE = "CLEAR_DIVISION_VEHICLE";

export const GET_DIVISION_VEHICLES = "GET_DIVISION_VEHICLES";
export const CLEAR_DIVISION_VEHICLES = "CLEAR_DIVISION_VEHICLES";
export const GET_DIVISION_VEHICLES_HISTORY = "GET_DIVISION_VEHICLES_HISTORY";
export const CLEAR_DIVISION_VEHICLES_HISTORY =
  "CLEAR_DIVISION_VEHICLES_HISTORY";
export const SET_DIVISION_VEHICLES_HISTORY_FILTERS =
  "SET_DIVISION_VEHICLES_HISTORY_FILTERS";
export const CLEAR_DIVISION_VEHICLES_HISTORY_FILTERS =
  "CLEAR_DIVISION_VEHICLES_HISTORY_FILTERS";
export const SET_DIVISION_VEHICLE_FILTERS = "SET_DIVISION_VEHICLE_FILTERS";
export const CLEAR_DIVISION_VEHICLE_FILTERS = "CLEAR_DIVISION_VEHICLE_FILTERS";
export const ASSIGN_VEHICLE_TO_DIVISION = "ASSIGN_VEHICLE_TO_DIVISION";

export const REGISTER_PROVIDER = "REGISTER_PROVIDER";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const CLEAR_PROVIDERS = "CLEAR_PROVIDERS";
export const GET_PROVIDER = "GET_PROVIDER";
export const CLEAR_PROVIDER = "CLEAR_PROVIDER";
export const SET_PROVIDER_FILTERS = "SET_PROVIDER_FILTERS";
export const CLEAR_PROVIDER_FILTERS = "CLEAR_PROVIDER_FILTERS";
export const SET_PROVIDER_STATUS = "SET_PROVIDER_STATUS";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";

export const REGISTER_WAREHOUSE = "REGISTER_WAREHOUSE";
export const GET_WAREHOUSES = "GET_WAREHOUSES";
export const CLEAR_WAREHOUSES = "CLEAR_WAREHOUSES";
export const CLEAR_WAREHOUSE = "CLEAR_WAREHOUSE";
export const GET_WAREHOUSE = "GET_WAREHOUSE";
export const SET_WAREHOUSE_FILTERS = "SET_WAREHOUSE_FILTERS";
export const CLEAR_WAREHOUSE_FILTERS = "CLEAR_WAREHOUSE_FILTERS";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const SET_WAREHOUSE_STATUS = "SET_WAREHOUSE_STATUS";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";

export const ASSIGN_TIRE_TO_WAREHOUSE = "ASSIGN_TIRE_TO_WAREHOUSE";
export const DELETE_LINK_TIRE_WITH_WAREHOUSE =
  "DELETE_LINK_TIRE_WITH_WAREHOUSE";
export const GET_WAREHOUSE_SUBSIDIARY = "GET_WAREHOUSE_SUBSIDIARY";
export const CLEAR_WAREHOUSE_SUBSIDIARY = "CLEAR_WAREHOUSE_SUBSIDIARY";
export const DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY =
  "DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY";
export const GET_WAREHOUSE_TIRE = "GET_WAREHOUSE_TIRE";
export const CLEAR_WAREHOUSE_TIRE = "CLEAR_WAREHOUSE_TIRE";
export const GET_WAREHOUSE_TIRES = "GET_WAREHOUSE_TIRES";
export const CLEAR_WAREHOUSE_TIRES = "CLEAR_WAREHOUSE_TIRES";
export const GET_WAREHOUSE_TIRES_HISTORY = "GET_WAREHOUSE_TIRES_HISTORY";
export const CLEAR_WAREHOUSE_TIRES_HISTORY = "CLEAR_WAREHOUSE_TIRES_HISTORY";
export const SET_WAREHOUSE_TIRE_FILTERS = "SET_WAREHOUSE_TIRE_FILTERS";
export const CLEAR_WAREHOUSE_TIRE_FILTERS = "CLEAR_WAREHOUSE_TIRE_FILTERS";

export const REGISTER_DRIVER = "REGISTER_DRIVER";
export const GET_DRIVER = "GET_DRIVER";
export const CLEAR_DRIVER = "CLEAR_DRIVER";
export const GET_DRIVERS = "GET_DRIVERS";
export const CLEAR_DRIVERS = "CLEAR_DRIVERS";
export const SET_DRIVER_STATUS = "SET_DRIVER_STATUS";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const SET_DRIVER_FILTERS = "SET_DRIVER_FILTERS";
export const CLEAR_DRIVER_FILTERS = "CLEAR_DRIVER_FILTERS";
export const DELETE_DRIVER = "DELETE_DRIVER";

export const DELETE_LINK_DRIVER_WITH_SUBSIDIARY =
  "DELETE_LINK_DRIVER_WITH_SUBSIDIARY";
export const GET_DRIVER_VEHICLE = "GET_DRIVER_VEHICLE";
export const CLEAR_DRIVER_VEHICLE = "CLEAR_DRIVER_VEHICLE";
export const GET_DRIVER_SUBSIDIARY = "GET_DRIVER_SUBSIDIARY";
export const CLEAR_DRIVER_SUBSIDIARY = "CLEAR_DRIVER_SUBSIDIARY";
export const GET_DRIVER_VEHICLES_HISTORY = "GET_DRIVER_VEHICLES_HISTORY";
export const CLEAR_DRIVER_VEHICLES_HISTORY = "CLEAR_DRIVER_VEHICLES_HISTORY";

export const REGISTER_BRAND = "REGISTER_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const CLEAR_BRANDS = "CLEAR_BRANDS";
export const SET_BRAND_FILTERS = "SET_BRAND_FILTERS";
export const CLEAR_BRAND_FILTERS = "CLEAR_BRAND_FILTERS";
export const GET_BRAND = "GET_BRAND";
export const CLEAR_BRAND = "CLEAR_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const SET_BRAND_STATUS = "SET_BRAND_STATUS";
export const SET_BRAND_APPROVAL_STATUS = "SET_BRAND_APPROVAL_STATUS";
export const DELETE_BRAND = "DELETE_BRAND";

export const REGISTER_TIRE_SIZE = "REGISTER_TIRE_SIZE";
export const GET_TIRE_SIZES = "GET_TIRE_SIZES";
export const CLEAR_TIRE_SIZES = "CLEAR_TIRE_SIZES";
export const SET_TIRE_SIZE_FILTERS = "SET_TIRE_SIZE_FILTERS";
export const CLEAR_TIRE_SIZE_FILTERS = "CLEAR_TIRE_SIZE_FILTERS";
export const GET_TIRE_SIZE = "GET_TIRE_SIZE";
export const CLEAR_TIRE_SIZE = "CLEAR_TIRE_SIZE";
export const SET_TIRE_SIZE_APPROVAL_STATUS = "SET_TIRE_SIZE_APPROVAL_STATUS";
export const SET_TIRE_SIZE_STATUS = "SET_TIRE_SIZE_STATUS";
export const DELETE_TIRE_SIZE = "DELETE_TIRE_SIZE";

export const CREATE_TIRE_MODEL = "REGISTER_TIRE_MODEL";
export const UPDATE_TIRE_MODEL = "UPDATE_TIRE_MODEL";
export const DELETE_TIRE_MODEL = "DELETE_TIRE_MODEL";
export const GET_TIRE_MODELS = "GET_TIRE_MODELS";
export const CLEAR_TIRE_MODELS = "CLEAR_TIRE_MODELS";
export const SET_TIRE_MODEL_FILTERS = "SET_TIRE_MODEL_FILTERS";
export const CLEAR_TIRE_MODEL_FILTERS = "CLEAR_TIRE_MODEL_FILTERS";
export const GET_TIRE_MODEL = "GET_TIRE_MODEL";
export const CLEAR_TIRE_MODEL = "CLEAR_TIRE_MODEL";
export const SET_TIRE_MODEL_STATUS = "SET_TIRE_MODEL_STATUS";
export const SET_TIRE_MODEL_APPROVAL_STATUS = "SET_TIRE_MODEL_APPROVAL_STATUS";
export const GET_TIRE_VARIATION_MODEL = "GET_TIRE_VARIATION_MODEL";

export const CLEAR_TIRE_MODEL_VARIATION = "CLEAR_TIRE_MODEL_VARIATION";
export const CREATE_TIRE_MODEL_VARIATION = "REGISTER_TIRE_MODEL_VARIATION";
export const UPDATE_TIRE_MODEL_VARIATION = "UPDATE_TIRE_MODEL_VARIATION";
export const GET_TIRE_MODEL_VARIATIONS = "GET_TIRE_MODEL_VARIATIONS";
export const CLEAR_TIRE_MODEL_VARIATIONS = "CLEAR_TIRE_MODEL_VARIATIONS";
export const SET_TIRE_MODEL_VARIATION_STATUS =
  "SET_TIRE_MODEL_VARIATION_STATUS";
export const SET_TIRE_MODEL_VARIATION_APPROVED =
  "SET_TIRE_MODEL_VARIATION_APPROVED";

export const GET_TIRE_APPLICATION_TYPES = "GET_TIRE_APPLICATION_TYPES";

export const GET_TIRE_USE_TYPES = "GET_TIRE_USE_TYPES";

export const REGISTER_VEHICLE = "REGISTER_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_VEHICLES_MOUNT = "GET_VEHICLES_MOUNT";
export const CLEAR_VEHICLES_MOUNT = "CLEAR_VEHICLES_MOUNT";
export const SET_VEHICLE_FILTERS_MOUNT = "SET_VEHICLE_FILTERS_MOUNT";
export const CLEAR_VEHICLE_FILTERS_MOUNT = "CLEAR_VEHICLE_FILTERS_MOUNT";
export const CLEAR_VEHICLES = "CLEAR_VEHICLES";
export const SET_VEHICLE_FILTERS = "SET_VEHICLE_FILTERS";
export const SET_SELECTED_VEHICLE_FILTERS = "SET_SELECTED_VEHICLE_FILTERS";
export const CLEAR_VEHICLE_FILTERS = "CLEAR_VEHICLE_FILTERS";
export const GET_VEHICLE = "GET_VEHICLE";
export const CLEAR_VEHICLE = "CLEAR_VEHICLE";
export const SET_VEHICLE_STATUS = "SET_VEHICLE_STATUS";

export const GET_VEHICLE_SUBSIDIARY = "GET_VEHICLE_SUBSIDIARY";
export const CLEAR_VEHICLE_SUBSIDIARY = "CLEAR_VEHICLE_SUBSIDIARY";
export const GET_VEHICLE_SUBSIDIARIES_HISTORY =
  "GET_VEHICLE_SUBSIDIARIES_HISTORY";
export const CLEAR_VEHICLE_SUBSIDIARIES_HISTORY =
  "CLEAR_VEHICLE_SUBSIDIARIES_HISTORY";

export const SET_VEHICLE_SUBSIDIARY_HISTORY_FILTERS =
  "SET_VEHICLE_SUBSIDIARY_HISTORY_FILTERS";
export const CLEAR_VEHICLE_SUBSIDIARY_HISTORY_FILTERS =
  "CLEAR_VEHICLE_SUBSIDIARY_HISTORY_FILTERS";

export const GET_VEHICLE_TIRE = "GET_VEHICLE_TIRE";
export const CLEAR_VEHICLE_TIRE = "CLEAR_VEHICLE_TIRE";
export const GET_VEHICLE_TIRES = "GET_VEHICLE_TIRES";
export const CLEAR_VEHICLE_TIRES = "CLEAR_VEHICLE_TIRES";
export const DELETE_LINK_TIRE_WITH_VEHICLE = "DELETE_LINK_TIRE_WITH_VEHICLE";

export const MOUNT_TIRE = "MOUNT_TIRE";
export const TIRE_ROTATION = "TIRE_ROTATION";
export const SINGLE_ROTATION = "SINGLE_ROTATION";

export const GET_VEHICLE_AXLES = "GET_VEHICLE_AXLES";
export const CLEAR_VEHICLE_AXLES = "CLEAR_VEHICLE_AXLES";

export const GET_LAST_VEHICLE_REVIEW = "GET_LAST_VEHICLE_REVIEW";
export const CLEAR_LAST_VEHICLE_REVIEW = "CLEAR_LAST_VEHICLE_REVIEW";
export const REGISTER_VEHICLE_REVIEW = "REGISTER_VEHICLE_REVIEW";
export const FINALIZE_VEHICLE_REVIEW = "FINALIZE_VEHICLE_REVIEW";
export const UPDATE_VEHICLE_REVIEW = "UPDATE_VEHICLE_REVIEW";

export const GET_GPS_LIST = "GET_GPS_LIST";
export const CLEAR_GPS_LIST = "CLEAR_GPS_LIST";
export const GET_GPS = "GET_GPS";
export const CLEAR_GPS = "CLEAR_GPS";
export const UPDATE_GPS = "UPDATE_GPS";
export const DELETE_GPS = "DELETE_GPS";
export const SET_GPS_STATUS = "SET_GPS_STATUS";
export const SET_GPS_FILTERS = "SET_GPS_FILTERS";
export const CLEAR_GPS_FILTERS = "CLEAR_GPS_FILTERS";

export const GET_GPS_GEOFENCE = "GET_GPS_GEOFENCE";
export const CLEAR_GPS_GEOFENCE = "CLEAR_GPS_GEOFENCE";
export const GET_GPS_GEOFENCE_LIST = "GET_GPS_GEOFENCE_LIST";
export const CLEAR_GPS_GEOFENCE_LIST = "CLEAR_GPS_GEOFENCE_LIST";
export const SET_GPS_GEOFENCE_FILTER = "SET_GPS_GEOFENCE_FILTER";
export const CLEAR_GPS_GEOFENCE_FILTER = "CLEAR_GPS_GEOFENCE_FILTER";

export const ASSIGN_VEHICLE_TO_GPS = "ASSIGN_VEHICLE_TO_GPS";
export const GET_VEHICLE_GPS = "GET_VEHICLE_GPS";
export const CLEAR_VEHICLE_GPS = "CLEAR_VEHICLE_GPS";
export const GET_GPS_VEHICLE = "GET_GPS_VEHICLE";
export const CLEAR_GPS_VEHICLE = "CLEAR_GPS_VEHICLE";
export const GET_GPS_VEHICLE_LINK = "GET_GPS_VEHICLE_LINK";
export const CLEAR_GPS_VEHICLE_LINK = "CLEAR_GPS_VEHICLE_LINK";
export const DELETE_LINK_VEHICLE_WITH_GPS = "DELETE_LINK_VEHICLE_WITH_GPS";

export const REGISTER_GPS = "REGISTER_GPS";

export const GET_VEHICLE_DRIVER = "GET_VEHICLE_DRIVER";
export const CLEAR_VEHICLE_DRIVER = "CLEAR_VEHICLE_DRIVER";
export const ASSIGN_VEHICLE_TO_DRIVER = "ASSIGN_VEHICLE_TO_DRIVER";
export const DELETE_LINK_VEHICLE_WITH_DRIVER =
  "DELETE_LINK_VEHICLE_WITH_DRIVER";
export const GET_VEHICLE_DIVISION = "GET_VEHICLE_DIVISION";
export const CLEAR_VEHICLE_DIVISION = "CLEAR_VEHICLE_DIVISION";

export const GET_VEHICLE_TRAVEL_HISTORY = "GET_VEHICLE_TRAVEL_HISTORY";
export const CLEAR_VEHICLE_TRAVEL_HISTORY = "CLEAR_VEHICLE_TRAVEL_HISTORY";
export const GET_VEHICLE_TRAVELS_HISTORY = "GET_VEHICLE_TRAVELS_HISTORY";
export const CLEAR_VEHICLE_TRAVELS_HISTORY = "CLEAR_VEHICLE_TRAVELS_HISTORY";
export const GET_LAST_VEHICLE_TRAVEL = "GET_LAST_VEHICLE_TRAVEL";
export const CLEAR_LAST_VEHICLE_TRAVEL = "CLEAR_LAST_VEHICLE_TRAVEL";
export const REGISTER_VEHICLE_TRAVEL = "REGISTER_VEHICLE_TRAVEL";
export const UPDATE_VEHICLE_TRAVEL = "UPDATE_VEHICLE_TRAVEL";
export const DELETE_VEHICLE_TRAVEL = "DELETE_VEHICLE_TRAVEL";
export const SET_VEHICLE_TRAVEL_HISTORY_FILTERS =
  "SET_VEHICLE_TRAVEL_HISTORY_FILTERS";
export const CLEAR_VEHICLE_TRAVEL_HISTORY_FILTERS =
  "CLEAR_VEHICLE_TRAVEL_HISTORY_FILTERS";

export const GET_VEHICLE_DRIVERS_HISTORY = "GET_VEHICLE_DRIVERS_HISTORY";
export const CLEAR_VEHICLE_DRIVERS_HISTORY = "CLEAR_VEHICLE_DRIVERS_HISTORY";

export const REGISTER_VEHICLE_TYPE = "REGISTER_VEHICLE_TYPE";
export const UPDATE_VEHICLE_TYPE = "UPDATE_VEHICLE_TYPE";
export const UPDATE_VEHICLE_TYPE_AXLE = "UPDATE_VEHICLE_TYPE_AXLE";
export const CREATE_VEHICLE_TYPE_AXLE = "CREATE_VEHICLE_TYPE_AXLE";
export const GET_VEHICLES_TYPES = "GET_VEHICLES_TYPES";
export const CLEAR_VEHICLES_TYPES = "CLEAR_VEHICLES_TYPES";
export const CLEAR_VEHICLE_TYPE_AXLES = "CLEAR_VEHICLE_TYPE_AXLES";
export const GET_VEHICLE_TYPE = "GET_VEHICLE_TYPE";
export const GET_VEHICLE_TYPE_AXLES = "GET_VEHICLE_TYPE_AXLES";
export const GET_VEHICLE_TYPE_AXLE = "GET_VEHICLE_TYPE_AXLE";
export const CLEAR_VEHICLE_TYPE_AXLE = "CLEAR_VEHICLE_TYPE_AXLE";
export const CLEAR_VEHICLE_TYPE = "CLEAR_VEHICLE_TYPE";
export const SET_VEHICLE_TYPE_FILTERS = "SET_VEHICLE_TYPE_FILTERS";
export const CLEAR_VEHICLE_TYPE_FILTERS = "CLEAR_VEHICLE_TYPE_FILTERS";
export const SET_VEHICLE_TYPE_APPROVAL_STATUS =
  "SET_VEHICLE_TYPE_APPROVAL_STATUS";
export const SET_VEHICLE_TYPE_STATUS = "SET_VEHICLE_TYPE_STATUS";
export const DELETE_VEHICLE_TYPE = "DELETE_VEHICLE_TYPE";

export const REGISTER_TIRE = "REGISTER_TIRE";
export const GET_TIRES = "GET_TIRES";
export const CLEAR_TIRES = "CLEAR_TIRES";
export const SET_TIRE_FILTERS = "SET_TIRE_FILTERS";
export const CLEAR_TIRE_FILTERS = "CLEAR_TIRE_FILTERS";
export const SET_TIRE_SELECTED_FILTERS = "SET_TIRE_SELECTED_FILTERS";
export const GET_TIRE = "GET_TIRE";
export const CLEAR_TIRE = "CLEAR_TIRE";
export const UPDATE_TIRE = "UPDATE_TIRE";
export const SET_TIRE_STATUS = "SET_TIRE_STATUS";
export const DELETE_TIRE = "DELETE_TIRE";
export const UPDATE_TIRE_CYCLE = "UPDATE_TIRE_CYCLE";
export const GET_TIRE_SUBSIDIARY = "GET_TIRE_SUBSIDIARY";
export const CLEAR_TIRE_SUBSIDIARY = "CLEAR_TIRE_SUBSIDIARY";

export const SET_TIRE_MOUNT_FILTERS = "SET_TIRE_MOUNT_FILTERS";
export const CLEAR_TIRE_MOUNT_FILTERS = "CLEAR_TIRE_MOUNT_FILTERS";

export const GET_TIRE_HISTORY = "GET_TIRE_HISTORY";
export const CLEAR_TIRE_HISTORY = "CLEAR_TIRE_HISTORY";
export const SET_TIRE_HISTORY_FILTERS = "SET_TIRE_HISTORY_FILTERS";
export const CLEAR_TIRE_HISTORY_FILTERS = "CLEAR_TIRE_HISTORY_FILTERS";

export const GET_TIRE_REVIEW_HISTORY = "GET_TIRE_REVIEW_HISTORY";
export const CLEAR_TIRE_REVIEW_HISTORY = "CLEAR_TIRE_REVIEW_HISTORY";
export const SET_TIRE_REVIEW_HISTORY_FILTERS =
  "SET_TIRE_REVIEW_HISTORY_FILTERS";
export const CLEAR_TIRE_REVIEW_HISTORY_FILTERS =
  "CLEAR_TIRE_REVIEW_HISTORY_FILTERS";

export const REGISTER_TIRE_REPAIR = "REGISTER_TIRE_REPAIR";
export const GET_TIRE_REPAIR = "GET_TIRE_REPAIR";
export const CLEAR_TIRE_REPAIR = "CLEAR_TIRE_REPAIR";
export const UPDATE_TIRE_REPAIR = "UPDATE_TIRE_REPAIR";
export const DELETE_TIRE_REPAIR = "DELETE_TIRE_REPAIR";

export const REGISTER_TIRE_DAMAGE = "REGISTER_TIRE_DAMAGE";
export const GET_TIRE_DAMAGES = "GET_TIRE_DAMAGES";
export const CLEAR_TIRE_DAMAGES = "CLEAR_TIRE_DAMAGES";
export const SET_TIRE_DAMAGE_FILTERS = "SET_TIRE_DAMAGE_FILTERS";
export const CLEAR_TIRE_DAMAGE_FILTERS = "CLEAR_TIRE_DAMAGE_FILTERS";
export const GET_TIRE_DAMAGE = "GET_TIRE_DAMAGE";
export const CLEAR_TIRE_DAMAGE = "CLEAR_TIRE_DAMAGE";
export const DELETE_TIRE_DAMAGE = "DELETE_TIRE_DAMAGE";

export const REGISTER_TIRE_WEAR = "REGISTER_TIRE_WEAR";
export const GET_TIRE_WEARS = "GET_TIRE_WEARS";
export const CLEAR_TIRE_WEARS = "CLEAR_TIRE_WEARS";
export const GET_TIRE_WEAR = "GET_TIRE_WEAR";
export const CLEAR_TIRE_WEAR = "CLEAR_TIRE_WEAR";
export const SET_TIRE_WEAR_FILTERS = "SET_TIRE_WEAR_FILTERS";
export const CLEAR_TIRE_WEAR_FILTERS = "CLEAR_TIRE_WEAR_FILTERS";
export const DELETE_TIRE_WEAR = "DELETE_TIRE_WEAR";

export const GET_TIRE_WAREHOUSE = "GET_TIRE_WAREHOUSE";
export const CLEAR_TIRE_WAREHOUSE = "CLEAR_TIRE_WAREHOUSE";
export const GET_TIRE_WAREHOUSES_HISTORY = "GET_TIRE_WAREHOUSES_HISTORY";
export const CLEAR_TIRE_WAREHOUSES_HISTORY = "CLEAR_TIRE_WAREHOUSES_HISTORY";
export const GET_TIRE_WAREHOUSE_HISTORY = "GET_TIRE_WAREHOUSE_HISTORY";
export const CLEAR_TIRE_WAREHOUSE_HISTORY = "CLEAR_TIRE_WAREHOUSE_HISTORY";

export const GET_TIRE_CYCLES_HISTORY = "GET_TIRE_CYCLES_HISTORY";
export const CLEAR_TIRE_CYCLES_HISTORY = "CLEAR_TIRE_CYCLES_HISTORY";
export const GET_TIRE_CYCLE_HISTORY = "GET_TIRE_CYCLE_HISTORY";
export const CLEAR_TIRE_CYCLE_HISTORY = "CLEAR_TIRE_CYCLE_HISTORY";

export const GET_TIRE_CYCLE = "GET_TIRE_CYCLE";
export const CLEAR_TIRE_CYCLE = "CLEAR_TIRE_CYCLE";
export const REGISTER_TIRE_CYCLE = "REGISTER_TIRE_CYCLE";
export const DELETE_TIRE_MODEL_VARIATION = "DELETE_TIRE_MODEL_VARIATION";
export const GET_TIRES_CONDITIONS = "GET_TIRES_CONDITIONS";
export const GET_TIRE_MODEL_VARIATION = "GET_TIRE_MODEL_VARIATION";

export const GET_TIRE_REVIEW = "GET_TIRE_REVIEW";
export const CLEAR_TIRE_REVIEW = "CLEAR_TIRE_REVIEW";
export const REGISTER_TIRE_REVIEW = "REGISTER_TIRE_REVIEW";
export const UPDATE_TIRE_REVIEW = "UPDATE_TIRE_REVIEW";

export const REGISTER_DEPTH_TOLERANCE_POLICY =
  "REGISTER_DEPTH_TOLERANCE_POLICY";
export const GET_DEPTH_TOLERANCE_POLICIES = "GET_DEPTH_TOLERANCE_POLICIES";
export const CLEAR_DEPTH_TOLERANCE_POLICIES = "CLEAR_DEPTH_TOLERANCE_POLICIES";
export const GET_DEPTH_TOLERANCE_POLICY = "GET_DEPTH_TOLERANCE_POLICY";
export const CLEAR_DEPTH_TOLERANCE_POLICY = "CLEAR_DEPTH_TOLERANCE_POLICY";
export const UPDATE_DEPTH_TOLERANCE_POLICY = "UPDATE_DEPTH_TOLERANCE_POLICY";
export const DELETE_DEPTH_TOLERANCE_POLICY = "DELETE_DEPTH_TOLERANCE_POLICY";

export const GET_RETIREMENT_CAUSES = "GET_RETIREMENT_CAUSES";
export const CLEAR_RETIREMENT_CAUSES = "CLEAR_RETIREMENT_CAUSES";
export const REGISTER_RETIREMENT_CAUSE = "REGISTER_RETIREMENT_CAUSE";
export const GET_RETIREMENT_CAUSE = "GET_RETIREMENT_CAUSE";
export const CLEAR_RETIREMENT_CAUSE = "CLEAR_RETIREMENT_CAUSE";
export const SET_RETIREMENT_CAUSE_FILTERS = "SET_RETIREMENT_CAUSE_FILTERS";
export const CLEAR_RETIREMENT_CAUSE_FILTERS = "CLEAR_RETIREMENT_CAUSE_FILTERS";
export const UPDATE_RETIREMENT_CAUSE = "UPDATE_RETIREMENT_CAUSE";
export const SET_RETIREMENT_CAUSE_STATUS = "SET_RETIREMENT_CAUSE_STATUS";
export const DELETE_RETIREMENT_CAUSE = "DELETE_RETIREMENT_CAUSE";

export const REGISTER_RFID = "REGISTER_RFID";
export const GET_RFIDS = "GET_RFIDS";
export const CLEAR_RFIDS = "CLEAR_RFIDS";
export const GET_RFID = "GET_RFID";
export const CLEAR_RFID = "CLEAR_RFID";
export const SET_RFID_FILTERS = "SET_RFID_FILTERS";
export const CLEAR_RFID_FILTERS = "CLEAR_RFID_FILTERS";
export const UPDATE_RFID = "UPDATE_RFID";
export const DELETE_RFID = "DELETE_RFID";
export const SET_RFID_STATUS = "SET_RFID_STATUS";

export const GET_TIRE_RFID = "GET_TIRE_RFID";
export const CLEAR_TIRE_RFID = "CLEAR_TIRE_RFID";
export const ASSIGN_RFID_TO_TIRE = "ASSIGN_RFID_TO_TIRE";
export const DELETE_LINK_TIRE_WITH_RFID = "DELETE_LINK_TIRE_WITH_RFID";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const INTERVAL_REFRESH = "INTERVAL_REFRESH";
export const REDIRECT = "REDIRECT";
export const PROGRESS = "PROGRESS";
export const LANGUAGE = "LANGUAGE";
export const PASSWORD_RESET = "PASSWORD_RESET";

export const REGISTER_ALERT = "REGISTER_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const GET_ALERTS = "GET_ALERTS";
export const SET_ALERT_FILTERS = "SET_ALERT_FILTERS";
export const CLEAR_ALERT_FILTERS = "CLEAR_ALERT_FILTERS";
export const GET_ALERT = "GET_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const SET_ALERT_STATUS = "SET_ALERT_STATUS";

export const REGISTER_FAVORITE = "REGISTER_FAVORITE";
export const DELETE_FAVORITE = "DELETE_FAVORITE";
export const GET_FAVORITES = "GET_FAVORITES";

export const GET_MODEL_VARIATION_POLICIES = "GET_MODEL_VARIATION_POLICIES";
export const CLEAR_MODEL_VARIATION_POLICIES = "CLEAR_MODEL_VARIATION_POLICIES";
export const REGISTER_MODEL_VARIATION_POLICY =
  "REGISTER_MODEL_VARIATION_POLICY";
export const DELETE_MODEL_VARIATION_POLICY = "DELETE_MODEL_VARIATION_POLICY";
export const UPDATE_MODEL_VARIATION_POLICY = "UPDATE_MODEL_VARIATION_POLICY";
export const GET_MODEL_VARIATION_POLICY = "GET_MODEL_VARIATION_POLICY";

export const REGISTER_CONDITION_POLICY = "REGISTER_CONDITION_POLICY";
export const UPDATE_CONDITION_POLICY = "UPDATE_CONDITION_POLICY";
export const DELETE_CONDITION_POLICY = "DELETE_CONDITION_POLICY";
export const GET_CONDITION_POLICIES = "GET_CONDITION_POLICIES";
export const CLEAR_CONDITION_POLICIES = "CLEAR_CONDITION_POLICIES";
export const SET_CONDITION_POLICIES_FILTERS = "SET_CONDITION_POLICIES_FILTERS";
export const CLEAR_CONDITION_POLICIES_FILTERS =
  "CLEAR_CONDITION_POLICIES_FILTERS";
export const GET_CONDITION_POLICY = "GET_CONDITION_POLICY";

export const GET_ALERTS_OF_TIRE = "GET_ALERTS_OF_TIRE";
export const CLEAR_ALERTS_OF_TIRE = "CLEAR_ALERTS_OF_TIRE";
export const UPDATE_TIRE_ALERT = "UPDATE_TIRE_ALERT";
export const GET_TIRE_ALERT = "GET_TIRE_ALERT";
export const CLEAR_TIRE_ALERT = "CLEAR_TIRE_ALERT";
export const SET_ALERTS_OF_TIRE_FILTERS = "SET_ALERTS_OF_TIRE_FILTERS";
export const CLEAR_ALERTS_OF_TIRE_FILTERS = "CLEAR_ALERTS_OF_TIRE_FILTERS";

export const GET_ALERTS_OF_MOUNT = "GET_ALERTS_OF_MOUNT";
export const CLEAR_ALERTS_OF_MOUNT = "CLEAR_ALERTS_OF_MOUNT";
export const GET_ALERT_OF_MOUNT = "GET_ALERT_OF_MOUNT";
export const CLEAR_ALERT_OF_MOUNT = "CLEAR_ALERT_OF_MOUNT";
export const UPDATE_ALERT_OF_MOUNT = "UPDATE_ALERT_OF_MOUNT";
export const SET_ALERTS_OF_MOUNT_FILTERS = "SET_ALERTS_OF_MOUNT_FILTERS";
export const CLEAR_ALERTS_OF_MOUNT_FILTERS = "CLEAR_ALERTS_OF_MOUNT_FILTERS";

export const SET_TIRE_STATS_FILTERS = "SET_TIRE_STATS_FILTERS";
export const CLEAR_TIRE_STATS_FILTERS = "CLEAR_TIRE_STATS_FILTERS";
export const SET_SELECTED_STATS_FILTERS = "SET_SELECTED_STATS_FILTERS";

export const SET_TIRE_SUMMARY_FILTERS = "SET_TIRE_SUMMARY_FILTERS";
export const CLEAR_TIRE_SUMMARY_FILTERS = "CLEAR_TIRE_SUMMARY_FILTERS";
export const SET_SELECTED_SUMMARY_FILTERS = "SET_SELECTED_SUMMARY_FILTERS";

export const SET_BEST_PERFORMANCE_FILTERS = "SET_BEST_PERFORMANCE_FILTERS";
export const CLEAR_BEST_PERFORMANCE_FILTERS = "CLEAR_BEST_PERFORMANCE_FILTERS";
export const SET_SELECTED_BEST_PERFORMANCE_FILTERS =
  "SET_SELECTED_BEST_PERFORMANCE_FILTERS";

export const SET_COMPLETED_CYCLES_FILTERS = "SET_COMPLETED_CYCLES_FILTERS";
export const CLEAR_COMPLETED_CYCLES_FILTERS = "CLEAR_COMPLETED_CYCLES_FILTERS";
export const SET_SELECTED_COMPLETED_CYCLES_FILTERS =
  "SET_SELECTED_COMPLETED_CYCLES_FILTERS";

export const SET_PILE_FILTERS = "SET_PILE_FILTERS";
export const CLEAR_PILE_FILTERS = "CLEAR_PILE_FILTERS";
export const SET_SELECTED_PILE_FILTERS = "SET_SELECTED_PILE_FILTERS";

export const SET_DYNAMIC_COST_FILTERS = "SET_DYNAMIC_COST_FILTERS";
export const CLEAR_DYNAMIC_COST_FILTERS = "CLEAR_DYNAMIC_COST_FILTERS";
export const SET_SELECTED_DYNAMIC_COST_FILTERS =
  "SET_SELECTED_DYNAMIC_COST_FILTERS";

export const SET_DAMAGES_WEARS_FILTERS = "SET_DAMAGES_WEARS_FILTERS";
export const CLEAR_DAMAGES_WEARS_FILTERS = "CLEAR_DAMAGES_WEARS_FILTERS";
export const SET_SELECTED_DAMAGES_WEARS_FILTERS =
  "SET_SELECTED_DAMAGES_WEARS_FILTERS";
export const GET_RENEWABILITY_INDEX_REPORT = "GET_RENEWABILITY_INDEX_REPORT";
export const CLEAR_RENEWABILITY_INDEX_REPORT =
  "CLEAR_RENEWABILITY_INDEX_REPORT";
export const SET_RENEWABILITY_INDEX_FILTERS = "SET_RENEWABILITY_INDEX_FILTERS";
export const CLEAR_RENEWABILITY_INDEX_FILTERS =
  "CLEAR_RENEWABILITY_INDEX_FILTERS";
export const SET_SELECTED_RENEWABILITY_INDEX_FILTERS =
  "SET_SELECTED_RENEWABILITY_INDEX_FILTERS";

export const GET_REVIEW_PERFORMANCE_REPORT = "GET_REVIEW_PERFORMANCE_REPORT";
export const CLEAR_REVIEW_PERFORMANCE_REPORT =
  "CLEAR_REVIEW_PERFORMANCE_REPORT";
export const SET_REVIEW_PERFORMANCE_FILTERS = "SET_REVIEW_PERFORMANCE_FILTERS";
export const CLEAR_REVIEW_PERFORMANCE_FILTERS =
  "CLEAR_REVIEW_PERFORMANCE_FILTERS";
export const SET_SELECTED_REVIEW_PERFORMANCE_FILTERS =
  "SET_SELECTED_REVIEW_PERFORMANCE_FILTERS";
export const GET_VEHICLES_NO_REVIEW = "GET_VEHICLES_NO_REVIEW";
export const CLEAR_VEHICLES_NO_REVIEW = "CLEAR_VEHICLES_NO_REVIEW";

export const GET_REVIEW_PERFORMANCE_COMPANY_REPORT =
  "GET_REVIEW_PERFORMANCE_COMPANY_REPORT";
export const CLEAR_REVIEW_PERFORMANCE_COMPANY_REPORT =
  "CLEAR_REVIEW_PERFORMANCE_COMPANY_REPORT";
export const SET_REVIEW_PERFORMANCE_COMPANY_FILTERS =
  "SET_REVIEW_PERFORMANCE_COMPANY_FILTERS";
export const CLEAR_REVIEW_PERFORMANCE_COMPANY_FILTERS =
  "CLEAR_REVIEW_PERFORMANCE_COMPANY_FILTERS";
export const SET_SELECTED_REVIEW_PERFORMANCE_COMPANY_FILTERS =
  "SET_SELECTED_REVIEW_PERFORMANCE_COMPANY_FILTERS";
export const GET_VEHICLES_NO_REVIEW_COMPANY = "GET_VEHICLES_NO_REVIEW_COMPANY";
export const CLEAR_VEHICLES_NO_REVIEW_COMPANY =
  "CLEAR_VEHICLES_NO_REVIEW_COMPANY";

export const CLEAR_MOUNT_REPORT_FILTERS = "CLEAR_MOUNT_REPORT_FILTERS";
export const SET_MOUNT_REPORT_FILTERS = "SET_MOUNT_REPORT_FILTERS";
export const SET_SELECTED_MOUNT_REPORT_FILTERS =
  "SET_SELECTED_MOUNT_REPORT_FILTERS";

export const SET_REPORT_WAREHOUSE_FILTERS = "SET_REPORT_WAREHOUSE_FILTERS";
export const CLEAR_REPORT_WAREHOUSE_FILTERS = "CLEAR_REPORT_WAREHOUSE_FILTERS";
export const SET_SELECTED_REPORT_WAREHOUSE_FILTERS =
  "SET_SELECTED_REPORT_WAREHOUSE_FILTERS";

export const CLEAR_LAST_MOVEMENT_REPORT_FILTERS =
  "CLEAR_LAST_MOVEMENT_REPORT_FILTERS";
export const SET_LAST_MOVEMENT_REPORT_FILTERS =
  "SET_LAST_MOVEMENT_REPORT_FILTERS";
export const SET_SELECTED_LAST_MOVEMENT_REPORT_FILTERS =
  "SET_SELECTED_LAST_MOVEMENT_REPORT_FILTERS";

export const SET_POTENTIAL_SAVINGS_FILTERS = "SET_POTENTIAL_SAVINGS_FILTERS";
export const CLEAR_POTENTIAL_SAVINGS_FILTERS =
  "CLEAR_POTENTIAL_SAVINGS_FILTERS";
export const SET_SELECTED_POTENTIAL_SAVINGS_FILTERS =
  "SET_SELECTED_POTENTIAL_SAVINGS_FILTERS";

export const SET_USER_REVIEW_FILTERS = "SET_USER_REVIEW_FILTERS";
export const CLEAR_USER_REVIEW_FILTERS = "CLEAR_USER_REVIEW_FILTERS";
export const SET_SELECTED_USER_REVIEW_FILTERS =
  "SET_SELECTED_USER_REVIEW_FILTERS";

export const SET_KM_PROJECTION_FILTERS = "SET_KM_PROJECTION_FILTERS";
export const CLEAR_KM_PROJECTION_FILTERS = "CLEAR_KM_PROJECTION_FILTERS";
export const SET_SELECTED_KM_PROJECTION_FILTERS =
  "SET_SELECTED_KM_PROJECTION_FILTERS";

export const SET_PURCHASE_FILTERS = "SET_PURCHASE_FILTERS";
export const CLEAR_PURCHASE_FILTERS = "CLEAR_PURCHASE_FILTERS";
export const SET_SELECTED_PURCHASE_FILTERS = "SET_SELECTED_PURCHASE_FILTERS";

export const GET_PURCHASE_REPORT = "GET_PURCHASE_REPORT";
export const CALCULATE_TOTAL_PURCHASE = "CALCULATE_TOTAL_PURCHASE";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_TO_CART = "REMOVE_TO_CART";
export const RESET_CART = "RESET_CART";

export const CLEAR_VEHICLE_HISTORY = "CLEAR_VEHICLE_HISTORY";
export const GET_VEHICLE_HISTORY = "GET_VEHICLE_HISTORY";
export const SET_VEHICLE_REVIEW_FILTERS = "SET_VEHICLE_REVIEW_FILTERS";
export const CLEAR_VEHICLE_REVIEW_FILTERS = "CLEAR_VEHICLE_REVIEW_FILTERS";

export const CLEAR_VEHICLE_MOVEMENT_HISTORY = "CLEAR_VEHICLE_MOVEMENT_HISTORY";
export const GET_VEHICLE_MOVEMENT_HISTORY = "GET_VEHICLE_MOVEMENT_HISTORY";
export const SET_VEHICLE_MOVEMENT_HISTORY_FILTERS =
  "SET_VEHICLE_MOVEMENT_HISTORY_FILTERS";
export const CLEAR_VEHICLE_MOVEMENT_HISTORY_FILTERS =
  "CLEAR_VEHICLE_MOVEMENT_HISTORY_FILTERS";

export const REGISTER_USER_ROLE = "REGISTER_USER_ROLE";
export const DELETE_USER_ROLE = "DELETE_USER_ROLE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";

export const GET_DEPTH_POLICIES = "GET_DEPTH_POLICIES";
export const CLEAR_DEPTH_POLICIES = "CLEAR_DEPTH_POLICIES";
export const GET_DEPTH_POLICY = "GET_DEPTH_POLICY";
export const CLEAR_DEPTH_POLICY = "CLEAR_DEPTH_POLICY";
export const REGISTER_DEPTH_POLICY = "REGISTER_DEPTH_POLICY";
export const UPDATE_DEPTH_POLICY = "UPDATE_DEPTH_POLICY";
export const DELETE_DEPTH_POLICY = "DELETE_DEPTH_POLICY";
export const SET_DEPTH_POLICY_FILTERS = "SET_DEPTH_POLICY_FILTERS";
export const CLEAR_DEPTH_POLICY_FILTERS = "CLEAR_DEPTH_POLICY_FILTERS";

export const GET_SUMMARY_DETAILS = "GET_SUMMARY_DETAILS";
export const CLEAR_SUMMARY_DETAILS = "CLEAR_SUMMARY_DETAILS";
export const GET_DYNAMIC_COST_DETAILS = "GET_DYNAMIC_COST_DETAILS";
export const CLEAR_DYNAMIC_COST_DETAILS = "CLEAR_DYNAMIC_COST_DETAILS";
export const GET_TIRE_PILE_DETAILS = "GET_TIRE_PILE_DETAILS";
export const CLEAR_TIRE_PILE_DETAILS = "CLEAR_TIRE_PILE_DETAILS";
export const GET_TIRE_PILE_CAUSE_DETAILS = "GET_TIRE_PILE_CAUSE_DETAILS";
export const CLEAR_TIRE_PILE_CAUSE_DETAILS = "CLEAR_TIRE_PILE_CAUSE_DETAILS";

export const CLEAR_SEMAPHORE_FILTERS = "CLEAR_SEMAPHORE_FILTERS";
export const SET_SEMAPHORE_FILTERS = "SET_SEMAPHORE_FILTERS";
export const SET_SELECTED_SEMAPHORE_FILTERS = "SET_SELECTED_SEMAPHORE_FILTERS";

export const CLEAR_FLEET_SEMAPHORE_FILTERS = "CLEAR_FLEET_SEMAPHORE_FILTERS";
export const SET_FLEET_SEMAPHORE_FILTERS = "SET_FLEET_SEMAPHORE_FILTERS";
export const SET_SELECTED_FLEET_SEMAPHORE_FILTERS =
  "SET_SELECTED_FLEET_SEMAPHORE_FILTERS";

export const CLEAR_ALERT_REPORT_FILTERS = "CLEAR_ALERT_REPORT_FILTERS";
export const SET_ALERT_REPORT_FILTERS = "SET_ALERT_REPORT_FILTERS";
export const SET_SELECTED_ALERT_REPORT_FILTERS =
  "SET_SELECTED_ALERT_REPORT_FILTERS";

export const SET_TIRE_REVITALIZED_REPORT_FILTERS =
  "SET_TIRE_REVITALIZED_REPORT_FILTERS";
export const CLEAR_TIRE_REVITALIZED_REPORT_FILTERS =
  "CLEAR_TIRE_REVITALIZED_REPORT_FILTERS";
export const SET_SELECTED_TIRE_REVITALIZED_REPORT_FILTERS =
  "SET_SELECTED_TIRE_REVITALIZED_REPORT_FILTERS";

export const SET_SUMMARY_WAREHOUSE_REPORT_FILTERS =
  "SET_SUMMARY_WAREHOUSE_REPORT_FILTERS";
export const CLEAR_SUMMARY_WAREHOUSE_REPORT_FILTERS =
  "CLEAR_SUMMARY_WAREHOUSE_REPORT_FILTERS";
export const SET_SELECTED_SUMMARY_WAREHOUSE_REPORT_FILTERS =
  "SET_SELECTED_SUMMARY_WAREHOUSE_REPORT_FILTERS";
export const SET_SUMMARY_MOUNT_REPORT_FILTERS =
  "SET_SUMMARY_MOUNT_REPORT_FILTERS";
export const CLEAR_SUMMARY_MOUNT_REPORT_FILTERS =
  "CLEAR_SUMMARY_MOUNT_REPORT_FILTERS";
export const SET_SELECTED_SUMMARY_MOUNT_REPORT_FILTERS =
  "SET_SELECTED_SUMMARY_MOUNT_REPORT_FILTERS";
export const SET_TIRE_REPAIR_REPORT_FILTERS = "SET_TIRE_REPAIR_REPORT_FILTERS";
export const CLEAR_TIRE_REPAIR_REPORT_FILTERS =
  "CLEAR_TIRE_REPAIR_REPORT_FILTERS";
export const SET_SELECTED_TIRE_REPAIR_REPORT_FILTERS =
  "SET_SELECTED_TIRE_REPAIR_REPORT_FILTERS";

export const GET_HELMET_VALUES = "GET_HELMET_VALUES";
export const CLEAR_HELMET_VALUES = "CLEAR_HELMET_VALUES";
export const GET_HELMET_VALUE = "GET_HELMET_VALUE";
export const CLEAR_HELMET_VALUE = "CLEAR_HELMET_VALUE";
export const REGISTER_HELMET_VALUE = "REGISTER_HELMET_VALUE";
export const UPDATE_HELMET_VALUE = "UPDATE_HELMET_VALUE";
export const DELETE_HELMET_VALUE = "DELETE_HELMET_VALUE";
export const SET_HELMET_VALUE_FILTERS = "SET_HELMET_VALUE_FILTERS";
export const CLEAR_HELMET_VALUE_FILTERS = "CLEAR_HELMET_VALUE_FILTERS";

export const GET_COMMISSIONED_DRIVERS = "GET_COMMISSIONED_DRIVERS";
export const CLEAR_COMMISSIONED_DRIVERS = "CLEAR_COMMISSIONED_DRIVERS";
export const SET_COMMISSIONED_DRIVER_STATUS = "SET_COMMISSIONED_DRIVER_STATUS";
export const DELETE_COMMISSIONED_DRIVER = "DELETE_COMMISSIONED_DRIVER";
export const REGISTER_COMMISSIONED_DRIVER = "REGISTER_COMMISSIONED_DRIVER";
export const GET_COMMISSIONED_DRIVER = "GET_COMMISSIONED_DRIVER";
export const CLEAR_COMMISSIONED_DRIVER = "CLEAR_COMMISSIONED_DRIVER";
export const UPDATE_COMMISSIONED_DRIVER = "UPDATE_COMMISSIONED_DRIVER";
export const SET_COMMISSIONED_DRIVER_FILTERS =
  "SET_COMMISSIONED_DRIVER_FILTERS";
export const CLEAR_COMMISSIONED_DRIVER_FILTERS =
  "CLEAR_COMMISSIONED_DRIVER_FILTERS";

export const GET_COMMISSIONED_VEHICLES = "GET_COMMISSIONED_VEHICLES";
export const CLEAR_COMMISSIONED_VEHICLES = "CLEAR_COMMISSIONED_VEHICLES";
export const SET_COMMISSIONED_VEHICLE_STATUS =
  "SET_COMMISSIONED_VEHICLE_STATUS";
export const DELETE_COMMISSIONED_VEHICLE = "DELETE_COMMISSIONED_VEHICLE";
export const REGISTER_COMMISSIONED_VEHICLE = "REGISTER_COMMISSIONED_VEHICLE";
export const GET_COMMISSIONED_VEHICLE = "GET_COMMISSIONED_VEHICLE";
export const CLEAR_COMMISSIONED_VEHICLE = "CLEAR_COMMISSIONED_VEHICLE";
export const UPDATE_COMMISSIONED_VEHICLE = "UPDATE_COMMISSIONED_VEHICLE";
export const SET_COMMISSIONED_VEHICLE_FILTERS =
  "SET_COMMISSIONED_VEHICLE_FILTERS";
export const CLEAR_COMMISSIONED_VEHICLE_FILTERS =
  "CLEAR_COMMISSIONED_VEHICLE_FILTERS";

export const SET_KM_PILE_FILTERS = "SET_KM_PILE_FILTERS";
export const CLEAR_KM_PILE_FILTERS = "CLEAR_KM_PILE_FILTERS";
export const SET_SELECTED_KM_PILE_FILTERS = "SET_SELECTED_KM_PILE_FILTERS";
export const CLEAR_KM_PILE_CAUSE_DETAILS = "CLEAR_KM_PILE_CAUSE_DETAILS";
export const GET_KM_PILE_CAUSE_DETAILS = "GET_KM_PILE_CAUSE_DETAILS";
export const CLEAR_KM_PILE_DETAILS = "CLEAR_KM_PILE_DETAILS";
export const GET_KM_PILE_DETAILS = "GET_KM_PILE_DETAILS";

export const GET_CHECKLISTS = "GET_CHECKLISTS";
export const CLEAR_CHECKLISTS = "CLEAR_CHECKLISTS";
export const ADD_CHECKLIST = "ADD_CHECKLIST";
export const GET_CHECKLIST = "GET_CHECKLIST";
export const CLEAR_CHECKLIST = "CLEAR_CHECKLIST";
export const UPDATE_CHECKLIST = "UPDATE_CHECKLIST";
export const SET_CHECKLIST_FILTERS = "SET_CHECKLIST_FILTERS";
export const CLEAR_CHECKLIST_FILTERS = "CLEAR_CHECKLIST_FILTERS";

export const GET_CONCEPTS = "GET_CONCEPTS";
export const CLEAR_CONCEPTS = "CLEAR_CONCEPTS";
export const GET_CONCEPT = "GET_CONCEPT";
export const CLEAR_CONCEPT = "CLEAR_CONCEPT";
export const REGISTER_CONCEPT = "REGISTER_CONCEPT";
export const UPDATE_CONCEPT = "UPDATE_CONCEPT";
export const DELETE_CONCEPT = "DELETE_CONCEPT";
export const SET_CONCEPT_FILTERS = "SET_CONCEPT_FILTERS";
export const CLEAR_CONCEPT_FILTERS = "CLEAR_CONCEPT_FILTERS";

export const GET_BLOCKS = "GET_BLOCKS";
export const CLEAR_BLOCKS = "CLEAR_BLOCKS";
export const GET_BLOCK = "GET_BLOCK";
export const CLEAR_BLOCK = "CLEAR_BLOCK";
export const REGISTER_BLOCK = "REGISTER_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const SET_BLOCK_FILTERS = "SET_BLOCK_FILTERS";
export const CLEAR_BLOCK_FILTERS = "CLEAR_BLOCK_FILTERS";

export const SET_DISPOSAL_FILTERS = "SET_DISPOSAL_FILTERS";
export const CLEAR_DISPOSAL_FILTERS = "CLEAR_DISPOSAL_FILTERS";
export const SET_SELECTED_DISPOSAL_FILTERS = "SET_SELECTED_DISPOSAL_FILTERS";

export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";
export const GET_ALL_USER_NOTIFICATIONS = "GET_ALL_USER_NOTIFICATIONS";
export const CLEAR_ALL_USER_NOTIFICATIONS = "CLEAR_ALL_USER_NOTIFICATIONS";
export const SET_USER_NOTIFICATION_FILTERS = "SET_USER_NOTIFICATION_FILTERS";
export const CLEAR_USER_NOTIFICATION_FILTERS =
  "CLEAR_USER_NOTIFICATION_FILTERS";

export const GET_TPMS = "GET_TPMS";
export const CLEAR_TPMS = "CLEAR_TPMS";
export const REGISTER_TPMS = "REGISTER_TPMS";
export const SET_TPMS_FILTERS = "SET_TPMS_FILTERS";
export const CLEAR_TPMS_FILTERS = "CLEAR_TPMS_FILTERS";
export const GET_TPMS_SENSOR = "GET_TPMS_SENSOR";
export const CLEAR_TPMS_SENSOR = "CLEAR_TPMS_SENSOR";
export const UPDATE_TPMS = "UPDATE_TPMS";
export const DELETE_TPMS = "DELETE_TPMS";
export const SET_TPMS_STATUS = "SET_TPMS_STATUS";
export const GET_TPMS_TIRE = "GET_TPMS_TIRE";
export const CLEAR_TPMS_TIRE = "CLEAR_TPMS_TIRE";
export const LINK_TPMS_TO_TIRE = "LINK_TPMS_TO_TIRE";
export const DELETE_LINK_TPMS_WITH_TIRE = "DELETE_LINK_TPMS_WITH_TIRE";

export const GET_CHECKLIST_HISTORY = "GET_CHECKLIST_HISTORY";
export const CLEAR_CHECKLIST_HISTORY = "CLEAR_CHECKLIST_HISTORY";

export const RESET_ODOMETER = "RESET_ODOMETER";
export const GET_TEMPERATURE_POLICIES = "GET_TEMPERATURE_POLICIES";
export const CLEAR_TEMPERATURE_POLICIES = "CLEAR_TEMPERATURE_POLICIES";
export const GET_TEMPERATURE_POLICY = "GET_TEMPERATURE_POLICY";
export const CLEAR_TEMPERATURE_POLICY = "CLEAR_TEMPERATURE_POLICY";
export const UPDATE_TEMPERATURE_POLICY = "UPDATE_TEMPERATURE_POLICY";

export const GET_VEHICLE_TPMS_HISTORY = "GET_VEHICLE_TPMS_HISTORY";
export const CLEAR_VEHICLE_TPMS_HISTORY = "CLEAR_VEHICLE_TPMS_HISTORY";

export const SET_SCHEDULED_WITHDRAWAL_FILTERS =
  "SET_SCHEDULED_WITHDRAWAL_FILTERS";
export const CLEAR_SCHEDULED_WITHDRAWAL_FILTERS =
  "CLEAR_SCHEDULED_WITHDRAWAL_FILTERS";
export const SET_SELECTED_SCHEDULED_WITHDRAWAL_FILTERS =
  "SET_SELECTED_SCHEDULED_WITHDRAWAL_FILTERS";
export class Exception {
  constructor(code, message, args) {
    this.code = code;
    this.args = args;
    this.message = message;
  }
}
