import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    values,
    setNotification,
    getCompanies,
    getSubsidiaries,
    setSubsidiaries,
    setCompanies,
    setFieldValue,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
            corporates: values.corporate_id,
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.corporate_id) {
      fetchData();
    }
  }, [values.corporate_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            scope: "subsidiary_id,name,status",
            companies: values.company_id.join(),
          },
          false
        );
        setSubsidiaries(data.sort(alphabeticalSort("name")));
        if (data.length === 0) {
          setFieldValue("subsidiary_id", []);
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.company_id.length > 0) {
      fetchData();
    }
  }, [values.company_id]);
  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
  setSubsidiaries: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
