export default {
  title: "Geofence",
  imei: "IMEI of the gps",
  date: "Date",
  odometer: "Odometer",
  status: "Status of the geofence",
  area: "Area",
  inside: "Inside",
  outside: "Outside",
};
