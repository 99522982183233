export default {
  filter: "Filtro",
  wear_status: "Estatus del desgaste",
  form: {
    wear_status: "Estatus del desgaste",
    enabled: "Habilitados",
    disabled: "Deshabilitados",
    clean_filters: "Limpiar filtros",
    cancel: "Cancelar",
    filter_action: "Filtrar",
    helper_text: "Se pueden seleccionar varias empresas",
    labels: { companies: "Empresas" },
  },
};
