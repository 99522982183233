import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionSetRouter } from "store/actions/general/setRouter";

import Page from "./page";

export const NAMESPACE = "components/organisms/general/Route";

function Route({ needSession, requiredPermits = [], isRootRequired, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const {
    setNotification,
    setRouter,
    redirect,
    isAuthenticate,
    account,
    router,
    permits,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      if (!isAuthenticate && needSession) {
        setLoad(false);
        await setRouter({ noSession: true });
        history.replace("/");

        return;
      }

      setLoad(redirect === null);
    }
    fetchData();
  }, [isAuthenticate, needSession, redirect]);

  useEffect(() => {
    if (
      isAuthenticate &&
      account &&
      !requiredPermits.every((permit) => permits.includes(permit))
    ) {
      setRouter({ noPermits: true });
      history.replace("/");
    }
  }, [isAuthenticate, account, requiredPermits]);

  useEffect(() => {
    if (isAuthenticate && account && !account.is_root && isRootRequired) {
      setRouter({ noRoot: true });
      history.replace("/");
    }
  }, [isAuthenticate, account, isRootRequired]);

  useEffect(() => {
    async function fetchData() {
      if (router) {
        if (router.noSession) {
          setNotification({
            message: t("no_session"),
          });
          await setRouter({ noSession: false });
        }
        if (router.noPermits) {
          setNotification({
            message: t("permits"),
          });
          setRouter({ noPermits: false });
        }
        if (router.noRoot) {
          setNotification({
            message: t("_permits.root"),
          });
          setRouter({ noRoot: false });
        }
      }
    }

    fetchData();
  }, [router]);

  return <Page load={load} {...rest} />;
}

Route.propTypes = {
  needSession: PropTypes.bool,
  requiredPermits: PropTypes.array,
  isRootRequired: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  redirect: state.General.redirect,
  isAuthenticate: state.Account.isAuthenticate,
  account: state.Account.account,
  permits: state.Account.permits,
  router: state.General.router,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setRouter: actionSetRouter(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Route);
