import React from "react";
import PropTypes from "prop-types";
import { fieldToTextField } from "formik-material-ui";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/molecules/general/Button";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function Page({ visibility, setVisibility, ...props }) {
  return (
    <TextField
      {...fieldToTextField(props)}
      type={visibility ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              tabIndex="-1"
              onMouseDown={() => setVisibility(!visibility)}
              onMouseUp={() => setVisibility(!visibility)}
              variant="icon"
            >
              {visibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
}

Page.propTypes = {
  visibility: PropTypes.bool.isRequired,
  setVisibility: PropTypes.func.isRequired,
};

export default Page;
