export default {
  disabled_rfid: "Rfid deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} rfids",
  },
  messages: {
    enable: "¿Estas seguro de habilitar el rfid?",
    disable: "¿Estas seguro de deshabilitar el rfid?",
  },
};
