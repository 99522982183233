export default {
  vehicle_type_name: "Nombre del tipo de vehículo",
  disabled_vehicle_type: "Tipo de vehículo deshabilitado",
  messages: {
    approve: "¿Estas seguro de aprobar el tipo de vehículo?",
    disapprove: "¿Estas seguro de desaprobar el tipo de vehículo?",
    disable: "¿Estas seguro de deshabilitar el tipo de vehículo?",
    enable: "¿Estas seguro de habilitar el tipo de vehículo?",
  },
  permits: {
    change_approval_status: "Sin permisos para {action} tipos de vehículos",
    change_status: "Sin permisos para {action} tipos de vehículos",
  },
};
