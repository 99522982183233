export default {
  title: "New depth tolerance",
  update: "Update depth tolerance",
  messages: {
    depth_tolerance_policy_created:
      "Done, the depth tolerance has been successfully created",
    depth_tolerance_policy_updated:
      "Done, the depth tolerance has been successfully updated",
    depth_tolerance_policy_deleted:
      "Done, the depth tolerance has been successfully deleted",
    delete: "Are you sure to delete the depth tolerance policy?",
  },
  form: {
    permits: {
      update: "No permissions to update depth tolerance policies",
      delete: "No permissions to delete depth tolerance policies",
    },
  },
};
