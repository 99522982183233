import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";
import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import Table from "./Table";
import TireDialog from "./TireDialog";
import FilterCompletedCyclesForm from "components/organisms/report/FilterCompletedCyclesForm";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
import css from "./index.module.scss";

function Page({
  t,
  filter,
  selectedFilter,
  data,
  details,
  openFilter,
  openDialog,
  handleCloseDialog,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
  handleViewTires,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  return (
    <ContainerTemplate
      title={t("_titles.completed_cycles_report")}
      options={
        <>
          <Button
            classNameButton={classnames("bw")}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.company_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiary_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.date_from && filter.date_to && (
                  <>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.from")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_from).format("LL")}
                      </p>
                    </div>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.to")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_to).format("LL")}
                      </p>
                    </div>
                  </>
                )}
                {"except_pile" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.except_pile")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.except_pile
                        ? t("_labels.yes")
                        : t("_labels.no")}
                    </p>
                  </div>
                )}
                {"number_cycle" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.number_cycle")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.number_cycle}
                    </p>
                  </div>
                )}
                {filter.codes && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_general.tires")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.codes}
                    </p>
                  </div>
                )}
                {filter.brands && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.brand")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.brands}
                    </p>
                  </div>
                )}
                {filter.models && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.model")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.models}
                    </p>
                  </div>
                )}
                {filter.sizes && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.size")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.sizes}
                    </p>
                  </div>
                )}
                {filter.tire_application && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.application")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.application}
                    </p>
                  </div>
                )}
                {filter.tire_condition && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.condition")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.condition}
                    </p>
                  </div>
                )}
                {filter.price && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.price")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {formatter.format(selectedFilter.price)}
                    </p>
                  </div>
                )}
                {filter.helmet_value && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.helmet_value")}:
                    </p>
                    <p className={classnames(css.value)}>
                      {formatter.format(selectedFilter.helmet_value)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">Rendimiento por ciclo completado</h1>
            <h5 className="sinmp">
              Este reporte muestra el rendimiento de los ciclos completados. Se
              consideran neumáticos que tengan ciclos terminados
            </h5>
            <hr />
          </div>
        </div>
        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-3">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    <Table data={data} handleViewTires={handleViewTires} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterCompletedCyclesForm
        open={openFilter}
        onClose={handleCloseFilter}
      />

      <TireDialog
        open={openDialog}
        onClose={handleCloseDialog}
        tires={details}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  data: PropTypes.array,
  details: PropTypes.array,
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
  handleViewTires: PropTypes.func.isRequired,
};

export default Page;
