import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { actionGetProvider } from "store/actions/provider/getProvider";
import { actionClearProvider } from "store/actions/provider/clearProvider";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetProviderStatus } from "store/actions/provider/setProviderStatus";

import { SHOW_PROVIDER, UPDATE_PROVIDER } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "components/organisms/provider/InfoProviderDialog";

export function InfoProviderDialog({ open, providerId, ...rest }) {
  const t = useLang(NAMESPACE);
  const {
    permits,
    provider,
    onClose,
    getProvider,
    clearProvider,
    setNotification,
    setProviderStatus,
  } = rest;
  const showProviderPermit = permits.includes(SHOW_PROVIDER);

  useEffect(() => {
    if (open && providerId) {
      if (showProviderPermit) {
        async function fetchData() {
          try {
            await getProvider(providerId);
          } catch (error) {
            setNotification(error, true);
          }
        }

        fetchData();
        return () => {
          clearProvider();
        };
      } else {
        setNotification({
          message: t("_permits.show.provider"),
        });
      }
    }
  }, [open]);

  async function onAction() {
    try {
      await setProviderStatus({
        providerId: provider.provider_id,
        status: provider.status ? 0 : 1,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus() {
    onClose();
    setNotification({
      onAction,
      message: provider.status ? t("messages.disable") : t("messages.enable"),
      textAction: t("_buttons.confirm"),
    });
  }

  return (
    <Page
      t={t}
      {...rest}
      open={open && showProviderPermit}
      updateProviderPermit={permits.includes(UPDATE_PROVIDER)}
      provider={{
        ...provider,
        created_by: provider.created_by
          ? t("_labels.actioned_by", {
              person: `${provider.created_by.name} ${
                provider.created_by.last_name_1
              } ${provider.created_by.last_name_2 || ""}`,
              date: moment(provider.created_at).format("LL"),
              time: moment(provider.created_at).format("h:mm a"),
            })
          : null,
        updated_by: provider.updated_by
          ? t("_labels.actioned_by", {
              person: `${provider.updated_by.name} ${
                provider.updated_by.last_name_1
              } ${provider.updated_by.last_name_2 || ""}`,
              date: moment(provider.updated_at).format("LL"),
              time: moment(provider.updated_at).format("h:mm a"),
            })
          : null,
      }}
      handleChangeStatus={handleChangeStatus}
    />
  );
}

InfoProviderDialog.propTypes = {
  summary: PropTypes.bool,
  providerId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onDialog: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  provider: state.Provider.provider,
});
const mapDispatchToProps = (dispatch) => ({
  getProvider: actionGetProvider(dispatch),
  clearProvider: actionClearProvider(dispatch),
  setNotification: actionSetNotification(dispatch),
  setProviderStatus: actionSetProviderStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoProviderDialog);
