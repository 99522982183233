export default {
  traveled: "Traveled",
  permits: {
    update: "No permissions to update vehicle travels",
    reset: "No permission to reset odometers",
  },
  buttons: {
    reset_odometer: "Reset odometer",
  },
  messages: {
    reset_confirmation: "Are you sure to reset the odometer?",
    vehicle_travel_restarted: "Odometer was successfully reset",
  },
};
