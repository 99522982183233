import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { toggleTable, removeCharacters } from "utils/tire";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const cpkFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({
  t,
  title,
  data,
  corporate,
  type,
  handleViewTires,
  handleViewCauses,
}) {
  return (
    <div className="panel-body">
      <div className="table-responsive">
        <table className="table table-condensed mb-1">
          <thead className="text-center">
            <tr
              className="corporate"
              onClick={() => {
                toggleTable(`${corporate}_companies`, corporate, type);
              }}
            >
              <th className="text-left col-4">
                {corporate} {title}
              </th>
              <th className="text-right col3">
                <small>{t("_labels.report_pile.tires")}</small>
                <br />
                {formatter.format(data[corporate]["statistics"])}
              </th>
              <th className="text-right">
                <small>{t("_labels.km_pile_report.average")}</small>
                <br />
                {formatter.format(data[corporate]["travel_average"])}
              </th>
              <th className="text-right col5">
                <small>{t("_labels.km_pile_report.summary")}</small>
                <br />
                {formatter.format(data[corporate]["travel_summary"])}
              </th>
              <th className="text-right col6">
                <small>{t("_labels.km_pile_report.cost_km")}</small>
                <br />${cpkFormatter.format(data[corporate]["cpk"])}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data[corporate])
              .filter((key) => key === "retirement")
              .map((cause) => (
                <Fragment key={cause}>
                  <tr
                    data-type={type}
                    data-id={removeCharacters(`${corporate}_causes`)}
                    className="responsibles box collapse"
                    onClick={() =>
                      toggleTable(
                        `${corporate}_${cause}_avoidable`,
                        `${corporate}_${cause}`,
                        type
                      )
                    }
                  >
                    <th colSpan={5} className="text-center">
                      {t("_general.responsibles")}
                    </th>
                  </tr>
                  {Object.keys(data[corporate][cause]).map((avoidable) => (
                    <Fragment key={avoidable}>
                      <tr
                        data-type={type}
                        data-id={removeCharacters(
                          `${corporate}_${cause}_avoidable`
                        )}
                        className="avoidable box collapse"
                        onClick={() =>
                          toggleTable(
                            `${corporate}_${cause}_${avoidable}_attributions`,
                            `${corporate}_${cause}_${avoidable}`,
                            type
                          )
                        }
                      >
                        <td colSpan={3} className="text-left">
                          {avoidable == "YES"
                            ? t("_labels.category.options.avoidable")
                            : t("_labels.category.options.not_avoidable")}
                        </td>
                        <td>
                          <span className="dato_a">
                            {t("_labels.incidents")}
                          </span>
                          <span className="dato_b">
                            {formatter.format(
                              data[corporate][cause][avoidable]["statistics"]
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="dato_a">{t("_labels.percent")}</span>
                          <span className="dato_b">
                            {percentFormatter.format(
                              data[corporate][cause][avoidable]["percent"]
                            )}
                            %
                          </span>
                        </td>
                      </tr>
                      {Object.keys(data[corporate][cause][avoidable])
                        .filter(
                          (index) =>
                            typeof data[corporate][cause][avoidable][index] ===
                            "object"
                        )
                        .map((attribution) => (
                          <Fragment key={attribution}>
                            <tr
                              data-type={type}
                              data-id={removeCharacters(
                                `${corporate}_${cause}_${avoidable}_attributions`
                              )}
                              className="attributions box collapse"
                              onClick={() =>
                                toggleTable(
                                  `${corporate}_${cause}_${avoidable}_${attribution}_rows`,
                                  `${corporate}_${cause}_${avoidable}_${attribution}`,
                                  type
                                )
                              }
                            >
                              <td colSpan={3}>
                                {t(
                                  `_labels.attribution.options.${attribution
                                    .replace(/\s/g, "_")
                                    .toLowerCase()}`
                                )}
                              </td>
                              <td>
                                <span className="dato_a">
                                  {t("_labels.incidents")}
                                </span>
                                <span className="dato_b">
                                  {formatter.format(
                                    data[corporate][cause][avoidable][
                                      attribution
                                    ]["statistics"]
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="dato_a">
                                  {t("_labels.percent")}
                                </span>
                                <span className="dato_b">
                                  {percentFormatter.format(
                                    data[corporate][cause][avoidable][
                                      attribution
                                    ]["percent"]
                                  )}
                                  %
                                </span>
                              </td>
                            </tr>
                            {data[corporate][cause][avoidable][attribution][
                              "data"
                            ].map((retirementCause) => (
                              <tr
                                key={retirementCause["key"]}
                                data-type={type}
                                data-id={removeCharacters(
                                  `${corporate}_${cause}_${avoidable}_${attribution}_rows`
                                )}
                                className="causes box collapse"
                                onClick={() =>
                                  handleViewCauses(
                                    getCauseParams({
                                      corporate,
                                      type,
                                      attribution,
                                      retirementCause: retirementCause["key"],
                                    })
                                  )
                                }
                              >
                                <td colSpan={3}>
                                  {t(
                                    `_retirement.name.${retirementCause["key"]}`
                                  )}
                                </td>
                                <td>
                                  <span className="dato_a">
                                    {t("_labels.incidents")}
                                  </span>
                                  <span className="dato_b">
                                    {formatter.format(
                                      retirementCause["statistics"]
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="dato_a">
                                    {t("_labels.percent")}
                                  </span>
                                  <span className="dato_b">
                                    {percentFormatter.format(
                                      retirementCause["percent"]
                                    )}
                                    %
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            {Object.keys(data[corporate])
              .filter(
                (index) =>
                  typeof data[corporate][index] === "object" &&
                  index !== "retirement"
              )
              .map((company) => (
                <Fragment key={company}>
                  <tr
                    data-type={type}
                    data-id={`${removeCharacters(corporate)}_companies`}
                    className="company box collapse"
                    onClick={() =>
                      toggleTable(
                        `${corporate}_${company}_subsidiaries`,
                        company,
                        type
                      )
                    }
                  >
                    <td className="text-left">{company}</td>
                    <td className="text-right col3">
                      {formatter.format(data[corporate][company]["statistics"])}
                    </td>
                    <td className="text-right">
                      {formatter.format(
                        data[corporate][company]["travel_average"]
                      )}
                    </td>
                    <td className="text-right col5">
                      {formatter.format(
                        data[corporate][company]["travel_summary"]
                      )}
                    </td>
                    <td className="text-right col6">
                      ${cpkFormatter.format(data[corporate][company]["cpk"])}
                    </td>
                  </tr>

                  {Object.keys(data[corporate][company])
                    .filter((key) => key === "retirement")
                    .map((cause) => (
                      <Fragment key={cause}>
                        <tr
                          data-type={type}
                          data-id={removeCharacters(
                            `${corporate}_${company}_causes`
                          )}
                          className="responsibles box collapse"
                          onClick={() =>
                            toggleTable(
                              `${corporate}_${company}_${cause}_avoidable`,
                              `${company}_${cause}`,
                              type
                            )
                          }
                        >
                          <th colSpan={5} className="text-center">
                            {t("_general.responsibles")}
                          </th>
                        </tr>
                        {Object.keys(data[corporate][company][cause]).map(
                          (avoidable) => (
                            <Fragment key={avoidable}>
                              <tr
                                data-type={type}
                                data-id={removeCharacters(
                                  `${corporate}_${company}_${cause}_avoidable`
                                )}
                                className="avoidable box collapse"
                                onClick={() =>
                                  toggleTable(
                                    `${corporate}_${company}_${cause}_${avoidable}_attributions`,
                                    `${company}_${cause}_${avoidable}`,
                                    type
                                  )
                                }
                              >
                                <td colSpan={3} className="text-left">
                                  {avoidable == "YES"
                                    ? t("_labels.category.options.avoidable")
                                    : t(
                                        "_labels.category.options.not_avoidable"
                                      )}
                                </td>
                                <td>
                                  <span className="dato_a">
                                    {t("_labels.incidents")}
                                  </span>
                                  <span className="dato_b">
                                    {formatter.format(
                                      data[corporate][company][cause][
                                        avoidable
                                      ]["statistics"]
                                    )}
                                  </span>
                                </td>
                                <td>
                                  <span className="dato_a">
                                    {t("_labels.percent")}
                                  </span>
                                  <span className="dato_b">
                                    {percentFormatter.format(
                                      data[corporate][company][cause][
                                        avoidable
                                      ]["percent"]
                                    )}
                                    %
                                  </span>
                                </td>
                              </tr>
                              {Object.keys(
                                data[corporate][company][cause][avoidable]
                              )
                                .filter(
                                  (index) =>
                                    typeof data[corporate][company][cause][
                                      avoidable
                                    ][index] === "object"
                                )
                                .map((attribution) => (
                                  <Fragment key={attribution}>
                                    <tr
                                      data-type={type}
                                      data-id={removeCharacters(
                                        `${corporate}_${company}_${cause}_${avoidable}_attributions`
                                      )}
                                      className="attributions box collapse"
                                      onClick={() =>
                                        toggleTable(
                                          `${corporate}_${company}_${cause}_${avoidable}_${attribution}_rows`,
                                          `${company}_${cause}_${avoidable}_${attribution}`,
                                          type
                                        )
                                      }
                                    >
                                      <td colSpan={3}>
                                        {t(
                                          `_labels.attribution.options.${attribution
                                            .replace(/\s/g, "_")
                                            .toLowerCase()}`
                                        )}
                                      </td>
                                      <td>
                                        <span className="dato_a">
                                          {t("_labels.incidents")}
                                        </span>
                                        <span className="dato_b">
                                          {formatter.format(
                                            data[corporate][company][cause][
                                              avoidable
                                            ][attribution]["statistics"]
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="dato_a">
                                          {t("_labels.percent")}
                                        </span>
                                        <span className="dato_b">
                                          {percentFormatter.format(
                                            data[corporate][company][cause][
                                              avoidable
                                            ][attribution]["percent"]
                                          )}
                                          %
                                        </span>
                                      </td>
                                    </tr>
                                    {data[corporate][company][cause][avoidable][
                                      attribution
                                    ]["data"].map((retirementCause) => (
                                      <tr
                                        key={retirementCause["key"]}
                                        data-type={type}
                                        data-id={removeCharacters(
                                          `${corporate}_${company}_${cause}_${avoidable}_${attribution}_rows`
                                        )}
                                        className="causes box collapse"
                                        onClick={() =>
                                          handleViewCauses(
                                            getCauseParams({
                                              corporate,
                                              company,
                                              type,
                                              attribution,
                                              retirementCause:
                                                retirementCause["key"],
                                            })
                                          )
                                        }
                                      >
                                        <td colSpan={3}>
                                          {t(
                                            `_retirement.name.${retirementCause["key"]}`
                                          )}
                                        </td>
                                        <td>
                                          <span className="dato_a">
                                            {t("_labels.incidents")}
                                          </span>
                                          <span className="dato_b">
                                            {formatter.format(
                                              retirementCause["statistics"]
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="dato_a">
                                            {t("_labels.percent")}
                                          </span>
                                          <span className="dato_b">
                                            {percentFormatter.format(
                                              retirementCause["percent"]
                                            )}
                                            %
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </Fragment>
                                ))}
                            </Fragment>
                          )
                        )}
                      </Fragment>
                    ))}
                  {Object.keys(data[corporate][company])
                    .filter(
                      (index) =>
                        typeof data[corporate][company][index] === "object" &&
                        index !== "retirement"
                    )
                    .map((subsidiary) => (
                      <Fragment key={subsidiary}>
                        <tr
                          data-type={type}
                          data-id={removeCharacters(
                            `${corporate}_${company}_subsidiaries`
                          )}
                          className="subsidiary box collapse"
                          onClick={() =>
                            toggleTable(
                              `${corporate}_${company}_${subsidiary}_divisions`,
                              `${company}_${subsidiary}`,
                              type
                            )
                          }
                        >
                          <td className="text-left">{subsidiary}</td>
                          <td className="text-right col3">
                            {formatter.format(
                              data[corporate][company][subsidiary]["statistics"]
                            )}
                          </td>
                          <td className="text-right">
                            {formatter.format(
                              data[corporate][company][subsidiary][
                                "travel_average"
                              ]
                            )}
                          </td>
                          <td className="text-right col5">
                            {formatter.format(
                              data[corporate][company][subsidiary][
                                "travel_summary"
                              ]
                            )}
                          </td>
                          <td className="text-right col6">
                            $
                            {cpkFormatter.format(
                              data[corporate][company][subsidiary]["cpk"]
                            )}
                          </td>
                        </tr>

                        {Object.keys(data[corporate][company][subsidiary])
                          .filter((key) => key === "retirement")
                          .map((cause) => (
                            <Fragment key={cause}>
                              <tr
                                data-type={type}
                                data-id={removeCharacters(
                                  `${corporate}_${company}_${subsidiary}_causes`
                                )}
                                className="responsibles box collapse"
                                onClick={() =>
                                  toggleTable(
                                    `${corporate}_${company}_${subsidiary}_${cause}_avoidable`,
                                    `${subsidiary}_${cause}`,
                                    type
                                  )
                                }
                              >
                                <th colSpan={5} className="text-center">
                                  {t("_general.responsibles")}
                                </th>
                              </tr>
                              {Object.keys(
                                data[corporate][company][subsidiary][cause]
                              ).map((avoidable) => (
                                <Fragment key={avoidable}>
                                  <tr
                                    data-type={type}
                                    data-id={removeCharacters(
                                      `${corporate}_${company}_${subsidiary}_${cause}_avoidable`
                                    )}
                                    className="avoidable box collapse"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate}_${company}_${subsidiary}_${cause}_${avoidable}_attributions`,
                                        `${subsidiary}_${cause}_${avoidable}`,
                                        type
                                      )
                                    }
                                  >
                                    <td colSpan={3} className="text-left">
                                      {avoidable == "YES"
                                        ? t(
                                            "_labels.category.options.avoidable"
                                          )
                                        : t(
                                            "_labels.category.options.not_avoidable"
                                          )}
                                    </td>
                                    <td>
                                      <span className="dato_a">
                                        {t("_labels.incidents")}
                                      </span>
                                      <span className="dato_b">
                                        {formatter.format(
                                          data[corporate][company][subsidiary][
                                            cause
                                          ][avoidable]["statistics"]
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="dato_a">
                                        {t("_labels.percent")}
                                      </span>
                                      <span className="dato_b">
                                        {percentFormatter.format(
                                          data[corporate][company][subsidiary][
                                            cause
                                          ][avoidable]["percent"]
                                        )}
                                        %
                                      </span>
                                    </td>
                                  </tr>
                                  {Object.keys(
                                    data[corporate][company][subsidiary][cause][
                                      avoidable
                                    ]
                                  )
                                    .filter(
                                      (index) =>
                                        typeof data[corporate][company][
                                          subsidiary
                                        ][cause][avoidable][index] === "object"
                                    )
                                    .map((attribution) => (
                                      <Fragment key={attribution}>
                                        <tr
                                          data-type={type}
                                          data-id={removeCharacters(
                                            `${corporate}_${company}_${subsidiary}_${cause}_${avoidable}_attributions`
                                          )}
                                          className="attributions box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate}_${company}_${subsidiary}_${cause}_${avoidable}_${attribution}_rows`,
                                              `${subsidiary}_${cause}_${avoidable}_${attribution}`,
                                              type
                                            )
                                          }
                                        >
                                          <td colSpan={3}>
                                            {t(
                                              `_labels.attribution.options.${attribution
                                                .replace(/\s/g, "_")
                                                .toLowerCase()}`
                                            )}
                                          </td>
                                          <td>
                                            <span className="dato_a">
                                              {t("_labels.incidents")}
                                            </span>
                                            <span className="dato_b">
                                              {formatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][cause][avoidable][
                                                  attribution
                                                ]["statistics"]
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="dato_a">
                                              {t("_labels.percent")}
                                            </span>
                                            <span className="dato_b">
                                              {percentFormatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][cause][avoidable][
                                                  attribution
                                                ]["percent"]
                                              )}
                                              %
                                            </span>
                                          </td>
                                        </tr>
                                        {data[corporate][company][subsidiary][
                                          cause
                                        ][avoidable][attribution]["data"].map(
                                          (retirementCause) => (
                                            <tr
                                              key={retirementCause["key"]}
                                              data-type={type}
                                              data-id={removeCharacters(
                                                `${corporate}_${company}_${subsidiary}_${cause}_${avoidable}_${attribution}_rows`
                                              )}
                                              className="causes box collapse"
                                              onClick={() =>
                                                handleViewCauses(
                                                  getCauseParams({
                                                    corporate,
                                                    company,
                                                    subsidiary,
                                                    type,
                                                    attribution,
                                                    retirementCause:
                                                      retirementCause["key"],
                                                  })
                                                )
                                              }
                                            >
                                              <td colSpan={3}>
                                                {t(
                                                  `_retirement.name.${retirementCause["key"]}`
                                                )}
                                              </td>
                                              <td>
                                                <span className="dato_a">
                                                  {t("_labels.incidents")}
                                                </span>
                                                <span className="dato_b">
                                                  {formatter.format(
                                                    retirementCause[
                                                      "statistics"
                                                    ]
                                                  )}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="dato_a">
                                                  {t("_labels.percent")}
                                                </span>
                                                <span className="dato_b">
                                                  {percentFormatter.format(
                                                    retirementCause["percent"]
                                                  )}
                                                  %
                                                </span>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </Fragment>
                                    ))}
                                </Fragment>
                              ))}
                            </Fragment>
                          ))}
                        {Object.keys(data[corporate][company][subsidiary])
                          .filter(
                            (index) =>
                              typeof data[corporate][company][subsidiary][
                                index
                              ] === "object" && index !== "retirement"
                          )
                          .map((division) => (
                            <Fragment key={division}>
                              <tr
                                data-type={type}
                                data-id={removeCharacters(
                                  `${corporate}_${company}_${subsidiary}_divisions`
                                )}
                                className="division box collapse"
                                onClick={() =>
                                  toggleTable(
                                    `${corporate}_${company}_${subsidiary}_${division}_applications`,
                                    `${company}_${subsidiary}_${division}`,
                                    type
                                  )
                                }
                              >
                                <td className="text-left">
                                  {division === "NO DIVISION"
                                    ? t("_labels.no_division")
                                    : division}
                                </td>
                                <td className="text-right col3">
                                  {formatter.format(
                                    data[corporate][company][subsidiary][
                                      division
                                    ]["statistics"]
                                  )}
                                </td>
                                <td className="text-right">
                                  {formatter.format(
                                    data[corporate][company][subsidiary][
                                      division
                                    ]["travel_average"]
                                  )}
                                </td>
                                <td className="text-right col5">
                                  {formatter.format(
                                    data[corporate][company][subsidiary][
                                      division
                                    ]["travel_summary"]
                                  )}
                                </td>
                                <td className="text-right col6">
                                  $
                                  {cpkFormatter.format(
                                    data[corporate][company][subsidiary][
                                      division
                                    ]["cpk"]
                                  )}
                                </td>
                              </tr>

                              {Object.keys(
                                data[corporate][company][subsidiary][division]
                              )
                                .filter((key) => key === "retirement")
                                .map((cause) => (
                                  <Fragment key={cause}>
                                    <tr
                                      data-type={type}
                                      data-id={removeCharacters(
                                        `${corporate}_${company}_${subsidiary}_${division}_causes`
                                      )}
                                      className="responsibles box collapse"
                                      onClick={() =>
                                        toggleTable(
                                          `${corporate}_${company}_${subsidiary}_${division}_${cause}_avoidable`,
                                          `${company}_${subsidiary}_${division}_${cause}`,
                                          type
                                        )
                                      }
                                    >
                                      <th colSpan={5} className="text-center">
                                        {t("_general.responsibles")}
                                      </th>
                                    </tr>
                                    {Object.keys(
                                      data[corporate][company][subsidiary][
                                        division
                                      ][cause]
                                    ).map((avoidable) => (
                                      <Fragment key={avoidable}>
                                        <tr
                                          data-type={type}
                                          data-id={removeCharacters(
                                            `${corporate}_${company}_${subsidiary}_${division}_${cause}_avoidable`
                                          )}
                                          className="avoidable box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate}_${company}_${subsidiary}_${division}_${cause}_${avoidable}_attributions`,
                                              `${company}_${subsidiary}_${division}_${cause}_${avoidable}`,
                                              type
                                            )
                                          }
                                        >
                                          <td colSpan={3} className="text-left">
                                            {avoidable == "YES"
                                              ? t(
                                                  "_labels.category.options.avoidable"
                                                )
                                              : t(
                                                  "_labels.category.options.not_avoidable"
                                                )}
                                          </td>
                                          <td>
                                            <span className="dato_a">
                                              {t("_labels.incidents")}
                                            </span>
                                            <span className="dato_b">
                                              {formatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][cause][avoidable][
                                                  "statistics"
                                                ]
                                              )}
                                            </span>
                                          </td>
                                          <td>
                                            <span className="dato_a">
                                              {t("_labels.percent")}
                                            </span>
                                            <span className="dato_b">
                                              {percentFormatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][cause][avoidable][
                                                  "percent"
                                                ]
                                              )}
                                              %
                                            </span>
                                          </td>
                                        </tr>
                                        {Object.keys(
                                          data[corporate][company][subsidiary][
                                            division
                                          ][cause][avoidable]
                                        )
                                          .filter(
                                            (index) =>
                                              typeof data[corporate][company][
                                                subsidiary
                                              ][division][cause][avoidable][
                                                index
                                              ] === "object"
                                          )
                                          .map((attribution) => (
                                            <Fragment key={attribution}>
                                              <tr
                                                data-type={type}
                                                data-id={removeCharacters(
                                                  `${corporate}_${company}_${subsidiary}_${division}_${cause}_${avoidable}_attributions`
                                                )}
                                                className="attributions box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate}_${company}_${subsidiary}_${division}_${cause}_${avoidable}_${attribution}_rows`,
                                                    `${company}_${subsidiary}_${division}_${cause}_${avoidable}_${attribution}`,
                                                    type
                                                  )
                                                }
                                              >
                                                <td colSpan={3}>
                                                  {t(
                                                    `_labels.attribution.options.${attribution
                                                      .replace(/\s/g, "_")
                                                      .toLowerCase()}`
                                                  )}
                                                </td>
                                                <td>
                                                  <span className="dato_a">
                                                    {t("_labels.incidents")}
                                                  </span>
                                                  <span className="dato_b">
                                                    {formatter.format(
                                                      data[corporate][company][
                                                        subsidiary
                                                      ][division][cause][
                                                        avoidable
                                                      ][attribution][
                                                        "statistics"
                                                      ]
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="dato_a">
                                                    {t("_labels.percent")}
                                                  </span>
                                                  <span className="dato_b">
                                                    {percentFormatter.format(
                                                      data[corporate][company][
                                                        subsidiary
                                                      ][division][cause][
                                                        avoidable
                                                      ][attribution]["percent"]
                                                    )}
                                                    %
                                                  </span>
                                                </td>
                                              </tr>
                                              {data[corporate][company][
                                                subsidiary
                                              ][division][cause][avoidable][
                                                attribution
                                              ]["data"].map(
                                                (retirementCause) => (
                                                  <tr
                                                    key={retirementCause["key"]}
                                                    data-type={type}
                                                    data-id={removeCharacters(
                                                      `${corporate}_${company}_${subsidiary}_${division}_${cause}_${avoidable}_${attribution}_rows`
                                                    )}
                                                    className="causes box collapse"
                                                    onClick={() =>
                                                      handleViewCauses(
                                                        getCauseParams({
                                                          corporate,
                                                          company,
                                                          subsidiary,
                                                          division,
                                                          type,
                                                          attribution,
                                                          retirementCause:
                                                            retirementCause[
                                                              "key"
                                                            ],
                                                        })
                                                      )
                                                    }
                                                  >
                                                    <td colSpan={3}>
                                                      {t(
                                                        `_retirement.name.${retirementCause["key"]}`
                                                      )}
                                                    </td>
                                                    <td>
                                                      <span className="dato_a">
                                                        {t("_labels.incidents")}
                                                      </span>
                                                      <span className="dato_b">
                                                        {formatter.format(
                                                          retirementCause[
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <span className="dato_a">
                                                        {t("_labels.percent")}
                                                      </span>
                                                      <span className="dato_b">
                                                        {percentFormatter.format(
                                                          retirementCause[
                                                            "percent"
                                                          ]
                                                        )}
                                                        %
                                                      </span>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                  </Fragment>
                                ))}
                              {Object.keys(
                                data[corporate][company][subsidiary][division]
                              )
                                .filter(
                                  (index) =>
                                    typeof data[corporate][company][subsidiary][
                                      division
                                    ][index] === "object" &&
                                    index !== "retirement"
                                )
                                .map((application) => (
                                  <Fragment key={application}>
                                    <tr
                                      data-type={type}
                                      data-id={removeCharacters(
                                        `${corporate}_${company}_${subsidiary}_${division}_applications`
                                      )}
                                      className={`box collapse application`}
                                      onClick={() =>
                                        toggleTable(
                                          `${corporate}_${company}_${subsidiary}_${division}_${application}_models`,
                                          `${company}_${subsidiary}_${division}_${application}`,
                                          type
                                        )
                                      }
                                    >
                                      <td className="text-left">
                                        {t(
                                          `_labels.tire_application.options.${application.toLowerCase()}`
                                        )}
                                      </td>
                                      <td className="text-right col3">
                                        {formatter.format(
                                          data[corporate][company][subsidiary][
                                            division
                                          ][application]["statistics"]
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {formatter.format(
                                          data[corporate][company][subsidiary][
                                            division
                                          ][application]["travel_average"]
                                        )}
                                      </td>
                                      <td className="text-right col5">
                                        {formatter.format(
                                          data[corporate][company][subsidiary][
                                            division
                                          ][application]["travel_summary"]
                                        )}
                                      </td>
                                      <td className="text-right col6">
                                        $
                                        {cpkFormatter.format(
                                          data[corporate][company][subsidiary][
                                            division
                                          ][application]["cpk"]
                                        )}
                                      </td>
                                    </tr>

                                    {Object.keys(
                                      data[corporate][company][subsidiary][
                                        division
                                      ][application]
                                    )
                                      .filter((key) => key === "retirement")
                                      .map((cause) => (
                                        <Fragment key={cause}>
                                          <tr
                                            data-type={type}
                                            data-id={removeCharacters(
                                              `${corporate}_${company}_${subsidiary}_${division}_${application}_causes`
                                            )}
                                            className="responsibles box collapse"
                                            onClick={() =>
                                              toggleTable(
                                                `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_avoidable`,
                                                `${company}_${subsidiary}_${division}_${application}_${cause}`,
                                                type
                                              )
                                            }
                                          >
                                            <th
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              {t("_general.responsibles")}
                                            </th>
                                          </tr>
                                          {Object.keys(
                                            data[corporate][company][
                                              subsidiary
                                            ][division][application][cause]
                                          ).map((avoidable) => (
                                            <Fragment key={avoidable}>
                                              <tr
                                                data-type={type}
                                                data-id={removeCharacters(
                                                  `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_avoidable`
                                                )}
                                                className="avoidable box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}_attributions`,
                                                    `${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}`,
                                                    type
                                                  )
                                                }
                                              >
                                                <td
                                                  colSpan={3}
                                                  className="text-left"
                                                >
                                                  {avoidable == "YES"
                                                    ? t(
                                                        "_labels.category.options.avoidable"
                                                      )
                                                    : t(
                                                        "_labels.category.options.not_avoidable"
                                                      )}
                                                </td>
                                                <td>
                                                  <span className="dato_a">
                                                    {t("_labels.incidents")}
                                                  </span>
                                                  <span className="dato_b">
                                                    {formatter.format(
                                                      data[corporate][company][
                                                        subsidiary
                                                      ][division][application][
                                                        cause
                                                      ][avoidable]["statistics"]
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="dato_a">
                                                    {t("_labels.percent")}
                                                  </span>
                                                  <span className="dato_b">
                                                    {percentFormatter.format(
                                                      data[corporate][company][
                                                        subsidiary
                                                      ][division][application][
                                                        cause
                                                      ][avoidable]["percent"]
                                                    )}
                                                    %
                                                  </span>
                                                </td>
                                              </tr>
                                              {Object.keys(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][application][cause][
                                                  avoidable
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof data[corporate][
                                                      company
                                                    ][subsidiary][division][
                                                      application
                                                    ][cause][avoidable][
                                                      index
                                                    ] === "object"
                                                )
                                                .map((attribution) => (
                                                  <Fragment key={attribution}>
                                                    <tr
                                                      data-type={type}
                                                      data-id={removeCharacters(
                                                        `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}_attributions`
                                                      )}
                                                      className="attributions box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}_${attribution}_rows`,
                                                          `${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}_${attribution}`,
                                                          type
                                                        )
                                                      }
                                                    >
                                                      <td colSpan={3}>
                                                        {t(
                                                          `_labels.attribution.options.${attribution
                                                            .replace(/\s/g, "_")
                                                            .toLowerCase()}`
                                                        )}
                                                      </td>
                                                      <td>
                                                        <span className="dato_a">
                                                          {t(
                                                            "_labels.incidents"
                                                          )}
                                                        </span>
                                                        <span className="dato_b">
                                                          {formatter.format(
                                                            data[corporate][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][application][
                                                              cause
                                                            ][avoidable][
                                                              attribution
                                                            ]["statistics"]
                                                          )}
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <span className="dato_a">
                                                          {t("_labels.percent")}
                                                        </span>
                                                        <span className="dato_b">
                                                          {percentFormatter.format(
                                                            data[corporate][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][application][
                                                              cause
                                                            ][avoidable][
                                                              attribution
                                                            ]["percent"]
                                                          )}
                                                          %
                                                        </span>
                                                      </td>
                                                    </tr>
                                                    {data[corporate][company][
                                                      subsidiary
                                                    ][division][application][
                                                      cause
                                                    ][avoidable][attribution][
                                                      "data"
                                                    ].map((retirementCause) => (
                                                      <tr
                                                        key={
                                                          retirementCause["key"]
                                                        }
                                                        data-type={type}
                                                        data-id={removeCharacters(
                                                          `${corporate}_${company}_${subsidiary}_${division}_${application}_${cause}_${avoidable}_${attribution}_rows`
                                                        )}
                                                        className="causes box collapse"
                                                        onClick={() =>
                                                          handleViewCauses(
                                                            getCauseParams({
                                                              corporate,
                                                              company,
                                                              subsidiary,
                                                              division,
                                                              application,
                                                              type,
                                                              attribution,
                                                              retirementCause:
                                                                retirementCause[
                                                                  "key"
                                                                ],
                                                            })
                                                          )
                                                        }
                                                      >
                                                        <td colSpan={3}>
                                                          {t(
                                                            `_retirement.name.${retirementCause["key"]}`
                                                          )}
                                                        </td>
                                                        <td>
                                                          <span className="dato_a">
                                                            {t(
                                                              "_labels.incidents"
                                                            )}
                                                          </span>
                                                          <span className="dato_b">
                                                            {formatter.format(
                                                              retirementCause[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span className="dato_a">
                                                            {t(
                                                              "_labels.percent"
                                                            )}
                                                          </span>
                                                          <span className="dato_b">
                                                            {percentFormatter.format(
                                                              retirementCause[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                        </Fragment>
                                      ))}
                                    {Object.keys(
                                      data[corporate][company][subsidiary][
                                        division
                                      ][application]
                                    )
                                      .filter(
                                        (index) =>
                                          typeof data[corporate][company][
                                            subsidiary
                                          ][division][application][index] ===
                                            "object" && index !== "retirement"
                                      )
                                      .map((model) => (
                                        <Fragment key={model}>
                                          <tr
                                            data-type={type}
                                            data-id={removeCharacters(
                                              `${corporate}_${company}_${subsidiary}_${division}_${application}_models`
                                            )}
                                            className={`model_variation box collapse `}
                                            onClick={() =>
                                              handleViewTires(
                                                getTireParams(
                                                  corporate,
                                                  company,
                                                  subsidiary,
                                                  division,
                                                  application,
                                                  model,
                                                  type
                                                )
                                              )
                                            }
                                          >
                                            <td className="text-left">
                                              {model}
                                            </td>
                                            <td className="text-right col3">
                                              {formatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][application][model][
                                                  "statistics"
                                                ]
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {formatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][application][model][
                                                  "travel_average"
                                                ]
                                              )}
                                            </td>
                                            <td className="text-right col5">
                                              {formatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][application][model][
                                                  "travel_summary"
                                                ]
                                              )}
                                            </td>
                                            <td className="text-right col6">
                                              $
                                              {cpkFormatter.format(
                                                data[corporate][company][
                                                  subsidiary
                                                ][division][application][model][
                                                  "cpk"
                                                ]
                                              )}
                                            </td>
                                          </tr>
                                        </Fragment>
                                      ))}
                                  </Fragment>
                                ))}
                            </Fragment>
                          ))}
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  corporate: PropTypes.string.isRequired,
  handleViewTires: PropTypes.func.isRequired,
  handleViewCauses: PropTypes.func.isRequired,
};

export default Page;

function getTireParams(
  corporate,
  company,
  subsidiary,
  division,
  application,
  model,
  type
) {
  const params = {
    corporate_name: corporate,
    company_name: company,
    subsidiary_name: subsidiary,
    division_name: division,
    tire_application_id: application,
    model_variation: model,
  };
  if (type == "original") {
    params.tire_condition = "ORIGINAL_NEW,ORIGINAL_USED";
  }

  if (type == "revitalized") {
    params.tire_condition = "RETREAD_NEW,RETREAD_USED";
  }

  if (type == "first") {
    params.number_cycle = 1;
  }

  if (type == "second") {
    params.number_cycle = 2;
  }

  if (type == "third") {
    params.number_cycle = 3;
  }

  if (type == "fourth") {
    params.number_cycle = 4;
  }

  for (const key in params) {
    if (params[key] === undefined) {
      delete params[key];
    }
  }

  return params;
}

function getCauseParams({
  corporate,
  company,
  subsidiary,
  division,
  application,
  model,
  type,
  attribution,
  retirementCause,
}) {
  const params = {
    corporate_name: corporate,
    company_name: company,
    subsidiary_name: subsidiary,
    division_name: division,
    tire_application_id: application,
    model_variation: model,
    attribution,
    retirement_cause_name: retirementCause,
  };

  if (type == "original") {
    params.tire_condition = "ORIGINAL_NEW,ORIGINAL_USED";
  }

  if (type == "revitalized") {
    params.tire_condition = "RETREAD_NEW,RETREAD_USED";
  }

  if (type == "first") {
    params.number_cycle = 1;
  }

  if (type == "second") {
    params.number_cycle = 2;
  }

  if (type == "third") {
    params.number_cycle = 3;
  }

  if (type == "fourth") {
    params.number_cycle = 4;
  }

  for (const key in params) {
    if (params[key] === undefined) {
      delete params[key];
    }
  }
  return params;
}
