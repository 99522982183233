import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import TireModelTabs from "components/organisms/tire/model/TireModelTabs";
import TireModelForm from "components/organisms/tire/model/TireModelForm";
import InfoTireModelCard from "components/organisms/tire/model/InfoTireModelCard";

import css from "./index.module.scss";

function Page({ open, tireModel, tireModelId, handleOpen, handleClose }) {
  return (
    <TireModelTabs className={classnames(css.container)} title={tireModel.name}>
      <div className={classnames(css.links)}>
        <InfoTireModelCard tireModel={tireModel} handleOpen={handleOpen} />
      </div>

      <TireModelForm
        onClose={handleClose}
        open={open}
        tireModelId={tireModelId}
      />
    </TireModelTabs>
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  tireModelId: PropTypes.string,
  tireModel: PropTypes.object.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Page;
