import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";

import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  movements,
  corporates,
  companies,
  subsidiaries,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="movement"
        component={SelectField}
        label={t("_labels.location.label")}
        className={classnames(css.field)}
      >
        {movements.map((movement, index) => {
          return (
            <MenuItem key={index} value={movement.value.toString()}>
              {movement.label}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="corporate_id"
        component={SelectField}
        label={t("_labels.corporate_field.singular.label")}
        defaultText={t("_labels.corporate_field.singular.value")}
        className={classnames(css.field)}
      >
        {corporates.map((corporate, index) => {
          return (
            <MenuItem
              key={index}
              value={corporate.corporate_id.toString()}
              disabled={!corporate.status}
            >
              {corporate.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="companies"
        component={SelectField}
        label={t("_labels.company_field.singular.label")}
        defaultText={t("_labels.company_field.singular.value")}
        className={classnames(css.field)}
      >
        {companies.map((company, index) => {
          return (
            <MenuItem
              key={index}
              value={company.company_id.toString()}
              disabled={!company.status}
            >
              {company.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="subsidiaries"
        component={SelectField}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        className={classnames(css.field)}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="days"
        component={SelectField}
        label={t("_labels.projection_day")}
        className={classnames(css.field)}
      >
        <MenuItem value="none">{t("_labels.select_default")}</MenuItem>
        <MenuItem value="1">30 {t("_labels.days")}</MenuItem>
        <MenuItem value="2">60 {t("_labels.days")}</MenuItem>
        <MenuItem value="3">90 {t("_labels.days")}</MenuItem>
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  movements: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
};

export default Page;
