export default {
  disabled_variation: "Variación de modelo deshabilitada",
  permits: {
    change_status: "Sin permisos para {action} variaciones de modelo",
    change_approve: "Sin permisos para {action} variaciones del modelo",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar la variación del modelo?",
    enable: "¿Estas seguro de habilitar la variación del modelo?",
    approve: "¿Estas seguro de aprobar la variación del modelo?",
    disapprove: "¿Estas seguro de desaprobar la variación del modelo?",
  },
};
