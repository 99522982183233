import React from "react";
import PropTypes from "prop-types";
import { removeCharacters, toggleTable } from "utils/tire";
import { useLang } from "hooks/lang";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

/* const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
*/
const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Table({ data, handleViewTires }) {
  return (
    <table className="table table-condensed">
      <tbody>
        {data.map((row, index) => (
          <TableRow
            key={index}
            row={row}
            level={row.level}
            handleViewTires={handleViewTires}
          />
        ))}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  handleViewTires: PropTypes.func.isRequired,
};

export default Table;

const values = {
  corporate: "corporate",
  company: "company",
  subsidiary: "subsidiary",
  division: "division",
  application: "application",
  size: "size",
  cycle: "cycle",
  model: "model",
};

const levels = {
  corporate: "",
  company: "_companies",
  subsidiary: "_subsidiaries",
  division: "_divisions",
  cycle: "_cycles",
  application: "_applications",
  size: "_sizes",
  model: "_models",
};

const sublevels = {
  corporate: "_companies",
  company: "_subsidiaries",
  subsidiary: "_divisions",
  division: "_cycles",
  cycle: "_applications",
  application: "_sizes",
  size: "_models",
  model: "",
};
const TableRow = ({ row, handleViewTires }) => {
  const t = useLang();

  const actionClick = row.data
    ? () =>
        toggleTable(
          `${row.lastKey ?? row.key}${sublevels[row.level]}`,
          row.lastKey ?? row.key
        )
    : () => handleViewTires(row.filterKey);

  const trProps = {
    className: `${values[row.level]} box ${
      row.level !== "corporate" ? "collapse" : "font-weight-bold"
    }`,
    onClick: actionClick,
  };

  if (row.level !== "corporate") {
    trProps["data-id"] = `${removeCharacters(row.innerKey)}`;
  }

  return (
    <>
      <tr {...trProps}>
        <td
          className={`text-left ${
            row.level === "corporate" ? "align-bottom" : ""
          }`}
        >
          {getKey(row, t)}
        </td>
        <td className="text-right col3">
          {row.level === "corporate" && (
            <>
              <small>{t("_labels.cycles")}</small>
              <br />
            </>
          )}
          {formatter.format(row.statistics)}
        </td>
        <td className="text-right col4">
          {row.level === "corporate" && (
            <>
              <small>{t("_labels.actual_cost")}</small>
              <br />
            </>
          )}
          {costKmFormatter.format(row.cpk)}
        </td>
        <td className="text-right col5">
          {row.level === "corporate" && (
            <>
              <small>{t("_labels.km_pile_report.average")}</small>
              <br />
            </>
          )}
          {formatter.format(row.travel_average)}
        </td>
        <td className="text-right col6">
          {row.level === "corporate" && (
            <>
              <small>{t("_labels.km_pile_report.summary")}</small>
              <br />
            </>
          )}
          {formatter.format(row.travel_summary)}
        </td>
      </tr>
      {row.data &&
        row.data.map((childRow, index) => (
          <TableRow
            key={index}
            row={{
              ...childRow,
              lastKey: `${row.lastKey ?? row.key}_${childRow.key}`,
              filterKey: `${row.filterKey ?? row.key}@${childRow.key}`,
              innerKey: removeCharacters(
                `${row.lastKey ?? row.key}${levels[childRow.level]}`
              ),
            }}
            level={row.level}
            handleViewTires={handleViewTires}
          />
        ))}
    </>
  );
};

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  handleViewTires: PropTypes.func.isRequired,
};

function getKey(row, t) {
  if (row.level === "cycle") {
    return row.key === 0
      ? t("_labels.tire_condition.options.original")
      : `${t("_labels.report_pile.cycle")} ${row.key}`;
  }

  if (row.level === "application") {
    return t(`_labels.tire_application.options.${row.key.toLowerCase()}`);
  }

  if (row.key.includes("NO DIVISIO")) {
    return t("_labels.no_division");
  }

  return row.key;
}
