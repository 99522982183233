import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { arrayJoin } from "utils/parse";

import Page from "./page";

export const NAMESPACE =
  "components/molecules/vehicle/type/axle/VehicleTypeAxleInfoCard";

export function VehicleTypeAxleInfoCard({
  position,
  tireApplication,
  ...rest
}) {
  const t = useLang(NAMESPACE);

  return (
    <Page
      t={t}
      {...rest}
      position={`${t(
        `_labels.vehicle_type_axle_position.options.${position.toLowerCase()}`
      )}`}
      tireApplication={arrayJoin(
        tireApplication.map((application) =>
          t(
            `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
          )
        ),
        ", ",
        ` ${t("_general.text.and")} `
      )}
    />
  );
}

VehicleTypeAxleInfoCard.propTypes = {
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  axleNumber: PropTypes.number.isRequired,
  tireApplication: PropTypes.array.isRequired,
  tireQuantityCount: PropTypes.number.isRequired,
};

export default VehicleTypeAxleInfoCard;
