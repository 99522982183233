import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";
import MenuItem from "@material-ui/core/MenuItem";

import PasswordField from "components/molecules/general/PasswordField";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";
function Page({ t, handleSubmit, onClose, isSubmitting, isValid }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="password"
        type="password"
        component={PasswordField}
        className={classnames(css.field)}
        label={t("_labels.password")}
      />

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="confirm_password"
        type="password"
        component={PasswordField}
        className={classnames(css.field)}
        label={t("labels.confirm_pass")}
      />

      <Field
        required
        fullWidth
        variant="filled"
        margin="normal"
        name="preferred_language"
        component={SelectField}
        className={classnames(css.field)}
        label={t("_labels.language_field.label")}
      >
        <MenuItem value="es">{t("_labels.language_field.options.es")}</MenuItem>
        <MenuItem value="en">{t("_labels.language_field.options.en")}</MenuItem>
      </Field>

      <DialogActions className={classnames(css.action)}>
        <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.update")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default Page;
