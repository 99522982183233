export default {
  damages: "Damages",
  wears: "Wears",
  retirement_causes: "Retirement causes",
  permits: {
    list: {
      tire_damage: "No permissions to list tire damages",
      tire_wear: "No permissions to list tire wears",
    },
  },
};
