import {
  GET_COMMISSIONED_DRIVERS,
  CLEAR_COMMISSIONED_DRIVERS,
  SET_COMMISSIONED_DRIVER_STATUS,
  DELETE_COMMISSIONED_DRIVER,
  GET_COMMISSIONED_DRIVER,
  CLEAR_COMMISSIONED_DRIVER,
  UPDATE_COMMISSIONED_DRIVER,
  SET_COMMISSIONED_DRIVER_FILTERS,
  CLEAR_COMMISSIONED_DRIVER_FILTERS,
} from "store/actions";

const initialState = {
  driver: {
    commissioned_driver_id: 0,
    name: "",
    driver_code: "",
    association_id: "",
  },
  drivers: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSIONED_DRIVERS:
      return {
        ...state,
        drivers: action.payload,
      };

    case CLEAR_COMMISSIONED_DRIVERS:
      return {
        ...state,
        drivers: { ...initialState.drivers },
      };

    case SET_COMMISSIONED_DRIVER_STATUS:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: state.drivers.data.map((driver) =>
            driver.commissioned_driver_id !== action.payload
              ? driver
              : { ...driver, status: !driver.status }
          ),
        },
      };

    case DELETE_COMMISSIONED_DRIVER:
      return {
        ...state,
        drivers: {
          ...state.drivers,
          data: state.drivers.data.filter(
            (driver) => driver.commissioned_driver_id !== action.payload
          ),
        },
      };

    case UPDATE_COMMISSIONED_DRIVER:
    case GET_COMMISSIONED_DRIVER:
      return {
        ...state,
        driver: action.payload,
      };

    case CLEAR_COMMISSIONED_DRIVER:
      return {
        ...state,
        driver: { ...initialState.driver },
      };

    case SET_COMMISSIONED_DRIVER_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_COMMISSIONED_DRIVER_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };
    default:
      return state;
  }
}
