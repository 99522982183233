export default {
  title: "Linked vehicles",
  form: {
    permits: {
      list: {
        vehicle: "No permission to view vehicle listings",
      },
    },
  },
  permits: {
    show_subsidiary_vehicle: "No permission to see vehicles to subsidiary",
    unlink_subsidiary_vehicle:
      "No permissions to unlink vehicles from subsidiaries",
  },
  messages: {
    delete: "Are you sure to unlink vehicle?",
    linked_subsidiary_vehicle:
      "Done, the vehicle was successfully linked to the subsidiary",
    delete_subsidiary_vehicle:
      "Done, the vehicle has been unlinked from the subsidiary",
  },
};
