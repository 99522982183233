import React from "react";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import TotalHeader from "./TotalHeader";
import PdfTireList from "./PdfTireList";
import DesgloseCondicionMedida from "./DesgloseCondicionMedida";
import RowPdf from "./RowPdf";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
});

const MyPDFDocument = ({
  date,
  totals,
  originalTireSizeList,
  revitalizedTireSizeList,
  originalTireList,
  revitalizedTireList,
  subsidiary,
  uuid,
}) => {
  return (
    // Se utiliza un uuid para que forzar que se renderize de nuevo el PDF.
    <Document key={uuid}>
      <Page size={"LETTER"} style={{ padding: "20px 20px 20px 20px" }}>
        <View style={styles.title}>
          <Text>Reporte de compras sugeridas</Text>
          <Text style={styles.subTitle}>{subsidiary}</Text>

          <View
            id={uuid}
            style={{
              display: "none",
              fontSize: "0px",
              width: "0px",
              height: "0px",
            }}
          />
        </View>
        <RowPdf titleText={"Listado de compras"} col5={date} />
        <TotalHeader {...totals} />
        <PdfTireList
          tireSizeList={originalTireSizeList}
          title={"Llantas Originales"}
          titleBgColor="rgb(77, 122, 255)"
          totalPrice={totals.priceOriginal}
        />
        <PdfTireList
          tireSizeList={revitalizedTireSizeList}
          title={"Llantas Renovadas"}
          titleBgColor="rgb(0, 128, 00)"
          totalPrice={totals.priceRevitalized}
        />

        <RowPdf titleText={"  "} />
        <View style={styles.subTitle}>
          <Text>Desglose por Condición y Medida</Text>
        </View>
        <DesgloseCondicionMedida
          condition={"Originales"}
          tireList={originalTireList}
          titleColor={"rgb(77, 122, 255)"}
        />
        <DesgloseCondicionMedida
          condition={"Renovadas"}
          tireList={revitalizedTireList}
          titleColor={"rgb(0, 128, 00)"}
        />
      </Page>
    </Document>
  );
};

MyPDFDocument.propTypes = {
  uuid: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  totals: PropTypes.shape({
    totalOriginal: PropTypes.number.isRequired,
    priceOriginal: PropTypes.number.isRequired,
    totalRevitalized: PropTypes.number.isRequired,
    priceRevitalized: PropTypes.number.isRequired,
  }),
  originalTireSizeList: PropTypes.array.isRequired,
  revitalizedTireSizeList: PropTypes.array.isRequired,
  originalTireList: PropTypes.array.isRequired,
  revitalizedTireList: PropTypes.array.isRequired,
  subsidiary: PropTypes.string.isRequired,
};

export default MyPDFDocument;
