export default {
  update_title: "Actualizar vehículo",
  form: {
    permits: {
      update: "Sin permisos para actualizar vehículos",
      delete: "Sin permisos para eliminar vehículos",
    },
  },
  messages: {
    vehicle_delete: "¿Estas seguro de eliminar el vehículo?",
    vehicle_updated: "Listo, se actualizó el vehículo con éxito",
  },
};
