import React from "react";
import { connect } from "react-redux";

import { actionGetAlerts } from "store/actions/alert/getAlerts";
import { actionClearAlerts } from "store/actions/alert/clearAlerts";
import { actionSetAlertFilters } from "store/actions/alert/setAlertFilters";
import { actionClearAlertFilters } from "store/actions/alert/clearAlertFilters";
import { actionDeleteAlert } from "store/actions/alert/deleteAlert";
import { actionSetAlertStatus } from "store/actions/alert/setAlertStatus";
import { actionGetAlert } from "store/actions/alert/getAlert";
import { actionAddAlert } from "store/actions/alert/addAlert";
import { actionUpdateAlert } from "store/actions/alert/updateAlert";

import { useLang } from "hooks/lang";

import {
  CREATE_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeAlert({ ...rest }) {
  const t = useLang();
  const {
    permits,
    alert,
    alerts,
    filter,
    deleteAlert,
    setAlertStatus,
    getAlert,
    addAlert,
    updateAlert,
    getAlerts,
    clearAlerts,
    setAlertFilters,
    clearAlertFilters,
  } = rest;

  return (
    <Page
      alerts={alerts}
      alert={alert}
      filter={filter}
      createAlertPermit={permits.includes(CREATE_ALERT)}
      updateAlertPermit={permits.includes(UPDATE_ALERT)}
      deleteAlertPermit={permits.includes(DELETE_ALERT)}
      t={t}
      getAlert={getAlert}
      addAlert={addAlert}
      updateAlert={updateAlert}
      clearAlertFilters={clearAlertFilters}
      setAlertFilters={setAlertFilters}
      deleteAlert={deleteAlert}
      setAlertStatus={setAlertStatus}
      getAlerts={getAlerts}
      clearAlerts={clearAlerts}
    />
  );
}

HomeAlert.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  alerts: state.Alert.Alert.alerts,
  alert: state.Alert.Alert.alert,
  filter: state.Alert.Alert.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getAlerts: actionGetAlerts(dispatch),
  clearAlerts: actionClearAlerts(dispatch),
  setAlertFilters: actionSetAlertFilters(dispatch),
  clearAlertFilters: actionClearAlertFilters(dispatch),
  deleteAlert: actionDeleteAlert(dispatch),
  setAlertStatus: actionSetAlertStatus(dispatch),
  getAlert: actionGetAlert(dispatch),
  addAlert: actionAddAlert(dispatch),
  updateAlert: actionUpdateAlert(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeAlert);
