export default {
  new_damage: "New damage",
  update_damage: "Update damage",
  form: {
    permits: {
      update: "No permissions to update damages",
      delete: "No permissions to delete damages",
    },
  },
  messages: {
    damage_updated: "Done, the damage was successfully updated",
    damage_created: "Done, new damage successfully created",
    damage_deleted: "Done, the damage was successfully deleted",
    delete: "Are you sure to delete damage?",
  },
};
