import { combineReducers } from "redux";

import Subsidiary from "./subsidiary";
import Vehicle from "./vehicle";
import Driver from "./driver";

export default combineReducers({
  Subsidiary,
  Vehicle,
  Driver,
});
