export default {
  subsidiary: "Sucursal",
  permits: {
    unlink: {
      tire_subsidiary: "Sin permisos para desvincular sucursal del neumático",
    },
    show: {
      tire_subsidiary: "Sin permisos para ver sucursal vinculada al neumático",
    },
  },
  messages: {
    unlinked_tire_subsidiary:
      "Listo, la sucursal se a desvinculado del neumático con éxito",
  },
};
