import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import { Field, Form } from "formik";

import Button from "components/molecules/general/Button";
import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";
import GetAppIcon from "@material-ui/icons/GetApp";
import css from "./index.module.scss";

export function Page({
  t,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
  excelErrors,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <>
        <div className={classnames(css.buttons)}>
          <Button
            type="button"
            rel="noopener"
            color="secondary"
            variant="contained"
            href={`${process.env.REACT_APP_API_URL}/api/RFID/download/format`}
            className={classnames(css.download)}
            tooltip="Descargar formato"
          >
            <GetAppIcon /> Formato
          </Button>

          <Button
            type="button"
            rel="noopener"
            color="secondary"
            variant="contained"
            href={`${process.env.REACT_APP_API_URL}/api/RFID/download/example`}
            className={classnames(css.download)}
            tooltip="Descargar ejemplo"
          >
            <GetAppIcon /> Ejemplo
          </Button>
        </div>
        <Field
          required
          name="excel_file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          placeholder={t("_labels.excel_placeholder")}
          component={FileDragAndDropField}
          className={classnames(css.drop)}
        />

        {excelErrors.length > 0 &&
          excelErrors.map((error, index) => (
            <Fragment key={index}>
              <Typography
                noWrap
                component="span"
                className={classnames(css.error)}
              >
                {error}
              </Typography>
            </Fragment>
          ))}
        <div className={classnames(css.action)}>
          <div className={classnames(css.buttons_right)}>
            <Button
              type="button"
              onClick={onClose}
              color="secondary"
              disabled={isSubmitting}
            >
              {t("_buttons.cancel")}
            </Button>
            <Button
              type="submit"
              color="secondary"
              disabled={isSubmitting || !isValid}
            >
              {t("_buttons.register")}
            </Button>
          </div>
        </div>
      </>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  excelErrors: PropTypes.array.isRequired,
};

export default Page;
