import VehicleTypeForm from "./VehicleTypeForm";
import FilterVehicleTypeForm from "./FilterVehicleTypeForm";
import InfoVehicleTypeCard from "./InfoVehicleTypeCard";
import axle from "./axle";

export default {
  axle,
  vehicle_type_form: VehicleTypeForm,
  filter_vehicle_type_form: FilterVehicleTypeForm,
  info_vehicle_type_card: InfoVehicleTypeCard,
};
