import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import ModelVariationPolicyForm from "components/organisms/subsidiary/ModelVariationPolicyForm";
import TableResponsive from "components/organisms/general/TableResponsive";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import FixedButton from "components/molecules/general/FixedButton";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

import css from "./index.module.scss";

function page({
  t,
  subsidiaryId,
  tireModelVariationPolicyId,
  modelVariationPolicies,

  updateTireModelVariationPolicyPermit,
  deleteTireModelVariationPolicyPermit,
  createTireModelVariationPolicyPermit,

  openForm,
  handleEdit,
  handleDelete,
  handleOpenForm,
  handleCloseForm,
}) {
  return (
    <SubsidiaryTabs
      className={classnames({
        [css.empty]: modelVariationPolicies.data.length === 0,
      })}
    >
      <div className={classnames(css.container)}>
        {modelVariationPolicies.data.length > 0 ? (
          <TableResponsive
            rows={modelVariationPolicies.data.map((policy) => {
              return {
                id: policy.tire_model_variation_policy_id,
                key: policy.tire_model_variation_policy_id,
                model: policy.tire_model_variation.tire_model.name,
                depth: policy.tire_model_variation.depth,
                size: policy.tire_model_variation.tire_size.size,
                layers: policy.tire_model_variation.number_layers,
                maximum_pressure: `${policy.maximum_pressure} psi`,
                recommended_pressure: `${policy.recommended_pressure} psi`,
                tolerance: `${policy.tolerance} psi`,
                edit: {
                  tooltipPermit: !updateTireModelVariationPolicyPermit
                    ? t("_permits.update.model_pressure_policy")
                    : "",
                },
                delete: {
                  tooltipPermit: !deleteTireModelVariationPolicyPermit
                    ? t("_permits.delete.model_pressure_policy")
                    : "",
                },
              };
            })}
            headers={[
              { id: "model", label: t("_labels.model") },
              {
                id: "depth",
                label: t("_labels.depth"),
              },
              {
                id: "size",
                label: t("_labels.size"),
              },
              {
                id: "layers",
                label: t("_labels.layer"),
              },
              {
                id: "maximum_pressure",
                label: t("_labels.tire_model_variation.maximum_pressure"),
              },
              {
                id: "recommended_pressure",
                label: t("_labels.tire_model_variation.recommended_pressure"),
              },
              {
                id: "tolerance",
                label: t("_labels.tolerance"),
              },
              { id: "actions", label: "" },
            ]}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onChangeStatus={() => {}}
            onChangeApproved={() => {}}
          />
        ) : (
          <EmptyListIcon text={t("_general.no_model_variation_policies")} />
        )}
      </div>

      <FixedButton
        tooltip={
          !createTireModelVariationPolicyPermit
            ? t("_permits.create.model_pressure_policy")
            : ""
        }
        onClick={handleOpenForm}
      />

      <ModelVariationPolicyForm
        open={openForm}
        onClose={handleCloseForm}
        subsidiaryId={subsidiaryId}
        tireModelVariationPolicyId={tireModelVariationPolicyId}
      />
    </SubsidiaryTabs>
  );
}

page.propTypes = {
  t: PropTypes.func.isRequired,
  modelVariationPolicies: PropTypes.object.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  tireModelVariationPolicyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  updateTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  deleteTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  createTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  openForm: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
};

export default page;
