import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetAlertsOfVehicleFilters } from "store/actions/vehicle/alert/setAlertsOfVehicleFilters";
import { actionClearAlertsOfVehicleFilters } from "store/actions/vehicle/alert/clearAlertsOfVehicleFilters";
import { useLang } from "hooks/lang";

import { useFormik } from "hooks/formik";

import Page from "./page";

export function FilterAlertsOfVehicleForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { filter, setVehicleAlertsFilters, clearVehicleAlertsFilters } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      date_from: "",
      date_to: "",
      ranking_alert: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        ranking_alert: filter.ranking_alert || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearVehicleAlertsFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      if (values.ranking_alert) {
        params.ranking_alert = values.ranking_alert;
      }

      await setVehicleAlertsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleClean={handleClean}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

FilterAlertsOfVehicleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Vehicle.Alert.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setVehicleAlertsFilters: actionSetAlertsOfVehicleFilters(dispatch),
  clearVehicleAlertsFilters: actionClearAlertsOfVehicleFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAlertsOfVehicleForm);
