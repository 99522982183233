import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Pagination from "components/molecules/general/Pagination";
import FixedButton from "components/molecules/general/FixedButton";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import TireModelTabs from "components/organisms/tire/model/TireModelTabs";
import ListItemApprovedCard from "components/molecules/general/cards/ListItemApprovedCard";
import TireModelVariationForm from "components/organisms/tire/model/variation/TireModelVariationForm";
import InfoTireModelVariationDialog from "components/organisms/tire/model/variation/InfoTireModelVariationDialog";

import css from "./index.module.scss";

function Page({
  t,
  formOpen,
  dialogOpen,
  variations,
  tireModelId,
  tireModelVariationId,
  createTireModelVariationPermit,
  handleOpenForm,
  handleCloseForm,
  handleOpenDialog,
  handleCloseDialog,
  handleChangePage,
}) {
  return (
    <TireModelTabs
      className={classnames({
        [css.empty]: variations.data.length === 0,
      })}
    >
      {variations.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            {variations.data.map((variation, index) => {
              return (
                <ListItemApprovedCard
                  key={index}
                  gender={"female"}
                  className={css.card}
                  status={variation.status}
                  approved={variation.approved}
                  description={`${variation.depth.toFixed(2)} mm`}
                  title={`${variation.tire_size.size} / ${variation.number_layers}`}
                  onClick={() => {
                    handleOpenDialog(variation.tire_model_variation_id);
                  }}
                />
              );
            })}
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={variations.current_page}
              totalPages={variations.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("no_tire_model_variation")} />
      )}

      <FixedButton
        disabled={!createTireModelVariationPermit}
        tooltip={!createTireModelVariationPermit ? t("permits.create") : ""}
        onClick={handleOpenForm}
      />
      <TireModelVariationForm
        open={formOpen}
        tireModelId={tireModelId}
        tireModelVariationId={tireModelVariationId}
        onClose={handleCloseForm}
      />
      <InfoTireModelVariationDialog
        open={dialogOpen}
        tireModelId={tireModelId}
        tireModelVariationId={tireModelVariationId}
        onClose={handleCloseDialog}
        handleOpen={handleOpenForm}
      />
    </TireModelTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  formOpen: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  variations: PropTypes.object.isRequired,
  tireModelVariationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  createTireModelVariationPermit: PropTypes.bool.isRequired,
  tireModelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

export default Page;
