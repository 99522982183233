import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchInput from "components/molecules/general/SearchInput";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import AddIcon from "@material-ui/icons/Add";

import ConditionPolicyForm from "components/organisms/subsidiary/vehicle/condition/ConditionPolicyForm";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import TableMultiple from "components/organisms/general/TableMultiple";
import ConditionPolicyFilterForm from "components/organisms/subsidiary/vehicle/condition/ConditionPolicyFilterForm";
import Pagination from "components/molecules/general/Pagination";
import MultipleConditionPolicyForm from "components/organisms/subsidiary/vehicle/condition/MultipleConditionPolicyForm";

import css from "./index.module.scss";

function Page({
  t,
  search,
  conditionPoliciesData,
  subsidiaryId,
  vehicleId,
  vehicleIds,
  policyId,
  openForm,
  openFilter,
  openMultiple,
  updateVehicleConditionPolicyPermit,
  deleteVehicleConditionPolicyPermit,
  createVehicleConditionPolicyPermit,
  handleSearch,
  handleEdit,
  handleOpenForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  handleChangePage,
  handleOpenMultiple,
  handleCloseMultiple,
}) {
  return (
    <SubsidiaryTabs
      className={classnames({
        [css.empty]: conditionPoliciesData.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            onClick={handleOpenFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      {conditionPoliciesData.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableMultiple
              headers={[
                { label: t("_general.vehicles"), colspan: 6 },
                { label: t("_labels.axle.plural"), colspan: 3 },
              ]}
              rows={conditionPoliciesData.data.map((vehicle) => ({
                id: vehicle.vehicle_id,
                subsidiary: vehicle.subsidiary.name,
                division:
                  vehicle.division.length > 0 ? vehicle.division[0].name : "",
                brand: vehicle.vehicle_brand.name,
                economic_number: vehicle.economic_number,
                type_of_vehicle: vehicle.vehicle_type.name,
                add: (
                  <Button
                    variant="icon"
                    color="secondary"
                    onClick={() => handleOpenForm(vehicle.vehicle_id)}
                    disabled={!createVehicleConditionPolicyPermit}
                    tooltip={
                      !createVehicleConditionPolicyPermit
                        ? t("_permits.create.condition_policy")
                        : ""
                    }
                  >
                    <AddIcon />
                  </Button>
                ),
                multiple:
                  vehicle.vehicle_condition_policy.length > 0
                    ? vehicle.vehicle_condition_policy.map((policy) => ({
                        id: policy.vehicle_condition_policy_id,
                        axle: t(
                          `_labels.axle_field.options.${policy.axle_type.toLowerCase()}`
                        ),
                        condition: t(
                          `_labels.tire_condition.options.${policy.condition.toLowerCase()}`
                        ),
                        edit: {
                          tooltipPermit: !updateVehicleConditionPolicyPermit
                            ? t("_permits.update.condition_policy")
                            : "",
                          onEdit: () =>
                            handleEdit(
                              vehicle.vehicle_id,
                              policy.vehicle_condition_policy_id
                            ),
                        },
                        delete: {
                          tooltipPermit: !deleteVehicleConditionPolicyPermit
                            ? t("_permits.delete.condition_policy")
                            : "",
                          onDelete: () =>
                            handleDelete(
                              subsidiaryId,
                              vehicle.vehicle_id,
                              policy.vehicle_condition_policy_id
                            ),
                        },
                      }))
                    : [{ id: "", axle: "", condition: "" }],
              }))}
              propertys={[
                "subsidiary",
                "division",
                "brand",
                "economic_number",
                "type_of_vehicle",
                "add",
                "multiple",
              ]}
              nestedPropertys={["axle", "condition"]}
              nestedHeaders={[
                t("_labels.subsidiary.singular"),
                t("_labels.division.singular"),
                t("_labels.brand"),
                t("_labels.economic_number"),
                t("_labels.vehicle_type.label"),
                "",
                t("_labels.axle.plural"),
                t("_labels.condition"),
                "",
              ]}
              multipleProperty={"multiple"}
              selectable={createVehicleConditionPolicyPermit}
              selectAction={handleOpenMultiple}
              selectActionButton={t("_general.set")}
              totalRows={conditionPoliciesData.total}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={conditionPoliciesData.current_page}
              totalPages={conditionPoliciesData.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_titles.policy.no_condition_policy")} />
      )}

      <ConditionPolicyForm
        open={openForm}
        onClose={handleCloseForm}
        subsidiaryId={subsidiaryId}
        vehicleId={vehicleId}
        conditionPolicyId={policyId}
      />

      <ConditionPolicyFilterForm
        open={openFilter}
        onClose={handleCloseFilter}
      />

      <MultipleConditionPolicyForm
        open={openMultiple}
        onClose={handleCloseMultiple}
        subsidiaryId={subsidiaryId}
        vehicleIds={vehicleIds}
      />
    </SubsidiaryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  conditionPoliciesData: PropTypes.object.isRequired,
  policy: PropTypes.object,
  openForm: PropTypes.bool.isRequired,
  openUpdate: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  openMultiple: PropTypes.bool.isRequired,
  updateConditionPolicy: PropTypes.func,
  handleSearch: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleCloseUpdate: PropTypes.func.isRequired,
  updateVehicleConditionPolicyPermit: PropTypes.bool.isRequired,
  deleteVehicleConditionPolicyPermit: PropTypes.bool.isRequired,
  createVehicleConditionPolicyPermit: PropTypes.bool.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleIds: PropTypes.array.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenMultiple: PropTypes.func.isRequired,
  handleCloseMultiple: PropTypes.func.isRequired,
};

export default Page;
