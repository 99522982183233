import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LabelAndText from "components/molecules/general/LabelAndText";

import Form from "./Form";
import { LOCATIONS } from "utils/tire";

import css from "./index.module.scss";

function Page({
  t,
  to,
  tire,
  movement,
  movementTireId,
  open,
  damages,
  wears,
  providers,
  drivers,
  retirementCauses,
  rfids,
  brands,
  warehouses,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>
          {to === "REVITALIZATION"
            ? t("_titles.send.revitalization")
            : to === "REPAIR"
            ? t("_titles.send.repair")
            : to === "PILE"
            ? t("_titles.send.pile")
            : to === "REJECTION"
            ? t("_titles.retread_rejection")
            : t("_titles.send.warehouse")}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          {!initialValues.similar_tires && (
            <>
              <div className={classnames(css.w100, css.flex)}>
                <LabelAndText
                  className={classnames(css.third_width)}
                  label={
                    tire.device_code
                      ? t("_labels.rfid_field.label")
                      : t("_labels.code")
                  }
                  text={tire.code || tire.device_code}
                />
                {tire.cycle.movement_tire.movement == LOCATIONS.MOUNT && (
                  <LabelAndText
                    className={classnames(css.third_width)}
                    label={t("_labels.tire_position")}
                    text={
                      tire.cycle.movement_tire?.vehicle_tire[0]?.vehicle_type_axle_tire.position.toString() ||
                      "0"
                    }
                  />
                )}
                <LabelAndText
                  className={classnames(css.third_width)}
                  label={t("_labels.condition")}
                  text={t(
                    `_labels.tire_condition.options.${tire.cycle.tire_condition_id.toLowerCase()}`
                  )}
                />
              </div>
              <div className={classnames(css.w100, css.flex)}>
                <LabelAndText
                  className={classnames(css.third_width)}
                  label={t("_labels.brand_field.label")}
                  text={
                    tire.cycle.number_cycle > 0
                      ? tire.cycle.revitalized.brand.name
                      : tire.cycle.variation.tire_model.brand.name
                  }
                />
                <LabelAndText
                  className={classnames(css.third_width)}
                  label={t("_labels.tire_model_field.label")}
                  text={
                    tire.cycle.number_cycle > 0
                      ? tire.cycle.revitalized?.name
                      : tire.cycle.variation.tire_model.name
                  }
                />
                <LabelAndText
                  className={classnames(css.third_width)}
                  label={t("_labels.size")}
                  text={tire.cycle.variation.tire_size.size}
                />
              </div>
            </>
          )}
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                to={to}
                movement={movement}
                movementTireId={movementTireId}
                damages={damages}
                wears={wears}
                providers={providers}
                drivers={drivers}
                retirementCauses={retirementCauses}
                warehouses={warehouses}
                brands={brands}
                rfids={rfids}
                onClose={onClose}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  tire: PropTypes.object,
  movement: PropTypes.string,
  movementTireId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  damages: PropTypes.array.isRequired,
  wears: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  retirementCauses: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
