import classnames from "classnames";
import css from "./index.module.scss";
import React from "react";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

function TirePerformanceChasisTablePage(props) {
  const t = props.t;

  function getAxleOffset(axleNumber) {
    let offset = 0;
    for (let i = 0; i < axleNumber; i++) {
      offset += props.vehicleTypeAxles[i].tireCount;
    }
    return offset;
  }

  return (
    <div className="table-responsive">
      <table className="table text-center">
        <tbody>
          {props.vehicleTypeAxles.map((axle, index) => {
            return (
              <>
                <tr className="table-bordered">
                  <td className={classnames(css.table_cell_tires)} colSpan={4}>
                    <b>
                      <span>
                        {axle.position === "REFECTION"
                          ? `REFACCIÓN `
                          : `Eje ${index + 1} `}
                        {`(${axle.tireCount} neumáticos)`}
                      </span>
                    </b>
                  </td>
                </tr>
                <tr key={index} className="table-bordered">
                  {axle.tireCount < 3 && (
                    <td className={classnames(css.table_cell_tires)}></td>
                  )}
                  {[...Array(axle.tireCount)].map((e, i) => {
                    const offset = getAxleOffset(index);
                    const tire = props.tires.find(
                      (t) => t.position === i + 1 + offset
                    );
                    if (!tire) {
                      return (
                        <td
                          key={i}
                          className={classnames(css.table_cell_tires)}
                        >
                          <div>S/N</div>
                        </td>
                      );
                    }
                    return (
                      <td key={i} className={classnames(css.table_cell_tires)}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span>
                              {axle.position === "REFECTION"
                                ? "R"
                                : `Posición ${tire.position ?? i + 1 + offset}`}
                            </span>
                            {tire.depthCondition && (
                              <>
                                <span>{": "}</span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      tire.depthCondition ===
                                      "CRITICAL WITHDRAWAL"
                                        ? "red"
                                        : tire.depthCondition ===
                                          "SCHEDULED WITHDRAWAL"
                                        ? "#8d6c06"
                                        : "green",
                                  }}
                                >
                                  {t(
                                    `_labels.depth_tolerance_policies.${tire.depthCondition
                                      .replace(" ", "_")
                                      .toLowerCase()}`
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                          {tire.economicNumber && (
                            <span>{`Código: ${tire.economicNumber}`}</span>
                          )}
                          {tire.brand && <span>{tire.brand}</span>}
                          {tire.model && <span>{tire.model}</span>}
                          {tire.size && <span>{`LDD: ${tire.size}`}</span>}
                          {tire.depth !== undefined && (
                            <span>{`${formatter.format(tire.depth)} mm`}</span>
                          )}
                          {tire.pressure !== undefined && (
                            <span>
                              {`PSI: ${formatter.format(tire.pressure)}`}
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  })}
                  {axle.tireCount < 3 && (
                    <td className={classnames(css.table_cell_tires)}></td>
                  )}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

TirePerformanceChasisTablePage.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleTypeAxles: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string.isRequired,
      tireCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  tires: PropTypes.arrayOf(
    PropTypes.shape({
      tireId: PropTypes.number,
      economicNumber: PropTypes.string,
      deviceCode: PropTypes.string,
      position: PropTypes.number,
      createdAt: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      size: PropTypes.string,
      depthVariation: PropTypes.number,
      depthCondition: PropTypes.string,
      depth: PropTypes.number,
      dualDifference: PropTypes.number,
      accumulatedMileage: PropTypes.number,
      monthsActive: PropTypes.number,
      monthlyWear: PropTypes.number,
      wearPercentage: PropTypes.number,
      pressure: PropTypes.number,
      pressureCondition: PropTypes.string,
      projection: PropTypes.number,
      hasAlerts: PropTypes.bool,
      handleOpenAlertDialog: PropTypes.func,
    }).isRequired
  ),
};

export default TirePerformanceChasisTablePage;
