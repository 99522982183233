export default {
  disabled: "Disabled revitalized tire model",
  messages: {
    enable: "Are you sure to enable revitalized tire model?",
    disable: "Are you sure to disable revitalized tire model?",
  },
  permits: {
    change_status: "No permissions to {action} revitalized tire models",
  },
};
