import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Formik } from "formik";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
  handleClean,
  onClose,
  open,
  ...rest
}) {
  return (
    <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{t("_general.filter")}</DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {(p) => (
            <Form
              onClose={onClose}
              handleClean={handleClean}
              {...rest}
              {...p}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
  setSubsidiaries: PropTypes.func.isRequired,
  setVehicleTypes: PropTypes.func.isRequired,
};

export default Page;
