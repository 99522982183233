import { http } from "store/actions/http";
import { DELETE_ASSOCIATION } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteAssociation = (dispatch) => async (associationId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: `DELETE`,
      path: `association/${associationId}`,
    });
    dispatch({
      type: DELETE_ASSOCIATION,
      payload: associationId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
