import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    values,
    dirty,
    brands,
    setFieldValue,
    getProviders,
    getWarehouses,
    getTireModelVariations,
    revitalizedModels,
    tireModels,
    setNotification,
  } = rest;

  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [tireModelVariations, setTireModelVariations] = useState([]);
  const [revModels, setRevModels] = useState(revitalizedModels);
  const [models, setModels] = useState(tireModels);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      try {
        let data = [];
        data = await getProviders(
          {
            subsidiaries: values.subsidiary_id,
            scope: "provider_id,name,status",
          },
          false
        );

        setProviders(data.sort(alphabeticalSort("name")));
        data = await getWarehouses(
          {
            subsidiaries: values.subsidiary_id,
            scope: "warehouse_id,name,status",
          },
          false
        );
        setWarehouses(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (mounted.current && values.subsidiary_id) {
      fetchData();
    }

    return () => {
      mounted.current = false;
    };
  }, [values.subsidiary_id]);

  useEffect(() => {
    if (dirty) {
      setFieldValue("tire_model_id", null);
      setFieldValue("tire_model_variation_id", "");
    }

    if (dirty && values.brand_id) {
      setModels(
        tireModels.filter((model) => model.brand_id == values.brand_id.brand_id)
      );
    }
  }, [values.brand_id]);

  useEffect(() => {
    mounted.current = true;

    async function fetchData() {
      try {
        let data = [];
        dirty && setFieldValue("tire_model_variation", "");

        data = await getTireModelVariations(
          {
            tireModelId: values.tire_model_id.tire_model_id,
            scope:
              "tire_model_variation_id,tire_size.size,number_layers,status,approved",
          },
          false
        );
        setTireModelVariations(data);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (mounted.current && values.tire_model_id) {
      fetchData();
    }
    return () => {
      mounted.current = false;
    };
  }, [values.tire_model_id]);

  useEffect(() => {
    dirty && setFieldValue("revitalized_tire_model_id", null);
    if (values.revitalized_brand_id) {
      setRevModels(
        revitalizedModels.filter(
          (model) => model.brand_id == values.revitalized_brand_id.brand_id
        )
      );
    }
  }, [values.revitalized_brand_id]);

  return (
    <Page
      {...rest}
      t={t}
      brands={brands.filter((brand) => brand.brand_type === "TIRE")}
      revitalizedBrands={brands.filter(
        (brand) => brand.brand_type === "RETREAD"
      )}
      providers={providers}
      warehouses={warehouses}
      tireModelVariations={tireModelVariations}
      revitalizedModels={revModels}
      tireModels={models}
    />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  tire: PropTypes.object,
  values: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
  getProviders: actionGetProviders(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
