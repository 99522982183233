import { combineReducers } from "redux";

import Stats from "./stats";
import Summary from "./summary";
import BestPerformance from "./best_performance";
import KmProjection from "./km_projection";
import Pile from "./pile";
import DynamicCost from "./dynamic_cost";
import DamagesWears from "./damages_wears";
import RenewabilityIndex from "./renewability_index";
import ReviewPerformance from "./review_performance";
import ReviewPerformanceCompany from "./review_performance_company";
import Warehouse from "./warehouse";
import PotentialSavings from "./potential_savings";
import Mount from "./mount";
import UserReview from "./user_review";
import Purchase from "./purchase";
import LastMovement from "./last_movement";
import Semaphore from "./semaphore";
import FleetSemaphore from "./fleet_semaphore";
import Alert from "./alert_report";
import TireRevitalized from "./tire_revitalized";
import SummaryWarehouse from "./summary_warehouse";
import SummaryMount from "./summary_mount";
import TireRepair from "./tire_repair";
import KmPile from "./km_pile";
import CompletedCycles from "./completed_cycles";
import ScheduledWithdrawal from "./scheduled_withdrawal";

export default combineReducers({
  Stats,
  Summary,
  DamagesWears,
  BestPerformance,
  KmProjection,
  Pile,
  DynamicCost,
  RenewabilityIndex,
  ReviewPerformance,
  Warehouse,
  PotentialSavings,
  Mount,
  UserReview,
  Purchase,
  LastMovement,
  Semaphore,
  FleetSemaphore,
  Alert,
  TireRevitalized,
  SummaryWarehouse,
  SummaryMount,
  TireRepair,
  KmPile,
  ReviewPerformanceCompany,
  CompletedCycles,
  ScheduledWithdrawal,
});
