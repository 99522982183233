export const REVIEW_TYPES = [
  { value: "COMPLETE", label: "_labels.review_type.options.complete" },
  { value: "PRESSURE", label: "_labels.review_type.options.pressure" },
  { value: "IDENTIFY", label: "_labels.review_type.options.identify" },
  {
    value: "DAMAGE AND WEAR",
    label: "_labels.review_type.options.damage_and_wear",
  },
  {
    value: "MOUNT/DISMOUNT",
    label: "_labels.review_type.options.mount_dismount",
  },
  { value: "ROTATION", label: "_labels.review_type.options.rotation" },
  { value: "INITIAL", label: "_labels.review_type.options.initial" },
  { value: "PARTIAL", label: "_labels.review_type.options.partial" },
];
