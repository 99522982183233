import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionChangeTireLocation } from "store/actions/tire/changeTireLocation";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

function TireMoveForm({ ...rest }) {
  const t = useLang();
  const {
    movementTireId,
    open,
    onClose,
    setNotification,
    getCompanies,
    changeTireLocation,
    clearTireFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      company_id: "",
      subsidiary_id: "",
      warehouse_id: "",
    },
  });

  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        company_id: "",
        subsidiary_id: "",
        warehouse_id: "",
      });
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = { ...values };

      const response = await changeTireLocation(movementTireId, fields);

      setNotification({
        message: response.message,
      });
      onClose();
      clearTireFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      companies={companies}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

TireMoveForm.propTypes = {
  movementTireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  changeTireLocation: actionChangeTireLocation(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireMoveForm);
