import {
  GET_TIRE_CYCLES_HISTORY,
  CLEAR_TIRE_CYCLES_HISTORY,
  GET_TIRE_CYCLE_HISTORY,
  CLEAR_TIRE_CYCLE_HISTORY,
} from "store/actions";

const initialState = {
  tire_cycles_history: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  tire_cycle_history: {
    provider_id: 0,
    tire_condition_id: "",
    price: "",
    expected_durability: 0,
    provider: {
      name: "",
    },
    variation: {
      tire_model: {
        name: "",
      },
      tire_size: {
        size: "",
      },
      number_layers: 0,
      tire_model_id: 0,
      tire_model_variation_id: 0,
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_CYCLES_HISTORY:
      return {
        ...state,
        tire_cycles_history: action.payload,
      };

    case CLEAR_TIRE_CYCLES_HISTORY:
      return {
        ...state,
        tire_cycles_history: { ...initialState.tire_cycles_history },
      };

    case GET_TIRE_CYCLE_HISTORY:
      return {
        ...state,
        tire_cycle_history: action.payload,
      };

    case CLEAR_TIRE_CYCLE_HISTORY:
      return {
        ...state,
        tire_cycle_history: { ...initialState.tire_cycle_history },
      };

    default:
      return state;
  }
}
