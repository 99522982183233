import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Link from "@material-ui/core/Link";
import Zoom from "@material-ui/core/Zoom";
import FilterListIcon from "@material-ui/icons/FilterList";
import ContainerTemplate from "components/templates/general/Container";

import Button from "components/molecules/general/Button";
import FilterScheduledWithdrawalForm from "components/organisms/report/FilterScheduledWithdrawalForm";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const daysPerKmformatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 0,
});

function Page({
  t,
  language,
  data,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <ContainerTemplate
      title={t("_titles.scheduled_withdrawal_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="container-fluid pdl100 sidebar_font">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.companies && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiaries && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.movement && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.location.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.movement}
                    </p>
                  </div>
                )}

                {filter.days && filter.days != "none" && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.projection_day")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.days}
                    </p>
                  </div>
                )}
                {filter.date_from && filter.date_to && (
                  <>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.from")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_from).format("LL")}
                      </p>
                    </div>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.to")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_to).format("LL")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">
              {t("_titles.scheduled_withdrawal_report")}
            </h1>
            <h5 className="sinmp">
              Este reporte muestra los neumáticos que según la política de
              retiro programado, están próximos a retirarse.
              <div
                className="mx-auto mt-1"
                style={{
                  backgroundColor: "beige",
                  padding: "6px 8px",
                  borderRadius: "4px",
                  border: "1px solid dimgray",
                  width: "fit-content",
                }}
              >
                <p className="m-0">
                  <small>Km por día = Km recorrido / Dias en servicio.</small>
                </p>
                <p className="m-0">
                  <small>
                    Días proyectados = Proyeccion de Km / Km por día
                  </small>
                </p>
              </div>
            </h5>
            <hr />
          </div>
        </div>
      </div>

      {/* Tabla */}
      {data.data && data.data.length > 0 && (
        <Zoom in timeout={550}>
          <div className="sidebar_font col-md-12">
            <div className="card">
              <div className="card-header sMarcoInfoFondoAzul">
                <p className="m-0 h5">
                  {`${data.data.length} ${t("_labels.tire.plural")}`}
                  {" | "}
                  {t("_labels.dynamic_cost_report.cost")} $
                  {formatter.format(data.total_cost)}
                </p>
              </div>
              <div className="table-responsive">
                <table className="table table-hover sFS12 m-0">
                  <thead>
                    <tr
                      className=""
                      style={{ backgroundColor: "#002849", color: "white" }}
                    >
                      <td className="text-center align-middle ">
                        {t("_labels.subsidiary.singular")}
                      </td>
                      {/* <td className="text-center align-middle">
                        {t("_labels.division.singular")}
                      </td> */}
                      <td className="text-center align-middle">
                        {t("_labels.code")}
                      </td>
                      {/* <td className="text-center align-middle">
                        RFID
                      </td> */}
                      <td className="text-center align-middle">
                        {t("_labels.model")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.location.label")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.ranking")}
                      </td>
                      {/* <td className="text-center align-middle">
                        {t("_labels.condition")}
                      </td> */}
                      <td className="text-center align-middle">
                        {t("_labels.depth")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.critical_withdrawal")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.mileage")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.tire_days_service")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.km_day")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.projection")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.projection_day")}
                      </td>
                      <td className="text-center align-middle">
                        {t("_labels.last_purchase_price")}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((row, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">
                          {row.subsidiary_name}
                        </td>
                        {/* <td className="text-center">{row.division_name}</td> */}
                        <td className="text-center align-middle">
                          <Link
                            href={`/tire/${row.tire_id}/history`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {/* <button className="btn btn-sm btn-primary text-white w-auto"> */}
                            {row.code}
                            {/* </button> */}
                          </Link>
                        </td>
                        {/* <td className="text-center align-middle">{row.device_code}</td> */}
                        <td className="text-center align-middle">
                          {row.model}
                        </td>
                        <td className="text-center align-middle">
                          {row.location}
                        </td>
                        <td className="text-center align-middle">
                          {row.real_position || "-"}
                        </td>
                        <td className="text-center align-middle">
                          {row.min_depth} mm
                        </td>
                        <td className="text-center align-middle">
                          {row.depth_critical_policy} mm
                        </td>
                        <td className="text-center align-middle">
                          {formatter.format(row.tire_travel)} km
                        </td>
                        <td className="text-center align-middle text-lowercase">
                          {formatter.format(row.days_in_service)}{" "}
                          {row.days_in_service != 1
                            ? t("_labels.days")
                            : t("_labels.day")}
                        </td>
                        <td className="text-center align-middle">
                          {daysPerKmformatter.format(row.km_day)}
                        </td>
                        {/* <td className="text-center">
                          {t(
                            `_labels.tire_condition.options.${row.tire_condition_id.toLowerCase()}`
                          )}
                        </td> */}
                        <td className="text-center align-middle">
                          {formatter.format(row.projection)} km
                        </td>
                        <td className="text-center align-middle">
                          {formatDays(row.projection_day, language)}
                        </td>
                        <td className="text-center align-middle">
                          ${formatter.format(row.current_cost)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Zoom>
      )}

      <FilterScheduledWithdrawalForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  data: PropTypes.object,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
};

export default Page;

function formatDays(days, language = "es") {
  const pluralRules = new Intl.PluralRules(language);

  const getPluralForm = (unit, value, language) => {
    const rule = pluralRules.select(value);
    const forms = {
      es: {
        day: { one: "día", other: "días" },
        month: { one: "mes", other: "meses" },
        year: { one: "año", other: "años" },
      },
      en: {
        day: { one: "day", other: "days" },
        month: { one: "month", other: "months" },
        year: { one: "year", other: "years" },
      },
    };
    return forms[language][unit][rule];
  };

  const conjunction = language === "es" ? " y " : " and ";

  if (days < 30) {
    return `${Math.floor(days)} ${getPluralForm(
      "day",
      Math.floor(days),
      language
    )}`;
  } else if (days < 365) {
    const months = Math.floor(days / 30);
    return `${months} ${getPluralForm("month", months, language)}`;
  } else {
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const months = Math.floor(remainingDays / 30);
    const yearPart = `${years} ${getPluralForm("year", years, language)}`;
    const monthPart =
      months > 0
        ? `${conjunction}${months} ${getPluralForm("month", months, language)}`
        : "";
    return yearPart + monthPart;
  }
}
