import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "components/molecules/general/Button";

import Typography from "@material-ui/core/Typography";

import css from "./index.module.scss";

function Page({
  t,
  title,
  description,
  status,
  className,
  tooltipPermit,
  handleClick,
}) {
  return (
    <Card className={classnames(css.card, className)}>
      <CardContent>
        <Typography noWrap variant="h6" component="h6">
          {title}
        </Typography>

        <Typography noWrap variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={handleClick}
          disabled={!!tooltipPermit}
          tooltip={tooltipPermit}
        >
          {t("_buttons.view")}
        </Button>

        <Chip
          size="small"
          variant="outlined"
          className={classnames(css.card_state)}
          label={status ? t("_general.inside") : t("_general.outside")}
        />
      </CardActions>
    </Card>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  description: PropTypes.number.isRequired,
  tooltipPermit: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Page;
