export default {
  title: "Geocerca",
  imei: "IMEI del gps",
  date: "Fecha",
  odometer: "Odometro",
  status: "Estatus de la geocerca",
  area: "Area",
  inside: "Adentro",
  outside: "Afuera",
};
