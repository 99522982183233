import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, FieldArray, Form } from "formik";
import { TextField } from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  rfids,
  isRegisterForm,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <FieldArray
        name="tires"
        validateOnChange={false}
        render={() => (
          <>
            {values.tires &&
              values.tires.map((tire, index) => (
                <div
                  key={index}
                  className={classnames(css.full_width, css.field_wrap)}
                >
                  <div className={classnames(css.full_width)}>
                    <Typography
                      className={classnames(css.ml8, css.mr8)}
                      variant="overline"
                      color="textSecondary"
                    >
                      {`${t("_general.tire")} ${index + 1}`}
                    </Typography>
                  </div>
                  <Field
                    required={!values.tires[index].device_code}
                    variant="filled"
                    margin="normal"
                    name={`tires[${index}].code`}
                    component={TextField}
                    className={classnames(css.field)}
                    label={t("_labels.code")}
                  />

                  <Field
                    required={!values.tires[index].code}
                    variant="filled"
                    margin="normal"
                    name={`tires[${index}].device_code`}
                    className={classnames(css.field)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    defaultText={t("_labels.rfid_field.default")}
                    component={SelectField}
                    label={t("_labels.rfid_field.label")}
                  >
                    {rfids.map((rfid, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={rfid.rfid_id.toString()}
                          disabled={!rfid.status}
                        >
                          {rfid.device_code}
                        </MenuItem>
                      );
                    })}
                  </Field>

                  {isRegisterForm && (
                    <Field
                      variant="filled"
                      margin="normal"
                      name={`tires[${index}].dot`}
                      component={TextField}
                      className={classnames(css.field)}
                      label={t("_labels.dot")}
                    />
                  )}
                </div>
              ))}
          </>
        )}
      />

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  rfids: PropTypes.array.isRequired,
  isRegisterForm: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
