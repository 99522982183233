export default {
  title: "New model",
  update_title: "Update model",
  messages: {
    tire_model_created: "Done, a new tire model was successfully created",
    tire_model_updated: "Done, the tire model was successfully updated",
    delete: "Are you sure to delete model?",
  },
  form: {
    placeholder: "Drop the data sheet document here or click to select it",
    brand: { default: "Select a brand" },
    use: {
      label: "Uses",
      helper_text: "Multiple uses can be selected",
    },
    permits: {
      update: "No permissions to update models",
      delete: "No permissions to delete models",
    },
  },
};
