export default {
  title: "Nuevo modelo",
  update_title: "Actualizar modelo",
  messages: {
    tire_model_created: "Listo, se creo un nuevo modelo de neumático con éxito",
    tire_model_updated: "Listo, se actualizó el modelo de neumático con éxito",
    delete: "¿Estas seguro de eliminar el modelo?",
  },
  form: {
    placeholder:
      "Suelte el documento de la ficha técnica aquí o haga clic para seleccionarla",
    brand: { default: "Selecciona una marca" },
    use: {
      label: "Usos",
      helper_text: "Se pueden seleccionar varios usos",
    },
    permits: {
      update: "Sin permisos para actualizar modelos",
      delete: "Sin permisos para eliminar modelos",
    },
  },
};
