import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";

import css from "./index.module.scss";

function Page({
  t,
  tireModel,
  updateTireModelPermit,
  handleApprovalStatusChange,
  handleOpen,
  handleChangeStatus,
}) {
  return (
    <>
      <Card className={classnames(css.card)} elevation={3}>
        <CardContent className={classnames(css.content)}>
          <Typography
            className={classnames(css.mb8)}
            variant="h6"
            color="textPrimary"
          >
            {t("_general.information")}
          </Typography>

          <div className={classnames(css.flex, css.content_info)}>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.tire_model.model_name")}
                text={tireModel.name}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("model_brand")}
                text={tireModel.brand.name}
              />
            </div>

            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.tire_model.use")}
                text={tireModel.tire_model_use}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.tire_model.application")}
                text={tireModel.tire_model_application}
              />
            </div>

            <div>
              <LabelAndText
                gutterBottom
                label={t("_labels.created_by")}
                text={tireModel.created_by}
                className={classnames(css.mr8)}
              />

              {tireModel.updated_by && (
                <LabelAndText
                  gutterBottom
                  label={t("_labels.updated_by")}
                  text={tireModel.updated_by}
                />
              )}
            </div>
          </div>
        </CardContent>
        <CardActions className={classnames(css.actions)}>
          <div className={classnames(css.half)}>
            <Button
              color="secondary"
              disabled={!updateTireModelPermit}
              tooltip={
                !updateTireModelPermit
                  ? t("permits.change_status", {
                      action: tireModel.status
                        ? t("_general.disable").toLowerCase()
                        : t("_general.enable").toLowerCase(),
                    })
                  : ""
              }
              onClick={handleChangeStatus}
            >
              {tireModel.status ? t("_general.disable") : t("_general.enable")}
            </Button>
            <Button
              color="secondary"
              disabled={!updateTireModelPermit}
              tooltip={
                !updateTireModelPermit
                  ? t("permits.change_approval_status", {
                      action: tireModel.approved
                        ? t("_general.disapprove").toLowerCase()
                        : t("_general.approve").toLowerCase(),
                    })
                  : ""
              }
              onClick={handleApprovalStatusChange}
            >
              {tireModel.approved
                ? t("_general.disapprove")
                : t("_general.approve")}
            </Button>
          </div>
          <div className={classnames(css.half)}>
            <div className={classnames(css.data_sheet)}>
              <Link
                href={`${process.env.REACT_APP_IMAGES_URL}${tireModel.data_sheet}`}
                target="_blank"
              >
                <Button color="secondary">
                  {t("buttons.view_technical_sheet")}
                </Button>
              </Link>
            </div>
            <Button
              variant="contained"
              className={classnames(css.mx8)}
              color="secondary"
              disabled={!tireModel.status}
              tooltip={!tireModel.status ? t("disabled_model") : ""}
              onClick={handleOpen}
            >
              {t("_buttons.edit")}
            </Button>
          </div>
        </CardActions>
      </Card>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  updateTireModelPermit: PropTypes.bool.isRequired,
  tireModel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    status: PropTypes.number.isRequired,
    approved: PropTypes.number.isRequired,
    tire_model_use: PropTypes.string.isRequired,
    tire_model_application: PropTypes.string.isRequired,
    created_by: PropTypes.string.isRequired,
    updated_by: PropTypes.string,
    data_sheet: PropTypes.string.isRequired,
  }).isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleApprovalStatusChange: PropTypes.func.isRequired,
};

export default Page;
