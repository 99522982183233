import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAssociations } from "store/actions/association/getAssociations";
import { actionAddCommissionedDriver } from "store/actions/commissioned/driver/addCommissionedDriver";
import { actionGetCommissionedDriver } from "store/actions/commissioned/driver/getCommissionedDriver";
import { actionClearCommissionedDriver } from "store/actions/commissioned/driver/clearCommissionedDriver";
import { actionUpdateCommissionedDriver } from "store/actions/commissioned/driver/updateCommissionedDriver";
import { actionClearCommissionedDriverFilters } from "store/actions/commissioned/driver/clearCommissionedDriverFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function CommissionedDriverForm({
  open,
  onClose,
  commissionedDriverId,
  ...rest
}) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(commissionedDriverId ? true : false);
  const {
    driver,
    setNotification,
    getAssociations,
    addCommissionedDriver,
    getCommissionedDriver,
    clearCommissionedDriver,
    updateCommissionedDriver,
    clearCommissionedDriverFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      name: "",
      driver_code: "",
      association_id: "",
    },
  });
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAssociations(
          {
            scope: "association_id,name,status",
          },
          false
        );

        setAssociations(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
      clearCommissionedDriver();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getCommissionedDriver(commissionedDriverId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && commissionedDriverId) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (commissionedDriverId && driver.commissioned_driver_id) {
      setInitialValues({
        name: driver.name,
        driver_code: driver.driver_code,
        association_id: driver.association_id.toString(),
      });
    }
  }, [driver]);

  useEffect(() => {
    setIsUpdate(commissionedDriverId ? true : false);
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = values;

      if (isUpdate) {
        await updateCommissionedDriver(fields, commissionedDriverId);
        setNotification({
          message: t("_messages.updated.driver"),
        });
      } else {
        await addCommissionedDriver(fields);
        setNotification({
          message: t("_messages.created.driver"),
        });
      }
      setSubmitting(false);
      resetForm();
      clearCommissionedDriverFilters();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      associations={associations}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

CommissionedDriverForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  commissionedDriverId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const mapStateToProps = (state) => ({
  driver: state.Commissioned.Driver.driver,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getAssociations: actionGetAssociations(dispatch),
  addCommissionedDriver: actionAddCommissionedDriver(dispatch),
  getCommissionedDriver: actionGetCommissionedDriver(dispatch),
  clearCommissionedDriver: actionClearCommissionedDriver(dispatch),
  updateCommissionedDriver: actionUpdateCommissionedDriver(dispatch),
  clearCommissionedDriverFilters:
    actionClearCommissionedDriverFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionedDriverForm);
