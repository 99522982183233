import { http } from "store/actions/http";
import { DELETE_WEAR } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteWear = (dispatch) => async (wearId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `wear/${wearId}`,
    });

    dispatch({
      type: DELETE_WEAR,
      payload: wearId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
