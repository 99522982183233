export default {
  title: "Nuevo Almacén",
  update_title: "Actualizar Almacén",
  form: {
    permits: {
      update: "Sin permisos para actualizar almacenes",
      delete: "Sin permisos para eliminar almacenes",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar el almacén?",
    warehouse_created: "Listo, se creo un nuevo almacén con éxito",
    warehouse_updated: "Listo, se actualizó el almacén con éxito",
  },
};
