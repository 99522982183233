import { http } from "store/actions/http";
import { DELETE_ASSOCIATION_DEPTH_POLICY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteAssociationDepthPolicy =
  (dispatch) =>
  async (
    associationId,
    commissionedVehicleId,
    commissionedVehicleDepthPolicyId
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: `DELETE`,
        path: `association/${associationId}/depth/policy/vehicle/${commissionedVehicleId}/axle/${commissionedVehicleDepthPolicyId}`,
      });
      dispatch({
        type: DELETE_ASSOCIATION_DEPTH_POLICY,
        payload: associationId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
