export default {
  title: "Nuevo tipo de vehículo",
  update_title: "Actualizar tipo de vehículo",
  form: {
    labels: {
      description: "Descripción",
    },
    structure_type: {
      default: "Selecciona un tipo de estructura",
    },
    permits: {
      create: {
        vehicle_type_axle:
          "Sin permisos para crear ejes de un tipo de vehículo",
      },
      update: {
        vehicle_type: "Sin permisos para actualizar tipos de vehículos",
        vehicle_type_axle:
          "Sin permisos para actualizar ejes de un tipo de vehículo",
      },
      delete: {
        vehicle_type: "Sin permisos para eliminar tipos de vehículos",
      },
    },
  },
  messages: {
    vehicle_type_created: "Listo, se creo un nuevo tipo de vehículo con éxito",
    vehicle_type_axles_created:
      "Listo, se crearon los ejes del tipo de vehículo con éxito",
    vehicle_type_updated: "Listo, se actualizó el tipo de vehículo con éxito",
    vehicle_type_axles_updated:
      "Listo, se actualizaron los ejes del tipo de vehículo con éxito",
    vehicle_type_delete: "¿Estas seguro de eliminar el tipo de vehículo?",
  },
};
