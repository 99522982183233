import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";

import { store } from "store";

function ProviderRedux({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

ProviderRedux.propTypes = {
  children: PropTypes.node,
};

export default ProviderRedux;
