import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTire } from "store/actions/vehicle/tire/getVehicleTire";
import { actionDifferentAssignment } from "store/actions/tire/review/differentAssignment";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";

import {
  CREATE_TIRE_PILE,
  CREATE_TIRE_REPAIR,
  CREATE_TIRE_REVITALIZATION,
  UPDATE_TIRE_REVIEW,
} from "store/actions/account/permits";

import { getSubsidiaryViaWorkArea } from "utils/workArea";
import Page from "./page";

export function VehicleMountedTireDialog({
  open,
  vehicleTireId,
  onClose,
  onSend,
  onTireReview,
  onDamagesAndWear,
  onDamagesAndRepair,
  onPhysicalDifference,
  ...rest
}) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    account,
    workArea,
    permits,
    review,
    vehicleTire,
    setNotification,
    getVehicleTire,
  } = rest;

  const subsidiary = getSubsidiaryViaWorkArea(account, workArea);
  const roles = subsidiary[0]?.role.map((r) => r.role.toUpperCase()) ?? [];
  const isOperator = roles.includes("OPERATOR");
  const policyAllowsEdit = !(
    isOperator && subsidiary[0]?.subsidiary.policy.operator_edit === 0
  );
  const isUpdateReview =
    review.vehicle_review_id ==
    vehicleTire.cycle.movement_tire.tire_review[
      vehicleTire.cycle.movement_tire.tire_review.length - 1
    ].vehicle_review_id;
  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleTire({ vehicleId, vehicleTireId });
      } catch (error) {
        setNotification(error, true);
        onClose();
      }
    }

    if (open && vehicleTireId) {
      fetchData();
    }
  }, [open, vehicleTireId]);

  function handlePhysicalDifference() {
    onPhysicalDifference(vehicleTire);
  }

  return (
    <Page
      t={t}
      open={open}
      vehicleTire={vehicleTire}
      onClose={onClose}
      onSend={onSend}
      onTireReview={onTireReview}
      onDamagesAndWear={onDamagesAndWear}
      onPhysicalDifference={handlePhysicalDifference}
      onDamagesAndRepair={onDamagesAndRepair}
      createTireRevitalizationPermit={permits.includes(
        CREATE_TIRE_REVITALIZATION
      )}
      createTireRepairPermit={permits.includes(CREATE_TIRE_REPAIR)}
      createTirePilePermit={permits.includes(CREATE_TIRE_PILE)}
      updateTireReviewPermit={permits.includes(UPDATE_TIRE_REVIEW)}
      isValidReview={moment().diff(moment(review.created_at), "hours") < 24}
      review={review}
      policyAllowsEdit={policyAllowsEdit}
      isUpdateReview={isUpdateReview}
    />
  );
}

VehicleMountedTireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  vehicleTireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func,
  onTireReview: PropTypes.func,
  onDamagesAndWear: PropTypes.func,
  onDamagesAndRepair: PropTypes.func,
  onPhysicalDifference: PropTypes.func,
};

const mapStateToProps = (state) => ({
  vehicleTire: state.Vehicle.Tire.vehicle_tire,
  permits: state.Account.permits,
  review: state.Vehicle.Review.vehicle_review,
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleTire: actionGetVehicleTire(dispatch),
  setDifferentAssignment: actionDifferentAssignment(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleMountedTireDialog);
