import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import WorkArea from "components/organisms/account/WorkArea";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ t, open, handleLogout }) {
  return (
    <DialogMediaQuery open={open} onClose={() => {}}>
      <DialogTitle>
        {t("title")}
        <DialogContentText>{t("description")}</DialogContentText>
      </DialogTitle>
      <DialogContent>
        <WorkArea className={classnames(css.work_area)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} color="secondary">
          {t("logout")}
        </Button>
      </DialogActions>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default Page;
