import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  damages,
  wears,
  isSubmitting,
  onClose,
  handleSubmit,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      {!!values.damages && (
        <>
          <div className={classnames(css.full_width, css.subtitle)}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("_labels.damage.plural")}
            </Typography>
          </div>
          <FieldArray
            name={"damages"}
            validateOnChange={false}
            render={(arrayHelpers) => (
              <>
                {values.damages.map((damage, index) => (
                  <div key={index} className={classnames(css.field_wrap)}>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        id={`${index}_damage_id`}
                        name={`damages[${index}].damage_id`}
                        size="small"
                        autoHighlight
                        blurOnSelect
                        component={Autocomplete}
                        options={damages}
                        getOptionLabel={(option) =>
                          option ? t(`_damage.name.${option.name}`) : ""
                        }
                        getOptionDisabled={(option) => !option.status}
                        getOptionSelected={(option, value) => {
                          return value && option.damage_id === value.damage_id;
                        }}
                        onChange={(_, value) =>
                          setFieldValue(`damages[${index}].damage_id`, value)
                        }
                        value={values["damages"][index]["damage_id"]}
                        noOptionsText={t("_labels.autocomplete.no_options")}
                        openText={t("_labels.autocomplete.open")}
                        clearText={t("_labels.autocomplete.clear")}
                        closeText={t("_labels.autocomplete.close")}
                        className={classnames(
                          "MuiFormControl-root",
                          css.full_width
                        )}
                        renderInput={(params) => (
                          <TextField2
                            {...params}
                            required
                            label={t("_labels.damage.singular")}
                            variant="filled"
                          />
                        )}
                      />
                      <Field
                        multiline
                        rows={2}
                        variant="filled"
                        margin="normal"
                        size="small"
                        name={`damages[${index}].comment`}
                        component={TextField}
                        className={classnames(css.full_width)}
                        label={t("_labels.comment.singular")}
                      />
                    </div>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        name={`damages[${index}].image`}
                        accept="image/*"
                        placeholder={t("_labels.drop_image")}
                        component={FileDragAndDropField}
                        className={classnames(css.drop)}
                      />
                    </div>

                    <div className={classnames(css.w100)}>
                      {values.damages.length > 1 && (
                        <div className={classnames(css.remove_button)}>
                          <Button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            color="secondary"
                            size="small"
                            variant="fab"
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      )}

                      <Divider className={classnames(css.full_width, css.m0)} />
                    </div>
                  </div>
                ))}

                <div className={classnames(css.full_width, css.add_button)}>
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        damage_id: null,
                        comment: "",
                        image: null,
                      })
                    }
                    color="secondary"
                    size="small"
                    variant="fab"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </>
            )}
          />
        </>
      )}

      {!!values.wears && (
        <>
          <div className={classnames(css.full_width, css.subtitle)}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("_labels.wear.plural")}
            </Typography>
          </div>
          <FieldArray
            name={"wears"}
            validateOnChange={false}
            render={(arrayHelpers) => (
              <>
                {values.wears.map((wear, index) => (
                  <div key={index} className={classnames(css.field_wrap)}>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        id={`${index}_wear_id`}
                        name={`wears[${index}].wear_id`}
                        size="small"
                        autoHighlight
                        blurOnSelect
                        component={Autocomplete}
                        options={wears}
                        getOptionLabel={(option) =>
                          option ? t(`_damage.name.${option.name}`) : ""
                        }
                        getOptionDisabled={(option) => !option.status}
                        getOptionSelected={(option, value) => {
                          return value && option.wear_id === value.wear_id;
                        }}
                        onChange={(_, value) =>
                          setFieldValue(`wears[${index}].wear_id`, value)
                        }
                        value={values["wears"][index]["wear_id"]}
                        noOptionsText={t("_labels.autocomplete.no_options")}
                        openText={t("_labels.autocomplete.open")}
                        clearText={t("_labels.autocomplete.clear")}
                        closeText={t("_labels.autocomplete.close")}
                        className={classnames(
                          "MuiFormControl-root",
                          css.full_width
                        )}
                        renderInput={(params) => (
                          <TextField2
                            {...params}
                            required
                            label={t("_labels.wear.singular")}
                            variant="filled"
                          />
                        )}
                      />
                      <Field
                        multiline
                        rows={2}
                        variant="filled"
                        margin="normal"
                        size="small"
                        name={`wears[${index}].comment`}
                        component={TextField}
                        className={classnames(css.full_width)}
                        label={t("_labels.comment.singular")}
                      />
                    </div>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        name={`wears[${index}].image`}
                        accept="image/*"
                        placeholder={t("_labels.drop_image")}
                        component={FileDragAndDropField}
                        className={classnames(css.drop)}
                      />
                    </div>

                    <div className={classnames(css.w100)}>
                      {values.wears.length > 1 && (
                        <div className={classnames(css.remove_button)}>
                          <Button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            color="secondary"
                            size="small"
                            variant="fab"
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      )}

                      <Divider className={classnames(css.full_width, css.m0)} />
                    </div>
                  </div>
                ))}

                <div className={classnames(css.full_width, css.add_button)}>
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        wear_id: null,
                        comment: "",
                        image: null,
                      })
                    }
                    color="secondary"
                    size="small"
                    variant="fab"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </>
            )}
          />
        </>
      )}

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  damages: PropTypes.array.isRequired,
  wears: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
};

export default Page;
