import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionUpdateTireReview } from "store/actions/tire/review/updateTireReview";
import { actionGetTireReview } from "store/actions/tire/review/getTireReview";
import { actionSetNotification } from "store/actions/general/notification";
import { actionClearTireReview } from "store/actions/tire/review/clearTireReview";
import { actionGetTire } from "store/actions/tire/getTire";
import { actionClearTire } from "store/actions/tire/clearTire";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";

import Page from "./page";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";

export function UpdateTireReviewForm({ ...rest }) {
  const t = useLang();
  const {
    open,
    onClose,
    tire,
    tireId,
    tireReview,
    setNotification,
    updateTireReview,
    getTireReview,
    clearTireReview,
    getTire,
    clearTire,
    clearTireFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    resetFormik,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      pressure: "",
      comment: "",
      lines: [
        {
          depth_external: "",
          depth_middle: "",
          depth_internal: "",
        },
      ],
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(tireId);
        await getTireReview(tire.cycle.movement_tire.movement_tire_id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearTire();
      clearTireReview();
    };
  }, [open]);

  useEffect(() => {
    if (open && tireReview) {
      setInitialValues({
        pressure: Number(tireReview.pressure),
        comment: tireReview.comment || "",
        lines:
          tireReview.tire_review_depth_line.tire_review_depth.length > 0
            ? tireReview.tire_review_depth_line.tire_review_depth.map(
                (review) => ({
                  depth_external: review.depth_external,
                  depth_middle: review.depth_middle,
                  depth_internal: review.depth_internal,
                })
              )
            : [
                {
                  depth_external:
                    tireReview.tire_review_depth_line.average_depth_external,
                  depth_middle:
                    tireReview.tire_review_depth_line.average_depth_middle,
                  depth_internal:
                    tireReview.tire_review_depth_line.average_depth_internal,
                },
              ],
      });
    } else {
      resetFormik();
    }
  }, [open, tireReview]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        pressure: values.pressure,
        depth: [...values.lines],
      };

      if (values.comment) {
        fields.comment = values.comment;
      }

      const movementTireId = tire.cycle.movement_tire.movement_tire_id;

      await updateTireReview(movementTireId, tireReview.tire_review_id, fields);
      setNotification({
        message: t("_messages.updated.tire_review"),
      });

      onClose();
      clearTireFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      tire={tire}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UpdateTireReviewForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  tireReview: state.Tire.Review.tire_review,
});

const mapDispatchToProps = (dispatch) => ({
  updateTireReview: actionUpdateTireReview(dispatch),
  getTireReview: actionGetTireReview(dispatch),
  clearTireReview: actionClearTireReview(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTire: actionGetTire(dispatch),
  clearTire: actionClearTire(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTireReviewForm);
