import {
  GET_TIRE_WAREHOUSES_HISTORY,
  CLEAR_TIRE_WAREHOUSES_HISTORY,
  GET_TIRE_WAREHOUSE_HISTORY,
  CLEAR_TIRE_WAREHOUSE_HISTORY,
} from "store/actions";

const initialState = {
  warehouses: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  warehouse: {
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    tire: {
      code: "",
    },
    warehouse: {
      name: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_WAREHOUSE_HISTORY:
      return {
        ...state,
        warehouse: action.payload,
      };

    case CLEAR_TIRE_WAREHOUSE_HISTORY:
      return {
        ...state,
        warehouse: initialState.warehouse,
      };

    case GET_TIRE_WAREHOUSES_HISTORY:
      return {
        ...state,
        warehouses: action.payload,
      };

    case CLEAR_TIRE_WAREHOUSES_HISTORY:
      return {
        ...state,
        warehouses: initialState.warehouses,
      };

    default:
      return state;
  }
}
