import { SET_USER_STATUS } from "store/actions";
import { http } from "store/actions/http";

export const actionSetUserStatus = (dispatch) => async (userId, status) => {
  try {
    const response = await http({
      method: "PUT",
      path: `user/${userId}/status`,
      data: { status },
    });

    dispatch({
      type: SET_USER_STATUS,
      payload: userId,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
