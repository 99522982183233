import {
  GET_COMPANY_USERS_HISTORY,
  CLEAR_COMPANY_USERS_HISTORY,
  SET_COMPANY_USERS_HISTORY_FILTERS,
  CLEAR_COMPANY_USERS_HISTORY_FILTERS,
} from "store/actions";

const initialState = {
  users: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_USERS_HISTORY:
      return {
        ...state,
        users: action.payload,
      };
    case CLEAR_COMPANY_USERS_HISTORY:
      return {
        ...state,
        users: { ...initialState.users },
      };

    case SET_COMPANY_USERS_HISTORY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_COMPANY_USERS_HISTORY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
