import { UPDATE_CONCEPT } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateConcept = (dispatch) => async (fields, conceptId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: "POST",
      path: `concept/${conceptId}`,
      data: { ...fields, _method: "PUT" },
      dataWithFiles: true,
    });

    dispatch({
      type: UPDATE_CONCEPT,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
