export default {
  disabled_driver: "Conductor deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} conductores",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar el conductor?",
    enable: "¿Estas seguro de habilitar el conductor?",
  },
};
