export default {
  filter: "Filter",
  wear_status: "Wear Status",
  form: {
    wear_status: "Wear status",
    enabled: "Enabled",
    disabled: "Disabled",
    clean_filters: "Clean filters",
    cancel: "Cancel",
    filter_action: "Filter",
    helper_text: "You can select many companies",
    labels: {
      companies: "Companies",
    },
  },
};
