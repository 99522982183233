import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionDeleteModelVariationPolicy } from "store/actions/subsidiary/model/deleteModelVariationPolicy";
import { actionGetModelVariationPolicies } from "store/actions/subsidiary/model/getModelVariationPolicies";
import { actionClearModelVariationPolicies } from "store/actions/subsidiary/model/clearModelVariationPolicies";

import {
  CREATE_TIRE_MODEL_VARIATION_POLICY,
  UPDATE_TIRE_MODEL_VARIATION_POLICY,
  DELETE_TIRE_MODEL_VARIATION_POLICY,
} from "store/actions/account/permits";

import Page from "./page";

export function ModelVariationPolicyTab({ ...rest }) {
  const { id } = useParams();
  const t = useLang();
  const {
    permits,
    modelVariationPolicies,
    getModelVariationPolicies,
    clearModelVariationPolicies,
    deleteModelVariationPolicy,
    setNotification,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [tireModelVariationPolicyId, setTireModelVariationPolicyId] =
    useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getModelVariationPolicies({ subsidiaryId: id });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearModelVariationPolicies();
    };
  }, []);

  async function onDeleteModelVariationPolicy(subsidiaryId, policyId) {
    try {
      await deleteModelVariationPolicy(subsidiaryId, policyId);
      setNotification({
        message: t("_messages.deleted.model_pressure_policy"),
      });
      await getModelVariationPolicies({ subsidiaryId: id });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(policyId) {
    setNotification({
      onAction: () => {
        onDeleteModelVariationPolicy(id, policyId);
      },
      message: t("_messages.delete.model_pressure_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setTireModelVariationPolicyId(null);
    setOpenForm(false);
  }

  function handleEdit(id) {
    setTireModelVariationPolicyId(id);
    setOpenForm(true);
  }

  return (
    <Page
      t={t}
      subsidiaryId={id}
      tireModelVariationPolicyId={tireModelVariationPolicyId}
      modelVariationPolicies={modelVariationPolicies}
      updateTireModelVariationPolicyPermit={permits.includes(
        UPDATE_TIRE_MODEL_VARIATION_POLICY
      )}
      deleteTireModelVariationPolicyPermit={permits.includes(
        DELETE_TIRE_MODEL_VARIATION_POLICY
      )}
      createTireModelVariationPolicyPermit={permits.includes(
        CREATE_TIRE_MODEL_VARIATION_POLICY
      )}
      openForm={openForm}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
    />
  );
}

ModelVariationPolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  modelVariationPolicies: state.Subsidiary.Model.model_variation_policies,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getModelVariationPolicies: actionGetModelVariationPolicies(dispatch),
  deleteModelVariationPolicy: actionDeleteModelVariationPolicy(dispatch),
  clearModelVariationPolicies: actionClearModelVariationPolicies(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelVariationPolicyTab);
