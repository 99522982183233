import { http } from "store/actions/http";
import { REGISTER_HELMET_VALUE } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionAddHelmetValue =
  (dispatch) => async (subsidiaryId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `subsidiary/${subsidiaryId}/helmet/value/tire/model/variation`,
        data: fields,
      });

      dispatch({
        type: REGISTER_HELMET_VALUE,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
