import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "components/organisms/gps/GpsGeofenceFilterForm/Form";

export function Form(props) {
  const t = useLang(NAMESPACE);
  return <Page {...props} t={t} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  handleClean: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Form;
