import { SET_TIRE_MODEL_VARIATION_APPROVED } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetTireModelVariationApproved =
  (dispatch) => async (tireModelId, tireModelVariationId, approved) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/model/${tireModelId}/variation/${tireModelVariationId}/approved`,
        data: { approved },
      });

      dispatch({
        type: SET_TIRE_MODEL_VARIATION_APPROVED,
        payload: { tireModelId, tireModelVariationId },
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
