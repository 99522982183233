import {
  GET_GPS_GEOFENCE_LIST,
  CLEAR_GPS_GEOFENCE_LIST,
  SET_GPS_GEOFENCE_FILTER,
  CLEAR_GPS_GEOFENCE_FILTER,
  GET_GPS_GEOFENCE,
  CLEAR_GPS_GEOFENCE,
} from "store/actions";

const initialState = {
  gpsGeofence: {
    date: "",
    odometer: 0,
    area: "",
    status: 0,
    g_p_s: {
      imei: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  gpsGeofenceList: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GPS_GEOFENCE_LIST:
      return {
        ...state,
        gpsGeofenceList: action.payload,
      };

    case CLEAR_GPS_GEOFENCE_LIST:
      return {
        ...state,
        gpsGeofenceList: initialState.gpsGeofenceList,
      };

    case SET_GPS_GEOFENCE_FILTER:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_GPS_GEOFENCE_FILTER:
      return {
        ...state,
        filter: { ...initialState.filter },
      };

    case GET_GPS_GEOFENCE:
      return {
        ...state,
        gpsGeofence: action.payload,
      };

    case CLEAR_GPS_GEOFENCE:
      return {
        ...state,
        gpsGeofence: initialState.gpsGeofence,
      };

    default:
      return state;
  }
}
