export default {
  disabled_rfid: "Disabled rfid",
  permits: {
    change_status: "No permissions to {action} rfids",
  },
  messages: {
    enable: "Are you sure to enable the rfid?",
    disable: "Are you sure to disable the rfid?",
  },
};
