import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetUserReviewFilters } from "store/actions/reports/user_review/setUserReviewFilters";
import { actionClearUserReviewFilters } from "store/actions/reports/user_review/clearUserReviewFilters";
import { actionSetSelectedUserReviewFilters } from "store/actions/reports/user_review/setSelectedUserReviewFilters";

import { getCompanyViaWorkArea, getCorporateViaWorkArea } from "utils/workArea";
import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import { arrayJoin } from "utils/parse";
import { REVIEW_TYPES } from "utils/review";

import Page from "./page";

export function FilterUserReviewReportForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setUserReviewFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      companies: [],
      subsidiaries: [],
      users: [],
      divisions: [],
      date_from: "",
      date_to: "",
      activity: "default",
      review_type: "default",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        companies: filter.companies ? filter.companies.split(",") : [],
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        users: filter.users
          ? users.filter((user) =>
              filter.users.split(",").includes(user.user_id.toString())
            )
          : [],
        divisions: filter.divisions ? filter.divisions.split(",") : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        activity: filter.activity || "default",
        review_type: filter.review_type || "default",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate?.name });
      }

      if (values.companies.length > 0) {
        params.companies = values.companies.join();
        const selectedCompanies = companies.filter((company) =>
          values.companies.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: arrayJoin(
            selectedCompanies.map((company) => company?.name),
            ",",
            t("_general.text.and")
          ),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
        const selectedSubsidiaries = subsidiaries.filter((subsidiary) =>
          values.subsidiaries.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: arrayJoin(
            selectedSubsidiaries.map((subsidiary) => subsidiary?.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.subsidiaries = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.users.length > 0) {
        params.users = values.users.map((user) => user.user_id).join();
        setSelectedFilters({
          users: arrayJoin(
            values.users.map(
              (user) =>
                `${user.name} ${user.last_name_1 ?? ""} ${
                  user.last_name_2 ?? ""
                }`
            ),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.users = null;
        setSelectedFilters({
          users: "",
        });
      }

      if (values.divisions.length > 0) {
        params.divisions = values.divisions.join();
        const selectedDivisions = divisions.filter((division) =>
          values.divisions.includes(division.division_id.toString())
        );

        if (params.divisions.includes("NO_DIVISION")) {
          selectedDivisions.push({
            division_id: 0,
            name: t("_labels.no_division"),
            status: 1,
          });
        }

        setSelectedFilters({
          divisions: arrayJoin(
            selectedDivisions.map((division) => division.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.divisions = null;
        setSelectedFilters({
          divisions: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      if (values.activity != "default") {
        params.activity = values.activity;

        setSelectedFilters({
          activity:
            values.activity == "GENERAL"
              ? t("_labels.activity.options.general")
              : t("_labels.activity.options.general_and_mount"),
        });
      }

      if (values.review_type != "default") {
        params.review_type = values.review_type;

        setSelectedFilters({
          review_type: t(
            REVIEW_TYPES.find((r) => r.value == values.review_type)?.label
          ),
        });
      }

      await setUserReviewFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };

    setUserReviewFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      users={users}
      divisions={divisions}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      setUsers={setUsers}
      setDivisions={setDivisions}
      t={t}
    />
  );
}

FilterUserReviewReportForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.UserReview.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setUserReviewFilters: actionSetUserReviewFilters(dispatch),
  clearUserReviewFilters: actionClearUserReviewFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedUserReviewFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterUserReviewReportForm);
