import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { useHistory } from "react-router-dom";

import { actionGetMountAlerts } from "store/actions/alert/mount/getMountAlerts";
import { actionClearMountAlerts } from "store/actions/alert/mount/clearMountAlerts";
import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

export function MountAlerts({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const {
    vehicles,
    getMountAlerts,
    clearMountAlerts,
    clearMountAlertsFilters,
    setNotification,
    filter,
  } = rest;
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getMountAlerts({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearMountAlerts();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearMountAlertsFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getMountAlerts({
        page,
        ...filter,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleViewAlerts(vehicleId) {
    history.push(`/mount/${vehicleId}/alerts`);
  }

  return (
    <Page
      t={t}
      vehicles={vehicles}
      openFilter={openFilter}
      onChangePage={onChangePage}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleViewAlerts={handleViewAlerts}
    />
  );
}

MountAlerts.propTypes = {};

const mapStateToProps = (state) => ({
  vehicles: state.Alert.MountAlerts.vehicles,
  filter: state.Alert.MountAlerts.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getMountAlerts: actionGetMountAlerts(dispatch),
  clearMountAlerts: actionClearMountAlerts(dispatch),
  clearMountAlertsFilters: actionClearMountAlerts(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MountAlerts);
