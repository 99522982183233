import React from "react";

import classnames from "classnames";

import CircularProgress from "@material-ui/core/CircularProgress";

import css from "./index.module.scss";

function Page({ ...rest }) {
  const { t } = rest;
  return (
    <div className={classnames(css.container)}>
      <div className="d-flex flex-column justify-content-center align-items-center px-2">
        <CircularProgress color="secondary" size={100} thickness={3.6} />
        <p
          className="m-0 mt-2 text-white"
          style={{ fontFamily: "Roboto", fontSize: "1.3rem" }}
        >
          {t("_general.loading")}
        </p>
      </div>
    </div>
  );
}

export default Page;
