import { http } from "store/actions/http";
import { GET_VEHICLE_HISTORY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehicleHistory =
  (dispatch) =>
  async (
    vehicleId,
    { page, date_from: dateFrom, date_to: dateTo, review_type: reviewType }
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `vehicle/${vehicleId}/review/history`,
        params: {
          page,
          date_from: dateFrom,
          date_to: dateTo,
          review_type: reviewType,
        },
      });

      dispatch({
        type: GET_VEHICLE_HISTORY,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
