import React from "react";

import PropTypes from "prop-types";

import Page from "./page";

function Button({ ...rest }) {
  return <Page {...rest} />;
}

Button.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
};

export default Button;
