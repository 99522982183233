import {
  REGISTER_PROVIDER,
  GET_PROVIDERS,
  CLEAR_PROVIDERS,
  GET_PROVIDER,
  CLEAR_PROVIDER,
  SET_PROVIDER_FILTERS,
  CLEAR_PROVIDER_FILTERS,
  SET_PROVIDER_STATUS,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
} from "store/actions";

const initialState = {
  provider: {
    rfc: "",
    name: "",
    company: {
      tradename: "",
    },
    status: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  providers: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_provider: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_PROVIDER:
    case GET_PROVIDER:
    case UPDATE_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };

    case GET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      };

    case DELETE_PROVIDER:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: state.providers.data.filter(
            (provider) => provider.provider_id !== action.payload
          ),
        },
      };

    case CLEAR_PROVIDERS:
      return {
        ...state,
        providers: { ...initialState.providers },
      };

    case CLEAR_PROVIDER:
      return {
        ...state,
        provider: { ...initialState.provider },
      };
    case SET_PROVIDER_FILTERS:
      const filter = {
        ...state.filter_provider,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_provider: { ...filter },
      };

    case CLEAR_PROVIDER_FILTERS:
      return {
        ...state,
        filter_provider: { search: state.filter_provider.search },
      };

    case SET_PROVIDER_STATUS:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: state.providers.data.map((provider) =>
            provider.provider_id !== action.payload
              ? provider
              : { ...provider, status: !provider.status }
          ),
        },
      };
    default:
      return state;
  }
}
