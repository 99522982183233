import React, { useState } from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";

const menuStyles = makeStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
  list: {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
});

const listStyles = makeStyles({
  root: {
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    "&:hover": {
      backgroundColor: "#D1D1D167",
    },
  },
});
function ActionsMenu({ ...rest }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { options, title } = rest;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuClasses = menuStyles();
  const listClasses = listStyles();
  return (
    <div>
      <button
        className="btn btn-sm btn-info"
        style={{ minWidth: "fit-content" }}
        onClick={handleClick}
      >
        {title}
      </button>
      <Menu
        id="simple-menu"
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={menuClasses}
      >
        {options.length > 0 &&
          options.map(({ label, action }, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                action();
              }}
              classes={listClasses}
            >
              {label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

ActionsMenu.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default ActionsMenu;
