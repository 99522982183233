import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import Home from "pages/general/Home";

function Page({
  gps,
  gpsList,
  subsidiaries,
  createGpsPermit,
  updateGpsPermit,
  deleteGpsPermit,
  filter,
  t,
  getGps,
  addGps,
  updateGps,
  clearGpsFilters,
  setGpsFilters,
  deleteGps,
  setGpsStatus,
  getGpsList,
  clearGpsList,
  linkVehiclePermit,
  handleUnlinkVehicle,
}) {
  return (
    <Home
      item={gps}
      name="gps"
      filter={filter}
      createTooltipPermit={!createGpsPermit ? t("_permits.create.gps") : ""}
      currentPage={gpsList.current_page}
      deleteConfirmationMessage={t("_messages.delete.gps")}
      disableConfirmationMessage={t("_messages.change_status.gps.disable")}
      enableConfirmationMessage={t("_messages.change_status.gps.enable")}
      rows={gpsList.data.map((gps) => {
        return {
          id: gps.gps_id,
          key: gps.gps_id,
          status: {
            status: gps.status,
            tooltipPermit: !updateGpsPermit ? t("_permits.update.gps") : "",
          },
          subsidiary: gps.subsidiary.name,
          imei: gps.imei,
          economic_number:
            gps.vehicle.length > 0
              ? gps.vehicle[0].vehicle.economic_number
              : "",
          created_at:
            moment(gps.created_at).format("LL") +
            " " +
            moment(gps.created_at).format("h:mm a"),
          edit: {
            tooltipPermit: !updateGpsPermit ? t("_permits.update.gps") : "",
          },
          delete: {
            tooltipPermit: !deleteGpsPermit ? t("_permits.delete.gps") : "",
          },
          link: {
            tooltipPermit: !linkVehiclePermit ? t("_permits.update.gps") : "",
          },
          unlink: {
            tooltipPermit: !deleteGpsPermit ? t("_permits.delete_gps") : "",
            onUnlink: () =>
              gps.vehicle.length > 0
                ? handleUnlinkVehicle(gps.gps_id, gps.vehicle[0].vehicle_id)
                : {},
          },
          vehicle: gps.vehicle,
        };
      })}
      title={t("_general.gps")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "imei", label: t("_labels.imei") },
        { id: "economic_number", label: t("_labels.vehicle.singular") },
        { id: "created_at", label: t("_labels.created_at") },
        { id: "actions", label: "" },
      ]}
      lastPage={gpsList.last_page}
      noItemsText={t("_general.no_gps")}
      initialDefaultValues={{
        imei: "",
        status: "",
        device_name: "",
        subsidiary_id: "",
      }}
      fields={[
        {
          name: "imei",
          required: true,
          label: t("_labels.imei"),
        },
        {
          name: "device_name",
          required: true,
          label: t("_labels.device_name"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
      ]}
      createFormTitle={t("_titles.new.gps")}
      updateFormTitle={t("_titles.update.gps")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.gps"),
        },
        date: true,
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.gps")}
      updatedMessage={t("_messages.updated.gps")}
      getItemAction={getGps}
      setFiltersAction={setGpsFilters}
      clearFiltersAction={clearGpsFilters}
      deleteAction={deleteGps}
      setStatusAction={setGpsStatus}
      getItemsAction={getGpsList}
      clearItemsAction={clearGpsList}
      addAction={addGps}
      updateAction={updateGps}
      withLinkVehicle={true}
    />
  );
}

Page.propTypes = {
  gps: PropTypes.object.isRequired,
  gpsList: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  createGpsPermit: PropTypes.bool.isRequired,
  updateGpsPermit: PropTypes.bool.isRequired,
  deleteGpsPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getGps: PropTypes.func.isRequired,
  addGps: PropTypes.func.isRequired,
  updateGps: PropTypes.func.isRequired,
  clearGpsFilters: PropTypes.func.isRequired,
  setGpsFilters: PropTypes.func.isRequired,
  deleteGps: PropTypes.func.isRequired,
  setGpsStatus: PropTypes.func.isRequired,
  getGpsList: PropTypes.func.isRequired,
  clearGpsList: PropTypes.func.isRequired,
  linkVehiclePermit: PropTypes.bool.isRequired,
  handleUnlinkVehicle: PropTypes.func.isRequired,
};

export default Page;
