import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDynamicCostDetails } from "store/actions/reports/dynamic_cost/getDynamicCostDetails";
import { actionClearDynamicCostDetails } from "store/actions/reports/dynamic_cost/clearDynamicCostDetails";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function TireDialog({ open, params, ...rest }) {
  const t = useLang();

  const {
    tires,
    setNotification,
    getDynamicCostDetails,
    clearDynamicCostDetails,
  } = rest;
  useEffect(() => {
    async function fetchData() {
      try {
        await getDynamicCostDetails(params);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearDynamicCostDetails();
    };
  }, [open]);

  return <Page {...rest} t={t} open={open} tires={getSortedData(tires)} />;
}

TireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tires: state.Reports.DynamicCost.tires,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDynamicCostDetails: actionGetDynamicCostDetails(dispatch),
  clearDynamicCostDetails: actionClearDynamicCostDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDialog);

function getSortedData(data) {
  return data.sort(alphabeticalSort("cpk"));
}
