export default {
  title: "New brand",
  update_title: "Update brand",
  form: {
    permits: {
      update: "No permissions to update brands",
      delete: "No permissions to delete brands",
    },
  },
  messages: {
    brand_created: "Done, the brand has been successfully created",
    brand_updated: "Done, the brand has been successfully updated",
    delete: "Are you sure to delete brand?",
  },
};
