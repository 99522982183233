export default {
  title: "New model variation",
  update_title: "Update model variation",
  messages: {
    variation_model_created:
      "Done, a new variation model was successfully created",
    tire_model_variation_updated:
      "Done, the model variation was successfully updated",
    delete_model_variation: "Are you sure to delete the model variation?",
  },
  form: {
    permits: {
      update: "No permissions to update model variations",
      delete: "No permissions to delete model variations",
    },
    size_field: "Select size",
  },
};
