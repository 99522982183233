import {
  GET_DRIVER_SUBSIDIARY,
  CLEAR_DRIVER_SUBSIDIARY,
  ASSIGN_DRIVER_TO_SUBSIDIARY,
  DELETE_LINK_DRIVER_WITH_SUBSIDIARY,
} from "store/actions";

const initialState = {
  subsidiary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DRIVER_SUBSIDIARY:
    case ASSIGN_DRIVER_TO_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };

    case CLEAR_DRIVER_SUBSIDIARY:
      return {
        ...state,
        subsidiary: initialState.subsidiary,
      };

    case DELETE_LINK_DRIVER_WITH_SUBSIDIARY:
      return {
        ...state,
        subsidiary:
          state.subsidiary && state.subsidiary.driver_id == action.payload
            ? initialState.subsidiary
            : state.subsidiary,
      };

    default:
      return state;
  }
}
