import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  CREATE_VEHICLE_TYPE_AXLE,
  UPDATE_VEHICLE_TYPE_AXLE,
} from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/vehicle/type/axle/VehicleTypeAxleForm/Form";

export function Form({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { permits, setNotification, getTireApplicationTypes } = rest;
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      try {
        const data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );

        if (mounted.current) {
          setTireApplicationTypes(
            data.sort(alphabeticalSort("tire_application_id"))
          );
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Page
      {...rest}
      t={t}
      tireApplicationTypes={tireApplicationTypes}
      createVehicleTypeAxlePermit={permits.includes(CREATE_VEHICLE_TYPE_AXLE)}
      updateVehicleTypeAxlePermit={permits.includes(UPDATE_VEHICLE_TYPE_AXLE)}
    />
  );
}

Form.propTypes = {
  onClose: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
