import React from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

import css from "./index.module.scss";

function Page({ color, name, labelRef, className }) {
  return (
    <label
      style={color && { color: `#${color}` }}
      ref={labelRef}
      htmlFor={`color-${name}`}
      id={`label-color-${name}`}
      className={classnames(css.label, className)}
    ></label>
  );
}

Page.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  labelRef: PropTypes.object,
  className: PropTypes.string,
};

export default Page;
