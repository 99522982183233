import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPotentialSavingsReport } from "store/actions/reports/potential_savings/getPotentialSavingsReport";

import Page from "./page";
import PropTypes from "prop-types";

function Analysis({ data, selectKey, ...rest }) {
  const t = useLang();

  const { filter } = rest;

  const currentMonth = filter.date_to
    ? moment(filter.date_to).format("MMMM Y")
    : "";
  const lastMonth = filter.date_to
    ? moment(filter.date_to).subtract(1, "month").format("MMMM Y")
    : "";

  return (
    <Page
      t={t}
      data={getData(data)}
      currentMonth={currentMonth}
      lastMonth={lastMonth}
      selectKey={selectKey}
    />
  );
}
Analysis.propTypes = {
  data: PropTypes.object.isRequired,
  selectKey: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.PotentialSavings.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReport: actionGetPotentialSavingsReport(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);

function getData(data) {
  if (Object.keys(data).length > 0) {
    return { ...data };
  }
  return {};
}
