import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";

function Page({
  t,
  open,
  isHistory,
  showTooltip,
  warehouseTire,
  unlinkTooltip,
  viewButtonName,
  onClose,
  handleView,
  handleUnlink,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{t("stored_tire")}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              gutterBottom
              label={t("_labels.warehouse_name")}
              text={warehouseTire.warehouse.name}
            />
            <LabelAndText
              gutterBottom
              text={warehouseTire.tire.code}
              label={t("code")}
              className={classnames(css.ml32)}
            />
          </div>

          <LabelAndText
            fullWidth
            gutterBottom
            text={warehouseTire.created_by}
            label={t("_labels.created_by")}
          />

          {warehouseTire.updated_by && (
            <LabelAndText
              fullWidth
              gutterBottom
              text={warehouseTire.updated_by}
              label={t("_labels.updated_by")}
            />
          )}
        </div>
        <div className={classnames(css.action)}>
          <div className={classnames(css.action_left)}>
            {!isHistory && (
              <Button
                color="secondary"
                onClick={handleUnlink}
                tooltip={unlinkTooltip}
                disabled={!!unlinkTooltip}
              >
                {t("take_out")}
              </Button>
            )}
          </div>

          <Button color="secondary" onClick={onClose}>
            {t("_buttons.close")}
          </Button>
          <Button
            color="secondary"
            onClick={handleView}
            tooltip={showTooltip}
            disabled={!!showTooltip}
          >
            {viewButtonName}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isHistory: PropTypes.bool.isRequired,
  showTooltip: PropTypes.string.isRequired,
  unlinkTooltip: PropTypes.string.isRequired,
  viewButtonName: PropTypes.string.isRequired,
  warehouseTire: PropTypes.shape({
    updated_by: PropTypes.string,
    warehouse: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    created_by: PropTypes.string.isRequired,
    tire: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
  handleUnlink: PropTypes.func.isRequired,
};

export default Page;
