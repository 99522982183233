import axios from "axios";
import { getTimeZone } from "utils/time";

import {
  USER_TOKEN,
  WORK_AREA_ID,
  WORK_AREA_NAME,
  LANGUAGE,
} from "store/actions";

export const http = async ({
  path,
  method = "POST",
  data = {},
  params = {},
  dataWithFiles = false,
  download = false,
}) => {
  const lang = localStorage.getItem(LANGUAGE) || "es";
  for (let k in params) {
    if (params[k] === null || params[k] === undefined) {
      delete params[k];
    }
  }

  try {
    const request = {
      method,
      params,
      data,
      url: `${process.env.REACT_APP_API_URL}/api/${path}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(USER_TOKEN)}`,
        Lang: lang,
        "Time-Zone": getTimeZone(),
        "Content-Type": !dataWithFiles
          ? "application/json"
          : "multipart/form-data",
        "Work-Area": localStorage.getItem(WORK_AREA_NAME),
        "Id-Assign-Work-Area": localStorage.getItem(WORK_AREA_ID),
      },
    };

    if (download) {
      request.responseType = "blob";
    }

    if (dataWithFiles) {
      const formData = new FormData();

      for (let k of Object.keys(data)) {
        if (Array.isArray(data[k])) {
          data[k].forEach((object, index) => {
            for (const key of Object.keys(object)) {
              formData.append(`${k}[${index}][${key}]`, object[key]);
            }
          });
        } else {
          formData.append(k, data[k]);
        }
      }

      request.data = formData;
    }

    const response = await axios(request);

    if (response.headers["content-type"] != "application/json") {
      downloadFile(response);
    }
    return response.data.data;
  } catch (error) {
    if (error.response) {
      // Si el ocurre un error en una respuesta de tipo blob, se convierte a json el error
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        const errorObject = JSON.parse(await error.response.data.text());
        throw errorObject.error;
      } else {
        throw error.response.data.error;
      }
    }

    console.error(error);
    throw {
      code: "INTERNAL",
    };
  }
};

function downloadFile(response) {
  const a = document.createElement("a");
  const href = window.URL.createObjectURL(new Blob([response.data]));
  a.href = href;
  let fileName = response.headers["content-disposition"].split("=")[1];
  fileName = fileName.replaceAll(/[\s"]/g, "");
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(href);
}
