import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import TableResponsive from "components/organisms/general/TableResponsive";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Form from "components/organisms/general/Form";

import css from "./index.module.scss";

function Page({
  t,
  subsidiaryId,
  policyId,
  policies,
  policy,
  getTemperaturePolicies,
  getTemperaturePolicy,
  updateTemperaturePolicy,
  openForm,
  handleOpenForm,
  handleCloseForm,
}) {
  return (
    <SubsidiaryTabs
      title={t("_general.temperature_policy")}
      className={classnames({
        [css.empty]: policies.data.length === 0,
      })}
    >
      {policies.data.length > 0 ? (
        <div className={classnames(css.container)}>
          <TableResponsive
            rows={policies.data.map((policy) => ({
              id: policy.temperature_policy_id,
              key: policy.temperature_policy_id,
              subsidiary: policy?.subsidiary.name || "",
              low: `${policy.low}°C`,
              high: `${policy.high}°C`,
              edit: {
                tooltipPermit: "",
              },
            }))}
            headers={[
              {
                id: "subsidiary",
                label: t("_labels.subsidiary_field.singular.label"),
              },
              {
                id: "low",
                label: t("_labels.priority.options.low"),
              },
              {
                id: "high",
                label: t("_labels.priority.options.high"),
              },
              { id: "actions", label: "" },
            ]}
            onEdit={handleOpenForm}
          />
        </div>
      ) : (
        <EmptyListIcon text={t("_general.no_temperature_policy")} />
      )}

      <Form
        open={openForm}
        item={policy}
        id={policyId}
        name={"temperature_policy"}
        initialDefaultValues={{
          low: "",
          high: "",
        }}
        fields={[
          {
            name: "low",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: 1,
                min: 0,
              },
            },
            label: t("_labels.labels.priority.options.low"),
          },
          {
            name: "high",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: 1,
                min: 0,
              },
            },
            label: t("_labels.labels.priority.options.high"),
          },
        ]}
        title={""}
        updateTitle={t("_titles.update.policy")}
        createdMessage={""}
        updatedMessage={t("_messages.updated.temperature_policy")}
        onClose={handleCloseForm}
        getAction={(id) => getTemperaturePolicy(subsidiaryId, id)}
        addAction={() => {}}
        updateAction={async (...rest) => {
          const [fields, id] = rest;

          try {
            await updateTemperaturePolicy(fields, subsidiaryId, id);
          } catch (error) {
            throw error;
          }
        }}
        clearFiltersAction={() =>
          getTemperaturePolicies({ page: 1 }, subsidiaryId)
        }
      />
    </SubsidiaryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  policies: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired,
  // updateSubsidiaryPolicyPermit: PropTypes.bool.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  getTemperaturePolicies: PropTypes.func.isRequired,
  getTemperaturePolicy: PropTypes.func.isRequired,
  updateTemperaturePolicy: PropTypes.func.isRequired,
  // getSubsidiaryPolicy: PropTypes.func.isRequired,
  // updateSubsidiaryPolicy: PropTypes.func.isRequired,
  // getSubsidiary: PropTypes.func.isRequired,
};

export default Page;
