import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { useHistory } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireSubsidiary } from "store/actions/tire/getTireSubsidiary";
import { actionClearTireSubsidiary } from "store/actions/tire/clearTireSubsidiary";
import { actionAssignTireToSubsidiary } from "store/actions/subsidiary/tire/assignTireToSubsidiary";
import { actionDeleteLinkTireWithSubsidiary } from "store/actions/subsidiary/tire/deleteLinkTireWithSubsidiary";

import {
  SHOW_TIRE,
  SHOW_SUBSIDIARY,
  LINK_TIRE_WITH_SUBSIDIARY,
  DELETE_LINK_TIRE_WITH_SUBSIDIARY,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/subsidiary/LinkTireSubsidiaryForm";

export function LinkTireSubsidiaryForm({ className, tireId, ...rest }) {
  const history = useHistory();
  const t = useLang(NAMESPACE);
  const {
    permits,
    subsidiary,
    setNotification,
    getTireSubsidiary,
    clearTireSubsidiary,
    assignTireToSubsidiary,
    deleteLinkTireWithSubsidiary,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      subsidiary_id: "",
    },
  });
  const [open, setOpen] = useState(false);
  const showTirePermit = permits.includes(SHOW_TIRE);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireSubsidiary(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showTirePermit) {
      fetchData();
    }

    return () => {
      clearTireSubsidiary();
    };
  }, [tireId]);

  function handleView() {
    history.push(`/subsidiary/${subsidiary.subsidiary_id}`);
  }

  async function deleteLinkWithSubsidiary() {
    try {
      await deleteLinkTireWithSubsidiary({
        subsidiaryId: subsidiary.subsidiary_id,
        tireId,
      });
      setNotification({
        message: t("messages.unlinked_tire_subsidiary"),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleUnlink() {
    setNotification({
      message: t("_messages.delete.link_subsidiary"),
      onAction: () => {
        deleteLinkWithSubsidiary();
      },
      textAction: t("_messages.confirm"),
    });
  }

  function handleLink() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    resetFormik();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      await assignTireToSubsidiary({
        tireId,
        subsidiaryId: values.subsidiary_id,
      });

      handleClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      className={className}
      subsidiary={subsidiary}
      initialValues={initialValues}
      initialErrors={initialErrors}
      showTirePermit={showTirePermit}
      linkTireWithSubsidiaryPermit={permits.includes(LINK_TIRE_WITH_SUBSIDIARY)}
      deleteLinkTireWithSubsidiaryPermit={permits.includes(
        DELETE_LINK_TIRE_WITH_SUBSIDIARY
      )}
      showSubsidiaryPermit={permits.includes(SHOW_SUBSIDIARY)}
      handleView={handleView}
      handleLink={handleLink}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleUnlink={handleUnlink}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkTireSubsidiaryForm.propTypes = {
  className: PropTypes.string,
  tireId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  subsidiary: state.Tire.Subsidiary.subsidiary,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireSubsidiary: actionGetTireSubsidiary(dispatch),
  clearTireSubsidiary: actionClearTireSubsidiary(dispatch),
  assignTireToSubsidiary: actionAssignTireToSubsidiary(dispatch),
  deleteLinkTireWithSubsidiary: actionDeleteLinkTireWithSubsidiary(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkTireSubsidiaryForm);
