export default {
  title: "Nueva Sucursal",
  update_title: "Actualizar sucursal",
  messages: {
    delete: "¿Estas seguro de eliminar la sucursal?",
    subsidiary_created: "Listo, se creo una nueva sucursal con éxito",
    subsidiary_updated: "Listo, se actualizo la sucursal con éxito",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar sucursales",
      delete: "Sin permisos para eliminar sucursales",
    },
  },
};
