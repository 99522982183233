import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";
import { actionGetListOfReviews } from "store/actions/vehicle/review/getListOfReviews";
import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

function UpdateReview({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const { setNotification, getReviews } = rest;

  const [reviews, setReviews] = useState([]);

  async function fetchData() {
    try {
      const response = await getReviews(id);
      setReviews(response);
    } catch (error) {
      setNotification(error, true);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return <Page t={t} id={id} reviews={reviews} fetchData={fetchData} />;
}

UpdateReview.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getReviews: actionGetListOfReviews(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReview);
