import { UPDATE_TIRE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateTire = (dispatch) => async (fields, tireId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "PUT",
      path: `tire/${tireId}`,
      data: fields,
    });

    dispatch({
      type: UPDATE_TIRE,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
