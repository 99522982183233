import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import VehicleTypeTabs from "components/organisms/vehicle/type/VehicleTypeTabs";
import TableResponsive from "components/organisms/general/TableResponsive";
import VehicleTypeAxleForm from "components/organisms/vehicle/type/axle/VehicleTypeAxleForm";
import LabelColorViewer from "components/molecules/general/LabelColorViewer";

import { arrayJoin } from "utils/parse";

import css from "./index.module.scss";

function Page({
  t,
  vehicleTypeId,
  vehicleTypeAxleId,
  vehicleTypeAxles,
  openForm,
  createVehicleTypeAxlePermit,
  updateVehicleTypeAxlePermit,
  handleOpenForm,
  handleCloseForm,
  handleEdit,
  onChangePage,
}) {
  return (
    <VehicleTypeTabs
      className={classnames({
        [css.empty]: vehicleTypeAxles.data.length === 0,
      })}
    >
      {vehicleTypeAxles.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={vehicleTypeAxles.data.map((vehicleTypeAxle) => {
                return {
                  id: vehicleTypeAxle.vehicle_type_axle_id,
                  key: vehicleTypeAxle.vehicle_type_axle_id,
                  color: <LabelColorViewer color={vehicleTypeAxle.color} />,
                  axle_number: vehicleTypeAxle.axle_number,
                  position: t(
                    `_labels.vehicle_type_axle_position.options.${vehicleTypeAxle.position.toLowerCase()}`
                  ),
                  axle_type: t(
                    `_labels.axle_field.options.${vehicleTypeAxle.axle_type.toLowerCase()}`
                  ),
                  tire_quantity_count: vehicleTypeAxle.tire_quantity_count,
                  application: arrayJoin(
                    vehicleTypeAxle.tire_application.map((application) =>
                      t(
                        `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
                      )
                    ),
                    ", ",
                    ` ${t("_general.text.and")} `
                  ),
                  created_by: t("_labels.actioned_by", {
                    person: `${vehicleTypeAxle.created_by.name} ${
                      vehicleTypeAxle.created_by.last_name_1
                    } ${vehicleTypeAxle.created_by.last_name_2 || ""}`,
                    date: moment(vehicleTypeAxle.created_at).format("LL"),
                    time: moment(vehicleTypeAxle.created_at).format("h:mm a"),
                  }),
                  updated_by: vehicleTypeAxle.updated_by
                    ? t("_labels.actioned_by", {
                        person: `${vehicleTypeAxle.updated_by.name} ${
                          vehicleTypeAxle.updated_by.last_name_1
                        } ${vehicleTypeAxle.updated_by.last_name_2 || ""}`,
                        date: moment(vehicleTypeAxle.updated_at).format("LL"),
                        time: moment(vehicleTypeAxle.updated_at).format(
                          "h:mm a"
                        ),
                      })
                    : "",
                  edit: {
                    tooltipPermit: !updateVehicleTypeAxlePermit
                      ? t("_permits.update.vehicle_type_axle")
                      : "",
                  },
                };
              })}
              headers={[
                { id: "color", label: t("_labels.color") },
                { id: "axle_number", label: t("_labels.axle_number") },
                {
                  id: "position",
                  label: t("_labels.vehicle_type_axle_position.label"),
                },
                { id: "axle_type", label: t("_labels.axle_field.label") },
                {
                  id: "application",
                  label: t("_labels.tire_application.label.plural"),
                },
                {
                  id: "tire_quantity_count",
                  label: t("_labels.tire_quantity"),
                },
                { id: "created_by", label: t("_labels.created_by") },
                { id: "updated_by", label: t("_labels.updated_by") },
                { id: "actions", label: "" },
              ]}
              onDelete={() => {}}
              onEdit={handleEdit}
              onChangeStatus={() => {}}
              onChangeApproved={() => {}}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={vehicleTypeAxles.current_page}
              totalPages={vehicleTypeAxles.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_vehicle_type_axles")} />
      )}

      <VehicleTypeAxleForm
        vehicleTypeId={vehicleTypeId}
        vehicleTypeAxleId={vehicleTypeAxleId}
        open={openForm}
        onClose={handleCloseForm}
      />
      <FixedButton
        disabled={!createVehicleTypeAxlePermit}
        tooltip={
          !createVehicleTypeAxlePermit
            ? t("_permits.create.vehicle_type_axle")
            : ""
        }
        onClick={handleOpenForm}
      />
    </VehicleTypeTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  vehicleTypeAxleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleTypeAxles: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired,
  updateVehicleTypeAxlePermit: PropTypes.bool.isRequired,
  createVehicleTypeAxlePermit: PropTypes.bool.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default Page;
