export default {
  wear_disabled: "Wear disabled",
  permits: {
    change_status: "No permissions to {action} wear",
  },
  messages: {
    disable: "Are you sure to disable the wear?",
    enable: "Are you sure to enable the wear?",
  },
};
