import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { useLang } from "hooks/lang";
import { actionGetGpsList } from "store/actions/gps/getGpsList";
import { actionClearGpsList } from "store/actions/gps/clearGpsList";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetGpsFilters } from "store/actions/gps/setGpsFilters";
import { actionClearGpsFilters } from "store/actions/gps/clearGpsFilters";
import { actionDeleteGps } from "store/actions/gps/deleteGps";
import { actionSetGpsStatus } from "store/actions/gps/setGpsStatus";
import { actionGetGps } from "store/actions/gps/getGps";
import { actionAddGps } from "store/actions/gps/addGps";
import { actionUpdateGps } from "store/actions/gps/updateGps";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionDeleteLinkVehicleWithGps } from "store/actions/gps/vehicle/deleteLinkVehicleWithGps";

import {
  CREATE_GPS,
  UPDATE_GPS,
  DELETE_GPS,
  LINK_VEHICLE_WITH_GPS,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeGps({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    gps,
    gpsList,
    account,
    deleteGps,
    setGpsStatus,
    setNotification,
    getGps,
    addGps,
    updateGps,
    getGpsList,
    getSubsidiaries,
    clearGpsList,
    setGpsFilters,
    clearGpsFilters,
    deleteLinkVehicle,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  async function unlinkVehicle(gpsId, vehicleId) {
    try {
      const response = await deleteLinkVehicle(gpsId, vehicleId);
      setNotification({
        message: response.message,
      });
      clearGpsFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleUnlinkVehicle(gpsId, vehicleId) {
    setNotification({
      message: t("_messages.delete.link_vehicle"),
      textAction: t("_messages.confirm"),
      onAction: () => unlinkVehicle(gpsId, vehicleId),
    });
  }

  return (
    <Page
      t={t}
      gps={gps}
      gpsList={gpsList}
      filter={filter}
      createGpsPermit={permits.includes(CREATE_GPS)}
      updateGpsPermit={permits.includes(UPDATE_GPS)}
      deleteGpsPermit={permits.includes(DELETE_GPS)}
      linkVehiclePermit={permits.includes(LINK_VEHICLE_WITH_GPS)}
      subsidiaries={subsidiaries}
      getGps={getGps}
      addGps={addGps}
      updateGps={updateGps}
      clearGpsFilters={clearGpsFilters}
      setGpsFilters={setGpsFilters}
      deleteGps={deleteGps}
      setGpsStatus={setGpsStatus}
      getGpsList={getGpsList}
      clearGpsList={clearGpsList}
      handleUnlinkVehicle={handleUnlinkVehicle}
    />
  );
}

HomeGps.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  gpsList: state.Gps.Gps.gps_list,
  gps: state.Gps.Gps.gps,
  filter: state.Gps.Gps.filter,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  getGpsList: actionGetGpsList(dispatch),
  clearGpsList: actionClearGpsList(dispatch),
  setNotification: actionSetNotification(dispatch),
  setGpsFilters: actionSetGpsFilters(dispatch),
  clearGpsFilters: actionClearGpsFilters(dispatch),
  deleteGps: actionDeleteGps(dispatch),
  setGpsStatus: actionSetGpsStatus(dispatch),
  getGps: actionGetGps(dispatch),
  addGps: actionAddGps(dispatch),
  updateGps: actionUpdateGps(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  deleteLinkVehicle: actionDeleteLinkVehicleWithGps(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeGps);
