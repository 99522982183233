import { GET_TIRE_APPLICATION_TYPES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireApplicationTypes =
  (dispatch) =>
  async ({ page, scope, status }, withDispatch = true) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "tire/application",
        params: { page, scope, status },
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRE_APPLICATION_TYPES,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
