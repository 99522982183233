import React from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionRecoveryPassword } from "store/actions/account/recoveryPassword";
import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE = "pages/account/RecoveryPassword";

export function RecoveryPassword({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { recoveryPassword, setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      email: "",
    },
  });

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const response = await recoveryPassword({
        email: values.email,
      });
      resetForm();
      setNotification({
        message: response.message,
      });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

RecoveryPassword.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  recoveryPassword: actionRecoveryPassword(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);
