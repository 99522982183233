export default {
  gps_disabled: "Gps deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} gps",
  },
  messages: {
    enable: "¿Estas seguro de habilitar el gps?",
    disable: "¿Estas seguro de deshabilitar el gps?",
  },
};
