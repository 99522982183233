export default {
  title: "Cambiar contraseña",
  sub_title: "Registra tu nueva contraseña",
  form: {
    labels: {
      password: "Nueva contraseña",
      confirm_password: "Confirmar contraseña",
    },
    enter_button: "Cambiar contraseña",
    back_button: "Iniciar Sesión",
  },
  messages: {
    password_updated: "Listo, se actualizo con éxito su contraseña.",
  },
};
