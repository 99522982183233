import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  rfids,
  isRegisterForm,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          {isRegisterForm ? t("_titles.new.tire") : t("_titles.send.warehouse")}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                rfids={rfids}
                isRegisterForm={isRegisterForm}
                onClose={onClose}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  rfids: PropTypes.array.isRequired,
  isRegisterForm: PropTypes.bool.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
