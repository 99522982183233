import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Snackbar from "@material-ui/core/Snackbar";

import CloseIcon from "@material-ui/icons/Close";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  open,
  hideDuration,
  message,
  code,
  handleClose,
  onAction,
  textAction,
  handleAction,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      className={classnames(css.snackbar)}
      open={open}
      autoHideDuration={hideDuration || 6000}
      onClose={handleClose}
      message={
        <>
          {message}{" "}
          {code && <span className={classnames(css.code)}>#{code}</span>}
        </>
      }
      action={
        <>
          {onAction && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleAction}
              style={{ marginRight: "16px" }}
            >
              {textAction || "Deshacer"}
            </Button>
          )}
          <Button
            variant="icon"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </Button>
        </>
      }
    />
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  hideDuration: PropTypes.number,
  message: PropTypes.string,
  code: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  handleAction: PropTypes.func,
  textAction: PropTypes.string,
};

export default Page;
