import React, { Fragment, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";

import { Field, FieldArray, Form } from "formik";
import { TextField } from "formik-material-ui";
import TextField2 from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import LabelAndText from "components/molecules/general/LabelAndText";
import css from "./index.module.scss";
import { arrayJoin } from "utils/parse";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export default function StepperForm({ ...rest }) {
  const classes = useStyles();

  const {
    t,
    values,
    concepts,
    blocks,
    vehicleTypes,
    isUpdate,
    isValid,
    isSubmitting,
    onClose,
    handleSubmit,
    setFieldValue,
    ...other
  } = rest;

  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    t("_labels.general_data"),
    t("_labels.headboard"),
    t("_labels.body"),
    t("_labels.preview"),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <Typography component="h5" className={classnames(css.m8)}>
              {"Datos generales"}
            </Typography>
            <Field
              required
              variant="filled"
              margin="normal"
              name="name"
              component={TextField}
              className={classnames(css.field)}
              label={t("_labels.name.singular")}
            />
            <Field
              required
              margin="normal"
              variant="filled"
              name="type"
              label={t("_labels.type")}
              className={classnames(css.field)}
              component={SelectField}
            >
              <MenuItem value="TIRE">{t("_labels.tire.singular")}</MenuItem>
              <MenuItem value="VEHICLE">
                {t("_labels.vehicle.singular")}
              </MenuItem>
            </Field>

            {values.type === "VEHICLE" && (
              <Field
                fullWidth
                multiple
                id="vehicle_types"
                name="vehicle_types"
                autoHighlight
                blurOnSelect
                component={Autocomplete}
                options={vehicleTypes}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.vehicle_type_id === value.vehicle_type_id
                }
                onChange={(_, value) => setFieldValue("vehicle_types", value)}
                value={values.vehicle_types}
                noOptionsText={t("_labels.autocomplete.no_options")}
                openText={t("_labels.autocomplete.open")}
                clearText={t("_labels.autocomplete.clear")}
                closeText={t("_labels.autocomplete.close")}
                className={classnames("MuiFormControl-root", css.field)}
                renderInput={(params) => (
                  <TextField2
                    {...params}
                    required={values.vehicle_types.length == 0}
                    margin="normal"
                    variant="filled"
                    label={t("_labels.vehicle_type.label")}
                    helperText={t("_labels.can_select_many")}
                  />
                )}
              />
            )}
          </>
        );
      case 1:
        return (
          <>
            <Typography component="h5" className={classnames(css.m8)}>
              {"Agregar conceptos al encabezado"}
            </Typography>
            <FieldArray
              name={"headboard"}
              validateOnChange={false}
              render={(arrayHelpers) => (
                <>
                  {values["headboard"].map((_, index) => (
                    <Fragment key={`frg_${index}`}>
                      <div className={classnames(css.field, css.dynamic)}>
                        <Field
                          required
                          margin="normal"
                          variant="filled"
                          name={`headboard[${index}]`}
                          label={t("_general.concept")}
                          className={classnames(css.field)}
                          component={SelectField}
                        >
                          {concepts.map((concept, index) => {
                            return (
                              <MenuItem
                                key={`h_concept_${index}`}
                                value={concept.concept_id.toString()}
                              >
                                {concept.name}
                              </MenuItem>
                            );
                          })}
                        </Field>

                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          color="secondary"
                          size="small"
                          variant="fab"
                          disabled={values["headboard"].length <= 1}
                          className={classnames(css.remove_button)}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                      <Divider className={classnames(css.field, css.m0)} />
                    </Fragment>
                  ))}

                  <div className={classnames(css.field, css.add_button)}>
                    <Button
                      type="button"
                      onClick={() => arrayHelpers.push("")}
                      color="secondary"
                      size="small"
                      variant="fab"
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </>
              )}
            />
          </>
        );
      case 2:
        return (
          <>
            <Typography component="h5" className={classnames(css.m8)}>
              {"Agregar uno o más bloques de conceptos"}
            </Typography>
            <FieldArray
              name={"body"}
              validateOnChange={false}
              render={(arrayHelpers) => (
                <>
                  {values["body"].map((_, index) => (
                    <Fragment key={`b_frg_${index}`}>
                      <div className={classnames(css.w100)}>
                        <Typography
                          variant="overline"
                          className={classnames(css.ml8, css.mr8)}
                        >
                          {t("_general.block")} {index + 1}
                        </Typography>
                        <div className={classnames(css.dynamic)}>
                          <Field
                            required
                            margin="normal"
                            variant="filled"
                            name={`body[${index}].block_id`}
                            label={t("_general.block")}
                            className={classnames(css.field)}
                            component={SelectField}
                          >
                            {blocks.map((block, index) => {
                              return (
                                <MenuItem
                                  key={`block_${index}`}
                                  value={block.block_id.toString()}
                                >
                                  {block.name}
                                </MenuItem>
                              );
                            })}
                          </Field>

                          {index > 0 && (
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              color="secondary"
                              size="small"
                              variant="fab"
                              disabled={values["body"].length <= 1}
                              className={classnames(css.remove_button)}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                        </div>

                        <FieldArray
                          name={`body[${index}].concepts`}
                          validateOnChange={false}
                          render={(helpers) => (
                            <div>
                              {values["body"][index].concepts.map(
                                (_, innerIdx) => (
                                  <Fragment key={`b_concept_${innerIdx}`}>
                                    <div
                                      className={classnames(
                                        css.field,
                                        css.dynamic
                                      )}
                                    >
                                      <Field
                                        required
                                        margin="normal"
                                        variant="filled"
                                        name={`body[${index}].concepts[${innerIdx}]`}
                                        label={t("_general.concept")}
                                        className={classnames(css.field)}
                                        component={SelectField}
                                      >
                                        {concepts.map((concept, idx) => {
                                          return (
                                            <MenuItem
                                              key={idx}
                                              value={concept.concept_id.toString()}
                                            >
                                              {concept.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Field>

                                      {values["body"][index].concepts.length >
                                        1 && (
                                        <Button
                                          type="button"
                                          onClick={() =>
                                            helpers.remove(innerIdx)
                                          }
                                          color="primary"
                                          size="small"
                                          variant="fab"
                                          disabled={
                                            values["body"][index].concepts
                                              .length <= 1
                                          }
                                          className={classnames(
                                            css.remove_button
                                          )}
                                        >
                                          <RemoveIcon />
                                        </Button>
                                      )}
                                    </div>
                                  </Fragment>
                                )
                              )}

                              <div
                                className={classnames(
                                  css.field,
                                  css.add_button
                                )}
                              >
                                <Button
                                  type="button"
                                  onClick={() => helpers.push("")}
                                  color="primary"
                                  size="small"
                                  variant="fab"
                                >
                                  <AddIcon />
                                </Button>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <Divider className={classnames(css.field, css.m0)} />
                    </Fragment>
                  ))}

                  <div className={classnames(css.field, css.add_button)}>
                    <Button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({ block_id: "", concepts: [""] })
                      }
                      color="secondary"
                      size="small"
                      variant="contained"
                    >
                      {"Agregar bloque"}
                    </Button>
                  </div>
                </>
              )}
            />
          </>
        );
      case 3:
        return (
          <div className={classnames(css.flex, css.content_info)}>
            {/* <Typography variant="overline">Datos generales</Typography> */}
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.name.singular")}
                text={values.name}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.type")}
                text={
                  values.type === "TIRE"
                    ? t("_labels.tire.singular")
                    : t("_labels.vehicle.singular")
                }
              />
            </div>
            {values.type === "VEHICLE" && values.vehicle_types.length > 0 && (
              <div className={classnames(css.horizontal)}>
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.vehicle_type.label")}
                  text={arrayJoin(
                    [...values.vehicle_types].map((v) => v.name),
                    " , ",
                    ` ${t("_general.text.and")} `
                  )}
                />
              </div>
            )}
            <Typography variant="overline">{t("_labels.headboard")}</Typography>
            {values.headboard.map((conceptId, idx) => {
              const foundConcept = concepts.find(
                (c) => c.concept_id == conceptId
              );

              return (
                <div
                  key={conceptId ?? idx}
                  className={classnames(css.horizontal)}
                >
                  <LabelAndText
                    fullWidth
                    gutterBottom
                    label={t("_general.concept")}
                    text={foundConcept?.name}
                  />
                  <LabelAndText
                    fullWidth
                    gutterBottom
                    label={t("_labels.description")}
                    text={foundConcept?.description}
                  />
                </div>
              );
            })}

            <Typography variant="overline">{t("_labels.body")}</Typography>
            {values.body.map((block, bIdx, array) => {
              const foundBlock = blocks.find(
                (b) => b.block_id == block.block_id
              );
              return (
                <Fragment key={foundBlock?.block_id ?? bIdx}>
                  <div className={classnames(css.horizontal)}>
                    <LabelAndText
                      fullWidth
                      gutterBottom
                      label={t("_general.block")}
                      text={foundBlock?.name}
                    />
                    <LabelAndText
                      fullWidth
                      gutterBottom
                      label={t("_labels.description")}
                      text={foundBlock?.description}
                    />
                  </div>
                  {block.concepts.map((conceptId, cIdx) => {
                    const foundConcept = concepts.find(
                      (c) => c.concept_id == conceptId
                    );

                    return (
                      <div
                        key={conceptId ?? cIdx}
                        className={classnames(css.horizontal)}
                      >
                        <LabelAndText
                          fullWidth
                          gutterBottom
                          label={t("_general.concept")}
                          text={foundConcept?.name}
                        />
                        <LabelAndText
                          fullWidth
                          gutterBottom
                          label={t("_labels.description")}
                          text={foundConcept?.description}
                        />
                      </div>
                    );
                  })}

                  {array.length > 1 && (
                    <Divider className={classnames(css.w100, css.my2)} />
                  )}
                </Fragment>
              );
            })}
          </div>
        );
      default:
        return null;
    }
  }

  function isLastStep() {
    return activeStep === steps.length - 1;
  }

  function onSubmit(e) {
    e.preventDefault();
    if (isLastStep()) {
      handleSubmit(values, other);
    } else {
      handleNext();
    }
  }
  return (
    <Form onSubmit={onSubmit} className={classnames(css.form)}>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} className={classnames(css.stepper)}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {getStepContent(activeStep)}
        <DialogActions className={classnames(css.actions)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.close")}
          </Button>

          <div>
            <Button
              type="button"
              onClick={handleBack}
              color="primary"
              variant="contained"
              disabled={activeStep === 0}
              className={classes.button}
            >
              {t("_buttons.back")}
            </Button>
            <Button
              type="submit"
              color={isLastStep() ? "secondary" : "primary"}
              variant={isLastStep() ? "text" : "contained"}
              disabled={isSubmitting || !isValid}
              className={classnames(css.pl8)}
            >
              {!isLastStep()
                ? t("_buttons.next")
                : isUpdate
                ? t("_buttons.update")
                : t("_buttons.save")}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Form>
  );
}
