export default {
  cancel: "Cancelar",
  close: "Cerrar",
  new: "Nuevo",
  update: "Actualizar",
  save: "Guardar",
  delete: "Eliminar",
  filter: "Filtrar",
  confirm: "Confirmar",
  continue: "Continuar",
  register: "Registrar",
  edit: "Editar",
  clean_filters: "Limpiar filtros",
  change: "Cambiar",
  generate_excel: "Generar excel",
  view: "Ver",
  link: "Vincular",
  unlink: "Desvincular",
  unlink_gps: "Desvincular gps",
  unlink_vehicle: "Desvincular vehículo",
  review: "Revisión",
  unlink_tire: "Desvincular neumático",
  view_tire: "Ver neumático",
  view_corporate: "Ver corporativo",
  view_gps: "Ver gps",
  view_vehicle: "Ver vehículo",
  view_subsidiary: "Ver sucursal",
  view_warehouse: "Ver almacén",
  view_user: "Ver usuario",
  view_driver: "Ver conductor",
  unlink_subsidiary: "Desvincular sucursal",
  unlink_driver: "Desvincular conductor",
  unlink_user: "Desvincular usuario",
  unlink_warehouse: "Desvincular almacén",
  repair: "Reparar",
  repaired: "Reparado",
  register_by_excel: "Registro por excel",
  register_by_vehicle_type: "Registro por tipo de vehículo",
  reset_odometer: "Reiniciar odómetro",
  return_from_retread: "Regresar de revitalizado",
  send_to_retread: "Enviar a revitalizar",
  send_to_repair: "Enviar a reparar",
  send_to_pile: "Enviar a pila",
  send_to_warehouse: "Enviar a almacén",
  mount: "Montar",
  show_filters: "Mostrar filtros",
  hide_filters: "Ocultar filtros",
  generate_excel: "Generar Excel",

  actions: {
    wear: "Desgaste",
    mount: "Montar",
    dismount: "Desmontar",
    repair: "Reparar",
    revitalize: "Revitalizar",
    discard: "Desechar",
    tire_mismatch: "¿No coincide el neumático?",
  },
  complete: "Completa",
  pressures: "Presiones",
  identification: "Identificación",
  damages_and_wear: "Daños y desgastes",
  damages: "Daños",
  wear: "Desgastes",
  finalize: "Finalizar",
  pressure_adjustment: "Ajuste de presiones",
  register_pressures: "Registrar presiones",
  identify_tires: "Identificar neumáticos",
  retread: "Revitalizados",
  rotation: "Rotaciones",
  best_tire: "Mejor neumático para posición",
  road_damage: "Daño en el camino",
  move: "Trasladar",
  see_table: "Ver tabla de datos",
  update_tire_review: "Actualizar revisión",
  cancel_movement: "Cancelar movimiento",
  edit_inspections: "Editar inspecciones",
  next: "Siguiente",
  back: "Atrás",
  retread_rejection: "Rechazo de revitalizado",
  pdf: "Descargar PDF",
  loading_pdf: "...Generando el PDF",
  register_pile: "Alta de neumáticos directo a pila",
  massive_pile: "Envío masivo a pila",
  example: "Ejemplo",
  format: "Formato",
};
