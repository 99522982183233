import {
  GET_RFIDS,
  CLEAR_RFIDS,
  REGISTER_RFID,
  GET_RFID,
  CLEAR_RFID,
  SET_RFID_FILTERS,
  CLEAR_RFID_FILTERS,
  UPDATE_RFID,
  DELETE_RFID,
  SET_RFID_STATUS,
} from "store/actions";

const initialState = {
  rfid: {
    rfid_id: 0,
    device_code: "",
    subsidiary_id: 0,
    subsidiary: {
      name: "",
    },
    status: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  rfids: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_RFID:
    case UPDATE_RFID:
    case GET_RFID:
      return {
        ...state,
        rfid: action.payload,
      };

    case GET_RFIDS:
      return {
        ...state,
        rfids: action.payload,
      };

    case CLEAR_RFIDS:
      return {
        ...state,
        rfids: initialState.rfids,
      };

    case CLEAR_RFID:
      return {
        ...state,
        rfid: initialState.rfid,
      };
    case SET_RFID_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_RFID_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case SET_RFID_STATUS:
      return {
        ...state,
        rfids: {
          ...state.rfids,
          data: state.rfids.data.map((rfid) =>
            rfid.rfid_id !== action.payload
              ? rfid
              : { ...rfid, status: !rfid.status }
          ),
        },
      };

    case DELETE_RFID:
      return {
        ...state,
        rfids: {
          ...state.rfids,
          data: state.rfids.data.filter(
            (rfid) => rfid.rfid_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
