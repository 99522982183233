import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAssociation } from "store/actions/association/getAssociation";

import Page from "./page";

export function AssociationTabs({
  children,
  className,
  optionsAppBar,
  ...rest
}) {
  const t = useLang();
  const { id } = useParams();
  const { association, getAssociation, setNotification } = rest;

  let tabLinks = [];
  if (association.association_id) {
    tabLinks = [
      {
        to: `/association/${id}/policy/pressure`,
        label: t("_general.pressure_policies"),
        // disabled: !showSubsidiaryPermit,
        // tooltip: !showSubsidiaryPermit ? t("_permits.show.subsidiary") : "",
      },
      {
        to: `/association/${id}/policy/depth`,
        label: t("_general.depth_policies"),
        // disabled: !showSubsidiaryPermit,
        // tooltip: !showSubsidiaryPermit ? t("_permits.show.subsidiary") : "",
      },
    ];
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await getAssociation(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [id]);

  return (
    <Page
      tabLinks={tabLinks.filter((item) => !item.hidden)}
      className={className}
      title={association.name}
      optionsAppBar={optionsAppBar}
    >
      {children}
    </Page>
  );
}

AssociationTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  association: state.Association.Association.association,
});
const mapDispatchToProps = (dispatch) => ({
  getAssociation: actionGetAssociation(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssociationTabs);
