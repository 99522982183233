import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddVehicleTypeAxle } from "store/actions/vehicle/type/addVehicleTypeAxle";
import { actionGetVehicleTypeAxle } from "store/actions/vehicle/type/axle/getVehicleTypeAxle";
import { actionUpdateVehicleTypeAxle } from "store/actions/vehicle/type/updateVehicleTypeAxle";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";
import { getRandomHexColor } from "utils/random";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/vehicle/type/axle/VehicleTypeAxleForm";

export function VehicleTypeAxleForm({
  open,
  vehicleTypeId,
  vehicleTypeAxleId,
  onClose,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const {
    vehicleTypeAxle,
    addVehicleTypeAxle,
    setNotification,
    getVehicleTypeAxle,
    updateVehicleTypeAxle,
    getTireApplicationTypes,
    getVehicleTypeAxles,
  } = rest;
  const initialDefaultValues = {
    color: getRandomHexColor(),
    position: "",
    tire_quantity: "",
    tire_application: [],
    axle_type: "",
  };
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: initialDefaultValues,
  });
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const [isUpdate, setIsUpdate] = useState(vehicleTypeAxleId ? true : false);

  useEffect(() => {
    if (vehicleTypeAxleId && vehicleTypeAxle.vehicle_type_axle_id) {
      setInitialValues({
        position: vehicleTypeAxle.position,
        tire_quantity: vehicleTypeAxle.tire_quantity_count.toString(),
        color: `#${vehicleTypeAxle.color}`,
        tire_application: vehicleTypeAxle.tire_application.map(
          (application) => application.tire_application_id
        ),
        axle_type: vehicleTypeAxle.axle_type,
      });
    } else {
      setInitialValues(initialDefaultValues);
    }
  }, [vehicleTypeAxle, vehicleTypeAxleId]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleTypeAxle(vehicleTypeId, vehicleTypeAxleId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (vehicleTypeAxleId && open) {
      fetchData();
    }
  }, [vehicleTypeAxleId, open]);

  useEffect(() => {
    setIsUpdate(vehicleTypeAxleId ? true : false);
  }, [vehicleTypeAxleId]);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );
        setTireApplicationTypes(
          data.sort(alphabeticalSort("tire_application_id"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const vehicleTypeAxleFields = {
      color: values.color.replace("#", ""),
      position: values.position,
      tire_quantity: values.tire_quantity,
      tire_application_id: values.tire_application.join(","),
      axle_type: values.axle_type,
    };

    try {
      if (isUpdate) {
        await updateVehicleTypeAxle(
          vehicleTypeAxleFields,
          vehicleTypeId,
          vehicleTypeAxleId
        );
        setNotification({
          message: t("messages.vehicle_type_axle_updated"),
        });
      } else {
        await addVehicleTypeAxle(vehicleTypeAxleFields, vehicleTypeId);
        setNotification({
          message: t("messages.vehicle_type_axles_created"),
        });
      }
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    try {
      await getVehicleTypeAxles({ vehicleTypeId, page: 1 });
    } catch (error) {
      setNotification(error, true);
    }

    setSubmitting(false);
    resetForm();
    onClose();
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      axleNumber={vehicleTypeAxle.axle_number}
      tireApplicationTypes={tireApplicationTypes}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

VehicleTypeAxleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  vehicleTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vehicleTypeAxleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vehicleTypeAxle: state.Vehicle.Type.Axle.Axle.vehicle_type_axle,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  addVehicleTypeAxle: actionAddVehicleTypeAxle(dispatch),
  getVehicleTypeAxle: actionGetVehicleTypeAxle(dispatch),
  updateVehicleTypeAxle: actionUpdateVehicleTypeAxle(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleTypeAxleForm);
