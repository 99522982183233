import React from "react";
import { connect } from "react-redux";

import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionClearTireSizes } from "store/actions/tire/size/clearTireSizes";
import { actionSetTireSizeFilters } from "store/actions/tire/size/setTireSizeFilters";
import { actionClearTireSizeFilters } from "store/actions/tire/size/clearTireSizeFilters";
import { actionDeleteTireSize } from "store/actions/tire/size/deleteTireSize";
import { actionSetTireSizeStatus } from "store/actions/tire/size/setTireSizeStatus";
import { actionSetTireSizeApprovalStatus } from "store/actions/tire/size/setTireSizeApprovalStatus";
import { actionGetTireSize } from "store/actions/tire/size/getTireSize";
import { actionAddTireSize } from "store/actions/tire/size/addTireSize";
import { actionUpdateTireSize } from "store/actions/tire/size/updateTireSize";

import { useLang } from "hooks/lang";

import {
  CREATE_TIRE_SIZE,
  UPDATE_TIRE_SIZE,
  DELETE_TIRE_SIZE,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "pages/tire/size/HomeTireSize";

export function HomeTireSize({ ...rest }) {
  const t = useLang();
  const {
    permits,
    tireSize,
    tireSizes,
    filter,
    deleteTireSize,
    setTireSizeStatus,
    setTireSizeApprovalStatus,
    getTireSize,
    addTireSize,
    updateTireSize,
    getTireSizes,
    clearTireSizes,
    setTireSizeFilters,
    clearTireSizeFilters,
  } = rest;

  return (
    <Page
      tireSizes={tireSizes}
      tireSize={tireSize}
      filter={filter}
      createTireSizePermit={permits.includes(CREATE_TIRE_SIZE)}
      updateTireSizePermit={permits.includes(UPDATE_TIRE_SIZE)}
      deleteTireSizePermit={permits.includes(DELETE_TIRE_SIZE)}
      t={t}
      getTireSize={getTireSize}
      addTireSize={addTireSize}
      updateTireSize={updateTireSize}
      clearTireSizeFilters={clearTireSizeFilters}
      setTireSizeFilters={setTireSizeFilters}
      deleteTireSize={deleteTireSize}
      setTireSizeStatus={setTireSizeStatus}
      setTireSizeApprovalStatus={setTireSizeApprovalStatus}
      getTireSizes={getTireSizes}
      clearTireSizes={clearTireSizes}
    />
  );
}

HomeTireSize.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  tireSizes: state.Tire.Size.tire_sizes,
  tireSize: state.Tire.Size.size,
  filter: state.Tire.Size.filter_tire_size,
});
const mapDispatchToProps = (dispatch) => ({
  getTireSizes: actionGetTireSizes(dispatch),
  clearTireSizes: actionClearTireSizes(dispatch),
  setTireSizeFilters: actionSetTireSizeFilters(dispatch),
  clearTireSizeFilters: actionClearTireSizeFilters(dispatch),
  deleteTireSize: actionDeleteTireSize(dispatch),
  setTireSizeStatus: actionSetTireSizeStatus(dispatch),
  setTireSizeApprovalStatus: actionSetTireSizeApprovalStatus(dispatch),
  getTireSize: actionGetTireSize(dispatch),
  addTireSize: actionAddTireSize(dispatch),
  updateTireSize: actionUpdateTireSize(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTireSize);
