import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const MuiTheme = createMuiTheme({
  // typography: {
  //   fontFamily: `"Poppins", "Roboto", Helvetica, sans-serif`
  // },
  palette: {
    primary: {
      main: "#0288D1",
    },
    secondary: {
      main: "#536DFE",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FAFAFA",
    },
  },
});
