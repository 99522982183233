import {
  GET_USER_CORPORATES_HISTORY,
  CLEAR_USER_CORPORATES_HISTORY,
  GET_USER_CORPORATE_HISTORY,
  CLEAR_USER_CORPORATE_HISTORY,
  SET_USER_CORPORATES_HISTORY_FILTERS,
  CLEAR_USER_CORPORATES_HISTORY_FILTERS,
} from "store/actions";

const initialState = {
  corporates: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  user_corporate: {
    user: {
      name: "",
      last_name_1: "",
    },
    corporate: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_CORPORATES_HISTORY:
      return {
        ...state,
        corporates: action.payload,
      };
    case CLEAR_USER_CORPORATES_HISTORY:
      return {
        ...state,
        corporates: { ...initialState.corporates },
      };
    case GET_USER_CORPORATE_HISTORY:
      return {
        ...state,
        user_corporate: action.payload,
      };
    case CLEAR_USER_CORPORATE_HISTORY:
      return {
        ...state,
        user_corporate: { ...initialState.user_corporate },
      };

    case SET_USER_CORPORATES_HISTORY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_USER_CORPORATES_HISTORY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
