import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterListIcon from "@material-ui/icons/FilterList";
import VisibilityIcon from "@material-ui/icons/Image";

import Button from "components/molecules/general/Button";
import FilterMountAlertsForm from "components/organisms/mount/alert/FilterMountAlertsForm";

import css from "./index.module.scss";

function Page({
  t,
  vehicles,
  openFilter,
  onChangePage,
  handleOpenFilter,
  handleCloseFilter,
  handleViewAlerts,
}) {
  return (
    <ContainerTemplate
      title={t("_general.mounting")}
      className={classnames({
        [css.empty]: vehicles.data.length === 0,
      })}
      options={
        <>
          <Button
            className={classnames("bw", css.filter)}
            variant="icon"
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      {vehicles.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={vehicles.data.map((vehicle) => ({
                economic_number: vehicle.economic_number,
                brand: vehicle.vehicle_brand,
                type: vehicle.vehicle_type,

                driver: `${vehicle.driver_name}`,

                redirect: {
                  tooltipPermit: "",
                  handleClick: () => handleViewAlerts(vehicle.vehicle_id),
                  label: <VisibilityIcon />,
                },
              }))}
              headers={[
                { id: "economic_number", label: t("_labels.economic_number") },

                {
                  id: "brand",
                  label: t("_labels.vehicle_brand.label"),
                },

                { id: "type", label: t("_labels.vehicle_type.label") },

                { id: "driver", label: t("_general.driver") },

                { id: "actions", label: "" },
              ]}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={vehicles.current_page}
              totalPages={vehicles.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_vehicles")} />
      )}
      <FilterMountAlertsForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleViewAlerts: PropTypes.func.isRequired,
};

export default Page;
