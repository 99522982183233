export default {
  unassigned_vehicle: "Sin vehículo asignado",
  change_vehicle: "Cambiar vehículo",
  link_vehicle: "Vincular vehículo",
  messages: {
    vehicle_changed: "Listo, el vehículo ha sido cambiado con éxito",
    same_vehicle: "El vehículo es el mismo que el anterior",
  },
  permits: {
    view_linked_vehicle:
      "Sin permisos para ver el vehículo vinculado al conductor",
  },
};
