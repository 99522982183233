export default {
  name: {
    retirement_donation: "Baja por venta/donación",
    kinked_distorted_bead: "Ceja distorsionada",
    burned_bead: "Calor en ceja",
    petro_lubricant_damage: "Daño por lubricante",
    damage_induced_sidewall_separation: "Costado dañado por separación",
    circumferential_fatigue_rupture_zipper: "Ruptura en cierre por fatiga",
    impact_breaks: "Ruptura por impacto",
    impact_break: "Costado dañado por ruptura por impacto",
    improper_nail_hole_repair: "Reparación mal realizada",
    lug_base_cracking: "Agrietamiento en banda",
    shoulder_scrubbing_scuffing: "Agrietamiento en hombro",
    high_inflation_pressure: "Alta presión",
    weathering: "Alta temperatura",
    brake_skid_damage: "Daño por frenado severo",
    run_flat: "Baja presión",
    ceded_to_another_company: "Baja total, cedida a otra empresa",
    crystallized_bead: "Ceja cristalizada",
    torn_bead: "Ceja dañada (rasgada/cortada)",
    cuts_snags: "Corte en llanta y rotura de rin interno",
    sidewall_abrasion_scuff_damage: "Costado dañado por abrasion/rozamiento",
    vehicle_equipment_damage: "Daño por vehículo/equipo",
    continuous_damages: "Daños muy juntos/demasiados daños",
    discard_in_journey: "Desecho en viaje",
    tread_chunking: "Desprendimiento de banda",
    shoulder_chunking: "Desprendimiento de hombro",
    excessive_wear: "Desgaste excesivo",
    pulled_loose_cords: "Desgaste total en cuerdas",
    erratic_depression_wear: "Desgaste irregular",
    excessive_retread: "Renovado excesivo",
    end_of_useful_life: "Fin de vida útil",
    inner_liner_bubbles_blisters_and_separations:
      "Infiltración de aire al interior de la llanta || lunares en cuerdas",
    object_trapped_in_duals: "Objeto atrapado en duales",
    crystallized_tread: "Piso cristalizado",
    penetrations_and_road_hazards: "Penetración o daños en el camino",
    lost_in_journey_in_an_accident: "Pérdida en el camino por accidente",
    dictamen: "Rechazo (por la renovadora)",
    reinforce_chafer_separation: "Refuerzo separado",
    bad_spot_repair: "Reparación mal realizada",
    stole: "Robo",
    belt_lift_separation: "Separación de cinturones",
    failed_repair_from_underinflation:
      "Soplada, falla reparación en carr. O taller",
    retread_reject: "Rechazo (por renovador)",
  },
  description: {
    retirement_donation_appearance: "La empresa donó/vendió el neumático",
    kinked_distorted_bead_appearance:
      "Distorsión en áreas locales en el asiento de la ceja (talón)",
    //kinked_distorted_bead_appearance: ",
    burned_bead_appearance: "Calor excesivo en el área de la ceja (talón)",
    petro_lubricant_damage_appearance:
      "El hule muestra una condición de rugosidad, arrugamiento o agrietamiento esponjoso en el área de la ceja (talón)",
    damage_induced_sidewall_separation_appearance:
      " Esta es detectada usualmente por grietas o protuberancias, daño en la ceja (talón)",
    circumferential_fatigue_rupture_zipper_appearance:
      "La apariencia del lateral de la goma puede manifestar un problema en su estructura (o una rotura) por falta de presión o sobrecarga",
    impact_breaks_appearance:
      "Impacto de la carcasa de la rueda con objetos, piedras, bordillos o badenes. Esto suele ocurrir cuando se conduce a una velocidad excesiva o con mal ángulo",
    pulled_loose_cords_appearance:
      "Causado por aceleraciones rápidas, frenadas, giros rápidos y otros malos hábitos de conducción.",
    impact_break_appearance:
      "Daño en la carcasa (la cubierta del neumático) después de que el neumático entre en contacto con ciertos obstáculos",
    improper_nail_hole_repair_appearance:
      "Mal mantenimiento del desempeño de las llantas",
    lug_base_cracking_appearance:
      "Pueden tener un carácter cosmético y no son peligrosas. Sin embargo, si atraviesan la superficie exterior de la goma y penetran más profundamente, el neumático puede quedar inutilizado.",
    shoulder_scrubbing_scuffing_appearance:
      "Llanta con desgaste en los hombros, debido a inapropiado asentamiento entre aro y llanta",
    high_inflation_pressure_appearance:
      "Inflación en las llantas con una presión que supera el PSI recomendado",
    weathering_appearance:
      "La banda de rodamiento al tener más contacto con el suelo se calentará antes y como consecuencia se desgastará más rápido",
    brake_skid_damage_appearance: "Desgaste en la llanta por freno severo",
    run_flat_appearance:
      "Llantas desinfladas, no logra mantener la trayectoria deseada y tiende a perder la trazada hacia el exterior",
    ceded_to_another_company_appearance:
      "El neumático pasó a ser propiedad de otra empresa.",
    crystallized_bead_appearance:
      "La cara del neumático se torna duro y ahora funciona como un cuerpo totalmente rígido en vez de ser elástico y adaptarse al pavimento garantizando un buen agarre.",
    torn_bead_appearance: "Ceja dañada en llanta",
    cuts_snags_appearance:
      "Corte en las llantas causados por piedras puntiagudas, pedazos de vidrio, clavos, tornillos, etc",
    sidewall_abrasion_scuff_damage_appearance:
      "Marcas de abrasión en las llantas. Los daños pueden extenderse a la cubierta",
    //circumferential_fatigue_rupture_zipper_appearance:
    vehicle_equipment_damage_appearance:
      "Apariencia de daños en el equipo del vehículo",
    continuous_damages_appearance:
      "El neumático ha tenido demasiados daños durante su ciclo de vida o bien sufrió varios daños en un corto periodo de tiempo.",
    discard_in_journey_appearance: "El neumático se perdió durante un viaje.",
    tread_chunking_appearance:
      "Los desprendimientos de la banda de rodamiento hacen que un vehículo sea difícil o imposible de controlar",
    shoulder_chunking_appearance:
      "Los hombros del neumático se desgastan más rápido que la parte central de la banda de rodadura causado por una baja presión de inflado",
    excessive_wear_appearance:
      "Causados por presión de aire incorrecta, mal equilibrado de llantas y alineación incorrecta de las ruedas",
    erratic_depression_wear_appearance:
      "Desgaste de llanta causados por presión de aire incorrecta, mal equilibrado de llantas y alineación incorrecta de las ruedas",
    excessive_retread_appearance:
      "Se ha sobre pasado la política de daños permitida para una llanta o casco.",
    end_of_useful_life_appearance:
      "El neumático terminó con su ciclo de vida útil.",
    inner_liner_bubbles_blisters_and_separations_appearance:
      "Las infiltraciones de aire de inflado entre la goma y la carcasa tienen como consecuencias protuberancias en la zona del flanco.",
    object_trapped_in_duals_appearance:
      "Daños de llantas ocasionados por cualquier objeto punzo cortante/punzante que se encuentre en el camino",
    crystallized_tread_appearance:
      "El piso del neumático se torna dura y ahora funciona como un cuerpo totalmente rígido en vez de ser elástico y adaptarse al pavimento garantizando un buen agarre.",
    penetrations_and_road_hazards_appearance:
      "Penetraciones y aparición de peligros en la carretera",
    lost_in_journey_in_an_accident_appearance:
      "El neumático se perdió durante un viaje a causa de un accidente.",
    dictamen_appearance: "El neumático se retiró por órden de un superior.",
    reinforce_chafer_separation_appearance:
      "Reforzar la apariencia de separación de chafer",
    bad_spot_repair_appearance: "Mala apariencia de reparación de puntos",
    stole_appearance: "El neumático fue robado.",
    belt_lift_separation_appearance:
      "Apariencia de separación de elevación de la correa",
    failed_repair_from_underinflation_appearance:
      "Reparación fallida debido a la apariencia de inflado insuficiente",
    retread_reject_appearance:
      "El neumático se retiró por órden de un superior.",
  },
};
