import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import VisibilityIcon from "@material-ui/icons/Visibility";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({ t, open, onClose, tires }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {`: ${tires.length}`}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <th className="text-center">{t("_labels.burn_code")}</th>
                  <th className="text-center">{t("_labels.location.label")}</th>
                  <th className="text-center">
                    {t("_labels.vehicle_type_axle_position.label")}
                  </th>
                  <th className="text-right">{t("_labels.cost")}</th>
                  <th className="text-right">
                    {t("_labels.dynamic_cost_report.cost_km")}
                  </th>
                  <th className="text-right">
                    {t("_labels.dynamic_cost_report.cost_mm")}
                  </th>
                  <th className="text-right">km/mm</th>
                </tr>
              </thead>
              <tbody>
                {tires.map((tire, index) => (
                  <tr key={index}>
                    <td className="text-left">
                      <Link
                        to={`/tire/${tire["tire_id"]}/history`}
                        target="_blank"
                        rel="noopener"
                      >
                        <button className="col5 btn btn-sm btn-success d-flex justify-content-center align-items-stretch">
                          <VisibilityIcon fontSize="small" className="mr-1" />
                          {tire["code"]}
                        </button>
                      </Link>
                    </td>
                    <td className="d-flex justify-content-center text-center">
                      {tire["movement"] == "MOUNT" && tire["vehicle_id"] ? (
                        <Link
                          to={`/vehicleReview/${tire["vehicle_id"]}/history`}
                          target="_blank"
                          rel="noopener"
                        >
                          <button className="col5 btn btn-sm btn-success d-flex justify-content-center align-items-stretch">
                            <VisibilityIcon fontSize="small" className="mr-1" />
                            {tire["location"]}
                          </button>
                        </Link>
                      ) : (
                        tire["location"]
                      )}
                    </td>
                    <td className="text-center">
                      {tire["real_position"] ?? "-"}
                    </td>
                    <td className="text-right col4">
                      ${formatter.format(tire["price"])}
                    </td>
                    <td className="text-right col4">
                      ${costKmFormatter.format(tire["cpk"])}
                    </td>
                    <td className="text-right col5">
                      ${formatter.format(tire["cpd"])}
                    </td>
                    <td className="text-right col6">
                      {formatter.format(tire["km_mm"])}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
