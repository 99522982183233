import { http } from "store/actions/http";
import { DELETE_MODEL_VARIATION_POLICY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteModelVariationPolicy =
  (dispatch) => async (subsidiaryId, policyId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: `DELETE`,
        path: `subsidiary/${subsidiaryId}/pressure/policy/tire/model/variation/${policyId}`,
      });
      dispatch({
        type: DELETE_MODEL_VARIATION_POLICY,
        payload: subsidiaryId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
