import { combineReducers } from "redux";

import Alert from "./alert";
import TireAlerts from "./tire_alerts";
import VehicleAlerts from "./vehicles";
import MountAlerts from "./mount";

export default combineReducers({
  Alert,
  VehicleAlerts,
  TireAlerts,
  MountAlerts,
});
