import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetReviewPerformanceFilters } from "store/actions/reports/review_performance/setReviewPerformanceFilters";
import { actionClearReviewPerformanceFilters } from "store/actions/reports/review_performance/clearReviewPerformanceFilters";
import { actionSetSelectedReviewPerformanceFilters } from "store/actions/reports/review_performance/setSelectedReviewPerformanceFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import { getCompanyViaWorkArea, getCorporateViaWorkArea } from "utils/workArea";

import Page from "./page";

export function FilterReviewPerformanceReport({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    actionSetReviewPerformanceFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      date_from: "",
      date_to: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.companies ? filter.companies.split(",") : [],
        subsidiary_id: filter.subsidiaries
          ? filter.subsidiaries.split(",")
          : [],
        date_from: filter.date_from
          ? moment(filter.date_from).format("YYYY-MM")
          : "",
        date_to: filter.date_to ? moment(filter.date_to).format("YYYY-MM") : "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.companies = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiaries = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );

        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiaries = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.date_from && values.date_to) {
        const start = moment(values.date_from)
          .startOf("month")
          .format("YYYY-MM-DD");
        const end = moment(values.date_to).endOf("month").format("YYYY-MM-DD");
        params.date_from = start;
        params.date_to = end;
        setSelectedFilters({
          date_from: start,
          date_to: end,
        });
      }
      await actionSetReviewPerformanceFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilter = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };

    actionSetReviewPerformanceFilters(filter);
    setSelectedFilters(selectedFilter);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      t={t}
    />
  );
}

FilterReviewPerformanceReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.ReviewPerformance.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  actionSetReviewPerformanceFilters:
    actionSetReviewPerformanceFilters(dispatch),
  actionClearReviewPerformanceFilters:
    actionClearReviewPerformanceFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedReviewPerformanceFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterReviewPerformanceReport);
