import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import {
  DELETE_TIRE_MODEL,
  UPDATE_TIRE_MODEL,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/model/TireModelForm/Form";

export function Form({ ...rest }) {
  const { permits } = rest;
  const t = useLang(NAMESPACE);

  return (
    <Page
      {...rest}
      t={t}
      updateTireModelPermit={permits.includes(UPDATE_TIRE_MODEL)}
      deleteTireModelPermit={permits.includes(DELETE_TIRE_MODEL)}
    />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  tireBrands: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  tireUseTypes: PropTypes.array.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
