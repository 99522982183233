import {
  REGISTER_ASSOCIATION,
  GET_ASSOCIATIONS,
  CLEAR_ASSOCIATIONS,
  SET_ASSOCIATION_FILTERS,
  CLEAR_ASSOCIATION_FILTERS,
  GET_ASSOCIATION,
  CLEAR_ASSOCIATION,
  UPDATE_ASSOCIATION,
  DELETE_ASSOCIATION,
  SET_STATUS_ASSOCIATION,
} from "store/actions";

const initialState = {
  association: {
    association_id: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    name: "",
    status: 0,
    country: "",
    province: "",
    direction_1: "",
    external_number: "",
    postal_code: "",
  },
  associations: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_association: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_ASSOCIATION:
      return {
        ...state,
        association: action.payload,
      };

    case GET_ASSOCIATIONS:
      return {
        ...state,
        associations: action.payload,
      };

    case CLEAR_ASSOCIATIONS:
      return {
        ...state,
        associations: { ...initialState.associations },
      };

    case SET_ASSOCIATION_FILTERS:
      const filter = {
        ...state.filter_association,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_association: { ...filter },
      };

    case CLEAR_ASSOCIATION_FILTERS:
      return {
        ...state,
        filter_association: { search: state.filter_association.search },
      };
    case GET_ASSOCIATION:
      return {
        ...state,
        association: action.payload,
      };

    case CLEAR_ASSOCIATION:
      return {
        ...state,
        association: { ...initialState.association },
      };

    case UPDATE_ASSOCIATION:
      return {
        ...state,
        association: action.payload,
      };
    case SET_STATUS_ASSOCIATION:
      return {
        ...state,
        associations: {
          ...state.associations,
          data: state.associations.data.map((association) =>
            association.association_id !== action.payload
              ? association
              : { ...association, status: !association.status }
          ),
        },
      };

    case DELETE_ASSOCIATION:
      return {
        ...state,
        associations: {
          ...state.associations,
          data: state.associations.data.filter(
            (association) => association.association_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
