import {
  CLOSE_NOTIFICATION,
  NOTIFICATION,
  PROGRESS,
  LANGUAGE,
  REDIRECT,
  SET_ROUTER,
} from "store/actions";

const initialState = {
  redirect: null,
  notifications: [],
  progress: [],
  language:
    localStorage.getItem(LANGUAGE) !== null
      ? localStorage.getItem(LANGUAGE)
      : "es",
  router: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLOSE_NOTIFICATION:
      state.notifications = state.notifications.filter(
        (i) => i.id !== action.payload
      );
      return {
        ...state,
        notifications: [...state.notifications],
      };
    case NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications.filter((n) => n.code !== action.payload.code),
          action.payload,
        ],
      };
    case PROGRESS:
      let index = state.progress.indexOf(action.payload);
      if (index === -1) {
        state.progress.push(action.payload);
      } else {
        state.progress.splice(index, 1);
      }
      return {
        ...state,
        progress: [...state.progress],
      };
    case LANGUAGE:
      localStorage.setItem(LANGUAGE, action.payload);
      return {
        ...state,
        language: action.payload,
      };
    case REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case SET_ROUTER:
      return {
        ...state,
        router: {
          ...state.router,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
