const messages = {
  MD200: "La empresa fue eliminada con éxito",
};

const errors = {
  S500: "Oops, hubo un error en el servidor. Intente mas tarde.",
  BSTR422: "El campo no contiene entre {min} y {max} caracteres.",
  MIN412: "El campo debe ser mayor que {min}.",
  EC405: "El correo o la contraseña son incorrectos.",
  RQR422: "Este campo es obligatorio.",
  TI401: "La sesión es inválida",
  MST412: "El campo debe contener al menos {min} caracteres.",
  RF422: "El RFC no es válido.",
  IMG422: "El campo debe ser una imagen",
  MIM120: "Debe ser un archivo de tipo: {values}.",
  MAX412: "El campo debe ser menor que {max}.",
  CM404: "La empresa no fue encontrada.",
  SB404: "La sucursal no fue encontrada.",
  SD200: "La sucursal fue eliminada.",
  UNQ425: "El valor ingresado ya esta en uso.",
  TA401: "La sesión no existe",
  TE401: "La sesión ha caducado",
  PUS403: "No cuentas con permisos dentro del área de trabajo.",
  NWL404: "El almacén no tiene una sucursal asignada.",
  NTW404: "El neumático no tiene una almacén asignado.",
  NTY404: "El ciclo del neumático no fue encontrado.",
  NDL404: "El conductor no tiene una sucursal asignada.",
  NVL404: "El vehículo no tiene una sucursal asignada.",
  NLD404: "El vehículo no tiene una división asignada.",
  NGL404: "El vehículo no tiene un gps asignado.",
  VD200: "El vehículo fue eliminado satisfactoriamente.",
  TVE200: "La variación del modelo fue habilitada satisfactoriamente.",
  TVD200: "La variación del modelo fue deshabilitada satisfactoriamente.",
};

export default {
  ...messages,
  ...errors,
  INTERNAL: "Oops, hubo un error. Intente mas tarde.",
};
