import React from "react";
import PropTypes from "prop-types";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";

import css from "./index.module.scss";
import "chartjs-adapter-moment";
import { capitalize } from "utils/string";
import PerformanceChart from "./PerformanceChart";
import VehicleReviewChart from "./VehicleReviewChart";
import moment from "moment-timezone";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Page({
  t,
  vehicleHistoricData,
  vehicleHistoricDataCurrentDate,
  performanceReviewData,
  subsidiaries,
}) {
  return (
    <>
      {subsidiaries.map((subsidiary) => {
        return (
          <React.Fragment key={subsidiary}>
            <div className="card-header text-center bg-dark text-white">
              <div
                className="h5 m-0 d-flex justify-content-center align-items-center"
                style={{ gap: "6px" }}
              >
                {subsidiary}
              </div>
            </div>
            <div className={classnames(css.data_container)}>
              {vehicleHistoricData[subsidiary] &&
                Object.keys(vehicleHistoricData[subsidiary]).length > 0 && (
                  <div
                    className="mt-3 card"
                    style={{ width: "100%", height: "500px" }}
                  >
                    <div className="card-header sMarcoInfoFondoAzul">
                      {t("_titles.graphs.vehicle_historic")}
                    </div>
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "32px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span>{t("_general.tire_reviews")}</span>
                          <span>
                            {vehicleHistoricDataCurrentDate === ""
                              ? "-"
                              : capitalize(
                                  moment(vehicleHistoricDataCurrentDate).format(
                                    "MMMM YYYY"
                                  )
                                )}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "32px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={classnames(css.labels_container)}>
                            <div>{t("_labels.depth")}</div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_good
                              )}
                            >
                              <CheckIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.goodDepth ?? "-"}
                              </span>
                            </div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_neutral
                              )}
                            >
                              <FiberManualRecordIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.scheduledDepth ?? "-"}
                              </span>
                            </div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_bad
                              )}
                            >
                              <CloseIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.criticalDepth ?? "-"}
                              </span>
                            </div>
                          </div>
                          <div className={classnames(css.labels_container)}>
                            <div>{t("_labels.pressure")}</div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_good
                              )}
                            >
                              <CheckIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.goodPressure ?? "-"}
                              </span>
                            </div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_bad
                              )}
                            >
                              <ArrowUpwardIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.highPressure ?? "-"}
                              </span>
                            </div>
                            <div
                              className={classnames(
                                css.data_label,
                                css.label_bad
                              )}
                            >
                              <ArrowDownwardIcon fontSize={"large"} />
                              <span>
                                {vehicleHistoricDataCurrentDate === ""
                                  ? "-"
                                  : vehicleHistoricData[
                                      subsidiary
                                    ].datasets[0].data.find(
                                      (e) =>
                                        `${e.fecha}` ===
                                        vehicleHistoricDataCurrentDate
                                    )?.lowPressure ?? "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "calc(100% - 300px)",
                          position: "relative",
                          height: "100%",
                        }}
                      >
                        <VehicleReviewChart
                          style={{
                            width: "100%",
                          }}
                          options={{
                            ...(vehicleHistoricData[subsidiary].datasets[0].data
                              .length >= 8
                              ? getDefaultQuarterOptions(
                                  t("_general.vehicle_review")
                                )
                              : getDefaultOptions(
                                  t("_general.vehicle_review")
                                )),
                            ...vehicleHistoricData.options,
                          }}
                          data={vehicleHistoricData[subsidiary]}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={classnames(css.data_container)}>
              {performanceReviewData[subsidiary] &&
                Object.keys(performanceReviewData[subsidiary]).length > 0 && (
                  <div
                    className="card shadow"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "0.62rem",
                    }}
                  >
                    <div
                      className="card-header sMarcoInfoFondoAzul"
                      style={{
                        borderTopLeftRadius: "0.62rem",
                        borderTopRightRadius: "0.62rem",
                      }}
                    >
                      {t("_titles.graphs.review_performance")}
                    </div>
                    <div className="card-body">
                      <PerformanceChart
                        options={
                          performanceReviewData[subsidiary].datasets[0].data
                            .length >= 8
                            ? getDefaultQuarterOptions("")
                            : getDefaultOptions("")
                        }
                        data={performanceReviewData[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleHistoricData: PropTypes.object.isRequired,
  vehicleHistoricDataCurrentDate: PropTypes.string.isRequired,
  performanceReviewData: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
};

export default Page;

function getDefaultOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          displayFormats: {
            month: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}

function getDefaultQuarterOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "quarter",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}
