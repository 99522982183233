import { DELETE_WAREHOUSE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteWarehouse = (dispatch) => async (warehouseId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `warehouse/${warehouseId}`,
    });
    dispatch({
      type: DELETE_WAREHOUSE,
      payload: warehouseId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
