import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import ContainerTemplate from "components/templates/general/Container";
import TireIdentifyForm from "components/organisms/vehicle/tire/review/TireIdentifyForm";

import css from "./index.module.scss";

function Page({ t, axleTires, economicNumber }) {
  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: axleTires.length === 0,
      })}
      title={`${t("_titles.vehicle_identify_inspection")} ${economicNumber}`}
    >
      {axleTires.length > 0 && <TireIdentifyForm axleTires={axleTires} />}
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  axleTires: PropTypes.array.isRequired,
  economicNumber: PropTypes.string.isRequired,
};

export default Page;
