import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import css from "./index.module.scss";
function Page({
  children,
  fullWidth,
  className,
  helperText,
  value,
  handleChange,
  name,
  label,
  tooltip,
  disabled,
}) {
  const Component = (
    <FormControl
      component="fieldset"
      className={classnames(
        css.FormControl,
        { [css.fullWidth]: fullWidth },
        className
      )}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={handleChange}
              name={name}
              disabled={disabled}
            />
          }
          label={label}
        />
        {children}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{Component}</Tooltip>;
  }

  return Component;
}

Page.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  tooltip: PropTypes.string,
  component: PropTypes.string,
  helperText: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.bool,
  className: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Page;
