export default {
  link_with_company: "Vínculo de usuario con empresa",
  link_with_user: "Vínculo de empresa con usuario",
  buttons: {
    unlink_company: "Desvincular empresa",
    view_company: "Ver empresa",
    unlink_user: "Desvincular usuario",
    view_user: "Ver usuario",
  },
  permits: {
    delete_assign: {
      role_to_user_company:
        "Sin permisos para eliminar asignaciones de roles a usuarios en empresas",
    },
  },
};
