import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import moment from "moment-timezone";

import FilterListIcon from "@material-ui/icons/FilterList";
import Typography from "@material-ui/core/Typography";

import Button from "components/molecules/general/Button";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Pagination from "components/molecules/general/Pagination";
import TireTabs from "components/organisms/tire/TireTabs";
import TableResponsive from "components/organisms/general/TableResponsive";
import FilterTireHistoryForm from "components/organisms/tire/FilterTireHistoryForm";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  tire,
  tires,
  openFilter,
  onChangePage,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <TireTabs
      className={classnames({
        [css.empty]: tires.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      <div className={classnames(css.title)}>
        <div className={classnames(css.horizontal)}>
          <Typography noWrap variant="h6">
            {`${t("_labels.code")}: ${tire.code}`}
          </Typography>
          {tire.device_code && (
            <Typography noWrap variant="h6">
              {`${t("_general.rfid")}: ${tire.device_code}`}
            </Typography>
          )}
        </div>
        <div className={classnames(css.horizontal)}>
          <Typography
            noWrap
            variant="overline"
            className={classnames(css.subtitle)}
          >
            {t("_labels.original_info")}
          </Typography>
        </div>
        <div className={classnames(css.horizontal)}>
          <Typography noWrap variant="h6" className={classnames(css.subtitle)}>
            {tire.cycle.variation.tire_model.brand.name}
          </Typography>
          <Typography noWrap variant="h6" className={classnames(css.subtitle)}>
            {tire.cycle.variation.tire_model.name}
          </Typography>
        </div>
      </div>
      {tires.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={tires.data.map((tire) => ({
                id: tire.movement_tire_id,
                key: tire.movement_tire_id,
                revitalized_brand:
                  tire.tire_cycle.revitalized?.brand.name || "",
                revitalized_model: tire.tire_cycle.revitalized
                  ? tire.tire_cycle.revitalized?.name
                  : "",
                location:
                  tire.warehouse_tire.length > 0
                    ? tire.warehouse_tire[0].warehouse.name
                    : tire.vehicle_tire.length > 0
                    ? tire.vehicle_tire[0].vehicle.economic_number
                    : t(
                        `_labels.location.options.${tire.movement.toLowerCase()}`
                      ),
                condition: t(
                  `_labels.tire_condition.options.${tire.tire_cycle.condition.tire_condition_id.toLowerCase()}`
                ),
                size: `${tire.tire_cycle.variation.tire_size.size} ${tire.tire_cycle.variation.number_layers}`,
                application: tire.tire_cycle.revitalized
                  ? t(
                      `_labels.tire_application.options.${tire.tire_cycle.revitalized.tire_application_id.toLowerCase()}`
                    )
                  : t(
                      `_labels.tire_application.options.${tire.tire_cycle.variation.tire_application_id.toLowerCase()}`
                    ),
                position:
                  tire.vehicle_tire.length > 0
                    ? tire.vehicle_tire[0].vehicle_type_axle_tire.position
                    : "",
                cycle: tire.tire_cycle.number_cycle,
                price:
                  tire.tire_cycle.number_cycle > 0
                    ? `$${formatter.format(tire.tire_cycle.price_revitalized)}`
                    : `$${formatter.format(tire.tire_cycle.price)}`,
                provider: tire.tire_cycle.provider.name,
                date: moment(tire.created_at).format("LL hh:mm a"),
                registered_by: `${tire.created_by.name} ${
                  tire.created_by.last_name_1
                } ${tire.created_by.last_name_2 || ""}`,
              }))}
              headers={[
                {
                  id: "revitalized_brand",
                  label: t("_labels.revitalized_brand_field.label"),
                },
                {
                  id: "revitalized_model",
                  label: t("_labels.revitalized_model_field.label"),
                },
                {
                  id: "size",
                  label: t("_labels.tire_model_variation_field.label"),
                },
                {
                  id: "application",
                  label: t("_labels.tire_application.label.singular"),
                },
                {
                  id: "condition",
                  label: t("_labels.condition"),
                },
                {
                  id: "location",
                  label: t("_labels.location.label"),
                },
                {
                  id: "position",
                  label: t("_labels.vehicle_type_axle_position.label"),
                },
                {
                  id: "cycle",
                  label: t("_labels.number_cycle"),
                },
                {
                  id: "price",
                  label: t("_labels.price"),
                },
                {
                  id: "provider",
                  label: t("_labels.provider"),
                },
                {
                  id: "date",
                  label: t("_labels.created_at"),
                },
                {
                  id: "registered_by",
                  label: t("_labels.registered_by"),
                },
              ]}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={tires.current_page}
              totalPages={tires.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterTireHistoryForm open={openFilter} onClose={handleCloseFilter} />
    </TireTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.object.isRequired,
  tire: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default Page;
