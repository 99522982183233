import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDamages } from "store/actions/damage/getDamages";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionAddDamageAndRepair } from "store/actions/tire/damage/addDamageAndRepair";

import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function DamagesAndRepairForm({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    open,
    onClose,
    vehicleTire,
    setNotification,
    getDamages,
    getProviders,
    getDrivers,
    getVehicleAxles,
    addDamageAndRepair,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      damages: [
        {
          damage_id: null,
          comment: "",
          image: null,
        },
      ],
      provider_id: "",
      invoice_date: "",
      invoice_folio: "",
      price: "",
      driver_id: "",
      surcharge: "",
      surcharge_item: "",
    },
  });

  const [damages, setDamages] = useState([]);
  const [providers, setProviders] = useState([]);
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        let dataProviders = [];
        let dataDrivers = [];
        data = await getDamages(
          {
            scope: "damage_id,name,status,frequency",
          },
          false
        );

        dataProviders = await getProviders(
          {
            scope: "provider_id,name,status",
          },
          false
        );

        dataDrivers = await getDrivers(
          {
            scope: "driver_id,name,status",
          },
          false
        );
        setDamages(data.sort((a, b) => b["frequency"] - a["frequency"]));
        setProviders(dataProviders.sort(alphabeticalSort("name")));
        setDrivers(dataDrivers.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        damages: [
          {
            damage_id: null,
            comment: "",
            image: null,
          },
        ],
        provider_id: "",
        invoice_date: "",
        invoice_folio: "",
        price: "",
        driver_id: "",
        surcharge: "",
        surcharge_item: "",
      });
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        damages: values.damages,
        provider_id: values.provider_id,
        price: values.price,
      };

      if (values.invoice_date) {
        fields.invoice_date = values.invoice_date;
      }

      if (values.invoice_folio) {
        fields.invoice_folio = values.invoice_folio;
      }

      if (values.surcharge) {
        fields.surcharge = values.surcharge;
        fields.driver_id = values.driver_id;
        fields.surcharge_item = values.surcharge_item;
      }

      const movementTireId = vehicleTire.cycle.movement_tire.movement_tire_id;

      fields.damages = values.damages.map((damage) => {
        return !!damage.image
          ? { ...damage, damage_id: damage.damage_id.damage_id }
          : { damage_id: damage.damage_id.damage_id, comment: damage.comment };
      });
      await addDamageAndRepair(movementTireId, fields, true);

      setNotification({
        message: t("_messages.created.tire_damage"),
      });
      setSubmitting(false);
      onClose();

      if (vehicleId) {
        getVehicleAxles(vehicleId);
      }
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicleTire={vehicleTire}
      damages={damages}
      providers={providers}
      drivers={drivers}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

DamagesAndRepairForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

DamagesAndRepairForm.defaultProps = {};

const mapStateToProps = (state) => ({
  vehicleTire: state.Vehicle.Tire.vehicle_tire,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDamages: actionGetDamages(dispatch),
  getProviders: actionGetProviders(dispatch),
  getDrivers: actionGetDrivers(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  addDamageAndRepair: actionAddDamageAndRepair(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DamagesAndRepairForm);
