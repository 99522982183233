import React from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import {
  CREATE_RETIREMENT_CAUSE,
  UPDATE_RETIREMENT_CAUSE,
  DELETE_RETIREMENT_CAUSE,
} from "store/actions/account/permits";
import { actionGetRetirementCauses } from "store/actions/retirement/cause/getRetirementCauses";
import { actionClearRetirementCauses } from "store/actions/retirement/cause/clearRetirementCauses";
import { actionSetRetirementCauseFilters } from "store/actions/retirement/cause/setRetirementCauseFilters";
import { actionClearRetirementCauseFilters } from "store/actions/retirement/cause/clearRetirementCauseFilters";
import { actionAddRetirementCause } from "store/actions/retirement/cause/addRetirementCause";
import { actionUpdateRetirementCause } from "store/actions/retirement/cause/updateRetirementCause";
import { actionDeleteRetirementCause } from "store/actions/retirement/cause/deleteRetirementCause";
import { actionGetRetirementCause } from "store/actions/retirement/cause/getRetirementCause";
import { actionSetRetirementCauseStatus } from "store/actions/retirement/cause/setRetirementCauseStatus";
import Page from "./page";

export const NAMESPACE = "pages/retirement/cause/HomeRetirementCause";

export function HomeRetirementCause({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    retirementCause,
    retirementCauses,
    addRetirementCause,
    updateRetirementCause,
    deleteRetirementCause,
    getRetirementCause,
    setRetirementCauseStatus,
    getRetirementCauses,
    clearRetirementCauses,
    setRetirementCauseFilters,
    clearRetirementCauseFilters,
  } = rest;

  return (
    <Page
      t={t}
      retirementCause={retirementCause}
      retirementCauses={retirementCauses}
      createRetirementCausePermit={permits.includes(CREATE_RETIREMENT_CAUSE)}
      updateRetirementCausePermit={permits.includes(UPDATE_RETIREMENT_CAUSE)}
      deleteRetirementCausePermit={permits.includes(DELETE_RETIREMENT_CAUSE)}
      filter={filter}
      getRetirementCause={getRetirementCause}
      getRetirementCauses={getRetirementCauses}
      addRetirementCause={addRetirementCause}
      updateRetirementCause={updateRetirementCause}
      deleteRetirementCause={deleteRetirementCause}
      setRetirementCauseFilters={setRetirementCauseFilters}
      clearRetirementCauseFilters={clearRetirementCauseFilters}
      clearRetirementCauses={clearRetirementCauses}
      setRetirementCauseStatus={setRetirementCauseStatus}
    />
  );
}

HomeRetirementCause.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  retirementCauses: state.RetirementCause.retirement_causes,
  filter: state.RetirementCause.filter,
  retirementCause: state.RetirementCause.retirement_cause,
});
const mapDispatchToProps = (dispatch) => ({
  getRetirementCause: actionGetRetirementCause(dispatch),
  getRetirementCauses: actionGetRetirementCauses(dispatch),
  clearRetirementCauses: actionClearRetirementCauses(dispatch),
  setRetirementCauseFilters: actionSetRetirementCauseFilters(dispatch),
  clearRetirementCauseFilters: actionClearRetirementCauseFilters(dispatch),
  setRetirementCauseStatus: actionSetRetirementCauseStatus(dispatch),
  addRetirementCause: actionAddRetirementCause(dispatch),
  updateRetirementCause: actionUpdateRetirementCause(dispatch),
  deleteRetirementCause: actionDeleteRetirementCause(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeRetirementCause);
