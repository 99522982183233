import {
  REGISTER_CORPORATE,
  CLEAR_CORPORATES,
  GET_CORPORATES,
  GET_CORPORATE,
  CLEAR_CORPORATE,
  SET_CORPORATE_FILTERS,
  CLEAR_CORPORATE_FILTERS,
  SET_STATUS_CORPORATE,
  UPDATE_CORPORATE,
  DELETE_CORPORATE,
} from "store/actions";

const initialState = {
  corporate: {
    corporate_id: 0,
    name: "",
    social_reason: "",
    rfc: "",
    status: 0,
    tire_fee: "",
    fee_currency_type: "",
    logo: null,
    created_at: "2020-01-01T12:16:10",
    updated_at: "2020-01-01T12:16:10",
    updated_by: {
      user_id: 0,
      name: "",
      last_name_1: "",
      last_name_2: "",
      email: "",
    },
    created_by: {
      user_id: 0,
      name: "",
      last_name_1: "",
      last_name_2: "",
      email: "",
    },
    companies: [],
  },
  corporates: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_corporate: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_CORPORATE:
    case GET_CORPORATE:
    case UPDATE_CORPORATE:
      return {
        ...state,
        corporate: action.payload,
      };

    case GET_CORPORATES:
      return {
        ...state,
        corporates: action.payload,
      };

    case CLEAR_CORPORATES:
      return {
        ...state,
        corporates: { ...initialState.corporates },
      };

    case CLEAR_CORPORATE:
      return {
        ...state,
        corporate: {
          corporate_id: 0,
          name: "",
          social_reason: "",
          rfc: "",
          status: 0,
          tire_fee: "",
          fee_currency_type: "",
          logo: null,
          created_at: "2020-01-01T12:16:10",
          updated_at: "2020-01-01T12:16:10",
          updated_by: {
            user_id: 0,
            name: "",
            last_name_1: "",
            last_name_2: "",
            email: "",
          },
          created_by: {
            user_id: 0,
            name: "",
            last_name_1: "",
            last_name_2: "",
            email: "",
          },
        },
      };

    case SET_CORPORATE_FILTERS:
      const filter = {
        ...state.filter_corporate,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_corporate: { ...filter },
      };

    case CLEAR_CORPORATE_FILTERS:
      return {
        ...state,
        filter_corporate: {},
      };

    case SET_STATUS_CORPORATE:
      return {
        ...state,
        corporates: {
          ...state.corporates,
          data: state.corporates.data.map((corporate) =>
            corporate.corporate_id !== action.payload
              ? corporate
              : { ...corporate, status: !corporate.status }
          ),
        },
      };

    case DELETE_CORPORATE:
      return {
        ...state,
        corporates: {
          ...state.corporates,
          data: state.corporates.data.filter(
            (corporate) => corporate.corporate_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
