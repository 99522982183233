import {
  CREATE_VEHICLE_TYPE_AXLE,
  GET_VEHICLE_TYPE_AXLES,
  CLEAR_VEHICLE_TYPE_AXLES,
  GET_VEHICLE_TYPE_AXLE,
  CLEAR_VEHICLE_TYPE_AXLE,
} from "store/actions";

const initialState = {
  vehicle_type_axles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  vehicle_type_axle: {
    axle_number: 0,
    position: "",
    tire_quantity_count: 0,
    color: "FFFFFF",
    tire_application: [
      {
        tire_application_id: "",
      },
    ],
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_VEHICLE_TYPE_AXLE:
      return {
        ...state,
        vehicle_type_axles: {
          ...state.vehicle_type_axles,
          data: [...state.vehicle_type_axles.data, action.payload],
        },
      };

    case CLEAR_VEHICLE_TYPE_AXLES:
      return {
        ...state,
        vehicle_type_axles: { ...initialState.vehicle_type_axles },
      };

    case GET_VEHICLE_TYPE_AXLES:
      return {
        ...state,
        vehicle_type_axles: action.payload,
      };

    case GET_VEHICLE_TYPE_AXLE:
      return {
        ...state,
        vehicle_type_axle: action.payload,
      };

    case CLEAR_VEHICLE_TYPE_AXLE:
      return {
        ...state,
        vehicle_type_axle: initialState.vehicle_type_axle,
      };

    default:
      return state;
  }
}
