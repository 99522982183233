import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";
import CheckboxField from "components/molecules/general/CheckboxField";
import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";

import css from "./index.module.scss";

function Page({
  t,
  typeValues,
  isUpdate,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="name"
        component={TextField}
        className={classnames(css.medium_field)}
        label={t("_labels.name.singular")}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="type_value"
        className={classnames(css.medium_field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.type")}
      >
        {typeValues.map((type, index) => {
          return (
            <MenuItem key={index} value={type.value}>
              {t(type.label)}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="use"
        className={classnames(css.medium_field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.use")}
      >
        <MenuItem value={"GENERAL"}>{t("_labels.use_value.general")}</MenuItem>
        <MenuItem value={"PER_TIRE"}>
          {t("_labels.use_value.per_tire")}
        </MenuItem>
      </Field>

      <Field
        variant="filled"
        margin="normal"
        name="ranking_value"
        className={classnames(css.medium_field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.ranking_value")}
      >
        <MenuItem value={"DRIVER"}>{t("_labels.driver.singular")}</MenuItem>
        <MenuItem value={"VEHICLE"}>{t("_labels.vehicle.singular")}</MenuItem>
        <MenuItem value={"TIRE"}>{t("_labels.tire.singular")}</MenuItem>
      </Field>

      <Field
        required
        multiline
        rows={3}
        variant="filled"
        margin="normal"
        name="description"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.description")}
      />

      <Field
        variant="filled"
        margin="normal"
        name="critical"
        component={CheckboxField}
        className={classnames(css.check)}
        label={t("_labels.is_critical")}
      />

      <Field
        variant="filled"
        margin="normal"
        name="sensor_check"
        component={CheckboxField}
        className={classnames(css.check)}
        label={t("_labels.sensor_check")}
      />

      <Field
        name="image"
        accept="image/*"
        placeholder={t("_labels.drop_image")}
        component={FileDragAndDropField}
        className={classnames(css.drop)}
      />

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  typeValues: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
