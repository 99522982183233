import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRfids } from "store/actions/rfid/getRfids";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/rfid/LinkTireRfidForm/Form";

export function Form({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { setNotification, getRfids } = rest;
  const [rfids, setRfids] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getRfids(
          { withLinkTire: 0, scope: "rfid_id,device_code,status" },
          false
        );
        setRfids(data.sort(alphabeticalSort("device_code")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
  }, []);

  return <Page {...rest} t={t} rfids={rfids} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRfids: actionGetRfids(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
