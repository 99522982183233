import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetChecklist } from "store/actions/checklist/getChecklist";
import { actionClearChecklist } from "store/actions/checklist/clearChecklist";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";

import { useLang } from "hooks/lang";
import css from "./index.module.scss";

export function ChecklistDialog({ checklistId, open, onClose, ...rest }) {
  const t = useLang();
  const { checklist, getChecklist, clearChecklist, setNotification } = rest;
  useEffect(() => {
    async function fetchData() {
      try {
        await getChecklist(checklistId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearChecklist();
    };
  }, [open]);

  const checklistRendered = JSON.parse(checklist.checklist_rendered ?? "{}");

  return (
    <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle className={classnames(css.pb8)}>
        {t("_titles.show.checklist")}
      </DialogTitle>
      <DialogContent className={classnames(css.box, css.pt0)}>
        <div className={classnames(css.flex, css.content_info)}>
          {/* <Typography variant="overline">Datos generales</Typography> */}
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.name.singular")}
              text={checklist.name}
            />

            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.type")}
              text={
                checklist.type === "TIRE"
                  ? t("_labels.tire.singular")
                  : t("_labels.vehicle.singular")
              }
            />
          </div>
          <Typography variant="overline">{t("_labels.headboard")}</Typography>
          {checklistRendered?.headboard?.map((concept, index) => {
            return (
              <div
                key={`${concept.name}_${index}`}
                className={classnames(css.horizontal)}
              >
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_general.concept")}
                  text={concept.concept_name}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.description")}
                  text={concept.details}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.type")}
                  text={t(`_labels.type_value.${concept.type_value}`)}
                />
              </div>
            );
          })}

          <Typography variant="overline">{t("_labels.body")}</Typography>
          {checklistRendered?.body?.map((block, index, array) => {
            return (
              <Fragment key={`${block.block_name}_${index}`}>
                <div className={classnames(css.horizontal)}>
                  <LabelAndText
                    fullWidth
                    gutterBottom
                    label={t("_general.block")}
                    text={block.block_name}
                  />
                </div>
                {block.concepts.map((concept, idx) => {
                  return (
                    <div
                      key={`concept_${concept.name}_${idx}`}
                      className={classnames(css.horizontal)}
                    >
                      <LabelAndText
                        fullWidth
                        gutterBottom
                        label={t("_general.concept")}
                        text={concept.concept_name}
                      />
                      <LabelAndText
                        fullWidth
                        gutterBottom
                        label={t("_labels.description")}
                        text={concept.details}
                      />
                      <LabelAndText
                        fullWidth
                        gutterBottom
                        label={t("_labels.type")}
                        text={t(`_labels.type_value.${concept.type_value}`)}
                      />
                    </div>
                  );
                })}

                {array.length > 1 && index !== array.length - 1 && (
                  <Divider className={classnames(css.w100, css.my2)} />
                )}
              </Fragment>
            );
          })}
        </div>
      </DialogContent>

      <DialogActions className={classnames(css.actions)}>
        <Button type="button" onClick={onClose} color="secondary">
          {t("_buttons.close")}
        </Button>
      </DialogActions>
    </DialogMediaQuery>
  );
}

ChecklistDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  checklistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  checklist: state.Checklist.checklist,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getChecklist: actionGetChecklist(dispatch),
  clearChecklist: actionClearChecklist(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDialog);
