import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { alphabeticalSort } from "utils/sort";
import { useLang } from "hooks/lang";

import { actionSetConditionPoliciesFilters } from "store/actions/subsidiary/policy/setConditionPoliciesFilters";
import { actionClearConditionPoliciesFilters } from "store/actions/subsidiary/policy/clearConditionPoliciesFilters";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";
import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";

import Page from "./page";

export function ConditionPolicyFilterForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    getVehiclesTypes,
    setConditionPoliciesFilters,
    clearConditionPoliciesFilters,
    setNotification,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      vehicle_type: [],
      condition: "",
      axle_type: "",
    },
  });

  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        vehicle_type: filter.vehicle_type ? filter.vehicle_type.split(",") : [],

        axle_type: filter.axle_type !== undefined ? filter.axle_type : "",

        condition: filter.condition !== undefined ? filter.condition : "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearConditionPoliciesFilters();
    onClose();
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehiclesTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );

        setVehicleTypes(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.vehicle_type.length > 0) {
        params.vehicle_type = values.vehicle_type.join(",");
      }

      if (values.axle_type !== "") {
        params.axle_type = values.axle_type;
      }

      if (values.condition !== "") {
        params.condition = values.condition;
      }

      await setConditionPoliciesFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      open={open}
      vehicleTypes={vehicleTypes}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleClean={handleClean}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      t={t}
    />
  );
}

ConditionPolicyFilterForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filter: state.Subsidiary.ConditionPolicy.Policy.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
  setConditionPoliciesFilters: actionSetConditionPoliciesFilters(dispatch),
  clearConditionPoliciesFilters: actionClearConditionPoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionPolicyFilterForm);
