import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";

import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import SearchInput from "components/molecules/general/SearchInput";
import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import FilterForm from "components/organisms/general/FilterForm";
import BuildIcon from "@material-ui/icons/Build";
import HistoryIcon from "@material-ui/icons/History";
import DescriptionIcon from "@material-ui/icons/Description";
import TirePressureIcon from "components/atoms/general/TirePressureIcon";
import TireIdentifyIcon from "components/atoms/general/TireIdentifyIcon";
import EqualizerIcon from "@material-ui/icons/Equalizer";

import css from "./index.module.scss";

function Page({
  t,
  vehicles,
  vehiclesInProcess,
  search,
  handleSearch,
  openFilter,
  onChangePage,
  filter,
  setFiltersAction,
  clearFiltersAction,
  handleDialogFilter,
  handleCloseFilter,
  onRedirect,
  onRedirectTirePerformance,
  doInspection,
  handleFilterInProcess,
  createVehicleReviewPermit,
}) {
  return (
    <ContainerTemplate
      title={t("_general.vehicle_review")}
      className={classnames({
        [css.empty]: vehicles.data.length === 0,
      })}
      options={
        <>
          <Button
            variant={"icon"}
            classNameButton={classnames(css.filter)}
            onClick={handleDialogFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      {vehicles.data.length > 0 ? (
        <>
          {vehiclesInProcess !== null && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              className={classnames(css.filter_button)}
              onClick={handleFilterInProcess}
            >
              {t("_labels.vehicle.in_process")}&nbsp;
              <span className="badge badge-light text-center">
                {vehiclesInProcess}
              </span>
            </Button>
          )}
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={vehicles.data.map((vehicle) => {
                return {
                  id: vehicle.vehicle_id,
                  key: vehicle.vehicle_id,
                  subsidiary: vehicle.subsidiary.name || "",
                  economic_number: vehicle.economic_number,
                  vehicle_brand: vehicle.vehicle_brand.name,
                  vehicle_type: vehicle.vehicle_type.name,
                  driver: vehicle.driver ? `${vehicle.driver.name}` : "",
                  redirect: [
                    {
                      label: <BuildIcon />,
                      handleClick: () => doInspection(vehicle.vehicle_id),
                      size: "medium",
                      variant: "icon",
                      tooltip: t("_labels.inspection.singular"),
                    },
                    {
                      label: <HistoryIcon />,
                      handleClick: () => {
                        onRedirect(vehicle.vehicle_id);
                      },
                      size: "medium",
                      variant: "icon",
                      tooltip: t("_labels.review.plural"),
                    },
                    {
                      label: <EqualizerIcon />,
                      handleClick: () => {
                        onRedirectTirePerformance(vehicle.vehicle_id);
                      },
                      size: "medium",
                      variant: "icon",
                      tooltip: t("_titles.tire_performance"),
                    },
                    {
                      label: <DescriptionIcon />,
                      href: `/vehicleReview/${vehicle.vehicle_id}/movement`,
                      target: "_blank",
                      rel: "noopener",
                      size: "medium",
                      variant: "icon",
                      tooltip: t("_labels.movement_sheet"),
                    },
                    {
                      label: <TirePressureIcon />,
                      href: `/vehicle/${vehicle.vehicle_id}/tire/pressure`,
                      target: "_blank",
                      rel: "noopener",
                      size: "medium",
                      variant: "icon",
                      disabled:
                        (vehicle?.vehicle_review.length > 0 &&
                          ["COMPLETE", "DAMAGE AND WEAR"].includes(
                            vehicle.vehicle_review[0].review_type
                          ) &&
                          !vehicle.vehicle_review[0].end_time) ||
                        !createVehicleReviewPermit,
                      tooltip:
                        vehicle?.vehicle_review.length > 0 &&
                        ["COMPLETE", "DAMAGE AND WEAR"].includes(
                          vehicle.vehicle_review[0].review_type
                        ) &&
                        !vehicle.vehicle_review[0].end_time
                          ? t("_labels.inspection_in_process")
                          : !createVehicleReviewPermit
                          ? t("_permits.create.vehicle_review")
                          : t("_labels.review_type.options.pressure"),
                    },
                    {
                      label: <TireIdentifyIcon />,
                      href: `/vehicle/${vehicle.vehicle_id}/tire/identify`,
                      target: "_blank",
                      rel: "noopener",
                      size: "medium",
                      variant: "icon",
                      disabled:
                        (vehicle?.vehicle_review.length > 0 &&
                          ["COMPLETE", "DAMAGE AND WEAR"].includes(
                            vehicle.vehicle_review[0].review_type
                          ) &&
                          !vehicle.vehicle_review[0].end_time) ||
                        !createVehicleReviewPermit,
                      tooltip:
                        vehicle?.vehicle_review.length > 0 &&
                        ["COMPLETE", "DAMAGE AND WEAR"].includes(
                          vehicle.vehicle_review[0].review_type
                        ) &&
                        !vehicle.vehicle_review[0].end_time
                          ? t("_labels.inspection_in_process")
                          : !createVehicleReviewPermit
                          ? t("_permits.create.vehicle_review")
                          : t("_labels.review_type.options.identify"),
                    },
                  ],
                };
              })}
              headers={[
                { id: "subsidiary", label: t("_labels.subsidiary.singular") },
                { id: "economic_number", label: t("_labels.economic_number") },
                { id: "vehicle_type", label: t("_labels.vehicle_type.label") },
                {
                  id: "vehicle_brand",
                  label: t("_labels.vehicle_brand.label"),
                },
                { id: "driver", label: t("_general.driver") },
                { id: "actions", label: "" },
              ]}
              onEdit={() => {}}
              onDelete={() => {}}
              onChangeStatus={() => {}}
              onChangeApproved={() => {}}
            />
          </div>

          <div className={classnames(css.pagination)}>
            <Pagination
              page={vehicles.current_page}
              totalPages={vehicles.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_vehicles")} />
      )}
      <FilterForm
        filter={filter}
        setFiltersAction={setFiltersAction}
        clearFiltersAction={clearFiltersAction}
        onClose={handleCloseFilter}
        open={openFilter}
        fields={{
          vehicle_type_id: true,
          vehicle_brand_id: true,
          driver_id: true,
          subsidiaries: true,
          date_review: true,
        }}
        initialDefaultValues={{
          vehicle_type_id: "",
          vehicle_brand_id: "",
          driver_id: "",
          subsidiaries: [],
          date_review_from: "",
          date_review_to: "",
        }}
        allFieldsFullWidth={true}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  vehicles: PropTypes.object.isRequired,
  vehiclesInProcess: PropTypes.number,
  filter: PropTypes.object.isRequired,
  setFiltersAction: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
  search: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleDialogFilter: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  onRedirect: PropTypes.func.isRequired,
  onRedirectTirePerformance: PropTypes.func.isRequired,
  doInspection: PropTypes.func.isRequired,
  handleFilterInProcess: PropTypes.func.isRequired,
  createVehicleReviewPermit: PropTypes.bool.isRequired,
};

export default Page;
