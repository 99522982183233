export default {
  text: {
    date_info: "{user} el {date} a las {hour}",
  },
  disabled_size: "Medida Deshabilitada",
  messages: {
    approve: "¿Estas seguro de aprobar la medida?",
    disapprove: "¿Estas seguro de desaprobar la medida?",
    enable: "¿Estas seguro de habilitar la medida de neumático?",
    disable: "¿Estas seguro de deshabilitar la medida de neumático?",
  },
  permits: {
    change_approved: "Sin permisos para {action} medidas de neumáticos",
    change_status: "Sin permisos para {action} medidas de neumáticos",
  },
};
