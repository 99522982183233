import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import VehicleTabs from "components/organisms/vehicle/VehicleTabs";
import VehicleTypePreviewPanel from "components/organisms/vehicle/type/VehicleTypePreviewPanel";
import VehicleTypeAxleListPanel from "components/organisms/vehicle/type/axle/VehicleTypeAxleListPanel";

import css from "./index.module.scss";

function Page({ axles, structureType }) {
  return (
    <VehicleTabs className={classnames(css.container)}>
      <VehicleTypePreviewPanel axles={axles} structureType={structureType} />
      <div className={classnames(css.links)}>
        <VehicleTypeAxleListPanel
          axles={axles}
          className={classnames(css.links__link)}
        />
      </div>
    </VehicleTabs>
  );
}

Page.propTypes = {
  axles: PropTypes.array.isRequired,
  structureType: PropTypes.string.isRequired,
};

export default Page;
