import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import FilterPileForm from "components/organisms/report/FilterPileForm";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import TireDialog from "./TireDialog";
import HistoryDialog from "./HistoryDialog";
import PileTable from "./PileTable";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";
import CauseDialog from "./CauseDialog";
import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  historic,
  generalData,
  originalData,
  revitalizedData,
  firstCycleData,
  secondCycleData,
  thirdCycleData,
  fourthCycleData,
  filter,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  chartData,
  corporates,
  params,
  policyNumberCycle,
  currentTab,
  setCurrentTab,
  openTireDialog,
  handleViewTires,
  handleCloseTireDialog,
  handleGenerateTirePileExcel,
  openHistory,
  handleOpenHistory,
  handleCloseHistory,
  openCauseDialog,
  handleViewCauses,
  handleCloseCauseDialog,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  return (
    <ContainerTemplate
      title={t("_titles.pile_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row container-fluid pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.size")}:
                      </td>
                      <td>{selectedFilter.sizes}</td>
                    </tr>
                  )}

                  {filter.tire_application && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.application")}:
                      </td>
                      <td>{selectedFilter.application}</td>
                    </tr>
                  )}
                  {filter.tire_condition && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.condition")}:
                      </td>
                      <td>{selectedFilter.condition}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                  {filter.number_cycle && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.report_pile.cycle")}:
                      </td>
                      <td>{selectedFilter.number_cycle}</td>
                    </tr>
                  )}
                  {filter.price && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.price")}:
                      </td>
                      <td>{formatter.format(selectedFilter.price)}</td>
                    </tr>
                  )}
                  {filter.helmet_value && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.helmet_value")}:
                      </td>
                      <td>{formatter.format(selectedFilter.helmet_value)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {!filter.movement && (
          <div className="row pdt12">
            <div className="col-md-12 text-center">
              <hr />
              <h5 className="sinmp">{t("_labels.mm_pile_desc")}</h5>
              <small className="sinmp" style={{ color: "lightcoral" }}>
                {t("_labels.location.label")}:{" "}
                {t("_labels.location.options.pile")}
              </small>
              <hr />
            </div>
          </div>
        )}

        {historic.datasets[0].data.length > 0 && (
          <div className="row pt-3">
            <div className="col-md-12">
              <div className="container_fluid px-3">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenHistory}
                >
                  {t("_titles.historic")}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  className="ml-2"
                  onClick={handleGenerateTirePileExcel}
                >
                  {t("_buttons.generate_excel")}
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="row" style={{ marginTop: "16px" }}>
          <div className="col-md-12">
            <div className="container-fluid">
              <div className={classnames(css.tab)}>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "General",
                  })}
                  onClick={() => {
                    setCurrentTab("General");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.general")}
                </div>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "Revitalized",
                  })}
                  onClick={() => {
                    setCurrentTab("Revitalized");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.revitalized")}
                </div>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "Original",
                  })}
                  onClick={() => {
                    setCurrentTab("Original");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.original")}
                </div>
                {[...Array(policyNumberCycle).keys()].map((key) => {
                  return (
                    <div
                      key={key}
                      className={classnames({
                        [css.option_tab]: true,
                        [css.active]: currentTab === `${key + 1}`,
                      })}
                      onClick={() => {
                        setCurrentTab(`${key + 1}`);
                      }}
                    >
                      {t("_labels.dynamic_cost_report.tabs.cycle")}
                      {` ${key + 1}`}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    {corporates.map((corporate) => (
                      <Fragment key={corporate}>
                        {generalData.hasOwnProperty(corporate) && (
                          <PileTable
                            data={generalData}
                            corporate={corporate}
                            type="general"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {originalData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={t("_labels.report_pile.originals")}
                            data={originalData}
                            corporate={corporate}
                            type="original"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {revitalizedData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={t("_labels.report_pile.revitalized")}
                            data={revitalizedData}
                            corporate={corporate}
                            type="revitalized"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {firstCycleData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={`${t("_labels.report_pile.cycle")} 1`}
                            data={firstCycleData}
                            corporate={corporate}
                            type="first"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {secondCycleData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={`${t("_labels.report_pile.cycle")} 2`}
                            data={secondCycleData}
                            corporate={corporate}
                            type="second"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {thirdCycleData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={`${t("_labels.report_pile.cycle")} 3`}
                            data={thirdCycleData}
                            corporate={corporate}
                            type="third"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {fourthCycleData.hasOwnProperty(corporate) && (
                          <PileTable
                            title={`${t("_labels.report_pile.cycle")} 4`}
                            data={fourthCycleData}
                            corporate={corporate}
                            type="fourth"
                            handleViewTires={handleViewTires}
                            handleViewCauses={handleViewCauses}
                          />
                        )}
                        {chartData.hasOwnProperty(corporate) && (
                          <div className="card mb-2">
                            {Object.keys(chartData).length > 0 && (
                              <div className="card-header">{corporate}</div>
                            )}
                            <div className="charts">
                              {Object.keys(chartData[corporate]).map(
                                (subsidiary) => (
                                  <Fragment key={subsidiary}>
                                    <div className="chart2">
                                      <DoughnutChart
                                        data={
                                          chartData[corporate][subsidiary].tires
                                        }
                                        title={t(
                                          "_labels.report_pile.number_of_tires"
                                        )}
                                        tooltipOptions={{
                                          tooltip: {
                                            callbacks: {
                                              label: (context) => {
                                                return `${
                                                  context.label
                                                }: ${percentFormatter.format(
                                                  context.parsed
                                                )}%`;
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="chart2">
                                      <DoughnutChart
                                        data={
                                          chartData[corporate][subsidiary]
                                            .remainder_depth
                                        }
                                        title={t(
                                          "_labels.report_pile.utils_mm"
                                        )}
                                        tooltipOptions={{
                                          tooltip: {
                                            callbacks: {
                                              label: (context) => {
                                                return `${
                                                  context.label
                                                }: ${percentFormatter.format(
                                                  context.parsed
                                                )}%`;
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="chart2">
                                      <DoughnutChart
                                        data={
                                          chartData[corporate][subsidiary]
                                            .utils_mm
                                        }
                                        title={t(
                                          "_labels.report_pile.cost_useful"
                                        )}
                                        tooltipOptions={{
                                          tooltip: {
                                            callbacks: {
                                              label: (context) => {
                                                return `${
                                                  context.label
                                                }: ${percentFormatter.format(
                                                  context.parsed
                                                )}%`;
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </Fragment>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterPileForm open={openFilter} onClose={handleCloseFilter} />
      <TireDialog
        open={openTireDialog}
        onClose={handleCloseTireDialog}
        params={params}
      />

      <CauseDialog
        open={openCauseDialog}
        onClose={handleCloseCauseDialog}
        params={params}
      />
      <HistoryDialog
        open={openHistory}
        onClose={handleCloseHistory}
        data={historic}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  generalData: PropTypes.object.isRequired,
  originalData: PropTypes.object.isRequired,
  revitalizedData: PropTypes.object.isRequired,
  firstCycleData: PropTypes.object.isRequired,
  secondCycleData: PropTypes.object.isRequired,
  thirdCycleData: PropTypes.object.isRequired,
  fourthCycleData: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  chartData: PropTypes.object.isRequired,
  corporates: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  policyNumberCycle: PropTypes.number.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  handleViewTires: PropTypes.func.isRequired,
  handleCloseTireDialog: PropTypes.func.isRequired,
  handleGenerateTirePileExcel: PropTypes.func.isRequired,
  historic: PropTypes.object,
  openHistory: PropTypes.bool.isRequired,
  handleOpenHistory: PropTypes.func.isRequired,
  handleCloseHistory: PropTypes.func.isRequired,
  openCauseDialog: PropTypes.bool.isRequired,
  handleViewCauses: PropTypes.func.isRequired,
  handleCloseCauseDialog: PropTypes.func.isRequired,
};

export default Page;
