import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionClearCompletedCyclesFilters } from "store/actions/reports/completed_cycles/clearCompletedCyclesFilters";
import { actionSetCompletedCyclesFilters } from "store/actions/reports/completed_cycles/setCompletedCyclesFilters";
import { actionSetSelectedCompletedCyclesFilters } from "store/actions/reports/completed_cycles/setSelectedCompletedCyclesFilters";
import { actionGetCompletedCyclesReport } from "store/actions/reports/completed_cycles/getCompletedCyclesReport";
import { actionGetCompletedCyclesDetail } from "store/actions/reports/completed_cycles/getCompletedCyclesDetail";
import { actionGenerateCompletedCyclesExcel } from "store/actions/reports/completed_cycles/generateCompletedCyclesExcel";

import { useLang } from "hooks/lang";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

function BestPerformance({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [details, setDetails] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  const {
    filter,
    selectedFilter,
    setNotification,
    getCompletedCyclesReport,
    getCompletedCyclesDetail,
    clearCompletedCyclesFilters,
    generateCompletedCyclesExcel,
    setCompletedCyclesFilters,
    setSelectedFilters,
    account,
    workArea,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCompletedCyclesReport({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    setCompletedCyclesFilters({
      date_from: startDate,
      date_to: endDate,
      except_pile: 0,
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    });
    setSelectedFilters({
      date_from: startDate,
      date_to: endDate,
      except_pile: 0,
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    });
    return () => {
      clearCompletedCyclesFilters();
    };
  }, []);

  async function handleViewTires(data) {
    const [
      corporateName,
      companyName,
      subsidiaryName,
      divisionName,
      cycle,
      application,
      size,
      model,
    ] = data.split("@");

    const params = {
      ...filter,
      corporate_name: corporateName,
      company_name: companyName,
      subsidiary_name: subsidiaryName,
      division_name: divisionName,
      number_cycle: cycle,
      tire_application_id: application,
      size,
      model,
    };

    try {
      const res = await getCompletedCyclesDetail(params);
      setDetails(res);
      setOpenDialog(true);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    setDetails([]);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateCompletedCyclesExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      data={data}
      details={details}
      openDialog={openDialog}
      handleCloseDialog={handleCloseDialog}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
      handleViewTires={handleViewTires}
    />
  );
}
BestPerformance.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.CompletedCycles.filter,
  selectedFilter: state.Reports.CompletedCycles.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getCompletedCyclesReport: actionGetCompletedCyclesReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearCompletedCyclesFilters: actionClearCompletedCyclesFilters(dispatch),
  generateCompletedCyclesExcel: actionGenerateCompletedCyclesExcel(dispatch),
  setCompletedCyclesFilters: actionSetCompletedCyclesFilters(dispatch),
  setSelectedFilters: actionSetSelectedCompletedCyclesFilters(dispatch),
  getCompletedCyclesDetail: actionGetCompletedCyclesDetail(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestPerformance);
