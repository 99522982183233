import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionClearVehicleAxles } from "store/actions/vehicle/mount/clearVehicleAxles";
import { actionSetNotification } from "store/actions/general/notification";

import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import Page from "./page";

export function TirePressureReview({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const { vehicle, setNotification, getVehicleAxles, clearVehicleAxles } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleAxles(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearVehicleAxles();
    };
  }, []);

  return (
    <Page
      t={t}
      economicNumber={vehicle.economic_number}
      vehicleTypeAxle={vehicle.vehicle_type_axle}
      axleTires={mapVehicleAxleTires(vehicle)}
      sendPressureSettingPolicy={
        vehicle.subsidiary.policy.send_pressure_setting
      }
    />
  );
}

TirePressureReview.propTypes = {};

const mapStateToProps = (state) => ({
  vehicle: state.Vehicle.Mount.vehicle_axles,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  clearVehicleAxles: actionClearVehicleAxles(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TirePressureReview);

function mapVehicleAxleTires(vehicle) {
  let tires = [];
  if (vehicle.vehicle_type_axle?.length > 0) {
    tires = vehicle.vehicle_type_axle.flatMap((axle) => {
      return axle.vehicle_type_axle_tire
        .filter((axleTire) => axleTire.vehicle_tire.length > 0)
        .map((axleTire) => ({
          ...axleTire,
          vehicle_tire: axleTire.vehicle_tire[0],
        }));
    });
  }
  return tires;
}
