import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";
import css from "./index.module.scss";
function Page({
  t,
  alerts,
  allFieldsFullWidth,
  corporates,
  subsidiaries,
  companies,
  divisions,
  tireSizes,
  tireApplicationTypes,
  tireModels,
  vehicleTypes,
  vehicleBrands,
  drivers,
  engineBrands,
  transmissionBrands,
  brands,
  initialValues,
  initialErrors,
  handleSubmit,
  handleClean,
  handleValidate,
  onClose,
  open,
  fields,
  revitalizedModels,
  getDivisions,
  setDivisions,
  setNotification,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size="xs" open={open} onClose={onClose}>
      <DialogTitle className={classnames(css.pl32, css.pr32)}>
        {t("_general.filter")}
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialErrors={initialErrors}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {(p) => (
            <Form
              allFieldsFullWidth={allFieldsFullWidth}
              fields={fields}
              alerts={alerts}
              corporates={corporates}
              subsidiaries={subsidiaries}
              companies={companies}
              tireSizes={tireSizes}
              tireApplicationTypes={tireApplicationTypes}
              tireModels={tireModels}
              vehicleTypes={vehicleTypes}
              vehicleBrands={vehicleBrands}
              brands={brands}
              drivers={drivers}
              engineBrands={engineBrands}
              transmissionBrands={transmissionBrands}
              handleClean={handleClean}
              onClose={onClose}
              revitalizedModels={revitalizedModels}
              divisions={divisions}
              getDivisions={getDivisions}
              setDivisions={setDivisions}
              setNotification={setNotification}
              {...p}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  corporates: PropTypes.array.isRequired,
  alerts: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  engineBrands: PropTypes.array.isRequired,
  transmissionBrands: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  allFieldsFullWidth: PropTypes.bool,
  revitalizedModels: PropTypes.array.isRequired,
  getDivisions: PropTypes.func,
  setDivisions: PropTypes.func,
  setNotification: PropTypes.func,
};

export default Page;
