import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Formik } from "formik";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
  onClose,
  open,
  ...rest
}) {
  return (
    <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{t("_general.filter")}</DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {(p) => <Form onClose={onClose} {...rest} {...p} />}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  setTireModels: PropTypes.func.isRequired,
  setTireModelVariations: PropTypes.func.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  setRevitalizedModels: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Page;
