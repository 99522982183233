import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetPotentialSavingsReport =
  (dispatch) =>
  async (
    path,
    {
      corporate_id: corporateId,
      company_id: companies,
      subsidiary_id: subsidiaries,
      date_from: dateFrom,
      date_to: dateTo,
      economic_number: economicNumber,
    }
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/potencial/savings/${path}`,
        params: {
          corporate_id: corporateId,
          companies,
          subsidiaries,
          date_from: dateFrom,
          date_to: dateTo,
          economic_number: economicNumber,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
