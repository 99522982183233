import React from "react";
import { StyleSheet, View } from "@react-pdf/renderer";
import RowPdf from "./RowPdf";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const DesgloseCondicionMedida = ({ condition, tireList = [], titleColor }) => {
  const styles = StyleSheet.create({
    grayRow: {
      backgroundColor: "rgb(0, 40, 73)",
      height: "2px",
      width: "100%",
      borderRadius: "2px",
    },
    underline: {
      backgroundColor: titleColor,
      height: "2px",
      width: "100%",
      borderRadius: "2px",
    },
    title: {
      fontWeight: "bold",
    },
    smallUnderline: {
      backgroundColor: titleColor,
      height: "1px",
      width: "100%",
      borderRadius: "2px",
    },
    header: {
      width: "100%",
      paddingTop: "2px",
      marginTop: "5px",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
    },

    totales: {
      padding: "10px 0px 10px 0px",
    },
    grayCol: {
      width: "16%",
      height: "100%",
      padding: "2px 10px 2px 10px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "rgb(0, 40, 73)",
    },
    grayColTitle: {
      width: "20%",
      height: "100%",
      padding: "2px 10px 2px 10px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "rgb(0, 40, 73)",
    },
  });
  return (
    <View>
      <RowPdf titleText={"  "} />
      <View style={styles.underline} />
      <View style={styles.title}>
        <RowPdf titleText={"Condición:"} col1={condition} />
      </View>
      <View style={styles.underline} />
      {tireList.map((e) => {
        return (
          <View key={`${e.size}-${e.application}`} wrap={false}>
            <RowPdf titleText={"Medida:"} col1={e.size} />
            <RowPdf titleText={"Aplicación:"} col1={e.application} />
            <RowPdf titleText={""} />
            <View style={styles.smallUnderline} />
            <RowPdf
              titleText={"Cantidad"}
              col1={"Marca"}
              col2={"Modelo"}
              col3={"Capas"}
              col4={"Precio"}
              col5={"Total"}
            />
            <View style={styles.smallUnderline} />
            <RowPdf
              titleText={e.amount}
              col1={e.brand}
              col2={e.model}
              col3={e.layers ?? ""}
              col4={`$${formatter.format(e.price)}`}
              col5={`$${formatter.format(e.price * e.amount)}`}
            />

            <View style={styles.grayRow} />
          </View>
        );
      })}
    </View>
  );
};

DesgloseCondicionMedida.propTypes = {
  condition: PropTypes.string.isRequired,
  tireList: PropTypes.array.isRequired,
  titleColor: PropTypes.string.isRequired,
};

export default DesgloseCondicionMedida;
