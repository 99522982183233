import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteTireRevitalization =
  (dispatch) =>
  async ({ movementTireId, tireRevitalizationId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "DELETE",
        path: `tire/${movementTireId}/revitalization/${tireRevitalizationId}`,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
