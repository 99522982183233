import {
  GET_SUBSIDIARY_VEHICLES_HISTORY,
  CLEAR_SUBSIDIARY_VEHICLES_HISTORY,
} from "store/actions";

const initialState = {
  subsidiary_vehicle: {
    vehicle_id: 0,
    vehicle: {
      economic_number: "",
    },
    subsidiary: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  subsidiary_vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARY_VEHICLES_HISTORY:
      return {
        ...state,
        subsidiary_vehicles: action.payload,
      };

    case CLEAR_SUBSIDIARY_VEHICLES_HISTORY:
      return {
        ...state,
        subsidiary_vehicles: initialState.subsidiary_vehicles,
      };

    default:
      return state;
  }
}
