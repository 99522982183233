import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetGpsGeofence } from "store/actions/gps/geofence/getGpsGeofence";
import { actionClearGpsGeofence } from "store/actions/gps/geofence/clearGpsGeofence";

import { SHOW_GPS_GEOFENCE } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "components/organisms/gps/InfoGpsGeofenceDialog";

export function InfoGpsGeofenceDialog({
  open,
  gpsGeofenceId,
  gpsId,
  onClose,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const {
    permits,
    setNotification,
    gpsGeofence,
    getGpsGeofence,
    clearGpsGeofence,
  } = rest;

  const showGpsGeofencePermit = permits.includes(SHOW_GPS_GEOFENCE);

  useEffect(() => {
    async function fetchData() {
      try {
        await getGpsGeofence(gpsId, gpsGeofenceId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && gpsGeofenceId) {
      if (showGpsGeofencePermit) {
        fetchData();
      } else {
        setNotification({
          message: t("_permits.show.gps"),
        });
      }
    }

    return () => {
      clearGpsGeofence();
    };
  }, [open, gpsGeofenceId]);

  return (
    <Page
      t={t}
      open={open && showGpsGeofencePermit}
      gpsGeofence={{
        ...gpsGeofence,
        imei: gpsGeofence.g_p_s.imei,
        date:
          moment(gpsGeofence.date).format("LL") +
          " " +
          t("_labels.time_at") +
          " " +
          moment(gpsGeofence.date).format("h:mm a"),
        odometer: gpsGeofence.odometer.toString(),
        status: gpsGeofence.status,
        area: gpsGeofence.area,
        created_by: t("_labels.actioned_by", {
          person: `${gpsGeofence.created_by.name} ${
            gpsGeofence.created_by.last_name_1
          } ${gpsGeofence.created_by.last_name_2 || ""}`,
          date: moment(gpsGeofence.created_at).format("LL"),
          time: moment(gpsGeofence.created_at).format("h:mm a"),
        }),
        updated_by: gpsGeofence.updated_by
          ? t("_labels.actioned_by", {
              person: `${gpsGeofence.updated_by.name} ${
                gpsGeofence.updated_by.last_name_1
              } ${gpsGeofence.updated_by.last_name_2 || ""}`,
              date: moment(gpsGeofence.updated_at).format("LL"),
              time: moment(gpsGeofence.updated_at).format("h:mm a"),
            })
          : null,
      }}
      onClose={onClose}
    />
  );
}

InfoGpsGeofenceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  gpsGeofenceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gpsId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  gpsGeofence: state.Gps.GpsGeofence.gpsGeofence,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getGpsGeofence: actionGetGpsGeofence(dispatch),
  clearGpsGeofence: actionClearGpsGeofence(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoGpsGeofenceDialog);
