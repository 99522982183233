import React, { useEffect } from "react";
import { useLang } from "hooks/lang";

import PropTypes from "prop-types";

import Page from "./page";
import { alphabeticalSort } from "utils/sort";

export function Form(props) {
  const t = useLang();

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await props.getDivisions(
          {
            scope: "division_id,name,status,subsidiary.name",
            subsidiaries: props.values.subsidiaries.join(","),
          },
          false
        );

        props.setDivisions(data.sort(alphabeticalSort("name")));
      } catch (error) {
        props.setNotification(error, true);
      }
    }
    if (
      open &&
      props.fields.divisions &&
      props.subsidiaries.length > 0 &&
      props.values.subsidiaries.length > 0
    ) {
      fetchData();
    }
  }, [open, props.subsidiaries, props.values.subsidiaries]);

  return <Page {...props} t={t} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  engineBrands: PropTypes.array.isRequired,
  transmissionBrands: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  allFieldsFullWidth: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  setNotification: PropTypes.func,
  setDivisions: PropTypes.func,
  getDivisions: PropTypes.func,
};

export default Form;
