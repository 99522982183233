export default {
  root: "No root permissions",
  create: {
    user: "No permissions to create users",
    company: "No permissions to create companies",
    subsidiary: "No permissions to create subsidiaries",
    association: "No permissions to create associations",
    division: "No permissions to create divisions",
    driver: "No permissions to create drivers",
    warehouse: "No permissions to create warehouses",
    gps: "No permissions to create gps",
    depth_tolerance_policy: "No permissions to create depth tolerance policies",
    vehicle_travel: "No permissions to create vehicle travels",
    retirement_cause: "No permissions to create retirement causes",
    gps: "No permissions to create gps",
    tire_wear: "No permission to create tire wear",
    tire_repair: "No permission to send tires to repair",
    alert: "No permission to create alerts",
    brand: "No permission to create brands",
    provider: "No permission to create providers",
    size: "No permission to create sizes",
    wear: "No permission to create wears",
    damage: "No permission to create damages",
    revitalized_tire_model: "No permission to create revitalized tire model",
    rfid: "No permission to create rfids",
    tire_model: "No permission to create tire models",
    vehicle_type: "No permission to create vehicles types",
    vehicle_type_axle: "No permission to create axle to vehicle type",
    vehicle: "No permissions to create vehicles",
    condition_policy: "No permission to create condition policies",
    pressure_policy: "No permissions to create pressure policies",
    depth_policy: "No permissions to create depth policies",
    tire: "No permissions to create tires",
    tire_revitalization: "No permissions to send to retread",
    tire_repair: "No permissions to send to repair",
    tire_pile: "No permissions to send to pile",
    model_pressure_policy:
      "No permissions to create pressure policies by model",
    vehicle_review: "No permissions to create vehicle reviews",
    concept: "No permissions to create concepts",
    block: "No permissions to create blocks",
    checklist: "No permissions to create checklist",
    tpms: "No permissions to create tpms sensor",
  },
  show: {
    gps: "No permissions to view gps",
    company: "No permissions to view companies",
    policy: "No permissions to view policies",
    tolerance_policy: "No permissions to view a depth tolerance policy",
    subsidiary: "No permissions to view subsidiaries",
    user: "No permissions to view users",
    provider: "No permissions to view providers",
    revitalized: "No permissions to view revitalized models",
    warehouse: "No permissions to view warehouses",
    driver: "No permissions to view drivers",
    tire: "No permissions to view tires",
    vehicle: "No permissions to view vehicles",
    division: "No permissions to view divisions",
    tire_cycle: "No permissions to view tire cycles",
    tire_repair: "No permissions to view tire repairs",
    tire_damage: "No permissions to view tire damages",
    damage: "No permissions to view damages",
    wear: "No permissions to view wears",
    tire_wear: "No permissions to view tire wears",
    link: {
      tire_with_warehouse:
        "No permissions to view links to tires with warehouses",
      driver_with_subsidiary:
        "No permissions to view links of drivers with subsidiaries",
      vehicle_with_subsidiary:
        "No permissions to view vehicle links with subsidiaries",
      vehicle_with_gps: "No permissions to view links to vehicles with gps",
      warehouse_with_subsidiary:
        "No permissions to view links of warehouses with subsidiaries",
      tire_with_division: "No permissions to view tire links with divisions",
    },
    rfid: "No permissions to view rfids",
    retirement_cause: "No permissions to view retirement causes",
    checklist: "No permissions to view checklist",
  },
  list: {
    user: "No permissions to list users",
    subsidiary: "No permissions to list subsidiaries",
    vehicle: "No permissions to list vehicles",
    tire: "No permissions to list tires",
    wear: "No permissions to list wear",
    warehouse: "No permissions to list warehouses",
    driver: "No permissions to list drivers",
    provider: "No permissions to list providers",
    rfid: "No permissions to list rfids",
    depth_tolerance_policy: "No permissions to list depth tolerance policies",
    condition_policy: "No permissions to list condition policies",
    pressure_policy: "No permissions to list pressure policies",
    depth_policy: "No permissions to list depth policies",
    helmet_value: "No permissions to list helmet values",
  },
  list_link: {
    tire_with_subsidiary: "No permissions to list tires linked to subsidiaries",
    tire_with_warehouse: "No permissions to list tires linked to warehouses",
    tire_with_division: "No permissions to list tires linked to divisions",
    vehicle_with_subsidiary:
      "No permissions to list vehicles linked to subsidiaries",
    driver_with_subsidiary:
      "No permissions to list drivers linked to subsidiaries",
    warehouse_with_subsidiary:
      "No permissions to list warehouses linked to subsidiaries",
    subsidiary_with_company:
      "No permissions to list subsidiary  linked to companies",
    user_with_company: "No permissions to list users linked to companies",
    user_with_subsidiary: "No permissions to list users linked to subsidiaries",
    vehicle_with_division:
      "No permissions to list vehicles linked to divisions",
    history: {
      warehouse_with_tire: "No permissions to list warehouses linked to tires",
    },
  },
  link: {
    vehicle_with_driver: "No permissions to link vehicles with drivers",
    tire_with_warehouse: "No permissions to link tires with warehouses",
    vehicle_with_division: "No permissions to link vehicles with divisions",
    vehicle_with_gps: "No permissions to link vehicles with gps",
    driver_with_subsidiary: "No permissions to link drivers with subsidiaries",
    tire_with_subsidiary: "No permissions to link tire with subsidiaries",
    vehicle_with_subsidiary: "No permission to link vehicles with subsidiaries",
    warehouse_with_subsidiary:
      "No permission to link warehouses with subsidiaries",
    tire_with_division: "No permissions to link tires with divisions",
    tire_with_rfid: "No permissions to link tires with rfids",
    tpms_with_tire: "No permissions to link tpms sensor with tire",
  },
  delete_link: {
    tire_with_warehouse: "No permission to unlink tires from warehouses",
    vehicle_with_driver:
      "No permissions to delete links from vehicles with drivers",
    vehicle_with_gps: "No permissions to unlink vehicles with gps",
    vehicle_with_division: "No permissions to unlink vehicles with divisions",
    driver_with_subsidiary: "No permissions to unlink driver with subsidiary",
    warehouse_with_subsidiary:
      "No permissions to unlink warehouse with subsidiary",
    tire_with_subsidiary: "No permissions to unlink tires with subsidiary",
    vehicle_with_subsidiary: "No permissions to unlink vehicle with subsidiary",
    tire_with_division: "No permissions to unlink tires with divisions",
  },
  assign: {
    user_to_corporate: "No permissions to link users with corporate",
    rol_to_user_corporate:
      "No permissions to assign role to users in corporate",
    user_to_company: "No permissions to link users with company",
    rol_to_user_company: "No permissions to assign role to users in company",
    user_to_subsidiary: "No permissions to link users with subsidiary",
    rol_to_user_subsidiary:
      "No permissions to assign role to users in subsidiary",
  },
  unlink: {
    user_corporate: "No permissions to delete user assignments in corporates",
    user_company: "No permissions to delete user assignments in companies",
    user_subsidiary:
      "No permissions to delete user assignments in subsidiaries",
    role: {
      user_corporate:
        "No permissions to delete role assignments to users in corporate",
      user_subsidiary:
        "No permissions to delete role assignments to users in subsidiary",
    },
  },
  update: {
    tire_damage: "No permissions to update tire damages",
    tire_wear: "No permissions to update tire wears",
    brand: "No permissions to update brands",
    size: "No permissions to update sizes",
    company: "No permissions to update companies",
    division: "No permissions to update divisions",
    subsidiary: "No permissions to update subsidiaries",
    association: "No permissions to update associations",
    provider: "No permissions to update providers",
    wear: "No permissions to update wears",
    retirement_cause: "No permissions to update retirement causes",
    warehouse: "No permissions to update warehouses",
    gps: "No permissions to update gps",
    driver: "No permissions to update drivers",
    damage: "No permissions to update damages",
    revitalized_tire_model: "No permissions to update revitalized tire model",
    alert: "No permissions to update alerts",
    rfid: "No permissions to update rfids",
    vehicle_type: "No permissions to update vehicles types",
    vehicle_type_axle: "No permissions to update axle to vehicle type",
    company_policy: "No permissions to update company policies",
    tire_model: "No permissions to update tire models",
    vehicle: "No permissions to update vehicles",
    condition_policy: "No permissions to update condition policies",
    pressure_policy: "No permissions to update pressure policies",
    depth_policy: "No permissions to update depth policies",
    tire: "No permissions to update tires",
    model_pressure_policy:
      "No permissions to update pressure policies by model",
    tire_review: "No permissions to update tire reviews",
    vehicle_review: "No permissions to update vehicle reviews",
    concept: "No permissions to update concepts",
    block: "No permissions to update blocks",
    user: "No permissions to update users",
    checklist: "No permissions to update checklist",
    tpms: "No permissions to update tpms sensor",
  },
  delete: {
    tire_wear: "No permissions to delete tire wears",
    brand: "No permissions to delete brands",
    size: "No permissions to delete sizes",
    company: "No permissions to delete companies",
    division: "No permissions to delete divisions",
    subsidiary: "No permissions to delete subsidiaries",
    association: "No permissions to delete associations",
    provider: "No permissions to delete providers",
    wear: "No permissions to delete wears",
    driver: "No permissions to delete drivers",
    warehouse: "No permissions to delete warehouses",
    retirement_cause: "No permissions to delete retirement causes",
    gps: "No permissions to delete gps",
    damage: "No permissions to delete damages",
    revitalized_tire_model: "No permissions to delete revitalized tire model",
    alert: "No permissions to delete alerts",
    rfid: "No permissions to delete rfids",
    tire_model: "No permissions to delete tire models",
    vehicle_type: "No permissions to delete vehicles types",
    vehicle: "No permissions to delete vehicles",
    condition_policy: "No permissions to delete condition policies",
    pressure_policy: "No permissions to delete pressure policies",
    depth_policy: "No permissions to delete depth policies",
    tire: "No permissions to delete tires",
    model_pressure_policy:
      "No permissions to delete pressure policies by model",
    vehicle_review: "No permissions to delete vehicle reviews",
    concept: "No permissions to delete concepts",
    block: "No permissions to delete blocks",
    user: "No permissions to delete users",
    checklist: "No permissions to delete checklist",
    tpms: "No permissions to tpms sensor",
  },
  reset: {
    odometer: "No permissions to reset odometer",
    difference: "No permissions to reset gps difference",
  },
  cancel_movement: "No permissions to cancel movements",
  reject: "No permissions to mark as retread reject",
};
