import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  subsidiaries,
  association,
  associations,
  countries,
  states,
  provinces,
  createAssociationPermit,
  updateAssociationPermit,
  deleteAssociationPermit,
  filter,
  t,
  getAssociation,
  addAssociation,
  updateAssociation,
  clearAssociationFilters,
  setAssociationFilters,
  deleteAssociation,
  setStatusAssociation,
  getAssociations,
  clearAssociations,
  handleChangeCountry,
  handleChangeState,
}) {
  return (
    <Home
      item={association}
      name="association"
      filter={filter}
      createTooltipPermit={
        !createAssociationPermit ? t("_permits.create.association") : ""
      }
      currentPage={associations.current_page}
      deleteConfirmationMessage={t("_messages.delete.association")}
      disableConfirmationMessage={t(
        "_messages.change_status.association.disable"
      )}
      enableConfirmationMessage={t(
        "_messages.change_status.association.enable"
      )}
      rows={associations.data.map((association) => {
        return {
          id: association.association_id,
          key: association.association_id,
          status: {
            status: association.status,
            tooltipPermit: !updateAssociationPermit
              ? t("_permits.update.association")
              : "",
          },
          name: association.name,
          address: `${association.direction_1} #${association.external_number}${
            association.internal_number
              ? ` #${association.internal_number}`
              : ""
          }${association.direction_2 ? ` ${association.direction_2}` : ""}, ${
            association.province
          }, ${association.state}, ${association.country}`,
          redirect: [
            {
              label: t("_general.policies"),
              href: `/association/${association.association_id}/policy/pressure`,
              target: "_blank",
              rel: "noopener",
            },
          ],
          edit: {
            tooltipPermit: !updateAssociationPermit
              ? t("_permits.update.association")
              : "",
          },
          delete: {
            tooltipPermit: !deleteAssociationPermit
              ? t("_permits.delete.association")
              : "",
          },
        };
      })}
      title={t("_general.association")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.association_name") },
        { id: "address", label: t("_labels.address") },
        { id: "actions", label: "" },
      ]}
      lastPage={associations.last_page}
      noItemsText={t("_general.no_associations")}
      initialDefaultValues={{
        name: "",
        subsidiary_id: "",
        direction_1: "",
        external_number: "",
        internal_number: "",
        direction_2: "",
        postal_code: "",
        province: "",
        state: "",
        country: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
        {
          name: "direction_1",
          required: true,
          label: t("_labels.direction1"),
        },
        {
          name: "external_number",
          required: true,
          label: t("_labels.external_number"),
        },
        {
          name: "internal_number",
          label: t("_labels.internal_number"),
          required: false,
        },
        {
          name: "direction_2",
          required: true,
          label: t("_labels.direction2"),
        },
        {
          name: "postal_code",
          required: true,
          label: t("_labels.postal_code"),
        },
        {
          name: "country",
          required: true,
          component: "select",
          label: t("_labels.country"),
          selectItems: countries.length
            ? countries.map((country) => ({
                value: country,
                name: country,
              }))
            : [],
          defaultText: t("_labels.country_field.value"),
          handleChange: handleChangeCountry,
        },
        {
          name: "state",
          required: true,
          component: "select",
          label: t("_labels.state"),
          selectItems: states.length
            ? states.map((state) => ({
                value: state,
                name: state,
              }))
            : [],
          defaultText: t("_labels.state_field.value"),
          handleChange: handleChangeState,
        },
        {
          name: "province",
          required: true,
          component: "select",
          label: t("_labels.province"),
          selectItems: provinces.length
            ? provinces.map((province) => ({
                value: province,
                name: province,
              }))
            : [],
          defaultText: t("_labels.province_field.value"),
        },
      ]}
      createFormTitle={t("_titles.new.association")}
      updateFormTitle={t("_titles.update.association")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.association"),
        },
        date: true,
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.association")}
      updatedMessage={t("_messages.updated.association")}
      getItemAction={getAssociation}
      setFiltersAction={setAssociationFilters}
      clearFiltersAction={clearAssociationFilters}
      deleteAction={deleteAssociation}
      setStatusAction={setStatusAssociation}
      getItemsAction={getAssociations}
      clearItemsAction={clearAssociations}
      addAction={addAssociation}
      updateAction={updateAssociation}
    />
  );
}

Page.propTypes = {
  subsidiaries: PropTypes.array.isRequired,
  association: PropTypes.object.isRequired,
  associations: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  provinces: PropTypes.array.isRequired,
  createAssociationPermit: PropTypes.bool.isRequired,
  updateAssociationPermit: PropTypes.bool.isRequired,
  deleteAssociationPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getAssociation: PropTypes.func.isRequired,
  addAssociation: PropTypes.func.isRequired,
  updateAssociation: PropTypes.func.isRequired,
  clearAssociationFilters: PropTypes.func.isRequired,
  setAssociationFilters: PropTypes.func.isRequired,
  deleteAssociation: PropTypes.func.isRequired,
  setStatusAssociation: PropTypes.func.isRequired,
  getAssociations: PropTypes.func.isRequired,
  clearAssociations: PropTypes.func.isRequired,
  handleChangeCountry: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
};

export default Page;
