export default {
  title: "New Subsidiary",
  update_title: "Update subsidiary",
  messages: {
    delete: "Are you sure to delete the subsidiary?",
    subsidiary_created: "Done, subsidiary has been successfully created",
    subsidiary_updated: "Done, the subsidiary has been successfully updated",
  },
  form: {
    permits: {
      update: "No permissions to update subsidiaries",
      delete: "No permissions to delete subsidiaries",
    },
  },
};
