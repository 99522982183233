/*
 * Cálculo de regresión lineal
 *
 * https://dracoblue.net/dev/linear-least-squares-in-javascript/
 */
export function findLineByLeastSquares(valuesX, valuesY) {
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let count = 0;

  let x = 0;
  let y = 0;
  let valuesLength = valuesX.length;

  if (valuesLength != valuesY.length) {
    throw new Error(
      "The parameters valuesX and valuesY need to have same size!"
    );
  }

  if (valuesLength === 0) {
    return [[], []];
  }

  for (let v = 0; v < valuesLength; v++) {
    x = valuesX[v];
    y = valuesY[v];
    sumX += x;
    sumY += y;
    sumXX += x * x;
    sumXY += x * y;
    count++;
  }

  let m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
  let b = sumY / count - (m * sumX) / count;

  let resultValuesX = [];
  let resultValuesY = [];

  for (let v = 0; v < valuesLength; v++) {
    x = valuesX[v];
    y = x * m + b;
    resultValuesX.push(x);
    resultValuesY.push(y);
  }

  return [resultValuesX, resultValuesY];
}
