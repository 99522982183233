import { GET_FAVORITES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetFavorites =
  (dispatch) =>
  async (params, withDispatch = true) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `favorite/report`,
        params: params,
      });

      if (withDispatch) {
        dispatch({
          type: GET_FAVORITES,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
