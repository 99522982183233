import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { alphabeticalSort } from "utils/sort";
import { actionGetTirePileDetails } from "store/actions/reports/pile/getTirePileDetails";
import { actionClearTirePileDetails } from "store/actions/reports/pile/clearTirePileDetails";

import Page from "./page";

export function TireDialog({ open, params, ...rest }) {
  const t = useLang();

  const { tires, setNotification, getTirePileDetails, clearTirePileDetails } =
    rest;
  useEffect(() => {
    async function fetchData() {
      try {
        await getTirePileDetails(params);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearTirePileDetails();
    };
  }, [open]);

  return <Page {...rest} t={t} open={open} tires={getSortedData(tires)} />;
}

TireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tires: state.Reports.Pile.tires,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTirePileDetails: actionGetTirePileDetails(dispatch),
  clearTirePileDetails: actionClearTirePileDetails(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDialog);

function getSortedData(data) {
  return data.sort(alphabeticalSort("cpk"));
}
