import { combineReducers } from "redux";

import Association from "./association";
import PressurePolicies from "./pressure_policy";
import DepthPolicies from "./depth_policy";

export default combineReducers({
  Association,
  PressurePolicies,
  DepthPolicies,
});
