import React, { useRef } from "react";

import PropTypes from "prop-types";

import Page from "./page";

function AppBar({ title, options, onClickMenu, children }) {
  const appBar = useRef();

  return (
    <Page
      title={title}
      appBarRef={appBar}
      options={options}
      onClickMenu={onClickMenu}
    >
      {children}
    </Page>
  );
}

AppBar.propTypes = {
  title: PropTypes.string.isRequired,
  onClickMenu: PropTypes.func.isRequired,
  options: PropTypes.node,
  children: PropTypes.node,
};

export default AppBar;
