export default {
  colloquial_name: {
    incorrect_application: "Aplicación incorrecta",
    incorrect_directional_condition: "Condición incorrecta (Eje Direccional)",
    critical_removal: "Retiro crítico",
    mm_removal_90_days: "Retiro por mm (90 días)",
    condition_mismatch_ro: "Mal emparejamiento por Condición (R/O)",
    condition_or_not_allowed_on_axis: "Condición (O/R) no permitida en eje",
    mismatch_ro: "Mal emparejamiento por Condición (R/O)",
    mismatch_mm_dual: "Mal emparejamiento por mm (Dual)",
    mismatch_model_dual: "Mal emparejamiento de modelos en dual",
    mismatch_measure_dual: "Mal emparejamiento por medida (Dual)",
    scheduled_removal: "Retiro programado",
    different_assignment: "Diferente asignación",
    mm_direction_alert: "Alerta por mm Dirección.",
    wear_decrement_alert: "Alerta por decremento del desgaste",
    wear_increment_alert: "Alerta por incremento del desgaste",
    review_required: "Revisión requerida",
    mm_removal_30_days: "Retiro por mm (30 días)",
    mismatch_pressure: "Mal Emparejamiento por presión",
    mismatch_depth: "Mal emparejamiento por profundidad.",
    mismatch_pressure_two: "Mal emparejamiento por presión.",
    incorrect_pressure: "Presión incorrecta.",
    rotation_required_directional_axis: "Rotación requerida eje (Direccional)",
    rotation_required_traction_axis: "Rotación requerida eje (tracción/libre)",
    patches_limit: "Limite de parches",
    irregular_wear: "Desgaste irregular",
  },

  suggestion: {
    install_appropriate_tire_model_on_axis:
      "Colocar un modelo de neumático que corresponda a la aplicación del eje donde se esta instalando.",
    only_original_tires_allowed_on_this_axis:
      "En este eje solo se permiten neumáticos originales.",
    immediate_tire_removal_required: "Retiro del neumático de forma inmediata",
    change_tire_within_90_days: "Cambiar el neumático en no más de 90 días.",
    install_tire_that_meets_condition_policy_on_axis:
      "Colocar un neumático que cumpla con las políticas de condición por eje establecidas.",
    install_same_condition_tires_on_dual_axis:
      "Colocar neumáticos con la misma condición (original/renovada) en el eje dual.",
    change_tire_if_mm_difference_exceeds_policy:
      "Cambiar neumático por uno que no tenga una diferencia mayor al mm definido en las políticas.",
    install_same_model_tires_on_dual_axis:
      "Colocar neumáticos con el mismo modelo en el dual",
    install_tire_with_same_size_or_dual_axis_size:
      "Colocar un neumático que sea de la misma medida que el otro neumático en el eje o que la medida corresponda al eje dual",
    monitor_wear_within_30_days:
      "Dar seguimiento dentro de los siguientes 30 días al desgaste",
    validate_tire_location_registered_in_system:
      "Validar la ubicación del neumático que estaba registrada en el sistema.",
    change_tire_or_pair_with_higher_mm_within_scheduled_removal_policy:
      "Cambiar neumático o ambos por un par que tenga mayor mm que el establecido en la política de retiro programado.",
    physically_inspect_tire_to_validate_depth:
      "Hacer una inspección física del neumático para validar la profundidad.",
    immediate_vehicle_inspection_required:
      "Realizar una inspección al vehículo de forma inmediata.",
    change_tire_within_30_days: "Cambiar el neumático en no más de 30 días",
    correct_dual_axis_pressure: "Corregir la presión en el eje dual.",
    change_tire_for_lower_mm_or_entire_dual_axis:
      "Cambiar el neumático por uno con menor de mm o cambiar el dual completo.",
    correct_tire_pressure: "Corregir presión del neumático.",
    perform_rotation_on_directional_axes:
      "Realizar rotación en los ejes direccionales.",
    perform_rotation_on_non_directional_axes:
      "Realizar rotación en los ejes no direccionales.",
    change_tire_to_spare_position_or_remove_from_operation:
      "Cambiar el neumático a una posición de refacción o retirarla de operación.",
    physically_inspect_tire_to_validate_correct_wear:
      "Hacer una inspección física del neumático para validarla que el desgaste sea correcto.",
  },

  details: {
    wrong_pairing_pressure_axle: "Mal apareamiento por presión",
    wrong_pairing_depth_axle: "Mal apareamiento por profundidad",
    wrong_pairing_pressure_axle_dual:
      "Emparejamiento incorrecto con la presión en el eje dual",
    wrong_pairing_difference_model_axle_dual:
      "Mal apareamiento por diferencia de modelos en dual",
    wrong_pairing_difference_condition_axle_dual:
      "Mal apareamiento por diferencia de condición dual (renovado y original)",
    wrong_pairing_difference_measurement_axle_dual:
      "Mal apareamiento, por diferencia de medida en dual",
    wrong_pairing_difference_condition_axle:
      "Mal apareamiento por diferencia de condición de neumáticos en el eje",
    wrong_depth_steer_axle:
      "Profundidad de eje direccional incorrecta, huella menor a 5 mm ",
    life_projection_one_month: "Proyección de vida restante no mayor a un mes",
    life_projection_three_month:
      "Proyección de vida restante no mayor a tres meses",
    maximum_patches_tire: "Neumático con cantidad máxima de parches permitidos",
    scheduled_tire_retirement: "Neumático en situación de retiro programado",
    critical_tire_retirement:
      "Neumático en situación de retiro crítico (inmediato)",
    steer_axle_original_tire:
      "La posición solo acepta neumáticos originales por ser eje de direccional",
    wrong_pairing_depth_dual:
      "Mal apareamiento por diferencia en profundidad en dual",
    application_tire_no_correspond:
      "La aplicación del neumático no corresponde con el eje en el que está instalado",
    difference_depth_upper:
      "Alerta por diferencia de profundidad de huella entre la última y penúltima revisión (Desgaste superior)",
    difference_depth_down:
      "Alerta por diferencia de profundidad de huella entre la última y penúltima revisión (Desgaste inferior)",
    pressure_out_range: "Presión del neumático fuera del rango recomendado",
    policy_not_allow_tire_condition_axle:
      "La condición de neumático no esta permitida en este eje según las políticas",
    uneven_wear: "El neumático a mostrado un desgaste irregular",
    physical_difference:
      "El neumático montado en las posición físicamente no corresponde a los registros del sistema.",
    lack_review_vehicle: "Falta de revisión para el vehículo.",
    steer_rotation: "Falta rotación neumáticos ejes direccionales.",
    non_steer_rotation: "Falta rotación neumáticos ejes no direccionales.",
  },

  cause: {
    tire_life_short:
      "El desgaste medio del neumático indica que no le queda más de un mes de vida útil.",
    tire_life_medium:
      "El desgaste medio del neumático indica que no le queda más de tres meses de vida útil.",
    critical_retirement:
      "El neumático ${0} se encuentra en situación de retiro crítico (inmediato)",
    incorrect_tire_application:
      "La aplicación del neumático no corresponde al eje en el que está instalado, las aplicaciones del eje son : ${0} y las del neumático es : ${1}",
    unusual_wear_more:
      "El neumático presenta una diferencia de desgaste inusual entre la última y penúltima revisión, el neumático ${0} ha sufrido más desgaste",
    unusual_wear_less:
      "El neumático presenta una diferencia de desgaste inusual entre la última y penúltima revisión, el neumático ${0} ha sufrido menos desgaste",
    measurement_difference:
      "El vehículo tiene una alerta a causa de la diferencia de medidas en el eje ${0}/${1} en las posiciones ${2}-${3}",
    model_difference:
      "El vehículo tiene una alerta a causa de la diferencia de modelos en el eje ${0} en las posiciones ${1}-${2}",
    condition_difference:
      "El vehículo tiene una alerta a causa de la diferencia de condición en el eje ${0}/${1}",
    condition_difference_at_position:
      "El vehículo tiene una alerta a causa de la diferencia de condición en el eje ${0}/${1} en las posiciones ${2}-${3}",
    pressure_out_of_range:
      "La presión del neumático se encuentra fuera del rango recomendado",
    original_tire_only:
      "La posición solo acepta neumáticos originales por ser eje direccional",
    irregular_wear_job:
      "El neumático esta presentando un desgaste irregular en sus lecturas de profundidad de huella.",
    low_tread_depth_directional:
      "El neumático de tipo direccional tiene una profundidad de huella menor a 5 mm",
    depth_difference:
      "El vehículo tiene una alerta a causa de la diferencia de profundidad en el eje ${0}/${1}",
    depth_difference_wrong_paring:
      "El vehículo tiene una alerta a causa de la diferencia de profundidad en el eje ${0}/${1} en las posiciones ${2}-${3}",
    pressure_difference:
      "El vehículo tiene una alerta a causa de la diferencia de presiones en el eje ${0}/${1}",
    pressure_difference_at_position:
      "El vehículo tiene una alerta a causa de la diferencia de presiones en el eje ${0} en las posiciones ${1}-${2}",
    scheduled_remove_tire:
      "El neumático ${0} se encuentra en situación de retiro programado",
    wrong_rfid_sensor:
      "El sensor RFID del neumático no fue utilizado para llevar a cabo la revisión.",
    vehicle_review_delayed:
      "El vehículo ${0} lleva ${1} días sin una inspección",
    vehicle_review_delayed_km:
      "El vehículo ${0} lleva ${1} kilómetros sin que se le realice una rotación en el eje direccional",
    vehicle_review_partial:
      "La revisión del vehículo ${0} no contenía todos los neumáticos, y paso a ser una revisión parcial",
    different_assignment_axle:
      "El vehículo tiene una alerta a causa de la diferencia de asignación física en el eje ${0} en las posiciones ${1}",
  },
  dynamic: {
    all_position: "Toda posición",
    directional: "Direccional",
    traction: "Tracción",
    trailer: "Remolque",
    central: "Central",
    rear: "Trasero",
    frontal: "Frontal",
    refection: "Refacción",
    maintenance: "Mantenimiento",
  },
};
