import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  isUpdate,
  tireSizes,
  isSubmitting,
  updateTireModelVariationPermit,
  deleteTireModelVariationPermit,
  onClose,
  handleDelete,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        fullWidth
        required
        variant="filled"
        margin="normal"
        name="size"
        className={classnames(css.m8)}
        disabled={isUpdate && !updateTireModelVariationPermit}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("size_field")}
        defaultValue={
          tireSizes.some((size) => size.tire_size_id === values.size)
            ? null
            : values.size
        }
        component={SelectField}
        label={t("_labels.size")}
      >
        {tireSizes.map((size, index) => {
          return (
            <MenuItem
              key={index}
              value={size.tire_size_id.toString()}
              disabled={!size.status}
            >
              {size.size}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        required
        variant="filled"
        margin="normal"
        name="number_layers"
        component={TextField}
        className={classnames(css.field)}
        disabled={isUpdate && !updateTireModelVariationPermit}
        label={t("_labels.tire_model_variation.number_layers")}
      />

      <Field
        fullWidth
        required
        variant="filled"
        margin="normal"
        name="depth"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.depth")}
        disabled={isUpdate && !updateTireModelVariationPermit}
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
      />

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="maximum_pressure"
        component={TextField}
        className={classnames(css.field)}
        disabled={isUpdate && !updateTireModelVariationPermit}
        label={t("_labels.tire_model_variation.maximum_pressure")}
        InputProps={{
          endAdornment: <InputAdornment position="end">psi</InputAdornment>,
        }}
      />

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="recommended_pressure"
        component={TextField}
        className={classnames(css.field)}
        disabled={isUpdate && !updateTireModelVariationPermit}
        label={t("_labels.tire_model_variation.recommended_pressure")}
        InputProps={{
          endAdornment: <InputAdornment position="end">psi</InputAdornment>,
        }}
      />

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="tolerance"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.tolerance")}
        disabled={isUpdate && !updateTireModelVariationPermit}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="helmet_value"
        component={TextField}
        className={classnames(css.field)}
        disabled={isUpdate && !updateTireModelVariationPermit}
        label={t("_labels.helmet_value")}
        InputProps={{
          endAdornment: <InputAdornment position="end">MXN</InputAdornment>,
        }}
      />

      <DialogActions className={classnames(css.action)}>
        <div className={classnames(css.delete)}>
          {isUpdate && (
            <Button
              color="secondary"
              type="button"
              disabled={isSubmitting || !deleteTireModelVariationPermit}
              tooltip={
                !deleteTireModelVariationPermit ? t("permits.delete") : ""
              }
              onClick={handleDelete}
            >
              {t("_buttons.delete")}
            </Button>
          )}
        </div>
        <div className={classnames(css.buttons_right)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={
              isSubmitting ||
              !isValid ||
              (isUpdate && !updateTireModelVariationPermit)
            }
            tooltip={
              isUpdate && !updateTireModelVariationPermit
                ? t("permits.update")
                : ""
            }
          >
            {!isUpdate ? t("_buttons.save") : t("_buttons.update")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tireSizes: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateTireModelVariationPermit: PropTypes.bool.isRequired,
  deleteTireModelVariationPermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
