export default {
  title: "Nuevo Ciclo",
  update_title: "Actualizar ciclo",
  form: {
    condition_field: {
      value: "Seleccione una condición",
      label: "Condición",
    },
    provider_field: {
      value: "Seleccione un proveedor",
      label: "Proveedor",
    },
    brand_field: {
      value: "Seleccione un marca",
      label: "Marca",
    },
    tire_size_field: {
      label: "Medida/Capas",
      value: "Selecciona una medida/capas",
    },
    permits: {
      update: "Sin permisos para actualizar ciclos de neumáticos",
    },
  },
  messages: {
    tire_cycle_created: "Listo, el ciclo ha sido creado con éxito",
    tire_cycle_updated: "Listo, se actualizo el ciclo con éxito",
  },
};
