export default {
  title: "Nuevo usuario",
  title_update: "Actualizar usuario",
  form: {
    labels: {
      password_confirm: "Confirmar contraseña",
      change_password: "Cambiar contraseña al iniciar sesión",
    },
    preferred_language: {
      label: "Idioma de preferencia",
      options: {
        es: "Español",
        en: "Ingles",
      },
    },
    permits: {
      delete: "Sin permisos para eliminar usuarios",
      update: "Sin permisos para actualizar usuarios",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar este usuario?",
    user_created: "Listo, se creo un nuevo usuario con éxito",
    user_updated: "Listo, se actualizo el usuario con éxito",
  },
};
