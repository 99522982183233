import {
  CLEAR_DIVISIONS,
  GET_DIVISIONS,
  REGISTER_DIVISION,
  SET_DIVISION_FILTERS,
  CLEAR_DIVISION_FILTERS,
  GET_DIVISION,
  CLEAR_DIVISION,
  UPDATE_DIVISION,
  DELETE_DIVISION,
  SET_DIVISION_STATUS,
} from "store/actions";

const initialState = {
  division: {
    division_id: 0,
    subsidiary_id: 0,
    name: "",
    status: 0,
    subsidiary: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  divisions: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_division: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_DIVISION:
    case GET_DIVISION:
    case UPDATE_DIVISION:
      return {
        ...state,
        division: action.payload,
      };

    case CLEAR_DIVISION:
      return {
        ...state,
        division: { ...initialState.division },
      };

    case GET_DIVISIONS:
      return {
        ...state,
        divisions: action.payload,
      };

    case CLEAR_DIVISIONS:
      return {
        ...state,
        divisions: initialState.divisions,
      };

    case SET_DIVISION_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_division: { ...filter },
      };

    case CLEAR_DIVISION_FILTERS:
      return {
        ...state,
        filter_division: { search: state.filter_division.search },
      };

    case SET_DIVISION_STATUS:
      return {
        ...state,
        divisions: {
          ...state.divisions,
          data: state.divisions.data.map((division) =>
            division.division_id !== action.payload
              ? division
              : { ...division, status: !division.status }
          ),
        },
      };

    case DELETE_DIVISION:
      return {
        ...state,
        divisions: {
          ...state.divisions,
          data: state.divisions.data.filter(
            (division) => division.division_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
