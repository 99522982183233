import {
  ASSIGN_VEHICLE_TO_DRIVER,
  DELETE_LINK_VEHICLE_WITH_DRIVER,
  GET_DRIVER_VEHICLE,
  CLEAR_DRIVER_VEHICLE,
} from "store/actions";

const initialState = {
  vehicle: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_VEHICLE_TO_DRIVER:
    case GET_DRIVER_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
      };

    case DELETE_LINK_VEHICLE_WITH_DRIVER:
    case CLEAR_DRIVER_VEHICLE:
      return {
        ...state,
        vehicle: initialState.vehicle,
      };

    default:
      return state;
  }
}
