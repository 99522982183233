export default {
  title: "Vehículos vinculados",
  form: {
    vehicle_division: "Solo se muestran los vehículos de la sucursal",
  },
  permits: {
    list_vehicle: "Sin permisos para listar vehículos con divisiones ",
    unlink_division_vehicle:
      "Sin permisos para desvincular vehículos de divisiones",
  },
  messages: {
    delete: "¿Estas seguro de desvincular el vehículo?",
    delete_division_vehicle: "Listo, se desvinculó el vehículo de la division",
    linked_division_vehicle:
      "Listo, el vehículo fue vinculado a la division con éxito",
  },
};
