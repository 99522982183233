import { UPDATE_SUBSIDIARY_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateSubsidiaryPolicy =
  (dispatch) => async (fields, subsidiaryId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `subsidiary/${subsidiaryId}/policy`,
        data: fields,
      });

      dispatch({
        type: UPDATE_SUBSIDIARY_POLICY,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
