export default {
  title: "New vehicle type",
  update_title: "Update vehicle type",
  form: {
    labels: {
      description: "Description",
    },
    structure_type: {
      default: "Select a structure type",
    },
    permits: {
      create: {
        vehicle_type_axle: "No permissions to create axles of a vehicle type",
      },
      update: {
        vehicle_type: "No permissions to update vehicle types",
        vehicle_type_axle: "No permissions to update axles of a vehicle type",
      },
      delete: {
        vehicle_type: "No permissions to delete vehicle types",
      },
    },
  },
  messages: {
    vehicle_type_created:
      "Done, the vehicle type has been successfully created",
    vehicle_type_axles_created:
      "Done, the vehicle type axles have been successfully created",
    vehicle_type_updated:
      "Done, the vehicle type has been successfully updated",
    vehicle_type_axles_updated:
      "Done, the vehicle type axles have been successfully updated",
    vehicle_type_delete: "Are you sure to delete vehicle type?",
  },
};
