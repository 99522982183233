import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { actionGetUsers } from "store/actions/user/getUsers";
import { actionClearUsers } from "store/actions/user/clearUsers";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetUserFilters } from "store/actions/user/setUserFilters";
import { actionClearUserFilters } from "store/actions/user/clearUserFilters";
import { actionGetUser } from "store/actions/user/getUser";
import { actionAddUser } from "store/actions/user/addUser";
import { actionUpdateUser } from "store/actions/user/updateUser";
import { actionDeleteUser } from "store/actions/user/deleteUser";
import { actionSetUserStatus } from "store/actions/user/setUserStatus";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionDeleteUserRole } from "store/actions/user/role/deleteUserRole";

import { alphabeticalSort } from "utils/sort";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  LIST_COMPANY,
  LIST_SUBSIDIARY,
} from "store/actions/account/permits";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "pages/user/HomeUser";

export function HomeUser({ ...rest }) {
  const t = useLang();
  const {
    permits,
    user,
    users,
    filter,
    account,
    getUser,
    addUser,
    updateUser,
    actionDelete,
    setUserStatus,
    getCorporates,
    getCompanies,
    getSubsidiaries,
    getUsers,
    clearUsers,
    setUserFilters,
    clearUserFilters,
    deleteUserRole,
    setNotification,
  } = rest;

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openRoleForm, setOpenRoleForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userAssigned, setUserAssigned] = useState(null);
  useEffect(() => {
    async function fetchData() {
      try {
        await getUsers({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearUsers();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearUserFilters();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [users.data]);

  useEffect(() => {
    fetchAreasData();
  }, []);

  async function fetchAreasData(update = false) {
    try {
      let dataCorporates = [];
      let dataCompanies = [];
      let dataSubsidiaries = [];

      // dataCorporates = account.corporates.map((corporate) => ({
      //   corporate_id: corporate.corporate.corporate_id,
      //   name: corporate.corporate.name,
      //   status: corporate.corporate.status,
      // }));
      dataCorporates = await getCorporates(
        { scope: "corporate_id,name,status" },
        false
      );
      if (update) {
        if (permits.includes(LIST_COMPANY)) {
          dataCompanies = await getCompanies(
            { scope: "company_id,name,status" },
            false
          );
        } else {
          dataCompanies = account.companies.map((company) => ({
            company_id: company.company.company_id,
            name: company.company.name,
            status: company.company.status,
          }));
        }

        if (permits.includes(LIST_SUBSIDIARY)) {
          dataSubsidiaries = await getSubsidiaries(
            { scope: "subsidiary_id,name,status", in_user_page: 1 },
            false
          );
        } else {
          dataSubsidiaries = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
      }

      setCorporates(dataCorporates.sort(alphabeticalSort("name")));
      setCompanies(dataCompanies.sort(alphabeticalSort("name")));
      setSubsidiaries(dataSubsidiaries.sort(alphabeticalSort("name")));
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleChangePage(page) {
    try {
      await getUsers({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function changeStatus(id, status) {
    try {
      const response = await setUserStatus(id, status ? 0 : 1);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(user) {
    setNotification({
      onAction: () => {
        changeStatus(user.user_id, user.status);
      },
      message: user.status
        ? t("_messages.change_status.user.disable")
        : t("_messages.change_status.user.enable"),
      textAction: t("_messages.confirm"),
    });
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setUserId(null);
  }

  async function handleEdit(userId) {
    setUserId(userId);
    await fetchAreasData(true);
    setOpenForm(true);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    try {
      setUserFilters({ search });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(userId) {
    setNotification({
      message: t("_messages.delete.user"),
      onAction: () => deleteUser(userId),
      textAction: t("_messages.confirm"),
    });
  }

  async function deleteUser(userId) {
    try {
      const response = await actionDelete(userId);
      setNotification({
        message: response.message,
        code: response.code,
      });
      clearUserFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenRoleForm(userId) {
    setUserId(userId);
    setOpenRoleForm(true);
  }

  function handleCloseRoleForm() {
    setOpenRoleForm(false);
    setUserId(null);
    setUserAssigned(null);
  }

  function handleEditRole(userId, userAssigned) {
    setUserId(userId);
    setUserAssigned(userAssigned);
    setOpenRoleForm(true);
  }
  async function handleChangeCorporate(form, value) {
    let data = [];
    data = await getCompanies(
      {
        corporates: value,
        scope: "company_id,name,status",
      },
      false
    );
    setCompanies(data.sort(alphabeticalSort("name")));
    form.setFieldValue("company_id", "");
    form.setFieldValue("subsidiary_id", "");
  }

  async function handleChangeCompany(form, value) {
    let data = [];
    data = await getSubsidiaries(
      {
        companies: value,
        scope: "subsidiary_id,name,status",
        in_user_page: 1,
      },
      false
    );
    setSubsidiaries(data.sort(alphabeticalSort("name")));
    // form.setFieldValue("corporate_id", "");
    form.setFieldValue("subsidiary_id", "");
  }

  function setRequiredPasswordConfirm(value) {
    if (value !== "") {
      return true;
    } else {
      return false;
    }
  }

  async function deleteRole(userId, subsidiaryId, role) {
    try {
      const response = await deleteUserRole(userId, {
        subsidiary_id: subsidiaryId,
        role,
      });
      setNotification({
        message: response.message,
        code: response.code,
      });
      clearUserFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDeleteRole(userId, subsidiaryId, role) {
    setNotification({
      message: t("_messages.delete.user_role"),
      onAction: () => deleteRole(userId, subsidiaryId, role),
      textAction: t("_messages.confirm"),
    });
  }
  return (
    <Page
      users={users}
      user={user}
      filter={filter}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      createUserPermit={permits.includes(CREATE_USER)}
      updateUserPermit={permits.includes(UPDATE_USER)}
      deleteUserPermit={permits.includes(DELETE_USER)}
      t={t}
      getUser={getUser}
      addUser={addUser}
      updateUser={updateUser}
      clearUserFilters={clearUserFilters}
      setUserFilters={setUserFilters}
      deleteUser={deleteUser}
      setUserStatus={setUserStatus}
      getUsers={getUsers}
      clearUsers={clearUsers}
      handleChangeCorporate={handleChangeCorporate}
      handleChangeCompany={handleChangeCompany}
      setRequiredPasswordConfirm={setRequiredPasswordConfirm}
      handleChangePage={handleChangePage}
      handleChangeStatus={handleChangeStatus}
      handleDelete={handleDelete}
      handleSearch={handleSearch}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      openForm={openForm}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleEdit={handleEdit}
      userId={userId}
      openRoleForm={openRoleForm}
      handleOpenRoleForm={handleOpenRoleForm}
      handleCloseRoleForm={handleCloseRoleForm}
      handleDeleteRole={handleDeleteRole}
      userAssigned={userAssigned}
      handleEditRole={handleEditRole}
    />
  );
}

HomeUser.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  users: state.User.User.users,
  filter: state.User.User.filter,
  user: state.User.User.user,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  getUsers: actionGetUsers(dispatch),
  clearUsers: actionClearUsers(dispatch),
  setUserFilters: actionSetUserFilters(dispatch),
  clearUserFilters: actionClearUserFilters(dispatch),
  actionDelete: actionDeleteUser(dispatch),
  setUserStatus: actionSetUserStatus(dispatch),
  getUser: actionGetUser(dispatch),
  addUser: actionAddUser(dispatch),
  updateUser: actionUpdateUser(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setNotification: actionSetNotification(dispatch),
  deleteUserRole: actionDeleteUserRole(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);
