import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import VehicleDialog from "../VehicleDialog";
import css from "./index.module.scss";
import { DepthTireDialog } from "../DepthTireDialog";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const numberOfTires = (maxPosition) => {
  const array = [];
  for (let index = 0; index < maxPosition; index++) {
    array.push(index + 1);
  }
  return array;
};

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const kmFormatter = new Intl.NumberFormat("en", {
  style: "unit",
  unit: "kilometer",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

function Page({
  t,
  vehicles,
  summaryData,
  divisionData,
  visibleStructure,
  handleChange,
  handleClickUnits,
  openDialog,
  setOpenDialog,
  division,
  divisionVehicles,
  handleClickDepth,
  openTireDialog,
  setOpenTireDialog,
  tires,
  depth,
}) {
  return (
    <>
      <div className="row container-fluid mx-0 px-0">
        <div className="w-100 d-flex" style={{ gap: "6px" }}>
          {vehicles.map((structure, index) => (
            <button
              key={index}
              className={classnames({
                btn: true,
                "text-uppercase": true,
                "btn-dark": visibleStructure == index,
                "btn-secondary": visibleStructure != index,
                [css.btn]: true,
              })}
              onClick={() => handleChange(index)}
            >
              {t(
                `_labels.structure_type.options.${structure.structure.toLowerCase()}`
              )}
            </button>
          ))}
          <button
            className={classnames({
              btn: true,
              "text-uppercase": true,
              "btn-dark": visibleStructure == "summary",
              "btn-secondary": visibleStructure != "summary",
              [css.btn]: true,
            })}
            onClick={() => handleChange("summary")}
          >
            {t("_labels.summary")}
          </button>
        </div>
      </div>

      {vehicles[visibleStructure] && (
        <>
          <p className="text-uppercase text-center h2 my-2">
            {t(
              `_labels.structure_type.options.${vehicles[
                visibleStructure
              ].structure.toLowerCase()}`
            )}
          </p>
          <div className="row justify-content-center mb-3">
            <div className="col-md-3">
              <div className="table-responsive">
                <table
                  className={classnames(
                    css.table,
                    "table",
                    "table-sm",
                    "table-bordered"
                  )}
                >
                  <thead className="sbgazul">
                    <tr>
                      <th>
                        {t("_labels.semaphore_report.total_amount")}{" "}
                        {t("_labels.originals")}
                      </th>
                      <th>
                        {t("_labels.semaphore_report.total_amount")}{" "}
                        {t("_labels.revitalized")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-weight-bold">
                        {formatter.format(
                          summaryData[visibleStructure].originals
                        )}
                      </td>
                      <td className="font-weight-bold">
                        {formatter.format(
                          summaryData[visibleStructure].revitalized
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-3">
              <div className="table-responsive">
                <table
                  className={classnames(
                    css.table,
                    "table",
                    "table-sm",
                    "table-bordered"
                  )}
                >
                  <thead>
                    <tr>
                      <th style={{ border: "none" }}>
                        <div style={{ width: 42 }}></div>
                      </th>
                      <th className="sbgazul">Total</th>
                      <th className="sbgazul">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={css.rojo}>
                        <BootstrapTooltip
                          title={t("_labels.semaphore_report.red_info")}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon />
                        </BootstrapTooltip>
                      </td>
                      <td className="font-weight-bold">
                        {formatter.format(
                          summaryData[visibleStructure].critical
                        )}
                      </td>
                      <td className="font-weight-bold">
                        {isNaN(
                          formatter.format(
                            (summaryData[visibleStructure].critical /
                              summaryData[visibleStructure].total) *
                              100
                          )
                        )
                          ? 0
                          : formatter.format(
                              (summaryData[visibleStructure].critical /
                                summaryData[visibleStructure].total) *
                                100
                            )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className={css.amarillo}>
                        <BootstrapTooltip
                          title={t("_labels.semaphore_report.yellow_info")}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon />
                        </BootstrapTooltip>
                      </td>
                      <td className="font-weight-bold">
                        {formatter.format(
                          summaryData[visibleStructure].scheduled
                        )}
                      </td>
                      <td className="font-weight-bold">
                        {isNaN(
                          formatter.format(
                            (summaryData[visibleStructure].scheduled /
                              summaryData[visibleStructure].total) *
                              100
                          )
                        )
                          ? 0
                          : formatter.format(
                              (summaryData[visibleStructure].scheduled /
                                summaryData[visibleStructure].total) *
                                100
                            )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className={css.verde}>
                        <BootstrapTooltip
                          title={t("_labels.semaphore_report.green_info")}
                          placement="bottom"
                        >
                          <InfoOutlinedIcon />
                        </BootstrapTooltip>
                      </td>
                      <td className="font-weight-bold">
                        {formatter.format(summaryData[visibleStructure].good)}
                      </td>
                      <td className="font-weight-bold">
                        {isNaN(
                          formatter.format(
                            (summaryData[visibleStructure].good /
                              summaryData[visibleStructure].total) *
                              100
                          )
                        )
                          ? 0
                          : formatter.format(
                              (summaryData[visibleStructure].good /
                                summaryData[visibleStructure].total) *
                                100
                            )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }}>
                        <div></div>
                      </td>
                      <td className="font-weight-bold">
                        {formatter.format(summaryData[visibleStructure].total)}
                      </td>
                      <td className="font-weight-bold">
                        {summaryData[visibleStructure].total != 0
                          ? "100%"
                          : "0%"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <p>O/R: Original o Revitalizado</p>
          </div>
          <div className="row container-fluid">
            <div className="col-md-10 p-0">
              <div className="table-responsive">
                <table
                  className={classnames(
                    css.table,
                    "table",
                    "table-sm",
                    "table-bordered"
                  )}
                >
                  <thead className="sbngris">
                    {numberOfTires(vehicles[visibleStructure].max).length >
                    0 ? (
                      <tr>
                        <th style={{ borderBottom: "none" }}></th>
                        {numberOfTires(vehicles[visibleStructure].max).map(
                          (pos) => (
                            <th key={pos} colSpan={2}>
                              #{pos}
                            </th>
                          )
                        )}
                      </tr>
                    ) : (
                      <tr>
                        <th style={{ borderBottom: "none" }}>&nbsp;</th>
                      </tr>
                    )}
                    <tr>
                      <th
                        style={{
                          borderTop: "1px solid",
                          borderBottom: "1px solid",
                        }}
                      >
                        {t("_labels.semaphore_report.unit")}
                      </th>
                      {numberOfTires(vehicles[visibleStructure].max).map(
                        (pos) => (
                          <Fragment key={pos}>
                            <th className="sbgazul">MM</th>
                            <th className="sbgazul">O/R</th>
                          </Fragment>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {vehicles[visibleStructure].vehicles.map((vehicle) => (
                      <tr key={vehicle.vehicle} style={{ height: "51.375px" }}>
                        <td
                          className={css.even}
                          style={{ fontWeight: 500, verticalAlign: "middle" }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <span>{vehicle.vehicle}</span>
                            <span className="text-muted">
                              {kmFormatter.format(vehicle.odometer)}
                            </span>
                          </div>
                        </td>
                        {numberOfTires(vehicles[visibleStructure].max).map(
                          (pos) => (
                            <Fragment key={pos}>
                              <td
                                className={getClassName(
                                  css,
                                  vehicle.positions,
                                  pos
                                )}
                                style={{ verticalAlign: "middle" }}
                              >
                                <p style={{ margin: 0, padding: 0 }}>
                                  {getDepth(vehicle.positions, pos)}
                                </p>
                                <p style={{ margin: 0, padding: 0 }}>
                                  {getCode(vehicle.positions, pos)}
                                </p>
                              </td>
                              <td
                                className={getConditionClass(
                                  css,
                                  vehicle.positions,
                                  pos
                                )}
                                style={{ verticalAlign: "middle" }}
                              >
                                {getCondition(vehicle.positions, pos)}
                              </td>
                            </Fragment>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-2">
              <div className="table-responsive">
                <table
                  className={classnames(
                    css.table,
                    "table",
                    "table-sm",
                    "table-hover",
                    "table-bordered"
                  )}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        &nbsp;
                      </th>
                      <th
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        &nbsp;
                      </th>
                      <th
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                        }}
                      >
                        &nbsp;
                      </th>
                    </tr>
                    <tr className="sbgazul">
                      <th>Total O</th>
                      <th>Total R</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicles[visibleStructure].vehicles.map(
                      (vehicle, index) => (
                        <tr key={index} style={{ height: "51.375px" }}>
                          {vehicle.stats
                            .sort(mySort)
                            .filter((stat) => stat[0] !== "odometer")
                            .map((stat) => (
                              <td
                                key={stat[0]}
                                style={{
                                  fontWeight: 500,
                                  verticalAlign: "middle",
                                }}
                              >
                                {stat[1]}
                              </td>
                            ))}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      {visibleStructure == "summary" && (
        <div className="row container-fluid m-0 px-0">
          <div className="col-md-6">
            <div className="row justify-content-between pr-3">
              <div
                className="col-12"
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>
                  {t("_labels.semaphore_report.summary_depth_description")}
                </h4>
              </div>
              {divisionData.map((division) => (
                <div key={division.division} className="col-md-5 mt-2 px-1">
                  <div className="table-responsive">
                    <table
                      className={classnames(
                        css.table,
                        "table",
                        "table-sm",
                        "table-bordered",
                        "m-0"
                      )}
                    >
                      <thead className="text-uppercase">
                        <tr>
                          <th colSpan={3}>
                            {division.division.includes("NO DIVI")
                              ? t("_labels.no_division")
                              : division.division}
                          </th>
                        </tr>
                        <tr
                          className={classnames(css.clickable)}
                          onClick={() => handleClickUnits(division.division)}
                        >
                          <th colSpan={3} style={{ fontWeight: 500 }}>
                            {formatter.format(division.vehicles)}{" "}
                            {t("_labels.semaphore_report.units")} (
                            {formatter.format(division.total)}{" "}
                            {t("_general.tires")})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          className={classnames(css.clickable)}
                          onClick={() =>
                            division.critical > 0
                              ? handleClickDepth(
                                  division.division,
                                  "CRITICAL WITHDRAWAL"
                                )
                              : {}
                          }
                        >
                          <td className={css.rojo}></td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.critical)}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.critical_percentage)}%
                          </td>
                        </tr>
                        <tr
                          className={classnames(css.clickable)}
                          onClick={() =>
                            division.scheduled > 0
                              ? handleClickDepth(
                                  division.division,
                                  "SCHEDULED WITHDRAWAL"
                                )
                              : {}
                          }
                        >
                          <td className={css.amarillo}></td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.scheduled)}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.scheduled_percentage)}%
                          </td>
                        </tr>
                        <tr
                          className={classnames(css.clickable)}
                          onClick={() =>
                            division.good > 0
                              ? handleClickDepth(
                                  division.division,
                                  "GOOD CONDITION"
                                )
                              : {}
                          }
                        >
                          <td className={css.verde}></td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.good)}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.good_percentage)}%
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.total)}
                          </td>
                          <td style={{ width: "28%" }}>100%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-6">
            <div className="row justify-content-between pl-3">
              <div
                className="col-12"
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>
                  {t("_labels.semaphore_report.summary_tire_description")}
                </h4>
              </div>
              {divisionData.map((division) => (
                <div key={division.division} className="col-md-5 mt-2 px-1">
                  <div className="table-responsive">
                    <table
                      className={classnames(
                        css.table,
                        "table",
                        "table-sm",
                        "table-bordered",
                        "m-0"
                      )}
                    >
                      <thead className="text-uppercase">
                        <tr>
                          <th colSpan={3}>
                            {division.division.includes("NO DIVI")
                              ? t("_labels.no_division")
                              : division.division}
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={3} style={{ fontWeight: 500 }}>
                            {formatter.format(division.vehicles)}{" "}
                            {t("_labels.semaphore_report.units")} (
                            {formatter.format(division.total)}{" "}
                            {t("_general.tires")})
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className={css.verde}>Original</td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.originals)}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(
                              division.total > 0
                                ? (division.originals / division.total) * 100
                                : 0
                            )}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td className={css.amarillo}>
                            {t("_labels.renovated")}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.revitalized)}
                          </td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(
                              division.total > 0
                                ? (division.revitalized / division.total) * 100
                                : 0
                            )}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style={{ width: "28%" }}>
                            {formatter.format(division.total)}
                          </td>
                          <td style={{ width: "28%" }}>100%</td>
                        </tr>
                        <tr>
                          <td style={{ border: "none" }}>&nbsp;</td>
                          <td style={{ border: "none" }}></td>
                          <td style={{ border: "none" }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <VehicleDialog
        open={openDialog}
        data={divisionVehicles}
        division={division}
        onClose={() => setOpenDialog(false)}
      />
      <DepthTireDialog
        open={openTireDialog}
        data={tires}
        division={division}
        depth={depth}
        onClose={() => setOpenTireDialog(false)}
      />
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicles: PropTypes.array.isRequired,
  summaryData: PropTypes.array.isRequired,
  divisionData: PropTypes.array,
  visibleStructure: PropTypes.any,
  handleChange: PropTypes.func,
  handleClickUnits: PropTypes.func,
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  division: PropTypes.string,
  divisionVehicles: PropTypes.array,
  handleClickDepth: PropTypes.func,
  openTireDialog: PropTypes.bool,
  setOpenTireDialog: PropTypes.func,
  depth: PropTypes.string,
  tires: PropTypes.array,
};

export default Page;

function getDepth(data, pos) {
  const formatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const position = data.find((el) => el.pos == pos);
  if (position) {
    return formatter.format(position.depth);
  }
  return "";
}

function getCode(data, pos) {
  const position = data.find((el) => el.pos == pos);
  if (position) {
    return position.code;
  }
  return "";
}

function getCondition(data, pos) {
  const position = data.find((el) => el.pos == pos);
  if (position) {
    return position.condition;
  }
  return "";
}
function getClassName(css, data, pos) {
  const position = data.find((el) => el.pos == pos);

  if (position) {
    if (position.depth_condition.includes("GOOD")) {
      return css.verde;
    }

    if (position.depth_condition.includes("SCHEDULE")) {
      return css.amarillo;
    }

    return css.rojo;
  }

  return pos % 2 == 0 ? css.odd : css.even;
}

function getConditionClass(css, data, pos) {
  const position = data.find((el) => el.pos == pos);
  if (position) {
    return pos % 2 == 0
      ? `${css.odd} font-weight-bold`
      : `${css.even} font-weight-bold`;
  }
  return pos % 2 == 0 ? css.odd : css.even;
}

function mySort(a, b) {
  const order = ["original_tires", "revitalized_tires", "total_tires"];

  return order.indexOf(a[0]) - order.indexOf(b[0]);
}
