import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PersonIcon from "@material-ui/icons/Person";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import ContainerTemplate from "components/templates/general/Container";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import FilterForm from "components/organisms/general/FilterForm";
import Form from "components/organisms/general/Form";

import { arrayJoin } from "utils/parse";
import { alphabeticalSort } from "utils/sort";
// import Home from "pages/general/Home";

import css from "./index.module.scss";
import UserRoleForm from "components/organisms/user/UserRoleForm";

function Page({
  user,
  userId,
  users,
  corporates,
  companies,
  subsidiaries,
  createUserPermit,
  updateUserPermit,
  deleteUserPermit,
  filter,
  t,
  getUser,
  addUser,
  updateUser,
  clearUserFilters,
  setUserFilters,
  // deleteUser,
  // setUserStatus,
  // getUsers,
  // clearUsers,
  handleChangeCorporate,
  handleChangeCompany,
  setRequiredPasswordConfirm,
  handleChangePage,
  handleChangeStatus,
  handleDelete,
  handleSearch,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  openForm,
  handleOpenForm,
  handleCloseForm,
  handleEdit,
  openRoleForm,
  handleOpenRoleForm,
  handleCloseRoleForm,
  handleDeleteRole,
  handleEditRole,
  userAssigned,
}) {
  return (
    <ContainerTemplate
      title={t("_general.users")}
      className={classnames({
        [css.empty]: users.data.length === 0,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput
            search={filter.search ? filter.search : ""}
            onChange={handleSearch}
          />
        </>
      }
    >
      {users.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className="bg-dark text-white rounded w-100 m-0 p-0 mb-1 text-center"
              style={{ fontFamily: "Roboto" }}
            >
              <p className="h3 m-0 pt-1">Nota:</p>
              <p className="h5 pt-2">
                Solo se muestran los usuarios de la empresa del area de trabajo
              </p>
            </div>
            <Paper className={classnames(css.w100)}>
              <TableContainer>
                <Table className={classnames(css.table)} size="medium">
                  <TableHead>
                    <TableRow>
                      {[
                        { label: t("_labels.status") },
                        { label: t("_labels.name.singular") },
                        { label: t("_labels.email") },
                        { label: t("_labels.corporate.singular") },
                        { label: t("_labels.company.singular") },
                        { label: <PersonIcon /> },
                        { label: t("_labels.subsidiary.singular") },
                        { label: t("_general.role.label") },
                        { label: "" },
                      ].map((header, index) => (
                        <TableCell
                          key={[header.label, index].join("_")}
                          className={classnames(css.head)}
                        >
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.data.map((user) => (
                      <Fragment key={user.user_id}>
                        <TableRow>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(css.column)}
                          >
                            <Button
                              variant="icon"
                              color="secondary"
                              disabled={!updateUserPermit}
                              tooltip={
                                !updateUserPermit
                                  ? t("_permits.update.user")
                                  : ""
                              }
                              onClick={() => handleChangeStatus(user)}
                            >
                              {user.status ? <DoneIcon /> : <CloseIcon />}
                            </Button>
                          </TableCell>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(css.column)}
                          >
                            {`${user.name} ${user.last_name_1} ${
                              user.last_name_2 || ""
                            }`}
                          </TableCell>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(css.column)}
                          >
                            {user.email}
                          </TableCell>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(css.column)}
                          >
                            {arrayJoin(
                              user.corporates.map(
                                (corporate) => corporate.corporate.name
                              ),
                              ", ",
                              ` ${t("_general.text.and")} `
                            )}
                          </TableCell>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(css.column)}
                          >
                            {arrayJoin(
                              user.companies.map(
                                (company) => company.company.name
                              ),
                              ", ",
                              ` ${t("_general.text.and")} `
                            )}
                          </TableCell>
                          <TableCell
                            rowSpan={user.subsidiaries.length + 1}
                            className={classnames(
                              css.column,
                              css.border_left,
                              css.border_right
                            )}
                          >
                            <>
                              <Button
                                variant="icon"
                                color="secondary"
                                onClick={() => handleEdit(user.user_id)}
                                disabled={!updateUserPermit}
                                tooltip={
                                  !updateUserPermit
                                    ? t("_permits.update.user")
                                    : ""
                                }
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                variant="icon"
                                color="secondary"
                                onClick={() => handleDelete(user.user_id)}
                                disabled={!deleteUserPermit}
                                tooltip={
                                  !deleteUserPermit
                                    ? t("_permits.delete.user")
                                    : ""
                                }
                              >
                                <DeleteIcon />
                              </Button>
                              <Button
                                variant="icon"
                                color="secondary"
                                onClick={() => handleOpenRoleForm(user.user_id)}
                                disabled={!createUserPermit}
                                tooltip={
                                  !createUserPermit
                                    ? t("_permits.create.user")
                                    : `${t("_buttons.new")} ${t(
                                        "_general.role.label"
                                      )}`
                                }
                              >
                                <PlaylistAddOutlinedIcon />
                              </Button>
                            </>
                          </TableCell>
                        </TableRow>
                        {user.subsidiaries
                          .sort(sortSubsidiaries)
                          .map((subsidiary, index) => (
                            <TableRow key={index}>
                              <TableCell className={classnames(css.column)}>
                                {subsidiary.subsidiary.name}
                              </TableCell>
                              <TableCell className={classnames(css.column)}>
                                {subsidiary.role.length > 0
                                  ? t(
                                      `_general.role.${subsidiary.role[0].role.toLowerCase()}`
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell className={classnames(css.column)}>
                                {subsidiary.role.length > 0 && (
                                  <>
                                    <Button
                                      variant="icon"
                                      style={{ color: "#ffa100" }}
                                      className={classnames({
                                        [css.disabled]: !updateUserPermit,
                                      })}
                                      onClick={() =>
                                        handleEditRole(
                                          user.user_id,
                                          subsidiary.role[0]
                                        )
                                      }
                                      disabled={!updateUserPermit}
                                      tooltip={
                                        !updateUserPermit
                                          ? t("_permits.update.user")
                                          : ""
                                      }
                                    >
                                      <EditIcon />
                                    </Button>

                                    <Button
                                      variant="icon"
                                      style={{ color: "#ea3c3c" }}
                                      className={classnames({
                                        [css.disabled]: !deleteUserPermit,
                                      })}
                                      onClick={() =>
                                        handleDeleteRole(
                                          user.user_id,
                                          subsidiary.subsidiary_id,
                                          subsidiary.role[0].role
                                        )
                                      }
                                      disabled={!deleteUserPermit}
                                      tooltip={
                                        !deleteUserPermit
                                          ? t("_permits.delete.user")
                                          : ""
                                      }
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={users.current_page}
              totalPages={users.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_users")} />
      )}

      <FixedButton
        disabled={!createUserPermit}
        tooltip={!createUserPermit ? t("_permits.create.user") : ""}
        onClick={handleOpenForm}
      />

      <Form
        open={openForm}
        item={user}
        id={userId}
        name={"user"}
        initialDefaultValues={{
          name: "",
          last_name_1: "",
          last_name_2: "",
          email: "",
          password: "",
          password_confirm: "",
          corporate_id: "",
          company_id: "",
          need_change_password: false,
          subsidiaries: [
            {
              subsidiary_id: "",
              role: "",
            },
          ],
        }}
        fields={[
          {
            name: "name",
            required: true,
            label: t("_labels.name.special"),
          },
          {
            name: "last_name_1",
            required: true,
            label: t("_labels.last_name_1"),
          },
          {
            name: "last_name_2",
            required: false,
            label: t("_labels.last_name_2"),
          },
          {
            name: "email",
            required: true,
            label: t("_labels.email"),
          },
          {
            name: "password",
            required: "isUpdate",
            autocomplete: "new-password",
            component: "password",
            type: "password",
            label: t("_labels.password"),
          },
          {
            name: "password_confirm",
            required: setRequiredPasswordConfirm,
            requiredInput: "password",
            autocomplete: "off",
            component: "password",
            type: "password",
            label: t("_labels.password_confirm"),
          },
          {
            name: "corporate_id",
            required: false,
            component: "select",
            label: t("_labels.corporate_field.singular.label"),
            selectItems: corporates.map((corporate) => ({
              value: corporate.corporate_id.toString(),
              name: corporate.name,
              disabled: !corporate.status,
            })),
            onChange: handleChangeCorporate,
          },
          {
            name: "company_id",
            required: false,
            component: "select",
            label: t("_labels.company_field.singular.label"),
            selectItems: companies.map((company) => ({
              value: company.company_id.toString(),
              name: company.name,
              disabled: !company.status,
            })),
            onChange: handleChangeCompany,
          },
          {
            name: "subsidiaries",
            title: t("_labels.roles"),
            onlyAdd: true,
            fields: [
              {
                name: "subsidiary_id",
                required: true,
                component: "select",
                label: t("_labels.subsidiary_field.singular.label"),
                selectItems: subsidiaries.map((subsidiary) => ({
                  value: subsidiary.subsidiary_id.toString(),
                  name: subsidiary.name,
                  disabled: !subsidiary.status,
                })),
              },
              {
                name: "role",
                required: true,
                component: "select",
                label: t("_general.role.label"),
                selectItems: [
                  // {
                  //   value: "ROOT",
                  //   name: t("_general.role.root"),
                  // },
                  {
                    value: "MANAGER",
                    name: t("_general.role.manager"),
                  },
                  // {
                  //   value: "CHIEF_OPERATIONS",
                  //   name: t("_general.role.chief_operations"),
                  // },
                  {
                    value: "OPERATOR",
                    name: t("_general.role.operator"),
                  },
                  {
                    value: "WAREHOUSE",
                    name: t("_general.role.warehouse"),
                  },
                  {
                    value: "REPORTS",
                    name: t("_general.role.reports"),
                  },
                  // {
                  //   value: "CHECKLIST_OPERATOR",
                  //   name: t("_general.role.checklist_operator"),
                  // },
                  {
                    value: "DISTRIBUTOR",
                    name: t("_general.role.distributor"),
                  },
                  {
                    value: "TPMS",
                    name: t("_general.tpms"),
                  },
                ].sort(alphabeticalSort("name")),
              },
            ],
          },
          {
            name: "need_change_password",
            required: false,
            component: "checkbox",
            label: t("_labels.change_password"),
          },
        ]}
        title={t("_titles.new.user")}
        updateTitle={t("_titles.update.user")}
        createdMessage={t("_messages.created.user")}
        updatedMessage={t("_messages.updated.user")}
        onClose={handleCloseForm}
        getAction={getUser}
        addAction={addUser}
        updateAction={updateUser}
        clearFiltersAction={clearUserFilters}
      />

      <FilterForm
        fields={{
          status: {
            title: t("_titles.filter.status.user"),
          },
        }}
        initialDefaultValues={{
          status: "",
        }}
        filter={filter}
        setFiltersAction={setUserFilters}
        clearFiltersAction={clearUserFilters}
        onClose={handleCloseFilter}
        open={openFilter}
      />

      <UserRoleForm
        open={openRoleForm}
        onClose={handleCloseRoleForm}
        userId={userId}
        userAssigned={userAssigned}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  createUserPermit: PropTypes.bool.isRequired,
  updateUserPermit: PropTypes.bool.isRequired,
  deleteUserPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  clearUserFilters: PropTypes.func.isRequired,
  setUserFilters: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setUserStatus: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  clearUsers: PropTypes.func.isRequired,
  handleChangeCorporate: PropTypes.func,
  handleChangeCompany: PropTypes.func,
  setRequiredPasswordConfirm: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  openForm: PropTypes.bool.isRequired,
  openRoleForm: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleOpenRoleForm: PropTypes.func.isRequired,
  handleCloseRoleForm: PropTypes.func.isRequired,
  handleDeleteRole: PropTypes.func.isRequired,
  userAssigned: PropTypes.object,
  handleEditRole: PropTypes.func.isRequired,
};

export default Page;

function sortSubsidiaries(a, b) {
  if (a.subsidiary.name > b.subsidiary.name) {
    return 1;
  } else if (b.subsidiary.name > a.subsidiary.name) {
    return -1;
  }
  return 0;
}
