export default {
  axle_information: "Información del eje",
  axle_position: "Posición del eje",
  tire_position: "Posición del neumático",
  axle_applications: "Aplicación de los neumáticos permitidas en el eje",
  actions: "Acciones",
  permits: {
    show_link: {
      tire_with_vehicle:
        "Sin permisos para ver enlaces de neumáticos con vehículos",
    },
    delete_link: {
      tire_with_vehicle: "Sin permisos para desmontar neumáticos de vehículos",
    },
  },
  buttons: {
    wear: "Desgaste",
    mount: "Montar",
    dismount: "Desmontar",
    repair: "Reparar",
    revitalize: "Revitalizar",
    discard: "Desechar",
    tire_mismatch: "¿No coincide el neumático?",
  },
  messages: {
    delete_link_confirmation: "¿Estas seguro de desmontar el neumático?",
  },
};
