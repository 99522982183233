import React from "react";
import { Formik } from "formik";
import classnames from "classnames";
import PropTypes from "prop-types";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  tireModels,
  initialErrors,
  initialValues,
  onClose,
  handleClean,
  handleSubmit,
  handleValidate,
}) {
  return (
    <DialogMediaQuery maxWidth="md" size="sm" open={open} onClose={onClose}>
      <DialogTitle>{t("_general.filter")}</DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={handleValidate}
        >
          {(p) => (
            <Form
              onClose={onClose}
              handleClean={handleClean}
              tireModels={tireModels}
              {...p}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tireModels: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
