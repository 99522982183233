import React from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import classnames from "classnames";

import ContainerTemplate from "components/templates/general/Container";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import TireIcon from "components/atoms/general/TireIcon";
import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterTireRevitalizedForm from "components/organisms/report/FilterTireRevitalizedForm";

import css from "./index.module.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  chartData,
  detailsData,
  showDetails,
  tableRef,
  filter,
  selectedFilter,
  openFilter,
  handleClickProvider,
  handleOpenFilter,
  handleCloseFilter,
}) {
  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: true,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: t("_general.tires"),
        },
      },
      y1: {
        min: 0,
        stacked: false,
        position: "right",
        visible: "auto",
        title: {
          display: true,
          text: t("_general.days"),
        },
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          displayFormats: {
            month: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const defaultQuarterOptions = {
    responsive: true,
    maintainAspectRatio: true,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: t("_general.tires"),
        },
      },
      y1: {
        min: 0,
        stacked: false,
        position: "right",
        visible: "auto",
        title: {
          display: true,
          text: t("_general.days"),
        },
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "quarter",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  return (
    <ContainerTemplate
      title={t("_titles.tire_revitalized_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pt-2">
        <div className="container-fluid py-2">
          {Object.keys(filter).length > 0 && (
            <div className="row pt-2 px-0">
              <div className="col-md-6">
                <table className="table table-sm">
                  <tbody>
                    {filter.corporate_id && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.corporate.singular")}:
                        </td>
                        <td>{selectedFilter.corporate}</td>
                      </tr>
                    )}
                    {filter.companies && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.company.singular")}:
                        </td>
                        <td>{selectedFilter.companies}</td>
                      </tr>
                    )}

                    {filter.subsidiaries && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.subsidiary.singular")}:
                        </td>
                        <td>{selectedFilter.subsidiaries}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table">
                  <tbody>
                    {filter.date_from && filter.date_to && (
                      <>
                        <tr>
                          <td className="text-right sbgazul">
                            {t("_labels.date.from")}:
                          </td>
                          <td>
                            {moment(selectedFilter.date_from).format("LL")}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right sbgazul">
                            {t("_labels.date.to")}:
                          </td>
                          <td>{moment(selectedFilter.date_to).format("LL")}</td>
                        </tr>
                      </>
                    )}
                    {filter.providers && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_general.providers")}:
                        </td>
                        <td>{selectedFilter.providers}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="text-center pb-2">
            <p className="m-0 p-0 h4">
              {t("_labels.tire_revitalized_report.title")}
            </p>
          </div>

          {data.length > 0 ? (
            data.map(([subsidiary, providers]) => (
              <div key={subsidiary} className="card mb-2">
                <div className="card-header text-center bg-dark text-white">
                  <div
                    className="h5 m-0 d-flex justify-content-center align-items-center"
                    style={{ gap: "6px" }}
                  >
                    {subsidiary}
                    <div className="d-flex align-items-stretch">
                      <TireIcon />
                      <span className={classnames(css.dato_a)}>
                        {formatter.format(
                          providers.reduce(
                            (acc, [, val]) => acc + val["statistics"],
                            0
                          )
                        )}
                      </span>
                      <span className={classnames(css.dato_b)}>
                        {formatter.format(100)}%
                      </span>
                    </div>
                    <div className="d-flex align-items-stretch">
                      <WatchLaterIcon />
                      <span className={classnames(css.dato_a)}>
                        {t("_labels.tire_revitalized_report.time")}
                      </span>
                      <span className={classnames(css.dato_b)}>
                        {formatter.format(
                          providers.reduce(
                            (acc, [, val]) =>
                              acc + val["average_days_revitalized"],
                            0
                          ) / providers.length
                        )}{" "}
                        {t("_general.days")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className={classnames(css.container)}>
                    <div className="d-flex flex-direction-column justify-conten-center align-items-center"></div>
                    <div className={classnames(css.alert_container)}>
                      {providers.map(([provider, info]) => (
                        <div
                          key={info["provider_id"]}
                          className={classnames({
                            [css.alert]: true,
                          })}
                          onClick={() =>
                            handleClickProvider({ ...info, name: provider })
                          }
                        >
                          <div>
                            <span className={classnames(css.alert_code)}>
                              <AccountCircleIcon />
                              {provider}
                            </span>
                          </div>
                          <div
                            className="w-100 d-flex justify-content-between align-items-center"
                            style={{ gap: "6px" }}
                          >
                            <div>
                              <TireIcon />
                              <span className={classnames(css.dato_a)}>
                                {formatter.format(info["statistics"])}
                              </span>
                              <span className={classnames(css.dato_b)}>
                                {formatter.format(info["percent"])}%
                              </span>
                            </div>
                            <div>
                              <WatchLaterIcon />
                              <span className={classnames(css.dato_a)}>
                                {t("_labels.tire_revitalized_report.time")}
                              </span>
                              <span className={classnames(css.dato_b)}>
                                {formatter.format(
                                  info["average_days_revitalized"]
                                )}{" "}
                                {t("_general.days")}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyListIcon text={t("_labels.no_results")} />
          )}

          {showDetails && Object.keys(chartData).length > 0 && (
            <div className={classnames(css.data_container)}>
              {Object.keys(chartData).length > 0 && (
                <div className="mt-2 card" style={{ width: "47%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_titles.historic")}
                  </div>
                  <div className="card-body">
                    <Line
                      options={
                        chartData.datasets[0].data.length >= 8
                          ? defaultQuarterOptions
                          : defaultOptions
                      }
                      data={chartData}
                    />
                  </div>
                </div>
              )}

              {detailsData.length > 0 && (
                <div className="mt-2 card" style={{ width: "52%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_general.tires")}
                  </div>
                  <div className="w-100 card-body">
                    <div
                      className={classnames("table-responsive", css.table_wrap)}
                      ref={tableRef}
                    >
                      <table className={classnames("table", css.tabla)}>
                        <thead>
                          <tr
                            className={classnames({
                              "text-center": true,
                            })}
                            style={{ backgroundColor: "rgb(0, 40, 73, 0.92)" }}
                          >
                            <th colSpan={4}>
                              {detailsData[0]["provider_name"]}
                            </th>
                          </tr>
                          <tr className="bg-dark text-white font-weight-normal">
                            <th>{t("_labels.date_send")}</th>
                            <th>{t("_general.days")}</th>
                            <th>{t("_labels.code")}</th>
                            <th>{t("_labels.subsidiary.singular")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailsData.map((tire, index) => (
                            <tr key={`tire_${index}`}>
                              <td>{`${moment(tire["date_send"]).format(
                                "LL"
                              )}`}</td>
                              <td>
                                {moment().diff(
                                  moment(tire["date_send"]).format(
                                    "YYYY-MM-DD"
                                  ),
                                  "days"
                                )}
                              </td>
                              <td>{tire["code"]}</td>
                              <td>{tire["subsidiary_tire_name"]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <FilterTireRevitalizedForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
  chartData: PropTypes.object,
  detailsData: PropTypes.array,
  showDetails: PropTypes.bool,
  filter: PropTypes.object,
  selectedFilter: PropTypes.object,
  tableRef: PropTypes.object,
  openFilter: PropTypes.bool,
  handleClickProvider: PropTypes.func,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
};

export default Page;
