import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Formik } from "formik";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Form from "./Form";
import css from "./index.module.scss";

export function Page({
  t,
  open,
  commissionedVehicle,
  commissionedDrivers,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
  onClose,
}) {
  return (
    <DialogMediaQuery size={"md"} open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>
        {!!commissionedVehicle
          ? t("_titles.link.vehicle_with_driver", {
              economic_number: commissionedVehicle.economic_number,
            })
          : "Reasignar conductor"}
      </DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <Formik
          initialValues={initialValues}
          initialErrors={initialErrors}
          validate={handleValidate}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {(p) => (
            <Form
              {...p}
              values={initialValues}
              commissionedDrivers={commissionedDrivers}
              onClose={onClose}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  commissionedVehicle: PropTypes.object,
  commissionedDrivers: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
