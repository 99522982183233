export default {
  form: {
    tire_model_status: "Tire model status",
    labels: {
      brand_field: {
        label: "Brands",
        helper_text: "You can select many brands",
      },
    },
  },
};
