import { http } from "store/actions/http";
import { ASSIGN_VEHICLE_TO_GPS } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAssignVehicleToGps =
  (dispatch) =>
  async ({ gpsId, vehicleId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `gps/${gpsId}/vehicle/${vehicleId}`,
      });

      dispatch({
        type: ASSIGN_VEHICLE_TO_GPS,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
