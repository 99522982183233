import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTires } from "store/actions/tire/getTires";
import { assignRfidToTire } from "store/actions/tire/rfid/assignRfidToTire";
import { actionClearRfidFilters } from "store/actions/rfid/clearRfidFilters";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function LinkTireForm({ open, onClose, id, ...rest }) {
  const t = useLang();
  const { setNotification, getTires, assignRfidToTire, clearRfidFilters } =
    rest;
  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      tire_id: "",
    },
  });

  const [tires, setTires] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTires(
          {
            with_link_rfid: 0,
            scope: "tire_id,code,status",
          },
          false
        );

        setTires(data.sort(alphabeticalSort("code")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && id) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    const tireId = values.tire_id;

    try {
      await assignRfidToTire(id, tireId);
      setNotification({
        message: t("_messages.linked.tire_with_rfid"),
      });
      clearRfidFilters();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      tires={tires}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkTireForm.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTires: actionGetTires(dispatch),
  assignRfidToTire: assignRfidToTire(dispatch),
  clearRfidFilters: actionClearRfidFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkTireForm);
