import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CREATE_TIRE,
  UPDATE_TIRE,
  DELETE_TIRE,
  CREATE_TIRE_REVITALIZATION,
} from "store/actions/account/permits";

import { actionGetTires } from "store/actions/tire/getTires";
import { actionSetNotification } from "store/actions/general/notification";
import { actionClearTires } from "store/actions/tire/clearTires";
import { actionSetTireFilters } from "store/actions/tire/setTireFilters";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";
import { actionDeleteTire } from "store/actions/tire/deleteTire";
import { actionSetTireStatus } from "store/actions/tire/setTireStatus";
import { actionSetTireSelectedFilters } from "store/actions/tire/setTireSelectedFilters";
import { useLang } from "hooks/lang";

import Page from "./page";

export function HomeTire({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const [openForm, setOpenForm] = useState(false);
  const [openSimilarForm, setOpenSimilarForm] = useState(false);
  const [openSendForm, setOpenSendForm] = useState(false);
  const [similarTires, setSimilarTires] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openExcelForm, setOpenExcelForm] = useState(false);
  const [tireValues, setTireValues] = useState({});
  const [tireId, setTireId] = useState(null);
  const [to, setTo] = useState("");
  const [movementTireId, setMovementTireId] = useState(null);
  const [movement, setMovement] = useState(null);
  const [tireActionId, setTireActionId] = useState(null);
  const [openDamagesForm, setOpenDamagesForm] = useState(false);
  const [tire, setTire] = useState({
    code: "",
  });
  const [openMoveTireForm, setOpenMoveTireForm] = useState(false);
  const [isRegisterForm, setIsRegisterForm] = useState(false);

  const [openBulkPileForm, setOpenBulkPileForm] = useState(false);
  const [openRegisterPileForm, setOpenRegisterPileForm] = useState(false);

  const {
    filter,
    permits,
    tires,
    getTires,
    clearTires,
    actionDelete,
    setTireStatus,
    setTireFilters,
    clearTireFilters,
    setNotification,
    selectedFilter,
    setSelectedFilters,
  } = rest;

  const previousFilter = useRef(null);

  useEffect(() => {
    const previous = { ...previousFilter.current };
    const current = { ...filter };
    const previousIsEmpty = Object.keys(previous).length === 0;
    delete previous.page;
    delete current.page;
    previousFilter.current = filter;

    if (
      !previousIsEmpty &&
      JSON.stringify(previous) !== JSON.stringify(current)
    ) {
      setTireFilters({ page: 1 });
      return;
    }

    async function fetchData() {
      try {
        await getTires({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearTires();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [tires.data]);

  function onChangePage(page) {
    setTireFilters({ page: page });
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setTireId(null);
  }

  function handleOpenSimilarForm(similarTires, fields) {
    setSimilarTires(similarTires);
    setTireValues(fields);
    setOpenSimilarForm(true);
  }

  function handleCloseSimilarForm() {
    setOpenSimilarForm(false);
    setSimilarTires(1);
    setTireValues({});
    setIsRegisterForm(false);
  }

  function handleEdit(tireId) {
    setTireId(tireId);
    setOpenForm(true);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setTireFilters({ search });
  }

  function handleDelete(tireId) {
    setNotification({
      message: t("_messages.delete.tire"),
      onAction: () => deleteTire(tireId),
      textAction: t("_messages.confirm"),
    });
  }

  async function deleteTire(tireId) {
    try {
      const response = await actionDelete(tireId);
      setNotification({
        message: response.message,
        code: response.code,
      });
      clearTireFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(tire) {
    setNotification({
      message: t(
        `_messages.change_status.tire.${tire.status ? "disable" : "enable"}`
      ),
      onAction: () => changeStatus(tire.id, tire.status),
      textAction: t("_messages.confirm"),
    });
  }

  async function changeStatus(tireId, status) {
    try {
      const response = await setTireStatus(tireId, status ? 0 : 1);
      setNotification({
        message: response.message,
        code: response.code,
      });
      clearTireFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenDialog(tireId) {
    setTireId(tireId);
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setTireId(null);
    setOpenDialog(false);
  }

  function handleOpenSendForm(
    movementTireId,
    to,
    movement = null,
    tireActionId
  ) {
    setTo(to);
    setMovementTireId(movementTireId);
    setMovement(movement);
    setTireActionId(tireActionId);
    setOpenSendForm(true);
  }

  function handleCloseSendForm() {
    setTo("");
    setMovementTireId(null);
    setMovement(null);
    setTireActionId(null);
    setOpenSendForm(false);
  }

  function handleOpenExcelForm() {
    setOpenExcelForm(true);
  }

  function handleCloseExcelForm() {
    setOpenExcelForm(false);
  }

  function handleOpenBulkPileForm() {
    setOpenBulkPileForm(true);
  }

  function handleCloseBulkPileForm() {
    setOpenBulkPileForm(false);
  }

  function handleOpenRegisterPileForm() {
    setOpenRegisterPileForm(true);
  }

  function handleCloseRegisterPileForm() {
    setOpenRegisterPileForm(false);
  }

  function onHistory(tireId) {
    history.push(`/tire/${tireId}/history`);
  }

  function handleOpenDamagesForm(tire) {
    setTire(tire);
    setOpenDamagesForm(true);
  }

  function handleCloseDamagesForm() {
    setOpenDamagesForm(false);
    setTire(null);
  }

  function handleOpenMoveTireForm(movementTireId) {
    setMovementTireId(movementTireId);
    setOpenMoveTireForm(true);
  }

  function handleCloseMoveTireForm() {
    setMovementTireId(null);
    setOpenMoveTireForm(false);
  }

  return (
    <Page
      t={t}
      to={to}
      search={filter.search}
      tires={tires}
      tireId={tireId}
      movementTireId={movementTireId}
      movement={movement}
      tireActionId={tireActionId}
      openForm={openForm}
      openSendForm={openSendForm}
      openFilter={openFilter}
      openDialog={openDialog}
      openSimilarForm={openSimilarForm}
      openExcelForm={openExcelForm}
      similarTires={similarTires}
      tireValues={tireValues}
      createTooltipPermit={
        !permits.includes(CREATE_TIRE) ? t("_permits.create.tire") : ""
      }
      updateTooltipPermit={
        !permits.includes(UPDATE_TIRE) ? t("_permits.update.tire") : ""
      }
      deleteTooltipPermit={
        !permits.includes(DELETE_TIRE) ? t("_permits.delete.tire") : ""
      }
      createRevitalizationTooltipPermit={
        !permits.includes(CREATE_TIRE_REVITALIZATION)
          ? t("_permits.create.tire_revitalization")
          : ""
      }
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleOpenSimilarForm={handleOpenSimilarForm}
      handleCloseSimilarForm={handleCloseSimilarForm}
      handleEdit={handleEdit}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
      handleChangeStatus={handleChangeStatus}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
      handleOpenSendForm={handleOpenSendForm}
      handleCloseSendForm={handleCloseSendForm}
      handleOpenExcelForm={handleOpenExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      onHistory={onHistory}
      openDamagesForm={openDamagesForm}
      handleOpenDamagesForm={handleOpenDamagesForm}
      handleCloseDamagesForm={handleCloseDamagesForm}
      tire={tire}
      openMoveTireForm={openMoveTireForm}
      handleOpenMoveTireForm={handleOpenMoveTireForm}
      handleCloseMoveTireForm={handleCloseMoveTireForm}
      setIsRegisterForm={setIsRegisterForm}
      isRegisterForm={isRegisterForm}
      selectedFilter={selectedFilter}
      setSelectedFilters={setSelectedFilters}
      openBulkPileForm={openBulkPileForm}
      openRegisterPileForm={openRegisterPileForm}
      handleOpenBulkPileForm={handleOpenBulkPileForm}
      handleOpenRegisterPileForm={handleOpenRegisterPileForm}
      handleCloseBulkPileForm={handleCloseBulkPileForm}
      handleCloseRegisterPileForm={handleCloseRegisterPileForm}
    />
  );
}

HomeTire.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  tires: state.Tire.Tire.tires,
  filter: state.Tire.Tire.filter_tire,
  selectedFilter: state.Tire.Tire.selected_filter,
});
const mapDispatchToProps = (dispatch) => ({
  getTires: actionGetTires(dispatch),
  clearTires: actionClearTires(dispatch),
  setNotification: actionSetNotification(dispatch),
  setTireFilters: actionSetTireFilters(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
  actionDelete: actionDeleteTire(dispatch),
  setTireStatus: actionSetTireStatus(dispatch),
  setSelectedFilters: actionSetTireSelectedFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTire);
