import {
  GET_VEHICLE_AXLES,
  CLEAR_VEHICLE_AXLES,
  DELETE_LINK_TIRE_WITH_VEHICLE,
} from "store/actions";

const initialState = {
  vehicle_axles: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_AXLES:
      return {
        ...state,
        // vehicle_axles: action.payload.axles.map((axle) => ({
        //   color: axle.color,
        //   position: axle.position,
        //   tire_quantity: axle.tire_quantity_count,
        //   tire_application: axle.tire_application,
        //   vehicle_type_axle_id: axle.vehicle_type_axle_id,
        //   vehicle_type_axle_tire: axle.vehicle_type_axle_tire.map(
        //     (axleTire) => ({
        //       ...axleTire,
        //       tire: action.payload.tires.find(
        //         (tire) =>
        //           tire.vehicle_type_axle_tire_id ===
        //           axleTire.vehicle_type_axle_tire_id
        //       ),
        //     })
        //   ),
        // })),
      };

    case CLEAR_VEHICLE_AXLES:
      return {
        ...state,
        // vehicle_axles: [...initialState.vehicle_axles],
      };

    case DELETE_LINK_TIRE_WITH_VEHICLE:
      return {
        ...state,
        vehicle_axles: state.vehicle_axles.map((axle) => ({
          ...axle,
          vehicle_type_axle_tire: axle.vehicle_type_axle_tire.map(
            (axleTire) => ({
              ...axleTire,
              tire:
                !!axleTire.tire &&
                axleTire.tire.vehicle_tire_id != action.payload
                  ? axleTire.tire
                  : null,
            })
          ),
        })),
      };

    default:
      return state;
  }
}
