export default {
  confirm: "Confirm",
  delete: {
    corporate: "Are you sure to delete the corporate?",
    division: "Are you sure to delete the division?",
    brand: "Are you sure to delete the brand?",
    size: "Are you sure to delete the size?",
    company: "Are you sure to delete the company?",
    subsidiary: "Are you sure to delete the subsidiary?",
    association: "Are you sure to delete the association?",
    provider: "Are you sure to delete the provider?",
    wear: "Are you sure to delete the wear?",
    driver: "Are you sure to delete the driver?",
    warehouse: "Are you sure to delete the warehouse?",
    retirement_cause: "Are you sure to delete the retirement cause?",
    gps: "Are you sure to delete the gps?",
    damage: "Are you sure to delete the damage?",
    alert: "Are you sure to delete the alert?",
    rfid: "Are you sure to delete the rfid?",
    vehicle_type: "Are you sure to delete the vehicle type?",
    link_tire_with_warehouse:
      "Are you sure to take the tire out of the warehouse?",
    link_user_with_corporate: "Are you sure to unlink user from corporate?",
    link_user_with_company: "Are you sure to unlink user from company?",
    link_user_with_subsidiary: "Are you sure to unlink user from subsidiary?",
    link_subsidiary: "Are you sure to unlink the subsidiary?",
    link_vehicle: "Are you sure to unlink the vehicle?",
    link_driver: "Are you sure to unlink the driver?",
    link_warehouse: "Are you sure to unlink the warehouse?",
    link_tire: "Are you sure to unlink the tire?",
    link_vehicle: "Are you sure to unlink the vehicle?",
    tire_wear: "Are you sure to delete the tire wear?",
    user: "Are you sure to delete the user?",
    user_role: "Are you sure to delete the user role?",
    tire_model: "Are you sure to delete the tire model?",
    revitalized_tire_model:
      "Are you sure to delete the revitalized tire model?",
    vehicle: "Are you sure to delete the vehicle?",
    condition_policy: "Are you sure to delete the condition policy?",
    pressure_policy: "Are you sure to delete the pressure policy?",
    depth_policy: "Are you sure to delete the depth policy?",
    tire: "Are you sure to delete the tire?",
    model_pressure_policy:
      "Are you sure to delete the pressure policy by model?",
    helmet_value: "Are you sure to delete the helmet value?",
    vehicle_review: "Are you sure to delete the vehicle review?",
    concept: "Are you sure to delete the concept?",
    block: "Are you sure to delete the block?",
    checklist: "Are you sure to delete the checklist?",
    tpms: "Are you sure to delete the tpms sensor?",
  },
  deleted: {
    link_tire_with_warehouse:
      "Done, the tire was successfully taken out of the warehouse",
    link_user_with_corporate:
      "Done, the user has been unlinked from the corporate",
    link_user_with_company: "Done, the user has been unlinked from the company",
    link_user_with_subsidiary:
      "Done, the user has been unlinked from the subsidiary",
    link_vehicle: "Done, the vehicle has been unlinked",
    link_driver: "Done, the driver has been unlinked",
    link_warehouse: "Done, the warehouse has been unlinked",
    link_tire: "Done, the tire has been unlinked",
    tire_wear: "Done, tire wear was successfully deleted",
    link_subsidiary: "Done, the subsidiary has been unlinked",
    condition_policy: "Done, the condition policy has been eliminated",
    pressure_policy: "Done, the pressure policy has been successfully deleted",
    depth_policy: "Done, the depth policy has been successfully deleted",
    model_pressure_policy:
      "Done, the pressure policy by model has been successfully deleted",
    helmet_value: "Done, the helmet value has been successfully deleted",
    vehicle_review: "Done, the vehicle review has been successfully deleted",
    tpms: "Done, the tpms sensor has been successfully deleted",
  },
  update: {
    same_vehicle: "The vehicle is the same as above",
    vehicle_review_policy_denied:
      "The subsidiary policies do not allow you to edit an inspection",
    tire_review_policy_denied:
      "The subsidiary policies do not allow you to edit a tire inspection",
    vehicle_review:
      "Are you sure to update the vehicle review with value {odometer}",
  },
  updated: {
    user_roles: "Done, the roles has been updated",
    tire_wear: "Done, the tire wear has been updated",
    vehicle_changed: "Done, the vehicle has been successfully changed.",
    corporate: "Done, corporate has been successfully updated",
    brand: "Done, brand has been successfully updated",
    size: "Done, size has been successfully updated",
    company: "Done, company has been successfully updated",
    division: "Done, division has been successfully updated",
    subsidiary: "Done, subsidiary has been successfully updated",
    association: "Done, association has been successfully updated",
    provider: "Done, provider has been successfully updated",
    wear: "Done, wear has been successfully updated",
    driver: "Done, driver has been successfully updated",
    user: "Done, the user has been successfully updated",
    user_role: "Done, the user role has been successfully updated",
    warehouse: "Done, warehouse has been successfully updated",
    retirement_cause: "Done, retirement cause has been successfully updated",
    gps: "Done, gps has been successfully updated",
    damage: "Done, damage has been successfully updated",
    revitalized_tire_model:
      "Done, revitalized tire model has been successfully updated",
    alert: "Done, alert has been successfully updated",
    rfid: "Done, the rfid has been successfully updated",
    vehicle_type: "Done, the vehicle type has been successfully updated",
    company_policy: "Done, the policies has been successfully updated",
    tire_model: "Done, the tire model has been successfully updated",
    vehicle: "Done, the vehicle has been successfully updated",
    policy: "Done, the condition policy has been successfully updated",
    pressure_policy: "Done, the pressure policy has been successfully updated",
    depth_policy: "Done, the depth policy has been successfully updated",
    tire: "Done, tire has been successfully updated",
    model_pressure_policy:
      "Done, the pressure policy by model has been successfully updated",
    tire_pressure: "Done, the tire pressures have been successfully updated",
    tire_review: "Done, the tire review has been successfully updated",
    vehicle_review: "Done, the vehicle review has been successfully updated",
    helmet_value: "Done, the helmet value has been successfully updated",
    concept: "Done, the concept has been successfully updated",
    block: "Done, the block has been successfully updated",
    checklist: "Done, the checklist has been successfully updated",
    tpms: "Done, the tpms sensor has been successfully updated",
    temperature_policy:
      "Done, the temperature policy has been successfully updated",
  },
  created: {
    alert: "Done, the alert has been successfully created",
    corporate: "Done, corporate has been successfully created",
    brand: "Done, brand has been successfully created",
    size: "Done, size has been successfully created",
    company: "Done, company has been successfully created",
    division: "Done, division has been successfully created",
    subsidiary: "Done, subsidiary has been successfully created",
    association: "Done, association has been successfully created",
    provider: "Done, provider has been successfully created",
    driver: "Done, driver has been successfully created",
    user: "Done, user has been successfully created",
    user_role: "Done, a user role has been successfully assigned",
    warehouse: "Done, warehouse has been successfully created",
    wear: "Done, wear has been successfully created",
    gps: "Done, gps has been successfully created",
    damage: "Done, damage has been successfully created",
    revitalized_tire_model:
      "Done, revitalized tire model has been successfully created",
    alert: "Done, alert has been successfully created",
    retirement_cause: "Done, retirement cause has been successfully created",
    rfid: "Done, rfid has been successfully created",
    tire_model: "Done, tire model has been successfully created",
    vehicle_type: "Done, vehicle type has been successfully created",
    vehicle: "Done, vehicle has been successfully created",
    policy: "Done, condition policy has been successfully created",
    pressure_policy: "Done, the pressure policy has been successfully created",
    depth_policy: "Done, the depth policy has been successfully created",
    tire: "Done, tire has been successfully created",
    tires: "Done, the tires has been successfully created",
    vehicle_review: "Done, the vehicle review has been successfully created",
    model_pressure_policy:
      "Done, the pressure policy by model has been successfully created",
    tire_damage: "Done, the damage(s) have been successfully assigned to tire",
    tire_wear: "Done, the wear have been successfully assigned to tire",
    tire_review: "Done, the tire review has been successfully created",
    helmet_value: "Done, the helmet value has been successfully created",
    concept: "Done, the concept has been successfully created",
    block: "Done, the block has been successfully created",
    checklist: "Done, the checklist has been successfully created",
    notification: "Done, the notification has been successfully created",
    tpms: "Done, the tpms sensor has been successfully created",
  },
  change_status: {
    corporate: {
      enable: "Are you sure to enable the corporate?",
      disable: "Are you sure to disable the corporate?",
    },
    brand: {
      enable: "Are you sure to enable the brand?",
      disable: "Are you sure to disable the brand?",
    },
    size: {
      enable: "Are you sure to enable the size?",
      disable: "Are you sure to disable the size?",
    },
    company: {
      enable: "Are you sure to enable the company?",
      disable: "Are you sure to disable the company?",
    },

    division: {
      enable: "Are you sure to enable the division?",
      disable: "Are you sure to disable the division?",
    },
    subsidiary: {
      enable: "Are you sure to enable the subsidiary?",
      disable: "Are you sure to disable the subsidiary?",
    },
    association: {
      enable: "Are you sure to enable the association?",
      disable: "Are you sure to disable the association?",
    },
    provider: {
      enable: "Are yoy sure to enable the provider?",
      disable: "Are you sure to disable the provider?",
    },
    wear: {
      enable: "Are you sure to enable the wear?",
      disable: "Are you sure to disable the wear?",
    },
    driver: {
      enable: "Are you sure to enable the driver?",
      disable: "Are you sure to disable the driver?",
    },
    user: {
      enable: "Are you sure to enable the user?",
      disable: "Are you sure to disable the user?",
    },
    warehouse: {
      enable: "Are you sure to enable the warehouse?",
      disable: "Are you sure to disable the warehouse?",
    },
    retirement_cause: {
      enable: "Are you sure to enable the retirement cause?",
      disable: "Are you sure to disable the retirement cause?",
    },
    gps: {
      enable: "Are you sure to enable the gps?",
      disable: "Are you sure to disable the gps?",
    },
    damage: {
      enable: "Are you sure to enable the damage?",
      disable: "Are you sure to disable the damage?",
    },
    revitalized_tire_model: {
      enable: "Are you sure to enable the revitalized tire model?",
      disable: "Are you sure to disable the revitalized tire model?",
    },
    alert: {
      enable: "Are you sure to enable the alert?",
      disable: "Are you sure to disable the alert?",
    },
    rfid: {
      enable: "Are you sure to enable the rfid?",
      disable: "Are you sure to disable the rfid?",
    },
    tire_model: {
      enable: "Are you sure to enable the tire model?",
      disable: "Are you sure to disable the tire model?",
    },
    vehicle_type: {
      enable: "Are you sure to enable the vehicle type?",
      disable: "Are you sure to disable the vehicle type?",
    },
    vehicle: {
      enable: "Are you sure to enable the vehicle?",
      disable: "Are you sure to disable the vehicle?",
    },
    tire: {
      enable: "Are you sure to enable the tire?",
      disable: "Are you sure to disable the tire?",
    },
    tpms: {
      enable: "Are you sure to enable the tpms sensor?",
      disable: "Are you sure to disable the tpms sensor?",
    },
  },
  change_approved: {
    brand: {
      approve: "Are you sure to approve the brand?",
      disapprove: "Are you sure to disapprove the brand?",
    },
    model: {
      approve: "Are you sure to approve the model?",
      disapprove: "Are you sure to disapprove the model?",
    },
    size: {
      approve: "Are you sure to approve the size?",
      disapprove: "Are you sure to disapprove the size?",
    },
  },
  reset: {
    odometer: "Are you sure to reset odometer?",
    difference: "Are you sure to reset gps difference?",
  },
  sent: {
    revitalization: "Done, the tire has been sent to retread",
    repair: "Done, the tire has been sent to repair",
    pile: "Done, the tire has been sent to pile",
    warehouse: "Done, the tire has been sent to warehouse",
    multiple_to_warehouse: "Done, the tires has been sent to warehouse",
  },
  linked: {
    tire_with_rfid: "Done, the rfid has been successfully linked to tire",
    gps_with_vehicle: "Done, the gps has been successfully linked to vehicle",
    vehicle_with_driver:
      "Done, the driver has been successfully linked to vehicle",
    tpms_with_tire:
      "Done, the tpms sensor has been successfully linked to tire",
  },
  mounted: "Done, the tire has been successfully mounted",
  finalize: "Are you sure to finalize the review?",
  finalized: "Done, the vehicle review has been successfully finalized",
  physical_difference: "Are you sure to report the tire mismatch?",
  pressure_adjustment: "Are you sure to pressure adjustment?",
  pressure_adjusted: "Done, the pressures have been successfully adjusted",
  identification_completed:
    "Done, the tires identification has been successfully completed",
  rotate:
    "Exchange the tire from position {position} with the one from position {position2}",
  single_rotate:
    "Exchange the tire from position {position} to position {position2}",
  cycle:
    "It is no longer possible to renew the tire, the cycles defined in the policy have already been completed",
  cancel_movement: "Are yo sure to cancel the current movement?",
  notice_cancel_movement:
    "The current movement is canceled and the tire will go to the storage of canceled movements",
  out_of_time_review:
    "24 hours or more have passed since the last review, to make movements you must register a new",
  travel_confirm:
    "The travel of the vehicle will be {value} km, are you sure to continue?",
  vehicle_not_found: "The vehicle {vehicle} was not found",
  vehicle_in_process:
    "The vehicle {vehicle} has an inspection in process and must be completed, to enable assembly",
  copied_to_clipboard: "Copied to clipboard",
};
