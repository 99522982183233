export default {
  title: "Neumáticos vinculados",
  form_title: "Vincular neumático",
  form: {
    permits: {
      list: {
        tire: "Sin permisos para ver listados de neumáticos",
      },
    },
  },
  permits: {
    link: {
      division_tire: "Sin permisos para vincular neumáticos con divisiones",
    },
    show_division_tire: "Sin permisos para ver neumáticos vinculados",
    unlink_division_tire:
      "Sin permisos para desvincular neumáticos de divisiones",
  },
  messages: {
    delete: "¿Estas seguro de desvincular el neumático?",
    delete_division_tire: "Listo, se desvinculó el neumático de la división",
    linked_division_tire: "Listo, el neumático ha sido vinculado con éxito",
  },
};
