import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetTireSummaryFilters } from "store/actions/reports/summary/setTireSummaryFilters";
import { actionClearTireSummaryFilters } from "store/actions/reports/summary/clearTireSummaryFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetSelectedSummaryFilters } from "store/actions/reports/summary/setSelectedTireSummaryFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";
import { REVIEW_TYPES } from "utils/review";

export function FilterTireSummaryForm({
  open,
  onClose,
  isPile,
  current,
  ...rest
}) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setTireSummaryFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      date_from: "",
      date_to: "",
      movement: "MOUNT",
      with_refection: false,
      depth_condition: "",
      pressure_condition: "",
      activity: "default",
      review_type: "default",
      number_cycle: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [movements, setMovements] = useState([
    {
      value: "WAREHOUSE",
      label: t(`_labels.report_summary.location.warehouse`),
    },
    { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
    { value: "BOTH", label: t("_labels.report_summary.location.both") },
  ]);
  const [depthConditions, setDepthConditions] = useState([]);
  const [pressureConditions, setPressureConditions] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);

        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
        setMovements([
          {
            value: "WAREHOUSE",
            label: t(`_labels.report_summary.location.warehouse`),
          },
          { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
          { value: "BOTH", label: t("_labels.report_summary.location.both") },
        ]);
        setDepthConditions([
          { value: "GOOD CONDITION", label: t("_labels.good_condition") },
          {
            value: "SCHEDULED WITHDRAWAL",
            label: t("_labels.scheduled_withdrawal"),
          },
          {
            value: "CRITICAL WITHDRAWAL",
            label: t("_labels.critical_withdrawal"),
          },
        ]);
        setPressureConditions([
          {
            value: "NO PRESSURE",
            label: t("_labels.summary_report.pressure_condition.no_pressure"),
          },
          {
            value: "NORMAL",
            label: t("_labels.summary_report.pressure_condition.normal"),
          },
          {
            value: "HIGH",
            label: t("_labels.summary_report.pressure_condition.high"),
          },
          {
            value: "LOW",
            label: t("_labels.summary_report.pressure_condition.low"),
          },
        ]);

        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.company_id ? filter.company_id.split(",") : [],
        subsidiary_id: filter.subsidiary_id
          ? filter.subsidiary_id.split(",")
          : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        movement: filter.movement || "MOUNT",
        with_refection: Boolean(filter.with_refection),
        depth_condition: filter.depth_condition || "",
        pressure_condition: filter.pressure_condition || "",
        activity: filter.activity || "default",
        review_type: filter.review_type || "default",
        number_cycle: "number_cycle" in filter ? filter.number_cycle : "",
      });
    } else {
      resetFormik();
    }
  }, [open, loaded]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (isPile) {
        params.movement = "PILE";
        setSelectedFilters({ movement: "Pila" });
      } else {
        if (values.movement) {
          params.movement = values.movement;
          const movement = movements.find(
            (movement) => movement.value == values.movement
          );
          setSelectedFilters({ movement: movement.label });
        }
      }

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.company_id = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.company_id = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiary_id = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiary_id = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (!current && values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      params.with_refection = Number(values.with_refection);
      setSelectedFilters({
        with_refection: values.with_refection
          ? t("_labels.yes")
          : t("_labels.no"),
      });

      if (values.depth_condition) {
        params.depth_condition = values.depth_condition;
        setSelectedFilters({
          depth_condition: depthConditions.find(
            (condition) => condition.value == values.depth_condition
          ).label,
        });
      }

      if (values.pressure_condition) {
        params.pressure_condition = values.pressure_condition;
        setSelectedFilters({
          pressure_condition: pressureConditions.find(
            (condition) => condition.value == values.pressure_condition
          ).label,
        });
      }

      if (values.activity != "default") {
        params.activity = values.activity;

        setSelectedFilters({
          activity:
            values.activity == "GENERAL"
              ? t("_labels.activity.options.general")
              : t("_labels.activity.options.general_and_mount"),
        });
      }

      if (values.review_type != "default") {
        params.review_type = values.review_type;

        setSelectedFilters({
          review_type: t(
            REVIEW_TYPES.find((r) => r.value == values.review_type)?.label
          ),
        });
      }

      params.number_cycle = values.number_cycle;
      setSelectedFilters({
        number_cycle: values.number_cycle,
      });

      await setTireSummaryFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
      movement: "MOUNT",
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    if (current) {
      delete filter.date_from;
      delete filter.date_to;
      delete selectedFilters.date_from;
      delete selectedFilters.date_to;
    }

    setTireSummaryFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      movements={movements}
      depthConditions={depthConditions}
      pressureConditions={pressureConditions}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      current={current}
      isPile={isPile}
      t={t}
    />
  );
}

FilterTireSummaryForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isPile: PropTypes.bool,
  current: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.Summary.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setTireSummaryFilters: actionSetTireSummaryFilters(dispatch),
  clearTireSummaryFilters: actionClearTireSummaryFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedSummaryFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTireSummaryForm);
