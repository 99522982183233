import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetHelmetValues } from "store/actions/subsidiary/helmet_value/getHelmetValues";
import { actionClearHelmetValues } from "store/actions/subsidiary/helmet_value/clearHelmetValues";
import { actionDeleteHelmetValue } from "store/actions/subsidiary/helmet_value/deleteHelmetValue";
import { actionClearHelmetValueFilters } from "store/actions/subsidiary/helmet_value/clearHelmetValueFilters";

import {
  CREATE_TIRE_MODEL_VARIATION_POLICY,
  UPDATE_TIRE_MODEL_VARIATION_POLICY,
  DELETE_TIRE_MODEL_VARIATION_POLICY,
} from "store/actions/account/permits";

import Page from "./page";

export function HelmetValueTab({ ...rest }) {
  const { id } = useParams();
  const t = useLang();
  const {
    permits,
    filter,
    helmetValues,
    getHelmetValues,
    clearHelmetValues,
    deleteHelmetValue,
    clearHelmetValueFilters,
    setNotification,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [helmetValueId, setHelmetValueId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getHelmetValues(id, { ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearHelmetValues();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearHelmetValueFilters();
    };
  }, []);

  async function onDeleteHelmetValue(subsidiaryId, helmetValueId) {
    try {
      await deleteHelmetValue(subsidiaryId, helmetValueId);
      setNotification({
        message: t("_messages.deleted.helmet_value"),
      });
      await getHelmetValues(id, {});
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(helmetValueId) {
    setNotification({
      onAction: () => {
        onDeleteHelmetValue(id, helmetValueId);
      },
      message: t("_messages.delete.helmet_value"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setHelmetValueId(null);
    setOpenForm(false);
  }

  function handleEdit(id) {
    setHelmetValueId(id);
    setOpenForm(true);
  }

  async function handleChangePage(page) {
    try {
      await getHelmetValues(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      subsidiaryId={id}
      helmetValues={helmetValues}
      helmetValueId={helmetValueId}
      updateTireModelVariationPolicyPermit={permits.includes(
        UPDATE_TIRE_MODEL_VARIATION_POLICY
      )}
      deleteTireModelVariationPolicyPermit={permits.includes(
        DELETE_TIRE_MODEL_VARIATION_POLICY
      )}
      createTireModelVariationPolicyPermit={permits.includes(
        CREATE_TIRE_MODEL_VARIATION_POLICY
      )}
      handleChangePage={handleChangePage}
      openForm={openForm}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}

HelmetValueTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  filter: state.Subsidiary.HelmetValue.filter,
  helmetValues: state.Subsidiary.HelmetValue.helmet_values,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getHelmetValues: actionGetHelmetValues(dispatch),
  clearHelmetValues: actionClearHelmetValues(dispatch),
  deleteHelmetValue: actionDeleteHelmetValue(dispatch),
  clearHelmetValueFilters: actionClearHelmetValueFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelmetValueTab);
