import {
  REGISTER_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  CLEAR_ALERTS,
  GET_ALERTS,
  SET_ALERT_FILTERS,
  CLEAR_ALERT_FILTERS,
  GET_ALERT,
  CLEAR_ALERT,
  SET_ALERT_STATUS,
} from "store/actions";

const initialState = {
  alert: {
    code: "",
    alert_type: "",
    priority: "",
    ranking_alert: "",
    status: 0,
    details: "",
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
  alerts: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALERT:
    case UPDATE_ALERT:
    case REGISTER_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case GET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case CLEAR_ALERTS:
      return {
        ...state,
        alert: initialState.alert,
      };
    case SET_ALERT_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter,
      };
    case CLEAR_ALERT_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case CLEAR_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case DELETE_ALERT:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          data: state.alerts.data.filter(
            (alert) => alert.alert_id !== action.payload
          ),
        },
      };

    case SET_ALERT_STATUS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          data: state.alerts.data.map((alert) =>
            alert.alert_id !== action.payload
              ? alert
              : { ...alert, status: !alert.status }
          ),
        },
      };

    default:
      return state;
  }
}
