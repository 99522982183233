import React from "react";
import PropTypes from "prop-types";
import Home from "pages/general/Home";

function Page({
  t,
  subsidiaries,
  filter,
  division,
  divisions,
  createDivisionPermit,
  updateDivisionPermit,
  deleteDivisionPermit,
  getDivision,
  addDivision,
  updateDivision,
  clearDivisionFilters,
  setDivisionFilters,
  deleteDivision,
  setDivisionStatus,
  getDivisions,
  clearDivisions,
}) {
  return (
    <Home
      item={division}
      name="division"
      filter={filter}
      createTooltipPermit={
        !createDivisionPermit ? t("_permits.create.division") : ""
      }
      currentPage={divisions.current_page}
      deleteConfirmationMessage={t("_messages.delete.division")}
      disableConfirmationMessage={t("_messages.change_status.division.disable")}
      enableConfirmationMessage={t("_messages.change_status.division.enable")}
      rows={divisions.data.map((division) => {
        return {
          id: division.division_id,
          key: division.division_id,
          status: {
            status: division.status,
            tooltipPermit: !updateDivisionPermit
              ? t("_permits.update.division")
              : "",
          },
          subsidiary: division.subsidiary.name,
          name: division.name,
          edit: {
            tooltipPermit: !updateDivisionPermit
              ? t("_permits.update.division")
              : "",
          },
          delete: {
            tooltipPermit: !deleteDivisionPermit
              ? t("_permits.delete.division")
              : "",
          },
        };
      })}
      title={t("_general.divisions")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "actions", label: "" },
      ]}
      lastPage={divisions.last_page}
      noItemsText={t("_general.no_divisions")}
      initialDefaultValues={{
        name: "",
        subsidiary_id: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "subsidiary_id",
          required: false,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
      ]}
      createFormTitle={t("_titles.new.division")}
      updateFormTitle={t("_titles.update.division")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.division"),
        },
        date: true,
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
        subsidiaries: [],
      }}
      createdMessage={t("_messages.created.division")}
      updatedMessage={t("_messages.updated.division")}
      getItemAction={getDivision}
      setFiltersAction={setDivisionFilters}
      clearFiltersAction={clearDivisionFilters}
      deleteAction={deleteDivision}
      setStatusAction={setDivisionStatus}
      getItemsAction={getDivisions}
      clearItemsAction={clearDivisions}
      addAction={addDivision}
      updateAction={updateDivision}
    />
  );
}

Page.propTypes = {
  division: PropTypes.object.isRequired,
  divisions: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  createDivisionPermit: PropTypes.bool.isRequired,
  updateDivisionPermit: PropTypes.bool.isRequired,
  deleteDivisionPermit: PropTypes.bool.isRequired,
  getDivision: PropTypes.func.isRequired,
  getDivisions: PropTypes.func.isRequired,
  clearDivisions: PropTypes.func.isRequired,
  addDivision: PropTypes.func.isRequired,
  updateDivision: PropTypes.func.isRequired,
  deleteDivision: PropTypes.func.isRequired,
  setDivisionStatus: PropTypes.func.isRequired,
  clearDivisionFilters: PropTypes.func.isRequired,
  setDivisionFilters: PropTypes.func.isRequired,
};

export default Page;
