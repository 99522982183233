import {
  GET_DIVISION_VEHICLES,
  CLEAR_DIVISION_VEHICLES,
  ASSIGN_VEHICLE_TO_DIVISION,
  DELETE_LINK_VEHICLE_WITH_DIVISION,
  SET_DIVISION_VEHICLE_FILTERS,
  CLEAR_DIVISION_VEHICLE_FILTERS,
  GET_DIVISION_VEHICLE,
  CLEAR_DIVISION_VEHICLE,
} from "store/actions";

const initialState = {
  division_vehicle: {
    vehicle_id: 0,
    vehicle: {
      economic_number: "",
    },
    division: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  division_vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DIVISION_VEHICLES:
      return {
        ...state,
        division_vehicles: action.payload,
      };

    case CLEAR_DIVISION_VEHICLES:
      return {
        ...state,
        division_vehicles: initialState.division_vehicles,
      };

    case ASSIGN_VEHICLE_TO_DIVISION:
      return {
        ...state,
        division_vehicles: {
          ...state.division_vehicles,
          data: [action.payload, ...state.division_vehicles.data],
        },
      };

    case DELETE_LINK_VEHICLE_WITH_DIVISION:
      return {
        ...state,
        division_vehicles: {
          ...state.division_vehicles,
          data: state.division_vehicles.data.filter(
            (vehicle) => vehicle.vehicle_id !== action.payload
          ),
        },
      };

    case SET_DIVISION_VEHICLE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_DIVISION_VEHICLE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case GET_DIVISION_VEHICLE:
      return {
        ...state,
        division_vehicle: action.payload,
      };

    case CLEAR_DIVISION_VEHICLE:
      return {
        ...state,
        division_vehicle: initialState.division_vehicle,
      };

    default:
      return state;
  }
}
