import { GET_VEHICLES_TYPES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehiclesTypes =
  (dispatch) =>
  async (
    {
      search,
      status,
      page,
      order = "DESC",
      scope,
      structure_type: structureType,
      axle_number: axleNumber,
      approved,
      date_from: dateFrom,
      date_to: dateTo,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `vehicle/type`,
        params: {
          search,
          status,
          page,
          order,
          scope,
          structure_type: structureType,
          axle_number: axleNumber,
          approved,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLES_TYPES,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
