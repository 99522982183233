import {
  GET_USER_CORPORATE,
  CLEAR_USER_CORPORATE,
  GET_USER_CORPORATES,
  CLEAR_USER_CORPORATES,
  SET_USER_CORPORATE_FILTERS,
  CLEAR_USER_CORPORATE_FILTERS,
  DELETE_USER_IN_CORPORATE,
} from "store/actions";

const initialState = {
  corporates: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
  user_corporate: {
    user: {
      name: "",
      last_name_1: "",
    },
    corporate: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    role: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_CORPORATE:
      return {
        ...state,
        user_corporate: action.payload,
      };
    case CLEAR_USER_CORPORATE:
      return {
        ...state,
        user_corporate: { ...initialState.user_corporate },
      };

    case GET_USER_CORPORATES:
      return {
        ...state,
        corporates: action.payload,
      };
    case CLEAR_USER_CORPORATES:
      return {
        ...state,
        corporates: { ...initialState.corporates },
      };

    case SET_USER_CORPORATE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_USER_CORPORATE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_USER_IN_CORPORATE:
      return {
        ...state,
        corporates: {
          ...state.corporates,
          data: state.corporates.data.filter(
            (corporate) =>
              corporate.user_assigned_corporate_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
