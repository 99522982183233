import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionClearScheduledWithdrawalFilters } from "store/actions/reports/scheduled_withdrawal_tires/clearScheduledWithdrawalFilters";
import { actionGetScheduledWithdrawalTiresReport } from "store/actions/reports/scheduled_withdrawal_tires/getScheduledWithdrawalTiresReport";
import { actionSetScheduledWithdrawalFilters } from "store/actions/reports/scheduled_withdrawal_tires/setScheduledWithdrawalFilters";
import { actionSetSelectedScheduledWithdrawalFilters } from "store/actions/reports/scheduled_withdrawal_tires/setSelectedScheduledWithdrawalFilters";
import { useLang } from "hooks/lang";

import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import Page from "./page";

function ScheduledWithdrawal({ ...rest }) {
  const t = useLang();

  const {
    language,
    filter,
    account,
    workArea,
    selectedFilter,
    setNotification,
    getScheduledWithdrawalReport,
    clearScheduledWithdrawalFilters,
    setScheduledWithdrawalFilters,
    setSelectedScheduledWithdrawalFilters,
  } = rest;
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getScheduledWithdrawalReport({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      movement: "BOTH",
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      movement: t("_labels.report_summary.location.both"),
    };

    setScheduledWithdrawalFilters(filter);
    setSelectedScheduledWithdrawalFilters(selectedFilters);

    return () => {
      clearScheduledWithdrawalFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      language={language}
      data={data}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}
ScheduledWithdrawal.propTypes = {};

const mapStateToProps = (state) => ({
  language: state.General.language,
  filter: state.Reports.ScheduledWithdrawal.filter,
  selectedFilter: state.Reports.ScheduledWithdrawal.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getScheduledWithdrawalReport:
    actionGetScheduledWithdrawalTiresReport(dispatch),
  clearScheduledWithdrawalFilters:
    actionClearScheduledWithdrawalFilters(dispatch),
  setScheduledWithdrawalFilters: actionSetScheduledWithdrawalFilters(dispatch),
  setSelectedScheduledWithdrawalFilters:
    actionSetSelectedScheduledWithdrawalFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledWithdrawal);
