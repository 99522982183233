export default {
  disabled: "Modelo revitalizado deshabilitado",
  messages: {
    enable: "¿Estas seguro de habilitar el modelo revitalizado?",
    disable: "¿Estas seguro de deshabilitar el modelo revitalizado?",
  },
  permits: {
    change_status: "Sin permisos para {action} modelos revitalizados",
  },
};
