export default {
  not_any_warehouse: "It is not in any warehouse",
  buttons: {
    take_out: "Take out",
    view_warehouse: "View warehouse",
  },
  permits: {
    show: {
      tire_warehouse: "No permissions to view tire-linked warehouses",
    },
  },
};
