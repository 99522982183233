import {
  GET_GPS,
  CLEAR_GPS,
  GET_GPS_LIST,
  CLEAR_GPS_LIST,
  REGISTER_GPS,
  UPDATE_GPS,
  DELETE_GPS,
  SET_GPS_STATUS,
  SET_GPS_FILTERS,
  CLEAR_GPS_FILTERS,
} from "store/actions";

const initialState = {
  gps_list: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  gps: {
    gps_id: 0,
    imei: "",
    status: 0,
    device_name: "",
    subsidiary_id: 0,
    subsidiary: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GPS_LIST:
      return {
        ...state,
        gps_list: action.payload,
      };

    case CLEAR_GPS_LIST:
      return {
        ...state,
        gps_list: initialState.gps_list,
      };

    case GET_GPS:
    case UPDATE_GPS:
    case REGISTER_GPS:
      return {
        ...state,
        gps: action.payload,
      };

    case CLEAR_GPS:
      return {
        ...state,
        gps: initialState.gps,
      };

    case SET_GPS_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_GPS_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case SET_GPS_STATUS:
      return {
        ...state,
        gps_list: {
          ...state.gps_list,
          data: state.gps_list.data.map((gps) =>
            gps.gps_id !== action.payload
              ? gps
              : { ...gps, status: !gps.status }
          ),
        },
      };

    case DELETE_GPS:
      return {
        ...state,
        gps_list: {
          ...state.gps_list,
          data: state.gps_list.data.filter(
            (gps) => gps.gps_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
