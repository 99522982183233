import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { actionSetOrigin } from "store/actions/tire/setTire";

import Page from "./page";

const useStyles = makeStyles(() => ({
  droppable: {
    transform: "scale(1.05)",
    opacity: "0.75 !important",
    borderRadius: "6px",
    padding: "1px",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
  },
}));

export function RefectionAxle({
  color,
  axleTires,
  tireQuantity,
  className,
  classNameAxle,
  classNameTire,
  onClickTire,
  onRotateTire,
  ...rest
}) {
  const [tires, setTires] = useState([]);
  const [hide, setHide] = useState(true);

  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    let a = [];
    switch (parseInt(tireQuantity)) {
      case 1:
        a = [[axleTires[0]]];
        break;
      case 2:
        a = [[axleTires[0]], [axleTires[1]]];
        break;
      case 4:
        a = [
          [axleTires[0], axleTires[1]],
          [axleTires[2], axleTires[3]],
        ];
        break;
    }
    setTires(a);
  }, [tireQuantity, axleTires]);

  const { origin, setOrigin, inProcess, review } = rest;

  function handleDragStart(e, tire) {
    e.stopPropagation();
    if (tire.vehicle_tire.length > 0) {
      setOrigin(tire);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDrop(e, tire) {
    e.preventDefault();
    e.target.classList.remove(classes.droppable);

    if (origin && tire.position !== origin.position) {
      onRotateTire(origin, tire);
    }
    setOrigin(null);
  }

  function handleDragEnter(e) {
    e.target.classList.add(classes.droppable);
  }

  function handleDragLeave(e) {
    e.target.classList.remove(classes.droppable);
  }

  return (
    <Page
      color={color}
      axleTires={tires}
      className={className}
      classNameAxle={classNameAxle}
      classNameTire={classNameTire}
      onClickTire={onClickTire}
      withTooltip={!location.pathname.includes("type")}
      inReview={location.pathname.includes("review")}
      origin={origin}
      handleDragStart={handleDragStart}
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleDragOver={handleDragOver}
      handleDrop={handleDrop}
      hide={hide}
      setHide={setHide}
      inProcess={inProcess}
      review={review}
    />
  );
}

RefectionAxle.propTypes = {
  classNameAxle: PropTypes.string,
  classNameTire: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  axleTires: PropTypes.array.isRequired,
  tireQuantity: PropTypes.oneOf([1, 2, 4]).isRequired,
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

const mapStateToProps = (state) => ({
  origin: state.Vehicle.Tire.origin,
  inProcess: state.Vehicle.Review.in_process,
  review: state.Vehicle.Review.vehicle_review,
});
const mapDispatchToProps = (dispatch) => ({
  setOrigin: actionSetOrigin(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefectionAxle);
