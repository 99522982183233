import { combineReducers } from "redux";

import General from "./general";
import Favorites from "./favorites";
import Alert from "./alert";
import Association from "./association";
import User from "./user";
import Damage from "./damage";
import Wear from "./wear";
import Corporate from "./corporate";
import Company from "./company";
import Subsidiary from "./subsidiary";
import Division from "./division";
import Provider from "./provider";
import Revitalized from "./revitalized";
import Warehouse from "./warehouse";
import Vehicle from "./vehicle";
import Driver from "./driver";
import Brand from "./brand";
import Tire from "./tire";
import Account from "./account";
import DepthTolerancePolicy from "./depth_tolerance_policy";
import Gps from "./gps";
import RetirementCause from "./retirement_cause";
import Rfid from "./rfid";
import MountAlerts from "./mount_alerts";
import Reports from "./reports";
import Commissioned from "./commissioned";
import Checklist from "./checklist";
import Concept from "./concept";
import Block from "./block";
import Disposal from "./disposal";
import UserNotifications from "./user_notifications";
import Tpms from "./tpms";
import ChecklistHistory from "./checklist_history";

export default combineReducers({
  General,
  Favorites,
  Alert,
  Association,
  User,
  Damage,
  Wear,
  Corporate,
  Company,
  Subsidiary,
  Division,
  Provider,
  Revitalized,
  Warehouse,
  Vehicle,
  Driver,
  Brand,
  Tire,
  Account,
  DepthTolerancePolicy,
  Gps,
  RetirementCause,
  Rfid,
  MountAlerts,
  Reports,
  Commissioned,
  Checklist,
  Concept,
  Block,
  Disposal,
  UserNotifications,
  Tpms,
  ChecklistHistory,
});
