export default {
  email_incorrect: "El correo electrónico no es válido.",
  different_passwords: "Las contraseñas no coinciden.",
  invalid_session: "La sesión no es válida.",
  password_incorrect: "La contraseña no es valida.",
  rfc_incorrect: "El rfc no es valido.",
  fee_tire: "Deberías cambiar la moneda a pesos",
  no_number: "Este campo debe ser un número",
  no_whole_number: "Este campo debe ser un número entero",
  dot: "Este campo contener entre 2 y 15 caracteres",
  no_integer: "Este campo debe ser un número entero",
  adjust_pressure: "Debes llenar la fecha y el odómetro",
  different_cost:
    "La suma de los costos no puede ser diferente al precio de la reparación",
  next_odometer: "No puede ser mayor que la siguiente {value}",
  previous_odometer: "No puede ser menor que la anterior {value}",
};
