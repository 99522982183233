import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionClearAssociations } from "store/actions/association/clearAssociations";
import { actionSetAssociationFilters } from "store/actions/association/setAssociationFilters";
import { actionClearAssociationFilters } from "store/actions/association/clearAssociationFilters";
import { actionGetAssociations } from "store/actions/association/getAssociations";
import { actionGetAssociation } from "store/actions/association/getAssociation";
import { actionDeleteAssociation } from "store/actions/association/deleteAssociation";
import { actionSetStatusAssociation } from "store/actions/association/setStatusAssociation";
import { actionAddAssociation } from "store/actions/association/addAssociation";
import { actionUpdateAssociation } from "store/actions/association/udpateAssociation";
import json from "resources/json/countries";

import {
  CREATE_ASSOCIATION,
  DELETE_ASSOCIATION,
  UPDATE_ASSOCIATION,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeAssociation({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    association,
    associations,
    account,
    deleteAssociation,
    setStatusAssociation,
    getAssociation,
    addAssociation,
    updateAssociation,
    getAssociations,
    getSubsidiaries,
    setNotification,
    clearAssociations,
    setAssociationFilters,
    clearAssociationFilters,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
        setCountries(Object.keys(json));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  function handleChangeCountry(form, value, stateValue = "") {
    if (value && json[value]) {
      setStates(Object.keys(json[value])?.sort((a, b) => a.localeCompare(b)));
    } else {
      setStates([]);
    }
    form.setFieldValue("state", stateValue);
  }

  function handleChangeState(form, value, countryValue, provinceValue = "") {
    if (value) {
      setProvinces(
        json?.[countryValue]?.[value]?.sort((a, b) => a.localeCompare(b)) ?? []
      );
    } else {
      setProvinces([]);
    }
    if (provinceValue) {
      form.setFieldValue("province", provinceValue);
    } else {
      form.setFieldValue("province", "");
    }
  }

  return (
    <Page
      subsidiaries={subsidiaries}
      createAssociationPermit={permits.includes(CREATE_ASSOCIATION)}
      updateAssociationPermit={permits.includes(UPDATE_ASSOCIATION)}
      deleteAssociationPermit={permits.includes(DELETE_ASSOCIATION)}
      association={association}
      associations={associations}
      filter={filter}
      handleChangeCountry={handleChangeCountry}
      handleChangeState={handleChangeState}
      countries={countries}
      states={states}
      provinces={provinces}
      t={t}
      getAssociation={getAssociation}
      addAssociation={addAssociation}
      updateAssociation={updateAssociation}
      clearAssociationFilters={clearAssociationFilters}
      setAssociationFilters={setAssociationFilters}
      deleteAssociation={deleteAssociation}
      setStatusAssociation={setStatusAssociation}
      getAssociations={getAssociations}
      clearAssociations={clearAssociations}
    />
  );
}

HomeAssociation.propTypes = {};

const mapStateToProps = (state) => ({
  associations: state.Association.Association.associations,
  association: state.Association.Association.association,
  filter: state.Association.Association.filter_association,
  permits: state.Account.permits,
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getAssociations: actionGetAssociations(dispatch),
  clearAssociations: actionClearAssociations(dispatch),
  setNotification: actionSetNotification(dispatch),
  setAssociationFilters: actionSetAssociationFilters(dispatch),
  clearAssociationFilters: actionClearAssociationFilters(dispatch),
  deleteAssociation: actionDeleteAssociation(dispatch),
  setStatusAssociation: actionSetStatusAssociation(dispatch),
  getAssociation: actionGetAssociation(dispatch),
  addAssociation: actionAddAssociation(dispatch),
  updateAssociation: actionUpdateAssociation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeAssociation);
