import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import ContainerTemplate from "components/templates/general/Container";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Markdown from "markdown-to-jsx";

import css from "./index.module.scss";
import FilterForm from "components/organisms/general/FilterForm";

function Page({
  t,
  filter,
  openFilter,
  notifications,
  handleChangePage,
  handleDelete,
  handleDeleteAll,
  handleOpenFilter,
  handleCloseFilter,
  setNotificationFilters,
  clearNotificationFilters,
}) {
  return (
    <ContainerTemplate
      title={t("_general.notifications")}
      className={classnames({
        [css.empty]: notifications.data.length === 0,
        [css.font]: true,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      {notifications.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <>
              <div className="mb-2 d-flex justify-content-end align-self-end">
                <button
                  className="btn btn-info w-auto"
                  onClick={handleDeleteAll}
                >
                  {t("_labels.discard_all")}
                </button>
              </div>
              {notifications.data.map((n) => (
                <div
                  key={n.id}
                  className={classnames({
                    [css.notification]: true,
                    [css.read]: !!n.read_at,
                  })}
                >
                  <p className={classnames(css.notification_title)}>
                    {n.data.title}
                  </p>
                  <Markdown>{n.data.body}</Markdown>
                  <div
                    className={`d-flex justify-content-${
                      !!n.read_at ? "end" : "between"
                    } align-items-center`}
                  >
                    {!n.read_at && (
                      <button
                        className="btn btn-sm btn-info w-auto"
                        onClick={() => handleDelete(n.id)}
                      >
                        {t("_labels.discard")}
                      </button>
                    )}
                    <p className={classnames(css.notification_date)}>
                      {moment(n.created_at).format("LL h:mm a")}
                    </p>
                  </div>
                </div>
              ))}
            </>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={notifications.current_page}
              totalPages={notifications.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterForm
        open={openFilter}
        filter={filter}
        fields={{ date: true }}
        initialDefaultValues={{
          date_from: "",
          date_to: "",
        }}
        onClose={handleCloseFilter}
        setFiltersAction={setNotificationFilters}
        clearFiltersAction={clearNotificationFilters}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object,
  openFilter: PropTypes.bool,
  notifications: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDeleteAll: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  setNotificationFilters: PropTypes.func.isRequired,
  clearNotificationFilters: PropTypes.func.isRequired,
};

export default Page;
