import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";
import { arrayJoin } from "utils/parse";

import { actionSetWorkArea } from "store/actions/account/setWorkArea";
import { actionGetUserPermits } from "store/actions/account/getUserPermits";
import { actionSetNotification } from "store/actions/general/notification";
import { alphabeticalSort } from "utils/sort";
import { useHistory } from "react-router-dom";

import Page from "./page";

export const NAMESPACE = "components/organisms/account/WorkArea";

export function WorkArea({ className, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const [areas, setAreas] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [area, setArea] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    for (const subsidiary of subsidiaries) {
      if (subsidiary.key === area) {
        setInputValue(subsidiary.name);
      }
    }
  }, [area]);

  const { account, setNotification, workArea, setWorkArea, getUserPermits } =
    rest;

  useEffect(() => {
    if (account) {
      let areas = {};
      let subsidiaries = [];
      account.subsidiaries.forEach((subsidiary) => {
        areas[`${subsidiary.user_assigned_subsidiary_id}-Subsidiary`] = {
          key: `${subsidiary.user_assigned_subsidiary_id}-Subsidiary`,
          id: subsidiary.user_assigned_subsidiary_id,
          name: `${subsidiary.subsidiary.company.name} - ${subsidiary.subsidiary.name}`,
          company: subsidiary.subsidiary.company.name,
          status: subsidiary.subsidiary.status,
          area: "Subsidiary",
          roles: arrayJoin(
            subsidiary.role.map((role) =>
              t(`_general.role.${role.role.toLowerCase()}`)
            ),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        };

        subsidiaries.push(
          areas[`${subsidiary.user_assigned_subsidiary_id}-Subsidiary`]
        );
      });

      setSubsidiaries(subsidiaries.sort(alphabeticalSort("company")));
      setAreas(areas);
    }
  }, [account]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getUserPermits();
      } catch (error) {
        setWorkArea({});
        setNotification(error, true);
      }
    }

    if (workArea.key in areas) {
      setArea(workArea.key);
      fetchData();
    }
  }, [workArea, areas]);

  function handleChange(_event, value) {
    setArea(value.key);
    setWorkArea(areas[value.key]);
    history && history.push("/");
  }

  return (
    <Page
      t={t}
      area={area}
      handleChange={handleChange}
      areas={areas}
      subsidiaries={subsidiaries}
      className={className}
      inputValue={inputValue}
      setInputValue={setInputValue}
    />
  );
}

WorkArea.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setWorkArea: actionSetWorkArea(dispatch),
  getUserPermits: actionGetUserPermits(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkArea);
