import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
  vehicleTypes,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        variant="filled"
        required
        name="vehicle_type"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.vehicle_type.label")}
      >
        {vehicleTypes.map((vehicle, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicle.vehicle_type_id.toString()}
              disabled={!vehicle.status}
            >
              {vehicle.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        required
        variant="filled"
        name="axle_type"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.axle_field.label")}
      >
        <MenuItem value="DIRECTIONAL">
          {t("_labels.axle_field.options.directional")}
        </MenuItem>
        <MenuItem value="TRACTION">
          {t("_labels.axle_field.options.traction")}
        </MenuItem>
        <MenuItem value="TRAILER">
          {t("_labels.axle_field.options.trailer")}
        </MenuItem>
        <MenuItem value="REFECTION">
          {t("_labels.axle_field.options.refection")}
        </MenuItem>
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="recommended_pressure"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.recommended_pressure")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              psi
            </InputAdornment>
          ),
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="tolerance"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.tolerance")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              %
            </InputAdornment>
          ),
        }}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
};

export default Page;
