import React from "react";
import PropTypes from "prop-types";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import classnames from "classnames";

import Chart from "./Chart";
import css from "./index.module.scss";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function Page({ t, pileData, pileDataCost, subsidiaries }) {
  return (
    <>
      {subsidiaries.map((subsidiary) => {
        return (
          <React.Fragment key={subsidiary}>
            <div className="mt-2 card-header text-center bg-dark text-white rounded">
              <div
                className="h5 m-0 d-flex justify-content-center align-items-center"
                style={{ gap: "6px" }}
              >
                {subsidiary}
              </div>
            </div>
            <div className={classnames(css.data_container)}>
              {pileData[subsidiary] &&
                Object.keys(pileData[subsidiary]).length > 0 && (
                  <div
                    className="mt-3 card shadow"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "0.62rem",
                    }}
                  >
                    <div
                      className="card-header sMarcoInfoFondoAzul"
                      style={{
                        borderTopLeftRadius: "0.62rem",
                        borderTopRightRadius: "0.62rem",
                      }}
                    >
                      {t("_titles.graphs.pile_by_responsible")}
                    </div>
                    <div className="card-body">
                      <Chart
                        options={
                          pileData[subsidiary].datasets[0].data.length >= 8
                            ? getDefaultQuarterOptions(t("_general.tires"))
                            : getDefaultOptions(t("_general.tires"))
                        }
                        data={pileData[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className={classnames(css.data_container)}>
              {pileDataCost[subsidiary] &&
                Object.keys(pileDataCost[subsidiary]).length > 0 && (
                  <div
                    className="card shadow"
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "0.62rem",
                    }}
                  >
                    <div
                      className="card-header sMarcoInfoFondoAzul"
                      style={{
                        borderTopLeftRadius: "0.62rem",
                        borderTopRightRadius: "0.62rem",
                      }}
                    >
                      {t("_titles.graphs.pile_by_responsible_cost")}
                    </div>
                    <div className="card-body rounded-circle">
                      <Chart
                        options={
                          pileDataCost[subsidiary].datasets[0].data.length >= 8
                            ? getDefaultQuarterOptions(t("_labels.costo"))
                            : getDefaultOptions(t("_labels.costo"))
                        }
                        data={pileDataCost[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  pileData: PropTypes.object.isRequired,
  pileDataCost: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
};

export default Page;

function getDefaultOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          displayFormats: {
            month: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}

function getDefaultQuarterOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "quarter",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}
