import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import ContainerTemplate from "components/templates/general/Container";

import css from "./index.module.scss";
import Chart from "react-apexcharts";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

function Page({ t, data, vehicle }) {
  return (
    <ContainerTemplate
      title={`${t("_labels.vehicle.singular")}: ${vehicle.economic_number}`}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={() => {}}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    >
      {data.seriesPressure.length > 0 && (
        <div className="mx-5 p-2 mt-5 rounded shadow">
          <p className="h4 py-1 pl-3 m-0 mt-1" style={{ fontFamily: "Roboto" }}>
            {t("_labels.pressure")}
          </p>
          <Chart
            options={data.optionsPressure}
            series={data.seriesPressure}
            type="line"
            height="400px"
          />
        </div>
      )}

      {data.seriesTemperature.length > 0 && (
        <div className="mx-5 p-2 mt-4 rounded shadow">
          <p className="h4 py-1 pl-3 m-0 mt-1" style={{ fontFamily: "Roboto" }}>
            {t("_labels.temperature")}
          </p>
          <Chart
            options={data.optionsTemperature}
            series={data.seriesTemperature}
            type="line"
            height="400px"
          />
        </div>
      )}

      {data.seriesPressure.length == 0 && data.seriesTemperature.length == 0 && (
        <div className="my-5">
          <EmptyListIcon text={t("_general.no_chart_tpms_data")} />
        </div>
      )}
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  vehicle: PropTypes.object,
};

export default Page;
