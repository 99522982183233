export default {
  vehicle_type_name: "Vehicle type name",
  disabled_vehicle_type: "Disabled vehicle type",
  messages: {
    approve: "Are you sure to approve the vehicle type?",
    disapprove: "Are you sure to disapprove the vehicle type?",
    enable: "Are you sure to enable the vehicle type?",
    disable: "Are you sure to disable the vehicle type?",
  },
  permits: {
    change_approval_status: "No permissions to {action} vehicles types",
    change_status: "No permissions to {action} vehicles types",
  },
};
