import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import FilterDynamicCostForm from "components/organisms/report/FilterDynamicCostForm";
import SearchInput from "components/molecules/general/SearchInput";
import TireDialog from "./TireDialog";
import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({
  t,
  filter,
  selectedFilter,
  search,
  openFilter,
  generalData,
  originalData,
  revitalizedData,
  oneData,
  twoData,
  treeData,
  fourData,
  policyNumberCycle,
  currentTab,
  setCurrentTab,
  handleOpenFilter,
  handleCloseFilter,
  params,
  openTireDialog,
  handleViewTires,
  handleCloseTireDialog,
  handleGenerateDynamicCostExcel,
  handleSearch,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.dynamic_cost_report")}
      options={
        <>
          <Button
            classNameButton={classnames("bw")}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.size")}:
                      </td>
                      <td>{selectedFilter.sizes}</td>
                    </tr>
                  )}
                  {filter.tire_application && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.application")}:
                      </td>
                      <td>{selectedFilter.application}</td>
                    </tr>
                  )}
                  {filter.tire_condition && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.condition")}:
                      </td>
                      <td>{selectedFilter.condition}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.movement && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.location.label")}:
                      </td>
                      <td>{selectedFilter.movement}</td>
                    </tr>
                  )}
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                  {filter.price && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.price")}:
                      </td>
                      <td>{formatter.format(selectedFilter.price)}</td>
                    </tr>
                  )}
                  {filter.number_cycle && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.number_cycle")}:
                      </td>
                      <td>
                        {isNaN(selectedFilter.number_cycle)
                          ? "Originales"
                          : formatter.format(selectedFilter.number_cycle)}
                      </td>
                    </tr>
                  )}
                  {filter.helmet_value && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.helmet_value")}:
                      </td>
                      <td>{formatter.format(selectedFilter.helmet_value)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">
              {t("_labels.dynamic_cost_report.cost_report")}
            </h1>
            <h5 className="sinmp">
              {t("_labels.dynamic_cost_report.this_report_shows")}
            </h5>
            <small
              className="sinmp font-weight-bold"
              style={{ color: "lightcoral" }}
            >
              {t("_labels.dynamic_cost_report.only_tires")}
            </small>
            {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")} {t("_general.text.and")}{" "}
                  {t("_labels.location.options.warehouse")}
                </small>
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-md-12">
            <div className="container-fluid">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateDynamicCostExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-md-12">
            <div className="container-fluid">
              <div className={classnames(css.tab)}>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "General",
                  })}
                  onClick={() => {
                    setCurrentTab("General");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.general")}
                </div>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "Revitalized",
                  })}
                  onClick={() => {
                    setCurrentTab("Revitalized");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.revitalized")}
                </div>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: currentTab === "Original",
                  })}
                  onClick={() => {
                    setCurrentTab("Original");
                  }}
                >
                  {t("_labels.dynamic_cost_report.tabs.original")}
                </div>
                {[...Array(policyNumberCycle).keys()].map((key) => {
                  return (
                    <div
                      key={key}
                      className={classnames({
                        [css.option_tab]: true,
                        [css.active]: currentTab === `${key + 1}`,
                      })}
                      onClick={() => {
                        setCurrentTab(`${key + 1}`);
                      }}
                    >
                      {t("_labels.dynamic_cost_report.tabs.cycle")}
                      {` ${key + 1}`}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    {Object.keys(generalData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.tire_cost")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(generalData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "general"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="general"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "general"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="general"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "general"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="general"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "general"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={JSON.stringify(
                                                          application
                                                        )}
                                                      >
                                                        <tr
                                                          data-type="general"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["applications"]}`,
                                                              "general"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="general"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "general"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="general"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(originalData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.cost_of_tires")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(originalData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "original"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="original"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "original"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="original"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "original"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="original"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "original"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={
                                                          application[
                                                            "application"
                                                          ]
                                                        }
                                                      >
                                                        <tr
                                                          data-type="original"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                              "original"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="original"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "original"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="original"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          tire_condition:
                                                                            "ORIGINAL_NEW,ORIGINAL_USED",
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(revitalizedData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t(
                            "_labels.dynamic_cost_report.tire_cost_revitalized"
                          )}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(revitalizedData).map(
                              (corporate) => (
                                <Fragment key={corporate[0]}>
                                  <thead className="text-center">
                                    <tr
                                      className="corporate"
                                      onClick={() =>
                                        toggleTable(
                                          `${corporate[0]}_companies`,
                                          corporate[0],
                                          "revitalized"
                                        )
                                      }
                                    >
                                      <th className="text-left">
                                        {corporate[0] === "NO CORPORATE"
                                          ? t("_labels.no_corporate")
                                          : corporate[0]}
                                      </th>
                                      <th></th>
                                      <th className="text-right col3"></th>
                                      <th className="text-right col3">
                                        <small>
                                          {t(
                                            "_labels.dynamic_cost_report.tires"
                                          )}
                                        </small>
                                        <br />
                                        {formatter.format(
                                          corporate[1]["statistics"]
                                        )}
                                      </th>
                                      <th className="text-right col4">
                                        <small>
                                          {t(
                                            "_labels.dynamic_cost_report.cost_km"
                                          )}
                                        </small>
                                        <br />$
                                        {costKmFormatter.format(
                                          corporate[1]["cpk"]
                                        )}
                                      </th>
                                      <th className="text-right col5">
                                        <small>
                                          {t(
                                            "_labels.dynamic_cost_report.cost_mm"
                                          )}
                                        </small>
                                        <br />$
                                        {formatter.format(corporate[1]["cpd"])}
                                      </th>
                                      <th className="text-right col5">
                                        <small>km/mm</small>
                                        <br />
                                        {formatter.format(
                                          corporate[1]["km/mm"]
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(corporate[1])
                                      .filter(
                                        (index) =>
                                          typeof corporate[1][index] ===
                                          "object"
                                      )
                                      .map((company) => (
                                        <Fragment key={company}>
                                          <tr
                                            data-type="revitalized"
                                            data-id={`${removeCharacters(
                                              corporate[0]
                                            )}_companies`}
                                            className="company box collapse"
                                            onClick={() =>
                                              toggleTable(
                                                `${corporate[0]}_${company}_subsidiaries`,
                                                company,
                                                "revitalized"
                                              )
                                            }
                                          >
                                            <td className="text-left">
                                              {company}
                                            </td>
                                            <td></td>
                                            <td className="text-right col3">
                                              {formatter.format(
                                                (corporate[1][company][
                                                  "statistics"
                                                ] *
                                                  100) /
                                                  corporate[1]["statistics"]
                                              )}
                                              %
                                            </td>
                                            <td className="text-right col3">
                                              {formatter.format(
                                                corporate[1][company][
                                                  "statistics"
                                                ]
                                              )}
                                            </td>
                                            <th className="text-right col4">
                                              <small>
                                                {t(
                                                  "_labels.dynamic_cost_report.cost_km"
                                                )}
                                              </small>
                                              <br />$
                                              {costKmFormatter.format(
                                                corporate[1][company]["cpk"]
                                              )}
                                            </th>
                                            <th className="text-right col5">
                                              <small>
                                                {t(
                                                  "_labels.dynamic_cost_report.cost_mm"
                                                )}
                                              </small>
                                              <br />$
                                              {formatter.format(
                                                corporate[1][company]["cpd"]
                                              )}
                                            </th>
                                            <th className="text-right col5">
                                              <small>km/mm</small>
                                              <br />
                                              {formatter.format(
                                                corporate[1][company]["km/mm"]
                                              )}
                                            </th>
                                          </tr>

                                          {Object.keys(corporate[1][company])
                                            .filter(
                                              (index) =>
                                                typeof corporate[1][company][
                                                  index
                                                ] === "object"
                                            )
                                            .map((subsidiary) => (
                                              <Fragment key={subsidiary}>
                                                <tr
                                                  data-type="revitalized"
                                                  data-id={removeCharacters(
                                                    `${corporate[0]}_${company}_subsidiaries`
                                                  )}
                                                  className="subsidiary box collapse"
                                                  onClick={() =>
                                                    toggleTable(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                      `${company}_${subsidiary}`,
                                                      "revitalized"
                                                    )
                                                  }
                                                >
                                                  <td className="text-left">
                                                    {subsidiary}
                                                  </td>
                                                  <td></td>
                                                  <td className="text-right col3">
                                                    {formatter.format(
                                                      (corporate[1][company][
                                                        subsidiary
                                                      ]["statistics"] *
                                                        100) /
                                                        corporate[1][company][
                                                          "statistics"
                                                        ]
                                                    )}
                                                    %
                                                  </td>
                                                  <td className="text-right col3">
                                                    {formatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["statistics"]
                                                    )}
                                                  </td>
                                                  <th className="text-right col4">
                                                    <small>
                                                      {t(
                                                        "_labels.dynamic_cost_report.cost_km"
                                                      )}
                                                    </small>
                                                    <br />$
                                                    {costKmFormatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["cpk"]
                                                    )}
                                                  </th>
                                                  <th className="text-right col5">
                                                    <small>
                                                      {t(
                                                        "_labels.dynamic_cost_report.cost_mm"
                                                      )}
                                                    </small>
                                                    <br />$
                                                    {formatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["cpd"]
                                                    )}
                                                  </th>
                                                  <th className="text-right col5">
                                                    <small>km/mm</small>
                                                    <br />
                                                    {formatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["km/mm"]
                                                    )}
                                                  </th>
                                                </tr>

                                                {Object.keys(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]
                                                )
                                                  .filter(
                                                    (index) =>
                                                      typeof corporate[1][
                                                        company
                                                      ][subsidiary][index] ===
                                                      "object"
                                                  )
                                                  .map((division) => (
                                                    <Fragment key={division}>
                                                      <tr
                                                        data-type="revitalized"
                                                        data-id={removeCharacters(
                                                          `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                        )}
                                                        className="division box collapse"
                                                        onClick={() =>
                                                          toggleTable(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                            `${company}_${subsidiary}_${division}`,
                                                            "revitalized"
                                                          )
                                                        }
                                                      >
                                                        <td className="text-left">
                                                          {division ===
                                                          "NO DIVISION"
                                                            ? t(
                                                                "_labels.no_division"
                                                              )
                                                            : division}
                                                        </td>
                                                        <td></td>
                                                        <td className="text-right col3">
                                                          {formatter.format(
                                                            (corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["statistics"] *
                                                              100) /
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                "statistics"
                                                              ]
                                                          )}
                                                          %
                                                        </td>
                                                        <td className="text-right col3">
                                                          {formatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["statistics"]
                                                          )}
                                                        </td>
                                                        <th className="text-right col4">
                                                          <small>
                                                            {t(
                                                              "_labels.dynamic_cost_report.cost_km"
                                                            )}
                                                          </small>
                                                          <br />$
                                                          {costKmFormatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["cpk"]
                                                          )}
                                                        </th>
                                                        <th className="text-right col5">
                                                          <small>
                                                            {t(
                                                              "_labels.dynamic_cost_report.cost_mm"
                                                            )}
                                                          </small>
                                                          <br />$
                                                          {formatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["cpd"]
                                                          )}
                                                        </th>
                                                        <th className="text-right col5">
                                                          <small>km/mm</small>
                                                          <br />
                                                          {formatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["km/mm"]
                                                          )}
                                                        </th>
                                                      </tr>

                                                      {corporate[1][company][
                                                        subsidiary
                                                      ][division][
                                                        "applications"
                                                      ].map((application) => (
                                                        <Fragment
                                                          key={
                                                            application[
                                                              "application"
                                                            ]
                                                          }
                                                        >
                                                          <tr
                                                            data-type="revitalized"
                                                            data-id={removeCharacters(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                            )}
                                                            className={`box collapse application`}
                                                            onClick={() =>
                                                              toggleTable(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                                `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                                "revitalized"
                                                              )
                                                            }
                                                          >
                                                            <td className="text-left">
                                                              {t(
                                                                `_labels.tire_application.options.${application[
                                                                  "application"
                                                                ].toLowerCase()}`
                                                              )}
                                                            </td>
                                                            <td></td>
                                                            <td className="text-right col3">
                                                              {formatter.format(
                                                                application[
                                                                  "percent"
                                                                ]
                                                              )}
                                                              %
                                                            </td>
                                                            <td className="text-right col3">
                                                              {formatter.format(
                                                                application[
                                                                  "statistics"
                                                                ]
                                                              )}
                                                            </td>
                                                            <th className="text-right col4">
                                                              <small>
                                                                {t(
                                                                  "_labels.dynamic_cost_report.cost_km"
                                                                )}
                                                              </small>
                                                              <br />$
                                                              {costKmFormatter.format(
                                                                application[
                                                                  "cpk"
                                                                ]
                                                              )}
                                                            </th>
                                                            <th className="text-right col5">
                                                              <small>
                                                                {t(
                                                                  "_labels.dynamic_cost_report.cost_mm"
                                                                )}
                                                              </small>
                                                              <br />$
                                                              {formatter.format(
                                                                application[
                                                                  "cpd"
                                                                ]
                                                              )}
                                                            </th>
                                                            <th className="text-right col5">
                                                              <small>
                                                                km/mm
                                                              </small>
                                                              <br />
                                                              {formatter.format(
                                                                application[
                                                                  "km/mm"
                                                                ]
                                                              )}
                                                            </th>
                                                          </tr>

                                                          {application[
                                                            "sizes"
                                                          ].map((size) => (
                                                            <Fragment
                                                              key={size["size"]}
                                                            >
                                                              <tr
                                                                data-type="revitalized"
                                                                data-id={removeCharacters(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                                )}
                                                                className={`box collapse size`}
                                                                onClick={() =>
                                                                  toggleTable(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                    `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                    "revitalized"
                                                                  )
                                                                }
                                                              >
                                                                <td className="text-left">
                                                                  {size["size"]}
                                                                </td>
                                                                <td></td>
                                                                <td className="text-right col3">
                                                                  {formatter.format(
                                                                    size[
                                                                      "percent"
                                                                    ]
                                                                  )}
                                                                  %
                                                                </td>
                                                                <td className="text-right col3">
                                                                  {formatter.format(
                                                                    size[
                                                                      "statistics"
                                                                    ]
                                                                  )}
                                                                </td>
                                                                <th className="text-right col4">
                                                                  <small>
                                                                    {t(
                                                                      "_labels.dynamic_cost_report.cost_km"
                                                                    )}
                                                                  </small>
                                                                  <br />$
                                                                  {costKmFormatter.format(
                                                                    size["cpk"]
                                                                  )}
                                                                </th>
                                                                <th className="text-right col5">
                                                                  <small>
                                                                    {t(
                                                                      "_labels.dynamic_cost_report.cost_mm"
                                                                    )}
                                                                  </small>
                                                                  <br />$
                                                                  {formatter.format(
                                                                    size["cpd"]
                                                                  )}
                                                                </th>
                                                                <th className="text-right col5">
                                                                  <small>
                                                                    km/mm
                                                                  </small>
                                                                  <br />
                                                                  {formatter.format(
                                                                    size[
                                                                      "km/mm"
                                                                    ]
                                                                  )}
                                                                </th>
                                                              </tr>

                                                              {size[
                                                                "models"
                                                              ].map((model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="revitalized"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          tire_condition:
                                                                            "RETREAD_NEW,RETREAD_USED",
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              ))}
                                                            </Fragment>
                                                          ))}
                                                        </Fragment>
                                                      ))}
                                                    </Fragment>
                                                  ))}
                                              </Fragment>
                                            ))}
                                        </Fragment>
                                      ))}
                                  </tbody>
                                </Fragment>
                              )
                            )}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(oneData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.cost_first_cycle")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(oneData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "one"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="one"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "one"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="one"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "one"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="one"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "one"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={
                                                          application[
                                                            "application"
                                                          ]
                                                        }
                                                      >
                                                        <tr
                                                          data-type="one"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                              "one"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="one"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "one"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="one"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          number_cycle: 1,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(twoData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.cost_second_cycle")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(twoData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "two"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="two"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "two"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="two"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "two"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="two"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "two"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={
                                                          application[
                                                            "application"
                                                          ]
                                                        }
                                                      >
                                                        <tr
                                                          data-type="two"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                              "two"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="two"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "two"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="two"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          number_cycle: 2,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(treeData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.cost_third_cycle")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(treeData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "tree"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="tree"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "tree"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="tree"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "tree"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="tree"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "tree"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={
                                                          application[
                                                            "application"
                                                          ]
                                                        }
                                                      >
                                                        <tr
                                                          data-type="tree"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                              "tree"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="tree"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "tree"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="tree"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          number_cycle: 3,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(fourData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.dynamic_cost_report.cost_fourth_cycle")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(fourData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "four"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>
                                        {t("_labels.dynamic_cost_report.tires")}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_km"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.dynamic_cost_report.cost_mm"
                                        )}
                                      </small>
                                      <br />$
                                      {formatter.format(corporate[1]["cpd"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>km/mm</small>
                                      <br />
                                      {formatter.format(corporate[1]["km/mm"])}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="four"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "tree"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <th className="text-right col4">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_km"
                                              )}
                                            </small>
                                            <br />$
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>
                                              {t(
                                                "_labels.dynamic_cost_report.cost_mm"
                                              )}
                                            </small>
                                            <br />$
                                            {formatter.format(
                                              corporate[1][company]["cpd"]
                                            )}
                                          </th>
                                          <th className="text-right col5">
                                            <small>km/mm</small>
                                            <br />
                                            {formatter.format(
                                              corporate[1][company]["km/mm"]
                                            )}
                                          </th>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="four"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "tree"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <th className="text-right col4">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_km"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>
                                                    {t(
                                                      "_labels.dynamic_cost_report.cost_mm"
                                                    )}
                                                  </small>
                                                  <br />$
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpd"]
                                                  )}
                                                </th>
                                                <th className="text-right col5">
                                                  <small>km/mm</small>
                                                  <br />
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km/mm"]
                                                  )}
                                                </th>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="four"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "tree"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <th className="text-right col4">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_km"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>
                                                          {t(
                                                            "_labels.dynamic_cost_report.cost_mm"
                                                          )}
                                                        </small>
                                                        <br />$
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpd"]
                                                        )}
                                                      </th>
                                                      <th className="text-right col5">
                                                        <small>km/mm</small>
                                                        <br />
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km/mm"]
                                                        )}
                                                      </th>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division][
                                                      "applications"
                                                    ].map((application) => (
                                                      <Fragment
                                                        key={
                                                          application[
                                                            "application"
                                                          ]
                                                        }
                                                      >
                                                        <tr
                                                          data-type="four"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                          )}
                                                          className={`box collapse application`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`,
                                                              `${company}_${subsidiary}_${division}_${application["application"]}`,
                                                              "tree"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            {t(
                                                              `_labels.tire_application.options.${application[
                                                                "application"
                                                              ].toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                          <td className="text-right col3">
                                                            {formatter.format(
                                                              application[
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <th className="text-right col4">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_km"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {costKmFormatter.format(
                                                              application["cpk"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_labels.dynamic_cost_report.cost_mm"
                                                              )}
                                                            </small>
                                                            <br />$
                                                            {formatter.format(
                                                              application["cpd"]
                                                            )}
                                                          </th>
                                                          <th className="text-right col5">
                                                            <small>km/mm</small>
                                                            <br />
                                                            {formatter.format(
                                                              application[
                                                                "km/mm"
                                                              ]
                                                            )}
                                                          </th>
                                                        </tr>

                                                        {application[
                                                          "sizes"
                                                        ].map((size) => (
                                                          <Fragment
                                                            key={size["size"]}
                                                          >
                                                            <tr
                                                              data-type="four"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_sizes`
                                                              )}
                                                              className={`box collapse size`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`,
                                                                  `${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}`,
                                                                  "tree"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {size["size"]}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "percent"
                                                                  ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  size[
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <th className="text-right col4">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_km"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {costKmFormatter.format(
                                                                  size["cpk"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  {t(
                                                                    "_labels.dynamic_cost_report.cost_mm"
                                                                  )}
                                                                </small>
                                                                <br />$
                                                                {formatter.format(
                                                                  size["cpd"]
                                                                )}
                                                              </th>
                                                              <th className="text-right col5">
                                                                <small>
                                                                  km/mm
                                                                </small>
                                                                <br />
                                                                {formatter.format(
                                                                  size["km/mm"]
                                                                )}
                                                              </th>
                                                            </tr>

                                                            {size["models"].map(
                                                              (model) => (
                                                                <Fragment
                                                                  key={
                                                                    model[
                                                                      "model"
                                                                    ]
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="four"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application["application"]}_${size["size"]}_models`
                                                                    )}
                                                                    className={`box collapse model`}
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          tire_application_id:
                                                                            application[
                                                                              "application"
                                                                            ],
                                                                          size: size[
                                                                            "size"
                                                                          ],
                                                                          model:
                                                                            model[
                                                                              "model"
                                                                            ],
                                                                          number_cycle: 4,
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {
                                                                        model[
                                                                          "model"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        model[
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <th className="text-right col4">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_km"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {costKmFormatter.format(
                                                                        model[
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.dynamic_cost_report.cost_mm"
                                                                        )}
                                                                      </small>
                                                                      <br />$
                                                                      {formatter.format(
                                                                        model[
                                                                          "cpd"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                    <th className="text-right col5">
                                                                      <small>
                                                                        km/mm
                                                                      </small>
                                                                      <br />
                                                                      {formatter.format(
                                                                        model[
                                                                          "km/mm"
                                                                        ]
                                                                      )}
                                                                    </th>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                          </Fragment>
                                                        ))}
                                                      </Fragment>
                                                    ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TireDialog
        open={openTireDialog}
        onClose={handleCloseTireDialog}
        params={params}
      />
      <FilterDynamicCostForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  openFilter: PropTypes.bool.isRequired,
  generalData: PropTypes.object.isRequired,
  originalData: PropTypes.object.isRequired,
  revitalizedData: PropTypes.object.isRequired,
  oneData: PropTypes.object.isRequired,
  twoData: PropTypes.object.isRequired,
  treeData: PropTypes.object.isRequired,
  fourData: PropTypes.object.isRequired,
  policyNumberCycle: PropTypes.number.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  handleViewTires: PropTypes.func.isRequired,
  handleCloseTireDialog: PropTypes.func.isRequired,
  handleGenerateDynamicCostExcel: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string) {
  return string.replaceAll(/[,\s\.\/]/g, "");
}
