import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import css from "./index.module.scss";

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: true,
  locale: "en",
  scales: {
    y: {
      min: 0,
      stacked: true,
    },
    x: {
      stacked: true,
      type: "time",
      time: {
        parser: "YYYY-MM",
        tooltipFormat: "MMMM YYYY",
        unit: "quarter",
        displayFormats: {
          quarter: "MMM YYYY",
        },
      },
    },
  },
  parsing: {
    xAxisKey: "fecha",
    yAxisKey: "cantidad",
  },
  plugins: {
    legend: {
      position: "top",
      // align: 'start',
    },
  },
};

function Page({ t, open, onClose, data }) {
  return (
    <DialogMediaQuery maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>{t("_titles.historic")}</DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <div className="container-fluid px-0">
          <div className="mx-auto" style={{ width: "820px", height: "420px" }}>
            <Line options={defaultOptions} data={data} />
          </div>
        </div>
        <div className={classnames(css.action)}>
          <Button onClick={onClose} color="secondary">
            {t("_buttons.close")}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Page;
