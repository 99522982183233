export default {
  driver_name: "Driver's name",
  unassigned_driver: "Unassigned driver",
  change_driver: "Change driver",
  messages: {
    driver_changed: "Done, the driver has been successfully changed.",
    same_driver: "The driver is the same as above",
  },
  permits: {
    show: {
      vehicle_driver: "No permissions to view the driver linked to the vehicle",
    },
  },
};
