import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  className,
  notEmpty,
  empty,
  word,
  setWord,
  submitSearch,
  show,
  showInputSearch,
  t,
  handleKeyPress,
  clearText,
}) {
  return (
    <>
      <Button
        variant="icon"
        color="inherit"
        className={classnames(css.hide, {
          [css.hide_force]: show,
        })}
        onClick={showInputSearch}
      >
        <SearchIcon />
      </Button>
      <div
        className={classnames(css.show, {
          [css.show_force]: show,
        })}
      >
        <InputBase
          className={classnames(className, css.input, {
            [css.black]: word.trim() !== "",
            [css.white]: word.trim() === "",
            [notEmpty]: !!notEmpty && word.trim() !== "",
            [empty]: !!empty && word.trim() === "",
          })}
          placeholder={t("_labels.search")}
          value={word}
          onChange={(e) => setWord(e.target.value)}
          onKeyUp={handleKeyPress}
          startAdornment={
            <InputAdornment position="start">
              <Button
                variant="icon"
                test="submit"
                color="inherit"
                onClick={submitSearch}
              >
                <SearchIcon />
              </Button>
            </InputAdornment>
          }
          endAdornment={
            word.trim() === "" ? null : (
              <InputAdornment>
                <Button variant="icon" color="inherit" onClick={clearText}>
                  <ClearIcon />
                </Button>
              </InputAdornment>
            )
          }
        />
      </div>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  notEmpty: PropTypes.string,
  empty: PropTypes.string,
  show: PropTypes.bool.isRequired,
  word: PropTypes.string.isRequired,
  setWord: PropTypes.func.isRequired,
  submitSearch: PropTypes.func.isRequired,
  showInputSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  clearText: PropTypes.func.isRequired,
};

export default Page;
