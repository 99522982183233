import {
  GET_DEPTH_TOLERANCE_POLICY,
  CLEAR_DEPTH_TOLERANCE_POLICY,
  REGISTER_DEPTH_TOLERANCE_POLICY,
  GET_DEPTH_TOLERANCE_POLICIES,
  CLEAR_DEPTH_TOLERANCE_POLICIES,
  UPDATE_DEPTH_TOLERANCE_POLICY,
  DELETE_DEPTH_TOLERANCE_POLICY,
} from "store/actions";

const initialState = {
  policy: {
    depth_tolerance_policy_id: 0,
    tire_application_id: "",
    good_condition: 0,
    scheduled_withdrawal: 0,
    critical_withdrawal: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEPTH_TOLERANCE_POLICY:
    case UPDATE_DEPTH_TOLERANCE_POLICY:
    case REGISTER_DEPTH_TOLERANCE_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

    case CLEAR_DEPTH_TOLERANCE_POLICY:
      return {
        ...state,
        policy: initialState.policy,
      };

    case GET_DEPTH_TOLERANCE_POLICIES:
      return {
        ...state,
        policies: action.payload,
      };

    case CLEAR_DEPTH_TOLERANCE_POLICIES:
      return {
        ...state,
        policies: initialState.policies,
      };

    case DELETE_DEPTH_TOLERANCE_POLICY:
      return {
        ...state,
        policies: {
          ...state.policies,
          data: state.policies.data.filter(
            (policy) => policy.depth_tolerance_policy_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
