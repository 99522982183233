export default {
  division: "Division",
  link_division: "Link division",
  division_name: "Division name",
  unassigned_division: "Unassigned division",
  permits: {
    delete_link: {
      vehicle_with_division: "No permissions to unlink divisions to vehicles",
    },
    show: {
      vehicle_with_division:
        "No permissions to view divisions linked to vehicles",
    },
    list: {
      division: "No permissions to list divisions",
    },
  },
  messages: {
    unlink_division_confirmation: "Are you sure to unlink the division?",
    vehicle_with_division_link_deleted:
      "Done, the division was successfully unlinked from the vehicle",
  },
  form: {
    select_a_division: "Select a division",
  },
};
