import { SET_TIRE_MODEL_VARIATION_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetTireModelVariationStatus =
  (dispatch) => async (tireModelVariationId, tireModelId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/model/${tireModelId}/variation/${tireModelVariationId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_TIRE_MODEL_VARIATION_STATUS,
        payload: { tireModelId, tireModelVariationId },
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
