import {
  CLEAR_TIRE_REPAIR,
  DELETE_TIRE_REPAIR,
  GET_TIRE_REPAIR,
  REGISTER_TIRE_REPAIR,
  UPDATE_TIRE_REPAIR,
} from "store/actions";

const initialState = {
  tire_repair: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_TIRE_REPAIR:
    case UPDATE_TIRE_REPAIR:
    case GET_TIRE_REPAIR:
      return {
        ...state,
        tire_repair: action.payload,
      };

    case CLEAR_TIRE_REPAIR:
    case DELETE_TIRE_REPAIR:
      return {
        ...state,
        tire_repair: initialState.tire_repair,
      };

    default:
      return state;
  }
}
