import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleSubsidiariesHistory } from "store/actions/vehicle/subsidiary/history/getVehicleSubsidiariesHistory";
import { actionClearVehicleSubsidiariesHistory } from "store/actions/vehicle/subsidiary/history/clearVehicleSubsidiariesHistory";

import { actionSetVehicleSubsidiaryHistoryFilters } from "store/actions/vehicle/subsidiary/history/setVehicleSubsidiaryHistoryFilters";
import { actionClearVehicleSubsidiaryHistoryFilters } from "store/actions/vehicle/subsidiary/history/clearVehicleSubsidiaryHistoryFilters";

import { useLang } from "hooks/lang";
export const NAMESPACE = "pages/vehicle/history/VehicleSubsidiaryHistoryTab";

import Page from "./page";

export function VehicleSubsidiaryHistoryTab({ ...rest }) {
  const {
    filter,
    subsidiaries,
    setNotification,
    getVehicleSubsidiariesHistory,
    clearVehicleSubsidiariesHistory,
    setVehicleSubsidiaryHistoryFilters,
    clearVehicleSubsidiaryHistoryFilters,
  } = rest;
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const { id: vehicleId } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleSubsidiariesHistory({ vehicleId, page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearVehicleSubsidiariesHistory();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearVehicleSubsidiaryHistoryFilters();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsidiaries.data]);

  async function handleChangePage(page) {
    try {
      await getVehicleSubsidiariesHistory({ vehicleId, page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleExitHistory() {
    history.push(`/vehicle/${vehicleId}`);
  }

  function handleOpenFilter() {
    setFilterOpen(true);
  }

  function handleCloseFilter() {
    setFilterOpen(false);
  }

  function handleSearch(search) {
    setVehicleSubsidiaryHistoryFilters({ search });
  }

  return (
    <Page
      t={t}
      subsidiaries={subsidiaries}
      filterOpen={filterOpen}
      search={filter.search}
      handleSearch={handleSearch}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleChangePage={handleChangePage}
      handleExitHistory={handleExitHistory}
    />
  );
}

VehicleSubsidiaryHistoryTab.propTypes = {};

const mapStateToProps = (state) => ({
  subsidiaries: state.Vehicle.Subsidiary.History.subsidiaries,
  filter: state.Vehicle.Subsidiary.History.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  clearVehicleSubsidiariesHistory:
    actionClearVehicleSubsidiariesHistory(dispatch),
  getVehicleSubsidiariesHistory: actionGetVehicleSubsidiariesHistory(dispatch),
  setVehicleSubsidiaryHistoryFilters:
    actionSetVehicleSubsidiaryHistoryFilters(dispatch),
  clearVehicleSubsidiaryHistoryFilters:
    actionClearVehicleSubsidiaryHistoryFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleSubsidiaryHistoryTab);
