import {
  ASSIGN_RFID_TO_TIRE,
  GET_TIRE_RFID,
  CLEAR_TIRE_RFID,
} from "store/actions";

const initialState = {
  rfid: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_RFID_TO_TIRE:
    case GET_TIRE_RFID:
      return {
        ...state,
        rfid: action.payload,
      };

    case CLEAR_TIRE_RFID:
      return {
        ...state,
        rfid: initialState.rfid,
      };

    default:
      return state;
  }
}
