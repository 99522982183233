import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Field, Form } from "formik";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";
import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  vehicleBrands,
  vehicleTypes,
  drivers,
  vehicles,
  onClose,
  isSubmitting,
  isValid,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="vehicle_brand_id"
        component={SelectField}
        label={t("_labels.vehicle_brand.label")}
        className={classnames(css.field)}
      >
        {vehicleBrands.map((brand, index) => {
          return (
            <MenuItem
              key={index}
              value={brand.brand_id.toString()}
              disabled={!brand.status}
            >
              {brand.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="vehicle_type_id"
        component={SelectField}
        label={t("_labels.vehicle_type.label")}
        className={classnames(css.field)}
      >
        {vehicleTypes.map((vehicleType, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicleType.vehicle_type_id.toString()}
              disabled={!vehicleType.status}
            >
              {vehicleType.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="vehicle"
        component={SelectField}
        label={t("_labels.economic_number")}
        className={classnames(css.field)}
      >
        {vehicles.map((vehicle, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicle.vehicle_id.toString()}
              disabled={!vehicle.status}
            >
              {vehicle.economic_number}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="driver_id"
        component={SelectField}
        label={t("_labels.driver.singular")}
        className={classnames(css.field)}
      >
        {drivers.map((driver, index) => {
          return (
            <MenuItem
              key={index}
              value={driver.driver_id.toString()}
              disabled={!driver.status}
            >
              {driver.name}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
