import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionUpdateAssociationPressurePolicy } from "store/actions/association/policy/pressure/updateAssociationPressurePolicy";
import { actionClearAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/clearAssociationPressureFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function UpdatePressurePolicyForm({
  open,
  onClose,
  vehicle,
  pressurePolicy,
  ...rest
}) {
  const t = useLang();
  const { addPressurePolicy, clearPressurePoliciesFilters, setNotification } =
    rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      axle_type: "",
      recommended_pressure: "",
      tolerance: "",
    },
  });

  const { id: associationId } = useParams();

  useEffect(() => {
    if (open && Object.keys(pressurePolicy).length > 0) {
      setInitialValues({
        axle_type: pressurePolicy.axle_type,
        recommended_pressure: pressurePolicy.recommended_pressure,
        tolerance: pressurePolicy.tolerance,
      });
    } else {
      resetFormik();
    }
  }, [open, pressurePolicy]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        ...values,
      };
      await addPressurePolicy(
        {
          associationId,
          commissionedVehicleId: vehicle.commissioned_vehicle_id,
          commissionedVehiclePressurePolicyId:
            pressurePolicy.commissioned_pressure_policy_id,
        },
        fields
      );

      resetForm();
      setNotification({
        message: t("_messages.updated.pressure_policy"),
      });

      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicle={vehicle}
      initialErrors={initialErrors}
      initialValues={initialValues}
      // isUpdate={isUpdate}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UpdatePressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehicle: PropTypes.object,
  pressurePolicy: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addPressurePolicy: actionUpdateAssociationPressurePolicy(dispatch),
  clearPressurePoliciesFilters:
    actionClearAssociationPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePressurePolicyForm);
