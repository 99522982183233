import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionRecoveryPassword = (dispatch) => async (email) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "POST",
      path: "auth/password/email",
      data: email,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
