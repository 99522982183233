import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "components/molecules/general/SearchInput";

function SearchInput({ search, onChange, className, notEmpty, empty }) {
  const t = useLang(NAMESPACE);
  const [word, setWord] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (search) {
      setWord(search);
      return;
    }

    setWord("");
  }, [search]);

  function submitSearch() {
    onChange(word);
  }

  function showInputSearch() {
    setShow(true);
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      submitSearch();
      setShow(false);
    }
  }

  function clearText() {
    setWord("");
    setShow(false);
    onChange(null);
  }

  return (
    <Page
      search={search}
      word={word}
      setWord={setWord}
      submitSearch={submitSearch}
      show={show}
      showInputSearch={showInputSearch}
      t={t}
      handleKeyPress={handleKeyPress}
      clearText={clearText}
      className={className}
      notEmpty={notEmpty}
      empty={empty}
    />
  );
}

SearchInput.propTypes = {
  search: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  notEmpty: PropTypes.string,
  empty: PropTypes.string,
};

export default SearchInput;
