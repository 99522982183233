import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { TextField } from "formik-material-ui";
import { Formik, Field, FieldArray, Form } from "formik";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import LabelAndText from "components/molecules/general/LabelAndText";
import DateTimeField from "components/molecules/general/DateTimeField";

import Button from "components/molecules/general/Button";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

import css from "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "#4eacdf",
    color: theme.palette.common.white,
    textAlign: "center",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  body: {
    textAlign: "center",
    paddingTop: "1px",
    paddingBottom: "0px",
  },
}));

function Page({
  t,
  review,
  vehicleTypeAxle,
  axleTires,
  hasGpsOdometer,
  isLoading,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
  sendPressureSettingPolicy,
  setIsRequired,
  getRecommendedPressure,
}) {
  const classes = useStyles();

  return (
    <>
      {axleTires.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <Card className={classnames(css.card)} elevation={1}>
              <Formik
                enableReinitialize
                initialErrors={initialErrors}
                initialValues={initialValues}
                validate={handleValidate}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <CardContent className={classnames(css.pt4)}>
                      <div className={classnames(css.content)}>
                        <Typography
                          className={classnames(css.mb0)}
                          variant="h6"
                          color="textPrimary"
                        >
                          {t("_titles.show.inspection")}
                        </Typography>
                        <div className={classnames(css.flex, css.content_info)}>
                          <Typography
                            className={classnames(css.full_width)}
                            variant="overline"
                            color="textSecondary"
                          >
                            {t("_labels.last_review")}
                          </Typography>

                          <Typography
                            className={classnames(css.full_width)}
                            variant="body1"
                            color="textPrimary"
                          >
                            {`${t("_labels.done_by")} ${review.created_by}`}
                          </Typography>

                          <div className={classnames(css.flex, css.w100)}>
                            <LabelAndText
                              className={classnames(css.full_width)}
                              label={t("_labels.odometer")}
                              text={review.odometer}
                            />

                            <LabelAndText
                              className={classnames(css.full_width)}
                              label={t("_labels.review_type.label")}
                              text={t(
                                `_labels.review_type.options.${review.review_type
                                  .toLowerCase()
                                  .replace(/\s|\//g, "_")}`
                              )}
                            />
                          </div>
                          <Typography
                            className={classnames(css.full_width)}
                            variant="overline"
                            color="textSecondary"
                          >
                            {t("_labels.new_review")}
                          </Typography>

                          <div className={classnames(css.full_width)}>
                            <div className={classnames(css.full_width)}>
                              <Field
                                required
                                variant="filled"
                                name="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type="date"
                                component={DateTimeField}
                                label={t("_labels.date.label")}
                                className={classnames(
                                  css.medium_field,
                                  css.mr8
                                )}
                                InputProps={{
                                  inputProps: {
                                    max: moment().format("YYYY-MM-DD"),
                                  },
                                }}
                              />

                              <Field
                                required
                                variant="filled"
                                name="odometer"
                                component={TextField}
                                type="number"
                                className={classnames(css.medium_field)}
                                label={t("_labels.odometer")}
                                disabled={hasGpsOdometer}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      className={classnames(css.adornments)}
                                      position="start"
                                    >
                                      km
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    step: "any",
                                    min: hasGpsOdometer
                                      ? initialValues.odometer
                                      : parseFloat(review.odometer),
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classnames(css.pl8, css.pr8)}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.head}
                                  width={"5%"}
                                >
                                  {t("_general.position")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.code")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.tire_model_field.label")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.size")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.pressure")}
                                </TableCell>
                                <TableCell
                                  className={classes.head}
                                  width={"10%"}
                                >
                                  {t(
                                    "_labels.tire_model_variation.recommended_pressure"
                                  )}
                                </TableCell>
                                <TableCell className={classes.head}></TableCell>
                              </TableRow>
                            </TableHead>
                            <FieldArray
                              name="review"
                              validateOnChange={false}
                              render={() => (
                                <TableBody>
                                  {axleTires.map((axleTire, index) => (
                                    <TableRow
                                      key={axleTire.vehicle_type_axle_tire_id}
                                    >
                                      <TableCell className={classes.body}>
                                        {axleTire.position}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {axleTire.vehicle_tire.movement_tire
                                          .tire_cycle.tire.code ||
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.tire.device_code}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {axleTire.vehicle_tire.movement_tire
                                          .tire_cycle.revitalized?.name ||
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.variation?.tire_model
                                            .name}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.variation.tire_size.size
                                        }
                                      </TableCell>
                                      <TableCell className={classes.body}>{`${
                                        axleTire.vehicle_tire.movement_tire
                                          .tire_review[
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_review.length - 1
                                        ].pressure
                                      } psi`}</TableCell>
                                      <TableCell
                                        className={classes.body}
                                      >{`${getRecommendedPressure(
                                        vehicleTypeAxle,
                                        axleTire
                                      ).toFixed(2)} psi`}</TableCell>
                                      <TableCell className={classes.body}>
                                        <Field
                                          size="small"
                                          variant="filled"
                                          name={`review[${index}].pressure`}
                                          component={TextField}
                                          type="number"
                                          label={t("_labels.pressure")}
                                          className={classnames(css.field)}
                                          InputProps={{
                                            inputProps: {
                                              min: 0,
                                              step: "any",
                                            },
                                            endAdornment: (
                                              <InputAdornment
                                                className={classnames(
                                                  css.adornments
                                                )}
                                                position="start"
                                              >
                                                psi
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            />
                          </Table>
                        </TableContainer>
                      </div>
                    </CardContent>
                    <CardActions className={css.actions}>
                      <Button
                        type="submit"
                        color="secondary"
                        disabled={
                          isSubmitting ||
                          isLoading ||
                          values.review.every((tire) => tire.pressure === "")
                        }
                      >
                        {t("_buttons.save")}
                      </Button>
                      {!!sendPressureSettingPolicy && (
                        <Button
                          disabled={
                            !values.date || !values.odometer || isLoading
                          }
                          tooltip={
                            !values.date || !values.odometer
                              ? t("_errors.adjust_pressure")
                              : ""
                          }
                          type={
                            values.date && values.odometer ? "submit" : "button"
                          }
                          color="secondary"
                          onClick={() => {
                            values.date && values.odometer
                              ? setIsRequired(false)
                              : "";
                          }}
                        >
                          {t("_buttons.pressure_adjustment")}
                        </Button>
                      )}
                    </CardActions>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  vehicleTypeAxle: PropTypes.array.isRequired,
  axleTires: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasGpsOdometer: PropTypes.bool.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handlePressureAdjustment: PropTypes.func.isRequired,
  sendPressureSettingPolicy: PropTypes.number.isRequired,
  isRequired: PropTypes.bool.isRequired,
  setIsRequired: PropTypes.func.isRequired,
  getRecommendedPressure: PropTypes.func.isRequired,
};

export default Page;
