import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

function Page({ children, ...props }) {
  if (children) {
    return <FormControlLabel control={<Radio />} label={children} {...props} />;
  }
  return <FormControlLabel control={<Radio />} {...props} />;
}

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
