import React from "react";
import Page from "./page";
import PropTypes from "prop-types";

function ReviewInfoTable(props) {
  return <Page {...props} />;
}

ReviewInfoTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  economicNumber: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  isFinished: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  vehicleKm: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  driverName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default ReviewInfoTable;
