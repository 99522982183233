import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionDeleteNotification } from "store/actions/notification/deleteNotification";
import { actionGetNotifications } from "store/actions/notification/getNotifications";
import { actionDeleteAllNotification } from "store/actions/notification/deleteAllNotification";

import Page from "./page";

export function NotificationDialog({ open, ...rest }) {
  const t = useLang();
  const {
    notifications,
    setNotification,
    deleteNotification,
    deleteAllNotification,
    getNotifications,
  } = rest;

  async function handleDelete(id) {
    try {
      const response = await deleteNotification(id);
      setNotification({ message: response.message });
      getNotifications();
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDeleteAll() {
    try {
      const response = await deleteAllNotification();
      setNotification({ message: response.message });
      getNotifications();
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      {...rest}
      t={t}
      open={open}
      notifications={notifications}
      handleDelete={handleDelete}
      handleDeleteAll={handleDeleteAll}
    />
  );
}

NotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notifications: state.UserNotifications.notifications,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  deleteNotification: actionDeleteNotification(dispatch),
  deleteAllNotification: actionDeleteAllNotification(dispatch),
  getNotifications: actionGetNotifications(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDialog);
