import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import moment from "moment-timezone";
import Markdown from "markdown-to-jsx";
import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import css from "./index.module.scss";

function Page({
  t,
  open,
  onClose,
  notifications,
  handleDelete,
  handleDeleteAll,
}) {
  return (
    <>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>{t("_general.notifications")}</DialogTitle>
        <DialogContent>
          <div className={classnames(css.content_info)}>
            {notifications.length > 0 && (
              <div className="mb-2 d-flex justify-content-end">
                <button
                  className="btn btn-info w-auto"
                  onClick={handleDeleteAll}
                >
                  {t("_labels.discard_all")}
                </button>
              </div>
            )}
            {notifications.map((n) => (
              <div key={n.id} className={classnames(css.notification)}>
                <p className={classnames(css.notification_title)}>
                  {n.data.title}
                </p>
                <Markdown>{n.data.body}</Markdown>
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-sm btn-info w-auto"
                    onClick={() => handleDelete(n.id)}
                  >
                    {t("_labels.discard")}
                  </button>
                  <p className={classnames(css.notification_date)}>
                    {moment(n.created_at).format("LL h:mm a")}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDeleteAll: PropTypes.func.isRequired,
};

export default Page;
