export default {
  title: "Nueva causa de retiro",
  update: "Actualizar causa de retiro",
  messages: {
    retirement_cause_created:
      "Listo, se creo una nueva causa de retiro con éxito",
    retirement_cause_updated:
      "Listo, se actualizó la causa de retiro con éxito",
    retirement_cause_deleted: "Listo, se eliminó la causa de retiro con éxito",

    delete: "¿Estas seguro de eliminar la causa de retiro?",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar causas de retiro",
      delete: "Sin permisos para eliminar causas de retiro",
    },
  },
};
