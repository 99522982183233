import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import BarChart from "components/organisms/general/Charts/BarChart";
import FilterTireStatsForm from "components/organisms/report/FilterTireStatsForm";
import Button from "components/molecules/general/Button";
import { TireDialog } from "./TireDialog";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en");

function Page({
  t,
  current,
  data,
  dataChart,
  selectedKey,
  handleClick,
  handleDetailsClick,
  hide,
  setHide,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
  detailsData,
  openTireDialog,
  setOpenTireDialog,
}) {
  return (
    <ContainerTemplate
      title={t("_titles.tire_stats_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100 px24">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.company_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiary_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.movement ? (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.location.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.movement}
                    </p>
                  </div>
                ) : (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.location.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {t("_labels.report_summary.location.mount")}
                    </p>
                  </div>
                )}
                {"with_refection" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.show_refection")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.with_refection}
                    </p>
                  </div>
                )}
                {filter.brands && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.brand_field.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.brands}
                    </p>
                  </div>
                )}
                {filter.models && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.tire_model_field.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.models}
                    </p>
                  </div>
                )}
                {filter.sizes && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>{t("_labels.size")}</p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.sizes}
                    </p>
                  </div>
                )}
                {filter.revitalized_brands && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.revitalized_brand_field.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.revitalized_brands}
                    </p>
                  </div>
                )}
                {filter.revitalized_models && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.revitalized_model_field.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.revitalized_models}
                    </p>
                  </div>
                )}
                {filter.date_from && filter.date_to && (
                  <>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.from")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_from).format("LL")}
                      </p>
                    </div>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.to")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_to).format("LL")}
                      </p>
                    </div>
                  </>
                )}
                {filter.activity ? (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.activity.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.activity}
                    </p>
                  </div>
                ) : (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.activity.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {t("_labels.activity.options.not_rule_out")}
                    </p>
                  </div>
                )}
                {filter.review_type ? (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.review_type.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.review_type}
                    </p>
                  </div>
                ) : (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.review_type.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {t("_labels.no_option")}
                    </p>
                  </div>
                )}
                {"number_cycle" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.number_cycle")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.number_cycle}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-md-12">
            <hr />
            <div className="row">
              <div
                className={classnames("col-md-2 pointer", css.header_tab)}
                onClick={(e) => handleClick(e, "brand_name")}
              >
                <span>{t("_general.brands").toUpperCase()}</span>
              </div>
              <div
                className={classnames("col-md-2 pointer", css.header_tab)}
                onClick={(e) => handleClick(e, "size")}
              >
                <span>{t("_general.sizes").toUpperCase()}</span>
              </div>
              <div
                className={classnames("col-md-2 pointer", css.header_tab)}
                onClick={(e) => handleClick(e, "depth")}
              >
                <span>{t("_labels.depths").toUpperCase()}</span>
              </div>
              <div
                className={classnames("col-md-2 pointer", css.header_tab)}
                onClick={(e) => handleClick(e, "model")}
              >
                <span>{t("_labels.models").toUpperCase()}</span>
              </div>
              <div
                className={classnames("col-md-2 pointer", css.header_tab)}
                onClick={(e) => handleClick(e, "number_cycle")}
              >
                <span>{t("_labels.cycles").toUpperCase()}</span>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: 30 }}>
            <h1>
              {t(
                `_labels.tire_stats_report.options.${selectedKey}`
              ).toUpperCase()}
            </h1>
          </div>
        </div>
        <div className="row pdt24">
          <div className="col-md-12">
            <div className="chart-container">
              {(dataChart.labels || dataChart.length > 0) && (
                <BarChart
                  options={
                    dataChart.labels
                      ? {
                          responsive: true,
                          maintainAspectRatio: false,
                          fill: true,
                          locale: "en",
                          scales: {
                            y: {
                              min: 0,
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.number_of_tires"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            x: {
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.range_of_depths"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          plugins: {
                            legend: {
                              position: "top",
                              labels: {
                                font: {
                                  size: 14,
                                  weight: "bold",
                                },
                              },
                              // align: 'start',
                            },
                          },
                        }
                      : null
                  }
                  data={dataChart}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row pdt24">
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={() => setHide(!hide)}>
              {t("_buttons.see_table")}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleGenerateExcel()}
            >
              {t("_buttons.generate_excel")}
            </button>
          </div>
          <div className="col-md-12 pdt24">
            <div className={`box ${hide ? "hide" : ""}`} id="collapseMarcas">
              <div className="card card-body sinmp" style={{ padding: 0 }}>
                <div className="row">
                  {data.length === 2 && (
                    <>
                      <div className="col-md-6">
                        <table className="table table-hover">
                          <thead className="sbgazul">
                            <tr>
                              <td>
                                {t(
                                  `_labels.tire_stats_report.headers.${selectedKey}`
                                ).toUpperCase()}
                              </td>
                              <td className="text-right">
                                {t(
                                  "_labels.tire_stats_report.headers.number_of_tires"
                                ).toUpperCase()}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data[0].map((row, index) => (
                              <tr
                                key={index}
                                onClick={() => {
                                  handleDetailsClick(row[0]);
                                }}
                              >
                                <td>{row[0]}</td>
                                <td className="text-right">{row[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-6">
                        <table className="table table-hover">
                          <thead className="sbgazul">
                            <tr>
                              <td>
                                {t(
                                  `_labels.tire_stats_report.headers.${selectedKey}`
                                ).toUpperCase()}
                              </td>
                              <td className="text-right">
                                {t(
                                  "_labels.tire_stats_report.headers.number_of_tires"
                                ).toUpperCase()}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data[1].map((row, index) => (
                              <tr
                                key={index}
                                onClick={() => {
                                  handleDetailsClick(row[0]);
                                }}
                              >
                                <td>{row[0]}</td>
                                <td className="text-right">{row[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {data.length === 1 && (
                    <div className="col-md-6">
                      <table className="table table-hover">
                        <thead className="sbgazul">
                          <tr>
                            <td>
                              {t(
                                `_labels.tire_stats_report.headers.${selectedKey}`
                              ).toUpperCase()}
                            </td>
                            <td className="text-right">
                              {t(
                                "_labels.tire_stats_report.headers.number_of_tires"
                              ).toUpperCase()}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data[0].map((row, index) => (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  handleDetailsClick(row[0]);
                                }}
                              >
                                {row[0]}
                              </td>
                              <td className="text-right">
                                {formatter.format(row[1])}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TireDialog
        open={openTireDialog}
        onClose={() => setOpenTireDialog(false)}
        tires={detailsData}
      />

      <FilterTireStatsForm
        open={openFilter}
        onClose={handleCloseFilter}
        current={current}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  current: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  dataChart: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  selectedKey: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDetailsClick: PropTypes.func.isRequired,
  setHide: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
  detailsData: PropTypes.array.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  setOpenTireDialog: PropTypes.func.isRequired,
};

export default Page;
