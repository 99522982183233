import React from "react";
import { connect } from "react-redux";

import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionClearBrands } from "store/actions/brand/clearBrands";
import { actionSetBrandFilters } from "store/actions/brand/setBrandFilters";
import { actionClearBrandFilters } from "store/actions/brand/clearBrandFilters";
import { actionDeleteBrand } from "store/actions/brand/deleteBrand";
import { actionSetBrandStatus } from "store/actions/brand/setBrandStatus";
import { actionSetBrandApprovalStatus } from "store/actions/brand/setBrandApprovalStatus";
import { actionGetBrand } from "store/actions/brand/getBrand";
import { actionAddBrand } from "store/actions/brand/addBrand";
import { actionUpdateBrand } from "store/actions/brand/updateBrand";

import { useLang } from "hooks/lang";

import {
  CREATE_BRAND,
  UPDATE_BRAND,
  DELETE_BRAND,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeBrand({ ...rest }) {
  const t = useLang();
  const {
    permits,
    brand,
    brands,
    filter,
    deleteBrand,
    setBrandStatus,
    setBrandApprovalStatus,
    getBrand,
    addBrand,
    updateBrand,
    getBrands,
    clearBrands,
    setBrandFilters,
    clearBrandFilters,
  } = rest;

  return (
    <Page
      brands={brands}
      brand={brand}
      filter={filter}
      createBrandPermit={permits.includes(CREATE_BRAND)}
      updateBrandPermit={permits.includes(UPDATE_BRAND)}
      deleteBrandPermit={permits.includes(DELETE_BRAND)}
      t={t}
      getBrand={getBrand}
      addBrand={addBrand}
      updateBrand={updateBrand}
      clearBrandFilters={clearBrandFilters}
      setBrandFilters={setBrandFilters}
      deleteBrand={deleteBrand}
      setBrandStatus={setBrandStatus}
      setBrandApprovalStatus={setBrandApprovalStatus}
      getBrands={getBrands}
      clearBrands={clearBrands}
    />
  );
}

HomeBrand.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  brands: state.Brand.brands,
  filter: state.Brand.filter_brand,
  brand: state.Brand.brand,
});
const mapDispatchToProps = (dispatch) => ({
  getBrands: actionGetBrands(dispatch),
  clearBrands: actionClearBrands(dispatch),
  setBrandFilters: actionSetBrandFilters(dispatch),
  clearBrandFilters: actionClearBrandFilters(dispatch),
  deleteBrand: actionDeleteBrand(dispatch),
  setBrandStatus: actionSetBrandStatus(dispatch),
  setBrandApprovalStatus: actionSetBrandApprovalStatus(dispatch),
  getBrand: actionGetBrand(dispatch),
  addBrand: actionAddBrand(dispatch),
  updateBrand: actionUpdateBrand(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeBrand);
