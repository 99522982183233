import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VirtualizedTable from "components/organisms/general/VirtualizedTable";
import { alphabeticalSort } from "utils/sort";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";
// import "./index.css";

function Page({
  t,
  open,
  onClose,
  tires,
  sortedData,
  detailType,
  handleViewAlerts,
  handleClickTire,
}) {
  const columns = [
    {
      dataKey: "code",
      label: t("_labels.code"),
    },
    {
      dataKey: "model",
      label: t("_labels.model"),
    },
    {
      dataKey: "size",
      label: t("_labels.size"),
    },
    {
      dataKey: "location",
      label: t("_labels.location.label"),
    },
    {
      dataKey: "position",
      label: t("_labels.vehicle_type_axle_position.label"),
    },
    {
      dataKey: "refection",
      label: t("_labels.vehicle_type_axle_position.options.refection"),
    },
    {
      dataKey: "pressure",
      label:
        detailType == "DEPTH"
          ? t("_labels.depth")
          : detailType == "PRESSURE"
          ? t("_labels.pressure")
          : t("_labels.see_alerts"),
    },
  ];

  if (["DEPTH", "PRESSURE"].includes(detailType)) {
    columns.push({
      dataKey: "policy",
      label:
        detailType == "DEPTH"
          ? t("_general.policies")
          : t("_labels.tire_model_variation.recommended_pressure"),
    });
  }
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {tires.length
              ? `: ${tires.length}`
              : `: ${Object.keys(tires).length}`}
          </span>
          {!Array.isArray(tires) && (
            <span className="float-right">{`${t(
              "_general.alerts"
            )}: ${Object.values(tires).reduce(
              (acc, val) => acc + val.length,
              0
            )}`}</span>
          )}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            style={{
              backgroundColor: "lightgray",
              color: "black",
            }}
          >
            <table style={{ visibility: "collapse" }}>
              <tbody>
                <tr>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                  <td className="text-capitalize px-5 font-weight-normal">
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>

            <VirtualizedTable
              width={800}
              height={500}
              rows={
                Array.isArray(tires)
                  ? tires.sort(alphabeticalSort("code")).map((tire) => ({
                      code: (
                        <Button
                          style={{
                            color: "white",
                          }}
                          className={classnames(css.spanButton)}
                          onClick={() => {
                            handleClickTire(tire["tire_id"]);
                          }}
                        >
                          <VisibilityIcon
                            className="mr-2"
                            style={{ fontSize: "1.2rem" }}
                          />
                          <div
                            style={{
                              lineHeight: "1.2rem",
                            }}
                          >
                            {tire["code"]}
                          </div>
                        </Button>
                      ),
                      model: tire["model"],
                      size: tire["size"],
                      location: tire["location"] || "-",
                      position: tire["real_position"] || "-",
                      refection: tire["is_refection"]
                        ? t("_labels.yes")
                        : t("_labels.no"),
                      pressure:
                        detailType === "DEPTH"
                          ? `${tire["depth"]} mm`
                          : `${tire["pressure"]} psi`,
                      policy:
                        detailType === "PRESSURE" ? (
                          `${tire["recommended_pressure"]} psi`
                        ) : (
                          <>
                            <BootstrapTooltip
                              title={t("_labels.scheduled_withdrawal")}
                              placement="left"
                            >
                              <span className={classnames(css.scheduled)}>
                                {tire["depth_schedule_policy"]} mm
                              </span>
                            </BootstrapTooltip>
                            <BootstrapTooltip
                              title={t("_labels.critical_withdrawal")}
                              placement="right"
                            >
                              <span className={classnames(css.critical)}>
                                {tire["depth_critical_policy"]} mm
                              </span>
                            </BootstrapTooltip>
                          </>
                        ),
                    }))
                  : sortedData.map((tire) => ({
                      code: tires[tire][0]["code"],
                      model: tires[tire][0]["model"],
                      size: tires[tire][0]["size"],
                      location: tires[tire][0]["location"] || "-",
                      position: tires[tire][0]["real_position"] || "-",
                      refection: tires[tire][0]["is_refection"]
                        ? t("_labels.yes")
                        : t("_labels.no"),
                      pressure: (
                        <>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 3,
                            }}
                            onClick={() => handleViewAlerts(tires[tire])}
                          >
                            <span>{tires[tire].length}</span>
                            <VisibilityIcon />{" "}
                          </button>
                        </>
                      ),
                    }))
              }
              columns={columns}
              rowRenderer={() => {}}
            />
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sortedData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  detailType: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleViewAlerts: PropTypes.func,
  handleClickTire: PropTypes.func,
};

export default Page;
