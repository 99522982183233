import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetAlertsOfTireFilters } from "store/actions/tire/alert/setAlertsOfTireFilters";
import { actionClearAlertsOfTireFilters } from "store/actions/tire/alert/clearAlertsOfTireFilters";
import { useLang } from "hooks/lang";

import { useFormik } from "hooks/formik";

import Page from "./page";

export function FilterAlertsOfTireForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { filter, setTireAlertsFilters, clearTireAlertsFilters } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      date_from: "",
      date_to: "",
      ranking_alert: "",
      priority: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        ranking_alert: filter.ranking_alert || "",
        priority: filter.priority || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearTireAlertsFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      if (values.ranking_alert) {
        params.ranking_alert = values.ranking_alert;
      }

      if (values.priority) {
        params.priority = values.priority;
      }

      await setTireAlertsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleClean={handleClean}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

FilterAlertsOfTireForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Tire.Alert.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setTireAlertsFilters: actionSetAlertsOfTireFilters(dispatch),
  clearTireAlertsFilters: actionClearAlertsOfTireFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterAlertsOfTireForm);
