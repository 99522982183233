import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";

function Page({
  t,
  open,
  summary,
  provider,
  updateProviderPermit,
  onClose,
  onDialog,
  handleChangeStatus,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{provider.name}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <LabelAndText
            gutterBottom
            label={t("_labels.company.singular")}
            text={provider.company.tradename}
          />

          <div
            className={classnames(
              { [css.horizontal]: !summary },
              { [css.summary]: summary }
            )}
          >
            {provider.rfc && (
              <LabelAndText
                gutterBottom
                label={t("_labels.rfc")}
                text={provider.rfc}
              />
            )}
            {!summary && (
              <LabelAndText
                className={classnames(css.status)}
                gutterBottom
                label={t("labels.status")}
                text={
                  provider.status
                    ? t("_general.enabled.singular.male")
                    : t("_general.disabled.singular.male")
                }
              />
            )}
          </div>

          {provider.observation && (
            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.observations")}
              text={provider.observation}
            />
          )}
          {!summary && (
            <>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.created_by")}
                text={provider.created_by}
              />

              {provider.updated_by && (
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.updated_by")}
                  text={provider.updated_by}
                />
              )}
            </>
          )}
        </div>
        <div className={classnames(css.action)}>
          {!summary && (
            <div className={classnames(css.enable_disable)}>
              <Button
                color="secondary"
                disabled={!updateProviderPermit}
                tooltip={
                  !updateProviderPermit
                    ? t("permits.change_status", {
                        action: provider.status
                          ? t("_general.disable").toLowerCase()
                          : t("_general.enable").toLowerCase(),
                      })
                    : ""
                }
                onClick={handleChangeStatus}
              >
                {provider.status ? t("_general.disable") : t("_general.enable")}
              </Button>
            </div>
          )}
          <div>
            <Button onClick={onClose} color="secondary">
              {t(!summary ? "_buttons.cancel" : "_buttons.close")}
            </Button>
            {!summary && (
              <Button
                color="secondary"
                disabled={!provider.status}
                tooltip={!provider.status ? t("disabled_provider") : ""}
                onClick={onDialog}
              >
                {t("_buttons.edit")}
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  summary: PropTypes.bool,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateProviderPermit: PropTypes.bool.isRequired,
  provider: PropTypes.shape({
    rfc: PropTypes.string,
    created_by: PropTypes.string.isRequired,
    updated_by: PropTypes.string,
    observation: PropTypes.string,
    name: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    company: PropTypes.shape({ tradename: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
  onDialog: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
};

export default Page;
