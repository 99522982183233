import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { actionAddConditionPolicy } from "store/actions/subsidiary/policy/addConditionPolicy";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionUpdateConditionPolicy } from "store/actions/subsidiary/policy/updateConditionPolicy";
import { actionGetConditionPolicy } from "store/actions/subsidiary/policy/getConditionPolicy";
import { actionClearConditionPoliciesFilters } from "store/actions/subsidiary/policy/clearConditionPoliciesFilters";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function ConditionPolicyForm({
  open,
  onClose,
  subsidiaryId,
  vehicleId,
  conditionPolicyId,
  ...rest
}) {
  const t = useLang();
  const {
    getVehicles,
    conditionPolicy,
    getConditionPolicy,
    updateConditionPolicy,
    addConditionPolicy,
    clearConditionPoliciesFilters,
    setNotification,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
  } = useFormik({
    initialValues: {
      vehicle_id: "",
      condition: "",
      axle_type: "",
    },
  });

  const [isUpdate, setIsUpdate] = useState(conditionPolicyId ? true : false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehicles(
          {
            subsidiaries: subsidiaryId.toString(),
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        setVehicles(data.sort(alphabeticalSort("economic_number")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getConditionPolicy(subsidiaryId, vehicleId, conditionPolicyId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (conditionPolicyId && open) {
      fetchData();
    }
  }, [conditionPolicyId, open]);

  useEffect(() => {
    if (conditionPolicyId && conditionPolicy.vehicle_condition_policy_id) {
      if (vehicles.length > 0) {
        setInitialValues({
          vehicle_id: conditionPolicy.vehicle.vehicle_id.toString(),
          condition: conditionPolicy.condition,
          axle_type: conditionPolicy.axle_type,
        });
      }
    } else {
      if (vehicles.length > 0) {
        setInitialValues({
          vehicle_id: vehicleId ? vehicleId.toString() : "",
          condition: "",
          axle_type: "",
        });
      }
    }
  }, [vehicles, conditionPolicy, conditionPolicyId]);

  useEffect(() => {
    setIsUpdate(conditionPolicyId ? true : false);
  }, [conditionPolicyId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      vehicle_id: values.vehicle_id,
      axle_type: values.axle_type,
      condition: values.condition,
    };

    try {
      if (isUpdate) {
        await updateConditionPolicy(
          fields,
          subsidiaryId,
          vehicleId,
          conditionPolicyId
        );
        setNotification({
          message: t("_messages.updated.policy"),
        });
      } else {
        const realFields = {
          vehicles: [{ vehicle_id: values.vehicle_id }],
          axle_policy: [
            {
              axle_type: values.axle_type,
              condition: values.condition,
            },
          ],
        };
        await addConditionPolicy(subsidiaryId, realFields);
        resetForm();
        setNotification({
          message: t("_messages.created.policy"),
        });
      }

      setSubmitting(false);
      onClose();
      clearConditionPoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicles={vehicles}
      initialErrors={initialErrors}
      initialValues={initialValues}
      isUpdate={isUpdate}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

ConditionPolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  conditionPolicyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  conditionPolicy: state.Subsidiary.ConditionPolicy.Policy.condition_policy,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicles: actionGetVehicles(dispatch),
  addConditionPolicy: actionAddConditionPolicy(dispatch),
  updateConditionPolicy: actionUpdateConditionPolicy(dispatch),
  getConditionPolicy: actionGetConditionPolicy(dispatch),
  clearConditionPoliciesFilters: actionClearConditionPoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionPolicyForm);
