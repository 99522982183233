import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { actionAddPressurePolicy } from "store/actions/subsidiary/policy/addPressurePolicy";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionUpdatePressurePolicy } from "store/actions/subsidiary/policy/updatePressurePolicy";
import { actionGetPressurePolicy } from "store/actions/subsidiary/policy/getPressurePolicy";
import { actionClearPressurePoliciesFilters } from "store/actions/subsidiary/policy/clearPressurePoliciesFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { getSubsidiaryViaWorkArea } from "utils/workArea";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function PressurePolicyForm({
  open,
  onClose,
  subsidiaryId,
  vehicleId,
  pressurePolicyId,
  ...rest
}) {
  const t = useLang();
  const {
    getVehicles,
    pressurePolicy,
    getPressurePolicy,
    updatePressurePolicy,
    addPressurePolicy,
    clearPressurePoliciesFilters,
    setNotification,
    account,
    workArea,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
  } = useFormik({
    initialValues: {
      vehicle_id: "",
      axle_type: "",
      recommended_pressure: "",
      tolerance: "",
    },
  });

  const subsidiary = getSubsidiaryViaWorkArea(account, workArea);
  const policy = subsidiary[0]?.subsidiary.policy ?? {};
  const [isUpdate, setIsUpdate] = useState(pressurePolicyId ? true : false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehicles(
          {
            subsidiaries: subsidiaryId.toString(),
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        setVehicles(data.sort(alphabeticalSort("economic_number")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getPressurePolicy(subsidiaryId, vehicleId, pressurePolicyId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (pressurePolicyId && open) {
      fetchData();
    }
  }, [pressurePolicyId, open]);

  useEffect(() => {
    if (pressurePolicyId && pressurePolicy.vehicle_pressure_policy_id) {
      if (vehicles.length > 0) {
        setInitialValues({
          vehicle_id: pressurePolicy.vehicle.vehicle_id.toString(),
          axle_type: pressurePolicy.axle_type,
          recommended_pressure: pressurePolicy.recommended_pressure,
          tolerance: pressurePolicy.tolerance,
        });
      }
    } else {
      if (vehicles.length > 0) {
        setInitialValues({
          vehicle_id: vehicleId ? vehicleId.toString() : "",
          axle_type: "",
          recommended_pressure: "",
          tolerance: "",
        });
      }
    }
  }, [vehicles, pressurePolicy, pressurePolicyId]);

  useEffect(() => {
    setIsUpdate(pressurePolicyId ? true : false);
  }, [pressurePolicyId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      vehicle_id: values.vehicle_id,
      axle_type: values.axle_type,
      recommended_pressure: values.recommended_pressure,
      tolerance: values.tolerance,
    };

    try {
      if (isUpdate) {
        await updatePressurePolicy(
          fields,
          subsidiaryId,
          vehicleId,
          pressurePolicyId
        );
        setNotification({
          message: t("_messages.updated.pressure_policy"),
        });
      } else {
        const realFields = {
          vehicles: [{ vehicle_id: values.vehicle_id }],
          axle_policy: [
            {
              axle_type: values.axle_type,
              recommended_pressure: values.recommended_pressure,
              tolerance: values.tolerance,
            },
          ],
        };
        await addPressurePolicy(subsidiaryId, realFields);
        resetForm();
        setNotification({
          message: t("_messages.created.pressure_policy"),
        });
      }

      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      policy={policy}
      vehicles={vehicles}
      initialErrors={initialErrors}
      initialValues={initialValues}
      isUpdate={isUpdate}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

PressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pressurePolicyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  pressurePolicy: state.Subsidiary.Policy.pressure_policy,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicles: actionGetVehicles(dispatch),
  addPressurePolicy: actionAddPressurePolicy(dispatch),
  updatePressurePolicy: actionUpdatePressurePolicy(dispatch),
  getPressurePolicy: actionGetPressurePolicy(dispatch),
  clearPressurePoliciesFilters: actionClearPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PressurePolicyForm);
