import { combineReducers } from "redux";

import History from "./history";
import Subsidiary from "./subsidiary";
import Role from "./role";

export default combineReducers({
  History,
  Subsidiary,
  Role,
});
