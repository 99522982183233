import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ t, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        margin="normal"
        variant="filled"
        name="odometer"
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: 1,
          },
          endAdornment: <InputAdornment position="start">km</InputAdornment>,
        }}
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.odometer")}
      />

      <DialogActions className={classnames(css.action)}>
        <div className={classnames(css.delete)}></div>
        <div className={classnames(css.buttons_right)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
