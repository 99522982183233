import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRfids } from "store/actions/rfid/getRfids";
import { actionClearRfids } from "store/actions/rfid/clearRfids";
import { actionSetRfidFilters } from "store/actions/rfid/setRfidFilters";
import { actionClearRfidFilters } from "store/actions/rfid/clearRfidFilters";
import { actionDeleteRfid } from "store/actions/rfid/deleteRfid";
import { actionSetRfidStatus } from "store/actions/rfid/setRfidStatus";
import { actionGetRfid } from "store/actions/rfid/getRfid";
import { actionAddRfid } from "store/actions/rfid/addRfid";
import { actionUpdateRfid } from "store/actions/rfid/updateRfid";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionDeleteLinkTire } from "store/actions/tire/rfid/deleteLinkTire";

import { useLang } from "hooks/lang";
import {
  CREATE_RFID,
  UPDATE_RFID,
  DELETE_RFID,
  LINK_TIRE_WITH_RFID,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeRfid({ ...rest }) {
  const t = useLang();
  const {
    permits,
    filter,
    rfid,
    rfids,
    account,
    deleteRfid,
    setRfidStatus,
    setNotification,
    getRfid,
    addRfid,
    updateRfid,
    getRfids,
    getSubsidiaries,
    clearRfids,
    setRfidFilters,
    clearRfidFilters,
    deleteLinkTire,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [openExcelForm, setOpenExcelForm] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  async function unlinkTire(rfidId, tireId) {
    try {
      const response = await deleteLinkTire(rfidId, tireId);
      setNotification({
        message: response.message,
      });
      getRfids(filter);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleUnlinkTire(rfidId, tireId) {
    setNotification({
      message: t("_messages.delete.link_tire"),
      textAction: t("_messages.confirm"),
      onAction: () => unlinkTire(rfidId, tireId),
    });
  }

  function handleOpenExcelForm() {
    setOpenExcelForm(true);
  }

  function handleCloseExcelForm() {
    setOpenExcelForm(false);
  }

  return (
    <Page
      t={t}
      rfid={rfid}
      rfids={rfids}
      filter={filter}
      createRfidPermit={permits.includes(CREATE_RFID)}
      updateRfidPermit={permits.includes(UPDATE_RFID)}
      deleteRfidPermit={permits.includes(DELETE_RFID)}
      linkTirePermit={permits.includes(LINK_TIRE_WITH_RFID)}
      subsidiaries={subsidiaries}
      getRfid={getRfid}
      addRfid={addRfid}
      updateRfid={updateRfid}
      clearRfidFilters={clearRfidFilters}
      setRfidFilters={setRfidFilters}
      deleteRfid={deleteRfid}
      setRfidStatus={setRfidStatus}
      getRfids={getRfids}
      clearRfids={clearRfids}
      handleUnlinkTire={handleUnlinkTire}
      handleOpenExcelForm={handleOpenExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      openExcelForm={openExcelForm}
    />
  );
}

HomeRfid.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  rfids: state.Rfid.rfids,
  rfid: state.Rfid.rfid,
  filter: state.Rfid.filter,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRfids: actionGetRfids(dispatch),
  clearRfids: actionClearRfids(dispatch),
  setRfidFilters: actionSetRfidFilters(dispatch),
  clearRfidFilters: actionClearRfidFilters(dispatch),
  deleteRfid: actionDeleteRfid(dispatch),
  setRfidStatus: actionSetRfidStatus(dispatch),
  getRfid: actionGetRfid(dispatch),
  addRfid: actionAddRfid(dispatch),
  updateRfid: actionUpdateRfid(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  deleteLinkTire: actionDeleteLinkTire(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeRfid);
