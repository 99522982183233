export default {
  update: {
    title: "Update Company",
  },
  title: "New Company",
  form: {
    placeholder: "Drop an image or click to select it",
    permits: {
      delete: "No permissions to delete companies",
      update: "No permissions to update companies",
    },
  },
  messages: {
    delete: "Are you sure to delete this company?",
    company_created: "Done, company has been successfully created",
    company_updated: "Done, company has been updated successfully",
  },
};
