import TireModelForm from "./TireModelForm";
import variation from "./variation";
import TireModelTabs from "./TireModelTabs";
import FilterTireModelForm from "./FilterTireModelForm";
import InfoTireModelCard from "./InfoTireModelCard";
import InfoTireModelDialog from "./InfoTireModelDialog";

export default {
  tire_model_form: TireModelForm,
  variation,
  tire_model_tabs: TireModelTabs,
  filter_tire_model_form: FilterTireModelForm,
  info_tire_model_card: InfoTireModelCard,
  info_tire_model_dialog: InfoTireModelDialog,
};
