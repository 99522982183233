import { USER_PROFILE, LANGUAGE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetProfile = (dispatch) => async () => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: "auth/profile",
    });

    dispatch({
      type: LANGUAGE,
      payload: response.preferred_language,
    });

    dispatch({
      type: USER_PROFILE,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
