import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    dirty,
    values,
    getTireModelVariations,
    setNotification,
    setFieldValue,
  } = rest;
  const [tireModelVariations, setTireModelVariations] = useState([]);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    async function fetchData() {
      try {
        let data = [];
        dirty && setFieldValue("tire_model_variation", "");

        data = await getTireModelVariations(
          {
            tireModelId: values.tire_model.tire_model_id,
            scope:
              "tire_model_variation_id,tire_size.size,number_layers,status,approved",
          },
          false
        );
        setTireModelVariations(data);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (mounted.current && values.tire_model) {
      fetchData();
    }
    return () => {
      mounted.current = false;
    };
  }, [values.tire_model]);

  return <Page {...rest} t={t} tireModelVariations={tireModelVariations} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  handleClean: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
