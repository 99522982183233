import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  companies,
  subsidiaries,
  warehouses,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="company_id"
        className={classnames(css.full_width)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        label={t("_labels.company.singular")}
        defaultText={t("_labels.company_field.singular.value")}
        component={SelectField}
      >
        {companies.map((company) => (
          <MenuItem
            key={company.company_id}
            value={company.company_id.toString()}
            disabled={!company.status}
          >
            {company.name}
          </MenuItem>
        ))}
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="subsidiary_id"
        className={classnames(css.full_width)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        label={t("_labels.subsidiary_field.singular.label")}
        defaultText={t("_labels.subsidiary_field.singular.value")}
        component={SelectField}
      >
        {subsidiaries.map((subsidiary) => (
          <MenuItem
            key={subsidiary.subsidiary_id}
            value={subsidiary.subsidiary_id.toString()}
            disabled={!subsidiary.status}
          >
            {subsidiary.name}
          </MenuItem>
        ))}
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="warehouse_id"
        className={classnames(css.full_width)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        label={t("_labels.warehouse.singular")}
        defaultText={t("_labels.warehouse.default")}
        component={SelectField}
      >
        {warehouses.map((warehouse) => (
          <MenuItem
            key={warehouse.warehouse_id}
            value={warehouse.warehouse_id.toString()}
            disabled={!warehouse.status}
          >
            {warehouse.name}
          </MenuItem>
        ))}
      </Field>
      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
