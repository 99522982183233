export default {
  title: "Vehículos vinculados",
  form: {
    permits: {
      list: {
        vehicle: "Sin permisos para ver listados de vehículos",
      },
    },
  },
  permits: {
    show_subsidiary_vehicle:
      "Sin permisos para ver los vehículos de la sucursal",
    unlink_subsidiary_vehicle:
      "Sin permisos para desvincular vehículos de sucursales",
  },
  messages: {
    delete: "¿Estas seguro de desvincular el vehículo?",
    linked_subsidiary_vehicle:
      "Listo, el vehículo fue vinculado a la sucursal con éxito",
    delete_subsidiary_vehicle:
      "Listo, se desvinculó el vehículo de la sucursal",
  },
};
