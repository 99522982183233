import {
  GET_REVITALIZED_TIRE_MODEL,
  GET_REVITALIZED_TIRE_MODELS,
  CLEAR_REVITALIZED_TIRE_MODEL,
  CLEAR_REVITALIZED_TIRE_MODELS,
  REGISTER_REVITALIZED_TIRE_MODEL,
  SET_REVITALIZED_TIRE_MODEL_FILTERS,
  CLEAR_REVITALIZED_TIRE_MODEL_FILTERS,
  UPDATE_REVITALIZED_TIRE_MODEL,
  SET_REVITALIZED_TIRE_MODEL_STATUS,
  DELETE_REVITALIZED_TIRE_MODEL,
  SET_REVITALIZED_TIRE_MODEL_APPROVAL_STATUS,
} from "store/actions";

const initialState = {
  revitalized_model: {
    brand_id: 0,
    brand: {
      name: "",
    },
    depth: 0,

    revitalized_tire_model_application: [],
    created_by: {
      name: "",
      last_name_1: "",
    },
    status: 0,
  },
  revitalized_models: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REVITALIZED_TIRE_MODEL:
    case REGISTER_REVITALIZED_TIRE_MODEL:
    case UPDATE_REVITALIZED_TIRE_MODEL:
      return {
        ...state,
        revitalized_model: action.payload,
      };

    case CLEAR_REVITALIZED_TIRE_MODEL:
      return {
        ...state,
        revitalized_model: initialState.revitalized_model,
      };

    case GET_REVITALIZED_TIRE_MODELS:
      return {
        ...state,
        revitalized_models: action.payload,
      };

    case CLEAR_REVITALIZED_TIRE_MODELS:
      return {
        ...state,
        revitalized_models: initialState.revitalized_models,
      };

    case DELETE_REVITALIZED_TIRE_MODEL:
      return {
        ...state,
        revitalized_models: {
          ...state.revitalized_models,
          data: state.revitalized_models.data.filter(
            (revitalizedModel) =>
              revitalizedModel.revitalized_tire_model_id !== action.payload
          ),
        },
      };

    case SET_REVITALIZED_TIRE_MODEL_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_REVITALIZED_TIRE_MODEL_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case SET_REVITALIZED_TIRE_MODEL_STATUS:
      return {
        ...state,
        revitalized_models: {
          ...state.revitalized_models,
          data: state.revitalized_models.data.map((model) =>
            model.revitalized_tire_model_id !== action.payload
              ? model
              : { ...model, status: !model.status }
          ),
        },
      };

    case SET_REVITALIZED_TIRE_MODEL_APPROVAL_STATUS:
      return {
        ...state,
        revitalized_models: {
          ...state.revitalized_models,
          data: state.revitalized_models.data.map((model) =>
            model.revitalized_tire_model_id !== action.payload
              ? model
              : { ...model, approved: !model.approved }
          ),
        },
      };

    default:
      return state;
  }
}
