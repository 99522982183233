import { combineReducers } from "redux";

import Gps from "./gps";
import Vehicle from "./vehicle";
import VehicleLink from "./vehicleLink";
import GpsGeofence from "./gpsGeofence";

export default combineReducers({
  Gps,
  Vehicle,
  VehicleLink,
  GpsGeofence,
});
