import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Formik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LinkedItemPanel from "components/molecules/general/LinkedItemPanel";

import Form from "./Form";

function Page({
  t,
  open,
  className,
  rfid,
  initialValues,
  initialErrors,
  showTirePermit,
  linkTireWithRfidPermit,
  listRfidPermit,
  handleLink,
  handleClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <LinkedItemPanel
        className={classnames(className)}
        title={t("_general.rfid")}
        label={t("_labels.device_code")}
        text={
          !showTirePermit
            ? t("_permits.show.tire")
            : !rfid
            ? t("_labels.rfid.not_rfid")
            : rfid.r_f_i_d.device_code
        }
        actionButtonName={!!rfid ? t("_buttons.unlink") : t("_buttons.link")}
        actionButtonTooltip={
          !rfid
            ? !linkTireWithRfidPermit
              ? t("_permits.link.tire_with_rfid")
              : !listRfidPermit
              ? t("_permits.list.rfid")
              : ""
            : ""
        }
        actionButtonDisabled={
          !showTirePermit ||
          (!rfid && (!linkTireWithRfidPermit || !listRfidPermit))
        }
        viewButtonDisabled={!showTirePermit || !rfid}
        viewButtonTooltip={""}
        onView={() => {}}
        onAction={!rfid ? handleLink : () => {}}
        onHistory={() => {}}
        historyTooltipPermit={""}
      />

      <DialogMediaQuery
        size={"sm"}
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>{t("_general.link_rfid")}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => <Form {...p} onClose={handleClose} />}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  rfid: PropTypes.object,
  open: PropTypes.bool.isRequired,
  showTirePermit: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  linkTireWithRfidPermit: PropTypes.bool.isRequired,
  listRfidPermit: PropTypes.bool.isRequired,
  handleLink: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
