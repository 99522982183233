import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { UPDATE_DEPTH_TOLERANCE_POLICY } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/policy/depth_tolerance/DepthToleranceForm/Form";

export function Form({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { permits } = rest;
  return (
    <Page
      {...rest}
      t={t}
      updateDepthTolerancePermit={permits.includes(
        UPDATE_DEPTH_TOLERANCE_POLICY
      )}
    />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
