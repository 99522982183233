import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
} from "store/actions/account/permits";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionClearWarehouses } from "store/actions/warehouse/clearWarehouses";
import { actionSetWarehouseFilters } from "store/actions/warehouse/setWarehouseFllters";
import { actionClearWarehouseFilters } from "store/actions/warehouse/clearWarehouseFilters";
import { actionDeleteWarehouse } from "store/actions/warehouse/deleteWarehouse";
import { actionSetWarehouseStatus } from "store/actions/warehouse/setWarehouseStatus";
import { actionGetWarehouse } from "store/actions/warehouse/getWarehouse";
import { actionAddWarehouse } from "store/actions/warehouse/addWarehouse";
import { actionUpdateWarehouse } from "store/actions/warehouse/updateWarehouse";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import json from "resources/json/countries";

import Page from "./page";

export function HomeWarehouse({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    warehouse,
    warehouses,
    deleteWarehouse,
    setWarehouseStatus,
    setNotification,
    getWarehouse,
    addWarehouse,
    updateWarehouse,
    getWarehouses,
    getSubsidiaries,
    clearWarehouses,
    setWarehouseFilters,
    clearWarehouseFilters,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          { scope: "subsidiary_id,name,status" },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
        setCountries(Object.keys(json));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  function handleChangeCountry(form, value, stateValue = "") {
    if (value && json[value]) {
      setStates(Object.keys(json[value])?.sort((a, b) => a.localeCompare(b)));
    } else {
      setStates([]);
    }
    form.setFieldValue("state", stateValue);
  }

  function handleChangeState(form, value, countryValue, provinceValue = "") {
    if (value) {
      setProvinces(
        json?.[countryValue]?.[value]?.sort((a, b) => a.localeCompare(b)) ?? []
      );
    } else {
      setProvinces([]);
    }
    if (provinceValue) {
      form.setFieldValue("province", provinceValue);
    } else {
      form.setFieldValue("province", "");
    }
  }

  return (
    <Page
      warehouse={warehouse}
      filter={filter}
      warehouses={warehouses}
      createWarehousePermit={permits.includes(CREATE_WAREHOUSE)}
      updateWarehousePermit={permits.includes(UPDATE_WAREHOUSE)}
      deleteWarehousePermit={permits.includes(DELETE_WAREHOUSE)}
      subsidiaries={subsidiaries}
      handleChangeCountry={handleChangeCountry}
      handleChangeState={handleChangeState}
      countries={countries}
      states={states}
      provinces={provinces}
      t={t}
      getWarehouse={getWarehouse}
      addWarehouse={addWarehouse}
      updateWarehouse={updateWarehouse}
      clearWarehouseFilters={clearWarehouseFilters}
      setWarehouseFilters={setWarehouseFilters}
      deleteWarehouse={deleteWarehouse}
      setWarehouseStatus={setWarehouseStatus}
      getWarehouses={getWarehouses}
      clearWarehouses={clearWarehouses}
    />
  );
}

HomeWarehouse.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  warehouses: state.Warehouse.Warehouse.warehouses,
  warehouse: state.Warehouse.Warehouse.warehouse,
  filter: state.Warehouse.Warehouse.filter_warehouse,
});
const mapDispatchToProps = (dispatch) => ({
  getWarehouses: actionGetWarehouses(dispatch),
  clearWarehouses: actionClearWarehouses(dispatch),
  setNotification: actionSetNotification(dispatch),
  setWarehouseFilters: actionSetWarehouseFilters(dispatch),
  clearWarehouseFilters: actionClearWarehouseFilters(dispatch),
  deleteWarehouse: actionDeleteWarehouse(dispatch),
  setWarehouseStatus: actionSetWarehouseStatus(dispatch),
  getWarehouse: actionGetWarehouse(dispatch),
  addWarehouse: actionAddWarehouse(dispatch),
  updateWarehouse: actionUpdateWarehouse(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWarehouse);
