import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";

import Page from "./page";

export const NAMESPACE = "pages/vehicle/type/VehicleTypeInfoTab";
export function VehicleTypeInfoTab({ ...rest }) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [axles, setAxles] = useState([]);

  const { vehicleType, setNotification, getVehicleTypeAxles } = rest;
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      try {
        let data = [];
        data = await getVehicleTypeAxles(
          {
            vehicleTypeId: id,
            status_axle: 1,
            scope: "color,position,vehicle_type_axle_id",
          },
          false
        );

        if (mounted.current) {
          setAxles(
            data.map((item) => ({
              color: item.color,
              position: item.position,
              tire_quantity: item.tire_quantity_count,
              vehicle_type_axle_tire: item.vehicle_type_axle_tire,
            }))
          );
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      mounted.current = false;
    };
  }, [id]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <Page
      vehicleTypeId={id}
      open={open}
      vehicleType={vehicleType}
      axles={axles}
      handleClose={handleClose}
    />
  );
}

VehicleTypeInfoTab.propTypes = {};

const mapStateToProps = (state) => ({
  vehicleType: state.Vehicle.Type.Type.vehicle_type,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypeInfoTab);
