export default {
  disabled_driver: "Disabled driver",
  permits: {
    change_status: "No permissions to {action} drivers",
  },
  messages: {
    disable: "Are you sure to disable the driver?",
    enable: "Are you sure to enable the driver?",
  },
};
