import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import DoneIcon from "@material-ui/icons/Done";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ListIcon from "@material-ui/icons/List";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import SearchInput from "components/molecules/general/SearchInput";
import InfoTireTooltip from "components/molecules/general/InfoTireTooltip";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import TableResponsive from "components/organisms/general/TableResponsive";

import css from "./index.module.scss";

function Page({
  t,
  isValid,
  values,
  showFilter,
  warehouses,
  originalBrands,
  retreadBrands,
  tireModels,
  revitalizedTireModels,
  tireSizes,
  tires,
  selectedTire,
  search,
  onChangePage,
  handleSelect,
  handleSearch,
  isSubmitting,
  onClose,
  handleSubmit,
  setShowFilter,
  selectedFilters,
  handleFilter,
  handleCleanFilters,
  searchBestTire,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <div className={classnames(css.w100, css.ml8)}>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setShowFilter((prevState) => !prevState)}
        >
          {showFilter ? t("_buttons.hide_filters") : t("_buttons.show_filters")}
        </Button>
      </div>

      {showFilter && (
        <div className={classnames(css.w100)}>
          <Field
            size="small"
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.small_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.warehouse.singular")}
            defaultText={t("_labels.warehouse.default")}
            component={SelectField}
          >
            {warehouses.map((warehouse, index) => {
              return (
                <MenuItem
                  key={index}
                  value={warehouse.warehouse_id.toString()}
                  disabled={!warehouse.status}
                >
                  {warehouse.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            size="small"
            variant="filled"
            margin="normal"
            name="condition"
            className={classnames(css.small_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.condition")}
            defaultText={t("_labels.tire_condition.default")}
            component={SelectField}
          >
            <MenuItem value={"ORIGINAL"}>
              {t("_labels.tire_condition.options.original")}
            </MenuItem>
            <MenuItem value={"RETREAD"}>
              {t("_labels.tire_condition.options.revitalized")}
            </MenuItem>
          </Field>

          {values.condition && values.condition === "ORIGINAL" && (
            <>
              <Field
                size="small"
                variant="filled"
                margin="normal"
                name="brand_id"
                className={classnames(css.small_field)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.brand.singular")}
                defaultText={t("_labels.brand_field.default")}
                component={SelectField}
              >
                {originalBrands.map((brand, index) => {
                  return (
                    <MenuItem key={index} value={brand.brand_id.toString()}>
                      {brand.name}
                    </MenuItem>
                  );
                })}
              </Field>

              <Field
                size="small"
                variant="filled"
                margin="normal"
                name="tire_model_id"
                className={classnames(css.small_field)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.tire_model_field.label")}
                defaultText={t("_labels.tire_model_field.value")}
                component={SelectField}
              >
                {tireModels.map((model, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={model.tire_model_id.toString()}
                    >
                      {model.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </>
          )}

          {values.condition && values.condition === "RETREAD" && (
            <>
              <Field
                size="small"
                variant="filled"
                margin="normal"
                name="revitalized_brand_id"
                className={classnames(css.small_field)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.revitalized_brand_field.label")}
                defaultText={t("_labels.revitalized_brand_field.default")}
                component={SelectField}
              >
                {retreadBrands.map((brand, index) => {
                  return (
                    <MenuItem key={index} value={brand.brand_id.toString()}>
                      {brand.name}
                    </MenuItem>
                  );
                })}
              </Field>

              <Field
                size="small"
                variant="filled"
                margin="normal"
                name="revitalized_tire_model_id"
                className={classnames(css.small_field)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.revitalized_model_field.label")}
                defaultText={t("_labels.revitalized_model_field.default")}
                component={SelectField}
              >
                {revitalizedTireModels.map((model, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={model.revitalized_tire_model_id.toString()}
                    >
                      {model.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </>
          )}

          <Field
            size="small"
            variant="filled"
            margin="normal"
            name="tire_size"
            className={classnames(css.small_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.size.label")}
            defaultText={t("_labels.revitalized_model_field.default")}
            component={SelectField}
          >
            {tireSizes.map((size, index) => {
              return (
                <MenuItem key={index} value={size.tire_size_id.toString()}>
                  {size.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            required={!!values.depth_max}
            size="small"
            variant="filled"
            margin="normal"
            name="depth_min"
            type="number"
            className={classnames(css.small_field)}
            label={t("_labels.depth_min")}
            component={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="end"
                >
                  mm
                </InputAdornment>
              ),
            }}
          />

          <Field
            required={!!values.depth_min}
            size="small"
            variant="filled"
            margin="normal"
            name="depth_max"
            type="number"
            className={classnames(css.small_field)}
            label={t("_labels.depth_max")}
            component={TextField}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="end"
                >
                  mm
                </InputAdornment>
              ),
            }}
          />

          <div className={classnames(css.w100, css.filter_buttons)}>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleFilter}
            >
              {t("_buttons.filter")}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleCleanFilters}
            >
              {t("_buttons.clean_filters")}
            </Button>
          </div>
        </div>
      )}

      <div className={classnames(css.full_width)}>
        {selectedFilters && Object.values(selectedFilters).length > 0 && (
          <>
            <div>
              <Typography variant="overline" className={classnames(css.mr8)}>
                {t("_general.selected_filters")}
              </Typography>
            </div>
            <div className={classnames(css.chips)}>
              {Object.keys(selectedFilters).map((key) => (
                <Chip
                  key={key}
                  size="small"
                  variant="outlined"
                  label={`${t(`_general.${key}`)}: ${
                    typeof selectedFilters[key] === "object"
                      ? selectedFilters[key].name
                      : selectedFilters[key]
                  }`}
                />
              ))}
            </div>
          </>
        )}

        <div className={classnames(css.flexbox)}>
          <SearchInput
            className={classnames(css.search, {
              [css.mb16]: tires.data.length === 0,
            })}
            notEmpty={classnames(css.notEmpty)}
            empty={classnames(css.empty)}
            search={search ? search : ""}
            onChange={handleSearch}
          />

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={searchBestTire}
          >
            {t("_buttons.best_tire")}
          </Button>

          <Typography variant="subtitle1" color="textPrimary" gutterBottom>
            {t("_general.selected_tire")}{" "}
            {selectedTire
              ? selectedTire.device_code || selectedTire.code
              : t("_general.no_choice")}
          </Typography>
        </div>

        {tires.data.length > 0 ? (
          <>
            <div className={classnames(css.container)}>
              <TableResponsive
                size="small"
                rows={tires.data.map((tire) => ({
                  id: tire.tire_id,
                  key: tire.tire_id,
                  code: tire.code || "",
                  rfid: tire.device_code || "",
                  tooltip: (
                    <InfoTireTooltip placement="right" tire={tire}>
                      <span>
                        <Button variant="icon" color="secondary" size="small">
                          <ListIcon fontSize="small" />
                        </Button>
                      </span>
                    </InfoTireTooltip>
                  ),
                  selected:
                    selectedTire && selectedTire.tire_id === tire.tire_id ? (
                      <DoneIcon style={{ verticalAlign: "middle" }} />
                    ) : (
                      " "
                    ),
                  bgColor:
                    selectedTire && selectedTire.tire_id === tire.tire_id
                      ? "yellow"
                      : "",
                  onRowClick: () => handleSelect(tire),
                }))}
                headers={[
                  {
                    id: "code",
                    label: t("_labels.code"),
                  },
                  {
                    id: "rfid",
                    label: t("_general.rfid"),
                  },
                  {
                    id: "tooltip",
                    label: "",
                  },
                  {
                    id: "selected",
                    label: "",
                  },
                ]}
              />
            </div>
            <div className={classnames(css.pagination)}>
              <Pagination
                page={tires.current_page}
                totalPages={tires.last_page}
                onChangePage={onChangePage}
                size="small"
              />
            </div>
          </>
        ) : (
          <EmptyListIcon text={t("_general.no_tires")} width="200" />
        )}
      </div>

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid || !selectedTire}
        >
          {t("_buttons.mount")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  showFilter: PropTypes.bool.isRequired,
  search: PropTypes.string,
  warehouses: PropTypes.array.isRequired,
  originalBrands: PropTypes.array.isRequired,
  retreadBrands: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  revitalizedTireModels: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  tires: PropTypes.object.isRequired,
  selectedTire: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  setShowFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  handleCleanFilters: PropTypes.func.isRequired,
  searchBestTire: PropTypes.func.isRequired,
};

export default Page;
