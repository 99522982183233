import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireWarehouseHistory } from "store/actions/tire/warehouse/history/getTireWarehouseHistory";
import { actionClearTireWarehouseHistory } from "store/actions/tire/warehouse/history/clearTireWarehouseHistory";

import { SHOW_WAREHOUSE } from "store/actions/account/permits";

import Page from "./page";

export function InfoWarehouseTireDialog({
  open,
  tireId,
  warehouseId,
  warehouseTireId,
  onClose,
  ...rest
}) {
  const t = useLang();
  const {
    permits,
    warehouse,
    setNotification,
    getTireWarehouseHistory,
    clearTireWarehouseHistory,
  } = rest;

  const showWarehousePermit = permits.includes(SHOW_WAREHOUSE);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireWarehouseHistory(warehouseId, tireId, warehouseTireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTireWarehouseHistory();
    };
  }, [open, warehouseTireId]);

  return (
    <Page
      t={t}
      to={`/warehouse/${warehouseId}`}
      open={open && showWarehousePermit}
      warehouse={warehouse}
      onClose={onClose}
    />
  );
}

InfoWarehouseTireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouseTireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  warehouse: state.Tire.Warehouse.History.warehouse,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireWarehouseHistory: actionGetTireWarehouseHistory(dispatch),
  clearTireWarehouseHistory: actionClearTireWarehouseHistory(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoWarehouseTireDialog);
