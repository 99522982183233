export default {
  preferred_language: "Idioma de preferencia",
  quit_admin_permissions: "Quitar permisos de administrador",
  enable_admin_permissions: "Darle permisos de administrador",
  messages: {
    quit_permissions: "¿Estas seguro de quitar permisos de administrador?",
    enable_permissions: "¿Estas seguro de dar permisos de administrador?",
    disable: "¿Estas seguro de deshabilitar el usuario?",
    enable: "¿Estas seguro de habilitar el usuario?",
  },
  disabled_user: "Usuario deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} usuarios",
  },
};
