export default {
  title: "Nuevo conductor",
  update_title: "Actualizar conductor",
  form: {
    permits: {
      update: "Sin permisos para actualizar conductores",
      delete: "Sin permisos para eliminar conductores",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar el conductor?",
    driver_created: "Listo, se creo un nuevo conductor con éxito",
    driver_updated: "Listo, se actualizó el conductor con éxito",
  },
};
