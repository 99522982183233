export default {
  title: "New Tire",
  update_title: "Update tire",
  form: {
    permits: {
      update: "No permissions to update tires",
      delete: "No permissions to delete tires",
    },
  },
  messages: {
    tire_created: "Done, tire has been successfully created",
    tire_updated: "Done, tire has been successfully updated",
    delete: "Are you sure to delete tire?",
  },
};
