import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

export function VirtualizedTable({ ...props }) {
  return <Page {...props} />;
}

VirtualizedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default VirtualizedTable;
