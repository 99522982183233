import React from "react";

import PropTypes from "prop-types";

import Page from "./page";

function LabelAndText({ label, text, ...props }) {
  return <Page label={label} text={text} {...props} />;
}

LabelAndText.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  noWrap: PropTypes.bool,
  noWrapLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  gutterBottom: PropTypes.bool,
};

export default LabelAndText;
