export default {
  link_with_corporate: "Vínculo de usuario con corporativo",
  link_with_user: "Vínculo de corporativo con usuario",
  buttons: {
    unlink_corporate: "Desvincular corporativo",
    unlink_user: "Desvincular usuario",
    view_corporate: "Ver corporativo",
    view_user: "Ver usuario",
  },
};
