import {
  GET_MOUNT_ALERTS,
  SET_MOUNT_ALERTS_FILTERS,
  CLEAR_MOUNT_ALERTS_FILTERS,
} from "store/actions";

const initialState = {
  vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MOUNT_ALERTS:
      return {
        ...state,
        vehicles: action.payload,
      };
    case SET_MOUNT_ALERTS_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_MOUNT_ALERTS_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
