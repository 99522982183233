import {
  REGISTER_VEHICLE,
  UPDATE_VEHICLE,
  GET_VEHICLES,
  CLEAR_VEHICLES,
  SET_VEHICLE_FILTERS,
  CLEAR_VEHICLE_FILTERS,
  SET_SELECTED_VEHICLE_FILTERS,
  GET_VEHICLE,
  CLEAR_VEHICLE,
  SET_VEHICLE_STATUS,
  DELETE_VEHICLE,
  RESET_ODOMETER,
} from "store/actions";

const initialState = {
  vehicle: {
    vehicle_id: 0,
    vehicle_type_id: 0,
    economic_number: "",
    status: 0,
    company: {
      tradename: "",
    },
    enrollment: "",
    has_odometer: 0,
    wheel: "",
    vehicle_type: {
      name: "",
      structure_type: "",
    },
    vehicle_brand: {
      name: "",
    },
    type_of_route: "",
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_vehicle: {},
  selected_filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_VEHICLE:
    case UPDATE_VEHICLE:
    case GET_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
      };

    case CLEAR_VEHICLE:
      return {
        ...state,
        vehicle: { ...initialState.vehicle },
      };

    case GET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };

    case CLEAR_VEHICLES:
      return {
        ...state,
        vehicles: { ...initialState.vehicles },
      };

    case SET_VEHICLE_FILTERS:
      const filter = {
        ...state.filter_vehicle,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_vehicle: { ...filter },
      };

    case CLEAR_VEHICLE_FILTERS:
      return {
        ...state,
        filter_vehicle: { search: state.filter_vehicle.search },
      };

    case SET_SELECTED_VEHICLE_FILTERS:
      const selectedFilter = {
        ...state.selected_filter,
        ...action.payload,
      };

      for (let key in selectedFilter) {
        if (
          selectedFilter[key] === undefined ||
          selectedFilter[key] === null ||
          selectedFilter[key] === ""
        ) {
          delete selectedFilter[key];
        }
      }

      return {
        ...state,
        selected_filter: { ...selectedFilter },
      };

    case SET_VEHICLE_STATUS:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.map((vehicle) =>
            vehicle.vehicle_id !== action.payload
              ? vehicle
              : { ...vehicle, status: !vehicle.status }
          ),
        },
      };

    case DELETE_VEHICLE:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.filter(
            (vehicle) => vehicle.vehicle_id !== action.payload
          ),
        },
      };

    case RESET_ODOMETER:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.map((vehicle) =>
            vehicle.vehicle_id !== action.payload.vehicle_id
              ? vehicle
              : {
                  ...vehicle,
                  vehicle_review: vehicle.vehicle_review.map((vr) => ({
                    ...vr,
                    odometer: action.payload.odometer,
                  })),
                }
          ),
        },
      };
    default:
      return state;
  }
}
