import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

export const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  fill: true,
  locale: "en",
  scales: {
    y: {
      min: 0,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      // align: 'start',
    },
  },
};
function BarChart({ data, options }) {
  return (
    <Page
      options={options ? options : defaultOptions}
      data={
        data.labels
          ? data
          : {
              labels: [""],
              datasets: data,
            }
      }
    />
  );
}

BarChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  options: PropTypes.object,
};

export default BarChart;
