import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function DialogMediaQuery({
  open,
  children,
  onClose,
  size,
  fullScreen,
  ...rest
}) {
  return (
    <Page
      size={size}
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      {...rest}
    >
      {children}
    </Page>
  );
}

DialogMediaQuery.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  fullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DialogMediaQuery;
