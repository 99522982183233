import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionSetVehicleTravelHistoryFilters } from "store/actions/vehicle/travel/history/setVehicleTravelHistoryFilters";
import { actionClearVehicleTravelHistoryFilters } from "store/actions/vehicle/travel/history/clearVehicleTravelHistoryFilters";

import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/vehicle/travel/history/FilterVehicleTravelHistoryForm";

export function FilterVehicleTravelHistoryForm({ open, onClose, ...rest }) {
  const t = useLang(NAMESPACE);
  const {
    filter,
    setVehicleTravelHistoryFilters,
    clearVehicleTravelHistoryFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      date_from: "",
      date_to: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearVehicleTravelHistoryFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      await setVehicleTravelHistoryFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleClean={handleClean}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

FilterVehicleTravelHistoryForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Vehicle.Travel.History.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setVehicleTravelHistoryFilters:
    actionSetVehicleTravelHistoryFilters(dispatch),
  clearVehicleTravelHistoryFilters:
    actionClearVehicleTravelHistoryFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterVehicleTravelHistoryForm);
