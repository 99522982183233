import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { actionSetLanguage } from "store/actions/general/language";

import Page from "./page";

export const NAMESPACE = "src/components/templates/general/Home";

export function HomeTemplate({ children, ...rest }) {
  const t = useLang(NAMESPACE);
  const { language, setLanguage } = rest;

  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <Page t={t} language={language} handleLanguage={handleLanguage}>
      {children}
    </Page>
  );
}

HomeTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({ language: state.General.language });
const mapDispatchToProps = (dispatch) => ({
  setLanguage: actionSetLanguage(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTemplate);
