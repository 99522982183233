import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateCompletedCyclesExcel =
  (dispatch) =>
  async ({
    subsidiary_id: subsidiaryId,
    company_id: companyId,
    corporate_id: corporateId,
    date_from: dateFrom,
    date_to: dateTo,
    tire_model_variation: tireModelVariation,
    tire_application: tireApplication,
    tire_condition: tireCondition,
    price,
    helmet_value: helmetValue,
    brands,
    models,
    sizes,
    except_pile: exceptPile,
    search,
    codes,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "report/completed/cycles/excel",
        params: {
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          date_from: dateFrom,
          date_to: dateTo,
          tire_model_variation: tireModelVariation,
          tire_application: tireApplication,
          tire_condition: tireCondition,
          price,
          helmet_value: helmetValue,
          brands,
          models,
          sizes,
          except_pile: exceptPile,
          search,
          codes,
        },
        download: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
