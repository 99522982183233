import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  vehicleTire,
  damages,
  drivers,
  providers,
  onClose,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" size="xs" open={open} onClose={onClose}>
        <DialogTitle>{`${t("_labels.tire.singular")} ${
          vehicleTire.code || vehicleTire.device_code
        }`}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div className={classnames(css.w100, css.flex)}>
            <LabelAndText
              className={classnames(css.half_width)}
              label={t("_labels.tire_position")}
              text={
                vehicleTire.cycle.movement_tire?.vehicle_tire[0]?.vehicle_type_axle_tire.position.toString() ||
                "0"
              }
            />
            <LabelAndText
              className={classnames(css.half_width)}
              label={t("_labels.condition")}
              text={t(
                `_labels.tire_condition.options.${vehicleTire.cycle.tire_condition_id.toLowerCase()}`
              )}
            />
          </div>
          <div className={classnames(css.w100, css.flex)}>
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.brand_field.label")}
              text={
                vehicleTire.cycle.number_cycle > 0
                  ? vehicleTire.cycle.revitalized.brand.name
                  : vehicleTire.cycle.variation.tire_model.brand.name
              }
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.tire_model_field.label")}
              text={
                vehicleTire.cycle.number_cycle > 0
                  ? vehicleTire.cycle.revitalized?.name
                  : vehicleTire.cycle.variation.tire_model.name
              }
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.size")}
              text={vehicleTire.cycle.variation.tire_size.size}
            />
          </div>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                onClose={onClose}
                damages={damages}
                drivers={drivers}
                providers={providers}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  vehicleTire: PropTypes.object,
  damages: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
