import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import ImageIcon from "@material-ui/icons/Image";

import css from "./index.module.scss";

function Page({ t, open, onClose, damages }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_labels.damage.plural")}:{damages.length}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
              minWidth: 750,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <td className="text-center">{t("_labels.date.label")}</td>
                  <td className="text-center">{t("_labels.name.singular")}</td>
                  <td className="text-center">
                    {t("_labels.comment.singular")}
                  </td>
                  <td className="text-center text-capitalize">
                    {t("_labels.image")}
                  </td>
                </tr>
              </thead>
              <tbody>
                {damages.map((damage, index) => (
                  <tr key={index}>
                    <td className="py-1 px-2 text-center align-middle">
                      {`${moment(damage.created_at).format("LL")} ${t(
                        "_labels.time_at"
                      )} ${moment(damage.created_at).format("h:mm a")}`}
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      {t(`_damage.name.${damage.damage.name}`)}
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      {damage.comment || ""}
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      <Button
                        variant="icon"
                        color="secondary"
                        disabled={!damage.image}
                        href={`${process.env.REACT_APP_IMAGES_URL}${damage.image}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <ImageIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  damages: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
