import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetWarehouseTire } from "store/actions/warehouse/tire/getWarehouseTire";
import { actionClearWarehouseTire } from "store/actions/warehouse/tire/clearWarehouseTire";
import { actionDeleteLinkTireWithWarehouse } from "store/actions/warehouse/tire/deleteLinkTireWithWarehouse";

import {
  SHOW_TIRE,
  SHOW_WAREHOUSE,
  SHOW_LINK_TIRE_WITH_WAREHOUSE,
  DELETE_LINK_TIRE_WITH_WAREHOUSE,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/warehouse/tire/WarehouseTireInfoDialog";

export function WarehouseTireInfoDialog({
  open,
  tireId,
  warehouseId,
  onClose,
  viewButtonName,
  ...rest
}) {
  const history = useHistory();
  const t = useLang(NAMESPACE);
  const {
    permits,
    warehouseTire,
    setNotification,
    getWarehouseTire,
    clearWarehouseTire,
    deleteLinkTireWithWarehouse,
  } = rest;
  const showLinkTireWithWarehousePermit = permits.includes(
    SHOW_LINK_TIRE_WITH_WAREHOUSE
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await getWarehouseTire({ warehouseId, tireId });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && tireId) {
      if (showLinkTireWithWarehousePermit) {
        fetchData();
      } else {
        setNotification({
          message: t("_permits.show.link.tire_with_warehouse"),
        });
      }
    }

    return () => {
      clearWarehouseTire();
    };
  }, [open, tireId]);

  function handleView() {
    if (viewButtonName === t("buttons.view_warehouse")) {
      history.push(`/warehouse/${warehouseId}`);
    }
    if (viewButtonName === t("buttons.view_tire")) {
      history.push(`/tire/${tireId}`);
    }
  }

  async function deleteLinkWithWarehouse() {
    try {
      await deleteLinkTireWithWarehouse({
        tireId,
        warehouseId,
      });

      setNotification({
        message: t("_messages.deleted.link_tire_with_warehouse"),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleUnlink() {
    onClose();
    setNotification({
      message: t("_messages.delete.link_tire_with_warehouse"),
      onAction: () => {
        deleteLinkWithWarehouse();
      },
      textAction: t("_messages.confirm"),
    });
  }
  return (
    <Page
      t={t}
      {...rest}
      warehouseTire={warehouseTire}
      viewButtonName={viewButtonName}
      showTirePermit={permits.includes(SHOW_TIRE)}
      showWarehousePermit={permits.includes(SHOW_WAREHOUSE)}
      deleteLinkTireWithWarehousePermit={permits.includes(
        DELETE_LINK_TIRE_WITH_WAREHOUSE
      )}
      open={open && showLinkTireWithWarehousePermit}
      onClose={onClose}
      handleView={handleView}
      handleUnlink={handleUnlink}
    />
  );
}

WarehouseTireInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  viewButtonName: PropTypes.string.isRequired,
  tireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  warehouseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  warehouseTire: state.Warehouse.Tire.Tire.warehouse_tire,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getWarehouseTire: actionGetWarehouseTire(dispatch),
  clearWarehouseTire: actionClearWarehouseTire(dispatch),
  deleteLinkTireWithWarehouse: actionDeleteLinkTireWithWarehouse(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehouseTireInfoDialog);
