export default {
  damage_disabled: "Daño deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} daño",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar el daño?",
    enable: "¿Estas seguro de habilitar el daño?",
  },
};
