import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  warehouses,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        required
        fullWidth
        variant="filled"
        name="warehouse_id"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        className={classnames(css.mb16)}
        label={t("_labels.warehouse.singular")}
        defaultText={t("_labels.warehouse.default")}
        defaultValue={
          warehouses.some(
            (warehouse) => warehouse.warehouse_id === values.warehouse_id
          )
            ? null
            : values.warehouse_id
        }
      >
        {warehouses.map((warehouse, index) => {
          return (
            <MenuItem
              key={index}
              disabled={!warehouse.status}
              value={warehouse.warehouse_id.toString()}
            >
              {warehouse.name}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button
            type="button"
            color="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_general.store")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  warehouses: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
