import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTires } from "store/actions/tire/getTires";
import { actionClearTires } from "store/actions/tire/clearTires";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";
import { actionGetBestTires } from "store/actions/vehicle/tire/getBestTires";
import { actionSetTireMountFilters } from "store/actions/vehicle/tire/setTireMountFilters";
import { actionClearTireMountFilters } from "store/actions/vehicle/tire/clearTireMountFilters";
import { useParams } from "react-router-dom";

import Page from "./page";

export function Form({ setFieldValue, brands, ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    values,
    warehouses,
    tires,
    tireSizes,
    filter,
    setNotification,
    getTires,
    clearTires,
    setTireFilters,
    clearTireFilters,
    getTireModels,
    getRevitalizedTireModels,
    getBestTires,
    vehicleTypeAxleTireId,
  } = rest;

  const [selectedTire, setSelectedTire] = useState(null);
  const [tireModels, setTireModels] = useState([]);
  const [revitalizedTireModels, setRevitalizedTireModels] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const filtered = useRef(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTires({
          ...filter,
        });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (filtered.current) {
      fetchData();
    }

    return () => {
      clearTires();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireFilters();
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireModels({
          scope: "tire_model_id,tire_model.name",
          status: 1,
          brands: values.brand_id,
        });
        setTireModels(
          data
            .map((model) => ({
              tire_model_id: model.tire_model_id,
              name: model.tire_model.name,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.brand_id) {
      fetchData();
    }
  }, [values.brand_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getRevitalizedTireModels({
          scope: "revitalized_tire_model_id,name",
          status: 1,
          brands: values.revitalized_brand_id,
        });

        setRevitalizedTireModels(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.revitalized_brand_id) {
      fetchData();
    }
  }, [values.revitalized_brand_id]);

  function handleSearch(search) {
    try {
      filtered.current = true;
      setTireFilters({ search });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function onChangePage(page) {
    try {
      await getTires({
        ...filter,
        page,
        location: "WAREHOUSE",
        status: 1,
        per_page: 9,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSelect(tire) {
    if (selectedTire && selectedTire.tire_id === tire.tire_id) {
      setSelectedTire(null);
      setFieldValue("tire", {});
    } else {
      setSelectedTire(tire);
      setFieldValue("tire", tire);
    }
  }

  function handleFilter() {
    try {
      const params = {};
      let filters = { ...selectedFilters };

      if (values.warehouse_id) {
        params.warehouses = values.warehouse_id;
        filters.warehouse = warehouses.find(
          (warehouse) => warehouse.warehouse_id == values.warehouse_id
        );
      } else {
        delete filters.warehouse;
      }

      if (values.brand_id) {
        params.brands = values.brand_id;
        filters.brand = brands.find(
          (brand) => brand.brand_id == values.brand_id
        );
      } else {
        delete filters.brand;
      }

      if (values.tire_model_id) {
        params.models = values.tire_model_id;
        filters.tire_model = tireModels.find(
          (tireModel) => tireModel.tire_model_id == values.tire_model_id
        );
      } else {
        delete filters.tire_model;
      }

      if (values.revitalized_brand_id) {
        params.revitalized_brands = values.revitalized_brand_id;
        filters.revitalized_brand = brands.find(
          (revitalizedBrand) =>
            revitalizedBrand.brand_id == values.revitalized_brand_id
        );
      } else {
        delete filters.revitalized_brand;
      }

      if (values.revitalized_tire_model_id) {
        params.revitalized_models = values.revitalized_tire_model_id;
        filters.revitalized_model = revitalizedTireModels.find(
          (revitalizedTireModel) =>
            revitalizedTireModel.revitalized_tire_model_id ==
            values.revitalized_tire_model_id
        );
      } else {
        delete filters.revitalized_model;
      }

      if (values.tire_size) {
        params.tire_size = values.tire_size;
        filters.size = tireSizes.find(
          (tireSize) => tireSize.tire_size_id == values.tire_size
        );
      } else {
        delete filters.size;
      }

      if (values.depth_min) {
        params.depth_min = values.depth_min;
        filters.depth_min = values.depth_min;
      }

      if (values.depth_max) {
        params.depth_max = values.depth_max;
        filters.depth_max = values.depth_max;
      }

      filtered.current = true;
      setTireFilters({ ...params });
      setSelectedFilters(filters);
      setShowFilter(false);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleCleanFilters() {
    clearTireFilters();
    setSelectedFilters(null);
    Object.keys(values).forEach((value) => {
      if (value !== "tire") {
        setFieldValue(value, "");
      }
    });
  }

  async function searchBestTire() {
    try {
      const response = await getBestTires(vehicleId, vehicleTypeAxleTireId);

      if (response.length > 0) {
        filtered.current = true;
        setTireFilters({ tire_ids: response.join(), sort_by_mm: 1 });
      } else {
        setNotification({
          message: t("_labels.no_results"),
        });
      }
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      {...rest}
      showFilter={showFilter}
      originalBrands={brands.filter((brand) => brand.brand_type === "TIRE")}
      retreadBrands={brands.filter((brand) => brand.brand_type === "RETREAD")}
      tireModels={tireModels}
      revitalizedTireModels={revitalizedTireModels}
      tires={tires}
      selectedTire={selectedTire}
      setSelectedTire={setSelectedTire}
      selectedFilters={selectedFilters}
      search={filter.search}
      handleSearch={handleSearch}
      onChangePage={onChangePage}
      handleSelect={handleSelect}
      setShowFilter={setShowFilter}
      handleFilter={handleFilter}
      handleCleanFilters={handleCleanFilters}
      searchBestTire={searchBestTire}
    />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  warehouses: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  tireSizes: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  vehicleTypeAxleTireId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  tires: state.Tire.Tire.tires,
  filter: state.Vehicle.Tire.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTires: actionGetTires(dispatch),
  clearTires: actionClearTires(dispatch),
  setTireFilters: actionSetTireMountFilters(dispatch),
  clearTireFilters: actionClearTireMountFilters(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
  getBestTires: actionGetBestTires(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
