import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";

import { useHistory } from "react-router-dom";

import { actionSetLogin } from "store/actions/account/login";
import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE = "pages/account/Login";

export function Login({ ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const { account, setLogin, setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (account != null) {
      history.replace("/review");
    }
  }, [account]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      await setLogin({
        email: values.email,
        password: values.password,
      });
      resetForm();
      setNotification({
        message: t("messages.login"),
      });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

Login.propTypes = {};

const mapStateToProps = (state) => ({ account: state.Account.account });
const mapDispatchToProps = (dispatch) => ({
  setLogin: actionSetLogin(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
