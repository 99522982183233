import { http } from "store/actions/http";
import { UPDATE_VEHICLE_TYPE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateVehicleType =
  (dispatch) => async (fields, vehicleTypeId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `vehicle/type/${vehicleTypeId}`,
        data: fields,
      });

      dispatch({
        type: UPDATE_VEHICLE_TYPE,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
