import { combineReducers } from "redux";

import User from "./user";
import Subsidiary from "./subsidiary";
import Warehouse from "./warehouse";
import Vehicle from "./vehicle";
import Driver from "./driver";
import Tire from "./tire";
import Division from "./division";
import Policy from "./policy";
import Model from "./model";
import ConditionPolicy from "./condition_policy";
import SubsidiaryPolicy from "./subsidiary_policy/index.js";
import depthTolerancePolicy from "./depth_tolerance_policy";
import DepthPolicy from "./depth_policy_by_axle";
import HelmetValue from "./helmet_value";
import TemperaturePolicy from "./temperature_policy";

export default combineReducers({
  User,
  Subsidiary,
  Warehouse,
  Vehicle,
  Driver,
  Tire,
  Division,
  Policy,
  Model,
  ConditionPolicy,
  SubsidiaryPolicy,
  depthTolerancePolicy,
  DepthPolicy,
  HelmetValue,
  TemperaturePolicy,
});
