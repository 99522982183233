import { GET_DEPTH_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetDepthPolicy =
  (dispatch) => async (subsidiaryId, vehicleId, vehicleDepthPolicyId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `subsidiary/${subsidiaryId}/depth/policy/vehicle/${vehicleId}/axle/${vehicleDepthPolicyId}`,
      });

      dispatch({
        type: GET_DEPTH_POLICY,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
