import { http } from "store/actions/http";
import { DELETE_BLOCK } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteBlock = (dispatch) => async (blockId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: `DELETE`,
      path: `block/${blockId}`,
    });
    dispatch({
      type: DELETE_BLOCK,
      payload: blockId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
