import { http } from "store/actions/http";
import { CREATE_TIRE_MODEL_VARIATION } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionAddTireModelVariation =
  (dispatch) => async (fields, tireModelId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `tire/model/${tireModelId}/variation`,
        data: fields,
      });

      dispatch({
        type: CREATE_TIRE_MODEL_VARIATION,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
