import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPurchaseReport } from "store/actions/reports/purchase/getPurchaseReport";

import { useLang } from "hooks/lang";

import Page from "./page";
import { actionClearPurchaseFilters } from "store/actions/reports/purchase/clearPurchaseFilters";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { actionSetPurchaseFilters } from "store/actions/reports/purchase/setPurchaseFilters";
import { actionSetSelectedPurchaseFilters } from "store/actions/reports/purchase/setSelectedPurchaseFilters";
import { actionResetCart } from "store/actions/reports/purchase/resetCart";
import { usePDF } from "@react-pdf/renderer";
import MyPDFDocument from "./PDF/Document";
import moment from "moment-timezone";

function PurchaseReport({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [estimatedData, setEstimatedData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();
  const formikRefs = useRef({});
  const previousPdfUrl = useRef("");
  const {
    amount,
    account,
    workArea,
    filter,
    selectedFilter,
    setNotification,
    getPurchaseReport,
    clearPurchaseFilters,
    setPurchaseFilters,
    setSelectedPurchaseFilters,
    resetCart,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        formikRefs.current = {};
        const response = await getPurchaseReport({ ...filter });
        setData(response);

        const summaryOriginal = getOriginalSummaryData(response);
        const summaryRevitalized = getRevitalizedSummaryData(response);
        setSummaryData({
          original: summaryOriginal,
          revitalized: summaryRevitalized,
        });

        const result = getEstimatedData(response);
        setEstimatedData(result);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
      resetCart();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      depth_condition: "CRITICAL WITHDRAWAL",
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries[0]?.subsidiary?.name,
      depth_condition: t(`_labels.critical_withdrawal`),
    };
    setPurchaseFilters(filter);
    setSelectedPurchaseFilters(selectedFilters);
    return () => {
      clearPurchaseFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function saveRef(condition, application, size, ref) {
    const currentFormikRefs = formikRefs.current;
    if (!currentFormikRefs[condition]) {
      currentFormikRefs[condition] = {};
    }
    if (!currentFormikRefs[condition][application]) {
      currentFormikRefs[condition][application] = {};
    }
    currentFormikRefs[condition][application][size] = ref;
  }

  function savePdf() {
    const currentFormikRefs = formikRefs.current;
    let totalOriginal = 0;
    let totalRevitalized = 0;
    let priceOriginal = 0;
    let priceRevitalized = 0;
    const originalTireSizeData = {};
    const revitalizedTireSizeData = {};
    const originalTireList = [];
    const revitalizedTireList = [];
    const originalData = currentFormikRefs.original ?? {};
    const revitalizedData = currentFormikRefs.revitalized ?? {};
    for (const [application, applicationValues] of Object.entries(
      originalData
    )) {
      for (const size of Object.keys(applicationValues)) {
        const data = applicationValues[size].current.values.data;
        const save = applicationValues[size].current.values.save;
        if (!save) {
          continue;
        }
        for (const item of data) {
          if (item.quantity === 0) {
            continue;
          }
          totalOriginal += item.quantity;
          priceOriginal += item.quantity * parseFloat(item.price);
          if (!originalTireSizeData[size]) {
            originalTireSizeData[size] = {
              directional: 0,
              allPosition: 0,
              traction: 0,
              remolque: 0,
            };
          }
          if (application === "ALL_POSITION") {
            originalTireSizeData[size].allPosition += item.quantity;
          } else if (application === "DIRECTIONAL") {
            originalTireSizeData[size].directional += item.quantity;
          } else if (application === "TRACTION") {
            originalTireSizeData[size].traction += item.quantity;
          } else if (application === "TRAILER") {
            originalTireSizeData[size].remolque += item.quantity;
          }
          const brand = item.model.split("-", 2)[0];
          const model = item.model.split("-", 2)[1];
          originalTireList.push({
            size: item.size,
            application: t(
              `_labels.tire_application.options.${item.tire_application_id.toLowerCase()}`
            ),
            amount: item.quantity,
            brand: brand,
            model: model,
            layers: item.number_layers,
            price: item.price,
          });
        }
      }
    }
    for (const [application, applicationValues] of Object.entries(
      revitalizedData
    )) {
      for (const size of Object.keys(applicationValues)) {
        const data = applicationValues[size].current.values.data;
        const save = applicationValues[size].current.values.save;
        if (!save) {
          continue;
        }
        for (const item of data) {
          totalRevitalized += item.quantity;
          priceRevitalized += item.quantity * parseFloat(item.price);
          if (!revitalizedTireSizeData[size]) {
            revitalizedTireSizeData[size] = {
              directional: 0,
              allPosition: 0,
              traction: 0,
              remolque: 0,
            };
          }
          if (application === "ALL_POSITION") {
            revitalizedTireSizeData[size].allPosition += item.quantity;
          } else if (application === "DIRECTIONAL") {
            revitalizedTireSizeData[size].directional += item.quantity;
          } else if (application === "TRACTION") {
            revitalizedTireSizeData[size].traction += item.quantity;
          } else if (application === "TRAILER") {
            revitalizedTireSizeData[size].remolque += item.quantity;
          }
          const brand = item.model.split("-", 2)[0];
          const model = item.model.split("-", 2)[1];
          revitalizedTireList.push({
            size: item.size,
            application: t(
              `_labels.tire_application.options.${item.tire_application_id.toLowerCase()}`
            ),
            amount: item.quantity,
            brand: brand,
            model: model,
            layers: item.number_layers,
            price: item.price,
          });
        }
      }
    }
    setIsSavingPdf(true);
    const pdfData = {
      date: moment().format("L"),
      totals: {
        totalOriginal: totalOriginal,
        totalRevitalized: totalRevitalized,
        priceOriginal: priceOriginal,
        priceRevitalized: priceRevitalized,
      },
      originalTireSizeList: Object.entries(originalTireSizeData).map(
        ([size, data]) => ({
          ...data,
          size: size,
        })
      ),
      revitalizedTireSizeList: Object.entries(revitalizedTireSizeData).map(
        ([size, data]) => ({
          ...data,
          size: size,
        })
      ),
      originalTireList: originalTireList,
      revitalizedTireList: revitalizedTireList,
      subsidiary: `${selectedFilter.companies} - ${selectedFilter.subsidiaries}`,
    };
    // Se genera un UUID para forzar que el pdf se descargue de nuevo,
    // aunque su contenido sea idéntico al anterior
    const uuid =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    updatePdfInstance(<MyPDFDocument {...pdfData} uuid={uuid} />);
  }

  useEffect(() => {
    if (
      isSavingPdf &&
      !pdfInstance.loading &&
      previousPdfUrl.current !== pdfInstance.url
    ) {
      previousPdfUrl.current = pdfInstance.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "ComprasSugeridas.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  return (
    <Page
      t={t}
      amount={amount}
      data={data}
      detailsData={getDetailsData(data)}
      modelsOriginal={getOriginalModelsData(data)}
      modelsRevitalized={getRevitalizedModelsData(data)}
      actualPurchase={getActualPurchaseData(data)}
      actualPurchaseRevitalized={getActualPurchaseRevitalizedData(data)}
      replacementData={getReplacementData(data)}
      replacementRevitalizedData={getReplacementRevitalizedData(data)}
      estimatedData={estimatedData}
      summaryData={summaryData}
      setSummaryData={setSummaryData}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      formikRefs={formikRefs}
      saveRef={saveRef}
      savePdf={savePdf}
      pdfInstance={pdfInstance}
    />
  );
}
PurchaseReport.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.Purchase.filter,
  selectedFilter: state.Reports.Purchase.selected_filter,
  amount: state.Reports.Purchase.cart_amount,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getPurchaseReport: actionGetPurchaseReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearPurchaseFilters: actionClearPurchaseFilters(dispatch),
  setPurchaseFilters: actionSetPurchaseFilters(dispatch),
  setSelectedPurchaseFilters: actionSetSelectedPurchaseFilters(dispatch),
  resetCart: actionResetCart(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseReport);

function getDetailsData(data) {
  if (data.details && Object.keys(data.details).length > 0) {
    return data.details;
  }

  return {};
}

function getOriginalModelsData(data) {
  if (data.models_originals && Object.keys(data.models_originals).length > 0) {
    return data.models_originals;
  }

  return {};
}

function getRevitalizedModelsData(data) {
  if (
    data.models_revitalized &&
    Object.keys(data.models_revitalized).length > 0
  ) {
    return data.models_revitalized;
  }

  return {};
}

function getActualPurchaseData(data) {
  if (
    data.actual_purchase_original &&
    Object.keys(data.actual_purchase_original).length > 0
  ) {
    return data.actual_purchase_original;
  }

  return {};
}

function getActualPurchaseRevitalizedData(data) {
  if (
    data.actual_purchase_revitalized &&
    Object.keys(data.actual_purchase_revitalized).length > 0
  ) {
    return data.actual_purchase_revitalized;
  }

  return {};
}

function getOriginalSummaryData(data) {
  const totalOriginal = {
    price: 0,
    quantity: 0,
    total: 0,
  };

  const originalModels = getOriginalModelsData(data);
  const { actual_purchase_original: actualPurchase } = data;
  const result = {};

  // const duplicated = {};
  // const aux = {};
  Object.keys(originalModels).forEach((application) => {
    Object.keys(originalModels[application]).forEach((size) => {
      const required = originalModels[application][size]
        .filter((row) => {
          // if (actualPurchase[row.tire_model_variation_id]) {

          //   if (!aux[row.tire_model_variation_id]) {
          //     aux[row.tire_model_variation_id] = row;
          //   } else {
          //     duplicated[row.tire_model_variation_id] = row;
          //   }
          //   return true;
          // }

          // return false;

          return actualPurchase[row.tire_model_variation_id] ? true : false;
        })
        .map(
          ({
            model,
            price,
            size,
            tire_application_id: tireApplication,
            tire_model_variation_id: tireModelVariationId,
          }) => ({
            model,
            price,
            size,
            application: tireApplication,
            quantity: actualPurchase[tireModelVariationId] ?? 0,
            tire_model_variation_id: tireModelVariationId,
          })
        );

      if (!result[application]) {
        result[application] = [];
      }
      result[application] = result[application].concat(required);
    });
  });

  const original = Object.keys(result).reduce((acc, application) => {
    const price = result[application].reduce(
      (acc, value) => acc + parseFloat(value.price),
      0
    );
    const quantity = result[application].reduce(
      (acc, value) => acc + value.quantity,
      0
    );
    const total = result[application].reduce(
      (acc, value) => acc + parseFloat(value.price) * value.quantity,
      0
    );

    totalOriginal.price += price;
    totalOriginal.quantity += quantity;
    totalOriginal.total += total;

    if (!acc[application]) {
      acc[application] = {};
    }

    acc[application] = { price, quantity, total };

    return acc;
  }, {});

  return {
    ...original,
    ...totalOriginal,
  };
}

function getRevitalizedSummaryData(data) {
  const totalRevitalized = {
    price: 0,
    quantity: 0,
    total: 0,
  };

  const revitalizedModels = getRevitalizedModelsData(data);
  const { actual_purchase_revitalized: actualPurchase } = data;
  const result = {};

  Object.keys(revitalizedModels).forEach((application) => {
    Object.keys(revitalizedModels[application]).forEach((size) => {
      const required = revitalizedModels[application][size]
        .filter((row) => {
          return actualPurchase[row.revitalized_tire_model_id] ? true : false;
        })
        .map(
          ({
            model,
            price_revitalized: price,
            size,
            tire_application_id: tireApplication,
            revitalized_tire_model_id: revitalizedTireModelId,
          }) => ({
            model,
            price_revitalized: price,
            size,
            application: tireApplication,
            revitalized_tire_model_id: revitalizedTireModelId,
            quantity: actualPurchase[revitalizedTireModelId] ?? 0,
          })
        );

      if (!result[application]) {
        result[application] = [];
      }
      result[application] = result[application].concat(required);
    });
  });

  const revitalized = Object.keys(result).reduce((acc, application) => {
    const price = result[application].reduce(
      (acc, value) => acc + parseFloat(value.price_revitalized),
      0
    );
    const quantity = result[application].reduce(
      (acc, value) => acc + value.quantity,
      0
    );
    const total = result[application].reduce(
      (acc, value) =>
        acc + parseFloat(value.price_revitalized) * value.quantity,
      0
    );

    totalRevitalized.price += price;
    totalRevitalized.quantity += quantity;
    totalRevitalized.total += total;

    if (!acc[application]) {
      acc[application] = {};
    }

    acc[application] = { price, quantity, total };

    return acc;
  }, {});

  return {
    ...revitalized,
    ...totalRevitalized,
  };
}

function getReplacementData(data) {
  if (
    data.replacement_original &&
    Object.keys(data.replacement_original).length > 0
  ) {
    return data.replacement_original;
  }

  return {};
}

function getReplacementRevitalizedData(data) {
  if (
    data.replacement_revitalized &&
    Object.keys(data.replacement_revitalized).length > 0
  ) {
    return data.replacement_revitalized;
  }

  return {};
}

function getEstimatedData(data) {
  const original = getOriginalSummaryData(data);
  const revitalized = getRevitalizedSummaryData(data);
  if (Object.keys(original).length > 0 && Object.keys(revitalized).length > 0) {
    return { original, revitalized };
  }

  return {};
}
