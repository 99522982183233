import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function TableHead({ ...props }) {
  return <Page {...props} />;
}

TableHead.propTypes = {
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  headers: PropTypes.array.isRequired,
  selectable: PropTypes.bool.isRequired,
  rows: PropTypes.array,
  totalRows: PropTypes.number,
  selected: PropTypes.array,
};

export default TableHead;
