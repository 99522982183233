import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionUpdateSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/updateSubsidiaryPolicy";
import { actionGetSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/getSubsidiaryPolicy";
import { actionClearSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/clearSubsidiaryPolicy";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiary } from "store/actions/subsidiary/getSubsidiary";

import { useLang } from "hooks/lang";

import { UPDATE_POLICY } from "store/actions/account/permits";

import Page from "./page";

export function HomeSubsidiaryPolicy({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    permits,
    policy,
    getSubsidiaryPolicy,
    clearSubsidiaryPolicy,
    updateSubsidiaryPolicy,
    getSubsidiary,
  } = rest;

  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getSubsidiaryPolicy(id);
      } catch (error) {
        actionSetNotification(error, true);
      }
    }
    fetchData();

    return () => {
      clearSubsidiaryPolicy();
    };
  }, []);

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      subsidiaryId={id}
      policy={policy}
      updateSubsidiaryPolicyPermit={permits.includes(UPDATE_POLICY)}
      getSubsidiaryPolicy={getSubsidiaryPolicy}
      updateSubsidiaryPolicy={updateSubsidiaryPolicy}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      getSubsidiary={getSubsidiary}
    />
  );
}

HomeSubsidiaryPolicy.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  filter: state.Subsidiary.SubsidiaryPolicy.Policy.filter,
  policy: state.Subsidiary.SubsidiaryPolicy.Policy.policy,
});
const mapDispatchToProps = (dispatch) => ({
  getSubsidiaryPolicy: actionGetSubsidiaryPolicy(dispatch),
  clearSubsidiaryPolicy: actionClearSubsidiaryPolicy(dispatch),
  updateSubsidiaryPolicy: actionUpdateSubsidiaryPolicy(dispatch),
  getSubsidiary: actionGetSubsidiary(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeSubsidiaryPolicy);
