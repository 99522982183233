export default {
  title: "Nueva marca",
  update_title: "Actualizar marca",
  form: {
    permits: {
      update: "Sin permisos para actualizar marcas",
      delete: "Sin permisos para eliminar marcas",
    },
  },
  messages: {
    brand_created: "Listo, se creo una nueva marca con éxito",
    brand_updated: "Listo, se actualizo la marca con éxito",
    delete: "¿Estas seguro de eliminar la marca?",
  },
};
