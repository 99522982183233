export default {
  languages: {
    es: "Spanish",
    en: "English",
  },
  text: {
    and: "and",
    or: "or",
  },
  home: "Home",
  role: {
    label: "Role",
    root: "Root",
    manager: "Manager",
    chief_operations: "Chief operations",
    operator: "Operator",
    warehouse: "Warehouse",
    reports: "Reports",
    checklist_operator: "Checklist operator",
    distributor: "Distributor",
    tpms: "Tpms",
  },
  mounting: "Mounting",
  companies: "Companies",
  no_companies: "No companies",
  warehouses: "Warehouses",
  no_warehouses: "No warehouses",
  alerts: "Alerts",
  no_alerts: "No Alerts",
  corporates: "Corporates",
  no_corporates: "No corporates",
  subsidiaries: "Subsidiaries",
  no_subsidiaries: "No subsidiaries",
  associations: "Associations",
  no_associations: "No associations",
  drivers: "Drivers",
  no_drivers: "No drivers",
  vehicles: "Vehicles",
  vehicle_review: "Vehicle Review",
  no_vehicles: "No vehicles",
  no_vehicle_type_axles: "No axles to vehicle type",
  users: "Users",
  no_users: "No users",
  brands: "Brands",
  no_brands: "No brands",
  size: "Size",
  sizes: "Sizes",
  no_sizes: "No sizes",
  tire: "Tire",
  tires: "Tires",
  no_tires: "No tires",
  providers: "Providers",
  no_providers: "No providers",
  wears: "Wears",
  no_wears: "No wears",
  damages: "Damages",
  no_damages: "No damages",
  no_tire_damages: "No tire damages",
  roles: "Roles",
  subsidiary: "Subsidiary",
  association: "Association",
  warehouse: "Warehouse",
  driver: "Driver",
  no_drivers: "No drivers",
  vehicle: "Vehicle",
  gps: "Gps",
  no_gps: "No gps",
  division: "Division",
  divisions: "Divisions",
  no_divisions: "No divisions",
  no_image: "No image",
  history_user: "User history of",
  retirement_causes: "Retirement causes",
  no_retirement_causes: "No retirement causes",
  no_image: "No image",

  tire_models: "Tire models",
  no_tire_models: "No tire models",
  revitalized_tire_models: "Revitalized Tire Model",
  no_revitalized_tire_models: "No Revitalized Tire Model",
  structure_types: {
    truck_tractor: "Truck tractor",
    truck: "Truck",
    box: "Box",
    dolly: "Dolly",
    van: "Van",
  },
  vehicles_types: "Vehicle types",
  no_vehicles_types: "No vehicle types",
  damage: "Damage",
  tire_damage: "Tire damage",
  update_tire_damage: "Update tire damage",
  tire_wear: "Tire wear",
  information: "Information",
  filter: "Filter",

  approved: {
    singular: {
      male: "Approved",
      female: "Approved",
    },
    plural: {
      male: "Approved",
      female: "Approved",
    },
  },

  not_approved: {
    singular: {
      male: "Not Approved",
      female: "Not Approved",
    },
    plural: {
      male: "Not Approved",
      female: "Not Approved",
    },
  },

  enabled: {
    singular: {
      male: "Enabled",
      female: "Enabled",
    },
    plural: {
      male: "Enabled",
      female: "Enabled",
    },
  },

  disabled: {
    singular: {
      male: "Disabled",
      female: "Disabled",
    },
    plural: {
      male: "Disabled",
      female: "Disabled",
    },
  },

  approve: "Approve",
  disapprove: "Disapprove",
  enable: "Enable",
  disable: "Disable",
  view: "View",
  link_vehicle: "Link vehicle",
  approval_status: "Approval status",
  link_subsidiary: "Link subsidiary",
  store: "Store",
  cycle: "Cycle",
  repair_tire: "Send for tire repair",
  gps_geofence: "Geofence",
  no_gps_geofence: "No geofences",
  inside: "Inside",
  outside: "Outside",
  rfid: "Rfid",
  no_rfid: "No rfid",
  link_rfid: "Link rfid",
  confirm: "Confirm",
  disabled_user: "Disabled user",
  company_policies: "Company policies",
  policies: "Policies",
  policies_application: "Application Policies",
  condition_policies: "Condition policies",
  pressure_policies: "Pressure policies",
  no_pressure_policies: "No pressure policies",
  depth_policies: "Depth policies",
  no_depth_policies: "No depth policies",
  application_tire_policies: "Application tire policies",
  model_variation_policies: "Pressure policies by model",
  no_model_variation_policies: "No pressure policies by model",
  tire_history: "Movement history",
  tire_review_history: "Tire review history",
  damage_list: "Damage list",
  wear_list: "Wear list",
  brand: "Brand",
  tire_model: "Tire model",
  revitalized_brand: "Retread brand",
  revitalized_model: "Retread model",
  selected_filters: "Selected filters:",
  selected_tire: "Selected tire:",
  position: "Position",
  depth_min: "Min depth",
  depth_max: "Max depth",
  no_choice: "No choice",
  reports: "Reports",
  set: "Set",
  responsibles: "Responsibles",
  vehicle_km: "Vehicle km",
  tire_reviews: "Tire reviews",
  support: "Contact support",
  alerts_panel: "Alerts panel",
  quantity_tire: "Quantity tire",
  average_days: "Average days",
  average_days_repair: "Average days",
  days: "Days",
  helmet_values: "Helmet values",
  no_model_helmet_values: "No helmet values by model",
  commissioned_drivers: "Commissioned drivers",
  commissioned_vehicles: "Commissioned vehicles",
  checklist: "Checklist",
  no_checklists: "No checklists",
  favorites: "Favorites",
  favorite_reports: "Favorite Reports",
  concepts: "Concepts",
  concept: "Concept",
  no_concepts: "No concepts",
  blocks: "Blocks",
  no_blocks: "No blocks",
  block: "Block",
  disposal: "Disposal",
  loading: "Loading...",
  notifications: "Notifications",
  tpms: "Tpms",
  no_tpms: "No tpms sensors",
  temperature_policy: "Temperature Policies",
  no_temperature_policy: "No Temperature Policies",
  no_chart_tpms_data: "No tpms data",
  tutorials: "Tutorials",
  tpms_link: "Link Tpms",
  administration: "Administration",
  areas: "Areas",
  catalogs: "Catalogs",
};
