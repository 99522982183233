import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateTireStatsExcel =
  (dispatch) =>
  async ({
    movement = "MOUNT",
    subsidiary_id: subsidiaryId,
    company_id: companyId,
    corporate_id: corporateId,
    date_from: dateFrom,
    date_to: dateTo,
    with_refection: withRefection,
    depth_condition: depthCondition,
    brands,
    models,
    sizes,
    revitalized_brands: revitalizedBrands,
    revitalized_models: revitalizedModels,
    depth_min: depthMin,
    depth_max: depthMax,
    activity,
    review_type: reviewType,
    number_cycle: numberCycle,
    current,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/statistics/excel`,
        params: {
          movement,
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          date_from: dateFrom,
          date_to: dateTo,
          with_refection: withRefection,
          depth_condition: depthCondition,
          brands,
          models,
          sizes,
          revitalized_brands: revitalizedBrands,
          revitalized_models: revitalizedModels,
          depth_min: depthMin,
          depth_max: depthMax,
          activity,
          review_type: reviewType,
          number_cycle: numberCycle,
          current,
        },
        download: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
