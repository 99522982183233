import {
  GET_TIRE_CYCLE,
  CLEAR_TIRE_CYCLE,
  REGISTER_TIRE_CYCLE,
  UPDATE_TIRE_CYCLE,
} from "store/actions";

const initialState = {
  cycle: {
    number_cycle: 0,
    variation: {
      revitalized_tire_model: {
        name: "",
      },
      tire_model: { name: "" },
      tire_size: { size: "" },
      number_layers: 0,
      tire_model_id: 0,
      tire_model_variation_id: 0,
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    tire_condition_id: "",
    provider: { name: "" },
    provider_id: 0,
    expected_durability: 0,
    price: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_CYCLE:
    case REGISTER_TIRE_CYCLE:
    case UPDATE_TIRE_CYCLE:
      return {
        ...state,
        cycle: action.payload,
      };

    case CLEAR_TIRE_CYCLE:
      return {
        ...state,
        cycle: initialState.cycle,
      };

    default:
      return state;
  }
}
