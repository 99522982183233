import {
  REGISTER_FAVORITE,
  DELETE_FAVORITE,
  GET_FAVORITES,
} from "store/actions";

const initialState = {
  favorites: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    case REGISTER_FAVORITE:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      };
    case DELETE_FAVORITE:
      return {
        ...state,
        favorites: state.favorites.filter(
          (favorite) => favorite.favorite_report_user_id !== action.payload
        ),
      };

    default:
      return state;
  }
}
