export default {
  stored_tires: "Stored tires",
  no_stored_tires: "No stored tires",
  store_tire: "Store tire",
  form: {
    buttons: {
      store: "Store",
    },
  },
  permits: {
    link: {
      tire_with_warehouse: "No permissions to link tires to warehouses",
    },
    list: {
      link: {
        tire_with_warehouse: "No permission to list warehouse-linked tires",
      },
    },
  },
  messages: {
    tire_with_warehouse_linked: "Done, the tire was successfully stored",
  },
};
