import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTemperaturePolicies } from "store/actions/subsidiary/temperature_policy/getTemperaturePolicies";
import { actionClearTemperaturePolicies } from "store/actions/subsidiary/temperature_policy/clearTemperaturePolicies";
import { actionGetTemperaturePolicy } from "store/actions/subsidiary/temperature_policy/getTemperaturePolicy";

import { useLang } from "hooks/lang";

import Page from "./page";
import { actionUpdateTemperaturePolicy } from "store/actions/subsidiary/temperature_policy/updateTemperaturePolicy";

export function TemperaturePolicyTab({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    policies,
    policy,
    setNotification,
    getTemperaturePolicies,
    clearTemperaturePolicies,
    updateTemperaturePolicy,
    getTemperaturePolicy,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [policyId, setPolicyId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTemperaturePolicies({ page: 1 }, id);
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();

    return () => {
      clearTemperaturePolicies();
    };
  }, []);

  function handleOpenForm(id) {
    setPolicyId(id);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setPolicyId(null);
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      subsidiaryId={id}
      policyId={policyId}
      policies={policies}
      policy={policy}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      getTemperaturePolicies={getTemperaturePolicies}
      getTemperaturePolicy={getTemperaturePolicy}
      updateTemperaturePolicy={updateTemperaturePolicy}
    />
  );
}

TemperaturePolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  policies: state.Subsidiary.TemperaturePolicy.policies,
  policy: state.Subsidiary.TemperaturePolicy.policy,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTemperaturePolicies: actionGetTemperaturePolicies(dispatch),
  clearTemperaturePolicies: actionClearTemperaturePolicies(dispatch),
  getTemperaturePolicy: actionGetTemperaturePolicy(dispatch),
  updateTemperaturePolicy: actionUpdateTemperaturePolicy(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemperaturePolicyTab);
