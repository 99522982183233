import React from "react";
import PropTypes from "prop-types";
import StepperForm from "../StepperForm";

function Page({ ...rest }) {
  return <StepperForm {...rest} />;
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  concepts: PropTypes.array.isRequired,
  blocks: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
