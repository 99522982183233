import React from "react";
import PropTypes from "prop-types";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";

function Page({ size, fullScreen, children, ...rest }) {
  const theme = useTheme();
  const screen = useMediaQuery(theme.breakpoints.down(size || "md"));

  return (
    <Dialog fullScreen={fullScreen ? fullScreen : screen} {...rest}>
      {children}
    </Dialog>
  );
}

Page.propTypes = {
  size: PropTypes.string,
  fullScreen: PropTypes.object,
  children: PropTypes.node,
};

export default Page;
