import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";

import Typography from "@material-ui/core/Typography";
import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";

function Page({
  t,
  origin,
  destination,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      {!!origin && (
        <div
          className={classnames({
            [css.field_wrap]: destination.vehicle_tire.length > 0,
            [css.field]: destination.vehicle_tire.length === 0,
            [css.m8]: destination.vehicle_tire.length === 0,
          })}
        >
          <div className={classnames(css.flex)}>
            <Typography variant="body2">
              <strong>
                {t("_labels.tire_in_position", { position: origin.position })}
              </strong>{" "}
              {origin?.vehicle_tire[0].movement_tire.tire_cycle.tire
                .device_code ||
                origin.vehicle_tire[0].movement_tire.tire_cycle.tire.code}
            </Typography>
            <Typography variant="body2">
              <strong>{t("_labels.depth")}: </strong>
              {`${getMinDepth(
                [...origin.vehicle_tire[0].movement_tire.tire_review].pop()[
                  "tire_review_depth_line"
                ]
              )} mm`}
            </Typography>
          </div>
          <Field
            required
            variant="filled"
            margin="normal"
            name={destination.vehicle_tire.length > 0 ? "depth_from" : "depth"}
            type="number"
            component={TextField}
            className={classnames(css.field)}
            label={t("_labels.depth")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  mm
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                max: getMinDepth(
                  [...origin.vehicle_tire[0].movement_tire.tire_review].pop()[
                    "tire_review_depth_line"
                  ]
                ),
                step: "any",
              },
            }}
          />
        </div>
      )}

      {!!destination && destination.vehicle_tire.length > 0 && (
        <div className={classnames(css.field_wrap)}>
          <div className={classnames(css.flex)}>
            <Typography variant="body2">
              <strong>
                {t("_labels.tire_in_position", {
                  position: destination.position,
                })}
              </strong>{" "}
              {destination?.vehicle_tire[0].movement_tire.tire_cycle.tire
                .device_code ||
                destination.vehicle_tire[0].movement_tire.tire_cycle.tire.code}
            </Typography>
            <Typography variant="body2">
              <strong>{t("_labels.depth")}: </strong>
              {`${getMinDepth(
                [
                  ...destination.vehicle_tire[0].movement_tire.tire_review,
                ].pop()["tire_review_depth_line"]
              )} mm`}
            </Typography>
          </div>

          <Field
            required
            variant="filled"
            margin="normal"
            name="depth_to"
            type="number"
            component={TextField}
            className={classnames(css.field)}
            label={t("_labels.depth")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  mm
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                max: getMinDepth(
                  [
                    ...destination.vehicle_tire[0].movement_tire.tire_review,
                  ].pop()["tire_review_depth_line"]
                ),
                step: "any",
              },
            }}
          />
        </div>
      )}

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  origin: PropTypes.object,
  destination: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
