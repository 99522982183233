export function alphabeticalSort(key, order = "asc") {
  return (a, b) => {
    if (a[key] > b[key]) {
      return order == "asc" ? 1 : -1;
    } else if (b[key] > a[key]) {
      return order == "asc" ? -1 : 1;
    }
    return 0;
  };
}

export function getSortedInfo({ chart, info, colors, borderColors }) {
  const auxArray = [];
  info.forEach((element, index) =>
    auxArray.push({
      value: element,
      label: chart.labels[index],
      color: colors[index],
      borderColor: borderColors[index],
    })
  );
  auxArray.sort(alphabeticalSort("label"));
  const sortedInfo = [];
  const sortedLabels = [];
  const sortedColors = [];
  const sortedBorderColors = [];
  auxArray.forEach((element) => {
    sortedInfo.push(element.value);
    sortedLabels.push(element.label);
    sortedColors.push(element.color);
    sortedBorderColors.push(element.borderColor);
  });

  return { sortedInfo, sortedLabels, sortedColors, sortedBorderColors };
}
