import { REGISTER_DEPTH_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddDepthPolicy =
  (dispatch) => async (subsidiaryId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `subsidiary/${subsidiaryId}/depth/policy/vehicle/axle`,
        data: fields,
      });

      dispatch({
        type: REGISTER_DEPTH_POLICY,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
