import React from "react";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";
import Page from "./page";

function PileTable({ ...props }) {
  const t = useLang();
  return <Page {...props} t={t} />;
}

PileTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  corporate: PropTypes.string.isRequired,
  handleViewTires: PropTypes.func,
  handleViewCauses: PropTypes.func,
};

export default PileTable;
