import { SET_TIRE_MODEL_APPROVAL_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetTireModelApprovalStatus =
  (dispatch) => async (tireModelId, approved) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/model/${tireModelId}/approved`,
        data: { approved },
      });

      dispatch({
        type: SET_TIRE_MODEL_APPROVAL_STATUS,
        payload: tireModelId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
