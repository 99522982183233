import React from "react";

import PropTypes from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";

function Page({
  value,
  variant,
  required,
  disabled,
  children,
  helperText,
  handleChange,
  classNameControl,
  classNameGroup,
}) {
  return (
    <FormControl
      required={required}
      error={helperText !== undefined}
      variant={variant}
      className={classNameControl}
    >
      <RadioGroup
        row
        name="status"
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className={classNameGroup}
      >
        {children}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

Page.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  helperText: PropTypes.string,
  classNameControl: PropTypes.string,
  classNameGroup: PropTypes.string,
};

export default Page;
