import DriverForm from "./DriverForm";
import DriverHistoryTabs from "./DriverHistoryTabs";
import FilterDriverForm from "./FilterDriverForm";
import InfoDriverCard from "./InfoDriverCard";
import vehicle from "./vehicle";
import subsidiary from "./subsidiary";

export default {
  driver_form: DriverForm,
  driver_history_tabs: DriverHistoryTabs,
  filter_driver_form: FilterDriverForm,
  info_driver_card: InfoDriverCard,
  vehicle,
  subsidiary,
};
