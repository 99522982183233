import tire from "./tire";
import vehicle from "./vehicle";
import driver from "./driver";
import FilterSubsidiaryForm from "./FilterSubsidiaryForm";
import InfoSubsidiaryCard from "./InfoSubsidiaryCard";
import SubsidiaryHistoryTabs from "./SubsidiaryHistoryTabs";
import LinkSubsidiaryWarehouseForm from "./LinkSubsidiaryWarehouseForm";
import SubsidiaryForm from "./SubsidiaryForm";
import InfoSubsidiaryWarehouseDialog from "./InfoSubsidiaryWarehouseDialog";
import FilterSubsidiaryWarehouseForm from "./FilterSubsidiaryWarehouseForm";

export default {
  tire,
  vehicle,
  driver,
  filter_subsidiary_form: FilterSubsidiaryForm,
  info_subsidiary_card: InfoSubsidiaryCard,
  subsidiary_history_tabs: SubsidiaryHistoryTabs,
  link_subsidiary_warehouse_form: LinkSubsidiaryWarehouseForm,
  subsidiary_form: SubsidiaryForm,
  info_subsidiary_warehouse_dialog: InfoSubsidiaryWarehouseDialog,
  filter_subsidiary_warehouse_form: FilterSubsidiaryWarehouseForm,
};
