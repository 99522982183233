import React from "react";

import PropTypes from "prop-types";
import { Formik } from "formik";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

function Page({
  t,
  initialErrors,
  initialValues,
  open,
  handleSubmit,
  handleValidate,
  onClose,
}) {
  return (
    <DialogMediaQuery open={open} onClose={onClose}>
      <DialogTitle>
        {t("title")}
        <DialogContentText>{t("sub_title")}</DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          validate={handleValidate}
          onSubmit={handleSubmit}
        >
          {(p) => <Form {...p} onClose={onClose} />}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  open: PropTypes.bool.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default Page;
