import React from "react";
import PropTypes from "prop-types";

import { useLang } from "hooks/lang";
import Page from "./page";

function Progress({ show }) {
  const t = useLang();
  if (!show) {
    return null;
  }
  return <Page t={t} />;
}

Progress.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Progress;
