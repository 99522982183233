import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetCompletedCyclesDetail =
  (dispatch) =>
  async ({ ...params }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "report/completed/cycles/details",
        params: {
          ...params,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
