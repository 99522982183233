import {
  GET_GPS_VEHICLE,
  CLEAR_GPS_VEHICLE,
  ASSIGN_VEHICLE_TO_GPS,
  DELETE_LINK_VEHICLE_WITH_GPS,
} from "store/actions";

const initialState = {
  vehicle: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GPS_VEHICLE:
    case ASSIGN_VEHICLE_TO_GPS:
      return {
        ...state,
        vehicle: action.payload,
      };

    case CLEAR_GPS_VEHICLE:
      return {
        ...state,
        vehicle: initialState.vehicle,
      };

    case DELETE_LINK_VEHICLE_WITH_GPS:
      return {
        ...state,
        vehicle: initialState.vehicle,
      };

    default:
      return state;
  }
}
