import React from "react";

import { Route } from "react-router-dom";

import PropTypes from "prop-types";

function Page({ children, load, ...props }) {
  return <Route {...props}>{load && children}</Route>;
}

Page.propTypes = {
  children: PropTypes.node,
  load: PropTypes.bool.isRequired,
};

export default Page;
