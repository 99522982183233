import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTirePileReport =
  (dispatch) =>
  async ({
    subsidiary_id: subsidiaryId,
    company_id: companyId,
    corporate_id: corporateId,
    date_from: dateFrom,
    date_to: dateTo,
    tire_condition: tireCondition,
    tire_application: tireApplication,
    helmet_value: helmetValue,
    number_cycle: numberCycle,
    brands,
    models,
    sizes,
    price,
    responsible,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/pile/depth/cycle/${numberCycle}`,
        params: {
          subsidiaries: subsidiaryId,
          companies: companyId,
          corporate_id: corporateId,
          date_from: dateFrom,
          date_to: dateTo,
          tire_condition: tireCondition,
          tire_application: tireApplication,
          helmet_value: helmetValue,
          brands,
          models,
          sizes,
          price,
          responsible,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
