import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Line, getElementAtEvent } from "react-chartjs-2";

function Chart({ data, options }) {
  const chartRef = useRef();

  function onClick(event) {
    const result = getElementAtEvent(chartRef.current, event);

    if (result.length > 0) {
      const [{ element }] = result;

      const data = element.$context.raw;

      const responsible =
        data.key !== "total" ? `&responsible=${data.key}` : "";
      const subsidiary = data.subsidiary
        ? `&subsidiary=${data.subsidiary}`
        : "";
      window.open(
        `/reports/pile?date=${data.fecha}${responsible}${subsidiary}`,
        "_blank"
      );
    }
  }
  return (
    <Line options={options} data={data} ref={chartRef} onClick={onClick} />
  );
}

Chart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default Chart;
