import {
  GET_USER_COMPANY_ROLE,
  CLEAR_USER_COMPANY_ROLE,
  GET_USER_COMPANY_HISTORY_ROLE,
  CLEAR_USER_COMPANY_HISTORY_ROLE,
  GET_USER_COMPANY_ROLES_HISTORY,
  CLEAR_USER_COMPANY_ROLES_HISTORY,
} from "store/actions";

const initialState = {
  role: {
    role: "",
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },
  history_role: {
    role: "",
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },
  roles_history: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_COMPANY_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case CLEAR_USER_COMPANY_ROLE:
      return {
        ...state,
        role: { ...initialState.role },
      };
    case GET_USER_COMPANY_HISTORY_ROLE:
      return {
        ...state,
        history_role: action.payload,
      };
    case CLEAR_USER_COMPANY_HISTORY_ROLE:
      return {
        ...state,
        history_role: { ...initialState.history_role },
      };
    case GET_USER_COMPANY_ROLES_HISTORY:
      return {
        ...state,
        roles_history: action.payload,
      };
    case CLEAR_USER_COMPANY_ROLES_HISTORY:
      return {
        ...state,
        roles_history: [...initialState.roles_history],
      };

    default:
      return state;
  }
}
