import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import ContainerTemplate from "components/templates/general/Container";

function Page({ t, video }) {
  return (
    <ContainerTemplate title={t("_general.tutorials")}>
      <div className="sidebar_font px-4 mt-4 pt-2 container-fluid">
        {video && (
          <Slide in timeout={600} direction="down">
            <div>
              <p className="h3 text-center">{t(video.name)}</p>
              <div className="px-4 mt-2 container-fluid d-flex justify-content-center">
                <video
                  className="rounded p-0 m-0"
                  height={480}
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <source src={video.url} type="video/mp4" />
                </video>
              </div>
            </div>
          </Slide>
        )}
      </div>
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func,
  video: PropTypes.object,
};

export default Page;
