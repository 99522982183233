import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE =
  "components/molecules/general/cards/ListItemStatusCard";

function ListItemStatusCard({ ...rest }) {
  const t = useLang(NAMESPACE);

  return <Page {...rest} t={t} />;
}

ListItemStatusCard.propTypes = {
  gender: PropTypes.string,
  className: PropTypes.string,
  tooltipPermit: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ListItemStatusCard;
