export default {
  title: "New Warehouse",
  update_title: "Update Warehouse",
  form: {
    permits: {
      update: "No permissions to update warehouses",
      delete: "No permissions to delete warehouses",
    },
  },
  messages: {
    delete: "Are you sure to delete this warehouse?",
    warehouse_created: "Done, warehouse has been successfully created",
    warehouse_updated: "Done, warehouse has been updated successfully",
  },
};
