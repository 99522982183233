import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    values,
    setNotification,
    getCompanies,
    getSubsidiaries,
    setSubsidiaries,
    setCompanies,
    setFieldValue,
    setModels,
    getTireModels,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
            corporates: values.corporate_id,
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.corporate_id) {
      fetchData();
    }
  }, [values.corporate_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            scope: "subsidiary_id,name,status",
            companies: values.company_id.join(),
          },
          false
        );
        setSubsidiaries(data.sort(alphabeticalSort("name")));
        if (data.length === 0) {
          setFieldValue("subsidiary_id", data);
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.company_id.length > 0) {
      fetchData();
    }
  }, [values.company_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataTireModels = [];
        dataTireModels = await getTireModels(
          {
            brands: values.brands.map((brand) => brand.brand_id).join(),
            scope:
              "tire_model_id,tire_model.name,tire_model.status,tire_model.approved",
          },
          false
        );
        const uniqueModels = dataTireModels.reduce((result, model) => {
          if (!result.find((el) => el.tire_model_id == model.tire_model_id)) {
            result.push(model);
          }
          return result;
        }, []);

        const result = uniqueModels
          .map((model) => ({
            tire_model_id: model.tire_model_id,
            name: model.tire_model.name,
            status: model.tire_model.status,
            approved: model.tire_model.approved,
          }))
          .sort(alphabeticalSort("name"));
        setModels(result);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (values.brands.length > 0) {
      fetchData();
    }
  }, [values.brands]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  movements: PropTypes.array.isRequired,
  conditions: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
  setSubsidiaries: PropTypes.func.isRequired,
  isPile: PropTypes.bool,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getTireModels: actionGetTireModels(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
