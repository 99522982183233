import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddDepthPolicy } from "store/actions/subsidiary/policy/depthPolicyByAxle/addDepthPolicy";
import { actionClearDepthPolicyFilters } from "store/actions/subsidiary/policy/depthPolicyByAxle/clearDepthPolicyFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function MultipleDepthPolicyForm({
  open,
  onClose,
  subsidiaryId,
  vehicleIds,
  ...rest
}) {
  const t = useLang();
  const { addDepthPolicy, actionClearDepthPolicyFilters, setNotification } =
    rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      axle_policy: [
        {
          axle_type: "",
          good_depth: "",
          scheduled_withdrawal: "",
          critical_withdrawal: "",
        },
      ],
    },
  });

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      vehicles: vehicleIds.map((vehicleId) => ({ vehicle_id: vehicleId })),
      axle_policy: values.axle_policy.map((policy) => ({
        ...policy,
        good_depth: policy.scheduled_withdrawal + 3,
      })),
    };

    try {
      await addDepthPolicy(subsidiaryId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.depth_policy"),
      });
      setSubmitting(false);
      onClose();
      actionClearDepthPolicyFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

MultipleDepthPolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleIds: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addDepthPolicy: actionAddDepthPolicy(dispatch),
  actionClearDepthPolicyFilters: actionClearDepthPolicyFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleDepthPolicyForm);
