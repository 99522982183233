import React from "react";
import TireIcon from "components/atoms/general/TireIcon";
import TireRevitalizedIcon from "components/atoms/general/TireRevitalizedIcon";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import BuildIcon from "@material-ui/icons/Build";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SpeedIcon from "@material-ui/icons/Speed";
import TrafficRoundedIcon from "@material-ui/icons/TrafficRounded";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";

export { getFavoriteIcon };

function getFavoriteIcon(name) {
  switch (name) {
    case "report_tire_pile_depth":
      return <ViewAgendaIcon />;
    case "report_tire_pile_travel":
      return <SpeedIcon />;
    case "report_tire_statistics":
    case "report_tire_statistics_historic":
      return <TireIcon />;
    case "report_km_performance":
      return <TrendingUpIcon />;
    case "report_km_projection":
      return <TrendingUpIcon />;
    case "report_tire_summary":
      return <ViewAgendaIcon />;
    case "report_tire_summary_current":
      return <ViewAgendaIcon />;
    case "report_km_dynamic_cost":
      return <MonetizationOnOutlinedIcon />;
    case "report_renewability_index":
      return <AutorenewIcon />;
    case "report_review_performance":
    case "report_review_performance_company":
      return <AutorenewIcon />;
    case "report_tire_po_savings":
      return <AutorenewIcon />;
    case "report_tire_damage_wear":
      return <ViewAgendaIcon />;
    case "report_tire_warehouse":
      return <ViewAgendaIcon />;
    case "report_user_review":
      return <GroupAddIcon />;
    case "report_purchase":
      return <ShoppingCartIcon />;
    case "report_last_movement":
      return <TrendingDownIcon />;
    case "report_vehicle_semaphore":
    case "report_vehicle_fleet_semaphore":
      return <TrafficRoundedIcon />;
    case "report_tire_revitalized":
      return <TireRevitalizedIcon />;
    case "report_tire_repair":
      return <BuildIcon />;
    case "report_tire_mount":
      return <TireRevitalizedIcon />;
    case "report_summary_warehouse":
      return <ViewAgendaIcon />;
    case "report_completed_cycles":
      return <DoneAllRoundedIcon />;
    default:
      return <TireIcon />;
  }
}
