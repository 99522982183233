import {
  DELETE_VEHICLE_TRAVEL,
  REGISTER_VEHICLE_TRAVEL,
  UPDATE_VEHICLE_TRAVEL,
  GET_LAST_VEHICLE_TRAVEL,
  CLEAR_LAST_VEHICLE_TRAVEL,
} from "store/actions";

const initialState = {
  travel: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_VEHICLE_TRAVEL:
    case UPDATE_VEHICLE_TRAVEL:
    case GET_LAST_VEHICLE_TRAVEL:
      return {
        ...state,
        travel: action.payload,
      };

    case DELETE_VEHICLE_TRAVEL:
    case CLEAR_LAST_VEHICLE_TRAVEL:
      return {
        ...state,
        travel: initialState.travel,
      };

    default:
      return state;
  }
}
