import {
  GET_TIRE_DAMAGE,
  CLEAR_TIRE_DAMAGE,
  GET_TIRE_DAMAGES,
  CLEAR_TIRE_DAMAGES,
  DELETE_TIRE_DAMAGE,
  REGISTER_TIRE_DAMAGE,
  SET_TIRE_DAMAGE_FILTERS,
  CLEAR_TIRE_DAMAGE_FILTERS,
} from "store/actions";

const initialState = {
  damage: {
    damage: { name: "" },
    tire: { code: "" },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  damages: [],
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_TIRE_DAMAGE:
    case GET_TIRE_DAMAGE:
      return {
        ...state,
        damage: action.payload,
      };

    case CLEAR_TIRE_DAMAGE:
      return {
        ...state,
        damage: initialState.damage,
      };

    case GET_TIRE_DAMAGES:
      return {
        ...state,
        damages: action.payload,
      };

    case CLEAR_TIRE_DAMAGES:
      return {
        ...state,
        damages: initialState.damages,
      };

    case DELETE_TIRE_DAMAGE:
      return {
        ...state,
        damages: {
          ...state.damages,
          data: state.damages.data.filter(
            (damage) => damage.tire_damage_id !== action.payload
          ),
        },
      };

    case SET_TIRE_DAMAGE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_TIRE_DAMAGE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
