import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetLastVehicleReview } from "store/actions/vehicle/review/getLastVehicleReview";
import { actionClearLastVehicleReview } from "store/actions/vehicle/review/clearLastVehicleReview";
import { actionAddVehicleReview } from "store/actions/vehicle/review/addVehicleReview";
import { actionFinalizeVehicleReview } from "store/actions/vehicle/review/finalizeVehicleReview";
import { actionGetGpsOdometer } from "store/actions/vehicle/gps/getGpsOdometer";

import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";

import {
  CREATE_VEHICLE_REVIEW,
  UPDATE_VEHICLE_REVIEW,
  DELETE_VEHICLE_REVIEW,
} from "store/actions/account/permits";

import Page from "./page";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export function LastVehicleReviewPanel({ vehicleId, ...rest }) {
  const t = useLang();
  const {
    review,
    permits,
    setNotification,
    getLastVehicleReview,
    clearLastVehicleReview,
    addVehicleReview,
    finalizeVehicleReview,
    getGpsOdometer,
    showProgress,
  } = rest;

  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
  } = useFormik({
    initialValues: {
      date: "",
      odometer: "",
      observation: "",
      review_type: "",
    },
  });

  const [hasGpsOdometer, setHasGpsOdometer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();
  useEffect(() => {
    async function fetchData() {
      try {
        await getLastVehicleReview(vehicleId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (vehicleId) {
      fetchData();
    }

    return () => {
      clearLastVehicleReview();
    };
  }, [vehicleId]);

  useEffect(() => {
    setInitialValues({
      date: "",
      odometer: parseFloat(review.odometer.toFixed(2)),
      observation: "",
      review_type: "",
    });
  }, [review.odometer]);

  useEffect(() => {
    if (review.vehicle_review_id) {
      if (review.vehicle.gps_vehicle.length > 0) {
        async function setGpsOdometer() {
          try {
            setIsLoading(true);
            const { gps_id: gpsId, vehicle_id: vehicleId } =
              review.vehicle.gps_vehicle[0];
            const response = await getGpsOdometer(gpsId, vehicleId);

            if (response.odometer) {
              setHasGpsOdometer(true);
              setInitialValues({
                date: "",
                odometer: parseFloat(response.odometer.toFixed(2)),
                observation: "",
                review_type: "",
              });
            }
          } catch (error) {
            setNotification(error, true);
          } finally {
            setIsLoading(false);
          }
        }

        setGpsOdometer();
      }
    }
  }, [review]);

  async function handleSubmit(values, { setSubmitting, setValues, ...rest }) {
    try {
      const fields = {
        odometer: values.odometer,
        date: values.date,
        review_type: values.review_type || "MOUNT/DISMOUNT",
      };

      if (values.observation !== "") {
        fields.observation = values.observation;
      }

      await addVehicleReview(vehicleId, fields);

      setNotification({
        message: t("_messages.created.vehicle_review"),
      });
      setValues({
        date: "",
        odometer: values.odometer,
        observation: "",
      });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  async function finalizeReview() {
    try {
      await finalizeVehicleReview(vehicleId);
      await getLastVehicleReview(vehicleId);
      setNotification({
        message: t("_messages.finalized"),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function onFinalize() {
    setNotification({
      message: t("_messages.finalize"),
      textAction: t("_buttons.confirm"),
      onAction: finalizeReview,
    });
  }

  function confirmSubmit() {
    const { odometer } = formRef.current.values;
    const travel = odometer - review.odometer;

    formRef.current.setSubmitting(false);
    setNotification({
      textAction: t("_buttons.confirm"),
      onAction: () => {
        formRef.current.setSubmitting(true);
        handleSubmit(formRef.current.values, { ...formRef.current });
      },
      message: t("_messages.travel_confirm", {
        value: formatter.format(travel),
      }),
    });
  }

  function onDeleteReview() {
    setNotification({
      textAction: t("_buttons.confirm"),
      onAction: deleteVehicleReview,
      message: t("_messages.delete.vehicle_review"),
    });
  }

  async function deleteVehicleReview() {
    const progress = showProgress();
    try {
      await http({
        path: `vehicle/${vehicleId}/review`,
        method: "DELETE",
      });

      setNotification({ message: t("_messages.deleted.vehicle_review") });
      await getLastVehicleReview(vehicleId);
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }
  return (
    <Page
      t={t}
      {...rest}
      review={{
        ...review,
        odometer: `${formatter.format(review.odometer)} km`,
        created_by: t("_labels.actioned_by", {
          person: `${review.created_by.name} ${review.created_by.last_name_1} ${
            review.created_by.last_name_2 || ""
          }`,
          date: moment(review.created_at).format("LL"),
          time: moment(review.created_at).format("h:mm a"),
        }),
        updated_by: review.updated_by
          ? t("_labels.actioned_by", {
              person: `${review.updated_by.name} ${
                review.updated_by.last_name_1
              } ${review.updated_by.last_name_2 || ""}`,
              date: moment(review.updated_at).format("LL"),
              time: moment(review.updated_at).format("h:mm a"),
            })
          : null,
      }}
      hasGpsOdometer={hasGpsOdometer}
      isLoading={isLoading}
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      onFinalize={onFinalize}
      createVehicleReviewPermit={permits.includes(CREATE_VEHICLE_REVIEW)}
      updateVehicleReviewPermit={permits.includes(UPDATE_VEHICLE_REVIEW)}
      deleteVehicleReviewPermit={permits.includes(DELETE_VEHICLE_REVIEW)}
      isValidReview={moment().diff(moment(review.created_at), "hours") < 24}
      confirmSubmit={confirmSubmit}
      formRef={formRef}
      onDeleteReview={onDeleteReview}
    />
  );
}

LastVehicleReviewPanel.propTypes = {
  vehicleId: PropTypes.number.isRequired,
  isInReviewPage: PropTypes.bool,
  inProcess: PropTypes.bool,
  handleOpenVehicleReviewForm: PropTypes.func,
};

const mapStateToProps = (state) => ({
  review: state.Vehicle.Review.vehicle_review,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getLastVehicleReview: actionGetLastVehicleReview(dispatch),
  clearLastVehicleReview: actionClearLastVehicleReview(dispatch),
  addVehicleReview: actionAddVehicleReview(dispatch),
  finalizeVehicleReview: actionFinalizeVehicleReview(dispatch),
  getGpsOdometer: actionGetGpsOdometer(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LastVehicleReviewPanel);
