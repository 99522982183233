export default {
  disabled_retirement_cause: "Disabled retirement cause",
  permits: {
    change_status: "No permissions to {action} retirement causes",
  },
  messages: {
    enable: "Are you sure to enable retirement cause?",
    disable: "Are you sure to disable retirement cause?",
  },
};
