import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDisposal } from "store/actions/disposal/getDisposal";
import { actionGetDisposalBill } from "store/actions/disposal/getDisposalBill";
import { actionGetDisposalYearly } from "store/actions/disposal/getDisposalYearly";
import { actionGetDisposalBillYearly } from "store/actions/disposal/getDisposalBillYearly";
import { actionSetDisposalFilters } from "store/actions/disposal/setDisposalFilters";
import { actionClearDisposalFilters } from "store/actions/disposal/clearDisposalFilters";
import { actionSetSelectedDisposalFilters } from "store/actions/disposal/setSelectedDisposalFilters";

import {
  getCompanyViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { getMonthlyCharts, getYearlyCharts } from "utils/disposalCharts";
import { useLang } from "hooks/lang";
import { arrayJoin } from "utils/parse";

import Page from "./page";

export function HomeDisposal({ ...rest }) {
  const t = useLang();
  const {
    account,
    workArea,
    language,
    filter,
    selectedFilter,
    setNotification,
    setDisposalFilters,
    clearDisposalFilters,
    setSelectedFilters,
    getDisposal,
    getDisposalBill,
    getDisposalYearly,
    getDisposalBillYearly,
  } = rest;

  const [monthly, setMonthly] = useState(true);
  const [data, setData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = monthly
          ? await getDisposal({ ...filter })
          : await getDisposalYearly({ ...filter });
        const billResponse = monthly
          ? await getDisposalBill({ ...filter })
          : await getDisposalBillYearly({ ...filter });
        const charts = monthly
          ? getMonthlyCharts(response, billResponse, t)
          : getYearlyCharts(response, billResponse, t);
        setData(charts);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [monthly, language, filter]);

  useEffect(() => {
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    setDisposalFilters({
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    });

    setSelectedFilters({
      companies: {
        label: t("_labels.company_field.singular.label"),
        value: companies.map((company) => company.company.name).join(),
      },
      subsidiaries: {
        label: t("_labels.subsidiary_field.plural.label"),
        value: arrayJoin(
          subsidiaries.map((subsidiary) => subsidiary.subsidiary.name),
          " , ",
          ` ${t("_general.text.and")} `
        ),
      },
    });

    return () => {
      clearDisposalFilters();
    };
  }, []);

  function toggleMonthly(value) {
    setMonthly(value);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      monthly={monthly}
      toggleMonthly={toggleMonthly}
      data={data}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      selectedFilter={selectedFilter}
      filter={filter}
    />
  );
}

HomeDisposal.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  language: state.General.language,
  filter: state.Disposal.filter,
  selectedFilter: state.Disposal.selected_filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDisposal: actionGetDisposal(dispatch),
  getDisposalBill: actionGetDisposalBill(dispatch),
  getDisposalYearly: actionGetDisposalYearly(dispatch),
  getDisposalBillYearly: actionGetDisposalBillYearly(dispatch),
  setDisposalFilters: actionSetDisposalFilters(dispatch),
  clearDisposalFilters: actionClearDisposalFilters(dispatch),
  setSelectedFilters: actionSetSelectedDisposalFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeDisposal);
