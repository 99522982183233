import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import FilterKmProjectionForm from "components/organisms/report/FilterKmProjectionForm";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({
  t,
  data,
  filter,
  selectedFilter,
  search,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleClickApplication,
  handleGenerateKmProjectionExcel,
  handleSearch,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.km_projection_report")}
      options={
        <>
          <Button
            classNameButton={classnames("bw")}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.companies && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiaries && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}

                  {filter.users && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.user.plural.label")}:
                      </td>
                      <td>{selectedFilter.users}</td>
                    </tr>
                  )}

                  {filter.review_types && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.review_type.label")}:
                      </td>
                      <td>{selectedFilter.review_types}</td>
                    </tr>
                  )}
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.size")}:
                      </td>
                      <td>{selectedFilter.sizes}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">{t("_labels.km_projection_report.title")}</h1>
            <h5 className="sinmp">{t("_labels.km_projection_report.label")}</h5>
            {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")}
                  {" Y "}
                  {t("_labels.location.options.warehouse")}
                </small>
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-3">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateKmProjectionExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    {Object.keys(data).length > 0 && (
                      <div className="table-responsive">
                        {Object.entries(data).map(
                          ([corporate, corporateData]) => (
                            <Fragment key={corporate}>
                              <table className="table table-condensed shadow-sm">
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate}_companies`,
                                        corporate,
                                        "original"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col4">
                                      <small>Km/mm</small>
                                      <br />
                                      {formatter.format(corporateData["km/mm"])}
                                    </th>
                                    <th className="text-right col5">
                                      <small>
                                        {t(
                                          "_labels.km_projection_report.km_projection"
                                        )}
                                      </small>
                                      <br />
                                      {formatter.format(
                                        corporateData["projection"]
                                      )}
                                    </th>
                                    <th className="text-right col6">
                                      <small>
                                        {t(
                                          "_labels.km_projection_report.km_projection_cost"
                                        )}
                                      </small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporateData["cpk"]
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(corporateData)
                                    .filter((e) => typeof e[1] === "object")
                                    .map(([company, companyData]) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="original"
                                          data-id={`${removeCharacters(
                                            corporate
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate}_${company}_subsidiaries`,
                                              company,
                                              "original"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3"></td>
                                          <td className="text-right col3"></td>
                                          <td className="text-right col4">
                                            {formatter.format(
                                              companyData["km/mm"]
                                            )}
                                          </td>
                                          <td className="text-right col5">
                                            {formatter.format(
                                              companyData["projection"]
                                            )}
                                          </td>
                                          <td className="text-right col6">
                                            $
                                            {costKmFormatter.format(
                                              companyData["cpk"]
                                            )}
                                          </td>
                                        </tr>

                                        {Object.entries(companyData)
                                          .filter(
                                            (e) => typeof e[1] === "object"
                                          )
                                          .map(
                                            ([subsidiary, subsidiaryData]) => {
                                              return (
                                                <Fragment key={subsidiary}>
                                                  <tr
                                                    data-type="original"
                                                    data-id={removeCharacters(
                                                      `${corporate}_${company}_subsidiaries`
                                                    )}
                                                    className="subsidiary box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate}_${company}_${subsidiary}_divisions`,
                                                        `${company}_${subsidiary}`,
                                                        "original"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {subsidiary}
                                                    </td>
                                                    <td></td>
                                                    <td className="text-right col3"></td>
                                                    <td className="text-right col3"></td>
                                                    <td className="text-right col4">
                                                      {formatter.format(
                                                        subsidiaryData["km/mm"]
                                                      )}
                                                    </td>
                                                    <td className="text-right col5">
                                                      {formatter.format(
                                                        subsidiaryData[
                                                          "projection"
                                                        ]
                                                      )}
                                                    </td>
                                                    <td className="text-right col6">
                                                      $
                                                      {costKmFormatter.format(
                                                        subsidiaryData["cpk"]
                                                      )}
                                                    </td>
                                                  </tr>

                                                  {Object.entries(
                                                    subsidiaryData
                                                  )
                                                    .filter(
                                                      (e) =>
                                                        typeof e[1] === "object"
                                                    )
                                                    .map(
                                                      ([
                                                        division,
                                                        divisionData,
                                                      ]) => {
                                                        return (
                                                          <Fragment
                                                            key={division}
                                                          >
                                                            <tr
                                                              data-type="original"
                                                              data-id={removeCharacters(
                                                                `${corporate}_${company}_${subsidiary}_divisions`
                                                              )}
                                                              className="division box collapse"
                                                              onClick={() => {
                                                                toggleTable(
                                                                  `${corporate}_${company}_${subsidiary}_${division}_applications`,
                                                                  `${company}_${subsidiary}_${division}`,
                                                                  "original"
                                                                );
                                                              }}
                                                            >
                                                              <td className="text-left">
                                                                {division ===
                                                                "NO DIVISION"
                                                                  ? t(
                                                                      "_labels.no_division"
                                                                    )
                                                                  : division}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3"></td>
                                                              <td className="text-right col3"></td>
                                                              <td className="text-right col4">
                                                                {formatter.format(
                                                                  divisionData[
                                                                    "km/mm"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="text-right col5">
                                                                {formatter.format(
                                                                  divisionData[
                                                                    "projection"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="text-right col6">
                                                                $
                                                                {costKmFormatter.format(
                                                                  divisionData[
                                                                    "cpk"
                                                                  ]
                                                                )}
                                                              </td>
                                                            </tr>

                                                            {Object.entries(
                                                              divisionData
                                                            )
                                                              .filter(
                                                                (e) =>
                                                                  typeof e[1] ===
                                                                  "object"
                                                              )
                                                              .map(
                                                                ([
                                                                  application,
                                                                  applicationData,
                                                                ]) => {
                                                                  return (
                                                                    <Fragment
                                                                      key={
                                                                        application
                                                                      }
                                                                    >
                                                                      <tr
                                                                        data-type="original"
                                                                        data-id={removeCharacters(
                                                                          `${corporate}_${company}_${subsidiary}_${division}_applications`
                                                                        )}
                                                                        className={`box collapse application`}
                                                                        onClick={() => {
                                                                          toggleTable(
                                                                            `${corporate}_${company}_${subsidiary}_${division}_${application}_models`,
                                                                            `${company}_${subsidiary}_${division}_${application}`,
                                                                            "original"
                                                                          );
                                                                          handleClickApplication(
                                                                            {
                                                                              corporate:
                                                                                corporate,
                                                                              company:
                                                                                company,
                                                                              subsidiary:
                                                                                subsidiary,
                                                                              division:
                                                                                division,
                                                                              tire_application:
                                                                                application,
                                                                            }
                                                                          );
                                                                        }}
                                                                      >
                                                                        <td className="text-left">
                                                                          {t(
                                                                            `_labels.tire_application.options.${application.toLowerCase()}`
                                                                          )}
                                                                        </td>
                                                                        <td></td>
                                                                        <td className="text-right col3"></td>
                                                                        <td className="text-right col3"></td>
                                                                        <td className="text-right col4">
                                                                          {formatter.format(
                                                                            applicationData[
                                                                              "km/mm"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right col5">
                                                                          {formatter.format(
                                                                            applicationData[
                                                                              "projection"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right col6">
                                                                          $
                                                                          {costKmFormatter.format(
                                                                            applicationData[
                                                                              "cpk"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                      </tr>
                                                                      {Object.entries(
                                                                        applicationData
                                                                      )
                                                                        .filter(
                                                                          (e) =>
                                                                            typeof e[1] ===
                                                                              "object" &&
                                                                            e[1] !==
                                                                              null
                                                                        )
                                                                        .map(
                                                                          ([
                                                                            model,
                                                                            modelData,
                                                                          ]) => {
                                                                            return (
                                                                              <Fragment
                                                                                key={
                                                                                  model
                                                                                }
                                                                              >
                                                                                <tr
                                                                                  data-type="original"
                                                                                  data-id={removeCharacters(
                                                                                    `${corporate}_${company}_${subsidiary}_${division}_${application}_models`
                                                                                  )}
                                                                                  className="vehicle_type box"
                                                                                  onClick={() => {
                                                                                    toggleTable(
                                                                                      `${corporate}_${company}_${subsidiary}_${division}_${application}_${model}_tires`,
                                                                                      `${company}_${subsidiary}_${division}_${application}_${model}`,
                                                                                      "original"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <td className="text-left">
                                                                                    {
                                                                                      model
                                                                                    }
                                                                                  </td>
                                                                                  <td></td>
                                                                                  <td className="text-right col3"></td>
                                                                                  <td className="text-right col3"></td>
                                                                                  <td className="text-right col4">
                                                                                    {formatter.format(
                                                                                      modelData[
                                                                                        "km/mm"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                  <td className="text-right col5">
                                                                                    {formatter.format(
                                                                                      modelData[
                                                                                        "projection"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                  <td className="text-right col6">
                                                                                    $
                                                                                    {costKmFormatter.format(
                                                                                      modelData[
                                                                                        "cpk"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                </tr>
                                                                                {Object.values(
                                                                                  modelData
                                                                                )
                                                                                  .filter(
                                                                                    (
                                                                                      e
                                                                                    ) =>
                                                                                      typeof e ===
                                                                                        "object" &&
                                                                                      e !==
                                                                                        null
                                                                                  )
                                                                                  .map(
                                                                                    (
                                                                                      tire
                                                                                    ) => {
                                                                                      return (
                                                                                        <tr
                                                                                          data-type="original"
                                                                                          data-id={removeCharacters(
                                                                                            `${corporate}_${company}_${subsidiary}_${division}_${application}_${model}_tires`
                                                                                          )}
                                                                                          key={
                                                                                            tire.code
                                                                                          }
                                                                                          className="review box collapse"
                                                                                        >
                                                                                          <td className="text-left">
                                                                                            <Link
                                                                                              to={`/tire/${tire["tire_id"]}/history`}
                                                                                              target="_blank"
                                                                                              rel="noopener"
                                                                                            >
                                                                                              <Button
                                                                                                style={{
                                                                                                  color:
                                                                                                    "white",
                                                                                                }}
                                                                                                className="spanButton"
                                                                                              >
                                                                                                <VisibilityIcon
                                                                                                  fontSize="small"
                                                                                                  className="mr-1"
                                                                                                />
                                                                                                {
                                                                                                  tire[
                                                                                                    "code"
                                                                                                  ]
                                                                                                }
                                                                                              </Button>
                                                                                            </Link>
                                                                                          </td>
                                                                                          <td className="text-right"></td>
                                                                                          <td className="text-right"></td>
                                                                                          <td className="text-right"></td>
                                                                                          <td className="text-right col4">
                                                                                            {formatter.format(
                                                                                              tire[
                                                                                                "km/mm"
                                                                                              ]
                                                                                            )}
                                                                                          </td>
                                                                                          <td className="text-right col5">
                                                                                            {formatter.format(
                                                                                              tire[
                                                                                                "projection"
                                                                                              ]
                                                                                            )}
                                                                                          </td>
                                                                                          <td className="text-right col6">
                                                                                            $
                                                                                            {costKmFormatter.format(
                                                                                              tire[
                                                                                                "cpk"
                                                                                              ]
                                                                                            )}
                                                                                          </td>
                                                                                        </tr>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                              </Fragment>
                                                                            );
                                                                          }
                                                                        )}
                                                                    </Fragment>
                                                                  );
                                                                }
                                                              )}
                                                          </Fragment>
                                                        );
                                                      }
                                                    )}
                                                </Fragment>
                                              );
                                            }
                                          )}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </table>
                            </Fragment>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterKmProjectionForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  handleOpenTireDialog: PropTypes.func.isRequired,
  handleCloseTireDialog: PropTypes.func.isRequired,
  handleGenerateKmProjectionExcel: PropTypes.func.isRequired,
  handleClickApplication: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  vehicleReviewId: PropTypes.number,
};

export default Page;

function removeCharacters(string, replacer = "") {
  return string.replaceAll(/[,()|-\s\.\/]/g, replacer);
}
