import corporate from "./corporate";
import company from "./company";
import subsidiary from "./subsidiary";
import FilterUserForm from "./FilterUserForm";
import InfoUserCard from "./InfoUserCard";
import UserHistoryTabs from "./UserHistoryTabs";
import UserForm from "./UserForm";

export default {
  corporate,
  company,
  subsidiary,
  filter_user_form: FilterUserForm,
  info_user_card: InfoUserCard,
  user_history_tabs: UserHistoryTabs,
  user_form: UserForm,
};
