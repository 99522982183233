import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddNotification } from "store/actions/notification/addNotification";

import Page from "./page";

export function NotificationForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { setNotification, addNotification } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    // setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      title: "",
    },
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (!open) {
      setEditorState(EditorState.createEmpty());
      resetFormik();
    }
  }, [open]);

  function handleEditorChange(state) {
    setEditorState(state);
  }

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const fields = {
        ...values,
        body: draftToMarkdown(rawContentState),
      };

      await addNotification(fields);
      setNotification({ message: t("_messages.created.notification") });

      setSubmitting(false);
      resetForm();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      editorState={editorState}
      handleEditorChange={handleEditorChange}
    />
  );
}

NotificationForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  addNotification: actionAddNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationForm);
