import { GET_TIRES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTires =
  (dispatch) =>
  async (
    {
      search,
      status,
      revitalized,
      with_pending_movement: withPendingMovement,
      with_link_subsidiary: withLinkSubsidiary,
      with_link_division: withLinkDivision,
      with_link_rfid: withLinkRfid,
      with_link_tpgps: withLinkTpgps,
      with_alerts: withAlerts,
      alert_code: alertCode,
      brands,
      rfids,
      subsidiaries,
      divisions,
      warehouses,
      vehicle,
      providers,
      tire_size: tireSize,
      models,
      model_variations: modelVariations,
      condition,
      location,
      DOT_initial: dotInitial,
      DOT_final: dotFinal,
      revitalized_brands: revitalizedBrands,
      revitalized_models: revitalizedModels,
      per_page: perPage,
      invoice_date: invoiceDate,
      invoice_folio: invoiceFolio,
      page,
      scope,
      order = "DESC",
      sort_by_mm: sortByMm,
      date_from: dateFrom,
      date_to: dateTo,
      depth_min: depthMin,
      depth_max: depthMax,
      tire_ids: tireIds,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `tire`,
        params: {
          search,
          status,
          revitalized,
          with_pending_movement: withPendingMovement,
          with_link_subsidiary: withLinkSubsidiary,
          with_link_division: withLinkDivision,
          with_link_rfid: withLinkRfid,
          with_link_tpgps: withLinkTpgps,
          with_alerts: withAlerts,
          alert_code: alertCode,
          model_variations: modelVariations,
          brands,
          rfids,
          subsidiaries,
          divisions,
          models,
          warehouses,
          providers,
          vehicle,
          tire_size: tireSize,
          condition,
          location,
          DOT_initial: dotInitial,
          DOT_final: dotFinal,
          revitalized_brands: revitalizedBrands,
          revitalized_models: revitalizedModels,
          per_page: perPage,
          page,
          scope,
          order,
          date_from: dateFrom,
          date_to: dateTo,
          depth_min: depthMin,
          depth_max: depthMax,
          tire_ids: tireIds,
          invoice_date: invoiceDate,
          invoice_folio: invoiceFolio,
          sort_by_mm: sortByMm,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRES,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
