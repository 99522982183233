import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Formik, Field, FieldArray, Form } from "formik";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LabelAndText from "components/molecules/general/LabelAndText";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateTimeField from "components/molecules/general/DateTimeField";

import RadioInput from "components/atoms/general/RadioInput";
import RadioField from "components/molecules/general/RadioField";
import Button from "components/molecules/general/Button";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

import css from "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "#4eacdf",
    color: theme.palette.common.white,
    textAlign: "center",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  body: {
    textAlign: "center",
    verticalAlign: "middle",
    paddingTop: "1px",
    paddingBottom: "1px",
  },
}));

function Page({
  t,
  review,
  axleTires,
  hasGpsOdometer,
  isLoading,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
}) {
  const classes = useStyles();
  return (
    <>
      {axleTires.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <Card className={classnames(css.card)} elevation={1}>
              <Formik
                enableReinitialize
                initialErrors={initialErrors}
                initialValues={initialValues}
                validate={handleValidate}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <CardContent className={classnames(css.pt4)}>
                      <div className={classnames(css.content)}>
                        <Typography
                          className={classnames(css.mb0)}
                          variant="h6"
                          color="textPrimary"
                        >
                          {t("_titles.show.inspection")}
                        </Typography>
                        <div className={classnames(css.flex, css.content_info)}>
                          <Typography
                            className={classnames(css.full_width)}
                            variant="overline"
                            color="textSecondary"
                          >
                            {t("_labels.last_review")}
                          </Typography>

                          <Typography
                            className={classnames(css.full_width)}
                            variant="body1"
                            color="textPrimary"
                          >
                            {`${t("_labels.done_by")} ${review.created_by}`}
                          </Typography>

                          <div className={classnames(css.flex, css.w100)}>
                            <LabelAndText
                              className={classnames(css.full_width)}
                              label={t("_labels.odometer")}
                              text={review.odometer}
                            />

                            <LabelAndText
                              className={classnames(css.full_width)}
                              label={t("_labels.review_type.label")}
                              text={t(
                                `_labels.review_type.options.${review.review_type
                                  .toLowerCase()
                                  .replace(/\s|\//g, "_")}`
                              )}
                            />
                          </div>
                          <Typography
                            className={classnames(css.full_width)}
                            variant="overline"
                            color="textSecondary"
                          >
                            {t("_labels.new_review")}
                          </Typography>

                          <div className={classnames(css.full_width)}>
                            <div className={classnames(css.full_width)}>
                              <Field
                                required
                                variant="filled"
                                name="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                type="date"
                                component={DateTimeField}
                                label={t("_labels.date.label")}
                                className={classnames(
                                  css.medium_field,
                                  css.mr8
                                )}
                                InputProps={{
                                  inputProps: {
                                    max: moment().format("YYYY-MM-DD"),
                                  },
                                }}
                              />

                              <Field
                                required
                                variant="filled"
                                name="odometer"
                                component={TextField}
                                type="number"
                                className={classnames(css.medium_field)}
                                label={t("_labels.odometer")}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      className={classnames(css.adornments)}
                                      position="start"
                                    >
                                      km
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    step: "any",
                                    min: hasGpsOdometer
                                      ? initialValues.odometer
                                      : parseFloat(review.odometer),
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={classnames(css.pl8, css.pr8)}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.head}>
                                  {t("_general.position")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.tire_model_field.label")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.size")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.code")}
                                </TableCell>
                                <TableCell className={classes.head}>
                                  {t("_labels.correct_tire.label")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <FieldArray
                              name="review"
                              validateOnChange={false}
                              render={() => (
                                <TableBody>
                                  {axleTires.map((axleTire, index) => (
                                    <TableRow
                                      key={axleTire.vehicle_type_axle_tire_id}
                                    >
                                      <TableCell className={classes.body}>
                                        {axleTire.position}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {axleTire.vehicle_tire.movement_tire
                                          .tire_cycle.revitalized?.name ||
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.variation?.tire_model
                                            .name}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.variation.tire_size.size
                                        }
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        {axleTire.vehicle_tire.movement_tire
                                          .tire_cycle.tire.code ||
                                          axleTire.vehicle_tire.movement_tire
                                            .tire_cycle.tire.device_code}
                                      </TableCell>
                                      <TableCell className={classes.body}>
                                        <Field
                                          required
                                          name={`review[${index}].correct`}
                                          component={RadioField}
                                          classNameControl={classnames(
                                            css.ml8,
                                            css.mr8
                                          )}
                                        >
                                          <RadioInput
                                            value="yes"
                                            className={classnames(css.mb0)}
                                          >
                                            {t("_labels.yes")}
                                          </RadioInput>
                                          <RadioInput
                                            value="no"
                                            className={classnames(css.mb0)}
                                          >
                                            {t("_labels.no")}
                                          </RadioInput>
                                        </Field>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              )}
                            />
                          </Table>
                        </TableContainer>
                      </div>
                    </CardContent>
                    <CardActions className={css.actions}>
                      <Button
                        type="submit"
                        color="secondary"
                        disabled={
                          values.review.some((tire) => tire.correct === "") ||
                          isSubmitting ||
                          isLoading
                        }
                        tooltip={
                          values.review.some((tire) => tire.correct === "")
                            ? t("_labels.all_tires")
                            : ""
                        }
                      >
                        {t("_buttons.save")}
                      </Button>
                    </CardActions>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  review: PropTypes.object.isRequired,
  axleTires: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasGpsOdometer: PropTypes.bool.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
