import { useState } from "react";

export function useMenu(initialValue = false) {
  const [openMenu, setOpenMenu] = useState(initialValue);

  function toggleMenu(value) {
    if (typeof value === "boolean") {
      setOpenMenu(value);
    } else {
      setOpenMenu((prevValue) => !prevValue);
    }
  }

  return [openMenu, toggleMenu];
}
