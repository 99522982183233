import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddUserRole } from "store/actions/user/role/addUserRole";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionClearUserFilters } from "store/actions/user/clearUserFilters";
import { actionGetUser } from "store/actions/user/getUser";
import { actionClearUser } from "store/actions/user/clearUser";
import { actionUpdateUserRole } from "store/actions/user/role/updateUserRole";

import { LIST_SUBSIDIARY, LIST_COMPANY } from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function UserRoleForm({ open, onClose, userId, userAssigned, ...rest }) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(userAssigned ? true : false);
  const {
    account,
    permits,
    user,
    setNotification,
    addUserRole,
    updateUserRole,
    getUser,
    getSubsidiaries,
    getCompanies,
    getCorporates,
    clearUserFilters,
    clearUser,
  } = rest;

  const initialDefaultValues = {
    corporate_id: "",
    company_id: "",
    subsidiary_id: "",
    role: "",
  };
  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataSubsidiaries = [];
        let dataCompanies = [];
        let dataCorporates = [];

        if (permits.includes(LIST_SUBSIDIARY)) {
          dataSubsidiaries = await getSubsidiaries(
            { scope: "subsidiary_id,name,status", in_user_page: 1 },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          dataSubsidiaries = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }

        if (permits.includes(LIST_COMPANY)) {
          dataCompanies = await getCompanies(
            { scope: "company_id,name,status" },
            false
          );
        } else if (Array.isArray(account.companies)) {
          data = account.companies.map((company) => ({
            company_id: company.company.company_id,
            name: company.company.name,
            status: company.company.status,
          }));
        }

        if (Array.isArray(account.corporates)) {
          dataCorporates = account.corporates.map((corporate) => ({
            corporate_id: corporate.corporate.corporate_id,
            name: corporate.corporate.name,
            status: corporate.corporate.status,
          }));
        } else {
          dataCorporates = await getCorporates(
            { scope: "corporate_id,name,status" },
            false
          );
        }
        setSubsidiaries(dataSubsidiaries.sort(alphabeticalSort("name")));
        setCompanies(dataCompanies.sort(alphabeticalSort("name")));
        setCorporates(dataCorporates.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open && userAssigned && user.user_id) {
      const [result] = user.subsidiaries
        .filter(
          (assignment) =>
            assignment.user_assigned_subsidiary_id ===
            userAssigned.user_assigned_subsidiary_id
        )
        .map((subsidiary) => ({
          subsidiary_id: subsidiary.subsidiary_id.toString(),
          role: subsidiary.role[0].role,
        }));

      setInitialValues({
        corporate_id: "",
        company_id: "",
        subsidiary_id: result.subsidiary_id || "",
        role: result.role || "",
      });
    } else {
      setInitialValues(initialDefaultValues);
    }
  }, [user, userAssigned, userId]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getUser(userId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (userAssigned && userId && open) {
      fetchData();
    }

    return () => {
      clearUser();
    };
  }, [userAssigned, userId, open]);

  useEffect(() => {
    setIsUpdate(userAssigned ? true : false);
  }, [userAssigned]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      subsidiary_id: values.subsidiary_id,
      role: values.role,
    };

    if (values.corporate_id) {
      fields.corporate_id = values.corporate_id;
    }

    if (values.company_id) {
      fields.company_id = values.company_id;
    }

    try {
      if (!isUpdate) {
        await addUserRole(userId, { ...fields });
        setNotification({
          message: t("_messages.created.user_role"),
        });
      } else {
        await updateUserRole(userId, { ...fields });
        setNotification({
          message: t("_messages.updated.user_role"),
        });
      }

      setSubmitting(false);
      resetForm();
      onClose();
      clearUserFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      subsidiaries={subsidiaries}
      companies={companies}
      corporates={corporates}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UserRoleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userAssigned: PropTypes.object,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  account: state.Account.account,
  user: state.User.User.user,
});

const mapDispatchToProps = (dispatch) => ({
  addUserRole: actionAddUserRole(dispatch),
  updateUserRole: actionUpdateUserRole(dispatch),
  getUser: actionGetUser(dispatch),
  clearUser: actionClearUser(dispatch),
  clearUserFilters: actionClearUserFilters(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleForm);
