import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";

import UpdateReviewRowForm from "./UpdateReviewRowForm";
import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: "unit",
  unit: "kilometer",
});

function Page({ t, reviews, fetchData }) {
  return (
    <ContainerTemplate
      title={t("_general.vehicle_review")}
      className={classnames({
        [css.empty]: reviews.length === 0,
      })}
    >
      <div className={classnames(css.container, css.font)}>
        {reviews.length > 0 && (
          <>
            <h4>
              {t("_labels.vehicle.singular")}{" "}
              {reviews[0].vehicle?.economic_number}
            </h4>
            <div className="table-responsive">
              <table className="table table-bordered table-striped m-0 text-center">
                <thead className="thead-dark">
                  <tr>
                    <th className="align-middle">
                      {t("_labels.review.singular")}
                    </th>
                    <th className="align-middle">{t("_labels.date.label")}</th>
                    <th className="align-middle">{t("_labels.mileage")}</th>
                    <th className="align-middle" width={"25%"}></th>
                  </tr>
                </thead>
                <tbody>
                  {reviews.map((review, index) => (
                    <tr key={index}>
                      <td className="align-middle">{reviews.length - index}</td>
                      <td className="align-middle">
                        {moment(review.date).format("Y-MM-DD hh:mm:ss a")}
                      </td>
                      <td className="align-middle">
                        {formatter.format(review.odometer)}
                      </td>
                      <td className="align-middle">
                        <UpdateReviewRowForm
                          vehicleReviewId={review.vehicle_review_id}
                          nextOdometer={reviews[index - 1]?.odometer}
                          previousOdometer={reviews[index + 1]?.odometer}
                          fetchData={fetchData}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  reviews: PropTypes.array,
  fetchData: PropTypes.func,
};

export default Page;
