import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";

import Page from "./page";

export function DamageDialog({ ...rest }) {
  const t = useLang();
  const {} = rest;
  return <Page t={t} {...rest} />;
}

DamageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  damages: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DamageDialog);
