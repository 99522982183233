export default {
  division: "División",
  link_division: "Vincular división",
  division_name: "Nombre de la división",
  unassigned_division: "División no asignada",
  permits: {
    delete_link: {
      vehicle_with_division:
        "Sin permisos para desvincular divisiones de vehículos",
    },
    show: {
      vehicle_with_division:
        "Sin permisos para ver divisiones vinculadas a vehículos",
    },
    list: {
      division: "Sin permisos para listar divisiones",
    },
  },
  messages: {
    unlink_division_confirmation: "¿Estas seguro de desvincular la división?",
    vehicle_with_division_link_deleted:
      "Listo, la división fue desvinculada del vehículo con éxito",
  },
  form: {
    select_a_division: "Selecciona una división",
  },
};
