import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  isValid,
  vehicles,
  isSubmitting,
  onClose,
  handleSubmit,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        id="combo-box-demo"
        name="vehicle_id"
        fullWidth
        autoHighlight
        blurOnSelect
        component={Autocomplete}
        options={vehicles}
        getOptionLabel={(option) => option.economic_number}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          option.vehicle_id === value.vehicle_id
        }
        onChange={(_, value) => {
          return setFieldValue(
            "vehicle_id",
            value && value.vehicle_id ? value.vehicle_id : ""
          );
        }}
        value={vehicles.vehicle_id}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.mb8)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_general.vehicle")}
            variant="filled"
          />
        )}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button
            type="button"
            color="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.link")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
