import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// import ModelVariationPolicyForm from "components/organisms/subsidiary/ModelVariationPolicyForm";
import TableResponsive from "components/organisms/general/TableResponsive";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import FixedButton from "components/molecules/general/FixedButton";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterListIcon from "@material-ui/icons/FilterList";
import Pagination from "components/molecules/general/Pagination";
import Button from "components/molecules/general/Button";
import HelmetValueForm from "components/organisms/subsidiary/HelmetValueForm";
import FilterHelmetValueForm from "components/organisms/subsidiary/FilterHelmetValueForm";

import css from "./index.module.scss";

function page({
  t,
  subsidiaryId,
  helmetValues,
  helmetValueId,

  updateTireModelVariationPolicyPermit,
  deleteTireModelVariationPolicyPermit,
  createTireModelVariationPolicyPermit,

  handleChangePage,
  openForm,
  openFilter,
  handleEdit,
  handleDelete,
  handleOpenForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <SubsidiaryTabs
      className={classnames({
        [css.empty]: helmetValues.data.length === 0,
      })}
      optionsAppBar={
        <Button
          onClick={handleOpenFilter}
          classNameButton={classnames(css.filter)}
          variant="icon"
        >
          <FilterListIcon />
        </Button>
      }
    >
      {helmetValues.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={helmetValues.data.map((row) => {
                return {
                  id: row.tire_model_variation_helmet_id,
                  key: row.tire_model_variation_helmet_id,
                  model: row.tire_model_variation.tire_model.name,
                  size: row.tire_model_variation.tire_size.size,
                  layers: row.tire_model_variation.number_layers,
                  depth: `${row.tire_model_variation.depth} mm`,
                  helmet_value_original: `$${row.helmet_value_original}`,
                  helmet_value_revitalized: `$${row.helmet_value_revitalized}`,
                  edit: {
                    tooltipPermit: !updateTireModelVariationPolicyPermit
                      ? t("_permits.update.model_pressure_policy")
                      : "",
                  },
                  delete: {
                    tooltipPermit: !deleteTireModelVariationPolicyPermit
                      ? t("_permits.delete.model_pressure_policy")
                      : "",
                  },
                };
              })}
              headers={[
                { id: "model", label: t("_labels.model") },
                {
                  id: "size",
                  label: t("_labels.size"),
                },
                {
                  id: "layers",
                  label: t("_labels.layer"),
                },
                {
                  id: "depth",
                  label: t("_labels.depth"),
                },
                {
                  id: "helmet_value_original",
                  label: t("_labels.helmet_value"),
                },
                {
                  id: "helmet_value_revitalized",
                  label: t("_labels.helmet_value_revitalized"),
                },
                { id: "actions", label: "" },
              ]}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={helmetValues.current_page}
              totalPages={helmetValues.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_model_helmet_values")} />
      )}

      <FixedButton
        tooltip={
          !createTireModelVariationPolicyPermit
            ? t("_permits.create.model_pressure_policy")
            : ""
        }
        onClick={handleOpenForm}
      />

      <HelmetValueForm
        open={openForm}
        onClose={handleCloseForm}
        subsidiaryId={subsidiaryId}
        helmetValueId={helmetValueId}
      />

      <FilterHelmetValueForm open={openFilter} onClose={handleCloseFilter} />
    </SubsidiaryTabs>
  );
}

page.propTypes = {
  t: PropTypes.func.isRequired,
  helmetValues: PropTypes.object.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  helmetValueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  deleteTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  createTireModelVariationPolicyPermit: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  openForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default page;
