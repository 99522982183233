import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionDeleteConditionPolicy } from "store/actions/subsidiary/policy/deleteConditionPolicy";
import { actionGetConditionPolicies } from "store/actions/subsidiary/policy/getConditionPolicies";
import { actionClearConditionPolicies } from "store/actions/subsidiary/policy/clearConditionPolicies";
import { actionSetConditionPoliciesFilters } from "store/actions/subsidiary/policy/setConditionPoliciesFilters";
import { actionClearConditionPoliciesFilters } from "store/actions/subsidiary/policy/clearConditionPoliciesFilters";
import { actionSetNotification } from "store/actions/general/notification";

import {
  UPDATE_VEHICLE_CONDITION_POLICY,
  DELETE_VEHICLE_CONDITION_POLICY,
  CREATE_VEHICLE_CONDITION_POLICY,
} from "store/actions/account/permits";

import Page from "./page";

export function ConditionPolicyTab({ ...rest }) {
  const t = useLang();
  const { id: subsidiaryId } = useParams();
  const {
    permits,
    filter,
    conditionPolicies,
    getConditionPolicies,
    clearConditionPolicies,
    setConditionPoliciesFilters,
    clearConditionPoliciesFilters,
    deleteConditionPolicy,
    setNotification,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [policyId, setPolicyId] = useState(null);
  const [openMultiple, setOpenMultiple] = useState(false);
  const [vehicleIds, setVehicleIds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getConditionPolicies({ subsidiaryId, page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearConditionPolicies();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearConditionPoliciesFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getConditionPolicies({ page, subsidiaryId, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(vehicleId) {
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setPolicyId(null);
    setOpenForm(false);
    setVehicleId(null);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setConditionPoliciesFilters({ search });
  }

  function handleCloseUpdate() {
    setOpenUpdate(false);
  }

  function handleEdit(vehicleId, policyId) {
    setPolicyId(policyId);
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  async function onDeleteConditionPolicy(
    subsidiaryId,
    vehicleId,
    conditionPolicyId
  ) {
    try {
      await deleteConditionPolicy(subsidiaryId, vehicleId, conditionPolicyId);
      setNotification({
        message: t("_messages.deleted.condition_policy"),
      });
      clearConditionPoliciesFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(subsidiaryId, vehicleId, conditionPolicyId) {
    setNotification({
      onAction: () => {
        onDeleteConditionPolicy(subsidiaryId, vehicleId, conditionPolicyId);
      },
      message: t("_messages.delete.condition_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenMultiple(vehicleIds) {
    setVehicleIds(vehicleIds);
    setOpenMultiple(true);
  }

  function handleCloseMultiple() {
    setOpenMultiple(false);
  }

  return (
    <Page
      t={t}
      subsidiaryId={subsidiaryId}
      conditionPoliciesData={conditionPolicies}
      search={filter.search}
      vehicleId={vehicleId}
      policyId={policyId}
      openFilter={openFilter}
      openUpdate={openUpdate}
      openForm={openForm}
      updateVehicleConditionPolicyPermit={permits.includes(
        UPDATE_VEHICLE_CONDITION_POLICY
      )}
      deleteVehicleConditionPolicyPermit={permits.includes(
        DELETE_VEHICLE_CONDITION_POLICY
      )}
      createVehicleConditionPolicyPermit={permits.includes(
        CREATE_VEHICLE_CONDITION_POLICY
      )}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleSearch={handleSearch}
      handleCloseUpdate={handleCloseUpdate}
      handleCloseFilter={handleCloseFilter}
      handleOpenFilter={handleOpenFilter}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleChangePage={handleChangePage}
      openMultiple={openMultiple}
      vehicleIds={vehicleIds}
      handleOpenMultiple={handleOpenMultiple}
      handleCloseMultiple={handleCloseMultiple}
      {...rest}
    />
  );
}

ConditionPolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  conditionPolicies: state.Subsidiary.ConditionPolicy.Policy.condition_policies,
  filter: state.Subsidiary.ConditionPolicy.Policy.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getConditionPolicies: actionGetConditionPolicies(dispatch),
  clearConditionPolicies: actionClearConditionPolicies(dispatch),
  deleteConditionPolicy: actionDeleteConditionPolicy(dispatch),
  setNotification: actionSetNotification(dispatch),
  setConditionPoliciesFilters: actionSetConditionPoliciesFilters(dispatch),
  clearConditionPoliciesFilters: actionClearConditionPoliciesFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionPolicyTab);
