import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionGetVehicleMovementHistory } from "store/actions/vehicle/review/getVehiclesMovementHistory";
import { actionClearVehicleMovementHistory } from "store/actions/vehicle/review/clearVehicleMovementHistory";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetVehicleMovementHistoryFilters } from "store/actions/vehicle/review/setVehicleMovementHistoryFilters";
import { actionClearVehicleMovementHistoryFilters } from "store/actions/vehicle/review/clearVehicleMovementHistoryFilters";

import Page from "./page";

export function MovementHistory({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDamageDialog, setOpenDamageDialog] = useState(false);
  const [damages, setDamages] = useState([]);
  const {
    filter,
    clearVehicleMovementHistory,
    getVehicleMovementHistory,
    setVehicleMovementHistoryFilters,
    clearVehicleMovementHistoryFilters,
    movementHistory,
    setNotification,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleMovementHistory(id, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();

    return () => {
      clearVehicleMovementHistory();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearVehicleMovementHistoryFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getVehicleMovementHistory(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleOpenDamageDialog(damages) {
    setDamages(damages);
    setOpenDamageDialog(true);
  }

  function handleCloseDamageDialog() {
    setOpenDamageDialog(false);
    setDamages([]);
  }

  return (
    <Page
      t={t}
      vehicleId={id}
      movementHistory={getSortedByPosition(movementHistory)}
      clearVehicleMovementHistory={clearVehicleMovementHistory}
      onChangePage={onChangePage}
      filter={filter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      setVehicleMovementHistoryFilters={setVehicleMovementHistoryFilters}
      clearVehicleMovementHistoryFilters={clearVehicleMovementHistoryFilters}
      openDamageDialog={openDamageDialog}
      damages={damages}
      handleOpenDamageDialog={handleOpenDamageDialog}
      handleCloseDamageDialog={handleCloseDamageDialog}
    />
  );
}

MovementHistory.propTypes = {};

const mapStateToProps = (state) => ({
  movementHistory: state.Vehicle.MovementHistory.movement_history,
  filter: state.Vehicle.MovementHistory.filter,
});

const mapDispatchToProps = (dispatch) => ({
  clearVehicleMovementHistory: actionClearVehicleMovementHistory(dispatch),
  getVehicleMovementHistory: actionGetVehicleMovementHistory(dispatch),
  setVehicleMovementHistoryFilters:
    actionSetVehicleMovementHistoryFilters(dispatch),
  clearVehicleMovementHistoryFilters:
    actionClearVehicleMovementHistoryFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovementHistory);

function getSortedByPosition(movementHistory) {
  if (movementHistory.data.length > 0) {
    movementHistory.data[0].movements.sort((a, b) => {
      if (
        a.movement_tire.vehicle_tire.length > 0 &&
        b.movement_tire.vehicle_tire.length > 0
      ) {
        if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] >
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return 1;
        } else if (
          a.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"][
            "position"
          ] <
          b.movement_tire.vehicle_tire[0]["vehicle_type_axle_tire"]["position"]
        ) {
          return -1;
        }
      }

      return 0;
    });
  }
  return movementHistory;
}
