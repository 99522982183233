export default {
  damage_name: "Damage name",
  messages: {
    created: "Done, new tire damage was successfully recorded",
    updated: "Done, new tire damage was successfully updated",
    delete_confirmation: "Are you sure to delete the tire damage?",
    deleted: "Done, tire damage was successfully deleted",
  },
  permits: {
    delete: "No permissions to delete tire damages",
  },
};
