import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLang } from "hooks/lang";

import { parseFieldsError } from "utils/parse";
import { actionSetNotification } from "store/actions/general/notification";

export function useFormik({
  NAMESPACE,
  initialErrors = {},
  initialValues = {},
}) {
  const t = useLang(NAMESPACE);
  const dispatch = useDispatch();
  const setNotification = actionSetNotification(dispatch);
  const [iErrors, setIErrors] = useState(initialErrors);
  const [iValues, setIValues] = useState(initialValues);

  function setFormikErrors(error, values, { setTouched, setErrors }) {
    if (error.fields) {
      const errors = parseFieldsError(error.fields, t);
      setIValues(values);
      setIErrors(errors);
      setTouched(errors);
      setErrors(errors);
      return;
    }

    setNotification(error, true);
  }

  function handleFormikValidate(values) {
    let errors = {};
    for (const k of Object.keys(iErrors)) {
      if (values[k] === iValues[k]) {
        errors[k] = iErrors[k];
      }
    }
    return errors;
  }

  function resetFormik() {
    setIValues(initialValues);
    setIErrors(initialErrors);
  }

  return {
    initialErrors: iErrors,
    initialValues: iValues,
    setInitialErrors: setIErrors,
    setInitialValues: setIValues,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  };
}
