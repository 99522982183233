import {
  REGISTER_BRAND,
  GET_BRANDS,
  CLEAR_BRANDS,
  SET_BRAND_FILTERS,
  CLEAR_BRAND_FILTERS,
  GET_BRAND,
  CLEAR_BRAND,
  SET_BRAND_APPROVAL_STATUS,
  SET_BRAND_STATUS,
  DELETE_BRAND,
} from "store/actions";

const initialState = {
  brand: {
    name: "",
    brand_type: "",
    status: 0,
    approved: 0,
    created_by: {
      name: "",
      last_name: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  brands: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_brand: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_BRAND:
    case GET_BRAND:
      return {
        ...state,
        brand: action.payload,
      };

    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };

    case CLEAR_BRANDS:
      return {
        ...state,
        brands: { ...initialState.brands },
      };

    case SET_BRAND_FILTERS:
      const filter = {
        ...state.filter_brand,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_brand: { ...filter },
      };

    case CLEAR_BRAND_FILTERS:
      return {
        ...state,
        filter_brand: { search: state.filter_brand.search },
      };

    case CLEAR_BRAND:
      return {
        ...state,
        brand: initialState.brand,
      };

    case SET_BRAND_APPROVAL_STATUS:
      return {
        ...state,
        brands: {
          ...state.brands,
          data: state.brands.data.map((brand) =>
            brand.brand_id !== action.payload
              ? brand
              : { ...brand, approved: !brand.approved }
          ),
        },
      };

    case SET_BRAND_STATUS:
      return {
        ...state,
        brands: {
          ...state.brands,
          data: state.brands.data.map((brand) =>
            brand.brand_id !== action.payload
              ? brand
              : { ...brand, status: !brand.status }
          ),
        },
      };

    case DELETE_BRAND:
      return {
        ...state,
        brands: {
          ...state.brands,
          data: state.brands.data.filter(
            (brand) => brand.brand_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
