import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModel } from "store/actions/tire/model/getTireModel";

import Page from "./page";

export const NAMESPACE = "src/components/organisms/tire/model/TireModelTabs";

export function TireModelTabs({ className, optionsAppBar, children, ...rest }) {
  const t = useLang(NAMESPACE);
  const { id: tireModelId } = useParams();
  const { tireModel, getTireModel, setNotification } = rest;
  const tabLinks = [
    {
      to: `/tire/model/${tireModelId}`,
      label: t("_labels.information"),
    },
    {
      to: `/tire/model/${tireModelId}/variation`,
      label: t("variations"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModel(tireModelId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [tireModelId]);

  return (
    <Page
      tabLinks={tabLinks}
      className={className}
      title={tireModel.name}
      optionsAppBar={optionsAppBar}
    >
      {children}
    </Page>
  );
}

TireModelTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  tireModel: state.Tire.Model.Model.model,
});
const mapDispatchToProps = (dispatch) => ({
  getTireModel: actionGetTireModel(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireModelTabs);
