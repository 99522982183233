import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import marcas from "./img/b_marcas.png";
import medidas from "./img/b_medidas.png";
import modelos from "./img/b_modelos.png";
import profundidades from "./img/b_profundidades.png";
import revitalizados from "./img/b_revitalizados.png";

import ContainerTemplate from "components/templates/general/Container";

// import $ from "resources/scripts/jquery-3.0.0.min.js";
import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import BarChart from "components/organisms/general/Charts/BarChart";
import FilterTireStatsForm from "components/organisms/report/FilterTireStatsForm";
import Button from "components/molecules/general/Button";

const formatter = new Intl.NumberFormat("en");

function Page({
  t,
  data,
  dataChart,
  selectedKey,
  handleClick,
  hide,
  setHide,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
}) {
  // const noTires = t('_labels.tire_pile_report.headers.number_of_tires');
  return (
    <ContainerTemplate
      title={t("_titles.pile_stats_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100 px24">
        {Object.keys(filter).length > 0 && (
          <div className="row pdt24">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {!filter.movement && (
          <div className="row pdt12">
            <div className="col-md-12 text-center">
              <hr />
              <small className="sinmp" style={{ color: "lightcoral" }}>
                {t("_labels.location.label")}:{" "}
                {t("_labels.location.options.pile")}
              </small>
              <hr />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <hr />
            <div className="row">
              <div className="col-md-2">
                <span
                  className="pointer"
                  onClick={(e) => handleClick(e, "brand_name")}
                >
                  <img src={marcas} className="img-fluid" />
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="pointer"
                  onClick={(e) => handleClick(e, "size")}
                >
                  <img src={medidas} className="img-fluid" />
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="pointer"
                  onClick={(e) => handleClick(e, "depth")}
                >
                  <img src={profundidades} className="img-fluid" />
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="pointer"
                  onClick={(e) => handleClick(e, "model_name")}
                >
                  <img src={modelos} className="img-fluid" />
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="pointer"
                  onClick={(e) => handleClick(e, "number_cycle")}
                >
                  <img src={revitalizados} className="img-fluid" />
                </span>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingTop: 30 }}>
            <h1>
              {t(
                `_labels.tire_stats_report.options.${selectedKey}`
              ).toUpperCase()}
            </h1>
          </div>
        </div>
        <div className="row pdt24">
          <div className="col-md-12">
            <div className="chart-container">
              {(dataChart.labels || dataChart.length > 0) && (
                <BarChart
                  options={
                    dataChart.labels
                      ? {
                          responsive: true,
                          maintainAspectRatio: false,
                          fill: true,
                          locale: "en",
                          scales: {
                            y: {
                              min: 0,
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.number_of_tires"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            x: {
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.range_of_depths"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          plugins: {
                            legend: {
                              position: "top",
                              labels: {
                                font: {
                                  size: 14,
                                  weight: "bold",
                                },
                              },
                              // align: 'start',
                            },
                          },
                        }
                      : null
                  }
                  data={dataChart}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row pdt24">
          <div className="col-md-12">
            <button className="btn btn-primary" onClick={() => setHide(!hide)}>
              {t("_buttons.see_table")}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleGenerateExcel()}
            >
              {t("_buttons.generate_excel")}
            </button>
          </div>
          <div className="col-md-12 pdt24">
            <div className={`box ${hide ? "hide" : ""}`} id="collapseMarcas">
              <div className="card card-body sinmp" style={{ padding: 0 }}>
                <div className="row">
                  {data.length === 2 && (
                    <>
                      <div className="col-md-6">
                        <table className="table table-hover">
                          <thead className="sbgazul">
                            <tr>
                              <td>
                                {t(
                                  `_labels.tire_stats_report.headers.${selectedKey}`
                                ).toUpperCase()}
                              </td>
                              <td className="text-right">
                                {t(
                                  "_labels.tire_stats_report.headers.number_of_tires"
                                ).toUpperCase()}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data[0].map((row, index) => (
                              <tr key={index}>
                                <td>{row[0]}</td>
                                <td className="text-right">{row[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-6">
                        <table className="table table-hover">
                          <thead className="sbgazul">
                            <tr>
                              <td>
                                {t(
                                  `_labels.tire_stats_report.headers.${selectedKey}`
                                ).toUpperCase()}
                              </td>
                              <td className="text-right">
                                {t(
                                  "_labels.tire_stats_report.headers.number_of_tires"
                                ).toUpperCase()}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {data[1].map((row, index) => (
                              <tr key={index}>
                                <td>{row[0]}</td>
                                <td className="text-right">{row[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {data.length === 1 && (
                    <div className="col-md-6">
                      <table className="table table-hover">
                        <thead className="sbgazul">
                          <tr>
                            <td>
                              {t(
                                `_labels.tire_stats_report.headers.${selectedKey}`
                              ).toUpperCase()}
                            </td>
                            <td className="text-right">
                              {t(
                                "_labels.tire_stats_report.headers.number_of_tires"
                              ).toUpperCase()}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data[0].map((row, index) => (
                            <tr key={index}>
                              <td>{row[0]}</td>
                              <td className="text-right">
                                {formatter.format(row[1])}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterTireStatsForm
        isPileStats={true}
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  dataChart: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  selectedKey: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  setHide: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
};

export default Page;
