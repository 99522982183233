export default {
  filter: "Filter",
  form: {
    brand_field: {
      label: "Brands",
      helper_text: "Multiple brands can be selected",
    },
    tire_size_field: {
      label: "Sizes",
      helper_text: "Multiple sizes can be selected",
    },
  },
};
