import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddChecklist } from "store/actions/checklist/addChecklist";
import { actionGetConcepts } from "store/actions/concept/getConcepts";
import { actionGetBlocks } from "store/actions/block/getBlocks";
import { actionGetChecklist } from "store/actions/checklist/getChecklist";
import { actionClearChecklist } from "store/actions/checklist/clearChecklist";
import { actionGetChecklists } from "store/actions/checklist/getChecklists";
import { actionUpdateChecklist } from "store/actions/checklist/updateChecklist";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function ChecklistForm({ open, onClose, checklistId, ...rest }) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(checklistId ? true : false);
  const {
    filter,
    checklist,
    setNotification,
    addChecklist,
    getConcepts,
    getBlocks,
    getChecklists,
    getChecklist,
    clearChecklist,
    updateChecklist,
    getVehicleTypes,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      name: "",
      type: "TIRE",
      vehicle_types: [],
      headboard: [""],
      body: [{ block_id: "", concepts: [""] }],
    },
  });
  const [concepts, setConcepts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = await getConcepts(
          {
            scope: "concept_id,name,description",
          },
          false
        );

        setConcepts(data.sort(alphabeticalSort("name")));

        data = await getBlocks(
          {
            scope: "block_id,name,description",
          },
          false
        );
        setBlocks(data.sort(alphabeticalSort("name")));

        data = await getVehicleTypes(
          {
            scope: "vehicle_type_id,name",
            status: 1,
          },
          false
        );
        setVehicleTypes(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }

    return () => {
      clearChecklist();
    };
  }, [open]);

  useEffect(() => {
    setIsUpdate(checklistId ? true : false);
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getChecklist(checklistId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open && checklistId) {
      fetchData();
    }
  }, [open, checklistId]);

  useEffect(() => {
    if (open && checklist.check_list_id) {
      const checklistHeadboard = JSON.parse(checklist.headboard);
      const checklistBody = JSON.parse(checklist.body);

      setInitialValues({
        name: checklist.name,
        type: checklist.type,
        headboard: checklistHeadboard,
        body: checklistBody,
      });
    }
  }, [checklist]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = { ...values };

      if (values.type === "TIRE") {
        delete fields.vehicle_types;
      } else {
        fields.vehicle_types = values.vehicle_types
          .map((v) => v.vehicle_type_id)
          .join();
      }

      if (isUpdate) {
        await updateChecklist(fields, checklistId);
        setNotification({
          message: t("_messages.updated.checklist"),
        });
      } else {
        await addChecklist(fields);
        setNotification({
          message: t("_messages.created.checklist"),
        });
      }

      setSubmitting(false);
      onClose();
      // resetFormik();
      getChecklists({ ...filter });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      concepts={concepts}
      blocks={blocks}
      vehicleTypes={vehicleTypes}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

ChecklistForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  checklistId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  filter: state.Checklist.filter_checklist,
  checklist: state.Checklist.checklist,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  addChecklist: actionAddChecklist(dispatch),
  getConcepts: actionGetConcepts(dispatch),
  getVehicleTypes: actionGetVehiclesTypes(dispatch),
  getBlocks: actionGetBlocks(dispatch),
  getChecklists: actionGetChecklists(dispatch),
  getChecklist: actionGetChecklist(dispatch),
  clearChecklist: actionClearChecklist(dispatch),
  updateChecklist: actionUpdateChecklist(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistForm);
