import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import { LIST_SUBSIDIARY } from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/subsidiary/LinkTireSubsidiaryForm/Form";

export function Form({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { permits, account, setNotification, getSubsidiaries } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (permits.includes(LIST_SUBSIDIARY)) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
            subsidiary_id: subsidiary.subsidiary_id,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
  }, []);

  return <Page {...rest} t={t} subsidiaries={subsidiaries} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
