export default {
  messages: {
    disable: "¿Estas seguro de deshabilitar el corporativo?",
    enable: "¿Estas seguro de habilitar el corporativo?",
  },
  buttons: {
    corporate_disabled: "Corporativo deshabilitado",
  },
  permits: {
    change_status: "Sin permisos para {action} corporativos",
  },
};
