import {
  GET_SUBSIDIARY_WAREHOUSE,
  CLEAR_SUBSIDIARY_WAREHOUSE,
  GET_SUBSIDIARY_WAREHOUSES,
  CLEAR_SUBSIDIARY_WAREHOUSES,
  SET_SUBSIDIARY_WAREHOUSES_FILTERS,
  CLEAR_SUBSIDIARY_WAREHOUSES_FILTERS,
  DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY,
} from "store/actions";

const initialState = {
  warehouse: {
    subsidiary: {
      name: "",
    },
    warehouse: {
      name: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
  },
  warehouses: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBSIDIARY_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      };
    case CLEAR_SUBSIDIARY_WAREHOUSES:
      return {
        ...state,
        warehouses: initialState.warehouses,
      };
    case GET_SUBSIDIARY_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case CLEAR_SUBSIDIARY_WAREHOUSE:
      return {
        ...state,
        warehouse: initialState.warehouse,
      };
    case SET_SUBSIDIARY_WAREHOUSES_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_SUBSIDIARY_WAREHOUSES_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY:
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: state.warehouses.data.filter(
            (warehouse) => warehouse.warehouse_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
