import { combineReducers } from "redux";

import User from "./user";
import Company from "./company";
import Corporate from "./corporate";

export default combineReducers({
  User,
  Company,
  Corporate,
});
