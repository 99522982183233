import {
  GET_MODEL_VARIATION_POLICIES,
  CLEAR_MODEL_VARIATION_POLICIES,
  REGISTER_MODEL_VARIATION_POLICY,
  DELETE_MODEL_VARIATION_POLICY,
  UPDATE_MODEL_VARIATION_POLICY,
  GET_MODEL_VARIATION_POLICY,
} from "store/actions";

const initialState = {
  model_variation_policy: {
    subsidiary_id: 0,
    tire_model_Variation_policy: {
      tire_model_id: 0,
      maximum_pressure: 0,
      recommended_pressure: 0,
      tolerance: 0,
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
  },
  model_variation_policies: {
    current_pages: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODEL_VARIATION_POLICY:
    case UPDATE_MODEL_VARIATION_POLICY:
    case REGISTER_MODEL_VARIATION_POLICY:
      return {
        ...state,
        model_variation_policy: action.payload,
      };

    case GET_MODEL_VARIATION_POLICIES:
      return {
        ...state,
        model_variation_policies: action.payload,
      };

    case CLEAR_MODEL_VARIATION_POLICIES:
      return {
        ...state,
        model_variation_policies: { ...initialState.model_variation_policies },
      };

    case DELETE_MODEL_VARIATION_POLICY:
      return {
        ...state,
        model_variation_policies: {
          ...state.model_variation_policies,
          data: state.model_variation_policies.data.filter(
            (tireModelVariationPolicy) =>
              tireModelVariationPolicy.tire_model_variation_policy_id !==
              action.payload
          ),
        },
      };

    default:
      return state;
  }
}
