import { REGISTER_CONCEPT } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionAddConcept = (dispatch) => async (fields) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: "POST",
      path: "concept",
      data: fields,
      dataWithFiles: true,
    });

    dispatch({
      type: REGISTER_CONCEPT,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
