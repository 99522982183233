import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { useParams } from "react-router-dom";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";
import { actionClearVehicle } from "store/actions/vehicle/clearVehicle";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAlertsOfVehicle } from "store/actions/vehicle/alert/getAlertsOfVehicle";
import { actionClearAlertsOfVehicle } from "store/actions/vehicle/alert/clearAlertsOfVehicle";
import { actionSetAlertsOfVehicleFilters } from "store/actions/vehicle/alert/setAlertsOfVehicleFilters";
import { actionClearAlertsOfVehicleFilters } from "store/actions/vehicle/alert/clearAlertsOfVehicleFilters";
import { actionDeleteVehicleAlert } from "store/actions/vehicle/alert/deleteVehicleAlert";

import { DELETE_ALERT } from "store/actions/account/permits";

import Page from "./page";

export function AlertsByVehicle({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();

  const {
    permits,
    alerts,
    getAlertsOfVehicle,
    clearAlertsOfVehicle,
    clearAlertsOfVehicleFilters,
    deleteVehicleAlert,
    setNotification,
    filter,
    vehicle,
    getVehicle,
    clearVehicle,
  } = rest;
  const [openFilter, setOpenFilter] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [alertId, setAlertId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getAlertsOfVehicle(vehicleId, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearAlertsOfVehicle();
    };
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicle(alerts.data[0].vehicle.vehicle_id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (alerts.data.length > 0 && !vehicle.vehicle_id) {
      fetchData();
    }

    return () => {
      clearVehicle();
    };
  }, [alerts.data.length]);

  useEffect(() => {
    return () => {
      clearAlertsOfVehicleFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getAlertsOfVehicle(vehicleId, {
        page,
        ...filter,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function deleteAlert(alertId) {
    try {
      const response = await deleteVehicleAlert(vehicleId, alertId);
      setNotification({
        message: response.message || "",
        code: response.code,
      });
      getAlertsOfVehicle(vehicleId, { page: 1, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(alertId) {
    setAlertId(alertId);
    setNotification({
      message: "¿Estas seguro de descartar la alerta?",
      textAction: t("_buttons.confirm"),
      onAction: () => deleteAlert(alertId),
    });
  }

  function handleOpenForm(alertId) {
    setAlertId(alertId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setAlertId(null);
    setOpenForm(false);
  }

  return (
    <Page
      t={t}
      alerts={alerts}
      vehicleId={vehicleId}
      alertId={alertId}
      deleteVehicleAlertPermit={permits.includes(DELETE_ALERT)}
      deleteVehicleAlert={deleteVehicleAlert}
      openFilter={openFilter}
      onChangePage={onChangePage}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleDelete={handleDelete}
      openForm={openForm}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      vehicle={vehicle}
    />
  );
}

AlertsByVehicle.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  alerts: state.Vehicle.Alert.alerts,
  filter: state.Vehicle.Alert.filter,
  vehicle: state.Vehicle.Vehicle.vehicle,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getAlertsOfVehicle: actionGetAlertsOfVehicle(dispatch),
  clearAlertsOfVehicle: actionClearAlertsOfVehicle(dispatch),
  setAlertsOfVehicleFilters: actionSetAlertsOfVehicleFilters(dispatch),
  clearAlertsOfVehicleFilters: actionClearAlertsOfVehicleFilters(dispatch),
  deleteVehicleAlert: actionDeleteVehicleAlert(dispatch),
  getVehicle: actionGetVehicle(dispatch),
  clearVehicle: actionClearVehicle(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsByVehicle);
