import { GET_TIRE_USE_TYPES } from "store/actions";

const initialState = {
  tire_use_types: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_USE_TYPES:
      return {
        ...state,
        tire_use_types: action.payload,
      };

    default:
      return state;
  }
}
