import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionClearVehicleAxles } from "store/actions/vehicle/mount/clearVehicleAxles";
import { actionSetInProcess } from "store/actions/vehicle/review/setInProcess";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";

import { useLang } from "hooks/lang";
import { useHistory, useParams } from "react-router-dom";

import Page from "./page";

const defaultAxleTireInfo = {
  position: 0,
};

export function DoInspection({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const history = useHistory();

  const {
    vehicle,
    review,
    inProcess,
    getVehicles,
    getVehicleAxles,
    clearVehicleAxles,
    setNotification,
    setInProcess,
  } = rest;

  const [axleTireInfo, setAxleTireInfo] = useState(defaultAxleTireInfo);
  const [openMountedDialog, setOpenMountedDialog] = useState(false);
  const [openTireReview, setOpenTireReview] = useState(false);
  const [openDamagesAndWear, setOpenDamagesAndWear] = useState(false);
  const [isDamagesForm, setIsDamagesForm] = useState(true);
  const [openDamagesAndRepair, setOpenDamagesAndRepair] = useState(false);
  const [openWrongTireForm, setOpenWrongTireForm] = useState(false);
  const [vehicleTire, setVehicleTire] = useState({});
  const [openVehicleReviewForm, setOpenVehicleReviewForm] = useState(false);
  const [search, setSearch] = useState("");
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setSearch("");
        const response = await getVehicles(
          {
            status: 1,
            scope: "vehicle_id,economic_number,status",
          },
          false
        );
        setVehicles(response);
        await getVehicleAxles(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearVehicleAxles();
    };
  }, [id]);

  useEffect(() => {
    if (
      ["COMPLETE", "DAMAGE AND WEAR"].includes(review.review_type) &&
      review.start_time &&
      !review.end_time
    ) {
      setInProcess(true);
    } else {
      setInProcess(false);
    }
  }, [review]);
  function handleClickTire(axleTire) {
    setAxleTireInfo(axleTire);
    handleOpenMountedDialog(true);
  }

  function handleOpenMountedDialog() {
    setOpenMountedDialog(true);
  }

  function handleCloseMountedDialog() {
    setOpenMountedDialog(false);
  }

  function handleOpenTireReview() {
    setOpenTireReview(true);
  }

  function handleCloseTireReview() {
    setOpenTireReview(false);
  }

  function handleOpenDamagesAndWear(isDamages) {
    setIsDamagesForm(isDamages);
    setOpenDamagesAndWear(true);
  }

  function handleCloseDamagesAndWear() {
    setOpenDamagesAndWear(false);
  }

  function handleOpenDamagesAndRepair() {
    setOpenDamagesAndRepair(true);
  }

  function handleCloseDamagesAndRepair() {
    setOpenDamagesAndRepair(false);
  }

  function handleOpenWrongTireForm(vehicleTire) {
    setVehicleTire(vehicleTire);
    handleCloseMountedDialog();
    setOpenWrongTireForm(true);
  }

  function handleCloseWrongTireForm() {
    setOpenWrongTireForm(false);
    setVehicleTire({});
  }

  function handleOpenVehicleReviewForm() {
    setOpenVehicleReviewForm(true);
  }

  function handleCloseVehicleReviewForm() {
    setOpenVehicleReviewForm(false);
  }

  function handleSearch(search) {
    setSearch(search);
    if (search) {
      const vehicleFound = vehicles.find(
        (vehicle) =>
          vehicle.economic_number.toLowerCase() === search.trim().toLowerCase()
      );
      if (vehicleFound) {
        if (vehicleFound.vehicle_id != id) {
          history.replace(`/vehicle/review/${vehicleFound.vehicle_id}`);
        }
      } else {
        setNotification({
          message: t("_messages.vehicle_not_found", { vehicle: search }),
        });
      }
    }
  }

  return (
    <Page
      t={t}
      vehicleId={id}
      inProcess={inProcess}
      openMountedDialog={openMountedDialog}
      openTireReview={openTireReview}
      openDamagesAndWear={openDamagesAndWear}
      openDamagesAndRepair={openDamagesAndRepair}
      vehicle={vehicle}
      axleTireInfo={axleTireInfo}
      isDamagesForm={isDamagesForm}
      handleClickTire={handleClickTire}
      handleCloseMountedDialog={handleCloseMountedDialog}
      handleOpenTireReview={handleOpenTireReview}
      handleCloseTireReview={handleCloseTireReview}
      handleOpenDamagesAndWear={handleOpenDamagesAndWear}
      handleCloseDamagesAndWear={handleCloseDamagesAndWear}
      setIsDamagesForm={setIsDamagesForm}
      handleOpenDamagesAndRepair={handleOpenDamagesAndRepair}
      handleCloseDamagesAndRepair={handleCloseDamagesAndRepair}
      openWrongTireForm={openWrongTireForm}
      handleOpenWrongTireForm={handleOpenWrongTireForm}
      handleCloseWrongTireForm={handleCloseWrongTireForm}
      vehicleTire={vehicleTire}
      openVehicleReviewForm={openVehicleReviewForm}
      handleOpenVehicleReviewForm={handleOpenVehicleReviewForm}
      handleCloseVehicleReviewForm={handleCloseVehicleReviewForm}
      handleSearch={handleSearch}
      search={search}
    />
  );
}

DoInspection.propTypes = {};

const mapStateToProps = (state) => ({
  vehicle: state.Vehicle.Mount.vehicle_axles,
  review: state.Vehicle.Review.vehicle_review,
  inProcess: state.Vehicle.Review.in_process,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  clearVehicleAxles: actionClearVehicleAxles(dispatch),
  setInProcess: actionSetInProcess(dispatch),
  getVehicles: actionGetVehicles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoInspection);
