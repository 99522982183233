import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import Page from "./page";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import moment from "moment-timezone";
import { findLineByLeastSquares } from "utils/trendLine";

export const NAMESPACE = "pages/review/HomeReview";

function InspectionsTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [vehicleHistoricData, setVehicleHistoricData] = useState({});
  const [vehicleHistoricDataCurrentDate, setVehicleHistoricDataCurrentDate] =
    useState("");
  const [performanceReviewData, setPerformanceReviewData] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);

  const { showProgress, setNotification, currentTab } = rest;

  useEffect(() => {
    let newSubsidiaries = [];
    if (vehicleHistoricData && Object.keys(vehicleHistoricData).length > 0) {
      newSubsidiaries = Object.keys(vehicleHistoricData);
    }
    if (
      performanceReviewData &&
      Object.keys(performanceReviewData).length > 0
    ) {
      newSubsidiaries = [
        ...Object.keys(performanceReviewData),
        ...newSubsidiaries,
      ];
    }
    newSubsidiaries = newSubsidiaries.filter(
      (subsidiary) => subsidiary !== "options"
    );
    newSubsidiaries = [...new Set(newSubsidiaries)];
    setSubsidiaries(newSubsidiaries);
  }, [vehicleHistoricData, performanceReviewData]);

  useEffect(() => {
    async function getVehicleHistoricData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/statistics/vehicle/review`,
          params: {
            level: currentTab === "SUBSIDIARY" ? "subsidiary" : "company",
          },
        });
        const newData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData =
            transformVehicleHistoricData(subsidiaryValues);
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData.data)) {
            if (key === "total") {
              datasets.push(
                getDataset(
                  values,
                  "Total",
                  false,
                  currentTab === "SUBSIDIARY" ? subsidiary : undefined
                )
              );
            } else {
              datasets.push(
                getDataset(
                  values,
                  key,
                  false,
                  currentTab === "SUBSIDIARY" ? subsidiary : undefined
                )
              );
            }
          }
          datasets.push(
            getDataset(transformedData.trend, t("_labels.trend"), true)
          );
          newData[subsidiary] = {
            datasets: datasets,
          };
        }
        newData["options"] = {
          onHover: (event, active) => {
            if (active.length > 0) {
              const raw = active[0].element.$context.raw;
              setVehicleHistoricDataCurrentDate(
                raw.fecha ? `${raw.fecha}` : ""
              );
            }
          },
        };
        setVehicleHistoricData(newData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getVehicleHistoricData();
  }, [currentTab]);

  useEffect(() => {
    async function getPerformanceReviewData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/review/performances/dashboard`,
          params: {
            group_by: currentTab,
          },
        });
        const newData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformPerformanceReviewData(
            subsidiaryValues,
            subsidiary
          );
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData.data)) {
            if (key === "total") {
              datasets.push(getDataset(values, "Total"));
            } else {
              datasets.push(
                getDataset(values, t(`_labels.dashboard.performance.${key}`))
              );
            }
          }
          newData[subsidiary] = {
            datasets: datasets,
          };
        }
        setPerformanceReviewData(newData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getPerformanceReviewData();
  }, [currentTab]);

  return (
    <Page
      t={t}
      vehicleHistoricData={vehicleHistoricData}
      vehicleHistoricDataCurrentDate={vehicleHistoricDataCurrentDate}
      performanceReviewData={performanceReviewData}
      subsidiaries={subsidiaries}
    />
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsTab);

function transformVehicleHistoricData(subsidiaryObject) {
  let newData = {
    total: [],
  };

  for (const [date, data] of Object.entries(subsidiaryObject)) {
    let total = 0;
    for (const [user, value] of Object.entries(data)) {
      if (typeof value === "object") {
        if (!Object.hasOwn(newData, user)) {
          newData[user] = [];
        }
        newData[user].push({
          fecha: date,
          total: value["number_vehicles_review"],
          user,
        });
        total += value["number_vehicles_review"];
      }
    }
    newData["total"].push({
      fecha: date,
      total: total,
      lowPressure: data["low"],
      goodPressure: data["normal"],
      highPressure: data["high"],
      criticalDepth: data["critical_withdrawal"],
      scheduledDepth: data["schedule_withdrawal"],
      goodDepth: data["good_condition"],
    });
  }
  for (const value of Object.values(newData)) {
    value.sort((a, b) => {
      return new Date(a.fecha) - new Date(b.fecha);
    });
  }
  const linearRegression = findLineByLeastSquares(
    newData["total"].map((value, index) => index),
    newData["total"].map((el) => el.total)
  );
  return {
    data: newData,
    trend: [
      {
        fecha: newData["total"][0].fecha,
        total: linearRegression[1][0],
      },
      {
        fecha: newData["total"][newData["total"].length - 1].fecha,
        total: linearRegression[1][linearRegression[1].length - 1],
      },
    ],
  };
}

function transformPerformanceReviewData(subsidiaryObject, subsidiary) {
  let newData = {};

  const keepFields = [
    "number_damage",
    "punctures",
    "number_revitalizations",
    "number_tires_pile",
  ];

  for (const [date, data] of Object.entries(subsidiaryObject)) {
    for (const [key, value] of Object.entries(data)) {
      if (!keepFields.includes(key)) {
        continue;
      }
      if (!Object.hasOwn(newData, key)) {
        newData[key] = [];
      }
      newData[key].push({
        fecha: date,
        total: value,
        subsidiary,
      });
    }
  }
  for (const value of Object.values(newData)) {
    value.sort((a, b) => {
      return new Date(a.fecha) - new Date(b.fecha);
    });
  }
  return {
    data: newData,
  };
}

function getDataset(data, label, disablePoints = false, subsidiary) {
  const colors = [
    "rgba(255, 99, 132, 1.0)",
    "rgba(54, 162, 235, 1.0)",
    "rgba(255, 206, 86, 1.0)",
    "rgba(75, 192, 192, 1.0)",
    "rgba(153, 102, 255, 1.0)",
    "rgba(255, 159, 64, 1.0)",
    "rgba(100, 159, 64, 1.0)",
    "rgba(255, 58, 86, 1.0)",
  ];
  return {
    label: label,
    data: data.map((el) => ({
      ...el,
      fecha: moment(el.fecha).format("YYYY-MM"),
      subsidiary: el.subsidiary ? el.subsidiary : subsidiary,
    })),
    yAxisID: "y",
    fill: false,
    pointWidth: 2,
    pointRadius: disablePoints ? 0 : 2,
    pointHoverRadius: 4,
    borderColor: colors,
    backgroundColor: colors,
    tension: 0.1,
    pointBackgroundColor: "rgb(50, 50, 50, 0.8)",
    pointBorderColor: "rgb(50, 50, 50, 0.8)",
  };
}
