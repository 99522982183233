import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/vehicle/type/axle/VehicleTypeAxleListPanel";

export function VehicleTypeAxleListPanel({ axles, ...rest }) {
  const t = useLang(NAMESPACE);

  return <Page t={t} {...rest} axles={axles} />;
}

VehicleTypeAxleListPanel.propTypes = {
  axles: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      axle_number: PropTypes.number.isRequired,
      tire_application: PropTypes.array.isRequired,
      tire_quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleTypeAxleListPanel);
