export default {
  driver_name: "Nombre del conductor",
  unassigned_driver: "Conductor no asignado",
  change_driver: "Cambiar conductor",
  messages: {
    driver_changed: "Listo, el conductor ha sido cambiado con éxito",
    same_driver: "El conductor es el mismo que el anterior",
  },
  permits: {
    show: {
      vehicle_driver:
        "Sin permisos para ver el conductor vinculado al vehículo",
    },
  },
};
