import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import css from "./index.module.scss";

function Page({ rows, headers, propertys }) {
  return (
    <>
      {rows.map((row, index) => {
        return (
          <TableContainer
            component={Paper}
            key={index}
            className={classnames(css.table, {
              [css.red]: row.bgColor === "red",
              [css.yellow]: row.bgColor === "yellow",
            })}
          >
            <Table>
              <TableBody>
                {propertys.map((prop, index) => {
                  return (
                    <TableRow key={index}>
                      {headers[index].label.length > 0 && (
                        <TableCell className={classnames(css.col, css.label)}>
                          {headers[index].label}:
                        </TableCell>
                      )}
                      <TableCell
                        className={classnames(css.col, {
                          [css.center]: prop === "actions",
                        })}
                        colSpan={prop === "actions" ? "2" : "1"}
                      >
                        <div
                          className={classnames({
                            [css.flex]: prop === "actions",
                          })}
                        >
                          {row[prop]}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      })}
    </>
  );
}

Page.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array,
  propertys: PropTypes.array.isRequired,
};

export default Page;
