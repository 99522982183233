import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { arrayJoin } from "utils/parse";

import { actionSetNotification } from "store/actions/general/notification";
import { actionSetTireModelApprovalStatus } from "store/actions/tire/model/setTireModelApprovalStatus";

import { UPDATE_TIRE_MODEL } from "store/actions/account/permits";
import { actionSetTireModelStatus } from "store/actions/tire/model/setTireModelStatus";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/model/InfoTireModelCard";

export function InfoTireModelCard({ tireModel, handleOpen, ...rest }) {
  const t = useLang(NAMESPACE);

  const {
    permits,
    setTireModelApprovalStatus,
    setNotification,
    setTireModelStatus,
  } = rest;

  async function onAction() {
    try {
      await setTireModelStatus({
        tireModelId: tireModel.tire_model_id,
        status: tireModel.status ? 0 : 1,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus() {
    setNotification({
      onAction,
      message: tireModel.status ? t("messages.disable") : t("messages.enable"),
      textAction: t("_messages.confirm"),
    });
  }

  async function changeApprovalStatus() {
    try {
      await setTireModelApprovalStatus({
        tireModelId: tireModel.tire_model_id,
        approved: tireModel.approved ? 0 : 1,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleApprovalStatusChange() {
    setNotification({
      onAction: changeApprovalStatus,
      message: tireModel.approved
        ? t("messages.disapprove")
        : t("messages.approve"),
      textAction: t("_messages.confirm"),
    });
  }

  return (
    <Page
      t={t}
      tireModel={{
        ...tireModel,
        created_by: t("_labels.actioned_by", {
          person: `${tireModel.created_by.name} ${
            tireModel.created_by.last_name_1
          } ${tireModel.created_by.last_name_2 || ""}`,
          date: moment(tireModel.created_at).format("LL"),
          time: moment(tireModel.created_at).format("h:mm a"),
        }),
        updated_by: tireModel.updated_by
          ? t("_labels.actioned_by", {
              person: `${tireModel.updated_by.name} ${
                tireModel.updated_by.last_name_1
              } ${tireModel.updated_by.last_name_2 || ""}`,
              date: moment(tireModel.updated_at).format("LL"),
              time: moment(tireModel.updated_at).format("h:mm a"),
            })
          : null,
        tire_model_use: arrayJoin(
          tireModel.tire_model_use.map((use) =>
            t(`_labels.tire_use.options.${use.tire_use_id.toLowerCase()}`)
          ),
          ", ",
          ` ${t("_general.text.and")} `
        ),
        tire_model_application: arrayJoin(
          tireModel.tire_model_application.map((application) =>
            t(
              `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
            )
          ),
          ", ",
          ` ${t("_general.text.and")} `
        ),
      }}
      handleOpen={handleOpen}
      handleChangeStatus={handleChangeStatus}
      updateTireModelPermit={permits.includes(UPDATE_TIRE_MODEL)}
      handleApprovalStatusChange={handleApprovalStatusChange}
    />
  );
}

InfoTireModelCard.propTypes = {
  tireModel: PropTypes.shape({
    tire_model_id: PropTypes.number,
    name: PropTypes.string.isRequired,
    brand: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    status: PropTypes.number.isRequired,
    approved: PropTypes.number.isRequired,
    tire_model_use: PropTypes.arrayOf(
      PropTypes.shape({
        tire_use_id: PropTypes.string.isRequired,
      })
    ).isRequired,
    tire_model_application: PropTypes.arrayOf(
      PropTypes.shape({
        tire_application_id: PropTypes.string.isRequired,
      })
    ).isRequired,
    created_at: PropTypes.string.isRequired,
    created_by: PropTypes.shape({
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
      last_name_2: PropTypes.string,
    }).isRequired,
    updated_at: PropTypes.string,
    updated_by: PropTypes.shape({
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
      last_name_2: PropTypes.string,
    }),
    data_sheet: PropTypes.string.isRequired,
  }).isRequired,
  handleOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setTireModelApprovalStatus: actionSetTireModelApprovalStatus(dispatch),
  setTireModelStatus: actionSetTireModelStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoTireModelCard);
