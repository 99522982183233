import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionClearAlertsOfMount } from "store/actions/vehicle/tire/alert/clearAlertsByMount";
import { actionGetAlertsOfMount } from "store/actions/vehicle/tire/alert/getAlertsByMount";
import { actionClearAlertsOfMountFilters } from "store/actions/vehicle/tire/alert/clearAlertsByMountFilters";
import { actionDeleteAlertByMount } from "store/actions/vehicle/tire/alert/deleteAlertByMount";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";

import { useLang } from "hooks/lang";
import { useParams, useLocation } from "react-router-dom";

import AlertVehicleTirePdf from "./PDF/Document";
import Page from "./page";
import { actionSetAlertsOfMountFilters } from "store/actions/vehicle/tire/alert/setAlertsByMountFilters";

export function AlertsByMount({ ...rest }) {
  const t = useLang();
  const { id: vehicleTireId } = useParams();
  const query = useLocation();
  const {
    filter,
    alerts,
    setNotification,
    getAlertsOfMount,
    clearAlertsOfMount,
    clearAlertsOfMountFilters,
    deleteAlertOfMount,
    getVehicle,
    setAlertsOfMountFilters,
  } = rest;
  const [alertId, setAlertId] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openTireForm, setOpenTireForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [id, setId] = useState(null);

  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();
  const [dataAlerts, setDataAlerts] = useState([]);

  const [economicNumber, setEconomicNumber] = useState("");

  const firstRender = useRef(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicle(vehicleTireId, false);
        setEconomicNumber(response.economic_number);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await getAlertsOfMount(vehicleTireId, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (firstRender.current && query.search) {
      if (Object.keys(filter).length > 0) {
        fetchData();
      }
    } else {
      fetchData();
    }

    return () => {
      clearAlertsOfMount();
    };
  }, [filter]);

  useEffect(() => {
    const params = new URLSearchParams(query.search);

    if (params.has("priority")) {
      setAlertsOfMountFilters({
        ...filter,
        priority: params.get("priority").toUpperCase(),
      });
    }

    firstRender.current = false;
    return () => {
      clearAlertsOfMountFilters();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [alerts.data]);

  useEffect(() => {
    if (isSavingPdf && !pdfInstance.loading) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "AlertasMontaje.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  useEffect(() => {
    if (dataAlerts.length > 0) {
      setIsSavingPdf(true);
      // Se genera un UUID para forzar que el pdf se descargue de nuevo,
      // aunque su contenido sea idéntico al anterior
      const uuid =
        Date.now().toString(36) + Math.random().toString(36).substring(2);
      const pdfData = {
        uuid: uuid,
        date: moment()
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY hh:mm:ss a"),
        alerts: dataAlerts,
        economicNumber,
      };
      updatePdfInstance(<AlertVehicleTirePdf {...pdfData} uuid={uuid} />);
    }
  }, [dataAlerts]);

  async function onChangePage(page) {
    try {
      await getAlertsOfMount(vehicleTireId, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(alertId, id, isVehicleTire) {
    setId(id);
    setAlertId(alertId);
    if (isVehicleTire) {
      setOpenForm(true);
    } else {
      setOpenTireForm(true);
    }
  }

  function handleCloseForm() {
    setAlertId(null);
    setOpenForm(false);
    setOpenTireForm(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleDelete(alert) {
    const { alert_id: alertId, vehicle_tire_id: vehicleTireId } = alert;
    setNotification({
      message: t("_messages.delete.alert"),
      textAction: t("_buttons.confirm"),
      onAction: () => onDelete(vehicleTireId, alertId),
    });
  }

  async function onDelete(vehicleTireId, alertId) {
    try {
      const response = await deleteAlertOfMount(vehicleTireId, alertId);
      setNotification({
        ...response,
      });
      clearAlertsOfMountFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDownloadPdf() {
    try {
      const response = await getAlertsOfMount(
        vehicleTireId,
        { ...filter, flatten: 1 },
        false
      );
      setDataAlerts(response);
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      alerts={alerts}
      economicNumber={economicNumber}
      openForm={openForm}
      openTireForm={openTireForm}
      openFilter={openFilter}
      id={id}
      alertId={alertId}
      vehicleTireId={vehicleTireId}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleDelete={handleDelete}
      handleDownloadPdf={handleDownloadPdf}
    />
  );
}

AlertsByMount.propTypes = {};

const mapStateToProps = (state) => ({
  alerts: state.MountAlerts.alerts,
  filter: state.MountAlerts.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getAlertsOfMount: actionGetAlertsOfMount(dispatch),
  clearAlertsOfMount: actionClearAlertsOfMount(dispatch),
  clearAlertsOfMountFilters: actionClearAlertsOfMountFilters(dispatch),
  deleteAlertOfMount: actionDeleteAlertByMount(dispatch),
  getVehicle: actionGetVehicle(dispatch),
  setAlertsOfMountFilters: actionSetAlertsOfMountFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsByMount);
