import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import LaunchIcon from "@material-ui/icons/Launch";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import AssessmentIcon from "@material-ui/icons/Assessment";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import Button from "components/molecules/general/Button";
import Home from "pages/general/Home";
import css from "./index.module.scss";
import VehicleMoveForm from "components/organisms/vehicle/VehicleMoveForm";
import ResetOdometerForm from "components/organisms/vehicle/ResetOdometerForm";
import TireIcon from "components/atoms/general/TireIcon";
import LinkTpmsByExcelForm from "components/organisms/tpms/LinkTpmsByExcelForm";

const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
});
function Page({
  policy,
  vehicle,
  vehicles,
  subsidiaries,
  divisions,
  drivers,
  vehicleTypes,
  vehicleBrands,
  engineBrands,
  transmissionBrands,
  createVehiclePermit,
  updateVehiclePermit,
  deleteVehiclePermit,
  resetVehicleReviewPermit,
  resetDifferencePermit,
  filter,
  t,
  openExcelForm,
  handleOpenExcelForm,
  handleCloseExcelForm,
  getVehicle,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  setVehicleStatus,
  clearVehicleFilters,
  setVehicleFilters,
  getVehicles,
  clearVehicles,
  handleResetDiff,
  setRequiredOdometer,
  openMoveVehicleForm,
  vehicleId,
  handleOpenMoveVehicleForm,
  handleCloseMoveVehicleForm,
  selectedFilter,
  setSelectedFilters,
  openResetOdometerForm,
  handleOpenResetOdometerForm,
  handleCloseResetOdometerForm,
  openLinkTpmsByExcelForm,
  handleOpenLinkTpmsByExcelForm,
  handleCloseLinkTpmsByExcelForm,
}) {
  const vehicleRows = useMemo(() => {
    return vehicles.data.map((vehicle) => {
      return {
        id: vehicle.vehicle_id,
        key: vehicle.vehicle_id,
        status: {
          status: vehicle.status,
          tooltipPermit: !updateVehiclePermit
            ? t("_permits.update.vehicle")
            : "",
        },
        subsidiary: vehicle.subsidiary.name,
        division:
          vehicle.division.length > 0 ? vehicle.division[0].division.name : "",
        economic_number: vehicle.economic_number,
        vehicle_type: vehicle.vehicle_type.name,
        vehicle_brand: vehicle.vehicle_brand.name,
        vehicle_year: vehicle.vehicle_year,
        type_of_route: t(
          `_labels.type_of_route.options.${vehicle.type_of_route.toLowerCase()}`
        ),
        has_odometer: vehicle.has_odometer ? t("_labels.yes") : t("_labels.no"),
        km: vehicle.vehicle_review
          ? `${kmFormatter.format(vehicle.vehicle_review[0].odometer)} km`
          : "",
        driver: vehicle.driver ? `${vehicle.driver.name}` : "",
        edit: {
          tooltipPermit: !updateVehiclePermit
            ? t("_permits.update.vehicle")
            : "",
        },
        delete: {
          tooltipPermit: !deleteVehiclePermit
            ? t("_permits.delete.vehicle")
            : "",
        },
        odometer: {
          disabled:
            !resetVehicleReviewPermit ||
            (vehicle.vehicle_review.length > 0 &&
              ["COMPLETE", "DAMAGE AND WEAR"].includes(
                vehicle.vehicle_review[0].review_type
              ) &&
              !vehicle.vehicle_review[0].end_time),
          tooltipPermit: !resetVehicleReviewPermit
            ? t("_permits.reset.odometer")
            : vehicle.vehicle_review.length > 0 &&
              ["COMPLETE", "DAMAGE AND WEAR"].includes(
                vehicle.vehicle_review[0].review_type
              ) &&
              !vehicle.vehicle_review[0].end_time
            ? t("_labels.inspection_in_process")
            : t("_buttons.reset_odometer"),
        },
        reset_diff: {
          has_gps: vehicle.gps_vehicle.length > 0,
          gps_id:
            vehicle.gps_vehicle.length > 0
              ? vehicle.gps_vehicle[0].gps_id
              : null,
          disabled: !resetDifferencePermit,
          tooltipPermit: !resetDifferencePermit
            ? t("_permits.reset.difference")
            : t("_labels.reset_difference"),
        },
        other: {
          tooltip: t("_buttons.move"),
          label: <LaunchIcon />,
          variant: "icon",
          size: "medium",
          handleClick: () => handleOpenMoveVehicleForm(vehicle.vehicle_id),
        },
        redirect: getRedirects(vehicle, t, policy),
      };
    });
  }, [vehicles.data]);

  return (
    <Home
      item={vehicle}
      name="vehicle"
      filter={filter}
      createTooltipPermit={
        !createVehiclePermit ? t("_permits.create.vehicle") : ""
      }
      currentPage={vehicles.current_page}
      deleteConfirmationMessage={t("_messages.delete.vehicle")}
      disableConfirmationMessage={t("_messages.change_status.vehicle.disable")}
      enableConfirmationMessage={t("_messages.change_status.vehicle.enable")}
      rows={vehicleRows}
      title={t("_general.vehicles")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "division", label: t("_labels.division.singular") },
        { id: "economic_number", label: t("_labels.economic_number") },
        { id: "vehicle_type", label: t("_labels.vehicle_type.label") },
        { id: "vehicle_brand", label: t("_labels.vehicle_brand.label") },
        { id: "vehicle_year", label: t("_labels.vehicle_year") },
        { id: "type_of_route", label: t("_labels.type_of_route.label") },
        { id: "has_odometer", label: t("_labels.has_odometer.simple") },
        { id: "km", label: t("_labels.mileage") },
        { id: "driver", label: t("_general.driver") },
        { id: "actions", label: "" },
      ]}
      lastPage={vehicles.last_page}
      noItemsText={t("_general.no_vehicles")}
      initialDefaultValues={{
        economic_number: "",
        enrollment: "",
        subsidiary_id: "",
        division_id: "",
        driver_id: "",
        vehicle_type_id: "",
        vehicle_brand_id: "",
        type_of_route: "",
        has_odometer: false,
        vehicle_model: "",
        vehicle_engine_brand_id: "",
        vehicle_year: "",
        engine_transmission_brand_id: "",
        transmission_model: "",
        wheel: "",
        transmission_speeds: "",
        cylinder_capacity: "",
        odometer: "",
      }}
      fields={[
        {
          name: "economic_number",
          required: true,
          label: t("_labels.economic_number"),
          mediumWidth: true,
        },
        {
          name: "enrollment",
          required: false,
          label: t("_labels.auto_enrollment"),
          mediumWidth: true,
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          label: t("_labels.subsidiary_field.singular.label"),
          smallWidth: true,
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
          defaultText: t("_labels.subsidiary_field.singular.value"),
        },
        {
          name: "division_id",
          required: false,
          component: "select",
          label: t("_labels.division.singular"),
          smallWidth: true,
          selectItems: divisions.map((division) => ({
            value: division.division_id.toString(),
            name: `${division.name} - ${division.subsidiary.name}`,
            disabled: !division.status,
          })),
          defaultText: t("_labels.division.default"),
        },
        {
          name: "driver_id",
          required: false,
          component: "select",
          label: t("_labels.driver.singular"),
          smallWidth: true,
          selectItems: drivers.map((driver) => ({
            value: driver.driver_id.toString(),
            name: driver.name,
            disabled: !driver.status,
          })),
          defaultText: t("_labels.select_a_driver"),
        },
        {
          name: "vehicle_type_id",
          required: true,
          component: "select",
          label: t("_labels.vehicle_type.label"),
          smallWidth: true,
          selectItems: vehicleTypes.map((vehicleType) => ({
            value: vehicleType.vehicle_type_id.toString(),
            name: vehicleType.name,
            disabled: !vehicleType.status,
          })),
          defaultText: t("_labels.vehicle_type.default"),
        },
        {
          name: "vehicle_brand_id",
          required: true,
          component: "select",
          label: t("_labels.vehicle_brand.label"),
          smallWidth: true,
          selectItems: vehicleBrands.map((brand) => ({
            value: brand.brand_id.toString(),
            name: brand.name,
            disabled: !brand.status || !brand.approved,
          })),
          defaultText: t("_labels.vehicle_brand.default"),
        },
        {
          name: "type_of_route",
          required: true,
          component: "select",
          label: t("_labels.type_of_route.label"),
          smallWidth: true,
          selectItems: [
            {
              value: "FOREIGN",
              name: t("_labels.type_of_route.options.foreign"),
            },
            {
              value: "LOCAL",
              name: t("_labels.type_of_route.options.local"),
            },
          ],
          defaultText: t("_labels.type_of_route.default"),
        },
        {
          name: "has_odometer",
          required: false,
          component: "checkbox",
          label: t("_labels.has_odometer.label"),
          fullWidth: true,
          helperText: t("_labels.has_odometer.helper_text"),
        },
        {
          name: "vehicle_model",
          required: false,
          label: t("_labels.vehicle_model"),
          smallWidth: true,
        },
        {
          name: "vehicle_engine_brand_id",
          required: false,
          component: "select",
          label: t("_labels.engine_brand.label"),
          smallWidth: true,
          selectItems: engineBrands.map((brand) => ({
            value: brand.brand_id.toString(),
            name: brand.name,
            disabled: !brand.status,
          })),
          defaultText: t("_labels.engine_brand.default"),
        },
        {
          name: "vehicle_year",
          required: false,
          type: "number",
          label: t("_labels.vehicle_year"),
          smallWidth: true,
        },
        {
          name: "engine_transmission_brand_id",
          required: false,
          component: "select",
          label: t("_labels.transmission_brand.label"),
          smallWidth: true,
          selectItems: transmissionBrands.map((brand) => ({
            value: brand.brand_id.toString(),
            name: brand.name,
            disabled: !brand.status,
          })),
          defaultText: t("_labels.transmission_brand.default"),
        },
        {
          name: "transmission_model",
          required: false,
          label: t("_labels.transmission_model"),
          smallWidth: true,
        },
        {
          name: "wheel",
          required: true,
          component: "select",
          label: t("_labels.wheel.label"),
          smallWidth: true,
          selectItems: [
            {
              value: "STEEL",
              name: t("_labels.wheel.options.steel"),
            },
            {
              value: "ALUMINUM",
              name: t("_labels.wheel.options.aluminum"),
            },
            {
              value: "BOTH",
              name: t("_labels.wheel.options.both"),
            },
          ],
          defaultText: t("_labels.wheel.default"),
        },
        {
          name: "transmission_speeds",
          required: false,
          type: "number",
          inputProps: {
            inputProps: {
              min: 1,
              step: 1,
            },
          },
          label: t("_labels.transmission_speeds"),
          mediumWidth: true,
        },
        {
          name: "cylinder_capacity",
          required: false,
          type: "number",
          inputProps: {
            inputProps: {
              min: 1,
              step: 1,
            },
          },
          label: t("_labels.cylinder"),
          mediumWidth: true,
          inputAdornment: (
            <>
              cm<sup>3</sup>
            </>
          ),
        },
        {
          name: "odometer",
          label: t("_labels.odometer"),
          required: setRequiredOdometer,
          requiredInput: "has_odometer",
          disabledInput: "has_odometer",
          type: "number",
          mediumWidth: true,
          inputAdornment: "km",
        },
      ]}
      createFormTitle={t("_titles.new.vehicle")}
      updateFormTitle={t("_titles.update.vehicle")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.vehicle"),
        },
        with_alerts: true,
        alert_code: true,
        alert_tire_code: true,
        date: true,
        subsidiaries: true,
        divisions: true,
        vehicle_type_id: true,
        vehicle_brand_id: true,
        type_of_route: true,
        has_odometer: {
          title: t("_labels.odometer"),
        },
        with_link_driver: true,
        driver_id: true,
        enrollment: true,
        vehicle_engine_brand_id: true,
        engine_transmission_brand_id: true,
        cylinder_capacity: true,
        transmission_model: true,
        transmission_speeds: true,
        vehicle_model: true,
        vehicle_year: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        with_alerts: "",
        alert_code: [],
        alert_tire_code: [],
        subsidiaries: [],
        divisions: [],
        vehicle_type_id: "",
        vehicle_brand_id: "",
        type_of_route: "",
        has_odometer: "",
        with_link_driver: false,
        driver_id: "",
        enrollment: "",
        vehicle_engine_brand_id: "",
        engine_transmission_brand_id: "",
        cylinder_capacity: "",
        transmission_model: "",
        transmission_speeds: "",
        vehicle_model: "",
        vehicle_year: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.vehicle")}
      updatedMessage={t("_messages.updated.vehicle")}
      getItemAction={getVehicle}
      setFiltersAction={setVehicleFilters}
      clearFiltersAction={clearVehicleFilters}
      deleteAction={deleteVehicle}
      setStatusAction={setVehicleStatus}
      getItemsAction={getVehicles}
      clearItemsAction={clearVehicles}
      addAction={addVehicle}
      updateAction={updateVehicle}
      handleReset={handleOpenResetOdometerForm}
      handleResetDiff={handleResetDiff}
      openExcelForm={openExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      selectedFilter={selectedFilter}
      setSelectedFilters={setSelectedFilters}
    >
      <div>
        <Button
          disabled={!createVehiclePermit}
          tooltip={!createVehiclePermit ? t("_permits.create.vehicle") : ""}
          color="secondary"
          variant="contained"
          onClick={handleOpenExcelForm}
          className={classnames(css.excel_btn)}
        >
          {t("_buttons.register_by_excel")}
        </Button>

        {Boolean(policy?.use_tpms) && Boolean(policy?.no_tire) && (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleOpenLinkTpmsByExcelForm}
            className="ml-3"
          >
            {t("_titles.link.tpms_with_vehicle")}
          </Button>
        )}
      </div>

      <VehicleMoveForm
        open={openMoveVehicleForm}
        onClose={handleCloseMoveVehicleForm}
        vehicleId={vehicleId}
      />

      <ResetOdometerForm
        open={openResetOdometerForm}
        onClose={handleCloseResetOdometerForm}
        vehicleId={vehicleId}
      />

      <LinkTpmsByExcelForm
        open={openLinkTpmsByExcelForm}
        onClose={handleCloseLinkTpmsByExcelForm}
      />
    </Home>
  );
}

Page.propTypes = {
  vehicle: PropTypes.object.isRequired,
  vehicles: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  engineBrands: PropTypes.array.isRequired,
  transmissionBrands: PropTypes.array.isRequired,
  createVehiclePermit: PropTypes.bool.isRequired,
  updateVehiclePermit: PropTypes.bool.isRequired,
  deleteVehiclePermit: PropTypes.bool.isRequired,
  resetVehicleReviewPermit: PropTypes.bool.isRequired,
  resetDifferencePermit: PropTypes.bool.isRequired,
  openExcelForm: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getVehicle: PropTypes.func.isRequired,
  addVehicle: PropTypes.func.isRequired,
  updateVehicle: PropTypes.func.isRequired,
  deleteVehicle: PropTypes.func.isRequired,
  setVehicleStatus: PropTypes.func.isRequired,
  setVehicleFilters: PropTypes.func.isRequired,
  clearVehicleFilters: PropTypes.func.isRequired,
  getVehicles: PropTypes.func.isRequired,
  clearVehicles: PropTypes.func.isRequired,
  handleResetDiff: PropTypes.func.isRequired,
  handleOpenExcelForm: PropTypes.func.isRequired,
  handleCloseExcelForm: PropTypes.func.isRequired,
  setRequiredOdometer: PropTypes.func.isRequired,
  openMoveVehicleForm: PropTypes.bool.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleOpenMoveVehicleForm: PropTypes.func.isRequired,
  handleCloseMoveVehicleForm: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  openResetOdometerForm: PropTypes.bool.isRequired,
  handleOpenResetOdometerForm: PropTypes.func.isRequired,
  handleCloseResetOdometerForm: PropTypes.func.isRequired,
  openLinkTpmsByExcelForm: PropTypes.bool.isRequired,
  handleOpenLinkTpmsByExcelForm: PropTypes.func.isRequired,
  handleCloseLinkTpmsByExcelForm: PropTypes.func.isRequired,
  policy: PropTypes.object,
};

export default Page;

function getRedirects(vehicle, t, policy) {
  const redirects = [];
  const hasTpgps = vehicle?.vehicle_tire.some(
    (vehicleTire) => vehicleTire?.movement_tire?.tire_cycle?.tire.tpgps_tire
  );
  const hasTpgpsAlert = vehicle?.vehicle_tire.some(
    (vehicleTire) => vehicleTire.alert_vehicle_tire_tpgps?.length > 0
  );

  const hasRedAlert =
    vehicle.vehicle_tire_alerts.some((v) => v.alert.priority == "HIGH") ||
    vehicle.vehicle_tire?.some(
      (v) => v.movement_tire.alert_tire_high?.length > 0
    );

  const countRedAlert =
    vehicle.vehicle_tire_alerts.filter((v) => v.alert.priority == "HIGH")
      .length +
    vehicle.vehicle_tire.reduce((acc, vehicleTire) => {
      return acc + vehicleTire?.movement_tire.alert_tire_high?.length ?? 0;
    }, 0);
  const hasYellowAlert =
    vehicle.vehicle_tire_alerts.some((v) => v.alert.priority == "HALF") ||
    vehicle.vehicle_tire?.some(
      (v) => v.movement_tire?.alert_tire_half?.length > 0
    );
  const countYellowAlert =
    vehicle.vehicle_tire_alerts.filter((v) => v.alert.priority == "HALF")
      .length +
    vehicle.vehicle_tire.reduce((acc, vehicleTire) => {
      return acc + vehicleTire?.movement_tire.alert_tire_half?.length ?? 0;
    }, 0);

  const hasGrayAlert =
    vehicle.vehicle_tire_alerts.some((v) => v.alert.priority == "LOW") ||
    vehicle.vehicle_tire?.some(
      (v) => v.movement_tire?.alert_tire_low?.length > 0
    );
  const countGrayAlert =
    vehicle.vehicle_tire_alerts.filter((v) => v.alert.priority == "LOW")
      .length +
    vehicle.vehicle_tire.reduce((acc, vehicleTire) => {
      return acc + vehicleTire?.movement_tire.alert_tire_low?.length ?? 0;
    }, 0);

  if (hasTpgps && Boolean(policy?.use_tpms)) {
    redirects.push({
      label: <AssessmentIcon />,
      variant: "icon",
      size: "medium",
      style: { color: "#777" },
      href: `vehicle/${vehicle.vehicle_id}/charts`,
      target: "_blank",
      ref: "noopener noreferrer",
    });
  }

  if (hasRedAlert) {
    redirects.push({
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {countRedAlert} <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#e54141" },
      href: `mount/${vehicle.vehicle_id}/alerts?priority=high`,
      target: "_blank",
      ref: "noopener noreferrer",
    });
  }

  if (hasYellowAlert) {
    redirects.push({
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {countYellowAlert} <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#fac957", color: "#333" },
      href: `mount/${vehicle.vehicle_id}/alerts?priority=half`,
      target: "_blank",
      ref: "noopener noreferrer",
    });
  }

  if (hasGrayAlert) {
    redirects.push({
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {countGrayAlert} <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#999" },
      href: `mount/${vehicle.vehicle_id}/alerts?priority=low`,
      target: "_blank",
      ref: "noopener noreferrer",
    });
  }

  if (hasTpgpsAlert) {
    redirects.push({
      label: <NewReleasesIcon />,
      variant: "icon",
      size: "medium",
      style: { color: "#e54141" },
      href: `mount/${vehicle.vehicle_id}/tpms/alerts`,
      target: "_blank",
      ref: "noopener noreferrer",
    });
  }

  redirects.push({
    label: <TireIcon />,
    variant: "icon",
    size: "medium",
    style: { color: "#444" },
    href: `vehicle/${vehicle.vehicle_id}/installations`,
    target: "_blank",
    ref: "noopener noreferrer",
    tooltip: t("_labels.historic_installations"),
  });

  return redirects;
}
