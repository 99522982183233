export function getSubsidiaryViaWorkArea(account, workArea) {
  if (!account) {
    return [];
  }
  return account.subsidiaries.filter(
    (assignment) => assignment.user_assigned_subsidiary_id == workArea.id
  );
}

export function getCompanyViaWorkArea(account, workArea) {
  const subsidiary = getSubsidiaryViaWorkArea(account, workArea)[0];

  if (!subsidiary) {
    return [];
  }

  return account.companies.filter(
    (company) => company.company_id == subsidiary.subsidiary.company_id
  );
}

export function getCorporateViaWorkArea(account, workArea) {
  const company = getCompanyViaWorkArea(account, workArea)[0];
  const corporate = account.corporates.find(
    (corporate) => corporate.corporate_id == company.company.corporate_id
  );
  return corporate;
}
