import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAssociationDepthPolicies } from "store/actions/association/policy/depth/getDepthPolicies";
import { actionClearAssociationDepthPolicies } from "store/actions/association/policy/depth/clearDepthPolicies";
import { actionSetAssociationDepthPoliciesFilters } from "store/actions/association/policy/depth/setAssociationDepthPolicyFilters";
import { actionClearAssociationDepthPoliciesFilters } from "store/actions/association/policy/depth/clearAssociationDepthFilters";
import { actionDeleteAssociationDepthPolicy } from "store/actions/association/policy/depth/deleteAssociationDepthPolicy";

import {
  CREATE_VEHICLE_DEPTH_POLICY,
  DELETE_VEHICLE_DEPTH_POLICY,
  UPDATE_VEHICLE_DEPTH_POLICY,
} from "store/actions/account/permits";
import Page from "./page";

export function DepthPolicyTab({ ...rest }) {
  const t = useLang();
  const { id: associationId } = useParams();
  const {
    filter,
    policies,
    permits,
    setNotification,
    getDepthPolicies,
    clearDepthPolicies,
    setDepthPoliciesFilters,
    clearDepthPoliciesFilters,
    deleteDepthPolicy,
  } = rest;
  const [openForm, setOpenForm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [depthPolicy, setDepthPolicy] = useState({});
  const [openMultipleForm, setOpenMultipleForm] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getDepthPolicies({ associationId, page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearDepthPolicies();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearDepthPoliciesFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getDepthPolicies({ associationId, page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(vehicle) {
    setVehicle(vehicle);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setVehicle({});
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setDepthPoliciesFilters({ search });
  }

  function handleEdit(vehicle, depthPolicy) {
    setVehicle(vehicle);
    setDepthPolicy(depthPolicy);
    setOpenUpdateForm(true);
  }

  function handleCloseUpdateForm() {
    setOpenUpdateForm(false);
    setDepthPolicy({});
    setVehicle({});
  }

  async function onDeleteDepthPolicy(vehicleId, depthPolicyId) {
    try {
      await deleteDepthPolicy(associationId, vehicleId, depthPolicyId);
      setNotification({
        message: t("_messages.deleted.depth_policy"),
      });
      clearDepthPoliciesFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(vehicleId, depthPolicyId) {
    setNotification({
      onAction: () => {
        onDeleteDepthPolicy(vehicleId, depthPolicyId);
      },
      message: t("_messages.delete.depth_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenMultiple(vehicles) {
    setVehicles(vehicles);
    setOpenMultipleForm(true);
  }

  function handleCloseMultiple() {
    setOpenMultipleForm(false);
  }

  return (
    <Page
      t={t}
      policies={policies}
      search={filter.search}
      filter={filter}
      vehicle={vehicle}
      depthPolicy={depthPolicy}
      openFilter={openFilter}
      openForm={openForm}
      openUpdateForm={openUpdateForm}
      handleEdit={handleEdit}
      handleCloseUpdateForm={handleCloseUpdateForm}
      handleDelete={handleDelete}
      handleSearch={handleSearch}
      handleCloseFilter={handleCloseFilter}
      handleOpenFilter={handleOpenFilter}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleChangePage={handleChangePage}
      openMultiple={openMultipleForm}
      vehicles={vehicles}
      handleOpenMultiple={handleOpenMultiple}
      handleCloseMultiple={handleCloseMultiple}
      updateVehicleDepthPolicyPermit={permits.includes(
        UPDATE_VEHICLE_DEPTH_POLICY
      )}
      createVehicleDepthPolicyPermit={permits.includes(
        CREATE_VEHICLE_DEPTH_POLICY
      )}
      deleteVehicleDepthPolicyPermit={permits.includes(
        DELETE_VEHICLE_DEPTH_POLICY
      )}
    />
  );
}

DepthPolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  policies: state.Association.DepthPolicies.policies,
  filter: state.Association.DepthPolicies.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDepthPolicies: actionGetAssociationDepthPolicies(dispatch),
  clearDepthPolicies: actionClearAssociationDepthPolicies(dispatch),
  setDepthPoliciesFilters: actionSetAssociationDepthPoliciesFilters(dispatch),
  clearDepthPoliciesFilters:
    actionClearAssociationDepthPoliciesFilters(dispatch),
  deleteDepthPolicy: actionDeleteAssociationDepthPolicy(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthPolicyTab);
