import React from "react";
import neumaticos from "resources/img/icons/neumaticos.png";
import pressure from "resources/img/icons/presion.png";
import Slide from "@material-ui/core/Slide";
import BarChart from "components/organisms/general/Charts/BarChart";
import { useLang } from "hooks/lang";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
const TIRE_APPLICATIONSS = [
  "ALL_POSITION",
  "DIRECTIONAL",
  "TRACTION",
  "TRAILER",
];

const DEPTH_CONDITIONS = [
  "GOOD CONDITION",
  "SCHEDULED WITHDRAWAL",
  "CRITICAL WITHDRAWAL",
];

const PRESSURE_CONDITIONS = [
  "VERY HIGH",
  "HIGH",
  "NORMAL",
  "LOW",
  "VERY LOW",
  "EXCESSIVELY LOW",
  "EXAGGERATELY LOW",
  "NO PRESSURE",
];

function TireFleetStatus({ ...rest }) {
  const t = useLang();
  const { show, data, type, tiresDepthChart, tiresPressureData } = rest;

  return (
    <Slide
      in={show}
      timeout={600}
      direction={type == "mount" ? "left" : "right"}
    >
      <div>
        {/* Semaforo de profundidad */}
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-12">
            <h5
              id={"Depth"}
              style={{
                scrollMarginTop: "130px",
              }}
            >
              <img src={neumaticos} width={48} />{" "}
              {t("_labels.floor_depth").toUpperCase()}
            </h5>
          </div>
          <div className="col-md-12">
            {Object.entries(data[`depth_condition_${type}`] ?? {})
              .slice()
              .sort(
                (a, b) =>
                  DEPTH_CONDITIONS.indexOf(a[0]) -
                  DEPTH_CONDITIONS.indexOf(b[0])
              )
              .map(([condition, info]) => {
                if (typeof info === "object" && info != undefined) {
                  return (
                    <div className="row" key={condition}>
                      <div className="col-md-12  text-center pdb20">
                        <div
                          className="sMarcoInfo"
                          style={{
                            backgroundColor:
                              condition === "GOOD CONDITION"
                                ? "limegreen"
                                : condition === "SCHEDULED WITHDRAWAL"
                                ? "goldenrod"
                                : condition === "CRITICAL WITHDRAWAL"
                                ? "darkred"
                                : "#0d0d0d",
                          }}
                        >
                          <h3 className="sfcblanco">
                            {t(
                              `_labels.depth_tolerance_policies.${condition
                                .replace(" ", "_")
                                .toLowerCase()}`
                            ).toUpperCase()}
                            {": "}
                            {info?.[`${type}_elements`]
                              ? formatter.format(info[`${type}_elements`])
                              : "0"}
                            {" | "}
                            {info?.[`${type}_percent`]
                              ? formatter.format(info[`${type}_percent`])
                              : "0"}
                            %
                          </h3>
                        </div>
                      </div>
                      {Object.entries(info?.tire_application ?? {}).map(
                        ([tireApplication, data]) => {
                          if (typeof data === "object" && data != undefined) {
                            return (
                              <div
                                className="col-6 col-md-3 text-center pdb20"
                                key={tireApplication}
                              >
                                <div className="sMarcoInfo">
                                  <h3 className="sinmp">
                                    {data[`${type}_elements`]
                                      ? formatter.format(
                                          data[`${type}_elements`]
                                        )
                                      : "0"}
                                  </h3>
                                  <small className="sinmp text-muted">
                                    {t(
                                      `_labels.tire_application.options.${tireApplication.toLowerCase()}`
                                    ).toUpperCase()}
                                  </small>
                                </div>
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  );
                }
              })}
          </div>

          <div className={"col-md-12"}>
            <div className="chart-container">
              {(tiresDepthChart.labels || tiresDepthChart.length > 0) && (
                <BarChart
                  options={
                    tiresDepthChart.labels
                      ? {
                          responsive: true,
                          maintainAspectRatio: false,
                          fill: true,
                          locale: "en",
                          scales: {
                            y: {
                              min: 0,
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.number_of_tires"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                            x: {
                              title: {
                                display: true,
                                text: t(
                                  "_labels.pile_stats_report.range_of_depths"
                                ),
                                font: {
                                  size: 14,
                                },
                              },
                            },
                          },
                          plugins: {
                            legend: {
                              position: "top",
                              labels: {
                                font: {
                                  size: 14,
                                  weight: "bold",
                                },
                              },
                              // align: "start",
                            },
                          },
                        }
                      : null
                  }
                  data={tiresDepthChart}
                />
              )}
            </div>
          </div>
        </div>

        {Object.keys(tiresPressureData).length > 0 && (
          <>
            {/* Semaforo de presion */}
            <div className="row">
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-12">
                <h3
                  id={"Pressure"}
                  style={{
                    scrollMarginTop: "130px",
                  }}
                >
                  <img src={pressure} width={40} /> {t("_labels.air_pressure")}
                </h3>
              </div>

              <div className="col-md-12">
                <div className="row justify-content-center">
                  {Object.entries(tiresPressureData ?? {})
                    .slice()
                    .sort(
                      (a, b) =>
                        PRESSURE_CONDITIONS.indexOf(a[0]) -
                        PRESSURE_CONDITIONS.indexOf(b[0])
                    )
                    .map(([condition, info]) => {
                      if (typeof info === "object" && info != undefined) {
                        return (
                          <div
                            className="col-6 col-md text-center pdb20"
                            key={condition}
                          >
                            <div
                              className="sMarcoInfo sfcblanco"
                              style={{
                                backgroundColor:
                                  getPressureConditionColor(condition),
                              }}
                            >
                              <h4 className="sinmp">
                                {formatter.format(
                                  info?.[`${type}_elements`] ?? 0
                                )}{" "}
                                |{" "}
                                {percentFormatter.format(
                                  info?.[`${type}_percent`] ?? 0
                                )}
                                %
                              </h4>
                              <div className="sinmp sfcblanco">
                                {t(
                                  `_labels.summary_report.pressure_condition.${condition
                                    .replace(" ", "_")
                                    .toLowerCase()}`
                                ).toUpperCase()}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Tabla de presión */}
        {`pressure_condition_${type}` in data && (
          <>
            <div className="col-md-12 text-center ml-2 mt-2 mb-3">
              <h5
                style={{
                  fontSize: "24px",
                }}
              >
                {t("_titles.tires_by_application")}
              </h5>
            </div>
            <div className="col-md-12">
              <div className="row justify-content-center">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td />
                      {TIRE_APPLICATIONSS.map((application) => {
                        return (
                          <td className="text-center" key={application}>
                            {t(
                              `_labels.tire_application.options.${application.toLowerCase()}`
                            ).toUpperCase()}
                          </td>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.vehicle_type_name ?? {}).length > 0 && (
                      <>
                        {Object.entries(
                          data?.[`pressure_condition_${type}`] ?? {}
                        )
                          .slice()
                          .sort(
                            (a, b) =>
                              PRESSURE_CONDITIONS.indexOf(a[0]) -
                              PRESSURE_CONDITIONS.indexOf(b[0])
                          )
                          .map(([condition, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr key={index}>
                                  <td>
                                    {t(
                                      `_labels.summary_report.pressure_condition.${condition
                                        .replace(" ", "_")
                                        .toLowerCase()}`
                                    ).toUpperCase()}
                                  </td>
                                  {TIRE_APPLICATIONSS.map((application) => {
                                    return (
                                      <td
                                        className="text-center"
                                        key={application}
                                      >
                                        {formatter.format(
                                          info.tire_application[application]?.[
                                            `${type}_elements`
                                          ] ?? 0
                                        )}
                                        {" | "}
                                        {formatter.format(
                                          info.tire_application[application]?.[
                                            `${type}_elements`
                                          ] ?? 0
                                        )}
                                        {"%"}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            }
                          })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </Slide>
  );
}

export default TireFleetStatus;

function getPressureConditionColor(condition) {
  switch (condition) {
    case "NORMAL":
      return "#28B463";
    case "VERY LOW":
      return "#2E86C1";
    case "LOW":
      return "#85C1E9";
    case "HIGH":
      return "#EC7063";
    case "VERY HIGH":
      return "#C0392B";
    case "NO PRESSURE":
      return "#0d0d0d";
    default:
      return "#C0392B";
  }
}
