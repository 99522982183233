import { http } from "store/actions/http";
import { DELETE_SUBSIDIARY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteSubsidiary = (dispatch) => async (subsidiaryId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: `DELETE`,
      path: `subsidiary/${subsidiaryId}`,
    });
    dispatch({
      type: DELETE_SUBSIDIARY,
      payload: subsidiaryId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
