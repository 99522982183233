import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";
import SearchInput from "components/molecules/general/SearchInput";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import VehicleHistoryTabs from "components/organisms/vehicle/VehicleHistoryTabs";
import ListItemStatusCard from "components/molecules/general/cards/ListItemStatusCard";

import FilterVehicleSubsidiaryHistoryForm from "components/organisms/vehicle/subsidiary/FilterVehicleSubsidiaryHistoryForm";

import css from "./index.module.scss";

function Page({
  t,
  search,
  filterOpen,
  subsidiaries,
  handleSearch,
  handleOpenFilter,
  handleCloseFilter,
  handleChangePage,
  handleExitHistory,
}) {
  return (
    <VehicleHistoryTabs
      className={classnames({
        [css.empty]: subsidiaries.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            variant="icon"
            className={classnames("bw")}
            onClick={handleExitHistory}
          >
            <VisibilityIcon />
          </Button>

          <Button
            className={classnames("bw")}
            variant="icon"
            classNameButton={classnames(css.mr8)}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      {subsidiaries.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            {subsidiaries.data.map((subsidiary, index) => {
              return (
                <ListItemStatusCard
                  key={index}
                  description={""}
                  tooltipPermit={""}
                  className={css.card}
                  title={subsidiary.subsidiary.name}
                  status={subsidiary.subsidiary.status}
                  handleClick={() => {}}
                />
              );
            })}
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={subsidiaries.current_page}
              onChangePage={handleChangePage}
              totalPages={subsidiaries.last_page}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_subsidiaries")} />
      )}

      <FilterVehicleSubsidiaryHistoryForm
        open={filterOpen}
        onClose={handleCloseFilter}
      />
    </VehicleHistoryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  filterOpen: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  subsidiaries: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleExitHistory: PropTypes.func.isRequired,
};

export default Page;
