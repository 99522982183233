import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTpmsAlerts =
  (dispatch) =>
  async (
    vehicleId,
    {
      search,
      page,
      scope,
      order = "DESC",
      date_from: dateFrom,
      date_to: dateTo,
      ranking_alert: rankingAlert,
      flatten,
    }
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `alert/mount/${vehicleId}/TPGPS`,
        params: {
          search,
          page,
          scope,
          order,
          date_from: dateFrom,
          date_to: dateTo,
          ranking_alert: rankingAlert,
          flatten,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
