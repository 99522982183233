import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDivision } from "store/actions/division/getDivision";
import { actionGetDivisions } from "store/actions/division/getDivisions";
import { actionClearDivisions } from "store/actions/division/clearDivisions";
import { actionSetDivisionFilters } from "store/actions/division/setDivisionFilters";
import { actionClearDivisionFilters } from "store/actions/division/clearDivisionFilters";
import { actionDeleteDivision } from "store/actions/division/deleteDivision";
import { actionSetDivisionStatus } from "store/actions/division/setDivisionStatus";
import { actionAddDivision } from "store/actions/division/addDivision";
import { actionUpdateDivision } from "store/actions/division/updateDivision";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import {
  CREATE_DIVISION,
  UPDATE_DIVISION,
  DELETE_DIVISION,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "pages/division/HomeDivision";

export function HomeDivision({ ...rest }) {
  const t = useLang();

  const {
    addDivision,
    account,
    filter,
    permits,
    deleteDivision,
    division,
    divisions,
    setNotification,
    getDivision,
    getDivisions,
    clearDivisions,
    setDivisionFilters,
    setDivisionStatus,
    clearDivisionFilters,
    updateDivision,
    getSubsidiaries,
  } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }
        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  return (
    <Page
      t={t}
      division={division}
      divisions={divisions}
      filter={filter}
      createDivisionPermit={permits.includes(CREATE_DIVISION)}
      updateDivisionPermit={permits.includes(UPDATE_DIVISION)}
      deleteDivisionPermit={permits.includes(DELETE_DIVISION)}
      getDivision={getDivision}
      getDivisions={getDivisions}
      updateDivision={updateDivision}
      addDivision={addDivision}
      clearDivisionFilters={clearDivisionFilters}
      setDivisionFilters={setDivisionFilters}
      subsidiaries={subsidiaries}
      deleteDivision={deleteDivision}
      setDivisionStatus={setDivisionStatus}
      clearDivisions={clearDivisions}
    />
  );
}

HomeDivision.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  divisions: state.Division.Division.divisions,
  filter: state.Division.Division.filter_division,
  division: state.Division.Division.division,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDivisions: actionGetDivisions(dispatch),
  getDivision: actionGetDivision(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setDivisionFilters: actionSetDivisionFilters(dispatch),
  deleteDivision: actionDeleteDivision(dispatch),
  setDivisionStatus: actionSetDivisionStatus(dispatch),
  addDivision: actionAddDivision(dispatch),
  updateDivision: actionUpdateDivision(dispatch),
  clearDivisions: actionClearDivisions(dispatch),
  clearDivisionFilters: actionClearDivisionFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeDivision);
