import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { fieldToTextField } from "formik-material-ui";

import Page from "./page";

function DateTimeField({ form, type, field, ...props }) {
  const rest = fieldToTextField({ form, field, ...props });

  const handleChange = (event) => {
    form.setFieldValue(
      field.name,
      type === "datetime-local"
        ? moment(event.target.value).format("YYYY-MM-DD HH:mm:ss")
        : event.target.value
    );
  };

  return (
    <Page
      {...rest}
      value={
        type === "datetime-local"
          ? moment(field.value).format("YYYY-MM-DDTHH:mm")
          : field.value
      }
      type={type}
      handleChange={handleChange}
    />
  );
}

DateTimeField.propTypes = {
  type: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default DateTimeField;
