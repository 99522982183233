import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetReviewPerformanceCompanyReport } from "store/actions/reports/review_performance_company/getReviewPerformanceReport";
import { actionClearReviewPerformanceCompanyReport } from "store/actions/reports/review_performance_company/clearReviewPerformanceReport";
import { actionClearReviewPerformanceCompanyFilters } from "store/actions/reports/review_performance_company/clearReviewPerformanceFilters";
import { actionSetReviewPerformanceCompanyFilters } from "store/actions/reports/review_performance_company/setReviewPerformanceFilters";
import { actionGenerateReviewPerformanceCompanyExcel } from "store/actions/reports/review_performance_company/generateExcelReviewPerformance";
import { actionGetVehiclesNoReviewCompany } from "store/actions/reports/review_performance_company/getVehiclesNoReview";
import { actionClearVehiclesNoReviewCompany } from "store/actions/reports/review_performance_company/clearVehiclesNoReview";
import { actionSetSelectedReviewPerformanceCompanyFilters } from "store/actions/reports/review_performance_company/setSelectedReviewPerformanceFilters";

import { useLang } from "hooks/lang";
import { useHistory } from "react-router-dom";
import { getCompanyViaWorkArea, getCorporateViaWorkArea } from "utils/workArea";

import Page from "./page";

function ReviewPerformanceCompany({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedCreatedAt, setSelectedCreatedAt] = useState("");
  const {
    account,
    workArea,
    report,
    vehicles,
    filter,
    selectedFilter,
    setNotification,
    getReviewPerformanceReport,
    clearReviewPerformanceReport,
    setReviewPerformanceFilters,
    clearReviewPerformanceFilters,
    setSelectedFilters,
    generateExcel,
    clearVehiclesNoReview,
    getVehiclesNoReview,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getReviewPerformanceReport({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }

    return () => {
      clearReviewPerformanceReport();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [report.data]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilter = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };

    setReviewPerformanceFilters(filter);
    setSelectedFilters(selectedFilter);

    return () => {
      clearReviewPerformanceFilters();
      clearVehiclesNoReview();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateReviewPerformanceExcel() {
    try {
      await generateExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleClickVehicle(vehicle) {
    history.push(`/vehicleReview/${vehicle.vehicle_id}/history`);
  }

  async function handleClickVehiclesNoReview(id, subsidiaryId) {
    try {
      clearVehiclesNoReview();
      await getVehiclesNoReview({ ...filter }, id, subsidiaryId);
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      data={report}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      selectedVehiclesNoReview={vehicles}
      handleClickVehiclesNoReview={handleClickVehiclesNoReview}
      handleGenerateReviewPerformanceExcel={
        handleGenerateReviewPerformanceExcel
      }
      handleClickVehicle={handleClickVehicle}
      selectedCreatedAt={selectedCreatedAt}
      setSelectedCreatedAt={setSelectedCreatedAt}
    />
  );
}
ReviewPerformanceCompany.propTypes = {};

const mapStateToProps = (state) => ({
  report: state.Reports.ReviewPerformanceCompany.report,
  vehicles: state.Reports.ReviewPerformanceCompany.vehicles,
  filter: state.Reports.ReviewPerformanceCompany.filter,
  selectedFilter: state.Reports.ReviewPerformanceCompany.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReviewPerformanceReport: actionGetReviewPerformanceCompanyReport(dispatch),
  clearReviewPerformanceReport:
    actionClearReviewPerformanceCompanyReport(dispatch),
  clearReviewPerformanceFilters:
    actionClearReviewPerformanceCompanyFilters(dispatch),
  setReviewPerformanceFilters:
    actionSetReviewPerformanceCompanyFilters(dispatch),
  generateExcel: actionGenerateReviewPerformanceCompanyExcel(dispatch),
  getVehiclesNoReview: actionGetVehiclesNoReviewCompany(dispatch),
  clearVehiclesNoReview: actionClearVehiclesNoReviewCompany(dispatch),
  setSelectedFilters:
    actionSetSelectedReviewPerformanceCompanyFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewPerformanceCompany);
