import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehiclesInProcess =
  (dispatch) =>
  async ({
    page,
    vehicle_type_id: vehicleTypeId,
    vehicle_brand_id: vehicleBrandId,
    driver_id: drivers,
    subsidiaries,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `vehicle/review/in/process`,
        params: {
          page,
          vehicle_type_id: vehicleTypeId,
          vehicle_brand_id: vehicleBrandId,
          drivers,
          subsidiaries,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
