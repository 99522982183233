import { SET_REVITALIZED_TIRE_MODEL_STATUS } from "store/actions";

import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionSetRevitalizedTireModelStatus =
  (dispatch) => async (revitalizedTireModelId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `revitalized/tire/model/${revitalizedTireModelId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_REVITALIZED_TIRE_MODEL_STATUS,
        payload: revitalizedTireModelId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
