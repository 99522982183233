export default {
  messages: {
    disable: "Are you sure to disable the subsidiary?",
    enable: "Are you sure to enable the subsidiary?",
  },
  buttons: {
    disabled_subsidiary: "Disabled subsidiary",
  },
  permits: {
    change_status: "No permissions to {action} subsidiaries",
  },
};
