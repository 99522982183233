export default {
  link_subsidiary: "Link subsidiary",
  permits: {
    unlink: {
      driver_subsidiary: "No permissions to unlink subsidiary from driver",
    },
    show: {
      driver_subsidiary: "No permissions to view subsidiary linked to driver",
    },
  },
  messages: {
    unlinked_driver_subsidiary:
      "Done, the subsidiary has been unlinked from the driver",
    linked_driver_subsidiary:
      "Done, the subsidiary has been linked to the driver",
  },
};
