import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  driver,
  drivers,
  subsidiaries,
  createDriverPermit,
  updateDriverPermit,
  deleteDriverPermit,
  filter,
  t,
  getDriver,
  addDriver,
  updateDriver,
  clearDriverFilters,
  setDriverFilters,
  deleteDriver,
  setDriverStatus,
  getDrivers,
  clearDrivers,
}) {
  return (
    <Home
      item={driver}
      name="driver"
      filter={filter}
      createTooltipPermit={
        !createDriverPermit ? t("_permits.create.driver") : ""
      }
      currentPage={drivers.current_page}
      deleteConfirmationMessage={t("_messages.delete.driver")}
      disableConfirmationMessage={t("_messages.change_status.driver.disable")}
      enableConfirmationMessage={t("_messages.change_status.driver.enable")}
      rows={drivers.data.map((driver) => {
        return {
          id: driver.driver_id,
          key: driver.driver_id,
          status: {
            status: driver.status,
            tooltipPermit: !updateDriverPermit
              ? t("_permits.update.driver")
              : "",
          },
          subsidiary: driver.subsidiary.name,
          name: `${driver.name}`,
          edit: {
            tooltipPermit: !updateDriverPermit
              ? t("_permits.update.driver")
              : "",
          },
          delete: {
            tooltipPermit: !deleteDriverPermit
              ? t("_permits.delete.driver")
              : "",
          },
        };
      })}
      title={t("_general.drivers")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "name", label: t("_labels.driver_name") },
        { id: "actions", label: "" },
      ]}
      lastPage={drivers.last_page}
      noItemsText={t("_general.no_drivers")}
      initialDefaultValues={{
        status: "",
        name: "",
        subsidiary_id: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
      ]}
      createFormTitle={t("_titles.new.driver")}
      updateFormTitle={t("_titles.update.driver")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.driver"),
        },
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
      }}
      createdMessage={t("_messages.created.driver")}
      updatedMessage={t("_messages.updated.driver")}
      getItemAction={getDriver}
      setFiltersAction={setDriverFilters}
      clearFiltersAction={clearDriverFilters}
      deleteAction={deleteDriver}
      setStatusAction={setDriverStatus}
      getItemsAction={getDrivers}
      clearItemsAction={clearDrivers}
      addAction={addDriver}
      updateAction={updateDriver}
    />
  );
}

Page.propTypes = {
  driver: PropTypes.object.isRequired,
  drivers: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  createDriverPermit: PropTypes.bool.isRequired,
  updateDriverPermit: PropTypes.bool.isRequired,
  deleteDriverPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getDriver: PropTypes.func.isRequired,
  addDriver: PropTypes.func.isRequired,
  updateDriver: PropTypes.func.isRequired,
  clearDriverFilters: PropTypes.func.isRequired,
  setDriverFilters: PropTypes.func.isRequired,
  deleteDriver: PropTypes.func.isRequired,
  setDriverStatus: PropTypes.func.isRequired,
  getDrivers: PropTypes.func.isRequired,
  clearDrivers: PropTypes.func.isRequired,
};

export default Page;
