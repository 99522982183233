import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function TruckPreview({ axles, size, onClickTire, onRotateTire }) {
  return (
    <Page
      axles={axles}
      size={size}
      onClickTire={onClickTire}
      onRotateTire={onRotateTire}
    />
  );
}

TruckPreview.propTypes = {
  size: PropTypes.oneOf(["sm"]).isRequired,
  axles: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.oneOf(["FRONTAL", "CENTRAL", "REAR", "REFECTION"])
        .isRequired,
      tire_quantity: PropTypes.number.isRequired,
      vehicle_type_axle_tire: PropTypes.array.isRequired,
    })
  ).isRequired,
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

export default TruckPreview;
