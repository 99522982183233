import React from "react";

import PropTypes from "prop-types";

import classnames from "classnames";

import Typography from "@material-ui/core/Typography";

import css from "./index.module.scss";

function Page({
  label,
  text,
  noWrap,
  noWrapLabel,
  className,
  fullWidth,
  gutterBottom,
  ...props
}) {
  return (
    <div
      {...props}
      className={classnames(
        {
          [css.fullWidth]: fullWidth,
          [css.mb8]: gutterBottom,
        },
        className,
        css.container
      )}
    >
      <Typography
        noWrap={noWrapLabel}
        variant="caption"
        color="textSecondary"
        display="block"
      >
        {label}
      </Typography>
      <Typography noWrap={noWrap} variant="body1">
        {text}
      </Typography>
    </div>
  );
}

Page.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
  noWrapLabel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gutterBottom: PropTypes.bool,
};

export default Page;
