export default {
  disabled_variation: "Disabled tire model variation",
  permits: {
    change_status: "No permissions to {action} tire model variations",
    change_approve: "No permissions to {action} tire model variation",
  },
  messages: {
    disable: "Are you sure to disable tire model variation?",
    enable: "Are you sure to enable tire model variation?",
    approve: "Are you sure to approve tire model variation?",
    disapprove: "Are you sure to disapprove tire model variation?",
  },
};
