import React from "react";

import PropTypes from "prop-types";

import Page from "./page";

function Pagination({ size, page, color, totalPages, onChangePage }) {
  return (
    <Page
      size={size}
      page={page}
      totalPages={totalPages}
      handleChangePage={onChangePage}
      color={color}
    />
  );
}

Pagination.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  page: PropTypes.number.isRequired,
  color: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default Pagination;
