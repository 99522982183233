import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import css from "./index.module.scss";

function CustomButton({ variant = "text", ...rest }) {
  switch (variant.toLowerCase()) {
    case "fab":
      return <Fab {...rest} />;
    case "icon":
      return <IconButton {...rest} />;
    default:
      return <Button {...rest} variant={variant} />;
  }
}

CustomButton.propTypes = {
  variant: PropTypes.string,
};

function Page({ className, classNameButton, tooltip, placement, ...rest }) {
  let button = (
    <CustomButton {...rest} className={classnames(classNameButton)} />
  );
  if (tooltip) {
    button = (
      <Tooltip title={tooltip} placement={placement || "bottom"}>
        <span className={classnames(css.span)}>{button}</span>
      </Tooltip>
    );
  }
  return <div className={classnames(css.container, className)}>{button}</div>;
}

Page.propTypes = {
  classNameButton: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
};

export default Page;
