import { UPDATE_DIVISION } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateDivision =
  (dispatch) => async (fields, divisionId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `division/${divisionId}`,
        data: fields,
      });

      dispatch({
        type: UPDATE_DIVISION,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
