import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddVehicleTypePressurePolicy } from "store/actions/association/policy/pressure/addVehicleTypePressurePolicy";
import { actionClearAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/clearAssociationPressureFilters";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function VehicleTypePressurePolicyForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    addPressurePolicy,
    clearPressurePoliciesFilters,
    setNotification,
    getVehiclesTypes,
  } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    resetFormik,
  } = useFormik({
    initialValues: {
      vehicle_type_id: "",
      axle_policy: [],
    },
  });

  const { id: associationId } = useParams();
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehiclesTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );
        setVehicleTypes(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = { ...values };
      await addPressurePolicy(associationId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.pressure_policy"),
      });

      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicleTypes={vehicleTypes}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

VehicleTypePressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehicles: PropTypes.array,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addPressurePolicy: actionAddVehicleTypePressurePolicy(dispatch),
  clearPressurePoliciesFilters:
    actionClearAssociationPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleTypePressurePolicyForm);
