import {
  GET_SUBSIDIARY_TIRE,
  CLEAR_SUBSIDIARY_TIRE,
  ASSIGN_TIRE_TO_SUBSIDIARY,
  GET_SUBSIDIARY_TIRES,
  CLEAR_SUBSIDIARY_TIRES,
  SET_SUBSIDIARY_TIRES_FILTER,
  CLEAR_SUBSIDIARY_TIRES_FILTER,
  DELETE_LINK_TIRE_WITH_SUBSIDIARY,
} from "store/actions";

const initialState = {
  tire: {
    subsidiary_id: 0,
    tire_id: 0,
    subsidiary: {
      name: "",
    },
    tire: {
      code: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
  },
  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_TIRE_TO_SUBSIDIARY:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: [action.payload, ...state.tires.data],
        },
      };

    case GET_SUBSIDIARY_TIRES:
      return {
        ...state,
        tires: action.payload,
      };

    case CLEAR_SUBSIDIARY_TIRES:
      return {
        ...state,
        tires: initialState.tires,
      };

    case GET_SUBSIDIARY_TIRE:
      return {
        ...state,
        tire: action.payload,
      };

    case CLEAR_SUBSIDIARY_TIRE:
      return {
        ...state,
        tire: initialState.tire,
      };

    case SET_SUBSIDIARY_TIRES_FILTER:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_SUBSIDIARY_TIRES_FILTER:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_LINK_TIRE_WITH_SUBSIDIARY:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: state.tires.data.filter(
            (tire) => tire.tire_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
