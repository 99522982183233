export default {
  filter: {
    status: {
      corporate: "Estatus del corporativo",
      brand: "Estatus de la marca",
      size: "Estatus de la medida",
      company: "Estatus de la empresa",
      division: "Estatus de la división",
      warehouse: "Estatus del almacén",
      subsidiary: "Estatus de la sucursal",
      association: "Estatus del transportista",
      provider: "Estatus del proveedor",
      wear: "Estatus del desgaste",
      driver: "Estatus del conductor",
      damage: "Estatus del tipo de daño",
      user: "Estatus del usuario",
      revitalized_tire_model: "Estatus del modelo de neumático revitalizado",
      alert: "Estatus de la alerta",
      retirement_cause: "Estatus de la causa de retiro",
      rfid: "Estatus de la rfid",
      gps: "Estatus del gps",
      tire_model: "Estatus del modelo de neumático",
      vehicle_type: "Estatus del tipo de vehículo",
      vehicle: "Estatus del vehículo",
      tpms: "Estatus del sensor tpms",
    },
  },
  update: {
    corporate: "Actualizar Corporativo",
    brand: "Actualizar Marca",
    size: "Actualizar Medida",
    company: "Actualizar Empresa",
    division: "Actualizar División",
    warehouse: "Actualizar Almacén",
    subsidiary: "Actualizar sucursal",
    association: "Actualizar Transportista",
    driver: "Actualizar conductor",
    tire_wear: "Actualizar desgaste del neumático",
    user_subsidiary: "Vínculo de usuario a sucursal",
    provider: "Actualizar proveedor",
    wear: "Actualizar desgaste",
    damage: "Actualizar tipo de daño",
    user: "Actualizar usuario",
    user_role: "Actualizar rol de usuario",
    revitalized_tire_model: "Actualizar el modelo de neumático revitalizado",
    alert: "Actualizar Alerta",
    retirement_cause: "Actualizar Causa de retiro",
    rfid: "Actualizar Rfid",
    gps: "Actualizar Gps",
    vehicle_type: "Actualizar Tipo de vehículo",
    company_policy: "Actualizar Políticas",
    tire_model: "Actualizar Modelo de neumático",
    vehicle: "Actualizar vehículo",
    policy: "Actualizar política",
    tire: "Actualizar neumático",
    tire_review: "Actualizar revisión del neumático",
    vehicle_review: "Actualizar revisión del vehículo",
    helmet_value: "Actualizar valor del casco",
    checklist: "Actualizar checklist",
    concept: "Actualizar concepto",
    block: "Actualizar bloque",
    tpms_sensor: "Actualizar sensor tpms",
  },
  link: {
    tire_with_division: "Vínculo de neumático con división",
    tire_with_rfid: "Vincular rfid a neumático",
    division: "Vincular división",
    vehicle_with_gps: "Vincular gps a vehículo",
    vehicle_with_driver: "Asignar conductor al vehículo {economic_number}",
    tpms_with_tire: "Vincular sensor tpms al neumático",
    tpms_with_vehicle: "Vincular sensores TPMS",
  },
  new: {
    corporate: "Nuevo Corporativo",
    brand: "Nueva Marca",
    size: "Nueva Medida",
    company: "Nueva Empresa",
    division: "Nueva División",
    warehouse: "Nuevo Almacén",
    subsidiary: "Nueva Sucursal",
    association: "Nuevo Transportista",
    alert: "Nueva Alerta",
    provider: "Nuevo Proveedor",
    wear: "Nuevo Desgaste",
    driver: "Nuevo Conductor",
    damage: "Nuevo Tipo de daño",
    user: "Nuevo usuario",
    user_role: "Nuevo rol de usuario",
    revitalized_tire_model: "Nuevo modelo de neumático revitalizado",
    alert: "Nueva Alerta",
    retirement_cause: "Nueva Causa de retiro",
    rfid: "Nuevo Rfid",
    gps: "Nuevo Gps",
    tire_model: "Nuevo Modelo de neumático",
    vehicle_type: "Nuevo Tipo de vehículo",
    vehicle: "Nuevo vehículo",
    policy: "Nueva política",
    tire: "Nuevo neumático",
    tire_review: "Nueva revisión de neumático",
    helmet_value: "Nuevo valor del casco",
    checklist: "Nuevo checklist",
    concept: "Nuevo concepto",
    block: "Nuevo bloque",
    notification: "Nueva notificación",
    tpms_sensor: "Nuevo sensor tpms",
  },
  show: {
    alert: "Alerta",
    link: {
      gps_with_vehicle: "Vínculo de vehículo con gps",
      gps_vehicle_link: "Vínculo de gps con vehículo",
      user_subsidiary: {
        with_user: "Vínculo de sucursal con usuario",
        with_subsidiary: "Vínculo de usuario con sucursal",
      },
      subsidiary_driver: {
        subsidiary: "Vínculo de sucursal con conductor",
        driver: "Vínculo de conductor con sucursal",
      },
    },
    tire: "Información del neumático",
    inspection: "Inspección",
    checklist: "Información del checklist",
  },
  history: {
    tire: {
      warehouse: "Sin historial de almacén",
    },
  },
  warehouse_tire: "Neumático almacenado",
  register: {
    vehicles: "Registrar vehículos por excel",
    tires: "Registrar neumáticos por excel",
    rfids: "Registrar rfids por excel",
    tpms: "Registrar sensores tpms por excel",
    pile: "Alta de neumáticos directo a pila",
  },
  policy: {
    no_condition_policy: "Sin políticas de condición",
  },
  send: {
    revitalization: "Enviar a Revitalización",
    repair: "Enviar a Reparación",
    pile: "Enviar a Pila de desecho",
    warehouse: "Enviar a Almacén",
    multiple_to_warehouse: "Enviar Neumáticos al Almacén",
  },
  mount: "Montar neumático en la posición {position}",
  vehicle_inspection: "Inspección del vehículo",
  vehicle_pressure_inspection: "Revisión de presiones al vehículo",
  vehicle_identify_inspection: "Revisión de identificación al vehículo",
  mount_alerts: "Alertas de montaje",
  move: {
    vehicle: "Trasladar vehículo",
    tire: "Trasladar neumático",
  },
  tire_performance: "Rendimiento de neumáticos",
  tire_stats_report: "Estadísticas de neumáticos",
  tire_stats_report_historic: "Estadísticas de neumáticos (Histórico)",
  pile_stats_report: "Estadísticas de pila",
  best_performance_report: "Mejores neumáticos por rendimiento",
  summary_report: "Resumen",
  damages_wears: "Daños y Desgastes",
  dynamic_cost_report: "Costo por modelo",
  tire_alerts: "Alertas del neumático",
  pile_summary_report: "Resumen de pila",
  pile_performance_report: "Rendimiento pila",
  pile_report: "Mm de Pila",
  semaphore_reports: "Semáforo",
  renewability_index_report: "Índice de renovabilidad",
  review_performance_report: "Desempeño de Neumáticos",
  mount_report: "Neumáticos instalados",
  report_warehouse: "Neumáticos almacenados",
  potential_savings_report: "Pérdidas potenciales",
  user_review_report: "Revisiones por usuario",
  km_projection_report: "Proyección de Km",
  purchase_report: "Compras sugeridas",
  km_pile_report: "Km de Pila",

  rotate: "Rotar neumático(s)",

  vehicle_summary:
    "Información de vehículos inspeccionados y totales de la flota, por tipo de vehículos",
  quantity_vehicle: "Cantidad de vehículos verificados reporte actual.",
  quantity_tire: "Cantidad de neumáticos verificados reporte actual.",
  total_vehicles: "Total de vehículos en la flotilla.",
  total_tires: "Total de neumáticos en la flotilla.",
  tire_info:
    "Información de llantas totales en la flota y las inspeccionadas, por tipo de aplicación y por ubicación",
  tires_by_application: "Por aplicación",
  tires_by_location: "Por ubicación",
  historic: "Histórico",
  semaphore_report: "Historial de neumáticos revisados",
  fleet_semaphore_report: "Neumáticos instalados en la flota",
  tire_revitalized_report: "Reporte de neumáticos revitalizados",
  summary_warehouse_report: "Reporte de neumáticos desmontados",
  summary_mount_report: "Reporte de montajes realizados",
  tire_repair_report: "Reporte de neumáticos reparados",

  graphs: {
    pile_by_responsible: "Pila de Desecho por Responsables",
    pile_by_responsible_cost: "Costos de Pila de Desecho por Responsables",
    original_vs_renewed: "Compra de neumáticos (Original vs Renovado)",
    yearly_savings: "Ahorros por año",
    vehicle_historic: "Revisiones de Vehículo",
    review_performance: "Desempeño de Neumáticos",
    cost_km: {
      main: "Costo por KM",
      cost: "Costo",
      km: "Kilómetros",
    },
  },
  retread_rejection: "Enviar al almacén por rechazo de revitalizado",
  historical_summary: "Resumen Histórico",
  current_summary: "Resumen Actual",
  review_performance_company_report: "Desempeño por empresa",
  completed_cycles_report: "Reporte de ciclos completados",
  bulk_pile: "Envió masivo a pila",
  tpms_alerts: "Alertas tpms",
  scheduled_withdrawal_report: "Reporte de retiro programado",
};
