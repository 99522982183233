export default {
  title: "New retirement cause",
  update: "Update retirement cause",
  messages: {
    retirement_cause_created:
      "Done, retirement cause has been successfully created",
    retirement_cause_updated:
      "Done, the retirement cause has been successfully updated",
    retirement_cause_deleted:
      "Done, the retirement cause has been successfully deleted",
    delete: "Are you sure to delete the retirement cause",
  },
  form: {
    permits: {
      update: "No permissions to update retirement causes",
      delete: "No permissions to delete retirement causes",
    },
  },
};
