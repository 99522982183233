import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddPressurePolicy } from "store/actions/association/policy/pressure/addVehiclePressurePolicy";
import { actionClearAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/clearAssociationPressureFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function PressurePolicyForm({ open, onClose, vehicle, ...rest }) {
  const t = useLang();
  const { addPressurePolicy, clearPressurePoliciesFilters, setNotification } =
    rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      axle_policy: [
        {
          axle_type: "",
          recommended_pressure: "",
          tolerance: "",
        },
      ],
    },
  });

  const { id: associationId } = useParams();
  // const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (open && Object.keys(vehicle).length > 0) {
      const validAxles = Object.keys(
        vehicle.vehicle_type.vehicle_type_axle.reduce((acc, val) => {
          if (!acc[val.axle_type]) {
            acc[val.axle_type] = true;
          }

          return acc;
        }, {})
      );

      const currentPolicies = Object.keys(
        vehicle.commissioned_vehicle_pressure_policy.reduce((acc, val) => {
          if (!acc[val.axle_type]) {
            acc[val.axle_type] = true;
          }

          return acc;
        }, {})
      );

      const axles = validAxles.filter(
        (axle) => !currentPolicies.includes(axle)
      );

      setInitialValues({
        axle_policy: axles.map((axle) => ({
          axle_type: axle,
          recommended_pressure: "",
          tolerance: "",
        })),
      });
    } else {
      resetFormik();
    }
  }, [open, vehicle]);

  // useEffect(() => {
  //   if (pressurePolicyId && pressurePolicy.vehicle_pressure_policy_id) {
  //     if (vehicles.length > 0) {
  //       setInitialValues({
  //         vehicle_id: pressurePolicy.vehicle.vehicle_id.toString(),
  //         axle_type: pressurePolicy.axle_type,
  //         recommended_pressure: pressurePolicy.recommended_pressure,
  //         tolerance: pressurePolicy.tolerance,
  //       });
  //     }
  //   } else {
  //     if (vehicles.length > 0) {
  //       setInitialValues({
  //         vehicle_id: vehicleId ? vehicleId.toString() : "",
  //         axle_type: "",
  //         recommended_pressure: "",
  //         tolerance: "",
  //       });
  //     }
  //   }
  // }, [vehicles, pressurePolicy, pressurePolicyId]);

  // useEffect(() => {
  //   setIsUpdate(pressurePolicyId ? true : false);
  // }, [pressurePolicyId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        vehicles: [
          { commissioned_vehicle_id: vehicle.commissioned_vehicle_id },
        ],
        axle_policy: values.axle_policy,
      };
      await addPressurePolicy(associationId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.pressure_policy"),
      });

      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicle={vehicle}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

PressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehicle: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addPressurePolicy: actionAddPressurePolicy(dispatch),
  clearPressurePoliciesFilters:
    actionClearAssociationPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PressurePolicyForm);
