import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form, FieldArray } from "formik";

import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  vehicleTypes,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        variant="filled"
        name="vehicle_type_id"
        className={classnames(css.field_full_width)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.vehicle_type.label")}
      >
        {vehicleTypes.map((vehicle, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicle.vehicle_type_id.toString()}
              disabled={!vehicle.status}
            >
              {vehicle.name}
            </MenuItem>
          );
        })}
      </Field>

      {values.axle_policy.length > 0 && (
        <Typography
          align="left"
          display="block"
          variant="overline"
          color="textSecondary"
          className={classnames(css.full_width, css.subtitle)}
        >
          {t("_labels.axle.plural")}
        </Typography>
      )}
      <FieldArray
        name="axle_policy"
        validateOnChange={false}
        render={() => (
          <>
            {values.axle_policy.map((_, index) => (
              <div key={`axle_${index}`} className={classnames(css.field_wrap)}>
                <div className={classnames(css.horizontal)}>
                  <Field
                    fullWidth
                    required
                    variant="filled"
                    name={`axle_policy[${index}].axle_type`}
                    className={classnames(css.field)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    component={SelectField}
                    label={t("_labels.axle_field.label")}
                    disabled
                  >
                    <MenuItem value="DIRECTIONAL">
                      {t("_labels.axle_field.options.directional")}
                    </MenuItem>
                    <MenuItem value="TRACTION">
                      {t("_labels.axle_field.options.traction")}
                    </MenuItem>
                    <MenuItem value="TRAILER">
                      {t("_labels.axle_field.options.trailer")}
                    </MenuItem>
                    <MenuItem value="REFECTION">
                      {t("_labels.axle_field.options.refection")}
                    </MenuItem>
                  </Field>
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    name={`axle_policy[${index}].recommended_pressure`}
                    component={TextField}
                    type="number"
                    className={classnames(css.field)}
                    label={t(
                      "_labels.tire_model_variation.recommended_pressure"
                    )}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classnames(css.adornment)}
                          position="start"
                        >
                          psi
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    name={`axle_policy[${index}].tolerance`}
                    component={TextField}
                    type="number"
                    className={classnames(css.field)}
                    label={t("_labels.tire_model_variation.tolerance")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                      endAdornment: (
                        <InputAdornment
                          className={classnames(css.adornment)}
                          position="start"
                        >
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
