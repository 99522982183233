import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSummaryMountReport } from "store/actions/reports/summary_mount/getSummaryMountReport";
import { actionSetSummaryMountReportFilters } from "store/actions/reports/summary_mount/setSummaryMountFilters";
import { actionSetSelectedSummaryMountReportFilters } from "store/actions/reports/summary_mount/setSelectedSummaryMountReportFilters";
import { actionClearSummaryMountReportFilters } from "store/actions/reports/summary_mount/clearSummaryMountFilters";
import { actionShowProgress } from "store/actions/general/progress";
import { http } from "store/actions/http";

import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { arrayJoin } from "utils/parse";

import Page from "./page";
import { actionGenerateSummaryMountExcel } from "store/actions/reports/summary_mount/generateSummaryMountExcel";

function SummaryMount({ ...rest }) {
  const t = useLang();
  const {
    account,
    workArea,
    filter,
    selectedFilter,
    setNotification,
    getSummaryMountReport,
    generateSummaryMountReportExcel,
    setSummaryMountReportFilters,
    setSelectedSummaryMountReportFilters,
    clearSummaryMountReportFilters,
    showProgress,
  } = rest;
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [detailsData, setDetailsData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const tableRef = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSummaryMountReport({ ...filter });
        setData(getData(response));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      setShowDetails(false);
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: arrayJoin(
        companies.map((company) => company.company.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
      subsidiaries: arrayJoin(
        subsidiaries.map((subsidiary) => subsidiary.subsidiary.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
      date_from: startDate,
      date_to: endDate,
    };
    setSummaryMountReportFilters(filter);
    setSelectedSummaryMountReportFilters(selectedFilters);
    return () => {
      clearSummaryMountReportFilters();
    };
  }, []);

  async function handleClickVehicle(vehicleType) {
    const progress = showProgress();

    const { corporate_id: corporate, companies, subsidiaries } = filter;
    const params = {
      corporate_id: corporate,
      companies,
      subsidiaries,
    };

    if (filter.date_from && filter.date_to) {
      params.date_from = filter.date_from;
      params.date_to = filter.date_to;
    }

    try {
      let response = await http({
        method: "GET",
        path: `report/mount/summary/history/${vehicleType["vehicle_type_id"]}`,
        params: {
          ...params,
        },
      });

      setChartData(getHistoricChart(response, t));

      response = await http({
        method: "GET",
        path: `report/mount/summary/${vehicleType["vehicle_type_id"]}`,
        params: {
          ...params,
        },
      });

      setDetailsData(response);
      setShowDetails(true);

      setSelectedVehicleType(vehicleType.name);

      if (tableRef.current) {
        tableRef.current.scroll({ behavior: "smooth", top: 0 });
      }
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateSummaryMountReportExcel() {
    try {
      await generateSummaryMountReportExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      data={data}
      chartData={chartData}
      detailsData={detailsData}
      showDetails={showDetails}
      tableRef={tableRef}
      filter={filter}
      selectedFilter={selectedFilter}
      handleClickVehicle={handleClickVehicle}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateSummaryMountReportExcel={
        handleGenerateSummaryMountReportExcel
      }
      selectedVehicleType={selectedVehicleType}
    />
  );
}

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.SummaryMount.filter,
  selectedFilter: state.Reports.SummaryMount.selected_filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSummaryMountReport: actionGetSummaryMountReport(dispatch),
  generateSummaryMountReportExcel: actionGenerateSummaryMountExcel(dispatch),
  setSummaryMountReportFilters: actionSetSummaryMountReportFilters(dispatch),
  setSelectedSummaryMountReportFilters:
    actionSetSelectedSummaryMountReportFilters(dispatch),
  clearSummaryMountReportFilters:
    actionClearSummaryMountReportFilters(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryMount);

function getData(data) {
  let result = [];

  if (Object.keys(data).length > 0) {
    result = Object.entries(data).map((entry) => {
      const [key, value] = entry;

      return [key, Object.entries(value)];
    });
  }
  return result;
}

function getHistoricChart(data, t) {
  return {
    datasets: [
      {
        label: t("_general.quantity_tire"),
        data: data.map((el) => ({
          ...el,
          fecha: moment(el.date).format("YYYY-MM"),
        })),
        yAxisID: "y",
        fill: false,
        pointWidth: 2,
        borderColor: "rgba(0, 40, 73, 0.85)",
        backgroundColor: "rgba(0, 40, 73, 0.85)",
        tension: 0.1,
      },
    ],
  };
}
