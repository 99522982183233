import {
  CREATE_VEHICLE_PRESSURE_POLICY,
  UPDATE_VEHICLE_PRESSURE_POLICY,
  DELETE_VEHICLE_PRESSURE_POLICY,
  GET_VEHICLE_PRESSURE_POLICY,
  GET_VEHICLE_PRESSURE_POLICIES,
  CLEAR_VEHICLE_PRESSURE_POLICIES,
  SET_VEHICLE_PRESSURE_POLICIES_FILTERS,
  CLEAR_VEHICLE_PRESSURE_POLICIES_FILTERS,
} from "store/actions";

const initialState = {
  pressure_policy: {
    subsidiary_id: 0,
    vehicle_id: 0,
    position: "",
    brand: {
      vehicle_brand_id: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
  },

  pressure_policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_PRESSURE_POLICY:
    case CREATE_VEHICLE_PRESSURE_POLICY:
    case UPDATE_VEHICLE_PRESSURE_POLICY:
      return {
        ...state,
        pressure_policy: action.payload,
      };

    case GET_VEHICLE_PRESSURE_POLICIES:
      return {
        ...state,
        pressure_policies: action.payload,
      };

    case CLEAR_VEHICLE_PRESSURE_POLICIES:
      return {
        ...state,
        pressure_policies: { ...initialState.pressure_policies },
      };

    case SET_VEHICLE_PRESSURE_POLICIES_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_VEHICLE_PRESSURE_POLICIES_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_VEHICLE_PRESSURE_POLICY:
      return {
        ...state,
        pressure_policies: {
          ...state.pressure_policies,
          data: state.pressure_policies.data.filter(
            (vehicleConditionPolicy) =>
              vehicleConditionPolicy.vehicle_pressure_policy_id !==
              action.payload
          ),
        },
      };

    default:
      return state;
  }
}
