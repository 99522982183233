import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTire } from "store/actions/tire/getTire";
import { actionClearTire } from "store/actions/tire/clearTire";
import { actionDeleteTirePile } from "store/actions/tire/pile/deleteTirePile";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";
import { actionDeleteTireRepair } from "store/actions/tire/repair/deleteTireRepair";
import { actionDeleteTireRevitalization } from "store/actions/tire/revitalization/deleteTireRevitalization";

import {
  CREATE_TIRE_PILE,
  CREATE_TIRE_REPAIR,
  CREATE_TIRE_REVITALIZATION,
  DELETE_TIRE_PILE,
  DELETE_TIRE_REPAIR,
  DELETE_TIRE_REVITALIZATION,
  UPDATE_TIRE_REVIEW,
} from "store/actions/account/permits";
import { LOCATIONS } from "utils/tire";
import { getSubsidiaryViaWorkArea } from "utils/workArea";

import Page from "./page";

export function InfoTireDialog({ open, ...rest }) {
  const t = useLang();
  const {
    permits,
    tire,
    tireId,
    getTire,
    setNotification,
    deleteTirePile,
    onClose,
    clearTireFilters,
    deleteTireRepair,
    deleteTireRevitalization,
    account,
    workArea,
  } = rest;

  const [openUpdateTireReviewForm, setOpenUpdateTireReviewForm] =
    useState(false);

  const subsidiary = getSubsidiaryViaWorkArea(account, workArea);
  const roles = subsidiary[0]?.role.map((r) => r.role.toUpperCase()) ?? [];
  const isOperator = roles.includes("OPERATOR");
  const policyAllowsEdit = !(
    isOperator && subsidiary[0]?.subsidiary.policy.operator_edit === 0
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(tireId);
      } catch (error) {
        setNotification(error, true);
        onClose();
      }
    }

    if (open && tireId) {
      fetchData();
    }
  }, [open]);

  function handleOpenUpdateTireReviewForm() {
    setOpenUpdateTireReviewForm(true);
  }

  function handleCloseUpdateTireReviewForm() {
    setOpenUpdateTireReviewForm(false);
  }

  async function cancelMovement() {
    try {
      if (tire.cycle.movement_tire.movement === LOCATIONS.PILE) {
        const res = await deleteTirePile({
          movementTireId: tire.cycle.movement_tire.movement_tire_id,
          tirePileId: tire.cycle.movement_tire.tire_pile[0].tire_pile_id,
        });
        setNotification({
          message: res.message,
        });
      }

      if (tire.cycle.movement_tire.movement === LOCATIONS.REPAIR) {
        const res = await deleteTireRepair({
          movementTireId: tire.cycle.movement_tire.movement_tire_id,
          tireRepairId: tire.cycle.movement_tire.tire_repair[0].tire_repair_id,
        });
        setNotification({
          message: res.message,
        });
      }

      if (tire.cycle.movement_tire.movement === LOCATIONS.REVITALIZATION) {
        const res = await deleteTireRevitalization({
          movementTireId: tire.cycle.movement_tire.movement_tire_id,
          tireRevitalizationId:
            tire.cycle.movement_tire.tire_revitalization[0]
              .tire_revitalization_id,
        });
        setNotification({
          message: res.message,
        });
      }
      onClose();
      clearTireFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleCancelMovement() {
    setNotification({
      textAction: t("_buttons.confirm"),
      onAction: cancelMovement,
      message: t("_messages.cancel_movement"),
    });
  }
  return (
    <Page
      {...rest}
      t={t}
      open={open}
      tire={{
        ...tire,
        created_by: t("_labels.actioned_by", {
          person: `${tire.created_by.name} ${tire.created_by.last_name_1} ${
            tire.created_by.last_name_2 || ""
          }`,
          date: moment(tire.created_at).format("LL"),
          time: moment(tire.created_at).format("h:mm a"),
        }),
        updated_by: tire.updated_by
          ? t("_labels.actioned_by", {
              person: `${tire.updated_by.name} ${tire.updated_by.last_name_1} ${
                tire.updated_by.last_name_2 || ""
              }`,
              date: moment(tire.updated_at).format("LL"),
              time: moment(tire.updated_at).format("h:mm a"),
            })
          : null,
      }}
      createTireRevitalizationPermit={permits.includes(
        CREATE_TIRE_REVITALIZATION
      )}
      createTireRepairPermit={permits.includes(CREATE_TIRE_REPAIR)}
      createTirePilePermit={permits.includes(CREATE_TIRE_PILE)}
      updateTireReviewPermit={permits.includes(UPDATE_TIRE_REVIEW)}
      deleteTirePilePermit={permits.includes(DELETE_TIRE_PILE)}
      deleteTireRepairPermit={permits.includes(DELETE_TIRE_REPAIR)}
      deleteTireRevitalizationPermit={permits.includes(
        DELETE_TIRE_REVITALIZATION
      )}
      openUpdateTireReviewForm={openUpdateTireReviewForm}
      handleOpenUpdateTireReviewForm={handleOpenUpdateTireReviewForm}
      handleCloseUpdateTireReviewForm={handleCloseUpdateTireReviewForm}
      handleCancelMovement={handleCancelMovement}
      policyAllowsEdit={policyAllowsEdit}
    />
  );
}

InfoTireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tireId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  handleOpenSendForm: PropTypes.func.isRequired,
  handleOpenDamagesForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  permits: state.Account.permits,
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTire: actionGetTire(dispatch),
  clearTire: actionClearTire(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
  deleteTirePile: actionDeleteTirePile(dispatch),
  deleteTireRepair: actionDeleteTireRepair(dispatch),
  deleteTireRevitalization: actionDeleteTireRevitalization(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoTireDialog);
