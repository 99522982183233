import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireWears } from "store/actions/tire/wear/getTireWears";
import { actionClearTireWears } from "store/actions/tire/wear/clearTireWears";

import Page from "./page";

export function TireWearTab({ ...rest }) {
  const t = useLang();
  const { id: tireId } = useParams();
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState(null);
  const [imageDialogTitle, setImageDialogTitle] = useState("");
  const { wears, getTireWears, clearTireWears, setNotification } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireWears(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearTireWears();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [wears.length]);

  function handleOpenImage(img, title) {
    setImage(img);
    setImageDialogTitle(title);
    setOpenImage(true);
  }

  function handleCloseImage() {
    setImage(null);
    setImageDialogTitle("");
    setOpenImage(false);
  }

  return (
    <Page
      t={t}
      wears={wears}
      image={image}
      imageDialogTitle={imageDialogTitle}
      openImage={openImage}
      handleOpenImage={handleOpenImage}
      handleCloseImage={handleCloseImage}
    />
  );
}

TireWearTab.propTypes = {};

const mapStateToProps = (state) => ({
  wears: state.Tire.Wear.wears,
});
const mapDispatchToProps = (dispatch) => ({
  getTireWears: actionGetTireWears(dispatch),
  clearTireWears: actionClearTireWears(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireWearTab);
