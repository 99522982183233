import React, { useState } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";

import css from "./index.module.scss";

import depthIcon from "resources/img/icons/neumaticos.png";
import pressureIcon from "resources/img/icons/presion.png";
import tiresIcon from "resources/img/icons/rueda.png";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
import { Zoom } from "@material-ui/core";
function Page({ t, data, handleClick }) {
  const [number, setNumber] = useState(1);
  return (
    <>
      <div>
        {data.map((summaryData, index) => (
          <div className="card" key={index}>
            <div className="card-header sMarcoInfoFondoAzul">
              <p className="m-0 h5">{`${t("_labels.summary")} ${
                summaryData.key
              }`}</p>
            </div>
            <div className="card-body">
              <div
                className={classnames({
                  [css.container]: true,
                })}
              >
                <Zoom in timeout={500}>
                  <div
                    className="d-flex flex-column justify-content-start alig-items-center text-center"
                    style={{ width: "47.5%" }}
                  >
                    <p
                      className="m-0 p-0 h5 d-flex justify-content-center align-items-center text-uppercase"
                      style={{ gap: "4px" }}
                    >
                      <img src={depthIcon} width={36} />
                      {t("_labels.depth_semaphore")}
                    </p>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div
                          className={classnames(
                            "sMarcoInfo w-100 text-center text-uppercase",
                            css.card
                          )}
                          style={{
                            backgroundColor: "#28B463",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick("depth_condition", "GOOD CONDITION")
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(
                              summaryData.good_depth.warehouse +
                                summaryData.good_depth.mount
                            )}
                            {" | "}
                            {getDepthPercentage(
                              summaryData,
                              summaryData.good_depth.warehouse +
                                summaryData.good_depth.mount
                            )}
                            %
                          </p>
                          <p className="sfcblanco m-0">
                            {t("_labels.good_condition")}
                          </p>
                          <small className="sfcblanco m-0">
                            {`${percentFormatter.format(
                              summaryData.good_depth.warehouse
                            )} ${t("_labels.location.options.warehouse")}`}{" "}
                            |{" "}
                            {`${percentFormatter.format(
                              summaryData.good_depth.mount
                            )} ${t(
                              "_labels.dynamic_cost_report.options.mount"
                            )}`}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div
                          className={classnames(
                            "sMarcoInfo w-100 text-center text-uppercase",
                            css.card
                          )}
                          style={{
                            backgroundColor: "goldenrod",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick(
                              "depth_condition",
                              "SCHEDULED WITHDRAWAL"
                            )
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(
                              summaryData.schedule_depth.warehouse +
                                summaryData.schedule_depth.mount
                            )}
                            {" | "}
                            {getDepthPercentage(
                              summaryData,
                              summaryData.schedule_depth.warehouse +
                                summaryData.schedule_depth.mount
                            )}
                            %
                          </p>
                          <p className="sfcblanco p m-0">
                            {t("_labels.scheduled_withdrawal")}
                          </p>
                          <small className="sfcblanco m-0">
                            {`${percentFormatter.format(
                              summaryData.schedule_depth.warehouse
                            )} ${t("_labels.location.options.warehouse")}`}{" "}
                            |{" "}
                            {`${percentFormatter.format(
                              summaryData.schedule_depth.mount
                            )} ${t(
                              "_labels.dynamic_cost_report.options.mount"
                            )}`}
                          </small>
                        </div>
                      </div>
                      <div className={"col-12 col-md-12"}>
                        <div
                          className={classnames(
                            "sMarcoInfo w-100 text-center text-uppercase",
                            css.card
                          )}
                          style={{
                            backgroundColor: "darkred",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick(
                              "depth_condition",
                              "CRITICAL WITHDRAWAL"
                            )
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(
                              summaryData.critical_depth.warehouse +
                                summaryData.critical_depth.mount
                            )}
                            {" | "}
                            {getDepthPercentage(
                              summaryData,
                              summaryData.critical_depth.warehouse +
                                summaryData.critical_depth.mount
                            )}
                            %
                          </p>
                          <p className="sfcblanco m-0">
                            {t("_labels.critical_withdrawal")}
                          </p>
                          <small className="sfcblanco m-0">
                            {`${percentFormatter.format(
                              summaryData.critical_depth.warehouse
                            )} ${t("_labels.location.options.warehouse")}`}{" "}
                            |{" "}
                            {`${percentFormatter.format(
                              summaryData.critical_depth.mount
                            )} ${t(
                              "_labels.dynamic_cost_report.options.mount"
                            )}`}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>

                <Zoom in timeout={500} onEntered={() => setNumber(number + 1)}>
                  <div
                    className="d-flex flex-column justify-content-start alig-items-center text-center"
                    style={{ width: "47.5%" }}
                  >
                    <p
                      className="m-0 p-0 h5 d-flex justify-content-center align-items-center text-uppercase"
                      style={{ gap: "4px" }}
                    >
                      <img src={pressureIcon} width={36} />
                      {t("_labels.pressure_semaphore")}
                    </p>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div
                          className="sMarcoInfo text-center text-uppercase"
                          style={{
                            backgroundColor: "#28B463",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick("pressure_condition", "NORMAL")
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(summaryData.good_pressure)}
                            {" | "}
                            {getPressurePercentage(
                              summaryData,
                              summaryData.good_pressure
                            )}
                            %
                          </p>
                          <p className="sfcblanco p m-0">
                            {t(
                              "_labels.summary_report.pressure_condition.normal"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div
                          className="sMarcoInfo text-center text-uppercase"
                          style={{
                            backgroundColor: "darkred",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick("pressure_condition", "HIGH")
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(summaryData.high_pressure)}
                            {" | "}
                            {getPressurePercentage(
                              summaryData,
                              summaryData.high_pressure
                            )}
                            %
                          </p>
                          <p className="sfcblanco p m-0">
                            {t(
                              "_labels.summary_report.pressure_condition.high"
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div
                          className="sMarcoInfo text-center text-uppercase"
                          style={{
                            backgroundColor: "#C0392B",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick("pressure_condition", "LOW")
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(summaryData.low_pressure)}
                            {" | "}
                            {getPressurePercentage(
                              summaryData,
                              summaryData.low_pressure
                            )}
                            %
                          </p>
                          <p className="sfcblanco p m-0">
                            {t("_labels.summary_report.pressure_condition.low")}
                          </p>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div
                          className="sMarcoInfo text-center text-uppercase"
                          style={{
                            backgroundColor: "#0d0d0d",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleClick("pressure_condition", "NO PRESSURE")
                          }
                        >
                          <p className="sfcblanco h5 m-0">
                            {percentFormatter.format(summaryData.no_pressure)}
                            {" | "}
                            {getPressurePercentage(
                              summaryData,
                              summaryData.no_pressure
                            )}
                            %
                          </p>
                          <p className="sfcblanco p m-0">
                            {t(
                              "_labels.summary_report.pressure_condition.no_pressure"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>

                <Zoom in={number > 1} timeout={1200}>
                  <div
                    className="d-flex flex-column justify-content-start alig-items-center text-center"
                    style={{ width: "47.5%" }}
                  >
                    <p
                      className="m-0 p-0 h5 d-flex flex-row justify-content-center align-items-center text-uppercase"
                      style={{ gap: "4px" }}
                    >
                      <img src={tiresIcon} width={36} />
                      {t("_labels.tires_by_application")}
                    </p>
                    <div className="row">
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(summaryData.all_position)}
                          </h3>
                          <small className="sinmp">
                            {t(`_labels.tire_application.options.all_position`)}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(summaryData.traction)}
                          </h3>
                          <small className="sinmp">
                            {t(`_labels.tire_application.options.traction`)}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(summaryData.trailer)}
                          </h3>
                          <small className="sinmp">
                            {t(`_labels.tire_application.options.trailer`)}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(summaryData.directional)}
                          </h3>
                          <small className="sinmp">
                            {t(`_labels.tire_application.options.directional`)}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>

                <Zoom in={number > 1} timeout={1200}>
                  <div
                    className="d-flex flex-column justify-content-start alig-items-center text-center"
                    style={{ width: "47.5%" }}
                  >
                    <p
                      className="m-0 p-0 h5 d-flex flex-row justify-content-center align-items-center text-uppercase"
                      style={{ gap: "4px" }}
                    >
                      <img src={tiresIcon} width={36} />
                      {t("_labels.tires_by_condition")}
                    </p>
                    <div className="row d-flex flex-wrap justify-content-center">
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(
                              summaryData.original_tires
                            )}
                          </h3>
                          <small className="sinmp">
                            {t("_labels.original_tires")}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(
                              summaryData.revitalized_tires
                            )}
                          </h3>
                          <small className="sinmp">
                            {t("_labels.revitalized_tires")}
                          </small>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 text-center pdb20">
                        <div
                          className="sMarcoInfo text-uppercase text-white"
                          style={{ backgroundColor: "#2E86C1" }}
                        >
                          <h3 className="sinmp">
                            {percentFormatter.format(summaryData.tires_fleet)}
                          </h3>
                          <small className="sinmp">{t("_labels.total")}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
};

export default Page;

function getDepthPercentage(summaryData, relative) {
  const total =
    summaryData.good_depth.warehouse +
    summaryData.good_depth.mount +
    summaryData.schedule_depth.warehouse +
    summaryData.schedule_depth.mount +
    summaryData.critical_depth.warehouse +
    summaryData.critical_depth.mount;
  return percentFormatter.format((relative / total) * 100);
}

function getPressurePercentage(summaryData, relative) {
  const total =
    summaryData.good_pressure +
    summaryData.low_pressure +
    summaryData.high_pressure +
    summaryData.no_pressure;
  return percentFormatter.format((relative / total) * 100);
}
