export default {
  name: {
    unclassifiable_condition: "unclassifiable condition",
    retread_reject: "retread reject",
    valve_damaged: "valve damaged",
    tread_depth: "tread depth",
    generic_damage: "Generic damage",
    alternate_lug_wear: "alternate lug wear",
    bad_bead_repair: "bad bead repair",
    bad_spot_repair: "bad spot repair",
    bead_area_flow_crack: "bead area flow crack",
    bead_damage_from_curbing: "bead damage from curbing",
    bead_deformation: "bead deformation",
    belt_lift_separation: "belt lift separation",
    belt_separation_repair_related: "belt separation repair related",
    bias_repair_in_radial_tire: "bias repair in radial tire",
    bond_line_porosity: "bond line porosity",
    brake_skid_damage: "brake skid damage",
    brake_skid_flat_spot_wear: "brake skid flat spot wear",
    brake_skid_flat_spot_wear_trailer: "brake skid flat spot wear trailer",
    branding_damage: "branding damage",
    bridged_repair: "bridged repair",
    buckled_tread: "buckled tread",
    burned_bead: "burned bead",
    chain_damage: "chain damage",
    chemical_damage: "chemical damage",
    chipping_flaking_chunking_tread: "chipping flaking chunking tread",
    circumferential_fatigue_rupture_zipper:
      "circumferential fatigue rupture zipper",
    cupping_scallop_wear: "cupping scallop wear",
    cuts_snags: "cuts and snags",
    damage_induced_sidewall_separation: "damage induced sidewall separation",
    defense_groove_tearing: "defense groove tearing",
    delamination: "delamination",
    diagonal_cracking: "diagonal cracking",
    diagonal_wear: "diagonal wear steer",
    diagonal_wear_trailer: "diagonal wear trailer",
    dynamometer_type_damage: "dynamometer type damage",
    eccentric_out_of_round_wear: "eccentric out of round wear",
    electrical_discharge: "electrical discharge",
    erosion_river_channel_wear: "erosion river channel wear steer",
    erosion_river_channel_wear_trailer: "erosion river channel wear trailer",
    erratic_depression_wear: "erratic depression wear steer",
    erratic_depression_wear_trailer: "erratic depression wear trailer",
    excessive_wear: "excessive wear",
    failed_inner_liner_repair: "failed inner liner repair",
    failed_repair_from_underinflation: "failed repair from underinflation",
    failed_repair_injury_not_removed: "failed repair injury not removed",
    feather_wear: "feather wear",
    foreign_object_inner_liner_damage_in_tubeless_tire:
      "foreign object inner liner damage in tubeless tire",
    forklift_damage: "forklift damage crown",
    forklift_damage_cuts_snags: "forklift damage cuts snags sidewall",
    full_shoulder_wear: "full shoulder wear",
    groove_cracking: "groove cracking",
    heel_toe_wear: "heel toe wear",
    impact_break: "impact break sidewall",
    impact_breaks: "impact breaks crown",
    improper_nail_hole_repair: "improper nail hole repair",
    improper_tread_width: "improper tread width",
    improperly_aligned_repair: "improperly aligned repair",
    inner_liner_bubbles_blisters_and_separations:
      "inner liner bubbles blisters and separations",
    inner_liner_cracking: "inner liner cracking",
    kinked_distorted_bead: "kinked/distorted bead",
    lug_base_cracking: "lug base cracking",
    lug_base_cracking_tearing: "lug base cracking tearing",
    missed_puncture: "missed puncture",
    multiple_flat_spotting_wear: "multiple flat spotting wear",
    on_the_wheel_repair: "on the wheel repair",
    one_sided_wear: "one sided wear steer",
    one_sided_wear_trailer: "one sided wear trailer",
    open_inner_liner_splice: "open inner liner splice",
    open_sidewall_splice: "open sidewall splice",
    open_tread_splice: "open tread splice",
    overall_fast_wear: "overall fast wear steer",
    overall_fast_wear_drive: "overall fast wear drive",
    penetrating_objects: "penetrating objects",
    penetrations_and_road_hazards: "penetrations and road hazards",
    petro_lubricant_damage: "petro lubricant damage",
    petroleum_product_damage: "petroleum product damage",
    pinch_shock: "pinch shock",
    pulled_loose_cords: "pulled loose cords",
    radial_split: "radial split",
    rapid_shoulder_wear_both_shoulders: "rapid shoulder wear both shoulders",
    rapid_shoulder_wear_one_shoulder: "rapid shoulder wear one shoulder",
    regrooving_damage: "regrooving damage",
    reinforce_chafer_separation: "reinforce chafer separation",
    repair_related_bulge: "repair related bulge",
    rib_depression_punch_wear: "rib depression punch wear steer",
    rib_depression_punch_wear_trailer: "rib depression punch wear trailer",
    rib_tearing: "rib tearing",
    run_flat: "run flat inner",
    run_flat: "run flat any",
    shoulder_scrubbing_scuffing: "shoulder scrubbing scuffing",
    shoulder_step_chamfer_wear: "shoulder step chamfer wear steer",
    shoulder_step_chamfer_wear_drive: "shoulder step chamfer wear drive",
    sidewall_abrasion_scuff_damage: "sidewall abrasion scuff damage",
    sidewall_bumps_blisters: "sidewall bumps blisters",
    sidewall_penetration: "sidewall penetration",
    sidewall_separation: "sidewall separation",
    skive_failure: "skive failure",
    spin_damage: "spin damage",
    spot_repair_should_be_a_section_repair:
      "spot repair should be a section repair",
    spread_damaged_cord: "spread damaged cord",
    stone_drilling: "stone drilling",
    tearing_mount_dismount_damage: "tearing mount dismount damage",
    torn_bead: "torn bead",
    tread_chunking: "tread chunking",
    tread_chunking_at_splice: "tread chunking at splice",
    tread_edge_lifting: "tread edge lifting",
    tread_lift_separation: "tread lift separation",
    tread_separation: "tread separation",
    tread_separation_repair_related: "tread separation repair related",
    tread_surface_porosity: "tread surface porosity",
    unfilled_nail_hole_repair: "unfilled nail hole repair",
    vehicle_damage: "vehicle damage",
    vehicle_equipment_damage: "vehicle equipment damage",
    weathering: "weathering",
    wild_wire: "wild wire",
    wing_lift: "wing lift",
  },
  appearance: {
    generic_damage_appearance: "Generic damage",
    tread_depth_appearance: "tread depth",
    run_flat_appearance_any_area:
      "Advanced deterioration of radial tire affecting 360° of tire sidewall.",
    torn_bead_appearance:
      "Bead toe rubber is torn or cut exposing the wire or fabric.",
    kinked_distorted_bead_appearance:
      "Local areas of distortion in the bead seat area.",
    bead_deformation_appearance:
      "Circumferential indentation of the bead area on the tire",
    burned_bead_appearance:
      "Rough, brittle, distorted and/or discolored hard surface in the bead area.",
    reinforce_chafer_separation_appearance:
      "Circumferential cracks above bead flange area with cord material exposed.",
    petro_lubricant_damage_appearance:
      "Rubber exhibits blistering, swelling, or spongy condition in the bead area. In later stages the bead may appear dry and brittle. Petroleum odor may be evident.",
    bead_damage_from_curbing_appearance:
      "Localized rippling or waviness in the bead flange area with no exposed cord.",
    bead_area_flow_crack_appearance:
      "Circumferential crack above the bead with no wire exposed.",
    spread_damaged_cord_appearance:
      "Radial, pencil shaped bulge in the sidewall of a radial tire.",
    cuts_snags_appearance: "Scrapes, gouges or cuts in the sidewall.",
    sidewall_separation_appearance:
      "Irregular shaped bulge in mid/upper sidewall area; may progress to complete separation of sidewall rubber from the casing exposing the ply cords. No repairs, breaks or punctures in the casing are evident.",
    chain_damage_appearance:
      "Numerous pock marks around the tire on the mid to upper sidewall and crown area.",
    vehicle_equipment_damage_appearance:
      "Uniform scuffing or cutting on some major portion of the tire´s, outer surface, often extending 360°",
    damage_induced_sidewall_separation_appearance:
      "Irregular shaped bulge in the mid/upper sidewall area; may progress to complete separation of the sidewall rubber from the casing, exposing the ply cords. Breaks in the liner, a puncture or damage to the casing is evident.",
    sidewall_abrasion_scuff_damage_appearance:
      "Abrasion on large areas of the sidewall, often 360˚ around the tire.",
    weathering_appearance:
      "Numerous tiny cracks in the rubber surface, most often 360˚ around the tire.",
    impact_break_appearance:
      "Break in the sidewall through the casing. This condition will usually deteriorate quickly to a radial run flat appearance.",
    branding_damage_appearance:
      "Stress cracks extending from the characters branded into the tire after manufacture.",
    diagonal_cracking_appearance:
      "Diagonal crack or cracks in the upper sidewall area of tires on drive and trailer axles.",
    petroleum_product_damage_appearance:
      "Sidewalls appear swollen, soft and spongy. In extreme cases, sidewalls may be undulated or distorted. Petroleum odor may be evident.",
    forklift_damage_appearance:
      "Cut similar to impact damage on a brand new tire.",
    circumferential_fatigue_rupture_zipper_appearance:
      "A circumferential break in the mid to upper sidewall exposing an even line of broken cords.",
    open_sidewall_splice_appearance:
      "Regular smooth opening of the top layer of sidewall rubber that may appear radially or diagonally. Appearance is similar to a cut, however, the opening extends at a sharp angle into the sidewall rubber. No cords are exposed.",
    sidewall_bumps_blisters_appearance:
      "Small, raised, scattered areas in the upper sidewall that can be felt when rubbing the casing. In later stages, the sidewall may appear blistered and can lead to a sidewall rupture.",
    sidewall_penetration_appearance:
      "Any damage caused by an object that goes through the sidewall of the tire.",
    radial_split_appearance:
      "A vertical break through the sidewall occurring between two body ply cables, that does not break the cables.",
    penetrations_and_road_hazards_appearance:
      "Evidence of a puncture or damage by a foreign object through the crown area; may result in a separation.",
    vehicle_damage_appearance:
      "Cut in tread or shoulder area, usually 360˚ around tire.",
    forklift_damage_cuts_snags_appearance:
      "Individual or multiple cuts, usually in the tread grooves in a localized area of the crown.",
    belt_lift_separation_appearance:
      "A bulge or split through the upper sidewall/shoulder area. Probing will reveal the belts sometimes accompanied by a worn spot in the tread.",
    tread_lift_separation_appearance:
      "Bulge on shoulder or tread face area may be accompanied by split through the bottom of the tread groove. Usually localized wear in the tread above the separated area will occur. May result in a loss of a section of tread. Belt package is intact.",
    brake_skid_damage_appearance:
      "Localized spot of excessive wear across the tread face showing abrasion marks from the tread sliding on the road surface; damage may extend into the casing.",
    tread_chunking_appearance: "Sections of tread torn from the tire.",
    lug_base_cracking_appearance:
      "One or more cracks in the tread rubber located between and at the base of lugs in the shoulder area.",
    wild_wire_appearance:
      "Wire protruding through the exterior or interior surface of the tire.",
    impact_breaks_appearance:
      "Localized break through the tread rubber and the casing.",
    chipping_flaking_chunking_tread_appearance:
      "Rough, abraded tread surface with numerous small flakes or chunks of tread removed.",
    stone_drilling_appearance:
      "Damage caused by stones trapped in the tread which penetrate the tread base and extend into the belts.",
    regrooving_damage_appearance: "Exposed belts at the base of the regroove.",
    dynamometer_type_damage_appearance:
      "Swollen, spongy area hidden inside the center rib/lug extending up to 360˚ around the tire. Can cause a flat wear spot or may surface as a localized cavity",
    chemical_damage_appearance:
      "Localized spot of blistered, spongy or deteriorated rubber in tread area.",
    excessive_wear_appearance:
      "Tire worn to point of exposing casing reinforcement material.",
    rib_tearing_appearance:
      "Tear at the base of the main tread grooves, generally at the shoulder; no tread missing",
    defense_groove_tearing_appearance:
      "Tear at the base of the defense groove (decoupling groove, stress relief groove, shoulder groove).",
    groove_cracking_appearance: "Areas at the base of the grooves with cracks.",
    spin_damage_appearance: "Circumferential cuts or lines around tire.",
    penetrating_objects_appearance:
      "Foreign object protruding through the interior surface of the tire.",
    open_inner_liner_splice_appearance:
      "Split in the rubber of the tire inner liner at the juncture of the inner liner material.",
    inner_liner_bubbles_blisters_and_separations_appearance:
      "Bubble or blister in the tire interior.",
    inner_liner_cracking_appearance:
      "One or more cracks in the inner liner at locations other than the inner liner splice.",
    pulled_loose_cords_appearance:
      "Depression or elevation in the surface of the tire interior along the radial cord path.",
    tearing_mount_dismount_damage_appearance:
      "Tearing or loss of adhesion of the inner liner material inside the bead toe area.",
    foreign_object_inner_liner_damage_in_tubeless_tire_appearance:
      "A series of random cuts or abrasion marks around the circumference of the tire interior, not otherwise explained.",
    run_flat_appearance:
      "Discoloration, blistering and/or separations of the inner liner.",
    pinch_shock_appearance:
      "A horizontal, crescent shaped crease or break in the inner liner in the sidewall area. A small bulge may also appear in the damaged area.",
    impact_break_appearance:
      "Crack or break in tire interior surface. May be accompanied by pulled or loose cords.",
    electrical_discharge_appearance:
      "Random oriented cracking on tire, sometimes associated with holes or punctures not otherwise explained.",
    bad_spot_repair_appearance:
      "The repair fill-rubber is missing or loose in the sidewall or tread area and sometimes exhibits cracks.",
    spot_repair_should_be_a_section_repair_appearance:
      "The repair fill-rubber is missing or loose from a sidewall spot repair. Ply cords may be exposed. A distortion or separation is evident inside the tire.",
    improper_nail_hole_repair_appearance:
      "Interior: A crack or split extends from the repair unit. The repair unit may be dimpled, cracked or loose. Some signs of heat build up may be evident such as powdery or sticky rubber. Exterior: Separation of tire components may be evident in advanced stages.",
    improperly_aligned_repair_appearance:
      "Interior: The repair unit may be dimpled, lifted or cracked. The repair unit alignment arrows do not point in the proper direction. Exterior: Tire components may be separated",
    unfilled_nail_hole_repair_appearance:
      "Interior: The repair unit is dimpled or cracked. Exterior: Rust is evident in the unfilled hole.",
    bridged_repair_appearance:
      "Interior: The repair unit is loose, split or torn in the tire´s shoulder area. A bulge, polished or gooey area caused by excessive heat may be evident under the repair unit. The fill material may also be cracked. Exterior: A sidewall bulge may be evident and the fill material may be cracked. Tire components may be separated.",
    on_the_wheel_repair_appearance:
      "Interior: A rubber-coated cord or a cured rubber plug is used to fill the hole with no repair unit covering the injury. Cracks or separation around the hole may be evident. Further damage from penetrating objects may also be evident. Exterior: The tread or tire components may be separated.",
    bad_bead_repair_appearance:
      "Interior: The inner liner may be separated and the bead area may exhibit exposed fabric or steel, cracking, loose rubber and/or improper bead contour. Exterior: Separation of the tire components may be evident.",
    failed_repair_injury_not_removed_appearance:
      "Interior: A split/crack extends from under the repair unit. The repair unit may also be torn loose or be missing. Exterior: Tire components may be separated leaving rusty, loose cords.",
    bias_repair_in_radial_tire_appearance:
      "Interior: The repair unit is cracked or distorted. Bias repair units may be round or 'X' shaped and are not labeled 'Radial' (All radial, reinforced tire repair units are marked 'Radial'.) Exterior: Tire components may be separated.",
    bond_line_porosity_appearance:
      "Large sections, if not all, of tread has separated from the casing; porosity is evident. This appears as a sponge-like surface that is frequently tacky.",
    tread_separation_appearance:
      "A portion of tread rubber only, located in any area of the tire, lifts and separates from the buffed surface of the tire body.",
    tread_chunking_at_splice_appearance:
      "Portion(s) of tread are missing in the area of the tread splice in a pre cured retread.",
    tread_separation_repair_related_appearance:
      "A portion of the tread is separated from the buffed surface. A repair is evident in the area of the separated tread.",
    belt_separation_repair_related_appearance:
      "Exterior: The tread and one or more belts is loose or missing from a portion or from the whole tire. A repair or skive is evident in the area of the separation. Interior: The repair unit may be sunk, dimpled, or cracked.",
    missed_puncture_appearance:
      "A portion of the tread is separated from the casing at the buff line in the area of the un repaired puncture. This usually occurs soon after retreading.",
    tread_edge_lifting_appearance:
      "The tread is worn to excess on one shoulder of the tire; the edge of the tread is loose and exhibits more wear in the area of separation.",
    failed_inner_liner_repair_appearance:
      "Interior: A crack extends from the repair rubber. Buff marks may be visible around the repair rubber. The repaired area may be coated with liner sealer. Exterior: Tire components may be separated.",
    lug_base_cracking_tearing_appearance:
      "Cracking is evident at the base of the tread lugs. Some lugs may be torn from the tread in severe cases.",
    improper_tread_width_appearance:
      "A crack appears at the tread edge which can extend into the belt. The tread edge may lift off the casing.",
    open_tread_splice_appearance: "A space is evident between the tread ends.",
    skive_failure_appearance:
      "A bulge or excessive wear in the tread area, or area where the tread is missing at a skive.",
    repair_related_bulge_appearance:
      "An excessive bulge in the mid/upper sidewall which may progress to a complete separation of the sidewall rubber from the casing. A slight bulge associated with a repair which can be identified with a blue triangular label is normal as the cables reposition themselves after repair. The bulge must not exceed 3/8in.",
    buckled_tread_appearance:
      "Undulations are evident in the tread surface and interior of a mold cured retread.",
    delamination_appearance: "Evidence of layers of rubber in the tread.",
    tread_surface_porosity_appearance:
      "A spongy appearance in the surface of the tread. Portions of the tread may be missing.",
    wing_lift_appearance:
      "The sidewall shoulder rubber of the retread is loose.",
    failed_repair_from_underinflation_appearance:
      "Cracks in the repair unit, cracks emanating from the repair unit, loose edges under the repair unit, tacky surface around the repaired area or under the repair unit.",
    shoulder_step_chamfer_wear_appearance:
      "Even tread wear in center with steps worn in shoulders. This is confined to the outer portion of the shoulder rib. Can be wider on one shoulder than the other.",
    full_shoulder_wear_appearance:
      "Excessive wear extending across entire shoulder rib to a major tread groove, usually on one side of tire only.",
    feather_wear_appearance:
      "Tread ribs worn so that one side of rib is higher than the other resulting in step-offs across the tread face. Generally, all ribs exhibit this wear.",
    erosion_river_channel_wear_appearance:
      "Circumferential wear along rib edges next to major tread grooves may be wavy in appearance and vary in width around tire.",
    cupping_scallop_wear_appearance:
      "Localized dished out areas of fast wear creating a scalloped appearance around tire. Appears around the tire on the shoulder ribs. May progress to adjoining ribs.",
    one_sided_wear_appearance:
      "Excessive wear on one side of tire extending from the shoulder towards the center of the tread.",
    diagonal_wear_appearance:
      "Localized flat spots worn diagonally across the tread, often repeating around tread circumference.",
    eccentric_out_of_round_wear_appearance:
      "Considerable difference in tread depth between 90 ̊ and 180 ̊ apart.",
    overall_fast_wear_appearance: "Good wear pattern but fast rate of wear.",
    rib_depression_punch_wear_appearance:
      "One or more inner ribs worn below the level of the adjacent ribs circumferentially around the tire.",
    erratic_depression_wear_appearance:
      "Random, erratic wear around tire circumference.",
    shoulder_step_chamfer_wear_drive_appearance:
      "Tire worn on edge of shoulder. This is confined to the outer portion of the shoulder. Can be wider on one shoulder than the other. This condition usually appears first on the inside shoulder of the inside dual of the front drive axle.",
    heel_toe_wear_appearance:
      "Each lug around tire worn high to low from front to back edge.",
    alternate_lug_wear_appearance:
      "Alternate lugs worn to different tread depths around tire circumference. May be every second lug, every third, etc... or a combination thereof.",
    brake_skid_flat_spot_wear_appearance:
      "Flat spot on tread surface. Surface texture may show abrasion marks from tread sliding on road surface, but surface may have since worn smooth. Often exhibited on more than one tire position on an axle.",
    overall_fast_wear_drive_appearance:
      "Uniform wear pattern, but fast rate of wear.",
    brake_skid_flat_spot_wear_trailer_appearance:
      "Localized spot of excessive wear across the tread face. Surface texture may show circumferential abrasion marks from tread sliding on road surface, but surface may have since worn smooth. Usually exhibited on both tires in a dual assembly.",
    diagonal_wear_trailer_appearance:
      "Localized flat spots worn diagonally across the tread at approximately 25 ̊ -35 ̊ angles often repeating around tread circumference.",
    multiple_flat_spotting_wear_appearance:
      "Numerous areas worn flat around circumference or tread.",
    rapid_shoulder_wear_one_shoulder_appearance:
      "Tire worn on edge of one shoulder sometimes extending to inner ribs. Can progress to diagonal wipeout.",
    shoulder_scrubbing_scuffing_appearance:
      "Abrasion on upper tire sidewall near tread edge. Can be accompanied by cracks in tread shoulder area.",
    rapid_shoulder_wear_both_shoulders_appearance:
      "Full rib wear on both shoulders.",
    erratic_depression_wear_trailer_appearance:
      "Random, erratic wear around tire circumference.",
    one_sided_wear_trailer_appearance: "Excessive wear on one side of tire.",
    erosion_river_channel_wear_trailer_appearance:
      "Localized wear along inside edges of tread ribs which may be wavy in appearance and vary in width around the tire.",
    rib_depression_punch_wear_trailer_appearance:
      "One or more inner ribs worn below the level of the adjacent rib around the tire´s circumference.",
  },
  probable_causes: {
    torn_bead_probable_causes:
      "Poor mounting/demounting techniques with tire tools and/or poor lubrication, forklift damage and poor tire handling procedures.",
    kinked_distorted_bead_probable_causes:
      "Improper use of tools, or incorrect techniques used when mounting the tire; shipping/handling damage.",
    bead_deformation_probable_causes:
      "Rusty rims, improper bead/rim lubrication, and bent or damaged rims which result in improper bead seating. Excessive heat in the bead area.",
    burned_bead_probable_causes:
      "Excessive heat exposure caused by frequent hard braking; improperly adjusted brakes; faulty braking system; insufficient air flow around the brakes.",
    reinforce_chafer_separation_probable_causes:
      "Improper bead seating, improper rim size, overload condition, underinflation, impact or possible manufacturing condition.",
    petro_lubricant_damage_probable_causes:
      "Use of petroleum-based products such as a lubricant, i.e., oil, diesel fuel and antifreeze.",
    bead_damage_from_curbing_probable_causes: "Curbing.",
    bead_area_flow_crack_probable_causes:
      "Improper rubber flow during the manufacturing process.",
    spread_damaged_cord_probable_causes:
      "Wider than normal wire spacing. A tire with a repair may show this condition soon after being repaired, or the condition may arise somewhere along a body ply that has been damaged — not necessarily in the repair area.",
    cuts_snags_probable_causes:
      "Road hazard, curbing, equipment damage, wash rack rails, pit rails, vandalism, etc.",
    sidewall_separation_probable_causes:
      "Loss of adhesion between the sidewall rubber and the body ply.",
    chain_damage_probable_causes:
      "Chain abrasion often due to loose or improperly sized chains or extended chain use on dry surfaces.",
    vehicle_equipment_damage_probable_causes:
      "Contact with vehicle hardware, such as loose U-bolts, slipped spring clips, restraining bolts, loose fenders, flap hangers and trailer wheel house molding, etc. Can also be caused by objects trapped between the duals.",
    damage_induced_sidewall_separation_probable_causes:
      "A puncture, impact, inner liner damage or bead damage.",
    sidewall_abrasion_scuff_damage_probable_causes:
      "Rubbing against curb or guide rails. Most often seen in city delivery service.",
    weathering_probable_causes:
      "Rubber surface exposure to environmental elements. Aggravated by long periods of parking and high concentrations of ozone.",
    impact_break_probable_causes:
      "Caused by a sudden impact with a road hazard or a chuck hole. Aggravated by over inflation.",
    branding_damage_probable_causes:
      "Caused by branding too deep, in the wrong location on the tire sidewall, at too high a temperature, or at a sharp angle.",
    diagonal_cracking_probable_causes:
      "Frequently can be a result of torque transfer. Aggravated by underinflation. This condition should not be confused with cut(s) from sharp curb edges, road hazards, etc.",
    petroleum_product_damage_probable_causes:
      "Oil, diesel fuel, antifreeze or chemical contamination.",
    forklift_damage_probable_causes:
      "Caused during shipping and handling by lift truck forks.",
    circumferential_fatigue_rupture_zipper_probable_causes:
      "Severe underinflation which produces casing cord fatigue, often a result of a puncture.",
    open_sidewall_splice_probable_causes: "Manufacturing process.",
    sidewall_bumps_blisters_probable_causes:
      "Deterioration of internal components over time. Fatigue of casing. Accelerated by overload/underinflation.",
    sidewall_penetration_probable_causes:
      "Road debris and, occasionally, vandalism with a sharp puncturing instrument.",
    radial_split_probable_causes:
      "Severe impact. This situation can occur in overinflated and/or overloaded conditions.",
    penetrations_and_road_hazards_probable_causes:
      "Road hazard or foreign object.",
    vehicle_damage_probable_causes:
      "Contact with vehicle hardware, such as mud flap brackets, trailer wheel house molding, bumpers, etc.",
    forklift_damage_cuts_snags_probable_causes:
      "Caused by lift truck forks or similar equipment.",
    belt_lift_separation_probable_causes:
      "Adhesion loss within the tire at the edge of the belt package. May be associated with a penetration or other damage. Can be aggravated by spread axle use or dragging tire sideways.",
    tread_lift_separation_probable_causes:
      "Adhesion loss between the tread rubber and the tire casing. Can be caused by tread penetration.",
    brake_skid_damage_probable_causes:
      "Brake skid usually occurs on trailer and drive tires. Aggravated by new brakes (high friction, not worn in), unbalanced brake system, aggressive use of brakes, driver abuse and unloaded vehicles.",
    tread_chunking_probable_causes:
      "Caused by tire running over curbs or rails or by severe localized impacts. Aggravated by hot tires, spread axles, sharp turning and off road utilization.",
    lug_base_cracking_probable_causes:
      "This condition is caused by a combination of drive axle torque, load and heat. It also can be due to tire construction or started by stone drilling. It is aggravated by underinflation.",
    wild_wire_probable_causes: "Loose or separated bead or body wires.",
    impact_breaks_probable_causes:
      "Severe, concentrated impact with a foreign object or a chuck hole. Aggravated by over inflation and high speed.",
    chipping_flaking_chunking_tread_probable_causes:
      "Operation of tires with over-the-road tread rubber compounds on gravel surfaces and haul roads; misapplication of the tire to service conditions. Aggravated by high torque, over inflation and short turns, especially on drive tires.",
    stone_drilling_probable_causes:
      "Tread design and/or misapplication of the tire to service conditions (gravel roads/quarry operation).",
    regrooving_damage_probable_causes: "Regrooving too deep.",
    dynamometer_type_damage_probable_causes:
      "Excessive heat buildup on a dynamometer, also on high speed, lightly loaded overinflated applications using deep lug tires.",
    chemical_damage_probable_causes:
      "Exposure to chemicals, usually solvent in nature, which attack tread rubber.",
    excessive_wear_probable_causes: "Run too long in service or brake skid.",
    rib_tearing_probable_causes:
      "Caused by running over curbs and rails, or by severe localized impacts, aggravated by spread axles, hot tires and sharp turning.",
    defense_groove_tearing_probable_causes:
      "Caused by running over curbs or rails, or by severe localized impacts, aggravated by sharp turning.",
    groove_cracking_probable_causes:
      "This condition is caused by high side forces applied to a rib type tire. It can also be caused by petroleum damage, weathering, or exhaust on drive tires.",
    spin_damage_probable_causes:
      "Spinning drive tires on ice, sand, gravel, etc.",
    penetrating_objects_probable_causes: "Impact with road hazard.",
    open_inner_liner_splice_probable_causes:
      "Loss of adhesion at the splice due to excessive flexing from underinflation; may also be manufacturing related.",
    inner_liner_bubbles_blisters_and_separations_probable_causes:
      "Adhesion loss of inner liner material to the casing.",
    inner_liner_cracking_probable_causes:
      "Under inflation, excessive heat buildup in the tire interior. May also be manufacturing related.",
    pulled_loose_cords_probable_causes:
      "Impacts, penetrations, poor repairs and under inflation which damage ply material.",
    tearing_mount_dismount_damage_probable_causes:
      "Poor mounting/demounting techniques or lack of lubricant.",
    foreign_object_inner_liner_damage_in_tubeless_tire_probable_causes:
      "Foreign object in tire.",
    run_flat_probable_causes:
      "Continued operation after loss of inflation pressure",
    pinch_shock_probable_causes: "Severe impact.",
    impact_break_probable_causes:
      "Sudden and excessive force applied to the tire exterior which exceeds reinforcing material limits. May be caused by road hazard or driver abuse.",
    run_flat_probable_causes:
      "Loss of inflation pressure. Diagnosis of cause of inflation loss becomes difficult or impossible as this condition progresses.",
    electrical_discharge_probable_causes:
      "Vehicle contact with electrical cables or lightning.",
    bad_spot_repair_probable_causes:
      "Poor workmanship. Contaminated buffed area, improper cementing, contaminated fill material, improper cure time or pressure, improper injury removal, etc.",
    spot_repair_should_be_a_section_repair_probable_causes:
      "Poor workmanship. Not all the injury was removed. A full reinforced section repair should have been made.",
    improper_nail_hole_repair_probable_causes:
      "Nail hole repair procedures were used instead of section repair procedures resulting in inadequate injury removal. An improper size and type of repair unit was used.",
    improperly_aligned_repair_probable_causes:
      "Improperly installed repair unit. (In this case, the bead arrows do not point to the beads.) Improperly trained repair personnel.",
    unfilled_nail_hole_repair_probable_causes:
      "The injury was not filled with a plug or rubber fill material.",
    bridged_repair_probable_causes:
      "Improper placement of the repair unit during installation which resulted in trapped air under the repair unit. The tire beads were probably spread during installation of the repair unit.",
    on_the_wheel_repair_probable_causes:
      "The tire was not demounted and properly inspected and repaired. This type of repair is not recommended.",
    bad_bead_repair_probable_causes:
      "The injury exceeds repair limitation, poor workmanship, improper cure, incorrect fill material, or poor mounting/demounting techniques.",
    failed_repair_injury_not_removed_probable_causes:
      "All injury or separation was not detected and removed during the repair process, poor workmanship.",
    bias_repair_in_radial_tire_probable_causes:
      "An incorrect repair unit was installed, improperly trained personnel.",
    bond_line_porosity_probable_causes:
      "Lack of proper cure conditions, i.e., time, temperature and pressure.",
    tread_separation_probable_causes:
      "Faulty retread workmanship and/or material such as a scorched or contaminated buffed surface, old cushion gum or tread rubber, improper cure conditions, missed nail hole, or a faulty repair",
    tread_chunking_at_splice_probable_causes:
      "Improper workmanship or contamination at the splice.",
    tread_separation_repair_related_probable_causes:
      "Faulty repair. Air seeped through or was trapped in the injury and under the tread which eventually reduced adhesion between the tread and the tire and resulted in a separation.",
    belt_separation_repair_related_probable_causes:
      "A faulty repair or skive. Air migrated through the injury causing separation between the belts.",
    missed_puncture_probable_causes:
      "An undetected and un repaired penetration.",
    tread_edge_lifting_probable_causes:
      "Caused by cure related problems, retread processing problems, the shoulder buffed lower on one side or possible belt edge separation.",
    failed_inner_liner_repair_probable_causes:
      "An attempt to repair liner blisters or an open liner splice failed to seal the air cavity of the tire and air migrated into the tire body causing separation.",
    lug_base_cracking_tearing_probable_causes:
      "The wrong tread design was used for the operation (single axle, high torque drive application with deep traction tread design may contribute to this condition), rubber compound, excessive or lack of undertread, over curing.",
    improper_tread_width_probable_causes:
      "Inadequate support of the belt package during operation which creates a new flex point in the tire. The result is a breakdown of the belts or rubber at the bondline of the retread and the casing",
    open_tread_splice_probable_causes:
      "Poor workmanship, poor tread end adhesion or the tread was cut too short.",
    skive_failure_probable_causes:
      "Improper skive or skive fill which traps air or allows air to seep under the tread and results in a localized loss of tread adhesion. Tread or casing separation may occur if not caught early in tread life.",
    repair_related_bulge_probable_causes:
      "The repair unit is too small or improperly installed.",
    buckled_tread_probable_causes:
      "The retread tire was too large for the mold in which it was cured, thus causing distortion in the tire during the curing process.",
    delamination_probable_causes:
      "Excessive mold lube or a rubber compounding problem, surface cure of the tread rubber or poor mold fitment.",
    tread_surface_porosity_probable_causes:
      "Insufficient tread rubber, improper cure, or poor mold fitment.",
    wing_lift_probable_causes:
      "Lack of shoulder buff or poor mold fitment (mold cure), poor tread building, scorched or contaminated buffed surface and/or improper crown radius.",
    failed_repair_from_underinflation_probable_causes:
      "Running the tire underinflated due to a puncture, cut, bad valve stem, lack of proper air pressure, etc.",
    shoulder_step_chamfer_wear_probable_causes:
      "Typical of radial tires in slow wearing operations. May vary with tread design and service application.",
    full_shoulder_wear_probable_causes:
      "Result of side scrubbing and generally is caused by either improper toe condition or drive axle misalignment. In case of toe in, the outside shoulders of both steer tires will be worn, while in the case of toe out, the inside shoulders of both steers will be worn. Drive axle misalignment wears the inside shoulder of one tire and the outside shoulder of the other steer tire. Poorly maintained suspension components (e.g. torque rods, springs, and spring bushings) can produce the same results.",
    feather_wear_probable_causes:
      "Excessive side force scrubbing, resulting from severe conditions of misalignment such as excessive toe, severe drive axle misalignment, worn, missing or damaged suspension components, bent tie rod or other chassis misalignment",
    erosion_river_channel_wear_probable_causes:
      "Characteristic of slow wear rate of radial tires on free rolling axles. May vary with individual tire tread design and construction. Common in line haul operations in which loads are light and turning is frequent.",
    cupping_scallop_wear_probable_causes:
      "Usually a result of moderate to severe assembly out of balance condition, improper rim/wheel mounting or other assembly non-uniformity. Can also be due to lack of shock absorber control on some suspension types.",
    one_sided_wear_probable_causes:
      "Improper alignment, worn king pins, loose front wheel bearings, excessive axle loads.",
    diagonal_wear_probable_causes:
      "Runout and/or out of balance in conjunction with a slow rate of wear. Can develop from a brake skid, spot wear, shoulder wear, or other advanced wear conditions. Can also be caused by loose wheel bearings and is aggravated by misalignment.",
    eccentric_out_of_round_wear_probable_causes:
      "Usually a result of excessive radial runout or other non-uniformity in the rotating assembly, e.g. hub, stud circle, wheel/rim, tire and/or improper bead seating of tire on rim. May also be caused by dragging or distorted brake drums and drums with excessive wall thickness variation. May be accompanied by, or develop into, a vibration complaint.",
    overall_fast_wear_probable_causes:
      "Can be caused by service conditions such as mountainous terrain, frequency and severity of turning, abrasive road surfaces in combination with vehicle configurations and their attributes such as power steering, heavy axle loads, high wheel cuts, setback axles, short wheel base tractors, long wheel base straight trucks.",
    rib_depression_punch_wear_probable_causes:
      "Lack of shock absorber control in some suspension types, improper inflation pressures, loose or worn wheel bearings, assembly non-uniformity such as improper bead seating and out-of-balance condition, aggravated by high speed empty hauls.",
    erratic_depression_wear_probable_causes:
      "Lack of shock absorber control in some suspension types. Loose or worn wheel bearings, assembly non-uniformity such as improper bead seating and out-of-balance condition, aggravated by high speed empty hauls.",
    shoulder_step_chamfer_wear_drive_probable_causes:
      "Typical of radial tire construction and slow rates of tread wear. May vary with tread design and service application. Can be aggravated by axle deflection, road conditions, and worn or damaged suspension parts.",
    heel_toe_wear_probable_causes:
      "Most often caused by mismatched inflation pressures or tire diameter in a dual assembly and certain conditions such as P&D operations, mountainous terrains, etc.",
    alternate_lug_wear_probable_causes:
      "Mismatched inflation pressures or tire diameters in a dual assembly and certain conditions such as pick up and delivery operations and variations in loads. Is accentuated by slow rate of tire wear and by worn or poorly maintained suspension components or axle misalignment.",
    brake_skid_flat_spot_wear_probable_causes:
      "Aggravated by new brakes (high friction, not worn in), unbalanced brake system, aggressive use of brakes and driver abuse. Sometimes seen on new vehicle drive-aways.",
    overall_fast_wear_drive_probable_causes:
      "Can be caused by service conditions such as mountainous terrain, frequency and severity of turning, abrasive road surfaces and equipment such as single axle drive, long wheel base straight trucks, heavy axle loads, high horsepower engines, or mismatched drive train components.",
    brake_skid_flat_spot_wear_trailer_probable_causes:
      "Brake skid occurs most often on dolly, trailer and drive tires. Aggravated by new brakes (high friction, not worn in), unbalanced brake system, aggressive use of brakes, frozen brakes and driver abuse such as the use of only trailer brakes to stop a vehicle. Sometimes seen on new vehicle drive-aways.",
    diagonal_wear_trailer_probable_causes:
      "Bad wheel bearings, toe out, mismounting of tire and wheel assembly to trailer, mismatched duals for size and/or inflation pressures; may start as brake skid. Aggravated by high speed empty or light load hauls.",
    multiple_flat_spotting_wear_probable_causes:
      "Uneven dual loading due to mismatched inflation pressures or tire sizes, worn wheel bearings, imbalance, air suspensions with bad shocks, driver abuse of trailer brakes; aggravated by high speed empty hauls.",
    rapid_shoulder_wear_one_shoulder_probable_causes:
      "Excessive toe, excessive camber. These conditions can be created by a misaligned or bent axle and can also be caused by loose or worn wheel bearings.",
    shoulder_scrubbing_scuffing_probable_causes:
      "Excessive lateral scuffing of tread, especially in tight turns when tire is hot. Most common on heavily loaded, spread axle trailers.",
    rapid_shoulder_wear_both_shoulders_probable_causes:
      "Frequently found on spread axles as a result of normal service conditions. Also found on dolly axles as a result of the push/pull action of that operation.",
    erratic_depression_wear_trailer_probable_causes:
      "Lack of shock absorber control in some suspension types, mismatched tire sizes and/or inflation pressures between duals, loose or worn wheel bearings, assembly non-uniformity such as improper bead seating and out-of-balance condition, aggravated by high speed empty hauls.",
    one_sided_wear_trailer_probable_causes:
      "Excessive toe, excessive axle camber, non-parallel axles, non-uniform tire and wheel assembly caused by improper bead seating or a bent wheel.",
    erosion_river_channel_wear_trailer_probable_causes:
      "Characteristic of slow wear rate of radial tires on free rolling axles. May vary with individual tire tread design and construction.",
    rib_depression_punch_wear_trailer_probable_causes:
      "Lack of shock absorber control in some suspension types, mismatched tire sizes and/or inflation pressures between duals, loose or worn wheel bearings, assembly non-uniformity such as improper bead seating and out-of-balance condition, aggravated by high speed empty hauls.",
    run_flat_probable_causes_any_area:
      "Loss of inflation pressure. Diagnosis of cause of inflation loss becomes difficult or impossible as this condition progresses.",
  },
  actions: {
    torn_bead_action_tire:
      "If no rust on the wire is evident, repair and return to service. If wire is rusty, gouged, kinked or broken, loose or separated, contact your tire supplier/retreader to determine repairability; otherwise, scrap tire.",
    torn_bead_action_vehicle: "None",
    torn_bead_action_operations:
      "Review mounting/demounting procedures and tire tools. Ensure the tire beads are well lubricated. Also check new tire handling procedures.",
    kinked_distorted_bead_action_tire: "Scrap tire.",
    kinked_distorted_bead_action_vehicle: "None",
    kinked_distorted_bead_action_operations:
      "Review mounting/dismounting procedures and tire tools. Ensure the tire beads are lubricated.",
    bead_deformation_action_tire:
      "If wires are visible, scrap the tire. If only the rubber is distorted, return to service on other than the steer axle.",
    bead_deformation_action_vehicle: "None",
    bead_deformation_action_operations:
      "Review mounting/demounting procedures and the use of tire tools. Ensure the tire’s beads are well lubricated.",
    burned_bead_action_tire: "Scrap tire.",
    burned_bead_action_vehicle: "None",
    burned_bead_action_operations:
      "Determine the source of excessive heat and correct the braking condition.",
    reinforce_chafer_separation_action_tire:
      "Remove from service and consult tire manufacturer.",
    reinforce_chafer_separation_action_vehicle: "None",
    reinforce_chafer_separation_action_operations:
      "Ensure proper rim sizes, mounting procedures and inflation pressures are utilized.",
    petro_lubricant_damage_action_tire: "Scrap tire.",
    petro_lubricant_damage_action_vehicle: "None",
    petro_lubricant_damage_action_operations:
      "Use of proper non-petroleum-based tire lubricants.",
    bead_damage_from_curbing_action_tire: "Return tire to service.",
    bead_damage_from_curbing_action_vehicle: "None",
    bead_damage_from_curbing_action_operations: "Review driving procedures.",
    bead_area_flow_crack_action_tire:
      "Remove from service and contact the tire manufacturer.",
    bead_area_flow_crack_action_vehicle: "None",
    bead_area_flow_crack_action_operations: "None",
    spread_damaged_cord_action_tire:
      "Determine the cause of the bulge. Repaired tires can often be returned to service on dual positions unless the bulge height exceeds 3/8in when inflated. If the cause of the bulge is not a repair or damage, contact the tire manufacturer.",
    cuts_snags_action_tire:
      "If damage has not exposed ply cords, tire may be run out on dual positions. If the cords are visible, repair the tire if damage to the cords is within repair limits and return to service; otherwise scrap tire.",
    sidewall_separation_action_tire:
      "Remove the tire from service and contact your tire manufacturer.",
    chain_damage_action_tire:
      "Run out the tire on a dual position unless damage extends to the ply cord. If ply cords are visible, consult your tire repair facility for the possibility of repair.",
    vehicle_equipment_damage_action_tire:
      "Return the tire to service on a dual position unless abrasion extends to the ply cords. If the cords are exposed, consult your tire repair facility for the possibility of repair.",
    damage_induced_sidewall_separation_action_tire: "Scrap tire.",
    sidewall_abrasion_scuff_damage_action_tire:
      "If the cords are exposed, scrap tire. Otherwise, the tire can be returned to service. If abrasion appearance is objectionable, mount the scuffed side away from the curb.",
    weathering_action_tire:
      "All tires may eventually exhibit this condition in late service-life stage. Tires can be run out on the steer axle if weathering is minor; on dual positions if weathering is moderate. Severe weathering may require removal from service.",
    impact_break_action_tire:
      "Consult your tire repair facility for the possibility of repair or scrap tire.",
    branding_damage_action_tire:
      "Tires with hairline cracks can be returned to service. Depending upon crack severity and location, consult your tire manufacturer; if cords are exposed, scrap tire. Otherwise consult your tire repair facility for the possibility of repair.",
    diagonal_cracking_action_tire:
      "Remove from service and consult your tire manufacturer.",
    petroleum_product_damage_action_tire:
      "If contamination is slight, return tire to service. If there is a difference in stiffness or distortion in one sidewall when compared with the other, scrap tire.",
    forklift_damage_action_tire:
      "Do not place in service. Determine responsibility for the damage.",
    circumferential_fatigue_rupture_zipper_action_tire: "Scrap tire.",
    open_sidewall_splice_action_tire:
      "Remove the tire from service. Consult your tire manufacturer.",
    sidewall_bumps_blisters_action_tire:
      "Remove the tire from service. If this condition arises during the warranty period, consult your tire manufacturer. Otherwise scrap tire.",
    sidewall_penetration_action_tire:
      "Have the tire inspected by your tire repair supplier to determine repairability.",
    radial_split_action_tire:
      "Have the tire inspected by your tire repair supplier to determine repairability.",
    spread_damaged_cord_action_vehicle: "None",
    cuts_snags_action_vehicle:
      "Ensure the tire does not come in contact with vehicle hardware.",
    sidewall_separation_action_vehicle: "None",
    chain_damage_action_vehicle: "None",
    vehicle_equipment_damage_action_vehicle:
      "Analyze cause of the condition and correct.",
    damage_induced_sidewall_separation_action_vehicle: "None",
    sidewall_abrasion_scuff_damage_action_vehicle: "None",
    weathering_action_vehicle: "None",
    impact_break_action_vehicle: "None",
    branding_damage_action_vehicle: "None",
    diagonal_cracking_action_vehicle: "None",
    petroleum_product_damage_action_vehicle: "Check vehicle for fluid leaks",
    forklift_damage_action_vehicle: "None",
    circumferential_fatigue_rupture_zipper_action_vehicle: "None",
    open_sidewall_splice_action_vehicle: "None",
    sidewall_bumps_blisters_action_vehicle: "None",
    sidewall_penetration_action_vehicle: "None",
    radial_split_action_vehicle: "None",
    spread_damaged_cord_action_operations: "None",
    cuts_snags_action_operations:
      "If similar damage occurs on several tires, investigate vehicle operations to determine the cause of the damage.",
    sidewall_separation_action_operations: "None",
    chain_damage_action_operations:
      "Select the proper chain size. Correct the installation procedures and enforce chain removal when conditions warrant.",
    vehicle_equipment_damage_action_operations: "None",
    damage_induced_sidewall_separation_action_operations:
      "Determine the cause and take appropriate corrective action",
    sidewall_abrasion_scuff_damage_action_operations:
      "Review driving procedures.",
    weathering_action_operations:
      "Weather protection materials compounded into the tire are more effective when the tire is exercised. Therefore, minimize parked vehicle time. Consult your tire manufacturer if long periods of parking are expected.",
    impact_break_action_operations:
      "Review driving and maintenance procedures.",
    branding_damage_action_operations:
      "Review the tire manufacturer´s branding recommendations.",
    diagonal_cracking_action_operations: "None",
    petroleum_product_damage_action_operations:
      "Take special precautions during fueling to avoid spills",
    forklift_damage_action_operations: "Inspect tire upon receipt.",
    circumferential_fatigue_rupture_zipper_action_operations:
      "Routinely inspect all tires with inflation pressures 20% lower than your fleet´s inflation standard; thoroughly inspect all tires prior to repair and retreading; always use a safety cage during inflation. Consult your tire or retread supplier for proper inspection procedures.",
    open_sidewall_splice_action_operations: "None",
    sidewall_bumps_blisters_action_operations: "None",
    sidewall_penetration_action_operations: "Avoid road hazards.",
    radial_split_action_operations:
      "Review inflation maintenance program and review driver training.",
    penetrations_and_road_hazards_action_tire:
      "Determine casing integrity. Consult your repair facility for possibility of repair. If the damage is excessive and/or a separation is evident, scrap tire.",
    vehicle_damage_action_tire:
      "If cuts are not deeper than the base or the tread groove, return to a dual position. If the damage is deeper, retread or remount if possible. If the condition is more severe, scrap tire",
    forklift_damage_cuts_snags_action_tire:
      "If the tire is new, determine the responsibility for damage before placing it into service. If the tire has been in service but no steel is exposed, return to service. If steel is exposed, retread or remount.",
    belt_lift_separation_action_tire:
      "Remove from service and consult your tire manufacturer.",
    tread_lift_separation_action_tire:
      "Remove tire from service. Consult your tire manufacturer.",
    brake_skid_damage_action_tire:
      "If condition does not extend below 2/32in of tread, duals can be rematched to position flat spots 180˚ from each other. If more severe, the tire can be repaired or retreaded if damage is not into the belts. If skid damage is into the tire belts, it may be possible to remove the top belt and/or remount the tire; consult your retreader. If damage is too excessive, scrap tire.",
    tread_chunking_action_tire:
      "Remove the tire from service. Repair or retread.",
    lug_base_cracking_action_tire:
      "If cracks do not extend into the retread buff line, return to dual wheel service. If cracks extend deeper, consult your tire manufacturer.",
    wild_wire_action_tire: "Consult your tire manufacturer.",
    impact_breaks_action_tire: "Scrap tire.",
    chipping_flaking_chunking_tread_action_tire:
      "Tires with minor chipping and flaking can be returned to service. If damage extends below 2/32in retread the tire. If steel is visible, consult your retreader for the possibility of repair and retread.",
    stone_drilling_action_tire:
      "Remove remaining stones and return to service. If penetrations are into the belts, consult the retreader or your tire manufacturer. If unable to retread or remount, scrap the tire",
    regrooving_damage_action_tire:
      "Consult your retreader for the possibility of retreading.",
    dynamometer_type_damage_action_tire:
      "Consult your retreader for the possibility of retreading.",
    chemical_damage_action_tire:
      "Depending upon extent of chemical damage, return to trailer service or retread tire.",
    excessive_wear_action_tire:
      "Retread and remount if possible; otherwise scrap tire.",
    rib_tearing_action_tire:
      "If steel is showing, consult your tire manufacturer. If steel is not evident, retread or return to service on a dual position",
    defense_groove_tearing_action_tire:
      "If steel is visible, contact your tire manufacturer. If the tear extends near the buff radius, consult your retreader for possible retread. Otherwise return to service.",
    groove_cracking_action_tire:
      "If cracking is superficial, continue tire in service. If cracks are greater than 2/32in or more past the bottom of the groove, contact your tire manufacturer.",
    spin_damage_action_tire:
      "Place the tire back into service if the damage does not extend beyond the base of the tread groove. If damage is deeper, retread or remount if possible. If the condition is more severe, scrap tire.",
    penetrations_and_road_hazards_action_vehicle: "None",
    vehicle_damage_action_vehicle:
      "Analyze the cause of the condition and correct.",
    forklift_damage_cuts_snags_action_vehicle: "None",
    belt_lift_separation_action_vehicle: "None",
    tread_lift_separation_action_vehicle: "None",
    brake_skid_damage_action_vehicle:
      "Check brake materials and brake balance.",
    tread_chunking_action_vehicle: "None",
    lug_base_cracking_action_vehicle: "None",
    wild_wire_action_vehicle: "None",
    impact_breaks_action_vehicle: "None",
    chipping_flaking_chunking_tread_action_vehicle: "None",
    stone_drilling_action_vehicle: "None",
    regrooving_damage_action_vehicle: "None",
    dynamometer_type_damage_action_vehicle: "None",
    chemical_damage_action_vehicle: "None",
    excessive_wear_action_vehicle: "None",
    rib_tearing_action_vehicle: "None",
    defense_groove_tearing_action_vehicle: "None",
    groove_cracking_action_vehicle: "Review exhaust routing.",
    spin_damage_action_vehicle: "None",
    penetrations_and_road_hazards_action_operations: "None",
    vehicle_damage_action_operations: "Review driving procedures.",
    forklift_damage_cuts_snags_action_operations:
      "Inspect new tires upon receipt",
    belt_lift_separation_action_operations:
      "Use largest radius turns possible to avoid tire scrubbing.",
    tread_lift_separation_action_operations: "None",
    brake_skid_damage_action_operations: "Review driving procedures.",
    tread_chunking_action_operations:
      "Review tire selection. Review driving procedures.",
    lug_base_cracking_action_operations: "None",
    wild_wire_action_operations: "None",
    impact_breaks_action_operations: "None",
    chipping_flaking_chunking_tread_action_operations: "Review tire selection.",
    stone_drilling_action_operations: "Review tire selection.",
    regrooving_damage_action_operations:
      "Review the cost effectiveness of regrooving.",
    dynamometer_type_damage_action_operations:
      "Review dynamometer procedures. Review load pressure or tire selection.",
    chemical_damage_action_operations: "None",
    excessive_wear_action_operations: "None",
    rib_tearing_action_operations: "Review driving procedures.",
    defense_groove_tearing_action_operations: "Review driving procedures.",
    groove_cracking_action_operations:
      "Review driving procedures in tight turns",
    spin_damage_action_operations:
      "Review driving procedures and/or drive tire selection.",
    penetrating_objects_action_tire:
      "Remove foreign object. Inspect degree of damage. Repair if permissible according to repair limit standards, and restore moisture seal and air retention integrity. If damage is excessive, scrap tire.",
    open_inner_liner_splice_action_tire:
      "Repair inner liner with rubber gum to ensure air retention integrity if ply cords are not exposed. If ply cords are visible, contact your tire manufacturer.",
    inner_liner_bubbles_blisters_and_separations_action_tire:
      "Remove from service and contact your tire manufacturer. If blisters are small and few, the inner liner can be repaired and the tire can be placed back into service.",
    inner_liner_cracking_action_tire:
      "Remove the tire from service and contact your tire manufacturer.",
    pulled_loose_cords_action_tire: "Scrap tire.",
    tearing_mount_dismount_damage_action_tire:
      "Repair the inner liner or scrap the tire if damage is too excessive.",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_tire:
      "Inspect for degree of damage, repair if possible; otherwise scrap the tire.",
    run_flat_action_tire: "Scrap the tire.",
    pinch_shock_action_tire:
      "Have the tire inspected by your tire repair supplier to determine repairability.",
    impact_break_action_tire: "Scrap tire.",
    penetrating_objects_action_vehicle: "None",
    open_inner_liner_splice_action_vehicle: "None",
    inner_liner_bubbles_blisters_and_separations_action_vehicle: "None",
    inner_liner_cracking_action_vehicle: "None",
    pulled_loose_cords_action_vehicle: "None",
    tearing_mount_dismount_damage_action_vehicle: "None",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_vehicle: "None",
    run_flat_action_vehicle: "None",
    pinch_shock_action_vehicle:
      "Severe impacts can cause bent rim flanges. Inspect wheels and recheck alignment.",
    impact_break_action_vehicle: "None",
    penetrating_objects_action_operations: "None",
    open_inner_liner_splice_action_operations: "None",
    inner_liner_bubbles_blisters_and_separations_action_operations: "None",
    inner_liner_cracking_action_operations: "None",
    pulled_loose_cords_action_operations: "None",
    tearing_mount_dismount_damage_action_operations:
      "Review tire mounting/dismounting procedures and the use of tire tools.",
    foreign_object_inner_liner_damage_in_tubeless_tire_action_operations:
      "None",
    run_flat_action_operations: "None",
    pinch_shock_action_operations:
      "Review driving procedures and driver education.",
    impact_break_action_operations: "Review driving procedures.",
    run_flat_action_tire_any_area: "Scrap the tire.",
    electrical_discharge_action_tire_any_area: "Scrap the tire",
    run_flat_action_vehicle_any_area: "None",
    electrical_discharge_action_vehicle_any_area: "None",
    run_flat_action_operations_any_area: "None",
    electrical_discharge_action_operations: "None",
    bad_spot_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible or scrap the tire if it is unrepairable.",
    spot_repair_should_be_a_section_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible or scrap the tire if it is unrepairable.",
    improper_nail_hole_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    improperly_aligned_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    unfilled_nail_hole_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    bridged_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    on_the_wheel_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    bad_bead_repair_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    failed_repair_injury_not_removed_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Scrap the tire.",
    bias_repair_in_radial_tire_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment. Re-repair if possible, otherwise scrap the tire.",
    bad_spot_repair_action_vehicle: "None",
    spot_repair_should_be_a_section_repair_action_vehicle: "None",
    improper_nail_hole_repair_action_vehicle: "None",
    improperly_aligned_repair_action_vehicle: "None",
    unfilled_nail_hole_repair_action_vehicle: "None",
    bridged_repair_action_vehicle: "None",
    on_the_wheel_repair_action_vehicle: "None",
    bad_bead_repair_action_vehicle: "None",
    failed_repair_injury_not_removed_action_vehicle: "None",
    bias_repair_in_radial_tire_action_vehicle: "None",
    bad_spot_repair_action_operations: "None",
    spot_repair_should_be_a_section_repair_action_operations: "None",
    improper_nail_hole_repair_action_operations:
      "Review repair techniques with your repair person.",
    improperly_aligned_repair_action_operations:
      "Review repair techniques with your repair person.",
    unfilled_nail_hole_repair_action_operations:
      "Review repair requirements with road service vendors and monitor on the road tire service.",
    bridged_repair_action_operations:
      "Review repair techniques with your repair person.",
    on_the_wheel_repair_action_operations:
      "Review repair requirements with road service vendors and monitor on the road tire service.",
    bad_bead_repair_action_operations:
      "Review mounting/demounting procedures to eliminate bead damage.",
    failed_repair_injury_not_removed_action_operations:
      "Review repair techniques with your repair person.",
    bias_repair_in_radial_tire_action_operations:
      "Review repair techniques with your repair person.",
    bond_line_porosity_action_tire:
      "Consult your retreader for possible warranty adjustment. Retread and rerun.",
    tread_separation_action_tire:
      "Consult your retreader for possible warranty adjustment. Retread the tire again.",
    tread_chunking_at_splice_action_tire:
      "Run out the tread in the desired position or consult your retreader for a tread spot repair. Retread again if possible.",
    tread_separation_repair_related_action_tire:
      "Consult your retreader and/or repairman for possible warranty adjustment or repair and retread the tire again.",
    belt_separation_repair_related_action_tire:
      "Consult your repairman and/or retreader for possible warranty adjustment. Scrap the tire.",
    missed_puncture_action_tire:
      "Consult your retreader for possible warranty adjustment. Retread again if possible.",
    tread_edge_lifting_action_tire:
      "Consult your retreader for possible warranty adjustment. If the tire casing is sound, retread the tire. If the belt is separating from the casing, consult the original tire manufacturer.",
    bond_line_porosity_action_vehicle: "None",
    tread_separation_action_vehicle: "None",
    tread_chunking_at_splice_action_vehicle: "None",
    tread_separation_repair_related_action_vehicle: "None",
    belt_separation_repair_related_action_vehicle: "None",
    missed_puncture_action_vehicle: "None",
    tread_edge_lifting_action_vehicle: "None",
    bond_line_porosity_action_operations: "None",
    tread_separation_action_operations: "None",
    tread_chunking_at_splice_action_operations: "None",
    tread_separation_repair_related_action_operations:
      "Consult your repairman to correct repair problem.",
    belt_separation_repair_related_action_operations: "None",
    missed_puncture_action_operations: "None",
    tread_edge_lifting_action_operations: "None",
    failed_inner_liner_repair_action_tire:
      "Consult your retreader for possible warranty adjustment. Scrap the tire.",
    lug_base_cracking_tearing_action_tire:
      "Consult your retreader for possible warranty adjustment. Retread if possible.",
    improper_tread_width_action_tire:
      "Consult your retreader for possible warranty adjustment.",
    open_tread_splice_action_tire:
      "The tire may run out its tread life and be returned to service; or consult your retreader for possible warranty adjustment and retread again.",
    failed_inner_liner_repair_action_vehicle: "None",
    lug_base_cracking_tearing_action_vehicle: "None",
    improper_tread_width_action_vehicle: "None",
    open_tread_splice_action_vehicle: "None",
    failed_inner_liner_repair_action_operations: "None",
    lug_base_cracking_tearing_action_operations:
      "Review tread design selection for application.",
    improper_tread_width_action_operations: "None",
    open_tread_splice_action_operations: "None",
    skive_failure_action_tire:
      "Consult your retreader for possible warranty adjustment. If within limits of repair, repair the tire again and retread.",
    repair_related_bulge_action_tire:
      "If the bulge exceeds 3/8in, scrap the tire.",
    buckled_tread_action_tire:
      "Consult your retreader/repair person for possible warranty adjustment",
    skive_failure_action_vehicle: "None",
    repair_related_bulge_action_vehicle: "None",
    buckled_tread_action_vehicle: "None",
    skive_failure_action_operations: "None",
    repair_related_bulge_action_operations:
      "Consult your tire repairman to correct the repair problems.",
    buckled_tread_action_operations: "None",
    delamination_action_tire:
      "The tread can be run out if the condition is not severe, otherwise consult your retreader for possible warranty adjustment and retread the tire again.",
    tread_surface_porosity_action_tire:
      "The tread can be run out if the tread adhesion to the casing is adequate, however, tread wear may be poor. If the adhesion is poor, consult your retreader for possible warranty adjustment, and retread the tire again.",
    wing_lift_action_tire:
      "Consult your retreader for possible warranty adjustment. Retread again.",
    failed_repair_from_underinflation_action_tire:
      "Repair again if permissible according to repair limit standards. If the failure cannot be re-repaired, scrap tire.",
    delamination_action_vehicle: "None",
    tread_surface_porosity_action_vehicle: "None",
    wing_lift_action_vehicle: "None",
    failed_repair_from_underinflation_action_vehicle: "None",
    delamination_action_operations: "None",
    tread_surface_porosity_action_operations: "None",
    wing_lift_action_operations: "None",
    failed_repair_from_underinflation_action_operations:
      "Review tire inflation maintenance procedures and load conditions.",
    shoulder_step_chamfer_wear_action_tire:
      "Little or no loss in overall mileage will result provided tread depth differential is not excessive. Tires can remain on the steer axle.",
    full_shoulder_wear_action_tire:
      "If wear is severe, tires can be rotated to another position or reversed on the wheel.",
    feather_wear_action_tire:
      "If feather wear is severe, tires can be rotated to another axle for maximum utilization of remaining tread.",
    erosion_river_channel_wear_action_tire:
      "Erosion wear should not be of concern.",
    cupping_scallop_wear_action_tire:
      "If ride complaints arise, tires may be rotated to drive axle.",
    one_sided_wear_action_tire:
      "Depending on severity of wear, tires may be rotated to drive axle or, if worn to minimum tread depths on shoulder, submit for possible retreading.",
    diagonal_wear_action_tire:
      "The tire may be rotated to the outside drive dual position with change in rotation of the tire. If wear is excessive, submit to retreader for further options",
    eccentric_out_of_round_wear_action_tire:
      "Tire may be rotated to the trailer dual. If wear is excessive on worn side, submit to retreader for further options.",
    overall_fast_wear_action_tire: "None",
    rib_depression_punch_wear_action_tire:
      "If not worn excessively, continue to run. If wear is excessive, retread.",
    erratic_depression_wear_action_tire:
      "If not worn excessively, continue to run. If wear is excessive, retread.",
    shoulder_step_chamfer_wear_action_vehicle: "None",
    full_shoulder_wear_action_vehicle:
      "Diagnose misalignment, check suspension components, and correct as required.",
    feather_wear_action_vehicle:
      "Diagnose and correct misalignment condition as required. If feather wear on both steer tires is in the same direction, drive axle or other chassis misalignment is indicated. If steer tire feathers are in opposite directions, a toe condition is indicated.",
    erosion_river_channel_wear_action_vehicle: "None",
    cupping_scallop_wear_action_vehicle:
      "Diagnose imbalance condition, i.e., wheel, rim, hub, brake drum. Correct as necessary.",
    one_sided_wear_action_vehicle: "Diagnose mechanical problem and correct.",
    diagonal_wear_action_vehicle: "Diagnose cause and correct.",
    eccentric_out_of_round_wear_action_vehicle:
      "Diagnose problem components/parts. Replace or correct as necessary.",
    overall_fast_wear_action_vehicle:
      "Consult vehicle and tire manufacturers when equipment is specified or replacing tires.",
    rib_depression_punch_wear_action_vehicle:
      "Diagnose mechanical problem and correct.",
    erratic_depression_wear_action_vehicle:
      "Diagnose mechanical problem and correct",
    shoulder_step_chamfer_wear_action_operations: "None",
    full_shoulder_wear_action_operations: "None",
    feather_wear_action_operations: "None",
    erosion_river_channel_wear_action_operations: "None",
    cupping_scallop_wear_action_operations: "None",
    one_sided_wear_action_operations: "None",
    diagonal_wear_action_operations: "None",
    eccentric_out_of_round_wear_action_operations: "None",
    overall_fast_wear_action_operations: "None",
    rib_depression_punch_wear_action_operations: "None",
    erratic_depression_wear_action_operations: "None",
    shoulder_step_chamfer_wear_drive_action_tire:
      "Consider rotating tire among drive axles. Does not adversely affect overall tread life.",
    heel_toe_wear_action_tire:
      "Run out on drive axle. If severe, change direction of rotation until tread is worn to point of retread.",
    alternate_lug_wear_action_tire:
      "Continue to run out unless tread depth variations become severe. Rotate to faster wearing position, i.e., single screw tractor or rear drive axle.",
    brake_skid_flat_spot_wear_action_tire:
      "If condition is not excessive, duals can be rematched to position flat spots 180° from each other. If more severe, the tire can be repaired or retreaded if damage is not into the belts. If skid damage is into the tire belts, it may be possible to remove the top belt and/or remount and then retread the tire. Consult your retreader. If damage is excessive, scrap tire.",
    overall_fast_wear_drive_action_tire:
      "Continue to run and retread. If condition continues, consult tire manufacturer.",
    shoulder_step_chamfer_wear_drive_action_vehicle:
      "Inspect for worn or damage suspension parts",
    heel_toe_wear_action_vehicle:
      "Review tire maintenance practices and tread design selection.",
    alternate_lug_wear_action_vehicle:
      "Check for worn or damaged components and axle misalignment.",
    brake_skid_flat_spot_wear_action_vehicle:
      "Check brake material and brake balance.",
    overall_fast_wear_drive_action_vehicle:
      "Review tire design selection. Carefully match equipment with service requirements. Consult vehicle and tire manufacturer when equipment is specified or replacing tires.",
    shoulder_step_chamfer_wear_drive_action_operations: "None",
    heel_toe_wear_action_operations: "None",
    alternate_lug_wear_action_operations:
      "Review tire maintenance practices and tread design selection.",
    brake_skid_flat_spot_wear_action_operations:
      "Review driver training program.",
    overall_fast_wear_drive_action_operations: "None",
    brake_skid_flat_spot_wear_trailer_action_tire:
      "If condition is not excessive, duals can be rematched to position flat spots 180 ̊ from each other. If more severe, the tire can be repaired or retreaded if damage is not into the belts. If skid damage is into the tire belts, it may be possible to remove the top belt and/or remount the tire. Consult your retreader. If damage is excessive, scrap tire.",
    diagonal_wear_trailer_action_tire:
      "Reverse direction of rotation of the tire. If wear is excessive, true or retread.",
    multiple_flat_spotting_wear_action_tire:
      "If not worn excessively, run tires out. If remaining tread at worn spots is 2/32in or less, retread tire.",
    rapid_shoulder_wear_one_shoulder_action_tire:
      "Change direction of rotation of tire. If shoulder wear is severe, remove and retread.",
    shoulder_scrubbing_scuffing_action_tire:
      "Move affected shoulders to low scrub positions. If severe, tire removal may be required. Inspect for serviceability on another axle or retread if possible.",
    rapid_shoulder_wear_both_shoulders_action_tire:
      "If shoulder wear is severe, remove and retread.",
    erratic_depression_wear_trailer_action_tire:
      "If not worn excessively, continue to run. If wear is excessive, retread.",
    one_sided_wear_trailer_action_tire:
      "Change tire position on trailer. If excessive, retread.",
    erosion_river_channel_wear_trailer_action_tire:
      "Erosion wear should not be of concern.",
    rib_depression_punch_wear_trailer_action_tire:
      "If not worn excessively, continue to run. If wear is excessive, retread.",
    brake_skid_flat_spot_wear_trailer_action_vehicle:
      "Check brake material and brake balance.",
    diagonal_wear_trailer_action_vehicle: "Diagnose cause and correct.",
    multiple_flat_spotting_wear_action_vehicle: "Diagnose cause and correct.",
    rapid_shoulder_wear_one_shoulder_action_vehicle:
      "Diagnose misalignment and/or mechanical condition and correct.",
    shoulder_scrubbing_scuffing_action_vehicle: "None",
    rapid_shoulder_wear_both_shoulders_action_vehicle: "None",
    erratic_depression_wear_trailer_action_vehicle:
      "Diagnose mechanical problem and correct.",
    one_sided_wear_trailer_action_vehicle: "Isolate and correct cause.",
    erosion_river_channel_wear_trailer_action_vehicle: "None",
    rib_depression_punch_wear_trailer_action_vehicle:
      "Diagnose mechanical problem and correct.",
    brake_skid_flat_spot_wear_trailer_action_operations:
      "Review driver training program.",
    diagonal_wear_trailer_action_operations: "None",
    multiple_flat_spotting_wear_action_operations:
      "Consult driver and review maintenance practices.",
    rapid_shoulder_wear_one_shoulder_action_operations: "None",
    shoulder_scrubbing_scuffing_action_operations: "None",
    rapid_shoulder_wear_both_shoulders_action_operations:
      "Review tire application with tire manufacturer.",
    erratic_depression_wear_trailer_action_operations: "None",
    one_sided_wear_trailer_action_operations: "None",
    erosion_river_channel_wear_trailer_action_operations: "None",
    rib_depression_punch_wear_trailer_action_operations: "None",
  },
};
