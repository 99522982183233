export default {
  title: "Nueva división",
  update_title: "Actualizar division",
  form: {
    permits: {
      delete: "Sin permisos para eliminar divisiones",
      update: "Sin permisos para actualizar divisiones",
    },
  },
  messages: {
    delete_division: "¿Estas seguro de eliminar la división?",
    division_created: "Listo, se creo una nueva división con éxito",
    division_updated: "Listo, se actualizo la division con éxito",
  },
};
