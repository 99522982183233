import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  brand,
  brands,
  createBrandPermit,
  updateBrandPermit,
  deleteBrandPermit,
  filter,
  t,
  getBrand,
  addBrand,
  updateBrand,
  clearBrandFilters,
  setBrandFilters,
  deleteBrand,
  setBrandStatus,
  setBrandApprovalStatus,
  getBrands,
  clearBrands,
}) {
  return (
    <Home
      item={brand}
      name="brand"
      filter={filter}
      createTooltipPermit={!createBrandPermit ? t("_permits.create.brand") : ""}
      currentPage={brands.current_page}
      deleteConfirmationMessage={t("_messages.delete.brand")}
      disableConfirmationMessage={t("_messages.change_status.brand.disable")}
      enableConfirmationMessage={t("_messages.change_status.brand.enable")}
      approveConfirmationMessage={t("_messages.change_approved.brand.approve")}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.brand.disapprove"
      )}
      rows={brands.data.map((brand) => {
        return {
          id: brand.brand_id,
          key: brand.brand_id,
          status: {
            status: brand.status,
            tooltipPermit: !updateBrandPermit ? t("_permits.update.brand") : "",
          },
          approved: {
            approved: brand.approved,
            tooltipPermit: !updateBrandPermit ? t("_permits.update.brand") : "",
          },
          name: brand.name,
          brand_type: t(
            `_labels.brand_type.options.${brand.brand_type.toLowerCase()}`
          ),
          edit: {
            tooltipPermit: !updateBrandPermit ? t("_permits.update.brand") : "",
          },
          delete: {
            tooltipPermit: !deleteBrandPermit ? t("_permits.delete.brand") : "",
          },
        };
      })}
      title={t("_general.brands")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "approved", label: t("_labels.approved") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "brand_type", label: t("_labels.brand_type.label.singular") },
        { id: "actions", label: "" },
      ]}
      lastPage={brands.last_page}
      noItemsText={t("_general.no_brands")}
      initialDefaultValues={{
        name: "",
        status: "",
        brand_type: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "brand_type",
          required: true,
          component: "select",
          label: t("_labels.brand_type.label.singular"),
          selectItems: [
            { value: "VEHICLE", name: t("_labels.brand_type.options.vehicle") },
            { value: "TIRE", name: t("_labels.brand_type.options.tire") },
            {
              value: "ENGINE_TRANSMISSION",
              name: t("_labels.brand_type.options.engine_transmission"),
            },
            {
              value: "VEHICLE_ENGINE",
              name: t("_labels.brand_type.options.vehicle_engine"),
            },
            { value: "RETREAD", name: t("_labels.brand_type.options.retread") },
          ],
        },
      ]}
      createFormTitle={t("_titles.new.brand")}
      updateFormTitle={t("_titles.update.brand")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.brand"),
        },
        approved: true,
        date: true,
        brand_type: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        approved: "",
        date_from: "",
        date_to: "",
        brand_type: [],
      }}
      createdMessage={t("_messages.created.brand")}
      updatedMessage={t("_messages.updated.brand")}
      getItemAction={getBrand}
      setFiltersAction={setBrandFilters}
      clearFiltersAction={clearBrandFilters}
      deleteAction={deleteBrand}
      setStatusAction={setBrandStatus}
      setApprovedAction={setBrandApprovalStatus}
      getItemsAction={getBrands}
      clearItemsAction={clearBrands}
      addAction={addBrand}
      updateAction={updateBrand}
    />
  );
}

Page.propTypes = {
  brand: PropTypes.object.isRequired,
  brands: PropTypes.object.isRequired,
  createBrandPermit: PropTypes.bool.isRequired,
  updateBrandPermit: PropTypes.bool.isRequired,
  deleteBrandPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getBrand: PropTypes.func.isRequired,
  addBrand: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
  clearBrandFilters: PropTypes.func.isRequired,
  setBrandFilters: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  setBrandStatus: PropTypes.func.isRequired,
  setBrandApprovalStatus: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  clearBrands: PropTypes.func.isRequired,
};

export default Page;
