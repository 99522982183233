import { CREATE_VEHICLE_PRESSURE_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddPressurePolicy =
  (dispatch) => async (subsidiaryId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `subsidiary/${subsidiaryId}/pressure/policy/vehicle`,
        data: fields,
      });

      dispatch({
        type: CREATE_VEHICLE_PRESSURE_POLICY,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
