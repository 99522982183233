import {
  GET_WAREHOUSES,
  CLEAR_WAREHOUSES,
  GET_WAREHOUSE,
  REGISTER_WAREHOUSE,
  SET_WAREHOUSE_FILTERS,
  CLEAR_WAREHOUSE_FILTERS,
  UPDATE_WAREHOUSE,
  CLEAR_WAREHOUSE,
  DELETE_WAREHOUSE,
  SET_WAREHOUSE_STATUS,
} from "store/actions";

const initialState = {
  warehouse: {
    warehouse_id: 0,
    subsidiary_id: 0,
    subsidiary: {
      name: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    name: "",
    status: 0,
    country: "",
    province: "",
    direction_1: "",
    external_number: "",
    postal_code: "",
  },
  warehouses: {
    current_page: 1,
    data: [],
    last_page: 1,
  },

  filter_warehouse: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_WAREHOUSE:
    case GET_WAREHOUSE:
    case REGISTER_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };

    case GET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.payload,
      };

    case DELETE_WAREHOUSE:
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: state.warehouses.data.filter(
            (warehouse) => warehouse.warehouse_id !== action.payload
          ),
        },
      };

    case CLEAR_WAREHOUSES:
      return {
        ...state,
        warehouses: { ...initialState.warehouses },
      };

    case SET_WAREHOUSE_FILTERS:
      const filter = {
        ...state.filter_warehouse,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_warehouse: { ...filter },
      };

    case CLEAR_WAREHOUSE_FILTERS:
      return {
        ...state,
        filter_warehouse: { search: state.filter_warehouse.search },
      };

    case CLEAR_WAREHOUSE:
      return {
        ...state,
        warehouse: { ...initialState.warehouse },
      };

    case SET_WAREHOUSE_STATUS:
      return {
        ...state,
        warehouses: {
          ...state.warehouses,
          data: state.warehouses.data.map((warehouse) =>
            warehouse.warehouse_id !== action.payload
              ? warehouse
              : { ...warehouse, status: !warehouse.status }
          ),
        },
      };

    default:
      return state;
  }
}
