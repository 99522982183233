import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ t, title, description, className }) {
  return (
    <Card className={classnames(css.card, className)}>
      <CardContent className={classnames(css.pb8)}>
        <Typography noWrap variant="h6" component="h6">
          {title}
        </Typography>

        <Typography noWrap variant="body2" color="textSecondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classnames(css.pt8)}>
        <Button size="small" color="primary">
          {t("_buttons.view")}
        </Button>
      </CardActions>
    </Card>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Page;
