import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { DELETE_VEHICLE_TYPE } from "store/actions/account/permits";

import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/vehicle/type/VehicleTypeForm/Form";

export function Form({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { permits } = rest;

  return (
    <Page
      {...rest}
      t={t}
      deleteVehicleTypePermit={permits.includes(DELETE_VEHICLE_TYPE)}
    />
  );
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateVehicleTypePermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
