export default {
  link_with_corporate: "Corporate user link",
  link_with_user: "User corporate link",
  buttons: {
    unlink_corporate: "Unlink corporate",
    unlink_user: "Unlink user",
    view_corporate: "View corporate",
    view_user: "View user",
  },
};
