import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LabelAndText from "components/molecules/general/LabelAndText";

import Form from "components/organisms/vehicle/tire/review/TireReviewForm/Form";
import css from "./index.module.scss";

function Page({
  t,
  open,
  tire,
  onClose,
  initialErrors,
  initialValues,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" size="xs" open={open} onClose={onClose}>
        <DialogTitle>{t("_titles.update.tire_review")}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div className={classnames(css.w100, css.flex)}>
            <LabelAndText
              className={classnames(css.third_width)}
              label={
                tire.code ? t("_labels.code") : t("_labels.rfid_field.label")
              }
              text={tire.code || tire.device_code}
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.tire_position")}
              text={
                tire.cycle.movement_tire?.vehicle_tire[0]?.vehicle_type_axle_tire.position.toString() ||
                "0"
              }
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.condition")}
              text={t(
                `_labels.tire_condition.options.${tire.cycle.tire_condition_id.toLowerCase()}`
              )}
            />
          </div>
          <div className={classnames(css.w100, css.flex)}>
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.brand_field.label")}
              text={
                tire.cycle.number_cycle > 0
                  ? tire.cycle.revitalized.brand.name
                  : tire.cycle.variation.tire_model.brand.name
              }
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.tire_model_field.label")}
              text={
                tire.cycle.number_cycle > 0
                  ? tire.cycle.revitalized?.name
                  : tire.cycle.variation.tire_model.name
              }
            />
            <LabelAndText
              className={classnames(css.third_width)}
              label={t("_labels.size")}
              text={tire.cycle.variation.tire_size.size}
            />
          </div>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => <Form onClose={onClose} {...p} />}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tire: PropTypes.object,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
