import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment-timezone";

import { actionClearAlertsOfTire } from "store/actions/tire/alert/clearAlertsOfTire";
import { actionGetAlertsOfTire } from "store/actions/tire/alert/getAlertsOfTire";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTire } from "store/actions/tire/getTire";
import { actionClearTire } from "store/actions/tire/clearTire";
import { actionClearAlertsOfTireFilters } from "store/actions/tire/alert/clearAlertsOfTireFilters";
import { actionDeleteTireAlert } from "store/actions/tire/alert/deleteAlertOfTire";
import { actionSetAlertsOfTireFilters } from "store/actions/tire/alert/setAlertsOfTireFilters";

import { useParams, useLocation } from "react-router-dom";
import { useLang } from "hooks/lang";

import AlertPdf from "./PDF/Document";
import Page from "./page";

function AlertsByTire({ ...rest }) {
  const t = useLang();
  const { id: movementTireId } = useParams();
  const query = useLocation();

  const {
    alerts,
    tire,
    filter,
    setNotification,
    getTireAlerts,
    clearTireAlerts,
    getTire,
    clearTire,
    clearAlertsOfTireFilters,
    deleteTireAlert,
    setAlertOfTireFilters,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [alertId, setAlertId] = useState(null);

  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();
  const [dataAlerts, setDataAlerts] = useState([]);

  const firstRender = useRef(true);
  useEffect(() => {
    async function fetchData() {
      try {
        await getTireAlerts(movementTireId, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (firstRender.current && query.search) {
      if (Object.keys(filter).length > 0) {
        fetchData();
      }
    } else {
      fetchData();
    }

    return () => {
      clearTireAlerts();
    };
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(alerts.data[0].movement_tire.tire_cycle.tire.tire_id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (alerts.data.length > 0 && !tire.tire_id) {
      fetchData();
    }

    return () => {
      clearTire();
    };
  }, [alerts.data.length]);

  useEffect(() => {
    const params = new URLSearchParams(query.search);
    if (params.has("priority")) {
      setAlertOfTireFilters({
        ...filter,
        priority: params.get("priority").toUpperCase(),
      });
    }

    firstRender.current = false;
    return () => {
      clearAlertsOfTireFilters();
    };
  }, []);

  useEffect(() => {
    if (isSavingPdf && !pdfInstance.loading) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "AlertasNeumático.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  useEffect(() => {
    if (dataAlerts.length > 0) {
      setIsSavingPdf(true);
      // Se genera un UUID para forzar que el pdf se descargue de nuevo,
      // aunque su contenido sea idéntico al anterior
      const uuid =
        Date.now().toString(36) + Math.random().toString(36).substring(2);
      const pdfData = {
        uuid: uuid,
        date: moment()
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY hh:mm:ss a"),
        tire,
        alerts: dataAlerts,
      };
      updatePdfInstance(<AlertPdf {...pdfData} uuid={uuid} />);
    }
  }, [dataAlerts]);

  async function onChangePage(page) {
    try {
      await getTireAlerts(movementTireId, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(alertId) {
    setAlertId(alertId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setAlertId(null);
    setOpenForm(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleDelete(alert) {
    const { alert_id: alertId, movement_tire_id: movementTireId } = alert;
    setNotification({
      message: t("_messages.delete.alert"),
      textAction: t("_buttons.confirm"),
      onAction: () => onDelete(movementTireId, alertId),
    });
  }

  async function onDelete(movementTireId, alertId) {
    try {
      const response = await deleteTireAlert(movementTireId, alertId);
      setNotification({
        ...response,
      });
      getTireAlerts(movementTireId, { page: 1, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDownloadPdf() {
    try {
      const response = await getTireAlerts(
        movementTireId,
        {
          ...filter,
          flatten: 1,
        },
        false
      );
      setDataAlerts(response);
    } catch (error) {
      setNotification(error, true);
    }
  }
  return (
    <Page
      t={t}
      alerts={alerts}
      tire={tire}
      alertId={alertId}
      movementTireId={movementTireId}
      openForm={openForm}
      openFilter={openFilter}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleDelete={handleDelete}
      handleDownloadPdf={handleDownloadPdf}
    />
  );
}
AlertsByTire.propTypes = {};

const mapStateToProps = (state) => ({
  alerts: state.Tire.Alert.alerts,
  tire: state.Tire.Tire.tire,
  filter: state.Tire.Alert.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getTireAlerts: actionGetAlertsOfTire(dispatch),
  clearTireAlerts: actionClearAlertsOfTire(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTire: actionGetTire(dispatch),
  clearTire: actionClearTire(dispatch),
  clearAlertsOfTireFilters: actionClearAlertsOfTireFilters(dispatch),
  deleteTireAlert: actionDeleteTireAlert(dispatch),
  setAlertOfTireFilters: actionSetAlertsOfTireFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsByTire);
