export default {
  title: "Link warehouse",
  warehouse_title_card: "Linked warehouses",
  no_items: "No warehouses",
  messages: {
    delete: "Are you sure to unlink warehouse?",
    delete_warehouse_subsidiary:
      "Done, the warehouse has been unlinked from the subsidiary",
    attached_subsidiary_warehouse: "Done, the warehouse has been attached",
  },
  form: {
    warehouse: { default: "Select a warehouse" },
  },
  permits: {
    link: {
      subsidiary_warehouse: "No permissions to link warehouses to subsidiaries",
    },
    unlink: {
      subsidiary_warehouse:
        "No permissions to unlink warehouses from subsidiaries",
    },
  },
};
