import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import Page from "./page";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import moment from "moment-timezone";
import { findLineByLeastSquares } from "utils/trendLine";

export const NAMESPACE = "pages/review/HomeReview";

const colors = [
  "rgba(255, 99, 132, 1.0)",
  "rgba(54, 162, 235, 1.0)",
  "rgba(255, 206, 86, 1.0)",
  "rgba(75, 192, 192, 1.0)",
  "rgba(153, 102, 255, 1.0)",
  "rgba(255, 159, 64, 1.0)",
  "rgba(100, 159, 64, 1.0)",
  "rgba(255, 58, 86, 1.0)",
];

function SavingsTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const [originalVsRenewedData, setOriginalVsRenewedData] = useState({});
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [ahorrosData, setAhorrosData] = useState({});
  const [yearlyAhorrosData, setYearlyAhorrosData] = useState({});

  const { showProgress, setNotification, currentTab } = rest;

  useEffect(() => {
    let newSubsidiaries = [];
    if (
      originalVsRenewedData &&
      Object.keys(originalVsRenewedData).length > 0
    ) {
      newSubsidiaries = Object.keys(originalVsRenewedData);
    }
    newSubsidiaries = [...new Set(newSubsidiaries)];
    setSubsidiaries(newSubsidiaries);
  }, [originalVsRenewedData]);

  useEffect(() => {
    async function getOriginalVsRenewedData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/statistics/compared`,
          params: {
            group_by: currentTab,
          },
        });
        const newData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformDataOther(
            subsidiaryValues,
            "statistics",
            currentTab
          );
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData)) {
            datasets.push(
              getDataset(
                values,
                t(
                  `_labels.tire_condition.options.${key
                    .replaceAll(" ", "_")
                    .toLowerCase()}`
                ),
                false,
                subsidiary
              )
            );
          }
          newData[subsidiary] = {
            datasets: datasets,
          };
        }
        setOriginalVsRenewedData(newData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getOriginalVsRenewedData();
  }, [currentTab]);

  useEffect(() => {
    async function getAhorrosData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: `report/potencial/savings/dashboard`,
          params: {
            level: currentTab === "SUBSIDIARY" ? "subsidiary" : "company",
          },
        });
        const newData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformAhorrosData(
            subsidiaryValues,
            currentTab
          );
          const datasets = [];
          for (const [key, values] of Object.entries(transformedData)) {
            datasets.push(
              getDataset(
                values,
                t(`_labels.dashboard.savings.${key.toLowerCase()}`),
                false,
                subsidiary
              )
            );
          }
          newData[subsidiary] = {
            datasets: datasets,
          };
        }
        setAhorrosData(newData);
        const yearlyData = {};
        for (const [subsidiary, subsidiaryValues] of Object.entries(response)) {
          const transformedData = transformYearlyAhorrosData(
            subsidiaryValues,
            currentTab
          );
          const datasets = [];
          let i = 0;
          for (const [key, values] of Object.entries(transformedData.data)) {
            const color = colors[i];
            datasets.push(
              getDatasetYearly(
                values,
                t(`_labels.dashboard.savings.${key.toLowerCase()}`),
                subsidiary,
                "bar",
                color
              )
            );
            i += 1;
          }
          datasets.push(
            getDatasetYearly(
              transformedData.trend,
              t("_labels.trend"),
              null,
              "line",
              "transparent"
            )
          );
          yearlyData[subsidiary] = {
            datasets: datasets,
          };
        }
        setYearlyAhorrosData(yearlyData);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    getAhorrosData();
  }, [currentTab]);

  return (
    <Page
      t={t}
      subsidiaries={subsidiaries}
      originalVsRenewedData={originalVsRenewedData}
      ahorrosData={ahorrosData}
      yearlyAhorrosData={yearlyAhorrosData}
    />
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavingsTab);

function transformDataOther(
  subsidiaryObject,
  valueName = "statistics",
  currentTab
) {
  const newData = {};
  const totals = {};
  newData["total"] = {};
  let totalArray = [];
  for (const [condition, values] of Object.entries(subsidiaryObject)) {
    newData[condition] = [];
    for (const [date, value] of Object.entries(values)) {
      newData[condition].push({
        fecha: date,
        total: value[valueName],
        currentTab,
      });
      if (totals[date]) {
        totals[date] += value[valueName];
      } else {
        totals[date] = value[valueName];
      }
    }
    totalArray = [];
    for (const [date, value] of Object.entries(totals)) {
      totalArray.push({
        fecha: date,
        total: value,
        currentTab,
      });
    }

    newData[condition] = newData[condition].sort((a, b) => {
      return new Date(a.fecha) - new Date(b.fecha);
    });
  }
  newData["total"] = totalArray;
  newData["total"] = newData["total"].sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  return newData;
}

function transformAhorrosData(subsidiaryObject, currentTab) {
  const newData = {
    PRESSURE_SAVINGS: [],
    REVITALIZED_SAVINGS: [],
  };
  newData["total"] = {};
  const totals = {};
  let totalArray = [];
  for (const [date, values] of Object.entries(subsidiaryObject)) {
    newData.PRESSURE_SAVINGS.push({
      fecha: values.created_at.substring(0, 7),
      total: values.pressure_saving,
      currentTab,
    });
    newData.REVITALIZED_SAVINGS.push({
      fecha: values.created_at.substring(0, 7),
      total: values.tires_revitalized_saving,
      currentTab,
    });
    totals[date] = values.pressure_saving + values.tires_revitalized_saving;
  }
  totalArray = [];
  for (const [date, value] of Object.entries(totals)) {
    totalArray.push({
      fecha: date,
      total: value,
      currentTab,
    });
  }
  newData.PRESSURE_SAVINGS = newData.PRESSURE_SAVINGS.sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  newData.REVITALIZED_SAVINGS = newData.REVITALIZED_SAVINGS.sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  newData["total"] = totalArray;
  newData["total"] = newData["total"].sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  return newData;
}

function transformYearlyAhorrosData(subsidiaryObject, currentTab) {
  const newData = {
    PRESSURE_SAVINGS: [],
    REVITALIZED_SAVINGS: [],
  };
  const totals = {};
  let totalArray = [];
  for (const [date, values] of Object.entries(subsidiaryObject)) {
    const year = date.substring(0, 4);
    if (newData.PRESSURE_SAVINGS.find((el) => el.fecha === year)) {
      const index = newData.PRESSURE_SAVINGS.findIndex(
        (el) => el.fecha === year
      );
      newData.PRESSURE_SAVINGS[index].total += values.pressure_saving;
    } else {
      newData.PRESSURE_SAVINGS.push({
        fecha: year,
        total: values.pressure_saving,
        currentTab,
      });
    }
    if (newData.REVITALIZED_SAVINGS.find((el) => el.fecha === year)) {
      const index = newData.REVITALIZED_SAVINGS.findIndex(
        (el) => el.fecha === year
      );
      newData.REVITALIZED_SAVINGS[index].total +=
        values.tires_revitalized_saving;
    } else {
      newData.REVITALIZED_SAVINGS.push({
        fecha: year,
        total: values.tires_revitalized_saving,
        currentTab,
      });
    }
    if (totals[year]) {
      totals[year] += values.pressure_saving + values.tires_revitalized_saving;
    } else {
      totals[year] = values.pressure_saving + values.tires_revitalized_saving;
    }
  }
  for (const [date, value] of Object.entries(totals)) {
    totalArray.push({
      fecha: date,
      total: value,
      currentTab,
    });
  }
  newData.PRESSURE_SAVINGS = newData.PRESSURE_SAVINGS.sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  newData.REVITALIZED_SAVINGS = newData.REVITALIZED_SAVINGS.sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  totalArray = totalArray.sort((a, b) => {
    return new Date(a.fecha) - new Date(b.fecha);
  });
  const linearRegression = findLineByLeastSquares(
    totalArray.map((value, index) => index),
    totalArray.map((el) => el.total)
  );
  return {
    data: newData,
    trend: [
      {
        fecha: totalArray[0]?.fecha ?? moment().format("YYYY"),
        total: isNaN(linearRegression[1][0]) ? 0 : linearRegression[1][0],
      },
      {
        fecha:
          totalArray[totalArray.length - 1]?.fecha ?? moment().format("YYYY"),
        total: isNaN(linearRegression[1][linearRegression[1].length - 1])
          ? 0
          : linearRegression[1][linearRegression[1].length - 1],
      },
    ],
  };
}

function getDataset(data, label, disablePoints = false, subsidiary) {
  return {
    label: label,
    data: data.map((el) => ({
      ...el,
      fecha: moment(el.fecha).format("YYYY-MM"),
      subsidiary,
    })),
    yAxisID: "y",
    fill: false,
    pointWidth: 2,
    pointRadius: disablePoints ? 0 : 2,
    pointHoverRadius: 4,
    borderColor: colors,
    backgroundColor: colors,
    tension: 0.1,
    pointBackgroundColor: "rgb(50, 50, 50, 0.8)",
    pointBorderColor: "rgb(50, 50, 50, 0.8)",
  };
}

function getDatasetYearly(data, label, subsidiary, type, backgroundColor) {
  const trendOptions = {
    borderColor: "#444",
    backgroundColor: "transparent",
    borderDash: [7, 4],
    pointRadius: 0,
    pointHitRadius: 0,
    order: 1,
  };
  const options = {
    label: label,
    data: data.map((el) => ({
      ...el,
      fecha: moment(el.fecha).format("YYYY"),
      subsidiary,
    })),
    yAxisID: "y",
    fill: false,
    backgroundColor: backgroundColor,
    tension: 0.1,
    order: 2,
    type: type,
  };
  if (type === "line") {
    return {
      ...options,
      ...trendOptions,
    };
  }

  return options;
}
