import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddNotification = (dispatch) => async (fields) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();
  try {
    const response = await http({
      method: "POST",
      path: "notification",
      data: fields,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
