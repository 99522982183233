import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function EmptyListIcon({ text, width }) {
  return <Page text={text} width={width} />;
}

EmptyListIcon.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default EmptyListIcon;
