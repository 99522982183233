import { combineReducers } from "redux";

import Gps from "./gps";
import Vehicle from "./vehicle";
import Tire from "./tire";
import Division from "./division";
import Travel from "./travel";
import Subsidiary from "./subsidiary";
import Driver from "./driver";
import Type from "./type";
import Axle from "./axle";
import Alert from "./alert";
import Mount from "./mount";
import Review from "./review";
import MovementHistory from "./movementHistory";
import History from "./history";
import VehicleMount from "./vehicle_mount";

export default combineReducers({
  Gps,
  Vehicle,
  Tire,
  Division,
  Travel,
  Subsidiary,
  Driver,
  Type,
  Axle,
  Alert,
  Mount,
  Review,
  MovementHistory,
  History,
  VehicleMount,
});
