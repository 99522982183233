import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionSetVehicleAlertsFilters } from "store/actions/alert/vehicles/setVehicleAlertsFilters";
import { actionClearVehicleAlertsFilters } from "store/actions/alert/vehicles/clearVehicleAlertsFilters";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionSetNotification } from "store/actions/general/notification";
import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterVehicleAlertsForm({ open, onClose, ...rest }) {
  const t = useLang();

  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);

  const {
    filter,
    getBrands,
    getVehicleTypes,
    getVehicles,
    getDrivers,
    setNotification,
    setVehicleAlertsFilters,
    clearVehicleAlertsFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      vehicle_brand_id: [],
      vehicle_type_id: "",
      vehicle: "",
      driver_id: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        vehicle_brand_id: filter.vehicle_brand_id
          ? filter.vehicle_brand_id.split(",")
          : [],
        vehicle_type_id: filter.vehicle_type_id || "",
        vehicle: filter.vehicle || "",
        driver_id: filter.driver_id || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataBrands = [];
        dataBrands = await getBrands(
          {
            scope: "brand_type,brand_id,name,status",
            brandType: "VEHICLE",
          },
          false
        );

        let dataVehicleTypes = [];
        dataVehicleTypes = await getVehicleTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );

        let dataVehicles = [];
        dataVehicles = await getVehicles(
          {
            scope: "vehicle_id,economic_number,status",
          },
          false
        );
        let dataDrivers = [];
        dataDrivers = await getDrivers(
          { scope: "driver_id,name,status" },
          false
        );

        let dataVehicleBrands = [];
        dataVehicleBrands = dataBrands
          .filter((brand) => brand.brand_type === "VEHICLE")
          .map((brand) => ({
            brand_id: brand.brand_id,
            name: brand.name,
            status: brand.status,
          }));

        dataDrivers = dataDrivers.map((driver) => {
          return {
            driver_id: driver.driver_id,
            name: `${driver.name}`.trim(),
            status: driver.status,
          };
        });

        setVehicleBrands(dataVehicleBrands.sort(alphabeticalSort("name")));
        setVehicleTypes(dataVehicleTypes.sort(alphabeticalSort("name")));
        setVehicles(dataVehicles.sort(alphabeticalSort("economic_number")));
        setDrivers(dataDrivers.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  function handleClean() {
    clearVehicleAlertsFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.vehicle_brand_id.length > 0) {
        params.vehicle_brand_id = values.vehicle_brand_id.join();
      }

      if (values.vehicle_type_id) {
        params.vehicle_type_id = values.vehicle_type_id;
      }

      if (values.vehicle) {
        params.vehicle_id = values.vehicle;
      }

      if (values.driver_id) {
        params.driver_id = values.driver_id;
      }

      await setVehicleAlertsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      vehicleBrands={vehicleBrands}
      vehicleTypes={vehicleTypes}
      vehicles={vehicles}
      drivers={drivers}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterVehicleAlertsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Alert.VehicleAlerts.filter,
  account: state.Account.account,
  permits: state.Account.permits,
});

const mapDispatchToProps = (dispatch) => ({
  setVehicleAlertsFilters: actionSetVehicleAlertsFilters(dispatch),
  clearVehicleAlertsFilters: actionClearVehicleAlertsFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
  getBrands: actionGetBrands(dispatch),
  getVehicleTypes: actionGetVehiclesTypes(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  getDrivers: actionGetDrivers(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterVehicleAlertsForm);
