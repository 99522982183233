import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetBestTires =
  (dispatch) => async (vehicleId, vehicleTypeAxleTireId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `vehicle/${vehicleId}/best/tire`,
        params: {
          vehicle_type_axle_tire_id: vehicleTypeAxleTireId,
        },
      });

      // dispatch({
      //   type: GET_VEHICLE_TIRE,
      //   payload: response,
      // });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
