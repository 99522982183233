import {
  GET_VEHICLE_TRAVEL_HISTORY,
  CLEAR_VEHICLE_TRAVEL_HISTORY,
  GET_VEHICLE_TRAVELS_HISTORY,
  CLEAR_VEHICLE_TRAVELS_HISTORY,
  CLEAR_VEHICLE_TRAVEL_HISTORY_FILTERS,
  SET_VEHICLE_TRAVEL_HISTORY_FILTERS,
} from "store/actions";

const initialState = {
  filter: {},
  travel: {
    date: "",
    odometer: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
  travels: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_TRAVEL_HISTORY:
      return {
        ...state,
        travel: action.payload,
      };

    case CLEAR_VEHICLE_TRAVEL_HISTORY:
      return {
        ...state,
        travel: initialState.travel,
      };

    case GET_VEHICLE_TRAVELS_HISTORY:
      return {
        ...state,
        travels: action.payload,
      };

    case CLEAR_VEHICLE_TRAVELS_HISTORY:
      return {
        ...state,
        travels: initialState.travels,
      };
    case SET_VEHICLE_TRAVEL_HISTORY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_VEHICLE_TRAVEL_HISTORY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
