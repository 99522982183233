import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { alphabeticalSort } from "utils/sort";
import { useLang } from "hooks/lang";

import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionSetHelmetValueFilters } from "store/actions/subsidiary/helmet_value/setHelmetValueFilters";
import { actionClearHelmetValueFilters } from "store/actions/subsidiary/helmet_value/clearHelmetValueFilters";
import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";

import Page from "./page";

export function FilterHelmetValueForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    getTireModels,
    setHelmetValueFilters,
    clearHelmetValueFilters,
    setNotification,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      tire_model: null,
      tire_model_variation: "",
    },
  });

  const [tireModels, setTireModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireModels(
          {
            scope:
              "tire_model_id,tire_model.brand_id,tire_model.name,tire_model.status,tire_model.approved",
          },
          false
        );

        const uniqueModels = data.reduce((result, model) => {
          if (!result.find((el) => el.tire_model_id == model.tire_model_id)) {
            result.push(model);
          }
          return result;
        }, []);

        setTireModels(
          uniqueModels
            .map((model) => ({
              tire_model_id: model.tire_model_id,
              brand_id: model.tire_model.brand_id,
              name: model.tire_model.name,
              status: model.tire_model.status,
              approved: model.tire_model.approved,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        tire_model: filter.tire_model
          ? tireModels.find((e) => e.tire_model_id == filter.tire_model)
          : null,
        tire_model_variation: filter.tire_model_variation || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearHelmetValueFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.tire_model) {
        params.tire_model = values.tire_model.tire_model_id;
      }

      if (values.tire_model_variation !== "") {
        params.tire_model_variation = values.tire_model_variation;
      }

      await setHelmetValueFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      open={open}
      tireModels={tireModels}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleClean={handleClean}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      t={t}
    />
  );
}

FilterHelmetValueForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filter: state.Subsidiary.HelmetValue.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getTireModels: actionGetTireModels(dispatch),
  setHelmetValueFilters: actionSetHelmetValueFilters(dispatch),
  clearHelmetValueFilters: actionClearHelmetValueFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterHelmetValueForm);
