export function getMinDepth(depthReview) {
  const res = Math.min(
    depthReview.average_depth_external,
    depthReview.average_depth_internal,
    depthReview.average_depth_middle
  );

  return Number.isNaN(res) ? 0 : res;
}

export const LOCATIONS = {
  WAREHOUSE: "WAREHOUSE",
  MOUNT: "MOUNT",
  REPAIR: "REPAIR",
  REVITALIZATION: "REVITALIZATION",
  PILE: "PILE",
};

export const CONDITION_TYPES = [
  {
    value: "ORIGINAL_NEW",
    label: "_labels.tire_condition.options.original_new",
  },
  {
    value: "ORIGINAL_USED",
    label: "_labels.tire_condition.options.original_used",
  },
  { value: "RETREAD_NEW", label: "_labels.tire_condition.options.retread_new" },
  {
    value: "RETREAD_USED",
    label: "_labels.tire_condition.options.retread_used",
  },
];

export function toggleTable(selector, key, type) {
  let retirement = selector.split("_").slice(0, -1).join("_");
  retirement = removeCharacters(retirement + "_causes");
  const elements = Array.from(
    document.querySelectorAll(
      `[data-id=${CSS.escape(
        removeCharacters(selector)
      )}],[data-id=${retirement}]`
    )
  );
  elements.forEach((element) => {
    if (element.dataset.type === type) {
      element.classList.toggle("collapse");
    }
  });

  elements
    .filter((item) => item.dataset.type === type)
    .forEach((element) => {
      if (element.classList.contains("collapse")) {
        Array.from(
          document.querySelectorAll(
            `[data-id*=${CSS.escape(removeCharacters(key))}]`
          )
        ).forEach((subelement) => {
          if (subelement.dataset.type === type && element != subelement) {
            subelement.classList.add("collapse");
          }
        });
      }
    });
}

export function removeCharacters(string, replacer = "") {
  return string.replaceAll(/[,\s\.\/|\(\)]/g, replacer);
}

export function tooltipLabel(context, label) {
  const percentFormatter = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  return `${context.label}: ${
    context.parsed
  } ${label} ${percentFormatter.format(context.raw.percent)}%`;
}
