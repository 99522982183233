import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDamagesWears } from "store/actions/reports/damages_wears/getDamagesWears";
import { actionClearDamagesWearsFilters } from "store/actions/reports/damages_wears/clearDamagesWearsFilters";
import { actionGenerateDamagesWearsExcel } from "store/actions/reports/damages_wears/generateDamagesWearsExcel";
import { actionSetSelectedDamagesWearsFilters } from "store/actions/reports/damages_wears/setSelectedDamagesWearsFilters";
import { actionSetDamagesWearsFilters } from "store/actions/reports/damages_wears/setDamagesWearsFilters";

import { useLang } from "hooks/lang";
import Page from "./page";
import moment from "moment-timezone";

function DamagesWears({ ...rest }) {
  const t = useLang();

  const {
    filter,
    selectedFilter,
    setNotification,
    getDamagesWears,
    clearDamagesWearsFilters,
    generateDamagesWearsExcel,
    setSelectedFilters,
    setDamagesWearsFilters,
  } = rest;
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDamagesWears({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    const filter = {
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilters = {
      date_from: startDate,
      date_to: endDate,
    };
    setDamagesWearsFilters(filter);
    setSelectedFilters(selectedFilters);
    return () => {
      clearDamagesWearsFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateDamagesWearsExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      data={data}
      dataChart={getDataChart(t, data)}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
    />
  );
}
DamagesWears.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.DamagesWears.filter,
  selectedFilter: state.Reports.DamagesWears.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getDamagesWears: actionGetDamagesWears(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearDamagesWearsFilters: actionClearDamagesWearsFilters(dispatch),
  generateDamagesWearsExcel: actionGenerateDamagesWearsExcel(dispatch),
  setSelectedFilters: actionSetSelectedDamagesWearsFilters(dispatch),
  setDamagesWearsFilters: actionSetDamagesWearsFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DamagesWears);

function getDataChart(t, data) {
  const result = {
    damageStatistics: {},
    damage: {},
    wearStatistics: {},
    wear: {},
  };
  if (data.damage && Object.keys(data.damage).length > 0) {
    const damage = {};
    Object.keys(data.damage)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        damage[corporate] = {};
        Object.keys(data.damage[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            damage[corporate][company] = {};
            Object.keys(data.damage[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                damage[corporate][company][subsidiary] = {};
                Object.keys(data.damage[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const acc = {};
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    const divisionName = division.includes("NO DIVI")
                      ? t("_labels.no_division")
                      : division;
                    data.damage[corporate][company][subsidiary][division][
                      "damages"
                    ].forEach((damage) => {
                      damage.models.forEach((model) => {
                        if (!acc[model.model]) {
                          acc[model.model] = model["statistics"];
                          const [red, green, blue] = getRandomColor();
                          colors.push(`rgba(${red}, ${green}, ${blue},0.55)`);
                          borderColors.push(
                            `rgba(${red}, ${green}, ${blue},1)`
                          );
                        } else {
                          acc[model.model] += model["statistics"];
                        }
                      });
                    });
                    chart.labels = Object.keys(acc);
                    chart.datasets = [
                      {
                        data: Object.values(acc),
                        backgroundColor: colors,
                        borderColor: borderColors,
                        borderWidth: 1,
                      },
                    ];
                    damage[corporate][company][subsidiary][divisionName] =
                      chart;
                  });
              });
          });
      });

    result.damage = damage;
  }

  if (data.damageStatistics && Object.keys(data.damageStatistics).length > 0) {
    const chart = {};
    let damageStatistics = {};
    const info = [];
    const colors = [];
    const borderColors = [];
    chart.labels = [];
    chart.datasets = [];
    Object.keys(data.damageStatistics)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((name) => {
        let [red, green, blue] = getRandomColor();
        chart.labels.push(
          t(
            `_damage.name.${data.damageStatistics[name]["damage"]}`
          ).toUpperCase()
        );
        info.push(data.damageStatistics[name]["statistics"]);
        colors.push(`rgba(${red}, ${green}, ${blue},0.55)`);
        borderColors.push(`rgba(${red}, ${green}, ${blue},1)`);
      });
    damageStatistics = {
      datasets: [
        {
          data: info,
          backgroundColor: colors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
      labels: chart.labels,
    };
    result.damageStatistics = damageStatistics;
  }

  if (data.wear && Object.keys(data.wear).length > 0) {
    const wear = {};
    Object.keys(data.wear)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        wear[corporate] = {};
        Object.keys(data.wear[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            wear[corporate][company] = {};
            Object.keys(data.wear[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                wear[corporate][company][subsidiary] = {};
                Object.keys(data.wear[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const acc = {};
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    const divisionName = division.includes("NO DIVI")
                      ? t("_labels.no_division")
                      : division;
                    data.wear[corporate][company][subsidiary][division][
                      "wears"
                    ].forEach((wear) => {
                      wear.models.forEach((model) => {
                        if (!acc[model.model]) {
                          acc[model.model] = model["statistics"];
                          const [red, green, blue] = getRandomColor();
                          colors.push(`rgba(${red}, ${green}, ${blue},0.55)`);
                          borderColors.push(
                            `rgba(${red}, ${green}, ${blue},1)`
                          );
                        } else {
                          acc[model.model] += model["statistics"];
                        }
                      });
                    });
                    chart.labels = Object.keys(acc);
                    chart.datasets = [
                      {
                        data: Object.values(acc),
                        backgroundColor: colors,
                        borderColor: borderColors,
                        borderWidth: 1,
                      },
                    ];
                    wear[corporate][company][subsidiary][divisionName] = chart;
                  });
              });
          });
      });

    result.wear = wear;
  }

  if (data.wearStatistics && Object.keys(data.wearStatistics).length > 0) {
    const chart = {};
    let wearStatistics = {};
    const info = [];
    const colors = [];
    const borderColors = [];
    chart.labels = [];
    chart.datasets = [];
    Object.keys(data.wearStatistics)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((name) => {
        let [red, green, blue] = getRandomColor();
        chart.labels.push(
          t(`_damage.name.${data.wearStatistics[name]["wear"]}`).toUpperCase()
        );
        info.push(data.wearStatistics[name]["statistics"]);
        colors.push(`rgba(${red}, ${green}, ${blue},0.55)`);
        borderColors.push(`rgba(${red}, ${green}, ${blue},1)`);
      });
    wearStatistics = {
      datasets: [
        {
          data: info,
          backgroundColor: colors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
      labels: chart.labels,
    };
    result.wearStatistics = wearStatistics;
  }

  return result;
}

function getRandomColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return [red, green, blue];
}
