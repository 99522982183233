export function UrlValidate(url) {
  return !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i.test(
    url || ""
  );
}

export function RequiredValidate(field) {
  if (field === null || field === undefined) {
    return true;
  }

  return typeof field === "string" && field.trim() === "";
}

export function IsImageUrl(url) {
  return /\.(jpeg|jpg|gif|png|bmp)$/.test(url);
}

export function isNumber(field) {
  return !isNaN(field.trim());
}

export function isWholeNumber(field) {
  const number = field.trim();
  return (
    number === "" || (isNumber(number) && Number.isInteger(parseFloat(number)))
  );
}
