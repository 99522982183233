import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import Page from "./page";

export function VehicleDialog({ data, ...rest }) {
  const t = useLang();

  const sortedData = getSortedData(data, t);
  return <Page {...rest} t={t} data={sortedData} />;
}

VehicleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  division: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDialog);

function getSortedData(data, t) {
  return data.sort((a, b) => vehicleSort(a, b, t));
}

function vehicleSort(a, b, t) {
  if (
    t(`_labels.structure_type.options.${a["structure_type"].toLowerCase()}`) <
    t(`_labels.structure_type.options.${b["structure_type"].toLowerCase()}`)
  ) {
    return -1;
  }

  if (
    t(`_labels.structure_type.options.${a["structure_type"].toLowerCase()}`) >
    t(`_labels.structure_type.options.${b["structure_type"].toLowerCase()}`)
  ) {
    return 1;
  }

  if (a["type"] < b["type"]) {
    return -1;
  }

  if ([a["type"] > b["type"]]) {
    return 1;
  }

  if (a["economic_number"] < b["economic_number"]) {
    return -1;
  }

  if (a["economic_number"] > b["economic_number"]) {
    return -1;
  }

  return 0;
}
