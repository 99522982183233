import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionClearCompanies } from "store/actions/company/clearCompanies";
import { actionSetCompanyFilters } from "store/actions/company/setCompanyFilters";
import { actionClearCompanyFilters } from "store/actions/company/clearCompanyFilters";
import { actionDeleteCompany } from "store/actions/company/deleteCompany";
import { actionSetStatusCompany } from "store/actions/company/setStatusCompany";
import { actionGetCompany } from "store/actions/company/getCompany";
import { actionAddCompany } from "store/actions/company/addCompany";
import { actionUpdateCompany } from "store/actions/company/updateCompany";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeCompany({ ...rest }) {
  const t = useLang();
  const {
    permits,
    company,
    companies,
    filter,
    account,
    deleteCompany,
    setStatusCompany,
    getCompany,
    addCompany,
    updateCompany,
    getCompanies,
    getCorporates,
    clearCompanies,
    setCompanyFilters,
    clearCompanyFilters,
    setNotification,
  } = rest;
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getCorporates(
            { scope: "corporate_id,name,status" },
            false
          );
        } else if (Array.isArray(account.corporates)) {
          data = account.corporates.map((corporate) => ({
            corporate_id: corporate.corporate.corporate_id,
            name: corporate.corporate.name,
            status: corporate.corporate.status,
          }));
        }
        setCorporates(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  return (
    <Page
      companies={companies}
      company={company}
      filter={filter}
      corporates={corporates}
      createCompanyPermit={permits.includes(CREATE_COMPANY)}
      updateCompanyPermit={permits.includes(UPDATE_COMPANY)}
      deleteCompanyPermit={permits.includes(DELETE_COMPANY)}
      t={t}
      getCompany={getCompany}
      addCompany={addCompany}
      updateCompany={updateCompany}
      clearCompanyFilters={clearCompanyFilters}
      setCompanyFilters={setCompanyFilters}
      deleteCompany={deleteCompany}
      setStatusCompany={setStatusCompany}
      getCompanies={getCompanies}
      clearCompanies={clearCompanies}
    />
  );
}

HomeCompany.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  companies: state.Company.Company.companies,
  filter: state.Company.Company.filter_company,
  company: state.Company.Company.company,
  account: state.Account.account,
});
const mapDispatchToProps = (dispatch) => ({
  getCompanies: actionGetCompanies(dispatch),
  clearCompanies: actionClearCompanies(dispatch),
  setCompanyFilters: actionSetCompanyFilters(dispatch),
  clearCompanyFilters: actionClearCompanyFilters(dispatch),
  deleteCompany: actionDeleteCompany(dispatch),
  setStatusCompany: actionSetStatusCompany(dispatch),
  getCompany: actionGetCompany(dispatch),
  addCompany: actionAddCompany(dispatch),
  updateCompany: actionUpdateCompany(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCompany);
