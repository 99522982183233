import { combineReducers } from "redux";

import Division from "./division";
import Vehicle from "./vehicle";
import Tire from "./tire";

export default combineReducers({
  Division,
  Vehicle,
  Tire,
});
