export default {
  title: "Nuevo Neumático",
  update_title: "Actualizar Neumático",
  form: {
    permits: {
      update: "Sin permisos para actualizar neumáticos",
      delete: "Sin permisos para eliminar neumáticos",
    },
  },
  messages: {
    tire_created: "Listo, se creo un nuevo neumático con éxito",
    tire_updated: "Listo, se ha actualizado el neumático con éxito",
    delete: "¿Estas seguro de eliminar el neumático?",
  },
};
