import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({ t, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        fullWidth
        required
        variant="filled"
        name="axle_type"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.axle_field.label")}
        disabled
      >
        <MenuItem value="DIRECTIONAL">
          {t("_labels.axle_field.options.directional")}
        </MenuItem>
        <MenuItem value="TRACTION">
          {t("_labels.axle_field.options.traction")}
        </MenuItem>
        <MenuItem value="TRAILER">
          {t("_labels.axle_field.options.trailer")}
        </MenuItem>
        <MenuItem value="REFECTION">
          {t("_labels.axle_field.options.refection")}
        </MenuItem>
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="good_depth"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.good_condition")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="scheduled_withdrawal"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.scheduled_withdrawal")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="critical_withdrawal"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.critical_withdrawal")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.update")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
