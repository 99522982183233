import { combineReducers } from "redux";

import Size from "./size";
import Model from "./model";
import Subsidiary from "./subsidiary";
import Application from "./application";
import Use from "./use";
import Tire from "./tire";
import Damage from "./damage";
import Wear from "./wear";
import Warehouse from "./warehouse";
import Cycle from "./cycle";
import Repair from "./repair";
import Rfid from "./rfid";
import Division from "./division";
import History from "./history";
import ReviewHistory from "./review_history";
import Review from "./review";
import Alert from "./alert";

export default combineReducers({
  Size,
  Model,
  Subsidiary,
  Application,
  Use,
  Tire,
  Damage,
  Wear,
  Warehouse,
  Cycle,
  Repair,
  Rfid,
  Division,
  History,
  ReviewHistory,
  Review,
  Alert,
});
