import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionClearVehicles } from "store/actions/vehicle/clearVehicles";
import { actionSetVehicleFilters } from "store/actions/vehicle/setVehicleFilters";
import { actionClearVehicleFilters } from "store/actions/vehicle/clearVehicleFilters";
import { actionDeleteVehicle } from "store/actions/vehicle/deleteVehicle";
import { actionSetVehicleStatus } from "store/actions/vehicle/setVehicleStatus";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";
import { actionAddVehicle } from "store/actions/vehicle/addVehicle";
import { actionUpdateVehicle } from "store/actions/vehicle/updateVehicle";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDivisions } from "store/actions/division/getDivisions";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import {
  CREATE_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  LIST_SUBSIDIARY,
  RESET_VEHICLE_REVIEW,
  UPDATE_LINK_VEHICLE_WITH_GPS,
} from "store/actions/account/permits";

import { alphabeticalSort } from "utils/sort";
import { useLang } from "hooks/lang";

import Page from "./page";
import { actionResetGpsDifference } from "store/actions/vehicle/gps/resetGpsDifference";
import { actionSetSelectedVehicleFilters } from "store/actions/vehicle/setSelectedVehicleFilters";
import { actionGetSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/getSubsidiaryPolicy";
import { actionClearSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/clearSubsidiaryPolicy";
import { getSubsidiaryViaWorkArea } from "utils/workArea";

export function HomeVehicle({ ...rest }) {
  const t = useLang();
  const {
    policy,
    permits,
    vehicle,
    vehicles,
    filter,
    account,
    workArea,
    getVehicle,
    addVehicle,
    updateVehicle,
    deleteVehicle,
    setVehicleStatus,
    resetGpsDiff,
    getSubsidiaries,
    getDivisions,
    getVehicleTypes,
    getDrivers,
    getBrands,
    getVehicles,
    clearVehicles,
    setVehicleFilters,
    clearVehicleFilters,
    setNotification,
    selectedFilter,
    setSelectedFilters,
    getSubsidiaryPolicy,
    clearSubsidiaryPolicy,
  } = rest;

  const [subsidiaries, setSubsidiaries] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleBrands, setVehicleBrands] = useState([]);
  const [engineBrands, setEngineBrands] = useState([]);
  const [transmissionBrands, setTransmissionBrands] = useState([]);
  const [openExcelForm, setOpenExcelForm] = useState(false);
  const [openMoveVehicleForm, setOpenMoveVehicleForm] = useState(false);
  const [openResetOdometerForm, setOpenResetOdometerForm] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [openLinkTpmsByExcelForm, setOpenLinkTpmsByExcelForm] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataSubsidiaries = [];
        let dataDivisions = [];
        let dataVehicleTypes = [];
        let dataDrivers = [];
        let dataBrands = [];
        let dataVehicleBrands = [];
        let dataEngineBrands = [];
        let dataTransmissionBrands = [];

        if (permits.includes(LIST_SUBSIDIARY)) {
          dataSubsidiaries = await getSubsidiaries(
            { scope: "subsidiary_id,name,status" },
            false
          );
        } else if (Array.isArray(account.subsidiaries)) {
          dataSubsidiaries = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: subsidiary.subsidiary.name,
            status: subsidiary.subsidiary.status,
          }));
        }

        dataDivisions = await getDivisions(
          { scope: "division_id,name,status,subsidiary.name" },
          false
        );

        dataVehicleTypes = await getVehicleTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );

        dataDrivers = await getDrivers(
          { scope: "driver_id,name,status" },
          false
        );

        dataBrands = await getBrands(
          {
            scope: "brand_type,brand_id,name,status,approved",
            brandType: "VEHICLE,VEHICLE_ENGINE,ENGINE_TRANSMISSION",
          },
          false
        );
        setSubsidiaries(dataSubsidiaries.sort(alphabeticalSort("name")));
        setDivisions(dataDivisions.sort(alphabeticalSort("name")));
        setVehicleTypes(dataVehicleTypes.sort(alphabeticalSort("name")));
        dataDrivers = dataDrivers.map((driver) => {
          return {
            driver_id: driver.driver_id,
            name: `${driver.name}`.trim(),
            status: driver.status,
          };
        });
        dataVehicleBrands = dataBrands
          .filter((brand) => brand.brand_type === "VEHICLE")
          .map((brand) => ({
            brand_id: brand.brand_id,
            name: brand.name,
            status: brand.status,
            approved: brand.approved,
          }));

        dataEngineBrands = dataBrands
          .filter((brand) => brand.brand_type === "VEHICLE_ENGINE")
          .map((brand) => ({
            brand_id: brand.brand_id,
            name: brand.name,
            status: brand.status,
          }));

        dataTransmissionBrands = dataBrands
          .filter((brand) => brand.brand_type === "ENGINE_TRANSMISSION")
          .map((brand) => ({
            brand_id: brand.brand_id,
            name: brand.name,
            status: brand.status,
          }));

        setDrivers(dataDrivers.sort(alphabeticalSort("name")));
        setVehicleBrands(dataVehicleBrands.sort(alphabeticalSort("name")));
        setEngineBrands(dataEngineBrands.sort(alphabeticalSort("name")));
        setTransmissionBrands(
          dataTransmissionBrands.sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const [assignedSubsidiary] = getSubsidiaryViaWorkArea(
          account,
          workArea
        );

        const subsidiary = !!assignedSubsidiary
          ? assignedSubsidiary.subsidiary
          : {};
        await getSubsidiaryPolicy(subsidiary.subsidiary_id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearSubsidiaryPolicy();
    };
  }, []);

  async function resetDiff(gpsId, vehicleId) {
    try {
      const response = await resetGpsDiff(gpsId, vehicleId);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleResetDiff(vehicleId, gpsId) {
    setNotification({
      onAction: () => resetDiff(gpsId, vehicleId),
      message: t("_messages.reset.difference"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenExcelForm() {
    setOpenExcelForm(true);
  }

  function handleCloseExcelForm() {
    setOpenExcelForm(false);
  }

  function setRequiredOdometer(value) {
    if (!!value) {
      return true;
    } else {
      return false;
    }
  }

  function handleOpenMoveVehicleForm(vehicleId) {
    setVehicleId(vehicleId);
    setOpenMoveVehicleForm(true);
  }

  function handleCloseMoveVehicleForm() {
    setVehicleId(null);
    setOpenMoveVehicleForm(false);
  }

  function handleOpenResetOdometerForm(vehicleId) {
    setVehicleId(vehicleId);
    setOpenResetOdometerForm(true);
  }

  function handleCloseResetOdometerForm() {
    setOpenResetOdometerForm(false);
    setVehicleId(null);
  }

  function handleOpenLinkTpmsByExcelForm() {
    setOpenLinkTpmsByExcelForm(true);
  }

  function handleCloseLinkTpmsByExcelForm() {
    setOpenLinkTpmsByExcelForm(false);
  }

  return (
    <Page
      policy={policy}
      vehicles={vehicles}
      vehicle={vehicle}
      filter={filter}
      subsidiaries={subsidiaries}
      divisions={divisions}
      drivers={drivers}
      vehicleTypes={vehicleTypes}
      vehicleBrands={vehicleBrands}
      engineBrands={engineBrands}
      transmissionBrands={transmissionBrands}
      createVehiclePermit={permits.includes(CREATE_VEHICLE)}
      updateVehiclePermit={permits.includes(UPDATE_VEHICLE)}
      deleteVehiclePermit={permits.includes(DELETE_VEHICLE)}
      resetVehicleReviewPermit={permits.includes(RESET_VEHICLE_REVIEW)}
      resetDifferencePermit={permits.includes(UPDATE_LINK_VEHICLE_WITH_GPS)}
      t={t}
      getVehicle={getVehicle}
      addVehicle={addVehicle}
      updateVehicle={updateVehicle}
      clearVehicleFilters={clearVehicleFilters}
      setVehicleFilters={setVehicleFilters}
      deleteVehicle={deleteVehicle}
      setVehicleStatus={setVehicleStatus}
      getVehicles={getVehicles}
      clearVehicles={clearVehicles}
      handleResetDiff={handleResetDiff}
      handleOpenExcelForm={handleOpenExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      openExcelForm={openExcelForm}
      setRequiredOdometer={setRequiredOdometer}
      openMoveVehicleForm={openMoveVehicleForm}
      vehicleId={vehicleId}
      handleOpenMoveVehicleForm={handleOpenMoveVehicleForm}
      handleCloseMoveVehicleForm={handleCloseMoveVehicleForm}
      selectedFilter={selectedFilter}
      setSelectedFilters={setSelectedFilters}
      openResetOdometerForm={openResetOdometerForm}
      handleOpenResetOdometerForm={handleOpenResetOdometerForm}
      handleCloseResetOdometerForm={handleCloseResetOdometerForm}
      openLinkTpmsByExcelForm={openLinkTpmsByExcelForm}
      handleOpenLinkTpmsByExcelForm={handleOpenLinkTpmsByExcelForm}
      handleCloseLinkTpmsByExcelForm={handleCloseLinkTpmsByExcelForm}
    />
  );
}

HomeVehicle.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehicles: state.Vehicle.Vehicle.vehicles,
  filter: state.Vehicle.Vehicle.filter_vehicle,
  vehicle: state.Vehicle.Vehicle.vehicle,
  account: state.Account.account,
  workArea: state.Account.workArea,
  selectedFilter: state.Vehicle.Vehicle.selected_filter,
  policy: state.Subsidiary.SubsidiaryPolicy.Policy.policy,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicles: actionGetVehicles(dispatch),
  clearVehicles: actionClearVehicles(dispatch),
  setVehicleFilters: actionSetVehicleFilters(dispatch),
  clearVehicleFilters: actionClearVehicleFilters(dispatch),
  deleteVehicle: actionDeleteVehicle(dispatch),
  setVehicleStatus: actionSetVehicleStatus(dispatch),
  getVehicle: actionGetVehicle(dispatch),
  addVehicle: actionAddVehicle(dispatch),
  updateVehicle: actionUpdateVehicle(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getVehicleTypes: actionGetVehiclesTypes(dispatch),
  getDivisions: actionGetDivisions(dispatch),
  getDrivers: actionGetDrivers(dispatch),
  getBrands: actionGetBrands(dispatch),
  resetGpsDiff: actionResetGpsDifference(dispatch),
  setNotification: actionSetNotification(dispatch),
  setSelectedFilters: actionSetSelectedVehicleFilters(dispatch),
  getSubsidiaryPolicy: actionGetSubsidiaryPolicy(dispatch),
  clearSubsidiaryPolicy: actionClearSubsidiaryPolicy(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeVehicle);
