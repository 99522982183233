import { GET_TIRE_MODELS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireModels =
  (dispatch) =>
  async (
    {
      tire_model_id: model,
      search,
      status,
      page,
      revitalized,
      order = "DESC",
      scope,
      brands,
      tire_application: tireApplication,
      tire_size: tireSize,
      depth,
      maximum_pressure: maximumPressure,
      number_layers: numberLayers,
      approved,
      date_from: dateFrom,
      date_to: dateTo,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `tire/model`,
        params: {
          tire_model_variation_id: model,
          search,
          status,
          page,
          revitalized,
          order,
          scope,
          brands,
          sizes: tireSize,
          tire_application: tireApplication,
          depth,
          maximum_pressure: maximumPressure,
          number_layers: numberLayers,
          approved,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRE_MODELS,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
