import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { http } from "store/actions/http";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";
import { actionClearKmProjectionFilters } from "store/actions/reports/km_projection/clearKmProjectionFilters";
import { actionGenerateKmProjectionExcel } from "store/actions/reports/km_projection/generateKmProjectionExcel";
import { actionSetSelectedKmProjectionFilters } from "store/actions/reports/km_projection/setSelectedKmProjectionFilters";
import { actionSetKmProjectionFilters } from "store/actions/reports/km_projection/setKmProjectionFilters";

import { useLang } from "hooks/lang";
import Page from "./page";
import moment from "moment-timezone";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

function KmProjectionReport({ ...rest }) {
  const t = useLang();

  const [projectionData, setProjectionData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [vehicleReviewId, setVehicleReviewId] = useState(null);

  const {
    filter,
    selectedFilter,
    setNotification,
    clearKmProjectionFilters,
    generateKmProjectionExcel,
    setSelectedKmProjectionFilters,
    setKmProjectionFilters,
    showProgress,
    account,
    workArea,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      const progress = showProgress();
      try {
        const response = await http({
          method: "GET",
          path: "report/km/projection",
          params: {
            movement: "BOTH",
            ...filter,
          },
        });
        setProjectionData(response.general);
      } catch (error) {
        setNotification(error, true);
      } finally {
        showProgress(progress);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    setKmProjectionFilters(filter);
    setSelectedKmProjectionFilters(selectedFilters);

    return () => {
      clearKmProjectionFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateKmProjectionExcel() {
    try {
      await generateKmProjectionExcel({ movement: "BOTH", ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setVehicleReviewId(null);
  }

  function handleOpenTireDialog(vehicleReviewId) {
    setOpenTireDialog(true);
    setVehicleReviewId(vehicleReviewId);
  }

  function handleSearch(search) {
    if (search === "") {
      setKmProjectionFilters({ ...filter, search: undefined });
    } else {
      setKmProjectionFilters({ ...filter, search });
    }
  }

  async function handleClickApplication(data) {
    if (
      Object.values(
        projectionData[data.corporate][data.company][data.subsidiary][
          data.division
        ][data.tire_application]
      ).filter((e) => e !== null && typeof e === "object").length > 0
    ) {
      return;
    }
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: "report/km/projection/details",
        params: {
          movement: "BOTH",
          ...filter,
          corporate_name: data.corporate,
          company_name: data.company,
          subsidiary_name: data.subsidiary,
          division_name: data.division,
          tire_application: data.tire_application,
        },
      });
      setProjectionData((previousData) => {
        const newData = JSON.parse(JSON.stringify(previousData));
        newData[data.corporate][data.company][data.subsidiary][data.division][
          data.tire_application
        ] = response;
        return newData;
      });
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  return (
    <Page
      t={t}
      data={projectionData ?? {}}
      filter={filter}
      selectedFilter={selectedFilter}
      search={filter.search}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateKmProjectionExcel={handleGenerateKmProjectionExcel}
      handleClickApplication={handleClickApplication}
      openTireDialog={openTireDialog}
      handleOpenTireDialog={handleOpenTireDialog}
      handleCloseTireDialog={handleCloseTireDialog}
      handleSearch={handleSearch}
      vehicleReviewId={vehicleReviewId}
    />
  );
}
KmProjectionReport.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.KmProjection.filter,
  selectedFilter: state.Reports.KmProjection.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  clearKmProjectionFilters: actionClearKmProjectionFilters(dispatch),
  generateKmProjectionExcel: actionGenerateKmProjectionExcel(dispatch),
  setSelectedKmProjectionFilters:
    actionSetSelectedKmProjectionFilters(dispatch),
  setKmProjectionFilters: actionSetKmProjectionFilters(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KmProjectionReport);
