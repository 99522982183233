import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  alerts,
  alert,
  createAlertPermit,
  updateAlertPermit,
  deleteAlertPermit,
  filter,
  t,
  getAlert,
  addAlert,
  updateAlert,
  clearAlertFilters,
  setAlertFilters,
  deleteAlert,
  setAlertStatus,
  getAlerts,
  clearAlerts,
}) {
  return (
    <Home
      item={alert}
      name="alert"
      filter={filter}
      createTooltipPermit={!createAlertPermit ? t("_permits.create.alert") : ""}
      currentPage={alerts.current_page}
      deleteConfirmationMessage={t("_messages.delete.alert")}
      disableConfirmationMessage={t("_messages.change_status.alert.disable")}
      enableConfirmationMessage={t("_messages.change_status.alert.enable")}
      rows={alerts.data.map((alert) => {
        return {
          id: alert.alert_id,
          key: alert.alert_id,
          status: {
            status: alert.status,
            tooltipPermit: !updateAlertPermit ? t("_permits.update.alert") : "",
          },
          code: alert.code,
          priority: t(
            `_labels.priority.options.${alert.priority.toLowerCase()}`
          ),
          details: alert.details ? t(`_alert.details.${alert.details}`) : "",
          edit: {
            tooltipPermit: !updateAlertPermit ? t("_permits.update.alert") : "",
          },
          delete: {
            tooltipPermit: !deleteAlertPermit ? t("_permits.delete.alert") : "",
          },
        };
      })}
      title={t("_general.alerts")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "code", label: t("_labels.code") },
        { id: "priority", label: t("_labels.priority.label") },
        { id: "details", label: t("_labels.alert.details") },
        { id: "actions", label: "" },
      ]}
      lastPage={alerts.last_page}
      noItemsText={t("_general.no_alerts")}
      initialDefaultValues={{
        code: "",
        alert_type: "",
        priority: "",
        ranking_alert: "",
        details: "",
        suggestion: "",
      }}
      fields={[
        {
          name: "code",
          required: true,
          label: t("_labels.alert.code"),
        },
        {
          name: "priority",
          required: true,
          component: "select",
          label: t("_labels.priority.label"),
          selectItems: [
            { value: "HIGH", name: t("_labels.priority.options.high") },
            { value: "MEDIUM", name: t("_labels.priority.options.medium") },
            { value: "LOW", name: t("_labels.priority.options.low") },
          ],
        },
        {
          name: "alert_type",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.alert_type.label"),
          selectItems: [
            { value: "VEHICLE", name: t("_labels.alert_type.options.vehicle") },
            { value: "TIRE", name: t("_labels.alert_type.options.tire") },
            { value: "MOUNT", name: t("_labels.alert_type.options.mount") },
          ],
        },
        {
          name: "ranking_alert",
          required: true,
          component: "select",
          label: t("_labels.ranking_alert.label"),
          selectItems: [
            {
              value: "PRESSURE",
              name: t("_labels.ranking_alert.options.pressure"),
            },
            { value: "DEPTH", name: t("_labels.ranking_alert.options.depth") },
          ],
        },
        {
          name: "details",
          required: true,
          label: t("_labels.alert.details"),
          fullWidth: true,
        },
        {
          name: "suggestion",
          required: false,
          label: t("_labels.alert.suggestion"),
          fullWidth: true,
        },
      ]}
      createFormTitle={t("_titles.new.alert")}
      updateFormTitle={t("_titles.update.alert")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.alert"),
        },
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.alert")}
      updatedMessage={t("_messages.updated.alert")}
      getItemAction={getAlert}
      setFiltersAction={setAlertFilters}
      clearFiltersAction={clearAlertFilters}
      deleteAction={deleteAlert}
      setStatusAction={setAlertStatus}
      getItemsAction={getAlerts}
      clearItemsAction={clearAlerts}
      addAction={addAlert}
      updateAction={updateAlert}
    />
  );
}

Page.propTypes = {
  alert: PropTypes.object.isRequired,
  alerts: PropTypes.object.isRequired,
  createAlertPermit: PropTypes.bool.isRequired,
  updateAlertPermit: PropTypes.bool.isRequired,
  deleteAlertPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getAlert: PropTypes.func.isRequired,
  addAlert: PropTypes.func.isRequired,
  updateAlert: PropTypes.func.isRequired,
  clearAlertFilters: PropTypes.func.isRequired,
  setAlertFilters: PropTypes.func.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  setAlertStatus: PropTypes.func.isRequired,
  getAlerts: PropTypes.func.isRequired,
  clearAlerts: PropTypes.func.isRequired,
};

export default Page;
