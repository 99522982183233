import React from "react";
import { connect } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line, Bar } from "react-chartjs-2";

const chartAreaBackgroundPlugin = {
  id: "chartAreaBackgroundPlugin",
  beforeDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { left, top, width, height },
    } = chart;
    ctx.save();
    ctx.fillStyle = options.backgroundColor; // Color de fondo
    ctx.fillRect(left, top, width, height);
    ctx.restore();
  },
};

function ExportableChart({ ...rest }) {
  const { type, options, data } = rest;

  return (
    <div className="w-100 card mb-2 border-0">
      <div className="card-body px-2 py-1" style={{ height: "560px" }}>
        {type == "line" ? (
          <Line
            type={type}
            options={options}
            data={data}
            plugins={[ChartDataLabels, chartAreaBackgroundPlugin]}
          />
        ) : (
          <Bar
            type={type}
            options={options}
            data={data}
            plugins={[ChartDataLabels, chartAreaBackgroundPlugin]}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExportableChart);
