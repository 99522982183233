import React from "react";
import { useLang } from "hooks/lang";
import { useParams } from "react-router-dom";
import Page from "./page";
import { VIDEOS } from "utils/tutorials";

function Tutorial({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {} = rest;

  let flattenVideos = [];
  Object.values(VIDEOS).forEach((category) => {
    Object.values(category).forEach((videos) => {
      flattenVideos = flattenVideos.concat(videos);
    });
  });

  const video = flattenVideos.find((video) => video.id == id);
  return <Page t={t} video={video} />;
}

Tutorial.propTypes = {};

export default Tutorial;
