import {
  GET_LAST_VEHICLE_REVIEW,
  CLEAR_LAST_VEHICLE_REVIEW,
  REGISTER_VEHICLE_REVIEW,
  FINALIZE_VEHICLE_REVIEW,
  UPDATE_VEHICLE_REVIEW,
} from "store/actions";

const initialState = {
  vehicle_review: {
    vehicle_review_id: 0,
    odometer: 0,
    review_type: "",
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
  in_process: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FINALIZE_VEHICLE_REVIEW:
    case UPDATE_VEHICLE_REVIEW:
    case REGISTER_VEHICLE_REVIEW:
    case GET_LAST_VEHICLE_REVIEW:
      return {
        ...state,
        vehicle_review: action.payload,
      };

    case CLEAR_LAST_VEHICLE_REVIEW:
      return {
        ...state,
        vehicle_review: { ...initialState.vehicle_review },
      };

    case "SET_IN_PROCESS":
      return {
        ...state,
        in_process: action.payload,
      };

    default:
      return state;
  }
}
