export default {
  title: "Login",
  sub_title: "Enter your credentials to log in",
  form: {
    enter_button: "Enter",
    change_password_button: "Forgot your Password?",
  },
  messages: {
    login: "Done, you have successfully logged in.",
  },
};
