import React from "react";
import { Formik } from "formik";
import classnames from "classnames";
import PropTypes from "prop-types";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LabelAndText from "components/molecules/general/LabelAndText";
import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  vehicle,
  // policy,
  // vehicles,
  // isUpdate,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <DialogMediaQuery maxWidth="md" size="xs" open={open} onClose={onClose}>
      <DialogTitle>
        {t("_titles.new.policy")}
        {/* {isUpdate ? t("_titles.update.policy") : t("_titles.new.policy")} */}
      </DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <div className={classnames(css.horizontal)}>
          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.economic_number")}
            text={vehicle.economic_number || "-"}
          />

          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.auto_enrollment")}
            text={vehicle.enrollment || "-"}
          />

          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.vehicle_type.label")}
            text={vehicle.vehicle_type?.name}
          />
        </div>
        <Formik
          enableReinitialize
          initialErrors={initialErrors}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={handleValidate}
        >
          {(p) => (
            <Form
              onClose={onClose}
              // vehicles={vehicles}
              // isUpdate={isUpdate}
              // policy={policy}
              {...p}
            />
          )}
        </Formik>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // policy: PropTypes.object.isRequired,
  // vehicles: PropTypes.array.isRequired,
  vehicle: PropTypes.object,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  // isUpdate: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
