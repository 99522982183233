import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";

import Page from "./page";

export function VehiclePreviewTab({ ...rest }) {
  const [axles, setAxles] = useState([]);
  const { vehicle, setNotification, getVehicleTypeAxles } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehicleTypeAxles(
          {
            vehicleTypeId: vehicle.vehicle_type_id,
            status_axle: 1,
            scope:
              "color,position,axle_number,tire_application.tire_application_id,vehicle_type_axle_id",
          },
          false
        );

        setAxles(
          data.map((item) => ({
            color: item.color,
            position: item.position,
            axle_number: item.axle_number,
            tire_application: item.tire_application,
            tire_quantity: item.tire_quantity_count,
            vehicle_type_axle_tire: item.vehicle_type_axle_tire,
          }))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (vehicle.vehicle_type_id) {
      fetchData();
    }
  }, [vehicle.vehicle_type_id]);

  return (
    <Page axles={axles} structureType={vehicle.vehicle_type.structure_type} />
  );
}

VehiclePreviewTab.propTypes = {};

const mapStateToProps = (state) => ({
  vehicle: state.Vehicle.Vehicle.vehicle,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePreviewTab);
