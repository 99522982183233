import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import VehiclePreview from "components/molecules/general/vehicle_preview/Preview";

import css from "./index.module.scss";

function Page({
  t,
  title,
  className,
  structureType,
  axles,
  onClickTire,
  onRotateTire,
}) {
  return (
    <Card className={classnames(css.card, className)} elevation={3}>
      <CardContent className={classnames(css.content)}>
        <div className={classnames(css.mt16)}>
          <Typography
            variant="h5"
            color="textPrimary"
            className={classnames(css.mb8)}
          >
            {title || t("_labels.preview")}
          </Typography>

          <VehiclePreview
            structureType={structureType}
            axles={axles}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          ></VehiclePreview>
        </div>
      </CardContent>
    </Card>
  );
}

Page.propTypes = {
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  structureType: PropTypes.string.isRequired,
  axles: PropTypes.array.isRequired,
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

export default Page;
