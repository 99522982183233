import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import CheckboxField from "components/molecules/general/CheckboxField";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="only_empty_vehicles"
        component={CheckboxField}
        className={classnames(css.field)}
        label={t("_labels.only_empty_vehicles")}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
