import {
  GET_USER_COMPANIES_HISTORY,
  CLEAR_USER_COMPANIES_HISTORY,
  SET_USER_COMPANIES_HISTORY_FILTERS,
  CLEAR_USER_COMPANIES_HISTORY_FILTERS,
} from "store/actions";

const initialState = {
  companies: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_COMPANIES_HISTORY:
      return {
        ...state,
        companies: action.payload,
      };
    case CLEAR_USER_COMPANIES_HISTORY:
      return {
        ...state,
        companies: { ...initialState.companies },
      };

    case SET_USER_COMPANIES_HISTORY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_USER_COMPANIES_HISTORY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
