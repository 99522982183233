import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  damage,
  damages,
  updateDamagePermit,
  deleteDamagePermit,
  filter,
  t,
  getDamage,
  updateDamage,
  clearDamageFilters,
  setDamageFilters,
  deleteDamage,
  setDamageStatus,
  getDamages,
  clearDamages,
}) {
  return (
    <Home
      item={damage}
      name="damage"
      filter={filter}
      currentPage={damages.current_page}
      deleteConfirmationMessage={t("_messages.delete.damage")}
      disableConfirmationMessage={t("_messages.change_status.damage.disable")}
      enableConfirmationMessage={t("_messages.change_status.damage.enable")}
      rows={damages.data.map((damage) => {
        return {
          id: damage.damage_id,
          key: damage.damage_id,
          status: {
            status: damage.status,
            tooltipPermit: !updateDamagePermit
              ? t("_permits.update.damage")
              : "",
          },
          name: t(`_damage.name.${damage.name}`),
          appearance: `${
            damage.appearance
              ? t(`_damage.appearance.${damage.appearance}`)
              : ""
          }`,
          image: {
            tooltipPermit: !damage.image ? t("_general.no_image") : "",
            imageUrl: damage.image,
            title: t(`_damage.name.${damage.name}`),
          },
          edit: {
            tooltipPermit: !updateDamagePermit
              ? t("_permits.update.damage")
              : "",
          },
          delete: {
            tooltipPermit: !deleteDamagePermit
              ? t("_permits.delete.damage")
              : "",
          },
        };
      })}
      title={t("_general.damages")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "appearance ", label: t("_labels.appearance") },
        { id: "actions", label: "" },
      ]}
      lastPage={damages.last_page}
      noItemsText={t("_general.no_damages")}
      initialDefaultValues={{
        name: "",
        status: "",
        appearance: "",
        area: "",
        probable_causes: "",
        damage_category: "",
        action_tire: "",
        action_vehicle: "",
        operation: "",
        lock_cycles: false,
        image: null,
      }}
      fields={[
        {
          name: "image",
          accept: "image/*",
          placeholder: t("_labels.placeholder.image"),
          component: "file",
          fullWidth: true,
          required: false,
        },
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "appearance",
          required: false,
          label: t("_labels.appearance"),
        },
        {
          name: "probable_causes",
          required: false,
          label: t("_labels.probable_cause"),
        },
        {
          name: "operation",
          required: false,
          label: t("_labels.action_operation"),
        },
        {
          name: "action_vehicle",
          required: false,
          label: t("_labels.action_vehicle"),
        },
        {
          name: "action_tire",
          required: false,
          label: t("_labels.action_tire"),
        },
        {
          name: "damage_category",
          required: true,
          component: "select",
          label: t("_labels.category.label"),
          selectItems: [
            {
              value: "AVOIDABLE",
              name: t("_labels.category.options.avoidable"),
            },
            {
              value: "HELMET_FAILURE",
              name: t("_labels.category.options.helmet_failure"),
            },
            {
              value: "OPERATION",
              name: t("_labels.category.options.operation"),
            },
            {
              value: "RETREAD/REPAIR",
              name: t("_labels.category.options.retread_repair"),
            },
            {
              value: "ROAD_RISK",
              name: t("_labels.category.options.road_risk"),
            },
          ],
        },
        {
          name: "area",
          required: true,
          component: "select",
          label: t("_labels.area.label"),
          selectItems: [
            { value: "SIDEWALL", name: t("_labels.area.options.sidewall") },
            { value: "BEAD", name: t("_labels.area.options.bead") },
            { value: "TREAD", name: t("_labels.area.options.tread") },
            { value: "INSIDE", name: t("_labels.area.options.inside") },
            { value: "ANY", name: t("_labels.area.options.any") },
          ],
        },
        {
          name: "lock_cycles",
          required: false,
          component: "checkbox",
          label: t("_labels.lock_cycle"),
        },
      ]}
      createFormTitle={t("_titles.new.damage")}
      updateFormTitle={t("_titles.update.damage")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.damage"),
        },
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
      }}
      withImageDialog={true}
      createdMessage={""}
      updatedMessage={t("_messages.updated.damage")}
      getItemAction={getDamage}
      setFiltersAction={setDamageFilters}
      clearFiltersAction={clearDamageFilters}
      deleteAction={deleteDamage}
      setStatusAction={setDamageStatus}
      getItemsAction={getDamages}
      clearItemsAction={clearDamages}
      updateAction={updateDamage}
    />
  );
}

Page.propTypes = {
  damage: PropTypes.object.isRequired,
  damages: PropTypes.object.isRequired,
  updateDamagePermit: PropTypes.bool.isRequired,
  deleteDamagePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getDamage: PropTypes.func.isRequired,
  updateDamage: PropTypes.func.isRequired,
  clearDamageFilters: PropTypes.func.isRequired,
  setDamageFilters: PropTypes.func.isRequired,
  deleteDamage: PropTypes.func.isRequired,
  setDamageStatus: PropTypes.func.isRequired,
  getDamages: PropTypes.func.isRequired,
  clearDamages: PropTypes.func.isRequired,
};

export default Page;
