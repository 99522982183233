import { USER_LOGOUT, INTERVAL_REFRESH } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionLogout = (dispatch) => async () => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    await http({
      method: "POST",
      path: "auth/logout",
    });
  } catch (error) {
    console.error(error);
  }

  dispatch({
    type: INTERVAL_REFRESH,
    payload: null,
  });

  dispatch({
    type: USER_LOGOUT,
  });

  showProgress(progress);
};
