export default {
  title: "Change password",
  sub_title: "Register your new password",
  form: {
    labels: {
      password: "New password",
      confirm_password: "Confirm password",
    },
    enter_button: "Change password",
    back_button: "Sing in",
  },
  messages: {
    password_updated: "Done, your password was successfully updated.",
  },
};
