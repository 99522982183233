import React from "react";

import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "pages/account/ChangePassword/Form";

function Form({ ...rest }) {
  const t = useLang(NAMESPACE);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool,
};

export default Form;
