import { http } from "store/actions/http";
import { DELETE_TIRE_MODEL_VARIATION } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteTireModelVariation =
  (dispatch) => async (tireModelId, tireModelVariationId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: `DELETE`,
        path: `tire/model/${tireModelId}/variation/${tireModelVariationId}`,
      });
      dispatch({
        type: DELETE_TIRE_MODEL_VARIATION,
        payload: { tireModelId, tireModelVariationId },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
