import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionAddPressureVehicleTypePolicy } from "store/actions/subsidiary/policy/addPressureVehicleTypePolicy";
import { actionClearPressurePoliciesFilters } from "store/actions/subsidiary/policy/clearPressurePoliciesFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";
import { alphabeticalSort } from "utils/sort";

export function PressurePolicyForm({ open, onClose, subsidiaryId, ...rest }) {
  const t = useLang();
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const {
    addPressureVehicleTypePolicy,
    clearPressurePoliciesFilters,
    setNotification,
    getVehiclesTypes,
  } = rest;

  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    resetFormik,
  } = useFormik({
    initialValues: {
      vehicle_type: "",
      axle_type: "",
      recommended_pressure: "",
      tolerance: "",
    },
  });

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehiclesTypes(
          { scope: "vehicle_type_id,name,status" },
          false
        );
        setVehicleTypes(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const realFields = {
        vehicle_type_id: values.vehicle_type,
        axle_policy: [
          {
            axle_type: values.axle_type,
            recommended_pressure: values.recommended_pressure,
            tolerance: values.tolerance,
          },
        ],
      };
      await addPressureVehicleTypePolicy(subsidiaryId, realFields);
      resetForm();
      setNotification({
        message: t("_messages.created.pressure_policy"),
      });
      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      vehicleTypes={vehicleTypes}
    />
  );
}

PressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => ({
  pressurePolicy: state.Subsidiary.Policy.pressure_policy,
});

const mapDispatchToProps = (dispatch) => ({
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
  addPressureVehicleTypePolicy: actionAddPressureVehicleTypePolicy(dispatch),
  clearPressurePoliciesFilters: actionClearPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PressurePolicyForm);
