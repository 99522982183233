import { http } from "store/actions/http";
import { GET_TIRE_WAREHOUSE_HISTORY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireWarehouseHistory =
  (dispatch) =>
  async (warehouseId, tireId, warehouseTireId, withDispatch = true) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `warehouse/${warehouseId}/tire/${tireId}/history/${warehouseTireId}`,
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRE_WAREHOUSE_HISTORY,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
