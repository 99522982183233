import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";
import { http } from "store/actions/http";

import Page from "./page";

export function TireDialog({ open, vehicleReviewId, filter, ...rest }) {
  const t = useLang();
  const [tires, setTires] = useState([]);
  const { setNotification, showProgress } = rest;

  async function fetchData() {
    if (!vehicleReviewId) {
      return;
    }
    const progress = await showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/user/review/details/${vehicleReviewId}/tires`,
        params: filter ? { ...filter } : {},
      });
      setTires(
        response?.sort((a, b) => a.real_position - b.real_position) ?? []
      );
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
    }
  }

  useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setTires([]);
    }
  }, [open, vehicleReviewId]);

  return <Page {...rest} t={t} open={open} tires={tires} />;
}

TireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleReviewId: PropTypes.number,
  filter: PropTypes.object,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDialog);
