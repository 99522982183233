import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";
function Page({
  provider,
  providers,
  createProviderPermit,
  updateProviderPermit,
  deleteProviderPermit,
  filter,
  t,
  getProvider,
  addProvider,
  updateProvider,
  clearProviderFilters,
  setProviderFilters,
  deleteProvider,
  setProviderStatus,
  getProviders,
  clearProviders,
  subsidiaries,
}) {
  return (
    <Home
      item={provider}
      name="provider"
      filter={filter}
      createTooltipPermit={
        !createProviderPermit ? t("_permits.create.provider") : ""
      }
      currentPage={providers.current_page}
      deleteConfirmationMessage={t("_messages.delete.provider")}
      disableConfirmationMessage={t("_messages.change_status.provider.disable")}
      enableConfirmationMessage={t("_messages.change_status.provider.enable")}
      approveConfirmationMessage={t(
        "_messages.change_approved.provider.approved"
      )}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.provider.disapprove"
      )}
      rows={providers.data.map((provider) => {
        return {
          id: provider.provider_id,
          key: provider.provider_id,
          status: {
            status: provider.status,
            tooltipPermit: !updateProviderPermit
              ? t("_permits.update.provider")
              : "",
          },
          subsidiary: provider.subsidiary.name,
          rfc: provider.rfc,
          name: provider.name,
          observation: provider.observation,

          edit: {
            tooltipPermit: !updateProviderPermit
              ? t("_permits.update.provider")
              : "",
          },
          delete: {
            tooltipPermit: !deleteProviderPermit
              ? t("_permits.update.provider")
              : "",
          },
        };
      })}
      title={t("_general.providers")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "rfc", label: t("_labels.rfc") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "observation", label: t("_labels.observation") },
        { id: "actions", label: "" },
      ]}
      lastPage={providers.last_page}
      noItemsText={t("_general.no_providers")}
      initialDefaultValues={{
        rfc: "",
        subsidiary_id: "",
        name: "",
        observation: "",
      }}
      fields={[
        {
          name: "rfc",
          required: true,
          label: t("_labels.rfc"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "observation",
          required: false,
          label: t("_labels.observation"),
        },
      ]}
      createFormTitle={t("_titles.new.provider")}
      updateFormTitle={t("_titles.update.provider")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.provider"),
        },
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
      }}
      createdMessage={t("_messages.created.provider")}
      updatedMessage={t("_messages.updated.provider")}
      getItemAction={getProvider}
      setFiltersAction={setProviderFilters}
      clearFiltersAction={clearProviderFilters}
      deleteAction={deleteProvider}
      setStatusAction={setProviderStatus}
      getItemsAction={getProviders}
      clearItemsAction={clearProviders}
      addAction={addProvider}
      updateAction={updateProvider}
    />
  );
}

Page.propTypes = {
  provider: PropTypes.object.isRequired,
  providers: PropTypes.object.isRequired,
  createProviderPermit: PropTypes.bool.isRequired,
  updateProviderPermit: PropTypes.bool.isRequired,
  deleteProviderPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getProvider: PropTypes.func.isRequired,
  addProvider: PropTypes.func.isRequired,
  updateProvider: PropTypes.func.isRequired,
  clearProviderFilters: PropTypes.func.isRequired,
  setProviderFilters: PropTypes.func.isRequired,
  deleteProvider: PropTypes.func.isRequired,
  setProviderStatus: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  clearProviders: PropTypes.func.isRequired,
  subsidiaries: PropTypes.array.isRequired,
};

export default Page;
