import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCommissionedVehicles } from "store/actions/commissioned/vehicle/getCommissionedVehicles";
import { actionClearCommissionedVehicles } from "store/actions/commissioned/vehicle/clearCommissionedVehicles";
import { actionSetCommissionedVehicleStatus } from "store/actions/commissioned/vehicle/setCommissionedVehicleStatus";
import { actionDeleteCommissionedVehicle } from "store/actions/commissioned/vehicle/deleteCommissionedVehicle";
import { actionSetCommissionedVehicleFilters } from "store/actions/commissioned/vehicle/setCommissionedVehicleFilters";
import { actionClearCommissionedVehicleFilters } from "store/actions/commissioned/vehicle/clearCommissionedVehicleFilters";

import {
  CREATE_VEHICLE,
  DELETE_VEHICLE,
  UPDATE_VEHICLE,
} from "store/actions/account/permits";
import { useLang } from "hooks/lang";
import Page from "./page";

function HomeCommissionedVehicle({ ...rest }) {
  const t = useLang();
  const {
    vehicles,
    filter,
    permits,
    setNotification,
    getCommissionedVehicles,
    clearCommissionedVehicles,
    setCommissionedVehicleStatus,
    deleteCommissionedVehicle,
    setCommissionedVehicleFilters,
    clearCommissionedVehicleFilters,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [commissionedVehicleId, setCommissionedVehicleId] = useState(null);
  const [openExcelForm, setOpenExcelForm] = useState(false);

  const [commissionedVehicle, setCommissionedVehicle] = useState(null);
  const [openLinkForm, setOpenLinkForm] = useState(false);

  const [commissionedDriver, setCommissionedDriver] = useState(null);
  const [openDriverDialog, setOpenDriverDialog] = useState(false);

  const [openVehicleFullForm, setOpenVehicleFullForm] = useState(false);

  const [openVehicleFullInfo, setOpenVehicleFullInfo] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getCommissionedVehicles({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearCommissionedVehicles();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [vehicles.data]);

  useEffect(() => {
    return () => {
      clearCommissionedVehicleFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getCommissionedVehicles({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(commissionedVehicleId, status) {
    setNotification({
      message: status
        ? t("_messages.change_status.vehicle.disable")
        : t("_messages.change_status.vehicle.enable"),
      onAction: () => onChangeStatus(commissionedVehicleId, !status),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onChangeStatus(commissionedVehicleId, status) {
    try {
      const response = await setCommissionedVehicleStatus(
        commissionedVehicleId,
        status
      );
      setNotification({ message: response.message });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(commissionedVehicleId) {
    setNotification({
      message: t("_messages.delete.vehicle"),
      onAction: () => onDelete(commissionedVehicleId),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onDelete(commissionedVehicleId) {
    try {
      const response = await deleteCommissionedVehicle(commissionedVehicleId);
      setNotification({ message: response.message });
      clearCommissionedVehicleFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setCommissionedVehicleId(null);
  }

  function handleEdit(commissionedVehicleId) {
    setCommissionedVehicleId(commissionedVehicleId);
    setOpenForm(true);
  }

  function handleSearch(search) {
    setCommissionedVehicleFilters({ search });
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleOpenExcelForm() {
    setOpenExcelForm(true);
  }

  function handleCloseExcelForm() {
    setOpenExcelForm(false);
  }

  function handleOpenLinkForm(commissionedVehicle, commissionedDriver) {
    setCommissionedVehicle(commissionedVehicle);
    setCommissionedDriver(commissionedDriver);
    setOpenLinkForm(true);
  }

  function handleCloseLinkForm({ closeDialog }) {
    setOpenLinkForm(false);
    setCommissionedVehicle(null);

    if (Boolean(closeDialog)) {
      handleCloseDriverDialog();
    }
  }

  function handleOpenDriverDialog(commissionedDriver) {
    setCommissionedDriver(commissionedDriver);
    setOpenDriverDialog(true);
  }

  function handleCloseDriverDialog() {
    setOpenDriverDialog(false);
    setCommissionedDriver(null);
  }

  function handleOpenVehicleFullForm(commissionedVehicle) {
    setCommissionedVehicle(commissionedVehicle);
    setOpenVehicleFullForm(true);
  }

  function handleCloseVehicleFullForm() {
    setOpenVehicleFullForm(false);
    setCommissionedVehicle(null);
  }

  function handleOpenVehicleFullInfo(commissionedVehicleId) {
    setCommissionedVehicleId(commissionedVehicleId);
    setOpenVehicleFullInfo(true);
  }

  function handleCloseVehicleFullInfo() {
    setOpenVehicleFullInfo(false);
    setCommissionedVehicleId(null);
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      vehicles={vehicles}
      search={filter.search}
      commissionedVehicle={commissionedVehicle}
      commissionedVehicleId={commissionedVehicleId}
      commissionedDriver={commissionedDriver}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleChangeStatus={handleChangeStatus}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleSearch={handleSearch}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleOpenExcelForm={handleOpenExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      openExcelForm={openExcelForm}
      openLinkForm={openLinkForm}
      handleOpenLinkForm={handleOpenLinkForm}
      handleCloseLinkForm={handleCloseLinkForm}
      openDriverDialog={openDriverDialog}
      handleOpenDriverDialog={handleOpenDriverDialog}
      handleCloseDriverDialog={handleCloseDriverDialog}
      openVehicleFullForm={openVehicleFullForm}
      handleOpenVehicleFullForm={handleOpenVehicleFullForm}
      handleCloseVehicleFullForm={handleCloseVehicleFullForm}
      openVehicleFullInfo={openVehicleFullInfo}
      handleOpenVehicleFullInfo={handleOpenVehicleFullInfo}
      handleCloseVehicleFullInfo={handleCloseVehicleFullInfo}
      createVehiclePermit={permits.includes(CREATE_VEHICLE)}
      updateVehiclePermit={permits.includes(UPDATE_VEHICLE)}
      deleteVehiclePermit={permits.includes(DELETE_VEHICLE)}
    />
  );
}

const mapStateToProps = (state) => ({
  vehicles: state.Commissioned.Vehicle.vehicles,
  filter: state.Commissioned.Vehicle.filter,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCommissionedVehicles: actionGetCommissionedVehicles(dispatch),
  clearCommissionedVehicles: actionClearCommissionedVehicles(dispatch),
  setCommissionedVehicleStatus: actionSetCommissionedVehicleStatus(dispatch),
  deleteCommissionedVehicle: actionDeleteCommissionedVehicle(dispatch),
  setCommissionedVehicleFilters: actionSetCommissionedVehicleFilters(dispatch),
  clearCommissionedVehicleFilters:
    actionClearCommissionedVehicleFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeCommissionedVehicle);
