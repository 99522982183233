export default {
  travel: "Recorrido",
  form: {
    previous_travel: "Recorrido anterior",
  },
  messages: {
    travel_created: "Listo, el recorrido ha sido creado con éxito",
    travel_updated: "Listo, el recorrido ha sido actualizado con éxito",
  },
};
