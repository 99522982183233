import {
  REGISTER_VEHICLE_TYPE,
  UPDATE_VEHICLE_TYPE,
  GET_VEHICLES_TYPES,
  CLEAR_VEHICLES_TYPES,
  GET_VEHICLE_TYPE,
  CLEAR_VEHICLE_TYPE,
  SET_VEHICLE_TYPE_FILTERS,
  CLEAR_VEHICLE_TYPE_FILTERS,
  GET_VEHICLE_TYPE_AXLES,
  SET_VEHICLE_TYPE_APPROVAL_STATUS,
  SET_VEHICLE_TYPE_STATUS,
  DELETE_VEHICLE_TYPE,
} from "store/actions";

const initialState = {
  vehicle_type: {
    name: "",
    structure_type: "",
    status: 0,
    approved: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
    vehicle_type_id: 0,
    vehicle_type_axles: [],
    vehicle_type_axle_count: 0,
  },
  vehicles_types: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_vehicle_type: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_VEHICLE_TYPE:
    case UPDATE_VEHICLE_TYPE:
    case GET_VEHICLE_TYPE:
      return {
        ...state,
        vehicle_type: {
          vehicle_type_axle_count: 0,
          ...action.payload,
          vehicle_type_axles: [],
        },
      };

    case GET_VEHICLES_TYPES:
      return {
        ...state,
        vehicles_types: action.payload,
      };

    case CLEAR_VEHICLES_TYPES:
      return {
        ...state,
        vehicles_types: { ...initialState.vehicles_types },
      };

    case CLEAR_VEHICLE_TYPE:
      return {
        ...state,
        vehicle_type: { ...initialState.vehicle_type },
      };
    case SET_VEHICLE_TYPE_FILTERS:
      const filter = {
        ...state.filter_vehicle_type,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_vehicle_type: { ...filter },
      };

    case CLEAR_VEHICLE_TYPE_FILTERS:
      return {
        ...state,
        filter_vehicle_type: { search: state.filter_vehicle_type.search },
      };

    case GET_VEHICLE_TYPE_AXLES:
      return {
        ...state,
        vehicle_type: {
          ...state.vehicle_type,
          vehicle_type_axles: action.payload.data,
        },
      };

    case SET_VEHICLE_TYPE_APPROVAL_STATUS:
      return {
        ...state,
        vehicle_type: { ...state.vehicle_type, approved: action.payload },
      };

    case SET_VEHICLE_TYPE_STATUS:
      return {
        ...state,
        vehicles_types: {
          ...state.vehicles_types,
          data: state.vehicles_types.data.map((vehicleType) =>
            vehicleType.vehicle_type_id !== action.payload
              ? vehicleType
              : { ...vehicleType, status: !vehicleType.status }
          ),
        },
      };

    case DELETE_VEHICLE_TYPE:
      return {
        ...state,
        vehicles_types: {
          ...state.vehicles_types,
          data: state.vehicles_types.data.filter(
            (vehicleType) => vehicleType.vehicle_type_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
