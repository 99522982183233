import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionSetNotification } from "store/actions/general/notification";
import { actionDifferentAssignment } from "store/actions/tire/review/differentAssignment";

import Page from "./page";

export function WrongTireForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    vehicleTire,
    setNotification,
    getVehicleAxles,
    setDifferentAssignment,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      option: "code",
      code: "",
      device_code: "",
    },
  });

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const code = values.code;
      const deviceCode = values.device_code;
      const response = await setDifferentAssignment(
        vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle_tire_id,
        vehicleTire.cycle.movement_tire.vehicle_tire[0]
          .vehicle_type_axle_tire_id,
        code,
        deviceCode
      );
      setNotification({
        message: response.message,
      });
    } catch (error) {
      setFormikErrors(error, values, rest);
    } finally {
      onClose();
      getVehicleAxles(id);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

WrongTireForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleTire: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setDifferentAssignment: actionDifferentAssignment(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrongTireForm);
