export default {
  email_incorrect: "The email is not valid.",
  different_passwords: "Passwords do not match.",
  invalid_session: "Invalid session.",
  password_incorrect: "The password is not valid.",
  rfc_incorrect: "The rfc is not valid.",
  fee_tire: "you must to change the coin",
  no_number: "This field must be a number",
  no_whole_number: "This field must be a whole number",
  dot: "This field must be contain between 2 and 15 characters",
  no_integer: "This field must be an integer",
  adjust_pressure: "The date and odometer are required",
  different_cost:
    "The sum of the costs cannot be different from the price of the repair",
  next_odometer: "The value cannot be greater than the next {value}",
  previous_odometer: "The value cannot be less than the previous {value}",
};
