import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  isValid,
  isUpdate,
  isSubmitting,
  updateVehicleTypePermit,
  deleteVehicleTypePermit,
  onClose,
  handleSubmit,
  handleDelete,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        fullWidth
        name="name"
        variant="filled"
        component={TextField}
        label={t("_labels.name.singular.name")}
        className={classnames(css.field)}
        disabled={isUpdate && !updateVehicleTypePermit}
      />

      <Field
        required
        fullWidth
        variant="filled"
        name="structure_type"
        component={SelectField}
        label={t("_labels.structure_type")}
        className={classnames(css.field)}
        defaultText={t("structure_type.default")}
        disabled={isUpdate}
      >
        <MenuItem value="TRUCK_TRACTOR">
          {t("_general.structure_types.truck_tractor")}
        </MenuItem>
        <MenuItem value="TRUCK">{t("_general.structure_types.truck")}</MenuItem>
        <MenuItem value="BOX">{t("_general.structure_types.box")}</MenuItem>
        <MenuItem value="DOLLY">{t("_general.structure_types.dolly")}</MenuItem>
        <MenuItem value="VAN">{t("_general.structure_types.van")}</MenuItem>
      </Field>

      <Field
        fullWidth
        multiline
        rows={4}
        variant="filled"
        name="description"
        component={TextField}
        label={t("labels.description")}
        className={classnames(css.large_field)}
        disabled={isUpdate && !updateVehicleTypePermit}
      />

      <DialogActions className={classnames(css.action)}>
        <div className={classnames(css.delete)}>
          {isUpdate && (
            <Button
              color="secondary"
              type="button"
              disabled={isSubmitting || !deleteVehicleTypePermit}
              tooltip={
                !deleteVehicleTypePermit ? t("permits.delete.vehicle_type") : ""
              }
              onClick={handleDelete}
            >
              {t("_buttons.delete")}
            </Button>
          )}
        </div>
        <div className={classnames(css.buttons)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={
              isSubmitting || !isValid || (isUpdate && !updateVehicleTypePermit)
            }
            tooltip={
              isUpdate && !updateVehicleTypePermit
                ? t("permits.update.vehicle_type")
                : ""
            }
          >
            {!isUpdate ? t("_buttons.save") : t("_buttons.update")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateVehicleTypePermit: PropTypes.bool.isRequired,
  deleteVehicleTypePermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Page;
