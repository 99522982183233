export const ASSIGN_USER_TO_CORPORATE = "ASSIGN_USER_TO_CORPORATE";
export const DELETE_ASSIGN_USER_TO_CORPORATE =
  "DELETE_ASSIGN_USER_TO_CORPORATE";

export const ASSIGN_ROLE_TO_USER_CORPORATE = "ASSIGN_ROLE_TO_USER_CORPORATE";
export const DELETE_ASSIGN_ROLE_TO_USER_CORPORATE =
  "DELETE_ASSIGN_ROLE_TO_USER_CORPORATE";

export const LIST_COMPANY = "LIST_COMPANY";
export const SHOW_COMPANY = "SHOW_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";

export const ASSIGN_USER_TO_COMPANY = "ASSIGN_USER_TO_COMPANY";
export const DELETE_ASSIGN_USER_TO_COMPANY = "DELETE_ASSIGN_USER_TO_COMPANY";

export const ASSIGN_ROLE_TO_USER_COMPANY = "ASSIGN_ROLE_TO_USER_COMPANY";
export const DELETE_ASSIGN_ROLE_TO_USER_COMPANY =
  "DELETE_ASSIGN_ROLE_TO_USER_COMPANY";

export const LIST_SUBSIDIARY = "LIST_SUBSIDIARY";
export const SHOW_SUBSIDIARY = "SHOW_SUBSIDIARY";
export const CREATE_SUBSIDIARY = "CREATE_SUBSIDIARY";
export const UPDATE_SUBSIDIARY = "UPDATE_SUBSIDIARY";
export const DELETE_SUBSIDIARY = "DELETE_SUBSIDIARY";

export const LIST_ASSOCIATION = "LIST_DIVISION";
export const SHOW_ASSOCIATION = "SHOW_DIVISION";
export const CREATE_ASSOCIATION = "CREATE_DIVISION";
export const UPDATE_ASSOCIATION = "UPDATE_DIVISION";
export const DELETE_ASSOCIATION = "DELETE_DIVISION";

export const LIST_VEHICLE_PRESSURE_POLICY = "LIST_VEHICLE_PRESSURE_POLICY";
export const CREATE_VEHICLE_PRESSURE_POLICY = "CREATE_VEHICLE_PRESSURE_POLICY";
export const UPDATE_VEHICLE_PRESSURE_POLICY = "UPDATE_VEHICLE_PRESSURE_POLICY";
export const DELETE_VEHICLE_PRESSURE_POLICY = "DELETE_VEHICLE_PRESSURE_POLICY";

export const ASSIGN_USER_TO_SUBSIDIARY = "ASSIGN_USER_TO_SUBSIDIARY";
export const DELETE_ASSIGN_USER_TO_SUBSIDIARY =
  "DELETE_ASSIGN_USER_TO_SUBSIDIARY";

export const ASSIGN_ROLE_TO_USER_SUBSIDIARY = "ASSIGN_ROLE_TO_USER_SUBSIDIARY";
export const DELETE_ASSIGN_ROLE_TO_USER_SUBSIDIARY =
  "DELETE_ASSIGN_ROLE_TO_USER_SUBSIDIARY";

export const LIST_USER = "LIST_USER";
export const SHOW_USER = "SHOW_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const LIST_PROVIDER = "LIST_PROVIDER";
export const SHOW_PROVIDER = "SHOW_PROVIDER";
export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";

export const LIST_WAREHOUSE = "LIST_WAREHOUSE";
export const SHOW_WAREHOUSE = "SHOW_WAREHOUSE";
export const CREATE_WAREHOUSE = "CREATE_WAREHOUSE";
export const UPDATE_WAREHOUSE = "UPDATE_WAREHOUSE";
export const DELETE_WAREHOUSE = "DELETE_WAREHOUSE";

export const LIST_LINK_WAREHOUSE_WITH_SUBSIDIARY =
  "LIST_LINK_WAREHOUSE_WITH_SUBSIDIARY";
export const LINK_WAREHOUSE_WITH_SUBSIDIARY = "LINK_WAREHOUSE_WITH_SUBSIDIARY";
export const DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY =
  "DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY";
export const SHOW_LINK_WAREHOUSE_WITH_SUBSIDIARY =
  "SHOW_LINK_WAREHOUSE_WITH_SUBSIDIARY";

export const SHOW_DRIVER = "SHOW_DRIVER";
export const LIST_DRIVER = "LIST_DRIVER";
export const CREATE_DRIVER = "CREATE_DRIVER";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";

export const LINK_DRIVER_WITH_SUBSIDIARY = "LINK_DRIVER_WITH_SUBSIDIARY";
export const LIST_LINK_DRIVER_WITH_SUBSIDIARY =
  "LIST_LINK_DRIVER_WITH_SUBSIDIARY";
export const DELETE_LINK_DRIVER_WITH_SUBSIDIARY =
  "DELETE_LINK_DRIVER_WITH_SUBSIDIARY";
export const SHOW_LINK_DRIVER_WITH_SUBSIDIARY =
  "SHOW_LINK_DRIVER_WITH_SUBSIDIARY";

export const CREATE_BRAND = "CREATE_BRAND";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";

export const CREATE_VEHICLE_TYPE = "CREATE_VEHICLE_TYPE";
export const UPDATE_VEHICLE_TYPE = "UPDATE_VEHICLE_TYPE";
export const DELETE_VEHICLE_TYPE = "DELETE_VEHICLE_TYPE";

export const CREATE_VEHICLE_TYPE_AXLE = "CREATE_VEHICLE_TYPE_AXLE";
export const UPDATE_VEHICLE_TYPE_AXLE = "UPDATE_VEHICLE_TYPE_AXLE";

export const SHOW_VEHICLE = "SHOW_VEHICLE";
export const LIST_VEHICLE = "LIST_VEHICLE";
export const CREATE_VEHICLE = "CREATE_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const RESET_VEHICLE_REVIEW = "RESET_VEHICLE_REVIEW";
export const CREATE_VEHICLE_REVIEW = "CREATE_VEHICLE_REVIEW";
export const UPDATE_VEHICLE_REVIEW = "UPDATE_VEHICLE_REVIEW";
export const DELETE_VEHICLE_REVIEW = "DELETE_VEHICLE_REVIEW";

export const LINK_VEHICLE_WITH_SUBSIDIARY = "LINK_VEHICLE_WITH_SUBSIDIARY";
export const LIST_LINK_VEHICLE_WITH_SUBSIDIARY =
  "LIST_LINK_VEHICLE_WITH_SUBSIDIARY";
export const DELETE_LINK_VEHICLE_WITH_SUBSIDIARY =
  "DELETE_LINK_VEHICLE_WITH_SUBSIDIARY";
export const SHOW_LINK_VEHICLE_WITH_SUBSIDIARY =
  "SHOW_LINK_VEHICLE_WITH_SUBSIDIARY";

export const LINK_VEHICLE_WITH_DRIVER = "LINK_VEHICLE_WITH_DRIVER";
export const LIST_LINK_VEHICLE_WITH_DRIVER_HISTORY =
  "LIST_LINK_VEHICLE_WITH_DRIVER_HISTORY";
export const DELETE_LINK_VEHICLE_WITH_DRIVER =
  "DELETE_LINK_VEHICLE_WITH_DRIVER";
export const SHOW_LINK_VEHICLE_WITH_DRIVER = "SHOW_LINK_VEHICLE_WITH_DRIVER";

export const CREATE_TIRE_SIZE = "CREATE_TIRE_SIZE";
export const UPDATE_TIRE_SIZE = "UPDATE_TIRE_SIZE";
export const DELETE_TIRE_SIZE = "DELETE_TIRE_SIZE";

export const CREATE_TIRE_MODEL = "CREATE_TIRE_MODEL";
export const UPDATE_TIRE_MODEL = "UPDATE_TIRE_MODEL";
export const DELETE_TIRE_MODEL = "DELETE_TIRE_MODEL";

export const CREATE_TIRE_MODEL_VARIATION = "CREATE_TIRE_MODEL_VARIATION";
export const UPDATE_TIRE_MODEL_VARIATION = "UPDATE_TIRE_MODEL_VARIATION";
export const DELETE_TIRE_MODEL_VARIATION = "DELETE_TIRE_MODEL_VARIATION";

export const CREATE_TIRE = "CREATE_TIRE";
export const LIST_TIRE = "LIST_TIRE";
export const SHOW_TIRE = "SHOW_TIRE";
export const UPDATE_TIRE = "UPDATE_TIRE";
export const DELETE_TIRE = "DELETE_TIRE";

export const CREATE_TIRE_CYCLE = "CREATE_TIRE_CYCLE";
export const UPDATE_TIRE_CYCLE = "UPDATE_TIRE_CYCLE";
export const DELETE_TIRE_CYCLE = "DELETE_TIRE_CYCLE";

export const LINK_TIRE_WITH_SUBSIDIARY = "LINK_TIRE_WITH_SUBSIDIARY";
export const LIST_LINK_TIRE_WITH_SUBSIDIARY = "LIST_LINK_TIRE_WITH_SUBSIDIARY";
export const DELETE_LINK_TIRE_WITH_SUBSIDIARY =
  "DELETE_LINK_TIRE_WITH_SUBSIDIARY";
export const SHOW_LINK_TIRE_WITH_SUBSIDIARY = "SHOW_LINK_TIRE_WITH_SUBSIDIARY";

export const CREATE_VEHICLE_TRAVEL = "CREATE_VEHICLE_TRAVEL";
export const UPDATE_VEHICLE_TRAVEL = "UPDATE_VEHICLE_TRAVEL";
export const DELETE_VEHICLE_TRAVEL = "DELETE_VEHICLE_TRAVEL";
export const RESET_VEHICLE_TRAVEL = "RESET_VEHICLE_TRAVEL";

export const CREATE_DIVISION = "CREATE_DIVISION";
export const LIST_DIVISION = "LIST_DIVISION";
export const SHOW_DIVISION = "SHOW_DIVISION";
export const UPDATE_DIVISION = "UPDATE_DIVISION";
export const DELETE_DIVISION = "DELETE_DIVISION";

export const LINK_VEHICLE_WITH_DIVISION = "LINK_VEHICLE_WITH_DIVISION";
export const LIST_LINK_VEHICLE_WITH_DIVISION =
  "LIST_LINK_VEHICLE_WITH_DIVISION";
export const DELETE_LINK_VEHICLE_WITH_DIVISION =
  "DELETE_LINK_VEHICLE_WITH_DIVISION";
export const SHOW_LINK_VEHICLE_WITH_DIVISION =
  "SHOW_LINK_VEHICLE_WITH_DIVISION";

export const LINK_TIRE_WITH_WAREHOUSE = "LINK_TIRE_WITH_WAREHOUSE";
export const LIST_LINK_TIRE_WITH_WAREHOUSE = "LIST_LINK_TIRE_WITH_WAREHOUSE";
export const DELETE_LINK_TIRE_WITH_WAREHOUSE =
  "DELETE_LINK_TIRE_WITH_WAREHOUSE";
export const LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE =
  "LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE";
export const LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE =
  "LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE";
export const SHOW_LINK_TIRE_WITH_WAREHOUSE = "SHOW_LINK_TIRE_WITH_WAREHOUSE";

export const LINK_TIRE_WITH_VEHICLE = "LINK_TIRE_WITH_VEHICLE";
export const LIST_LINK_TIRE_WITH_VEHICLE = "LIST_LINK_TIRE_WITH_VEHICLE";
export const DELETE_LINK_TIRE_WITH_VEHICLE = "DELETE_LINK_TIRE_WITH_VEHICLE";
export const LIST_LINK_HISTORY_TIRE_WITH_VEHICLE =
  "LIST_LINK_HISTORY_TIRE_WITH_VEHICLE";
export const LIST_LINK_HISTORY_VEHICLE_WITH_TIRE =
  "LIST_LINK_HISTORY_VEHICLE_WITH_TIRE";
export const SHOW_LINK_TIRE_WITH_VEHICLE = "SHOW_LINK_TIRE_WITH_VEHICLE";
export const SHOW_LINK_HISTORY_TIRE_WITH_VEHICLE =
  "SHOW_LINK_HISTORY_TIRE_WITH_VEHICLE";

export const CREATE_DAMAGE = "CREATE_DAMAGE";
export const LIST_DAMAGE = "LIST_DAMAGE";
export const SHOW_DAMAGE = "SHOW_DAMAGE";
export const UPDATE_DAMAGE = "UPDATE_DAMAGE";
export const DELETE_DAMAGE = "DELETE_DAMAGE";

export const CREATE_WEAR = "CREATE_WEAR";
export const LIST_WEAR = "LIST_WEAR";
export const SHOW_WEAR = "SHOW_WEAR";
export const UPDATE_WEAR = "UPDATE_WEAR";
export const DELETE_WEAR = "DELETE_WEAR";

export const CREATE_TIRE_DAMAGE = "CREATE_TIRE_DAMAGE";
export const LIST_TIRE_DAMAGE = "LIST_TIRE_DAMAGE";
export const DELETE_TIRE_DAMAGE = "DELETE_TIRE_DAMAGE";
export const SHOW_TIRE_DAMAGE = "SHOW_TIRE_DAMAGE";
export const UPDATE_TIRE_DAMAGE = "UPDATE_TIRE_DAMAGE";

export const CREATE_TIRE_WEAR = "CREATE_TIRE_WEAR";
export const LIST_TIRE_WEAR = "LIST_TIRE_WEAR";
export const DELETE_TIRE_WEAR = "DELETE_TIRE_WEAR";
export const SHOW_TIRE_WEAR = "SHOW_TIRE_WEAR";
export const UPDATE_TIRE_WEAR = "UPDATE_TIRE_WEAR";

export const CREATE_TIRE_REPAIR = "CREATE_TIRE_REPAIR";
export const LIST_TIRE_REPAIR_HISTORY = "LIST_TIRE_REPAIR_HISTORY";
export const DELETE_TIRE_REPAIR = "DELETE_TIRE_REPAIR";
export const UPDATE_TIRE_REPAIR = "UPDATE_TIRE_REPAIR";

export const CREATE_TIRE_REVITALIZATION = "CREATE_TIRE_REVITALIZATION";
export const LIST_TIRE_REVITALIZATION_HISTORY =
  "LIST_TIRE_REVITALIZATION_HISTORY";
export const DELETE_TIRE_REVITALIZATION = "DELETE_TIRE_REVITALIZATION";
export const UPDATE_TIRE_REVITALIZATION = "UPDATE_TIRE_REVITALIZATION";

export const CREATE_RETIREMENT_CAUSE = "CREATE_RETIREMENT_CAUSE";

export const LIST_RETIREMENT_CAUSE = "LIST_RETIREMENT_CAUSE";
export const SHOW_RETIREMENT_CAUSE = "SHOW_RETIREMENT_CAUSE";
export const UPDATE_RETIREMENT_CAUSE = "UPDATE_RETIREMENT_CAUSE";
export const DELETE_RETIREMENT_CAUSE = "DELETE_RETIREMENT_CAUSE";

export const CREATE_GPS = "CREATE_GPS";
export const LIST_GPS = "LIST_GPS";
export const SHOW_GPS = "SHOW_GPS";
export const UPDATE_GPS = "UPDATE_GPS";
export const DELETE_GPS = "DELETE_GPS";

export const SHOW_POLICY = "SHOW_POLICY";
export const UPDATE_POLICY = "UPDATE_POLICY";

export const CREATE_VEHICLE_TYPE_AXLE_POLICY =
  "CREATE_VEHICLE_TYPE_AXLE_POLICY";
export const SHOW_VEHICLE_TYPE_AXLE_POLICY = "SHOW_VEHICLE_TYPE_AXLE_POLICY";
export const UPDATE_VEHICLE_TYPE_AXLE_POLICY =
  "UPDATE_VEHICLE_TYPE_AXLE_POLICY";
export const DELETE_VEHICLE_TYPE_AXLE_POLICY =
  "DELETE_VEHICLE_TYPE_AXLE_POLICY";

export const CREATE_DEPTH_TOLERANCE_POLICY = "CREATE_DEPTH_TOLERANCE_POLICY";
export const UPDATE_DEPTH_TOLERANCE_POLICY = "UPDATE_DEPTH_TOLERANCE_POLICY";
export const SHOW_DEPTH_TOLERANCE_POLICY = "SHOW_DEPTH_TOLERANCE_POLICY";
export const LIST_DEPTH_TOLERANCE_POLICY = "LIST_DEPTH_TOLERANCE_POLICY";
export const DELETE_DEPTH_TOLERANCE_POLICY = "DELETE_DEPTH_TOLERANCE_POLICY";

export const LIST_TIRE_MODEL_VARIATION_POLICY =
  "LIST_TIRE_MODEL_VARIATION_POLICY";
export const CREATE_TIRE_MODEL_VARIATION_POLICY =
  "CREATE_TIRE_MODEL_VARIATION_POLICY";
export const SHOW_TIRE_MODEL_VARIATION_POLICY =
  "SHOW_TIRE_MODEL_VARIATION_POLICY";
export const UPDATE_TIRE_MODEL_VARIATION_POLICY =
  "UPDATE_TIRE_MODEL_VARIATION_POLICY";
export const DELETE_TIRE_MODEL_VARIATION_POLICY =
  "DELETE_TIRE_MODEL_VARIATION_POLICY";

export const LINK_VEHICLE_WITH_GPS = "LINK_VEHICLE_WITH_GPS";
export const UPDATE_LINK_VEHICLE_WITH_GPS = "UPDATE_LINK_VEHICLE_WITH_GPS";
export const DELETE_LINK_VEHICLE_WITH_GPS = "DELETE_LINK_VEHICLE_WITH_GPS";
export const SHOW_LINK_VEHICLE_WITH_GPS = "SHOW_LINK_VEHICLE_WITH_GPS";
export const SHOW_LINK_VEHICLE_WITH_GPS_HISTORY =
  "SHOW_LINK_VEHICLE_WITH_GPS_HISTORY";

export const LINK_TIRE_WITH_DIVISION = "LINK_TIRE_WITH_DIVISION";
export const LIST_LINK_TIRE_WITH_DIVISION = "LIST_LINK_TIRE_WITH_DIVISION";
export const SHOW_LINK_TIRE_WITH_DIVISION = "SHOW_LINK_TIRE_WITH_DIVISION";
export const DELETE_LINK_TIRE_WITH_DIVISION = "DELETE_LINK_TIRE_WITH_DIVISION";

export const CREATE_GPS_GEOFENCE = "CREATE_GPS_GEOFENCE";
export const LIST_GPS_GEOFENCE = "LIST_GPS_GEOFENCE";
export const SHOW_GPS_GEOFENCE = "SHOW_GPS_GEOFENCE";

export const CREATE_REVITALIZED_TIRE_MODEL = "CREATE_REVITALIZED_TIRE_MODEL";
export const LIST_REVITALIZED_TIRE_MODEL = "LIST_REVITALIZED_TIRE_MODEL";
export const SHOW_REVITALIZED_TIRE_MODEL = "SHOW_REVITALIZED_TIRE_MODEL";
export const UPDATE_REVITALIZED_TIRE_MODEL = "UPDATE_REVITALIZED_TIRE_MODEL";
export const DELETE_REVITALIZED_TIRE_MODEL = "DELETE_REVITALIZED_TIRE_MODEL";

export const CREATE_TIRE_PILE_RETIREMENT_CAUSE =
  "CREATE_TIRE_PILE_RETIREMENT_CAUSE";
export const LIST_TIRE_PILE_RETIREMENT_CAUSE =
  "LIST_TIRE_PILE_RETIREMENT_CAUSE";
export const SHOW_TIRE_PILE_RETIREMENT_CAUSE =
  "SHOW_TIRE_PILE_RETIREMENT_CAUSE";
export const UPDATE_TIRE_PILE_RETIREMENT_CAUSE =
  "UPDATE_TIRE_PILE_RETIREMENT_CAUSE";
export const DELETE_TIRE_PILE_RETIREMENT_CAUSE =
  "DELETE_TIRE_PILE_RETIREMENT_CAUSE";

export const CREATE_TIRE_PILE = "CREATE_TIRE_PILE";
export const UPDATE_TIRE_PILE = "UPDATE_TIRE_PILE";
export const DELETE_TIRE_PILE = "DELETE_TIRE_PILE";
export const LIST_TIRE_PILE_HISTORY = "LIST_TIRE_PILE_HISTORY";
export const SHOW_TIRE_PILE_HISTORY = "SHOW_TIRE_PILE_HISTORY";

export const CREATE_TIRE_REVIEW = "CREATE_TIRE_REVIEW";
export const SHOW_TIRE_REVIEW = "SHOW_TIRE_REVIEW";
export const UPDATE_TIRE_REVIEW = "UPDATE_TIRE_REVIEW";
export const DELETE_TIRE_REVIEW = "DELETE_TIRE_REVIEW";
export const LIST_TIRE_REVIEW_HISTORY = "LIST_TIRE_REVIEW_HISTORY";

export const CREATE_RFID = "CREATE_RFID";
export const LIST_RFID = "LIST_RFID";
export const SHOW_RFID = "SHOW_RFID";
export const UPDATE_RFID = "UPDATE_RFID";
export const DELETE_RFID = "DELETE_RFID";

export const CREATE_TIRE_REVIEW_DEPTH = "CREATE_TIRE_REVIEW_DEPTH";
export const LIST_TIRE_REVIEW_DEPTH = "LIST_TIRE_REVIEW_DEPTH";
export const UPDATE_TIRE_REVIEW_DEPTH = "UPDATE_TIRE_REVIEW_DEPTH";
export const DELETE_TIRE_REVIEW_DEPTH = "DELETE_TIRE_REVIEW_DEPTH";
export const SHOW_TIRE_REVIEW_DEPTH = "SHOW_TIRE_REVIEW_DEPTH";

export const LINK_TIRE_WITH_RFID = "LINK_TIRE_WITH_RFID";
export const UPDATE_LINK_TIRE_WITH_RFID = "UPDATE_LINK_TIRE_WITH_RFID";
export const DELETE_LINK_TIRE_WITH_RFID = "DELETE_LINK_TIRE_WITH_RFID";
export const SHOW_LINK_TIRE_WITH_RFID = "SHOW_LINK_TIRE_WITH_RFID";
export const SHOW_LINK_TIRE_WITH_RFID_HISTORY =
  "SHOW_LINK_TIRE_WITH_RFID_HISTORY";

export const CREATE_ALERT = "CREATE_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";

export const UPDATE_VEHICLE_CONDITION_POLICY =
  "UPDATE_VEHICLE_CONDITION_POLICY";
export const DELETE_VEHICLE_CONDITION_POLICY =
  "DELETE_VEHICLE_CONDITION_POLICY";
export const CREATE_VEHICLE_CONDITION_POLICY =
  "CREATE_VEHICLE_CONDITION_POLICY";

export const UPDATE_VEHICLE_DEPTH_POLICY = "UPDATE_VEHICLE_DEPTH_POLICY";
export const DELETE_VEHICLE_DEPTH_POLICY = "DELETE_VEHICLE_DEPTH_POLICY";
export const CREATE_VEHICLE_DEPTH_POLICY = "CREATE_VEHICLE_DEPTH_POLICY";

export const LIST_LINK_ALERT_WITH_TIRE = "LIST_LINK_ALERT_WITH_TIRE";
export const LIST_LINK_ALERT_WITH_VEHICLE = "LIST_LINK_ALERT_WITH_VEHICLE";

export const SHOW_TIRE_STATISTICS = "SHOW_TIRE_STATISTICS";
export const SHOW_TIRE_SUMMARY = "SHOW_TIRE_SUMMARY";
export const SHOW_TIRE_PILE_REPORT = "SHOW_TIRE_PILE_REPORT";
export const SHOW_LAST_MOVEMENT_REPORT = "SHOW_LAST_MOVEMENT_REPORT";
export const SHOW_TIRE_WAREHOUSE_REPORT = "SHOW_TIRE_WAREHOUSE_REPORT";
export const SHOW_DYNAMIC_COST_REPORT = "SHOW_DYNAMIC_COST_REPORT";
export const SHOW_PERFORMANCE_COST = "SHOW_PERFORMANCE_COST";
export const SHOW_TIRE_DAMAGE_WEAR_REPORT = "SHOW_TIRE_DAMAGE_WEAR_REPORT";
export const SHOW_RENEWABILITY_INDEX = "SHOW_RENEWABILITY_INDEX";
export const SHOW_REVIEW_PERFORMANCE_REPORT = "SHOW_REVIEW_PERFORMANCE_REPORT";
export const SHOW_PURCHASE_REPORT = "SHOW_PURCHASE_REPORT";
export const SHOW_USER_REVIEW_REPORT = "SHOW_USER_REVIEW_REPORT";
export const SHOW_TIRE_MOUNT_REPORT = "SHOW_TIRE_MOUNT_REPORT";
export const SHOW_KM_PROJECTION_REPORT = "SHOW_KM_PROJECTION_REPORT";

export const CREATE_CHECK_LIST = "CREATE_CHECK_LIST";
export const UPDATE_CHECK_LIST = "UPDATE_CHECK_LIST";
export const LIST_CHECK_LIST = "LIST_CHECK_LIST";
export const SHOW_CHECK_LIST = "SHOW_CHECK_LIST";
export const DELETE_CHECK_LIST = "DELETE_CHECK_LIST";

export const CREATE_CONCEPT = "CREATE_CONCEPT";
export const UPDATE_CONCEPT = "UPDATE_CONCEPT";
export const LIST_CONCEPT = "LIST_CONCEPT";
export const DELETE_CONCEPT = "DELETE_CONCEPT";
export const SHOW_CONCEPT = "SHOW_CONCEPT";

export const CREATE_BLOCK = "CREATE_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const LIST_BLOCK = "LIST_BLOCK";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const SHOW_BLOCK = "SHOW_BLOCK";

export const CREATE_TPGPS = "CREATE_TPGPS";
export const LIST_TPGPS = "LIST_TPGPS";
export const SHOW_TPGPS = "SHOW_TPGPS";
export const UPDATE_TPGPS = "UPDATE_TPGPS";
export const DELETE_TPGPS = "DELETE_TPGPS";
export const LINK_TIRE_WITH_TPGPS = "LINK_TIRE_WITH_TPGPS";
