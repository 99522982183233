import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import HistoryIcon from "@material-ui/icons/History";
import Typography from "@material-ui/core/Typography";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";

import css from "./index.module.scss";

function Page({
  t,
  text,
  text2,
  text3,
  title,
  label,
  label2,
  label3,
  className,
  actionButtonName,
  viewButtonTooltip,
  viewButtonDisabled,
  actionButtonTooltip,
  historyTooltipPermit,
  actionButtonDisabled,
  onView,
  onAction,
  onHistory,
}) {
  return (
    <Paper className={classnames(css.paper, className)} elevation={3}>
      <div className={classnames(css.flex)}>
        <Typography noWrap gutterBottom variant="h6">
          {title}
        </Typography>

        {onHistory && (
          <div>
            <Button
              size="small"
              variant="icon"
              color="secondary"
              aria-label="history"
              className={classnames("bb")}
              tooltip={historyTooltipPermit}
              disabled={!!historyTooltipPermit}
              onClick={onHistory}
            >
              <HistoryIcon />
            </Button>
          </div>
        )}
      </div>

      <div className={classnames(css.content)}>
        <div>
          <LabelAndText
            text={text}
            label={label}
            className={classnames(css.mr32)}
          />
          {label2 && text2 && <LabelAndText text={text2} label={label2} />}

          {label3 && text3 && (
            <LabelAndText
              text={text3}
              label={label3}
              className={classnames(css.ml32)}
            />
          )}
        </div>

        <div>
          {onAction && (
            <Button
              size="small"
              color="primary"
              onClick={onAction}
              tooltip={actionButtonTooltip}
              disabled={actionButtonDisabled}
            >
              {actionButtonName}
            </Button>
          )}

          <Button
            size="small"
            color="primary"
            tooltip={viewButtonTooltip}
            disabled={viewButtonDisabled}
            onClick={onView}
          >
            {t("_buttons.view")}
          </Button>
        </div>
      </div>
    </Paper>
  );
}

Page.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  viewButtonDisabled: PropTypes.bool,
  viewButtonTooltip: PropTypes.string,
  actionButtonDisabled: PropTypes.bool,
  actionButtonTooltip: PropTypes.string,
  historyTooltipPermit: PropTypes.string,
  actionButtonName: PropTypes.string,
  onHistory: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAction: PropTypes.func,
};

export default Page;
