import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "./page";
import { actionAddFavorite } from "store/actions/favorites/addFavorite";
import { actionDeleteFavorite } from "store/actions/favorites/deleteFavorite";
import { useLang } from "hooks/lang";

function ListItem({
  to,
  children,
  name,
  endIcon,
  classNameIcon,
  classNameText,
  onClick,
  requiredPermits = [],
  isRootRequired,
  favoriteName,
  ...rest
}) {
  const t = useLang();
  const history = useHistory();
  const { account, permits, divider, favorites, addFavorite, deleteFavorite } =
    rest;

  function handleClick() {
    if (onClick) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
  }

  if (
    account &&
    !account.is_root &&
    !requiredPermits.every((permit) => permits.includes(permit))
  ) {
    return null;
  }

  if (account && !account.is_root && isRootRequired) {
    return null;
  }

  function checkIsFavorite() {
    return favorites.some(
      (favorite) =>
        favorite.favorite_report.favorite_report_name === favoriteName
    );
  }

  const isFavorite = checkIsFavorite();

  function handleRegisterFavorite(name) {
    addFavorite({
      favorite_report_name: name,
    });
  }

  function handleDeleteFavorite(id) {
    deleteFavorite(id);
  }

  return (
    <Page
      t={t}
      name={name}
      handleClick={handleClick}
      classNameIcon={classNameIcon}
      classNameText={classNameText}
      endIcon={endIcon}
      divider={divider}
      favoriteName={favoriteName}
      handleRegisterFavorite={handleRegisterFavorite}
      handleDeleteFavorite={handleDeleteFavorite}
      isFavorite={isFavorite}
      favorites={favorites}
    >
      {children}
    </Page>
  );
}

ListItem.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  divider: PropTypes.bool,
  endIcon: PropTypes.node,
  classNameIcon: PropTypes.string,
  classNameText: PropTypes.string,
  onClick: PropTypes.func,
  requiredPermits: PropTypes.array,
  isRootRequired: PropTypes.bool,
  favoriteName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  permits: state.Account.permits,
  favorites: state.Favorites.favorites,
});
const mapDispatchToProps = (dispatch) => ({
  addFavorite: actionAddFavorite(dispatch),
  deleteFavorite: actionDeleteFavorite(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
