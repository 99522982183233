import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import ContainerTemplate from "components/templates/general/Container";
import TirePressureForm from "components/organisms/vehicle/tire/review/TirePressureForm";

import css from "./index.module.scss";

function Page({
  t,
  vehicleTypeAxle,
  axleTires,
  economicNumber,
  sendPressureSettingPolicy,
}) {
  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: axleTires.length === 0,
      })}
      title={`${t("_titles.vehicle_pressure_inspection")} ${economicNumber}`}
    >
      {axleTires.length > 0 && (
        <TirePressureForm
          vehicleTypeAxle={vehicleTypeAxle}
          axleTires={axleTires}
          sendPressureSettingPolicy={sendPressureSettingPolicy}
        />
      )}
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleTypeAxle: PropTypes.array.isRequired,
  axleTires: PropTypes.array.isRequired,
  economicNumber: PropTypes.string.isRequired,
  sendPressureSettingPolicy: PropTypes.number.isRequired,
};

export default Page;
