export default {
  title: "Nueva variación del modelo",
  update_title: "Actualizar variación del modelo",
  messages: {
    variation_model_created:
      "Listo, se creo una nueva variación del modelo con éxito",
    tire_model_variation_updated:
      "Listo, se actualizó la variación del modelo con éxito",
    delete_model_variation:
      "¿Estas seguro de eliminar la variación del modelo?",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar variaciones de modelos",
      delete: "Sin permisos para eliminar variaciones de modelos",
    },
    size_field: "Selecciona una medida",
  },
};
