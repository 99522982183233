import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";
import "./semaforos.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import FilterTireSummaryForm from "components/organisms/report/FilterTireSummaryForm";
import TireDialog from "./TireDialog";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

import { removeCharacters, tooltipLabel } from "utils/tire";
import css from "./index.module.scss";

function Page({
  t,
  current,
  data,
  dataChart,
  section,
  handleChangeSection,
  handleViewAlerts,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
  openTireDialog,
  params,
  handleViewTires,
  handleCloseTireDialog,
  handleGenerateSummaryExcel,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={
        current ? t("_titles.current_summary") : t("_titles.historical_summary")
      }
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="container-fluid pdl100 sidebar_font">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.company_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiary_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.movement && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.location.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.movement}
                    </p>
                  </div>
                )}
                {filter.pressure_condition && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.pressure_rating")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.pressure_condition}
                    </p>
                  </div>
                )}
                {filter.depth_condition && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.depth_rating")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.depth_condition}
                    </p>
                  </div>
                )}
                {"with_refection" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.show_refection")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.with_refection}
                    </p>
                  </div>
                )}
                {filter.date_from && filter.date_to && (
                  <>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.from")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_from).format("LL")}
                      </p>
                    </div>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.to")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_to).format("LL")}
                      </p>
                    </div>
                  </>
                )}
                {filter.activity ? (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.activity.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.activity}
                    </p>
                  </div>
                ) : (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.activity.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {t("_labels.activity.options.not_rule_out")}
                    </p>
                  </div>
                )}

                {filter.review_type ? (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.review_type.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.review_type}
                    </p>
                  </div>
                ) : (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.review_type.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {t("_labels.no_option")}
                    </p>
                  </div>
                )}
                {"number_cycle" in filter && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.number_cycle")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.number_cycle}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {!filter.movement && (
          <div className="row pt-0">
            <div className="col-md-12 text-center">
              <div className="container-fluid px-4">
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")}
                </small>
                <h5 className="px-5 sinmp">
                  {t("_labels.summary_report.report_show")}
                </h5>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  <strong>
                    {t("_labels.summary_report.report_desc_strong")}{" "}
                  </strong>
                  {t("_labels.summary_report.report_desc")}
                </small>
              </div>
            </div>
          </div>
        )}

        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-4">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateSummaryExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>

        <div className="row py-2 justifiy-content-center">
          <div className="col-md-12">
            <div className="container-fluid px-4 h5 m-0 text-center">
              {t("_labels.report_summary.desc")}
            </div>
          </div>
        </div>

        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-4">
              <div className={classnames(css.tab)}>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: section == "pressure",
                  })}
                  onClick={() => handleChangeSection("pressure")}
                >
                  {t("_labels.pressure")}
                  {/* <span className="badge badge-pill badge-light">
              {formatter.format(totalAlerts["tires"])}
            </span> */}
                </div>
                <div
                  className={classnames({
                    [css.option_tab]: true,
                    [css.active]: section == "depth",
                  })}
                  onClick={() => handleChangeSection("depth")}
                >
                  {t("_labels.depth")}
                  {/* <span className="badge badge-pill badge-light">
              {formatter.format(totalAlerts["vehicles"])}
            </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {section == "pressure" &&
          data.pressure &&
          Object.keys(data.pressure).length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <h4 className="text-center">
                          {t("_labels.pressure_rating")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(data.pressure).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "pressure"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th className="text-right col1" />
                                    <th className="text-right col3" />
                                    <th className="text-right col3" />
                                    <th className="text-right col4" />
                                    <th className="text-right col5">
                                      {corporate[1]["statistics"]}
                                    </th>
                                    <th className="text-right col6">
                                      {percentFormatter.format(
                                        corporate[1]["percent"]
                                      )}
                                      %
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        !["statistics", "percent"].includes(
                                          index
                                        )
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="pressure"
                                          data-id={removeCharacters(
                                            `${corporate[0]}_companies`
                                          )}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "pressure"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td className="text-right col1"> </td>
                                          <td className="text-right col3" />
                                          <td className="text-right col3" />
                                          <td className="text-right col4" />
                                          <td className="text-right col5">
                                            {
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            }
                                          </td>
                                          <td className="text-right col6">
                                            {percentFormatter.format(
                                              corporate[1][company]["percent"]
                                            )}
                                            %
                                          </td>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              ![
                                                "statistics",
                                                "percent",
                                              ].includes(index)
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="pressure"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "pressure"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td className="text-right col1">
                                                  {" "}
                                                </td>
                                                <td className="text-right col3" />
                                                <td className="text-right col3" />
                                                <td className="text-right col4" />
                                                <td className="text-right col5">
                                                  {
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  }
                                                </td>
                                                <td className="text-right col6">
                                                  {percentFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["percent"]
                                                  )}
                                                  %
                                                </td>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    ![
                                                      "statistics",
                                                      "percent",
                                                    ].includes(index)
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="pressure"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "pressure"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td className="text-right col1">
                                                        {" "}
                                                      </td>
                                                      <td className="text-right col3" />
                                                      <td className="text-right col3" />
                                                      <td className="text-right col4" />
                                                      <td className="text-right col5">
                                                        {
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-right col6">
                                                        {percentFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["percent"]
                                                        )}
                                                        %
                                                      </td>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division]["labels"]
                                                      .filter((el) =>
                                                        [
                                                          "HIGH",
                                                          "LOW",
                                                          "NORMAL",
                                                          "NO PRESSURE",
                                                        ].includes(el)
                                                      )
                                                      .map((condition) => (
                                                        <Fragment
                                                          key={condition}
                                                        >
                                                          <tr
                                                            data-type="pressure"
                                                            data-id={removeCharacters(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                            )}
                                                            className={`box collapse condition ${
                                                              condition.includes(
                                                                "HIGH"
                                                              )
                                                                ? "set_sem_amarillo"
                                                                : condition.includes(
                                                                    "LOW"
                                                                  )
                                                                ? "set_sem_rojo"
                                                                : condition.includes(
                                                                    "NORMAL"
                                                                  )
                                                                ? "set_sem_verde"
                                                                : "set_sem_negro"
                                                            }`}
                                                            onClick={() =>
                                                              toggleTable(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                                `${company}_${subsidiary}_${division}_${condition}`,
                                                                "pressure"
                                                              )
                                                            }
                                                          >
                                                            <td
                                                              className="text-left"
                                                              style={{
                                                                paddingTop: 27,
                                                                color:
                                                                  condition.includes(
                                                                    "NO PRE"
                                                                  )
                                                                    ? "white"
                                                                    : "",
                                                              }}
                                                            >
                                                              <i
                                                                className={`bx bxs-circle ${
                                                                  condition.includes(
                                                                    "HIGH"
                                                                  )
                                                                    ? "set_sem_amarillo"
                                                                    : condition.includes(
                                                                        "LOW"
                                                                      )
                                                                    ? "set_sem_rojo"
                                                                    : condition.includes(
                                                                        "NORMAL"
                                                                      )
                                                                    ? "set_sem_verde"
                                                                    : "set_sem_negro"
                                                                }`}
                                                              />
                                                              &nbsp;
                                                              {t(
                                                                `_labels.summary_report.pressure_condition.${condition
                                                                  .replaceAll(
                                                                    /\s/g,
                                                                    "_"
                                                                  )
                                                                  .toLowerCase()}`
                                                              )}
                                                            </td>
                                                            <td className="text-right col1">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col2">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col3">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col4">
                                                              {" "}
                                                            </td>
                                                            <td
                                                              className="text-right col5"
                                                              style={{
                                                                color:
                                                                  condition.includes(
                                                                    "NO PRE"
                                                                  )
                                                                    ? "white"
                                                                    : "",
                                                              }}
                                                            >
                                                              <small>
                                                                {t(
                                                                  "_general.tires"
                                                                )}
                                                              </small>
                                                              <br />
                                                              {
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][condition][
                                                                  "statistics"
                                                                ]
                                                              }
                                                            </td>
                                                            <td
                                                              className="text-right col6"
                                                              style={{
                                                                color:
                                                                  condition.includes(
                                                                    "NO PRE"
                                                                  )
                                                                    ? "white"
                                                                    : "",
                                                              }}
                                                            >
                                                              <small>
                                                                {t(
                                                                  "_labels.summary_report.percent"
                                                                )}
                                                              </small>
                                                              <br />
                                                              {percentFormatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][condition][
                                                                  "percent"
                                                                ]
                                                              )}
                                                              %
                                                            </td>
                                                          </tr>

                                                          {Object.keys(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][condition]
                                                          )
                                                            .filter(
                                                              (index) =>
                                                                ![
                                                                  "statistics",
                                                                  "percent",
                                                                ].includes(
                                                                  index
                                                                )
                                                            )
                                                            .map(
                                                              (application) => (
                                                                <Fragment
                                                                  key={
                                                                    application
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="pressure"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                    )}
                                                                    className="application box collapse"
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          pressure_condition:
                                                                            condition,
                                                                          tire_application_id:
                                                                            application,
                                                                          detail_type:
                                                                            "PRESSURE",
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td
                                                                      className="text-left"
                                                                      style={{
                                                                        paddingTop: 27,
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        `_labels.tire_application.options.${application.toLowerCase()}`
                                                                      )}
                                                                    </td>
                                                                    <td className="text-left col2">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col2">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col4">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_general.tires"
                                                                        )}
                                                                      </small>
                                                                      <br />
                                                                      {
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          condition
                                                                        ][
                                                                          application
                                                                        ][
                                                                          "statistics"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td className="text-right col6">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.summary_report.percent"
                                                                        )}
                                                                      </small>
                                                                      <br />
                                                                      {percentFormatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          condition
                                                                        ][
                                                                          application
                                                                        ][
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                        </Fragment>
                                                      ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {section == "pressure" &&
          dataChart.pressure &&
          Object.keys(dataChart.pressure).length > 0 && (
            <>
              {Object.keys(dataChart.pressure).map((corporate, index) => (
                <div className="row pt-1 pb-3" key={index}>
                  <div className="col-md-12">
                    <div className="container-fluid px-4">
                      <div className="card">
                        {Object.keys(dataChart.pressure).length > 1 && (
                          <div className="card-header">{corporate}</div>
                        )}
                        <div className="charts">
                          {Object.keys(dataChart.pressure[corporate]).map(
                            (division) => (
                              <div key={division} className="chart">
                                <DoughnutChart
                                  data={dataChart.pressure[corporate][division]}
                                  title={division}
                                  tooltipOptions={{
                                    tooltip: {
                                      callbacks: {
                                        label: (context) =>
                                          tooltipLabel(
                                            context,
                                            t("_labels.tire.plural")
                                          ),
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

        {section == "depth" &&
          data.depth &&
          Object.keys(data.depth).length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid px-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel panel-default">
                        <h4 className="text-center">
                          {t("_labels.depth_rating")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(data.depth).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "depth"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th className="text-right col1" />
                                    <th className="text-right col3" />
                                    <th className="text-right col3" />
                                    <th className="text-right col4" />
                                    <th className="text-right col5">
                                      {corporate[1]["statistics"]}
                                    </th>
                                    <th className="text-right col6">
                                      {percentFormatter.format(
                                        corporate[1]["percent"]
                                      )}
                                      %
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        !["statistics", "percent"].includes(
                                          index
                                        )
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="depth"
                                          data-id={removeCharacters(
                                            `${corporate[0]}_companies`
                                          )}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "depth"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td className="text-right col1"> </td>
                                          <td className="text-right col3" />
                                          <td className="text-right col3" />
                                          <td className="text-right col4" />
                                          <td className="text-right col5">
                                            {
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            }
                                          </td>
                                          <td className="text-right col6">
                                            {percentFormatter.format(
                                              corporate[1][company]["percent"]
                                            )}
                                            %
                                          </td>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              ![
                                                "statistics",
                                                "percent",
                                              ].includes(index)
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="depth"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "depth"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td className="text-right col1">
                                                  {" "}
                                                </td>
                                                <td className="text-right col3" />
                                                <td className="text-right col3" />
                                                <td className="text-right col4" />
                                                <td className="text-right col5">
                                                  {
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  }
                                                </td>
                                                <td className="text-right col6">
                                                  {percentFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["percent"]
                                                  )}
                                                  %
                                                </td>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    ![
                                                      "statistics",
                                                      "percent",
                                                    ].includes(index)
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="depth"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "depth"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td className="text-right col1">
                                                        {" "}
                                                      </td>
                                                      <td className="text-right col3" />
                                                      <td className="text-right col3" />
                                                      <td className="text-right col4" />
                                                      <td className="text-right col5">
                                                        {
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        }
                                                      </td>
                                                      <td className="text-right col6">
                                                        {percentFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["percent"]
                                                        )}
                                                        %
                                                      </td>
                                                    </tr>

                                                    {corporate[1][company][
                                                      subsidiary
                                                    ][division]["labels"]
                                                      .filter(
                                                        (el) =>
                                                          !el
                                                            .toLowerCase()
                                                            .includes("label")
                                                      )
                                                      .map((condition) => (
                                                        <Fragment
                                                          key={condition}
                                                        >
                                                          <tr
                                                            data-type="depth"
                                                            data-id={removeCharacters(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                            )}
                                                            className={`box collapse condition ${
                                                              condition.includes(
                                                                "SCHEDULED"
                                                              )
                                                                ? "set_sem_amarillo"
                                                                : condition.includes(
                                                                    "CRITICAL"
                                                                  )
                                                                ? "set_sem_rojo"
                                                                : "set_sem_verde"
                                                            }`}
                                                            onClick={() =>
                                                              toggleTable(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                                `${company}_${subsidiary}_${division}_${condition}`,
                                                                "depth"
                                                              )
                                                            }
                                                          >
                                                            <td
                                                              className="text-left"
                                                              style={{
                                                                paddingTop: 27,
                                                              }}
                                                            >
                                                              <i
                                                                className={`bx bxs-circle ${
                                                                  condition.includes(
                                                                    "SCHEDULED"
                                                                  )
                                                                    ? "set_sem_amarillo"
                                                                    : condition.includes(
                                                                        "CRITICAL"
                                                                      )
                                                                    ? "set_sem_rojo"
                                                                    : "set_sem_verde"
                                                                }`}
                                                              />
                                                              &nbsp;
                                                              {t(
                                                                `_labels.summary_report.depth_condition.${removeCharacters(
                                                                  condition,
                                                                  "_"
                                                                ).toLowerCase()}`
                                                              )}
                                                            </td>
                                                            <td className="text-right col1">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col2">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col3">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col4">
                                                              {" "}
                                                            </td>
                                                            <td className="text-right col5">
                                                              <small>
                                                                {t(
                                                                  "_general.tires"
                                                                )}
                                                              </small>
                                                              <br />
                                                              {
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][condition][
                                                                  "statistics"
                                                                ]
                                                              }
                                                            </td>
                                                            <td className="text-right col6">
                                                              <small>
                                                                {t(
                                                                  "_labels.summary_report.percent"
                                                                )}
                                                              </small>
                                                              <br />
                                                              {percentFormatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][condition][
                                                                  "percent"
                                                                ]
                                                              )}
                                                              %
                                                            </td>
                                                          </tr>

                                                          {Object.keys(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][condition]
                                                          )
                                                            .filter(
                                                              (index) =>
                                                                ![
                                                                  "statistics",
                                                                  "percent",
                                                                ].includes(
                                                                  index
                                                                )
                                                            )
                                                            .map(
                                                              (application) => (
                                                                <Fragment
                                                                  key={
                                                                    application
                                                                  }
                                                                >
                                                                  <tr
                                                                    data-type="depth"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                    )}
                                                                    className="application box collapse"
                                                                    onClick={() =>
                                                                      handleViewTires(
                                                                        {
                                                                          corporate_name:
                                                                            corporate[0],
                                                                          company_name:
                                                                            company,
                                                                          subsidiary_name:
                                                                            subsidiary,
                                                                          division_name:
                                                                            division,
                                                                          depth_condition:
                                                                            condition,
                                                                          tire_application_id:
                                                                            application,
                                                                          detail_type:
                                                                            "DEPTH",
                                                                        }
                                                                      )
                                                                    }
                                                                  >
                                                                    <td
                                                                      className="text-left"
                                                                      style={{
                                                                        paddingTop: 27,
                                                                      }}
                                                                    >
                                                                      {t(
                                                                        `_labels.tire_application.options.${application.toLowerCase()}`
                                                                      )}
                                                                    </td>
                                                                    <td className="text-left col2">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col2">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col4">
                                                                      {" "}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      <small>
                                                                        {t(
                                                                          "_general.tires"
                                                                        )}
                                                                      </small>
                                                                      <br />
                                                                      {
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          condition
                                                                        ][
                                                                          application
                                                                        ][
                                                                          "statistics"
                                                                        ]
                                                                      }
                                                                    </td>
                                                                    <td className="text-right col6">
                                                                      <small>
                                                                        {t(
                                                                          "_labels.summary_report.percent"
                                                                        )}
                                                                      </small>
                                                                      <br />
                                                                      {percentFormatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          condition
                                                                        ][
                                                                          application
                                                                        ][
                                                                          "percent"
                                                                        ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                  </tr>
                                                                </Fragment>
                                                              )
                                                            )}
                                                        </Fragment>
                                                      ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {section == "depth" &&
          dataChart.depth &&
          Object.keys(dataChart.depth).length > 0 && (
            <>
              {Object.keys(dataChart.depth).map((corporate, index) => (
                <div className="row pt-1 pb-3" key={index}>
                  <div className="col-md-12">
                    <div className="container-fluid px-4">
                      <div className="card">
                        {Object.keys(dataChart.depth).length > 1 && (
                          <div className="card-header">{corporate}</div>
                        )}
                        <div className="charts">
                          {Object.keys(dataChart.depth[corporate]).map(
                            (division) => (
                              <div key={division} className="chart">
                                <DoughnutChart
                                  data={dataChart.depth[corporate][division]}
                                  title={division}
                                  tooltipOptions={{
                                    tooltip: {
                                      callbacks: {
                                        label: (context) =>
                                          tooltipLabel(
                                            context,
                                            t("_labels.tire.plural")
                                          ),
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
      </div>

      <TireDialog
        open={openTireDialog}
        onClose={handleCloseTireDialog}
        params={params}
        handleViewAlerts={handleViewAlerts}
      />
      <FilterTireSummaryForm
        current={current}
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  current: PropTypes.bool,
  data: PropTypes.object,
  dataChart: PropTypes.object,
  section: PropTypes.string.isRequired,
  handleChangeSection: PropTypes.func.isRequired,
  handleViewAlerts: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  handleCloseTireDialog: PropTypes.func.isRequired,
  handleViewTires: PropTypes.func.isRequired,
  handleGenerateSummaryExcel: PropTypes.func.isRequired,
};

export default Page;
