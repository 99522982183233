import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "components/organisms/general/Table";

function Table({ selectable = false, ...props }) {
  const t = useLang(NAMESPACE);
  const [selected, setSelected] = useState([]);
  const { rows, headers } = props;
  const propertys = headers.map((header) => header.id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.key);
      const newArray = newSelecteds.concat(selected);

      setSelected([...new Set(newArray)]);
      return;
    }

    const elements = rows.map((n) => n.key);
    setSelected(selected.filter((item) => !elements.includes(item)));
    return;
  };

  function handleClick(id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function isSelected(id) {
    return selected.indexOf(id) !== -1;
  }

  return (
    <Page
      t={t}
      {...props}
      headers={headers}
      selectable={selectable}
      propertys={propertys}
      selected={selected}
      isSelected={isSelected}
      handleClick={handleClick}
      handleSelectAllClick={handleSelectAllClick}
    />
  );
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  sticky: PropTypes.bool,
  size: PropTypes.string,
  selectable: PropTypes.bool,
  totalRows: PropTypes.number,
  onDelete: PropTypes.func,
};

export default Table;
