import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { TextField } from "formik-material-ui";

import RadioInput from "components/atoms/general/RadioInput";
import SelectField from "components/molecules/general/SelectField";
import RadioField from "components/molecules/general/RadioField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  subsidiaries,
  onClose,
  isSubmitting,
  isValid,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="overline" color="textSecondary" display="block">
        {t("_titles.filter.status.tpms")}
      </Typography>
      <Field fullWidth required name="status" component={RadioField}>
        <RadioInput value="enabled">
          {t("_general.enabled.plural.male")}
        </RadioInput>
        <RadioInput value="disabled">
          {t("_general.disabled.plural.male")}
        </RadioInput>
      </Field>

      <Field
        fullWidth
        multiple
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        margin="normal"
        name="subsidiaries"
        component={SelectField}
        helperText={t("_labels.subsidiary_field.plural.helper_text")}
        label={t("_labels.subsidiary_field.plural.label")}
      >
        {subsidiaries.map((subsidiary, index) => {
          return (
            <MenuItem
              key={index}
              value={subsidiary.subsidiary_id.toString()}
              disabled={!subsidiary.status}
            >
              {subsidiary.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        variant="filled"
        margin="normal"
        name="search_code"
        component={TextField}
        label={t("_labels.tire_code")}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  subsidiaries: PropTypes.array,
  values: PropTypes.object.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
