import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";
import ContainerTemplate from "components/templates/general/Container";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterAlertReportForm from "components/organisms/report/FilterAlertReportForm";
import Button from "components/molecules/general/Button";

import camion from "resources/img/icons/camion-de-reparto.png";
import neumaticos from "resources/img/icons/rueda.png";
import revision from "resources/img/icons/revision.png";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import css from "./index.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: true,
  locale: "en",
  scales: {
    y: {
      min: 0,
      stacked: true,
    },
    x: {
      stacked: true,
      type: "time",
      time: {
        parser: "YYYY-MM",
        tooltipFormat: "MMMM YYYY",
        unit: "month",
        displayFormats: {
          month: "MMM YYYY",
        },
      },
    },
  },
  parsing: {
    xAxisKey: "fecha",
    yAxisKey: "total",
  },
  plugins: {
    legend: {
      position: "top",
    },
  },
};
function Page({
  t,
  filter,
  selectedFilter,
  tireData,
  vehicleData,
  mountData,
  damagesData,
  wearData,
  chartData,
  detailsData,
  totalAlerts,
  tableRef,
  selectedOption,
  visibleOption,
  setVisible,
  showDetails,
  handleClickAlert,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <ContainerTemplate
      title={t("_general.alerts")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pt-2">
        <div className="container-fluid py-2">
          {Object.keys(filter).length > 0 && (
            <div className="row pt-2 px-0">
              <div className="col-md-6">
                <table className="table table-sm">
                  <tbody>
                    {filter.corporate_id && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.corporate.singular")}:
                        </td>
                        <td>{selectedFilter.corporate}</td>
                      </tr>
                    )}
                    {filter.companies && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.company.singular")}:
                        </td>
                        <td>{selectedFilter.companies}</td>
                      </tr>
                    )}

                    {filter.subsidiaries && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.subsidiary.singular")}:
                        </td>
                        <td>{selectedFilter.subsidiaries}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table">
                  <tbody>
                    {filter.date_from && filter.date_to && (
                      <>
                        <tr>
                          <td className="text-right sbgazul">
                            {t("_labels.date.from")}:
                          </td>
                          <td>
                            {moment(selectedFilter.date_from).format("LL")}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right sbgazul">
                            {t("_labels.date.to")}:
                          </td>
                          <td>{moment(selectedFilter.date_to).format("LL")}</td>
                        </tr>
                      </>
                    )}
                    {filter.rankingAlert && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.ranking_alert.label")}:
                        </td>
                        <td>{selectedFilter.rankingAlerts}</td>
                      </tr>
                    )}
                    {filter.priority && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.priority.label")}:
                        </td>
                        <td>{selectedFilter.priorities}</td>
                      </tr>
                    )}
                    {filter.codes && (
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_general.alerts")}:
                        </td>
                        <td>{selectedFilter.codes}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className={classnames(css.tab)}>
            <div
              className={classnames({
                [css.option_tab]: true,
                [css.active]: visibleOption === "tire",
              })}
              onClick={() => setVisible("tire")}
            >
              <img src={neumaticos} width={36} /> {t("_general.tires")}{" "}
              <span className="badge badge-pill badge-light">
                {formatter.format(totalAlerts["tires"])}
              </span>
            </div>
            <div
              className={classnames({
                [css.option_tab]: true,
                [css.active]: visibleOption === "vehicle",
              })}
              onClick={() => setVisible("vehicle")}
            >
              <img src={camion} width={36} /> {t("_general.vehicles")}{" "}
              <span className="badge badge-pill badge-light">
                {formatter.format(totalAlerts["vehicles"])}
              </span>
            </div>
            <div
              className={classnames({
                [css.option_tab]: true,
                [css.active]: visibleOption === "mount",
              })}
              onClick={() => setVisible("mount")}
            >
              <img src={revision} width={36} /> {t("_general.mounting")}{" "}
              <span className="badge badge-pill badge-light">
                {formatter.format(totalAlerts["mounts"])}
              </span>
            </div>
            <div
              className={classnames({
                [css.option_tab]: true,
                [css.active]: visibleOption === "damages",
              })}
              onClick={() => setVisible("damages")}
            >
              {t("_labels.damage.plural")}{" "}
              <span className="badge badge-pill badge-light">
                {formatter.format(totalAlerts["damages"])}
              </span>
            </div>
            <div
              className={classnames({
                [css.option_tab]: true,
                [css.active]: visibleOption === "wear",
              })}
              onClick={() => setVisible("wear")}
            >
              {t("_labels.wear.plural")}{" "}
              <span className="badge badge-pill badge-light">
                {formatter.format(totalAlerts["wears"])}
              </span>
            </div>
          </div>
          {visibleOption === "tire" && (
            <>
              {tireData.length > 0 ? (
                tireData.map(([ranking, alerts]) => (
                  <div key={ranking}>
                    <p className="h5 mb-1">
                      {t(
                        `_labels.ranking_alert.options.${ranking.toLowerCase()}`
                      )}
                    </p>
                    <div className={classnames(css.container)}>
                      <div className={classnames(css.alert_container)}>
                        {alerts.map(([, alert]) => (
                          <BootstrapTooltip
                            key={alert["alert_id"]}
                            title={t(`_alert.details.${alert["details"]}`)}
                            placement="bottom"
                          >
                            <div
                              className={classnames({
                                [css.alert]: true,
                                [css.alert_high]: alert["priority"] === "HIGH",
                                [css.alert_low]: alert["priority"] === "LOW",
                                [css.alert_half]: alert["priority"] === "HALF",
                              })}
                              onClick={() => handleClickAlert(alert)}
                            >
                              <span className={classnames(css.alert_code)}>
                                <VisibilityIcon />
                                {t(
                                  `_alert.colloquial_name.${alert["colloquial_name"]}`
                                )}
                              </span>
                              <div>
                                <span className={classnames(css.dato_a)}>
                                  {formatter.format(alert["statistics"])}
                                </span>
                                <span className={classnames(css.dato_b)}>
                                  {formatter.format(alert["percent"])}%
                                </span>
                              </div>
                            </div>
                          </BootstrapTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyListIcon text={t("_labels.no_results")} />
              )}
            </>
          )}

          {visibleOption === "vehicle" && (
            <>
              {vehicleData.length > 0 ? (
                vehicleData.map(([ranking, alerts]) => (
                  <div key={ranking}>
                    <p className="h5 mb-1">
                      {t(
                        `_labels.ranking_alert.options.${ranking.toLowerCase()}`
                      )}
                    </p>
                    <div className={classnames(css.container)}>
                      <div className={classnames(css.alert_container)}>
                        {alerts.map(([, alert]) => (
                          <BootstrapTooltip
                            key={alert["alert_id"]}
                            title={t(`_alert.details.${alert["details"]}`)}
                            placement="bottom"
                          >
                            <div
                              className={classnames({
                                [css.alert]: true,
                                [css.alert_high]: alert["priority"] === "HIGH",
                                [css.alert_low]: alert["priority"] === "LOW",
                                [css.alert_half]: alert["priority"] === "HALF",
                              })}
                              onClick={() => handleClickAlert(alert)}
                            >
                              <span className={classnames(css.alert_code)}>
                                <VisibilityIcon />
                                {t(
                                  `_alert.colloquial_name.${alert["colloquial_name"]}`
                                )}
                              </span>
                              <div>
                                <span className={classnames(css.dato_a)}>
                                  {formatter.format(alert["statistics"])}
                                </span>
                                <span className={classnames(css.dato_b)}>
                                  {formatter.format(alert["percent"])}%
                                </span>
                              </div>
                            </div>
                          </BootstrapTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyListIcon text={t("_labels.no_results")} />
              )}
            </>
          )}

          {visibleOption === "mount" && (
            <>
              {mountData.length > 0 ? (
                mountData.map(([ranking, alerts]) => (
                  <div key={ranking}>
                    <p className="h5 mb-1">
                      {t(
                        `_labels.ranking_alert.options.${ranking.toLowerCase()}`
                      )}
                    </p>
                    <div className={classnames(css.container)}>
                      <div className={classnames(css.alert_container)}>
                        {alerts.map(([, alert]) => (
                          <BootstrapTooltip
                            key={alert["alert_id"]}
                            title={t(`_alert.details.${alert["details"]}`)}
                            placement="bottom"
                          >
                            <div
                              className={classnames({
                                [css.alert]: true,
                                [css.alert_high]: alert["priority"] === "HIGH",
                                [css.alert_low]: alert["priority"] === "LOW",
                                [css.alert_half]: alert["priority"] === "HALF",
                              })}
                              onClick={() => handleClickAlert(alert)}
                            >
                              <span className={classnames(css.alert_code)}>
                                <VisibilityIcon />
                                {t(
                                  `_alert.colloquial_name.${alert["colloquial_name"]}`
                                )}
                              </span>
                              <div>
                                <span className={classnames(css.dato_a)}>
                                  {formatter.format(alert["statistics"])}
                                </span>
                                <span className={classnames(css.dato_b)}>
                                  {formatter.format(alert["percent"])}%
                                </span>
                              </div>
                            </div>
                          </BootstrapTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyListIcon text={t("_labels.no_results")} />
              )}
            </>
          )}

          {visibleOption === "damages" && (
            <>
              {damagesData.length > 0 ? (
                damagesData.map(([attribution, damages]) => (
                  <div key={attribution}>
                    <p className="h5 mb-1">
                      {t(
                        `_labels.attribution.options.${attribution.toLowerCase()}`
                      )}
                    </p>
                    <div className={classnames(css.container)}>
                      <div className={classnames(css.alert_container)}>
                        {damages.map(([, damage]) => (
                          <BootstrapTooltip
                            key={damage["damage_id"]}
                            title={t(
                              `_damage.appearance.${damage["name"]}_appearance`
                            )}
                            placement="bottom"
                          >
                            <div
                              className={classnames({
                                [css.alert]: true,
                                [css.alert_half]: true,
                              })}
                              onClick={() => handleClickAlert(damage)}
                            >
                              <span className={classnames(css.alert_code)}>
                                <VisibilityIcon />
                                {t(`_damage.name.${damage["name"]}`)}
                              </span>
                              <div>
                                <span className={classnames(css.dato_a)}>
                                  {formatter.format(damage["statistics"])}
                                </span>
                                <span className={classnames(css.dato_b)}>
                                  {formatter.format(damage["percent"])}%
                                </span>
                              </div>
                            </div>
                          </BootstrapTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyListIcon text={t("_labels.no_results")} />
              )}
            </>
          )}

          {visibleOption === "wear" && (
            <>
              {wearData.length > 0 ? (
                wearData.map(([attribution, wears]) => (
                  <div key={attribution}>
                    <p className="h5 mb-1">
                      {t(
                        `_labels.attribution.options.${attribution.toLowerCase()}`
                      )}
                    </p>
                    <div className={classnames(css.container)}>
                      <div className={classnames(css.alert_container)}>
                        {wears.map(([, wear]) => (
                          <BootstrapTooltip
                            key={wear["wear_id"]}
                            title={t(
                              `_damage.appearance.${wear["name"]}_appearance`
                            )}
                            placement="bottom"
                          >
                            <div
                              className={classnames({
                                [css.alert]: true,
                                [css.alert_half]: true,
                              })}
                              onClick={() => handleClickAlert(wear)}
                            >
                              <span className={classnames(css.alert_code)}>
                                <VisibilityIcon />
                                {t(`_damage.name.${wear["name"]}`)}
                              </span>
                              <div>
                                <span className={classnames(css.dato_a)}>
                                  {formatter.format(wear["statistics"])}
                                </span>
                                <span className={classnames(css.dato_b)}>
                                  {formatter.format(wear["percent"])}%
                                </span>
                              </div>
                            </div>
                          </BootstrapTooltip>
                        ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyListIcon text={t("_labels.no_results")} />
              )}
            </>
          )}

          {showDetails && (
            <div className={classnames(css.data_container)}>
              {Object.keys(chartData).length > 0 && (
                <div className="mt-2 card" style={{ width: "47%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_titles.historic")}
                  </div>
                  <div className="card-body">
                    <Line options={defaultOptions} data={chartData} />
                  </div>
                </div>
              )}
              {visibleOption === "tire" && detailsData.length > 0 && (
                <div className="mt-2 card" style={{ width: "52%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_general.alerts")}
                  </div>
                  <div className="w-100 card-body">
                    <div
                      className={classnames("table-responsive", css.table_wrap)}
                      ref={tableRef}
                    >
                      <table className={classnames("table", css.tabla)}>
                        <thead>
                          <tr
                            className={classnames({
                              [css.red]: selectedOption["priority"] === "HIGH",
                              [css.green]: selectedOption["priority"] === "LOW",
                              [css.yellow]:
                                selectedOption["priority"] === "HALF",
                              "text-center": true,
                            })}
                          >
                            <th colSpan={4}>
                              {t(
                                `_alert.colloquial_name.${detailsData[0]["colloquial_name"]}`
                              )}
                            </th>
                          </tr>
                          <tr className="bg-dark text-white font-weight-normal">
                            <th>{t("_labels.date.label")}</th>
                            <th>{t("_labels.code")}</th>
                            <th>{t("_labels.location.label")}</th>
                            <th>Pos.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailsData.map((alert, index) => (
                            <tr key={`${alert["alert_id"]}_${index}`}>
                              <td>
                                {visibleOption === "tire"
                                  ? `${moment(alert["created_at"]).format(
                                      "LL"
                                    )} ${t("_labels.time_at")} ${moment(
                                      alert["created_at"]
                                    ).format("h:mm a")}`
                                  : `${moment(alert["updated_at"]).format(
                                      "LL"
                                    )} ${t("_labels.time_at")} ${moment(
                                      alert["updated_at"]
                                    ).format("h:mm a")}`}
                              </td>
                              <td>{alert["code"]}</td>
                              <td>{alert["location"]}</td>
                              <td>{alert["real_position"]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {visibleOption === "mount" && detailsData.length > 0 && (
                <div className="mt-2 card" style={{ width: "52%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_general.alerts")}
                  </div>
                  <div className="w-100 card-body">
                    <div
                      className={classnames("table-responsive", css.table_wrap)}
                      ref={tableRef}
                    >
                      <table className={classnames("table", css.tabla)}>
                        <thead>
                          <tr
                            className={classnames({
                              [css.red]: selectedOption["priority"] === "HIGH",
                              [css.green]: selectedOption["priority"] === "LOW",
                              [css.yellow]:
                                selectedOption["priority"] === "HALF",
                              "text-center": true,
                            })}
                          >
                            <th colSpan={5}>
                              {t(
                                `_alert.colloquial_name.${detailsData[0]["colloquial_name"]}`
                              )}
                            </th>
                          </tr>
                          <tr className="bg-dark text-white font-weight-normal">
                            <th>{t("_labels.inspection_date")}</th>
                            <th>{t("_labels.created_at")}</th>
                            <th>{t("_labels.code")}</th>
                            <th>{t("_labels.location.label")}</th>
                            <th>Pos.</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailsData.map((alert, index) => (
                            <tr key={`${alert["alert_id"]}_${index}`}>
                              <td>{getDate(t, alert["tire_review_date"])}</td>
                              <td>
                                {`${moment(alert["created_at"]).format(
                                  "LL"
                                )} ${t("_labels.time_at")} ${moment(
                                  alert["created_at"]
                                ).format("h:mm a")}`}
                              </td>
                              <td>{alert["code"]}</td>
                              <td>{alert["location"]}</td>
                              <td>{alert["real_position"]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {(visibleOption === "damages" || visibleOption === "wear") &&
                detailsData.length > 0 && (
                  <div className="mt-2 card" style={{ width: "52%" }}>
                    <div className="card-header sMarcoInfoFondoAzul">
                      {visibleOption === "damages"
                        ? t("_labels.damage.plural")
                        : t("_labels.wear.plural")}
                    </div>
                    <div className="w-100 card-body">
                      <div
                        className={classnames(
                          "table-responsive",
                          css.table_wrap
                        )}
                        ref={tableRef}
                      >
                        <table className={classnames("table", css.tabla)}>
                          <thead>
                            <tr
                              className={classnames({
                                [css.yellow]: true,
                                "text-center": true,
                              })}
                            >
                              <th colSpan={4}>
                                {t(`_damage.name.${detailsData[0]["name"]}`)}
                              </th>
                            </tr>
                            <tr className="bg-dark text-white font-weight-normal">
                              <th>{t("_labels.registered_by")}</th>
                              <th>{t("_labels.code")}</th>
                              <th>{t("_labels.comment.singular")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {detailsData.map((damage, index) => {
                              const id =
                                damage.tire_wear_id ?? damage.tire_damage_id;
                              return (
                                <tr key={`${id}_${index}`}>
                                  <td>
                                    {`${damage.created_by.name}`}
                                    {damage.created_by.last_name_1
                                      ? ` ${damage.created_by.last_name_1}`
                                      : ""}
                                    {damage.created_by.last_name_2
                                      ? ` ${damage.created_by.last_name_2}`
                                      : ""}
                                    <br />
                                    {`${moment(damage["created_at"]).format(
                                      "LL"
                                    )} ${t("_labels.time_at")} ${moment(
                                      damage["created_at"]
                                    ).format("h:mm a")}`}
                                  </td>
                                  <td>{damage["code"]}</td>
                                  <td>{damage["comment"] ?? "-"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

              {visibleOption === "vehicle" && detailsData.length > 0 && (
                <div className="mt-2 card" style={{ width: "52%" }}>
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_general.alerts")}
                  </div>
                  <div className="w-100 card-body">
                    <div
                      className={classnames("table-responsive", css.table_wrap)}
                      ref={tableRef}
                    >
                      <table className={classnames("table", css.tabla)}>
                        <thead>
                          <tr
                            className={classnames({
                              [css.red]: selectedOption["priority"] === "HIGH",
                              [css.green]: selectedOption["priority"] === "LOW",
                              [css.yellow]:
                                selectedOption["priority"] === "HALF",
                              "text-center": true,
                            })}
                          >
                            <th colSpan={4}>
                              {t(
                                `_alert.colloquial_name.${detailsData[0]["colloquial_name"]}`
                              )}
                            </th>
                          </tr>
                          <tr className="bg-dark text-white font-weight-normal">
                            <th>{t("_labels.date.label")}</th>
                            <th>{t("_labels.economic_number")}</th>
                            <th>{t("_labels.type")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailsData.map((alert, index) => (
                            <tr key={`${alert["alert_id"]}_${index}`}>
                              <td>{`${moment(alert["created_at"]).format(
                                "LL"
                              )} ${t("_labels.time_at")} ${moment(
                                alert["created_at"]
                              ).format("h:mm a")}`}</td>
                              <td>{alert["economic_number"]}</td>
                              <td>{alert["TYPE"]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <FilterAlertReportForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  chartData: PropTypes.object.isRequired,
  detailsData: PropTypes.array.isRequired,
  tireData: PropTypes.array.isRequired,
  vehicleData: PropTypes.array.isRequired,
  damagesData: PropTypes.array.isRequired,
  wearData: PropTypes.array.isRequired,
  mountData: PropTypes.array.isRequired,
  visibleOption: PropTypes.string,
  setVisible: PropTypes.func,
  showDetails: PropTypes.bool,
  tableRef: PropTypes.object,
  totalAlerts: PropTypes.object,
  selectedOption: PropTypes.object,
  handleClickAlert: PropTypes.func,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default Page;

function getDate(t, date) {
  const local = moment.utc(date).toDate();
  const dateString = moment(local).format("LL");
  const time = moment(local).format("hh:mm a");

  return `${dateString} ${t("_labels.time_at")} ${time}`;
}
