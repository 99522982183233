export default {
  title: "Inicio de sesión",
  sub_title: "Ingresa tus credenciales para iniciar sesión",
  form: {
    enter_button: "Entrar",
    change_password_button: "¿Olvidaste tu contraseña?",
  },
  messages: {
    login: "Listo, ha iniciado sesión con éxito.",
  },
};
