export default {
  link_gps: "Vincular gps",
  device_name: "Nombre del dispositivo",
  geofence_status: "Estatus de la geocerca",
  inside: "Adentro",
  outside: "Afuera",
  no_gps: "Sin gps vinculado",
  permits: {
    show_link: {
      vehicle_with_gps: "Sin permisos ver vínculos de vehículos con gps",
    },
    list: {
      gps: "Sin permisos para listar gps",
    },
  },
  form: {
    select_a_gps: "Selecciona un gps",
  },
};
