export default {
  title: "Update general policies",
  messages: {
    policy_updated: "Done, the general policies has been successfully updated",
  },
  form: {
    permits: {
      update: "No permissions to update policies",
    },
  },
};
