export default {
  new_wear: "New wear",
  update_wear: "Update wear",
  form: {
    permits: {
      update: "No permissions to update wear",
      delete: "No permissions to delete wear",
    },
  },
  messages: {
    wear_created: "Done, a new wear was successfully created",
    wear_updated: "Done, the wear was successfully updated",
    wear_deleted: "Done, the wear was successfully deleted",
    delete: "Are you sure to delete wear?",
  },
};
