export default {
  root: "Sin permisos de administrador",
  create: {
    user: "Sin permisos para crear usuarios",
    company: "Sin permisos para crear empresas",
    subsidiary: "Sin permisos para crear sucursales",
    association: "Sin permisos para crear transportistas",
    division: "Sin permisos para crear divisiones",
    gps: "Sin permisos para crear gps",
    driver: "Sin permisos para crear conductores",
    warehouse: "Sin permisos para crear almacenes",
    depth_tolerance_policy:
      "Sin permisos para crear políticas de tolerancia de profundidad",
    vehicle_travel: "Sin permisos para crear recorridos de vehículos",
    retirement_cause: "Sin permisos para crear causas de retiro",
    gps: "Sin permisos para crear gps",
    tire_wear: "Sin permisos para crear desgastes a neumáticos",
    tire_repair: "Sin permisos para enviar a reparar neumáticos",
    alert: "Sin permisos para crear alertas",
    brand: "Sin permisos para crear marcas",
    provider: "Sin permisos para crear proveedor",
    size: "Sin permisos para crear medidas",
    wear: "Sin permisos para crear desgastes",
    damage: "Sin permisos para crear tipos de daños",
    revitalized_tire_model:
      "Sin permisos para crear un modelo de neumático revitalizado",
    rfid: "Sin permisos para crear rfid",
    tire_model: "Sin permisos para crear modelos de neumático",
    vehicle_type: "Sin permisos para crear tipo de vehículo",
    vehicle_type_axle: "Sin permisos para crear el eje del tipo de vehículo",
    vehicle: "Sin permisos para crear vehículos",
    condition_policy: "Sin permisos para crear políticas de condición",
    pressure_policy: "Sin permisos para crear políticas de presión",
    depth_policy: "Sin permisos para crear políticas de profundidad",
    tire: "Sin permisos para crear neumáticos",
    tire_revitalization: "Sin permisos para enviar a revitalizado",
    tire_repair: "Sin permisos para enviar a reparación",
    tire_pile: "Sin permisos para enviar a pila de desecho",
    model_pressure_policy:
      "Sin permisos para crear políticas de presión por modelo",
    vehicle_review: "Sin permisos para crear revisiones de vehículo",
    concept: "Sin permisos para crear conceptos",
    block: "Sin permisos para crear bloques",
    checklist: "Sin permisos para crear checklists",
    tpms: "Sin permisos para crear sensores tpms",
  },
  show: {
    gps: "Sin permisos para ver gps",
    company: "Sin permisos para ver empresas",
    policy: "Sin permisos para ver políticas",
    tolerance_policy:
      "Sin permisos para ver una política de tolerancias de profundidad",
    subsidiary: "Sin permisos para ver sucursales",
    user: "Sin permisos para ver usuarios",
    provider: "Sin permisos para ver proveedores",
    revitalized: "Sin permisos para ver los modelos revitalizados",
    warehouse: "Sin permisos para ver almacenes",
    driver: "Sin permisos para ver conductores",
    tire: "Sin permisos para ver neumáticos",
    vehicle: "Sin permisos para ver vehículos",
    division: "Sin permisos para ver divisiones",
    tire_cycle: "Sin permisos para ver ciclos de neumáticos",
    tire_repair: "Sin permisos para ver reparaciones de neumáticos",
    tire_damage: "Sin permisos para ver daños en neumáticos",
    damage: "Sin permisos para ver daños",
    wear: "Sin permisos para ver desgastes",
    tire_wear: "Sin permisos para ver desgastes en neumáticos",
    link: {
      tire_with_warehouse:
        "Sin permisos para ver enlaces de neumáticos con almacenes",
      driver_with_subsidiary:
        "Sin permisos para ver enlaces de conductores con sucursales",
      vehicle_with_subsidiary:
        "Sin permisos para ver enlaces de vehículos con sucursales",
      vehicle_with_gps: "Sin permisos para ver enlaces de vehículos con gps",
      warehouse_with_subsidiary:
        "Sin permisos para ver enlaces de almacenes con sucursales",
      tire_with_division:
        "Sin permisos para ver enlaces de neumáticos con divisiones",
    },
    rfid: "Sin permisos para ver rfids",
    checklist: "Sin permisos para ver checklists",
  },
  list: {
    user: "Sin permisos para listar usuarios",
    subsidiary: "Sin permisos para listar sucursales",
    vehicle: "Sin permisos para listar vehículos",
    tire: "Sin permisos para listar neumáticos",
    wear: "Sin permisos para listar desgastes",
    warehouse: "Sin permisos para listar almacenes",
    driver: "Sin permisos para listar conductores",
    provider: "Sin permisos para listar proveedores",
    rfid: "Sin permisos para listar rfids",
    depth_tolerance_policy:
      "Sin permisos para listar políticas de tolerancia de profundidad",
    condition_policy: "Sin permisos para listar políticas de condición",
    pressure_policy: "Sin permisos para listar políticas de presión",
    depth_policy: "Sin permisos para listar políticas de profundidad",
    helmet_value: "Sin permisos para listar valores de casco",
  },
  list_link: {
    tire_with_subsidiary:
      "Sin permisos para listar neumáticos vinculados a sucursales",
    tire_with_warehouse:
      "Sin permisos para listar neumáticos vinculados a almacenes",
    tire_with_division:
      "Sin permisos para listar neumáticos vinculados a divisiones",
    vehicle_with_subsidiary:
      "Sin permisos para listar vehículos vinculados a sucursales",
    driver_with_subsidiary:
      "Sin permisos para listar conductores vinculados a sucursales",
    warehouse_with_subsidiary:
      "Sin permisos para listar almacenes vinculados a sucursales",
    subsidiary_with_company:
      "Sin permisos para listar sucursales vinculadas a empresas",
    user_with_company:
      "Sin permisos para listar usuarios vinculados a empresas",
    user_with_subsidiary:
      "Sin permisos para listar usuarios vinculados a sucursales",
    vehicle_with_division:
      "Sin permisos para listar vehículos vinculados a divisiones",
    history: {
      warehouse_with_tire:
        "Sin permisos para listar almacenes vinculados a neumáticos",
    },
  },
  link: {
    vehicle_with_driver: "Sin permisos para vincular vehículos con conductores",
    tire_with_warehouse: "Sin permisos para vincular neumáticos con almacenes",
    vehicle_with_division:
      "Sin permisos para vincular vehículos con divisiones",
    vehicle_with_gps: "Sin permisos para vincular vehículos con gps",
    driver_with_subsidiary:
      "Sin permisos para vincular conductores con sucursales",
    tire_with_subsidiary:
      "Sin permisos para vincular neumáticos con sucursales",
    vehicle_with_subsidiary:
      "Sin permisos para vincular vehículos con sucursales",
    warehouse_with_subsidiary:
      "Sin permisos para vincular almacenes con sucursales",
    tire_with_division: "Sin permisos para vincular neumáticos con divisiones",
    tire_with_rfid: "Sin permisos para vincular neumáticos con rfids",
    tpms_with_tire: "Sin permisos para vincular sensores tpms con neumáticos",
  },
  delete_link: {
    tire_with_warehouse:
      "Sin permisos para desvincular neumáticos de almacenes",
    vehicle_with_driver:
      "Sin permisos para eliminar vínculos de vehículos con conductores",
    vehicle_with_gps: "Sin permisos para desvincular vehículos y gps",
    vehicle_with_division:
      "Sin permisos para desvincular vehículos y divisiones",
    driver_with_subsidiary:
      "Sin permisos para desvincular conductores y sucursales",
    warehouse_with_subsidiary:
      "Sin permisos para desvincular almacenes y sucursales",
    tire_with_subsidiary:
      "Sin permisos para desvincular neumáticos y sucursales",
    vehicle_with_subsidiary:
      "Sin permisos para desvincular vehículos y sucursales",
    tire_with_division:
      "Sin permisos para desvincular neumáticos de divisiones",
  },
  assign: {
    user_to_corporate: "Sin permisos para vincular usuarios con corporativos",
    rol_to_user_corporate:
      "Sin permisos para asignar rol a usuarios en corporativos",
    user_to_company: "Sin permisos para vincular usuarios con empresas",
    rol_to_user_company: "Sin permisos para asignar rol a usuarios en empresas",
    user_to_subsidiary: "Sin permisos para vincular usuarios con sucursales",
    rol_to_user_subsidiary:
      "Sin permisos para asignar rol a usuarios en sucursales",
  },
  unlink: {
    user_corporate:
      "Sin permisos para eliminar asignaciones de usuarios en corporativos",
    user_company:
      "Sin permisos para eliminar asignaciones de usuarios en empresas",
    user_subsidiary:
      "Sin permisos para eliminar asignaciones de usuarios en sucursales",
    role: {
      user_corporate:
        "Sin permisos para eliminar asignaciones de roles a usuarios en corporativos",
      user_subsidiary:
        "Sin permisos para eliminar asignaciones de roles a usuarios en sucursales",
    },
  },
  update: {
    tire_damage: "Sin permisos para actualizar daños de neumáticos",
    tire_wear: "Sin permisos para actualizar desgastes de neumáticos",
    brand: "Sin permisos para actualizar marcas",
    size: "Sin permisos para actualizar medidas",
    company: "Sin permisos para actualizar empresas",
    division: "Sin permisos para actualizar división",
    subsidiary: "Sin permisos para actualizar sucursales",
    association: "Sin permisos para actualizar transportistas",
    provider: "Sin permisos para actualizar proveedor",
    wear: "Sin permisos para actualizar desgastes",
    retirement_cause: "Sin permisos para actualizar la causas de retiro",
    warehouse: "Sin permisos para actualizar almacén",
    gps: "Sin permisos para actualizar gps",
    driver: "Sin permisos para actualizar conductores",
    damage: "Sin permisos para actualizar tipo de daño",
    revitalized_tire_model:
      "Sin permisos para actualizar el modelo de neumático revitalizado",
    alert: "Sin permisos para actualizar alertas",
    rfid: "Sin permisos para actualizar rfid",
    vehicle_type: "Sin permisos para actualizar tipo de vehículo",
    company_policy: "Sin permisos para actualizar políticas de empresa",
    tire_model: "Sin permisos para actualizar modelos de neumático",
    vehicle_type_axle:
      "Sin permisos para actualizar el eje del tipo de vehículo",
    vehicle: "Sin permisos para actualizar vehículos",
    condition_policy: "Sin permisos para actualizar políticas de condición",
    pressure_policy: "Sin permisos para actualizar políticas de presión",
    depth_policy: "Sin permisos para actualizar políticas de profundidad",
    tire: "Sin permisos para actualizar neumáticos",
    model_pressure_policy:
      "Sin permisos para actualizar políticas de presión por modelo",
    tire_review: "Sin permisos para actualizar revisiones de neumático",
    vehicle_review: "Sin permisos para actualizar revisiones de vehículo",
    concept: "Sin permisos para actualizar conceptos",
    block: "Sin permisos para actualizar bloques",
    user: "Sin permisos para actualizar usuarios",
    checklist: "Sin permisos para actualizar checklists",
    tpms: "Sin permisos para actualizar sensores tpms",
  },
  delete: {
    tire_wear: "Sin permisos para eliminar desgastes de neumáticos",
    brand: "Sin permisos para eliminar marcas",
    size: "Sin permisos para eliminar medidas",
    company: "Sin permisos para eliminar empresas",
    division: "Sin permisos para eliminar división",
    subsidiary: "Sin permisos para eliminar sucursales",
    association: "Sin permisos para eliminar transportistas",
    provider: "Sin permisos para eliminar proveedor",
    wear: "Sin permisos para eliminar desgastes",
    gps: "Sin permisos para eliminar gps",
    driver: "Sin permisos para eliminar conductores",
    warehouse: "Sin permisos para eliminar almacenes",
    retirement_cause: "Sin permisos para eliminar las causas de retiro",
    damage: "Sin permisos para eliminar tipo de daño",
    revitalized_tire_model:
      "Sin permisos para eliminar el modelo de neumático revitalizado",
    alert: "Sin permisos para eliminar alertas",
    rfid: "Sin permisos para eliminar rfid",
    tire_model: "Sin permisos para eliminar modelos de neumático",
    vehicle_type: "Sin permisos para eliminar tipo de vehículo",
    vehicle: "Sin permisos para eliminar vehículos",
    condition_policy: "Sin permisos para eliminar políticas de condición",
    pressure_policy: "Sin permisos para eliminar políticas de presión",
    depth_policy: "Sin permisos para eliminar políticas de profundidad",
    tire: "Sin permisos para eliminar neumáticos",
    model_pressure_policy:
      "Sin permisos para eliminar políticas de presión por modelo",
    vehicle_review: "Sin permisos para eliminar revisiones de vehículo",
    concept: "Sin permisos para eliminar conceptos",
    block: "Sin permisos para eliminar bloques",
    user: "Sin permisos para eliminar usuarios",
    checklist: "Sin permisos para eliminar checklists",
    tpms: "Sin permisos para eliminar sensores tpms",
  },
  reset: {
    odometer: "Sin permisos para reiniciar odómetro",
    difference: "Sin permisos para reiniciar la diferencia del gps",
  },
  cancel_movement: "Sin permisos para cancelar movimientos",
  reject: "Sin permisos para marcar como rechazo de revitalizado",
};
