import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import css from "./index.module.scss";

function Page({
  size,
  name,
  value,
  variant,
  required,
  disabled,
  children,
  label,
  helperText,
  margin,
  fullWidth,
  className,
  handleChange,
  MenuProps,
  multiple,
  defaultValue,
  defaultText,
  error,
}) {
  return (
    <FormControl
      size={size}
      required={required}
      error={error}
      variant={variant}
      className={classnames(
        {
          [css.margin]: margin === "normal",
          [css.fullWidth]: fullWidth,
        },
        className
      )}
    >
      <InputLabel
        className={classnames({
          ["MuiInputLabel-shrink"]: value === "" && defaultText,
        })}
        id={`label-select-${name}`}
      >
        {label}
      </InputLabel>
      <Select
        displayEmpty
        id={`select-${name}`}
        labelId={`label-select-${name}`}
        label={label}
        value={value}
        multiple={multiple}
        MenuProps={MenuProps}
        required={required}
        disabled={disabled}
        onChange={handleChange}
      >
        {defaultText && (
          <MenuItem disabled value={defaultValue}>
            {defaultText}
          </MenuItem>
        )}
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

Page.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  MenuProps: PropTypes.object,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultText: PropTypes.string,
};

export default Page;
