import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionGetGps } from "store/actions/gps/getGps";
import { actionSetNotification } from "store/actions/general/notification";

import { SHOW_GPS } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "src/components/organisms/gps/GpsTabs";

export function GpsTabs({ className, optionsAppBar, children, ...rest }) {
  const t = useLang(NAMESPACE);
  const { id: gpsId } = useParams();
  const { gps, permits, getGps, setNotification } = rest;
  const showGpsPermit = permits.includes(SHOW_GPS);

  const tabLinks = [
    {
      to: `/gps/${gpsId}`,
      label: t("_labels.information"),
      disabled: !showGpsPermit,
      tooltip: !showGpsPermit ? t("_permits.show.gps") : "",
    },
    {
      to: `/gps/${gpsId}/geofence`,
      label: t("geofence"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getGps(gpsId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showGpsPermit) {
      fetchData();
    }
  }, [gpsId]);

  return (
    <Page
      tabLinks={tabLinks}
      className={className}
      optionsAppBar={optionsAppBar}
      title={showGpsPermit ? gps.device_name : t("_permits.show.gps")}
    >
      {children}
    </Page>
  );
}

GpsTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  gps: state.Gps.Gps.gps,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  getGps: actionGetGps(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GpsTabs);
