import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import FilterUserReviewReportForm from "components/organisms/report/FilterUserReviewReportForm";
import TireDialog from "./TireDialog";
import { removeCharacters, tooltipLabel } from "utils/tire";
import VehicleDialog from "./VehicleDialog";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  chartData,
  userReviewData,
  filter,
  selectedFilter,
  openFilter,
  openTireDialog,
  handleOpenTireDialog,
  handleCloseTireDialog,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateUserReviewExcel,
  handleClickVehicleType,
  vehicleReviewId,
  incompletedVehicles,
  openVehicleDialog,
  handleOpenVehicleDialog,
  handleCloseVehicleDialog,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.user_review_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table table-sm">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.companies && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiaries && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}

                  {filter.users && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.user.plural.label")}:
                      </td>
                      <td>{selectedFilter.users}</td>
                    </tr>
                  )}

                  {filter.review_types && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.review_type.label")}:
                      </td>
                      <td>{selectedFilter.review_types}</td>
                    </tr>
                  )}
                  {filter.with_mount_dismount !== undefined && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.with_mount_dismount")}
                      </td>
                      <td>{selectedFilter.with_mount_dismount}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-sm">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                  {filter.divisions && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.division.plural")}:
                      </td>
                      <td>{selectedFilter.divisions}</td>
                    </tr>
                  )}
                  {filter.activity ? (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.activity.label")}:
                      </td>
                      <td>{selectedFilter.activity}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.activity.label")}:
                      </td>
                      <td>{t("_labels.activity.options.not_rule_out")}</td>
                    </tr>
                  )}
                  {filter.review_type ? (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.review_type.label")}:
                      </td>
                      <td>{selectedFilter.review_type}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.review_type.label")}:
                      </td>
                      <td>{t("_labels.no_option")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">
              {t("_labels.user_review_report.review_report")}
            </h1>
            <h5 className="sinmp">
              {t("_labels.user_review_report.report_show")}
            </h5>
            {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")}
                </small>
              </div>
            )}
            <hr />
          </div>
        </div>

        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-3">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateUserReviewExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
              {incompletedVehicles.length > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="ml-2"
                  onClick={handleOpenVehicleDialog}
                >
                  Vehículos con posiciones vacías{" "}
                  <span className="ml-1 badge badge-light">
                    {incompletedVehicles.length}
                  </span>
                </Button>
              )}
            </div>
          </div>

          {Object.keys(userReviewData).length > 0 && (
            <div className="col-md-12 my-2">
              <div className="container-fluid px-3">
                <div className="table-responsive rounded shadow-sm">
                  <table className="table table-bordered m-0 text-center">
                    <thead className="sMarcoInfoFondoAzul">
                      <tr>
                        <th>Usuario</th>
                        <th>Revisiones de Vehículo</th>
                        <th>Neumáticos Revisados</th>
                        <th>Revisiones de Neumáticos Realizadas</th>
                        <th>Porcentaje</th>
                        <th>Vehículos Revisados</th>
                        <th>Flota</th>
                        <th>Porcentaje de Revisión de Flota</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(userReviewData).map(
                        ([user, userData]) => (
                          <tr
                            key={user}
                            className={
                              user.key == "Total"
                                ? "sbngris font-weight-bold"
                                : ""
                            }
                          >
                            <td>{user}</td>
                            <td>{formatter.format(userData.vehicle_review)}</td>
                            <td>{formatter.format(userData.tire_review)}</td>
                            <td>
                              {formatter.format(userData.total_tire_review)}
                            </td>
                            <td>{formatter.format(userData.percent)}%</td>
                            <td>
                              {formatter.format(
                                userData.number_vehicles_review
                              )}
                            </td>
                            <td>{formatter.format(userData.fleet)}</td>
                            <td>
                              {percentFormatter.format(
                                userData.fleet_percentage
                              )}
                              %
                            </td>
                          </tr>
                        )
                      )}
                      <tr className={"sbngris font-weight-bold"}>
                        <td>Total</td>
                        <td>
                          {formatter.format(
                            Object.values(userReviewData)
                              .map((e) => e.vehicle_review)
                              .reduce((a, b) => a + b, 0)
                          )}
                        </td>
                        <td>
                          {formatter.format(
                            Object.values(userReviewData)
                              .map((e) => e.tire_review)
                              .reduce((a, b) => a + b, 0)
                          )}
                        </td>
                        <td>
                          {formatter.format(
                            Object.values(userReviewData)
                              .map((e) => e.total_tire_review)
                              .reduce((a, b) => a + b, 0)
                          )}
                        </td>
                        <td>100%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    {Object.keys(data).length > 0 && (
                      <div className="table-responsive">
                        {Object.entries(data).map((corporate) => (
                          <Fragment key={corporate[0]}>
                            <table className="table table-condensed shadow-sm">
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "original"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th></th>
                                  <th className="text-right col3"></th>
                                  <th className="text-right col3"></th>
                                  <th className="text-right col4"></th>
                                  <th className="text-right col5">
                                    <small>{t("_labels.review.plural")}</small>
                                    <br />
                                    {formatter.format(
                                      corporate[1]["statistics"]
                                    )}
                                  </th>
                                  <th className="text-right col6">
                                    <small>
                                      {t("_labels.user_review_report.percent")}
                                    </small>
                                    <br />
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      typeof corporate[1][index] === "object"
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="original"
                                        data-id={`${removeCharacters(
                                          corporate[0]
                                        )}_companies`}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "original"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td></td>
                                        <td className="text-right col3"></td>
                                        <td className="text-right col3"></td>
                                        <td className="text-right col4"></td>
                                        <td className="text-right col5">
                                          {formatter.format(
                                            corporate[1][company]["statistics"]
                                          )}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            typeof corporate[1][company][
                                              index
                                            ] === "object"
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="original"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "original"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td></td>
                                              <td className="text-right col3"></td>
                                              <td className="text-right col3"></td>
                                              <td className="text-right col4"></td>
                                              <td className="text-right col5">
                                                {formatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                )}
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  typeof corporate[1][company][
                                                    subsidiary
                                                  ][index] === "object"
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="original"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="division box collapse"
                                                    onClick={() => {
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_users`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "original"
                                                      );
                                                    }}
                                                  >
                                                    <td className="text-left">
                                                      {division ===
                                                      "NO DIVISION"
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td></td>
                                                    <td className="text-right col3"></td>
                                                    <td className="text-right col3"></td>
                                                    <td className="text-right col4"></td>
                                                    <td className="text-right col5">
                                                      {formatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      )}
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {Object.keys(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ][division]
                                                  )
                                                    .filter(
                                                      (index) =>
                                                        typeof corporate[1][
                                                          company
                                                        ][subsidiary][division][
                                                          index
                                                        ] === "object"
                                                    )
                                                    .map((user) => (
                                                      <Fragment key={user}>
                                                        <tr
                                                          data-type="original"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_users`
                                                          )}
                                                          className={`box collapse user`}
                                                          onClick={() => {
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_vehicletypes`,
                                                              `${company}_${subsidiary}_${division}_${user}`,
                                                              "original"
                                                            );
                                                          }}
                                                        >
                                                          <td className="text-left">
                                                            {user}
                                                          </td>
                                                          <td></td>
                                                          <td className="text-right col3"></td>
                                                          <td className="text-right col3"></td>
                                                          <td className="text-right col4"></td>
                                                          <td className="text-right col5">
                                                            {formatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][user][
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <td className="text-right col6">
                                                            {percentFormatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][user]["percent"]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][user]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              typeof corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][user][index] ===
                                                              "object"
                                                          )
                                                          .map(
                                                            (vehicleType) => (
                                                              <Fragment
                                                                key={
                                                                  vehicleType
                                                                }
                                                              >
                                                                <tr
                                                                  data-type="original"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_vehicletypes`
                                                                  )}
                                                                  className="vehicle_type box collapse"
                                                                  onClick={() => {
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_${vehicleType}_vehicles`,
                                                                      `${company}_${subsidiary}_${division}_${user}_${vehicleType}`,
                                                                      "original"
                                                                    );
                                                                    handleClickVehicleType(
                                                                      {
                                                                        corporate:
                                                                          corporate[0],
                                                                        company:
                                                                          company,
                                                                        subsidiary:
                                                                          subsidiary,
                                                                        division:
                                                                          division,
                                                                        user: user,
                                                                        vehicleType:
                                                                          vehicleType,
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <td className="text-left">
                                                                    {
                                                                      vehicleType
                                                                    }
                                                                  </td>
                                                                  <td></td>
                                                                  <td className="text-right col3"></td>
                                                                  <td className="text-right col3"></td>
                                                                  <td className="text-right col4"></td>
                                                                  <td className="text-right col5">
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][user][
                                                                        vehicleType
                                                                      ][
                                                                        "statistics"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    {percentFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][user][
                                                                        vehicleType
                                                                      ][
                                                                        "percent"
                                                                      ]
                                                                    )}
                                                                    %
                                                                  </td>
                                                                </tr>

                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][user][
                                                                    vehicleType
                                                                  ]
                                                                )
                                                                  .filter(
                                                                    (index) =>
                                                                      typeof corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][user][
                                                                        vehicleType
                                                                      ][
                                                                        index
                                                                      ] ===
                                                                      "object"
                                                                  )
                                                                  .map(
                                                                    (
                                                                      vehicle
                                                                    ) => (
                                                                      <Fragment
                                                                        key={
                                                                          vehicle
                                                                        }
                                                                      >
                                                                        <tr
                                                                          data-type="original"
                                                                          data-id={removeCharacters(
                                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_${vehicleType}_vehicles`
                                                                          )}
                                                                          className="vehicle box"
                                                                          onClick={() =>
                                                                            toggleTable(
                                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_${vehicleType}_${vehicle}_reviews`,
                                                                              `${company}_${subsidiary}_${division}_${user}_${vehicleType}_${vehicle}`,
                                                                              "original"
                                                                            )
                                                                          }
                                                                        >
                                                                          <td className="text-left pl-3">
                                                                            {
                                                                              vehicle
                                                                            }
                                                                          </td>
                                                                          <td></td>
                                                                          <td className="text-right col3"></td>
                                                                          <td className="text-right col3"></td>
                                                                          <td className="text-right col4"></td>
                                                                          <td className="text-right col5"></td>
                                                                          <td className="text-right col6"></td>
                                                                        </tr>

                                                                        {Object.values(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            user
                                                                          ][
                                                                            vehicleType
                                                                          ][
                                                                            vehicle
                                                                          ]
                                                                        ).map(
                                                                          (
                                                                            review
                                                                          ) => (
                                                                            <tr
                                                                              data-type="original"
                                                                              data-id={removeCharacters(
                                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${user}_${vehicleType}_${vehicle}_reviews`
                                                                              )}
                                                                              key={
                                                                                review.vehicle_review_id
                                                                              }
                                                                              className="review box collapse"
                                                                            >
                                                                              <td className="text-left">
                                                                                <Button
                                                                                  style={{
                                                                                    color:
                                                                                      "white",
                                                                                  }}
                                                                                  className="spanButton"
                                                                                  onClick={() => {
                                                                                    handleOpenTireDialog(
                                                                                      review.vehicle_review_id
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <VisibilityIcon className="mr-2" />
                                                                                  {t(
                                                                                    `_labels.review_type.options.${removeCharacters(
                                                                                      review.review_type,
                                                                                      "_"
                                                                                    ).toLowerCase()}`
                                                                                  )}
                                                                                  {
                                                                                    " - "
                                                                                  }
                                                                                  {moment(
                                                                                    review.date
                                                                                  ).format(
                                                                                    "LLL"
                                                                                  )}
                                                                                </Button>
                                                                              </td>
                                                                              <td className="text-right"></td>
                                                                              <td className="text-right"></td>
                                                                              <td className="text-right"></td>
                                                                              <td className="text-right"></td>
                                                                              <td className="text-right"></td>
                                                                              <td className="text-right"></td>
                                                                            </tr>
                                                                          )
                                                                        )}
                                                                      </Fragment>
                                                                    )
                                                                  )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                      </Fragment>
                                                    ))}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </table>
                            {chartData.hasOwnProperty(corporate[0]) && (
                              <div className="card mb-2 shadow-sm">
                                {Object.keys(chartData).length > 1 && (
                                  <div className="card-header">
                                    {corporate[0]}
                                  </div>
                                )}
                                <div className="charts">
                                  {Object.keys(chartData[corporate[0]]).map(
                                    (subsidiary) => (
                                      <Fragment key={subsidiary}>
                                        <div className="chart_user">
                                          <DoughnutChart
                                            data={
                                              chartData[corporate[0]][
                                                subsidiary
                                              ]
                                            }
                                            title={subsidiary}
                                            tooltipOptions={{
                                              tooltip: {
                                                callbacks: {
                                                  label: (context) =>
                                                    tooltipLabel(
                                                      context,
                                                      t("_labels.review.plural")
                                                    ),
                                                },
                                              },
                                            }}
                                          />
                                        </div>
                                      </Fragment>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TireDialog
        open={openTireDialog}
        onClose={handleCloseTireDialog}
        vehicleReviewId={vehicleReviewId}
        filter={filter}
      />

      <VehicleDialog
        open={openVehicleDialog}
        onClose={handleCloseVehicleDialog}
        vehicles={incompletedVehicles}
      />

      <FilterUserReviewReportForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  chartData: PropTypes.object.isRequired,
  userReviewData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  openTireDialog: PropTypes.bool.isRequired,
  handleOpenTireDialog: PropTypes.func.isRequired,
  handleCloseTireDialog: PropTypes.func.isRequired,
  handleGenerateUserReviewExcel: PropTypes.func.isRequired,
  handleClickVehicleType: PropTypes.func.isRequired,
  vehicleReviewId: PropTypes.number,
  incompletedVehicles: PropTypes.array,
  openVehicleDialog: PropTypes.bool,
  handleOpenVehicleDialog: PropTypes.func,
  handleCloseVehicleDialog: PropTypes.func,
};

export default Page;
