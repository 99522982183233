import { http } from "store/actions/http";
import { RESET_ODOMETER } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionResetVehicleReview =
  (dispatch) => async (vehicleId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "POST",
        path: `vehicle/${vehicleId}/review/reset`,
        data: fields,
      });

      dispatch({
        type: RESET_ODOMETER,
        payload: { vehicle_id: vehicleId, odometer: fields.odometer },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
