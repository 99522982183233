import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  isUpdate,
  isValid,
  vehicles,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        fullWidth
        required
        variant="filled"
        name="vehicle_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.economic_number")}
        disabled
      >
        {vehicles.map((vehicle, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicle.vehicle_id.toString()}
              disabled={!vehicle.status}
            >
              {vehicle.economic_number}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        required
        variant="filled"
        name="axle_type"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.axle_field.label")}
        disabled={isUpdate}
      >
        <MenuItem value="DIRECTIONAL" disabled={isUpdate}>
          {t("_labels.axle_field.options.directional")}
        </MenuItem>
        <MenuItem value="TRACTION" disabled={isUpdate}>
          {t("_labels.axle_field.options.traction")}
        </MenuItem>
        <MenuItem value="TRAILER" disabled={isUpdate}>
          {t("_labels.axle_field.options.trailer")}
        </MenuItem>
        <MenuItem value="REFECTION">
          {t("_labels.axle_field.options.refection")}
        </MenuItem>
      </Field>

      {/* <Field
        required
        variant="filled"
        margin="normal"
        name="good_depth"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.good_condition")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      /> */}

      <Field
        required
        variant="filled"
        margin="normal"
        name="scheduled_withdrawal"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.scheduled_withdrawal")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="critical_withdrawal"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.critical_withdrawal")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            step: "any",
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="start"
            >
              mm
            </InputAdornment>
          ),
        }}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
