import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useLang } from "hooks/lang";

import Page from "./page";

export const NAMESPACE = "components/molecules/general/TableToolbar";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
    minHeight: "48px",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

function TableToolbar({ onDelete, ...props }) {
  const t = useLang(NAMESPACE);
  const classes = useToolbarStyles();
  const { selected } = props;
  function handleClick() {
    onDelete(selected);
  }
  return (
    <Page
      {...props}
      t={t}
      classes={classes}
      numSelected={selected.length}
      handleClick={handleClick}
    />
  );
}

TableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  selectActionButton: PropTypes.node,
};

export default TableToolbar;
