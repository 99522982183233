import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";

function Page({
  t,
  open,
  title,
  text1,
  text2,
  label1,
  label2,
  viewName,
  createdBy,
  updatedBy,
  isHistory,
  unlinkName,
  viewTooltip,
  unlinkTooltip,
  onClose,
  onUnlink,
  handleView,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText gutterBottom label={label1} text={text1} />
            <LabelAndText gutterBottom label={label2} text={text2} />
          </div>

          <LabelAndText
            fullWidth
            gutterBottom
            text={createdBy}
            label={t("_labels.created_by")}
          />

          {updatedBy && (
            <LabelAndText
              fullWidth
              gutterBottom
              text={updatedBy}
              label={t("_labels.updated_by")}
            />
          )}
        </div>
        <div className={classnames(css.action)}>
          <div className={classnames(css.action_left)}>
            {!isHistory && (
              <Button
                color="secondary"
                onClick={onUnlink}
                tooltip={unlinkTooltip}
                disabled={!!unlinkTooltip}
              >
                {unlinkName}
              </Button>
            )}
          </div>

          <Button color="secondary" onClick={onClose}>
            {t("_buttons.close")}
          </Button>
          <Button
            color="secondary"
            onClick={handleView}
            tooltip={viewTooltip}
            disabled={!!viewTooltip}
          >
            {viewName}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  isHistory: PropTypes.bool,
  updatedBy: PropTypes.string,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  viewName: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  unlinkName: PropTypes.string.isRequired,
  viewTooltip: PropTypes.string.isRequired,
  unlinkTooltip: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
};

export default Page;
