export default {
  title: "Nuevo rfid",
  update_title: "Actualizar rfid",
  messages: {
    rfid_created: "Listo, se creó un nuevo rfid con éxito",
    rfid_updated: "Listo, se actualizó el rfid con éxito",
    rfid_deleted: "Listo, se eliminó el rfid con éxito",
    delete: "¿Estas seguro de eliminar el rfid?",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar rfids",
      delete: "Sin permisos para eliminar rfids",
    },
  },
};
