import React, { useState } from "react";
import PropTypes from "prop-types";

import Page from "./page";
function TableMultiple({ ...props }) {
  const [selected, setSelected] = useState([]);
  const { rows, objectSelection } = props;

  const handleAllSelect = (event) => {
    if (event.target.checked) {
      let newSelecteds = [];
      if (objectSelection) {
        newSelecteds = [...rows].filter(
          (row) => selected.findIndex((el) => el.id == row.id) < 0
        );
        setSelected(newSelecteds.concat(selected));
      } else {
        newSelecteds = rows.map((n) => n.id);
        const newArray = newSelecteds.concat(selected);

        setSelected([...new Set(newArray)]);
      }
      return;
    }

    if (objectSelection) {
      const elements = [...rows];
      setSelected(
        selected.filter(
          (item) => elements.findIndex((el) => el.id == item.id) < 0
        )
      );
    } else {
      const elements = rows.map((n) => n.id);
      setSelected(selected.filter((item) => !elements.includes(item)));
    }
  };

  function handleSelect(id) {
    let selectedIndex = null;

    if (objectSelection) {
      selectedIndex = selected.findIndex((el) => el.id === id.id);
    } else {
      selectedIndex = selected.indexOf(id);
    }

    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((element) => element.id != id.id);
    }

    setSelected(newSelected);
  }

  function isSelected(id) {
    if (objectSelection) {
      return selected.findIndex((el) => el.id === id) !== -1;
    }

    return selected.indexOf(id) !== -1;
  }

  return (
    <Page
      {...props}
      selected={selected}
      handleAllSelect={handleAllSelect}
      handleSelect={handleSelect}
      isSelected={isSelected}
    />
  );
}

TableMultiple.propTypes = {
  selectable: PropTypes.bool,
  selectAction: PropTypes.func,
  selectActionButton: PropTypes.node,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  propertys: PropTypes.array.isRequired,
  nestedPropertys: PropTypes.array.isRequired,
  nestedHeaders: PropTypes.array.isRequired,
  multipleProperty: PropTypes.string.isRequired,
  totalRows: PropTypes.number,
  objectSelection: PropTypes.bool,
};

export default TableMultiple;
