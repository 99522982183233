import { combineReducers } from "redux";

import Role from "./role";
import History from "./history";
import Company from "./company";

export default combineReducers({
  Role,
  History,
  Company,
});
