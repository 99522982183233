import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAssociationPressurePolicies } from "store/actions/association/policy/pressure/getPressurePolicies";
import { actionClearAssociationPressurePolicies } from "store/actions/association/policy/pressure/clearPressurePolicies";
import { actionSetAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/setAssociationPressurePolicyFilters";
import { actionClearAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/clearAssociationPressureFilters";
import { actionDeleteAssociationPressurePolicy } from "store/actions/association/policy/pressure/deleteAssociationPressurePolicy";

import {
  CREATE_VEHICLE_PRESSURE_POLICY,
  DELETE_VEHICLE_PRESSURE_POLICY,
  UPDATE_VEHICLE_PRESSURE_POLICY,
} from "store/actions/account/permits";
import Page from "./page";

export function PressurePolicyTab({ ...rest }) {
  const t = useLang();
  const { id: associationId } = useParams();
  const {
    filter,
    policies,
    permits,
    setNotification,
    getPressurePolicies,
    clearPressurePolicies,
    setPressurePoliciesFilters,
    clearPressurePoliciesFilters,
    deletePressurePolicy,
  } = rest;
  const [openForm, setOpenForm] = useState(false);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [openVehicleTypeForm, setOpenVehicleTypeForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [pressurePolicy, setPressurePolicy] = useState({});
  const [openMultipleForm, setOpenMultipleForm] = useState(false);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getPressurePolicies({ associationId, page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearPressurePolicies();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearPressurePoliciesFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getPressurePolicies({ associationId, page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(vehicle) {
    setVehicle(vehicle);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setVehicle({});
  }

  function handleOpenVehicleTypeForm() {
    setOpenVehicleTypeForm(true);
  }

  function handleCloseVehicleTypeForm() {
    setOpenVehicleTypeForm(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setPressurePoliciesFilters({ search });
  }

  function handleEdit(vehicle, pressurePolicy) {
    setVehicle(vehicle);
    setPressurePolicy(pressurePolicy);
    setOpenUpdateForm(true);
  }

  function handleCloseUpdateForm() {
    setOpenUpdateForm(false);
    setPressurePolicy({});
    setVehicle({});
  }

  async function onDeletePressurePolicy(vehicleId, pressurePolicyId) {
    try {
      await deletePressurePolicy(associationId, vehicleId, pressurePolicyId);
      setNotification({
        message: t("_messages.deleted.pressure_policy"),
      });
      clearPressurePoliciesFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(vehicleId, pressurePolicyId) {
    setNotification({
      onAction: () => {
        onDeletePressurePolicy(vehicleId, pressurePolicyId);
      },
      message: t("_messages.delete.pressure_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenMultiple(vehicles) {
    setVehicles(vehicles);
    setOpenMultipleForm(true);
  }

  function handleCloseMultiple() {
    setOpenMultipleForm(false);
  }

  return (
    <Page
      t={t}
      policies={policies}
      search={filter.search}
      filter={filter}
      vehicle={vehicle}
      pressurePolicy={pressurePolicy}
      openFilter={openFilter}
      openForm={openForm}
      openUpdateForm={openUpdateForm}
      openVehicleTypeForm={openVehicleTypeForm}
      handleEdit={handleEdit}
      handleCloseUpdateForm={handleCloseUpdateForm}
      handleDelete={handleDelete}
      handleSearch={handleSearch}
      handleCloseFilter={handleCloseFilter}
      handleOpenFilter={handleOpenFilter}
      handleOpenForm={handleOpenForm}
      handleOpenVehicleTypeForm={handleOpenVehicleTypeForm}
      handleCloseVehicleTypeForm={handleCloseVehicleTypeForm}
      handleCloseForm={handleCloseForm}
      handleChangePage={handleChangePage}
      openMultiple={openMultipleForm}
      vehicles={vehicles}
      handleOpenMultiple={handleOpenMultiple}
      handleCloseMultiple={handleCloseMultiple}
      updateVehiclePressurePolicyPermit={permits.includes(
        UPDATE_VEHICLE_PRESSURE_POLICY
      )}
      createVehiclePressurePolicyPermit={permits.includes(
        CREATE_VEHICLE_PRESSURE_POLICY
      )}
      deleteVehiclePressurePolicyPermit={permits.includes(
        DELETE_VEHICLE_PRESSURE_POLICY
      )}
    />
  );
}

PressurePolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  policies: state.Association.PressurePolicies.policies,
  filter: state.Association.PressurePolicies.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getPressurePolicies: actionGetAssociationPressurePolicies(dispatch),
  clearPressurePolicies: actionClearAssociationPressurePolicies(dispatch),
  setPressurePoliciesFilters:
    actionSetAssociationPressurePoliciesFilters(dispatch),
  clearPressurePoliciesFilters:
    actionClearAssociationPressurePoliciesFilters(dispatch),
  deletePressurePolicy: actionDeleteAssociationPressurePolicy(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PressurePolicyTab);
