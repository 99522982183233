import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetBestPerformanceReport } from "store/actions/reports/best_performance/getBestPerformanceReport";
import { actionClearBestPerformanceFilters } from "store/actions/reports/best_performance/clearBestPerformanceFilters";
import { actionGenerateBestPerformanceExcel } from "store/actions/reports/best_performance/generateBestPerformanceExcel";
import { actionSetBestPerformanceFilters } from "store/actions/reports/best_performance/setBestPerformanceFilters";
import { actionSetSelectedBestPerformanceFilters } from "store/actions/reports/best_performance/setSelectedBestPerformanceFilters";

import { useLang } from "hooks/lang";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

function BestPerformance({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [openFilter, setOpenFilter] = useState(false);

  const {
    filter,
    selectedFilter,
    setNotification,
    getBestPerformanceReport,
    clearBestPerformanceFilters,
    generateBestPerformanceExcel,
    setBestPerformanceFilters,
    setSelectedFilters,
    account,
    workArea,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBestPerformanceReport({ ...filter });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    setBestPerformanceFilters({
      movement: "MOUNT",
      min_travel: 15000,
      date_from: startDate,
      date_to: endDate,
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    });
    setSelectedFilters({
      movement:
        t("_labels.location.options.mount")[0] +
        t("_labels.location.options.mount").slice(1).toLowerCase(),
      min_travel: 15000,
      date_from: startDate,
      date_to: endDate,
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    });
    return () => {
      clearBestPerformanceFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateBestPerformanceExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSearch(search) {
    if (search === "") {
      setLastMovementReportFilters({ ...filter, search: undefined });
    } else {
      setLastMovementReportFilters({ ...filter, search });
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      search={filter.search}
      openFilter={openFilter}
      originalData={getOriginal(data)}
      revitalizedData={getRevitalized(data)}
      originalTierList={getOriginalTierList(data)}
      revitalizedTierList={getRevitalizedTierList(data)}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
      handleSearch={handleSearch}
    />
  );
}
BestPerformance.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.BestPerformance.filter,
  selectedFilter: state.Reports.BestPerformance.selected_filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  getBestPerformanceReport: actionGetBestPerformanceReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearBestPerformanceFilters: actionClearBestPerformanceFilters(dispatch),
  generateBestPerformanceExcel: actionGenerateBestPerformanceExcel(dispatch),
  setBestPerformanceFilters: actionSetBestPerformanceFilters(dispatch),
  setSelectedFilters: actionSetSelectedBestPerformanceFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestPerformance);

function getOriginal(data) {
  if (data.origin) {
    const corporates = Object.entries(data.origin).filter(
      ([key]) => key !== "tier_list"
    );

    const result = corporates.reduce((acc, corporate) => {
      const [key, value] = corporate;
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(result).forEach((corporate) => {
      Object.keys(result[corporate])
        .filter((key) => typeof result[corporate][key] === "object")
        .forEach((company) => {
          Object.keys(result[corporate][company])
            .filter(
              (key) => typeof result[corporate][company][key] === "object"
            )
            .forEach((subsidiary) => {
              Object.keys(result[corporate][company][subsidiary])
                .filter(
                  (key) =>
                    typeof result[corporate][company][subsidiary][key] ===
                    "object"
                )
                .forEach((division) => {
                  Object.keys(result[corporate][company][subsidiary][division])
                    .filter(
                      (key) =>
                        typeof result[corporate][company][subsidiary][division][
                          key
                        ] === "object"
                    )
                    .forEach((application) => {
                      Object.keys(
                        result[corporate][company][subsidiary][division][
                          application
                        ]
                      )
                        .filter(
                          (key) =>
                            typeof result[corporate][company][subsidiary][
                              division
                            ][application][key] === "object"
                        )
                        .forEach((size) => {
                          let aux = [];
                          Object.keys(
                            result[corporate][company][subsidiary][division][
                              application
                            ][size]
                          )
                            .filter(
                              (key) =>
                                typeof result[corporate][company][subsidiary][
                                  division
                                ][application][size][key] === "object" &&
                                !Array.isArray(
                                  result[corporate][company][subsidiary][
                                    division
                                  ][application][size][key]
                                )
                            )
                            .forEach((model) => {
                              aux.push([
                                model,
                                result[corporate][company][subsidiary][
                                  division
                                ][application][size][model]["cpk"],
                              ]);
                            });
                          result[corporate][company][subsidiary][division][
                            application
                          ][size]["data"] = aux.sort((a, b) => a[1] - b[1]);
                        });
                    });
                });
            });
        });
    });
    return result;
  }

  return {};
}

function getRevitalized(data) {
  if (data.revitalized) {
    const corporates = Object.entries(data.revitalized).filter(
      ([key]) => key !== "tier_list"
    );

    const result = corporates.reduce((acc, corporate) => {
      const [key, value] = corporate;
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(result).forEach((corporate) => {
      Object.keys(result[corporate])
        .filter((key) => typeof result[corporate][key] === "object")
        .forEach((company) => {
          Object.keys(result[corporate][company])
            .filter(
              (key) => typeof result[corporate][company][key] === "object"
            )
            .forEach((subsidiary) => {
              Object.keys(result[corporate][company][subsidiary])
                .filter(
                  (key) =>
                    typeof result[corporate][company][subsidiary][key] ===
                    "object"
                )
                .forEach((division) => {
                  Object.keys(result[corporate][company][subsidiary][division])
                    .filter(
                      (key) =>
                        typeof result[corporate][company][subsidiary][division][
                          key
                        ] === "object"
                    )
                    .forEach((application) => {
                      Object.keys(
                        result[corporate][company][subsidiary][division][
                          application
                        ]
                      )
                        .filter(
                          (key) =>
                            typeof result[corporate][company][subsidiary][
                              division
                            ][application][key] === "object"
                        )
                        .forEach((size) => {
                          let aux = [];
                          Object.keys(
                            result[corporate][company][subsidiary][division][
                              application
                            ][size]
                          )
                            .filter(
                              (key) =>
                                typeof result[corporate][company][subsidiary][
                                  division
                                ][application][size][key] === "object" &&
                                !Array.isArray(
                                  result[corporate][company][subsidiary][
                                    division
                                  ][application][size][key]
                                )
                            )
                            .forEach((model) => {
                              aux.push([
                                model,
                                result[corporate][company][subsidiary][
                                  division
                                ][application][size][model]["cpk"],
                              ]);
                            });
                          result[corporate][company][subsidiary][division][
                            application
                          ][size]["data"] = aux.sort((a, b) => a[1] - b[1]);
                        });
                    });
                });
            });
        });
    });

    return result;
  }

  return {};
}

function getOriginalTierList(data) {
  if (data.origin) {
    return data.origin.tier_list;
  }

  return [];
}

function getRevitalizedTierList(data) {
  if (data.revitalized) {
    return data.revitalized.tier_list;
  }

  return [];
}
