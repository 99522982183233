import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

import PropTypes from "prop-types";

import {
  LIST_GPS,
  LIST_GPS_GEOFENCE,
  LIST_USER,
  LIST_PROVIDER,
  LIST_COMPANY,
  LIST_SUBSIDIARY,
  LIST_DRIVER,
  LIST_WAREHOUSE,
  LIST_VEHICLE,
  LIST_DIVISION,
  LIST_ASSOCIATION,
  LIST_DAMAGE,
  LIST_WEAR,
  LIST_TIRE,
  SHOW_TIRE,
  SHOW_POLICY,
  LIST_RETIREMENT_CAUSE,
  LIST_REVITALIZED_TIRE_MODEL,
  LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE,
  SHOW_VEHICLE,
  LIST_RFID,
  SHOW_SUBSIDIARY,
  LIST_LINK_ALERT_WITH_TIRE,
  LIST_LINK_ALERT_WITH_VEHICLE,
  SHOW_TIRE_DAMAGE,
  SHOW_TIRE_WEAR,
  LIST_VEHICLE_PRESSURE_POLICY,
  LIST_TIRE_MODEL_VARIATION_POLICY,
  LIST_CHECK_LIST,
  LIST_CONCEPT,
  LIST_BLOCK,
  SHOW_TIRE_PILE_REPORT,
  LIST_TPGPS,
  SHOW_LINK_TIRE_WITH_VEHICLE,
} from "store/actions/account/permits";

import Route from "components/organisms/general/Route";
import Login from "pages/account/Login";
import NotFound from "pages/general/NotFound";
import HomeVehicle from "pages/vehicle/HomeVehicle";
import HomeMount from "pages/mount/HomeMount";
import HomeReview from "pages/review/HomeReview";

import HomeDriver from "pages/driver/HomeDriver";

import HomeTire from "pages/tire/HomeTire";
import TireHistory from "pages/tire/TireHistory";
import TireReviewHistory from "pages/tire/TireReviewHistory";
import TireInfoTab from "pages/tire/TireInfoTab";
import TireWearTab from "pages/tire/TireWearTab";
import TireDamageTab from "pages/tire/TireDamageTab";

import TireAlertTab from "pages/tire/TireAlertTab";

import HomeVehicleType from "pages/vehicle/type/HomeVehicleType";
import RecoveryPassword from "pages/account/RecoveryPassword";
import ChangePassword from "pages/account/ChangePassword";

import HomeCorporate from "pages/corporate/HomeCorporate";

import HomeWarehouse from "pages/warehouse/HomeWarehouse";
import HomeCompany from "pages/company/HomeCompany";
import ApplicationTirePolicyTab from "pages/subsidiary/ApplicationTirePolicyTab";

import HomeDamage from "pages/damage/HomeDamage";

import HomeWear from "pages/wear/HomeWear";

import HomeBrand from "pages/brand/HomeBrand";

import HomeUser from "pages/user/HomeUser";

import HomeTireSize from "pages/tire/size/HomeTireSize";

import HomeSubsidiary from "pages/subsidiary/HomeSubsidiary";
import ConditionPolicyTab from "pages/subsidiary/ConditionPolicyTab";
import PressurePolicyTab from "pages/subsidiary/PressurePolicyTab";
import ModelVariationPolicyTab from "pages/subsidiary/ModelVariationPolicyTab";
import HomeSubsidiaryPolicy from "pages/subsidiary/HomeSubsidiaryPolicy";
import DepthPolicyTab from "pages/subsidiary/DepthPolicyTab";
import HelmetValueTab from "pages/subsidiary/HelmetValueTab";

import HomeDivision from "pages/division/HomeDivision";
import HomeAssociation from "pages/association/HomeAssociation";

import HomeProvider from "pages/provider/HomeProvider";

import HomeTireModel from "pages/tire/model/HomeTireModel";
import TireModelInfoTab from "pages/tire/model/TireModelInfoTab";
import TireModelVariationTab from "pages/tire/model/TireModelVariationTab";

import TireReviewHistoryTab from "pages/tire/history/TireReviewHistoryTab";
import TireVehicleHistoryTab from "pages/tire/history/TireVehicleHistoryTab";
import TireRevitalizationHistoryTab from "pages/tire/history/TireRevitalizationHistoryTab";
import TireRepairHistoryTab from "pages/tire/history/TireRepairHistoryTab";
import TireCycleHistoryTab from "pages/tire/history/TireCycleHistoryTab";
import TireWarehouseHistoryTab from "pages/tire/history/TireWarehouseHistoryTab";
import TirePileHistoryTab from "pages/tire/history/TirePileHistoryTab";
import TireDivisionHistoryTab from "pages/tire/history/TireDivisionHistoryTab";
import TireSubsidiaryHistoryTab from "pages/tire/history/TireSubsidiaryHistoryTab";
import TireAlertHistoryTab from "pages/tire/history/TireAlertHistoryTab";

import VehicleTypeInfoTab from "pages/vehicle/type/VehicleTypeInfoTab";
import VehicleTypeAxleTab from "pages/vehicle/type/VehicleTypeAxleTab";
import VehiclePreviewTab from "pages/vehicle/VehiclePreviewTab";
import VehicleAlertTab from "pages/vehicle/VehicleAlertTab";
import AlertsByVehicle from "pages/vehicle/alert/AlertsByVehicle";

import VehicleReviewHistory from "pages/vehicle/ReviewHistory";
import VehicleTirePerformance from "pages/vehicle/TirePerformance";
import VehicleMovementHistory from "pages/vehicle/MovementHistory";
import VehicleTravelHistoryTab from "pages/vehicle/history/VehicleTravelHistoryTab";
import VehicleTireHistoryTab from "pages/vehicle/history/VehicleTireHistoryTab";
import VehicleDriverHistoryTab from "pages/vehicle/history/VehicleDriverHistoryTab";
import VehicleGpsHistoryTab from "pages/vehicle/history/VehicleGpsHistoryTab";
import VehicleDivisionHistoryTab from "pages/vehicle/history/VehicleDivisionHistoryTab";
import VehicleSubsidiaryHistoryTab from "pages/vehicle/history/VehicleSubsidiaryHistoryTab";
import VehicleAlertHistoryTab from "pages/vehicle/history/VehicleAlertHistoryTab";

import HomeVehicleReview from "pages/vehicle/review/HomeVehicleReview";
import DoInspection from "pages/vehicle/review/DoInspection";
import TirePressureReview from "pages/vehicle/review/TirePressureReview";
import TireIdentifyReview from "pages/vehicle/review/TireIdentifyReview";

import Mount from "pages/vehicle/Mount";

import HomeRetirementCause from "pages/retirement/cause/HomeRetirementCause";

import GpsGeofenceTab from "pages/gps/GpsGeofenceTab";
import HomeGps from "pages/gps/HomeGps";

import HomeRfid from "pages/rfid/HomeRfid";
import HomeAlert from "pages/alert/HomeAlert";
import TireAlerts from "pages/alert/TireAlerts";
import VehicleAlerts from "pages/alert/VehicleAlerts";
import MountAlerts from "pages/alert/MountAlerts";
import AlertsByMount from "pages/mount/AlertsByMount";
import AlertsByTire from "pages/tire/AlertsByTire";

import HomeRevitalizedTireModel from "pages/revitalized/tire/model/HomeRevitalizedTireModel";
import TireStats from "pages/reports/TireStats";
import PileStats from "pages/reports/PileStats";
import BestPerformance from "pages/reports/BestPerformance";
import KmProjectionReport from "pages/reports/KmProjection";
import SummaryReport from "pages/reports/SummaryReport";
import DamagesWears from "pages/reports/DamagesWears";
import DynamicCost from "pages/reports/DynamicCost";
import PileSummary from "pages/reports/PileSummary";
import PilePerformance from "pages/reports/PilePerformance";
import Pile from "pages/reports/Pile";
import RenewabilityIndex from "pages/reports/RenewabilityIndex";
import ReviewPerformance from "pages/reports/ReviewPerformance";
import Warehouse from "pages/reports/Warehouse";
import PotentialSavings from "pages/reports/PotentialSavings";
import UserReviewReport from "pages/reports/UserReviewReport";
import Purchase from "pages/reports/Purchase";
import LastMovement from "pages/reports/LastMovement";
import SemaphoreReport from "pages/reports/SemaphoreReport";
import FleetSemaphoreReport from "pages/reports/FleetSemaphoreReport";
import AlertReport from "pages/alert/AlertReport";
import TireRevitalized from "pages/reports/TireRevitalized";
import SummaryWarehouse from "pages/reports/SummaryWarehouse";
import SummaryMount from "pages/reports/SummaryMount";
import TireRepair from "pages/reports/TireRepair";
import HomeCommissionedDriver from "pages/commissioned/HomeCommissionedDriver";
import HomeCommissionedVehicle from "pages/commissioned/HomeCommissionedVehicle";
import UpdateReview from "pages/vehicle/review/UpdateReview";
import KmPile from "pages/reports/KmPile";
import HomeChecklist from "pages/checklist/HomeChecklist";
import HomeConcept from "pages/concept/HomeConcept";
import HomeBlock from "pages/block/HomeBlock";
import HomeDisposal from "pages/disposal/HomeDisposal";
import AssociationPressurePolicyTab from "pages/association/PressurePolicyTab";
import AssociationDepthPolicyTab from "pages/association/DepthPolicyTab";
import ReviewPerformanceCompany from "pages/reports/ReviewPerformanceCompany";
import CompletedCycles from "pages/reports/CompletedCycles";
import HomeTpms from "pages/tpms/HomeTpms";
import HomeNotification from "pages/notification/HomeNotification";
import ChecklistHistory from "pages/checklist/ChecklistHistory";
import TpmsHistory from "pages/vehicle/TpmsHistory";
import TemperaturePolicyTab from "pages/subsidiary/TemperaturePolicyTab";
import TpmsAlerts from "pages/mount/TpmsAlerts";
import ScheduledWithdrawalTires from "pages/reports/ScheduledWithdrawalTires";
import VehicleInstallations from "pages/vehicle/VehicleInstallations";
import HomeTutorials from "pages/help/HomeTutorials";
import Tutorial from "pages/help/Tutorial";

function Page({ to }) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact needSession path="/tutorials">
          <HomeTutorials />
        </Route>
        <Route exact needSession path="/tutorials/:id">
          <Tutorial />
        </Route>
        <Route exact needSession path="/review">
          <HomeReview />
        </Route>
        <Route exact needSession path="/alert/home">
          <AlertReport />
        </Route>
        <Route exact path="/recovery/password">
          <RecoveryPassword />
        </Route>

        <Route exact needSession path="/vehicle/type/:id/axle">
          <VehicleTypeAxleTab />
        </Route>

        <Route exact needSession path="/vehicle/type/:id">
          <VehicleTypeInfoTab />
        </Route>

        <Route exact needSession path="/vehicle/type">
          <HomeVehicleType />
        </Route>

        <Route exact needSession path="/vehicle/review">
          <HomeVehicleReview />
        </Route>

        <Route
          exact
          needSession
          path="/vehicle/review/:id"
          requiredPermits={[]}
        >
          <DoInspection />
        </Route>

        <Route exact needSession path="/vehicle/review/:id/update">
          <UpdateReview />
        </Route>

        <Route
          exact
          needSession
          path="/vehicle/:id/preview"
          requiredPermits={[SHOW_VEHICLE]}
        >
          <VehiclePreviewTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/alert">
          <VehicleAlertTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/alerts">
          <AlertsByVehicle />
        </Route>

        <Route
          exact
          needSession
          path="/vehicle/:id/installations"
          requiredPermits={[SHOW_LINK_TIRE_WITH_VEHICLE]}
        >
          <VehicleInstallations />
        </Route>

        <Route exact needSession path="/vehicleReview/:id/history">
          <VehicleReviewHistory />
        </Route>

        <Route exact needSession path="/vehicleReview/:id/tire_performance">
          <VehicleTirePerformance />
        </Route>

        <Route exact needSession path="/vehicleReview/:id/movement">
          <VehicleMovementHistory />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_VEHICLE]}
          path="/vehicle/:id/travel/history"
        >
          <VehicleTravelHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/tire/history">
          <VehicleTireHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/driver/history">
          <VehicleDriverHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/gps/history">
          <VehicleGpsHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/division/history">
          <VehicleDivisionHistoryTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_VEHICLE]}
          path="/vehicle/:id/subsidiary/history"
        >
          <VehicleSubsidiaryHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/alert/history">
          <VehicleAlertHistoryTab />
        </Route>

        <Route exact needSession path="/vehicle/:id/mount">
          <Mount />
        </Route>

        <Route exact needSession path="/vehicle/:id/tire/pressure">
          <TirePressureReview />
        </Route>

        <Route exact needSession path="/vehicle/:id/tire/identify">
          <TireIdentifyReview />
        </Route>

        <Route exact needSession path="/brand">
          <HomeBrand />
        </Route>
        <Route exact needSession isRootRequired path="/corporate">
          <HomeCorporate />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_ASSOCIATION]}
          path="/association"
        >
          <HomeAssociation />
        </Route>

        <Route exact needSession path="/association/:id/policy/pressure">
          <AssociationPressurePolicyTab />
        </Route>

        <Route exact needSession path="/association/:id/policy/depth">
          <AssociationDepthPolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_DRIVER]}
          path="/association/driver"
        >
          <HomeCommissionedDriver />
        </Route>

        <Route exact needSession requiredPermits={[LIST_BLOCK]} path="/block">
          <HomeBlock />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_CONCEPT]}
          path="/concept"
        >
          <HomeConcept />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_CHECK_LIST]}
          path="/checklist"
        >
          <HomeChecklist />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[]}
          path="/checklist/history/:id"
        >
          <ChecklistHistory />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_VEHICLE]}
          path="/association/vehicle"
        >
          <HomeCommissionedVehicle />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_DIVISION]}
          path="/division"
        >
          <HomeDivision />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_COMPANY]}
          path="/company"
        >
          <HomeCompany />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_SUBSIDIARY]}
          path="/subsidiary"
        >
          <HomeSubsidiary />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_POLICY]}
          path="/subsidiary/:id/policy"
        >
          <HomeSubsidiaryPolicy />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY]}
          path="/subsidiary/:id/application/policy/tire"
        >
          <ApplicationTirePolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY, LIST_VEHICLE_PRESSURE_POLICY]}
          path="/subsidiary/:id/condition/policy/vehicle"
        >
          <ConditionPolicyTab />
        </Route>

        <Route
          exact
          needSession
          // requiredPermits={[SHOW_SUBSIDIARY, LIST_VEHICLE_PRESSURE_POLICY]}
          path="/subsidiary/:id/temperature/policy"
        >
          <TemperaturePolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY, LIST_VEHICLE_PRESSURE_POLICY]}
          path="/subsidiary/:id/pressure/policy/vehicle"
        >
          <PressurePolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY, LIST_VEHICLE_PRESSURE_POLICY]}
          path="/subsidiary/:id/depth/policy/vehicle"
        >
          <DepthPolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY]}
          path="/subsidiary/:id/model/pressure/policy/vehicle"
        >
          <ModelVariationPolicyTab />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[SHOW_SUBSIDIARY, LIST_TIRE_MODEL_VARIATION_POLICY]}
          path="/subsidiary/:id/helmet/value"
        >
          <HelmetValueTab />
        </Route>

        <Route exact needSession requiredPermits={[LIST_DRIVER]} path="/driver">
          <HomeDriver />
        </Route>

        <Route
          exact
          needSession
          path="/warehouse"
          requiredPermits={[LIST_WAREHOUSE]}
        >
          <HomeWarehouse />
        </Route>

        <Route exact needSession path="/tire/size">
          <HomeTireSize />
        </Route>

        <Route exact needSession path="/damage" requiredPermits={[LIST_DAMAGE]}>
          <HomeDamage />
        </Route>

        <Route exact needSession path="/user" requiredPermits={[LIST_USER]}>
          <HomeUser />
        </Route>

        <Route exact needSession requiredPermits={[LIST_WEAR]} path="/wear">
          <HomeWear />
        </Route>

        <Route exact path="/change/password/:token?">
          <ChangePassword />
        </Route>

        <Route exact needSession path="/tire/model">
          <HomeTireModel />
        </Route>

        <Route
          exact
          needSession
          path="/vehicle"
          requiredPermits={[LIST_VEHICLE]}
        >
          <HomeVehicle />
        </Route>

        <Route
          exact
          needSession
          path="/vehicle/:id/charts"
          requiredPermits={[]}
        >
          <TpmsHistory />
        </Route>

        <Route exact needSession path="/mount" requiredPermits={[LIST_VEHICLE]}>
          <HomeMount />
        </Route>

        <Route
          exact
          needSession
          requiredPermits={[LIST_PROVIDER]}
          path="/provider"
        >
          <HomeProvider />
        </Route>

        <Route
          exact
          needSession
          path="/tire/:id/history"
          requiredPermits={[LIST_TIRE]}
        >
          <TireHistory />
        </Route>

        <Route
          exact
          needSession
          path="/tire/:id/review_history"
          requiredPermits={[LIST_TIRE]}
        >
          <TireReviewHistory />
        </Route>

        <Route exact needSession path="/tire/:id/review/history">
          <TireReviewHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/vehicle/history">
          <TireVehicleHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/revitalization/history">
          <TireRevitalizationHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/repair/history">
          <TireRepairHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/cycle/history">
          <TireCycleHistoryTab />
        </Route>
        <Route
          exact
          needSession
          requiredPermits={[LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE]}
          path="/tire/:id/warehouse/history"
        >
          <TireWarehouseHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/pile/history">
          <TirePileHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/division/history">
          <TireDivisionHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/subsidiary/history">
          <TireSubsidiaryHistoryTab />
        </Route>
        <Route exact needSession path="/tire/:id/alert/history">
          <TireAlertHistoryTab />
        </Route>

        <Route exact needSession path="/tire/model/:id/variation">
          <TireModelVariationTab />
        </Route>

        <Route
          exact
          needSession
          path="/tire/:id/damage"
          requiredPermits={[SHOW_TIRE_DAMAGE]}
        >
          <TireDamageTab />
        </Route>

        <Route
          exact
          needSession
          path="/tire/:id/wear"
          requiredPermits={[SHOW_TIRE_WEAR]}
        >
          <TireWearTab />
        </Route>

        <Route exact needSession path="/tire/:id/alert">
          <TireAlertTab />
        </Route>

        <Route exact needSession path="/tire" requiredPermits={[LIST_TIRE]}>
          <HomeTire />
        </Route>

        <Route exact needSession path="/tire/:id" requiredPermits={[SHOW_TIRE]}>
          <TireInfoTab />
        </Route>

        <Route exact needSession path="/tire/model/:id">
          <TireModelInfoTab />
        </Route>

        <Route
          exact
          needSession
          path="/retirement/cause"
          requiredPermits={[LIST_RETIREMENT_CAUSE]}
        >
          <HomeRetirementCause />
        </Route>

        <Route
          exact
          needSession
          path="/gps/:id/geofence"
          requiredPermits={[LIST_GPS_GEOFENCE]}
        >
          <GpsGeofenceTab />
        </Route>

        <Route exact needSession path="/gps" requiredPermits={[LIST_GPS]}>
          <HomeGps />
        </Route>

        <Route
          exact
          needSession
          path="/revitalized/tire/model"
          requiredPermits={[LIST_REVITALIZED_TIRE_MODEL]}
        >
          <HomeRevitalizedTireModel />
        </Route>

        <Route exact needSession path="/rfid" requiredPermits={[LIST_RFID]}>
          <HomeRfid />
        </Route>

        <Route exact needSession path="/tpms" requiredPermits={[LIST_TPGPS]}>
          <HomeTpms />
        </Route>

        <Route exact needSession path="/alert">
          <HomeAlert />
        </Route>

        <Route exact needSession path="/notifications">
          <HomeNotification />
        </Route>
        <Route exact needSession path="/alert/tires">
          <TireAlerts />
        </Route>

        <Route exact needSession path="/alert/vehicles">
          <VehicleAlerts />
        </Route>

        <Route exact needSession path="/alert/mounts">
          <MountAlerts />
        </Route>

        <Route
          exact
          needSession
          path="/mount/:id/alerts"
          requiredPermits={[LIST_LINK_ALERT_WITH_VEHICLE]}
        >
          <AlertsByMount />
        </Route>

        <Route
          exact
          needSession
          path="/mount/:id/tpms/alerts"
          requiredPermits={[LIST_LINK_ALERT_WITH_VEHICLE]}
        >
          <TpmsAlerts />
        </Route>

        <Route
          exact
          needSession
          path="/tire/:id/alerts"
          requiredPermits={[LIST_LINK_ALERT_WITH_TIRE]}
        >
          <AlertsByTire />
        </Route>

        <Route exact needSession path="/reports/tire/stats">
          <TireStats current={true} key={1} />
        </Route>

        <Route exact needSession path="/reports/tire/stats/historic">
          <TireStats current={false} key={0} />
        </Route>

        <Route exact needSession path="/reports/tire/pile/stats">
          <PileStats />
        </Route>

        <Route exact needSession path="/reports/tire/best/performance">
          <BestPerformance />
        </Route>

        <Route exact needSession path="/reports/completed/cycles">
          <CompletedCycles />
        </Route>

        <Route exact needSession path="/reports/scheduled/withdrawal">
          <ScheduledWithdrawalTires />
        </Route>

        <Route exact needSession path="/reports/km/projection">
          <KmProjectionReport />
        </Route>

        <Route exact needSession path="/reports/tire/pile/performance">
          <PilePerformance />
        </Route>

        <Route exact needSession path="/reports/summary">
          <SummaryReport key="historical_summary" />
        </Route>

        <Route exact needSession path="/reports/current/summary">
          <SummaryReport current key="current_summary" />
        </Route>

        <Route exact needSession path="/reports/dynamic/cost">
          <DynamicCost />
        </Route>

        <Route exact needSession path="/reports/pile/summary">
          <PileSummary />
        </Route>

        <Route exact needSession path="/reports/pile">
          <Pile />
        </Route>

        <Route exact needSession path="/reports/km/pile">
          <KmPile />
        </Route>

        <Route exact needSession path="/reports/renewability/index">
          <RenewabilityIndex />
        </Route>

        <Route exact needSession path="/reports/review/performance">
          <ReviewPerformance />
        </Route>

        <Route exact needSession path="/reports/review/performance/company">
          <ReviewPerformanceCompany />
        </Route>

        <Route exact needSession path="/reports/potential/savings">
          <PotentialSavings />
        </Route>

        <Route exact needSession path="/report/damage/wear">
          <DamagesWears />
        </Route>

        <Route exact needSession path="/report/warehouse">
          <Warehouse />
        </Route>

        {/*
        <Route exact needSession path="/report/tires/mount">
          <MountReport />
        </Route>
        */}

        <Route exact needSession path="/report/user/review">
          <UserReviewReport />
        </Route>

        <Route exact needSession path="/report/purchase">
          <Purchase />
        </Route>
        <Route exact needSession path="/report/last/movement">
          <LastMovement />
        </Route>
        <Route exact needSession path="/report/vehicle/semaphore">
          <SemaphoreReport />
        </Route>
        <Route exact needSession path="/report/vehicle/fleet_semaphore">
          <FleetSemaphoreReport />
        </Route>

        <Route exact needSession path="/report/tire/revitalized">
          <TireRevitalized />
        </Route>
        <Route exact needSession path="/report/warehouse/summary">
          <SummaryWarehouse />
        </Route>
        <Route exact needSession path="/report/mount/summary">
          <SummaryMount />
        </Route>

        <Route exact needSession path="/report/tire/repair">
          <TireRepair />
        </Route>

        <Route
          exact
          needSession
          path="/disposal"
          requiredPermits={[SHOW_TIRE_PILE_REPORT]}
        >
          <HomeDisposal />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

      {to && <Redirect to={to} />}
    </BrowserRouter>
  );
}

Page.propTypes = {
  to: PropTypes.string,
};

export default Page;
