import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function FieldsList({ ...rest }) {
  const { field } = rest;

  let newField = {};
  field.fields.forEach((field) => {
    newField[field.name] = "";
  });

  return <Page newField={newField} {...rest} />;
}

FieldsList.propTypes = {
  field: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  menuProps: PropTypes.object,
  defaultText: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.object,
};

export default FieldsList;
