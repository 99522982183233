import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetTireRevitalizedReportFilters } from "store/actions/reports/tire_revitalized/setTireRevitalizedFilters";
import { actionSetSelectedTireRevitalizedReportFilters } from "store/actions/reports/tire_revitalized/setSelectedTireRevitalizedReportFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { alphabeticalSort } from "utils/sort";
import { arrayJoin } from "utils/parse";

import Page from "./page";

export function FilterTireRevitalizedForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setTireRevitalizedFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      companies: [],
      subsidiaries: [],
      date_from: "",
      date_to: "",
      providers: [],
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );
        setCorporates(data.sort(alphabeticalSort("name")));

        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        companies: filter.companies ? filter.companies.split(",") : [],
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        providers: filter.providers ? filter.providers.split(",") : [],
      });
    } else {
      resetFormik();
    }
  }, [open, loaded]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.companies.length > 0) {
        params.companies = values.companies.join();
        const company = companies.filter((company) =>
          values.companies.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: arrayJoin(
            company.map((company) => company.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiaries.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: arrayJoin(
            subsidiary.map((subsidiary) => subsidiary.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.subsidiaries = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      if (values.providers.length > 0) {
        params.providers = values.providers.join();

        const foundProviders = providers.filter((provider) =>
          values.providers.includes(provider.provider_id.toString())
        );

        setSelectedFilters({
          providers: arrayJoin(
            foundProviders.map((provider) => provider.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      }
      await setTireRevitalizedFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: arrayJoin(
        companies.map((company) => company.company.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
      subsidiaries: arrayJoin(
        subsidiaries.map((subsidiary) => subsidiary.subsidiary.name),
        ", ",
        ` ${t("_general.text.and")} `
      ),
    };
    setTireRevitalizedFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      setCompanies={setCompanies}
      subsidiaries={subsidiaries}
      setSubsidiaries={setSubsidiaries}
      providers={providers}
      setProviders={setProviders}
      t={t}
    />
  );
}

FilterTireRevitalizedForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.TireRevitalized.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setTireRevitalizedFilters: actionSetTireRevitalizedReportFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedTireRevitalizedReportFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTireRevitalizedForm);
