import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireRfid } from "store/actions/tire/rfid/getTireRfid";
import { actionClearTireRfid } from "store/actions/tire/rfid/clearTireRfid";
import { assignRfidToTire } from "store/actions/tire/rfid/assignRfidToTire";

import {
  SHOW_TIRE,
  LINK_TIRE_WITH_RFID,
  LIST_RFID,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/rfid/LinkTireRfidForm";

export function LinkTireRfidForm({ className, tireId, ...rest }) {
  const t = useLang(NAMESPACE);
  const {
    permits,
    rfid,
    setNotification,
    getRfidTire,
    clearRfidTire,
    assignRfidToTire,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      rfid_id: "",
    },
  });
  const [open, setOpen] = useState(false);
  const showTirePermit = permits.includes(SHOW_TIRE);

  useEffect(() => {
    async function fetchData() {
      try {
        await getRfidTire(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showTirePermit) {
      fetchData();
    }

    return () => {
      clearRfidTire();
    };
  }, [tireId]);

  function handleLink() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    resetFormik();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    const rfidId = values.rfid_id;

    try {
      await assignRfidToTire({
        rfidId,
        tireId,
      });

      handleClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      className={className}
      rfid={rfid}
      initialValues={initialValues}
      initialErrors={initialErrors}
      showTirePermit={showTirePermit}
      linkTireWithRfidPermit={permits.includes(LINK_TIRE_WITH_RFID)}
      listRfidPermit={permits.includes(LIST_RFID)}
      handleLink={handleLink}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkTireRfidForm.propTypes = {
  className: PropTypes.string,
  tireId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  rfid: state.Tire.Rfid.rfid,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRfidTire: actionGetTireRfid(dispatch),
  clearRfidTire: actionClearTireRfid(dispatch),
  assignRfidToTire: assignRfidToTire(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkTireRfidForm);
