export default {
  update: {
    title: "Actualizar Empresa",
  },
  title: "Nueva Empresa",
  form: {
    placeholder: "Suelta la imagen ó da click para seleccionar una",
    permits: {
      delete: "Sin permisos para eliminar empresas",
      update: "Sin permisos para actualizar empresas",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar esta empresa?",
    company_created: "Listo, se creo una nueva empresa con éxito",
    company_updated: "Listo, se actualizo la empresa con éxito.",
  },
};
