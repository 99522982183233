import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "13px",
    fontFamily: "Poppins",
    padding: "6px",
    textAlign: "center",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip disableFocusListener arrow classes={classes} {...props} />;
}

export default BootstrapTooltip;
