import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Formik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LinkedItemPanel from "components/molecules/general/LinkedItemPanel";

import Form from "./Form";

function Page({
  t,
  open,
  className,
  subsidiary,
  initialValues,
  initialErrors,
  showTirePermit,
  showSubsidiaryPermit,
  linkTireWithSubsidiaryPermit,
  deleteLinkTireWithSubsidiaryPermit,
  handleView,
  handleUnlink,
  handleLink,
  handleClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <LinkedItemPanel
        label={t("_labels.subsidiary_name")}
        title={t("subsidiary")}
        className={classnames(className)}
        text={
          !showTirePermit
            ? t("permits.show.tire_subsidiary")
            : !subsidiary
            ? t("_labels.unassigned_subsidiary")
            : subsidiary.subsidiary.name
        }
        actionButtonName={
          !!subsidiary ? t("_buttons.unlink") : t("_buttons.link")
        }
        actionButtonTooltip={
          !!subsidiary && !deleteLinkTireWithSubsidiaryPermit
            ? t("permits.unlink.tire_subsidiary")
            : !subsidiary
            ? !linkTireWithSubsidiaryPermit
              ? t("_permits.link.tire_with_subsidiary")
              : ""
            : ""
        }
        actionButtonDisabled={
          !showTirePermit ||
          (!!subsidiary && !deleteLinkTireWithSubsidiaryPermit) ||
          (!subsidiary && !linkTireWithSubsidiaryPermit)
        }
        viewButtonDisabled={
          !showTirePermit || !subsidiary || !showSubsidiaryPermit
        }
        viewButtonTooltip={
          !showSubsidiaryPermit ? t("_permits.show.subsidiary") : ""
        }
        onView={handleView}
        onAction={!!subsidiary ? handleUnlink : handleLink}
      />

      <DialogMediaQuery
        size={"sm"}
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>{t("_general.link_subsidiary")}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => <Form {...p} onClose={handleClose} />}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  subsidiary: PropTypes.object,
  open: PropTypes.bool.isRequired,
  showTirePermit: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  showSubsidiaryPermit: PropTypes.bool.isRequired,
  linkTireWithSubsidiaryPermit: PropTypes.bool.isRequired,
  deleteLinkTireWithSubsidiaryPermit: PropTypes.bool.isRequired,
  handleUnlink: PropTypes.func,
  handleView: PropTypes.func.isRequired,
  handleLink: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
