export default {
  title: "Nueva medida",
  update_title: "Actualizar medida",
  form: {
    permits: {
      update: "Sin permisos para actualizar medidas de neumáticos",
      delete: "Sin permisos para eliminar medidas de neumáticos",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar la medida de neumático?",
    tire_size_created: "Listo, se creo una nueva medida de neumático con éxito",
    tire_size_updated: "Listo, se actualizó la medida de neumático con éxito",
  },
};
