export default {
  text: {
    date_info: "{user} on {date} at {hour}",
  },
  disabled_size: "Disabled size",
  messages: {
    approve: "Are you sure to approve the size?",
    disapprove: "Are you sure to disapprove the size?",
    enable: "Are you sure to enable the tire size?",
    disable: "Are you sure to disable the tire size?",
  },
  permits: {
    change_approved: "No permissions to {action} tires sizes",
    change_status: "No permissions to {action} tires sizes",
  },
};
