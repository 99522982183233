import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";
import { GET_SUMMARY_DETAILS } from "store/actions";

export const actionGetSummaryDetails =
  (dispatch) =>
  async (path, { movement = "BOTH", ...params }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path,
        params: {
          movement,
          ...params,
        },
      });

      dispatch({
        type: GET_SUMMARY_DETAILS,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
