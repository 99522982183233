import {
  ASSIGN_VEHICLE_TO_DRIVER,
  DELETE_LINK_VEHICLE_WITH_DRIVER,
  GET_VEHICLE_DRIVER,
  CLEAR_VEHICLE_DRIVER,
} from "store/actions";

const initialState = {
  driver: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ASSIGN_VEHICLE_TO_DRIVER:
    case GET_VEHICLE_DRIVER:
      return {
        ...state,
        driver: action.payload,
      };

    case DELETE_LINK_VEHICLE_WITH_DRIVER:
    case CLEAR_VEHICLE_DRIVER:
      return {
        ...state,
        driver: initialState.driver,
      };

    default:
      return state;
  }
}
