import { GET_ALL_USER_NOTIFICATIONS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetAllNotifications =
  (dispatch) =>
  async (
    { page, per_page: perPage, date_from: dateFrom, date_to: dateTo },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `notification/list`,
        params: {
          page,
          per_page: perPage,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_ALL_USER_NOTIFICATIONS,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
