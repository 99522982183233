import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import { useHistory } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionClearSubsidiaries } from "store/actions/subsidiary/clearSubsidiaries";
import { actionSetSubsidiaryFilters } from "store/actions/subsidiary/setSubsidiaryFilters";
import { actionClearSubsidiaryFilters } from "store/actions/subsidiary/clearSubsidiaryFilters";
import { actionDeleteSubsidiary } from "store/actions/subsidiary/deleteSubsidiary";
import { actionSetStatusSubsidiary } from "store/actions/subsidiary/setStatusSubsidiary";
import { actionGetSubsidiary } from "store/actions/subsidiary/getSubsidiary";
import { actionAddSubsidiary } from "store/actions/subsidiary/addSubsidiary";
import { actionUpdateSubsidiary } from "store/actions/subsidiary/updateSubsidiary";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import json from "resources/json/countries";

import {
  CREATE_SUBSIDIARY,
  UPDATE_SUBSIDIARY,
  DELETE_SUBSIDIARY,
  SHOW_SUBSIDIARY,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeSubsidiary({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const {
    filter,
    permits,
    subsidiary,
    subsidiaries,
    account,
    workArea,
    deleteSubsidiary,
    setStatusSubsidiary,
    getSubsidiary,
    addSubsidiary,
    updateSubsidiary,
    getSubsidiaries,
    getCompanies,
    setNotification,
    clearSubsidiaries,
    setSubsidiaryFilters,
    clearSubsidiaryFilters,
  } = rest;
  const [companies, setCompanies] = useState([]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const subsidiaryActive = account.subsidiaries.find(
    (el) => el.user_assigned_subsidiary_id == workArea.id
  );

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (account && account.is_root) {
          data = await getCompanies({ scope: "company_id,name,status" }, false);
        } else if (Array.isArray(account.companies)) {
          data = account.companies.map((company) => ({
            company_id: company.company.company_id,
            name: company.company.name,
            status: company.company.status,
          }));
        }
        setCompanies(data.sort(alphabeticalSort("name")));
        setCountries(Object.keys(json));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  function handleChangeCountry(form, value, stateValue = "") {
    if (value && json[value]) {
      setStates(Object.keys(json[value])?.sort((a, b) => a.localeCompare(b)));
    } else {
      setStates([]);
    }
    form.setFieldValue("state", stateValue);
  }

  function handleChangeState(form, value, countryValue, provinceValue = "") {
    if (value) {
      setProvinces(
        json?.[countryValue]?.[value]?.sort((a, b) => a.localeCompare(b)) ?? []
      );
    } else {
      setProvinces([]);
    }
    if (provinceValue) {
      form.setFieldValue("province", provinceValue);
    } else {
      form.setFieldValue("province", "");
    }
  }

  function handleViewPolicies(subsidiaryId) {
    history.push(`/subsidiary/${subsidiaryId}/policy`);
  }

  return (
    <Page
      subsidiaryActive={subsidiaryActive}
      subsidiaries={subsidiaries}
      createSubsidiaryPermit={permits.includes(CREATE_SUBSIDIARY)}
      updateSubsidiaryPermit={permits.includes(UPDATE_SUBSIDIARY)}
      deleteSubsidiaryPermit={permits.includes(DELETE_SUBSIDIARY)}
      subsidiary={subsidiary}
      filter={filter}
      companies={companies}
      handleChangeCountry={handleChangeCountry}
      handleChangeState={handleChangeState}
      countries={countries}
      states={states}
      provinces={provinces}
      t={t}
      getSubsidiary={getSubsidiary}
      addSubsidiary={addSubsidiary}
      updateSubsidiary={updateSubsidiary}
      clearSubsidiaryFilters={clearSubsidiaryFilters}
      setSubsidiaryFilters={setSubsidiaryFilters}
      deleteSubsidiary={deleteSubsidiary}
      setStatusSubsidiary={setStatusSubsidiary}
      getSubsidiaries={getSubsidiaries}
      clearSubsidiaries={clearSubsidiaries}
      handleViewPolicies={handleViewPolicies}
      showSubsidiaryPermit={permits.includes(SHOW_SUBSIDIARY)}
    />
  );
}

HomeSubsidiary.propTypes = {};

const mapStateToProps = (state) => ({
  subsidiaries: state.Subsidiary.Subsidiary.subsidiaries,
  subsidiary: state.Subsidiary.Subsidiary.subsidiary,
  filter: state.Subsidiary.Subsidiary.filter_subsidiary,
  permits: state.Account.permits,
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  clearSubsidiaries: actionClearSubsidiaries(dispatch),
  setNotification: actionSetNotification(dispatch),
  setSubsidiaryFilters: actionSetSubsidiaryFilters(dispatch),
  clearSubsidiaryFilters: actionClearSubsidiaryFilters(dispatch),
  deleteSubsidiary: actionDeleteSubsidiary(dispatch),
  setStatusSubsidiary: actionSetStatusSubsidiary(dispatch),
  getSubsidiary: actionGetSubsidiary(dispatch),
  addSubsidiary: actionAddSubsidiary(dispatch),
  updateSubsidiary: actionUpdateSubsidiary(dispatch),
  getCompanies: actionGetCompanies(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSubsidiary);
