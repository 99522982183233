import {
  GET_VEHICLE_DIVISION,
  CLEAR_VEHICLE_DIVISION,
  ASSIGN_VEHICLE_TO_DIVISION,
  DELETE_LINK_VEHICLE_WITH_DIVISION,
} from "store/actions";

const initialState = {
  division: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_DIVISION:
    case ASSIGN_VEHICLE_TO_DIVISION:
      return {
        ...state,
        division: action.payload,
      };

    case CLEAR_VEHICLE_DIVISION:
    case DELETE_LINK_VEHICLE_WITH_DIVISION:
      return {
        ...state,
        division: initialState.division,
      };

    default:
      return state;
  }
}
