import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import moment from "moment-timezone";

import Page from "./page";

export const NAMESPACE =
  "components/molecules/warehouse/tire/WarehouseTireDialog";

function WarehouseTireDialog({ warehouseTire, ...rest }) {
  const t = useLang(NAMESPACE);

  return (
    <Page
      {...rest}
      t={t}
      warehouseTire={{
        ...warehouseTire,
        created_by: t("_labels.actioned_by", {
          person: `${warehouseTire.created_by.name} ${
            warehouseTire.created_by.last_name_1
          } ${warehouseTire.created_by.last_name_2 || ""}`,
          date: moment(warehouseTire.created_at).format("LL"),
          time: moment(warehouseTire.created_at).format("h:mm a"),
        }),
        updated_by: warehouseTire.updated_by
          ? t("_labels.actioned_by", {
              person: `${warehouseTire.updated_by.name} ${
                warehouseTire.updated_by.last_name_1
              } ${warehouseTire.updated_by.last_name_2 || ""}`,
              date: moment(warehouseTire.updated_at).format("LL"),
              time: moment(warehouseTire.updated_at).format("h:mm a"),
            })
          : null,
      }}
    />
  );
}

WarehouseTireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isHistory: PropTypes.bool.isRequired,
  showTooltip: PropTypes.string.isRequired,
  unlinkTooltip: PropTypes.string.isRequired,
  viewButtonName: PropTypes.string.isRequired,
  warehouseTire: PropTypes.shape({
    updated_at: PropTypes.string,
    warehouse: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    created_at: PropTypes.string.isRequired,
    tire: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }).isRequired,
    updated_by: PropTypes.shape({
      last_name_2: PropTypes.string,
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
    }),
    created_by: PropTypes.shape({
      last_name_2: PropTypes.string,
      name: PropTypes.string.isRequired,
      last_name_1: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  handleView: PropTypes.func.isRequired,
  handleUnlink: PropTypes.func.isRequired,
};

export default WarehouseTireDialog;
