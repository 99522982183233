export default {
  disabled_vehicle: "Vehículo deshabilitado",
  type_of_route: "Tipo de ruta",
  economic_number: "Numero económico",
  enrollment: "Matricula del vehículo",
  has_odometer: {
    yes: "Si",
    no: "No",
  },
  rove: "Recorridos",
  buttons: {
    inspection: "Inspección",
  },
  permits: {
    change_status: "Sin permisos para {action} vehículo",
    list_link: {
      tire_with_vehicle:
        "Sin permisos para listar neumáticos montados en vehículos",
    },
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar el vehículo?",
    enable: "¿Estas seguro de habilitar el vehículo?",
  },
};
