import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionSetKmProjectionFilters } from "store/actions/reports/km_projection/setKmProjectionFilters";
import { actionClearKmProjectionFilters } from "store/actions/reports/km_projection/clearKmProjectionFilters";
import { actionSetSelectedKmProjectionFilters } from "store/actions/reports/km_projection/setSelectedKmProjectionFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import { arrayJoin } from "utils/parse";

import Page from "./page";
import moment from "moment-timezone";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

export function FilterKmProjectionForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    setNotification,
    setKmProjectionFilters,
    getCorporates,
    getBrands,
    getSizes,
    setSelectedFilters,
    account,
    workArea,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      companies: [],
      subsidiaries: [],
      date_from: "",
      date_to: "",
      movement: "BOTH",
      search: "",
      tire_model_variation: "",
      tire_application: [],
      tire_condition: [],
      price: "",
      helmet_value: "",
      brands: [],
      models: [],
      sizes: [],
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [sizes, setSizes] = useState([]);

  const [conditions, setConditions] = useState([
    {
      value: "ORIGINAL_NEW",
      label: t("_labels.tire_condition.options.original_new"),
    },
    {
      value: "ORIGINAL_USED",
      label: t("_labels.tire_condition.options.original_used"),
    },
    {
      value: "RETREAD_NEW",
      label: t("_labels.tire_condition.options.retread_new"),
    },
    {
      value: "RETREAD_USED",
      label: t("_labels.tire_condition.options.retread_used"),
    },
  ]);
  const [applications, setApplications] = useState([
    {
      value: "ALL_POSITION",
      label: t("_labels.tire_application.options.all_position"),
    },
    {
      value: "DIRECTIONAL",
      label: t("_labels.tire_application.options.directional"),
    },
    {
      value: "TRACTION",
      label: t("_labels.tire_application.options.traction"),
    },
    { value: "TRAILER", label: t("_labels.tire_application.options.trailer") },
  ]);

  const movements = [
    { value: "BOTH", label: t("_labels.dynamic_cost_report.options.both") },
    {
      value: "WAREHOUSE",
      label: t("_labels.dynamic_cost_report.options.warehouse"),
    },
    { value: "MOUNT", label: t("_labels.dynamic_cost_report.options.mount") },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
        setConditions([
          {
            value: "ORIGINAL_NEW",
            label: t("_labels.tire_condition.options.original_new"),
          },
          {
            value: "ORIGINAL_USED",
            label: t("_labels.tire_condition.options.original_used"),
          },
          {
            value: "RETREAD_NEW",
            label: t("_labels.tire_condition.options.retread_new"),
          },
          {
            value: "RETREAD_USED",
            label: t("_labels.tire_condition.options.retread_used"),
          },
        ]);
        setApplications([
          {
            value: "ALL_POSITION",
            label: t("_labels.tire_application.options.all_position"),
          },
          {
            value: "DIRECTIONAL",
            label: t("_labels.tire_application.options.directional"),
          },
          {
            value: "TRACTION",
            label: t("_labels.tire_application.options.traction"),
          },
          {
            value: "TRAILER",
            label: t("_labels.tire_application.options.trailer"),
          },
        ]);
        data = await getBrands(
          {
            brandType: "TIRE,RETREAD",
            scope: "brand_id,name,status,approved,brand_type",
          },
          false
        );
        setBrands(
          data
            .filter((b) => b.brand_type == "TIRE")
            .sort(alphabeticalSort("name"))
        );
        data = await getSizes(
          { scope: "tire_size_id,size,status,approved" },
          false
        );
        setSizes(data.sort(alphabeticalSort("size")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        companies: filter.companies ? filter.companies.split(",") : [],
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        tire_condition: filter.tire_condition
          ? filter.tire_condition.split(",")
          : [],
        tire_application: filter.tire_application
          ? filter.tire_application.split(",")
          : [],
        price: filter.price || "",
        helmet_value: filter.helmet_value || "",
        brands: filter.brands
          ? brands.filter((e) =>
              filter.brands.split(",").includes(e.brand_id.toString())
            )
          : [],
        models: filter.models
          ? models.filter((e) =>
              filter.models.split(",").includes(e.tire_model_id.toString())
            )
          : [],
        sizes: filter.sizes
          ? sizes.filter((e) =>
              filter.sizes.split(",").includes(e.tire_size_id.toString())
            )
          : [],
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.companies.length > 0) {
        params.companies = values.companies.join();
        const selectedCompanies = companies.filter((company) =>
          values.companies.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: arrayJoin(
            selectedCompanies.map((company) => company.name),
            ",",
            t("_general.text.and")
          ),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
        const selectedSubsidiaries = subsidiaries.filter((subsidiary) =>
          values.subsidiaries.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: arrayJoin(
            selectedSubsidiaries.map((subsidiary) => subsidiary.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      } else {
        params.subsidiaries = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      if (values.movement) {
        params.movement = values.movement;
        const movement = movements.find(
          (movement) => movement.value == values.movement
        );
        setSelectedFilters({ movement: movement.label });
      }

      if (values.tire_condition.length > 0) {
        params.tire_condition = values.tire_condition.join();
        const condition = conditions.filter((condition) =>
          values.tire_condition.includes(condition.value.toString())
        );
        setSelectedFilters({
          condition: condition.map((condition) => condition.label).join(),
        });
      } else {
        params.tire_condition = null;
        setSelectedFilters({
          condition: "",
        });
      }

      if (values.tire_application.length > 0) {
        params.tire_application = values.tire_application.join();
        const application = applications.filter((application) =>
          values.tire_application.includes(application.value.toString())
        );
        setSelectedFilters({
          application: application
            .map((application) => application.label)
            .join(),
        });
      } else {
        params.tire_application = null;
        setSelectedFilters({
          application: "",
        });
      }

      if (values.price) {
        params.price = values.price;
        setSelectedFilters({ price: values.price });
      }

      if (values.helmet_value) {
        params.helmet_value = values.helmet_value;
        setSelectedFilters({ helmet_value: values.helmet_value });
      }

      if (values.brands.length > 0) {
        params.brands = values.brands.map((b) => b.brand_id).join();
        const brand = brands.filter((brand) =>
          values.brands.find((b) => b.brand_id == brand.brand_id.toString())
        );

        setSelectedFilters({
          brands: brand.map((brand) => brand.name).join(),
        });
      } else {
        params.brands = null;
        setSelectedFilters({
          brands: "",
        });
      }

      if (values.models.length > 0) {
        params.models = values.models.map((b) => b.tire_model_id).join();
        const model = models.filter((model) =>
          values.models.find(
            (m) => m.tire_model_id == model.tire_model_id.toString()
          )
        );

        setSelectedFilters({
          models: model.map((model) => model.name).join(),
        });
      } else {
        params.models = null;
        setSelectedFilters({
          models: "",
        });
      }

      if (values.sizes.length > 0) {
        params.sizes = values.sizes.map((b) => b.tire_size_id).join();
        const size = sizes.filter((s) =>
          values.sizes.find(
            (size) => size.tire_size_id == s.tire_size_id.toString()
          )
        );

        setSelectedFilters({
          sizes: size.map((s) => s.size).join(),
        });
      } else {
        params.sizes = null;
        setSelectedFilters({
          sizes: "",
        });
      }

      if (values.search) {
        params.search = values.search;
      }

      await setKmProjectionFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    setKmProjectionFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      conditions={conditions}
      applications={applications}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      brands={brands}
      models={models}
      sizes={sizes}
      setCompanies={setCompanies}
      setModels={setModels}
      setSubsidiaries={setSubsidiaries}
      movements={movements}
      t={t}
    />
  );
}

FilterKmProjectionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.KmProjection.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setKmProjectionFilters: actionSetKmProjectionFilters(dispatch),
  clearKmProjectionFilters: actionClearKmProjectionFilters(dispatch),
  setSelectedFilters: actionSetSelectedKmProjectionFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  getBrands: actionGetBrands(dispatch),
  getSizes: actionGetTireSizes(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterKmProjectionForm);
