import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import Page from "./page";

function InfoLinkDialog({
  to,
  createdBy,
  createdAt,
  updatedBy,
  updatedAt,
  ...rest
}) {
  const t = useLang();
  const history = useHistory();

  function handleView() {
    history.push(to);
  }

  return (
    <Page
      {...rest}
      t={t}
      createdBy={t("_labels.actioned_by", {
        person: `${createdBy.name} ${createdBy.last_name_1} ${
          createdBy.last_name_2 || ""
        }`,
        date: moment(createdAt).format("LL"),
        time: moment(createdAt).format("h:mm a"),
      })}
      updatedBy={
        updatedBy
          ? t("_labels.actioned_by", {
              person: `${updatedBy.name} ${updatedBy.last_name_1} ${
                updatedBy.last_name_2 || ""
              }`,
              date: moment(updatedAt).format("LL"),
              time: moment(updatedAt).format("h:mm a"),
            })
          : null
      }
      handleView={handleView}
    />
  );
}

InfoLinkDialog.propTypes = {
  isHistory: PropTypes.bool,
  updatedAt: PropTypes.string,
  to: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  label1: PropTypes.string.isRequired,
  label2: PropTypes.string.isRequired,
  viewName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  unlinkName: PropTypes.string.isRequired,
  viewTooltip: PropTypes.string.isRequired,
  unlinkTooltip: PropTypes.string.isRequired,
  updatedBy: PropTypes.shape({
    last_name_2: PropTypes.string,
    name: PropTypes.string.isRequired,
    last_name_1: PropTypes.string.isRequired,
  }),
  createdBy: PropTypes.shape({
    last_name_2: PropTypes.string,
    name: PropTypes.string.isRequired,
    last_name_1: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};

export default InfoLinkDialog;
