import {
  REGISTER_TIRE,
  GET_TIRES,
  CLEAR_TIRES,
  SET_TIRE_FILTERS,
  CLEAR_TIRE_FILTERS,
  GET_TIRE,
  CLEAR_TIRE,
  UPDATE_TIRE,
  SET_TIRE_STATUS,
  DELETE_TIRE,
  SET_TIRE_SELECTED_FILTERS,
} from "store/actions";

const initialState = {
  tire: {
    code: "",
    subsidiary: {
      name: "",
    },
    status: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    rfid_tire: [],
    is_refection: 0,
    cap_and_casing: 0,
    dot: "",
    cycle: {
      tire_condition_id: "ORIGINAL_USED",
      price: 0,
      expected_durability: 10,
      invoice_date: "",
      invoice_folio: "",
      tire_travel: 0,
      number_cycle: 0,
      provider: {
        name: "",
      },
      variation: {
        number_layers: 0,
        helmet_value_original: 0,
        helmet_value_revitalized: 0,
        tire_size: {
          size: "",
        },
        tire_model: {
          name: "",
          brand: {
            name: "",
          },
        },
      },
      movement_tire: {
        movement: "",
        warehouse_tire: [],
        vehicle_tire: [],
        tire_review: [],
      },
      revitalized_tire_model_id: "",
    },
  },
  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_tire: {},
  selected_filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE:
    case REGISTER_TIRE:
    case UPDATE_TIRE:
      return {
        ...state,
        tire: action.payload,
      };

    case GET_TIRES:
      return {
        ...state,
        tires: action.payload,
      };

    case CLEAR_TIRE:
      return {
        ...state,
        tire: { ...initialState.tire },
      };

    case CLEAR_TIRES:
      return {
        ...state,
        tires: { ...initialState.tires },
      };

    case SET_TIRE_FILTERS:
      const filter = {
        ...state.filter_tire,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_tire: { ...filter },
      };

    case CLEAR_TIRE_FILTERS:
      return {
        ...state,
        filter_tire: { search: state.filter_tire.search },
      };

    case SET_TIRE_SELECTED_FILTERS:
      const selectedFilter = {
        ...state.selected_filter,
        ...action.payload,
      };

      for (let key in selectedFilter) {
        if (
          selectedFilter[key] === undefined ||
          selectedFilter[key] === null ||
          selectedFilter[key] === ""
        ) {
          delete selectedFilter[key];
        }
      }

      return {
        ...state,
        selected_filter: { ...selectedFilter },
      };

    case SET_TIRE_STATUS:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: state.tires.data.map((tire) =>
            tire.tire_id !== action.payload
              ? tire
              : { ...tire, status: !tire.status }
          ),
        },
      };

    case DELETE_TIRE:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: state.tires.data.filter(
            (tire) => tire.tire_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
