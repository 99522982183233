import React from "react";
import { useLang } from "hooks/lang";

import Page from "./page";
import { VIDEOS } from "utils/tutorials";

function HomeTutorials({ ...rest }) {
  const t = useLang();
  const {} = rest;
  return <Page t={t} videos={VIDEOS} />;
}

HomeTutorials.propTypes = {};

export default HomeTutorials;
