import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LabelAndText from "components/molecules/general/LabelAndText";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  alert,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="md" size="sm" open={open} onClose={onClose}>
        <DialogTitle>{t("_titles.update.alert")}</DialogTitle>
        <DialogContent className={classnames(css.box)}>
          {alert.alert && (
            <>
              <div className={classnames(css.flex)}>
                <LabelAndText
                  className={classnames(css.ml8, css.mr8)}
                  label={t("_labels.alert.code")}
                  text={alert.alert.code}
                />
                <LabelAndText
                  className={classnames(css.ml8, css.mr8)}
                  label={t("_labels.priority.label")}
                  text={t(
                    `_labels.priority.options.${alert.alert.priority.toLowerCase()}`
                  )}
                />
                <LabelAndText
                  className={classnames(css.ml8, css.mr8)}
                  label={t("_labels.date.label")}
                  text={`${moment(alert.created_at).format("LL")} ${t(
                    "_labels.time_at"
                  )} ${moment(alert.created_at).format("h:mm a")}`}
                />
              </div>
              <LabelAndText
                fullWidth
                className={classnames(css.ml8, css.mr8)}
                label={t("_labels.alert.details")}
                text={
                  alert.alert.details
                    ? t(`_alerts.details.${alert.alert.details}`)
                    : ""
                }
              />
            </>
          )}
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => <Form onClose={onClose} {...p} />}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  alert: PropTypes.object,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
