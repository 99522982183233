import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTpms } from "store/actions/tpms/getTpms";
import { actionClearTpms } from "store/actions/tpms/clearTpms";
import { actionSetTpmsFilters } from "store/actions/tpms/setTpmsFilters";
import { actionClearTpmsFilters } from "store/actions/tpms/clearTpmsFilters";
import { actionDeleteTpms } from "store/actions/tpms/deleteTpms";
import { actionDeleteLinkTpmsWithTire } from "store/actions/tpms/tire/deleteLinkTpmsWithTire";
import { actionSetTpmsStatus } from "store/actions/tpms/setTpmsStatus";
import { useLang } from "hooks/lang";
import {
  CREATE_TPGPS,
  DELETE_TPGPS,
  LINK_TIRE_WITH_TPGPS,
  UPDATE_TPGPS,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeTpms({ ...rest }) {
  const t = useLang();
  const {
    tpms,
    permits,
    filter,
    setNotification,
    getTpms,
    clearTpms,
    setTpmsFilters,
    clearTpmsFilters,
    deleteTpms,
    setTpmsStatus,
    deleteLinkTpmsWithTire,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [openExcelForm, setOpenExcelForm] = useState(false);
  const [tpmsId, setTpmsId] = useState(null);
  const [openLinkForm, setOpenLinkForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTpms({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTpms();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTpmsFilters();
    };
  }, []);

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setTpmsId(null);
  }

  function handleEdit(tpmsId) {
    setTpmsId(tpmsId);
    setOpenForm(true);
  }

  function handleChangePage(page) {
    setTpmsFilters({ page });
  }

  function handleSearch(search) {
    setTpmsFilters({ search, page: 1 });
  }

  function handleOpenExcelForm() {
    setOpenExcelForm(true);
  }

  function handleCloseExcelForm() {
    setOpenExcelForm(false);
  }

  function handleDelete(tpmsId) {
    setNotification({
      onAction: () => onDelete(tpmsId),
      textAction: t("_buttons.confirm"),
      message: t("_messages.delete.tpms"),
    });
  }

  async function onDelete(tpmsId) {
    try {
      await deleteTpms(tpmsId);
      setNotification({ message: t("_messages.deleted.tpms") });
      clearTpmsFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(tpms) {
    const { id: tpmsId, status } = tpms;
    setNotification({
      message: status
        ? t("_messages.change_status.tpms.disable")
        : t("_messages.change_status.tpms.enable"),
      onAction: () => onChangeStatus(tpmsId, !status),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onChangeStatus(tpmsId, status) {
    try {
      const response = await setTpmsStatus(tpmsId, status);
      setNotification({ message: response.message });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenLinkForm(tpmsId) {
    setTpmsId(tpmsId);
    setOpenLinkForm(true);
  }

  function handleCloseLinkForm() {
    setOpenLinkForm(false);
    setTpmsId(null);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleUnlink(tpmsId) {
    setNotification({
      message: t("_messages.delete.link_tire"),
      textAction: t("_buttons.confirm"),
      onAction: () => deleteLink(tpmsId),
    });
  }

  async function deleteLink(tpmsId) {
    try {
      await deleteLinkTpmsWithTire(tpmsId);
      setNotification({
        message: t("_messages.deleted.link_tire"),
      });
      clearTpmsFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      tpms={tpms}
      openForm={openForm}
      openExcelForm={openExcelForm}
      openLinkForm={openLinkForm}
      openFilter={openFilter}
      search={filter.search}
      tpmsId={tpmsId}
      handleSearch={handleSearch}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleChangeStatus={handleChangeStatus}
      handleChangePage={handleChangePage}
      handleOpenExcelForm={handleOpenExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      handleOpenLinkForm={handleOpenLinkForm}
      handleCloseLinkForm={handleCloseLinkForm}
      handleUnlink={handleUnlink}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      createTpmsPermit={permits.includes(CREATE_TPGPS)}
      updateTpmsPermit={permits.includes(UPDATE_TPGPS)}
      deleteTpmsPermit={permits.includes(DELETE_TPGPS)}
      linkTpmsPermit={permits.includes(LINK_TIRE_WITH_TPGPS)}
    />
  );
}

HomeTpms.propTypes = {};

const mapStateToProps = (state) => ({
  tpms: state.Tpms.tpms,
  filter: state.Tpms.filter,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTpms: actionGetTpms(dispatch),
  clearTpms: actionClearTpms(dispatch),
  setTpmsFilters: actionSetTpmsFilters(dispatch),
  clearTpmsFilters: actionClearTpmsFilters(dispatch),
  deleteTpms: actionDeleteTpms(dispatch),
  setTpmsStatus: actionSetTpmsStatus(dispatch),
  deleteLinkTpmsWithTire: actionDeleteLinkTpmsWithTire(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTpms);
