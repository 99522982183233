import { http } from "store/actions/http";
import { GET_TIRE_CYCLE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireCycle = (dispatch) => async (tireId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `tire/${tireId}/cycle`,
    });

    dispatch({
      type: GET_TIRE_CYCLE,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
