import {
  GET_DIVISION_TIRES_HISTORY,
  CLEAR_DIVISION_TIRES_HISTORY,
  SET_DIVISION_TIRE_HISTORY_FILTERS,
  CLEAR_DIVISION_TIRE_HISTORY_FILTERS,
} from "store/actions";

const initialState = {
  division_tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DIVISION_TIRES_HISTORY:
      return {
        ...state,
        division_tires: action.payload,
      };

    case CLEAR_DIVISION_TIRES_HISTORY:
      return {
        ...state,
        division_tires: initialState.division_tires,
      };

    case SET_DIVISION_TIRE_HISTORY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_DIVISION_TIRE_HISTORY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    default:
      return state;
  }
}
