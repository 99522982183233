import { SET_STATUS_SUBSIDIARY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetStatusSubsidiary =
  (dispatch) => async (subsidiaryId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `subsidiary/${subsidiaryId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_STATUS_SUBSIDIARY,
        payload: subsidiaryId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
