import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionLogout } from "store/actions/account/logout";
import { actionGetFavorites } from "store/actions/favorites/getFavorites";
import { useMenu } from "hooks/menu";
import { getSubsidiaryViaWorkArea } from "utils/workArea";
import { actionGetSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/getSubsidiaryPolicy";
import { actionClearSubsidiaryPolicy } from "store/actions/subsidiary/policy/policy/clearSubsidiaryPolicy";
import { actionGetNotifications } from "store/actions/notification/getNotifications";

import Page from "./page";

export const NAMESPACE = "components/organisms/general/NavigationDrawer";

export function NavigationDrawer({ open, onClose, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [openVehiclesSubmenu, setOpenVehiclesSubmenu] = useState(false);
  const [openAdminSubmenu, setOpenAdminSubmenu] = useState(false);
  const [openAreasSubmenu, setOpenAreasSubmenu] = useState(false);
  const [openControlSubmenu, setOpenControlSubmenu] = useState(false);
  const [openReportsSubmenu, setOpenReportsSubmenu] = useState(false);
  const [openFavoritesSubmenu, setOpenFavoritesSubmenu] = useState(false);
  const [openPileMenu, togglePileMenu] = useMenu(false);
  const [openSemaphoreSubmenu, setOpenSemaphoreSubmenu] = useState(false);
  const [openSummaryMenu, toggleSummaryMenu] = useMenu(false);
  const [openReviewPerformanceMenu, toggleReviewPerformanceMenu] =
    useMenu(false);

  const [openNotificationForm, setOpenNotificationForm] = useState(false);

  const {
    fullName,
    email,
    permits,
    subsidiary,
    policy,
    logout,
    setNotification,
    getFavorites,
    getNotifications,
    favorites,
    getSubsidiaryPolicy,
    clearSubsidiaryPolicy,
  } = rest;

  useEffect(() => {
    getFavorites();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await getNotifications();
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await getSubsidiaryPolicy(subsidiary.subsidiary_id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (!!subsidiary) {
      fetchData();
    }

    return () => {
      clearSubsidiaryPolicy();
    };
  }, []);

  function handleProfileForm(status) {
    if (status) {
      onClose();
    }
    setShowProfileForm(status);
  }

  async function handleLogout() {
    try {
      await logout();
    } catch (error) {
      setNotification(error, true);
    }

    history.replace("/");
  }

  function handleOpenNotificationForm() {
    onClose();
    setOpenNotificationForm(true);
  }

  function handleCloseNotificationForm() {
    setOpenNotificationForm(false);
  }

  function handleToggleVehiclesSubmenu() {
    setOpenVehiclesSubmenu(!openVehiclesSubmenu);
  }

  function handleToggleAdminSubmenu() {
    setOpenAdminSubmenu(!openAdminSubmenu);
  }

  function handleToggleAreasSubmenu() {
    setOpenAreasSubmenu(!openAreasSubmenu);
  }

  function handleToggleControlSubmenu() {
    setOpenControlSubmenu(!openControlSubmenu);
  }

  function handleToggleReportsSubmenu() {
    if (openReportsSubmenu) {
      togglePileMenu(false);
    }
    setOpenReportsSubmenu(!openReportsSubmenu);
  }

  function handleToggleFavoritesSubmenu() {
    setOpenFavoritesSubmenu(!openFavoritesSubmenu);
  }

  function handleToggleSemaphoreSubmenu() {
    setOpenSemaphoreSubmenu(!openSemaphoreSubmenu);
  }

  return (
    <Page
      t={t}
      permits={permits}
      fullName={fullName}
      email={email}
      favorites={favorites}
      policy={policy}
      open={open}
      onClose={onClose}
      handleLogout={handleLogout}
      showProfileForm={showProfileForm}
      handleProfileForm={handleProfileForm}
      openVehiclesSubmenu={openVehiclesSubmenu}
      openAdminSubmenu={openAdminSubmenu}
      openAreasSubmenu={openAreasSubmenu}
      openControlSubmenu={openControlSubmenu}
      openReportsSubmenu={openReportsSubmenu}
      openFavoritesSubmenu={openFavoritesSubmenu}
      openPileMenu={openPileMenu}
      handleToggleVehiclesSubmenu={handleToggleVehiclesSubmenu}
      handleToggleAdminSubmenu={handleToggleAdminSubmenu}
      handleToggleAreasSubmenu={handleToggleAreasSubmenu}
      handleToggleControlSubmenu={handleToggleControlSubmenu}
      handleToggleReportsSubmenu={handleToggleReportsSubmenu}
      handleToggleFavoritesSubmenu={handleToggleFavoritesSubmenu}
      togglePileMenu={togglePileMenu}
      handleToggleSemaphoreSubmenu={handleToggleSemaphoreSubmenu}
      openSemaphoreSubmenu={openSemaphoreSubmenu}
      openSummaryMenu={openSummaryMenu}
      toggleSummaryMenu={toggleSummaryMenu}
      openReviewPerformanceMenu={openReviewPerformanceMenu}
      toggleReviewPerformanceMenu={toggleReviewPerformanceMenu}
      openNotificationForm={openNotificationForm}
      handleOpenNotificationForm={handleOpenNotificationForm}
      handleCloseNotificationForm={handleCloseNotificationForm}
    />
  );
}

NavigationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  let infoAccount = { fullName: "", email: "" };
  const account = state.Account.account;
  if (account !== null) {
    infoAccount.fullName = `${account.name} ${account.last_name_1} ${
      account.last_name_2 || ""
    }`;
    infoAccount.email = account.email;
  }

  const [assignedSubsidiary] = getSubsidiaryViaWorkArea(
    account,
    state.Account.workArea
  );

  const subsidiary = !!assignedSubsidiary ? assignedSubsidiary.subsidiary : {};
  const permits = state.Account.permits;

  return {
    fullName: infoAccount.fullName,
    email: infoAccount.email,
    permits,
    subsidiary,
    favorites: state.Favorites.favorites,
    policy: state.Subsidiary.SubsidiaryPolicy.Policy.policy,
  };
};
const mapDispatchToProps = (dispatch) => ({
  logout: actionLogout(dispatch),
  setNotification: actionSetNotification(dispatch),
  getFavorites: actionGetFavorites(dispatch),
  getSubsidiaryPolicy: actionGetSubsidiaryPolicy(dispatch),
  clearSubsidiaryPolicy: actionClearSubsidiaryPolicy(dispatch),
  getNotifications: actionGetNotifications(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDrawer);
