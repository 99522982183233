import { http } from "store/actions/http";
import { GET_DEPTH_TOLERANCE_POLICIES } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetDepthTolerancePolicies =
  (dispatch) => async (subsidiaryId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `subsidiary/${subsidiaryId}/depth/tolerance/policy`,
      });

      dispatch({
        type: GET_DEPTH_TOLERANCE_POLICIES,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
