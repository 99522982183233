import React from "react";
import classnames from "classnames";

import HistoryIcon from "@material-ui/icons/History";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import VehicleHistoryTabs from "components/organisms/vehicle/VehicleHistoryTabs";

import css from "./index.module.scss";

function Page() {
  return (
    <VehicleHistoryTabs
      className={classnames(css.container)}
      optionsAppBar={
        <>
          <Button className={classnames("bw")} variant="icon">
            <HistoryIcon />
          </Button>
          <Button className={classnames("bw")} variant="icon">
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    ></VehicleHistoryTabs>
  );
}

Page.propTypes = {};

export default Page;
