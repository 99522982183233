import React from "react";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { actionGetDrivers } from "store/actions/driver/getDrivers";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  vehicleTypes: PropTypes.array.isRequired,
  vehicleBrands: PropTypes.array.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  getVehicleTypes: actionGetVehiclesTypes(dispatch),
  getBrands: actionGetBrands(dispatch),
  getDrivers: actionGetDrivers(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
