import {
  USER_TOKEN,
  USER_PROFILE,
  UPDATE_PROFILE,
  USER_LOGOUT,
  PASSWORD_RESET,
  REFRESH_TOKEN,
  INTERVAL_REFRESH,
  UPDATE_PASSWORD,
  GET_USER_PERMITS,
  WORK_AREA,
  WORK_AREA_NAME,
  WORK_AREA_ID,
} from "store/actions";
import { isJson } from "utils/parse";

const initialState = {
  isAuthenticate: localStorage.getItem(USER_TOKEN) !== null,
  workArea: isJson(localStorage.getItem(WORK_AREA))
    ? JSON.parse(localStorage.getItem(WORK_AREA))
    : {},
  account: null,
  permits: [],
  intervalRefresh: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_TOKEN:
      localStorage.setItem(USER_TOKEN, action.payload);
      return {
        ...state,
        isAuthenticate: true,
      };
    case USER_PROFILE:
      return {
        ...state,
        account: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
        },
      };
    case USER_LOGOUT:
      localStorage.removeItem(USER_TOKEN);
      return {
        ...state,
        account: null,
        isAuthenticate: false,
      };
    case PASSWORD_RESET:
      localStorage.removeItem(USER_TOKEN);
      return {
        ...state,
        isAuthenticate: false,
      };
    case UPDATE_PASSWORD:
      if (state.account) {
        state.account.need_change_password = false;
      }
      return {
        ...state,
        account: state.account,
      };
    case REFRESH_TOKEN:
      localStorage.removeItem(USER_TOKEN);
      localStorage.setItem(USER_TOKEN, action.payload);
      return {
        ...state,
        isAuthenticate: true,
      };
    case INTERVAL_REFRESH:
      clearInterval(state.intervalRefresh);
      state.intervalRefresh = null;
      return {
        ...state,
        intervalRefresh: action.payload,
      };
    case GET_USER_PERMITS:
      return {
        ...state,
        permits: action.payload.filter((p) => p !== ""),
      };
    case WORK_AREA:
      localStorage.setItem(WORK_AREA, JSON.stringify(action.payload));
      localStorage.setItem(WORK_AREA_ID, action.payload.id);
      localStorage.setItem(WORK_AREA_NAME, action.payload.area);
      return {
        ...state,
        workArea: action.payload,
      };
    default:
      return state;
  }
}
