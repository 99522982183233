import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiary } from "store/actions/subsidiary/getSubsidiary";

import {
  LIST_VEHICLE_PRESSURE_POLICY,
  LIST_TIRE_MODEL_VARIATION_POLICY,
  SHOW_SUBSIDIARY,
} from "store/actions/account/permits";

import Page from "./page";

export function SubsidiaryTabs({
  children,
  className,
  optionsAppBar,
  ...rest
}) {
  const t = useLang();
  const { id } = useParams();
  const { permits, subsidiary, getSubsidiary, setNotification } = rest;

  const showSubsidiaryPermit = permits.includes(SHOW_SUBSIDIARY);
  const listVehiclePressurePolicyPermit = permits.includes(
    LIST_VEHICLE_PRESSURE_POLICY
  );
  const listTireModelVariationPolicy = permits.includes(
    LIST_TIRE_MODEL_VARIATION_POLICY
  );

  let tabLinks = [];
  if (subsidiary.subsidiary_id) {
    tabLinks = [
      {
        to: `/subsidiary/${id}/policy`,
        label: t("_general.policies"),
        disabled: !showSubsidiaryPermit,
        tooltip: !showSubsidiaryPermit ? t("_permits.show.subsidiary") : "",
      },
      {
        to: `/subsidiary/${id}/application/policy/tire`,
        label: t("_general.policies_application"),
      },
      {
        to: `/subsidiary/${id}/condition/policy/vehicle`,
        label: t("_general.condition_policies"),
        disabled: !showSubsidiaryPermit || !listVehiclePressurePolicyPermit,
        tooltip: !showSubsidiaryPermit
          ? t("_permits.list.condition_policy")
          : "",
        hidden: !Boolean(subsidiary.policy.tire_condition_axle_type),
      },
      {
        to: `/subsidiary/${id}/pressure/policy/vehicle`,
        label: t("_general.pressure_policies"),
        disabled: !showSubsidiaryPermit || !listVehiclePressurePolicyPermit,
        tooltip: !showSubsidiaryPermit
          ? t("_permits.list.pressure_policy")
          : "",
        hidden: !Boolean(subsidiary.policy.pressure_type_axle),
      },
      {
        to: `/subsidiary/${id}/depth/policy/vehicle`,
        label: t("_general.depth_policies"),
        disabled: !showSubsidiaryPermit || !listVehiclePressurePolicyPermit,
        tooltip: !showSubsidiaryPermit ? t("_permits.list.depth_policy") : "",
      },
      {
        to: `/subsidiary/${id}/model/pressure/policy/vehicle`,
        label: t("_general.model_variation_policies"),
        disabled: !showSubsidiaryPermit,
        tooltip: !showSubsidiaryPermit ? t("_permits.show.subsidiary") : "",
        hidden: Boolean(subsidiary.policy.pressure_type_axle),
      },
      {
        to: `/subsidiary/${id}/temperature/policy`,
        label: t("_general.temperature_policy"),
      },
      {
        to: `/subsidiary/${id}/helmet/value`,
        label: t("_general.helmet_values"),
        disabled: !showSubsidiaryPermit || !listTireModelVariationPolicy,
        tooltip: !showSubsidiaryPermit
          ? t("_permits.show.subsidiary")
          : !listTireModelVariationPolicy
          ? t("_permits.list.helmet_value")
          : "",
      },
    ];
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await getSubsidiary(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [id]);

  return (
    <Page
      tabLinks={tabLinks.filter((item) => !item.hidden)}
      className={className}
      title={subsidiary.name}
      optionsAppBar={optionsAppBar}
    >
      {children}
    </Page>
  );
}

SubsidiaryTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  subsidiary: state.Subsidiary.Subsidiary.subsidiary,
});
const mapDispatchToProps = (dispatch) => ({
  getSubsidiary: actionGetSubsidiary(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubsidiaryTabs);
