import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import { useLang } from "hooks/lang";
import css from "./index.module.scss";
import { alertCauseTranslation } from "utils/translation";

function Alerts({ ...rest }) {
  const t = useLang();
  const { alerts, open, onClose } = rest;
  return (
    <DialogMediaQuery
      maxWidth="lg"
      open={open}
      onClose={onClose}
      className="alert_info"
    >
      <DialogTitle>
        <span>
          {t("_general.alerts")}: {alerts.length}
        </span>
      </DialogTitle>
      <DialogContent className={classnames(css.box)}>
        <div
          style={{
            backgroundColor: "whitesmoke",
            color: "black",
          }}
          className="table-responsive"
        >
          <table className="table m-0">
            <thead className="thead-dark">
              <tr>
                <th>{t("_labels.alert.label")}</th>
                <th>{t("_labels.date.label")}</th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert, index) => (
                <tr key={index}>
                  <td>{alertCauseTranslation(t, alert.alert_cause)}</td>
                  <td>{moment(alert.created_at).format("LL h:mm a")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={classnames(css.action)}>
          <Button onClick={onClose} color="secondary">
            {t("_buttons.close")}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Alerts.propTypes = {
  alerts: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Alerts;
