export default {
  title: "New Corporate",
  update_title: "Update Corporate",
  form: {
    placeholder: "Drop an image or click to select it",
    permits: {
      update: "No permissions to update corporates",
      delete: "No permissions to delete corporates",
    },
  },
  messages: {
    delete: "Are you sure to delete the corporate?",
    corporate_created: "Done, corporate has been successfully created",
    corporate_updated: "Done, corporate has been successfully updated",
  },
};
