import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import Page from "./page";

export function HistoryDialog({ ...rest }) {
  const t = useLang();

  return <Page {...rest} t={t} />;
}

HistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default HistoryDialog;
