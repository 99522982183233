import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";

import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

import FileDragAndDropField from "components/molecules/general/FileDragAndDropField";
import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import CheckboxField from "components/molecules/general/CheckboxField";

import css from "./index.module.scss";

function Page({
  t,
  to,
  movement,
  movementTireId,
  values,
  initialValues,
  isValid,
  damages,
  providers,
  wears,
  drivers,
  retirementCauses,
  brands,
  models,
  warehouses,
  rfids,
  isSubmitting,
  onClose,
  handleSubmit,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      {to === "PILE" && (
        <>
          <Field
            id="retirement_cause_id"
            name="retirement_cause_id"
            autoHighlight
            disableClearable={initialValues.retirement_cause_id !== ""}
            blurOnSelect
            component={Autocomplete}
            options={retirementCauses}
            getOptionLabel={(option) => t(`_retirement.name.${option.name}`)}
            getOptionDisabled={(option) => !option.status}
            getOptionSelected={(option, value) => option === value}
            onChange={(_, value) => setFieldValue("retirement_cause_id", value)}
            value={values.retirement_cause_id}
            noOptionsText={t("_labels.autocomplete.no_options")}
            openText={t("_labels.autocomplete.open")}
            clearText={t("_labels.autocomplete.clear")}
            closeText={t("_labels.autocomplete.close")}
            className={classnames("MuiFormControl-root", css.full_width)}
            renderInput={(params) => (
              <TextField2
                {...params}
                required
                label={t("_labels.retirement_cause_field.label")}
                variant="filled"
              />
            )}
          />
          <Field
            multiline
            rows={3}
            variant="filled"
            margin="normal"
            name="comment"
            component={TextField}
            className={classnames(css.full_width)}
            label={t("_labels.comment.singular")}
          />
          <Field
            name="image"
            accept="image/*"
            placeholder={t("_labels.drop_image")}
            component={FileDragAndDropField}
            className={classnames(css.drop)}
          />

          <Field
            required
            variant="filled"
            margin="normal"
            name="date_send"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.full_width)}
            component={DateTimeField}
            label={t("_labels.date_send")}
          />

          <Field
            required
            variant="filled"
            margin="normal"
            name="cost_dispose_helmet"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.cost_dispose_helmet")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                step: "any",
              },
            }}
          />

          <Field
            variant="filled"
            margin="normal"
            name="surcharge"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.surcharge")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
              inputProps: {
                min: 0,
                step: "any",
              },
            }}
          />

          {!!values.surcharge && (
            <>
              <Field
                required
                variant="filled"
                margin="normal"
                name="driver_id"
                className={classnames(css.full_width)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.driver.singular")}
                defaultText={t("_labels.select_a_driver")}
                component={SelectField}
              >
                {drivers.map((driver, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={driver.driver_id.toString()}
                      disabled={!driver.status}
                    >
                      {driver.name}
                    </MenuItem>
                  );
                })}
              </Field>

              <Field
                required
                variant="filled"
                margin="normal"
                name="surcharge_item"
                className={classnames(css.full_width)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.surcharge_item.label")}
                defaultText={t("_labels.surcharge_item.default")}
                component={SelectField}
              >
                <MenuItem value="OPERATION">
                  {t("_labels.surcharge_item.options.operation")}
                </MenuItem>
              </Field>
            </>
          )}
        </>
      )}
      {!!values.wears && (
        <Fragment>
          <div className={classnames(css.full_width)}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("_labels.wear.plural")}
            </Typography>
          </div>
          <FieldArray
            name={"wears"}
            validateOnChange={false}
            render={(arrayHelpers) => (
              <>
                {values.wears.map((wear, index) => (
                  <div key={index} className={classnames(css.field_wrap)}>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        id={`${index}_wear_id`}
                        name={`wears[${index}].wear_id`}
                        size="small"
                        autoHighlight
                        blurOnSelect
                        component={Autocomplete}
                        options={wears}
                        getOptionLabel={(option) =>
                          option ? t(`_damage.name.${option.name}`) : ""
                        }
                        getOptionDisabled={(option) => !option.status}
                        getOptionSelected={(option, value) => {
                          return value && option.wear_id === value.wear_id;
                        }}
                        onChange={(_, value) =>
                          setFieldValue(`wears[${index}].wear_id`, value)
                        }
                        value={values["wears"][index]["wear_id"]}
                        noOptionsText={t("_labels.autocomplete.no_options")}
                        openText={t("_labels.autocomplete.open")}
                        clearText={t("_labels.autocomplete.clear")}
                        closeText={t("_labels.autocomplete.close")}
                        className={classnames(
                          "MuiFormControl-root",
                          css.full_width
                        )}
                        renderInput={(params) => (
                          <TextField2
                            {...params}
                            required
                            label={t("_labels.wear.singular")}
                            variant="filled"
                          />
                        )}
                      />
                      <Field
                        multiline
                        rows={2}
                        variant="filled"
                        margin="normal"
                        name={`wears[${index}].comment`}
                        component={TextField}
                        className={classnames(css.full_width)}
                        label={t("_labels.comment.singular")}
                        size="small"
                      />
                    </div>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        name={`wears[${index}].image`}
                        accept="image/*"
                        placeholder={t("_labels.drop_image")}
                        component={FileDragAndDropField}
                        className={classnames(css.drop)}
                        size="small"
                      />
                    </div>

                    <div className={classnames(css.w100)}>
                      {values.wears.length > 1 && (
                        <div className={classnames(css.remove_button)}>
                          <Button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            color="secondary"
                            size="small"
                            variant="fab"
                          >
                            <RemoveIcon />
                          </Button>
                        </div>
                      )}

                      <Divider className={classnames(css.full_width, css.m0)} />
                    </div>
                  </div>
                ))}

                <div className={classnames(css.full_width, css.add_button)}>
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        wear_id: null,
                        comment: "",
                        image: null,
                      })
                    }
                    color="secondary"
                    size="small"
                    variant="fab"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </>
            )}
          />
        </Fragment>
      )}

      {!!values.damages && (
        <Fragment>
          <div className={classnames(css.full_width)}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("_labels.damage.plural")}
            </Typography>
          </div>
          <FieldArray
            name={"damages"}
            validateOnChange={false}
            render={(arrayHelpers) => (
              <>
                {values.damages.map((damage, index) => (
                  <div key={index} className={classnames(css.field_wrap)}>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        id={`${index}_damage_id`}
                        name={`damages[${index}].damage_id`}
                        size="small"
                        autoHighlight
                        blurOnSelect
                        component={Autocomplete}
                        options={damages}
                        getOptionLabel={(option) =>
                          option ? t(`_damage.name.${option.name}`) : ""
                        }
                        getOptionDisabled={(option) => !option.status}
                        getOptionSelected={(option, value) => {
                          return value && option.damage_id === value.damage_id;
                        }}
                        onChange={(_, value) =>
                          setFieldValue(`damages[${index}].damage_id`, value)
                        }
                        value={values["damages"][index]["damage_id"]}
                        noOptionsText={t("_labels.autocomplete.no_options")}
                        openText={t("_labels.autocomplete.open")}
                        clearText={t("_labels.autocomplete.clear")}
                        closeText={t("_labels.autocomplete.close")}
                        className={classnames(
                          "MuiFormControl-root",
                          css.full_width
                        )}
                        renderInput={(params) => (
                          <TextField2
                            {...params}
                            required
                            label={t("_labels.damage.singular")}
                            variant="filled"
                          />
                        )}
                      />
                      <Field
                        multiline
                        rows={2}
                        variant="filled"
                        margin="normal"
                        name={`damages[${index}].comment`}
                        component={TextField}
                        className={classnames(css.full_width)}
                        label={t("_labels.comment.singular")}
                      />
                    </div>
                    <div className={classnames(css.horizontal)}>
                      <Field
                        name={`damages[${index}].image`}
                        accept="image/*"
                        placeholder={t("_labels.drop_image")}
                        component={FileDragAndDropField}
                        className={classnames(css.drop)}
                      />
                    </div>

                    <div className={classnames(css.w100)}>
                      <div className={classnames(css.remove_button)}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          color="secondary"
                          size="small"
                          variant="fab"
                          disabled={
                            to === "REPAIR" && values.damages.length <= 1
                          }
                        >
                          <RemoveIcon />
                        </Button>
                      </div>

                      <Divider className={classnames(css.full_width, css.m0)} />
                    </div>
                  </div>
                ))}

                <div className={classnames(css.full_width, css.add_button)}>
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        damage_id: null,
                        comment: "",
                        image: null,
                      })
                    }
                    color="secondary"
                    size="small"
                    variant="fab"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </>
            )}
          />
        </Fragment>
      )}

      {["REVITALIZATION", "REPAIR"].includes(to) && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="provider_id"
            className={classnames(css.full_width)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.provider_field.label")}
            defaultText={t("_labels.provider_field.default")}
            component={SelectField}
          >
            {providers.map((provider, index) => {
              return (
                <MenuItem
                  key={index}
                  value={provider.provider_id.toString()}
                  disabled={!provider.status}
                >
                  {provider.name}
                </MenuItem>
              );
            })}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="date_send"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.medium_field)}
            component={DateTimeField}
            label={t("_labels.date_send")}
          />
        </>
      )}

      {to === "WAREHOUSE" && movement === "REVITALIZATION" && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.full_width)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.warehouse.singular")}
            defaultText={t("_labels.warehouse.default")}
            component={SelectField}
          >
            {warehouses.map((warehouse) => (
              <MenuItem
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id.toString()}
                disabled={!warehouse.status}
              >
                {warehouse.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="price"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.price")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="revitalized_brand"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.revitalized_brand_field.label")}
            defaultText={t("_labels.revitalized_brand_field.default")}
            component={SelectField}
          >
            {brands.map((brand) => (
              <MenuItem
                key={brand.brand_id}
                value={brand.brand_id.toString()}
                disabled={!brand.status || !brand.approved}
              >
                {brand.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="revitalized_tire_model_id"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.revitalized_model_field.label")}
            defaultText={t("_labels.revitalized_model_field.default")}
            component={SelectField}
          >
            {models.map((model) => (
              <MenuItem
                key={model.revitalized_tire_model_id}
                value={model.revitalized_tire_model_id.toString()}
                disabled={!model.status || !model.approved}
              >
                {model.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            variant="filled"
            margin="normal"
            name="expected_durability"
            component={TextField}
            type="number"
            className={classnames(css.medium_field)}
            label={t("_labels.expected_durability")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  km
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="millimeter"
            component={TextField}
            type="number"
            className={classnames(css.medium_field)}
            label={t("_labels.depth")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  mm
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="date_return"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.full_width)}
            component={DateTimeField}
            label={t("_labels.date_return")}
          />
          <Field
            multiline
            rows={2}
            variant="filled"
            margin="normal"
            name="comment"
            component={TextField}
            className={classnames(css.full_width)}
            label={t("_labels.comment.singular")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.medium_field)}
            component={DateTimeField}
            label={t("_labels.invoice_date")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_folio"
            component={TextField}
            className={classnames(css.medium_field)}
            label={t("_labels.invoice_folio")}
          />
        </>
      )}

      {to === "WAREHOUSE" && movement === "REPAIR" && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.full_width)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.warehouse.singular")}
            defaultText={t("_labels.warehouse.default")}
            component={SelectField}
          >
            {warehouses.map((warehouse) => (
              <MenuItem
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id.toString()}
                disabled={!warehouse.status}
              >
                {warehouse.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="price"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.price")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="date_return"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.full_width)}
            component={DateTimeField}
            label={t("_labels.date_return")}
          />
          <Field
            multiline
            rows={2}
            variant="filled"
            margin="normal"
            name="comment"
            component={TextField}
            className={classnames(css.full_width)}
            label={t("_labels.comment.singular")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.medium_field)}
            component={DateTimeField}
            label={t("_labels.invoice_date")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_folio"
            component={TextField}
            className={classnames(css.medium_field)}
            label={t("_labels.invoice_folio")}
          />

          <Field
            variant="filled"
            margin="normal"
            name="surcharge"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.surcharge")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />

          {!!values.surcharge && (
            <>
              <Field
                required
                variant="filled"
                margin="normal"
                name="driver_id"
                className={classnames(css.full_width)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.driver.singular")}
                defaultText={t("_labels.select_a_driver")}
                component={SelectField}
              >
                {drivers.map((driver, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={driver.driver_id.toString()}
                      disabled={!driver.status}
                    >
                      {driver.name}
                    </MenuItem>
                  );
                })}
              </Field>

              <Field
                required
                variant="filled"
                margin="normal"
                name="surcharge_item"
                className={classnames(css.full_width)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                label={t("_labels.surcharge_item.label")}
                defaultText={t("_labels.surcharge_item.default")}
                component={SelectField}
              >
                <MenuItem value="OPERATION">
                  {t("_labels.surcharge_item.options.operation")}
                </MenuItem>
              </Field>
            </>
          )}

          <Field
            name="repair_detail"
            component={CheckboxField}
            className={classnames(css.m8)}
            label={t("_labels.repair_detail")}
          />

          {values.repair_detail && (
            <>
              <FieldArray
                name={"repairs"}
                validateOnChange={false}
                render={(arrayHelpers) => (
                  <>
                    {values.repairs.map((_, index) => (
                      <div key={index} className={classnames(css.field_wrap)}>
                        <div className={classnames(css.horizontal)}>
                          <Field
                            required
                            variant="filled"
                            margin="normal"
                            name={`repairs[${index}].repair_detail_name`}
                            className={classnames(css.full_width)}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                            label={t("_labels.repair_detail_name.label")}
                            defaultText={t(
                              "_labels.repair_detail_name.default"
                            )}
                            component={SelectField}
                          >
                            <MenuItem value="PATCH">
                              {t("_labels.repair_detail_name.options.patch")}
                            </MenuItem>
                            <MenuItem value="STUFFED">
                              {t("_labels.repair_detail_name.options.stuffed")}
                            </MenuItem>
                          </Field>
                          <Field
                            required
                            variant="filled"
                            margin="normal"
                            name={`repairs[${index}].repair_detail_cost`}
                            component={TextField}
                            type="number"
                            className={classnames(css.full_width)}
                            label={t("_labels.costo")}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  className={classnames(css.adornments)}
                                  position="start"
                                >
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className={classnames(css.w100)}>
                          <div className={classnames(css.remove_button)}>
                            <Button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              color="secondary"
                              size="small"
                              variant="fab"
                              disabled={values.repairs.length <= 1}
                            >
                              <RemoveIcon />
                            </Button>
                          </div>

                          <Divider
                            className={classnames(css.full_width, css.m0)}
                          />
                        </div>
                      </div>
                    ))}

                    <div className={classnames(css.full_width, css.add_button)}>
                      <Button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({
                            repair_detail_name: "",
                            repair_detail_cost: "",
                          })
                        }
                        color="secondary"
                        size="small"
                        variant="fab"
                      >
                        <AddIcon />
                      </Button>
                    </div>
                  </>
                )}
              />
            </>
          )}
        </>
      )}

      {to === "WAREHOUSE" && movementTireId === null && (
        <>
          <Field
            required={!values.device_code}
            variant="filled"
            margin="normal"
            name="code"
            component={TextField}
            className={classnames(css.medium_field)}
            label={t("_labels.code")}
          />
          <Field
            required={!values.code}
            variant="filled"
            margin="normal"
            name="device_code"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.rfid_field.label")}
            defaultText={t("_labels.rfid_field.default")}
            component={SelectField}
          >
            {rfids.map((rfid) => (
              <MenuItem
                key={rfid.rfid_id}
                value={rfid.device_code}
                disabled={!rfid.status}
              >
                {rfid.device_code}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.full_width)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.warehouse.singular")}
            defaultText={t("_labels.warehouse.default")}
            component={SelectField}
          >
            {warehouses.map((warehouse) => (
              <MenuItem
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id.toString()}
                disabled={!warehouse.status}
              >
                {warehouse.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="price"
            component={TextField}
            type="number"
            className={classnames(css.full_width)}
            label={t("_labels.price")}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="revitalized_brand"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.revitalized_brand_field.label")}
            defaultText={t("_labels.revitalized_brand_field.default")}
            component={SelectField}
          >
            {brands.map((brand) => (
              <MenuItem
                key={brand.brand_id}
                value={brand.brand_id.toString()}
                disabled={!brand.status || !brand.approved}
              >
                {brand.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            required
            variant="filled"
            margin="normal"
            name="revitalized_tire_model_id"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.revitalized_model_field.label")}
            defaultText={t("_labels.revitalized_model_field.default")}
            component={SelectField}
          >
            {models.map((model) => (
              <MenuItem
                key={model.revitalized_tire_model_id}
                value={model.revitalized_tire_model_id.toString()}
                disabled={!model.status || !model.approved}
              >
                {model.name}
              </MenuItem>
            ))}
          </Field>
          <Field
            variant="filled"
            margin="normal"
            name="expected_durability"
            component={TextField}
            type="number"
            className={classnames(css.medium_field)}
            label={t("_labels.expected_durability")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  km
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="millimeter"
            component={TextField}
            type="number"
            className={classnames(css.medium_field)}
            label={t("_labels.depth")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classnames(css.adornments)}
                  position="start"
                >
                  mm
                </InputAdornment>
              ),
            }}
          />
          <Field
            required
            variant="filled"
            margin="normal"
            name="date_return"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.full_width)}
            component={DateTimeField}
            label={t("_labels.date_return")}
          />
          <Field
            multiline
            rows={2}
            variant="filled"
            margin="normal"
            name="comment"
            component={TextField}
            className={classnames(css.full_width)}
            label={t("_labels.comment.singular")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classnames(css.medium_field)}
            component={DateTimeField}
            label={t("_labels.invoice_date")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="invoice_folio"
            component={TextField}
            className={classnames(css.medium_field)}
            label={t("_labels.invoice_folio")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="similar_tires"
            type="number"
            component={TextField}
            className={classnames(css.full_width)}
            label={t("_labels.similar_tires")}
          />
        </>
      )}

      {to === "WAREHOUSE" && movement === "MOUNT" && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.full_width)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            label={t("_labels.warehouse.singular")}
            defaultText={t("_labels.warehouse.default")}
            component={SelectField}
          >
            {warehouses.map((warehouse) => (
              <MenuItem
                key={warehouse.warehouse_id}
                value={warehouse.warehouse_id.toString()}
                disabled={!warehouse.status}
              >
                {warehouse.name}
              </MenuItem>
            ))}
          </Field>
        </>
      )}

      {to === "REJECTION" && (
        <Field
          required
          variant="filled"
          margin="normal"
          name="warehouse_id"
          className={classnames(css.full_width)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          }}
          label={t("_labels.warehouse.singular")}
          defaultText={t("_labels.warehouse.default")}
          component={SelectField}
        >
          {warehouses.map((warehouse) => (
            <MenuItem
              key={warehouse.warehouse_id}
              value={warehouse.warehouse_id.toString()}
              disabled={!warehouse.status}
            >
              {warehouse.name}
            </MenuItem>
          ))}
        </Field>
      )}

      {values.hasOwnProperty("with_review") && (
        <div className={classnames(css.full_width)}>
          <Field
            fullWidth
            name="with_review"
            component={CheckboxField}
            className={classnames(css.m8)}
            label={t("_labels.with_review")}
          />
        </div>
      )}

      {values.with_review && (
        <Field
          required
          variant="filled"
          margin="normal"
          name="depth"
          component={TextField}
          type="number"
          className={classnames(css.full_width)}
          label={t("_labels.depth")}
          InputProps={{
            endAdornment: (
              <InputAdornment
                className={classnames(css.adornments)}
                position="start"
              >
                mm
              </InputAdornment>
            ),
          }}
        />
      )}
      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={
            isSubmitting ||
            !isValid ||
            (values.hasOwnProperty("similar_tires") &&
              values.similar_tires <= 1)
          }
        >
          {values.similar_tires > 1
            ? t("_buttons.continue")
            : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  movement: PropTypes.string,
  movementTireId: PropTypes.number,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  damages: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  wears: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  retirementCauses: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
