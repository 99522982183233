export default {
  messages: {
    disable: "Are you sure to disable the corporate?",
    enable: "Are you sure to enable the corporate?",
  },
  buttons: {
    corporate_disabled: "Corporate disabled",
  },
  permits: {
    change_status: "No permissions to {action} corporates",
  },
};
