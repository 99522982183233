export default {
  form: {
    tire_model_status: "Estatus del modelo",
    labels: {
      brand_field: {
        label: "Marcas",
        helper_text: "Se pueden seleccionar varias marcas",
      },
    },
  },
};
