import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";
import MenuItem from "@material-ui/core/MenuItem";

import css from "./index.module.scss";

function Page({
  t,
  values,
  tireModels,
  tireModelVariations,
  isValid,
  isUpdate,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Field
        fullWidth
        required
        variant="filled"
        name="tire_model_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.tire_model.model_name")}
        disabled={isUpdate}
        defaultText={t("_labels.revitalized_model_field.default")}
        defaultValue={
          tireModels.some(
            (tireModel) => tireModel.tire_model_id === values.tire_model_id
          )
            ? null
            : values.tire_model_id
        }
      >
        {tireModels.map((tireModel, index) => {
          return (
            <MenuItem
              key={index}
              value={tireModel.tire_model.tire_model_id.toString()}
              disabled={!tireModel.tire_model.status}
            >
              {tireModel.tire_model.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        fullWidth
        required
        variant="filled"
        name="tire_model_variation_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        label={t("_labels.tire_model_variation_field.label")}
        disabled={isUpdate}
        defaultText={t("_labels.tire_model_variation_field.default")}
        defaultValue={
          tireModelVariations.some(
            (tireModelVariation) =>
              tireModelVariation.tire_model_variation_id ===
              values.tire_model_variation_id
          )
            ? null
            : values.tire_model_variation_id
        }
      >
        {tireModelVariations.map((tireModelVariation, index) => {
          return (
            <MenuItem
              key={index}
              value={tireModelVariation.tire_model_variation_id.toString()}
              disabled={!tireModelVariation.status}
            >
              {`${tireModelVariation.tire_size.size} ${tireModelVariation.number_layers}`}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="maximum_pressure"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.maximum_pressure")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="recommended_pressure"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.recommended_pressure")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="tolerance"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.tire_model_variation.tolerance")}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <DialogActions className={classnames(css.actions)}>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.save")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
