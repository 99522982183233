export default {
  cancel: "Cancel",
  close: "Close",
  new: "New",
  update: "Update",
  save: "Save",
  delete: "Delete",
  filter: "Filter",
  confirm: "Confirm",
  continue: "Continue",
  register: "Register",
  edit: "Edit",
  clean_filters: "Clean filters",
  change: "Change",
  generate_excel: "Generate excel",
  view: "View",
  link: "Link",
  unlink: "Unlink",
  unlink_gps: "Unlink gps",
  unlink_vehicle: "Unlink vehicle",
  review: "Review",
  unlink_tire: "Unlink tire",
  view_tire: "View tire",
  view_corporate: "View corporate",
  view_gps: "View gps",
  view_vehicle: "Ver vehicle",
  view_subsidiary: "View subsidiary",
  view_warehouse: "View warehouse",
  view_user: "View user",
  view_driver: "View driver",
  unlink_subsidiary: "Unlink subsidiary",
  unlink_driver: "Unlink driver",
  unlink_user: "Unlink user",
  unlink_warehouse: "Unlink warehouse",
  repair: "Repair",
  repaired: "Repaired",
  register_by_excel: "Register by excel",
  register_by_vehicle_type: "Register by vehicle type",
  reset_odometer: "Reset odometer",
  return_from_retread: "Return from retread",
  send_to_retread: "Send to retread",
  send_to_repair: "Send to repair",
  send_to_pile: "Send to pile",
  send_to_warehouse: "Send to warehouse",
  mount: "Mount",
  show_filters: "Show filters",
  hide_filters: "Hide filters",
  generate_excel: "Generate Excel",

  actions: {
    wear: "Wear",
    mount: "Mount",
    dismount: "Dismount",
    repair: "Repair",
    revitalize: "Revitalize",
    discard: "Discard",
    tire_mismatch: "Tire mismatch?",
  },
  complete: "Complete",
  pressures: "Pressures",
  identification: "Identification",
  damages_and_wear: "Damages and wear",
  damages: "Damages",
  wear: "Wear",
  finalize: "Finalize",
  pressure_adjustment: "Pressure adjustment",
  register_pressures: "Register pressures",
  identify_tires: "Identify tires",
  retread: "Retreads",
  rotation: "Rotation",
  best_tire: "Best tire for position",
  road_damage: "Road damage",
  move: "Move",
  see_table: "See Table",
  update_tire_review: "Update tire review",
  cancel_movement: "Cancel movement",
  edit_inspections: "Edit inspections",
  next: "Next",
  back: "Back",
  retread_rejection: "Retread rejection",
  pdf: "Download PDF",
  loading_pdf: "...Creating PDF",
  register_pile: "Adding tires directly to scrap",
  massive_pile: "Bulk Send to Scrap",
  example: "Example",
  format: "Format",
};
