export default {
  title: "Tipos de Vehículos",
  no_vehicle_type: "Sin tipos de Vehículos",
  permits: {
    create: "Sin permisos para crear tipos de vehículos",
  },
  text: {
    with: "con",
    axles: {
      singular: "eje",
      plural: "ejes",
    },
  },
};
