import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionGetTire } from "store/actions/tire/getTire";
import { actionSetNotification } from "store/actions/general/notification";

import {
  SHOW_TIRE,
  LIST_TIRE,
  SHOW_TIRE_WEAR,
  SHOW_TIRE_DAMAGE,
} from "store/actions/account/permits";

import Page from "./page";

export function TireTabs({ className, optionsAppBar, children, ...rest }) {
  const t = useLang();
  const { id: tireId } = useParams();
  const { tire, permits, getTire, setNotification } = rest;
  const listTirePermit = permits.includes(LIST_TIRE);
  const showTirePermit = permits.includes(SHOW_TIRE);
  const showTireWearPermit = permits.includes(SHOW_TIRE_WEAR);
  const showTireDamagePermit = permits.includes(SHOW_TIRE_DAMAGE);

  const tabLinks = [
    {
      to: `/tire/${tireId}/history`,
      label: t("_general.tire_history"),
      disabled: !listTirePermit,
      tooltip: !listTirePermit ? t("_permits.list.tire") : "",
    },
    {
      to: `/tire/${tireId}/review_history`,
      label: t("_general.tire_review_history"),
      disabled: !listTirePermit,
      tooltip: !listTirePermit ? t("_permits.list.tire") : "",
    },
    {
      to: `/tire/${tireId}/damage`,
      label: t("_labels.damage.plural"),
      disabled: !showTireDamagePermit,
      tooltip: !showTireDamagePermit ? t("permits.list.tire_damage") : "",
    },
    {
      to: `/tire/${tireId}/wear`,
      label: t("_general.wears"),
      disabled: !showTireWearPermit,
      tooltip: !showTireWearPermit ? t("permits.list.tire_wear") : "",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showTirePermit) {
      fetchData();
    }
  }, [tireId]);

  return (
    <Page
      tabLinks={tabLinks}
      className={className}
      title={
        showTirePermit ? tire.device_code || tire.code : t("_permits.show.tire")
      }
      optionsAppBar={optionsAppBar}
    >
      {children}
    </Page>
  );
}

TireTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  tire: state.Tire.Tire.tire,
});
const mapDispatchToProps = (dispatch) => ({
  getTire: actionGetTire(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireTabs);
