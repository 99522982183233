import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Container from "components/templates/general/Container";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Pagination from "components/molecules/general/Pagination";
import FixedButton from "components/molecules/general/FixedButton";
import CommissionedVehicleForm from "components/organisms/commissioned/commissioned_vehicle/CommissionedVehicleForm";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import FilterCommissionedVehicleForm from "components/organisms/commissioned/commissioned_vehicle/FilterCommissionedVehicleForm";
import CommissionedVehicleRegisterExcelForm from "components/organisms/commissioned/commissioned_vehicle/CommissionedVehicleRegisterExcelForm";
import ActionsMenu from "./ActionsMenu";
import LinkVehicleDriverForm from "components/organisms/commissioned/commissioned_vehicle_driver/LinkVehicleDriverForm";
import CommissionedDriverInfoDialog from "components/organisms/commissioned/commissioned_driver/CommissionedDriverInfoDialog";
import VehicleFullForm from "components/organisms/commissioned/commissioned_vehicle/VehicleFullForm";
import { Link } from "react-router-dom";
import MuiLink from "@material-ui/core/Link";

import css from "./index.module.scss";
import VehicleFullDialog from "components/organisms/commissioned/commissioned_vehicle/VehicleFullDialog";

function Page({
  t,
  vehicles,
  search,
  commissionedVehicleId,
  commissionedVehicle,
  commissionedDriver,
  openForm,
  openExcelForm,
  openFilter,
  openLinkForm,
  onChangePage,
  handleChangeStatus,
  handleDelete,
  handleEdit,
  handleOpenForm,
  handleCloseForm,
  handleSearch,
  handleOpenFilter,
  handleCloseFilter,
  handleOpenExcelForm,
  handleCloseExcelForm,
  handleOpenLinkForm,
  handleCloseLinkForm,
  openDriverDialog,
  handleOpenDriverDialog,
  handleCloseDriverDialog,
  openVehicleFullForm,
  handleOpenVehicleFullForm,
  handleCloseVehicleFullForm,
  openVehicleFullInfo,
  handleOpenVehicleFullInfo,
  handleCloseVehicleFullInfo,
  createVehiclePermit,
  updateVehiclePermit,
  deleteVehiclePermit,
}) {
  return (
    <Container
      title={t("_general.commissioned_vehicles")}
      className={classnames({
        [css.empty]: vehicles.data.length === 0,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search || ""} onChange={handleSearch} />
        </>
      }
    >
      <Button
        disabled={!createVehiclePermit}
        tooltip={!createVehiclePermit ? t("_permits.create.vehicle") : ""}
        color="primary"
        variant="contained"
        onClick={handleOpenExcelForm}
        className={classnames(css.excel_btn)}
      >
        {t("_buttons.register_by_excel")}
      </Button>
      {vehicles.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th className="text-center">{t("_labels.status")}</th>
                    <th>{t("_general.association")}</th>
                    <th>{t("_labels.driver.singular")}</th>
                    <th>{t("_labels.economic_number")}</th>
                    <th>{t("_labels.auto_enrollment")}</th>
                    <th>{t("_labels.vehicle_type.label")}</th>
                    <th>{t("_labels.has_odometer.simple")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {vehicles.data.map((vehicle) => (
                    <tr key={vehicle.commissioned_vehicle_id}>
                      <td className="text-center">
                        <Tooltip
                          title={
                            !updateVehiclePermit
                              ? t("_permits.update.vehicle")
                              : ""
                          }
                        >
                          <div>
                            {vehicle.status ? (
                              <button
                                className="btn btn-sm btn-success mx-auto d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateVehiclePermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleChangeStatus(
                                    vehicle.commissioned_vehicle_id,
                                    vehicle.status
                                  )
                                }
                                disabled={!updateVehiclePermit}
                              >
                                <DoneIcon fontSize="small" />
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm btn-danger mx-auto d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateVehiclePermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleChangeStatus(
                                    vehicle.commissioned_vehicle_id,
                                    vehicle.status
                                  )
                                }
                                disabled={!updateVehiclePermit}
                              >
                                <CloseIcon fontSize="small" />
                              </button>
                            )}
                          </div>
                        </Tooltip>
                      </td>
                      <td>{vehicle.association.name}</td>
                      <td>
                        {vehicle.commissioned_vehicle_driver.length > 0
                          ? vehicle.commissioned_vehicle_driver[0]
                              .commissioned_driver.name
                          : "-"}
                      </td>
                      <td>{vehicle.economic_number}</td>
                      <td>{vehicle.enrollment || "-"}</td>
                      <td>{vehicle.vehicle_type.name}</td>
                      <td>
                        {vehicle.has_odometer
                          ? t("_labels.yes")
                          : t("_labels.no")}
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-start"
                          style={{ gap: "6px" }}
                        >
                          <ActionsMenu
                            options={getOptions(vehicle, {
                              actionAdd: handleOpenLinkForm,
                              actionShow: handleOpenDriverDialog,
                              actionVehicleFull: handleOpenVehicleFullForm,
                              actionDeleteFull: handleOpenVehicleFullInfo,
                              actionViewChecklistHistory: () => {},
                            })}
                            title={t("_labels.actions")}
                          />
                          <Tooltip
                            title={
                              !updateVehiclePermit
                                ? t("_permits.update.vehicle")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateVehiclePermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleEdit(vehicle.commissioned_vehicle_id)
                                }
                                disabled={!updateVehiclePermit}
                              >
                                <EditIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>

                          <Tooltip
                            title={
                              !deleteVehiclePermit
                                ? t("_permits.delete.vehicle")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-danger d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !deleteVehiclePermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleDelete(vehicle.commissioned_vehicle_id)
                                }
                                disabled={!deleteVehiclePermit}
                              >
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={vehicles.current_page}
              totalPages={vehicles.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_vehicles")} />
      )}

      <FixedButton
        color="primary"
        onClick={handleOpenForm}
        disabled={!createVehiclePermit}
        tooltip={!createVehiclePermit ? t("_permits.create.vehicle") : ""}
      />

      <CommissionedVehicleForm
        commissionedVehicleId={commissionedVehicleId}
        open={openForm}
        onClose={handleCloseForm}
      />

      <FilterCommissionedVehicleForm
        open={openFilter}
        onClose={handleCloseFilter}
      />

      <CommissionedVehicleRegisterExcelForm
        open={openExcelForm}
        onClose={handleCloseExcelForm}
      />

      <LinkVehicleDriverForm
        open={openLinkForm}
        onClose={handleCloseLinkForm}
        commissionedVehicle={commissionedVehicle}
        commissionedDriver={commissionedDriver}
      />

      <CommissionedDriverInfoDialog
        open={openDriverDialog}
        onClose={handleCloseDriverDialog}
        commissionedDriver={commissionedDriver}
        handleOpenLinkForm={handleOpenLinkForm}
      />

      <VehicleFullForm
        open={openVehicleFullForm}
        onClose={handleCloseVehicleFullForm}
        commissionedVehicle={commissionedVehicle}
      />

      <VehicleFullDialog
        open={openVehicleFullInfo}
        onClose={handleCloseVehicleFullInfo}
        commissionedVehicleId={commissionedVehicleId}
      />
    </Container>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  search: PropTypes.string,
  commissionedVehicleId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  commissionedVehicle: PropTypes.object,
  commissionedDriver: PropTypes.object,
  onChangePage: PropTypes.func,
  openForm: PropTypes.bool,
  openFilter: PropTypes.bool,
  openExcelForm: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleOpenForm: PropTypes.func,
  handleCloseForm: PropTypes.func,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  handleOpenExcelForm: PropTypes.func,
  handleCloseExcelForm: PropTypes.func,
  handleSearch: PropTypes.func,
  openLinkForm: PropTypes.bool,
  handleOpenLinkForm: PropTypes.func,
  handleCloseLinkForm: PropTypes.func,
  openDriverDialog: PropTypes.bool,
  handleOpenDriverDialog: PropTypes.func,
  handleCloseDriverDialog: PropTypes.func,
  openVehicleFullForm: PropTypes.bool,
  handleOpenVehicleFullForm: PropTypes.func,
  handleCloseVehicleFullForm: PropTypes.func,
  openVehicleFullInfo: PropTypes.bool,
  handleOpenVehicleFullInfo: PropTypes.func,
  handleCloseVehicleFullInfo: PropTypes.func,
  createVehiclePermit: PropTypes.bool,
  updateVehiclePermit: PropTypes.bool,
  deleteVehiclePermit: PropTypes.bool,
};

export default Page;

function getOptions(vehicle, { ...rest }) {
  const {
    actionShow,
    actionAdd,
    actionVehicleFull,
    actionDeleteFull,
    actionViewChecklistHistory,
  } = rest;

  const options = [];

  if (vehicle.commissioned_vehicle_driver.length > 0) {
    options.push({
      label: "Ver conductor",
      action: () => actionShow(vehicle.commissioned_vehicle_driver[0]),
    });
  } else {
    options.push({
      label: "Asignar conductor",
      action: () => actionAdd(vehicle),
    });
  }

  if (
    ["TRUCK", "TRUCK_TRACTOR"].includes(vehicle.vehicle_type.structure_type)
  ) {
    options.push({
      label: "Vincular remolques",
      action: () => actionVehicleFull(vehicle),
    });
  }

  if (vehicle.commissioned_vehicle_full.length > 0) {
    options.push({
      label: "Desvincular remolques",
      action: () => actionDeleteFull(vehicle.commissioned_vehicle_id),
    });
  }

  if (vehicle.check_list_review.length > 0) {
    options.push({
      label: (
        <MuiLink
          component={Link}
          to={`/checklist/history/${vehicle.commissioned_vehicle_id}`}
          target="_blank"
          rel="noreferrer"
          color="inherit"
          underline="none"
        >
          Ver historial de checklist
        </MuiLink>
      ),
      action: () => actionViewChecklistHistory(vehicle.commissioned_vehicle_id),
    });
  }

  return options;
}
