import { GET_TIRE_REVIEW, CLEAR_TIRE_REVIEW } from "store/actions";

const initialState = {
  tire_review: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_REVIEW:
      return {
        ...state,
        tire_review: action.payload,
      };

    case CLEAR_TIRE_REVIEW:
      return {
        ...state,
        tire_review: initialState.tire_review,
      };

    default:
      return state;
  }
}
