import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireHistory } from "store/actions/tire/getTireHistory";
import { actionClearTireHistory } from "store/actions/tire/clearTireHistory";
import { actionClearTireHistoryFilters } from "store/actions/tire/clearTireHistoryFilters";

import { useLang } from "hooks/lang";
import Page from "./page";

export function TireHistory({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    tire,
    tires,
    filter,
    setNotification,
    getTireHistory,
    clearTireHistory,
    clearTireHistoryFilters,
  } = rest;
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireHistory(id, { page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTireHistory();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireHistoryFilters();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [tires.data]);

  async function onChangePage(page) {
    try {
      await getTireHistory(id, { page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      tires={tires}
      tire={tire}
      openFilter={openFilter}
      onChangePage={onChangePage}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}

TireHistory.propTypes = {};

const mapStateToProps = (state) => ({
  tires: state.Tire.History.tires,
  filter: state.Tire.History.filter,
  tire: state.Tire.Tire.tire,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireHistory: actionGetTireHistory(dispatch),
  clearTireHistory: actionClearTireHistory(dispatch),
  clearTireHistoryFilters: actionClearTireHistoryFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireHistory);
