import { GET_ALERTS_OF_MOUNT } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetAlertsOfMount =
  (dispatch) =>
  async (
    vehicleTireId,
    {
      page,
      scope,
      order = "DESC",
      date_from: dateFrom,
      date_to: dateTo,
      ranking_alert: rankingAlert,
      priority,
      flatten,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `alert/mount/${vehicleTireId}`,
        params: {
          page,
          scope,
          order,
          date_from: dateFrom,
          date_to: dateTo,
          ranking_alert: rankingAlert,
          priority,
          flatten,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_ALERTS_OF_MOUNT,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
