import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireDivision } from "store/actions/tire/division/getTireDivision";
import { actionClearTireDivision } from "store/actions/tire/division/clearTireDivision";
import { actionAssignTireToDivision } from "store/actions/division/tire/assignTireToDivision";
import { actionGetTireSubsidiary } from "store/actions/tire/getTireSubsidiary";
import { actionClearTireSubsidiary } from "store/actions/tire/clearTireSubsidiary";

import {
  LINK_TIRE_WITH_DIVISION,
  SHOW_LINK_TIRE_WITH_DIVISION,
  SHOW_LINK_TIRE_WITH_SUBSIDIARY,
} from "store/actions/account/permits";

import Page from "./page";

export function LinkTireDivisionForm({ className, tireId, ...rest }) {
  const t = useLang();
  const {
    permits,
    subsidiary,
    division,
    setNotification,
    getTireDivision,
    clearTireDivision,
    assignTireToDivision,
    getTireSubsidiary,
    clearTireSubsidiary,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      division_id: "",
    },
  });
  const [open, setOpen] = useState(false);
  const showLinkTireWithDivisionPermit = permits.includes(
    SHOW_LINK_TIRE_WITH_DIVISION
  );
  const showLinkTireWithSubsidiaryPermit = permits.includes(
    SHOW_LINK_TIRE_WITH_SUBSIDIARY
  );

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireDivision(tireId);
        await getTireSubsidiary(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showLinkTireWithDivisionPermit && showLinkTireWithSubsidiaryPermit) {
      fetchData();
    }

    return () => {
      clearTireDivision();
      clearTireSubsidiary();
    };
  }, [tireId]);

  function handleLink() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    resetFormik();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      await assignTireToDivision({
        tireId,
        divisionId: values.division_id,
      });

      handleClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      className={className}
      division={division}
      subsidiary={subsidiary}
      initialValues={initialValues}
      initialErrors={initialErrors}
      showLinkTireWithDivisionPermit={showLinkTireWithDivisionPermit}
      linkTireWithDivisionPermit={permits.includes(LINK_TIRE_WITH_DIVISION)}
      handleLink={handleLink}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkTireDivisionForm.propTypes = {
  className: PropTypes.string,
  tireId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  division: state.Tire.Division.division,
  subsidiary: state.Tire.Subsidiary.subsidiary,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireDivision: actionGetTireDivision(dispatch),
  clearTireDivision: actionClearTireDivision(dispatch),
  assignTireToDivision: actionAssignTireToDivision(dispatch),
  getTireSubsidiary: actionGetTireSubsidiary(dispatch),
  clearTireSubsidiary: actionClearTireSubsidiary(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkTireDivisionForm);
