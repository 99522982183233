import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function RadioInput({ children, ...props }) {
  return <Page {...props}>{children}</Page>;
}

RadioInput.propTypes = {
  children: PropTypes.node,
};

export default RadioInput;
