import tire from "./tire";
import subsidiary from "./subsidiary";
import WarehouseForm from "./WarehouseForm";
import InfoWarehouseCard from "./InfoWarehouseCard";
import FilterWarehouseForm from "./FilterWarehouseForm";
import WarehouseHistoryTabs from "./WarehouseHistoryTabs";

export default {
  tire,
  subsidiary,
  warehouse_form: WarehouseForm,
  info_warehouse_card: InfoWarehouseCard,
  filter_warehouse_form: FilterWarehouseForm,
  warehouse_history_tabs: WarehouseHistoryTabs,
};
