import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetTireStatsFilters } from "store/actions/reports/stats/setTireStatsFilters";
import { actionClearTireStatsFilters } from "store/actions/reports/stats/clearTireStatsFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionSetSelectedStatsFilters } from "store/actions/reports/stats/setSelectedStatsFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import { REVIEW_TYPES } from "utils/review";
import Page from "./page";

export function FilterTireStatsForm({
  open,
  onClose,
  isPileStats,
  current,
  ...rest
}) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setTireStatsFilters,
    getCorporates,
    getBrands,
    getSizes,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      date_from: current ? undefined : "",
      date_to: current ? undefined : "",
      movement: "MOUNT",
      brands: [],
      models: [],
      sizes: [],
      revitalized_brands: [],
      revitalized_models: [],
      depth_condition: "",
      depth_min: "",
      depth_max: "",
      with_refection: false,
      activity: "default",
      review_type: "default",
      number_cycle: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [revitalizedBrands, setRevitalizedBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [revitalizedModels, setRevitalizedModels] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [depthConditions, setDepthConditions] = useState([]);
  const [movements, setMovements] = useState([
    {
      value: "WAREHOUSE",
      label: t(`_labels.report_summary.location.warehouse`),
    },
    { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
    { value: "PILE", label: t(`_labels.report_summary.location.pile`) },
  ]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);

        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));

        data = await getBrands(
          {
            brandType: "TIRE,RETREAD",
            scope: "brand_id,name,status,approved,brand_type",
          },
          false
        );

        setBrands(
          data
            .filter((b) => b.brand_type == "TIRE")
            .sort(alphabeticalSort("name"))
        );
        setRevitalizedBrands(
          data
            .filter((b) => b.brand_type == "RETREAD")
            .sort(alphabeticalSort("name"))
        );

        data = await getSizes(
          { scope: "tire_size_id,size,status,approved" },
          false
        );
        setSizes(data.sort(alphabeticalSort("size")));

        setMovements([
          {
            value: "WAREHOUSE",
            label: t(`_labels.report_summary.location.warehouse`),
          },
          { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
          { value: "PILE", label: t(`_labels.report_summary.location.pile`) },
        ]);

        setDepthConditions([
          { value: "GOOD CONDITION", label: t("_labels.good_condition") },
          {
            value: "SCHEDULED WITHDRAWAL",
            label: t("_labels.scheduled_withdrawal"),
          },
          {
            value: "CRITICAL WITHDRAWAL",
            label: t("_labels.critical_withdrawal"),
          },
        ]);

        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.company_id ? filter.company_id.split(",") : [],
        subsidiary_id: filter.subsidiary_id
          ? filter.subsidiary_id.split(",")
          : [],
        date_from: current ? undefined : filter.date_from || "",
        date_to: current ? undefined : filter.date_to || "",
        brands: filter.brands
          ? brands.filter((e) =>
              filter.brands.split(",").includes(e.brand_id.toString())
            )
          : [],
        models: filter.models
          ? models.filter((e) =>
              filter.models.split(",").includes(e.tire_model_id.toString())
            )
          : [],
        sizes: filter.sizes
          ? sizes.filter((e) =>
              filter.sizes.split(",").includes(e.tire_size_id.toString())
            )
          : [],
        revitalized_brands: filter.revitalized_brands
          ? revitalizedBrands.filter((e) =>
              filter.revitalized_brands
                .split(",")
                .includes(e.brand_id.toString())
            )
          : [],
        revitalized_models: filter.revitalized_models
          ? revitalizedModels.filter((e) =>
              filter.revitalized_models
                .split(",")
                .includes(e.revitalized_tire_model_id.toString())
            )
          : [],
        movement: filter.movement || "MOUNT",
        with_refection: Boolean(filter.with_refection),
        depth_condition: filter.depth_condition || "",
        depth_min: filter.depth_min ? filter.depth_min : "",
        depth_max: filter.depth_max ? filter.depth_max : "",
        activity: filter.activity || "default",
        review_type: filter.review_type || "default",
        number_cycle: "number_cycle" in filter ? filter.number_cycle : "",
      });
    } else {
      resetFormik();
    }
  }, [open, models, loaded]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      params.movement = values.movement;

      setSelectedFilters({
        movement: movements.find((e) => e.value === values.movement).label,
      });

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.company_id = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.company_id = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiary_id = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiary_id = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      params.with_refection = Number(values.with_refection);
      setSelectedFilters({
        with_refection: values.with_refection
          ? t("_labels.yes")
          : t("_labels.no"),
      });

      if (values.depth_condition) {
        params.depth_condition = values.depth_condition;
        setSelectedFilters({
          depth_condition: depthConditions.find(
            (condition) => condition.value == values.depth_condition
          ).label,
        });
      }

      if (values.brands.length > 0) {
        params.brands = values.brands.map((b) => b.brand_id).join();
        const brand = brands.filter((brand) =>
          values.brands.find((b) => b.brand_id == brand.brand_id.toString())
        );

        setSelectedFilters({
          brands: brand.map((brand) => brand.name).join(),
        });
      } else {
        params.brands = null;
        setSelectedFilters({
          brands: "",
        });
      }

      if (values.models.length > 0) {
        params.models = values.models.map((b) => b.tire_model_id).join();
        const model = models.filter((model) =>
          values.models.find(
            (m) => m.tire_model_id == model.tire_model_id.toString()
          )
        );

        setSelectedFilters({
          models: model.map((model) => model.name).join(),
        });
      } else {
        params.models = null;
        setSelectedFilters({
          models: "",
        });
      }

      if (values.sizes.length > 0) {
        params.sizes = values.sizes.map((b) => b.tire_size_id).join();
        const size = sizes.filter((s) =>
          values.sizes.find(
            (size) => size.tire_size_id == s.tire_size_id.toString()
          )
        );

        setSelectedFilters({
          sizes: size.map((s) => s.size).join(),
        });
      } else {
        params.sizes = null;
        setSelectedFilters({
          sizes: "",
        });
      }

      if (values.revitalized_brands.length > 0) {
        params.revitalized_brands = values.revitalized_brands
          .map((b) => b.brand_id)
          .join();
        const brand = revitalizedBrands.filter((brand) =>
          values.revitalized_brands.find(
            (b) => b.brand_id == brand.brand_id.toString()
          )
        );

        setSelectedFilters({
          revitalized_brands: brand.map((brand) => brand.name).join(),
        });
      } else {
        params.revitalized_brands = null;
        setSelectedFilters({
          revitalized_brands: "",
        });
      }

      if (values.revitalized_models.length > 0) {
        params.revitalized_models = values.revitalized_models
          .map((b) => b.revitalized_tire_model_id)
          .join();
        const model = revitalizedModels.filter((model) =>
          values.revitalized_models.find(
            (m) =>
              m.revitalized_tire_model_id ==
              model.revitalized_tire_model_id.toString()
          )
        );

        setSelectedFilters({
          revitalized_models: model.map((model) => model.name).join(),
        });
      } else {
        params.revitalized_models = null;
        setSelectedFilters({
          revitalized_models: "",
        });
      }

      if (values.depth_min && values.depth_max) {
        params.depth_min = values.depth_min;
        params.depth_max = values.depth_max;
      }

      if (values.activity != "default") {
        params.activity = values.activity;

        setSelectedFilters({
          activity:
            values.activity == "GENERAL"
              ? t("_labels.activity.options.general")
              : t("_labels.activity.options.general_and_mount"),
        });
      }

      if (values.review_type != "default") {
        params.review_type = values.review_type;

        setSelectedFilters({
          review_type: t(
            REVIEW_TYPES.find((r) => r.value == values.review_type)?.label
          ),
        });
      }

      params.number_cycle = values.number_cycle;
      setSelectedFilters({
        number_cycle: values.number_cycle,
      });

      await setTireStatsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const company = getCompanyViaWorkArea(account, workArea)[0];
    const subsidiary = getSubsidiaryViaWorkArea(account, workArea)[0];
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");

    setTireStatsFilters({
      corporate_id: corporate.corporate.corporate_id.toString(),
      company_id: company.company.company_id.toString(),
      subsidiary_id: subsidiary.subsidiary.subsidiary_id.toString(),
      date_from: !current ? startDate : undefined,
      date_to: !current ? endDate : undefined,
    });

    setSelectedFilters({
      corporate: corporate.corporate.name,
      companies: company.company.name,
      subsidiaries: subsidiary.subsidiary.name,
      date_from: !current ? startDate : undefined,
      date_to: !current ? endDate : undefined,
    });
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      depthConditions={depthConditions}
      movements={movements}
      brands={brands}
      models={models}
      sizes={sizes}
      revitalizedBrands={revitalizedBrands}
      revitalizedModels={revitalizedModels}
      setRevitalizedModels={setRevitalizedModels}
      setModels={setModels}
      isPileStats={isPileStats}
      t={t}
    />
  );
}

FilterTireStatsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isPileStats: PropTypes.bool,
  current: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.Stats.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setTireStatsFilters: actionSetTireStatsFilters(dispatch),
  clearTireStatsFilters: actionClearTireStatsFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  getBrands: actionGetBrands(dispatch),
  getSizes: actionGetTireSizes(dispatch),
  setSelectedFilters: actionSetSelectedStatsFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterTireStatsForm);
