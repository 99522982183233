import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  t,
  open,
  tire,
  isUpdate,
  subsidiaries,
  brands,
  tireModels,
  setTireModels,
  revitalizedModels,
  rfids,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>
          {isUpdate ? t("_titles.update.tire") : t("_titles.new.tire")}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                tire={tire}
                subsidiaries={subsidiaries}
                brands={brands}
                tireModels={tireModels}
                setTireModels={setTireModels}
                rfids={rfids}
                revitalizedModels={revitalizedModels}
                isUpdate={isUpdate}
                onClose={onClose}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tire: PropTypes.object,
  open: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  setTireModels: PropTypes.func.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
