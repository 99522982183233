import { http } from "store/actions/http";
import { DELETE_FAVORITE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteFavorite = (dispatch) => async (favoriteId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `favorite/report/${favoriteId}`,
    });

    dispatch({
      type: DELETE_FAVORITE,
      payload: favoriteId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
