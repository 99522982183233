import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddVehicleType } from "store/actions/vehicle/type/addVehicleType";
import { actionGetVehicleType } from "store/actions/vehicle/type/getVehicleType";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";
import { actionUpdateVehicleType } from "store/actions/vehicle/type/updateVehicleType";
import { actionDeleteVehicleType } from "store/actions/vehicle/type/deleteVehicleType";

import { UPDATE_VEHICLE_TYPE } from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export const NAMESPACE = "components/organisms/vehicle/type/VehicleTypeForm";

export function VehicleTypeForm({ open, vehicleTypeId, onClose, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const {
    permits,
    vehicleType,
    getVehicleType,
    addVehicleType,
    setNotification,
    getVehiclesTypes,
    updateVehicleType,
    deleteVehicleType,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      name: "",
      structure_type: "",
      description: "",
    },
  });
  const [isUpdate, setIsUpdate] = useState(vehicleTypeId ? true : false);
  const updateVehicleTypePermit = permits.includes(UPDATE_VEHICLE_TYPE);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleType(vehicleTypeId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (vehicleTypeId && open) {
      fetchData();
    }
  }, [vehicleTypeId, open]);

  useEffect(() => {
    if (vehicleTypeId && vehicleType.vehicle_type_id) {
      setInitialValues({
        name: vehicleType.name,
        structure_type: vehicleType.structure_type,
        description: vehicleType.description || "",
      });
    } else {
      setInitialValues({
        name: "",
        structure_type: "",
        description: "",
      });
    }
  }, [vehicleType, vehicleTypeId]);

  useEffect(() => {
    setIsUpdate(vehicleTypeId ? true : false);
  }, [vehicleTypeId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const vehicleTypeFields = {
      name: values.name,
      structure_type: values.structure_type,
      description: values.description,
    };

    try {
      if (!isUpdate) {
        await addVehicleType(vehicleTypeFields);
        setNotification({
          message: t("messages.vehicle_type_created"),
        });
      } else {
        if (updateVehicleTypePermit) {
          await updateVehicleType(vehicleTypeFields, vehicleTypeId);
          setNotification({
            message: t("messages.vehicle_type_updated"),
          });
        }
      }

      resetForm();

      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);

    try {
      await getVehiclesTypes({ page: 1 });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDeleteVehicleType() {
    try {
      const response = await deleteVehicleType(vehicleTypeId);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }

    history.replace("/vehicle/type");
  }

  function handleDelete() {
    onClose();
    setNotification({
      message: t("messages.vehicle_type_delete"),
      textAction: t("_messages.confirm"),
      onAction: handleDeleteVehicleType,
    });
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      updateVehicleTypePermit={updateVehicleTypePermit}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      handleDelete={handleDelete}
    />
  );
}

VehicleTypeForm.propTypes = {
  open: PropTypes.bool.isRequired,
  vehicleTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehicleType: state.Vehicle.Type.Type.vehicle_type,
});

const mapDispatchToProps = (dispatch) => ({
  addVehicleType: actionAddVehicleType(dispatch),
  getVehicleType: actionGetVehicleType(dispatch),
  setNotification: actionSetNotification(dispatch),
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
  updateVehicleType: actionUpdateVehicleType(dispatch),
  deleteVehicleType: actionDeleteVehicleType(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypeForm);
