import history from "./history";
import TireCyclePanel from "./TireCyclePanel";
import InfoTireCycleDialog from "./InfoTireCycleDialog";
import TireCycleForm from "./TireCycleForm";

export default {
  history,
  tire_cycle_panel: TireCyclePanel,
  info_tire_cycle_dialog: InfoTireCycleDialog,
  tire_cycle_form: TireCycleForm,
};
