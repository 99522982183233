import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionClearRfidFilters } from "store/actions/rfid/clearRfidFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { actionRegisterRfidByExcel } from "store/actions/rfid/registerRfidByExcel";

import Page from "./page";

export function RfidRegisterExcelForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { setNotification, clearRfidFilters, registerRfidByExcel } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      excel_file: null,
    },
  });

  const [excelErrors, setExcelErrors] = useState([]);
  useEffect(() => {
    if (!open) {
      resetFormik();
      setExcelErrors([]);
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      excel_file: values.excel_file,
    };

    try {
      const response = await registerRfidByExcel(fields);
      resetForm();
      setNotification({
        message: response.message,
        code: response.code,
      });

      setSubmitting(false);
      try {
        clearRfidFilters();
      } catch (error) {
        setNotification(error, true);
      }
      onClose();
    } catch (error) {
      if (error.message || error.fields) {
        setFormikErrors(error, values, rest);
      } else {
        setExcelErrors(error);
      }
    }
  }

  return (
    <Page
      t={t}
      open={open}
      excelErrors={excelErrors}
      setExcelErrors={setExcelErrors}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

RfidRegisterExcelForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  clearRfidFilters: actionClearRfidFilters(dispatch),
  registerRfidByExcel: actionRegisterRfidByExcel(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RfidRegisterExcelForm);
