export default {
  preferred_language: "Preferred language",
  quit_admin_permissions: "quit admin permissions",
  enable_admin_permissions: "enable admin permissions",
  messages: {
    quit_permissions: "Are you sure to quit admin permissions?",
    enable_permissions: "Are you sure to enable admin permissions?",
    disable: "Are you sure to disable the user?",
    enable: "Are you sure to enable the user?",
  },
  disabled_user: "Disabled user",
  permits: {
    change_status: "No permissions to {action} users",
  },
};
