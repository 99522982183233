import {
  GET_WEAR,
  GET_WEARS,
  CLEAR_WEAR,
  CLEAR_WEARS,
  REGISTER_WEAR,
  UPDATE_WEAR,
  DELETE_WEAR,
  SET_WEAR_FILTERS,
  CLEAR_WEAR_FILTERS,
  SET_WEAR_STATUS,
} from "store/actions";

const initialState = {
  wear: {
    wear_id: 0,
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    name: "",
    status: 0,
    appearance: "",
    wear_category: "",
    axle: "",
    probable_causes: "",
    action_tire: "",
    action_vehicle: "",
    operation: "",
    image: null,
  },
  wears: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_wear: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEARS:
      return {
        ...state,
        wears: action.payload,
      };

    case CLEAR_WEARS:
      return {
        ...state,
        wears: initialState.wears,
      };

    case CLEAR_WEAR:
      return {
        ...state,
        wear: { ...initialState.wear },
      };

    case GET_WEAR:
    case REGISTER_WEAR:
    case UPDATE_WEAR:
      return {
        ...state,
        wear: action.payload,
      };
    case SET_WEAR_FILTERS:
      const filter = {
        ...state.filter_wear,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_wear: { ...filter },
      };

    case CLEAR_WEAR_FILTERS:
      return {
        ...state,
        filter_wear: { search: state.filter_wear.search },
      };

    case SET_WEAR_STATUS:
      return {
        ...state,
        wears: {
          ...state.wears,
          data: state.wears.data.map((wear) =>
            wear.wear_id !== action.payload
              ? wear
              : { ...wear, status: !wear.status }
          ),
        },
      };

    case DELETE_WEAR:
      return {
        ...state,
        wears: {
          ...state.wears,
          data: state.wears.data.filter(
            (wear) => wear.wear_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
