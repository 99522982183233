import vehicle from "./vehicle";
import DivisionForm from "./DivisionForm";
import DivisionHistoryTabs from "./DivisionHistoryTabs";
import FilterDivisionForm from "./FilterDivisionForm";
import InfoDivisionCard from "./InfoDivisionCard";
import LinkDivisionTireForm from "./LinkDivisionTireForm";

export default {
  vehicle,
  division_form: DivisionForm,
  division_history_tabs: DivisionHistoryTabs,
  filter_division_form: FilterDivisionForm,
  info_division_card: InfoDivisionCard,
  link_division_tire_form: LinkDivisionTireForm,
};
