import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionSetVehicleSubsidiaryHistoryFilters } from "store/actions/vehicle/subsidiary/history/setVehicleSubsidiaryHistoryFilters";
import { actionClearVehicleSubsidiaryHistoryFilters } from "store/actions/vehicle/subsidiary/history/clearVehicleSubsidiaryHistoryFilters";

import { useFormik } from "hooks/formik";

import Page from "./page";

export function FilterVehicleSubsidiaryHistoryForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    setVehicleSubsidiaryHistoryFilters,
    clearVehicleSubsidiaryHistoryFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      date_from: "",
      date_to: "",
      status: "",
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        status:
          filter.status !== undefined
            ? filter.status
              ? "enabled"
              : "disabled"
            : "",
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  function handleClean() {
    clearVehicleSubsidiaryHistoryFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.status !== "") {
        params.status = values.status === "enabled" ? 1 : 0;
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      await setVehicleSubsidiaryHistoryFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterVehicleSubsidiaryHistoryForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Vehicle.Subsidiary.History.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setVehicleSubsidiaryHistoryFilters:
    actionSetVehicleSubsidiaryHistoryFilters(dispatch),
  clearVehicleSubsidiaryHistoryFilters:
    actionClearVehicleSubsidiaryHistoryFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterVehicleSubsidiaryHistoryForm);
