import React from "react";

import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookMarkBorderFilledIcon from "@material-ui/icons/Bookmark";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";

function Page({
  children,
  t,
  classNameIcon,
  classNameText,
  name,
  endIcon,
  divider,
  handleClick,
  handleRegisterFavorite,
  handleDeleteFavorite,
  favoriteName,
  isFavorite,
  favorites,
}) {
  return (
    <ListItem
      button
      onClick={handleClick}
      divider={divider == false ? false : true}
    >
      {children && (
        <ListItemIcon className={classNameIcon}>{children}</ListItemIcon>
      )}
      <ListItemText primary={name} className={classNameText} />
      {endIcon && (
        <ListItemIcon style={{ justifyContent: "flex-end" }}>
          {endIcon}
        </ListItemIcon>
      )}
      {favoriteName && (
        <BootstrapTooltip
          key={favoriteName}
          title={
            isFavorite
              ? t("_labels.remove_favorite")
              : t("_labels.save_favorite")
          }
          placement="bottom"
        >
          <ListItemIcon
            style={{
              justifyContent: "flex-end",
              minWidth: "0px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (!isFavorite) {
                handleRegisterFavorite(favoriteName);
              } else {
                const favorite = favorites.find(
                  (favorite) =>
                    favorite.favorite_report.favorite_report_name ===
                    favoriteName
                );
                const favoriteId = favorite.favorite_report_user_id;
                handleDeleteFavorite(favoriteId);
              }
            }}
          >
            {isFavorite ? (
              <BookMarkBorderFilledIcon
                style={{
                  color: "#FFC107",
                }}
              />
            ) : (
              <BookmarkBorderIcon />
            )}
          </ListItemIcon>
        </BootstrapTooltip>
      )}
    </ListItem>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  endIcon: PropTypes.node,
  classNameIcon: PropTypes.string,
  classNameText: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  divider: PropTypes.bool,
  favoriteName: PropTypes.string,
  handleRegisterFavorite: PropTypes.func,
  handleDeleteFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  favorites: PropTypes.array,
};

export default Page;
