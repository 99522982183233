import { http } from "store/actions/http";
import { DELETE_VEHICLE_TYPE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteVehicleType = (dispatch) => async (vehicleTypeId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `vehicle/type/${vehicleTypeId}`,
    });

    dispatch({
      type: DELETE_VEHICLE_TYPE,
      payload: vehicleTypeId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
