import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import SpeedIcon from "@material-ui/icons/Speed";
// import HistoryIcon from "@material-ui/icons/History";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import BlockIcon from "@material-ui/icons/Block";

import Button from "components/molecules/general/Button";

import { useLang } from "hooks/lang";

import Page from "./page";

function RowButton({ icon, id, imageUrl, title, ...rest }) {
  const { tooltipPermit, onClick } = rest;
  if (imageUrl) {
    return (
      <Button
        variant="icon"
        color="secondary"
        disabled={!!tooltipPermit}
        tooltip={tooltipPermit}
        onClick={() => {
          onClick(imageUrl, title);
        }}
      >
        {icon}
      </Button>
    );
  }

  return (
    <Button
      variant="icon"
      color="secondary"
      disabled={!!tooltipPermit}
      tooltip={tooltipPermit}
      onClick={() => {
        onClick(id);
      }}
    >
      {icon}
    </Button>
  );
}

RowButton.propTypes = {
  id: PropTypes.number,
  icon: PropTypes.node,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  tooltipPermit: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function TableResponsive({
  rows,
  onEdit,
  onDelete,
  onChangeStatus,
  onChangeApproved,
  onImage,
  onReset,
  onResetDiff,
  onDialog,
  onLink,
  ...props
}) {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const history = useHistory();
  const t = useLang();

  const newRows = rows.map((row) => {
    const mappedRow = { ...row };

    let array = [];

    if (row.hasOwnProperty("status")) {
      mappedRow.status = (
        <RowButton
          {...row.status}
          id={row.id}
          onClick={() => {
            onChangeStatus({
              id: row.id,
              status: row.status.status,
            });
          }}
          icon={row.status.status ? <DoneIcon /> : <CloseIcon />}
        />
      );
    }

    if (row.hasOwnProperty("approved")) {
      array.push(
        <Button
          size="small"
          color="secondary"
          disabled={!!row.approved.tooltipPermit}
          tooltip={row.approved.tooltipPermit}
          onClick={() => {
            onChangeApproved({
              id: row.id,
              approved: row.approved.approved,
            });
          }}
        >
          {row.approved.approved
            ? t("_general.disapprove")
            : t("_general.approve")}
        </Button>
      );

      mappedRow.approved = row.approved.approved
        ? t(`_general.approved.singular.${row.approved.gender || "male"}`)
        : t(`_general.not_approved.singular.${row.approved.gender || "male"}`);
    }
    if (row.hasOwnProperty("redirect")) {
      if (Array.isArray(row.redirect)) {
        row.redirect.forEach((redirect) => {
          array.push(
            <Button
              size={redirect.size || "small"}
              color="secondary"
              disabled={redirect.disabled}
              tooltip={redirect.tooltip}
              onClick={!!redirect.handleClick ? redirect.handleClick : () => {}}
              href={redirect.href}
              target={!!redirect.target ? redirect.target : null}
              rel={!!redirect.rel ? redirect.rel : null}
              variant={redirect.variant || "text"}
              style={redirect.style}
            >
              {redirect.label}
            </Button>
          );
        });
      } else {
        if (!!row.redirect) {
          array.push(
            <Button
              size={row.redirect.size || "small"}
              color="secondary"
              disabled={!!row.redirect.tooltipPermit}
              tooltip={row.redirect.tooltipPermit}
              onClick={row.redirect.handleClick}
              variant={row.redirect.variant || "text"}
              style={row.redirect.style}
              href={row.redirect.href}
              target={!!row.redirect.target ? row.redirect.target : null}
              rel={!!row.redirect.rel ? row.redirect.rel : null}
            >
              {row.redirect.label}
            </Button>
          );
        }
      }
    }

    if (row.hasOwnProperty("view")) {
      array.push(
        <RowButton
          {...row.view}
          icon={<VisibilityIcon />}
          onClick={() => history.push(row.view.to)}
        />
      );
    }

    if (row.hasOwnProperty("image")) {
      array.push(
        <RowButton {...row.image} onClick={onImage} icon={<ImageIcon />} />
      );
    }

    if (row.hasOwnProperty("info")) {
      array.push(
        <RowButton
          {...row.info}
          id={row.id}
          onClick={onDialog}
          icon={<MoreVertIcon />}
        />
      );
    }

    if (row.hasOwnProperty("data_sheet")) {
      array.push(
        <Link
          href={
            row.data_sheet.data_sheet
              ? new URL(
                  `${process.env.REACT_APP_IMAGES_URL}${row.data_sheet.data_sheet}`
                )
              : null
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            variant="icon"
            color="secondary"
            tooltip={row.data_sheet.tooltipPermit || "Ver ficha técnica"}
            disabled={!!row.data_sheet.tooltipPermit}
          >
            <VisibilityIcon />
          </Button>
        </Link>
      );
    }

    if (row.hasOwnProperty("history")) {
      array.push(
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            row.history.onHistory(row.id);
          }}
        >
          {t("_labels.history")}
        </Button>
      );
    }
    if (
      (row.hasOwnProperty("link") && row.tire?.length === 0) ||
      row.vehicle?.length === 0
    ) {
      array.push(
        <RowButton
          {...row.link}
          id={row.id}
          onClick={onLink}
          icon={<LinkIcon />}
        />
      );
    }

    if (
      (row.hasOwnProperty("unlink") && row.tire?.length > 0) ||
      row.vehicle?.length > 0
    ) {
      array.push(
        <RowButton
          {...row.unlink}
          onClick={row.unlink.onUnlink}
          icon={<LinkOffIcon />}
        />
      );
    }

    if (row.hasOwnProperty("edit")) {
      array.push(
        <RowButton
          {...row.edit}
          id={row.id}
          onClick={onEdit}
          icon={<EditIcon />}
        />
      );
    }

    if (row.hasOwnProperty("delete")) {
      array.push(
        <RowButton
          {...row.delete}
          id={row.id}
          onClick={onDelete}
          icon={<DeleteIcon />}
        />
      );
    }

    if (
      row.hasOwnProperty("odometer") &&
      row.has_odometer === t("_labels.yes")
    ) {
      array.push(
        <Button
          variant="icon"
          color="secondary"
          disabled={row.odometer.disabled}
          tooltip={row.odometer.tooltipPermit}
          onClick={() => {
            onReset(row.id);
          }}
        >
          <SpeedIcon />
        </Button>
      );
    }

    if (row.hasOwnProperty("reset_diff") && row.reset_diff.has_gps) {
      array.push(
        <Button
          variant="icon"
          color="secondary"
          disabled={row.reset_diff.disabled}
          tooltip={row.reset_diff.tooltipPermit}
          onClick={() => {
            onResetDiff(row.id, row.reset_diff.gps_id);
          }}
        >
          <BlockIcon />
        </Button>
      );
    }

    if (row.hasOwnProperty("other")) {
      array.push(
        <Button
          size={row.other.size || "small"}
          color="secondary"
          disabled={!!row.other.disabled}
          tooltip={row.other.tooltip}
          onClick={row.other.handleClick}
          variant={row.other.variant || "text"}
        >
          {row.other.label}
        </Button>
      );
    }

    if (row.hasOwnProperty("select")) {
      array.push(
        <Button variant="icon" color="secondary" onClick={row.select.onSelect}>
          {row.select.isSelected ? <DoneIcon /> : <CloseIcon />}
        </Button>
      );
    }

    mappedRow.actions = (
      <>
        {array.map((btn, index) => (
          <div key={index}>{btn}</div>
        ))}
      </>
    );

    return mappedRow;
  });

  return <Page isMobile={isMobile} rows={newRows} {...props} />;
}

TableResponsive.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  size: PropTypes.string,
  selectable: PropTypes.bool,
  sticky: PropTypes.bool,
  totalRows: PropTypes.number,
  onDelete: PropTypes.func,
  onChangeStatus: PropTypes.func,
  onChangeApproved: PropTypes.func,
  onEdit: PropTypes.func,
  onImage: PropTypes.func,
  onReset: PropTypes.func,
  onResetDiff: PropTypes.func,
  onDialog: PropTypes.func,
  onRowClick: PropTypes.func,
  onLink: PropTypes.func,
};

export default TableResponsive;
