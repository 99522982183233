import {
  GET_DRIVER_VEHICLES_HISTORY,
  CLEAR_DRIVER_VEHICLES_HISTORY,
} from "store/actions";

const initialState = {
  vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DRIVER_VEHICLES_HISTORY:
      return {
        ...state,
        vehicles: action.payload,
      };

    case CLEAR_DRIVER_VEHICLES_HISTORY:
      return {
        ...state,
        vehicles: initialState.vehicles,
      };

    default:
      return state;
  }
}
