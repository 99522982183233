import {
  REGISTER_USER,
  GET_USERS,
  CLEAR_USERS,
  GET_USER,
  CLEAR_USER,
  SET_USER_FILTERS,
  CLEAR_USER_FILTERS,
  SET_ROOT_USER,
  UPDATE_USER,
  SET_USER_STATUS,
  DELETE_USER,
  REGISTER_USER_ROLE,
} from "store/actions";

const initialState = {
  user: {
    user_id: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      user_id: 0,
      name: "",
      last_name_1: "",
      last_name_2: "",
      email: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
    name: "",
    last_name_1: "",
    last_name_2: "",
    email: "",
    preferred_language: "",
    status: 0,
    need_change_password: 0,
    is_root: 0,
    corporates: [],
  },
  users: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        user: action.payload,
      };

    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case CLEAR_USERS:
      return {
        ...state,
        users: { ...initialState.users },
      };

    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case CLEAR_USER:
      return {
        ...state,
        user: { ...initialState.user },
      };
    case SET_USER_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_USER_FILTERS:
      return {
        ...state,
        filter: {},
      };

    case SET_ROOT_USER:
      return {
        ...state,
        user: { ...state.user, is_root: action.payload },
      };

    case REGISTER_USER_ROLE:
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case SET_USER_STATUS:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((user) =>
            user.user_id !== action.payload
              ? user
              : { ...user, status: !user.status }
          ),
        },
      };

    case DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter(
            (user) => user.user_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
