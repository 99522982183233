import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { actionSetNotification } from "store/actions/general/notification";
// import { actionGetTires } from "store/actions/tire/getTires";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
// import { assignRfidToTire } from "store/actions/tire/rfid/assignRfidToTire";
import { actionAssignVehicleToGps } from "store/actions/gps/vehicle/assignVehicleToGps";
// import { actionClearRfidFilters } from "store/actions/rfid/clearRfidFilters";
import { actionClearGpsFilters } from "store/actions/gps/clearGpsFilters";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function LinkVehicleForm({ open, onClose, id, ...rest }) {
  const t = useLang();
  const { setNotification, getVehicles, assignVehicleToGps, clearGpsFilters } =
    rest;
  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      vehicle_id: null,
    },
  });
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getVehicles(
          {
            with_link_vehicle: 0,
            scope: "vehicle_id,economic_number,status",
          },
          false
        );
        setVehicles(data.sort(alphabeticalSort("economic_number")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && id) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      // const fields = {
      //   subsidiary_id: 1,
      //   diff_odometer: 2,
      //   geofence_status: 1,
      // };
      await assignVehicleToGps({
        gpsId: id,
        vehicleId: values.vehicle_id,
      });
      setNotification({
        message: t("_messages.linked.gps_with_vehicle"),
      });
      clearGpsFilters();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      vehicles={vehicles}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

LinkVehicleForm.propTypes = {
  id: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  // getTires: actionGetTires(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  // assignRfidToTire: assignRfidToTire(dispatch),
  assignVehicleToGps: actionAssignVehicleToGps(dispatch),
  clearGpsFilters: actionClearGpsFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkVehicleForm);
