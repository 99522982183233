export default {
  title: "Conductores vinculados",
  form_title: "Vincular conductor",
  permits: {
    unlink: {
      subsidiary_driver:
        "Sin permisos para desvincular conductores de sucursales",
    },
  },
  messages: {
    delete: "¿Estas seguro de desvincular el conductor?",
    delete_subsidiary_driver:
      "Listo, se desvinculó el conductor de la sucursal",
    attached_subsidiary_driver:
      "Listo, el conductor ha sido vinculado con éxito",
  },
};
