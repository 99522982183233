import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetReportWarehouseFilters } from "store/actions/reports/warehouse/setWarehouseFilters";
import { actionClearReportWarehouseFilters } from "store/actions/reports/warehouse/clearWarehouseFilters";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetSelectedReportWarehouseFilters } from "store/actions/reports/warehouse/setSelectedWarehouseFilters";

import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "../../../../utils/workArea";

export function FilterWarehouseForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    setNotification,
    setWarehouseFilters,
    getCorporates,
    setSelectedFilters,
    getBrands,
    getTireSizes,
    account,
    workArea,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      company_id: [],
      subsidiary_id: [],
      tire_application: [],
      tire_condition: [],
      date_from: "",
      date_to: "",
      warehouse_id: [],
      provider_id: "",
      brands: [],
      models: "",
      tire_model_variations: [],
      tire_size: "",
      revitalized_brands: "",
      revitalized_models: "",
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [conditions] = useState([
    {
      value: "ORIGINAL_NEW",
      label: t("_labels.tire_condition.options.original_new"),
    },
    {
      value: "ORIGINAL_USED",
      label: t("_labels.tire_condition.options.original_used"),
    },
    {
      value: "RETREAD_NEW",
      label: t("_labels.tire_condition.options.retread_new"),
    },
    {
      value: "RETREAD_USED",
      label: t("_labels.tire_condition.options.retread_used"),
    },
  ]);
  const [applications] = useState([
    {
      value: "ALL_POSITION",
      label: t("_labels.tire_application.options.all_position"),
    },
    {
      value: "DIRECTIONAL",
      label: t("_labels.tire_application.options.directional"),
    },
    {
      value: "TRACTION",
      label: t("_labels.tire_application.options.traction"),
    },
    { value: "TRAILER", label: t("_labels.tire_application.options.trailer") },
  ]);

  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tireModels, setTireModels] = useState([]);
  const [tireModelVariations, setTireModelVariations] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);
  const [revitalizedBrands, setRevitalizedBrands] = useState([]);
  const [revitalizedModels, setRevitalizedModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        let dataBrands = [];
        dataBrands = await getBrands(
          { brandType: "TIRE", scope: "brand_id,name,status" },
          false
        );

        let dataTireSizes = [];
        dataTireSizes = await getTireSizes(
          {
            scope: "tire_size_id,size,status",
          },
          false
        );

        let dataRevitalizedBrands = [];
        dataRevitalizedBrands = await getBrands(
          { brandType: "RETREAD", scope: "brand_id,name,status" },
          false
        );

        setBrands(dataBrands.sort(alphabeticalSort("name")));
        setTireSizes(dataTireSizes.sort(alphabeticalSort("size")));
        setRevitalizedBrands(
          dataRevitalizedBrands.sort(alphabeticalSort("name"))
        );

        setCorporates(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        company_id: filter.company_id ? filter.company_id.split(",") : [],
        subsidiary_id: filter.subsidiary_id
          ? filter.subsidiary_id.split(",")
          : [],
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        tire_condition: filter.tire_condition
          ? filter.tire_condition.split(",")
          : [],
        tire_application: filter.tire_application
          ? filter.tire_application.split(",")
          : [],
        warehouse_id: filter.warehouse_id ? filter.warehouse_id.split(",") : [],
        provider_id: filter.provider_id || "",
        brands: filter.brands
          ? brands.filter((e) =>
              filter.brands.split(",").includes(e.brand_id.toString())
            )
          : [],
        models: filter.models
          ? tireModels.find((model) => model.tire_model_id === filter.models)
          : null,
        tire_model_variations: filter.tire_model_variations
          ? filter.tire_model_variations.split(",")
          : [],

        tire_size: filter.tire_size || "",

        revitalized_brands: filter.revitalized_brands || "",
        revitalized_models: filter.revitalized_models || "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.company_id.length > 0) {
        params.company_id = values.company_id.join();
        const company = companies.filter((company) =>
          values.company_id.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.company_id = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiary_id.length > 0) {
        params.subsidiary_id = values.subsidiary_id.join();
        const subsidiary = subsidiaries.filter((subsidiary) =>
          values.subsidiary_id.includes(subsidiary.subsidiary_id.toString())
        );
        setSelectedFilters({
          subsidiaries: subsidiary.map((subsidiary) => subsidiary.name).join(),
        });
      } else {
        params.subsidiary_id = null;
        setSelectedFilters({
          subsidiaries: "",
        });
      }

      if (values.tire_condition.length > 0) {
        params.tire_condition = values.tire_condition.join();
        const condition = conditions.filter((condition) =>
          values.tire_condition.includes(condition.value)
        );
        setSelectedFilters({
          conditions: condition.map((condition) => condition.label).join(),
        });
      } else {
        params.tire_condition = null;
        setSelectedFilters({
          conditions: "",
        });
      }

      if (values.tire_application.length > 0) {
        params.tire_application = values.tire_application.join();
        const application = applications.filter((application) =>
          values.tire_application.includes(application.value)
        );
        setSelectedFilters({
          applications: application
            .map((application) => application.label)
            .join(),
        });
      } else {
        params.tire_application = null;
        setSelectedFilters({
          applications: "",
        });
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
        setSelectedFilters({
          date_from: values.date_from,
          date_to: values.date_to,
        });
      }

      if (values.provider_id !== "") {
        params.provider_id = values.provider_id;
        const provider = providers.find(
          (provider) => provider.provider_id == values.provider_id
        );
        setSelectedFilters({ provider: provider.name });
      }

      if (values.warehouse_id.length > 0) {
        params.warehouse_id = values.warehouse_id.join();
        const warehouse = warehouses.filter((warehouse) =>
          values.warehouse_id.includes(warehouse.warehouse_id.toString())
        );
        setSelectedFilters({
          warehouse: warehouse.map((warehouse) => warehouse.name).join(),
        });
      } else {
        params.warehouse_id = null;
        setSelectedFilters({
          warehouse: "",
        });
      }

      if (values.brands.length > 0) {
        params.brands = values.brands.map((b) => b.brand_id).join();
        const brand = brands.filter((brand) =>
          values.brands.find((b) => b.brand_id == brand.brand_id.toString())
        );

        setSelectedFilters({
          brands: brand.map((brand) => brand.name).join(),
        });
      } else {
        params.brands = null;
        setSelectedFilters({
          brands: "",
        });
      }

      if (values.models) {
        params.models = values.models.tire_model_id;
        const model = tireModels.find(
          (model) => model.tire_model_id == values.models.tire_model_id
        );
        setSelectedFilters({ models: model.name });
      }

      if (values.tire_model_variations.length > 0) {
        params.tire_model_variations = values.tire_model_variations.join();
        const variations = tireModelVariations.filter((variation) =>
          values.tire_model_variations.includes(
            variation.tire_model_variation_id.toString()
          )
        );
        setSelectedFilters({
          variations: variations
            .map(
              (variation) =>
                `${variation.tire_size.size} ${variation.number_layers}`
            )
            .join(),
        });
      } else {
        params.tire_model_variations = null;
        setSelectedFilters({
          variations: "",
        });
      }

      if (values.revitalized_brands) {
        params.revitalized_brands = values.revitalized_brands;
        const revitalizedBrand = revitalizedBrands.find(
          (revitalizedBrand) =>
            revitalizedBrand.brand_id == values.revitalized_brands
        );
        setSelectedFilters({ revitalized_brands: revitalizedBrand.name });
      }

      if (values.revitalized_models) {
        params.revitalized_models = values.revitalized_models;
        const revitalizedModel = revitalizedModels.find(
          (revitalizedModel) =>
            revitalizedModel.revitalized_tire_model_id ==
            values.revitalized_models
        );
        setSelectedFilters({ revitalized_models: revitalizedModel.name });
      }

      if (values.tire_size) {
        params.tire_size = values.tire_size;
      }

      await setWarehouseFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
    };

    setWarehouseFilters(filter);
    setSelectedFilters(selectedFilters);

    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      conditions={conditions}
      applications={applications}
      brands={brands}
      providers={providers}
      warehouses={warehouses}
      revitalizedBrands={revitalizedBrands}
      tireModels={tireModels}
      tireModelVariations={tireModelVariations}
      revitalizedModels={revitalizedModels}
      tireSizes={tireSizes}
      setCompanies={setCompanies}
      setProviders={setProviders}
      setWarehouses={setWarehouses}
      setSubsidiaries={setSubsidiaries}
      setTireModels={setTireModels}
      setTireModelVariations={setTireModelVariations}
      setRevitalizedModels={setRevitalizedModels}
      t={t}
    />
  );
}

FilterWarehouseForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.Warehouse.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setWarehouseFilters: actionSetReportWarehouseFilters(dispatch),
  clearWarehouseFilters: actionClearReportWarehouseFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedReportWarehouseFilters(dispatch),

  setNotification: actionSetNotification(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterWarehouseForm);
