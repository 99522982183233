export default {
  condition: "Condition",
  provider: "Provider",
  model: "Model",
  size_no_layers: "Size / No. of layers",
  permits: {
    show: {
      tire_cycle_history: "No permissions to view tire cycle histories",
    },
  },
};
