import React from "react";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";

function Page({ type, value, handleChange, ...rest }) {
  return (
    <TextField {...rest} value={value} type={type} onChange={handleChange} />
  );
}

Page.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Page;
