import moment from "moment-timezone";
import { getTimeZone } from "utils/time";
import { setTranslations, setDefaultLanguage } from "react-multi-lang";
import "moment/locale/es";

import es from "resources/lang/es";
import en from "resources/lang/en";

export function setLanguage(language = "es") {
  setTranslations({ es, en });
  setDefaultLanguage(language);

  moment.locale(language);
  const timeZone = getTimeZone();
  moment.tz.setDefault(timeZone);
  if (timeZone.includes("Mexico_City") && moment().isDST()) {
    moment.tz.setDefault("GMT -0600");
  }
}

export const languages = { es: "Spanish", en: "English" };
