import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDetailsRetirementCause } from "store/actions/reports/pile/getDetailsRetirementCauses";
import { actionClearDetailsRetirementCauses } from "store/actions/reports/pile/clearDetailsRetirementCauses";

import Page from "./page";

export function CauseDialog({ open, params, ...rest }) {
  const t = useLang();

  const {
    tires,
    setNotification,
    getPileRetirementCauses,
    clearPileRetirementCauses,
  } = rest;
  useEffect(() => {
    async function fetchData() {
      try {
        await getPileRetirementCauses(params);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }

    return () => {
      clearPileRetirementCauses();
    };
  }, [open]);

  return (
    <Page
      {...rest}
      t={t}
      open={open}
      tires={getSortedData(tires)}
      isInApplication={"tire_application_id" in params}
    />
  );
}

CauseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tires: state.Reports.Pile.causes,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getPileRetirementCauses: actionGetDetailsRetirementCause(dispatch),
  clearPileRetirementCauses: actionClearDetailsRetirementCauses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CauseDialog);

function getSortedData(data) {
  return data.sort((a, b) => b["utils_mm"] - a["utils_mm"]);
}
