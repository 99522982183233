import { combineReducers } from "redux";

import User from "./user";
import Policy from "./policy";
import depthTolerancePolicy from "./depthTolerancePolicy";
import Company from "./company";
import Subsidiary from "./subsidiary";

export default combineReducers({
  User,
  Policy,
  Company,
  depthTolerancePolicy,
  Subsidiary,
});
