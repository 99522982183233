import {
  GET_CHECKLISTS,
  CLEAR_CHECKLISTS,
  SET_CHECKLIST_FILTERS,
  CLEAR_CHECKLIST_FILTERS,
  GET_CHECKLIST,
  CLEAR_CHECKLIST,
} from "store/actions";

const initialState = {
  checklist: {},
  checklists: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_checklist: {
    page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHECKLISTS:
      return {
        ...state,
        checklists: action.payload,
      };

    case CLEAR_CHECKLISTS:
      return {
        ...state,
        checklists: { ...initialState.checklists },
      };

    case SET_CHECKLIST_FILTERS:
      const filter = {
        ...state.filter_checklist,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_checklist: { ...filter },
      };

    case CLEAR_CHECKLIST_FILTERS:
      return {
        ...state,
        filter_checklist: { search: state.filter_checklist.search },
      };

    case GET_CHECKLIST:
      return {
        ...state,
        checklist: action.payload,
      };

    case CLEAR_CHECKLIST:
      return {
        ...state,
        checklist: { ...initialState.checklist },
      };

    default:
      return state;
  }
}
