export default {
  labels: {
    status: "Estatus",
  },
  disabled_provider: "Proveedor deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} proveedores",
  },
  messages: {
    enable: "¿Estas seguro de habilitar el proveedor?",
    disable: "¿Estas seguro de deshabilitar el proveedor?",
  },
};
