import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  values,
  providers,
  warehouses,
  vehicles,
  onClose,
  isSubmitting,
  isValid,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.field)}
      >
        {t("_labels.date.range")}
      </Typography>
      <Field
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.from")}
        className={classnames(css.medium_field)}
        required={!!values.date_to}
      />

      <Field
        name="date_to"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.to")}
        className={classnames(css.medium_field)}
        required={!!values.date_from}
      />

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="providers"
        component={SelectField}
        label={t("_labels.provider")}
        className={classnames(css.medium_field)}
      >
        {providers.map((provider, index) => {
          return (
            <MenuItem
              key={index}
              value={provider.provider_id.toString()}
              disabled={!provider.status}
            >
              {provider.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="warehouses"
        component={SelectField}
        label={t("_labels.warehouse.singular")}
        className={classnames(css.medium_field)}
      >
        {warehouses.map((warehouse, index) => {
          return (
            <MenuItem
              key={index}
              value={warehouse.warehouse_id.toString()}
              disabled={!warehouse.status}
            >
              {warehouse.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="vehicles"
        component={SelectField}
        label={t("_labels.vehicle.singular")}
        className={classnames(css.medium_field)}
      >
        {vehicles.map((vehicle, index) => {
          return (
            <MenuItem
              key={index}
              value={vehicle.vehicle_id.toString()}
              disabled={!vehicle.status}
            >
              {vehicle.economic_number}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="tire_condition_id"
        component={SelectField}
        label={t("_labels.condition")}
        className={classnames(css.medium_field)}
      >
        <MenuItem value="ORIGINAL_NEW">
          {t("_labels.tire_condition.options.original_new")}
        </MenuItem>
        <MenuItem value="ORIGINAL_USED">
          {t("_labels.tire_condition.options.original_used")}
        </MenuItem>
        <MenuItem value="RETREAD_NEW">
          {t("_labels.tire_condition.options.retread_new")}
        </MenuItem>
        <MenuItem value="RETREAD_USED">
          {t("_labels.tire_condition.options.retread_used")}
        </MenuItem>
      </Field>

      <Field
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="movement"
        component={SelectField}
        label={t("_labels.location.label")}
        className={classnames(css.field)}
      >
        <MenuItem value="WAREHOUSE">
          {t("_labels.location.options.warehouse")}
        </MenuItem>
        <MenuItem value="MOUNT">{t("_labels.location.options.mount")}</MenuItem>
        <MenuItem value="REVITALIZATION">
          {t("_labels.location.options.revitalization")}
        </MenuItem>
        <MenuItem value="REPAIR">
          {t("_labels.location.options.repair")}
        </MenuItem>
        <MenuItem value="PILE">{t("_labels.location.options.pile")}</MenuItem>
      </Field>

      <Field
        variant="filled"
        name="number_cycle"
        type="number"
        component={TextField}
        label={t("_labels.number_cycle")}
        className={classnames(css.field)}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
