import { LANGUAGE } from "store/actions";

export const actionSetLanguage = (dispatch) => (language) => {
  dispatch({
    type: LANGUAGE,
    payload: language,
  });

  return language;
};
