import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import es from "apexcharts/dist/locales/es.json";
import en from "apexcharts/dist/locales/en.json";
import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTpmsHistory } from "store/actions/tpms/vehicle/getVehicleTpmsHistory";
import { actionClearVehicleTpmsHistory } from "store/actions/tpms/vehicle/clearVehicleTpmsHistory";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";

import Page from "./page";

const languages = {
  es: es,
  en: en,
};
export function TpmsHistory({ ...rest }) {
  const t = useLang();
  const {
    language,
    setNotification,
    vehicleTpmsHistory,
    getVehicleTpmsHistory,
    clearVehicleTpmsHistory,
    getVehicle,
  } = rest;
  const { id } = useParams();

  const [vehicle, setVehicle] = useState({ economic_number: "" });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicle(id, false);
        setVehicle(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleTpmsHistory({}, id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearVehicleTpmsHistory();
    };
  }, []);

  return (
    <Page
      t={t}
      data={getData(vehicleTpmsHistory, language, t)}
      vehicle={vehicle}
    />
  );
}

TpmsHistory.propTypes = {};

const mapStateToProps = (state) => ({
  language: state.General.language,
  vehicleTpmsHistory: state.Tpms.vehicle_tpms,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleTpmsHistory: actionGetVehicleTpmsHistory(dispatch),
  clearVehicleTpmsHistory: actionClearVehicleTpmsHistory(dispatch),
  getVehicle: actionGetVehicle(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TpmsHistory);

function getData(data, language, t) {
  const pressureSeries = [];
  const temperatureSeries = [];
  const customPalette = [
    "#008FFB",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    "#2B908F",
    "#F9A3A4",
    "#A5978B",
    "#8D5B4C",
    "#F86624",
    "#00B1F2",
    "#D4526E",
  ];

  data.forEach((tire) => {
    const reviews = tire.review;
    let pressureData = [];
    let temperatureData = [];

    reviews.forEach((review) => {
      pressureData.push({
        x: review.date,
        y: review.pressure,
      });

      temperatureData.push({
        x: review.date,
        y: review.temperature,
      });
    });

    pressureSeries.push({
      name: `${tire.code} - Pos: ${tire.real_position}`,
      data: pressureData,
    });

    temperatureSeries.push({
      name: `${tire.code} - Pos: ${tire.real_position}`,
      data: temperatureData,
    });
  });

  const optionsPresion = {
    chart: {
      type: "line",
      height: 400,
      width: "100%",
      animations: {
        enabled: false,
      },
      locales: [languages[language]],
      defaultLocale: language,
      toolbar: {
        export: {
          csv: {
            headerCategory: t("_labels.date.label"),
            datetimeUTC: false,
            dateFormatter: (timestamp) =>
              moment(timestamp).format("YYYY-MM-DD HH:mm:ss.SSS"),
          },
        },
      },
    },
    colors: customPalette,
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "ddd dd",
          hour: "HH:mm:ss",
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val, ...rest) {
          if (val != undefined) {
            return val.toFixed(2) + " psi";
          }
          const [data] = rest;
          return (
            data.series[data.seriesIndex][data.dataPointIndex]?.toFixed(2) +
            " psi"
          );
        },
      },
      x: {
        format: "yyyy-MM-dd HH:mm:ss.fff",
      },
      shared: true,
    },
    markers: {
      strokeWidth: 1,
      size: 5,
    },
  };

  const optionsTemperatura = {
    chart: {
      type: "line",
      height: 400,
      width: "100%",
      animations: {
        enabled: false,
      },
      locales: [languages[language]],
      defaultLocale: language,
      toolbar: {
        export: {
          csv: {
            headerCategory: t("_labels.date.label"),
            datetimeUTC: false,
            dateFormatter: (timestamp) =>
              moment(timestamp).format("YYYY-MM-DD HH:mm:ss.SSS"),
          },
        },
      },
    },
    colors: customPalette,
    xaxis: {
      type: "datetime",
      // labels: {
      //   format: 'ddd dd',
      // },
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "ddd dd",
          hour: "HH:mm:ss",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val, ...rest) {
          if (val != undefined) {
            return val.toFixed(2) + " °C";
          }
          const [data] = rest;
          return (
            data.series[data.seriesIndex][data.dataPointIndex]?.toFixed(2) +
            " °C"
          );
        },
      },
      x: {
        format: "yyyy-MM-dd HH:mm:ss.fff",
      },
      shared: true,
    },
    markers: {
      strokeWidth: 1,
      size: 5,
    },
  };

  return {
    optionsPressure: optionsPresion,
    seriesPressure: pressureSeries,
    optionsTemperature: optionsTemperatura,
    seriesTemperature: temperatureSeries,
  };
}
