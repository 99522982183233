import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddTireReview } from "store/actions/tire/review/addTireReview";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionUpdateTireReview } from "store/actions/tire/review/updateTireReview";

import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function TireReviewForm({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    open,
    onClose,
    vehicleTire,
    vehicleReview,
    lastTireReview,
    setNotification,
    addTireReview,
    updateTireReview,
    getVehicleAxles,
  } = rest;

  const {
    initialValues,
    initialErrors,
    setFormikErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      pressure: "",
      comment: "",
      lines: [
        {
          depth_external: "",
          depth_middle: "",
          depth_internal: "",
        },
      ],
    },
  });

  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    if (open) {
      setIsUpdate(
        lastTireReview.vehicle_review_id === vehicleReview.vehicle_review_id
      );
    }
  }, [open]);
  useEffect(() => {
    if (open) {
      if (isUpdate) {
        setInitialValues({
          pressure: Number(lastTireReview.pressure),
          comment: lastTireReview.comment || "",
          lines: lastTireReview.tire_review_depth_line.tire_review_depth.map(
            (review) => ({
              depth_external: review.depth_external,
              depth_middle: review.depth_middle,
              depth_internal: review.depth_internal,
            })
          ),
        });
      } else {
        setInitialValues({
          pressure: getRecommendedPressure(vehicleTire),
          comment: "",
          lines: [
            {
              depth_external: "",
              depth_middle: "",
              depth_internal: "",
            },
          ],
        });
      }
    } else {
      resetFormik();
    }
  }, [open, isUpdate]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        pressure: values.pressure,
        depth: [...values.lines],
      };

      if (values.comment) {
        fields.comment = values.comment;
      }

      const movementTireId = vehicleTire.cycle.movement_tire.movement_tire_id;

      if (isUpdate) {
        await updateTireReview(
          movementTireId,
          lastTireReview.tire_review_id,
          fields
        );
        setNotification({
          message: t("_messages.updated.tire_review"),
        });
      } else {
        await addTireReview(movementTireId, fields);
        setNotification({
          message: t("_messages.created.tire_review"),
        });
      }

      onClose();
      getVehicleAxles(vehicleId);
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      vehicleTire={vehicleTire}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

TireReviewForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

TireReviewForm.defaultProps = {};

const mapStateToProps = (state) => {
  const vehicleTire = state.Vehicle.Tire.vehicle_tire;
  const lastTireReview =
    vehicleTire.cycle.movement_tire.tire_review[
      vehicleTire.cycle.movement_tire.tire_review.length - 1
    ] ?? undefined;
  return {
    vehicleTire,
    vehicleReview: state.Vehicle.Review.vehicle_review,
    lastTireReview,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  addTireReview: actionAddTireReview(dispatch),
  updateTireReview: actionUpdateTireReview(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireReviewForm);

function getRecommendedPressure(vehicleTire) {
  const axlePosition =
    vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle_type_axle_tire
      .vehicle_type_axle.axle_type;
  if (
    vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle
      ?.vehicle_pressure_policy &&
    vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle.vehicle_pressure_policy.find(
      (policy) => policy.axle_type === axlePosition
    )
  ) {
    const pressure =
      vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle.vehicle_pressure_policy.find(
        (policy) => policy.axle_type === axlePosition
      ).recommended_pressure;
    return pressure;
  }

  if (vehicleTire.cycle.variation.tire_model_variation_policy) {
    return vehicleTire.cycle.variation.tire_model_variation_policy
      .recommended_pressure;
  }

  return vehicleTire.cycle.variation.recommended_pressure;
}
