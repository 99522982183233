import { GET_WEAR } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetWear = (dispatch) => async (wearId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `wear/${wearId}`,
    });

    dispatch({
      type: GET_WEAR,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
