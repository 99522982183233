export default {
  subsidiary: "Sucursal",
  link_subsidiary: "Vincular sucursal",
  subsidiary_name: "Nombre de la sucursal",
  unassigned_subsidiary: "Sucursal no asignada",
  permits: {
    delete_link: {
      vehicle_with_subsidiary:
        "Sin permisos para desvincular sucursales de vehículos",
    },
    show: {
      vehicle_with_subsidiary:
        "Sin permisos para ver sucursales vinculadas a vehículos",
    },
    list: {
      subsidiary: "Sin permisos para listar sucursales",
    },
  },
  messages: {
    unlink_subsidiary_confirmation: "¿Estas seguro de desvincular la sucursal?",
    vehicle_with_subsidiary_link_deleted:
      "Listo, la sucursal fue desvinculada del vehículo con éxito",
  },
  form: {
    select_a_subsidiary: "Selecciona una sucursal",
  },
};
