import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCompanies } from "store/actions/company/getCompanies";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";

import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const {
    values,
    setNotification,
    getCompanies,
    setCompanies,
    getSubsidiaries,
    setSubsidiaries,
    getWarehouses,
    setWarehouses,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getCompanies(
          {
            scope: "company_id,name,status",
            corporates: values.corporate_id,
          },
          false
        );

        setCompanies(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.corporate_id) {
      fetchData();
    }
  }, [values.corporate_id]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            scope: "subsidiary_id,name,status",
            companies: values.companies.join(),
          },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.companies.length > 0) {
      fetchData();
    }
  }, [values.companies]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getWarehouses(
          {
            scope: "warehouse_id,name,status,subsidiary.name",
            subsidiaries: values.subsidiaries.join(),
          },
          false
        );
        setWarehouses(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.subsidiaries.length > 0) {
      fetchData();
    }
  }, [values.subsidiaries]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  setCompanies: PropTypes.func.isRequired,
  setSubsidiaries: PropTypes.func.isRequired,
  setWarehouses: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getCompanies: actionGetCompanies(dispatch),
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
