export default {
  wear_disabled: "Desgaste deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} desgaste",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar el desgaste?",
    enable: "¿Estas seguro de habilitar el desgaste?",
  },
};
