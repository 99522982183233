export default {
  title: "Nuevo modelo revitalizado",
  update: "Actualizar modelo revitalizado",
  messages: {
    revitalized_tire_model_created:
      "Listo, se creo un nuevo modelo revitalizado con éxito",
    revitalized_tire_model_updated:
      "Listo, se actualizó el modelo revitalizado con éxito",
    revitalized_tire_model_deleted:
      "Listo, se eliminó el modelo revitalizado con éxito",
    delete: "¿Estas seguro de eliminar el modelo revitalizado?",
  },
  form: {
    labels: {
      brand_field: "Selecciona una marca",
    },
    permits: {
      update: "Sin permisos para actualizar modelos revitalizados",
      delete: "Sin permisos para eliminar modelos revitalizados",
    },
  },
};
