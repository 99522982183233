import React from "react";
import PropTypes from "prop-types";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";

import ContainerTemplate from "components/templates/general/Container";

function Page({
  title,
  optionsAppBar,
  className,
  tabIndex,
  tabLinks,
  onChangeTab,
  children,
}) {
  return (
    <ContainerTemplate
      title={title}
      options={optionsAppBar}
      className={className}
      tabs={
        <Tabs
          value={tabIndex}
          onChange={onChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabLinks.map((item, index) => (
            <Tab
              key={index}
              disabled={item.disabled}
              style={{ pointerEvents: "auto" }}
              label={
                item.tooltip ? (
                  <Tooltip title={item.tooltip}>
                    <span>{item.label}</span>
                  </Tooltip>
                ) : (
                  item.label
                )
              }
            />
          ))}
        </Tabs>
      }
    >
      {children}
    </ContainerTemplate>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  tabLinks: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      tooltip: PropTypes.string,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChangeTab: PropTypes.func.isRequired,
  optionsAppBar: PropTypes.node,
};
export default Page;
