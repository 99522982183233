import React from "react";
import PropTypes from "prop-types";

import Page from "./page";

function List({ headers, ...props }) {
  const propertys = headers.map((header) => header.id);

  return <Page {...props} headers={headers} propertys={propertys} />;
}

List.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default List;
