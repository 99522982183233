import { GET_VEHICLES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehicles =
  (dispatch) =>
  async (
    {
      search,
      status,
      page,
      scope,
      order = "DESC",
      subsidiaries,
      vehicle_type_id: vehicleTypeId,
      vehicle_brand_id: vehicleBrandId,
      type_of_route: typeOfRoute,
      driver_id: drivers,
      with_link_driver: withLinkDriver,
      has_odometer: hasOdometer,
      enrollment,
      vehicle_engine_brand_id: vehicleEngineBrandId,
      engine_transmission_brand_id: engineTransmissionBrandId,
      cylinder_capacity: cylinderCapacity,
      transmission_model: transmissionModel,
      transmission_speeds: transmissionSpeeds,
      vehicle_model: vehicleModel,
      vehicle_year: vehicleYear,
      date_from: dateFrom,
      date_to: dateTo,
      divisions,
      with_alerts: withAlerts,
      alert_code: alertCode,
      alert_tire_code: alertTireCode,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `vehicle`,
        params: {
          search,
          status,
          page,
          scope,
          order,
          subsidiaries,
          vehicle_type_id: vehicleTypeId,
          vehicle_brand_id: vehicleBrandId,
          type_of_route: typeOfRoute,
          drivers,
          has_odometer: hasOdometer,
          with_link_driver: withLinkDriver,
          enrollment,
          vehicle_engine_brand_id: vehicleEngineBrandId,
          engine_transmission_brand_id: engineTransmissionBrandId,
          cylinder_capacity: cylinderCapacity,
          transmission_model: transmissionModel,
          transmission_speeds: transmissionSpeeds,
          vehicle_model: vehicleModel,
          vehicle_year: vehicleYear,
          date_from: dateFrom,
          date_to: dateTo,
          divisions,
          with_alerts: withAlerts,
          alert_code: alertCode,
          alert_tire_code: alertTireCode,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLES,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
