import React from "react";
import PropTypes from "prop-types";

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";

import classnames from "classnames";

import css from "./index.module.scss";
import Chart from "./Chart";
import "chartjs-adapter-moment";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  subsidiaries,
  originalVsRenewedData,
  ahorrosData,
  yearlyAhorrosData,
}) {
  return (
    <>
      {subsidiaries.map((subsidiary) => {
        const savingsTableData = getSavingsTableData(
          yearlyAhorrosData,
          subsidiary
        );
        return (
          <React.Fragment key={subsidiary}>
            <div className="card-header text-center bg-dark text-white">
              <div
                className="h5 m-0 d-flex justify-content-center align-items-center"
                style={{ gap: "6px" }}
              >
                {subsidiary}
              </div>
            </div>

            <div className={classnames(css.data_container)}>
              {ahorrosData[subsidiary] &&
                Object.keys(ahorrosData[subsidiary]).length > 0 && (
                  <div
                    className="mt-3 card"
                    style={{ width: "100%", height: "400px" }}
                  >
                    <div className="card-header sMarcoInfoFondoAzul">
                      {t("_labels.savings")}
                    </div>
                    <div className="card-body">
                      <Chart
                        options={
                          ahorrosData[subsidiary].datasets[0].data.length >= 8
                            ? getDefaultQuarterOptions("")
                            : getDefaultOptions("")
                        }
                        data={ahorrosData[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className={classnames(css.data_container)}>
              {yearlyAhorrosData[subsidiary] &&
                Object.keys(yearlyAhorrosData[subsidiary]).length > 0 && (
                  <div
                    className="mt-3 card"
                    style={{ width: "100%", height: "400px" }}
                  >
                    <div className="card-header sMarcoInfoFondoAzul">
                      {t("_titles.graphs.yearly_savings")}
                    </div>
                    <div className="card-body">
                      <Chart
                        options={getYearlyOptions()}
                        data={yearlyAhorrosData[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {savingsTableData.headers.map((year) => {
                      return (
                        <TableCell key={year} align="right">
                          {year}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {savingsTableData.rows.map((row) => {
                    return (
                      <TableRow
                        key={row[0]}
                        style={{
                          height: "60px",
                        }}
                      >
                        <TableCell
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          {row[0]}
                        </TableCell>
                        {row.slice(1, row.length).map((value, index) => {
                          return (
                            <TableCell
                              key={index}
                              align="right"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              ${formatter.format(value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <div className={classnames(css.data_container)}>
              {originalVsRenewedData[subsidiary] &&
                Object.keys(originalVsRenewedData[subsidiary]).length > 0 && (
                  <div
                    className="mt-3 card"
                    style={{ width: "100%", height: "400px" }}
                  >
                    <div className="card-header sMarcoInfoFondoAzul">
                      {t("_titles.graphs.original_vs_renewed")}
                    </div>
                    <div className="card-body">
                      <Chart
                        options={
                          originalVsRenewedData[subsidiary].datasets[0].data
                            .length >= 8
                            ? getDefaultQuarterOptions(t("_general.tires"))
                            : getDefaultOptions(t("_general.tires"))
                        }
                        data={originalVsRenewedData[subsidiary]}
                      />
                    </div>
                  </div>
                )}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  originalVsRenewedData: PropTypes.object.isRequired,
  ahorrosData: PropTypes.object.isRequired,
  yearlyAhorrosData: PropTypes.object.isRequired,
};

export default Page;

function getSavingsTableData(ahorrosData, subsidiary) {
  const data = {};
  const headers = [];
  const rows = [];
  for (const dataset of ahorrosData?.[subsidiary]?.datasets.slice(0, 2) ?? []) {
    data[dataset.label] = {};
    for (const dataPoint of dataset.data) {
      const year = dataPoint.fecha.split("-")[0];
      data[dataset.label][year] = dataPoint.total;
      if (!headers.includes(year)) {
        headers.push(year);
      }
    }
  }
  headers.push("Total");
  for (const label of Object.keys(data)) {
    const row = [label];
    let total = 0;
    for (const year of headers) {
      if (year !== "Total") {
        row.push(data[label]?.[year] ?? 0);
        total += data[label]?.[year] ?? 0;
      }
    }
    row.push(total);
    rows.push(row);
  }
  let globalTotal = 0;
  const row = ["Total"];
  for (const year of headers) {
    let total = 0;
    for (const label of Object.keys(data)) {
      total += data[label]?.[year] ?? 0;
    }
    globalTotal += total;
    if (year !== "Total") {
      row.push(total);
    }
  }
  row.push(globalTotal);
  rows.push(row);
  return {
    headers,
    rows,
    total: globalTotal,
  };
}

function getYearlyOptions() {
  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "x",
    },
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: true,
        position: "left",
        visible: "auto",
        title: {
          display: false,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY",
          tooltipFormat: "YYYY",
          unit: "year",
          displayFormats: {
            month: "YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        onClick: null,
        position: "top",
      },
      tooltip: {
        callbacks: {
          footer: (tooltipItems) => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return "Total: " + `$${formatter.format(sum)}`;
          },
          label: (context) => {
            let label = context.dataset.label || "";
            return `${label}: $${formatter.format(context.parsed.y)}`;
          },
        },
      },
    },
  };
}

function getDefaultOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          displayFormats: {
            month: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}

function getDefaultQuarterOptions(title) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    locale: "en",
    scales: {
      y: {
        min: 0,
        stacked: false,
        position: "left",
        visible: "auto",
        title: {
          display: true,
          text: title,
        },
      },
      x: {
        stacked: true,
        type: "time",
        time: {
          parser: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "quarter",
          displayFormats: {
            quarter: "MMM YYYY",
          },
        },
      },
    },
    parsing: {
      xAxisKey: "fecha",
      yAxisKey: "total",
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
}
