import React from "react";
import PropTypes from "prop-types";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "../index.css";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
function Page({ data, t }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <table className="table table-hover text-center sFS12">
            <thead className="sbgazul">
              <tr>
                <td>{t("_labels.condition").toUpperCase()}</td>
                <td>{t("_labels.application").toUpperCase()}</td>
                <td>{t("_labels.brand").toUpperCase()}</td>
                <td>{t("_labels.model").toUpperCase()}</td>
                <td>{t("_labels.size").toUpperCase()}</td>
                <td>
                  {t(
                    "_labels.tire_stats_report.headers.number_of_tires"
                  ).toUpperCase()}
                </td>
                <td>{t("_labels.costo").toUpperCase()}</td>
                <td>{t("_labels.sum_of_mm").toUpperCase()}</td>
                <td>{t("_labels.wear.singular").toUpperCase()}</td>
                <td>{t("_labels.average_mm").toUpperCase()}:</td>
                <td className="text-right">
                  {t("_labels.cost_mm").toUpperCase()}
                </td>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.condition}</td>
                  <td>{row.application}</td>
                  <td>{row.brand}</td>
                  <td>{row.model}</td>
                  <td>{row.size}</td>
                  <td>{formatter.format(row.count)}</td>
                  <td>${decimalFormatter.format(row.cost)}</td>
                  <td>{formatter.format(row.depth)}</td>
                  <td>{decimalFormatter.format(row.wear)}</td>
                  <td>{decimalFormatter.format(row.depth / row.count)}</td>
                  <td className="text-right">
                    ${decimalFormatter.format(row.depth_cost)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right sFS12 pdb10 pdt10">
          {t("_labels.copyright")}
        </div>
      </div>
    </>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default Page;
