import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import {
  CREATE_TIRE_MODEL,
  UPDATE_TIRE_MODEL,
  DELETE_TIRE_MODEL,
} from "store/actions/account/permits";
import { actionGetTireModels } from "store/actions/tire/model/getTireModels";
import { actionClearTireModels } from "store/actions/tire/model/clearTireModels";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetTireModelFilters } from "store/actions/tire/model/setTireModelFilters";
import { actionClearTireModelFilters } from "store/actions/tire/model/clearTireModelFilters";
import { actionAddTireModel } from "store/actions/tire/model/addTireModel";
import { actionUpdateTireModel } from "store/actions/tire/model/updateTireModel";
import { actionDeleteTireModel } from "store/actions/tire/model/deleteTireModel";
import { actionGetTireModel } from "store/actions/tire/model/getTireModel";
import { actionSetTireModelStatus } from "store/actions/tire/model/setTireModelStatus";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionSetTireModelApprovalStatus } from "store/actions/tire/model/setTireModelApprovalStatus";
import Page from "./page";

export const NAMESPACE = "pages/tire/model/HomeTireModel";
export function HomeTireModel({ ...rest }) {
  const t = useLang();
  const {
    permits,
    filter,
    tireModel,
    tireModels,
    getTireModels,
    clearTireModels,
    setNotification,
    setTireModelFilters,
    clearTireModelFilters,
    addTireModel,
    updateTireModel,
    deleteTireModel,
    getTireModel,
    setTireModelStatus,
    setTireModelApprovalStatus,
    getBrands,
    getTireApplicationTypes,
    getTireSizes,
  } = rest;
  const [brands, setTireBrands] = useState([]);
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        let dataSize = [];
        let dataApplication = [];
        let dataBrad = [];

        dataSize = await getTireSizes(
          {
            scope: "tire_size_id,size,status,approved",
          },
          false
        );

        setTireSizes(dataSize.sort(alphabeticalSort("size")));

        dataApplication = await getTireApplicationTypes(
          {
            scope: "tire_application_id,status",
          },
          false
        );

        setTireApplicationTypes(
          dataApplication.sort(alphabeticalSort("tire_application_id"))
        );

        dataBrad = await getBrands(
          { scope: "brand_id,name,status,approved", brandType: "TIRE" },
          false
        );
        setTireBrands(dataBrad.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  function copyToClipboard(id) {
    navigator.clipboard.writeText(id);
    setNotification({ message: "Copiado en el portapapeles" });
  }
  return (
    <Page
      brands={brands}
      tireApplicationTypes={tireApplicationTypes}
      tireSizes={tireSizes}
      t={t}
      tireModel={tireModel}
      tireModels={tireModels}
      filter={filter}
      getTireModel={getTireModel}
      getTireModels={getTireModels}
      addTireModel={addTireModel}
      updateTireModel={updateTireModel}
      deleteTireModel={deleteTireModel}
      setTireModelFilters={setTireModelFilters}
      setTireModelStatus={setTireModelStatus}
      setTireModelApprovalStatus={setTireModelApprovalStatus}
      clearTireModelFilters={clearTireModelFilters}
      clearTireModels={clearTireModels}
      createTireModelPermit={permits.includes(CREATE_TIRE_MODEL)}
      updateTireModelPermit={permits.includes(UPDATE_TIRE_MODEL)}
      deleteTireModelPermit={permits.includes(DELETE_TIRE_MODEL)}
      copyToClipboard={copyToClipboard}
    />
  );
}

HomeTireModel.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  tireModels: state.Tire.Model.Model.tire_models,
  filter: state.Tire.Model.Model.filter_tire_model,
  tireModel: state.Tire.Model.Model.model,
});
const mapDispatchToProps = (dispatch) => ({
  getTireModel: actionGetTireModel(dispatch),
  getTireModels: actionGetTireModels(dispatch),
  clearTireModels: actionClearTireModels(dispatch),
  setNotification: actionSetNotification(dispatch),
  setTireModelFilters: actionSetTireModelFilters(dispatch),
  clearTireModelFilters: actionClearTireModelFilters(dispatch),
  setTireModelStatus: actionSetTireModelStatus(dispatch),
  setTireModelApprovalStatus: actionSetTireModelApprovalStatus(dispatch),
  addTireModel: actionAddTireModel(dispatch),
  updateTireModel: actionUpdateTireModel(dispatch),
  deleteTireModel: actionDeleteTireModel(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeTireModel);
