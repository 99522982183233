import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import ContainerTemplate from "components/templates/general/Container";
import SearchInput from "components/molecules/general/SearchInput";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import FilterBestPerformanceForm from "components/organisms/report/FilterBestPerformanceForm";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({
  t,
  filter,
  selectedFilter,
  search,
  openFilter,
  originalData,
  revitalizedData,
  originalTierList,
  revitalizedTierList,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
  handleSearch,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.best_performance_report")}
      options={
        <>
          <Button
            classNameButton={classnames("bw")}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.size")}:
                      </td>
                      <td>{selectedFilter.sizes}</td>
                    </tr>
                  )}
                  {filter.tire_application && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.application")}:
                      </td>
                      <td>{selectedFilter.application}</td>
                    </tr>
                  )}
                  {filter.tire_condition && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.condition")}:
                      </td>
                      <td>{selectedFilter.condition}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.movement && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.location.label")}:
                      </td>
                      <td>{selectedFilter.movement}</td>
                    </tr>
                  )}
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                  {filter.price && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.price")}:
                      </td>
                      <td>{formatter.format(selectedFilter.price)}</td>
                    </tr>
                  )}
                  {filter.helmet_value && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.helmet_value")}:
                      </td>
                      <td>{formatter.format(selectedFilter.helmet_value)}</td>
                    </tr>
                  )}
                  {"min_travel" in filter && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.min_km")}:
                      </td>
                      <td>{formatter.format(selectedFilter.min_travel)}</td>
                    </tr>
                  )}
                  {"max_travel" in filter && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.max_km")}:
                      </td>
                      <td>{formatter.format(selectedFilter.max_travel)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">{t("_labels.best_tire")}</h1>
            <h5 className="sinmp">{t("_labels.best_performance_desc")}</h5>
            {/* <small className="sinmp" style={{ color: "lightcoral" }}>
              {t("_labels.best_performance_desc_small")}
            </small> */}
            {/* {!filter.movement && (
              <div>
                <small className="sinmp" style={{ color: "lightcoral" }}>
                  {t("_labels.location.label")}:{" "}
                  {t("_labels.location.options.mount")} {t("_general.text.and")}{" "}
                  {t("_labels.location.options.warehouse")}
                </small>
              </div>
            )} */}
            <hr />
          </div>
        </div>
        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid px-3">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    {Object.keys(originalData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.tire_cost")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(originalData).map((corporate) => (
                              <Fragment key={corporate[0]}>
                                <thead className="text-center">
                                  <tr
                                    className="corporate"
                                    onClick={() =>
                                      toggleTable(
                                        `${corporate[0]}_companies`,
                                        corporate[0],
                                        "original"
                                      )
                                    }
                                  >
                                    <th className="text-left">
                                      {corporate[0] === "NO CORPORATE"
                                        ? t("_labels.no_corporate")
                                        : corporate[0]}
                                    </th>
                                    <th></th>
                                    <th className="text-right col3"></th>
                                    <th className="text-right col3">
                                      <small>{t("_labels.tire.plural")}</small>
                                      <br />
                                      {formatter.format(
                                        corporate[1]["statistics"]
                                      )}
                                    </th>
                                    <th className="text-right col4">
                                      <small>{t("_labels.actual_cost")}</small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["cpk"]
                                      )}
                                    </th>
                                    <th className="text-right col5">
                                      <small>{t("_labels.proposal")}</small>
                                      <br />$
                                      {costKmFormatter.format(
                                        corporate[1]["potential"]
                                      )}
                                    </th>
                                    <th className="text-right col6">
                                      <small>{t("_labels.real_saving")}</small>
                                      <br />$
                                      {formatter.format(
                                        corporate[1]["km*Real"]
                                      )}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.keys(corporate[1])
                                    .filter(
                                      (index) =>
                                        typeof corporate[1][index] === "object"
                                    )
                                    .map((company) => (
                                      <Fragment key={company}>
                                        <tr
                                          data-type="original"
                                          data-id={`${removeCharacters(
                                            corporate[0]
                                          )}_companies`}
                                          className="company box collapse"
                                          onClick={() =>
                                            toggleTable(
                                              `${corporate[0]}_${company}_subsidiaries`,
                                              company,
                                              "original"
                                            )
                                          }
                                        >
                                          <td className="text-left">
                                            {company}
                                          </td>
                                          <td></td>
                                          <td className="text-right col3">
                                            {percentFormatter.format(
                                              (corporate[1][company][
                                                "statistics"
                                              ] *
                                                100) /
                                                corporate[1]["statistics"]
                                            )}
                                            %
                                          </td>
                                          <td className="text-right col3">
                                            {formatter.format(
                                              corporate[1][company][
                                                "statistics"
                                              ]
                                            )}
                                          </td>
                                          <td className="text-right col4">
                                            $
                                            {costKmFormatter.format(
                                              corporate[1][company]["cpk"]
                                            )}
                                          </td>
                                          <td className="text-right col5">
                                            $
                                            {costKmFormatter.format(
                                              corporate[1][company]["potential"]
                                            )}
                                          </td>
                                          <td className="text-right col6">
                                            $
                                            {formatter.format(
                                              corporate[1][company]["km*Real"]
                                            )}
                                          </td>
                                        </tr>

                                        {Object.keys(corporate[1][company])
                                          .filter(
                                            (index) =>
                                              typeof corporate[1][company][
                                                index
                                              ] === "object"
                                          )
                                          .map((subsidiary) => (
                                            <Fragment key={subsidiary}>
                                              <tr
                                                data-type="original"
                                                data-id={removeCharacters(
                                                  `${corporate[0]}_${company}_subsidiaries`
                                                )}
                                                className="subsidiary box collapse"
                                                onClick={() =>
                                                  toggleTable(
                                                    `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                    `${company}_${subsidiary}`,
                                                    "original"
                                                  )
                                                }
                                              >
                                                <td className="text-left">
                                                  {subsidiary}
                                                </td>
                                                <td></td>
                                                <td className="text-right col3">
                                                  {percentFormatter.format(
                                                    (corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"] *
                                                      100) /
                                                      corporate[1][company][
                                                        "statistics"
                                                      ]
                                                  )}
                                                  %
                                                </td>
                                                <td className="text-right col3">
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["statistics"]
                                                  )}
                                                </td>
                                                <td className="text-right col4">
                                                  $
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["cpk"]
                                                  )}
                                                </td>
                                                <td className="text-right col5">
                                                  $
                                                  {costKmFormatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["potential"]
                                                  )}
                                                </td>
                                                <td className="text-right col6">
                                                  $
                                                  {formatter.format(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ]["km*Real"]
                                                  )}
                                                </td>
                                              </tr>

                                              {Object.keys(
                                                corporate[1][company][
                                                  subsidiary
                                                ]
                                              )
                                                .filter(
                                                  (index) =>
                                                    typeof corporate[1][
                                                      company
                                                    ][subsidiary][index] ===
                                                    "object"
                                                )
                                                .map((division) => (
                                                  <Fragment key={division}>
                                                    <tr
                                                      data-type="original"
                                                      data-id={removeCharacters(
                                                        `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                      )}
                                                      className="division box collapse"
                                                      onClick={() =>
                                                        toggleTable(
                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                          `${company}_${subsidiary}_${division}`,
                                                          "original"
                                                        )
                                                      }
                                                    >
                                                      <td className="text-left">
                                                        {division ===
                                                        "NO DIVISION"
                                                          ? t(
                                                              "_labels.no_division"
                                                            )
                                                          : division}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3">
                                                        {percentFormatter.format(
                                                          (corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ]["statistics"] *
                                                            100) /
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              "statistics"
                                                            ]
                                                        )}
                                                        %
                                                      </td>
                                                      <td className="text-right col3">
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "statistics"
                                                          ]
                                                        )}
                                                      </td>
                                                      <td className="text-right col4">
                                                        $
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["cpk"]
                                                        )}
                                                      </td>
                                                      <td className="text-right col5">
                                                        $
                                                        {costKmFormatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][
                                                            "potential"
                                                          ]
                                                        )}
                                                      </td>
                                                      <td className="text-right col6">
                                                        $
                                                        {formatter.format(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division]["km*Real"]
                                                        )}
                                                      </td>
                                                    </tr>

                                                    {Object.keys(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ][division]
                                                    )
                                                      .filter(
                                                        (index) =>
                                                          typeof corporate[1][
                                                            company
                                                          ][subsidiary][
                                                            division
                                                          ][index] === "object"
                                                      )
                                                      .map((application) => (
                                                        <Fragment
                                                          key={application}
                                                        >
                                                          <tr
                                                            data-type="original"
                                                            data-id={removeCharacters(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                            )}
                                                            className={`box collapse application`}
                                                            onClick={() =>
                                                              toggleTable(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_sizes`,
                                                                `${company}_${subsidiary}_${division}_${application}`,
                                                                "original"
                                                              )
                                                            }
                                                          >
                                                            <td className="text-left">
                                                              {t(
                                                                `_labels.tire_application.options.${application.toLowerCase()}`
                                                              )}
                                                            </td>
                                                            <td></td>
                                                            <td className="text-right col3">
                                                              {percentFormatter.format(
                                                                (corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  "statistics"
                                                                ] *
                                                                  100) /
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    "statistics"
                                                                  ]
                                                              )}
                                                              %
                                                            </td>
                                                            <td className="text-right col3">
                                                              {formatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  "statistics"
                                                                ]
                                                              )}
                                                            </td>
                                                            <td className="text-right col4">
                                                              $
                                                              {costKmFormatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  "cpk"
                                                                ]
                                                              )}
                                                            </td>
                                                            <td className="text-right col5">
                                                              $
                                                              {costKmFormatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  "potential"
                                                                ]
                                                              )}
                                                            </td>
                                                            <td className="text-right col6">
                                                              $
                                                              {formatter.format(
                                                                corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  "km*Real"
                                                                ]
                                                              )}
                                                            </td>
                                                          </tr>

                                                          {Object.keys(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][application]
                                                          )
                                                            .filter(
                                                              (index) =>
                                                                typeof corporate[1][
                                                                  company
                                                                ][subsidiary][
                                                                  division
                                                                ][application][
                                                                  index
                                                                ] === "object"
                                                            )
                                                            .map((size) => (
                                                              <Fragment
                                                                key={size}
                                                              >
                                                                <tr
                                                                  data-type="original"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_sizes`
                                                                  )}
                                                                  className="size box collapse"
                                                                  onClick={() =>
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_${size}_tires`,
                                                                      `${company}_${subsidiary}_${division}_${application}_${size}`,
                                                                      "original"
                                                                    )
                                                                  }
                                                                >
                                                                  <td className="text-left">
                                                                    {size}
                                                                  </td>
                                                                  <td></td>
                                                                  <td className="text-right col3">
                                                                    {percentFormatter.format(
                                                                      (corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        application
                                                                      ][size][
                                                                        "statistics"
                                                                      ] *
                                                                        100) /
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][
                                                                          "statistics"
                                                                        ]
                                                                    )}
                                                                    %
                                                                  </td>
                                                                  <td className="text-right col3">
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        application
                                                                      ][size][
                                                                        "statistics"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col4">
                                                                    $
                                                                    {costKmFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        application
                                                                      ][size][
                                                                        "cpk"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col5">
                                                                    $
                                                                    {costKmFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        application
                                                                      ][size][
                                                                        "potential"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    $
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        application
                                                                      ][size][
                                                                        "km*Real"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                </tr>

                                                                {Object.values(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ][size][
                                                                    "data"
                                                                  ]
                                                                ).map(
                                                                  (
                                                                    [modelKey],
                                                                    index
                                                                  ) => (
                                                                    <tr
                                                                      data-type="original"
                                                                      data-id={removeCharacters(
                                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_${size}_tires`
                                                                      )}
                                                                      key={
                                                                        index
                                                                      }
                                                                      className="tire box collapse"
                                                                      style={{
                                                                        backgroundColor:
                                                                          "white",
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                    >
                                                                      <td
                                                                        className="text-left"
                                                                        style={{
                                                                          paddingTop:
                                                                            "27px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][0][
                                                                            "model"
                                                                          ]
                                                                        }
                                                                      </td>
                                                                      <td className="text-right"></td>
                                                                      <td className="text-right">
                                                                        <small className="text-muted">
                                                                          KM
                                                                        </small>{" "}
                                                                        <br />
                                                                        {formatter.format(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][
                                                                            "travel_summary"
                                                                          ]
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right">
                                                                        <small className="text-muted">
                                                                          {t(
                                                                            "_labels.tire.plural"
                                                                          )}
                                                                        </small>{" "}
                                                                        <br />
                                                                        {formatter.format(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][
                                                                            "statistics"
                                                                          ]
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right">
                                                                        <small className="text-muted">
                                                                          $KM
                                                                        </small>{" "}
                                                                        <br />$
                                                                        {costKmFormatter.format(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][
                                                                            "cpk"
                                                                          ]
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right">
                                                                        <small className="text-muted">
                                                                          1vs
                                                                          {index +
                                                                            1}
                                                                        </small>
                                                                        <br />$
                                                                        {costKmFormatter.format(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][
                                                                            "vs"
                                                                          ]
                                                                        )}
                                                                      </td>
                                                                      <td className="text-right">
                                                                        <small className="text-muted">
                                                                          Costo
                                                                          Km
                                                                        </small>
                                                                        <br />$
                                                                        {formatter.format(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            size
                                                                          ][
                                                                            modelKey
                                                                          ][
                                                                            "costKm"
                                                                          ]
                                                                        )}{" "}
                                                                      </td>
                                                                      <td className="text-right"></td>
                                                                    </tr>
                                                                  )
                                                                )}
                                                              </Fragment>
                                                            ))}
                                                        </Fragment>
                                                      ))}
                                                  </Fragment>
                                                ))}
                                            </Fragment>
                                          ))}
                                      </Fragment>
                                    ))}
                                </tbody>
                              </Fragment>
                            ))}
                          </table>
                        </div>
                      </>
                    )}

                    {Object.keys(revitalizedData).length > 0 && (
                      <>
                        <h4 className="text-center">
                          {t("_labels.revitalized_cost")}
                        </h4>
                        <div className="panel-body">
                          <table className="table table-condensed">
                            {Object.entries(revitalizedData).map(
                              (corporate) => (
                                <Fragment key={corporate[0]}>
                                  <thead className="text-center">
                                    <tr
                                      className="corporate"
                                      onClick={() =>
                                        toggleTable(
                                          `${corporate[0]}_companies`,
                                          corporate[0],
                                          "revitalized"
                                        )
                                      }
                                    >
                                      <th className="text-left">
                                        {corporate[0] === "NO CORPORATE"
                                          ? t("_labels.no_corporate")
                                          : corporate[0]}
                                      </th>
                                      <th></th>
                                      <th className="text-right col3"></th>
                                      <th className="text-right col3">
                                        <small>
                                          {t("_labels.tire.plural")}
                                        </small>
                                        <br />
                                        {formatter.format(
                                          corporate[1]["statistics"]
                                        )}
                                      </th>
                                      <th className="text-right col4">
                                        <small>
                                          {t("_labels.actual_cost")}
                                        </small>
                                        <br />$
                                        {costKmFormatter.format(
                                          corporate[1]["cpk"]
                                        )}
                                      </th>
                                      <th className="text-right col5">
                                        <small>{t("_labels.proposal")}</small>
                                        <br />$
                                        {costKmFormatter.format(
                                          corporate[1]["potential"]
                                        )}
                                      </th>
                                      <th className="text-right col6">
                                        <small>
                                          {t("_labels.real_saving")}
                                        </small>
                                        <br />$
                                        {formatter.format(
                                          corporate[1]["km*Real"]
                                        )}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.keys(corporate[1])
                                      .filter(
                                        (index) =>
                                          typeof corporate[1][index] ===
                                          "object"
                                      )
                                      .map((company) => (
                                        <Fragment key={company}>
                                          <tr
                                            data-type="revitalized"
                                            data-id={`${removeCharacters(
                                              corporate[0]
                                            )}_companies`}
                                            className="company box collapse"
                                            onClick={() =>
                                              toggleTable(
                                                `${corporate[0]}_${company}_subsidiaries`,
                                                company,
                                                "revitalized"
                                              )
                                            }
                                          >
                                            <td className="text-left">
                                              {company}
                                            </td>
                                            <td></td>
                                            <td className="text-right col3">
                                              {percentFormatter.format(
                                                (corporate[1][company][
                                                  "statistics"
                                                ] *
                                                  100) /
                                                  corporate[1]["statistics"]
                                              )}
                                              %
                                            </td>
                                            <td className="text-right col3">
                                              {formatter.format(
                                                corporate[1][company][
                                                  "statistics"
                                                ]
                                              )}
                                            </td>
                                            <td className="text-right col4">
                                              $
                                              {costKmFormatter.format(
                                                corporate[1][company]["cpk"]
                                              )}
                                            </td>
                                            <td className="text-right col5">
                                              $
                                              {costKmFormatter.format(
                                                corporate[1][company][
                                                  "potential"
                                                ]
                                              )}
                                            </td>
                                            <td className="text-right col6">
                                              $
                                              {formatter.format(
                                                corporate[1][company]["km*Real"]
                                              )}
                                            </td>
                                          </tr>

                                          {Object.keys(corporate[1][company])
                                            .filter(
                                              (index) =>
                                                typeof corporate[1][company][
                                                  index
                                                ] === "object"
                                            )
                                            .map((subsidiary) => (
                                              <Fragment key={subsidiary}>
                                                <tr
                                                  data-type="revitalized"
                                                  data-id={removeCharacters(
                                                    `${corporate[0]}_${company}_subsidiaries`
                                                  )}
                                                  className="subsidiary box collapse"
                                                  onClick={() =>
                                                    toggleTable(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                      `${company}_${subsidiary}`,
                                                      "revitalized"
                                                    )
                                                  }
                                                >
                                                  <td className="text-left">
                                                    {subsidiary}
                                                  </td>
                                                  <td></td>
                                                  <td className="text-right col3">
                                                    {percentFormatter.format(
                                                      (corporate[1][company][
                                                        subsidiary
                                                      ]["statistics"] *
                                                        100) /
                                                        corporate[1][company][
                                                          "statistics"
                                                        ]
                                                    )}
                                                    %
                                                  </td>
                                                  <td className="text-right col3">
                                                    {formatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["statistics"]
                                                    )}
                                                  </td>
                                                  <td className="text-right col4">
                                                    $
                                                    {costKmFormatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["cpk"]
                                                    )}
                                                  </td>
                                                  <td className="text-right col5">
                                                    $
                                                    {costKmFormatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["potential"]
                                                    )}
                                                  </td>
                                                  <td className="text-right col6">
                                                    $
                                                    {formatter.format(
                                                      corporate[1][company][
                                                        subsidiary
                                                      ]["km*Real"]
                                                    )}
                                                  </td>
                                                </tr>

                                                {Object.keys(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]
                                                )
                                                  .filter(
                                                    (index) =>
                                                      typeof corporate[1][
                                                        company
                                                      ][subsidiary][index] ===
                                                      "object"
                                                  )
                                                  .map((division) => (
                                                    <Fragment key={division}>
                                                      <tr
                                                        data-type="revitalized"
                                                        data-id={removeCharacters(
                                                          `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                        )}
                                                        className="division box collapse"
                                                        onClick={() =>
                                                          toggleTable(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_applications`,
                                                            `${company}_${subsidiary}_${division}`,
                                                            "revitalized"
                                                          )
                                                        }
                                                      >
                                                        <td className="text-left">
                                                          {division ===
                                                          "NO DIVISION"
                                                            ? t(
                                                                "_labels.no_division"
                                                              )
                                                            : division}
                                                        </td>
                                                        <td></td>
                                                        <td className="text-right col3">
                                                          {percentFormatter.format(
                                                            (corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["statistics"] *
                                                              100) /
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                "statistics"
                                                              ]
                                                          )}
                                                          %
                                                        </td>
                                                        <td className="text-right col3">
                                                          {formatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["statistics"]
                                                          )}
                                                        </td>
                                                        <td className="text-right col4">
                                                          $
                                                          {costKmFormatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["cpk"]
                                                          )}
                                                        </td>
                                                        <td className="text-right col5">
                                                          $
                                                          {costKmFormatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["potential"]
                                                          )}
                                                        </td>
                                                        <td className="text-right col6">
                                                          $
                                                          {formatter.format(
                                                            corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ]["km*Real"]
                                                          )}
                                                        </td>
                                                      </tr>

                                                      {Object.keys(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]
                                                      )
                                                        .filter(
                                                          (index) =>
                                                            typeof corporate[1][
                                                              company
                                                            ][subsidiary][
                                                              division
                                                            ][index] ===
                                                            "object"
                                                        )
                                                        .map((application) => (
                                                          <Fragment
                                                            key={application}
                                                          >
                                                            <tr
                                                              data-type="revitalized"
                                                              data-id={removeCharacters(
                                                                `${corporate[0]}_${company}_${subsidiary}_${division}_applications`
                                                              )}
                                                              className={`box collapse application`}
                                                              onClick={() =>
                                                                toggleTable(
                                                                  `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_sizes`,
                                                                  `${company}_${subsidiary}_${division}_${application}`,
                                                                  "revitalized"
                                                                )
                                                              }
                                                            >
                                                              <td className="text-left">
                                                                {t(
                                                                  `_labels.tire_application.options.${application.toLowerCase()}`
                                                                )}
                                                              </td>
                                                              <td></td>
                                                              <td className="text-right col3">
                                                                {percentFormatter.format(
                                                                  (corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ][
                                                                    "statistics"
                                                                  ] *
                                                                    100) /
                                                                    corporate[1][
                                                                      company
                                                                    ][
                                                                      subsidiary
                                                                    ][division][
                                                                      "statistics"
                                                                    ]
                                                                )}
                                                                %
                                                              </td>
                                                              <td className="text-right col3">
                                                                {formatter.format(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ][
                                                                    "statistics"
                                                                  ]
                                                                )}
                                                              </td>
                                                              <td className="text-right col4">
                                                                $
                                                                {costKmFormatter.format(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ]["cpk"]
                                                                )}
                                                              </td>
                                                              <td className="text-right col5">
                                                                $
                                                                {costKmFormatter.format(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ]["potential"]
                                                                )}
                                                              </td>
                                                              <td className="text-right col6">
                                                                $
                                                                {formatter.format(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ]["km*Real"]
                                                                )}
                                                              </td>
                                                            </tr>

                                                            {Object.keys(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][application]
                                                            )
                                                              .filter(
                                                                (index) =>
                                                                  typeof corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][
                                                                    application
                                                                  ][index] ===
                                                                  "object"
                                                              )
                                                              .map((size) => (
                                                                <Fragment
                                                                  key={size}
                                                                >
                                                                  <tr
                                                                    data-type="revitalized"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_sizes`
                                                                    )}
                                                                    className="size box collapse"
                                                                    onClick={() =>
                                                                      toggleTable(
                                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_${size}_tires`,
                                                                        `${company}_${subsidiary}_${division}_${application}_${size}`,
                                                                        "revitalized"
                                                                      )
                                                                    }
                                                                  >
                                                                    <td className="text-left">
                                                                      {size}
                                                                    </td>
                                                                    <td></td>
                                                                    <td className="text-right col3">
                                                                      {percentFormatter.format(
                                                                        (corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][size][
                                                                          "statistics"
                                                                        ] *
                                                                          100) /
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            application
                                                                          ][
                                                                            "statistics"
                                                                          ]
                                                                      )}
                                                                      %
                                                                    </td>
                                                                    <td className="text-right col3">
                                                                      {formatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][size][
                                                                          "statistics"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col4">
                                                                      $
                                                                      {costKmFormatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][size][
                                                                          "cpk"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      $
                                                                      {costKmFormatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][size][
                                                                          "potential"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col6">
                                                                      $
                                                                      {formatter.format(
                                                                        corporate[1][
                                                                          company
                                                                        ][
                                                                          subsidiary
                                                                        ][
                                                                          division
                                                                        ][
                                                                          application
                                                                        ][size][
                                                                          "km*Real"
                                                                        ]
                                                                      )}
                                                                    </td>
                                                                  </tr>

                                                                  {Object.values(
                                                                    corporate[1][
                                                                      company
                                                                    ][
                                                                      subsidiary
                                                                    ][division][
                                                                      application
                                                                    ][size][
                                                                      "data"
                                                                    ]
                                                                  ).map(
                                                                    (
                                                                      [
                                                                        modelKey,
                                                                      ],
                                                                      index
                                                                    ) => (
                                                                      <tr
                                                                        data-type="revitalized"
                                                                        data-id={removeCharacters(
                                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_${application}_${size}_tires`
                                                                        )}
                                                                        key={
                                                                          index
                                                                        }
                                                                        className="tire box collapse"
                                                                        style={{
                                                                          backgroundColor:
                                                                            "white",
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      >
                                                                        <td
                                                                          className="text-left"
                                                                          style={{
                                                                            paddingTop:
                                                                              "27px",
                                                                          }}
                                                                        >
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][0][
                                                                              "model"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right"></td>
                                                                        <td className="text-right">
                                                                          <small className="text-muted">
                                                                            KM
                                                                          </small>{" "}
                                                                          <br />
                                                                          {formatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][
                                                                              "travel_summary"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right">
                                                                          <small className="text-muted">
                                                                            {t(
                                                                              "_labels.tire.plural"
                                                                            )}
                                                                          </small>{" "}
                                                                          <br />
                                                                          {formatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][
                                                                              "statistics"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right">
                                                                          <small className="text-muted">
                                                                            $KM
                                                                          </small>{" "}
                                                                          <br />
                                                                          $
                                                                          {costKmFormatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][
                                                                              "cpk"
                                                                            ]
                                                                          )}
                                                                        </td>

                                                                        <td className="text-right">
                                                                          <small className="text-muted">
                                                                            1vs
                                                                            {index +
                                                                              1}
                                                                          </small>
                                                                          <br />
                                                                          $
                                                                          {costKmFormatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][
                                                                              "vs"
                                                                            ]
                                                                          )}
                                                                        </td>
                                                                        <td className="text-right">
                                                                          <small className="text-muted">
                                                                            Costo
                                                                            Km
                                                                          </small>
                                                                          <br />
                                                                          $
                                                                          {formatter.format(
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              application
                                                                            ][
                                                                              size
                                                                            ][
                                                                              modelKey
                                                                            ][
                                                                              "costKm"
                                                                            ]
                                                                          )}{" "}
                                                                        </td>
                                                                        <td className="text-right"></td>
                                                                      </tr>
                                                                    )
                                                                  )}
                                                                </Fragment>
                                                              ))}
                                                          </Fragment>
                                                        ))}
                                                    </Fragment>
                                                  ))}
                                              </Fragment>
                                            ))}
                                        </Fragment>
                                      ))}
                                  </tbody>
                                </Fragment>
                              )
                            )}
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {originalTierList.length > 0 && (
                <div className="row">
                  <div className="container-fluid">
                    <h4
                      className="text-center mt-5"
                      style={{
                        backgroundColor: "#002849",
                        color: "white",
                        height: 50,
                        paddingTop: 10,
                      }}
                    >
                      {t("_labels.best_tire_original")}
                    </h4>
                    <table className="table mb-5 text-center">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "dimgrey",
                            color: "white",
                            height: 50,
                            paddingTop: 10,
                          }}
                        >
                          <th scope="col" className="text-left">
                            {t("_labels.division.singular")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.application")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.size")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.model")}
                          </th>
                          <th scope="col" className="text-right">
                            KM
                          </th>
                          <th scope="col" className="text-right">
                            $KM
                          </th>
                          <th scope="col" className="text-right">
                            $KMx10,000
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {originalTierList.map((tier, index) => (
                          <tr key={index}>
                            <td className="text-left">
                              {tier["division_name"] === "NO DIVISION"
                                ? t("_labels.no_division")
                                : tier["division_name"]}
                            </td>
                            <td className="text-left">
                              {t(
                                `_labels.tire_application.options.${tier[
                                  "tire_application_id"
                                ].toLowerCase()}`
                              )}
                            </td>
                            <td className="text-left">{tier["size"]}</td>
                            <td className="text-left">{tier["model"]}</td>
                            <td className="text-right">
                              {percentFormatter.format(tier["travel_summary"])}
                            </td>
                            <td className="text-right">
                              ${costKmFormatter.format(tier["cpk"])}
                            </td>
                            <td className="text-right">
                              ${costKmFormatter.format(tier["cpk"] * 10000)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {revitalizedTierList.length > 0 && (
                <div className="row">
                  <div className="container-fluid">
                    <h4
                      className="text-center mt-5"
                      style={{
                        backgroundColor: "#002849",
                        color: "white",
                        height: 50,
                        paddingTop: 10,
                      }}
                    >
                      {t("_labels.best_tire_revitalized")}
                    </h4>
                    <table className="table mb-5 text-center">
                      <thead>
                        <tr
                          style={{
                            backgroundColor: "dimgrey",
                            color: "white",
                            height: 50,
                            paddingTop: 10,
                          }}
                        >
                          <th scope="col" className="text-left">
                            {t("_labels.division.singular")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.application")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.size")}
                          </th>
                          <th scope="col" className="text-left">
                            {t("_labels.model")}
                          </th>
                          <th scope="col" className="text-right">
                            KM
                          </th>
                          <th scope="col" className="text-right">
                            $KM
                          </th>
                          <th scope="col" className="text-right">
                            $KMx10,000
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {revitalizedTierList.map((tier, index) => (
                          <tr key={index}>
                            <td className="text-left">
                              {tier["division_name"] === "NO DIVISION"
                                ? t("_labels.no_division")
                                : tier["division_name"]}
                            </td>
                            <td className="text-left">
                              {t(
                                `_labels.tire_application.options.${tier[
                                  "tire_application_id"
                                ].toLowerCase()}`
                              )}
                            </td>
                            <td className="text-left">{tier["size"]}</td>
                            <td className="text-left">{tier["model"]}</td>
                            <td className="text-right">
                              {formatter.format(tier["travel_summary"])}
                            </td>
                            <td className="text-right">
                              ${costKmFormatter.format(tier["cpk"])}
                            </td>
                            <td className="text-right">
                              ${costKmFormatter.format(tier["cpk"] * 10000)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <FilterBestPerformanceForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  openFilter: PropTypes.bool.isRequired,
  originalData: PropTypes.object.isRequired,
  revitalizedData: PropTypes.object.isRequired,
  originalTierList: PropTypes.array.isRequired,
  revitalizedTierList: PropTypes.array.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string) {
  return string.replaceAll(/[,\s\.\/]/g, "");
}
