export default {
  disabled_brand: "Marca deshabilitada",
  messages: {
    approve: "¿Estas seguro de aprobar la marca?",
    disapprove: "¿Estas seguro de desaprobar la marca?",
    enable: "¿Estas seguro de habilitar la marca?",
    disable: "¿Estas seguro de deshabilitar la marca?",
  },
  permits: {
    change_approval_status: "Sin permisos para {action} marcas",
    change_status: "Sin permisos para {action} marcas",
  },
};
