import { GET_ASSOCIATION_PRESSURE_POLICIES } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetAssociationPressurePolicies =
  (dispatch) =>
  async (
    {
      associationId,
      search,
      status,
      page,
      order = "DESC",
      scope,
      associations,
      vehicle_type: vehicleType,
      axle_type: axleType,
      date_from: dateFrom,
      date_to: dateTo,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `association/${associationId}/pressure/policy/vehicle/axle`,
        params: {
          search,
          status,
          page,
          order,
          scope,
          associations,
          vehicle_type: vehicleType,
          axle_type: axleType,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_ASSOCIATION_PRESSURE_POLICIES,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
