import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Link from "@material-ui/core/Link";
import ContainerTemplate from "components/templates/general/Container";
import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterLastMovement from "components/organisms/report/FilterLastMovement";
import SearchInput from "components/molecules/general/SearchInput";
import moment from "moment-timezone";

const formatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const cpdFormatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 4,
});

function Page({
  t,
  data,
  filter,
  selectedFilter,
  search,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
  handleSearch,
}) {
  return (
    <ContainerTemplate
      title={t("_labels.report_last_movement.label")}
      options={
        <>
          <Button
            classNameButton={classnames("bw")}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <div className="row pdt24">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.movement && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.locacation.label")}:
                      </td>
                      <td>{selectedFilter.movement}</td>
                    </tr>
                  )}
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.companies && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiaries && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.brands && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.brand")}:
                      </td>
                      <td>{selectedFilter.brands}</td>
                    </tr>
                  )}
                  {filter.models && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.model")}:
                      </td>
                      <td>{selectedFilter.models}</td>
                    </tr>
                  )}
                  {filter.sizes && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.size")}:
                      </td>
                      <td>{selectedFilter.sizes}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <small className="sinmp" style={{ color: "lightcoral" }}>
              {t("_labels.location.label")}:{" "}
              {t("_labels.location.options.pile")}
            </small>
            <hr />
          </div>
        </div>
        <div className="row py-2">
          <div className="col-md-12">
            <div className="container-fluid">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        {/* Tabla */}
        <div className="col-md-12">
          <div className="card">
            <div className="card-header text-center h2">
              {t("_labels.report_last_movement.label")}
            </div>
            <div className="card-content table-responsive">
              <table className="table sFS12 m-0">
                <thead>
                  <tr
                    className=""
                    style={{ backgroundColor: "#002849", color: "white" }}
                  >
                    <td className="text-center align-middle ">
                      {t("_labels.company.singular")}
                    </td>
                    <td className="text-center align-middle ">
                      {t("_labels.corporate.singular")}
                    </td>
                    <td className="text-center align-middle ">
                      {t("_labels.subsidiary.singular")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.division.singular")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.economic_number")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.last_position")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.burn_code")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.size")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.brand")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.model")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.condition")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.renovated_number")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.revitalized_brand_field.label")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.revitalized_model_field.label")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.mileage")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.retirement_cause_field.label")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.shipping_date")}{" "}
                      {t("_labels.location.options.pile").toLowerCase()}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.depth_model")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.depth_last_revision")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.wasted_rubber")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.price")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.cost")}
                    </td>
                    <td className="text-center align-middle">
                      {t("_labels.report_last_movement.disposal_loss")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(data).map((row, index) => (
                    <tr className="flex" key={index}>
                      <td className="text-center">{row.company_name}</td>
                      <td className="text-center">{row.corporate_name}</td>
                      <td className="text-center">{row.subsidiary_name}</td>
                      <td className="text-center">{row.division_name}</td>
                      <td className="text-center">{row.economic_number}</td>
                      <td className="text-center">{row.real_position}</td>
                      <td className="text-center">
                        <Link
                          href={`/tire/${row.tire_id}/history`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.code}
                        </Link>
                      </td>
                      <td className="text-center">{row.size}</td>
                      <td className="text-center">{row.brand_name}</td>
                      <td className="text-center">{row.original_model}</td>
                      <td className="text-center">
                        {t(
                          `_labels.tire_condition.options.${row.tire_condition_id.toLowerCase()}`
                        )}
                      </td>
                      <td className="text-center">{row.number_cycle}</td>
                      <td className="text-center">{row.brand_retread_name}</td>
                      <td className="text-center">{row.type}</td>
                      <td className="text-center">{row.tire_travel}</td>
                      <td className="text-center">
                        {t(`_retirement.name.${row.retirement_cause}`)}
                      </td>
                      <td className="text-center text-nowrap">
                        {row.movement_date}
                      </td>
                      <td className="text-center">{row.depth_variation}</td>
                      <td className="text-center">{row.depth}</td>
                      <td className="text-center">{row.remainder_depth}</td>
                      <td className="text-center">{row.cost}</td>
                      <td className="text-center">
                        {cpdFormatter.format(row.cpd)}
                      </td>
                      <td className="text-center">
                        {formatter.format(row.disposal_loss)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <FilterLastMovement open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  openFilter: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default Page;
