import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import TireHistoryTabs from "components/organisms/tire/TireHistoryTabs";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import ListItemStatusCard from "components/molecules/general/cards/ListItemStatusCard";

import InfoWarehouseTireDialog from "components/organisms/tire/warehouse/history/InfoWarehouseTireDialog";

import css from "./index.module.scss";

function Page({
  t,
  tireId,
  warehouse,
  warehouseId,
  warehouseTireId,
  dialogOpen,
  showWarehousePermit,
  handleChangePage,
  handleOpenDialog,
  handleCloseDialog,
  handleExitHistory,
}) {
  return (
    <TireHistoryTabs
      className={classnames({
        [css.empty]: warehouse.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            className={classnames("bw")}
            variant="icon"
            onClick={handleExitHistory}
          >
            <VisibilityIcon />
          </Button>
          <Button className={classnames("bw")} variant="icon">
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    >
      {warehouse.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            {warehouse.data.map((warehouse, index) => {
              return (
                <ListItemStatusCard
                  key={index}
                  title={warehouse.warehouse.name}
                  status={warehouse.warehouse.status}
                  description=""
                  className={css.card}
                  tooltipPermit={
                    !showWarehousePermit ? t("_permits.show.warehouse") : ""
                  }
                  onClick={() => {
                    handleOpenDialog(
                      warehouse.warehouse.warehouse_id,
                      warehouse.warehouse_tire_id
                    );
                  }}
                />
              );
            })}
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={warehouse.current_page}
              totalPages={warehouse.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_titles.history.tire.warehouse")} />
      )}

      {!!warehouseId && (
        <InfoWarehouseTireDialog
          open={dialogOpen}
          tireId={tireId}
          warehouseId={warehouseId}
          warehouseTireId={warehouseTireId}
          onClose={handleCloseDialog}
        />
      )}
    </TireHistoryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouse: PropTypes.object.isRequired,
  warehouseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  warehouseTireId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showWarehousePermit: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleExitHistory: PropTypes.func.isRequired,
};

export default Page;
