import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";

import noImage from "resources/images/noImage.svg";
import Page from "./page";

function getCorporateId(item, name) {
  if (item.corporate_id) {
    return item.corporate_id.toString();
  }

  if (name === "user" && item.corporates.length > 0) {
    return item.corporates[0].corporate_id.toString();
  }

  return "";
}

function getCompanyId(item, name) {
  if (item.company_id) {
    return item.company_id.toString();
  }

  if (name === "user" && item.companies.length > 0) {
    return item.companies[0].company_id.toString();
  }

  return "";
}

function getSubsidiaryId(item, name) {
  if (item.subsidiary_id) {
    return item.subsidiary_id.toString();
  }

  if (name === "user" && item.subsidiaries.length > 0) {
    return item.subsidiaries[0].subsidiary_id.toString();
  }

  return "";
}

function getRoles(item, name) {
  if (name === "user") {
    if (item.subsidiaries.length > 0 && item.subsidiaries[0].role.length > 0) {
      const res = item.subsidiaries.map((subsidiary) => ({
        subsidiary_id: subsidiary.subsidiary_id.toString(),
        role: subsidiary.role[0].role,
      }));

      return res;
    }
    return [];
  }

  return [];
}

function getDepth(item) {
  if (item.depth) {
    return item.depth;
  }

  return "";
}

function getNumberLayers(item) {
  if (item.number_layers) {
    return item.number_layers;
  }

  return "";
}

function getModelUse(item, name) {
  if (name === "tire_model") {
    if (
      item.tire_model_variation_use &&
      item.tire_model_variation_use.length > 0
    ) {
      return item.tire_model_variation_use.map((use) => use.tire_use_id);
    }
  }

  if (item.tire_model_use && item.tire_model_use.length > 0) {
    return item.tire_model_use.map((use) => use.tire_use_id);
  }

  return [];
}

function getBrand(item, name) {
  if (name === "tire_model") {
    return item.tire_model.brand_id.toString();
  }

  if (item.brand_id) {
    return item.brand_id.toString();
  }

  return "";
}

function getName(item, name) {
  if (name === "tire_model") {
    return item.tire_model.name;
  }

  if (item.name) {
    return item.name.toString();
  }

  return "";
}

export function Form({
  id,
  open,
  item,
  name,
  title,
  fields,
  updateTitle,
  createdMessage,
  updatedMessage,
  initialDefaultValues,
  onClose,
  getAction,
  addAction,
  updateAction,
  clearFiltersAction,
  ...rest
}) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(id ? true : false);
  const { setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: initialDefaultValues,
  });
  useEffect(() => {
    async function fetchData() {
      try {
        await getAction(id);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (id && open) {
      fetchData();
    }
  }, [id, open]);

  useEffect(() => {
    if (id && item[`${name}_id`]) {
      let fields = {
        ...item,
        logo: item.logo
          ? `${process.env.REACT_APP_IMAGES_URL}${item.logo}`
          : null,
        image: item.image
          ? `${process.env.REACT_APP_IMAGES_URL}${item.image}`
          : null,
        data_sheet:
          item.tire_model && item.tire_model.data_sheet
            ? `${process.env.REACT_APP_IMAGES_URL}${item.tire_model.data_sheet}`
            : item.data_sheet
            ? `${process.env.REACT_APP_IMAGES_URL}${item.data_sheet}`
            : null,
        observation: item.observation ? item.observation : "",
        corporate_id: getCorporateId(item, name),
        subsidiary_id: getSubsidiaryId(item, name),
        company_id: getCompanyId(item, name),
        internal_number: item.internal_number || "",
        last_name_2: item.last_name_2 || "",
        subsidiaries: getRoles(item, name),
        depth: getDepth(item, name),
        maximum_pressure: item.maximum_pressure ? item.maximum_pressure : "",
        recommended_pressure: item.recommended_pressure
          ? item.recommended_pressure
          : "",
        size: item.size ? item.size : "",
        helmet_value_original: item.helmet_value_original
          ? item.helmet_value_original
          : "",
        helmet_value_revitalized: item.helmet_value_revitalized
          ? item.helmet_value_revitalized
          : "",
        tolerance: item.tolerance || "",
        number_layers: getNumberLayers(item, name),
        password: item.password || "",
        password_confirm: item.password_confirm || "",
        need_change_password: item.need_change_password ? true : false,
        name: getName(item, name),
        description: item.description ? item.description : "",
        lock_cycles: item.lock_cycles ? true : false,
        appearance: item.appearance || "",
        probable_causes: item.probable_causes || "",
        operation: item.operation || "",
        action_vehicle: item.action_vehicle || "",
        action_tire: item.action_tire || "",
        pressure_type_axle: !!item.pressure_type_axle,
        helmet_policy: !!item.helmet_policy,
        tire_condition_axle_type: !!item.tire_condition_axle_type,
        show_alerts_different_assignment:
          !!item.show_alerts_different_assignment,
        send_pressure_setting: !!item.send_pressure_setting,
        use_checklist: !!item.use_checklist,
        use_tpms: !!item.use_tpms,
        no_tire: !!item.no_tire,
        refection_review: !!item.refection_review,
        suggestion: item.suggestion || "",
        vehicle_model: item.vehicle_model || "",
        vehicle_year: item.vehicle_year || "",
        transmission_model: item.transmission_model || "",
        transmission_speeds: item.transmission_speeds || "",
        cylinder_capacity: item.cylinder_capacity || "",
        operator_edit: !!item.operator_edit,
        brand_id: getBrand(item, name),
        tire_size_id: item.tire_size?.tire_size_id
          ? item.tire_size.tire_size_id.toString()
          : "",
        tire_application_id: item.tire_application_id || "",
        tire_model_variation_use: getModelUse(item, name),
        vehicle_brand_id: item.vehicle_brand_id
          ? item.vehicle_brand_id.toString()
          : "",

        vehicle_engine_brand_id: item.vehicle_engine_brand_id
          ? item.vehicle_engine_brand_id.toString()
          : "",
        engine_transmission_brand_id: item.engine_transmission_brand_id
          ? item.engine_transmission_brand_id.toString()
          : "",
        vehicle_type_id: item.vehicle_type_id
          ? item.vehicle_type_id.toString()
          : "",
        division_id: item.division
          ? item.division.length
            ? item.division[0].division_id.toString()
            : ""
          : "",
        driver_id: item.driver ? item.driver.driver_id.toString() : "",
        has_odometer: item.has_odometer ? true : false,
      };

      for (const key in fields) {
        if (initialDefaultValues[key] === undefined) {
          delete fields[key];
        }
      }
      setInitialValues(fields);
    } else {
      setInitialValues(initialDefaultValues);
    }
  }, [item]);

  useEffect(() => {
    setIsUpdate(id ? true : false);
  }, [id]);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    let withFile = false;

    try {
      const fields = {
        ...values,
      };

      if (values.odometer === "") {
        delete fields.odometer;
      }

      if (values.logo !== null) {
        if (
          values.logo !== `${process.env.REACT_APP_IMAGES_URL}${item.logo}` &&
          values.logo !== noImage
        ) {
          fields.logo = values.logo;
          withFile = true;
        } else {
          delete fields.logo;
        }
      } else if (!isUpdate) {
        delete fields.logo;
      }

      if (values.image !== null) {
        if (
          values.image !== `${process.env.REACT_APP_IMAGES_URL}${item.image}` &&
          values.image !== noImage
        ) {
          fields.image = values.image;
          withFile = true;
        } else {
          delete fields.image;
        }
      } else if (!isUpdate) {
        delete fields.image;
      }

      if (values.data_sheet && typeof values.data_sheet === "object") {
        fields.data_sheet = values.data_sheet;
        withFile = true;
      } else {
        delete fields.data_sheet;
      }

      if (values.image !== null) {
        if (
          values.image !== `${process.env.REACT_APP_IMAGES_URL}${item.image}` &&
          values.image !== noImage
        ) {
          fields.image = values.image;
          withFile = true;
        } else {
          delete fields.image;
        }
      } else if (!isUpdate) {
        delete fields.image;
      }

      if (!!values.roles && values.roles.length > 0) {
        fields.roles = values.roles;
      }

      if (
        !!values.tire_model_variation_application &&
        values.tire_model_variation_application.length > 0
      ) {
        fields.tire_model_variation_application =
          values.tire_model_variation_application.join(",");
      }

      if (
        !!values.tire_model_variation_use &&
        values.tire_model_variation_use.length > 0
      ) {
        fields.tire_model_variation_use =
          values.tire_model_variation_use.join(",");
      }

      if (
        !!values.revitalized_tire_model_application &&
        values.revitalized_tire_model_application.length > 0
      ) {
        fields.revitalized_tire_model_application =
          values.revitalized_tire_model_application.join(",");
      }

      if (values.lock_cycles !== undefined) {
        fields.lock_cycles = values.lock_cycles ? 1 : 0;
      }

      if (values.pressure_type_axle !== undefined) {
        fields.pressure_type_axle = values.pressure_type_axle ? 1 : 0;
      }

      if (values.helmet_policy !== undefined) {
        fields.helmet_policy = values.helmet_policy ? 1 : 0;
      }

      if (values.tire_condition_axle_type !== undefined) {
        fields.tire_condition_axle_type = values.tire_condition_axle_type
          ? 1
          : 0;
      }

      if (values.show_alerts_different_assignment !== undefined) {
        fields.show_alerts_different_assignment =
          values.show_alerts_different_assignment ? 1 : 0;
      }

      if (values.send_pressure_setting !== undefined) {
        fields.send_pressure_setting = values.send_pressure_setting ? 1 : 0;
      }

      if (values.use_checklist !== undefined) {
        fields.use_checklist = values.use_checklist ? 1 : 0;
      }

      if (values.use_tpms !== undefined) {
        fields.use_tpms = values.use_tpms ? 1 : 0;
      }

      if (values.no_tire !== undefined) {
        fields.no_tire = values.no_tire ? 1 : 0;
      }

      if (values.refection_review !== undefined) {
        fields.refection_review = values.refection_review ? 1 : 0;
      }

      if (isUpdate) {
        await updateAction(fields, id, withFile);
        setNotification({
          message: updatedMessage,
        });
      } else {
        if (!!addAction) {
          await addAction(fields, withFile);
          resetForm();
          setNotification({
            message: createdMessage,
          });
        }
      }

      setSubmitting(false);
      onClose();

      try {
        clearFiltersAction();
      } catch (error) {
        setNotification(error, true);
      }
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);
    if (name === "vehicle" && !(values.cylinder_capacity % 1 === 0)) {
      errors.cylinder_capacity = t("_errors.no_integer");
    }
    if (name === "vehicle" && !(values.transmission_speeds % 1 === 0)) {
      errors.transmission_speeds = t("_errors.no_integer");
    }
    if (name === "policy" && !(values.inspection % 1 === 0)) {
      errors.inspection = t("_errors.no_integer");
    }
    if (name === "policy" && !(values.tire_rotation % 1 === 0)) {
      errors.tire_rotation = t("_errors.no_integer");
    }
    if (name === "policy" && !(values.directional_tire_rotation % 1 === 0)) {
      errors.directional_tire_rotation = t("_errors.no_integer");
    }
    if (
      name === "user" &&
      values.password.trim() !== "" &&
      values.password !== values.password_confirm
    ) {
      errors.password_confirm = t("_errors.different_passwords");
    }

    return errors;
  }

  return (
    <Page
      open={open}
      name={name}
      title={title}
      fields={fields}
      updateTitle={updateTitle}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleValidate={handleValidate}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  updateTitle: PropTypes.string.isRequired,
  createdMessage: PropTypes.string,
  updatedMessage: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  initialDefaultValues: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  getAction: PropTypes.func.isRequired,
  addAction: PropTypes.func,
  updateAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
