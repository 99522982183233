import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { usePDF } from "@react-pdf/renderer";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";
import { actionGetTpmsAlerts } from "store/actions/tpms/alert/getTpmsAlerts";
import { actionDeleteTpmsAlert } from "store/actions/tpms/alert/deleteTpmsAlert";

import { useLang } from "hooks/lang";
import { useParams } from "react-router-dom";

import AlertVehicleTirePdf from "./PDF/Document";
import Page from "./page";

export function TpmsAlerts({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    filter,
    setNotification,
    getTpmsAlerts,
    deleteAlertOfMount,
    getVehicle,
  } = rest;

  const [alerts, setAlerts] = useState({
    current_page: 1,
    data: [],
    last_page: 1,
  });
  const [alertId, setAlertId] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [id, setId] = useState(null);

  const [isSavingPdf, setIsSavingPdf] = useState(false);
  const [pdfInstance, updatePdfInstance] = usePDF();
  const [dataAlerts, setDataAlerts] = useState([]);

  const [economicNumber, setEconomicNumber] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getVehicle(vehicleId, false);
        setEconomicNumber(response.economic_number);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTpmsAlerts(vehicleId, { page: 1, ...filter });
        setAlerts(data);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [alerts.data]);

  useEffect(() => {
    if (isSavingPdf && !pdfInstance.loading) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = pdfInstance.url;
      a.download = "AlertasTPMS.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsSavingPdf(false);
    }
  }, [isSavingPdf, pdfInstance]);

  useEffect(() => {
    if (dataAlerts.length > 0) {
      setIsSavingPdf(true);
      // Se genera un UUID para forzar que el pdf se descargue de nuevo,
      // aunque su contenido sea idéntico al anterior
      const uuid =
        Date.now().toString(36) + Math.random().toString(36).substring(2);
      const pdfData = {
        uuid: uuid,
        date: moment()
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY hh:mm:ss a"),
        alerts: dataAlerts,
        economicNumber,
      };
      updatePdfInstance(<AlertVehicleTirePdf {...pdfData} uuid={uuid} />);
    }
  }, [dataAlerts]);

  async function onChangePage(page) {
    try {
      const data = await getTpmsAlerts(vehicleId, { page, ...filter });
      setAlerts(data);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(alertId, id) {
    setId(id);
    setAlertId(alertId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setAlertId(null);
    setOpenForm(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleDelete(alert) {
    const { alert_id: alertId, vehicle_tire_id: vehicleTireId } = alert;
    setNotification({
      message: t("_messages.delete.alert"),
      textAction: t("_buttons.confirm"),
      onAction: () => onDelete(vehicleTireId, alertId),
    });
  }

  async function onDelete(vehicleTireId, alertId) {
    try {
      const response = await deleteAlertOfMount(vehicleTireId, alertId);
      setNotification({
        ...response,
      });

      setAlerts({
        ...alerts,
        data: alerts.data.filter(
          (alert) => alert.vehicle_tire_id !== vehicleTireId
        ),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDownloadPdf() {
    try {
      const response = await getTpmsAlerts(vehicleId, {
        ...filter,
        flatten: 1,
      });
      setDataAlerts(response);
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      alerts={alerts}
      economicNumber={economicNumber}
      openForm={openForm}
      openFilter={openFilter}
      id={id}
      alertId={alertId}
      vehicleTireId={vehicleId}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleDelete={handleDelete}
      handleDownloadPdf={handleDownloadPdf}
    />
  );
}

TpmsAlerts.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.MountAlerts.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTpmsAlerts: actionGetTpmsAlerts(dispatch),
  deleteAlertOfMount: actionDeleteTpmsAlert(dispatch),
  getVehicle: actionGetVehicle(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TpmsAlerts);

//FV051583 FV051584 FV049003
