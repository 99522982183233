import gps from "./gps";
import type from "./type";
import driver from "./driver";
import division from "./division";
import travel from "./travel";
import subsidiary from "./subsidiary";
import tire from "./tire";
import VehicleUpdateForm from "./VehicleUpdateForm";
import VehicleRegisterForm from "./VehicleRegisterForm";
import VehiclesRegisterForm from "./VehiclesRegisterForm";
import InfoVehicleCard from "./InfoVehicleCard";
import FilterVehicleForm from "./FilterVehicleForm";
import VehicleTabs from "./VehicleTabs";
import VehicleHistoryTabs from "./VehicleHistoryTabs";

export default {
  gps,
  type,
  driver,
  division,
  travel,
  subsidiary,
  tire,
  vehicle_update_form: VehicleUpdateForm,
  vehicle_register_form: VehicleRegisterForm,
  vehicles_register_form: VehiclesRegisterForm,
  info_vehicle_card: InfoVehicleCard,
  filter_vehicle_form: FilterVehicleForm,
  vehicle_history_tabs: VehicleHistoryTabs,
  vehicle_tabs: VehicleTabs,
};
