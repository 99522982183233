import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionGetVehicle } from "store/actions/vehicle/getVehicle";
import { actionSetNotification } from "store/actions/general/notification";

import { SHOW_VEHICLE } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "src/components/organisms/vehicle/VehicleHistoryTabs";

export function VehicleHistoryTabs({
  children,
  className,
  optionsAppBar,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const { id } = useParams();
  const { vehicle, permits, getVehicle, setNotification } = rest;
  const showVehiclePermit = permits.includes(SHOW_VEHICLE);

  const tabLinks = [
    {
      to: `/vehicle/${id}/inspection/history`,
      label: t("_labels.inspection.plural"),
    },
    {
      to: `/vehicle/${id}/travel/history`,
      label: t("_labels.travel.plural"),
      disabled: !showVehiclePermit,
      tooltip: !showVehiclePermit ? t("_permits.show.vehicle") : "",
    },
    {
      to: `/vehicle/${id}/tire/history`,
      label: t("_labels.mounted_tire.plural"),
    },
    {
      to: `/vehicle/${id}/driver/history`,
      label: t("_labels.driver.plural"),
    },
    {
      to: `/vehicle/${id}/gps/history`,
      label: t("_labels.gps"),
    },
    {
      to: `/vehicle/${id}/division/history`,
      label: t("_labels.division.plural"),
    },
    {
      to: `/vehicle/${id}/subsidiary/history`,
      label: t("_labels.subsidiary.plural"),
      disabled: !showVehiclePermit,
      tooltip: !showVehiclePermit ? t("_permits.show.vehicle") : "",
    },
    {
      to: `/vehicle/${id}/alert/history`,
      label: t("_labels.alerts"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicle(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showVehiclePermit) {
      fetchData();
    }
  }, [id]);

  return (
    <Page
      tabLinks={tabLinks}
      className={className}
      optionsAppBar={optionsAppBar}
      title={
        showVehiclePermit
          ? `${t("vehicle_history")} ${vehicle.economic_number}`
          : t("_permits.show.vehicle")
      }
    >
      {children}
    </Page>
  );
}

VehicleHistoryTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehicle: state.Vehicle.Vehicle.vehicle,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicle: actionGetVehicle(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleHistoryTabs);
