import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Line, getElementAtEvent } from "react-chartjs-2";

function Chart({ data, options, style, plugins }) {
  const chartRef = useRef();

  function onClick(event) {
    const result = getElementAtEvent(chartRef.current, event);

    if (result.length > 0) {
      const [{ element }] = result;

      const data = element.$context.raw;
      const subsidiary = data.subsidiary
        ? `&subsidiary=${data.subsidiary}`
        : "";

      window.open(
        `/reports/dynamic/cost?date=${data.fecha}${subsidiary}`,
        "_blank"
      );
    }
  }
  return (
    <Line
      style={style}
      options={options}
      data={data}
      ref={chartRef}
      onClick={onClick}
      plugins={plugins}
    />
  );
}

Chart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  style: PropTypes.object,
  plugins: PropTypes.array,
};

export default Chart;
