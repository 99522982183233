import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { actionAddToCart } from "store/actions/reports/purchase/addToCart";
import { actionRemoveToCart } from "store/actions/reports/purchase/removeToCart";

import Page from "./page";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

const priceFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  useGrouping: false,
});

function SizeTable({ ...rest }) {
  const t = useLang();
  const {
    data,
    original,
    actualPurchase,
    replacementData,
    saveRef,
    addToCart,
    removeToCart,
  } = rest;
  const [editable, setEditable] = useState(true);
  const formikRef = React.useRef();

  const { initialValues, handleFormikValidate } = useFormik({
    initialValues: {
      save: false,
      data: [
        ...data.map((row) => ({
          ...row,
          required:
            actualPurchase[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ] ?? 0,
          quantity: getRealQuantity(
            actualPurchase[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ],
            replacementData[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ]
          ),
          stock:
            replacementData[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ] ?? 0,
          cpko: formatter.format(row.cpko || 0),
          cpkr: formatter.format(row.cpkr || 0),
          cpk: original
            ? formatter.format(row.cpko)
            : formatter.format(row.cpkr || 0),
          price: original
            ? priceFormatter.format(parseFloat(row.price))
            : priceFormatter.format(parseFloat(row.price_revitalized)),
        })),
      ],
    },
  });

  function handleToggleEditable() {
    if (editable) {
      addToCart();
    } else {
      removeToCart();
    }

    setEditable((prev) => !prev);
  }

  useEffect(() => {
    saveRef(formikRef);
  }, []);

  return (
    <Page
      t={t}
      {...rest}
      initialValues={initialValues}
      handleFormikValidate={handleFormikValidate}
      isEditable={editable}
      handleToggleEditable={handleToggleEditable}
      formikRef={formikRef}
    />
  );
}
SizeTable.propTypes = {
  size: PropTypes.string,
  application: PropTypes.string,
  data: PropTypes.array,
  actualPurchase: PropTypes.object,
  replacementData: PropTypes.object,
  original: PropTypes.bool,
  setSummaryData: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addToCart: actionAddToCart(dispatch),
  removeToCart: actionRemoveToCart(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SizeTable);

function getRealQuantity(actualPurchase, replacement) {
  const required = actualPurchase ?? 0;

  if (required === 0) {
    return 0;
  }

  const inStock = replacement ?? 0;

  if (required - inStock < 0) {
    return 0;
  }

  return required - inStock;
}
