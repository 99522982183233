import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddDepthPolicy } from "store/actions/association/policy/depth/addVehicleDepthPolicy";
import { actionClearAssociationDepthPoliciesFilters } from "store/actions/association/policy/depth/clearAssociationDepthFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function DepthPolicyForm({ open, onClose, vehicle, ...rest }) {
  const t = useLang();
  const { addDepthPolicy, clearDepthPoliciesFilters, setNotification } = rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      axle_policy: [
        {
          axle_type: "",
          good_depth: "",
          scheduled_withdrawal: "",
          critical_withdrawal: "",
        },
      ],
    },
  });

  const { id: associationId } = useParams();

  useEffect(() => {
    if (open && Object.keys(vehicle).length > 0) {
      const validAxles = Object.keys(
        vehicle.vehicle_type.vehicle_type_axle.reduce((acc, val) => {
          if (!acc[val.axle_type]) {
            acc[val.axle_type] = true;
          }

          return acc;
        }, {})
      );

      const currentPolicies = Object.keys(
        vehicle.commissioned_vehicle_depth_policy.reduce((acc, val) => {
          if (!acc[val.axle_type]) {
            acc[val.axle_type] = true;
          }

          return acc;
        }, {})
      );

      const axles = validAxles.filter(
        (axle) => !currentPolicies.includes(axle)
      );

      setInitialValues({
        axle_policy: axles.map((axle) => ({
          axle_type: axle,
          good_depth: "",
          scheduled_withdrawal: "",
          critical_withdrawal: "",
        })),
      });
    } else {
      resetFormik();
    }
  }, [open, vehicle]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        vehicles: [
          { commissioned_vehicle_id: vehicle.commissioned_vehicle_id },
        ],
        axle_policy: values.axle_policy,
      };
      await addDepthPolicy(associationId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.depth_policy"),
      });

      setSubmitting(false);
      onClose();
      clearDepthPoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      vehicle={vehicle}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

DepthPolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehicle: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addDepthPolicy: actionAddDepthPolicy(dispatch),
  clearDepthPoliciesFilters:
    actionClearAssociationDepthPoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepthPolicyForm);
