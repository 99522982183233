import React, { useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModelVariation } from "store/actions/tire/model/variation/getTireModelVariation";
import { actionClearTireModelVariation } from "store/actions/tire/model/variation/clearTireModelVariation";
import { actionSetTireModelVariationStatus } from "store/actions/tire/model/variation/setTireModelVariationStatus";
import { actionSetTireModelVariationApproved } from "store/actions/tire/model/variation/setTireModelVariationsApproved";

import { UPDATE_TIRE_MODEL_VARIATION } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/model/variation/InfoTireModelVariationDialog";

export function InfoTireModelVariationDialog({
  open,
  onClose,
  tireModelId,
  tireModelVariationId,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const {
    permits,
    tireModelVariation,
    setNotification,
    getTireModelVariation,
    clearTireModelVariation,
    setTireModelVariationStatus,
    setTireModelVariationsApproved,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModelVariation(tireModelId, tireModelVariationId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && tireModelId && tireModelVariationId) {
      fetchData();
      return () => {
        clearTireModelVariation();
      };
    }
  }, [open]);

  async function changeStatus(tireModelVariationId, status) {
    try {
      const response = await setTireModelVariationStatus({
        tireModelId,
        status: status ? 0 : 1,
        tireModelVariationId,
      });
      onClose();
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }
  function handleStatusChange(tireModelVariationId, status) {
    setNotification({
      message: status ? t("messages.disable") : t("messages.enable"),
      textAction: t("_messages.confirm"),
      onAction: () => {
        changeStatus(tireModelVariationId, status);
      },
    });
  }
  async function changeApproved(tireModelVariationId, approved) {
    try {
      const response = await setTireModelVariationsApproved({
        tireModelId,
        approved: approved ? 0 : 1,
        tireModelVariationId,
      });
      onClose();
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }
  function handleApprovedChange(tireModelVariationId, approved) {
    setNotification({
      message: approved ? t("messages.disapprove") : t("messages.approve"),
      textAction: t("_messages.confirm"),
      onAction: () => {
        changeApproved(tireModelVariationId, approved);
      },
    });
  }

  return (
    <Page
      t={t}
      {...rest}
      open={open}
      tireModelVariation={{
        ...tireModelVariation,
        created_by: t("_labels.actioned_by", {
          person: `${tireModelVariation.created_by.name} ${
            tireModelVariation.created_by.last_name_1
          } ${tireModelVariation.created_by.last_name_2 || ""}`,
          date: moment(tireModelVariation.created_at).format("LL"),
          time: moment(tireModelVariation.created_at).format("h:mm a"),
        }),
        updated_by: tireModelVariation.updated_by
          ? t("_labels.actioned_by", {
              person: `${tireModelVariation.updated_by.name} ${
                tireModelVariation.updated_by.last_name_1
              } ${tireModelVariation.updated_by.last_name_2 || ""}`,
              date: moment(tireModelVariation.updated_at).format("LL"),
              time: moment(tireModelVariation.updated_at).format("h:mm a"),
            })
          : null,
      }}
      updateTireModelVariationPermit={permits.includes(
        UPDATE_TIRE_MODEL_VARIATION
      )}
      onClose={onClose}
      handleStatusChange={handleStatusChange}
      handleApprovedChange={handleApprovedChange}
    />
  );
}

InfoTireModelVariationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tireModelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tireModelVariationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  tireModelVariation: state.Tire.Model.Variation.variation,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireModelVariation: actionGetTireModelVariation(dispatch),
  clearTireModelVariation: actionClearTireModelVariation(dispatch),
  setTireModelVariationStatus: actionSetTireModelVariationStatus(dispatch),
  setTireModelVariationsApproved: actionSetTireModelVariationApproved(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoTireModelVariationDialog);
