export default {
  title: "Nuevo eje de tipo de vehículo",
  update_title: "Actualizar eje",
  form: {
    position: {
      default: "Selecciona una posición",
    },
    tire_quantity: {
      default: "Selecciona una cantidad de neumáticos",
    },
    permits: {
      create: {
        vehicle_type_axle:
          "Sin permisos para crear ejes de un tipo de vehículo",
      },
      update: {
        vehicle_type_axle:
          "Sin permisos para actualizar ejes de un tipo de vehículo",
      },
    },
  },
  messages: {
    vehicle_type_axles_created:
      "Listo, se crearon los ejes del tipo de vehículo con éxito",
    vehicle_type_axle_updated:
      "Listo, se actualizó el eje del tipo de vehículo con éxito",
  },
};
