import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import ContainerTemplate from "components/templates/general/Container";
import VisibilityIcon from "@material-ui/icons/Visibility";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";
import "./semaforos.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import AlertsDialog from "components/organisms/report/AlertsDialog";
import FilterTireSummaryForm from "components/organisms/report/FilterTireSummaryForm";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  dataChart,
  alerts,
  openDialog,
  handleViewAlerts,
  handleCloseDialog,
  openFilter,
  filter,
  selectedFilter,
  handleOpenFilter,
  handleCloseFilter,
}) {
  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.pile_summary_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="container-fluid pdl100 sidebar_font">
        {Object.keys(filter).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.company_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiary_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.movement && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.location.label")}:
                      </td>
                      <td>{selectedFilter.movement}</td>
                    </tr>
                  )}
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>{moment(selectedFilter.date_from).format("LL")}</td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>{moment(selectedFilter.date_to).format("LL")}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {!filter.movement && (
          <div className="row pdt12">
            <div className="col-md-12 text-center">
              <hr />
              <small className="sinmp" style={{ color: "lightcoral" }}>
                {t("_labels.location.label")}:{" "}
                {t("_labels.location.options.pile")}
              </small>
              <hr />
            </div>
          </div>
        )}

        {data.pressure && Object.keys(data.pressure).length > 0 && (
          <div className="row pt-4">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t(
                          "_labels.pile_summary_report.pressure_classification"
                        )}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data.pressure).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "pressure"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="pressure"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "pressure"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="pressure"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "pressure"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="pressure"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="division box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "pressure"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division ===
                                                      "NO DIVISION"
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {corporate[1][company][
                                                    subsidiary
                                                  ][division]["labels"].map(
                                                    (condition) => (
                                                      <Fragment key={condition}>
                                                        <tr
                                                          data-type="pressure"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                          )}
                                                          className={`box collapse condition ${
                                                            condition.includes(
                                                              "HIGH"
                                                            )
                                                              ? "set_sem_amarillo"
                                                              : condition.includes(
                                                                  "LOW"
                                                                )
                                                              ? "set_sem_rojo"
                                                              : "set_sem_verde"
                                                          }`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                              `${company}_${subsidiary}_${division}_${condition}`,
                                                              "pressure"
                                                            )
                                                          }
                                                        >
                                                          <td
                                                            className="text-left"
                                                            style={{
                                                              paddingTop: 27,
                                                            }}
                                                          >
                                                            <i
                                                              className={`bx bxs-circle ${
                                                                condition.includes(
                                                                  "HIGH"
                                                                )
                                                                  ? "set_sem_amarillo"
                                                                  : condition.includes(
                                                                      "LOW"
                                                                    )
                                                                  ? "set_sem_rojo"
                                                                  : "set_sem_verde"
                                                              }`}
                                                            />
                                                            &nbsp;
                                                            {t(
                                                              `_labels.summary_report.pressure_condition.${condition
                                                                .replaceAll(
                                                                  /\s/g,
                                                                  "_"
                                                                )
                                                                .toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col2">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col4">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_general.tires"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "statistics"
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-right col6">
                                                            <small>
                                                              {t(
                                                                "_labels.summary_report.percent"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {percentFormatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][condition]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              ![
                                                                "statistics",
                                                                "percent",
                                                              ].includes(index)
                                                          )
                                                          .map(
                                                            (application) => (
                                                              <Fragment
                                                                key={
                                                                  application
                                                                }
                                                              >
                                                                <tr
                                                                  data-type="pressure"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                  )}
                                                                  className="application box collapse"
                                                                  onClick={() =>
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`,
                                                                      `${company}_${subsidiary}_${division}_${condition}_${application}`,
                                                                      "pressure"
                                                                    )
                                                                  }
                                                                >
                                                                  <td
                                                                    className="text-left"
                                                                    style={{
                                                                      paddingTop: 27,
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      `_labels.tire_application.options.${application.toLowerCase()}`
                                                                    )}
                                                                  </td>
                                                                  <td className="text-left col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col3">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col4">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col5">
                                                                    <small>
                                                                      {t(
                                                                        "_general.tires"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "statistics"
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    <small>
                                                                      {t(
                                                                        "_labels.summary_report.percent"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {percentFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "percent"
                                                                      ]
                                                                    )}
                                                                    %
                                                                  </td>
                                                                </tr>

                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                ).filter(
                                                                  (index) =>
                                                                    ![
                                                                      "statistics",
                                                                      "percent",
                                                                    ].includes(
                                                                      index
                                                                    )
                                                                ).length >
                                                                  0 && (
                                                                  <tr
                                                                    data-type="pressure"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                    )}
                                                                    className="tire box collapse"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "lightgray",
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    <td className="text-left  ">
                                                                      {t(
                                                                        "_labels.code"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.model"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.size"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.location.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.options.refection"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.pressure"
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                )
                                                                  .filter(
                                                                    (index) =>
                                                                      ![
                                                                        "statistics",
                                                                        "percent",
                                                                      ].includes(
                                                                        index
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (tire) => (
                                                                      <tr
                                                                        data-type="pressure"
                                                                        data-id={removeCharacters(
                                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                        )}
                                                                        key={`${corporate[1][company][subsidiary][division][condition][application][tire]["movement_tire_id"]}_${tire}`}
                                                                        className="tire box collapse"
                                                                        style={{
                                                                          backgroundColor:
                                                                            "white",
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      >
                                                                        <td className="text-left text-muted ">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "code"
                                                                          ] ||
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "device_code"
                                                                            ]}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "model"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "size"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "location"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "real_position"
                                                                          ] ||
                                                                            "-"}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "is_refection"
                                                                          ]
                                                                            ? t(
                                                                                "_labels.yes"
                                                                              )
                                                                            : t(
                                                                                "_labels.no"
                                                                              )}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "pressure"
                                                                            ]
                                                                          }{" "}
                                                                          psi
                                                                        </td>
                                                                      </tr>
                                                                    )
                                                                  )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                      </Fragment>
                                                    )
                                                  )}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {dataChart.pressure && Object.keys(dataChart.pressure).length > 0 && (
          <>
            {Object.keys(dataChart.pressure).map((corporate, index) => (
              <div className="row pt-1 pb-3" key={index}>
                <div className="col-md-12">
                  <div className="container-fluid px-4">
                    <div className="card">
                      {Object.keys(dataChart.pressure).length > 1 && (
                        <div className="card-header">{corporate}</div>
                      )}
                      <div className="charts">
                        {Object.keys(dataChart.pressure[corporate]).map(
                          (division) => (
                            <div key={division} className="chart">
                              <DoughnutChart
                                data={dataChart.pressure[corporate][division]}
                                title={division}
                                tooltipOptions={{
                                  tooltip: {
                                    callbacks: {
                                      label: (context) => {
                                        return `${
                                          context.label
                                        }: ${percentFormatter.format(
                                          context.parsed
                                        )}%`;
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {data.depth && Object.keys(data.depth).length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t("_labels.pile_summary_report.depth_classification")}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data.depth).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "depth"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="depth"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "depth"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="depth"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "depth"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="depth"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="division box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "depth"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division ===
                                                      "NO DIVISION"
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {corporate[1][company][
                                                    subsidiary
                                                  ][division]["labels"].map(
                                                    (condition) => (
                                                      <Fragment key={condition}>
                                                        <tr
                                                          data-type="depth"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                          )}
                                                          className={`box collapse condition ${
                                                            condition.includes(
                                                              "SCHEDULED"
                                                            )
                                                              ? "set_sem_amarillo"
                                                              : condition.includes(
                                                                  "CRITICAL"
                                                                )
                                                              ? "set_sem_rojo"
                                                              : "set_sem_verde"
                                                          }`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                              `${company}_${subsidiary}_${division}_${condition}`,
                                                              "depth"
                                                            )
                                                          }
                                                        >
                                                          <td
                                                            className="text-left"
                                                            style={{
                                                              paddingTop: 27,
                                                            }}
                                                          >
                                                            <i
                                                              className={`bx bxs-circle ${
                                                                condition.includes(
                                                                  "SCHEDULED"
                                                                )
                                                                  ? "set_sem_amarillo"
                                                                  : condition.includes(
                                                                      "CRITICAL"
                                                                    )
                                                                  ? "set_sem_rojo"
                                                                  : "set_sem_verde"
                                                              }`}
                                                            />
                                                            &nbsp;
                                                            {t(
                                                              `_labels.summary_report.depth_condition.${removeCharacters(
                                                                condition,
                                                                "_"
                                                              ).toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col2">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col4">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_general.tires"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "statistics"
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-right col6">
                                                            <small>
                                                              {t(
                                                                "_labels.summary_report.percent"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {percentFormatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][condition]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              ![
                                                                "statistics",
                                                                "percent",
                                                              ].includes(index)
                                                          )
                                                          .map(
                                                            (application) => (
                                                              <Fragment
                                                                key={
                                                                  application
                                                                }
                                                              >
                                                                <tr
                                                                  data-type="depth"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                  )}
                                                                  className="application box collapse"
                                                                  onClick={() =>
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`,
                                                                      `${company}_${subsidiary}_${division}_${condition}_${application}`,
                                                                      "depth"
                                                                    )
                                                                  }
                                                                >
                                                                  <td
                                                                    className="text-left"
                                                                    style={{
                                                                      paddingTop: 27,
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      `_labels.tire_application.options.${application.toLowerCase()}`
                                                                    )}
                                                                  </td>
                                                                  <td className="text-left col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col3">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col4">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col5">
                                                                    <small>
                                                                      {t(
                                                                        "_general.tires"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "statistics"
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    <small>
                                                                      {t(
                                                                        "_labels.summary_report.percent"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {percentFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "percent"
                                                                      ]
                                                                    )}
                                                                    %
                                                                  </td>
                                                                </tr>

                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                ).filter(
                                                                  (index) =>
                                                                    ![
                                                                      "statistics",
                                                                      "percent",
                                                                    ].includes(
                                                                      index
                                                                    )
                                                                ).length >
                                                                  0 && (
                                                                  <tr
                                                                    data-type="depth"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                    )}
                                                                    className="tire box collapse"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "lightgray",
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    <td className="text-left  ">
                                                                      {t(
                                                                        "_labels.code"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.model"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.size"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.location.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.options.refection"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.depth"
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                )
                                                                  .filter(
                                                                    (index) =>
                                                                      ![
                                                                        "statistics",
                                                                        "percent",
                                                                      ].includes(
                                                                        index
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (tire) => (
                                                                      <tr
                                                                        data-type="depth"
                                                                        data-id={removeCharacters(
                                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                        )}
                                                                        key={`${corporate[1][company][subsidiary][division][condition][application][tire]["movement_tire_id"]}_${tire}`}
                                                                        className="tire box collapse"
                                                                        style={{
                                                                          backgroundColor:
                                                                            "white",
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      >
                                                                        <td className="text-left text-muted ">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "code"
                                                                          ] ||
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "device_code"
                                                                            ]}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "model"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "size"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "location"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "real_position"
                                                                          ] ||
                                                                            "-"}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][
                                                                            "is_refection"
                                                                          ]
                                                                            ? t(
                                                                                "_labels.yes"
                                                                              )
                                                                            : t(
                                                                                "_labels.no"
                                                                              )}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][
                                                                              "depth"
                                                                            ]
                                                                          }{" "}
                                                                          mm
                                                                        </td>
                                                                      </tr>
                                                                    )
                                                                  )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                      </Fragment>
                                                    )
                                                  )}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {dataChart.depth && Object.keys(dataChart.depth).length > 0 && (
          <>
            {Object.keys(dataChart.depth).map((corporate, index) => (
              <div className="row pt-1 pb-3" key={index}>
                <div className="col-md-12">
                  <div className="container-fluid px-4">
                    <div className="card">
                      {Object.keys(dataChart.depth).length > 1 && (
                        <div className="card-header">{corporate}</div>
                      )}
                      <div className="charts">
                        {Object.keys(dataChart.depth[corporate]).map(
                          (division) => (
                            <div key={division} className="chart">
                              <DoughnutChart
                                data={dataChart.depth[corporate][division]}
                                title={division}
                                tooltipOptions={{
                                  tooltip: {
                                    callbacks: {
                                      label: (context) => {
                                        return `${
                                          context.label
                                        }: ${percentFormatter.format(
                                          context.parsed
                                        )}%`;
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {data.alert && Object.keys(data.alert).length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        {t("_labels.pile_summary_report.alert_classification")}
                      </h4>
                      <div className="panel-body">
                        <table className="table table-condensed">
                          {Object.entries(data.alert).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "alert"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4" />
                                  <th className="text-right col5">
                                    {corporate[1]["statistics"]}
                                  </th>
                                  <th className="text-right col6">
                                    {percentFormatter.format(
                                      corporate[1]["percent"]
                                    )}
                                    %
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      !["statistics", "percent"].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="alert"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "alert"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4" />
                                        <td className="text-right col5">
                                          {corporate[1][company]["statistics"]}
                                        </td>
                                        <td className="text-right col6">
                                          {percentFormatter.format(
                                            corporate[1][company]["percent"]
                                          )}
                                          %
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            !["statistics", "percent"].includes(
                                              index
                                            )
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="alert"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "alert"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4" />
                                              <td className="text-right col5">
                                                {
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                }
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["percent"]
                                                )}
                                                %
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "percent",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="alert"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="division box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "alert"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division ===
                                                      "NO DIVISION"
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4" />
                                                    <td className="text-right col5">
                                                      {
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      }
                                                    </td>
                                                    <td className="text-right col6">
                                                      {percentFormatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division]["percent"]
                                                      )}
                                                      %
                                                    </td>
                                                  </tr>

                                                  {Object.keys(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ][division]
                                                  )
                                                    .filter(
                                                      (index) =>
                                                        ![
                                                          "statistics",
                                                          "percent",
                                                        ].includes(index)
                                                    )
                                                    .map((condition) => (
                                                      <Fragment key={condition}>
                                                        <tr
                                                          data-type="alert"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                          )}
                                                          className={`box collapse condition ${
                                                            condition.includes(
                                                              "LOW"
                                                            )
                                                              ? "set_sem_amarillo"
                                                              : condition.includes(
                                                                  "HIGH"
                                                                )
                                                              ? "set_sem_rojo"
                                                              : "set_sem_verde"
                                                          }`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                              `${company}_${subsidiary}_${division}_${condition}`,
                                                              "alert"
                                                            )
                                                          }
                                                        >
                                                          <td
                                                            className="text-left"
                                                            style={{
                                                              paddingTop: 27,
                                                            }}
                                                          >
                                                            <i
                                                              className={`bx bxs-circle ${
                                                                condition.includes(
                                                                  "LOW"
                                                                )
                                                                  ? "set_sem_amarillo"
                                                                  : condition.includes(
                                                                      "HIGH"
                                                                    )
                                                                  ? "set_sem_rojo"
                                                                  : "set_sem_verde"
                                                              }`}
                                                            />
                                                            &nbsp;
                                                            {t(
                                                              `_labels.priority.options.${condition
                                                                .replaceAll(
                                                                  /\s/g,
                                                                  "_"
                                                                )
                                                                .toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col2">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col4">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col5">
                                                            <small>
                                                              {t(
                                                                "_general.alerts"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "statistics"
                                                              ]
                                                            }
                                                          </td>
                                                          <td className="text-right col6">
                                                            <small>
                                                              {t(
                                                                "_labels.summary_report.percent"
                                                              )}
                                                            </small>
                                                            <br />
                                                            {percentFormatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "percent"
                                                              ]
                                                            )}
                                                            %
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][condition]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              ![
                                                                "statistics",
                                                                "percent",
                                                              ].includes(index)
                                                          )
                                                          .map(
                                                            (application) => (
                                                              <Fragment
                                                                key={
                                                                  application
                                                                }
                                                              >
                                                                <tr
                                                                  data-type="alert"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                  )}
                                                                  className="application box collapse"
                                                                  onClick={() =>
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`,
                                                                      `${company}_${subsidiary}_${division}_${condition}_${application}`,
                                                                      "alert"
                                                                    )
                                                                  }
                                                                >
                                                                  <td
                                                                    className="text-left"
                                                                    style={{
                                                                      paddingTop: 27,
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      `_labels.tire_application.options.${application.toLowerCase()}`
                                                                    )}
                                                                  </td>
                                                                  <td className="text-left col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col3">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col4">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col5">
                                                                    <small>
                                                                      {t(
                                                                        "_general.alerts"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "statistics"
                                                                      ]
                                                                    }
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    <small>
                                                                      {t(
                                                                        "_labels.summary_report.percent"
                                                                      )}
                                                                    </small>
                                                                    <br />
                                                                    {percentFormatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "percent"
                                                                      ]
                                                                    )}
                                                                    %
                                                                  </td>
                                                                </tr>

                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                ).filter(
                                                                  (index) =>
                                                                    ![
                                                                      "statistics",
                                                                      "percent",
                                                                    ].includes(
                                                                      index
                                                                    )
                                                                ).length >
                                                                  0 && (
                                                                  <tr
                                                                    data-type="alert"
                                                                    data-id={removeCharacters(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                    )}
                                                                    className="tire box collapse"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "lightgray",
                                                                      color:
                                                                        "black",
                                                                    }}
                                                                  >
                                                                    <td className="text-left  ">
                                                                      {t(
                                                                        "_labels.code"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.model"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.size"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.location.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.label"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.vehicle_type_axle_position.options.refection"
                                                                      )}
                                                                    </td>
                                                                    <td className="text-right col5">
                                                                      {t(
                                                                        "_labels.pile_summary_report.view_alerts"
                                                                      )}
                                                                    </td>
                                                                  </tr>
                                                                )}
                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                )
                                                                  .filter(
                                                                    (index) =>
                                                                      ![
                                                                        "statistics",
                                                                        "percent",
                                                                      ].includes(
                                                                        index
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      tire,
                                                                      index
                                                                    ) => (
                                                                      <tr
                                                                        data-type="alert"
                                                                        data-id={removeCharacters(
                                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_tires`
                                                                        )}
                                                                        key={`alert_${index}`}
                                                                        className="tire box collapse"
                                                                        style={{
                                                                          backgroundColor:
                                                                            "white",
                                                                          color:
                                                                            "black",
                                                                        }}
                                                                      >
                                                                        <td className="text-left text-muted">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][0][
                                                                            "code"
                                                                          ] ||
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][0][
                                                                              "device_code"
                                                                            ]}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][0][
                                                                              "model"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][0][
                                                                              "size"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {
                                                                            corporate[1][
                                                                              company
                                                                            ][
                                                                              subsidiary
                                                                            ][
                                                                              division
                                                                            ][
                                                                              condition
                                                                            ][
                                                                              application
                                                                            ][
                                                                              tire
                                                                            ][0][
                                                                              "location"
                                                                            ]
                                                                          }
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][0][
                                                                            "real_position"
                                                                          ] ||
                                                                            "-"}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          {corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            tire
                                                                          ][0][
                                                                            "is_refection"
                                                                          ]
                                                                            ? t(
                                                                                "_labels.yes"
                                                                              )
                                                                            : t(
                                                                                "_labels.no"
                                                                              )}
                                                                        </td>
                                                                        <td className="text-right text-muted col5">
                                                                          <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() =>
                                                                              handleViewAlerts(
                                                                                corporate[1][
                                                                                  company
                                                                                ][
                                                                                  subsidiary
                                                                                ][
                                                                                  division
                                                                                ][
                                                                                  condition
                                                                                ][
                                                                                  application
                                                                                ][
                                                                                  tire
                                                                                ]
                                                                              )
                                                                            }
                                                                          >
                                                                            {" "}
                                                                            <VisibilityIcon />{" "}
                                                                          </button>
                                                                        </td>
                                                                      </tr>
                                                                    )
                                                                  )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                      </Fragment>
                                                    ))}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {dataChart.alert && Object.keys(dataChart.alert).length > 0 && (
          <>
            {Object.keys(dataChart.alert).map((corporate, index) => (
              <div className="row pt-1 pb-3" key={index}>
                <div className="col-md-12">
                  <div className="container-fluid px-4">
                    <div className="card">
                      {Object.keys(dataChart.alert).length > 1 && (
                        <div className="card-header">{corporate}</div>
                      )}
                      <div className="charts">
                        {Object.keys(dataChart.alert[corporate]).map(
                          (division) => (
                            <div key={division} className="chart">
                              <DoughnutChart
                                data={dataChart.alert[corporate][division]}
                                title={division}
                                tooltipOptions={{
                                  tooltip: {
                                    callbacks: {
                                      label: (context) => {
                                        return `${
                                          context.label
                                        }: ${percentFormatter.format(
                                          context.parsed
                                        )}%`;
                                      },
                                    },
                                  },
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <AlertsDialog
        alerts={alerts}
        open={openDialog}
        onClose={handleCloseDialog}
      />
      <FilterTireSummaryForm
        isPile={true}
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  dataChart: PropTypes.object,
  alerts: PropTypes.array.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleViewAlerts: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
};

export default Page;

function removeCharacters(string, replacer = "") {
  return string.replaceAll(/[,\s\.\/]/g, replacer);
}
