import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import "./index.css";
import Page from "./page";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

const priceFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  useGrouping: false,
});
function TableForm({ original, setSummaryData, ...rest }) {
  const t = useLang();
  const { values, setValues, data, actualPurchase, replacementData } = rest;

  useEffect(() => {
    const prices = Array.from(
      document.querySelectorAll(
        `input[data-type=price]${
          original ? "[data-original=true]" : "[data-original=false]"
        }`
      )
    );
    const quantities = Array.from(
      document.querySelectorAll(
        `input[data-type=quantity]${
          original ? "[data-original=true]" : "[data-original=false]"
        }`
      )
    );

    const totalPrices = prices.reduce((total, element, index) => {
      const application = element.dataset.application;
      total[application] = total[application] ?? 0;

      if (quantities[index].value != 0) {
        const value = isNaN(parseFloat(element.value))
          ? 0
          : parseFloat(element.value);
        total[application] += value;
      }
      return total;
    }, {});

    const totalQuantities = quantities.reduce((total, element) => {
      const application = element.dataset.application;

      total[application] = total[application] ?? 0;
      const value = isNaN(parseFloat(element.value))
        ? 0
        : parseFloat(element.value);
      total[application] += value;
      return total;
    }, {});

    const aux = {};
    prices.reduce((total, element, index) => {
      const quantity = isNaN(parseFloat(quantities[index].value))
        ? 0
        : quantities[index].value;
      const price = isNaN(parseFloat(element.value))
        ? 0
        : parseFloat(element.value);
      const result = price * quantity;
      const application = element.dataset.application;

      aux[application] = aux[application] ?? 0;
      aux[application] += result;
      total += result;

      return total;
    }, 0);

    const joinedResult = Object.keys(totalPrices).reduce((acc, key) => {
      acc[key] = acc[key] ?? {};
      acc[key].price = totalPrices[key];
      acc[key].quantity = totalQuantities[key];
      acc[key].total = aux[key];

      return acc;
    }, {});

    const result = Object.keys(joinedResult).reduce(
      (acc, application) => {
        acc["quantity"] += joinedResult[application].quantity;
        acc["price"] += joinedResult[application].price;
        acc["total"] += joinedResult[application].total;
        return acc;
      },
      { price: 0, quantity: 0, total: 0 }
    );
    if (original) {
      setSummaryData((prevValue) => ({
        ...prevValue,
        original: { ...joinedResult, ...result },
      }));
    } else {
      setSummaryData((prevValue) => ({
        ...prevValue,
        revitalized: { ...joinedResult, ...result },
      }));
    }
  }, [values.data]);

  useEffect(() => {
    setValues({
      save: false,
      data: [
        ...data.map((row) => ({
          ...row,
          required:
            actualPurchase[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ] ?? 0,
          quantity: getRealQuantity(
            actualPurchase[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ] ?? 0,
            replacementData[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ]
          ),
          stock:
            replacementData[
              original
                ? row.tire_model_variation_id
                : row.revitalized_tire_model_id
            ] ?? 0,
          cpko: formatter.format(row.cpko || 0),
          cpkr: formatter.format(row.cpkr || 0),
          cpk: original
            ? formatter.format(row.cpko)
            : formatter.format(row.cpkr || 0),
          price: original
            ? priceFormatter.format(parseFloat(row.price))
            : priceFormatter.format(parseFloat(row.price_revitalized)),
        })),
      ],
    });
  }, [data]);

  return <Page original={original} t={t} {...rest} />;
}

TableForm.propTypes = {
  data: PropTypes.array,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  actualPurchase: PropTypes.object,
  replacementData: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  application: PropTypes.string.isRequired,
  original: PropTypes.bool,
  setSummaryData: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TableForm);

function getRealQuantity(actualPurchase, replacement) {
  const required = actualPurchase || 0;

  if (required === 0) {
    return 0;
  }

  const inStock = replacement ?? 0;

  if (required - inStock < 0) {
    return 0;
  }

  return required - inStock;
}
