import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";

import { actionGetTire } from "store/actions/tire/getTire";
import { actionSetNotification } from "store/actions/general/notification";

import { SHOW_TIRE } from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE = "src/components/organisms/tire/TireHistoryTabs";

export function TireHistoryTabs({
  children,
  className,
  optionsAppBar,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const { id: tireId } = useParams();
  const { tire, permits, getTire, setNotification } = rest;
  const showTirePermit = permits.includes(SHOW_TIRE);

  const tabLinks = [
    {
      to: `/tire/${tireId}/review/history`,
      label: t("_labels.review.plural"),
    },
    {
      to: `/tire/${tireId}/vehicle/history`,
      label: t("_labels.vehicle.plural"),
    },
    {
      to: `/tire/${tireId}/revitalization/history`,
      label: t("revitalization"),
    },
    {
      to: `/tire/${tireId}/repair/history`,
      label: t("repair"),
    },
    {
      to: `/tire/${tireId}/cycle/history`,
      label: t("cycle"),
    },
    {
      to: `/tire/${tireId}/warehouse/history`,
      label: t("_labels.warehouse.plural"),
    },
    {
      to: `/tire/${tireId}/pile/history`,
      label: t("pile"),
    },
    {
      to: `/tire/${tireId}/division/history`,
      label: t("_labels.division.plural"),
    },
    {
      to: `/tire/${tireId}/subsidiary/history`,
      label: t("_labels.subsidiary.plural"),
    },
    {
      to: `/tire/${tireId}/alert/history`,
      label: t("_labels.alerts"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showTirePermit) {
      fetchData();
    }
  }, [tireId]);

  return (
    <Page
      tabLinks={tabLinks}
      className={className}
      optionsAppBar={optionsAppBar}
      title={
        showTirePermit
          ? `${t("history_of_tire")} ${tire.code}`
          : t("_permits.show.tire")
      }
    >
      {children}
    </Page>
  );
}

TireHistoryTabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  optionsAppBar: PropTypes.node,
};

const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  getTire: actionGetTire(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireHistoryTabs);
