export default {
  new_damage: "Nuevo daño",
  update_damage: "Actualizar daño",
  form: {
    permits: {
      update: "Sin permisos para actualizar daños",
      delete: "Sin permisos para eliminar daños",
    },
  },
  messages: {
    damage_updated: "Listo, se actualizo el daño con éxito",
    damage_created: "Listo, se creo un nuevo daño con éxito",
    damage_deleted: "Listo, el daño fue eliminado con éxito",
    delete: "¿Estas seguro de eliminar el daño?",
  },
};
