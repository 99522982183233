import { GET_GPS_GEOFENCE } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetGpsGeofence =
  (dispatch) => async (gpsId, gpsGeofenceId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `gps/${gpsId}/geofence/${gpsGeofenceId}`,
      });

      dispatch({
        type: GET_GPS_GEOFENCE,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
