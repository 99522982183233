import company from "./company";
import corporate from "./corporate";
import general from "./general";
import subsidiary from "./subsidiary";
import user from "./user";
import wear from "./wear";
import provider from "./provider";
import warehouse from "./warehouse";
import driver from "./driver";
import tire from "./tire";
import vehicle from "./vehicle";
import gps from "./gps";
import division from "./division";
import damage from "./damage";

export default {
  company,
  corporate,
  general,
  subsidiary,
  user,
  wear,
  provider,
  warehouse,
  driver,
  tire,
  vehicle,
  gps,
  division,
  damage,
};
