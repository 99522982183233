import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";

import { arrayJoin } from "utils/parse";

import { actionGetTireModel } from "store/actions/tire/model/getTireModel";
import { actionClearTireModel } from "store/actions/tire/model/clearTireModel";
import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/model/InfoTireModelDialog";

export function InfoTireModelDialog({ modelId, ...rest }) {
  const t = useLang(NAMESPACE);
  const { open, model, getTireModel, clearTireModel, setNotification } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModel(modelId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && modelId) {
      fetchData();
      return () => {
        clearTireModel();
      };
    }
  }, [open]);

  return (
    <Page
      t={t}
      {...rest}
      model={{
        ...model,
        tire_model_use: arrayJoin(
          model.tire_model_use.map((use) =>
            t(`_labels.tire_use.options.${use.tire_use_id.toLowerCase()}`)
          ),
          ", ",
          ` ${t("_general.text.and")} `
        ),
        tire_model_application: arrayJoin(
          model.tire_model_application.map((application) =>
            t(
              `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
            )
          ),
          ", ",
          ` ${t("_general.text.and")} `
        ),
      }}
    />
  );
}

InfoTireModelDialog.propTypes = {
  modelId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  model: state.Tire.Model.Model.model,
});
const mapDispatchToProps = (dispatch) => ({
  getTireModel: actionGetTireModel(dispatch),
  clearTireModel: actionClearTireModel(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoTireModelDialog);
