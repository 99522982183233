import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAlertOfMount } from "store/actions/vehicle/tire/alert/getAlertByMount";
import { actionClearAlertOfMount } from "store/actions/vehicle/tire/alert/clearAlertByMount";
import { actionUpdateAlertOfMount } from "store/actions/vehicle/tire/alert/updateAlertByMount";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function UpdateCommentForm({
  open,
  onClose,
  alertId,
  vehicleTireId,
  ...rest
}) {
  const t = useLang();
  const {
    alert,
    setNotification,
    getAlertOfMount,
    clearAlertOfMount,
    updateAlertOfMount,
  } = rest;

  const initialDefaultValues = {
    comment: "",
  };
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getAlertOfMount(vehicleTireId, alertId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (alertId && open) {
      fetchData();
    }

    return () => {
      clearAlertOfMount();
    };
  }, [alertId, open]);

  useEffect(() => {
    setInitialValues({
      comment: alert.comment || "",
    });
  }, [alert, alertId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = values;
      await updateAlertOfMount(vehicleTireId, alertId, fields);
      setNotification({
        message: t("_messages.updated.alert"),
      });
      setSubmitting(false);
      resetForm();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }
  return (
    <Page
      t={t}
      open={open}
      alert={alert}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UpdateCommentForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleTireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alertId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  alert: state.MountAlerts.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getAlertOfMount: actionGetAlertOfMount(dispatch),
  clearAlertOfMount: actionClearAlertOfMount(dispatch),
  updateAlertOfMount: actionUpdateAlertOfMount(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCommentForm);
