import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { useLang } from "hooks/lang";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const costKmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

function Page({ open, onClose, tires }) {
  const t = useLang();
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {`: ${tires.length}`}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <td className="text-center">{t("_labels.code")}</td>
                  <td className="text-center col3 ">
                    {t("_labels.report_pile.cost_km")}
                  </td>
                  <td className="text-center col4">{t("_labels.mileage")}</td>
                  <td className="text-center col5">
                    {t("_labels.economic_number")}
                  </td>
                  <td className="text-center col6">
                    {t("_labels.tires_mount_report.position")}
                  </td>
                </tr>
              </thead>
              <tbody>
                {tires.map((tire, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/tire/${tire["tire_id"]}/history`}
                        target="_blank"
                        rel="noopener"
                      >
                        <button className="col5 btn btn-sm btn-success d-flex justify-content-center align-items-stretch">
                          <VisibilityIcon fontSize="small" className="mr-1" />
                          {tire["code"]}
                        </button>
                      </Link>
                    </td>
                    <td className="text-center col3">
                      ${costKmFormatter.format(tire["cpk"])}
                    </td>
                    <td className="text-center col4">
                      {formatter.format(tire["tire_travel"])}
                    </td>
                    <td className="text-center col5">
                      {tire["economic_number"]}
                    </td>
                    <td className="text-center col6">
                      {tire["real_position"]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
