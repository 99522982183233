import { DELETE_TIRE_SIZE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteTireSize = (dispatch) => async (tireSizeId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `tire/size/${tireSizeId}`,
    });
    dispatch({
      type: DELETE_TIRE_SIZE,
      payload: tireSizeId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
