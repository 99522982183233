export default {
  title: "Linked tires",
  form_title: "Link tire",
  form: {
    permits: {
      list: {
        tire: "No permissions to view tire listings",
      },
    },
  },
  permits: {
    link: {
      division_tire: "No permission to link tires with divisions",
    },
    show_division_tire: "No permissions to see tires linked",
    unlink_division_tire: "No permissions to unlink tires from divisions",
  },
  messages: {
    delete: "Are you sure to unlink tire?",
    delete_division_tire: "Done, the tire has been unlinked from the division",
    linked_division_tire: "Done, the tire has been successfully linked",
  },
};
