import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import { Field } from "formik";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 4,
  minimumFractionDigits: 2,
});

function Page({
  t,
  application,
  original,
  values,
  setFieldValue,
  isEditable,
  handleToggleEditable,
}) {
  return (
    <>
      {values.data
        .sort(
          (a, b) => (a["cpk"] == 0) - (b["cpk"] == 0) || a["cpk"] - b["cpk"]
        )
        .map((row, index) => (
          <tr
            key={index}
            style={{ backgroundColor: !row.pile ? "#e4eff7" : "white" }}
          >
            <td className="text-center align-middle">{index + 1}</td>
            <td className="text-center align-middle">{row.model}</td>
            <td className="text-center align-middle">{row.tires}</td>
            <td className="text-center align-middle">
              {formatter.format(row.tire_travel)}
            </td>
            <td className="text-center align-middle">
              $
              {typeof row.cpk != "string"
                ? decimalFormatter.format(row.cpk)
                : row.cpk}
            </td>
            <td className="text-center align-middle">
              {formatter.format(row.number_layers)}
            </td>
            <td className="text-center align-middle">
              {formatter.format(row.required)}
            </td>
            <td className="text-center align-middle">
              {formatter.format(row.stock)}
            </td>
            <td className="text-center align-middle">
              <Field
                data-original={original ? true : false}
                data-type="quantity"
                data-application={application}
                data-model={row.model}
                name={`data[${index}].quantity`}
                type="number"
                className="form-control form-control-sm"
                step={1}
                min={0}
                style={{ fontSize: 12 }}
                disabled={!isEditable}
              />
            </td>
            <td className="text-center align-middle">{`${moment(
              row.date
            ).format("LL")}`}</td>
            <td className="text-center align-middle">
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ fontSize: 12 }}>
                    $
                  </span>
                </div>
                <Field
                  data-original={original ? true : false}
                  data-type="price"
                  data-application={application}
                  name={`data[${index}].price`}
                  type="number"
                  className="form-control form-control-sm"
                  min={0}
                  step="any"
                  style={{ fontSize: 12 }}
                  disabled={!isEditable}
                  onBlur={() =>
                    setFieldValue(
                      `data[${index}].cpk`,
                      values.data[index].price / row.tire_travel
                    )
                  }
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      setFieldValue(
                        `data[${index}].cpk`,
                        values.data[index].price / row.tire_travel
                      );
                      e.target.blur();
                    }
                  }}
                />
              </div>
            </td>

            <td className="text-center align-middle">
              ${decimalFormatter.format(row.quantity * row.price)}
            </td>
          </tr>
        ))}

      {renderTotalRow(
        values.data,
        isEditable,
        handleToggleEditable,
        t,
        setFieldValue
      )}
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  application: PropTypes.string.isRequired,
  original: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  handleToggleEditable: PropTypes.func.isRequired,
};

export default Page;

function renderTotalRow(
  array,
  isEditable,
  handleToggleEditable,
  t,
  setFieldValue
) {
  const { quantity, total, required } = array.reduce(
    (acc, value) => {
      const quantity = value.quantity ?? 0;
      const price = parseFloat(value.price) ?? 0;
      const total = quantity * price;
      const required = value.required;

      acc["quantity"] += quantity;
      acc["price"] += price;
      acc["total"] += total;
      acc["required"] += required;
      return acc;
    },
    { quantity: 0, price: 0, total: 0, required: 0 }
  );

  return (
    <tr className="sbngris">
      <th className="text-center align-middle">
        <button
          type="button"
          className={classnames("btn btn-sm w-100", {
            ["btn-primary"]: !isEditable,
            ["btn-success"]: isEditable,
          })}
          onClick={() => {
            setFieldValue("save", isEditable);
            handleToggleEditable();
          }}
        >
          {isEditable ? t("_buttons.save") : t("_buttons.edit")}
        </button>
      </th>
      <th className="text-right align-middle" colSpan={9}>
        {t("_labels.required")}: {required}
      </th>
      <th className="text-right align-middle">
        Total: {formatter.format(quantity)}
      </th>
      <th className="text-center align-middle">
        ${decimalFormatter.format(isNaN(total) ? 0 : total)}
      </th>
    </tr>
  );
}
