export default {
  title: "New division",
  update_title: "Update division",
  form: {
    permits: {
      delete: "No permissions to delete divisions",
      update: "No permissions to update divisions",
    },
  },
  messages: {
    delete_division: "Are you sure to delete the division?",
    division_created: "Done, a new division has been successfully created",
    division_updated: "Done, the division has been successfully updated",
  },
};
