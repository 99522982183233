export default {
  disabled_model: "Modelo deshabilitado",
  model_brand: "Marca del modelo",
  buttons: {
    view_technical_sheet: "Ver ficha técnica",
  },
  messages: {
    approve: "¿Estas seguro de aprobar el modelo de neumático?",
    disapprove: "¿Estas seguro de desaprobar el modelo de neumático?",
    disable: "¿Estas seguro de deshabilitar el modelo?",
    enable: "¿Estas seguro de habilitar el modelo?",
  },
  permits: {
    change_approval_status: "Sin permisos para {action} modelos de neumáticos",
    change_status: "No tienes permiso para {action} el modelo del neumático",
  },
};
