export default {
  filter: "Filtro",
  form: {
    brand_field: {
      label: "Marcas",
      helper_text: "Se pueden seleccionar varias marcas",
    },
    tire_size_field: {
      label: "Medidas",
      helper_text: "Se pueden seleccionar varias medidas",
    },
  },
};
