import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TableResponsive from "components/organisms/general/TableResponsive";
// import CompanyTabs from "components/organisms/company/CompanyTabs";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import DepthToleranceForm from "components/organisms/policy/depth_tolerance/DepthToleranceForm";

import css from "./index.module.scss";

function Page({
  t,
  id,
  depthTolerancePolicyId,
  policies,
  openForm,
  updateDepthTolerancePolicyPermit,
  handleOpenForm,
  handleCloseForm,
}) {
  return (
    <SubsidiaryTabs>
      <div className={classnames(css.container)}>
        <TableResponsive
          rows={policies.data.map((policy) => {
            return {
              id: policy.depth_tolerance_policy_id,
              key: policy.depth_tolerance_policy_id,
              subsidiary: policy.subsidiary.name,
              tire_application_id: t(
                `_labels.tire_application.options.${policy.tire_application_id.toLowerCase()}`
              ),
              scheduled_withdrawal: `${policy.scheduled_withdrawal} mm`,
              critical_withdrawal: `${policy.critical_withdrawal} mm`,
              maximum_number_patches: policy.maximum_number_patches,
              critical_number_patches: policy.critical_number_patches,
              edit: {
                tooltipPermit: !updateDepthTolerancePolicyPermit
                  ? t("_permits.update.company_policy")
                  : "",
              },
            };
          })}
          headers={[
            { id: "subsidiary", label: t("_labels.subsidiary.singular") },
            {
              id: "tire_application_id",
              label: t("_labels.depth_tolerance_policies.tire_application_id"),
            },
            {
              id: "scheduled_withdrawal",
              label: t("_labels.depth_tolerance_policies.scheduled_withdrawal"),
            },
            {
              id: "critical_withdrawal",
              label: t("_labels.depth_tolerance_policies.critical_withdrawal"),
            },
            {
              id: "maximum_number_patches",
              label: t(
                "_labels.depth_tolerance_policies.maximum_number_patches"
              ),
            },
            {
              id: "critical_number_patches",
              label: t(
                "_labels.depth_tolerance_policies.critical_number_patches"
              ),
            },
            { id: "actions", label: "" },
          ]}
          onDelete={() => {}}
          onEdit={handleOpenForm}
          onChangeStatus={() => {}}
          onChangeApproved={() => {}}
        />
      </div>

      <DepthToleranceForm
        open={openForm}
        onClose={handleCloseForm}
        subsidiaryId={id}
        depthTolerancePolicyId={depthTolerancePolicyId}
      />
    </SubsidiaryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  depthTolerancePolicyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  policies: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired,
  updateDepthTolerancePolicyPermit: PropTypes.bool.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  getDepthTolerancePolicies: PropTypes.func.isRequired,
};

export default Page;
