import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Typography from "@material-ui/core/Typography";

import { getMinDepth } from "utils/tire";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import UpdateTireReviewForm from "../UpdateTireReviewForm";

import css from "./index.module.scss";

function Page({
  t,
  tire,
  open,
  createTirePilePermit,
  createTireRepairPermit,
  createTireRevitalizationPermit,
  updateTireReviewPermit,
  onClose,
  handleOpenSendForm,
  handleOpenDamagesForm,
  openUpdateTireReviewForm,
  handleOpenUpdateTireReviewForm,
  handleCloseUpdateTireReviewForm,
  handleCancelMovement,
  deleteTirePilePermit,
  deleteTireRepairPermit,
  deleteTireRevitalizationPermit,
  policyAllowsEdit,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>{t("_titles.show.tire")}</DialogTitle>
        <DialogContent>
          <div className={classnames(css.flex, css.content_info)}>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.code")}
                text={tire.code || "-"}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.rfid_field.label")}
                text={tire.device_code || "-"}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.subsidiary.singular")}
                text={tire.subsidiary.name}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.spare")}
                text={
                  tire.cycle.is_refection ? t("_labels.yes") : t("_labels.no")
                }
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.cap_and_casing")}
                text={tire.cap_and_casing ? t("_labels.yes") : t("_labels.no")}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.number_patches")}
                text={tire.cycle?.number_patch?.toString() || "-"}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.price")}
                text={
                  tire.cycle.tire_condition_id.includes("RETREAD")
                    ? tire.cycle.price_revitalized.toString()
                    : tire.cycle.price.toString()
                }
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.provider")}
                text={tire.cycle.provider.name}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.brand_field.label")}
                text={tire.cycle.variation.tire_model.brand.name}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.tire_model_field.label")}
                text={tire.cycle.variation.tire_model.name}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.tire_model_variation_field.label")}
                text={`${tire.cycle.variation.tire_size.size} - ${tire.cycle.variation.number_layers}`}
              />
            </div>
            {tire.cycle.revitalized && (
              <div className={classnames(css.horizontal)}>
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.revitalized_brand_field.label")}
                  text={tire.cycle.revitalized?.brand.name}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.revitalized_tire_model_field.label")}
                  text={tire.cycle.revitalized?.name || "-"}
                />
              </div>
            )}
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.invoice_date")}
                text={
                  tire.cycle.invoice_date
                    ? moment(tire.cycle.invoice_date).format("LL")
                    : "-"
                }
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.invoice_folio")}
                text={tire.cycle.invoice_folio || "-"}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.mileage")}
                text={tire.cycle.tire_travel.toString()}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.review_date")}
                text={`${
                  tire.cycle.movement_tire.tire_review.length > 0
                    ? moment(
                        tire.cycle.movement_tire.tire_review[0].date
                      ).format("LL")
                    : "-"
                }`}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.number_cycle")}
                text={tire.cycle.number_cycle.toString()}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.helmet_value")}
                text={getHelmetValue(tire)}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.depth")}
                text={
                  tire.cycle.movement_tire.tire_review.length > 0 &&
                  tire.cycle.movement_tire.tire_review[0].tire_review_depth_line
                    ? `${getMinDepth(
                        tire.cycle.movement_tire.tire_review[
                          tire.cycle.movement_tire.tire_review.length - 1
                        ].tire_review_depth_line
                      )} mm`
                    : ""
                }
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.dot")}
                text={tire.dot}
              />

              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.pressure")}
                text={`${
                  tire.cycle.movement_tire.tire_review.length > 0
                    ? tire.cycle.movement_tire.tire_review[
                        tire.cycle.movement_tire.tire_review.length - 1
                      ].pressure
                    : "-"
                } psi`}
              />
            </div>
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.location.label")}
                text={t(
                  `_labels.location.options.${tire.cycle.movement_tire.movement.toLowerCase()}`
                )}
              />
              {["WAREHOUSE", "MOUNT"].includes(
                tire.cycle.movement_tire.movement
              ) && renderLocation(t, tire, tire.cycle.movement_tire.movement)}
            </div>

            <div>
              <LabelAndText
                fullWidth
                gutterBottom
                label={t("_labels.created_by")}
                text={tire.created_by}
                className={classnames(css.mr8)}
              />

              {tire.updated_by && (
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.updated_by")}
                  text={tire.updated_by}
                />
              )}
            </div>
          </div>

          <div className={classnames(css.w100)}>
            <Typography
              className={classnames(css.mt4, css.mb8)}
              variant="overline"
              color="textSecondary"
              display="block"
            >
              {t("_labels.tire_actions")}
            </Typography>
            <div className={classnames(css.actions)}>
              {tire.cycle.movement_tire.movement === "WAREHOUSE" && (
                <>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={
                      !createTireRevitalizationPermit ||
                      tire.cycle.number_cycle + 1 >
                        tire.subsidiary.policy.number_cycle
                    }
                    tooltip={
                      !createTireRevitalizationPermit
                        ? t("_permits.create.tire_revitalization")
                        : tire.cycle.number_cycle + 1 >
                          tire.subsidiary.policy.number_cycle
                        ? t("_messages.cycle")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "REVITALIZATION"
                      );
                    }}
                  >
                    {t("_buttons.actions.revitalize")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTireRepairPermit}
                    tooltip={
                      !createTireRepairPermit
                        ? t("_permits.create.tire_repair")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "REPAIR"
                      );
                    }}
                  >
                    {t("_buttons.actions.repair")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTirePilePermit}
                    tooltip={
                      !createTirePilePermit
                        ? t("_permits.create.tire_pile")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "PILE"
                      );
                    }}
                  >
                    {t("_buttons.actions.discard")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      onClose();
                      handleOpenDamagesForm(tire);
                    }}
                  >
                    {t("_buttons.damages")}
                  </Button>
                </>
              )}

              {tire.cycle.movement_tire.movement === "REVITALIZATION" && (
                <>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTireRevitalizationPermit}
                    tooltip={
                      !createTireRevitalizationPermit
                        ? t("_permits.create.tire_revitalization")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "WAREHOUSE",
                        tire.cycle.movement_tire.movement,
                        tire.cycle.movement_tire.tire_revitalization[0]
                          .tire_revitalization_id
                      );
                    }}
                  >
                    {t("_buttons.send_to_warehouse")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTirePilePermit}
                    tooltip={
                      !createTirePilePermit
                        ? t("_permits.create.tire_pile")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "PILE"
                      );
                    }}
                  >
                    {t("_buttons.send_to_pile")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTireRevitalizationPermit}
                    tooltip={
                      !createTireRevitalizationPermit
                        ? t("_permits.reject")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "REJECTION",
                        null,
                        tire.cycle.movement_tire.tire_revitalization[0]
                          .tire_revitalization_id
                      );
                    }}
                  >
                    {t("_buttons.retread_rejection")}
                  </Button>
                </>
              )}

              {tire.cycle.movement_tire.movement === "REPAIR" && (
                <>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTireRepairPermit}
                    tooltip={
                      !createTireRepairPermit
                        ? t("_permits.create.tire_repair")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "WAREHOUSE",
                        tire.cycle.movement_tire.movement,
                        tire.cycle.movement_tire.tire_repair[0].tire_repair_id
                      );
                    }}
                  >
                    {t("_buttons.send_to_warehouse")}
                  </Button>
                  <Button
                    className={classnames(css.flex)}
                    variant="contained"
                    color="secondary"
                    disabled={!createTirePilePermit}
                    tooltip={
                      !createTirePilePermit
                        ? t("_permits.create.tire_pile")
                        : ""
                    }
                    onClick={() => {
                      onClose();
                      handleOpenSendForm(
                        tire.cycle.movement_tire.movement_tire_id,
                        "PILE"
                      );
                    }}
                  >
                    {t("_buttons.send_to_pile")}
                  </Button>
                </>
              )}
              {tire.cycle.movement_tire.movement === "MOUNT" && (
                <Button
                  className={classnames(css.flex)}
                  variant="contained"
                  color="secondary"
                  disabled={!policyAllowsEdit || !updateTireReviewPermit}
                  tooltip={
                    !policyAllowsEdit
                      ? t("_messages.update.tire_review_policy_denied")
                      : !updateTireReviewPermit
                      ? t("_permits.update.tire_review")
                      : ""
                  }
                  onClick={() => {
                    onClose();
                    handleOpenUpdateTireReviewForm();
                  }}
                >
                  {t("_buttons.update_tire_review")}
                </Button>
              )}
              {["PILE", "REVITALIZATION", "REPAIR"].includes(
                tire.cycle.movement_tire.movement
              ) && (
                <Button
                  className={classnames(css.flex)}
                  variant="contained"
                  color="secondary"
                  disabled={
                    (tire.cycle.movement_tire.movement === "PILE" &&
                      !deleteTirePilePermit) ||
                    (tire.cycle.movement_tire.movement === "REPAIR" &&
                      !deleteTireRepairPermit) ||
                    (tire.cycle.movement_tire.movement === "REVITALIZATION" &&
                      !deleteTireRevitalizationPermit)
                  }
                  tooltip={
                    (tire.cycle.movement_tire.movement === "PILE" &&
                      !deleteTirePilePermit) ||
                    (tire.cycle.movement_tire.movement === "REPAIR" &&
                      !deleteTireRepairPermit) ||
                    (tire.cycle.movement_tire.movement === "REVITALIZATION" &&
                      !deleteTireRevitalizationPermit)
                      ? t("_permits.cancel_movement")
                      : t("_messages.notice_cancel_movement")
                  }
                  placement="top"
                  onClick={handleCancelMovement}
                >
                  {t("_buttons.cancel_movement")}
                </Button>
              )}
            </div>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>

      <UpdateTireReviewForm
        open={openUpdateTireReviewForm}
        onClose={handleCloseUpdateTireReviewForm}
        tireId={tire.tire_id}
      />
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tire: PropTypes.object.isRequired,
  createTireRevitalizationPermit: PropTypes.bool.isRequired,
  createTireRepairPermit: PropTypes.bool.isRequired,
  createTirePilePermit: PropTypes.bool.isRequired,
  updateTireReviewPermit: PropTypes.bool.isRequired,
  deleteTireRevitalizationPermit: PropTypes.bool.isRequired,
  deleteTireRepairPermit: PropTypes.bool.isRequired,
  deleteTirePilePermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOpenSendForm: PropTypes.func.isRequired,
  handleOpenDamagesForm: PropTypes.func.isRequired,
  openUpdateTireReviewForm: PropTypes.bool.isRequired,
  handleOpenUpdateTireReviewForm: PropTypes.func.isRequired,
  handleCloseUpdateTireReviewForm: PropTypes.func.isRequired,
  handleCancelMovement: PropTypes.func.isRequired,
  policyAllowsEdit: PropTypes.bool.isRequired,
};

export default Page;

function renderLocation(t, tire, location) {
  switch (location) {
    case "WAREHOUSE":
      return (
        <LabelAndText
          fullWidth
          gutterBottom
          label={t("_labels.warehouse.singular")}
          text={
            tire.cycle.movement_tire.warehouse_tire.length > 0
              ? tire.cycle.movement_tire.warehouse_tire[0].warehouse.name
              : t("_labels.not_warehouse")
          }
        />
      );

    case "MOUNT":
      return (
        <>
          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.vehicle.singular")}
            text={
              tire.cycle.movement_tire.vehicle_tire.length > 0
                ? tire.cycle.movement_tire.vehicle_tire[0].vehicle
                    .economic_number
                : t("_labels.not_vehicle")
            }
          />
          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.vehicle_type_axle_position.label")}
            text={
              tire.cycle.movement_tire.vehicle_tire.length > 0
                ? tire.cycle.movement_tire.vehicle_tire[0].vehicle_type_axle_tire.position.toString()
                : t("_labels.not_vehicle")
            }
          />
        </>
      );

    default:
      return;
  }
}

function getHelmetValue(tire) {
  if (tire.cycle.tire_condition_id.includes("ORIGINAL")) {
    return tire.cycle.variation.helmet_value_original.toString();
  }

  return tire.cycle.variation.helmet_value_revitalized.toString();
}
