import React from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

import Input from "@material-ui/core/Input";

import LabelColorViewer from "components/molecules/general/LabelColorViewer";

import css from "./index.module.scss";

function Page({
  name,
  value,
  required,
  disabled,
  labelRef,
  className,
  handleChange,
}) {
  return (
    <>
      <LabelColorViewer
        labelRef={labelRef}
        name={name}
        className={className}
      ></LabelColorViewer>
      <Input
        type="color"
        value={value}
        disabled={disabled}
        required={required}
        id={`color-${name}`}
        className={classnames(css.color_input)}
        onChange={handleChange}
      ></Input>
    </>
  );
}

Page.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelRef: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Page;
