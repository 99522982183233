import React from "react";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import TireTabs from "components/organisms/tire/TireTabs";
import SearchInput from "components/molecules/general/SearchInput";

function Page() {
  return (
    <TireTabs
      optionsAppBar={
        <>
          <Button className={classnames("bw")} variant="icon">
            <FilterListIcon />
          </Button>
          <SearchInput search={""} onChange={() => {}} />
        </>
      }
    />
  );
}

Page.propTypes = {};

export default Page;
