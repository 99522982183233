import {
  GET_TPMS,
  CLEAR_TPMS,
  REGISTER_TPMS,
  SET_TPMS_FILTERS,
  CLEAR_TPMS_FILTERS,
  GET_TPMS_SENSOR,
  CLEAR_TPMS_SENSOR,
  UPDATE_TPMS,
  SET_TPMS_STATUS,
  GET_VEHICLE_TPMS_HISTORY,
  CLEAR_VEHICLE_TPMS_HISTORY,
} from "store/actions";

const initialState = {
  sensor: {},
  tpms: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
  vehicle_tpms: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TPMS_SENSOR:
    case UPDATE_TPMS:
    case REGISTER_TPMS:
      return {
        ...state,
        sensor: action.payload,
      };

    case CLEAR_TPMS_SENSOR:
      return {
        ...state,
        sensor: { ...initialState.sensor },
      };

    case GET_TPMS:
      return {
        ...state,
        tpms: action.payload,
      };

    case CLEAR_TPMS:
      return {
        ...state,
        tpms: initialState.tpms,
      };

    case SET_TPMS_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_TPMS_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case SET_TPMS_STATUS:
      return {
        ...state,
        tpms: {
          ...state.tpms,
          data: state.tpms.data.map((sensor) => {
            if (sensor.tpgps_id === action.payload) {
              return { ...sensor, status: !sensor.status };
            }

            return { ...sensor };
          }),
        },
      };

    case GET_VEHICLE_TPMS_HISTORY:
      return {
        ...state,
        vehicle_tpms: action.payload,
      };

    case CLEAR_VEHICLE_TPMS_HISTORY:
      return {
        ...state,
        vehicle_tpms: initialState.vehicle_tpms,
      };

    default:
      return state;
  }
}
