import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

import css from "./index.module.scss";

function Page({
  selectable,
  onSelectAllClick,
  numSelected,
  headers,
  rows,
  selected,
  totalRows,
}) {
  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox" className={classnames(css.head)}>
            <Checkbox
              className={classnames(css.text_white)}
              indeterminate={numSelected > 0 && numSelected < totalRows}
              checked={
                numSelected === totalRows ||
                rows.every((row) => selected.includes(row))
              }
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            className={classnames(css.head)}
            key={header.id}
            align={"left"}
            padding={header.disablePadding ? "none" : "default"}
          >
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Page.propTypes = {
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  headers: PropTypes.array.isRequired,
  selectable: PropTypes.bool.isRequired,
  rows: PropTypes.array,
  totalRows: PropTypes.number,
  selected: PropTypes.array,
};

export default Page;
