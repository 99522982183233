import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";

export const NAMESPACE = "pages/review/HomeReview";
import Page from "./page";
import { actionShowProgress } from "store/actions/general/progress";
import { actionSetNotification } from "store/actions/general/notification";
import { getCompanyViaWorkArea } from "utils/workArea";

function HomeReview({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { account, workArea } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [currentTab, setCurrentTab] = useState("SUBSIDIARY");
  const [graphTab, setGraphTab] = useState("SUMMARY");

  const [company, setCompany] = useState();

  useEffect(() => {
    if (Object.keys(workArea).length > 0 && account) {
      setCompany(getCompanyViaWorkArea(account, workArea)[0]);
    }
  }, [account, workArea]);

  useEffect(() => {
    if (company) {
      const newSubsidiaries = account.subsidiaries.filter(
        (subsidiary) => subsidiary.subsidiary.company_id === company.company_id
      );
      setSubsidiaries(
        newSubsidiaries.map((subsidiary) => subsidiary.subsidiary.name)
      );
    }
  }, [company]);

  return (
    <Page
      t={t}
      subsidiaries={subsidiaries}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      graphTab={graphTab}
      setGraphTab={setGraphTab}
    />
  );
}

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeReview);
