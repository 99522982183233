export default {
  title: "Vincular almacén",
  warehouse_title_card: "Almacenes vinculados",
  no_items: "Sin Almacenes",
  messages: {
    delete: "¿Estas seguro de desvincular el almacén?",
    delete_warehouse_subsidiary:
      "Listo, se desvinculó el almacén de la sucursal",
    attached_subsidiary_warehouse:
      "Listo, el almacén ha sido vinculado con éxito",
  },
  form: {
    warehouse: { default: "Selecciona un almacén" },
  },
  permits: {
    link: {
      subsidiary_warehouse:
        "Sin permisos para vincular almacenes a las sucursales",
    },
    unlink: {
      subsidiary_warehouse:
        "Sin permisos para desvincular almacenes de las sucursales",
    },
  },
};
