import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import UpdateTpmsAlertForm from "components/organisms/tpms/UpdateTpmsForm";
import FilterAlertsByMountForm from "components/organisms/mount/alert/FilterAlertsByMountForm";
import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";

function Page({
  t,
  alerts,
  alertId,
  economicNumber,
  id,
  openForm,
  openFilter,
  onChangePage,
  handleOpenForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  handleDownloadPdf,
}) {
  return (
    <ContainerTemplate
      title={t("_titles.tpms_alerts")}
      className={classnames({
        [css.empty]: alerts.data.length === 0,
      })}
      options={
        <>
          <Button
            className={classnames("bw", css.filter)}
            variant="icon"
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      {alerts.data.length > 0 && (
        <div
          className={classnames({
            [css.title]: true,
            "d-flex flex-column justify-content-center align-items-start": true,
          })}
        >
          <Typography noWrap variant="h6" component="h6">
            {t("_labels.vehicle.singular")}: {economicNumber}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownloadPdf}
          >
            {t("_buttons.pdf")}
          </Button>
        </div>
      )}
      {alerts.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={alerts.data.map((alert) => ({
                id: alert.alert_vehicle_tire_tpgps_id,
                key: alert.alert_vehicle_tire_tpgps_id,
                alert_code: alert.alert.colloquial_name
                  ? t(`_alert.colloquial_name.${alert.alert.colloquial_name}`)
                  : "",
                code: alert.vehicle_tire.movement_tire.tire_cycle.tire.code,
                priority: t(
                  `_labels.priority.options.${alert.alert.priority.toLowerCase()}`
                ),
                ranking_alert: t(
                  `_labels.ranking_alert.options.${alert.alert.ranking_alert.toLowerCase()}`
                ),
                suggestion: alert.alert.suggestion
                  ? t(`_alert.suggestion.${alert.alert.suggestion}`)
                  : "",
                position: `${alert?.vehicle_tire.movement_tire.tire_review[0]?.real_position}`,
                pressure: `${alert?.vehicle_tire.movement_tire.tire_review[0]?.pressure} psi`,
                depth: `${getMinDepth(
                  alert?.vehicle_tire?.movement_tire?.tire_review[0]
                    .tire_review_depth_line
                )} mm`,
                date: `${moment(alert.created_at).format("LL")} ${moment(
                  alert.created_at
                ).format("h:mm a")}`,
                redirect: [
                  {
                    label: <InfoRoundedIcon />,
                    size: "medium",
                    variant: "icon",
                    handleClick: () =>
                      handleOpenForm(
                        alert.alert.alert_id,
                        alert.vehicle_tire_id
                      ),
                  },
                  {
                    label: <DeleteRoundedIcon />,
                    size: "medium",
                    variant: "icon",
                    handleClick: () => handleDelete(alert),
                    style: { color: "#e54141" },
                  },
                ],
                color:
                  alert.alert.priority === "HIGH"
                    ? "red"
                    : alert.alert.priority === "HALF"
                    ? "yellow"
                    : null,
              }))}
              headers={[
                { id: "alert_code", label: t("_labels.alert.label") },
                { id: "code", label: t("_labels.code") },
                { id: "priority", label: t("_labels.priority.label") },
                {
                  id: "ranking_alert",
                  label: t("_labels.ranking_alert.label"),
                },
                { id: "suggestion", label: t("_labels.alert.suggestion") },
                { id: "position", label: t("_labels.ranking") },
                { id: "pressure", label: t("_labels.pressure") },
                { id: "depth", label: t("_labels.depth") },
                { id: "date", label: t("_labels.date.label") },
                { id: "actions", label: "" },
              ]}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={alerts.current_page}
              totalPages={alerts.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_alerts")} />
      )}

      <UpdateTpmsAlertForm
        open={openForm}
        onClose={handleCloseForm}
        alertId={alertId}
        vehicleTireId={id}
      />

      <FilterAlertsByMountForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  alerts: PropTypes.object.isRequired,
  vehicleTireId: PropTypes.string.isRequired,
  economicNumber: PropTypes.string,
  id: PropTypes.number,
  alertId: PropTypes.number,
  openForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
};

export default Page;
