export default {
  title: "Actualizar políticas generales",
  messages: {
    policy_updated: "Listo, se actualizaron las políticas generales con éxito",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar políticas",
    },
  },
};
