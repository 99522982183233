import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import PropTypes from "prop-types";
const styles = StyleSheet.create({
  cell: {
    width: "16%",
    fontSize: "10px",
    color: "rgb(0,40,73)",
    height: "100%",
    alignContent: "center",
    alignItems: "start",
    padding: "2px 10px 2px 10px",
  },
  row: {
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
  },
});
const CellPdf = (text, style) => (
  <View style={style}>
    <Text>{text}</Text>
  </View>
);
const RowPdf = ({
  titleText,
  col1,
  col2,
  col3,
  col4,
  col5,
  titleStyle = {
    width: "20%",
    fontSize: "10px",
    color: "rgb(0,40,73)",
    height: "100%",
    alignContent: "center",
    alignItems: "start",
    padding: "2px 10px 2px 10px",
  },
  col1Style = styles.cell,
  col2Style = styles.cell,
  col3Style = styles.cell,
  col4Style = styles.cell,
  col5Style = styles.cell,
}) => {
  return (
    <View style={styles.row}>
      {CellPdf(titleText ?? "", titleStyle)}
      {CellPdf(col1 ?? "", col1Style)}
      {CellPdf(col2 ?? "", col2Style)}
      {CellPdf(col3 ?? "", col3Style)}
      {CellPdf(col4 ?? "", col4Style)}
      {CellPdf(col5 ?? "", col5Style)}
    </View>
  );
};

RowPdf.propTypes = {
  titleText: PropTypes.string,
  col1: PropTypes.string,
  col2: PropTypes.string,
  col3: PropTypes.string,
  col4: PropTypes.string,
  col5: PropTypes.string,
  titleStyle: PropTypes.object,
  col1Style: PropTypes.object,
  col2Style: PropTypes.object,
  col3Style: PropTypes.object,
  col4Style: PropTypes.object,
  col5Style: PropTypes.object,
};

export default RowPdf;
