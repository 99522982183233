import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionGetTire } from "store/actions/tire/getTire";
import { actionClearTire } from "store/actions/tire/clearTire";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireCycle } from "store/actions/tire/cycle/getTireCycle";
import { actionClearTireCycle } from "store/actions/tire/cycle/clearTireCycle";

import Page from "./page";

export function TireInfoTab({ ...rest }) {
  const { id } = useParams();

  const {
    tire,
    cycle,
    getTire,
    clearTire,
    getTireCycle,
    clearTireCycle,
    setNotification,
  } = rest;
  const [isUpdate, setIsUpdate] = useState(false);
  const [providerId, setProviderId] = useState(null);
  const [tireCycleId, setTireCycleId] = useState(null);
  const [tireModelId, setTireModelId] = useState(null);
  const [tireFormOpen, setTireFormOpen] = useState(false);
  const [modelInfoOpen, setModelInfoOpen] = useState(false);
  const [tireModelVariationId, setTireModelVariationId] = useState(null);
  const [openCycleForm, setOpenCycleForm] = useState(false);
  const [cycleInfoOpen, setCycleInfoOpen] = useState(false);
  const [providerInfoOpen, setProviderInfoOpen] = useState(false);
  const [variationInfoOpen, setVariationInfoOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTire(id);
        await getTireCycle(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearTire();
      clearTireCycle();
    };
  }, [id]);

  function handleOpenTireForm() {
    setTireFormOpen(true);
  }

  function handleCloseTireForm() {
    setTireFormOpen(false);
  }

  function handleOpenCycleInfo() {
    setCycleInfoOpen(true);
  }

  function handleCloseCycleInfo() {
    setCycleInfoOpen(false);
    setTireCycleId(null);
  }

  function handleOpenProviderInfo(id) {
    setProviderId(id);
    setCycleInfoOpen(false);
    setProviderInfoOpen(true);
  }

  function handleCloseProviderInfo() {
    setProviderId(null);
    setProviderInfoOpen(false);
  }

  function handleOpenModelInfo(id) {
    setTireModelId(id);
    setCycleInfoOpen(false);
    setModelInfoOpen(true);
  }

  function handleCloseModelInfo() {
    setTireModelId(null);
    setModelInfoOpen(false);
  }

  function handleOpenVariationInfo(modeId, variationId) {
    setTireModelId(modeId);
    setTireModelVariationId(variationId);
    setCycleInfoOpen(false);
    setVariationInfoOpen(true);
  }

  function handleCloseVariationInfo() {
    setTireModelId(null);
    setTireModelVariationId(null);
    setVariationInfoOpen(false);
  }

  function handleOpenCycleHistoryInfo(id) {
    setTireCycleId(id);
    handleOpenCycleInfo();
  }

  function handleCloseCycleForm() {
    setOpenCycleForm(false);
  }

  function handleOpenTireCycleForm() {
    setOpenCycleForm(true);
    setCycleInfoOpen(false);
    setIsUpdate(true);
  }

  return (
    <Page
      id={id}
      tire={tire}
      cycle={cycle}
      isUpdate={isUpdate}
      providerId={providerId}
      tireModelId={tireModelId}
      tireCycleId={tireCycleId}
      tireFormOpen={tireFormOpen}
      modelInfoOpen={modelInfoOpen}
      cycleInfoOpen={cycleInfoOpen}
      providerInfoOpen={providerInfoOpen}
      variationInfoOpen={variationInfoOpen}
      tireModelVariationId={tireModelVariationId}
      openCycleForm={openCycleForm}
      handleOpenTireForm={handleOpenTireForm}
      handleCloseTireForm={handleCloseTireForm}
      handleOpenModelInfo={handleOpenModelInfo}
      handleOpenCycleInfo={handleOpenCycleInfo}
      handleCloseCycleForm={handleCloseCycleForm}
      handleCloseModelInfo={handleCloseModelInfo}
      handleCloseCycleInfo={handleCloseCycleInfo}
      handleOpenProviderInfo={handleOpenProviderInfo}
      handleCloseProviderInfo={handleCloseProviderInfo}
      handleOpenVariationInfo={handleOpenVariationInfo}
      handleOpenTireCycleForm={handleOpenTireCycleForm}
      handleCloseVariationInfo={handleCloseVariationInfo}
      handleOpenCycleHistoryInfo={handleOpenCycleHistoryInfo}
    />
  );
}

TireInfoTab.propTypes = {};

const mapStateToProps = (state) => ({
  tire: state.Tire.Tire.tire,
  cycle: state.Tire.Cycle.Cycle.cycle,
});

const mapDispatchToProps = (dispatch) => ({
  getTire: actionGetTire(dispatch),
  clearTire: actionClearTire(dispatch),
  getTireCycle: actionGetTireCycle(dispatch),
  clearTireCycle: actionClearTireCycle(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireInfoTab);
