import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDivisions } from "store/actions/division/getDivisions";
import { LIST_DIVISION } from "store/actions/account/permits";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { permits, subsidiary, setNotification, getDivisions } = rest;
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (permits.includes(LIST_DIVISION) && !!subsidiary) {
          data = await getDivisions(
            {
              scope: "division_id,name,status",
              subsidiaries: subsidiary.subsidiary_id,
            },
            false
          );
        }
        setDivisions(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
  }, []);

  return <Page {...rest} t={t} divisions={divisions} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  subsidiary: PropTypes.object,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getDivisions: actionGetDivisions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
