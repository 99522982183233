import React from "react";
import PropTypes from "prop-types";

import InfoLinkDialog from "components/molecules/general/InfoLinkDialog";

function Page({ t, open, warehouse, onClose, to }) {
  return (
    <InfoLinkDialog
      to={to}
      open={open}
      isHistory={true}
      title={t("_titles.warehouse_tire")}
      label1={t("_labels.warehouse_name")}
      text1={warehouse.warehouse.name}
      label2={t("_labels.tire_code")}
      text2={warehouse.tire.code}
      createdBy={warehouse.created_by}
      createdAt={warehouse.created_at}
      updatedBy={warehouse.updated_by}
      updatedAt={warehouse.updated_at}
      viewName={t("_buttons.view_warehouse")}
      viewTooltip={""}
      onClose={onClose}
      unlinkName={""}
      onUnlink={() => {}}
      unlinkTooltip={""}
    />
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  warehouse: PropTypes.object,
  to: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Page;
