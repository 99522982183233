import { http } from "store/actions/http";
import { GET_TIRE_WAREHOUSE } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireWarehouse = (dispatch) => async (tireId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `tire/${tireId}/warehouse`,
    });

    dispatch({
      type: GET_TIRE_WAREHOUSE,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
