import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form, FieldArray } from "formik";
import { TextField } from "formik-material-ui";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import css from "./index.module.scss";

function Page({ t, values, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="pressure"
        component={TextField}
        type="number"
        className={classnames(css.field)}
        label={t("_labels.pressure")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="start"
            >
              psi
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
          },
        }}
      />

      <Typography
        align="left"
        display="block"
        variant="overline"
        color="textSecondary"
        className={classnames(css.full_width, css.subtitle)}
      >
        {t("_labels.depths")}
      </Typography>
      <FieldArray
        name="lines"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {values.lines.map((line, index) => (
              <div key={`line_${index}`} className={classnames(css.field_wrap)}>
                <div className={classnames(css.horizontal)}>
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    size="small"
                    name={`lines[${index}].depth_external`}
                    component={TextField}
                    type="number"
                    className={classnames(css.small_field)}
                    label={t("_labels.depth_external")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                    }}
                  />
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    size="small"
                    name={`lines[${index}].depth_middle`}
                    component={TextField}
                    type="number"
                    className={classnames(css.small_field)}
                    label={t("_labels.depth_middle")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                    }}
                  />
                  <Field
                    required
                    variant="filled"
                    margin="normal"
                    size="small"
                    name={`lines[${index}].depth_internal`}
                    component={TextField}
                    type="number"
                    className={classnames(css.small_field)}
                    label={t("_labels.depth_internal")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: "any",
                      },
                    }}
                  />
                </div>

                <div className={classnames(css.w100)}>
                  <Typography
                    align="left"
                    display="block"
                    variant="caption"
                    color="textSecondary"
                    className={classnames(css.subtitle, css.full_width)}
                  >
                    {`${t("_labels.line")} ${index + 1}`}
                  </Typography>
                  <Divider className={classnames(css.full_width, css.m0)} />
                </div>
              </div>
            ))}

            <div className={classnames(css.full_width, css.buttons)}>
              <Button
                type="button"
                onClick={() => arrayHelpers.pop()}
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
                disabled={values.lines.length <= 1}
              >
                <RemoveIcon />
              </Button>
              <Button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    depth_external: "",
                    depth_middle: "",
                    depth_internal: "",
                  })
                }
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
              >
                <AddIcon />
              </Button>
            </div>
          </>
        )}
      />

      <Field
        multiline
        rows={2}
        variant="filled"
        margin="normal"
        name="comment"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.observation")}
      />

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
