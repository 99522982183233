import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import AddIcon from "@material-ui/icons/Add";

import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ className, children, color, ...rest }) {
  return (
    <Button
      className={classnames(css.button, className)}
      color={color || "secondary"}
      variant="fab"
      aria-label="add"
      {...rest}
    >
      {children || <AddIcon />}
    </Button>
  );
}

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
};
export default Page;
