export default {
  link_subsidiary: "Vincular sucursal",
  permits: {
    unlink: {
      driver_subsidiary: "Sin permisos para desvincular sucursal del conductor",
    },
    show: {
      driver_subsidiary:
        "Sin permisos para ver sucursal vinculada al conductor",
    },
  },
  messages: {
    unlinked_driver_subsidiary:
      "Listo, la sucursal se a desvinculado del conductor con éxito",
    linked_driver_subsidiary:
      "Listo, la sucursal se a vinculado al conductor con éxito",
  },
};
