import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import SearchInput from "components/molecules/general/SearchInput";
import ContainerTemplate from "components/templates/general/Container";
import TableResponsive from "components/organisms/general/TableResponsive";
import TireForm from "components/organisms/tire/TireForm";
import SimilarTiresForm from "components/organisms/tire/SimilarTiresForm";
import FilterTireForm from "components/organisms/tire/FilterTireForm";
import InfoTireDialog from "components/organisms/tire/InfoTireDialog";
import TireSendForm from "components/organisms/tire/TireSendForm";
import TireRegisterExcelForm from "components/organisms/tire/TireRegisterExcelForm";
import LaunchIcon from "@material-ui/icons/Launch";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import DamagesAndWearForm from "components/organisms/tire/DamagesAndWearForm";
import TireMoveForm from "components/organisms/tire/TireMoveForm";

import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";
import RegisterPileForm from "components/organisms/tire/RegisterPileForm";
import BulkPileForm from "components/organisms/tire/BulkPileForm";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
function Page({
  t,
  to,
  search,
  tires,
  tireId,
  movementTireId,
  movement,
  tireActionId,
  openForm,
  openFilter,
  openDialog,
  openSendForm,
  openSimilarForm,
  openExcelForm,
  similarTires,
  tireValues,
  createTooltipPermit,
  updateTooltipPermit,
  deleteTooltipPermit,
  createRevitalizationTooltipPermit,
  onChangePage,
  handleOpenForm,
  handleCloseForm,
  handleOpenSimilarForm,
  handleCloseSimilarForm,
  handleEdit,
  handleSearch,
  handleOpenFilter,
  handleCloseFilter,
  handleDelete,
  // handleChangeStatus,
  handleOpenDialog,
  handleCloseDialog,
  handleOpenSendForm,
  handleCloseSendForm,
  handleOpenExcelForm,
  handleCloseExcelForm,
  onHistory,
  openDamagesForm,
  handleOpenDamagesForm,
  handleCloseDamagesForm,
  tire,
  openMoveTireForm,
  handleOpenMoveTireForm,
  handleCloseMoveTireForm,
  isRegisterForm,
  setIsRegisterForm,
  selectedFilter,
  setSelectedFilters,
  openRegisterPileForm,
  handleOpenRegisterPileForm,
  handleCloseRegisterPileForm,
  openBulkPileForm,
  handleOpenBulkPileForm,
  handleCloseBulkPileForm,
}) {
  return (
    <ContainerTemplate
      title={t("_general.tires")}
      className={classnames({
        [css.empty]: tires.data.length === 0,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <Button
        disabled={!!createTooltipPermit}
        tooltip={createTooltipPermit}
        color="secondary"
        variant="contained"
        onClick={handleOpenExcelForm}
        className={classnames(css.excel_btn)}
      >
        {t("_buttons.register_by_excel")}
      </Button>
      <Button
        disabled={!!createRevitalizationTooltipPermit}
        tooltip={createRevitalizationTooltipPermit}
        color="secondary"
        variant="contained"
        className="ml-2"
        onClick={() => {
          handleOpenSendForm(null, "WAREHOUSE");
        }}
      >
        {t("_buttons.retread")}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        className="ml-2"
        onClick={handleOpenBulkPileForm}
      >
        {t("_buttons.massive_pile")}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        className="ml-2"
        onClick={handleOpenRegisterPileForm}
      >
        {t("_buttons.register_pile")}
      </Button>

      {selectedFilter &&
        Object.values(selectedFilter).filter((e) => e.value != undefined)
          .length > 0 && (
          <>
            <div className={classnames(css.filter_title)}>
              <Typography variant="overline">
                {t("_general.selected_filters")}
              </Typography>
            </div>
            <div className={classnames(css.filters)}>
              {Object.entries(selectedFilter ?? {}).map(([key, value]) => {
                if (value.value === undefined) {
                  return undefined;
                }
                return (
                  <Chip
                    key={key}
                    label={`${value.label}: ${value.value}`}
                    variant="outlined"
                  />
                );
              })}
            </div>
          </>
        )}

      {tires.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              rows={tires.data.map((tire) => ({
                id: tire.tire_id,
                key: tire.tire_id,
                status: {
                  status: tire.status,
                  tooltipPermit: "",
                },
                subsidiary: tire.subsidiary.name,
                code: tire.code || "",
                rfid: tire.device_code || "",
                brand:
                  tire.cycle.revitalized?.brand.name ||
                  tire.cycle.variation.tire_model.brand.name,
                model:
                  tire.cycle.revitalized?.name ||
                  tire.cycle.variation.tire_model.name,
                condition: t(
                  `_labels.tire_condition.options.${tire.cycle.tire_condition_id.toLowerCase()}`
                ),
                location:
                  tire.cycle.movement_tire.warehouse_tire.length > 0
                    ? tire.cycle.movement_tire.warehouse_tire[0].warehouse.name
                    : tire.cycle.movement_tire.vehicle_tire.length > 0
                    ? tire.cycle.movement_tire.vehicle_tire[0].vehicle
                        .economic_number
                    : t(
                        `_labels.location.options.${tire.cycle.movement_tire.movement.toLowerCase()}`
                      ),
                position:
                  tire.cycle.movement_tire.movement === "MOUNT"
                    ? tire.cycle.movement_tire.vehicle_tire[0]
                        .vehicle_type_axle_tire.position
                    : "-",
                depth:
                  tire.cycle.movement_tire.tire_review.length > 0
                    ? `${getMinDepth(
                        tire.cycle.movement_tire.tire_review[
                          tire.cycle.movement_tire.tire_review.length - 1
                        ].tire_review_depth_line
                      )} mm`
                    : "",
                km: `${formatter.format(tire.cycle.tire_travel)}`,
                edit: {
                  tooltipPermit: updateTooltipPermit,
                },
                delete: {
                  tooltipPermit: deleteTooltipPermit,
                },
                info: {
                  tooltipPermit: "",
                },
                history: {
                  tooltipPermit: "",
                  onHistory,
                },
                redirect: getAlertIcon(tire),
                other: {
                  tooltip:
                    tire.cycle.movement_tire.movement !== "WAREHOUSE"
                      ? t("_labels.cant_move_tire")
                      : t("_buttons.move"),
                  disabled: tire.cycle.movement_tire.movement !== "WAREHOUSE",
                  label: <LaunchIcon />,
                  variant: "icon",
                  size: "medium",
                  handleClick: () =>
                    handleOpenMoveTireForm(
                      tire.cycle.movement_tire.movement_tire_id
                    ),
                },
              }))}
              headers={[
                { id: "status", label: t("_labels.status") },
                { id: "subsidiary", label: t("_labels.subsidiary.singular") },
                {
                  id: "code",
                  label: t("_labels.code"),
                },
                {
                  id: "rfid",
                  label: t("_general.rfid"),
                },
                {
                  id: "brand",
                  label: t("_labels.brand"),
                },
                {
                  id: "model",
                  label: t("_labels.model"),
                },
                {
                  id: "condition",
                  label: t("_labels.condition"),
                },
                {
                  id: "location",
                  label: t("_labels.location.label"),
                },
                {
                  id: "position",
                  label: t("_labels.vehicle_type_axle_position.label"),
                },
                { id: "depth", label: t("_labels.depth") },
                { id: "km", label: "Km" },
                { id: "actions", label: "" },
              ]}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onChangeStatus={() => {}}
              onDialog={handleOpenDialog}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={tires.current_page}
              totalPages={tires.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FixedButton
        disabled={!!createTooltipPermit}
        tooltip={createTooltipPermit}
        onClick={handleOpenForm}
      />

      <TireForm
        open={openForm}
        tireId={tireId}
        onClose={handleCloseForm}
        onOpenSimilarForm={handleOpenSimilarForm}
        setIsRegisterForm={setIsRegisterForm}
      />
      <SimilarTiresForm
        open={openSimilarForm}
        onClose={handleCloseSimilarForm}
        similarTires={similarTires}
        tireValues={tireValues}
        newTires={isRegisterForm}
      />
      <FilterTireForm
        open={openFilter}
        onClose={handleCloseFilter}
        setSelectedFilters={setSelectedFilters}
      />

      <InfoTireDialog
        tireId={tireId}
        open={openDialog}
        onClose={handleCloseDialog}
        handleOpenSendForm={handleOpenSendForm}
        handleOpenDamagesForm={handleOpenDamagesForm}
      />
      <TireSendForm
        movementTireId={movementTireId}
        to={to}
        movement={movement}
        tireActionId={tireActionId}
        open={openSendForm}
        onClose={handleCloseSendForm}
        handleOpenSimilarForm={handleOpenSimilarForm}
      />

      <TireRegisterExcelForm
        open={openExcelForm}
        onClose={handleCloseExcelForm}
      />

      <RegisterPileForm
        open={openRegisterPileForm}
        onClose={handleCloseRegisterPileForm}
      />

      <BulkPileForm open={openBulkPileForm} onClose={handleCloseBulkPileForm} />

      <DamagesAndWearForm
        open={openDamagesForm}
        onClose={handleCloseDamagesForm}
        isDamages={true}
        tire={tire}
      />

      <TireMoveForm
        movementTireId={movementTireId}
        open={openMoveTireForm}
        onClose={handleCloseMoveTireForm}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
  tires: PropTypes.object,
  tireId: PropTypes.number,
  movementTireId: PropTypes.number,
  movement: PropTypes.string,
  tireActionId: PropTypes.number,
  search: PropTypes.string,
  openForm: PropTypes.bool.isRequired,
  openSendForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  openSimilarForm: PropTypes.bool.isRequired,
  openExcelForm: PropTypes.bool.isRequired,
  tireValues: PropTypes.object.isRequired,
  similarTires: PropTypes.number.isRequired,
  createTooltipPermit: PropTypes.string.isRequired,
  updateTooltipPermit: PropTypes.string.isRequired,
  deleteTooltipPermit: PropTypes.string.isRequired,
  createRevitalizationTooltipPermit: PropTypes.string.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenSimilarForm: PropTypes.func.isRequired,
  handleCloseSimilarForm: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleOpenSendForm: PropTypes.func.isRequired,
  handleCloseSendForm: PropTypes.func.isRequired,
  handleOpenExcelForm: PropTypes.func.isRequired,
  handleCloseExcelForm: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
  openDamagesForm: PropTypes.bool.isRequired,
  handleOpenDamagesForm: PropTypes.func.isRequired,
  handleCloseDamagesForm: PropTypes.func.isRequired,
  tire: PropTypes.object,
  openMoveTireForm: PropTypes.bool.isRequired,
  handleOpenMoveTireForm: PropTypes.func.isRequired,
  handleCloseMoveTireForm: PropTypes.func.isRequired,
  isRegisterForm: PropTypes.bool,
  setIsRegisterForm: PropTypes.func,
  selectedFilter: PropTypes.object.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  openBulkPileForm: PropTypes.bool.isRequired,
  openRegisterPileForm: PropTypes.bool.isRequired,
  handleOpenRegisterPileForm: PropTypes.func.isRequired,
  handleCloseRegisterPileForm: PropTypes.func.isRequired,
  handleOpenBulkPileForm: PropTypes.func.isRequired,
  handleCloseBulkPileForm: PropTypes.func.isRequired,
};

export default Page;

function getAlertIcon(tire) {
  const icons = [];

  if (tire.cycle?.movement_tire?.alert_tire_high?.length > 0) {
    icons.push({
      tooltipPermit: "",
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {tire.cycle?.movement_tire?.alert_tire_high?.length}
          <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#e54141" },
      href: `tire/${tire.cycle.movement_tire.movement_tire_id}/alerts?priority=high`,
      target: "_blank",
      rel: "noopener noreferrer",
    });
  }

  if (tire.cycle?.movement_tire?.alert_tire_half?.length > 0) {
    icons.push({
      tooltipPermit: "",
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {tire.cycle?.movement_tire?.alert_tire_half?.length}
          <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#fac957", color: "#444" },
      href: `tire/${tire.cycle.movement_tire.movement_tire_id}/alerts?priority=half`,
      target: "_blank",
      rel: "noopener noreferrer",
    });
  }

  if (tire.cycle?.movement_tire?.alert_tire_low?.length > 0) {
    icons.push({
      tooltipPermit: "",
      label: (
        <div className="d-flex justify-content-center align-items-center">
          {tire.cycle?.movement_tire?.alert_tire_low?.length}
          <WarningRoundedIcon />
        </div>
      ),
      variant: "contained",
      size: "small",
      style: { backgroundColor: "#999" },
      href: `tire/${tire.cycle.movement_tire.movement_tire_id}/alerts?priority=low`,
      target: "_blank",
      rel: "noopener noreferrer",
    });
  }

  return icons;
}
