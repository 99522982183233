import {
  GET_VEHICLES_MOUNT,
  SET_VEHICLE_FILTERS_MOUNT,
  CLEAR_VEHICLE_FILTERS_MOUNT,
  CLEAR_VEHICLES_MOUNT,
} from "store/actions";

const initialState = {
  vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_vehicle: {},
  selected_filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLES_MOUNT:
      return {
        ...state,
        vehicles: action.payload,
      };

    case CLEAR_VEHICLES_MOUNT:
      return {
        ...state,
        vehicles: { ...initialState.vehicles },
      };

    case SET_VEHICLE_FILTERS_MOUNT:
      const filterMount = {
        ...state.filter_vehicle,
        ...action.payload,
      };

      for (let key in filterMount) {
        if (
          filterMount[key] === undefined ||
          filterMount[key] === null ||
          filterMount[key] === ""
        ) {
          delete filterMount[key];
        }
      }

      return {
        ...state,
        filter_vehicle: { ...filterMount },
      };

    case CLEAR_VEHICLE_FILTERS_MOUNT:
      return {
        ...state,
        filter_vehicle: { search: state.filter_vehicle.search },
      };

    default:
      return state;
  }
}
