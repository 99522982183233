import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

import Button from "components/molecules/general/Button";
import DateTimeField from "components/molecules/general/DateTimeField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  isSubmitting,
  onClose,
  handleClean,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="overline" color="textSecondary" display="block">
        {t("_labels.date.range")}
      </Typography>

      <Field
        fullWidth
        name="date_from"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        required={!!values.date_to}
        label={t("_labels.date.from")}
      />

      <Field
        fullWidth
        name="date_to"
        variant="filled"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.to")}
        required={!!values.date_from}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClean: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
