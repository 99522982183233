import { http } from "store/actions/http";
import { UPDATE_TIRE_MODEL_VARIATION } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateTireModelVariation =
  (dispatch) => async (fields, tireModelId, tireModelVariationId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/model/${tireModelId}/variation/${tireModelVariationId}`,
        data: fields,
      });

      dispatch({
        type: UPDATE_TIRE_MODEL_VARIATION,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
