export default {
  disabled_division: "Disabled division",
  subsidiary: "Subsidiary",
  permits: {
    change_status: "No permissions to {action} division",
  },
  messages: {
    disable: "Are you sure to disable the division?",
    enable: "Are you sure to enable the division?",
  },
};
