import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetFleetSemaphoreFilters } from "store/actions/reports/fleet_semaphore/setSemaphoreFilters";
import { actionSetSelectedFleetSemaphoreFilters } from "store/actions/reports/fleet_semaphore/setSelectedSemaphoreFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { getCompanyViaWorkArea, getCorporateViaWorkArea } from "utils/workArea";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterSemaphoreForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    account,
    workArea,
    setNotification,
    setSemaphoreFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      companies: [],
      only_empty_vehicles: false,
    },
  });

  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        companies: filter.companies ? filter.companies.split(",") : [],
        only_empty_vehicles: Boolean(filter.only_empty_vehicles),
      });
    } else {
      resetFormik();
    }
  }, [open, loaded]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.companies.length > 0) {
        params.companies = values.companies.join();
        const company = companies.filter((company) =>
          values.companies.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: company.map((company) => company.name).join(),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      params.only_empty_vehicles = Number(values.only_empty_vehicles);
      setSelectedFilters({
        only_empty_vehicles: values.only_empty_vehicles
          ? t("_labels.yes")
          : t("_labels.no"),
      });

      if (values.search) {
        params.search = values.search;
      }

      await setSemaphoreFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };
    setSemaphoreFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      setCompanies={setCompanies}
      t={t}
    />
  );
}

FilterSemaphoreForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.FleetSemaphore.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setSemaphoreFilters: actionSetFleetSemaphoreFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedFleetSemaphoreFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSemaphoreForm);
