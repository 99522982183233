export default {
  messages: {
    disable: "Are you sure to disable the warehouse?",
    enable: "Are you sure to enable the warehouse?",
  },
  buttons: {
    disabled_warehouse: "Disabled warehouse",
  },
  permits: {
    change_status: "No permissions to {action} warehouses",
  },
};
