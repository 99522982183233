import { http } from "store/actions/http";
import { CREATE_TIRE_MODEL } from "store/actions";
import { actionShowProgress } from "store/actions/general/progress";

export const actionAddTireModel =
  (dispatch) => async (fields, dataWithFiles) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: "tire/model",
        data: fields,
        dataWithFiles,
      });

      dispatch({
        type: CREATE_TIRE_MODEL,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
