import { SET_STATUS_COMPANY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetStatusCompany =
  (dispatch) => async (companyId, status) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `company/${companyId}/status`,
        data: { status },
      });

      dispatch({
        type: SET_STATUS_COMPANY,
        payload: companyId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
