import {
  SET_TIRE_STATS_FILTERS,
  CLEAR_TIRE_STATS_FILTERS,
  SET_SELECTED_STATS_FILTERS,
} from "store/actions";

const initialState = {
  filter: {},
  selected_filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TIRE_STATS_FILTERS:
      const filter = {
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_TIRE_STATS_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
        selected_filter: { ...initialState.selected_filter },
      };

    case SET_SELECTED_STATS_FILTERS:
      const selectedFilter = {
        ...state.selected_filter,
        ...action.payload,
      };

      for (let key in selectedFilter) {
        if (
          selectedFilter[key] === undefined ||
          selectedFilter[key] === null ||
          selectedFilter[key] === ""
        ) {
          delete selectedFilter[key];
        }
      }

      return {
        ...state,
        selected_filter: { ...selectedFilter },
      };

    default:
      return state;
  }
}
