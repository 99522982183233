import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleType } from "store/actions/vehicle/type/getVehicleType";

import Page from "./page";

export const NAMESPACE =
  "src/components/organisms/vehicle/type/VehicleTypeTabs";

export function VehicleTypeTabs({
  className,
  optionsAppBar,
  children,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const { id } = useParams();
  const { vehicleType, getVehicleType, setNotification } = rest;
  const tabLinks = [
    {
      to: `/vehicle/type/${id}`,
      label: t("_labels.information"),
    },
    {
      to: `/vehicle/type/${id}/axle`,
      label: t("_labels.axle.plural"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicleType(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [id]);

  return (
    <Page
      title={vehicleType.name}
      className={className}
      optionsAppBar={optionsAppBar}
      tabLinks={tabLinks}
    >
      {children}
    </Page>
  );
}

VehicleTypeTabs.propTypes = {
  children: PropTypes.node,
  optionsAppBar: PropTypes.node,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  vehicleType: state.Vehicle.Type.Type.vehicle_type,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicleType: actionGetVehicleType(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTypeTabs);
