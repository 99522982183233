import account from "./account";
import company from "./company";
import corporate from "./corporate";
import general from "./general";
import subsidiary from "./subsidiary";
import division from "./division";
import revitalized from "./revitalized";
import warehouse from "./warehouse";
import vehicle from "./vehicle";
import user from "./user";
import damage from "./damage";
import wear from "./wear";
import provider from "./provider";
import driver from "./driver";
import brand from "./brand";
import tire from "./tire";
import policy from "./policy";
import retirement from "./retirement";
import gps from "./gps";
import rfid from "./rfid";

export default {
  account,
  company,
  corporate,
  general,
  subsidiary,
  warehouse,
  vehicle,
  division,
  revitalized,
  user,
  damage,
  wear,
  provider,
  driver,
  brand,
  tire,
  policy,
  retirement,
  gps,
  rfid,
};
