import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Tire from "resources/images/vehicle/refection_tire.png";
import InfoTireTooltip from "components/molecules/general/InfoTireTooltip";

import css from "./index.module.scss";

function Page({
  className,
  color,
  axleTires,
  classNameAxle,
  classNameTire,
  onClickTire,
  withTooltip,
  handleDragStart,
  handleDragEnter,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  origin,
  inReview,
  inProcess,
  review,
}) {
  return (
    <div className={classnames(css.container, className)}>
      <div
        className={classnames(css.axle, classNameAxle)}
        style={{ background: `#${color}` }}
      ></div>
      <div className={classnames(css.tire_container)}>
        {withTooltip
          ? axleTires.map((tires, index) => (
              <div key={index}>
                {tires.map((tire) =>
                  tire.vehicle_tire.length > 0 ? (
                    <InfoTireTooltip
                      hidden={!!origin}
                      key={tire.position}
                      tire={tire.vehicle_tire[0]}
                      placement={index % 2 ? "right" : "left"}
                    >
                      {inReview ? (
                        <span
                          className={{
                            [css.tire]: true,
                            [css.damage]:
                              inProcess /* review.review_type === "DAMAGE AND WEAR" && */ &&
                              (checkIfHasDamages(tire.vehicle_tire[0]) ||
                                checkIfHasWears(tire.vehicle_tire[0])),
                          }}
                        >
                          <img
                            draggable={false}
                            src={Tire}
                            className={classnames(css.tire, classNameTire, {
                              [css.clickable_tire]: !!onClickTire,
                              [css.border_green]:
                                inProcess &&
                                ((review.review_type === "COMPLETE" &&
                                  checkIfHasTireReviewInLastVehicleReview(
                                    tire.vehicle_tire[0],
                                    review
                                  )) ||
                                  (review.review_type === "DAMAGE AND WEAR" &&
                                    (checkIfHasDamages(tire.vehicle_tire[0]) ||
                                      checkIfHasWears(tire.vehicle_tire[0])))),
                            })}
                            onClick={
                              !!onClickTire ? () => onClickTire(tire) : () => {}
                            }
                          />
                        </span>
                      ) : (
                        <span
                          className={css.tire}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDragStart={(e) => handleDragStart(e, tire)}
                          onDragOver={handleDragOver}
                          onDrop={(e) => handleDrop(e, tire)}
                        >
                          <img
                            draggable={review.review_type === "ROTATION"}
                            src={Tire}
                            className={classnames(css.tire, classNameTire, {
                              [css.clickable_tire]: !!onClickTire,
                              [css.border_green]:
                                review.review_type === "ROTATION" &&
                                checkIfHasTireReviewInLastVehicleReview(
                                  tire.vehicle_tire[0],
                                  review
                                ),
                            })}
                            onClick={
                              !!onClickTire ? () => onClickTire(tire) : () => {}
                            }
                          />
                        </span>
                      )}
                    </InfoTireTooltip>
                  ) : inReview ? (
                    <span key={tire.position} className={css.tire}>
                      <img
                        draggable={false}
                        key={tire.position}
                        src={Tire}
                        className={classnames(
                          css.tire,
                          classNameTire,
                          css.no_tire,
                          css.border_none
                        )}
                      />
                    </span>
                  ) : (
                    <span
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, tire)}
                      key={tire.position}
                      className={css.tire}
                    >
                      <img
                        draggable={false}
                        key={tire.position}
                        src={Tire}
                        className={classnames(
                          css.tire,
                          classNameTire,
                          css.no_tire,
                          {
                            [css.clickable_tire]: !!onClickTire,
                          }
                        )}
                        onClick={
                          !!onClickTire ? () => onClickTire(tire) : () => {}
                        }
                      />
                    </span>
                  )
                )}
              </div>
            ))
          : axleTires.map((tires, index) => (
              <div key={index}>
                {tires.map((tire) => (
                  <img
                    draggable={false}
                    key={tire.position}
                    src={Tire}
                    className={classnames(css.tire, classNameTire, {
                      [css.clickable_tire]: !!onClickTire,
                    })}
                    onClick={!!onClickTire ? () => onClickTire(tire) : () => {}}
                  />
                ))}
              </div>
            ))}
      </div>
    </div>
  );
}

Page.propTypes = {
  classNameAxle: PropTypes.string,
  classNameTire: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  axleTires: PropTypes.array,
  onClickTire: PropTypes.func,
  withTooltip: PropTypes.bool,
  handleDragStart: PropTypes.func,
  handleDragEnter: PropTypes.func,
  handleDragLeave: PropTypes.func,
  handleDragOver: PropTypes.func,
  handleDrop: PropTypes.func,
  origin: PropTypes.object,
  hide: PropTypes.bool,
  setHide: PropTypes.func,
  inReview: PropTypes.bool,
  inProcess: PropTypes.bool,
  review: PropTypes.object,
};

export default Page;

/* Para poner en verde si esta en completa (en proceso) y ya se reviso 
   O si esta en rotaciones y ya tiene revisión (se roto) 
*/
function checkIfHasTireReviewInLastVehicleReview(vehicleTire, review) {
  const lastReviewIndex = vehicleTire?.movement_tire?.tire_review.length - 1;

  return (
    vehicleTire?.movement_tire?.tire_review[lastReviewIndex]
      .vehicle_review_id === review.vehicle_review_id
  );
}

function checkIfHasDamages(vehicleTire) {
  return vehicleTire.movement_tire.tire_damage.some(
    (damage) =>
      damage.movement_tire_id === vehicleTire.movement_tire.movement_tire_id &&
      damage.movement_tire_id ===
        vehicleTire.movement_tire.tire_review[
          vehicleTire.movement_tire.tire_review.length - 1
        ].movement_tire_id
  );
}

function checkIfHasWears(vehicleTire) {
  return vehicleTire.movement_tire.tire_wear.some(
    (wear) =>
      wear.movement_tire_id === vehicleTire.movement_tire.movement_tire_id &&
      wear.movement_tire_id ===
        vehicleTire.movement_tire.tire_review[
          vehicleTire.movement_tire.tire_review.length - 1
        ].movement_tire_id
  );
}
