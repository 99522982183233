import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";

import { useLang } from "hooks/lang";
import css from "./index.module.scss";

export function ChecklistReviewDialog({
  open,
  onClose,
  checklist,
  checklistReview,
}) {
  const t = useLang();

  const checklistRendered = JSON.parse(checklistReview ?? "{}");

  return (
    <DialogMediaQuery maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle className={classnames(css.pb8)}>
        {t("_titles.show.checklist")}
      </DialogTitle>
      <DialogContent className={classnames(css.box, css.pt0)}>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.name.singular")}
              text={checklist.name}
            />

            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.type")}
              text={
                checklist.type === "TIRE"
                  ? t("_labels.tire.singular")
                  : t("_labels.vehicle.singular")
              }
            />
          </div>
          <Typography variant="overline">{t("_labels.headboard")}</Typography>
          {checklistRendered?.headboard?.map((concept, index) => {
            return (
              <div
                key={`${concept.name}_${index}`}
                className={classnames(css.horizontal)}
              >
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_general.concept")}
                  text={concept.concept_name}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.description")}
                  text={concept.details}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.type")}
                  text={t(`_labels.type_value.${concept.type_value}`)}
                />
                <LabelAndText
                  fullWidth
                  gutterBottom
                  label={t("_labels.ranking_value")}
                  text={concept.type_value.toString()}
                />
              </div>
            );
          })}

          <Typography variant="overline">{t("_labels.body")}</Typography>
          {checklistRendered?.body?.map((block, index) => {
            return (
              <Fragment key={`${block.block_name}_${index}`}>
                <div
                  className={classnames(css.horizontal)}
                  style={{ backgroundColor: "#ddd" }}
                >
                  <Typography variant="overline">{block.block_name}</Typography>
                </div>
                {block.concepts.map((concept, idx) => {
                  return (
                    <Fragment key={`concept_${concept.concept_name}_${idx}`}>
                      {"tireConcepts" in concept ? (
                        concept.tireConcepts?.map((tireConcept) => (
                          <div
                            className={classnames(css.horizontal)}
                            key={`concept_${concept.concept_name}_${idx}_${tireConcept.concept_name}`}
                          >
                            <LabelAndText
                              fullWidth
                              gutterBottom
                              label={t("_general.concept")}
                              text={`${t("_labels.ranking")} ${
                                tireConcept.concept_name.split("-")[1]
                              }`}
                            />
                            <LabelAndText
                              fullWidth
                              gutterBottom
                              label={t("_labels.description")}
                              text={tireConcept.details}
                            />
                            <LabelAndText
                              fullWidth
                              gutterBottom
                              label={t("_labels.type")}
                              text={t(
                                `_labels.type_value.${tireConcept.type_value}`
                              )}
                            />
                            <LabelAndText
                              fullWidth
                              gutterBottom
                              label={t("_labels.ranking_value")}
                              text={
                                ["pressure", "depth"].includes(
                                  tireConcept.type_value
                                )
                                  ? `${tireConcept.value} ${
                                      tireConcept.type_value == "pressure"
                                        ? "psi"
                                        : "mm"
                                    }`
                                  : tireConcept.type_value == "boolean"
                                  ? `${
                                      tireConcept.value
                                        ? t("_labels.yes")
                                        : t("_labels.no")
                                    }`
                                  : tireConcept.value
                              }
                            />
                          </div>
                        ))
                      ) : (
                        <div className={classnames(css.horizontal)}>
                          <LabelAndText
                            fullWidth
                            gutterBottom
                            label={t("_general.concept")}
                            text={concept.concept_name}
                          />
                          <LabelAndText
                            fullWidth
                            gutterBottom
                            label={t("_labels.description")}
                            text={concept.details}
                          />
                          <LabelAndText
                            fullWidth
                            gutterBottom
                            label={t("_labels.type")}
                            text={t(`_labels.type_value.${concept.type_value}`)}
                          />
                          <LabelAndText
                            fullWidth
                            gutterBottom
                            label={t("_labels.ranking_value")}
                            text={
                              ["pressure", "depth"].includes(concept.type_value)
                                ? `${concept.value} ${
                                    concept.type_value == "pressure"
                                      ? "psi"
                                      : "mm"
                                  }`
                                : concept.type_value == "boolean"
                                ? `${
                                    concept.value
                                      ? t("_labels.yes")
                                      : t("_labels.no")
                                  }`
                                : concept.value
                            }
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}

                {/* {array.length > 1 && index !== array.length - 1 && (
                  <Divider className={classnames(css.w100, css.my2)} />
                )} */}
              </Fragment>
            );
          })}
        </div>
      </DialogContent>

      <DialogActions className={classnames(css.actions)}>
        <Button type="button" onClick={onClose} color="secondary">
          {t("_buttons.close")}
        </Button>
      </DialogActions>
    </DialogMediaQuery>
  );
}

ChecklistReviewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  checklist: PropTypes.object,
  checklistReview: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistReviewDialog);
