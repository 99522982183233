import React from "react";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useLang } from "hooks/lang";
import { getMinDepth } from "utils/tire";

const styles = StyleSheet.create({
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowHeader: {
    backgroundColor: "#0061a4",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  tableRowHeader2: {
    backgroundColor: "#212529",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  row1: {
    backgroundColor: "rgb(240, 240, 240)",
  },
  row2: {
    backgroundColor: "rgb(220, 220, 220)",
  },
  tableColHeader: {
    width: "100%",
  },
  tableCol: {
    width: "16.66%",
  },
  tableCol2: {
    width: "6.66%",
  },
  tableCell: {
    margin: "auto",
    fontSize: 8,
    padding: "2px",
  },
});

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const ReviewHistoryPdf = ({ uuid, vehicleInspection, tireInspections }) => {
  const t = useLang();
  return (
    <Document key={uuid}>
      <Page
        size={"LETTER"}
        style={{ padding: "20px 20px 20px 20px" }}
        orientation={"landscape"}
      >
        <View style={styles.title}>
          <Text>
            Revisión de Vehículo - {vehicleInspection.vehicle.economic_number}
          </Text>
          {/*<Text style={styles.subTitle}>{subsidiary}</Text>*/}
        </View>
        <View
          style={{
            height: "20px",
          }}
        />
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}>
                Vehículo {vehicleInspection.vehicle.economic_number}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tipo de Revisión</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Estatus</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Fecha</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Kilometraje del Vehículo</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Usuario</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Comentario</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.row1]} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(
                  `_labels.review_type.options.${vehicleInspection.review_type
                    .toLowerCase()
                    .replace(/\s|\//g, "_")}`
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {vehicleInspection.end_time
                  ? t("_labels.finalized")
                  : t("_labels.inspection_in_process")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(vehicleInspection.date).format("LL hh:mm:ss a")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatter.format(vehicleInspection.odometer)} km
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{`${
                vehicleInspection.created_by.name
              } ${vehicleInspection.created_by.last_name_1} ${
                vehicleInspection.created_by.last_name_2 || ""
              }`}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {vehicleInspection.observation}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            height: "20px",
          }}
        />
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}>Revisiones de neumáticos</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.tableRowHeader2]} wrap={false}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Código</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>RFID</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Posición</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Detalle</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Marca</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Modelo</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Medida</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Dias en servicio</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Kilometraje (km)</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Presión (psi)</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Profundidad (mm)</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Desgaste (mm)</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Km/mm</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Costo por km </Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Proyección de KM </Text>
            </View>
          </View>
          {tireInspections.map((tireInspection, index) => {
            return (
              <View
                style={[styles.tableRow, index % 2 ? styles.row1 : styles.row2]}
                key={tireInspection.tire_review_id}
                wrap={false}
              >
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection?.movement_tire.tire_cycle.tire.code || "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection?.movement_tire.tire_cycle.tire
                      .device_code || "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection?.movement_tire.vehicle_tire.length > 0
                      ? tireInspection?.movement_tire.vehicle_tire[0]
                          .vehicle_type_axle_tire.position
                      : ""}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection.comment || ""}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection.movement_tire.brand_name}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection.movement_tire.tire_model}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection.movement_tire.tire_size}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tireInspection.days_in_service}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {kmFormatter.format(tireInspection.accumulated_mileage)}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol2,
                    {
                      fontFamily: "Helvetica-Bold",
                      color: "black",
                      backgroundColor:
                        tireInspection.pressure_condition.includes("LOW")
                          ? "#ffb0b0"
                          : tireInspection.pressure_condition.includes("HIGH")
                          ? "#ffb0b0"
                          : "#c2ffc2",
                    },
                  ]}
                >
                  <Text style={styles.tableCell}>
                    {formatter.format(tireInspection.pressure)}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol2,
                    {
                      fontFamily: "Helvetica-Bold",
                      color: "black",
                      backgroundColor:
                        tireInspection.tire_review_depth_line
                          .depth_condition === "CRITICAL WITHDRAWAL"
                          ? "#ffb0b0"
                          : tireInspection.tire_review_depth_line
                              .depth_condition === "SCHEDULED WITHDRAWAL"
                          ? "#ffefbd"
                          : "#c2ffc2",
                    },
                  ]}
                >
                  <Text style={[styles.tableCell]}>
                    {formatter.format(
                      getMinDepth(tireInspection.tire_review_depth_line)
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {formatter.format(
                      tireInspection.tire_review_depth_line.wear
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {kmFormatter.format(
                      tireInspection.tire_review_depth_line.km_mm
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {formatter.format(
                      tireInspection.tire_review_depth_line.cpk
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {kmFormatter.format(
                      tireInspection.tire_review_depth_line.projection
                    )}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

ReviewHistoryPdf.propTypes = {
  uuid: PropTypes.string.isRequired,
  vehicleInspection: PropTypes.object.isRequired,
  tireInspections: PropTypes.array.isRequired,
};

export default ReviewHistoryPdf;
