import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";
import RadioField from "components/molecules/general/RadioField";
import RadioInput from "components/atoms/general/RadioInput";

import css from "./index.module.scss";

function Page({ t, values, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        fullWidth
        name="option"
        component={RadioField}
        classNameControl={classnames(css.ml8, css.mr8)}
      >
        <RadioInput value="code" className={classnames(css.mb0)}>
          {t("_labels.code")}
        </RadioInput>
        <RadioInput value="device_code" className={classnames(css.mb0)}>
          {t("_labels.rfid_field.label")}
        </RadioInput>
      </Field>

      {values.option == "code" && (
        <div className={classnames(css.w100, css.m8)}>
          <Field
            required
            fullWidth
            variant="filled"
            margin="normal"
            name="code"
            component={TextField}
            className={classnames(css.field)}
            label={t("_labels.code")}
          />
        </div>
      )}

      {values.option == "device_code" && (
        <div className={classnames(css.flex, css.w100, css.m8)}>
          <Field
            required
            fullWidth
            variant="filled"
            margin="normal"
            name="device_code"
            component={TextField}
            className={classnames(css.field)}
            label={t("_labels.rfid_field.label")}
          />
        </div>
      )}

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
