export default {
  title: "New size",
  update_title: "Update size",
  form: {
    permits: {
      update: "No permissions to update tire sizes",
      delete: "No permissions to delete tire sizes",
    },
  },
  messages: {
    delete: "Are you sure to delete this tire size?",
    tire_size_created: "Done, the tire size has been successfully created",
    tire_size_updated: "Done, the tire size was successfully updated.",
  },
};
