import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import css from "./index.module.scss";

function Page({
  t,
  open,
  tireModelVariation,
  updateTireModelVariationPermit,
  onClose,
  handleOpen,
  handleStatusChange,
  handleApprovedChange,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{`${tireModelVariation.tire_size.size} - ${tireModelVariation.number_layers}`}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              fullWidth
              gutterBottom
              text={`${tireModelVariation.depth} mm`}
              label={t("_labels.tire_model_variation.depth")}
            />

            {tireModelVariation.maximum_pressure && (
              <LabelAndText
                fullWidth
                gutterBottom
                text={`${tireModelVariation.maximum_pressure} psi`}
                label={t("_labels.tire_model_variation.maximum_pressure")}
              />
            )}

            {tireModelVariation.recommended_pressure && (
              <LabelAndText
                fullWidth
                gutterBottom
                text={`${tireModelVariation.recommended_pressure} psi`}
                label={t("_labels.tire_model_variation.recommended_pressure")}
              />
            )}
          </div>

          <div className={classnames(css.horizontal)}>
            {tireModelVariation.tolerance && (
              <LabelAndText
                fullWidth
                gutterBottom
                text={`${tireModelVariation.tolerance} %`}
                label={t("_labels.tire_model_variation.tolerance")}
              />
            )}

            {tireModelVariation.helmet_value && (
              <LabelAndText
                fullWidth
                gutterBottom
                text={`${tireModelVariation.helmet_value} MXN`}
                label={t("_labels.helmet_value")}
              />
            )}
          </div>
          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.created_by")}
            text={tireModelVariation.created_by.toString()}
            className={classnames(css.mr8)}
          />

          {tireModelVariation.updated_by && (
            <LabelAndText
              fullWidth
              gutterBottom
              label={t("_labels.updated_by")}
              text={tireModelVariation.updated_by.toString()}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.half)}>
          <Button
            color="secondary"
            tooltip={
              !updateTireModelVariationPermit
                ? t("permits.change_status", {
                    action: tireModelVariation.status
                      ? t("_general.disable").toLowerCase()
                      : t("_general.enable").toLowerCase(),
                  })
                : ""
            }
            disabled={!updateTireModelVariationPermit}
            onClick={() => {
              handleStatusChange(
                tireModelVariation.tire_model_variation_id,
                tireModelVariation.status
              );
            }}
          >
            {tireModelVariation.status
              ? t("_general.disable")
              : t("_general.enable")}
          </Button>

          <Button
            color="secondary"
            tooltip={
              !updateTireModelVariationPermit
                ? t("permits.change_approve", {
                    action: tireModelVariation.approved
                      ? t("_general.disapprove").toLowerCase()
                      : t("_general.approve").toLowerCase(),
                  })
                : ""
            }
            disabled={!updateTireModelVariationPermit}
            onClick={() => {
              handleApprovedChange(
                tireModelVariation.tire_model_variation_id,
                tireModelVariation.approved
              );
            }}
          >
            {tireModelVariation.approved
              ? t("_general.disapprove")
              : t("_general.approve")}
          </Button>
        </div>
        <div className={classnames(css.half)}>
          <Button onClick={onClose} color="secondary">
            {t("_buttons.close")}
          </Button>
          <Button
            variant="contained"
            className={classnames(css.mx8)}
            color="secondary"
            disabled={!tireModelVariation.status}
            tooltip={!tireModelVariation.status ? t("disabled_variation") : ""}
            onClick={handleOpen}
          >
            {t("_buttons.edit")}
          </Button>
        </div>
      </DialogActions>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  tireModelVariation: PropTypes.shape({
    tire_model_variation_id: PropTypes.number.isRequired,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    approved: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
      .isRequired,
    tolerance: PropTypes.number,
    helmet_value: PropTypes.number,
    maximum_pressure: PropTypes.number,
    depth: PropTypes.number.isRequired,
    recommended_pressure: PropTypes.number,
    number_layers: PropTypes.number.isRequired,
    tire_size: PropTypes.shape({ size: PropTypes.string.isRequired })
      .isRequired,
    created_by: PropTypes.string.isRequired,
    updated_by: PropTypes.string,
  }).isRequired,
  updateTireModelVariationPermit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  handleApprovedChange: PropTypes.func.isRequired,
};

export default Page;
