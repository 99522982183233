import React from "react";

import classnames from "classnames";

import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

import PropTypes from "prop-types";

import logo from "resources/images/setenal.jpg";

import css from "./index.module.scss";

function Page({ t, handleLanguage, language, children }) {
  return (
    <div className={classnames(css.container)}>
      <div
        className={classnames(css.banner)}
        style={{
          backgroundImage: `linear-gradient(rgba(2,136,210, .5), rgba(2,136,210, .5)), url(${logo})`,
        }}
      >
        <Typography
          variant="h5"
          align="center"
          display="block"
          style={{ color: "white" }}
        >
          PRETYRE
        </Typography>
      </div>
      <div className={classnames(css.container__form)}>
        <div className={classnames(css.box_children)}>{children}</div>
        <FormControl
          variant="outlined"
          className={classnames(css.form_control)}
        >
          <InputLabel id="select-language">
            {t("_labels.language_field.label")}
          </InputLabel>
          <Select
            labelId="select-language"
            id="select-language"
            label={t("_labels.language_field.label")}
            value={language}
            onChange={handleLanguage}
          >
            <MenuItem value="es">
              {t("_labels.language_field.options.es")}
            </MenuItem>
            <MenuItem value="en">
              {t("_labels.language_field.options.en")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  handleLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Page;
