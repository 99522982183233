export default {
  title: "Neumáticos vinculados",
  form_title: "Vincular neumático",
  form: {
    permits: {
      list: {
        tire: "Sin permisos para ver listados de neumáticos",
      },
    },
  },
  permits: {
    link: {
      subsidiary_tire: "Sin permisos para vincular neumáticos con sucursales",
    },
    show_subsidiary_tire: "Sin permisos para ver neumáticos vinculados",
    unlink_subsidiary_tire:
      "Sin permisos para desvincular neumáticos de sucursales",
  },
  messages: {
    delete: "¿Estas seguro de desvincular el neumático?",
    delete_subsidiary_tire: "Listo, se desvinculó el neumático de la sucursal",
    linked_subsidiary_tire: "Listo, el neumático ha sido vinculado con éxito",
  },
};
