import {
  GET_VEHICLE_SUBSIDIARY,
  CLEAR_VEHICLE_SUBSIDIARY,
  ASSIGN_VEHICLE_TO_SUBSIDIARY,
} from "store/actions";

const initialState = {
  subsidiary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VEHICLE_SUBSIDIARY:
    case ASSIGN_VEHICLE_TO_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };

    case CLEAR_VEHICLE_SUBSIDIARY:
      return {
        ...state,
        subsidiary: initialState.subsidiary,
      };

    default:
      return state;
  }
}
