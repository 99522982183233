import {
  GET_SUBSIDIARY_POLICY,
  CLEAR_SUBSIDIARY_POLICY,
  UPDATE_SUBSIDIARY_POLICY,
} from "store/actions";

const initialState = {
  policy: {
    policy_id: 0,
    number_cycle: 0,
    inspection: 0,
    tire_rotation: 0,
    tolerance_inflation_pressure: 0,
    directional_tire_rotation: 0,
    tolerance_mating_pressure: 0,
    tolerance_directional_mating_depth: 0,
    tolerance_mating_depth: 0,
    helmet_policy: 0,
    pressure_type_axle: 0,
    tire_condition_axle_type: 0,
    show_alerts_different_assignment: 0,
    notify_inappropriate_pressures: 0,
    send_pressure_setting: 0,
    use_checklist: 0,
    subsidiary_id: 0,
    subsidiary: {
      tradename: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUBSIDIARY_POLICY:
    case GET_SUBSIDIARY_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

    case CLEAR_SUBSIDIARY_POLICY:
      return {
        ...state,
        policy: initialState.policy,
      };

    default:
      return state;
  }
}
