import { http } from "store/actions/http";
import { GET_TIRE_WAREHOUSES_HISTORY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireWarehousesHistory =
  (dispatch) =>
  async (
    { tireId, search, status, page, scope, order = "DESC" },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `tire/${tireId}/warehouse/history`,
        params: { search, status, page, scope, order },
      });

      if (withDispatch) {
        dispatch({
          type: GET_TIRE_WAREHOUSES_HISTORY,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
