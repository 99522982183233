import React, { useState } from "react";

import Page from "./page";

function PasswordField(props) {
  const [visibility, setVisibility] = useState(false);

  return (
    <Page visibility={visibility} setVisibility={setVisibility} {...props} />
  );
}

export default PasswordField;
