import React from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionShowProgress } from "store/actions/general/progress";
import { http } from "store/actions/http";

import Page from "./page";

export function VehicleDialog({ open, ...rest }) {
  const t = useLang();
  const { filter, setNotification, actionProgress } = rest;

  async function handleClickExcel() {
    const progress = actionProgress();
    try {
      await http({
        method: "GET",
        path: `report/user/review/vehicles/without/tires/excel`,
        params: { ...filter },
        download: true,
      });
    } catch (error) {
      setNotification(error, true);
    } finally {
      actionProgress(progress);
    }
  }

  return (
    <Page {...rest} t={t} open={open} handleClickExcel={handleClickExcel} />
  );
}

VehicleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicles: PropTypes.array,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.UserReview.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  actionProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDialog);
