export default {
  link_gps: "Link gps",
  device_name: "Device name",
  geofence_status: "Geofence status",
  inside: "Inside",
  outside: "Outside",
  no_gps: "No gps linked",
  permits: {
    show_link: {
      vehicle_with_gps: "No permissions to view vehicle links with gps",
    },
    list: {
      gps: "No permissions to list gps",
    },
  },
  form: {
    select_a_gps: "select a gps",
  },
};
