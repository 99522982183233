import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import Link from "@material-ui/core/Link";
import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import ContainerTemplate from "components/templates/general/Container";
import FilterForm from "components/organisms/general/FilterForm";
import DamageDialog from "components/organisms/tire/damage/DamageDialog";
import Pagination from "components/molecules/general/Pagination";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import WarningIcon from "@material-ui/icons/Warning";

import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";
import Alerts from "./Alerts";
// import Tooltip from "@material-ui/core/Tooltip";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});
function Page({
  t,
  vehicleReview,
  onChangePage,
  filter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  setVehicleReviewFilters,
  clearVehicleReviewFilters,
  damages,
  openDamageDialog,
  handleOpenDamageDialog,
  handleCloseDamageDialog,
  reviewAlerts,
  openAlertDialog,
  handleOpenAlertDialog,
  handleCloseAlertDialog,
  savePdf,
}) {
  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: vehicleReview.data.length === 0,
      })}
      title={`${t("_labels.review.plural")} `}
      options={
        <Button
          onClick={handleOpenFilter}
          classNameButton={classnames(css.filter)}
          variant="icon"
        >
          <FilterListIcon />
        </Button>
      }
    >
      {vehicleReview.data.length > 0 ? (
        <>
          <div
            className={classnames(css.container)}
            style={{
              fontFamily: "roboto",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Button
              style={{
                display: "block",
              }}
              color="secondary"
              variant="contained"
              onClick={() => {
                savePdf();
              }}
            >
              Descargar PDF
            </Button>
            <div className="table-responsive">
              <table className="table text-center">
                <thead style={{ backgroundColor: "#0061a4", color: "white" }}>
                  <tr>
                    <th colSpan={7}>{`${t("_labels.vehicle.singular")} ${
                      vehicleReview.data[0].vehicle.economic_number
                    }`}</th>
                  </tr>
                  <tr>
                    <th>{t("_labels.review_type.label")}</th>
                    <th>{t("_labels.status")}</th>
                    <th>{t("_labels.date.label")}</th>
                    <th>{t("_general.vehicle_km")}</th>
                    <th>{t("_labels.user.singular")}</th>
                    <th>{t("_labels.comment.singular")}</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleReview.data.map((review) => {
                    return (
                      <tr
                        key={review.vehicle_review_id}
                        className="table-bordered"
                      >
                        <td className={classnames(css.table_cell)}>
                          {t(
                            `_labels.review_type.options.${review.review_type
                              .toLowerCase()
                              .replace(/\s|\//g, "_")}`
                          )}
                        </td>
                        <td
                          className={classnames({
                            [css.table_cell]: true,
                            [css.error]: !review.end_time,
                          })}
                        >
                          {review.end_time
                            ? t("_labels.finalized")
                            : t("_labels.inspection_in_process")}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {moment(review.date).format("LL hh:mm:ss a")}
                        </td>
                        <td className={classnames(css.table_cell)}>
                          {formatter.format(review.odometer)} km
                        </td>
                        <td className={classnames(css.table_cell)}>{`${
                          review.created_by.name
                        } ${review.created_by.last_name_1} ${
                          review.created_by.last_name_2 || ""
                        }`}</td>
                        <td className={classnames(css.table_cell)}>
                          {review.observation}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={classnames(css.container_two)}
            style={{ fontFamily: "roboto" }}
          >
            <div className="table-responsive">
              <table className="table text-center">
                <thead style={{ backgroundColor: "#0061a4", color: "white" }}>
                  <tr>
                    <th>{t("_general.tire_reviews")}</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleReview.data.map((review) => {
                    return (
                      <tr
                        key={review.vehicle_review_id}
                        className="table-bordered"
                      >
                        <td className={classnames(css.table_cell, "p-0")}>
                          <table className="table table-striped m-0 p-0">
                            <thead className="thead-dark">
                              <tr>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.code")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  Rfid
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t(
                                    "_labels.vehicle_type_axle_position.label"
                                  )}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.detail")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.brand")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.model")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.size")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.tire_days_service")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.mileage")} (km)
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.pressure")} (psi)
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.depth")} (mm)
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.wear.singular")} (mm)
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  Km/mm
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.cost_km")}
                                </th>
                                <th className={classnames(css.table_cell)}>
                                  {t("_labels.projection")}
                                </th>
                                <th className={classnames(css.table_cell)}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {review.tire_review.map((tireReview) => {
                                return (
                                  <tr key={tireReview.tire_review_id}>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                      }}
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      <Link
                                        href={`/tire/${tireReview?.movement_tire.tire_cycle.tire_id}/history`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {tireReview?.movement_tire.tire_cycle
                                          .tire.code || "-"}
                                      </Link>
                                      {(tireReview.alert_tire.length > 0 ||
                                        tireReview.alert_vehicle_tire.length >
                                          0) && (
                                        <button
                                          className="btn-sm btn btn-warning d-flex justify-content-between align-items-center"
                                          style={{ width: "100%" }}
                                          onClick={() =>
                                            handleOpenAlertDialog(
                                              [...tireReview.alert_tire].concat(
                                                tireReview.alert_vehicle_tire
                                              )
                                            )
                                          }
                                        >
                                          <WarningIcon
                                            fontSize="small"
                                            className="mr-1"
                                          />{" "}
                                          {t("_general.alerts")}
                                        </button>
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      <Link
                                        href={`/tire/${tireReview?.movement_tire.tire_cycle.tire_id}/history`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {tireReview?.movement_tire.tire_cycle
                                          .tire.device_code || "-"}
                                      </Link>
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {tireReview?.movement_tire.vehicle_tire
                                        .length > 0
                                        ? tireReview?.movement_tire
                                            .vehicle_tire[0]
                                            .vehicle_type_axle_tire.position
                                        : ""}
                                    </td>
                                    <td className={classnames(css.table_cell)}>
                                      {tireReview.comment || ""}
                                    </td>
                                    <td className={classnames(css.table_cell)}>
                                      {tireReview.movement_tire.brand_name}
                                    </td>
                                    <td className={classnames(css.table_cell)}>
                                      {tireReview.movement_tire.tire_model}
                                    </td>
                                    <td className={classnames(css.table_cell)}>
                                      {tireReview.movement_tire.tire_size}
                                    </td>
                                    <td className={classnames(css.table_cell)}>
                                      {tireReview.days_in_service}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {kmFormatter.format(
                                        tireReview.accumulated_mileage
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle",
                                        {
                                          [css.critical]:
                                            tireReview.pressure_condition.includes(
                                              "LOW"
                                            ) ||
                                            tireReview.pressure_condition.includes(
                                              "HIGH"
                                            ),
                                          [css.good]:
                                            tireReview.pressure_condition ===
                                            "NORMAL",
                                        }
                                      )}
                                    >
                                      {formatter.format(tireReview.pressure)}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle",
                                        {
                                          [css.critical]:
                                            tireReview.tire_review_depth_line
                                              .depth_condition ===
                                            "CRITICAL WITHDRAWAL",
                                          [css.warning]:
                                            tireReview.tire_review_depth_line
                                              .depth_condition ===
                                            "SCHEDULED WITHDRAWAL",
                                          [css.good]:
                                            tireReview.tire_review_depth_line
                                              .depth_condition ===
                                            "GOOD CONDITION",
                                        }
                                      )}
                                    >
                                      {formatter.format(
                                        getMinDepth(
                                          tireReview.tire_review_depth_line
                                        )
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {formatter.format(
                                        tireReview.tire_review_depth_line.wear
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {kmFormatter.format(
                                        tireReview.tire_review_depth_line.km_mm
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {formatter.format(
                                        tireReview.tire_review_depth_line.cpk
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {kmFormatter.format(
                                        tireReview.tire_review_depth_line
                                          .projection
                                      )}
                                    </td>
                                    <td
                                      className={classnames(
                                        css.table_cell,
                                        "align-middle"
                                      )}
                                    >
                                      {tireReview.tire_damage.length > 0 && (
                                        <button
                                          className={classnames(
                                            css.btn,
                                            "btn-sm",
                                            "btn-danger"
                                          )}
                                          onClick={() =>
                                            handleOpenDamageDialog(
                                              tireReview.tire_damage
                                            )
                                          }
                                        >
                                          <ReportProblemRoundedIcon
                                            fontSize="small"
                                            className="mr-1"
                                          />
                                          {t("_labels.damage.plural")}
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={vehicleReview.current_page}
              totalPages={vehicleReview.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterForm
        open={openFilter}
        filter={filter}
        setFiltersAction={setVehicleReviewFilters}
        clearFiltersAction={clearVehicleReviewFilters}
        onClose={handleCloseFilter}
        fields={{
          date: true,
          review_type: true,
        }}
        initialDefaultValues={{
          date_from: "",
          date_to: "",
          review_type: [],
        }}
      />

      <DamageDialog
        open={openDamageDialog}
        onClose={handleCloseDamageDialog}
        damages={damages}
      />

      <Alerts
        open={openAlertDialog}
        onClose={handleCloseAlertDialog}
        alerts={reviewAlerts}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleReview: PropTypes.object.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  filter: PropTypes.object,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  setVehicleReviewFilters: PropTypes.func.isRequired,
  clearVehicleReviewFilters: PropTypes.func.isRequired,
  openDamageDialog: PropTypes.bool.isRequired,
  damages: PropTypes.array.isRequired,
  handleOpenDamageDialog: PropTypes.func.isRequired,
  handleCloseDamageDialog: PropTypes.func.isRequired,
  reviewAlerts: PropTypes.array,
  openAlertDialog: PropTypes.bool,
  handleOpenAlertDialog: PropTypes.func.isRequired,
  handleCloseAlertDialog: PropTypes.func.isRequired,
  savePdf: PropTypes.func.isRequired,
};

export default Page;
