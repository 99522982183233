import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPotentialSavingsReport } from "store/actions/reports/potential_savings/getPotentialSavingsReport";

import Page from "./page";

function Semaphore({ data }) {
  const t = useLang();

  return (
    <Page
      t={t}
      semaphoreData={getMountsData(data)}
      summaryDepthData={getMountsDepthData(data)}
      summaryDepthSizeData={getMountsDepthSizeData(data)}
      potentialSavingsData={getPotentialSavings(data)}
    />
  );
}
Semaphore.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.PotentialSavings.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReport: actionGetPotentialSavingsReport(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Semaphore);

function getMountsData(data) {
  if (data && data.semaphore && Object.keys(data.semaphore).length > 0) {
    return data.semaphore;
  }

  return {};
}

function getMountsDepthData(data) {
  if (data && data.tire_condition_depth_condition) {
    return data.tire_condition_depth_condition;
  }

  return {};
}

function getMountsDepthSizeData(data) {
  if (data && data.semaphore_depth_condition_size) {
    return data.semaphore_depth_condition_size["CRITICAL WITHDRAWAL"];
  }
  return {};
}

function getPotentialSavings(data) {
  if (data && data.semaphore_savings) {
    return data.semaphore_savings["CRITICAL WITHDRAWAL"];
  }
  return {};
}
