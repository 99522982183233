import {
  GET_DAMAGE_TIRES,
  CLEAR_DAMAGE_TIRES,
  SET_DAMAGE_TIRE_FILTERS,
  CLEAR_DAMAGE_TIRE_FILTERS,
  DELETE_TIRE_DAMAGE,
} from "store/actions";

const initialState = {
  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DAMAGE_TIRES:
      return {
        ...state,
        tires: action.payload,
      };
    case CLEAR_DAMAGE_TIRES:
      return {
        ...state,
        tires: initialState.tires,
      };

    case SET_DAMAGE_TIRE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_DAMAGE_TIRE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_TIRE_DAMAGE:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: state.tires.data.filter(
            (tire) => tire.tire_damage_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
