import { INTERVAL_REFRESH } from "store/actions";

export const actionSetIntervalRefresh = (dispatch) => (intervalRefresh) => {
  dispatch({
    type: INTERVAL_REFRESH,
    payload: intervalRefresh,
  });

  return intervalRefresh;
};
