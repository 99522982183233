import {
  GET_USER_SUBSIDIARY,
  CLEAR_USER_SUBSIDIARY,
  GET_USER_SUBSIDIARIES,
  CLEAR_USER_SUBSIDIARIES,
  DELETE_USER_IN_SUBSIDIARY,
  SET_USER_SUBSIDIARIES_FILTERS,
  CLEAR_USER_SUBSIDIARIES_FILTERS,
} from "store/actions";

const initialState = {
  subsidiaries: {
    data: [],
    last_page: 1,
    current_page: 1,
  },
  filter: {},
  user_subsidiary: {
    user: {
      name: "",
      last_name_1: "",
    },
    subsidiary: {
      name: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    role: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_SUBSIDIARY:
      return {
        ...state,
        user_subsidiary: action.payload,
      };
    case CLEAR_USER_SUBSIDIARY:
      return {
        ...state,
        user_subsidiary: { ...initialState.user_subsidiary },
      };

    case GET_USER_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: action.payload,
      };
    case CLEAR_USER_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: { ...initialState.subsidiaries },
      };
    case SET_USER_SUBSIDIARIES_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_USER_SUBSIDIARIES_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };
    case DELETE_USER_IN_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: {
          ...state.subsidiaries,
          data: state.subsidiaries.data.filter(
            (subsidiary) =>
              subsidiary.user_assigned_subsidiary_id !== action.payload
          ),
        },
      };
    default:
      return state;
  }
}
