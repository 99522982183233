import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { values, setFieldValue, setNotification, getRevitalizedModels } = rest;
  const [models, setModels] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getRevitalizedModels(
          {
            scope: "revitalized_tire_model_id,name,status,depth,approved",
            brands: values.revitalized_brand,
          },
          false
        );

        setModels(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.revitalized_brand) {
      fetchData();
    }
  }, [values.revitalized_brand]);

  useEffect(() => {
    if (values.revitalized_tire_model_id) {
      const model = models.find(
        (model) =>
          model.revitalized_tire_model_id == values.revitalized_tire_model_id
      );

      if (model.depth) {
        setFieldValue("millimeter", model.depth, false);
      }
    }
  }, [values.revitalized_tire_model_id]);
  return <Page t={t} {...rest} models={models} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  damages: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  wears: PropTypes.array.isRequired,
  retirementCauses: PropTypes.array.isRequired,
  drivers: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  to: PropTypes.string.isRequired,
  movement: PropTypes.string,
  movementTireId: PropTypes.number,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRevitalizedModels: actionGetRevitalizedTireModels(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
