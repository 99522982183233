export default {
  unassigned_vehicle: "Unassigned vehicle",
  change_vehicle: "Change vehicle",
  link_vehicle: "Link vehicle",
  messages: {
    vehicle_changed: "Done, the vehicle has been successfully changed",
    same_vehicle: "The vehicle is the same as above",
  },
  permits: {
    view_linked_vehicle:
      "No permissions to view the vehicle linked to the driver",
  },
};
