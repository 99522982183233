import {
  GET_WAREHOUSE_TIRES_HISTORY,
  CLEAR_WAREHOUSE_TIRES_HISTORY,
} from "store/actions";

const initialState = {
  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WAREHOUSE_TIRES_HISTORY:
      return {
        ...state,
        tires: action.payload,
      };

    case CLEAR_WAREHOUSE_TIRES_HISTORY:
      return {
        ...state,
        tires: initialState.tires,
      };

    default:
      return state;
  }
}
