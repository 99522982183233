export default {
  title: "New Driver",
  update_title: "Update driver",
  form: {
    permits: {
      update: "No permissions to update drivers",
      delete: "No permissions to delete drivers",
    },
  },
  messages: {
    delete: "Are you sure to delete this driver?",
    driver_created: "Done, the driver has been successfully created",
    driver_updated: "Done, the driver was updated successfully",
  },
};
