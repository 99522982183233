import { GET_CHECKLIST_HISTORY, CLEAR_CHECKLIST_HISTORY } from "store/actions";

const initialState = {
  checklists: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHECKLIST_HISTORY:
      return {
        ...state,
        checklists: action.payload,
      };

    case CLEAR_CHECKLIST_HISTORY:
      return {
        ...state,
        checklists: { ...initialState.checklists },
      };

    default:
      return state;
  }
}
