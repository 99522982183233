import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";

import Button from "components/molecules/general/Button";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import VehicleHistoryTabs from "components/organisms/vehicle/VehicleHistoryTabs";
import VehicleTravelHistoryCard from "components/molecules/vehicle/travel/VehicleTravelHistoryCard";
import FilterVehicleTravelHistoryForm from "components/organisms/vehicle/travel/history/FilterVehicleTravelHistoryForm";

import css from "./index.module.scss";

function Page({
  t,
  travels,
  handleChangePage,
  handleExitHistory,
  filterOpen,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <VehicleHistoryTabs
      className={classnames({
        [css.empty]: travels.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            variant="icon"
            className={classnames("bw")}
            onClick={handleExitHistory}
          >
            <VisibilityIcon />
          </Button>
          <Button
            className={classnames("bw", css.filter)}
            variant="icon"
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
        </>
      }
    >
      {travels.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            {travels.data.map((travel, index) => {
              return (
                <VehicleTravelHistoryCard
                  key={index}
                  date={travel.date}
                  className={css.card}
                  odometer={travel.odometer}
                />
              );
            })}
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={travels.current_page}
              totalPages={travels.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("no_travels")} />
      )}
      <FilterVehicleTravelHistoryForm
        open={filterOpen}
        onClose={handleCloseFilter}
      />
    </VehicleHistoryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  travels: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleExitHistory: PropTypes.func.isRequired,
  filterOpen: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
};

export default Page;
