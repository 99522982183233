import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionSetNotification } from "store/actions/general/notification";
import { actionAddTireModelVariation } from "store/actions/tire/model/variation/addTireModelVariation";
import { actionGetTireModelVariation } from "store/actions/tire/model/variation//getTireModelVariation";
import { actionUpdateTireModelVariation } from "store/actions/tire/model/variation//updateTireModelVariation";
import { actionDeleteTireModelVariation } from "store/actions/tire/model/variation//deleteTireModelVariation";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/model/variation/TireModelVariationForm";

export function TireModelVariationForm({
  tireModelId,
  open,
  tireModelVariationId,
  onClose,
  ...rest
}) {
  const t = useLang(NAMESPACE);
  const [tireSizes, setTireSizes] = useState([]);
  const [isUpdate, setIsUpdate] = useState(tireModelVariationId ? true : false);
  const {
    tireModelVariation,
    setNotification,
    getTireSizes,
    addTireModelVariation,
    getTireModelVariation,
    deleteTireModelVariation,
    updateTireModelVariation,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      size: "",
      number_layers: "",
      depth: "",
      maximum_pressure: "",
      helmet_value: "",
      recommended_pressure: "",
      tolerance: "",
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModelVariation(tireModelId, tireModelVariationId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (tireModelVariationId && open) {
      fetchData();
    }
  }, [tireModelVariationId, open]);

  useEffect(() => {
    if (tireModelVariationId && tireModelVariation.tire_model_variation_id) {
      setInitialValues({
        size: tireModelVariation.tire_size_id.toString(),
        number_layers: tireModelVariation.number_layers.toString(),
        depth: tireModelVariation.depth.toString(),
        maximum_pressure: tireModelVariation.maximum_pressure
          ? tireModelVariation.maximum_pressure.toString()
          : "",
        helmet_value: tireModelVariation.helmet_value
          ? tireModelVariation.helmet_value.toString()
          : "",
        recommended_pressure: tireModelVariation.recommended_pressure
          ? tireModelVariation.recommended_pressure.toString()
          : "",
        tolerance: tireModelVariation.tolerance
          ? tireModelVariation.tolerance.toString()
          : "",
      });
    } else {
      setInitialValues({
        size: "",
        number_layers: "",
        depth: "",
        maximum_pressure: "",
        helmet_value: "",
        recommended_pressure: "",
        tolerance: "",
      });
    }
  }, [tireModelVariation, tireModelVariationId]);

  useEffect(() => {
    setIsUpdate(tireModelVariationId ? true : false);
  }, [tireModelVariationId]);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getTireSizes({ scope: "tire_size_id,size,status" }, false);
        setTireSizes(data.sort(alphabeticalSort("size")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      tire_size_id: values.size,
      number_layers: values.number_layers,
      depth: values.depth,
    };

    if (isUpdate || values.maximum_pressure !== "") {
      fields.maximum_pressure = values.maximum_pressure;
    }

    if (isUpdate || values.helmet_value !== "") {
      fields.helmet_value = values.helmet_value;
    }

    if (isUpdate || values.recommended_pressure !== "") {
      fields.recommended_pressure = values.recommended_pressure;
    }

    if (isUpdate || values.tolerance !== "") {
      fields.tolerance = values.tolerance;
    }

    try {
      if (!isUpdate) {
        await addTireModelVariation(fields, tireModelId);
        resetForm();
        setNotification({
          message: t("messages.variation_model_created"),
        });
      } else {
        await updateTireModelVariation(
          fields,
          tireModelId,
          tireModelVariationId
        );
        setNotification({
          message: t("messages.tire_model_variation_updated"),
        });
      }

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  async function handleDeleteTireModelVariation() {
    try {
      const response = await deleteTireModelVariation(
        tireModelId,
        tireModelVariationId
      );
      onClose();
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete() {
    setNotification({
      message: t("messages.delete_model_variation"),
      textAction: t("_messages.confirm"),
      onAction: handleDeleteTireModelVariation,
    });
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      tireSizes={tireSizes}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleValidate={handleFormikValidate}
    />
  );
}

TireModelVariationForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tireModelId: PropTypes.string,
  tireModelVariationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const mapStateToProps = (state) => ({
  tireModelVariation: state.Tire.Model.Variation.variation,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
  addTireModelVariation: actionAddTireModelVariation(dispatch),
  getTireModelVariation: actionGetTireModelVariation(dispatch),
  deleteTireModelVariation: actionDeleteTireModelVariation(dispatch),
  updateTireModelVariation: actionUpdateTireModelVariation(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TireModelVariationForm);
