import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

export function Home({
  createTooltipPermit,
  item,
  name,
  filter,
  currentPage,
  deleteConfirmationMessage,
  disableConfirmationMessage,
  enableConfirmationMessage,
  approveConfirmationMessage,
  disapproveConfirmationMessage,
  rows,
  title,
  headers,
  lastPage,
  noItemsText,
  initialDefaultValues,
  fields,
  createFormTitle,
  updateFormTitle,
  filterFields,
  filterInitialDefaultValues,
  createdMessage,
  updatedMessage,

  getItemAction,
  setFiltersAction,
  clearFiltersAction,
  deleteAction,
  setStatusAction,
  setApprovedAction,
  getItemsAction,
  clearItemsAction,
  addAction,
  updateAction,
  handleReset,
  handleResetDiff,

  withImageDialog,
  children,
  openExcelForm,
  handleCloseExcelForm,
  withLinkTire,
  withLinkVehicle,
  selectedFilter,
  setSelectedFilters,
  ...rest
}) {
  const t = useLang();
  const { setNotification } = rest;
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openLinkForm, setOpenLinkForm] = useState(false);
  const [image, setImage] = useState(null);
  const [imageDialogTitle, setImageDialogTitle] = useState("");

  const previousFilter = useRef();

  useEffect(() => {
    const previous = { ...previousFilter.current };
    const current = { ...filter };
    const previousIsEmpty = Object.keys(previous).length === 0;
    delete previous.page;
    delete current.page;
    previousFilter.current = filter;

    if (
      !previousIsEmpty &&
      JSON.stringify(previous) !== JSON.stringify(current)
    ) {
      setFiltersAction({ page: 1 });
      return;
    }

    async function fetchData() {
      try {
        await getItemsAction({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearItemsAction();
    };
  }, [filter]);

  useEffect(() => {
    if (name === "vehicle") {
      return;
    }
    return () => {
      clearFiltersAction();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [rows]);

  function handleDialog() {
    setOpen(true);
  }

  function handleClose() {
    setId(null);
    setOpen(false);
  }

  function handleDialogFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setFiltersAction({ search });
  }

  async function onChangePage(page) {
    setFiltersAction({ page: page });
  }

  function handleEdit(id) {
    setId(id);
    setOpen(true);
  }

  async function handleDeleteItem(id) {
    try {
      const response = await deleteAction(id);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(id) {
    setNotification({
      onAction: () => {
        handleDeleteItem(id);
      },
      message: deleteConfirmationMessage,
      textAction: t("_buttons.confirm"),
    });
  }

  async function changeStatus({ id, status }) {
    try {
      const response = await setStatusAction(id, status ? 0 : 1);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeStatus(item) {
    setNotification({
      onAction: () => {
        changeStatus(item);
      },
      message: item.status
        ? disableConfirmationMessage
        : enableConfirmationMessage,
      textAction: t("_messages.confirm"),
    });
  }

  async function changeApproved({ id, approved }) {
    try {
      const response = await setApprovedAction(id, approved ? 0 : 1);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleChangeApproved(item) {
    setNotification({
      onAction: () => {
        changeApproved(item);
      },
      message: item.approved
        ? disapproveConfirmationMessage
        : approveConfirmationMessage,
      textAction: t("_messages.confirm"),
    });
  }

  function handleOpenImage(img, title) {
    setImage(img);
    setImageDialogTitle(title);
    setOpenImage(true);
  }

  function handleCloseImage() {
    setImage(null);
    setImageDialogTitle("");
    setOpenImage(false);
  }

  function handleLinkTire(id) {
    setId(id);
    setOpenLinkForm(true);
  }

  function handleCloseLinkTire() {
    setId(null);
    setOpenLinkForm(false);
  }

  return (
    <Page
      t={t}
      id={id}
      item={item}
      open={open}
      openFilter={openFilter}
      filter={filter}
      createTooltipPermit={createTooltipPermit}
      rows={rows}
      title={title}
      headers={headers}
      currentPage={currentPage}
      lastPage={lastPage}
      noItemsText={noItemsText}
      name={name}
      initialDefaultValues={initialDefaultValues}
      fields={fields}
      createFormTitle={createFormTitle}
      updateFormTitle={updateFormTitle}
      filterFields={filterFields}
      filterInitialDefaultValues={filterInitialDefaultValues}
      createdMessage={createdMessage}
      updatedMessage={updatedMessage}
      handleDialog={handleDialog}
      handleClose={handleClose}
      handleDialogFilter={handleDialogFilter}
      handleCloseFilter={handleCloseFilter}
      handleSearch={handleSearch}
      onChangePage={onChangePage}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onChangeStatus={handleChangeStatus}
      onChangeApproved={handleChangeApproved}
      getItemAction={getItemAction}
      addAction={addAction}
      updateAction={updateAction}
      clearFiltersAction={clearFiltersAction}
      setFiltersAction={setFiltersAction}
      withImageDialog={withImageDialog}
      imageDialogTitle={imageDialogTitle}
      image={image}
      openImage={openImage}
      onOpenImage={handleOpenImage}
      handleCloseImage={handleCloseImage}
      onReset={handleReset}
      onResetDiff={handleResetDiff}
      openExcelForm={openExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
      openLinkForm={openLinkForm}
      handleLinkTire={handleLinkTire}
      handleCloseLinkTire={handleCloseLinkTire}
      withLinkTire={withLinkTire}
      withLinkVehicle={withLinkVehicle}
      selectedFilter={selectedFilter}
      setSelectedFilters={setSelectedFilters}
    >
      {children}
    </Page>
  );
}

Home.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  createTooltipPermit: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  deleteConfirmationMessage: PropTypes.string.isRequired,
  disableConfirmationMessage: PropTypes.string.isRequired,
  enableConfirmationMessage: PropTypes.string.isRequired,
  approveConfirmationMessage: PropTypes.string,
  disapproveConfirmationMessage: PropTypes.string,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  lastPage: PropTypes.number.isRequired,
  noItemsText: PropTypes.string.isRequired,
  initialDefaultValues: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  createFormTitle: PropTypes.string.isRequired,
  updateFormTitle: PropTypes.string.isRequired,
  filterFields: PropTypes.object.isRequired,
  filterInitialDefaultValues: PropTypes.object.isRequired,
  createdMessage: PropTypes.string,
  updatedMessage: PropTypes.string.isRequired,

  getItemAction: PropTypes.func.isRequired,
  getItemsAction: PropTypes.func.isRequired,
  clearItemsAction: PropTypes.func.isRequired,
  setFiltersAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  setStatusAction: PropTypes.func.isRequired,
  setApprovedAction: PropTypes.func,
  addAction: PropTypes.func,
  updateAction: PropTypes.func.isRequired,
  handleReset: PropTypes.func,
  handleResetDiff: PropTypes.func,

  withImageDialog: PropTypes.bool,
  imageDialogTitle: PropTypes.string,

  children: PropTypes.node,
  openExcelForm: PropTypes.bool,
  handleCloseExcelForm: PropTypes.func,

  withLinkTire: PropTypes.bool,
  withLinkVehicle: PropTypes.bool,

  selectedFilter: PropTypes.object,
  setSelectedFilters: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
