import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { actionTireRotation } from "store/actions/vehicle/mount/tireRotation";
import { actionSingleRotation } from "store/actions/vehicle/mount/singleRotation";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionSetNotification } from "store/actions/general/notification";

import Page from "./page";

export function TireRotationForm({ open, onClose, ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    origin,
    destination,
    setNotification,
    tireRotation,
    singleRotation,
    getVehicleAxles,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      depth_from: "",
      depth_to: "",
      depth: "",
    },
  });

  useEffect(() => {
    if (open) {
      const values = {};
      if (destination.vehicle_tire.length > 0) {
        values.depth_from = "";
        values.depth_to = "";
      } else {
        values.depth = "";
      }

      setInitialValues(values);
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      let response;
      if (destination.vehicle_tire.length === 0) {
        response = await singleRotation(id, {
          vehicle_type_axle_tire_id: destination.vehicle_type_axle_tire_id,
          movement_tire_id: origin.vehicle_tire[0].movement_tire_id,
          depth: values.depth,
        });
      } else {
        const fields = {
          vehicle_type_axle_tire_id_from: origin.vehicle_type_axle_tire_id,
          movement_tire_id_from: origin.vehicle_tire[0].movement_tire_id,
          depth_from: values.depth_from,
          vehicle_type_axle_tire_id_to: destination.vehicle_type_axle_tire_id,
          movement_tire_id_to: destination.vehicle_tire[0].movement_tire_id,
          depth_to: values.depth_to,
        };

        response = await tireRotation(id, fields);
      }

      setNotification({
        message: response.message,
      });
      onClose();
      getVehicleAxles(id);
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      onClose={onClose}
      origin={origin}
      destination={destination}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

TireRotationForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  origin: PropTypes.object,
  destination: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  tireRotation: actionTireRotation(dispatch),
  singleRotation: actionSingleRotation(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireRotationForm);
