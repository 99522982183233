export default {
  gps_disabled: "Gps disabled",
  permits: {
    change_status: "No permissions to {action} gps",
  },
  messages: {
    enable: "Are you sure to enable the gps?",
    disable: "Are you sure to disable the gps?",
  },
};
