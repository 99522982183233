import {
  GET_DEPTH_POLICIES,
  CLEAR_DEPTH_POLICIES,
  GET_DEPTH_POLICY,
  CLEAR_DEPTH_POLICY,
  REGISTER_DEPTH_POLICY,
  UPDATE_DEPTH_POLICY,
  DELETE_DEPTH_POLICY,
  SET_DEPTH_POLICY_FILTERS,
  CLEAR_DEPTH_POLICY_FILTERS,
} from "store/actions";

const initialState = {
  depth_policy: {
    subsidiary_id: 0,
    vehicle_id: 0,
    position: "",
    brand: {
      vehicle_brand_id: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
  },
  depth_policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEPTH_POLICY:
    case REGISTER_DEPTH_POLICY:
    case UPDATE_DEPTH_POLICY:
      return {
        ...state,
        depth_policy: action.payload,
      };

    case GET_DEPTH_POLICIES:
      return {
        ...state,
        depth_policies: action.payload,
      };

    case CLEAR_DEPTH_POLICIES:
      return {
        ...state,
        depth_policies: { ...initialState.depth_policies },
      };

    case CLEAR_DEPTH_POLICY:
      return {
        ...state,
        depth_policy: { ...initialState.depth_policy },
      };

    case DELETE_DEPTH_POLICY:
      return {
        ...state,
        depth_policies: {
          ...state.depth_policies,
          data: state.depth_policies.data.filter(
            (vehicleDepthPolicy) =>
              vehicleDepthPolicy.vehicle_depth_policy_id !== action.payload
          ),
        },
      };

    case SET_DEPTH_POLICY_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_DEPTH_POLICY_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };
    default:
      return state;
  }
}
