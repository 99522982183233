import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { actionSetRedirect } from "store/actions/general/redirect";

import Page from "./page";

function Router({ ...rest }) {
  const { redirect, setRedirect } = rest;
  const [to, setTo] = useState(redirect);

  useEffect(() => {
    if (redirect) {
      setTo(redirect);
      setRedirect(null);
    }
  }, [redirect]);

  return <Page to={to} />;
}

Router.propTypes = {};

const mapStateToProps = (state) => ({
  redirect: state.General.redirect,
});
const mapDispatchToProps = (dispatch) => ({
  setRedirect: actionSetRedirect(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
