import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetDynamicCostReport } from "store/actions/reports/dynamic_cost/getDynamicCostReport";
import { actionGenerateDynamicCostExcel } from "store/actions/reports/dynamic_cost/generateDynamicCostExcel";
import { actionClearDynamicCostFilters } from "store/actions/reports/dynamic_cost/clearDynamicCostFilters";
import { actionSetDynamicCostFilters } from "store/actions/reports/dynamic_cost/setDynamicCostFilters";
import { actionSetSelectedDynamicCostFilters } from "store/actions/reports/dynamic_cost/setSelectedDynamicCostFilters";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import { useLang } from "hooks/lang";

import Page from "./page";

function DynamicCost({ ...rest }) {
  const t = useLang();
  const query = useLocation();
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState("General");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [params, setParams] = useState({});
  const {
    filter,
    selectedFilter,
    setNotification,
    getDynamicCostReport,
    clearDynamicCostFilters,
    generateDynamicCostExcel,
    setSelectedFilters,
    setDynamicCostFilters,
    account,
    workArea,
  } = rest;

  const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
  const policy = subsidiaries[0]?.subsidiary.policy;
  const policyNumberCycle = policy?.number_cycle ?? 0;

  useEffect(() => {
    async function fetchData() {
      let numberCycle = "G";
      switch (currentTab) {
        case "General":
          numberCycle = "G";
          break;
        case "Revitalized":
          numberCycle = "R";
          break;
        case "Original":
          numberCycle = "0";
          break;
        case "1":
          numberCycle = "1";
          break;
        case "2":
          numberCycle = "2";
          break;
        case "3":
          numberCycle = "3";
          break;
        case "4":
          numberCycle = "4";
          break;
        default:
          break;
      }
      try {
        const response = await getDynamicCostReport({
          ...filter,
          number_cycle: numberCycle,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter, currentTab]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");

    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const selectedFilter = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
    };

    const params = new URLSearchParams(query.search);

    if (params.has("date")) {
      const date = moment(params.get("date"));
      const startDate = date.startOf("month").format("YYYY-MM-DD");
      const endDate = date.endOf("month").format("YYYY-MM-DD");

      filter.date_from = startDate;
      filter.date_to = endDate;
      selectedFilter.date_from = startDate;
      selectedFilter.date_to = endDate;

      if (params.has("subsidiary")) {
        const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
        const subsidiary = getSubsidiaries(account.subsidiaries)
          .filter(
            (subsidiary) =>
              subsidiary.company_id === subsidiaries[0].subsidiary.company_id
          )
          .find((subsidiary) => subsidiary.name === params.get("subsidiary"));

        if (subsidiary) {
          filter.subsidiary_id = subsidiary.subsidiary_id.toString();
          selectedFilter.subsidiaries = subsidiary.name;
        }
      } else {
        delete filter.subsidiary_id;
        delete selectedFilter.subsidiaries;
      }
      setCurrentTab("General");
    }

    setDynamicCostFilters(filter);
    setSelectedFilters(selectedFilter);

    return () => {
      clearDynamicCostFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleViewTires({ ...params }) {
    setParams({ ...filter, ...params });
    setOpenTireDialog(true);
  }

  function handleCloseTireDialog() {
    setOpenTireDialog(false);
    setParams({});
  }

  async function handleGenerateDynamicCostExcel() {
    try {
      await generateDynamicCostExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSearch(search) {
    if (search === "") {
      setLastMovementReportFilters({ ...filter, search: undefined });
    } else {
      setLastMovementReportFilters({ ...filter, search });
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      search={filter.search}
      generalData={getGeneral(data)}
      originalData={getOriginal(data)}
      revitalizedData={getRevitalized(data)}
      oneData={getOne(data)}
      twoData={getTwo(data)}
      treeData={getTree(data)}
      fourData={getFour(data)}
      policyNumberCycle={policyNumberCycle}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      openTireDialog={openTireDialog}
      params={params}
      handleViewTires={handleViewTires}
      handleCloseTireDialog={handleCloseTireDialog}
      handleGenerateDynamicCostExcel={handleGenerateDynamicCostExcel}
      handleSearch={handleSearch}
    />
  );
}
DynamicCost.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.DynamicCost.filter,
  selectedFilter: state.Reports.DynamicCost.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getDynamicCostReport: actionGetDynamicCostReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearDynamicCostFilters: actionClearDynamicCostFilters(dispatch),
  generateDynamicCostExcel: actionGenerateDynamicCostExcel(dispatch),
  setDynamicCostFilters: actionSetDynamicCostFilters(dispatch),
  setSelectedFilters: actionSetSelectedDynamicCostFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicCost);

function getGeneral(data) {
  if (data.general && Object.keys(data.general).length > 0) {
    const result = data.general;

    return result;
  }

  return {};
}

function getOriginal(data) {
  if (data.origin && Object.keys(data.origin).length > 0) {
    const result = data.origin;

    return result;
  }

  return {};
}

function getRevitalized(data) {
  if (data.revitalized && Object.keys(data.revitalized).length > 0) {
    const result = data.revitalized;
    return result;
  }

  return {};
}

function getOne(data) {
  if (data["1"] && Object.keys(data["1"]).length > 0) {
    const result = data["1"];
    return result;
  }
  return {};
}

function getTwo(data) {
  if (data["2"] && Object.keys(data["2"]).length > 0) {
    const result = data["2"];
    return result;
  }
  return {};
}

function getTree(data) {
  if (data["3"] && Object.keys(data["3"]).length > 0) {
    const result = data["3"];
    return result;
  }
  return {};
}
function getFour(data) {
  if (data["4"] && Object.keys(data["4"]).length > 0) {
    const result = data["4"];
    return result;
  }
  return {};
}

function getSubsidiaries(assignedSubsidiaries) {
  return assignedSubsidiaries.map(
    (assignedSubsidiary) => assignedSubsidiary.subsidiary
  );
}
