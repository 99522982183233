import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Typography from "@material-ui/core/Typography";
import Container from "components/templates/general/Container";
// import SearchInput from "components/molecules/general/SearchInput";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";

import css from "./index.module.scss";
import moment from "moment";
import ChecklistReviewDialog from "./ChecklistReviewDialog";

function Page({
  t,
  checklists,
  checklist,
  economicNumber,
  openDialog,
  handleOpenDialog,
  handleCloseDialog,
  checklistReview,
  handleChangePage,
}) {
  return (
    <Container
      title={t("_general.checklist")}
      className={classnames({
        [css.empty]: checklists.data.length === 0,
      })}
      // options={<SearchInput search={""} onChange={() => { }} />}
    >
      <Typography variant="h5" className="px-4 pt-3">
        {t("_general.vehicle")}: {economicNumber}
      </Typography>
      {checklists.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                  "text-center": true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th className="pl-4">Num. de Conceptos</th>
                    <th>Bien</th>
                    <th>Mal</th>
                    <th>Críticos Mal</th>
                    <th>{t("_labels.registered_by")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {checklists.data.map((checklist) => (
                    <tr key={checklist.check_list_review_id}>
                      <td className="pl-4">{checklist.number_concept}</td>
                      <td>{checklist.good_concept}</td>
                      <td>{checklist.bad_concept}</td>
                      <td>{checklist.critical_bad_concept}</td>
                      <td>
                        {t("_labels.actioned_by", {
                          person: `${checklist.created_by.name} ${
                            checklist.created_by.last_name_1
                          } ${checklist.created_by.last_name_2 ?? ""}`,
                          date: moment(checklist.created_at).format("LL"),
                          time: moment(checklist.created_at).format("HH:mm a"),
                        })}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-info"
                          style={{ minWidth: "fit-content" }}
                          onClick={() =>
                            handleOpenDialog(
                              checklist.check_list,
                              checklist.check_list_info
                            )
                          }
                        >
                          {t("_labels.alert.details")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={checklists.current_page}
              totalPages={checklists.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_checklists")} />
      )}

      <ChecklistReviewDialog
        checklist={checklist}
        checklistReview={checklistReview}
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </Container>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  checklists: PropTypes.object,
  economicNumber: PropTypes.string,
  checklistReview: PropTypes.string,
  checklist: PropTypes.object,
  openDialog: PropTypes.bool,
  handleOpenDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default Page;
