import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  values,
  corporates,
  companies,
  subsidiaries,
  brands,
  models,
  sizes,
  setFieldValue,
  initialValues,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Field
        id="corporate_id"
        name="corporate_id"
        autoHighlight
        disableClearable={initialValues.corporate_id !== null}
        blurOnSelect
        component={Autocomplete}
        options={corporates}
        getOptionLabel={(option) => option?.name ?? ""}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) => {
          return value && `${option.corporate_id}` === `${value.corporate_id}`;
        }}
        onChange={(_, value) =>
          setFieldValue("corporate_id", `${value.corporate_id}`)
        }
        value={corporates.find(
          (c) => `${c.corporate_id}` === `${values.corporate_id}`
        )}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.corporate_field.singular.label")}
            variant="filled"
          />
        )}
      />

      <Field
        fullWidth
        multiple
        id="company_id"
        name="company_id"
        autoHighlight
        disableClearable={initialValues.company_id.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={companies}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          `${option.company_id}` === `${value.company_id}`
        }
        onChange={(_, value) =>
          setFieldValue(
            "company_id",
            value.map((c) => `${c.company_id}`)
          )
        }
        value={companies.filter((c) =>
          values.company_id.includes(`${c.company_id}`)
        )}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            variant="filled"
            label={t("_labels.company.plural")}
            helperText={t("_labels.can_select_many_feminine")}
          />
        )}
      />

      <Field
        fullWidth
        multiple
        id="subsidiary_id"
        name="subsidiary_id"
        autoHighlight
        disableClearable={initialValues.subsidiary_id.length > 0}
        blurOnSelect
        component={Autocomplete}
        options={subsidiaries}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) =>
          `${option.subsidiary_id}` === `${value.subsidiary_id}`
        }
        onChange={(_, value) =>
          setFieldValue(
            "subsidiary_id",
            value.map((s) => `${s.subsidiary_id}`)
          )
        }
        value={subsidiaries.filter((s) =>
          values.subsidiary_id.includes(`${s.subsidiary_id}`)
        )}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            variant="filled"
            label={t("_labels.subsidiary.plural")}
            helperText={t("_labels.can_select_many_feminine")}
          />
        )}
      />

      <Field
        id="brands"
        name="brands"
        autoHighlight
        disableClearable={initialValues.brands !== null}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) => {
          return value && `${option.brand_id}` === `${value.brand_id}`;
        }}
        onChange={(_, value) => setFieldValue("brands", `${value.brand_id}`)}
        value={brands.find((b) => `${b.brand_id}` === `${values.brands}`)}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="models"
        name="models"
        autoHighlight
        disableClearable={initialValues.models !== null}
        blurOnSelect
        component={Autocomplete}
        options={models}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) => {
          return (
            value && `${option.tire_model_id}` === `${value.tire_model_id}`
          );
        }}
        onChange={(_, value) =>
          setFieldValue("models", `${value.tire_model_id}`)
        }
        value={models.find((b) => `${b.tire_model_id}` === `${values.models}`)}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        id="sizes"
        name="sizes"
        autoHighlight
        disableClearable={initialValues.sizes !== null}
        blurOnSelect
        component={Autocomplete}
        options={sizes}
        getOptionLabel={(option) => option?.size}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) => {
          return value && `${option.tire_size_id}` === `${value.tire_size_id}`;
        }}
        onChange={(_, value) => setFieldValue("sizes", `${value.tire_size_id}`)}
        value={sizes.find((b) => `${b.tire_size_id}` === `${values.sizes}`)}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_size.label.singular")}
            variant="filled"
          />
        )}
      />

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  corporates: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  sizes: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default Page;
