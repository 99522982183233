import { GET_TIRE_SUBSIDIARY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetTireSubsidiary = (dispatch) => async (tireId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "GET",
      path: `tire/${tireId}/subsidiary`,
    });

    dispatch({
      type: GET_TIRE_SUBSIDIARY,
      payload: response,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
