import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";
import camion from "resources/img/icons/camion-de-reparto.png";
import rueda from "resources/img/icons/rueda.png";

import SemaforoAhorrosPotenciales from "resources/img/semaforos_ahorros_potensiales.png";
import CostosMm from "resources/img/costomm_ahorros_potensiales.png";
import Presion from "resources/img/presion_ahorros_potensiales.png";

import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import ContainerTemplate from "components/templates/general/Container";
import Button from "components/molecules/general/Button";
import DoughnutChart from "components/organisms/general/Charts/DoughnutChart";
import Semaphore from "./Semaphore";
import Costs from "./Costs";
import Analysis from "./Analysis";
import Pressures from "./Pressures";
import FilterPotentialSavingsForm from "components/organisms/report/FilterPotentialSavingsForm";
import Link from "@material-ui/core/Link";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import TireFleetStatus from "./TireFleetStatus";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  vehiclesChart,
  tiresChart,
  tiresDepthChartMount,
  tiresDepthChartWarehouse,
  selectedKey,
  selectKey,
  filter,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
}) {
  const [collapseBrandTable, setCollapseBrandTable] = React.useState(true);
  const [collapseModelTable, setCollapseModelTable] = React.useState(true);
  const [collapseSizeTable, setCollapseSizeTable] = React.useState(true);
  const [value, setValue] = React.useState(true);

  function handleChange() {
    setValue(!value);
  }
  return (
    <ContainerTemplate
      title={t("_titles.potential_savings_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100 px-4">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.company_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiary_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.month && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.month")}
                    </p>
                    <p className={classnames(css.value)}>
                      {moment(selectedFilter.month).format("MMMM YYYY")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <h5 className="px24 sinmp">
              {t("_labels.potential_savings_desc")}
            </h5>
            <p className="pt-1 m-0">
              <Link href={"/reports/summary"} target="_blank">
                {t("_labels.summary_report_link")}
              </Link>
            </p>
          </div>
        </div>

        <div className="row pt-1">
          {selectedKey === "summary" && (
            <>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
          {selectedKey === "semaphore" && (
            <>
              <div className="col-md-2">
                <img src={SemaforoAhorrosPotenciales} className="img-fluid" />
              </div>
              <div className="col-md-10" style={{ paddingTop: 30 }}>
                <h1>{t("_labels.semaphore")}</h1>
                <br />
                <br />
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
          {selectedKey === "costs" && (
            <>
              <div className="col-md-2">
                <img src={CostosMm} className="img-fluid" />
              </div>
              <div className="col-md-10" style={{ paddingTop: 30 }}>
                <h1>{t("_labels.costs").toUpperCase()}</h1>
                <br />
                <br />
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
          {selectedKey === "pressure" && (
            <>
              <div className="col-md-2">
                <img src={Presion} className="img-fluid" />
              </div>
              <div className="col-md-10" style={{ paddingTop: 30 }}>
                <h1>{t("_labels.pressures").toUpperCase()}</h1>
                <br />
                <br />
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
        </div>

        <div className="col-md-12">
          <hr />
        </div>
        <div
          className="row p-0 justify-content-center"
          style={{
            position: "sticky",
            top: 64,
            backgroundColor: "#fafafa",
            zIndex: 10,
          }}
        >
          <div
            className={classnames("col-4", "col-md-2 pointer", css.header_tab)}
            onClick={() => selectKey("summary")}
          >
            <span>{t("_labels.summary").toUpperCase()}</span>
          </div>
          <div
            className={classnames("col-4", "col-md-2 pointer", css.header_tab)}
            onClick={() => selectKey("semaphore")}
          >
            <span>{t("_labels.semaphore").toUpperCase()}</span>
          </div>
          <div
            className={classnames("col-4", "col-md-2 pointer", css.header_tab)}
            onClick={() => selectKey("costs")}
          >
            <span>{t("_labels.cost_mm").toUpperCase()}</span>
          </div>
          <div
            className={classnames("col-4", "col-md-2 pointer", css.header_tab)}
            onClick={() => selectKey("pressure")}
          >
            <span>{t("_labels.air_pressure").toUpperCase()}</span>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
        {selectedKey === "summary" && (
          <>
            <div className="row">
              <div className="col-md-12" style={{ paddingTop: 30 }}>
                <h1>{t("_labels.summary").toUpperCase()}</h1>
                <h6 className="text-muted fwnormal">
                  {t("_labels.summary_desc")}
                </h6>
              </div>

              <div className="row py-2">
                <div className="col-md-12">
                  <div className="container-fluid">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleGenerateExcel}
                    >
                      {t("_buttons.generate_excel")}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-2">
                <h5>
                  {t("_labels.analysis")}
                  <hr />
                </h5>
                <Analysis data={data?.analyses ?? {}} selectKey={selectKey} />
              </div>

              <div className="col-md-12 text-left pdt24">
                <h5
                  id={"Vehicles"}
                  style={{
                    scrollMarginTop: "130px",
                  }}
                >
                  <img src={camion} width={36} />{" "}
                  {t("_labels.vehicle.plural").toUpperCase()}
                  <hr />
                </h5>
              </div>
              <div className="col-md-7">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td />
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.reviewed_vehicles"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.reviewed_vehicles_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.reviewed_vehicles_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.vehicle_type_name ?? {}).length > 0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data.vehicle_type_name?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data.vehicle_type_name?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data.vehicle_type_name?.vehicles_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data.vehicle_type_name?.vehicles_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>100%</td>
                        </tr>
                        {Object.entries(data.vehicle_type_name).map(
                          ([vehicleType, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr key={index}>
                                  <td>{vehicleType}</td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.vehicles_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.vehicles_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.vehicles_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-5">
                <div className="chart-container">
                  {vehiclesChart && Object.keys(vehiclesChart).length > 0 && (
                    <DoughnutChart data={vehiclesChart} legendPosition="top" />
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <hr />
              </div>
              <div className="col-md-12 text-left">
                <h5
                  id={"Tires"}
                  style={{
                    scrollMarginTop: "130px",
                  }}
                >
                  <img src={rueda} width={48} />{" "}
                  {t("_labels.tire.plural").toUpperCase()}
                  <hr />
                </h5>
              </div>
              <div className="col-md-12 text-center ml-2 mt-2 mb-3">
                <h5
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {t("_titles.tires_by_application")}
                </h5>
              </div>
              <div className="col-md-4">
                <div className="chart-container">
                  {tiresChart.chart &&
                    Object.keys(tiresChart.chart).length > 0 && (
                      <DoughnutChart
                        data={tiresChart.chart}
                        legendPosition="top"
                      />
                    )}
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td />
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.mounted")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage_over_fleet"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.warehouse")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.tire_application_id ?? {}).length >
                      0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_application_id?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_application_id?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_application_id?.mount_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_application_id?.mount_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_application_id
                                ?.mount_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_application_id?.warehouse_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_application_id?.warehouse_percentage ??
                                0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_application_id
                                ?.warehouse_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                        </tr>
                        {Object.entries(data?.tire_application_id ?? {}).map(
                          ([tireApplication, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr key={index}>
                                  <td>
                                    {t(
                                      `_labels.tire_application.options.${tireApplication.toLowerCase()}`
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.mount_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.mount_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.mount_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.warehouse_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.warehouse_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.warehouse_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center ml-2 mt-2 mb-3">
                <h5
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {t("_titles.tires_by_location")}
                </h5>
              </div>
              <div className="col-md-4">
                <div className="chart-container">
                  {tiresChart.locationChart &&
                    Object.keys(tiresChart.locationChart).length > 0 && (
                      <DoughnutChart
                        data={tiresChart.locationChart}
                        legendPosition="top"
                      />
                    )}
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td />
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.reviews")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.reviews_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.movement ?? {}).length > 0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.movement?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.movement?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            -
                          </td>
                          <td className={classnames("text-center")}>-</td>
                        </tr>
                        {Object.entries(data?.movement ?? {}).map(
                          ([tireLocation, info]) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr key={tireLocation}>
                                  <td>
                                    {t(
                                      `_labels.location.options.${tireLocation
                                        .replaceAll('"', "")
                                        .toLowerCase()}`
                                    )[0] +
                                      t(
                                        `_labels.location.options.${tireLocation
                                          .replaceAll('"', "")
                                          .toLowerCase()}`
                                      )
                                        .slice(1)
                                        .toLowerCase()}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {Object.values(info)[2]
                                      ? formatter.format(Object.values(info)[2])
                                      : "-"}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {Object.values(info)[2]
                                      ? `${formatter.format(
                                          Object.values(info)[4]
                                        )}%`
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center ml-2 mt-2 mb-3">
                <h5
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {t("_labels.potential_savings_report.by_brand")}
                </h5>
              </div>
              <div className="col-md-4">
                <div className="chart-container">
                  {tiresChart.chart &&
                    Object.keys(tiresChart.brandChart).length > 0 && (
                      <DoughnutChart
                        data={tiresChart.brandChart}
                        legendPosition="top"
                      />
                    )}
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td>
                        <IconButton
                          style={{
                            color: "white",
                          }}
                          onClick={() =>
                            setCollapseBrandTable(!collapseBrandTable)
                          }
                        >
                          {collapseBrandTable ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )}
                        </IconButton>
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.mounted")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage_over_fleet"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.warehouse")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.brand_name ?? {}).length > 0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.brand_name?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.brand_name?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.brand_name?.mount_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.brand_name?.mount_percentage ?? 0
                            )}
                            %
                          </td>

                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.brand_name?.mount_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.brand_name?.warehouse_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.brand_name?.warehouse_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.brand_name
                                ?.warehouse_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                        </tr>
                        {Object.entries(data?.brand_name ?? {}).map(
                          ([brand, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    visibility: collapseBrandTable
                                      ? "collapse"
                                      : "visible",
                                  }}
                                >
                                  <td>{brand}</td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.mount_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.mount_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.mount_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.warehouse_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.warehouse_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.warehouse_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center ml-2 mt-2 mb-3">
                <h5
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {t("_labels.potential_savings_report.by_model")}
                </h5>
              </div>
              <div className="col-md-4">
                <div className="chart-container">
                  {tiresChart.chart &&
                    Object.keys(tiresChart.modelChart).length > 0 && (
                      <DoughnutChart
                        data={tiresChart.modelChart}
                        legendPosition="top"
                      />
                    )}
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td>
                        <IconButton
                          style={{
                            color: "white",
                          }}
                          onClick={() =>
                            setCollapseModelTable(!collapseModelTable)
                          }
                        >
                          {collapseModelTable ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )}
                        </IconButton>
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.mounted")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage_over_fleet"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.warehouse")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.tire_model ?? {}).length > 0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_model?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_model?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_model?.mount_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_model?.mount_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_model?.mount_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_model?.warehouse_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_model?.warehouse_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_model
                                ?.warehouse_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                        </tr>
                        {Object.entries(data?.tire_model ?? {}).map(
                          ([model, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    visibility: collapseModelTable
                                      ? "collapse"
                                      : "visible",
                                  }}
                                >
                                  <td>{model}</td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.mount_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.mount_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.mount_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.warehouse_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.warehouse_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.warehouse_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 text-center ml-2 mt-2 mb-3">
                <h5
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {t("_labels.potential_savings_report.by_size")}
                </h5>
              </div>
              <div className="col-md-4">
                <div className="chart-container">
                  {tiresChart.chart &&
                    Object.keys(tiresChart.sizeChart).length > 0 && (
                      <DoughnutChart
                        data={tiresChart.sizeChart}
                        legendPosition="top"
                      />
                    )}
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-hover sFS12">
                  <thead>
                    <tr style={{ backgroundColor: "#002849", color: "white" }}>
                      <td>
                        <IconButton
                          style={{
                            color: "white",
                          }}
                          onClick={() =>
                            setCollapseSizeTable(!collapseSizeTable)
                          }
                        >
                          {collapseSizeTable ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )}
                        </IconButton>
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.fleet_percentage")}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.mounted")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.mounted_percentage_over_fleet"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t("_labels.potential_savings_report.warehouse")}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage"
                        )}
                      </td>
                      <td width={90} className="text-center">
                        {t(
                          "_labels.potential_savings_report.warehouse_percentage_over_fleet"
                        )}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data?.tire_size ?? {}).length > 0 && (
                      <>
                        <tr>
                          <td>Total</td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_size?.fleet_elements ?? 0
                            )}
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_size?.fleet_percentage ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_size?.mount_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_size?.mount_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_size?.mount_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                          <td
                            className={classnames(
                              "text-center",
                              css.left_border
                            )}
                          >
                            {formatter.format(
                              data?.tire_size?.warehouse_elements ?? 0
                            )}
                          </td>
                          <td className="text-center">
                            {formatter.format(
                              data?.tire_size?.warehouse_percentage ?? 0
                            )}
                            %
                          </td>
                          <td className={classnames("text-center")}>
                            {formatter.format(
                              data?.tire_size
                                ?.warehouse_percentage_over_fleet ?? 0
                            )}
                            %
                          </td>
                        </tr>
                        {Object.entries(data?.tire_size ?? {}).map(
                          ([size, info], index) => {
                            if (typeof info === "object" && info != undefined) {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    visibility: collapseSizeTable
                                      ? "collapse"
                                      : "visible",
                                  }}
                                >
                                  <td>{size}</td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_elements ?? 0
                                    )}
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.fleet_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.mount_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.mount_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.mount_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                  <td
                                    className={classnames(
                                      "text-center",
                                      css.left_border
                                    )}
                                  >
                                    {formatter.format(
                                      info?.warehouse_elements ?? 0
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {formatter.format(
                                      info?.warehouse_percentage ?? 0
                                    )}
                                    %
                                  </td>
                                  <td className={classnames("text-center")}>
                                    {formatter.format(
                                      info?.warehouse_percentage_over_fleet ?? 0
                                    )}
                                    %
                                  </td>
                                </tr>
                              );
                            }
                          }
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <hr />
              </div>
            </div>

            <div className="d-flex align-items-center col-md-3">
              <p
                className="m-0 h5"
                style={{ cursor: "pointer" }}
                onClick={handleChange}
              >
                {t("_labels.report_summary.location.warehouse")}
              </p>
              <div>
                <Switch checked={value} onChange={handleChange} />
              </div>
              <p
                className="m-0 h5"
                style={{ cursor: "pointer" }}
                onClick={handleChange}
              >
                {t("_labels.report_summary.location.mount")}
              </p>
            </div>

            {value ? (
              <TireFleetStatus
                key="mount"
                show={value}
                data={data}
                type="mount"
                tiresDepthChart={tiresDepthChartMount}
                tiresPressureData={data.pressure_condition_mount ?? {}}
              />
            ) : (
              <TireFleetStatus
                key="warehouse"
                show={!value}
                data={data}
                type="warehouse"
                tiresDepthChart={tiresDepthChartWarehouse}
                tiresPressureData={{}}
              />
            )}

            <div className="row">
              <div className="col-md-12 text-right sFS12 pdb10 pdt10">
                {t("_labels.copyright", { year: new Date().getFullYear() })}
              </div>
            </div>
          </>
        )}
        {selectedKey === "semaphore" && <Semaphore data={data} />}
        {selectedKey === "costs" && <Costs data={data} />}
        {selectedKey === "pressure" && <Pressures data={data} />}
      </div>

      <FilterPotentialSavingsForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  vehiclesChart: PropTypes.object.isRequired,
  tiresChart: PropTypes.object.isRequired,
  tiresDepthData: PropTypes.object.isRequired,
  tiresDepthChartMount: PropTypes.object.isRequired,
  tiresDepthChartWarehouse: PropTypes.object.isRequired,
  tiresPressureData: PropTypes.object.isRequired,
  selectedKey: PropTypes.string.isRequired,
  selectKey: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
};

export default Page;
