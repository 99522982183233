import { http } from "store/actions/http";
import { REGISTER_VEHICLE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddVehicle = (dispatch) => async (fields) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  if (fields.division_id === "") {
    delete fields["division_id"];
  }

  if (fields.driver_id === "") {
    delete fields["driver_id"];
  }

  try {
    const response = await http({
      method: "POST",
      path: "vehicle",
      data: fields,
    });

    dispatch({
      type: REGISTER_VEHICLE,
      payload: response,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
