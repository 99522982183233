import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetChecklists } from "store/actions/checklist/getChecklists";
import { actionClearChecklists } from "store/actions/checklist/clearChecklists";
import { actionSetChecklistFilters } from "store/actions/checklist/setChecklistFilters";
import { actionClearChecklistFilters } from "store/actions/checklist/clearChecklistFilters";
import { actionDeleteChecklist } from "store/actions/checklist/deleteChecklist";

import { useLang } from "hooks/lang";
import Page from "./page";
import {
  CREATE_CHECK_LIST,
  DELETE_CHECK_LIST,
  SHOW_CHECK_LIST,
  UPDATE_CHECK_LIST,
} from "store/actions/account/permits";

function HomeChecklist({ ...rest }) {
  const t = useLang();
  const {
    permits,
    filter,
    checklists,
    setNotification,
    getChecklists,
    clearChecklists,
    deleteChecklist,
    setChecklistFilters,
    clearChecklistFilters,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [checklistId, setChecklistId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getChecklists({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearChecklists();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearChecklistFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getChecklists({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(checklistId) {
    setNotification({
      message: t("_messages.delete.checklist"),
      onAction: () => onDelete(checklistId),
      textAction: t("_buttons.confirm"),
    });
  }

  async function onDelete(checklistId) {
    try {
      const response = await deleteChecklist(checklistId);
      setNotification({ message: response.message });
      clearChecklistFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setChecklistId(null);
  }

  function handleEdit(checklistId) {
    setChecklistId(checklistId);
    setOpenForm(true);
  }

  function handleSearch(search) {
    setChecklistFilters({ ...filter, search });
  }

  function handleShow(checklistId) {
    setChecklistId(checklistId);
    setOpenDialog(true);
  }

  function handleCloseShow() {
    setOpenDialog(false);
    setChecklistId(null);
  }

  return (
    <Page
      t={t}
      openForm={openForm}
      checklists={checklists}
      search={filter.search}
      checklistId={checklistId}
      onChangePage={onChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      handleSearch={handleSearch}
      openDialog={openDialog}
      handleShow={handleShow}
      handleCloseShow={handleCloseShow}
      createChecklistPermit={permits.includes(CREATE_CHECK_LIST)}
      updateChecklistPermit={permits.includes(UPDATE_CHECK_LIST)}
      deleteChecklistPermit={permits.includes(DELETE_CHECK_LIST)}
      showChecklistPermit={permits.includes(SHOW_CHECK_LIST)}
    />
  );
}

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  checklists: state.Checklist.checklists,
  filter: state.Checklist.filter_checklist,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getChecklists: actionGetChecklists(dispatch),
  clearChecklists: actionClearChecklists(dispatch),
  deleteChecklist: actionDeleteChecklist(dispatch),
  setChecklistFilters: actionSetChecklistFilters(dispatch),
  clearChecklistFilters: actionClearChecklistFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeChecklist);
