import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();
  const { values, setNotification, getSubsidiaries } = rest;
  const [subsidiaries, setSubsidiaries] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getSubsidiaries(
          {
            companies: values.company_id,
            scope: "subsidiary_id,name,status",
          },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.company_id) {
      fetchData();
    }
  }, [values.company_id]);
  return <Page {...rest} t={t} subsidiaries={subsidiaries} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
