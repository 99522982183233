import {
  GET_WAREHOUSE_SUBSIDIARY,
  CLEAR_WAREHOUSE_SUBSIDIARY,
  ASSIGN_WAREHOUSE_TO_SUBSIDIARY,
  DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY,
} from "store/actions";

const initialState = {
  subsidiary: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WAREHOUSE_SUBSIDIARY:
    case ASSIGN_WAREHOUSE_TO_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };

    case CLEAR_WAREHOUSE_SUBSIDIARY:
      return {
        ...state,
        subsidiary: initialState.subsidiary,
      };

    case DELETE_LINK_WAREHOUSE_WITH_SUBSIDIARY:
      return {
        ...state,
        subsidiary:
          state.subsidiary && state.subsidiary.warehouse_id == action.payload
            ? initialState.subsidiary
            : state.subsidiary,
      };

    default:
      return state;
  }
}
