import React from "react";
import { connect } from "react-redux";

import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionClearCorporates } from "store/actions/corporate/clearCorporates";
import { actionSetCorporateFilters } from "store/actions/corporate/setCorporateFilters";
import { actionClearCorporateFilters } from "store/actions/corporate/clearCorporateFilters";
import { actionDeleteCorporate } from "store/actions/corporate/deleteCorporate";
import { actionSetStatusCorporate } from "store/actions/corporate/setStatusCorporate";
import { actionGetCorporate } from "store/actions/corporate/getCorporate";
import { actionAddCorporate } from "store/actions/corporate/addCorporate";
import { actionUpdateCorporate } from "store/actions/corporate/updateCorporate";

import { useLang } from "hooks/lang";

import Page from "./page";

export function HomeCorporate({ ...rest }) {
  const t = useLang();
  const {
    account,
    corporate,
    corporates,
    filter,
    deleteCorporate,
    setStatusCorporate,
    getCorporate,
    addCorporate,
    updateCorporate,
    getCorporates,
    clearCorporates,
    setCorporateFilters,
    clearCorporateFilters,
  } = rest;

  return (
    <Page
      corporates={corporates}
      corporate={corporate}
      filter={filter}
      isRoot={!!account && !!account.is_root}
      t={t}
      getCorporate={getCorporate}
      addCorporate={addCorporate}
      updateCorporate={updateCorporate}
      clearCorporateFilters={clearCorporateFilters}
      setCorporateFilters={setCorporateFilters}
      deleteCorporate={deleteCorporate}
      setStatusCorporate={setStatusCorporate}
      getCorporates={getCorporates}
      clearCorporates={clearCorporates}
    />
  );
}

HomeCorporate.propTypes = {};

const mapStateToProps = (state) => ({
  corporates: state.Corporate.Corporate.corporates,
  filter: state.Corporate.Corporate.filter_corporate,
  account: state.Account.account,
  corporate: state.Corporate.Corporate.corporate,
});
const mapDispatchToProps = (dispatch) => ({
  getCorporates: actionGetCorporates(dispatch),
  clearCorporates: actionClearCorporates(dispatch),
  setCorporateFilters: actionSetCorporateFilters(dispatch),
  clearCorporateFilters: actionClearCorporateFilters(dispatch),
  deleteCorporate: actionDeleteCorporate(dispatch),
  setStatusCorporate: actionSetStatusCorporate(dispatch),
  getCorporate: actionGetCorporate(dispatch),
  addCorporate: actionAddCorporate(dispatch),
  updateCorporate: actionUpdateCorporate(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCorporate);
