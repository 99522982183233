import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function VehicleAlertHistoryTab() {
  return <Page />;
}

VehicleAlertHistoryTab.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleAlertHistoryTab);
