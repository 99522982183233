export default {
  title: {
    subsidiary: "Subsidiary link with warehouse",
    warehouse: "Warehouse link with subsidiary",
  },
  subsidiary_name: "Subsidiary name",
  warehouse_name: "Warehouse name",
  buttons: {
    unlink_warehouse: "Unlink warehouse",
    view_warehouse: "View warehouse",
  },
};
