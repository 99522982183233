import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import ContainerTemplate from "components/templates/general/Container";
import VehiclesTable from "./VehiclesTable";
import FilterFleetSemaphoreForm from "components/organisms/report/FilterFleetSemaphoreForm";

import css from "./index.module.scss";
import SearchInput from "components/molecules/general/SearchInput";

function Page({
  t,
  corporates,
  data,
  filter,
  search,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateExcel,
  handleSearch,
}) {
  return (
    <ContainerTemplate
      title={t("_titles.fleet_semaphore_report")}
      options={
        <>
          <Button
            classNameButton={classnames("bw", css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search ? search : ""} onChange={handleSearch} />
        </>
      }
    >
      <div className={classnames(css.sidebar_font, "container-fluid")}>
        {Object.keys(filter).length > 0 && (
          <div className="row container-fluid pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.corporate.singular")}:
                      </td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.companies && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.company.singular")}:
                      </td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiaries && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.subsidiary.singular")}:
                      </td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.tire_application && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.application")}:
                      </td>
                      <td>{selectedFilter.application}</td>
                    </tr>
                  )}
                  {filter.tire_condition && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.condition")}:
                      </td>
                      <td>{selectedFilter.condition}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {"only_empty_vehicles" in filter && (
                    <tr>
                      <td className="text-right sbgazul">
                        {t("_labels.only_empty_vehicles")}:
                      </td>
                      <td>{selectedFilter.only_empty_vehicles}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <p className="m-0 p-0 h5 text-center">
              {t("_labels.semaphore_report.fleet_report_desc")}
            </p>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-md-12">
            <div className="container-fluid">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleGenerateExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>

        <div className="row pt-1">
          <div className="col-md-12">
            <div className="container-fluid">
              {corporates.map((corporate) => (
                <Fragment key={corporate}>
                  <VehiclesTable data={data} />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <FilterFleetSemaphoreForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  corporates: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateExcel: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default Page;
