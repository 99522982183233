import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";

import SelectField from "components/molecules/general/SelectField";
import DateTimeField from "components/molecules/general/DateTimeField";
import CheckboxField from "components/molecules/general/CheckboxField";

import css from "./index.module.scss";

function Page({
  t,
  tire,
  values,
  isValid,
  subsidiaries,
  rfids,
  brands,
  providers,
  warehouses,
  tireModels,
  tireModelVariations,
  revitalizedBrands,
  revitalizedModels,
  isSubmitting,
  isUpdate,
  onClose,
  handleSubmit,
  initialValues,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required={!values.device_code}
        variant="filled"
        margin="normal"
        name="code"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.code")}
      />

      <Field
        id="device_code"
        required={!values.code}
        name="device_code"
        autoHighlight
        disableClearable={initialValues.device_code !== null}
        blurOnSelect
        component={Autocomplete}
        options={rfids}
        getOptionLabel={(option) => (option ? option.device_code : "")}
        getOptionDisabled={(option) => !option.status}
        getOptionSelected={(option, value) => option.rfid_id === value.rfid_id}
        onChange={(_, value) => setFieldValue("device_code", value)}
        value={values.device_code}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.rfid_field.label")}
            variant="filled"
          />
        )}
      />

      {!isUpdate && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="subsidiary_id"
            className={classnames(css.field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            defaultText={t("_labels.subsidiary_field.singular.value")}
            defaultValue={
              subsidiaries.some(
                (subsidiary) =>
                  subsidiary.subsidiary_id === values.subsidiary_id
              )
                ? null
                : values.subsidiary_id
            }
            component={SelectField}
            label={t("_labels.subsidiary_field.singular.label")}
          >
            {subsidiaries.map((subsidiary, index) => {
              return (
                <MenuItem
                  key={index}
                  value={subsidiary.subsidiary_id.toString()}
                  disabled={!subsidiary.status}
                >
                  {subsidiary.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            required={!isUpdate}
            variant="filled"
            margin="normal"
            name="warehouse_id"
            className={classnames(css.field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            defaultText={t("_labels.warehouse.default")}
            defaultValue={
              warehouses.some(
                (warehouse) => warehouse.warehouse_id === values.warehouse_id
              )
                ? null
                : values.warehouse_id
            }
            component={SelectField}
            label={t("_labels.warehouse.singular")}
          >
            {warehouses.map((warehouse, index) => {
              return (
                <MenuItem
                  key={index}
                  value={warehouse.warehouse_id.toString()}
                  disabled={!warehouse.status}
                >
                  {warehouse.name}
                </MenuItem>
              );
            })}
          </Field>
        </>
      )}

      <Field
        required
        variant="filled"
        margin="normal"
        name="provider_id"
        className={classnames(css.field)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.provider_field.default")}
        defaultValue={
          providers.some(
            (provider) => provider.provider_id === values.provider_id
          )
            ? null
            : values.provider_id
        }
        component={SelectField}
        label={t("_labels.provider_field.label")}
      >
        {providers.map((provider, index) => {
          return (
            <MenuItem
              key={index}
              value={provider.provider_id.toString()}
              disabled={!provider.status}
            >
              {provider.name}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        id="brand_id"
        name="brand_id"
        autoHighlight
        disableClearable={initialValues.brand_id !== null}
        blurOnSelect
        component={Autocomplete}
        options={brands}
        getOptionLabel={(option) => (option ? option.name : "")}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) => {
          return value && option.brand_id === value.brand_id;
        }}
        onChange={(_, value) => setFieldValue("brand_id", value)}
        value={values.brand_id}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames("MuiFormControl-root", css.field)}
        disabled={isUpdate && tire.cycle.tire_condition_id.includes("RETREAD")}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.brand.singular")}
            variant="filled"
          />
        )}
      />

      <Field
        id="tire_model_id"
        name="tire_model_id"
        autoHighlight
        disableClearable={initialValues.tire_model_id !== null}
        blurOnSelect
        component={Autocomplete}
        options={tireModels}
        getOptionLabel={(option) => (option ? option.name : "")}
        getOptionDisabled={(option) => !option.status || !option.approved}
        getOptionSelected={(option, value) =>
          option.tire_model_id === value.tire_model_id
        }
        onChange={(_, value) => setFieldValue("tire_model_id", value)}
        value={values.tire_model_id}
        noOptionsText={t("_labels.autocomplete.no_options")}
        openText={t("_labels.autocomplete.open")}
        clearText={t("_labels.autocomplete.clear")}
        closeText={t("_labels.autocomplete.close")}
        className={classnames({
          "MuiFormControl-root": true,
          [css.medium_field]: !isUpdate,
          [css.field]: isUpdate,
        })}
        disabled={isUpdate && tire.cycle.tire_condition_id.includes("RETREAD")}
        renderInput={(params) => (
          <TextField2
            {...params}
            label={t("_labels.tire_model_field.label")}
            variant="filled"
          />
        )}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="tire_model_variation_id"
        disabled={isUpdate && tire.cycle.tire_condition_id.includes("RETREAD")}
        className={classnames({
          [css.medium_field]: !isUpdate,
          [css.field]: isUpdate,
        })}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        defaultText={t("_labels.tire_model_variation_field.default")}
        defaultValue={
          tireModelVariations.some(
            (tireModelVariation) =>
              tireModelVariation.tire_model_variation_id ===
              values.tire_model_variation_id
          )
            ? null
            : values.tire_model_variation_id
        }
        component={SelectField}
        label={t("_labels.tire_model_variation_field.label")}
      >
        {tireModelVariations.map((tireModelVariation, index) => {
          return (
            <MenuItem
              key={index}
              value={tireModelVariation.tire_model_variation_id.toString()}
              disabled={
                !tireModelVariation.status || !tireModelVariation.approved
              }
            >
              {`${tireModelVariation.tire_size.size} ${tireModelVariation.number_layers}`}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        required
        variant="filled"
        margin="normal"
        name="price"
        type="number"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.price")}
        disabled={isUpdate && tire.cycle.tire_condition_id.includes("RETREAD")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="end"
            >
              MXN
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
            step: "any",
          },
        }}
      />

      <Field
        variant="filled"
        margin="normal"
        name="expected_durability"
        type="number"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.expected_durability")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="end"
            >
              km
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
            step: "any",
          },
        }}
      />

      <Field
        variant="filled"
        margin="normal"
        name="dot"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.dot")}
      />

      <Field
        fullWidth
        type="date"
        name="invoice_date"
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        className={classnames({
          [css.field]: !isUpdate,
          [css.medium_field]: isUpdate,
        })}
        component={DateTimeField}
        label={t("_labels.invoice_date")}
      />

      <Field
        variant="filled"
        margin="normal"
        name="invoice_folio"
        component={TextField}
        className={classnames({
          [css.field]: !isUpdate,
          [css.medium_field]: isUpdate,
        })}
        label={t("_labels.invoice_folio")}
      />

      {!isUpdate && (
        <Field
          required
          variant="filled"
          margin="normal"
          name="similar_tires"
          type="number"
          component={TextField}
          className={classnames(css.field)}
          label={t("_labels.similar_tires")}
        />
      )}

      {isUpdate && (
        <>
          {tire.cycle.tire_condition_id.includes("RETREAD") && (
            <>
              <Field
                id="revitalized_brand_id"
                name="revitalized_brand_id"
                autoHighlight
                disableClearable={initialValues.revitalized_brand_id !== null}
                blurOnSelect
                component={Autocomplete}
                options={revitalizedBrands}
                getOptionLabel={(option) => (option ? option.name : "")}
                getOptionDisabled={(option) =>
                  !option.status || !option.approved
                }
                getOptionSelected={(option, value) => {
                  return value && option.brand_id === value.brand_id;
                }}
                onChange={(_, value) =>
                  setFieldValue("revitalized_brand_id", value)
                }
                value={values.revitalized_brand_id}
                noOptionsText={t("_labels.autocomplete.no_options")}
                openText={t("_labels.autocomplete.open")}
                clearText={t("_labels.autocomplete.clear")}
                closeText={t("_labels.autocomplete.close")}
                className={classnames("MuiFormControl-root", css.field)}
                renderInput={(params) => (
                  <TextField2
                    {...params}
                    label={t("_labels.revitalized_brand_field.label")}
                    variant="filled"
                  />
                )}
              />

              <Field
                id="revitalized_tire_model_id"
                name="revitalized_tire_model_id"
                autoHighlight
                disableClearable={
                  initialValues.revitalized_tire_model_id !== null
                }
                blurOnSelect
                component={Autocomplete}
                options={revitalizedModels}
                getOptionLabel={(option) => (option ? option.name : "")}
                getOptionDisabled={(option) =>
                  !option.status || !option.approved
                }
                getOptionSelected={(option, value) =>
                  option.revitalized_tire_model_id ===
                  value.revitalized_tire_model_id
                }
                onChange={(_, value) =>
                  setFieldValue("revitalized_tire_model_id", value)
                }
                value={values.revitalized_tire_model_id}
                noOptionsText={t("_labels.autocomplete.no_options")}
                openText={t("_labels.autocomplete.open")}
                clearText={t("_labels.autocomplete.clear")}
                closeText={t("_labels.autocomplete.close")}
                className={classnames({
                  "MuiFormControl-root": true,
                  [css.medium_field]: !isUpdate,
                  [css.field]: isUpdate,
                })}
                renderInput={(params) => (
                  <TextField2
                    {...params}
                    label={t("_labels.revitalized_tire_model_field.label")}
                    variant="filled"
                  />
                )}
              />
              <Field
                variant="filled"
                margin="normal"
                name="price_revitalized"
                type="number"
                component={TextField}
                className={classnames(css.field)}
                label={t("_labels.retread_price")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      className={classnames(css.adornments)}
                      position="end"
                    >
                      MXN
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: "any",
                  },
                }}
              />
            </>
          )}
          <Field
            variant="filled"
            margin="normal"
            name="is_refection"
            component={CheckboxField}
            className={classnames(css.medium_field)}
            label={t("_labels.vehicle_type_axle_position.options.refection")}
          />
          <Field
            variant="filled"
            margin="normal"
            name="cap_and_casing"
            component={CheckboxField}
            className={classnames(css.medium_field)}
            label={t("_labels.cap_and_casing")}
          />
        </>
      )}

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {!isUpdate
            ? values.similar_tires > 1
              ? t("_buttons.continue")
              : t("_buttons.save")
            : t("_buttons.update")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tire: PropTypes.object,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  rfids: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  warehouses: PropTypes.array.isRequired,
  tireModels: PropTypes.array.isRequired,
  tireModelVariations: PropTypes.array.isRequired,
  revitalizedBrands: PropTypes.array.isRequired,
  revitalizedModels: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Page;
