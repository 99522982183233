export default {
  title: "Nueva tolerancia de profundidad",
  update: "Actualizar tolerancia de profundidad",
  messages: {
    depth_tolerance_policy_created:
      "Listo, se creo una nueva tolerancia de profundidad",
    depth_tolerance_policy_updated:
      "Listo, se actualizó la tolerancia de profundidad con éxito",
    depth_tolerance_policy_deleted:
      "Listo, se eliminó la tolerancia de profundidad con éxito",
    delete: "¿Estas seguro de eliminar la política de tolerancia?",
  },
  form: {
    permits: {
      update: "Sin permisos para actualizar tolerancias de profundidad",
      delete: "Sin permisos para eliminar tolerancias de profundidad",
    },
  },
};
