import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAssociations } from "store/actions/association/getAssociations";
import { actionAddCommissionedVehicle } from "store/actions/commissioned/vehicle/addCommissionedVehicle";
import { actionGetCommissionedVehicle } from "store/actions/commissioned/vehicle/getCommissionedVehicle";
import { actionClearCommissionedVehicle } from "store/actions/commissioned/vehicle/clearCommissionedVehicle";
import { actionUpdateCommissionedVehicle } from "store/actions/commissioned/vehicle/updateCommissionedVehicle";
import { actionClearCommissionedVehicleFilters } from "store/actions/commissioned/vehicle/clearCommissionedVehicleFilters";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function CommissionedVehicleForm({
  open,
  onClose,
  commissionedVehicleId,
  ...rest
}) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(
    commissionedVehicleId ? true : false
  );
  const {
    vehicle,
    setNotification,
    getAssociations,
    getVehicles,
    getVehicleTypes,
    addCommissionedVehicle,
    getCommissionedVehicle,
    clearCommissionedVehicle,
    updateCommissionedVehicle,
    clearCommissionedVehicleFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      economic_number: "",
      enrollment: "",
      vehicle_type_id: "",
      vehicle_id: null,
      association_id: "",
      has_odometer: false,
    },
  });
  const [associations, setAssociations] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);

        const data = await getAssociations(
          {
            scope: "association_id,name,status",
          },
          false
        );

        setAssociations(data.sort(alphabeticalSort("name")));

        const dataVehicles = await getVehicles(
          {
            status: 1,
            scope:
              "vehicle_id,economic_number,enrollment,vehicle_type_id,has_odometer",
          },
          false
        );

        setVehicles(dataVehicles.sort(alphabeticalSort("economic_number")));

        const dataVehicleTypes = await getVehicleTypes(
          {
            status: 1,
            scope: "vehicle_type_id,name",
          },
          false
        );

        setVehicleTypes(dataVehicleTypes.sort(alphabeticalSort("name")));
        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
      clearCommissionedVehicle();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getCommissionedVehicle(commissionedVehicleId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && commissionedVehicleId) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (loaded && commissionedVehicleId && vehicle.commissioned_vehicle_id) {
      setInitialValues({
        economic_number: vehicle.economic_number,
        enrollment: vehicle.enrollment ?? "",
        vehicle_id: vehicle.vehicle_id
          ? vehicles.find((item) => item.vehicle_id == vehicle.vehicle_id)
          : null,
        vehicle_type_id: vehicle.vehicle_type_id.toString(),
        association_id: vehicle.association_id.toString(),
        has_odometer: Boolean(vehicle.has_odometer),
      });
    }
  }, [vehicle, loaded]);

  useEffect(() => {
    setIsUpdate(commissionedVehicleId ? true : false);
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        association_id: values.association_id,
        vehicle_type_id: values.vehicle_type_id,
        economic_number: values.economic_number,
        has_odometer: values.has_odometer,
      };

      if (values.vehicle_id) {
        fields.vehicle_id = values.vehicle_id.vehicle_id;
      }

      if (values.enrollment) {
        fields.enrollment = values.enrollment;
      }

      if (isUpdate) {
        await updateCommissionedVehicle(fields, commissionedVehicleId);
        setNotification({
          message: t("_messages.updated.vehicle"),
        });
      } else {
        await addCommissionedVehicle(fields);
        setNotification({
          message: t("_messages.created.vehicle"),
        });
      }
      setSubmitting(false);
      resetForm();
      clearCommissionedVehicleFilters();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      associations={associations}
      vehicles={vehicles}
      vehicleTypes={vehicleTypes}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

CommissionedVehicleForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  commissionedVehicleId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

const mapStateToProps = (state) => ({
  vehicle: state.Commissioned.Vehicle.vehicle,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  getVehicleTypes: actionGetVehiclesTypes(dispatch),
  getAssociations: actionGetAssociations(dispatch),
  addCommissionedVehicle: actionAddCommissionedVehicle(dispatch),
  getCommissionedVehicle: actionGetCommissionedVehicle(dispatch),
  clearCommissionedVehicle: actionClearCommissionedVehicle(dispatch),
  updateCommissionedVehicle: actionUpdateCommissionedVehicle(dispatch),
  clearCommissionedVehicleFilters:
    actionClearCommissionedVehicleFilters(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionedVehicleForm);
