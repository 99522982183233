// import { DELETE_BRAND } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteChecklist = (dispatch) => async (checklistId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `checklist/${checklistId}`,
    });
    dispatch({
      type: "DELETE_CHECKLIST",
      payload: checklistId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
