import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import TireForm from "components/organisms/tire/TireForm";
import TireTabs from "components/organisms/tire/TireTabs";
import InfoTireDialog from "components/organisms/tire/InfoTireDialog";
import InfoProviderDialog from "components/organisms/provider/InfoProviderDialog";
import InfoTireModelDialog from "components/organisms/tire/model/InfoTireModelDialog";
import LinkTireSubsidiaryForm from "components/organisms/tire/subsidiary/LinkTireSubsidiaryForm";
import LinkTireWarehouseForm from "components/organisms/tire/warehouse/LinkTireWarehouseForm";
import InfoTireModelVariationDialog from "components/organisms/tire/model/variation/InfoTireModelVariationDialog";
import LinkTireRfidForm from "components/organisms/tire/rfid/LinkTireRfidForm";

import css from "./index.module.scss";
import LinkTireDivisionForm from "components/organisms/tire/division/LinkTireDivisionForm";

function Page({
  id,
  tire,
  providerId,
  tireModelId,
  tireFormOpen,
  modelInfoOpen,
  providerInfoOpen,
  variationInfoOpen,
  tireModelVariationId,
  handleOpenTireForm,
  handleCloseTireForm,
  handleCloseModelInfo,
  handleCloseProviderInfo,
  handleCloseVariationInfo,
}) {
  return (
    <TireTabs className={classnames(css.container)}>
      <div className={classnames(css.links)}>
        <InfoTireDialog tire={tire} onOpen={handleOpenTireForm} />
      </div>

      <div className={classnames(css.links)}>
        <LinkTireDivisionForm
          tireId={id}
          className={classnames(css.links__link)}
        />
        <LinkTireSubsidiaryForm
          tireId={id}
          className={classnames(css.links__link)}
        />
        <LinkTireRfidForm tireId={id} className={classnames(css.links__link)} />
        <LinkTireWarehouseForm
          tireId={id}
          className={classnames(css.links__link)}
        />
      </div>

      <TireForm tireId={id} open={tireFormOpen} onClose={handleCloseTireForm} />

      <InfoProviderDialog
        summary={true}
        open={providerInfoOpen}
        providerId={providerId}
        onClose={handleCloseProviderInfo}
      />
      <InfoTireModelDialog
        open={modelInfoOpen}
        modelId={tireModelId}
        onClose={handleCloseModelInfo}
      />
      <InfoTireModelVariationDialog
        open={variationInfoOpen}
        tireModelId={tireModelId}
        tireModelVariationId={tireModelVariationId}
        handleOpen={() => {}}
        onClose={handleCloseVariationInfo}
      />
    </TireTabs>
  );
}

Page.propTypes = {
  cycle: PropTypes.object.isRequired,
  providerId: PropTypes.number,
  tireCycleId: PropTypes.number,
  tireModelId: PropTypes.number,
  tire: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  tireModelVariationId: PropTypes.number,
  handleCloseCycleForm: PropTypes.func.isRequired,
  openCycleForm: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tireFormOpen: PropTypes.bool.isRequired,
  modelInfoOpen: PropTypes.bool.isRequired,
  cycleInfoOpen: PropTypes.bool.isRequired,
  providerInfoOpen: PropTypes.bool.isRequired,
  variationInfoOpen: PropTypes.bool.isRequired,
  handleOpenTireForm: PropTypes.func.isRequired,
  handleCloseTireForm: PropTypes.func.isRequired,
  handleOpenCycleInfo: PropTypes.func.isRequired,
  handleOpenModelInfo: PropTypes.func.isRequired,
  handleCloseCycleInfo: PropTypes.func.isRequired,
  handleCloseModelInfo: PropTypes.func.isRequired,
  handleOpenProviderInfo: PropTypes.func.isRequired,
  handleCloseProviderInfo: PropTypes.func.isRequired,
  handleOpenTireCycleForm: PropTypes.func.isRequired,
  handleOpenVariationInfo: PropTypes.func.isRequired,
  handleCloseVariationInfo: PropTypes.func.isRequired,
  handleOpenCycleHistoryInfo: PropTypes.func.isRequired,
};

export default Page;
