import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import Fade from "@material-ui/core/Fade";
import Link from "@material-ui/core/Link";
import BootstrapTooltip from "components/molecules/general/BootstrapTooltip";

export default function Table({
  t,
  tires,
  handleSort,
  sortBy,
  sortOrder,
  formatter,
}) {
  const columns = [
    { key: "code", label: t("_labels.code") },
    { key: "model", label: t("_labels.model") },
    { key: "real_position", label: t("_labels.tires_mount_report.position") },
    { key: "current_cost", label: t("_labels.cost") },
    { key: "date", label: t("_labels.mount_date") },
  ];

  return (
    <div className="w-100 table-responsive" style={{ maxHeight: "70vh" }}>
      <Fade in timeout={600}>
        <table className="table table-hover sFS14 text-center m-0">
          <thead className="thead-dark" style={{ position: "sticky", top: -1 }}>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  <BootstrapTooltip
                    title={`Ordenar por ${column.label}`}
                    placement="top"
                  >
                    <span
                      onClick={() => handleSort(column.key)}
                      style={{ cursor: "pointer" }}
                    >
                      {column.label}
                      {sortBy === column.key &&
                        (sortOrder === "asc" ? " 🔼" : " 🔽")}
                    </span>
                  </BootstrapTooltip>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tires.map((tire, index) => (
              <tr key={`${tire.code}_${index}`}>
                <td className="align-middle">
                  <Link href={`/tire/${tire.tire_id}/history`} target="_blank">
                    <button className="btn btn-sm btn-info w-auto">
                      {tire.code}
                    </button>
                  </Link>
                </td>
                <td className="align-middle">{tire.model}</td>
                <td className="align-middle">{tire.real_position}</td>
                <td className="align-middle">
                  {formatter.format(tire.current_cost)}
                </td>
                <td className="align-middle">
                  {moment(tire.date).format("LL hh:mm:ss")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fade>
    </div>
  );
}

Table.propTypes = {
  tires: PropTypes.array,
  t: PropTypes.func,
  handleSort: PropTypes.func,
  sortOrder: PropTypes.string,
  sortBy: PropTypes.string,
  formatter: PropTypes.object,
};
