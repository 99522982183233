import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function TireRevitalizationHistoryTab() {
  return <Page />;
}

TireRevitalizationHistoryTab.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TireRevitalizationHistoryTab);
