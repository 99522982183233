export default {
  title: "Vehicles types",
  no_vehicle_type: "No Vehicles types",
  permits: {
    create: "No permissions to create vehicle types",
  },
  text: {
    with: "with",
    axles: {
      singular: "axle",
      plural: "axles",
    },
  },
};
