export default {
  link_subsidiary: "Link subsidiary",
  label: "Subsidiary name",
  permits: {
    link: {
      warehouse_subsidiary:
        "No permissions to link warehouse with subsidiaries",
    },
    unlink: {
      warehouse_subsidiary: "No permissions to unlink warehouse subsidiaries",
    },
    show: {
      warehouse_subsidiary: "No permissions to view warehouse subsidiaries",
    },
  },
  messages: {
    unlinked_warehouse_subsidiary:
      "Done, the subsidiary has been unlinked from the warehouse",
    linked_warehouse_subsidiary:
      "Done, the subsidiary has been linked to the warehouse",
  },
};
