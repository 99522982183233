import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPotentialSavingsReport } from "store/actions/reports/potential_savings/getPotentialSavingsReport";
import { actionClearPotentialSavingsFilters } from "store/actions/reports/potential_savings/clearPotentialSavingsFilters";
import { actionSetPotentialSavingsFilters } from "store/actions/reports/potential_savings/setPotentialSavingsFilters";
import { actionSetSelectedPotentialSavingsFilters } from "store/actions/reports/potential_savings/setSelectedPotentialSavingsFilters";
import { actionGeneratePotentialSavingsExcel } from "store/actions/reports/potential_savings/generatePotentialSavingsExcel";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import { useLang } from "hooks/lang";

import Page from "./page";

function PotentialSavings({ ...rest }) {
  const t = useLang();
  const query = useLocation();
  const {
    account,
    workArea,
    filter,
    selectedFilter,
    setNotification,
    getPotentialSavingsReport,
    clearPotentialSavingsFilters,
    setPotentialSavingsFilters,
    setSelectedPotentialSavingsFilters,
    generatePotentialSavingsExcel,
  } = rest;

  const [data, setData] = useState({});
  const [selectedKey, setSelectedKey] = useState("summary");
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getPotentialSavingsReport("summary", {
          ...filter,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);

    const lastMonth = moment().subtract(1, "months");
    const startDate = lastMonth.startOf("month").format("YYYY-MM-DD");
    const endDate = lastMonth.endOf("month").format("YYYY-MM-DD");

    const month = lastMonth.startOf("month").format("YYYY-MM");
    const filters = {
      date_from: startDate,
      date_to: endDate,
      month,
      corporate_id: corporate.corporate_id.toString(),
      company_id: companies.map((company) => company.company_id).join(),
      subsidiary_id: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      date_from: startDate,
      date_to: endDate,
      month,
    };

    const params = new URLSearchParams(query.search);
    if (params.has("date")) {
      const date = moment(params.get("date"));
      const startDate = date.startOf("month").format("YYYY-MM-DD");
      const endDate = date.endOf("month").format("YYYY-MM-DD");

      filters.date_from = startDate;
      filters.date_to = endDate;
      selectedFilters.date_from = startDate;
      selectedFilters.date_to = endDate;
      selectedFilters.month = date.endOf("month").format("YYYY-MM");

      if (params.has("subsidiary")) {
        const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
        const subsidiary = getSubsidiaries(account.subsidiaries)
          .filter(
            (subsidiary) =>
              subsidiary.company_id === subsidiaries[0].subsidiary.company_id
          )
          .find((subsidiary) => subsidiary.name === params.get("subsidiary"));
        if (subsidiary) {
          filters.subsidiary_id = subsidiary.subsidiary_id.toString();
          selectedFilters.subsidiaries = subsidiary.name;
        }
      } else {
        delete filters.subsidiary_id;
        delete selectedFilters.subsidiaries;
      }
    }

    setPotentialSavingsFilters(filters);
    setSelectedPotentialSavingsFilters(selectedFilters);

    return () => {
      clearPotentialSavingsFilters();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedKey]);

  function selectKey(key) {
    setSelectedKey(key);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generatePotentialSavingsExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      data={data}
      vehiclesChart={getVehiclesChart(data)}
      tiresChart={getTiresChart(data, t)}
      tiresDepthData={getTiresDepthData(data)}
      tiresDepthChartMount={getTiresDepthChart(data, t, "mount")}
      tiresDepthChartWarehouse={getTiresDepthChart(data, t, "warehouse")}
      tiresPressureData={getTiresPressureData(data)}
      selectedKey={selectedKey}
      selectKey={selectKey}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
    />
  );
}
PotentialSavings.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.PotentialSavings.filter,
  selectedFilter: state.Reports.PotentialSavings.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getPotentialSavingsReport: actionGetPotentialSavingsReport(dispatch),
  clearPotentialSavingsFilters: actionClearPotentialSavingsFilters(dispatch),
  setPotentialSavingsFilters: actionSetPotentialSavingsFilters(dispatch),
  setSelectedPotentialSavingsFilters:
    actionSetSelectedPotentialSavingsFilters(dispatch),
  generatePotentialSavingsExcel: actionGeneratePotentialSavingsExcel(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PotentialSavings);

function getVehiclesChart(data) {
  const chart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [vehicleType, value] of Object.entries(
    data?.vehicle_type_name ?? {}
  )) {
    if (typeof value === "object" && value !== null) {
      chart.labels.push(vehicleType);
      chart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      chart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      chart.datasets[0].borderColor.push(`rgba(${red}, ${green}, ${blue}, 1)`);
    }
  }
  return chart;
}

function getTiresChart(data, t) {
  const chart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [tireApplication, value] of Object.entries(
    data?.tire_application_id ?? {}
  )) {
    if (typeof value === "object" && value !== null) {
      chart.labels.push(
        t(`_labels.tire_application.options.${tireApplication.toLowerCase()}`)
      );
      chart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      chart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      chart.datasets[0].borderColor.push(`rgba(${red}, ${green}, ${blue}, 1)`);
    }
  }
  const locationChart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [location, value] of Object.entries(data?.movement ?? {})) {
    if (typeof value === "object" && value !== null) {
      locationChart.labels.push(
        t(
          `_labels.location.options.${location
            .replaceAll('"', "")
            .toLowerCase()}`
        )[0] +
          t(
            `_labels.location.options.${location
              .replaceAll('"', "")
              .toLowerCase()}`
          )
            .slice(1)
            .toLowerCase()
      );
      locationChart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      locationChart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      locationChart.datasets[0].borderColor.push(
        `rgba(${red}, ${green}, ${blue}, 1)`
      );
    }
  }

  const brandChart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [brand, value] of Object.entries(data?.brand_name ?? {})) {
    if (typeof value === "object" && value !== null) {
      brandChart.labels.push(brand);
      brandChart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      brandChart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      brandChart.datasets[0].borderColor.push(
        `rgba(${red}, ${green}, ${blue}, 1)`
      );
    }
  }

  const modelChart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [model, value] of Object.entries(data?.tire_model ?? {})) {
    if (typeof value === "object" && value !== null) {
      modelChart.labels.push(model);
      modelChart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      modelChart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      modelChart.datasets[0].borderColor.push(
        `rgba(${red}, ${green}, ${blue}, 1)`
      );
    }
  }

  const sizeChart = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
    labels: [],
  };
  for (const [size, value] of Object.entries(data?.tire_size ?? {})) {
    if (typeof value === "object" && value !== null) {
      sizeChart.labels.push(size);
      sizeChart.datasets[0].data.push(value["fleet_elements"]);
      const [red, green, blue] = getRandomColor();
      sizeChart.datasets[0].backgroundColor.push(
        `rgba(${red}, ${green}, ${blue}, 0.55)`
      );
      sizeChart.datasets[0].borderColor.push(
        `rgba(${red}, ${green}, ${blue}, 1)`
      );
    }
  }

  return { chart, locationChart, brandChart, modelChart, sizeChart };
}

function getTiresDepthChart(data, t, type) {
  const depthChart = {
    labels: [],
    datasets: [
      {
        label: t("_labels.pile_stats_report.number_of_tires"),
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };
  if (data && Object.keys(data).length > 0 && data?.[`depth_ranking_${type}`]) {
    const likeArray = Object.entries(data[`depth_ranking_${type}`]).filter(
      (e) => typeof e[1] === "object" && e[1] != undefined
    );
    likeArray.sort((a, b) => a[0].split(" - ")[1] - b[0].split(" - ")[1]);
    const labels = likeArray.map((data) => data[0]);
    const values = likeArray.map((data) => data[1][`${type}_elements`]);
    const bgColors = [];
    const bColors = [];
    labels.forEach((label) => {
      if (label.split(" - ")[1] <= 4) {
        bgColors.push("rgba(255, 40, 40, 0.5)");
        bColors.push("rgba(255, 40 , 40, 1)");
      } else {
        bgColors.push("rgba(124, 181, 236, 0.6)");
        bColors.push("rgba(124, 181, 236, 1)");
      }
    });
    depthChart.labels = labels;
    depthChart.datasets[0].data = values;
    depthChart.datasets[0].backgroundColor = bgColors;
    depthChart.datasets[0].borderColor = bColors;
  }
  return depthChart;
}

function getTiresDepthData(data) {
  return data;
  if (
    data &&
    Object.keys(data).length > 0 &&
    Object.keys(data.tires_depth).length > 0
  ) {
    const result = Object.keys(data.tires_depth).reduce((acc, val) => {
      const label = val.includes("GOOD")
        ? "Normal"
        : val.includes("SCHEDULED")
        ? "Advertencia"
        : "Punto de retiro";
      acc[label] = data.tires_depth[val];
      return acc;
    }, {});

    return result;
  }

  return {};
}

function getTiresPressureData(data) {
  return data?.pressure_condition ?? {};
  // if (
  //   data &&
  //   Object.keys(data).length > 0 &&
  //   Object.keys(data.pressure_condition).length > 0
  // ) {
  //   const newData = JSON.parse(JSON.stringify(data.pressure_condition));
  //   for (const [condition, value] of Object.entries(newData)) {
  //     if (typeof value === "object" && value !== null) {
  //       if (["EXCESSIVELY HIGH"].includes(condition)) {
  //         newData["VERY HIGH"].fleet_elements += value.fleet_elements;
  //         newData["VERY HIGH"].fleet_percent += value.fleet_percent;
  //       } else if (
  //         ["EXAGGERATELY LOW", "EXCESSIVELY LOW"].includes(condition)
  //       ) {
  //         newData["VERY LOW"].fleet_elements += value.fleet_elements;
  //         newData["VERY LOW"].fleet_percent += value.fleet_percent;
  //       }
  //     }
  //   }
  //   delete newData["EXCESSIVELY HIGH"];
  //   delete newData["EXAGGERATELY LOW"];
  //   delete newData["EXCESSIVELY LOW"];
  //   return newData;
  // }
  // return {};
}

function getRandomColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  return [red, green, blue];
}

function getSubsidiaries(assignedSubsidiaries) {
  return assignedSubsidiaries.map(
    (assignedSubsidiary) => assignedSubsidiary.subsidiary
  );
}
