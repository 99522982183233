export default {
  colloquial_name: {
    incorrect_application: "Incorrect Application",
    incorrect_directional_condition: "Incorrect Condition (Directional Axis)",
    critical_removal: "Critical Removal",
    mm_removal_90_days: "Removal per mm (90 days)",
    condition_mismatch_ro: "Mismatched Condition (R/O)",
    condition_or_not_allowed_on_axis: "Condition (O/R) not allowed on axis",
    mismatch_ro: "Mismatched Condition (R/O)",
    mismatch_mm_dual: "Mismatched per mm (Dual)",
    mismatch_model_dual: "Mismatched Model (Dual)",
    mismatch_measure_dual: "Mismatched per Measure (Dual)",
    scheduled_removal: "Scheduled Removal",
    different_assignment: "Different Assignment",
    mm_direction_alert: "Directional mm Alert",
    wear_decrement_alert: "Wear Decrement Alert",
    wear_increment_alert: "Wear Increment Alert",
    review_required: "Review Required",
    mm_removal_30_days: "Removal per mm (30 days)",
    mismatch_pressure: "Mismatched Pressure",
    mismatch_depth: "Mismatched Depth",
    mismatch_pressure_two: "Mismatched Pressure",
    incorrect_pressure: "Incorrect Pressure",
    rotation_required_directional_axis: "Rotation Required on Directional Axis",
    rotation_required_traction_axis: "Rotation Required on Traction/Free Axis",
    patches_limit: "Patches Limit",
    irregular_wear: "Irregular Wear",
    partial_review_instead_complete: "Partial review",
    manual_review: "Sensorless inspection",
    incorrect_temperature: "Incorrect temperature",
    tpms_not_link: "The TPMS is not linked to any tire",
    sensor_not_found: "Sensor not found",
  },

  suggestion: {
    install_appropriate_tire_model_on_axis:
      "Install a tire model appropriate for the application of the axis where it is being installed.",
    only_original_tires_allowed_on_this_axis:
      "Only original tires are allowed on this axis.",
    immediate_tire_removal_required: "Immediate tire removal required",
    change_tire_within_90_days: "Change the tire within 90 days.",
    install_tire_that_meets_condition_policy_on_axis:
      "Install a tire that meets the condition policy for the axis established.",
    install_same_condition_tires_on_dual_axis:
      "Install tires with the same condition (original/renewed) on the dual axis.",
    change_tire_if_mm_difference_exceeds_policy:
      "Change tire for one that does not have a difference greater than the mm defined in the policies.",
    install_same_model_tires_on_dual_axis:
      "Install tires with the same model on the dual axis.",
    install_tire_with_same_size_or_dual_axis_size:
      "Install a tire that is the same size as the other tire on the axis or that corresponds to the dual axis size.",
    monitor_wear_within_30_days: "Monitor wear within the next 30 days",
    validate_tire_location_registered_in_system:
      "Validate the location of the tire that was registered in the system.",
    change_tire_or_pair_with_higher_mm_within_scheduled_removal_policy:
      "Change tire or both for a pair that has higher mm than the one established in the scheduled removal policy.",
    physically_inspect_tire_to_validate_depth:
      "Physically inspect the tire to validate its depth.",
    immediate_vehicle_inspection_required:
      "Immediate vehicle inspection required.",
    change_tire_within_30_days: "Change the tire within 30 days.",
    correct_dual_axis_pressure: "Correct pressure on the dual axis.",
    change_tire_for_lower_mm_or_entire_dual_axis:
      "Change tire for one with lower mm or change the entire dual axis.",
    correct_tire_pressure: "Correct tire pressure.",
    perform_rotation_on_directional_axes:
      "Perform rotation on directional axes.",
    perform_rotation_on_non_directional_axes:
      "Perform rotation on non-directional axes.",
    change_tire_to_spare_position_or_remove_from_operation:
      "Change the tire to a spare position or remove it from operation.",
    physically_inspect_tire_to_validate_correct_wear:
      "Physically inspect the tire to validate that the wear is correct.",
    check_temperature_physically: " ",
    check_vehicle_tpms:
      "Review the vehicle's TPMS and compare them with the data on the web platform.",
    check_vehicle_physically:
      "Check the vehicle physically to determine why the TPMS is not sending data.",
  },

  details: {
    wrong_pairing_pressure_axle: "Wrong pairing to pressure",
    wrong_pairing_depth_axle: "Wrong pairing to depth",
    wrong_pairing_pressure_axle_dual: "Wrong pairing to pressure in axle dual",
    wrong_pairing_difference_model_axle_dual:
      "Wrong pairing to model difference in axle dual",
    wrong_pairing_difference_condition_axle_dual:
      "Wrong pairing due to difference in condition dual (retread and original)",
    wrong_pairing_difference_measurement_axle_dual:
      "Wrong pairing due to measurement difference in axle dual",
    wrong_pairing_difference_condition_axle:
      "Wrong pairing due to in difference condition in axles",
    wrong_depth_steer_axle: "Wrong depth in steer axle, tread less than 5 mm",
    life_projection_one_month:
      "Remaining life projection of no more than one month",
    life_projection_three_month:
      "Remaining life projection of no more than three months",
    maximum_patches_tire: "Maximum number of patches on tire",
    scheduled_tire_retirement: "scheduled retirement situation tire",
    critical_tire_retirement: "critical retirement situation tire",
    steer_axle_original_tire:
      "The steer axle position only accepts original tires",
    wrong_pairing_depth_dual: "Wrong pairing to depth in dual",
    application_tire_no_correspond:
      "The tire application does not correspond to the axle on which it is installed",
    difference_depth_upper:
      "Alert for difference in depth of drawing between the last and the penultimate revision (Upper wear)",
    difference_depth_down:
      "Alert for difference in depth of drawing between the last and the penultimate revision (Down wear)",
    pressure_out_range: "Tire pressure out of recommended range",
    policy_not_allow_tire_condition_axle:
      "The tire condition is not allowed on axle according to policy",
    uneven_wear: "The tire has shown irregular wear.",
    physical_difference:
      "The tire mounted on the positions physically does not correspond to the system records.",
    lack_review_vehicle: "Lack of inspection for the vehicle.",
    steer_rotation: "Steer axle tire rotation.",
    non_steer_rotation: "Non-steer axle tire rotation.",
    partial_review:
      "The inspection was completed without inspecting all the tires",
    unused_sensor: "The tire inspection was carried out without the sensor",
    temperature: "Tire temperature is outside optimal ranges",
    device_not_linked: "The TPMS is not linked to any tire",
    device_lost: "Unable to get information from TPMS",
  },

  cause: {
    tire_life_short:
      "The average tire wear indicates that it has no more than one month of remaining service life.",
    tire_life_medium:
      "The average tire wear indicates that it has no more than three months of remaining service life.",
    critical_retirement:
      "The tire ${0} is in a critical retirement situation (immediate).",
    incorrect_tire_application:
      "The tire application does not correspond to the axle it is installed on. The axle applications are: ${0}, and the tire application is: ${1}.",
    unusual_wear_more:
      "The tire shows an unusual wear difference between the last and penultimate inspection. The tire ${0} has experienced more wear.",
    unusual_wear_less:
      "The tire shows an unusual wear difference between the last and penultimate inspection. The tire ${0} has experienced less wear.",
    measurement_difference:
      "The vehicle has an alert due to the difference in measurements on axle ${0}/${1} at positions ${2}-${3}.",
    model_difference:
      "The vehicle has an alert due to the difference in models on axle ${0} at positions ${1}-${2}.",
    condition_difference:
      "The vehicle has an alert due to the difference in condition on axle ${0}/${1}.",
    condition_difference_at_position:
      "The vehicle has an alert due to the difference in condition on axle ${0}/${1} at positions ${2}-${3}",
    pressure_out_of_range:
      "The tire pressure is outside the recommended range.",
    original_tire_only:
      "The position only accepts original tires as it is a steering axle.",
    irregular_wear_job:
      "The tire is showing irregular wear in its tread depth readings.",
    low_tread_depth_directional:
      "The directional tire has a tread depth less than 5 mm.",
    depth_difference:
      "The vehicle has an alert due to the difference in depth on axle ${0}/${1}",
    depth_difference_wrong_paring:
      "The vehicle has an alert due to the difference in depth on axle ${0}/${1} at positions ${2}-${3}",
    pressure_difference:
      "The vehicle has an alert due to the difference in pressures on axle ${0}/${1}.",
    pressure_difference_at_position:
      "The vehicle has an alert due to the difference in pressures on axle ${0} at positions ${1}-${2}.",
    scheduled_remove_tire: "The tire ${0} has been scheduled for removal.",
    wrong_rfid_sensor: "The sensor rfid was not used to carry out the review.",
    vehicle_review_delayed: "The vehicle ${0} has ${1} days without a review.",
    vehicle_review_delayed_km: "The vehicle ${0} has ${1} km without a review.",
    vehicle_review_partial: "The vehicle ${0} has a partial review.",
    different_assignment_axle:
      "The vehicle has a different assignment on axle ${0} at position ${1}",
  },

  dynamic: {
    all_position: "All position",
    directional: "Directional",
    traction: "Traction",
    trailer: "Trailer",
    central: "Central",
    rear: "Rear",
    frontal: "Frontal",
    refection: "Refection",
    maintenance: "Maintenance",
  },
};
