import React from "react";
import PropTypes from "prop-types";
import Home from "pages/general/Home";

function Page({
  t,
  filter,
  retirementCause,
  retirementCauses,
  createRetirementCausePermit,
  updateRetirementCausePermit,
  deleteRetirementCausePermit,
  getRetirementCause,
  getRetirementCauses,
  addRetirementCause,
  updateRetirementCause,
  deleteRetirementCause,
  clearRetirementCauseFilters,
  setRetirementCauseFilters,
  setRetirementCauseStatus,
  clearRetirementCauses,
}) {
  return (
    <Home
      item={retirementCause}
      name="retirement_cause"
      filter={filter}
      createTooltipPermit={
        !createRetirementCausePermit
          ? t("_permits.create.retirement_cause")
          : ""
      }
      currentPage={retirementCauses.current_page}
      deleteConfirmationMessage={t("_messages.delete.retirement_cause")}
      disableConfirmationMessage={t(
        "_messages.change_status.retirement_cause.disable"
      )}
      enableConfirmationMessage={t(
        "_messages.change_status.retirement_cause.enable"
      )}
      rows={retirementCauses.data.map((retirementCause) => {
        return {
          id: retirementCause.retirement_cause_id,
          key: retirementCause.retirement_Cause_id,
          status: {
            status: retirementCause.status,
            tooltipPermit: !updateRetirementCausePermit
              ? t("_permits.update.retirement_cause")
              : "",
          },

          name: t(`_retirement.name.${retirementCause.name}`),
          description: t(
            `_retirement.description.${retirementCause.description}`
          ),

          edit: {
            tooltipPermit: !updateRetirementCausePermit
              ? t("_permits.update.retirement_cause")
              : "",
          },
          delete: {
            tooltipPermit: !deleteRetirementCausePermit
              ? t("_permits.delete.retirement_cause")
              : "",
          },
        };
      })}
      title={t("_general.retirement_causes")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "description", label: t("_labels.description") },
        { id: "actions", label: "" },
      ]}
      lastPage={retirementCauses.last_page}
      noItemsText={t("_general.no_retirement_causes")}
      initialDefaultValues={{
        status: "",
        name: "",
        description: "",
      }}
      fields={[
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "description",
          required: false,
          label: t("_labels.description"),
          disable: "isUpdate",
        },
      ]}
      createFormTitle={t("_titles.new.retirement_cause")}
      updateFormTitle={t("_titles.update.retirement_cause")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.retirement_cause"),
        },
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.retirement_cause")}
      updatedMessage={t("_messages.updated.retirement_cause")}
      getItemAction={getRetirementCause}
      setFiltersAction={setRetirementCauseFilters}
      clearFiltersAction={clearRetirementCauseFilters}
      deleteAction={deleteRetirementCause}
      setStatusAction={setRetirementCauseStatus}
      getItemsAction={getRetirementCauses}
      clearItemsAction={clearRetirementCauses}
      addAction={addRetirementCause}
      updateAction={updateRetirementCause}
    />
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  retirementCause: PropTypes.object.isRequired,
  retirementCauses: PropTypes.object.isRequired,
  createRetirementCausePermit: PropTypes.bool.isRequired,
  updateRetirementCausePermit: PropTypes.bool.isRequired,
  deleteRetirementCausePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  getRetirementCause: PropTypes.func.isRequired,
  getRetirementCauses: PropTypes.func.isRequired,
  addRetirementCause: PropTypes.func.isRequired,
  updateRetirementCause: PropTypes.func.isRequired,
  deleteRetirementCause: PropTypes.func.isRequired,
  clearRetirementCauseFilters: PropTypes.func.isRequired,
  setRetirementCauseFilters: PropTypes.func.isRequired,
  setRetirementCauseStatus: PropTypes.func.isRequired,
  clearRetirementCauses: PropTypes.func.isRequired,
};

export default Page;
