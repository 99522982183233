import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";
import { DELETE_LINK_VEHICLE_WITH_GPS } from "store/actions";

export const actionDeleteLinkVehicleWithGps =
  (dispatch) => async (gpsId, vehicleId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "DELETE",
        path: `gps/${gpsId}/vehicle/${vehicleId}`,
      });

      dispatch({
        type: DELETE_LINK_VEHICLE_WITH_GPS,
        payload: { gps_id: gpsId, vehicle_id: vehicleId },
        // payload: gpsId
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
