import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";
import { connect } from "react-redux";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionUnlinkCommissionedVehicleDriver } from "store/actions/commissioned/vehicle_driver/unlinkVehicleDriver";
import { actionGetCommissionedVehicles } from "store/actions/commissioned/vehicle/getCommissionedVehicles";

import css from "./index.module.scss";

function CommissionedDriverInfoDialog({ ...rest }) {
  const t = useLang();
  const {
    filter,
    open,
    onClose,
    commissionedDriver,
    unlinkVehicleDriver,
    getCommissionedVehicles,
    handleOpenLinkForm,
    setNotification,
  } = rest;

  async function unlink() {
    try {
      const response = await unlinkVehicleDriver(
        commissionedDriver.commissioned_vehicle_driver_id
      );

      setNotification({
        message: response.message,
      });
      onClose();
      getCommissionedVehicles({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function confirmUnlink() {
    setNotification({
      message: "¿Estas seguro de desasignar al conductor de este vehículo?",
      onAction: unlink,
      textAction: t("_buttons.confirm"),
    });
  }
  return (
    <DialogMediaQuery size={"sm"} open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle className={classnames(css.title)}>
        {"Información del conductor asignado"}
      </DialogTitle>
      <DialogContent className={classnames(css.content)}>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              text={commissionedDriver?.commissioned_driver.name}
              label={t("_labels.name.singular")}
              className="w-50"
            />
            <LabelAndText
              text={commissionedDriver?.commissioned_driver.driver_code}
              label={t("_labels.driver_code")}
              className="w-50"
            />
          </div>
          <div>
            <LabelAndText
              text={commissionedDriver?.commissioned_driver.association.name}
              label={t("_labels.association_name")}
            />
          </div>
          <div>
            <LabelAndText
              text={t("_labels.actioned_by", {
                person: `${commissionedDriver?.created_by.name} ${
                  commissionedDriver?.created_by.last_name_1
                } ${commissionedDriver?.created_by.last_name_2 ?? ""}`,
                date: moment(commissionedDriver?.created_at).format("LL"),
                time: moment(commissionedDriver?.created_at).format("hh:m:s a"),
              })}
              label={t("_labels.assigned_by")}
            />
          </div>

          <div className={classnames(css.w100)}>
            <Typography
              className={classnames(css.mt4, css.mb8)}
              variant="overline"
              display="block"
            >
              {t("_labels.actions")}
            </Typography>
            <div className={classnames(css.horizontal)}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={confirmUnlink}
              >
                Desasignar
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => handleOpenLinkForm(null, commissionedDriver)}
              >
                Cambiar conductor
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {t("_buttons.close")}
        </Button>
      </DialogActions>
    </DialogMediaQuery>
  );
}

CommissionedDriverInfoDialog.propTypes = {
  commissionedDriver: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleOpenLinkForm: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filter: state.Commissioned.Vehicle.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  unlinkVehicleDriver: actionUnlinkCommissionedVehicleDriver(dispatch),
  getCommissionedVehicles: actionGetCommissionedVehicles(dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionedDriverInfoDialog);
