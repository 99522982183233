import size from "./size";
import damage from "./damage";
import wear from "./wear";
import subsidiary from "./subsidiary";
import warehouse from "./warehouse";
import TireForm from "./TireForm";
import TireTabs from "./TireTabs";
import TireHistoryTabs from "./TireHistoryTabs";
import FilterTireForm from "./FilterTireForm";
import InfoTireCard from "./InfoTireCard";
import model from "./model";
import cycle from "./cycle";
import repair from "./repair";

export default {
  size,
  damage,
  wear,
  subsidiary,
  warehouse,
  model,
  cycle,
  tire_form: TireForm,
  tire_tabs: TireTabs,
  tire_history_tabs: TireHistoryTabs,
  filter_tire_form: FilterTireForm,
  info_tire_card: InfoTireCard,
  repair,
};
