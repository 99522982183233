import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLang } from "hooks/lang";

import TruckTractorPreview from "components/molecules/general/vehicle_preview/TruckTractorPreview";
import TruckPreview from "components/molecules/general/vehicle_preview/TruckPreview";
import BoxPreview from "components/molecules/general/vehicle_preview/BoxPreview";
import DollyPreview from "components/molecules/general/vehicle_preview/DollyPreview";
import VanPreview from "components/molecules/general/vehicle_preview/VanPreview";

import Page from "./page";

export const NAMESPACE =
  "components/molecules/general/vehicle_preview/VehiclePreview";

function Preview({
  message,
  structureType,
  axles,
  className,
  size,
  onClickTire,
  onRotateTire,
}) {
  const t = useLang(NAMESPACE);
  const [compSize, setCompSize] = useState("sm");
  const [structure, setStructure] = useState(null);
  const [children, setChildren] = useState(null);

  useEffect(() => {
    if (!size) {
      setCompSize("sm");
      return;
    }

    setCompSize(size);
  }, [size]);

  useEffect(() => {
    setStructure(structureType);
    switch (structureType) {
      case "TRUCK_TRACTOR":
        setChildren(
          <TruckTractorPreview
            axles={axles ? axles : []}
            size={compSize}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          />
        );
        break;
      case "TRUCK":
        setChildren(
          <TruckPreview
            axles={axles ? axles : []}
            size={compSize}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          />
        );
        break;
      case "BOX":
        setChildren(
          <BoxPreview
            axles={axles ? axles : []}
            size={compSize}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          />
        );
        break;
      case "DOLLY":
        setChildren(
          <DollyPreview
            axles={axles ? axles : []}
            size={compSize}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          />
        );
        break;
      case "VAN":
        setChildren(
          <VanPreview
            axles={axles ? axles : []}
            size={compSize}
            onClickTire={onClickTire}
            onRotateTire={onRotateTire}
          />
        );
        break;
      default:
        setChildren(null);
        setStructure(null);
        break;
    }
  }, [structureType, axles]);

  return (
    <Page
      t={t}
      message={message}
      structureType={structure}
      className={className}
    >
      {children}
    </Page>
  );
}

Preview.propTypes = {
  message: PropTypes.string,
  size: PropTypes.oneOf(["sm"]),
  className: PropTypes.string,
  structureType: PropTypes.string,
  axles: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      tire_quantity: PropTypes.number.isRequired,
      vehicle_type_axle_tire: PropTypes.array.isRequired,
    })
  ),
  onClickTire: PropTypes.func,
  onRotateTire: PropTypes.func,
};

export default Preview;
