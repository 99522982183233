import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, FieldArray, Form } from "formik";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  isUpdate,
  initialValues,
  values,
  vehicles,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
  setFieldValue,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <FieldArray
        name="vehicles"
        validateOnChange={false}
        render={(arrayHelpers) => (
          <>
            {values.vehicles.map((_, index) => (
              <div
                key={`commissioned_vehicle_${index}`}
                className={classnames(css.field_wrap)}
              >
                <Typography
                  variant="overline"
                  color="textSecondary"
                  className={classnames(css.subtitle, css.mx8)}
                >
                  Posición {index + 2}
                </Typography>
                <div className={classnames(css.horizontal)}>
                  <Field
                    id={`commissioned_vehicle_id_${index}`}
                    name={`vehicles[${index}]`}
                    autoHighlight
                    blurOnSelect
                    component={Autocomplete}
                    options={vehicles}
                    getOptionLabel={(option) => option.economic_number}
                    onChange={(_, value) =>
                      setFieldValue(`vehicles[${index}]`, value)
                    }
                    value={values.vehicles[index]}
                    getOptionSelected={(option, value) =>
                      option.commissioned_vehicle_id ==
                      value.commissioned_vehicle_id
                    }
                    noOptionsText={t("_labels.autocomplete.no_options")}
                    openText={t("_labels.autocomplete.open")}
                    clearText={t("_labels.autocomplete.clear")}
                    closeText={t("_labels.autocomplete.close")}
                    className={classnames("MuiFormControl-root", css.field)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("_labels.vehicle.singular")}
                        variant="filled"
                      />
                    )}
                  />
                </div>
              </div>
            ))}

            <div className={classnames(css.full_width, css.buttons)}>
              <Button
                type="button"
                onClick={() => arrayHelpers.pop()}
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
                disabled={
                  isUpdate
                    ? values.vehicles.length <= initialValues.vehicles.length
                    : values.vehicles.length <= 1
                }
              >
                <RemoveIcon />
              </Button>
              <Button
                type="button"
                onClick={() => arrayHelpers.push(null)}
                color="secondary"
                size="small"
                variant="fab"
                tabIndex="-1"
                disabled={values.vehicles.length >= 3}
              >
                <AddIcon />
              </Button>
            </div>
          </>
        )}
      />
      <DialogActions className={classnames(css.actions)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  vehicles: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func,
};

export default Page;
