import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  company,
  companies,
  corporates,
  createCompanyPermit,
  updateCompanyPermit,
  deleteCompanyPermit,
  filter,
  t,
  getCompany,
  addCompany,
  updateCompany,
  clearCompanyFilters,
  setCompanyFilters,
  deleteCompany,
  setStatusCompany,
  getCompanies,
  clearCompanies,
}) {
  return (
    <Home
      item={company}
      name="company"
      filter={filter}
      createTooltipPermit={
        !createCompanyPermit ? t("_permits.create.company") : ""
      }
      currentPage={companies.current_page}
      deleteConfirmationMessage={t("_messages.delete.company")}
      disableConfirmationMessage={t("_messages.change_status.company.disable")}
      enableConfirmationMessage={t("_messages.change_status.company.enable")}
      rows={companies.data.map((company) => {
        return {
          id: company.company_id,
          key: company.company_id,
          status: {
            status: company.status,
            tooltipPermit: !updateCompanyPermit
              ? t("_permits.update.company")
              : "",
          },
          name: company.name,
          social_reason: company.social_reason,
          rfc: company.rfc,
          edit: {
            tooltipPermit: !updateCompanyPermit
              ? t("_permits.update.company")
              : "",
          },
          delete: {
            tooltipPermit: !deleteCompanyPermit
              ? t("_permits.delete.company")
              : "",
          },
        };
      })}
      title={t("_general.companies")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.tradename") },
        { id: "social_reason", label: t("_labels.social_reason") },
        { id: "rfc", label: t("_labels.rfc") },
        { id: "actions", label: "" },
      ]}
      lastPage={companies.last_page}
      noItemsText={t("_general.no_companies")}
      initialDefaultValues={{
        name: "",
        corporate_id: "",
        social_reason: "",
        rfc: "",
        tire_fee: "",
        fee_currency_type: "MXN",
        logo: null,
      }}
      fields={[
        {
          name: "logo",
          accept: "image/*",
          placeholder: t("_labels.placeholder.image"),
          component: "file",
          fullWidth: true,
          required: false,
        },
        {
          name: "name",
          required: true,
          label: t("_labels.name.special"),
        },
        {
          name: "corporate_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.corporate_field.singular.label"),
          selectItems: corporates.map((corporate) => ({
            value: corporate.corporate_id.toString(),
            name: corporate.name,
            disabled: !corporate.status,
          })),
        },
        {
          name: "social_reason",
          required: true,
          label: t("_labels.social_reason"),
        },
        {
          name: "rfc",
          required: true,
          label: t("_labels.rfc"),
        },
        {
          name: "tire_fee",
          required: true,
          type: "number",
          label: t("_labels.tire_fee"),
        },
        {
          name: "fee_currency_type",
          required: true,
          component: "select",
          label: t("_labels.fee_field.label"),
          selectItems: [
            { value: "MXN", name: t("_labels.fee_field.options.mxn") },
            { value: "USD", name: t("_labels.fee_field.options.usd") },
            { value: "EUR", name: t("_labels.fee_field.options.eur") },
          ],
        },
      ]}
      createFormTitle={t("_titles.new.company")}
      updateFormTitle={t("_titles.update.company")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.company"),
        },
        date: true,
        corporates: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        corporates: [],
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.company")}
      updatedMessage={t("_messages.updated.company")}
      getItemAction={getCompany}
      setFiltersAction={setCompanyFilters}
      clearFiltersAction={clearCompanyFilters}
      deleteAction={deleteCompany}
      setStatusAction={setStatusCompany}
      getItemsAction={getCompanies}
      clearItemsAction={clearCompanies}
      addAction={addCompany}
      updateAction={updateCompany}
    />
  );
}

Page.propTypes = {
  company: PropTypes.object.isRequired,
  companies: PropTypes.object.isRequired,
  corporates: PropTypes.array.isRequired,
  createCompanyPermit: PropTypes.bool.isRequired,
  updateCompanyPermit: PropTypes.bool.isRequired,
  deleteCompanyPermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  addCompany: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  clearCompanyFilters: PropTypes.func.isRequired,
  setCompanyFilters: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  setStatusCompany: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  clearCompanies: PropTypes.func.isRequired,
};

export default Page;
