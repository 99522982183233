import { http } from "store/actions/http";
import { REGISTER_VEHICLE_REVIEW } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddVehicleReview =
  (dispatch) => async (vehicleId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "POST",
        path: `vehicle/${vehicleId}/review`,
        data: fields,
      });

      dispatch({
        type: REGISTER_VEHICLE_REVIEW,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
