import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRfids } from "store/actions/rfid/getRfids";
import { actionAddTire } from "store/actions/tire/addTire";
import { actionReturnMultipleRevitalization } from "store/actions/tire/returnMultipleRevitalization";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function SimilarTiresForm({
  open,
  onClose,
  tireValues,
  similarTires,
  newTires,
  ...rest
}) {
  const t = useLang();
  const {
    setNotification,
    getRfids,
    addTire,
    clearTireFilters,
    returnMultipleRevitalization,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({});

  const [rfids, setRfids] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getRfids(
          {
            scope: "rfid_id,device_code,status",
            withLinkTire: 0,
          },
          false
        );
        setRfids(data.sort(alphabeticalSort("device_code")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      let fields = [];

      if (tireValues.dot) {
        fields[0] = {
          code: tireValues.code,
          device_code: tireValues.device_code || "",
          dot: tireValues.dot,
        };

        for (let index = 1; index < similarTires; index++) {
          fields[index] = {
            code: "",
            device_code: "",
            dot: "",
          };
        }
      } else {
        fields[0] = {
          code: tireValues.code,
          device_code: tireValues.device_code || "",
          dot: "",
        };

        for (let index = 1; index < similarTires; index++) {
          fields[index] = {
            code: "",
            device_code: "",
            dot: "",
          };
        }
      }

      setInitialValues({
        tires: fields,
      });
    } else {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    if (newTires && values.tires.length) {
      let registered = false;
      let index = 0;
      try {
        for (index = 0; index < values.tires.length; index++) {
          const fields = {
            ...tireValues,
            ...values.tires[index],
          };

          if (fields.dot === "") {
            delete fields.dot;
          }

          if (fields.code === "") {
            delete fields.code;
          }

          if (fields.device_code === "") {
            delete fields.device_code;
          }
          await addTire(fields);
          registered = true;
        }
      } catch (error) {
        registered = false;
        if (error.code === "TCE422") {
          error.message = error.message.replace(
            "neumático",
            `neumático ${
              values.tires[index].code || values.tires[index].device_code || ""
            }`
          );
        }
        setFormikErrors(error, values, rest);
      }

      if (registered) {
        resetForm();
        setNotification({
          message: t("_messages.created.tires"),
        });

        setSubmitting(false);
        onClose();
      }
      try {
        clearTireFilters();
      } catch (error) {
        setNotification(error, true);
      }
    } else {
      let codes = [];
      let deviceCodes = [];
      for (let index = 0; index < values.tires.length; index++) {
        if (values.tires[index].code !== "") {
          codes.push(values.tires[index].code);
        }

        if (values.tires[index].device_code !== "") {
          deviceCodes.push(values.tires[index].device_codes);
        }
      }

      try {
        const fields = {
          warehouse_id: tireValues.warehouse_id,
          revitalized_tire_model_id: tireValues.revitalized_tire_model_id,
          price: tireValues.price,
          depth: tireValues.millimeter,
          comment: tireValues.comment,
          date_return: tireValues.date_return,
        };

        if (tireValues.invoice_date) {
          fields.invoice_date = tireValues.invoice_date;
        }
        if (tireValues.invoice_folio) {
          fields.invoice_folio = tireValues.invoice_folio;
        }
        if (tireValues.expected_durability) {
          fields.expected_durability = tireValues.expected_durability;
        }

        if (codes.join() !== "") {
          fields.code = codes.join();
        }

        if (deviceCodes.join() !== "") {
          fields.device_code = deviceCodes.join();
        }

        await returnMultipleRevitalization(fields);

        resetForm();
        setNotification({
          message: t("_messages.sent.multiple_to_warehouse"),
        });

        setSubmitting(false);
        onClose();
      } catch (error) {
        setFormikErrors(error, values, rest);
      }

      clearTireFilters();
    }
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);
    errors.tires = [];

    if (tireValues.dot) {
      if (values.tires.length > 0) {
        values.tires.forEach((item) => {
          const error = {};
          if (!!item.dot && (item.dot.length < 2 || item.dot.length > 12)) {
            error.dot = t("_errors.dot");
          }
          errors.tires.push(error);
        });
      }

      let count = 0;
      errors.tires.forEach((error) => {
        if (error.dot) {
          count++;
        }
      });

      if (count === 0) {
        return {};
      }
      return errors;
    }
  }
  return (
    <Page
      t={t}
      open={open}
      rfids={rfids}
      isRegisterForm={tireValues.hasOwnProperty("dot")}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
    />
  );
}

SimilarTiresForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  similarTires: PropTypes.number.isRequired,
  tireValues: PropTypes.object.isRequired,
  newTires: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getRfids: actionGetRfids(dispatch),
  setNotification: actionSetNotification(dispatch),
  addTire: actionAddTire(dispatch),
  returnMultipleRevitalization: actionReturnMultipleRevitalization(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimilarTiresForm);
