import { GET_VEHICLES_MOUNT } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehiclesMount =
  (dispatch) =>
  async (
    {
      search,
      vehicle_type_id: vehicleTypeId,
      vehicle_brand_id: vehicleBrandId,
      driver_id: drivers,
      in_process: inProcess,
      date_review_from: dateReviewFrom,
      date_review_to: dateReviewTo,
      page,
      subsidiaries,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `vehicle/review`,
        params: {
          search,
          vehicle_type_id: vehicleTypeId,
          vehicle_brand_id: vehicleBrandId,
          drivers,
          in_process: inProcess,
          date_review_from: dateReviewFrom,
          date_review_to: dateReviewTo,
          page,
          subsidiaries,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLES_MOUNT,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
