export default {
  update_title: "Update vehicle",
  form: {
    permits: {
      update: "No permissions to update vehicles",
      delete: "No permissions to delete vehicles",
    },
  },
  messages: {
    vehicle_delete: "Are you sure to delete this vehicle?",
    vehicle_updated: "Done, the vehicle was successfully updated",
  },
};
