export default {
  title: "Nuevo Corporativo",
  update_title: "Actualizar Corporativo",
  form: {
    placeholder: "Suelta la imagen ó da click para seleccionar una",
    permits: {
      update: "Sin permisos para actualizar corporativos",
      delete: "Sin permisos para eliminar corporativos",
    },
  },
  messages: {
    delete: "¿Estas seguro de eliminar el corporativo?",
    corporate_created: "Listo, se creo un nuevo corporativo con éxito",
    corporate_updated: "Listo, se actualizo el corporativo con éxito",
  },
};
