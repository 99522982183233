export default {
  damage_disabled: "Damage disabled",
  permits: {
    change_status: "No permissions to {action} damage",
  },
  messages: {
    disable: "Are you sure to disable the damage?",
    enable: "Are you sure to enable the damage?",
  },
};
