import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { useLang } from "hooks/lang";

import { SHOW_VEHICLE } from "store/actions/account/permits";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicle } from "store/actions/vehicle/getVehicle";

import Page from "./page";

export const NAMESPACE = "src/components/organisms/vehicle/VehicleTabs";

export function VehicleTabs({ className, optionsAppBar, children, ...rest }) {
  const t = useLang(NAMESPACE);
  const { id } = useParams();
  const { vehicle, permits, getVehicle, setNotification } = rest;
  const showVehiclePermit = permits.includes(SHOW_VEHICLE);
  const tabLinks = [
    {
      to: `/vehicle/${id}`,
      label: t("_labels.information"),
      disabled: !showVehiclePermit,
      tooltip: !showVehiclePermit ? t("_permits.show.vehicle") : "",
    },
    {
      to: `/vehicle/${id}/tire`,
      label: t("mounted_tire"),
    },
    {
      to: `/vehicle/${id}/preview`,
      label: t("vehicle_type_preview"),
      disabled: !showVehiclePermit,
      tooltip: !showVehiclePermit ? t("_permits.show.vehicle") : "",
    },
    {
      to: `/vehicle/${id}/alert`,
      label: t("_alerts"),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        await getVehicle(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showVehiclePermit) {
      fetchData();
    }
  }, [id]);

  return (
    <Page
      title={
        showVehiclePermit ? vehicle.economic_number : t("_permits.show.vehicle")
      }
      className={className}
      optionsAppBar={optionsAppBar}
      tabLinks={tabLinks}
    >
      {children}
    </Page>
  );
}

VehicleTabs.propTypes = {
  children: PropTypes.node,
  optionsAppBar: PropTypes.node,
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehicle: state.Vehicle.Vehicle.vehicle,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicle: actionGetVehicle(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleTabs);
