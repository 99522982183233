import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionAddPressurePolicy } from "store/actions/association/policy/pressure/addVehiclePressurePolicy";
import { actionClearAssociationPressurePoliciesFilters } from "store/actions/association/policy/pressure/clearAssociationPressureFilters";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function MultiPressurePolicyForm({ open, onClose, vehicles, ...rest }) {
  const t = useLang();
  const { addPressurePolicy, clearPressurePoliciesFilters, setNotification } =
    rest;
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setFormikErrors,
    setInitialValues,
    resetFormik,
  } = useFormik({
    initialValues: {
      axle_policy: [
        {
          axle_type: "",
          recommended_pressure: "",
          tolerance: "",
        },
      ],
    },
  });

  const { id: associationId } = useParams();

  useEffect(() => {
    if (open && vehicles.length > 0) {
      const validAxles = vehicles.reduce((acc, vehicle) => {
        const axles = Object.keys(
          vehicle.vehicle_type.vehicle_type_axle.reduce((acc, val) => {
            if (!acc[val.axle_type]) {
              acc[val.axle_type] = true;
            }

            return acc;
          }, {})
        );

        acc = acc.concat(axles);
        return acc;
      }, []);

      const order = ["DIRECTIONAL", "TRACTION", "TRAILER", "REFECTION"];
      const axles = [...new Set([...validAxles])].sort((a, b) => {
        return order.indexOf(a) - order.indexOf(b);
      });

      setInitialValues({
        axle_policy: axles.map((axle) => ({
          axle_type: axle,
          recommended_pressure: "",
          tolerance: "",
        })),
      });
    } else {
      resetFormik();
    }
  }, [open, vehicles]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        vehicles: vehicles.map((el) => ({ commissioned_vehicle_id: el.id })),
        axle_policy: values.axle_policy,
      };
      await addPressurePolicy(associationId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.pressure_policy"),
      });

      setSubmitting(false);
      onClose();
      clearPressurePoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

MultiPressurePolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  vehicles: PropTypes.array,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addPressurePolicy: actionAddPressurePolicy(dispatch),
  clearPressurePoliciesFilters:
    actionClearAssociationPressurePoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiPressurePolicyForm);
