import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleTypeAxles } from "store/actions/vehicle/type/axle/getVehicleTypeAxles";
import { useLang } from "hooks/lang";

import Page from "./page";

export function Form({ ...rest }) {
  const t = useLang();

  const { values, getVehicleTypeAxles, setNotification, setFieldValue } = rest;
  useEffect(() => {
    async function fetchData() {
      try {
        const axles = await getVehicleTypeAxles(
          {
            vehicleTypeId: values.vehicle_type_id,
            scope: "vehicle_type_axle_id,axle_type,status",
          },
          false
        );

        const validAxles = Object.keys(
          axles.reduce((acc, val) => {
            if (!acc[val.axle_type]) {
              acc[val.axle_type] = true;
            }

            return acc;
          }, {})
        );

        const axleValues = validAxles.map((axle) => ({
          axle_type: axle,
          recommended_pressure: "",
          tolerance: "",
        }));

        setFieldValue("axle_policy", axleValues);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (values.vehicle_type_id != "") {
      fetchData();
    }
  }, [values.vehicle_type_id]);

  return <Page {...rest} t={t} />;
}

Form.propTypes = {
  isValid: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  vehicleTypes: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleTypeAxles: actionGetVehicleTypeAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
