import {
  REGISTER_CONDITION_POLICY,
  UPDATE_CONDITION_POLICY,
  DELETE_CONDITION_POLICY,
  GET_CONDITION_POLICIES,
  CLEAR_CONDITION_POLICIES,
  GET_CONDITION_POLICY,
  SET_CONDITION_POLICIES_FILTERS,
  CLEAR_CONDITION_POLICIES_FILTERS,
} from "store/actions";

const initialState = {
  condition_policy: {
    vehicle_condition_policy_id: 0,
    economic_number: "",
    vehicle_id: 0,
    type_of_route: "",
    position: "",
    condition: "",
    vehicle_brand: {
      name: "",
    },
    subsidiary: {
      subsidiary_id: 0,
      name: "",
    },
    division: {
      name: "",
    },
    vehicle_condition_policy: {
      vehicle_condition_policy_id: 0,
      axle: "",
      condition: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
  },

  condition_policies: {
    current_page: 1,
    data: [],
    last_page: 1,
  },

  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONDITION_POLICY:
    case UPDATE_CONDITION_POLICY:
    case REGISTER_CONDITION_POLICY:
      return {
        ...state,
        condition_policy: action.payload,
      };

    case GET_CONDITION_POLICIES:
      return {
        ...state,
        condition_policies: action.payload,
      };

    case CLEAR_CONDITION_POLICIES:
      return {
        ...state,
        condition_policies: { ...initialState.condition_policies },
      };

    case SET_CONDITION_POLICIES_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_CONDITION_POLICIES_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };

    case DELETE_CONDITION_POLICY:
      return {
        ...state,
        condition_policies: {
          ...state.condition_policies,
          data: state.condition_policies.data.filter(
            (vehicleConditionPolicy) =>
              vehicleConditionPolicy.vehicle_condition_policy_id !==
              action.payload
          ),
        },
      };

    default:
      return state;
  }
}
