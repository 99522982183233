import { REGISTER_MODEL_VARIATION_POLICY } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAddModelVariationPolicy =
  (dispatch) => async (subsidiaryId, fields) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "POST",
        path: `subsidiary/${subsidiaryId}/pressure/policy/tire/model/variation`,
        data: fields,
      });

      dispatch({
        type: REGISTER_MODEL_VARIATION_POLICY,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
