import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DateTimeField from "components/molecules/general/DateTimeField";

import css from "./index.module.scss";

function Page({ t, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        name="date"
        InputLabelProps={{
          shrink: true,
        }}
        type="date"
        component={DateTimeField}
        label={t("_labels.date.label")}
        className={classnames(css.medium_field)}
      />

      <Field
        required
        variant="filled"
        margin="normal"
        name="odometer"
        component={TextField}
        type="number"
        className={classnames(css.medium_field)}
        label={t("_labels.odometer")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornments)}
              position="start"
            >
              km
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        multiline
        rows={3}
        variant="filled"
        margin="normal"
        name="observation"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.observation")}
      />

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {t("_buttons.update")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
