import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDifferentAssignment =
  (dispatch) =>
  async (vehicleTireId, vehicleTypeAxleTireId, code, deviceCode) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const data = {
        vehicle_type_axle_tire_id: vehicleTypeAxleTireId,
      };
      if (code) {
        data.code = code;
      }
      if (deviceCode) {
        data.device_code = deviceCode;
      }

      if (code || deviceCode) {
        data.required = 1;
      }
      const response = await http({
        method: "POST",
        path: `vehicle/${vehicleTireId}/physical/difference`,
        data,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
