import React from "react";
import PropTypes from "prop-types";
import Home from "pages/general/Home";

function Page({
  wear,
  wears,
  updateWearPermit,
  deleteWearPermit,
  filter,
  t,
  getWear,
  getWears,
  updateWear,
  deleteWear,
  clearWearFilters,
  setWearFilters,
  setWearStatus,
  clearWears,
}) {
  return (
    <Home
      item={wear}
      name="wear"
      filter={filter}
      currentPage={wears.current_page}
      deleteConfirmationMessage={t("_messages.delete.wear")}
      disableConfirmationMessage={t("_messages.change_status.wear.disable")}
      enableConfirmationMessage={t("_messages.change_status.wear.enable")}
      approveConfirmationMessage={t("_messages.change_approved.wear.approved")}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.wear.disapprove"
      )}
      rows={wears.data.map((wear) => {
        return {
          id: wear.wear_id,
          key: wear.wear_id,
          status: {
            status: wear.status,
            tooltipPermit: !updateWearPermit ? t("_permits.update.Wear") : "",
          },

          name: t(`_damage.name.${wear.name}`),

          description: `${
            wear.appearance ? t(`_damage.appearance.${wear.appearance}`) : ""
          }`,

          image: {
            tooltipPermit: !wear.image ? t("_general.no_image") : "",
            imageUrl: wear.image,
            title: t(`_damage.name.${wear.name}`),
          },

          edit: {
            tooltipPermit: !updateWearPermit ? t("_permits.update.wear") : "",
          },
          delete: {
            tooltipPermit: !deleteWearPermit ? t("_permits.update.Wear") : "",
          },
        };
      })}
      title={t("_general.wears")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "name", label: t("_labels.name.singular") },
        { id: "description", label: t("_labels.appearance") },
        { id: "actions", label: "" },
      ]}
      lastPage={wears.last_page}
      noItemsText={t("_general.no_wears")}
      initialDefaultValues={{
        status: "",
        name: "",
        appearance: "",
        wear_category: "",
        axle: "",
        probable_causes: "",
        action_tire: "",
        action_vehicle: "",
        operation: "",
        image: null,
      }}
      fields={[
        {
          name: "image",
          accept: "image/*",
          placeholder: t("_labels.placeholder.image"),
          component: "file",
          fullWidth: true,
          required: false,
        },
        {
          name: "name",
          required: true,
          label: t("_labels.name.singular"),
        },
        {
          name: "appearance",
          required: false,
          label: t("_labels.appearance"),
        },
        {
          name: "wear_category",
          required: true,
          component: "select",
          label: t("_labels.category.label"),
          selectItems: [
            {
              value: "AVOIDABLE",
              name: t("_labels.category.options.avoidable"),
            },
            {
              value: "HELMET_FAILURE",
              name: t("_labels.category.options.helmet_failure"),
            },
            {
              value: "OPERATION",
              name: t("_labels.category.options.operation"),
            },
            {
              value: "RETREAD/REPAIR",
              name: t("_labels.category.options.retread_repair"),
            },
            {
              value: "ROAD_RISK",
              name: t("_labels.category.options.road_risk"),
            },
          ],
        },
        {
          name: "axle",
          required: true,
          component: "select",

          label: t("_labels.axle.singular"),
          selectItems: [
            {
              value: "DIRECTIONAL",
              name: t("_labels.category.options.directional"),
            },
            { value: "TRACTION", name: t("_labels.category.options.traction") },
            { value: "TRAILER", name: t("_labels.category.options.trailer") },
          ],
        },
        {
          name: "probable_causes",
          required: false,

          label: t("_labels.probable_causes"),
        },
        {
          name: "action_tire",
          required: false,

          label: t("_labels.action_tire"),
        },
        {
          name: "action_vehicle",
          required: false,

          label: t("_labels.action_vehicle"),
        },
        {
          name: "operation",
          required: false,

          label: t("_labels.operation"),
        },
      ]}
      createFormTitle={t("_titles.new.wear")}
      updateFormTitle={t("_titles.update.wear")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.wear"),
        },
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        date_from: "",
        date_to: "",
      }}
      withImageDialog={true}
      updatedMessage={t("_messages.updated.wear")}
      getItemAction={getWear}
      setFiltersAction={setWearFilters}
      clearFiltersAction={clearWearFilters}
      deleteAction={deleteWear}
      setStatusAction={setWearStatus}
      getItemsAction={getWears}
      clearItemsAction={clearWears}
      updateAction={updateWear}
    />
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  wear: PropTypes.object.isRequired,
  wears: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  updateWearPermit: PropTypes.bool.isRequired,
  deleteWearPermit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  getWear: PropTypes.func.isRequired,
  getWears: PropTypes.func.isRequired,
  updateWear: PropTypes.func.isRequired,
  deleteWear: PropTypes.func.isRequired,
  clearWearFilters: PropTypes.func.isRequired,
  setWearFilters: PropTypes.func.isRequired,
  setWearStatus: PropTypes.func.isRequired,
  clearWears: PropTypes.func.isRequired,
};

export default Page;
