import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router";

import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionSetNotification } from "store/actions/general/notification";
import { actionAddTireModel } from "store/actions/tire/model/addTireModel";
import { actionGetTireModel } from "store/actions/tire/model/getTireModel";
import { actionGetTireUseTypes } from "store/actions/tire/use/getTireUseTypes";
import { actionUpdateTireModel } from "store/actions/tire/model/updateTireModel";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";
import { actionClearTireModelFilters } from "store/actions/tire/model/clearTireModelFilters";
import { actionDeleteTireModel } from "store/actions/tire/model/deleteTireModel";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export const NAMESPACE = "components/organisms/tire/model/TireModelForm";

export function TireModelForm({ open, tireModelId, onClose, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const {
    getBrands,
    tireModel,
    addTireModel,
    getTireModel,
    updateTireModel,
    setNotification,
    getTireUseTypes,
    getTireApplicationTypes,
    clearTireModelFilters,
    deleteTireModel,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      brand_id: "",
      name: "",
      data_sheet: null,
      tire_model_application: [],
      tire_model_use: [],
    },
  });
  const [tireBrands, setTireBrands] = useState([]);
  const [tireUseTypes, setTireUseTypes] = useState([]);
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);
  const [isUpdate, setIsUpdate] = useState(tireModelId ? true : false);

  useEffect(() => {
    async function fetchData() {
      try {
        let data;
        data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );
        setTireApplicationTypes(
          data.sort(alphabeticalSort("tire_application_id"))
        );

        data = await getTireUseTypes({ scope: "tire_use_id,status" }, false);
        setTireUseTypes(data.sort(alphabeticalSort("tire_use_id")));

        data = await getBrands(
          { scope: "brand_id,name,status", brandType: "TIRE" },
          false
        );
        setTireBrands(data.sort(alphabeticalSort("brand_id")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModel(tireModelId);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (tireModelId && open) {
      fetchData();
    }
  }, [tireModelId, open]);

  useEffect(() => {
    if (tireModelId && tireModel.tire_model_id) {
      setInitialValues({
        brand_id: tireModel.brand_id.toString(),
        name: tireModel.name,
        data_sheet: `${process.env.REACT_APP_IMAGES_URL}${tireModel.data_sheet}`,
        tire_model_application: tireModel.tire_model_application.map(
          (application) => application.tire_application_id
        ),
        tire_model_use: tireModel.tire_model_use.map((use) => use.tire_use_id),
      });
    } else {
      setInitialValues({
        brand_id: "",
        name: "",
        data_sheet: null,
        tire_model_application: [],
        tire_model_use: [],
      });
    }
  }, [tireModel, tireModelId]);

  useEffect(() => {
    setIsUpdate(tireModelId ? true : false);
  }, [tireModelId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    let withFile = false;
    const fields = {
      brand_id: values.brand_id,
      name: values.name,
      tire_model_application: values.tire_model_application.join(),
      tire_model_use: values.tire_model_use.join(),
    };

    if (values.data_sheet && typeof values.data_sheet === "object") {
      fields.data_sheet = values.data_sheet;
      withFile = true;
    }

    try {
      if (!isUpdate) {
        await addTireModel(fields, withFile);
        resetForm();
        setNotification({
          message: t("messages.tire_model_created"),
        });
      } else {
        await updateTireModel(fields, tireModelId, withFile);
        setNotification({
          message: t("messages.tire_model_updated"),
        });
      }

      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    clearTireModelFilters();
  }

  async function onActionDelete() {
    try {
      const response = await deleteTireModel(tireModel.tire_model_id);
      setNotification({
        message: response.message,
        code: response.code,
      });
    } catch (error) {
      setNotification(error, true);
    }

    history.replace("/tire/model");
  }

  function handleDelete() {
    onClose();
    setNotification({
      message: t("messages.delete"),
      textAction: t("_messages.confirm"),
      onAction: onActionDelete,
    });
  }
  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      tireBrands={tireBrands}
      tireUseTypes={tireUseTypes}
      initialValues={initialValues}
      initialErrors={initialErrors}
      tireApplicationTypes={tireApplicationTypes}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
      handleDelete={handleDelete}
    />
  );
}

TireModelForm.propTypes = {
  open: PropTypes.bool.isRequired,
  tireModelId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tireModel: state.Tire.Model.Model.model,
});

const mapDispatchToProps = (dispatch) => ({
  getBrands: actionGetBrands(dispatch),
  addTireModel: actionAddTireModel(dispatch),
  getTireModel: actionGetTireModel(dispatch),
  updateTireModel: actionUpdateTireModel(dispatch),
  getTireUseTypes: actionGetTireUseTypes(dispatch),
  setNotification: actionSetNotification(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  clearTireModelFilters: actionClearTireModelFilters(dispatch),
  deleteTireModel: actionDeleteTireModel(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireModelForm);
