import { http } from "store/actions/http";
import { GET_VEHICLES_NO_REVIEW_COMPANY } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetVehiclesNoReviewCompany =
  (dispatch) =>
  async (
    {
      date_from: dateFrom,
      date_to: dateTo,
      corporate_id: corporateId,
      companies,
      subsidiaries,
    },
    id,
    companyId,
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();
    try {
      const response = await http({
        method: "GET",
        path: `report/review/performances/company/detail/${id}/${companyId}`,
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          corporate_id: corporateId,
          companies,
          subsidiaries,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_VEHICLES_NO_REVIEW_COMPANY,
          payload: response,
        });
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
