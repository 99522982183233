import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ContainerTemplate from "components/templates/general/Container";

import VehicleTypePreviewPanel from "components/organisms/vehicle/type/VehicleTypePreviewPanel";
import VehicleMountedTireDialog from "components/organisms/vehicle/tire/VehicleMountedTireDialog";
import LastVehicleReviewPanel from "components/organisms/vehicle/review/LastVehicleReviewPanel";
import TireReviewForm from "components/organisms/vehicle/tire/review/TireReviewForm";
import DamagesAndWearForm from "components/organisms/tire/DamagesAndWearForm";
import DamagesAndRepairForm from "components/organisms/tire/DamagesAndRepairForm";
import WrongTireForm from "components/organisms/vehicle/tire/WrongTireForm";
import UpdateVehicleReviewForm from "components/organisms/vehicle/review/UpdateVehicleReviewForm";
import SearchInput from "components/molecules/general/SearchInput";
import Button from "components/molecules/general/Button";

import Tire from "resources/images/vehicle/tire.svg";

import css from "./index.module.scss";

function Page({
  t,
  vehicle,
  vehicleId,
  axleTireInfo,
  inProcess,
  openMountedDialog,
  openTireReview,
  openDamagesAndWear,
  openDamagesAndRepair,
  isDamagesForm,
  handleClickTire,
  handleCloseMountedDialog,
  handleOpenTireReview,
  handleCloseTireReview,
  handleOpenDamagesAndWear,
  handleCloseDamagesAndWear,
  handleOpenDamagesAndRepair,
  handleCloseDamagesAndRepair,
  openWrongTireForm,
  handleOpenWrongTireForm,
  handleCloseWrongTireForm,
  vehicleTire,
  openVehicleReviewForm,
  handleOpenVehicleReviewForm,
  handleCloseVehicleReviewForm,
  handleSearch,
  search,
}) {
  return (
    <ContainerTemplate
      className={classnames(css.container)}
      title={`${t("_titles.vehicle_inspection")} ${vehicle.economic_number}`}
      options={
        <SearchInput search={search ? search : ""} onChange={handleSearch} />
      }
    >
      <div className={classnames(css.mb8)}>
        <Button
          href={`/vehicle/review/${vehicleId}/update`}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          variant="contained"
        >
          {t("_buttons.edit_inspections")}
        </Button>
      </div>
      <div className={classnames(css.wrap)}>
        <div className={classnames(css.w50)}>
          {vehicle.vehicle_type.structure_type && (
            <VehicleTypePreviewPanel
              className={classnames(css.preview)}
              axles={vehicle.vehicle_type_axle.map((axle) => ({
                ...axle,
                tire_quantity: axle.vehicle_type_axle_tire.length,
              }))}
              structureType={vehicle.vehicle_type.structure_type}
              title={vehicle.vehicle_type.name || null}
              onClickTire={inProcess ? handleClickTire : null}
            />
          )}
        </div>

        <div className={classnames(css.w50, css.column)}>
          {vehicle.vehicle_id !== 0 && (
            <LastVehicleReviewPanel
              vehicleId={vehicle.vehicle_id}
              isInReviewPage={true}
              inProcess={inProcess}
              handleOpenVehicleReviewForm={handleOpenVehicleReviewForm}
            />
          )}

          {inProcess && (
            <div className={classnames(css.examples)}>
              <div className={classnames(css.symbol)}>
                <img
                  src={Tire}
                  className={classnames(css.tire, css.tire_with_review)}
                  draggable={false}
                />
                <span>{t("_labels.reviewed")}</span>
              </div>
              <div className={classnames(css.symbol)}>
                <img
                  src={Tire}
                  className={classnames(css.tire, css.tire_without_review)}
                  draggable={false}
                />
                <span>{t("_labels.not_reviewed")}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <VehicleMountedTireDialog
        vehicleTireId={
          axleTireInfo.vehicle_tire?.length > 0
            ? axleTireInfo.vehicle_tire[0].vehicle_tire_id
            : null
        }
        open={openMountedDialog}
        onClose={handleCloseMountedDialog}
        onTireReview={handleOpenTireReview}
        onDamagesAndWear={handleOpenDamagesAndWear}
        onDamagesAndRepair={handleOpenDamagesAndRepair}
        onPhysicalDifference={handleOpenWrongTireForm}
      />

      <TireReviewForm
        open={openTireReview}
        onClose={() => {
          handleCloseTireReview();
          handleCloseMountedDialog();
        }}
      />
      <DamagesAndWearForm
        open={openDamagesAndWear}
        onClose={() => {
          handleCloseDamagesAndWear();
          handleCloseMountedDialog();
        }}
        isDamages={isDamagesForm}
      />

      <DamagesAndRepairForm
        open={openDamagesAndRepair}
        onClose={handleCloseDamagesAndRepair}
      />

      <WrongTireForm
        open={openWrongTireForm}
        onClose={handleCloseWrongTireForm}
        vehicleTire={vehicleTire}
      />

      <UpdateVehicleReviewForm
        open={openVehicleReviewForm}
        onClose={handleCloseVehicleReviewForm}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
  vehicleId: PropTypes.string,
  inProcess: PropTypes.bool.isRequired,
  movementTireId: PropTypes.number,
  openMountedDialog: PropTypes.bool.isRequired,
  openTireReview: PropTypes.bool.isRequired,
  openDamagesAndWear: PropTypes.bool.isRequired,
  openDamagesAndRepair: PropTypes.bool.isRequired,
  openWrongTireForm: PropTypes.bool.isRequired,
  vehicle: PropTypes.object.isRequired,
  axleTireInfo: PropTypes.object,
  vehicleTire: PropTypes.object,
  isDamagesForm: PropTypes.bool.isRequired,
  handleClickTire: PropTypes.func.isRequired,
  handleCloseMountedDialog: PropTypes.func.isRequired,
  handleOpenTireReview: PropTypes.func.isRequired,
  handleCloseTireReview: PropTypes.func.isRequired,
  handleOpenDamagesAndWear: PropTypes.func.isRequired,
  handleCloseDamagesAndWear: PropTypes.func.isRequired,
  handleOpenDamagesAndRepair: PropTypes.func.isRequired,
  handleCloseDamagesAndRepair: PropTypes.func.isRequired,
  handleOpenWrongTireForm: PropTypes.func.isRequired,
  handleCloseWrongTireForm: PropTypes.func.isRequired,
  openVehicleReviewForm: PropTypes.bool.isRequired,
  handleOpenVehicleReviewForm: PropTypes.func.isRequired,
  handleCloseVehicleReviewForm: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
};

export default Page;
