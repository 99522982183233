import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Button from "components/molecules/general/Button";

import notFound from "resources/images/notFound.svg";
import css from "./index.module.scss";

function Page({ t }) {
  return (
    <div className={classnames(css.container)}>
      <div>
        <img src={notFound} alt={t("alt")} className={classnames(css.img)} />
        <Typography variant="h2" display="block" color="textPrimary">
          {t("title")}
        </Typography>
        <Typography
          gutterBottom
          display="block"
          variant="overline"
          color="textSecondary"
          className={classnames(css.mb16)}
        >
          {t("description")}
        </Typography>
        <Link to="/">
          <Button variant="contained" color="secondary">
            {t("homepage")}
          </Button>
        </Link>
      </div>
    </div>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Page;
