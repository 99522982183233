import { GET_GPS_VEHICLE_LINK, CLEAR_GPS_VEHICLE_LINK } from "store/actions";

const initialState = {
  gps_vehicle: {
    diff_odometer: 0,
    geofence_status: 0,
    g_p_s: {
      device_name: "",
    },
    vehicle: {
      economic_number: "",
    },
    created_at: "",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GPS_VEHICLE_LINK:
      return {
        ...state,
        gps_vehicle: action.payload,
      };

    case CLEAR_GPS_VEHICLE_LINK:
      return {
        ...state,
        gps_vehicle: initialState.gps_vehicle,
      };

    default:
      return state;
  }
}
