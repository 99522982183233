import React from "react";
import PropTypes from "prop-types";
import Home from "pages/general/Home";

function Page({
  t,
  filter,
  tireApplicationTypes,
  revitalizedTireModel,
  revitalizedTireModels,
  getRevitalizedTireModel,
  getRevitalizedTireModels,
  addRevitalizedTireModel,
  updateRevitalizedTireModel,
  deleteRevitalizedTireModel,
  clearRevitalizedTireModelFilters,
  setRevitalizedTireModelFilters,
  clearRevitalizedTireModels,
  setRevitalizedTireModelStatus,
  setRevitalizedTireModelApprovalStatus,
  createRevitalizedTireModelPermit,
  updateRevitalizedTireModelPermit,
  deleteRevitalizedTireModelPermit,
  brands,
}) {
  return (
    <Home
      item={revitalizedTireModel}
      name="revitalized_tire_model"
      filter={filter}
      createTooltipPermit={
        !createRevitalizedTireModelPermit
          ? t("_permits.create.revitalized_tire_model")
          : ""
      }
      currentPage={revitalizedTireModels.current_page}
      deleteConfirmationMessage={t("_messages.delete.revitalized_tire_model")}
      disableConfirmationMessage={t(
        "_messages.change_status.revitalized_tire_model.disable"
      )}
      enableConfirmationMessage={t(
        "_messages.change_status.revitalized_tire_model.enable"
      )}
      rows={revitalizedTireModels.data.map((revitalizedTireModel) => {
        return {
          id: revitalizedTireModel.revitalized_tire_model_id,
          key: revitalizedTireModel.revitalized_tire_model_id,
          status: {
            status: revitalizedTireModel.status,
            tooltipPermit: !updateRevitalizedTireModelPermit
              ? t("_permits.update.revitalized_tire_model")
              : "",
          },
          approved: {
            approved: revitalizedTireModel.approved,
            tooltipPermit: !updateRevitalizedTireModelPermit
              ? t("_permits.update.revitalized_tire_model")
              : "",
          },
          name: revitalizedTireModel.name,
          depth: `${
            revitalizedTireModel.depth ? `${revitalizedTireModel.depth} mm` : ""
          }`,
          brand: revitalizedTireModel.brand.name,
          application: t(
            `_labels.tire_application.options.${revitalizedTireModel.tire_application_id.toLowerCase()}`
          ),
          created_by: `${revitalizedTireModel.created_by.name} ${
            revitalizedTireModel.created_by.last_name_1
          } ${revitalizedTireModel.created_by.last_name_2 ?? ""}`.trim(),
          edit: {
            tooltipPermit: !updateRevitalizedTireModelPermit
              ? t("_permits.update.revitalized_tire_model")
              : "",
          },
          delete: {
            tooltipPermit: !deleteRevitalizedTireModelPermit
              ? t("_permits.delete.revitalized_tire_model")
              : "",
          },
        };
      })}
      title={t("_general.revitalized_tire_models")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "approved", label: t("_labels.approved") },
        { id: "id", label: "Id" },
        { id: "name", label: t("_labels.revitalized_tire_model_name") },
        { id: "brand", label: t("_labels.brand") },
        { id: "depth", label: t("_labels.depth") },
        { id: "application", label: t("_labels.application") },
        { id: "created_by", label: t("_labels.registered_by") },
        { id: "actions", label: "" },
      ]}
      lastPage={revitalizedTireModels.last_page}
      noItemsText={t("_general.no_revitalized_tire_models")}
      initialDefaultValues={{
        status: "",
        id: "",
        name: "",
        brand_id: "",
        depth: "",
        tire_application_id: "",
        data_sheet: null,
      }}
      fields={[
        {
          name: "data_sheet",
          required: true,
          label: t("_labels.data_sheet.label"),
          accept: "image/*,application/pdf",
          placeholder: t("_labels.data_sheet.placeholder"),
          component: "file",
          fullWidth: true,
        },
        {
          name: "name",
          required: true,
          label: t("_labels.revitalized_tire_model_name"),
        },

        {
          name: "brand_id",
          required: true,
          component: "select",
          label: t("_labels.brand"),
          selectItems: brands.map((brand) => ({
            value: brand.brand_id.toString(),
            name: brand.name,
            disabled: !brand.status || !brand.approved,
          })),
        },
        {
          name: "tire_application_id",
          required: true,
          component: "select",
          label: t("_labels.tire_application.label.singular"),
          selectItems: tireApplicationTypes.map((application) => ({
            value: application.tire_application_id.toString(),
            name: t(
              `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
            ),

            disabled: !application.status,
          })),
        },

        {
          name: "depth",
          type: "number",
          required: true,
          label: t("_labels.depth"),
          inputAdornment: "mm",
        },
      ]}
      createFormTitle={t("_titles.new.revitalized_tire_model")}
      updateFormTitle={t("_titles.update.revitalized_tire_model")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.revitalized_tire_model"),
        },
        approved: true,
        revitalized_tire_model_id: true,
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        approved: "",
        revitalized_tire_model_id: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.revitalized_tire_model")}
      updatedMessage={t("_messages.updated.revitalized_tire_model")}
      getItemAction={getRevitalizedTireModel}
      setFiltersAction={setRevitalizedTireModelFilters}
      clearFiltersAction={clearRevitalizedTireModelFilters}
      addAction={addRevitalizedTireModel}
      updateAction={updateRevitalizedTireModel}
      deleteAction={deleteRevitalizedTireModel}
      setStatusAction={setRevitalizedTireModelStatus}
      getItemsAction={getRevitalizedTireModels}
      clearItemsAction={clearRevitalizedTireModels}
      approveConfirmationMessage={t("_messages.change_approved.model.approve")}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.model.disapprove"
      )}
      setApprovedAction={setRevitalizedTireModelApprovalStatus}
    />
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  revitalizedTireModel: PropTypes.object.isRequired,
  revitalizedTireModels: PropTypes.object.isRequired,
  getRevitalizedTireModel: PropTypes.func.isRequired,
  getRevitalizedTireModels: PropTypes.func.isRequired,

  addRevitalizedTireModel: PropTypes.func.isRequired,
  updateRevitalizedTireModel: PropTypes.func.isRequired,
  deleteRevitalizedTireModel: PropTypes.func.isRequired,

  clearRevitalizedTireModels: PropTypes.func.isRequired,
  clearRevitalizedTireModelFilters: PropTypes.func.isRequired,
  setRevitalizedTireModelFilters: PropTypes.func.isRequired,
  setRevitalizedTireModelStatus: PropTypes.func.isRequired,
  setRevitalizedTireModelApprovalStatus: PropTypes.func.isRequired,

  createRevitalizedTireModelPermit: PropTypes.bool.isRequired,
  updateRevitalizedTireModelPermit: PropTypes.bool.isRequired,
  deleteRevitalizedTireModelPermit: PropTypes.bool.isRequired,
};

export default Page;
