import React from "react";
import PropTypes from "prop-types";

import Home from "pages/general/Home";

function Page({
  tireSize,
  tireSizes,
  createTireSizePermit,
  updateTireSizePermit,
  deleteTireSizePermit,
  filter,
  t,
  getTireSize,
  addTireSize,
  updateTireSize,
  clearTireSizeFilters,
  setTireSizeFilters,
  deleteTireSize,
  setTireSizeStatus,
  setTireSizeApprovalStatus,
  getTireSizes,
  clearTireSizes,
}) {
  return (
    <Home
      item={tireSize}
      name="tire_size"
      filter={filter}
      createTooltipPermit={
        !createTireSizePermit ? t("_permits.create.size") : ""
      }
      currentPage={tireSizes.current_page}
      deleteConfirmationMessage={t("_messages.delete.size")}
      disableConfirmationMessage={t("_messages.change_status.size.disable")}
      enableConfirmationMessage={t("_messages.change_status.size.enable")}
      approveConfirmationMessage={t("_messages.change_approved.size.approve")}
      disapproveConfirmationMessage={t(
        "_messages.change_approved.size.disapprove"
      )}
      rows={tireSizes.data.map((tireSize) => {
        return {
          id: tireSize.tire_size_id,
          key: tireSize.tire_size_id,
          status: {
            status: tireSize.status,
            tooltipPermit: !updateTireSizePermit
              ? t("_permits.update.size")
              : "",
          },
          approved: {
            approved: tireSize.approved,
            tooltipPermit: !updateTireSizePermit
              ? t("_permits.update.size")
              : "",
          },
          name: tireSize.size,
          edit: {
            tooltipPermit: !updateTireSizePermit
              ? t("_permits.update.size")
              : "",
          },
          delete: {
            tooltipPermit: !deleteTireSizePermit
              ? t("_permits.delete.size")
              : "",
          },
        };
      })}
      title={t("_general.sizes")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "approved", label: t("_labels.approved") },
        { id: "name", label: t("_labels.size") },
        { id: "actions", label: "" },
      ]}
      lastPage={tireSizes.last_page}
      noItemsText={t("_general.no_sizes")}
      initialDefaultValues={{
        size: "",
      }}
      fields={[
        {
          name: "size",
          required: true,
          label: t("_labels.size"),
          fullWidth: true,
        },
      ]}
      createFormTitle={t("_titles.new.size")}
      updateFormTitle={t("_titles.update.size")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.size"),
        },
        approved: true,
        date: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        approved: "",
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.size")}
      updatedMessage={t("_messages.updated.size")}
      getItemAction={getTireSize}
      setFiltersAction={setTireSizeFilters}
      clearFiltersAction={clearTireSizeFilters}
      deleteAction={deleteTireSize}
      setStatusAction={setTireSizeStatus}
      setApprovedAction={setTireSizeApprovalStatus}
      getItemsAction={getTireSizes}
      clearItemsAction={clearTireSizes}
      addAction={addTireSize}
      updateAction={updateTireSize}
    />
  );
}

Page.propTypes = {
  tireSize: PropTypes.object.isRequired,
  tireSizes: PropTypes.object.isRequired,
  createTireSizePermit: PropTypes.bool.isRequired,
  updateTireSizePermit: PropTypes.bool.isRequired,
  deleteTireSizePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getTireSize: PropTypes.func.isRequired,
  addTireSize: PropTypes.func.isRequired,
  updateTireSize: PropTypes.func.isRequired,
  clearTireSizeFilters: PropTypes.func.isRequired,
  setTireSizeFilters: PropTypes.func.isRequired,
  deleteTireSize: PropTypes.func.isRequired,
  setTireSizeStatus: PropTypes.func.isRequired,
  setTireSizeApprovalStatus: PropTypes.func.isRequired,
  getTireSizes: PropTypes.func.isRequired,
  clearTireSizes: PropTypes.func.isRequired,
};

export default Page;
