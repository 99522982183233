import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetCorporates } from "store/actions/corporate/getCorporates";
import { actionSetPurchaseFilters } from "store/actions/reports/purchase/setPurchaseFilters";
import { actionClearPurchaseFilters } from "store/actions/reports/purchase/clearPurchaseFilters";
import { actionSetSelectedPurchaseFilters } from "store/actions/reports/purchase/setSelectedPurchaseFilters";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";
import { arrayJoin } from "utils/parse";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";

import Page from "./page";

export function FilterPurchaseForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    account,
    workArea,
    filter,
    setNotification,
    setPurchaseFilters,
    getCorporates,
    setSelectedFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      corporate_id: "",
      companies: [],
      subsidiaries: "",
      warehouses: [],
      vehicles: [],
      movement: "BOTH",
      depth_condition: "",
      projection_day: "",
    },
  });

  const [movements, setMovements] = useState([
    { value: "BOTH", label: t(`_labels.report_summary.location.both`) },
    {
      value: "WAREHOUSE",
      label: t(`_labels.report_summary.location.warehouse`),
    },
    { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
  ]);
  const [corporates, setCorporates] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [conditions, setConditions] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoaded(false);
        let data = [];
        data = await getCorporates(
          {
            scope: "corporate_id,name,status",
          },
          false
        );

        setCorporates(data.sort(alphabeticalSort("name")));
        setMovements([
          { value: "BOTH", label: t(`_labels.report_summary.location.both`) },
          {
            value: "WAREHOUSE",
            label: t(`_labels.report_summary.location.warehouse`),
          },
          { value: "MOUNT", label: t(`_labels.report_summary.location.mount`) },
        ]);

        setConditions([
          {
            value: "CRITICAL WITHDRAWAL",
            label: t(`_labels.critical_withdrawal`),
          },
          {
            value: "SCHEDULED WITHDRAWAL",
            label: t(`_labels.scheduled_withdrawal`),
          },
        ]);

        setLoaded(true);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    if (open && loaded) {
      setInitialValues({
        corporate_id: filter.corporate_id || "",
        companies: filter.companies ? filter.companies.split(",") : [],
        subsidiaries: subsidiaries.length > 0 ? filter.subsidiaries : "",
        warehouses: filter.warehouses ? filter.warehouses.split(",") : [],
        vehicles: filter.vehicles ? filter.vehicles.split(",") : [],
        movement: filter.movement || "BOTH",
        depth_condition: filter.depth_condition || "",
        projection_day: filter.projection_day || "",
      });
    } else {
      resetFormik();
    }
  }, [open, loaded, subsidiaries]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.movement) {
        params.movement = values.movement;
        const movement = movements.find(
          (movement) => movement.value == values.movement
        );
        setSelectedFilters({ movement: movement.label });
      }

      if (values.corporate_id) {
        params.corporate_id = values.corporate_id;
        const corporate = corporates.find(
          (corporate) => corporate.corporate_id == values.corporate_id
        );
        setSelectedFilters({ corporate: corporate.name });
      }

      if (values.companies.length > 0) {
        params.companies = values.companies.join();
        const selectedCompanies = companies.filter((company) =>
          values.companies.includes(company.company_id.toString())
        );
        setSelectedFilters({
          companies: arrayJoin(
            selectedCompanies.map((company) => company.name),
            ",",
            t("_general.text.and")
          ),
        });
      } else {
        params.companies = null;
        setSelectedFilters({
          companies: "",
        });
      }

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries;
        const subsidiary = subsidiaries.find(
          (subsidiary) => subsidiary.subsidiary_id == values.subsidiaries
        );
        setSelectedFilters({
          subsidiaries: subsidiary.name,
        });
      }

      if (values.warehouses.length > 0) {
        params.warehouses = values.warehouses.join();

        const foundWarehouses = warehouses.filter((warehouse) =>
          values.warehouses.includes(warehouse.warehouse_id.toString())
        );

        setSelectedFilters({
          warehouses: arrayJoin(
            foundWarehouses.map((warehouse) => warehouse.name),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      }

      if (values.vehicles.length > 0) {
        params.vehicles = values.vehicles.join();

        const foundVehicles = vehicles.filter((vehicle) =>
          values.vehicles.includes(vehicle.vehicle_id.toString())
        );

        setSelectedFilters({
          vehicles: arrayJoin(
            foundVehicles.map((vehicle) => vehicle.economic_number),
            ", ",
            ` ${t("_general.text.and")} `
          ),
        });
      }

      if (values.depth_condition) {
        params.depth_condition = values.depth_condition;
        const condition = conditions.find(
          (condition) => condition.value == values.depth_condition
        );
        setSelectedFilters({ depth_condition: condition.label });
      }

      if (
        values.depth_condition == "SCHEDULED WITHDRAWAL" &&
        values.projection_day != "none" &&
        values.projection_day != ""
      ) {
        params.projection_day = values.projection_day;
        setSelectedFilters({
          projection_day: `${values.projection_day * 30} ${t("_labels.days")}`,
        });
      } else {
        delete params.projection_day;
        setSelectedFilters({ projection_day: "" });
      }

      await setPurchaseFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  function handleClean() {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const subsidiaries = getSubsidiaryViaWorkArea(account, workArea);
    const filter = {
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary_id)
        .join(),
      depth_condition: "CRITICAL WITHDRAWAL",
    };

    const selectedFilters = {
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
      subsidiaries: subsidiaries
        .map((subsidiary) => subsidiary.subsidiary.name)
        .join(),
      depth_condition: t(`_labels.critical_withdrawal`),
    };
    setPurchaseFilters(filter);
    setSelectedFilters(selectedFilters);
    onClose();
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      corporates={corporates}
      companies={companies}
      subsidiaries={subsidiaries}
      warehouses={warehouses}
      vehicles={vehicles}
      movements={movements}
      conditions={conditions}
      setCompanies={setCompanies}
      setSubsidiaries={setSubsidiaries}
      setWarehouses={setWarehouses}
      setVehicles={setVehicles}
      t={t}
    />
  );
}

FilterPurchaseForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.Purchase.filter,
  account: state.Account.account,
  workArea: state.Account.workArea,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  setPurchaseFilters: actionSetPurchaseFilters(dispatch),
  clearPurchaseFilters: actionClearPurchaseFilters(dispatch),
  getCorporates: actionGetCorporates(dispatch),
  setSelectedFilters: actionSetSelectedPurchaseFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPurchaseForm);
