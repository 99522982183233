export default {
  link_subsidiary: "Vincular sucursal",
  label: "Nombre de la sucursal",
  permits: {
    link: {
      warehouse_subsidiary:
        "Sin permisos para vincular sucursales con almacenes",
    },
    unlink: {
      warehouse_subsidiary:
        "Sin permisos para desvincular sucursales de almacenes",
    },
    show: {
      warehouse_subsidiary: "Sin permisos para ver sucursales de almacenes",
    },
  },
  messages: {
    unlinked_warehouse_subsidiary:
      "Listo, la sucursal se ha desvinculado del almacén con éxito",
    linked_warehouse_subsidiary:
      "Listo, la sucursal se ha vinculado al almacén con éxito",
  },
};
