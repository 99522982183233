import {
  GET_TIRE_WAREHOUSE,
  CLEAR_TIRE_WAREHOUSE,
  ASSIGN_TIRE_TO_WAREHOUSE,
  DELETE_LINK_TIRE_WITH_WAREHOUSE,
} from "store/actions";

const initialState = {
  warehouse: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_WAREHOUSE:
    case ASSIGN_TIRE_TO_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };

    case CLEAR_TIRE_WAREHOUSE:
    case DELETE_LINK_TIRE_WITH_WAREHOUSE:
      return {
        ...state,
        warehouse: initialState.warehouse,
      };

    default:
      return state;
  }
}
