import React from "react";
import { connect } from "react-redux";

import Page from "./page";

export function TireAlertTab() {
  return <Page />;
}

TireAlertTab.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TireAlertTab);
