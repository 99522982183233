import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import ContainerTemplate from "components/templates/general/Container";
import FilterForm from "components/organisms/general/FilterForm";
import DamageDialog from "components/organisms/tire/damage/DamageDialog";
import Pagination from "components/molecules/general/Pagination";

import css from "./index.module.scss";
import Alerts from "./Alerts";
import ReviewInfoTable from "./ReviewInfoTable/index";
import TirePerformanceTable from "./TirePerformanceTable/index";
import TirePerformanceChasisTable from "./TirePerformanceChasisTable/index";

function Page({
  t,
  vehicleReview,
  vehicleTypeAxles,
  onChangePage,
  filter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  setVehicleReviewFilters,
  clearVehicleReviewFilters,
  damages,
  openDamageDialog,
  handleCloseDamageDialog,
  reviewAlerts,
  openAlertDialog,
  handleCloseAlertDialog,
  savePdf,
  companyName,
  tires,
}) {
  const review = vehicleReview.data[0];

  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: vehicleReview.data.length === 0,
      })}
      title={`${t("_titles.tire_performance")}`}
      options={
        <Button
          onClick={handleOpenFilter}
          classNameButton={classnames(css.filter)}
          variant="icon"
        >
          <FilterListIcon />
        </Button>
      }
    >
      {vehicleReview.data.length > 0 ? (
        <>
          <div
            className={classnames(css.container)}
            style={{
              fontFamily: "roboto",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Button
              style={{
                display: "block",
              }}
              color="secondary"
              variant="contained"
              onClick={() => {
                savePdf();
              }}
            >
              Descargar PDF
            </Button>
            <ReviewInfoTable
              t={t}
              companyName={companyName}
              economicNumber={review.vehicle.economic_number}
              reviewType={review.review_type}
              isFinished={!!review.end_time}
              date={review.date}
              vehicleKm={review.odometer}
              userName={`${review.created_by.name} ${
                review.created_by.last_name_1
              } ${review.created_by.last_name_2 || ""}`}
              driverName={review.vehicle.vehicle_driver?.name ?? "-"}
              comment={review.observation ?? "-"}
            />
          </div>
          <div
            className={classnames(css.container_two)}
            style={{ fontFamily: "roboto" }}
          >
            <TirePerformanceTable t={t} tires={tires} />
          </div>
          <div
            className={classnames(css.container_two)}
            style={{ fontFamily: "roboto" }}
          >
            <TirePerformanceChasisTable
              t={t}
              tires={tires}
              vehicleTypeAxles={vehicleTypeAxles}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={vehicleReview.current_page}
              totalPages={vehicleReview.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_tires")} />
      )}

      <FilterForm
        open={openFilter}
        filter={filter}
        setFiltersAction={setVehicleReviewFilters}
        clearFiltersAction={clearVehicleReviewFilters}
        onClose={handleCloseFilter}
        fields={{
          date: true,
          review_type: true,
        }}
        initialDefaultValues={{
          date_from: "",
          date_to: "",
          review_type: [],
        }}
      />

      <DamageDialog
        open={openDamageDialog}
        onClose={handleCloseDamageDialog}
        damages={damages}
      />

      <Alerts
        open={openAlertDialog}
        onClose={handleCloseAlertDialog}
        alerts={reviewAlerts}
      />
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleReview: PropTypes.object.isRequired,
  vehicleTypeAxles: PropTypes.array.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  filter: PropTypes.object,
  openFilter: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  setVehicleReviewFilters: PropTypes.func.isRequired,
  clearVehicleReviewFilters: PropTypes.func.isRequired,
  openDamageDialog: PropTypes.bool.isRequired,
  damages: PropTypes.array.isRequired,
  handleOpenDamageDialog: PropTypes.func.isRequired,
  handleCloseDamageDialog: PropTypes.func.isRequired,
  reviewAlerts: PropTypes.array,
  openAlertDialog: PropTypes.bool,
  handleCloseAlertDialog: PropTypes.func.isRequired,
  savePdf: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  tires: PropTypes.array.isRequired,
};

export default Page;
