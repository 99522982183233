import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

import Container from "components/templates/general/Container";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FixedButton from "components/molecules/general/FixedButton";
import Pagination from "components/molecules/general/Pagination";
import SearchInput from "components/molecules/general/SearchInput";
import BlockForm from "components/organisms/block/BlockForm";

import css from "./index.module.scss";
function Page({
  t,
  blocks,
  blockId,
  search,
  openForm,
  handleSearch,
  handleChangePage,
  handleOpenForm,
  handleCloseForm,
  handleEdit,
  handleDelete,
  createBlockPermit,
  updateBlockPermit,
  deleteBlockPermit,
}) {
  return (
    <Container
      title={t("_general.blocks")}
      className={classnames({
        [css.empty]: blocks.data.length === 0,
      })}
      options={<SearchInput search={search ?? ""} onChange={handleSearch} />}
    >
      {blocks.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th className="pl-4">{t("_labels.name.singular")}</th>
                    <th>{t("_labels.description")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {blocks.data.map((block) => (
                    <tr key={block.block_id}>
                      <td className="pl-4">{block.name}</td>
                      <td>{block.description}</td>
                      <td>
                        <div
                          className="d-flex justify-content-start"
                          style={{ gap: "6px" }}
                        >
                          <Tooltip
                            title={
                              !updateBlockPermit
                                ? t("_permits.update.block")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateBlockPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() => handleEdit(block.block_id)}
                                disabled={!updateBlockPermit}
                              >
                                <EditIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>

                          <Tooltip
                            title={
                              !deleteBlockPermit
                                ? t("_permits.delete.block")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-danger d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !deleteBlockPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() => handleDelete(block.block_id)}
                                disabled={!deleteBlockPermit}
                              >
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={blocks.current_page}
              totalPages={blocks.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_blocks")} />
      )}

      <FixedButton
        color="primary"
        onClick={handleOpenForm}
        disabled={!createBlockPermit}
        tooltip={!createBlockPermit ? t("_permits.create.block") : ""}
      />

      <BlockForm open={openForm} onClose={handleCloseForm} blockId={blockId} />
    </Container>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  blocks: PropTypes.object.isRequired,
  blockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openForm: PropTypes.bool.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  createBlockPermit: PropTypes.bool.isRequired,
  updateBlockPermit: PropTypes.bool.isRequired,
  deleteBlockPermit: PropTypes.bool.isRequired,
};

export default Page;
