import { http } from "store/actions/http";
import { ASSIGN_TIRE_TO_WAREHOUSE } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionAssignTireToWarehouse =
  (dispatch) =>
  async ({ tireId, warehouseId }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "POST",
        path: `warehouse/${warehouseId}/tire/${tireId}`,
      });

      dispatch({
        type: ASSIGN_TIRE_TO_WAREHOUSE,
        payload: response,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
