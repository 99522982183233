import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";

import MenuItem from "@material-ui/core/MenuItem";
import SelectField from "components/molecules/general/SelectField";
import Button from "components/molecules/general/Button";
import DialogActions from "@material-ui/core/DialogActions";

import { alphabeticalSort } from "utils/sort";
import css from "./index.module.scss";

function Page({
  t,
  values,
  subsidiaries,
  companies,
  corporates,
  isValid,
  isUpdate,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      {!isUpdate && (
        <>
          <Field
            required
            variant="filled"
            margin="normal"
            name="corporate_id"
            className={classnames(css.field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            defaultText={t("_labels.corporate_field.singular.value")}
            defaultValue={
              corporates.some(
                (corporate) => corporate.corporate_id === values.corporate_id
              )
                ? null
                : values.corporate_id
            }
            component={SelectField}
            label={t("_labels.corporate_field.singular.label")}
          >
            {corporates.map((corporate, index) => {
              return (
                <MenuItem
                  key={index}
                  value={corporate.corporate_id.toString()}
                  disabled={!corporate.status}
                >
                  {corporate.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            required
            variant="filled"
            margin="normal"
            name="company_id"
            className={classnames(css.field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            defaultText={t("_labels.company_field.singular.value")}
            defaultValue={
              companies.some(
                (company) => company.company_id === values.company_id
              )
                ? null
                : values.company_id
            }
            component={SelectField}
            label={t("_labels.company_field.singular.label")}
          >
            {companies.map((company, index) => {
              return (
                <MenuItem
                  key={index}
                  value={company.company_id.toString()}
                  disabled={!company.status}
                >
                  {company.name}
                </MenuItem>
              );
            })}
          </Field>
        </>
      )}

      <div className={classnames(css.field_wrap)}>
        <div className={classnames(css.horizontal)}>
          <Field
            required
            variant="filled"
            margin="normal"
            name="subsidiary_id"
            disabled={isUpdate}
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            defaultText={t("_labels.subsidiary_field.singular.value")}
            defaultValue={
              subsidiaries.some(
                (subsidiary) =>
                  subsidiary.subsidiary_id === values.subsidiary_id
              )
                ? null
                : values.subsidiary_id
            }
            component={SelectField}
            label={t("_labels.subsidiary_field.singular.label")}
          >
            {subsidiaries.map((subsidiary, index) => {
              return (
                <MenuItem
                  key={index}
                  value={subsidiary.subsidiary_id.toString()}
                  disabled={!subsidiary.status}
                >
                  {subsidiary.name}
                </MenuItem>
              );
            })}
          </Field>

          <Field
            required
            variant="filled"
            margin="normal"
            name="role"
            className={classnames(css.medium_field)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
            component={SelectField}
            label={t("_general.role.label")}
          >
            {[
              // {
              //   value: "ROOT",
              //   name: t("_general.role.root"),
              // },
              {
                value: "MANAGER",
                name: t("_general.role.manager"),
              },
              // {
              //   value: "CHIEF_OPERATIONS",
              //   name: t("_general.role.chief_operations"),
              // },
              {
                value: "OPERATOR",
                name: t("_general.role.operator"),
              },
              {
                value: "WAREHOUSE",
                name: t("_general.role.warehouse"),
              },
              {
                value: "REPORTS",
                name: t("_general.role.reports"),
              },
              // {
              //   value: "CHECKLIST_OPERATOR",
              //   name: t("_general.role.checklist_operator"),
              // },
              {
                value: "DISTRIBUTOR",
                name: t("_general.role.distributor"),
              },
              {
                value: "TPMS",
                name: t("_general.tpms"),
              },
            ]
              .sort(alphabeticalSort("name"))
              .map((role, index) => (
                <MenuItem key={index} value={role.value}>
                  {role.name}
                </MenuItem>
              ))}
          </Field>
        </div>
      </div>

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  companies: PropTypes.array.isRequired,
  corporates: PropTypes.array.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
