import { useLang } from "hooks/lang";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetAllNotifications } from "store/actions/notification/getAllNotifications";
import { actionDeleteNotification } from "store/actions/notification/deleteNotification";
import { actionDeleteAllNotification } from "store/actions/notification/deleteAllNotification";
import { actionSetNotificationFilters } from "store/actions/notification/setNotificationFilters";
import { actionClearNotificationFilters } from "store/actions/notification/clearNotificationFilters";

import Page from "./page";

const perPage = 4;

export function HomeNotification({ ...rest }) {
  const t = useLang();
  const {
    filter,
    notifications,
    getNotifications,
    setNotification,
    deleteNotification,
    deleteAllNotification,
    setNotificationFilters,
    clearNotificationFilters,
  } = rest;

  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getNotifications({ ...filter, page: 1, per_page: perPage });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  async function handleChangePage(page) {
    try {
      await getNotifications({ ...filter, page, per_page: perPage });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDelete(id) {
    try {
      const response = await deleteNotification(id);
      setNotification({ message: response.message });
      getNotifications({ ...filter, page: 1, per_page: perPage });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleDeleteAll() {
    try {
      const response = await deleteAllNotification();
      setNotification({ message: response.message });
      getNotifications({ ...filter, page: 1, per_page: perPage });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }
  return (
    <Page
      t={t}
      filter={filter}
      notifications={notifications}
      openFilter={openFilter}
      handleChangePage={handleChangePage}
      handleDelete={handleDelete}
      handleDeleteAll={handleDeleteAll}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      setNotificationFilters={setNotificationFilters}
      clearNotificationFilters={clearNotificationFilters}
    />
  );
}

HomeNotification.propTypes = {};

const mapStateToProps = (state) => ({
  notifications: state.UserNotifications.all_notifications,
  filter: state.UserNotifications.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getNotifications: actionGetAllNotifications(dispatch),
  deleteNotification: actionDeleteNotification(dispatch),
  deleteAllNotification: actionDeleteAllNotification(dispatch),
  setNotificationFilters: actionSetNotificationFilters(dispatch),
  clearNotificationFilters: actionClearNotificationFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeNotification);
