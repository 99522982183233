import { DELETE_HELMET_VALUE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteHelmetValue =
  (dispatch) => async (subsidiaryId, helmetValueId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: `DELETE`,
        path: `subsidiary/${subsidiaryId}/helmet/value/tire/model/variation/${helmetValueId}`,
      });
      dispatch({
        type: DELETE_HELMET_VALUE,
        payload: helmetValueId,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
