import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import css from "./index.module.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField2 from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  paper: {
    minWidth: "100%",
    width: "max-content",
  },
});

function Page({
  className,
  t,
  area,
  subsidiaries,
  handleChange,
  inputValue,
  setInputValue,
}) {
  const classes = useStyles();
  return (
    <div className={classnames(css.container, className)}>
      <FormControl fullWidth variant="outlined">
        {/*<InputLabel id="select-language"></InputLabel>*/}
        <Autocomplete
          id="select-language"
          name="select-language"
          classes={classes}
          blurOnSelect
          options={subsidiaries}
          onOpen={() => {
            setInputValue("");
          }}
          getOptionLabel={(option) => option.name ?? ""}
          getOptionDisabled={(option) => !option.status}
          getOptionSelected={(option, value) => option.key === value}
          onChange={handleChange}
          value={area}
          inputValue={inputValue}
          onInputChange={(_event, value) => setInputValue(value)}
          disableClearable={true}
          defaultValue={null}
          noOptionsText={t("_labels.autocomplete.no_options")}
          openText={t("_labels.autocomplete.open")}
          clearText={t("_labels.autocomplete.clear")}
          closeText={t("_labels.autocomplete.close")}
          className={classnames("MuiFormControl-root", css.full_width)}
          renderOption={(option) => {
            return (
              <Typography noWrap display="block">
                {option.name}
                <Typography
                  noWrap
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {option.roles}
                </Typography>
              </Typography>
            );
          }}
          renderInput={(params) => (
            <TextField2
              {...params}
              required
              label={t("name")}
              variant="filled"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

Page.propTypes = {
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  area: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  areas: PropTypes.object,
  subsidiaries: PropTypes.array,
  className: PropTypes.string,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
};

export default Page;
