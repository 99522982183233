import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import Button from "components/molecules/general/Button";

import noImage from "resources/images/noImage.svg";
import css from "./index.module.scss";

function Page({ t, open, title, image, onClose }) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"sm"} open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classnames(css.flex, css.content_info)}>
          <img
            className={classnames(css.img)}
            src={
              !image ? noImage : `${process.env.REACT_APP_IMAGES_URL}${image}`
            }
            alt={image}
          />
        </div>
        <div className={classnames(css.action)}>
          <Button color="secondary" onClick={onClose}>
            {t("_buttons.close")}
          </Button>
        </div>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Page;
