export default {
  title: "Linked drivers",
  form_title: "Link driver",
  permits: {
    unlink: {
      subsidiary_driver: "No permissions to unlink drivers from subsidiaries",
    },
  },
  messages: {
    delete: "Are you sure to unlink driver?",
    delete_subsidiary_driver:
      "Done, the driver has been unlinked from the subsidiary",
    attached_subsidiary_driver: "Done, the driver has been successfully linked",
  },
};
