export default {
  disabled_tire: "Neumático deshabilitado",
  permits: {
    change_status: "Sin permisos para {action} neumáticos",
  },
  messages: {
    disable: "¿Estas seguro de deshabilitar el neumático?",
    enable: "¿Estas seguro de habilitar el neumático?",
  },
};
