import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment-timezone";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import ContainerTemplate from "components/templates/general/Container";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import SizeTable from "./SizeTable";
import FilterPurchaseForm from "components/organisms/report/FilterPurchaseForm";

import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  amount,
  detailsData,
  modelsOriginal,
  modelsRevitalized,
  actualPurchase,
  actualPurchaseRevitalized,
  replacementData,
  replacementRevitalizedData,
  summaryData,
  setSummaryData,
  estimatedData,
  filter,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  saveRef,
  savePdf,
  data,
}) {
  useEffect(() => {
    Array.from(document.querySelectorAll("[data-id]")).forEach((element) => {
      element.classList.add("collapse");
    });
  }, [filter]);

  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }
  return (
    <ContainerTemplate
      title={t("_titles.purchase_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).length > 0 && (
          <>
            <div className="row px24 pt-3 pb-2">
              <div className={classnames(css.filter_wrap)}>
                {filter.corporate_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.corporate.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.corporate}
                    </p>
                  </div>
                )}
                {filter.companies && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.company.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.companies}
                    </p>
                  </div>
                )}
                {filter.subsidiaries && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.subsidiary.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.subsidiaries}
                    </p>
                  </div>
                )}
                {filter.movement && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.location.label")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.movement}
                    </p>
                  </div>
                )}
                {filter.vehicle_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.vehicle.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.vehicle}
                    </p>
                  </div>
                )}

                {filter.warehouse_id && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.warehouse.singular")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.warehouse}
                    </p>
                  </div>
                )}

                {filter.depth_condition && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.condition")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.depth_condition}
                    </p>
                  </div>
                )}
                {filter.projection_day && filter.projection_day != "none" && (
                  <div className={classnames(css.filter_label, "shadow")}>
                    <p className={classnames(css.label)}>
                      {t("_labels.projection_day")}
                    </p>
                    <p className={classnames(css.value)}>
                      {selectedFilter.projection_day}
                    </p>
                  </div>
                )}
                {filter.date_from && filter.date_to && (
                  <>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.from")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_from).format("LL")}
                      </p>
                    </div>
                    <div className={classnames(css.filter_label, "shadow")}>
                      <p className={classnames(css.label)}>
                        {t("_labels.date.to")}
                      </p>
                      <p className={classnames(css.value)}>
                        {moment(selectedFilter.date_to).format("LL")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="row pt-1">
          <div className="col-md-12 text-center">
            <hr className="p-0 mx-0 my-1" />
            <h1 className="sinmp">{t("_titles.purchase_report")}</h1>
            <p className="h5 m-1 px-4">
              El reporte muestra las sugerencias de compra con base en los
              precios registrados del mismo modelo.
            </p>
            <p>
              Estas recomendaciones se realizan sobre los neumaticos cuya
              condición actual de profundidad es retiro critico o retiro
              programado.
            </p>
            <hr className="p-0 mx-0 my-1" />
          </div>
        </div>
        <div className="row justify-content-between px-4 mt-2">
          {estimatedData.original && estimatedData.revitalized && (
            <div className="col-md-6">
              <p className="m-0">&nbsp;</p>
              <div className="card">
                <div className="card-header sMarcoInfoFondoGris">
                  {t("_labels.suggested")}
                </div>
                <div className="card-content">
                  <table className="table sFS12 m-0">
                    <thead>
                      <tr
                        style={{ backgroundColor: "#002849", color: "white" }}
                      >
                        <td />
                        <td width={90} className="text-center">
                          {t("_labels.quantity")}
                        </td>
                        <td width={90} className="text-center"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="sbngris font-weight-bold">
                        <td>{t("_labels.originals")}</td>
                        <td className="text-center">
                          {formatter.format(estimatedData.original.quantity)}
                        </td>
                        <td className="text-center">
                          ${formatter.format(estimatedData.original.total)}
                        </td>
                      </tr>
                      {Object.keys(estimatedData.original)
                        .filter(
                          (key) => !["price", "quantity", "total"].includes(key)
                        )
                        .map((application) => (
                          <tr key={application}>
                            <td className="px-4">
                              {t(
                                `_labels.tire_application.options.${application.toLowerCase()}`
                              )}
                            </td>
                            <td className="text-center">
                              {formatter.format(
                                estimatedData.original[application].quantity
                              )}
                            </td>
                            <td className="text-center">
                              $
                              {formatter.format(
                                estimatedData.original[application].total
                              )}
                            </td>
                          </tr>
                        ))}
                      <tr className="sbngris font-weight-bold">
                        <td>{t("_labels.revitalized")}</td>
                        <td className="text-center">
                          {formatter.format(estimatedData.revitalized.quantity)}
                        </td>
                        <td className="text-center">
                          ${formatter.format(estimatedData.revitalized.total)}
                        </td>
                      </tr>
                      {Object.keys(estimatedData.revitalized)
                        .filter(
                          (key) => !["price", "quantity", "total"].includes(key)
                        )
                        .map((application) => (
                          <tr key={application}>
                            <td className="px-4">
                              {t(
                                `_labels.tire_application.options.${application.toLowerCase()}`
                              )}
                            </td>
                            <td className="text-center">
                              {formatter.format(
                                estimatedData.revitalized[application].quantity
                              )}
                            </td>
                            <td className="text-center">
                              $
                              {formatter.format(
                                estimatedData.revitalized[application].total
                              )}
                            </td>
                          </tr>
                        ))}

                      <tr className="sbngris">
                        <th>Total</th>
                        <th className="text-center">
                          {formatter.format(
                            estimatedData.original.quantity +
                              estimatedData.revitalized.quantity
                          )}
                        </th>
                        <th className="text-center">
                          $
                          {formatter.format(
                            estimatedData.original.total +
                              estimatedData.revitalized.total
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {summaryData.original && summaryData.revitalized && (
            <>
              <div className="col-md-6">
                <p className="m-0" style={{ fontSize: "0.9rem" }}>
                  <b>Calculado*</b> Ya se descuentan los neumáticos (originales
                  nuevos) disponibles en el almacén
                </p>
                <div className="card">
                  <div className="card-header sMarcoInfoFondoAzul">
                    {t("_labels.calculated")}
                  </div>
                  <div className="card-content">
                    <table className="table sFS12 m-0">
                      <thead>
                        <tr
                          style={{ backgroundColor: "#002849", color: "white" }}
                        >
                          <td />
                          <td width={90} className="text-center">
                            {t("_labels.quantity")}
                          </td>
                          <td width={90} className="text-center"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="sbngris font-weight-bold">
                          <td>{t("_labels.originals")}</td>
                          <td className="text-center">
                            {formatter.format(summaryData.original.quantity)}
                          </td>
                          <td className="text-center">
                            ${formatter.format(summaryData.original.total)}
                          </td>
                        </tr>
                        {Object.keys(summaryData.original)
                          .filter(
                            (key) =>
                              !["price", "quantity", "total"].includes(key)
                          )
                          .map((application) => (
                            <tr key={application}>
                              <td className="px-4">
                                {t(
                                  `_labels.tire_application.options.${application.toLowerCase()}`
                                )}
                              </td>
                              <td className="text-center">
                                {formatter.format(
                                  summaryData.original[application].quantity
                                )}
                              </td>
                              <td className="text-center">
                                $
                                {formatter.format(
                                  summaryData.original[application].total
                                )}
                              </td>
                            </tr>
                          ))}
                        <tr className="sbngris font-weight-bold">
                          <td>{t("_labels.revitalized")}</td>
                          <td className="text-center">
                            {formatter.format(summaryData.revitalized.quantity)}
                          </td>
                          <td className="text-center">
                            ${formatter.format(summaryData.revitalized.total)}
                          </td>
                        </tr>
                        {Object.keys(summaryData.revitalized)
                          .filter(
                            (key) =>
                              !["price", "quantity", "total"].includes(key)
                          )
                          .map((application) => (
                            <tr key={application}>
                              <td className="px-4">
                                {t(
                                  `_labels.tire_application.options.${application.toLowerCase()}`
                                )}
                              </td>
                              <td className="text-center">
                                {formatter.format(
                                  summaryData.revitalized[application].quantity
                                )}
                              </td>
                              <td className="text-center">
                                $
                                {formatter.format(
                                  summaryData.revitalized[application].total
                                )}
                              </td>
                            </tr>
                          ))}

                        <tr className="sbngris">
                          <th>Total</th>
                          <th className="text-center">
                            {formatter.format(
                              summaryData.original.quantity +
                                summaryData.revitalized.quantity
                            )}
                          </th>
                          <th className="text-center">
                            $
                            {formatter.format(
                              summaryData.original.total +
                                summaryData.revitalized.total
                            )}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row px-4" key={JSON.stringify(data)}>
          <div className="col-md-6">
            {Object.keys(modelsOriginal).length > 0 && (
              <>
                <div className="sMarcoInfo sbgazul mb-1">
                  <h3 className="sfcblanco m-0">{t("_labels.originals")}</h3>
                </div>
                <div className="d-flex mb-1" style={{ gap: "6px" }}>
                  {Object.keys(modelsOriginal)
                    .sort()
                    .map((application) => (
                      <button
                        key={application}
                        className="btn btn-dark d-flex flex-wrap justify-content-center align-items-baseline"
                        style={{ gap: "4px" }}
                        onClick={() => toggleByClass("originales", application)}
                      >
                        {t(
                          `_labels.tire_application.options.${application.toLowerCase()}`
                        )}
                        {summaryData.original &&
                          summaryData.original[application] && (
                            <span className="badge badge-primary">
                              $
                              {formatter.format(
                                summaryData.original[application].total
                              )}
                            </span>
                          )}
                      </button>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className="col-md-6">
            {Object.keys(modelsRevitalized).length > 0 && (
              <>
                <div className="sMarcoInfo sbgverde mb-1">
                  <h3 className="sfcblanco m-0">{t("_labels.revitalized")}</h3>
                </div>
                <div className="d-flex mb-1" style={{ gap: "6px" }}>
                  {Object.keys(modelsRevitalized)
                    .sort()
                    .map((application) => (
                      <button
                        key={application}
                        className="btn btn-dark d-flex justify-content-center align-items-baseline"
                        style={{ gap: "4px" }}
                        onClick={() =>
                          toggleByClass("revitalizados", application)
                        }
                      >
                        {t(
                          `_labels.tire_application.options.${application.toLowerCase()}`
                        )}
                        {summaryData.revitalized &&
                          summaryData.revitalized[application] && (
                            <span className="badge badge-primary">
                              $
                              {formatter.format(
                                summaryData.revitalized[application].total
                              )}
                            </span>
                          )}
                      </button>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 mt-1 mb-2">
            <div className="row align-items-baseline">
              <div
                className="col-md-6 d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    savePdf();
                  }}
                  disabled={amount <= 0}
                  tooltip={
                    amount > 0
                      ? `${amount} medida(s) guardadas`
                      : "Sin elementos guardados"
                  }
                >
                  Descargar PDF {amount > 0 ? amount : ""}
                </Button>

                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{ gap: "6px" }}
                >
                  <div
                    style={{ backgroundColor: "#e4eff7", fontSize: "0.85rem" }}
                    className="p-1 border border-secondary rounded"
                  >
                    {t("_labels.model")}
                  </div>
                  <p className="m-0" style={{ fontSize: "0.87rem" }}>
                    {" "}
                    Costo por km proyectado
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 d-flex justify-content-start align-items-center"
                style={{ gap: "6px" }}
              >
                <div
                  style={{ backgroundColor: "white", fontSize: "0.85rem" }}
                  className="p-1 border border-secondary rounded"
                >
                  {t("_labels.model")}
                </div>
                <p className="m-0" style={{ fontSize: "0.87rem" }}>
                  {" "}
                  Costo por km de neumáticos en pila
                </p>
              </div>
            </div>
          </div>
          <div className="originales col-md-12">
            {Object.keys(modelsOriginal).map((application) =>
              Object.keys(modelsOriginal[application]).map((size, index) => (
                <SizeTable
                  key={`${application}_${size}_original_${index}`}
                  original={true}
                  actualPurchase={actualPurchase}
                  replacementData={replacementData}
                  size={size}
                  application={application}
                  data={modelsOriginal[application][size]}
                  setSummaryData={setSummaryData}
                  saveRef={(ref) => saveRef("original", application, size, ref)}
                />
              ))
            )}
          </div>

          <div className="revitalizados box col-md-12">
            {Object.keys(modelsRevitalized).map((application) =>
              Object.keys(modelsRevitalized[application]).map((size, index) => (
                <SizeTable
                  key={`${application}_${size}_revitalized_${index}`}
                  original={false}
                  actualPurchase={actualPurchaseRevitalized}
                  replacementData={replacementRevitalizedData}
                  size={size}
                  application={application}
                  data={modelsRevitalized[application][size]}
                  setSummaryData={setSummaryData}
                  saveRef={(ref) =>
                    saveRef("revitalized", application, size, ref)
                  }
                />
              ))
            )}
          </div>
        </div>

        {Object.keys(detailsData).length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <h4 className="text-center">
                        Detalle de neumáticos en punto de retiro crítico
                      </h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0 p-0">
                          <b>*Costo originales</b>: El costo de reponer el total
                          de neumaticos con originales
                        </p>
                        <p className="m-0 p-0">
                          <b>*Costo revitalizados</b>: El costo de reponer el
                          total de neumaticos con revitalizados
                        </p>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-condensed">
                          {Object.entries(detailsData).map((corporate) => (
                            <Fragment key={corporate[0]}>
                              <thead className="text-center">
                                <tr
                                  className="corporate"
                                  onClick={() =>
                                    toggleTable(
                                      `${corporate[0]}_companies`,
                                      corporate[0],
                                      "depth"
                                    )
                                  }
                                >
                                  <th className="text-left">
                                    {corporate[0] === "NO CORPORATE"
                                      ? t("_labels.no_corporate")
                                      : corporate[0]}
                                  </th>
                                  <th className="text-right col1" />
                                  <th className="text-right col3" />
                                  <th className="text-right col3" />
                                  <th className="text-right col4">
                                    <small>{t("_general.tires")}</small>
                                    <br />
                                    {formatter.format(
                                      corporate[1]["statistics"]
                                    )}
                                  </th>
                                  <th className="text-right col5">
                                    <small>{t("_labels.original_cost")}</small>
                                    <br />$
                                    {formatter.format(
                                      corporate[1]["costOriginal"]
                                    )}
                                  </th>
                                  <th className="text-right col6">
                                    <small>
                                      {t("_labels.revitalized_cost")}
                                    </small>
                                    <br />$
                                    {formatter.format(
                                      corporate[1]["costRevitalized"]
                                    )}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.keys(corporate[1])
                                  .filter(
                                    (index) =>
                                      ![
                                        "statistics",
                                        "costOriginal",
                                        "costRevitalized",
                                      ].includes(index)
                                  )
                                  .map((company) => (
                                    <Fragment key={company}>
                                      <tr
                                        data-type="depth"
                                        data-id={removeCharacters(
                                          `${corporate[0]}_companies`
                                        )}
                                        className="company box collapse"
                                        onClick={() =>
                                          toggleTable(
                                            `${corporate[0]}_${company}_subsidiaries`,
                                            company,
                                            "depth"
                                          )
                                        }
                                      >
                                        <td className="text-left">{company}</td>
                                        <td className="text-right col1"> </td>
                                        <td className="text-right col3" />
                                        <td className="text-right col3" />
                                        <td className="text-right col4">
                                          {formatter.format(
                                            corporate[1][company]["statistics"]
                                          )}
                                        </td>
                                        <td className="text-right col5">
                                          $
                                          {formatter.format(
                                            corporate[1][company][
                                              "costOriginal"
                                            ]
                                          )}
                                        </td>
                                        <td className="text-right col6">
                                          $
                                          {formatter.format(
                                            corporate[1][company][
                                              "costRevitalized"
                                            ]
                                          )}
                                        </td>
                                      </tr>

                                      {Object.keys(corporate[1][company])
                                        .filter(
                                          (index) =>
                                            ![
                                              "statistics",
                                              "costOriginal",
                                              "costRevitalized",
                                            ].includes(index)
                                        )
                                        .map((subsidiary) => (
                                          <Fragment key={subsidiary}>
                                            <tr
                                              data-type="depth"
                                              data-id={removeCharacters(
                                                `${corporate[0]}_${company}_subsidiaries`
                                              )}
                                              className="subsidiary box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${corporate[0]}_${company}_${subsidiary}_divisions`,
                                                  `${company}_${subsidiary}`,
                                                  "depth"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {subsidiary}
                                              </td>
                                              <td className="text-right col1">
                                                {" "}
                                              </td>
                                              <td className="text-right col3" />
                                              <td className="text-right col3" />
                                              <td className="text-right col4">
                                                {formatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["statistics"]
                                                )}
                                              </td>
                                              <td className="text-right col5">
                                                $
                                                {formatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["costOriginal"]
                                                )}
                                              </td>
                                              <td className="text-right col6">
                                                $
                                                {formatter.format(
                                                  corporate[1][company][
                                                    subsidiary
                                                  ]["costRevitalized"]
                                                )}
                                              </td>
                                            </tr>

                                            {Object.keys(
                                              corporate[1][company][subsidiary]
                                            )
                                              .filter(
                                                (index) =>
                                                  ![
                                                    "statistics",
                                                    "costOriginal",
                                                    "costRevitalized",
                                                  ].includes(index)
                                              )
                                              .map((division) => (
                                                <Fragment key={division}>
                                                  <tr
                                                    data-type="depth"
                                                    data-id={removeCharacters(
                                                      `${corporate[0]}_${company}_${subsidiary}_divisions`
                                                    )}
                                                    className="division box collapse"
                                                    onClick={() =>
                                                      toggleTable(
                                                        `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`,
                                                        `${company}_${subsidiary}_${division}`,
                                                        "depth"
                                                      )
                                                    }
                                                  >
                                                    <td className="text-left">
                                                      {division ===
                                                      "NO DIVISION"
                                                        ? t(
                                                            "_labels.no_division"
                                                          )
                                                        : division}
                                                    </td>
                                                    <td className="text-right col1">
                                                      {" "}
                                                    </td>
                                                    <td className="text-right col3" />
                                                    <td className="text-right col3" />
                                                    <td className="text-right col4">
                                                      {formatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "statistics"
                                                        ]
                                                      )}
                                                    </td>
                                                    <td className="text-right col5">
                                                      $
                                                      {formatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "costOriginal"
                                                        ]
                                                      )}
                                                    </td>
                                                    <td className="text-right col6">
                                                      $
                                                      {formatter.format(
                                                        corporate[1][company][
                                                          subsidiary
                                                        ][division][
                                                          "costRevitalized"
                                                        ]
                                                      )}
                                                    </td>
                                                  </tr>

                                                  {Object.keys(
                                                    corporate[1][company][
                                                      subsidiary
                                                    ][division]
                                                  )
                                                    .filter(
                                                      (index) =>
                                                        ![
                                                          "statistics",
                                                          "costOriginal",
                                                          "costRevitalized",
                                                        ].includes(index)
                                                    )
                                                    .map((condition) => (
                                                      <Fragment key={condition}>
                                                        <tr
                                                          data-type="depth"
                                                          data-id={removeCharacters(
                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_conditions`
                                                          )}
                                                          className={`box collapse condition ${
                                                            condition.includes(
                                                              "SCHEDULED"
                                                            )
                                                              ? "set_sem_amarillo"
                                                              : condition.includes(
                                                                  "CRITICAL"
                                                                )
                                                              ? "set_sem_rojo"
                                                              : "set_sem_verde"
                                                          }`}
                                                          onClick={() =>
                                                            toggleTable(
                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`,
                                                              `${company}_${subsidiary}_${division}_${condition}`,
                                                              "depth"
                                                            )
                                                          }
                                                        >
                                                          <td className="text-left">
                                                            <i
                                                              className={`bx bxs-circle ${
                                                                condition.includes(
                                                                  "SCHEDULED"
                                                                )
                                                                  ? "set_sem_amarillo"
                                                                  : condition.includes(
                                                                      "CRITICAL"
                                                                    )
                                                                  ? "set_sem_rojo"
                                                                  : "set_sem_verde"
                                                              }`}
                                                            />
                                                            &nbsp;
                                                            {t(
                                                              `_labels.summary_report.depth_condition.${removeCharacters(
                                                                condition,
                                                                "_"
                                                              ).toLowerCase()}`
                                                            )}
                                                          </td>
                                                          <td className="text-right col1">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col2">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col3">
                                                            {" "}
                                                          </td>
                                                          <td className="text-right col4">
                                                            {formatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "statistics"
                                                              ]
                                                            )}
                                                          </td>
                                                          <td className="text-right col5">
                                                            $
                                                            {formatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "costOriginal"
                                                              ]
                                                            )}
                                                          </td>
                                                          <td className="text-right col6">
                                                            $
                                                            {formatter.format(
                                                              corporate[1][
                                                                company
                                                              ][subsidiary][
                                                                division
                                                              ][condition][
                                                                "costRevitalized"
                                                              ]
                                                            )}
                                                          </td>
                                                        </tr>

                                                        {Object.keys(
                                                          corporate[1][company][
                                                            subsidiary
                                                          ][division][condition]
                                                        )
                                                          .filter(
                                                            (index) =>
                                                              ![
                                                                "statistics",
                                                                "costOriginal",
                                                                "costRevitalized",
                                                              ].includes(index)
                                                          )
                                                          .map(
                                                            (application) => (
                                                              <Fragment
                                                                key={
                                                                  application
                                                                }
                                                              >
                                                                <tr
                                                                  data-type="depth"
                                                                  data-id={removeCharacters(
                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_applications`
                                                                  )}
                                                                  className="application box collapse"
                                                                  onClick={() =>
                                                                    toggleTable(
                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_models`,
                                                                      `${company}_${subsidiary}_${division}_${condition}_${application}`,
                                                                      "depth"
                                                                    )
                                                                  }
                                                                >
                                                                  <td className="text-left">
                                                                    {t(
                                                                      `_labels.tire_application.options.${application.toLowerCase()}`
                                                                    )}
                                                                  </td>
                                                                  <td className="text-left col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col2">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col3">
                                                                    {" "}
                                                                  </td>
                                                                  <td className="text-right col4">
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "statistics"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col5">
                                                                    $
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "costOriginal"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                  <td className="text-right col6">
                                                                    $
                                                                    {formatter.format(
                                                                      corporate[1][
                                                                        company
                                                                      ][
                                                                        subsidiary
                                                                      ][
                                                                        division
                                                                      ][
                                                                        condition
                                                                      ][
                                                                        application
                                                                      ][
                                                                        "costRevitalized"
                                                                      ]
                                                                    )}
                                                                  </td>
                                                                </tr>

                                                                {Object.keys(
                                                                  corporate[1][
                                                                    company
                                                                  ][subsidiary][
                                                                    division
                                                                  ][condition][
                                                                    application
                                                                  ]
                                                                )
                                                                  .filter(
                                                                    (index) =>
                                                                      ![
                                                                        "statistics",
                                                                        "costOriginal",
                                                                        "costRevitalized",
                                                                      ].includes(
                                                                        index
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      model,
                                                                      index
                                                                    ) => (
                                                                      <Fragment
                                                                        key={`${model}_${index}`}
                                                                      >
                                                                        <tr
                                                                          data-type="depth"
                                                                          data-id={removeCharacters(
                                                                            `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_models`
                                                                          )}
                                                                          className="model box collapse"
                                                                          onClick={() =>
                                                                            toggleTable(
                                                                              `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_${model}_sizes`,
                                                                              `${company}_${subsidiary}_${division}_${condition}_${application}_${model}`,
                                                                              "depth"
                                                                            )
                                                                          }
                                                                        >
                                                                          <td className="text-left">
                                                                            {
                                                                              model
                                                                            }
                                                                          </td>
                                                                          <td className="text-left col2">
                                                                            {" "}
                                                                          </td>
                                                                          <td className="text-right col2">
                                                                            {" "}
                                                                          </td>
                                                                          <td className="text-right col3">
                                                                            {" "}
                                                                          </td>
                                                                          <td className="text-right col4">
                                                                            {formatter.format(
                                                                              corporate[1][
                                                                                company
                                                                              ][
                                                                                subsidiary
                                                                              ][
                                                                                division
                                                                              ][
                                                                                condition
                                                                              ][
                                                                                application
                                                                              ][
                                                                                model
                                                                              ][
                                                                                "statistics"
                                                                              ]
                                                                            )}
                                                                          </td>
                                                                          <td className="text-right col5">
                                                                            $
                                                                            {formatter.format(
                                                                              corporate[1][
                                                                                company
                                                                              ][
                                                                                subsidiary
                                                                              ][
                                                                                division
                                                                              ][
                                                                                condition
                                                                              ][
                                                                                application
                                                                              ][
                                                                                model
                                                                              ][
                                                                                "costOriginal"
                                                                              ]
                                                                            )}
                                                                          </td>
                                                                          <td className="text-right col6">
                                                                            $
                                                                            {formatter.format(
                                                                              corporate[1][
                                                                                company
                                                                              ][
                                                                                subsidiary
                                                                              ][
                                                                                division
                                                                              ][
                                                                                condition
                                                                              ][
                                                                                application
                                                                              ][
                                                                                model
                                                                              ][
                                                                                "costRevitalized"
                                                                              ]
                                                                            )}
                                                                          </td>
                                                                        </tr>

                                                                        {Object.keys(
                                                                          corporate[1][
                                                                            company
                                                                          ][
                                                                            subsidiary
                                                                          ][
                                                                            division
                                                                          ][
                                                                            condition
                                                                          ][
                                                                            application
                                                                          ][
                                                                            model
                                                                          ]
                                                                        )
                                                                          .filter(
                                                                            (
                                                                              index
                                                                            ) =>
                                                                              ![
                                                                                "statistics",
                                                                                "costOriginal",
                                                                                "costRevitalized",
                                                                              ].includes(
                                                                                index
                                                                              )
                                                                          )
                                                                          .map(
                                                                            (
                                                                              size,
                                                                              index
                                                                            ) => (
                                                                              <Fragment
                                                                                key={`${size}_${index}`}
                                                                              >
                                                                                <tr
                                                                                  data-type="depth"
                                                                                  data-id={removeCharacters(
                                                                                    `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_${model}_sizes`
                                                                                  )}
                                                                                  className="size box collapse"
                                                                                  onClick={() =>
                                                                                    toggleTable(
                                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_${model}_${size}_tires`,
                                                                                      `${company}_${subsidiary}_${division}_${condition}_${application}_${model}_${size}`,
                                                                                      "depth"
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <td className="text-left">
                                                                                    {
                                                                                      size
                                                                                    }
                                                                                  </td>
                                                                                  <td className="text-left col2">
                                                                                    {" "}
                                                                                  </td>
                                                                                  <td className="text-right col2">
                                                                                    {" "}
                                                                                  </td>
                                                                                  <td className="text-right col3">
                                                                                    {" "}
                                                                                  </td>
                                                                                  <td className="text-right col4">
                                                                                    {formatter.format(
                                                                                      corporate[1][
                                                                                        company
                                                                                      ][
                                                                                        subsidiary
                                                                                      ][
                                                                                        division
                                                                                      ][
                                                                                        condition
                                                                                      ][
                                                                                        application
                                                                                      ][
                                                                                        model
                                                                                      ][
                                                                                        size
                                                                                      ][
                                                                                        "statistics"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                  <td className="text-right col5">
                                                                                    $
                                                                                    {formatter.format(
                                                                                      corporate[1][
                                                                                        company
                                                                                      ][
                                                                                        subsidiary
                                                                                      ][
                                                                                        division
                                                                                      ][
                                                                                        condition
                                                                                      ][
                                                                                        application
                                                                                      ][
                                                                                        model
                                                                                      ][
                                                                                        size
                                                                                      ][
                                                                                        "costOriginal"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                  <td className="text-right col6">
                                                                                    $
                                                                                    {formatter.format(
                                                                                      corporate[1][
                                                                                        company
                                                                                      ][
                                                                                        subsidiary
                                                                                      ][
                                                                                        division
                                                                                      ][
                                                                                        condition
                                                                                      ][
                                                                                        application
                                                                                      ][
                                                                                        model
                                                                                      ][
                                                                                        size
                                                                                      ][
                                                                                        "costRevitalized"
                                                                                      ]
                                                                                    )}
                                                                                  </td>
                                                                                </tr>
                                                                                {Object.keys(
                                                                                  corporate[1][
                                                                                    company
                                                                                  ][
                                                                                    subsidiary
                                                                                  ][
                                                                                    division
                                                                                  ][
                                                                                    condition
                                                                                  ][
                                                                                    application
                                                                                  ][
                                                                                    model
                                                                                  ][
                                                                                    size
                                                                                  ]
                                                                                ).filter(
                                                                                  (
                                                                                    index
                                                                                  ) =>
                                                                                    ![
                                                                                      "statistics",
                                                                                      "costOriginal",
                                                                                      "costRevitalized",
                                                                                    ].includes(
                                                                                      index
                                                                                    )
                                                                                )
                                                                                  .length >
                                                                                  0 && (
                                                                                  <tr
                                                                                    data-type="depth"
                                                                                    data-id={removeCharacters(
                                                                                      `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_${model}_${size}_tires`
                                                                                    )}
                                                                                    className="tire box collapse"
                                                                                    style={{
                                                                                      backgroundColor:
                                                                                        "lightgray",
                                                                                      color:
                                                                                        "black",
                                                                                    }}
                                                                                  >
                                                                                    <td className="text-left  ">
                                                                                      {t(
                                                                                        "_labels.code"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.model"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.size"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.location.label"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.vehicle_type_axle_position.label"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.vehicle_type_axle_position.options.refection"
                                                                                      )}
                                                                                    </td>
                                                                                    <td className="text-right col5">
                                                                                      {t(
                                                                                        "_labels.depth"
                                                                                      )}
                                                                                    </td>
                                                                                  </tr>
                                                                                )}
                                                                                {Object.keys(
                                                                                  corporate[1][
                                                                                    company
                                                                                  ][
                                                                                    subsidiary
                                                                                  ][
                                                                                    division
                                                                                  ][
                                                                                    condition
                                                                                  ][
                                                                                    application
                                                                                  ][
                                                                                    model
                                                                                  ][
                                                                                    size
                                                                                  ]
                                                                                )
                                                                                  .filter(
                                                                                    (
                                                                                      index
                                                                                    ) =>
                                                                                      ![
                                                                                        "statistics",
                                                                                        "costOriginal",
                                                                                        "costRevitalized",
                                                                                      ].includes(
                                                                                        index
                                                                                      )
                                                                                  )
                                                                                  .map(
                                                                                    (
                                                                                      tire,
                                                                                      index
                                                                                    ) => (
                                                                                      <tr
                                                                                        data-type="depth"
                                                                                        data-id={removeCharacters(
                                                                                          `${corporate[0]}_${company}_${subsidiary}_${division}_${condition}_${application}_${model}_${size}_tires`
                                                                                        )}
                                                                                        key={`${tire}_${index}`}
                                                                                        className="tire box collapse"
                                                                                        style={{
                                                                                          backgroundColor:
                                                                                            "white",
                                                                                          color:
                                                                                            "black",
                                                                                        }}
                                                                                      >
                                                                                        <td className="text-left text-muted ">
                                                                                          {corporate[1][
                                                                                            company
                                                                                          ][
                                                                                            subsidiary
                                                                                          ][
                                                                                            division
                                                                                          ][
                                                                                            condition
                                                                                          ][
                                                                                            application
                                                                                          ][
                                                                                            model
                                                                                          ][
                                                                                            size
                                                                                          ][
                                                                                            tire
                                                                                          ][
                                                                                            "code"
                                                                                          ] ||
                                                                                            corporate[1][
                                                                                              company
                                                                                            ][
                                                                                              subsidiary
                                                                                            ][
                                                                                              division
                                                                                            ][
                                                                                              condition
                                                                                            ][
                                                                                              application
                                                                                            ][
                                                                                              model
                                                                                            ][
                                                                                              size
                                                                                            ][
                                                                                              tire
                                                                                            ][
                                                                                              "device_code"
                                                                                            ]}
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {
                                                                                            corporate[1][
                                                                                              company
                                                                                            ][
                                                                                              subsidiary
                                                                                            ][
                                                                                              division
                                                                                            ][
                                                                                              condition
                                                                                            ][
                                                                                              application
                                                                                            ][
                                                                                              model
                                                                                            ][
                                                                                              size
                                                                                            ][
                                                                                              tire
                                                                                            ][
                                                                                              "model"
                                                                                            ]
                                                                                          }
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {
                                                                                            corporate[1][
                                                                                              company
                                                                                            ][
                                                                                              subsidiary
                                                                                            ][
                                                                                              division
                                                                                            ][
                                                                                              condition
                                                                                            ][
                                                                                              application
                                                                                            ][
                                                                                              model
                                                                                            ][
                                                                                              size
                                                                                            ][
                                                                                              tire
                                                                                            ][
                                                                                              "size"
                                                                                            ]
                                                                                          }
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {
                                                                                            corporate[1][
                                                                                              company
                                                                                            ][
                                                                                              subsidiary
                                                                                            ][
                                                                                              division
                                                                                            ][
                                                                                              condition
                                                                                            ][
                                                                                              application
                                                                                            ][
                                                                                              model
                                                                                            ][
                                                                                              size
                                                                                            ][
                                                                                              tire
                                                                                            ][
                                                                                              "location"
                                                                                            ]
                                                                                          }
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {corporate[1][
                                                                                            company
                                                                                          ][
                                                                                            subsidiary
                                                                                          ][
                                                                                            division
                                                                                          ][
                                                                                            condition
                                                                                          ][
                                                                                            application
                                                                                          ][
                                                                                            model
                                                                                          ][
                                                                                            size
                                                                                          ][
                                                                                            tire
                                                                                          ][
                                                                                            "real_position"
                                                                                          ] ||
                                                                                            "-"}
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {corporate[1][
                                                                                            company
                                                                                          ][
                                                                                            subsidiary
                                                                                          ][
                                                                                            division
                                                                                          ][
                                                                                            condition
                                                                                          ][
                                                                                            application
                                                                                          ][
                                                                                            model
                                                                                          ][
                                                                                            size
                                                                                          ][
                                                                                            tire
                                                                                          ][
                                                                                            "is_refection"
                                                                                          ]
                                                                                            ? t(
                                                                                                "_labels.yes"
                                                                                              )
                                                                                            : t(
                                                                                                "_labels.no"
                                                                                              )}
                                                                                        </td>
                                                                                        <td className="text-right text-muted col5">
                                                                                          {
                                                                                            corporate[1][
                                                                                              company
                                                                                            ][
                                                                                              subsidiary
                                                                                            ][
                                                                                              division
                                                                                            ][
                                                                                              condition
                                                                                            ][
                                                                                              application
                                                                                            ][
                                                                                              model
                                                                                            ][
                                                                                              size
                                                                                            ][
                                                                                              tire
                                                                                            ][
                                                                                              "depth"
                                                                                            ]
                                                                                          }{" "}
                                                                                          mm
                                                                                        </td>
                                                                                      </tr>
                                                                                    )
                                                                                  )}
                                                                              </Fragment>
                                                                            )
                                                                          )}
                                                                      </Fragment>
                                                                    )
                                                                  )}
                                                              </Fragment>
                                                            )
                                                          )}
                                                      </Fragment>
                                                    ))}
                                                </Fragment>
                                              ))}
                                          </Fragment>
                                        ))}
                                    </Fragment>
                                  ))}
                              </tbody>
                            </Fragment>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <FilterPurchaseForm open={openFilter} onClose={handleCloseFilter} />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  data: PropTypes.object,
  detailsData: PropTypes.object.isRequired,
  modelsOriginal: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  modelsRevitalized: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  actualPurchase: PropTypes.object.isRequired,
  actualPurchaseRevitalized: PropTypes.object.isRequired,
  replacementData: PropTypes.object.isRequired,
  replacementRevitalizedData: PropTypes.object.isRequired,
  summaryData: PropTypes.object.isRequired,
  setSummaryData: PropTypes.func.isRequired,
  estimatedData: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
  savePdf: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string, replacer = "") {
  const res = string.replaceAll(/[,\s\.]/g, replacer);

  return res.replaceAll(/\+/g, "plus").replaceAll(/\//g, "slash");
}

function toggleByClass(parentClass, className) {
  const target = Array.from(
    document.querySelectorAll(`.${parentClass}>.${className}`)
  );
  const forHide = Array.from(
    document.querySelectorAll(`.${parentClass}>div:not(.${className})`)
  );
  forHide.forEach((element) => {
    if (!element.classList.contains("collapse")) {
      element.classList.add("collapse");
    }
  });

  if (parentClass === "originales") {
    Array.from(document.querySelectorAll(".revitalizados>div")).forEach(
      (element) => element.classList.add("collapse")
    );
  } else {
    Array.from(document.querySelectorAll(".originales>div")).forEach(
      (element) => element.classList.add("collapse")
    );
  }
  target.forEach((element) => {
    element.classList.toggle("collapse");
  });

  // originals.classList.toggle("collapse");
}
