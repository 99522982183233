import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Container from "components/templates/general/Container";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Pagination from "components/molecules/general/Pagination";
import FixedButton from "components/molecules/general/FixedButton";
import SearchInput from "components/molecules/general/SearchInput";
import ChecklistForm from "components/organisms/checklist/ChecklistForm";
import ChecklistDialog from "./ChecklistDialog";

import css from "./index.module.scss";

function Page({
  t,
  checklists,
  search,
  checklistId,
  openForm,
  onChangePage,
  handleDelete,
  handleEdit,
  handleOpenForm,
  handleCloseForm,
  handleSearch,
  openDialog,
  handleShow,
  handleCloseShow,
  createChecklistPermit,
  updateChecklistPermit,
  deleteChecklistPermit,
  showChecklistPermit,
}) {
  return (
    <Container
      title={t("_general.checklist")}
      className={classnames({
        [css.empty]: checklists.data.length === 0,
      })}
      options={<SearchInput search={search || ""} onChange={handleSearch} />}
    >
      {checklists.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th className="pl-4">{t("_labels.subsidiary.singular")}</th>
                    <th>{t("_labels.name.singular")}</th>
                    <th>{t("_labels.type")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {checklists.data.map((checklist) => (
                    <tr key={checklist.check_list_id}>
                      <td className="pl-4">{checklist?.subsidiary?.name}</td>
                      <td>{checklist.name}</td>
                      <td>
                        {checklist.type == "VEHICLE"
                          ? t("_labels.vehicle.singular")
                          : t("_labels.tire.singular")}
                      </td>
                      <td>
                        <div
                          className="d-flex justify-content-start"
                          style={{ gap: "6px" }}
                        >
                          <Tooltip
                            title={
                              !showChecklistPermit
                                ? t("_permits.show.checklist")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-info d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !showChecklistPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleShow(checklist.check_list_id)
                                }
                                disabled={!showChecklistPermit}
                              >
                                <VisibilityIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>

                          <Tooltip
                            title={
                              !updateChecklistPermit
                                ? t("_permits.update.checklist")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-primary d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !updateChecklistPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleEdit(checklist.check_list_id)
                                }
                                disabled={!updateChecklistPermit}
                              >
                                <EditIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>

                          <Tooltip
                            title={
                              !deleteChecklistPermit
                                ? t("_permits.delete.checklist")
                                : ""
                            }
                          >
                            <div>
                              <button
                                className="btn btn-sm btn-danger d-flex justify-content-center"
                                style={{
                                  borderRadius: "50%",
                                  width: "30px",
                                  height: "30px",
                                  pointerEvents: !deleteChecklistPermit
                                    ? "none"
                                    : "auto",
                                }}
                                onClick={() =>
                                  handleDelete(checklist.check_list_id)
                                }
                                disabled={!deleteChecklistPermit}
                              >
                                <DeleteIcon fontSize="small" />
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={checklists.current_page}
              totalPages={checklists.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_checklists")} />
      )}

      <FixedButton
        color="primary"
        onClick={handleOpenForm}
        tooltip={!createChecklistPermit ? t("_permits.create.checklist") : ""}
        disabled={!createChecklistPermit}
      />

      <ChecklistForm
        checklistId={checklistId}
        open={openForm}
        onClose={handleCloseForm}
      />

      <ChecklistDialog
        open={openDialog}
        onClose={handleCloseShow}
        checklistId={checklistId}
      />
    </Container>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  checklists: PropTypes.object.isRequired,
  search: PropTypes.string,
  checklistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangePage: PropTypes.func,
  openForm: PropTypes.bool,
  openDialog: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleOpenForm: PropTypes.func,
  handleCloseForm: PropTypes.func,
  handleSearch: PropTypes.func,
  handleShow: PropTypes.func,
  handleCloseShow: PropTypes.func,
  createChecklistPermit: PropTypes.bool,
  updateChecklistPermit: PropTypes.bool,
  deleteChecklistPermit: PropTypes.bool,
  showChecklistPermit: PropTypes.bool,
};

export default Page;
