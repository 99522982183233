import {
  REGISTER_SUBSIDIARY,
  GET_SUBSIDIARIES,
  CLEAR_SUBSIDIARIES,
  SET_SUBSIDIARY_FILTERS,
  CLEAR_SUBSIDIARY_FILTERS,
  GET_SUBSIDIARY,
  CLEAR_SUBSIDIARY,
  UPDATE_SUBSIDIARY,
  DELETE_SUBSIDIARY,
  SET_STATUS_SUBSIDIARY,
} from "store/actions";

const initialState = {
  subsidiary: {
    subsidiary_id: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    name: "",
    status: 0,
    country: "",
    province: "",
    direction_1: "",
    external_number: "",
    postal_code: "",
  },
  subsidiaries: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_subsidiary: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };

    case GET_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: action.payload,
      };

    case CLEAR_SUBSIDIARIES:
      return {
        ...state,
        subsidiaries: { ...initialState.subsidiaries },
      };

    case SET_SUBSIDIARY_FILTERS:
      const filter = {
        ...state.filter_subsidiary,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter_subsidiary: { ...filter },
      };

    case CLEAR_SUBSIDIARY_FILTERS:
      return {
        ...state,
        filter_subsidiary: { search: state.filter_subsidiary.search },
      };
    case GET_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };

    case CLEAR_SUBSIDIARY:
      return {
        ...state,
        subsidiary: { ...initialState.subsidiary },
      };

    case UPDATE_SUBSIDIARY:
      return {
        ...state,
        subsidiary: action.payload,
      };
    case SET_STATUS_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: {
          ...state.subsidiaries,
          data: state.subsidiaries.data.map((subsidiary) =>
            subsidiary.subsidiary_id !== action.payload
              ? subsidiary
              : { ...subsidiary, status: !subsidiary.status }
          ),
        },
      };

    case DELETE_SUBSIDIARY:
      return {
        ...state,
        subsidiaries: {
          ...state.subsidiaries,
          data: state.subsidiaries.data.filter(
            (subsidiary) => subsidiary.subsidiary_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
