import React from "react";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useLang } from "hooks/lang";
import { getMinDepth } from "utils/tire";

const styles = StyleSheet.create({
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowHeader: {
    backgroundColor: "rgb(78, 172, 223)",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  tableRowHeader2: {
    backgroundColor: "#212529",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  row1: {
    backgroundColor: "rgb(240, 240, 240)",
  },
  row2: {
    backgroundColor: "rgb(220, 220, 220)",
  },
  tableColHeader: {
    width: "100%",
  },
  tableCol: {
    width: "16.66%",
  },
  tableCol2: {
    width: "6.66%",
  },
  tableCell: {
    margin: "auto",
    fontSize: 8,
    padding: "2px",
  },
});

const AlertVehicleTirePdf = ({ uuid, alerts, economicNumber }) => {
  const t = useLang();
  return (
    <Document key={uuid}>
      <Page size={"LETTER"} style={{ padding: "20px 20px 20px 20px" }}>
        <View style={styles.title}>
          <Text>
            {t("_labels.vehicle.singular")} {economicNumber}
          </Text>
          {/*<Text style={styles.subTitle}>{subsidiary}</Text>*/}
        </View>
        <View
          style={{
            height: "20px",
          }}
        />

        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}>{t("_general.alerts")}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.tableRowHeader2]} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.alert.label")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.code")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t("_labels.priority.label")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t("_labels.ranking_alert.label")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t("_labels.alert.suggestion")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.ranking")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.pressure")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.depth")}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{t("_labels.date.label")}</Text>
            </View>
          </View>
          {alerts.map((alert, index) => {
            return (
              <View
                style={[styles.tableRow, index % 2 ? styles.row1 : styles.row2]}
                key={alert.alert_vehicle_tire_tpgps_id}
                wrap={false}
              >
                <View style={styles.tableCol}>
                  <Text
                    style={{
                      ...styles.tableCell,
                      color:
                        alert.alert.priority == "HIGH"
                          ? "rgb(250, 69, 71)"
                          : alert.alert.priority == "HALF"
                          ? "rgb(233, 186, 0)"
                          : "black",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {alert.alert.colloquial_name
                      ? t(
                          `_alert.colloquial_name.${alert.alert.colloquial_name}`
                        )
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {alert.vehicle_tire?.movement_tire?.tire_cycle?.tire.code}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {t(
                      `_labels.priority.options.${alert.alert.priority.toLowerCase()}`
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {t(
                      `_labels.ranking_alert.options.${alert.alert.ranking_alert.toLowerCase()}`
                    )}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {alert.alert.suggestion
                      ? t(`_alert.suggestion.${alert.alert.suggestion}`)
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {
                      alert?.vehicle_tire?.movement_tire?.tire_review[0]
                        ?.real_position
                    }
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {`${alert?.vehicle_tire?.movement_tire?.tire_review[0]?.pressure} psi`}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {`${getMinDepth(
                      alert?.vehicle_tire?.movement_tire?.tire_review[0]
                        ?.tire_review_depth_line
                    )} mm`}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {`${moment(alert.created_at).format("LL")} ${moment(
                      alert.created_at
                    ).format("h:mm a")}`}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

AlertVehicleTirePdf.propTypes = {
  uuid: PropTypes.string.isRequired,
  alerts: PropTypes.array.isRequired,
  economicNumber: PropTypes.string,
};

export default AlertVehicleTirePdf;
