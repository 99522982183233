import { SET_BRAND_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetBrandStatus = (dispatch) => async (brandId, status) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "PUT",
      path: `brand/${brandId}/status`,
      data: { status },
    });

    dispatch({
      type: SET_BRAND_STATUS,
      payload: brandId,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
