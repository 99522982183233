import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSemaphoreReport } from "store/actions/reports/semaphore/getSemaphoreReport";
import { actionSetSemaphoreFilters } from "store/actions/reports/semaphore/setSemaphoreFilters";
import { actionSetSelectedSemaphoreFilters } from "store/actions/reports/semaphore/setSelectedSemaphoreFilters";
import { actionClearSemaphoreFilters } from "store/actions/reports/semaphore/clearSemaphoreFilters";
import { actionGenerateExcelSemaphore } from "store/actions/reports/semaphore/generateExcelSemaphore";

import { getCompanyViaWorkArea, getCorporateViaWorkArea } from "utils/workArea";
import { useLang } from "hooks/lang";

import Page from "./page";
import moment from "moment-timezone";

export function SemaphoreReport({ ...rest }) {
  const t = useLang();
  const {
    account,
    filter,
    selectedFilter,
    workArea,
    setNotification,
    getSemaphoreReport,
    setSemaphoreFilters,
    setSelectedFilters,
    clearSemaphoreFilters,
    generateExcelReport,
  } = rest;

  const [data, setData] = useState({});
  const [corporates, setCorporates] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSemaphoreReport({ ...filter });
        setData(response);
        setCorporates(getCorporates(response));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0) {
      fetchData();
    }
  }, [filter]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const companies = getCompanyViaWorkArea(account, workArea);
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    const filter = {
      date_from: startDate,
      date_to: endDate,
      corporate_id: corporate.corporate_id.toString(),
      companies: companies.map((company) => company.company_id).join(),
    };

    const selectedFilters = {
      date_from: startDate,
      date_to: endDate,
      corporate: corporate.corporate.name,
      companies: companies.map((company) => company.company.name).join(),
    };
    setSemaphoreFilters(filter);
    setSelectedFilters(selectedFilters);
    return () => {
      clearSemaphoreFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  async function handleGenerateExcel() {
    try {
      await generateExcelReport({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleSearch(search) {
    if (search === "") {
      setSemaphoreFilters({ ...filter, search: undefined });
    } else {
      setSemaphoreFilters({ ...filter, search });
    }
  }

  return (
    <Page
      t={t}
      data={data}
      search={filter.search}
      corporates={corporates}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
      handleSearch={handleSearch}
    />
  );
}

SemaphoreReport.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.Semaphore.filter,
  selectedFilter: state.Reports.Semaphore.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getSemaphoreReport: actionGetSemaphoreReport(dispatch),
  setSemaphoreFilters: actionSetSemaphoreFilters(dispatch),
  setSelectedFilters: actionSetSelectedSemaphoreFilters(dispatch),
  clearSemaphoreFilters: actionClearSemaphoreFilters(dispatch),
  generateExcelReport: actionGenerateExcelSemaphore(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SemaphoreReport);

function getCorporates(data) {
  return Object.keys(data);
}
