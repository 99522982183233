import { GET_CONCEPTS } from "store/actions";
import { http } from "store/actions/http";
import { actionShowProgress } from "store/actions/general/progress";

export const actionGetConcepts =
  (dispatch) =>
  async (
    {
      page,
      order = "DESC",
      scope,
      search,
      type_value: typeValue,
      date_from: dateFrom,
      date_to: dateTo,
    },
    withDispatch = true
  ) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: "concept",
        params: {
          page,
          order,
          scope,
          search,
          type_value: typeValue,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      if (withDispatch) {
        dispatch({
          type: GET_CONCEPTS,
          payload: response,
        });
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
