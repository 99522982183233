import React from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPotentialSavingsReport } from "store/actions/reports/potential_savings/getPotentialSavingsReport";

import Page from "./page";
import PropTypes from "prop-types";

function Costs({ data }) {
  const t = useLang();

  return <Page t={t} data={getData(data, t)} />;
}
Costs.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Reports.PotentialSavings.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getReport: actionGetPotentialSavingsReport(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Costs);

function getData(data, t) {
  if (data.costo_mm) {
    const array = [];
    for (const [brand, brandData] of Object.entries(data.costo_mm)) {
      for (const [model, modelData] of Object.entries(brandData)) {
        for (const [condition, conditionData] of Object.entries(modelData)) {
          for (const [application, applicationData] of Object.entries(
            conditionData
          )) {
            for (const [size, sizeData] of Object.entries(applicationData)) {
              array.push({
                brand: brand,
                model: model,
                condition: condition.includes("ORIGINAL")
                  ? "Originales"
                  : "Revitalizado",
                application: t(
                  `_labels.tire_application.options.${application.toLowerCase()}`
                ).toUpperCase(),
                size: size,
                count: sizeData.elements,
                cost: sizeData.cost,
                depth: sizeData.depth,
                wear: sizeData.wear,
                depth_cost: sizeData.depth_cost,
              });
            }
          }
        }
      }
    }
    return array.sort(sortFunction);
  }
  return [];
}

function sortFunction(a, b) {
  if (a["condition"] > b["condition"]) {
    return 1;
  } else if (a["condition"] < b["condition"]) {
    return -1;
  } else {
    if (a["application"] > b["application"]) {
      return 1;
    } else if (a["application"] < b["application"]) {
      return -1;
    } else {
      if (a["brand"] > b["brand"]) {
        return 1;
      } else if (a["brand"] < b["brand"]) {
        return -1;
      } else {
        if (a["cost"] < b["cost"]) {
          return 1;
        } else if (a["cost"] > b["cost"]) {
          return -1;
        }

        return 0;
      }
    }
  }
}
