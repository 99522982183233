import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({ t, isUpdate, isValid, isSubmitting, onClose, handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="name"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.name.singular")}
      />

      <Field
        required
        multiline
        rows={4}
        variant="filled"
        margin="normal"
        name="description"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.description")}
      />

      <DialogActions className={classnames(css.action)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={isSubmitting || !isValid}
        >
          {isUpdate ? t("_buttons.update") : t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
