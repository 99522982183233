import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRenewabilityIndexReport } from "store/actions/reports/renewability_index/getRenewabilityIndexReport";
import { actionClearRenewabilityIndexReport } from "store/actions/reports/renewability_index/clearRenewabilityIndexReport";
import { actionSetRenewabilityIndexFilters } from "store/actions/reports/renewability_index/setTireRenewabilityIndexFilters";
import { actionClearRenewabilityIndexFilters } from "store/actions/reports/renewability_index/clearRenewabilityIndexFilters";
import { actionGenerateRenewabilityIndexExcel } from "store/actions/reports/renewability_index/generateExcelRenewabilityIndex";

import { useLang } from "hooks/lang";

import Page from "./page";

function RenewabilityIndex({ ...rest }) {
  const t = useLang();
  const [openFilter, setOpenFilter] = useState(false);
  const {
    report,
    filter,
    selectedFilter,
    setNotification,
    getRenewabilityIndexReport,
    clearRenewabilityIndexReport,
    setRenewabilityIndexFilters,
    clearRenewabilityIndexFilters,
    generateExcel,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getRenewabilityIndexReport({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearRenewabilityIndexReport();
    };
  }, [filter]);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [report.data]);

  useEffect(() => {
    return () => {
      clearRenewabilityIndexFilters();
    };
  }, []);

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSort(key) {
    let order = "ASC";
    if (!filter.sort_by) {
      order = "DESC";
    } else if (key === filter.sort_by) {
      order = filter.order === "ASC" ? "DESC" : "ASC";
    }

    setRenewabilityIndexFilters({ ...filter, sort_by: key, order });
  }

  async function onChangePage(page) {
    try {
      await getRenewabilityIndexReport({ page, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  async function handleGenerateRenewabilityIndexExcel() {
    try {
      await generateExcel({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      data={report}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleSort={handleSort}
      onChangePage={onChangePage}
      handleGenerateRenewabilityIndexExcel={
        handleGenerateRenewabilityIndexExcel
      }
    />
  );
}
RenewabilityIndex.propTypes = {};

const mapStateToProps = (state) => ({
  report: state.Reports.RenewabilityIndex.report,
  filter: state.Reports.RenewabilityIndex.filter,
  selectedFilter: state.Reports.RenewabilityIndex.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRenewabilityIndexReport: actionGetRenewabilityIndexReport(dispatch),
  clearRenewabilityIndexReport: actionClearRenewabilityIndexReport(dispatch),
  clearRenewabilityIndexFilters: actionClearRenewabilityIndexFilters(dispatch),
  setRenewabilityIndexFilters: actionSetRenewabilityIndexFilters(dispatch),
  generateExcel: actionGenerateRenewabilityIndexExcel(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewabilityIndex);
