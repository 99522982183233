import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ImageIcon from "@material-ui/icons/Image";

import css from "./index.module.scss";
import "resources/css/bootstrap.min.css";
import { Link } from "react-router-dom";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({ t, open, onClose, tires, isInApplication }) {
  return (
    <>
      <DialogMediaQuery
        maxWidth="lg"
        open={open}
        onClose={onClose}
        className="alert_info"
      >
        <DialogTitle>
          <span>
            {t("_general.tires")}
            {`: ${tires.length}`}
          </span>
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <div
            className="table-responsive"
            style={{
              maxHeight: 500,
              minWidth: isInApplication ? 900 : 750,
            }}
          >
            <table className="table">
              <thead
                style={{
                  color: "black",
                  backgroundColor: "lightgray",
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr>
                  <td className="text-center">{t("_labels.code")}</td>
                  <td className="text-center">
                    {t("_labels.report_pile.cost")}
                  </td>
                  <td className="text-center">
                    {t("_labels.retirement_cause_field.label")}
                  </td>
                  {isInApplication && (
                    <td className="text-center">{t("_labels.model")}</td>
                  )}
                  <td className="text-center text-capitalize">
                    {t("_labels.evidence")}
                  </td>
                  <td className="text-center text-capitalize">
                    {t("_labels.image")}
                  </td>
                </tr>
              </thead>
              <tbody>
                {tires.map((tire, index) => (
                  <tr key={index}>
                    <td className="py-1 px-2 text-center align-middle">
                      <Link
                        to={`/tire/${tire["tire_id"]}/history`}
                        target="_blank"
                        rel="noopener"
                      >
                        <button className="w-100 btn btn-sm btn-success d-flex justify-content-center align-items-stretch">
                          <VisibilityIcon fontSize="small" className="mr-1" />
                          {tire["code"]}
                        </button>
                      </Link>
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      ${formatter.format(tire["utils_mm"])}
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      {t(`_retirement.name.${tire.retirement_cause_name}`)}
                    </td>
                    {isInApplication && (
                      <td className="py-1 px-2 text-center align-middle">
                        {tire["model_variation"]}
                      </td>
                    )}
                    <td className="py-1 px-2 text-center align-middle">
                      <Button
                        variant="icon"
                        color="secondary"
                        disabled={!tire.tire_pile_image}
                        href={`${process.env.REACT_APP_IMAGES_URL}${tire.tire_pile_image}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <ImageIcon />
                      </Button>
                    </td>
                    <td className="py-1 px-2 text-center align-middle">
                      <Button
                        variant="icon"
                        color="secondary"
                        disabled={!tire.retirement_cause_image}
                        href={`${process.env.REACT_APP_IMAGES_URL}${tire.retirement_cause_image}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <ImageIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classnames(css.action)}>
            <Button onClick={onClose} color="secondary">
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  tires: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isInApplication: PropTypes.bool.isRequired,
};

export default Page;
