import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetTireFilters } from "store/actions/tire/setTireFilters";
import { actionClearTireFilters } from "store/actions/tire/clearTireFilters";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";
import { actionGetProviders } from "store/actions/provider/getProviders";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";
import { actionGetAlerts } from "store/actions/alert/getAlerts";

import { LIST_SUBSIDIARY } from "store/actions/account/permits";

import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterTireForm({ open, onClose, ...rest }) {
  const t = useLang();
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tireModels, setTireModels] = useState([]);
  const [tireModelVariations, setTireModelVariations] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [revitalizedBrands, setRevitalizedBrands] = useState([]);
  const [revitalizedModels, setRevitalizedModels] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const {
    filter,
    account,
    permits,
    getSubsidiaries,
    getBrands,
    setNotification,
    setTireFilters,
    clearTireFilters,
    getTireSizes,
    getProviders,
    getWarehouses,
    getVehicles,
    setSelectedFilters,
    getAlerts,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      status: "",
      brands: [],
      models: null,
      model_variations: [],
      invoice_folio: "",
      invoice_date: "",
      DOT_initial: "",
      DOT_final: "",
      tire_size: "",
      providers: "",
      subsidiaries: [],
      warehouses: "",
      vehicle: null,
      condition: "",
      location: "",
      date_from: "",
      date_to: "",
      revitalized_brands: null,
      revitalized_models: null,
      with_alerts: "",
      alert_code: [],
    },
  });

  useEffect(() => {
    if (open) {
      setInitialValues({
        status:
          filter.status !== undefined
            ? filter.status
              ? "enabled"
              : "disabled"
            : "",
        with_alerts:
          filter.with_alerts !== undefined
            ? filter.with_alerts
              ? "1"
              : "0"
            : "",
        brands: filter.brands
          ? brands.filter((brand) =>
              filter.brands.split(",").includes(brand.brand_id.toString())
            )
          : [],
        models: filter.models
          ? tireModels.find((model) => model.tire_model_id === filter.models)
          : null,
        model_variations: filter.model_variations
          ? filter.model_variations.split(",")
          : [],
        invoice_folio: filter.invoice_folio || "",
        invoice_date: filter.invoice_date || "",
        DOT_initial: filter.DOT_initial || "",
        DOT_final: filter.DOT_final || "",
        tire_size: filter.tire_size || "",
        providers: filter.providers || "",
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        warehouses: filter.warehouses || "",
        vehicle: filter.vehicle
          ? vehicles.find((vehicle) => vehicle.vehicle_id === filter.vehicle)
          : null,
        condition: filter.condition || "",
        location: filter.location || "",
        date_from: filter.date_from || "",
        date_to: filter.date_to || "",
        revitalized_brands: filter.revitalized_brands
          ? revitalizedBrands.find(
              (revitalizedBrand) =>
                revitalizedBrand.brand_id === filter.revitalized_brands
            )
          : null,
        revitalized_models: filter.revitalized_models
          ? revitalizedModels.find(
              (revitalizedModel) =>
                revitalizedModel.revitalized_tire_model_id ===
                filter.revitalized_models
            )
          : null,
        alert_code: filter.alert_code ? filter.alert_code.split(",") : [],
      });
    } else {
      resetFormik();
    }
  }, [
    open,
    brands,
    tireModels,
    vehicles,
    revitalizedBrands,
    revitalizedModels,
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        if (permits.includes(LIST_SUBSIDIARY)) {
          data = await getSubsidiaries(
            {
              scope:
                "subsidiary_id,name,status,company.name,company.company_id",
            },
            false
          );

          data = data.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary_id,
            status: subsidiary.status,
            name: `${subsidiary.name} | ${subsidiary.company.name}`,
          }));
        } else if (Array.isArray(account.subsidiaries)) {
          data = account.subsidiaries.map((subsidiary) => ({
            subsidiary_id: subsidiary.subsidiary.subsidiary_id,
            name: `${subsidiary.subsidiary.name} | ${subsidiary.subsidiary.company.name}`,
            status: subsidiary.subsidiary.status,
          }));
        }

        let dataBrands = [];
        dataBrands = await getBrands(
          { brandType: "TIRE", scope: "brand_id,name,status,approved" },
          false
        );

        let dataTireSizes = [];
        dataTireSizes = await getTireSizes(
          {
            scope: "tire_size_id,size,status,approved",
          },
          false
        );

        let dataProviders = [];
        dataProviders = await getProviders(
          {
            scope: "provider_id,name,status,subsidiary.name",
          },
          false
        );

        let dataWarehouses = [];
        dataWarehouses = await getWarehouses(
          {
            scope: "warehouse_id,name,status,subsidiary.name",
          },
          false
        );

        let dataVehicles = [];
        dataVehicles = await getVehicles(
          {
            scope: "vehicle_id,economic_number,status",
          },
          false
        );

        let dataRevitalizedBrands = [];
        dataRevitalizedBrands = await getBrands(
          { brandType: "RETREAD", scope: "brand_id,name,status,approved" },
          false
        );

        let dataAlerts = [];
        dataAlerts = await getAlerts(
          {
            scope: "alert_id,colloquial_name,code",
            alert_type: "TIRE,MOUNT",
          },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
        setBrands(dataBrands.sort(alphabeticalSort("name")));
        setTireSizes(dataTireSizes.sort(alphabeticalSort("size")));
        setProviders(
          dataProviders
            .map((x) => ({ ...x, name: `${x.name} | ${x.subsidiary.name}` }))
            .sort(alphabeticalSort("name"))
        );
        setWarehouses(
          dataWarehouses
            .map((x) => ({ ...x, name: `${x.name} | ${x.subsidiary.name}` }))
            .sort(alphabeticalSort("name"))
        );
        setVehicles(dataVehicles.sort(alphabeticalSort("economic_number")));
        setRevitalizedBrands(
          dataRevitalizedBrands.sort(alphabeticalSort("name"))
        );
        setAlerts(
          dataAlerts
            .map((alert) => ({
              ...alert,
              colloquial_name: t(
                `_alert.colloquial_name.${alert.colloquial_name.toLowerCase()}`
              ),
            }))
            .sort(alphabeticalSort("colloquial_name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  function handleSelectedFilters(filter) {
    const conditionLabels = {
      ORIGINAL_NEW: t("_labels.tire_condition.options.original_new"),
      ORIGINAL_USED: t("_labels.tire_condition.options.original_used"),
      RETREAD_NEW: t("_labels.tire_condition.options.retread_new"),
      RETREAD_USED: t("_labels.tire_condition.options.retread_used"),
    };
    const locationLabels = {
      WAREHOUSE: t("_labels.location.options.warehouse"),
      MOUNT: t("_labels.location.options.mount"),
      REVITALIZATION: t("_labels.location.options.revitalization"),
      REPAIR: t("_labels.location.options.repair"),
      PILE: t("_labels.location.options.pile"),
    };
    const humanReadableFilter = {
      status: {
        label: t("_labels.status"),
        value:
          `${filter.status}` === "1"
            ? t("_general.enabled.singular.male")
            : `${filter.status}` === "0"
            ? t("_general.disabled.singular.male")
            : undefined,
      },
      date_from: {
        label: t("_labels.date.from"),
        value: filter.date_from,
      },
      date_to: {
        label: t("_labels.date.to"),
        value: filter.date_to,
      },
      tire_size: {
        label: t("_labels.tire_size.label.singular"),
        value: tireSizes.find(
          (e) => `${e.tire_size_id}` === `${filter.tire_size}`
        )?.size,
      },
      models: {
        label: t("_labels.tire_model.model_name"),
        value: tireModels.find(
          (e) => `${e.tire_model_id}` === `${filter.models}`
        )?.name,
      },
      model_variations: {
        label: t("_labels.tire_model_variation.label"),
        value: filter.model_variations
          ?.split(",")
          .map(
            (e) =>
              tireModelVariations.find(
                (m) => `${m.tire_model_variation_id}` === `${e}`
              )?.tire_size?.size
          )
          .join(", "),
      },
      brands: {
        label: t("_labels.brand_field.label"),
        value: filter.brands
          ?.split(",")
          .map((e) => brands.find((b) => `${b.brand_id}` === `${e}`)?.name)
          .join(", "),
      },
      revitalized_brands: {
        label: t("_labels.revitalized_brand_field.label"),
        value: revitalizedBrands.find(
          (e) => `${e.brand_id}` === `${filter.revitalized_brands}`
        )?.name,
      },
      subsidiaries: {
        label: t("_labels.subsidiary.plural"),
        value: filter.subsidiaries
          ?.split(",")
          .map(
            (subsidiary) =>
              subsidiaries.find((e) => `${e.subsidiary_id}` === `${subsidiary}`)
                ?.name
          )
          .join(", "),
      },
      warehouses: {
        label: t("_labels.warehouse.singular"),
        value: warehouses.find(
          (e) => `${e.warehouse_id}` === `${filter.warehouses}`
        )?.name,
      },
      revitalized_models: {
        label: t("_labels.revitalized_tire_model_field.label"),
        value: revitalizedModels.find(
          (e) =>
            `${e.revitalized_tire_model_id}` === `${filter.revitalized_models}`
        )?.name,
      },
      invoice_folio: {
        label: t("_labels.invoice_folio"),
        value: filter.invoice_folio,
      },
      invoice_date: {
        label: t("_labels.invoice_date"),
        value: filter.invoice_date,
      },
      DOT_initial: {
        label: t("_labels.dot_initial"),
        value: filter.DOT_initial,
      },
      DOT_final: {
        label: t("_labels.dot_final"),
        value: filter.DOT_final,
      },
      providers: {
        label: t("_labels.provider"),
        value: providers.find(
          (e) => `${e.provider_id}` === `${filter.providers}`
        )?.name,
      },
      vehicle: {
        label: t("_labels.vehicle.singular"),
        value: vehicles.find((e) => `${e.vehicle_id}` === `${filter.vehicle}`)
          ?.economic_number,
      },
      condition: {
        label: t("_labels.condition"),
        value: conditionLabels[filter.condition],
      },
      location: {
        label: t("_labels.location.label"),
        value: locationLabels[filter.location],
      },
    };
    setSelectedFilters(humanReadableFilter);
  }

  function handleClean() {
    clearTireFilters();
    handleSelectedFilters({});
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = {};

      if (values.status !== "") {
        params.status = values.status === "enabled" ? 1 : 0;
      }

      if (values.with_alerts !== "") {
        params.with_alerts = Number(values.with_alerts);
      }

      if (values.alert_code.length > 0) {
        params.alert_code = values.alert_code.join();
      } else {
        params.alert_code = undefined;
      }

      if (values.subsidiaries.length > 0) {
        params.subsidiaries = values.subsidiaries.join();
      }

      if (values.brands.length > 0) {
        params.brands = values.brands.map((brand) => brand.brand_id).join();
      }

      if (values.models) {
        params.models = values.models.tire_model_id;
      }

      if (values.model_variations.length > 0) {
        params.model_variations = values.model_variations.join();
      }

      if (values.invoice_folio) {
        params.invoice_folio = values.invoice_folio;
      }

      if (values.invoice_date) {
        params.invoice_date = values.invoice_date;
      }

      if (values.DOT_initial) {
        params.DOT_initial = values.DOT_initial;
      }

      if (values.DOT_final) {
        params.DOT_final = values.DOT_final;
      }

      if (values.tire_size) {
        params.tire_size = values.tire_size;
      }

      if (values.providers) {
        params.providers = values.providers;
      }

      if (values.warehouses) {
        params.warehouses = values.warehouses;
      }

      if (values.vehicle) {
        params.vehicle = values.vehicle.vehicle_id;
      }

      if (values.condition) {
        params.condition = values.condition;
      }

      if (values.location) {
        params.location = values.location;
      }

      if (values.date_from && values.date_to) {
        params.date_from = values.date_from;
        params.date_to = values.date_to;
      }

      if (values.revitalized_brands) {
        params.revitalized_brands = values.revitalized_brands.brand_id;
      }

      if (values.revitalized_models) {
        params.revitalized_models =
          values.revitalized_models.revitalized_tire_model_id;
      }

      await setTireFilters(params);
      handleSelectedFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      subsidiaries={subsidiaries}
      brands={brands}
      revitalizedBrands={revitalizedBrands}
      tireModels={tireModels}
      tireModelVariations={tireModelVariations}
      revitalizedModels={revitalizedModels}
      tireSizes={tireSizes}
      providers={providers}
      warehouses={warehouses}
      vehicles={vehicles}
      alerts={alerts}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      setTireModels={setTireModels}
      setTireModelVariations={setTireModelVariations}
      setRevitalizedModels={setRevitalizedModels}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterTireForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Tire.Tire.filter_tire,
  account: state.Account.account,
  permits: state.Account.permits,
});

const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setTireFilters: actionSetTireFilters(dispatch),
  clearTireFilters: actionClearTireFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
  getProviders: actionGetProviders(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
  getVehicles: actionGetVehicles(dispatch),
  getAlerts: actionGetAlerts(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTireForm);
