import React from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";

import { actionGetWears } from "store/actions/wear/getWears";
import { actionClearWears } from "store/actions/wear/clearWears";
import { actionSetWearFilters } from "store/actions/wear/setWearFilters";
import { actionClearWearFilters } from "store/actions/wear/clearWearFilters";
import { actionDeleteWear } from "store/actions/wear/deleteWear";
import { actionSetWearStatus } from "store/actions/wear/setWearStatus";
import { actionGetWear } from "store/actions/wear/getWear";
import { actionUpdateWear } from "store/actions/wear/updateWear";

import { UPDATE_WEAR, DELETE_WEAR } from "store/actions/account/permits";

import Page from "./page";

export function HomeWear({ ...rest }) {
  const t = useLang();
  const {
    updateWear,
    deleteWear,
    setWearStatus,
    getWear,
    setWearFilters,
    clearWearFilters,
    wear,
    wears,
    permits,
    getWears,
    clearWears,
    filter,
  } = rest;

  return (
    <Page
      updateWearPermit={permits.includes(UPDATE_WEAR)}
      deleteWearPermit={permits.includes(DELETE_WEAR)}
      clearWearFilters={clearWearFilters}
      clearWears={clearWears}
      deleteWear={deleteWear}
      filter={filter}
      getWear={getWear}
      getWears={getWears}
      wear={wear}
      wears={wears}
      setWearFilters={setWearFilters}
      setWearStatus={setWearStatus}
      t={t}
      updateWear={updateWear}
    />
  );
}

HomeWear.propTypes = {};

const mapStateToProps = (state) => ({
  wears: state.Wear.Wear.wears,
  permits: state.Account.permits,
  filter: state.Wear.Wear.filter_wear,
  wear: state.Wear.Wear.wear,
});
const mapDispatchToProps = (dispatch) => ({
  getWears: actionGetWears(dispatch),
  clearWears: actionClearWears(dispatch),
  setWearFilters: actionSetWearFilters(dispatch),
  clearWearFilters: actionClearWearFilters(dispatch),
  deleteWear: actionDeleteWear(dispatch),
  setWearStatus: actionSetWearStatus(dispatch),
  getWear: actionGetWear(dispatch),
  updateWear: actionUpdateWear(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeWear);
