import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSubsidiaries } from "store/actions/subsidiary/getSubsidiaries";
import { actionClearTpmsFilters } from "store/actions/tpms/clearTpmsFilters";
import { actionSetTpmsFilters } from "store/actions/tpms/setTpmsFilters";

import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function FilterTpmsForm({ open, onClose, ...rest }) {
  const t = useLang();
  const {
    filter,
    getSubsidiaries,
    setNotification,
    setTpmsFilters,
    clearTpmsFilters,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setInitialValues,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      status: "",
      subsidiaries: [],
      search_code: "",
    },
  });

  const [subsidiaries, setSubsidiaries] = useState([]);
  useEffect(() => {
    if (open) {
      setInitialValues({
        status:
          filter.status !== undefined
            ? filter.status
              ? "enabled"
              : "disabled"
            : "",
        subsidiaries: filter.subsidiaries ? filter.subsidiaries.split(",") : [],
        search_code: filter.search_code ?? "",
      });
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getSubsidiaries(
          { scope: "subsidiary_id,name,status" },
          false
        );

        setSubsidiaries(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open]);

  function handleClean() {
    clearTpmsFilters();
    onClose();
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const params = { search_code: values.search_code };

      if (values.status !== "") {
        params.status = values.status === "enabled" ? 1 : 0;
      }

      if (values.subsidiaries.length > 0) {
        let subsidiaries = values.subsidiaries.join();
        params.subsidiaries = subsidiaries;
      }

      params.page = 1;
      await setTpmsFilters(params);
      setSubmitting(false);
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      initialErrors={initialErrors}
      initialValues={initialValues}
      subsidiaries={subsidiaries}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      handleValidate={handleFormikValidate}
      onClose={onClose}
      open={open}
      t={t}
    />
  );
}

FilterTpmsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  filter: state.Tpms.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getSubsidiaries: actionGetSubsidiaries(dispatch),
  setTpmsFilters: actionSetTpmsFilters(dispatch),
  clearTpmsFilters: actionClearTpmsFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterTpmsForm);
