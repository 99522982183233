export default {
  messages: {
    disable: "¿Estas seguro de deshabilitar la sucursal?",
    enable: "¿Estas seguro de habilitar la sucursal?",
  },
  buttons: {
    disabled_subsidiary: "Sucursal deshabilitada",
  },
  permits: {
    change_status: "Sin permisos para {action} sucursales",
  },
};
