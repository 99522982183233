import { combineReducers } from "redux";

import User from "./user";
import Corporate from "./corporate";
import Company from "./company";
import Subsidiary from "./subsidiary";

export default combineReducers({
  User,
  Corporate,
  Company,
  Subsidiary,
});
