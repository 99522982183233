import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useLang } from "hooks/lang";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireDamages } from "store/actions/tire/damage/getTireDamages";
import { actionClearTireDamages } from "store/actions/tire/damage/clearTireDamages";

import Page from "./page";

export function TireDamageTab({ ...rest }) {
  const t = useLang();
  const { id: tireId } = useParams();
  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState(null);
  const [imageDialogTitle, setImageDialogTitle] = useState("");
  const { damages, getTireDamages, setNotification, clearTireDamages } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireDamages(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearTireDamages();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [damages.length]);

  function handleOpenImage(img, title) {
    setImage(img);
    setImageDialogTitle(title);
    setOpenImage(true);
  }

  function handleCloseImage() {
    setImage(null);
    setImageDialogTitle("");
    setOpenImage(false);
  }
  return (
    <Page
      t={t}
      damages={damages}
      image={image}
      imageDialogTitle={imageDialogTitle}
      openImage={openImage}
      handleOpenImage={handleOpenImage}
      handleCloseImage={handleCloseImage}
    />
  );
}

TireDamageTab.propTypes = {};

const mapStateToProps = (state) => ({
  damages: state.Tire.Damage.damages,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireDamages: actionGetTireDamages(dispatch),
  clearTireDamages: actionClearTireDamages(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireDamageTab);
