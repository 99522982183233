import {
  GET_WAREHOUSE_TIRE,
  CLEAR_WAREHOUSE_TIRE,
  GET_WAREHOUSE_TIRES,
  CLEAR_WAREHOUSE_TIRES,
  ASSIGN_TIRE_TO_WAREHOUSE,
  DELETE_LINK_TIRE_WITH_WAREHOUSE,
  SET_WAREHOUSE_TIRE_FILTERS,
  CLEAR_WAREHOUSE_TIRE_FILTERS,
} from "store/actions";

const initialState = {
  warehouse_tire: {
    warehouse: {
      name: "",
    },
    tire: {
      code: "",
    },
    created_by: {
      name: "",
      last_name_1: "",
    },
    created_at: "",
  },

  tires: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WAREHOUSE_TIRE:
      return {
        ...state,
        warehouse_tire: action.payload,
      };

    case CLEAR_WAREHOUSE_TIRE:
      return {
        ...state,
        warehouse_tire: { ...initialState.warehouse_tire },
      };

    case GET_WAREHOUSE_TIRES:
      return {
        ...state,
        tires: action.payload,
      };

    case CLEAR_WAREHOUSE_TIRES:
      return {
        ...state,
        tires: initialState.tires,
      };

    case ASSIGN_TIRE_TO_WAREHOUSE:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: [action.payload, ...state.tires.data],
        },
      };

    case DELETE_LINK_TIRE_WITH_WAREHOUSE:
      return {
        ...state,
        tires: {
          ...state.tires,
          data: state.tires.data.filter(
            (tire) => tire.tire_id !== action.payload
          ),
        },
      };

    case SET_WAREHOUSE_TIRE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_WAREHOUSE_TIRE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };
    default:
      return state;
  }
}
