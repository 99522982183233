import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import CompanyTabs from "components/organisms/company/CompanyTabs";
import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import TableResponsive from "components/organisms/general/TableResponsive";
import Form from "components/organisms/general/Form";

import css from "./index.module.scss";

function Page({
  t,
  subsidiaryId,
  policy,
  openForm,
  updateSubsidiaryPolicyPermit,
  handleOpenForm,
  handleCloseForm,
  getSubsidiaryPolicy,
  updateSubsidiaryPolicy,
  getSubsidiary,
}) {
  return (
    <SubsidiaryTabs
      title={t("_general.policies_application")}
      className={classnames(css.container)}
    >
      <TableResponsive
        rows={[
          {
            id: policy.policy_id,
            key: policy.policy_id,
            number_cycle: policy.number_cycle,
            inspection: `${policy.inspection} ${t(
              "_labels.company_policies.days"
            )}`,
            pressure_type_axle: policy.pressure_type_axle
              ? t("_labels.yes")
              : t("_labels.no"),
            tolerance_inflation_pressure: `${policy.tolerance_inflation_pressure} %`,
            tolerance_mating_pressure: `${policy.tolerance_mating_pressure} psi`,
            tolerance_directional_mating_depth: `${policy.tolerance_directional_mating_depth} mm`,
            tolerance_mating_depth: `${policy.tolerance_mating_depth} mm`,
            helmet_policy: policy.helmet_policy
              ? t("_labels.yes")
              : t("_labels.no"),
            tire_rotation: `${policy.tire_rotation} km`,
            directional_tire_rotation: `${policy.directional_tire_rotation} km`,
            tire_condition_axle_type: policy.tire_condition_axle_type
              ? t("_labels.yes")
              : t("_labels.no"),
            show_alerts_different_assignment:
              policy.show_alerts_different_assignment
                ? t("_labels.yes")
                : t("_labels.no"),
            send_pressure_setting: policy.send_pressure_setting
              ? t("_labels.yes")
              : t("_labels.no"),
            use_checklist: policy.use_checklist
              ? t("_labels.yes")
              : t("_labels.no"),
            use_tpms: policy.use_tpms ? t("_labels.yes") : t("_labels.no"),
            operator_edit: policy.operator_edit
              ? t("_labels.yes")
              : t("_labels.no"),
            refection_review: policy.refection_review
              ? t("_labels.yes")
              : t("_labels.no"),
            no_tire: policy.no_tire ? t("_labels.yes") : t("_labels.no"),
            edit: {
              tooltipPermit: !updateSubsidiaryPolicyPermit
                ? t("_permits.update.company_policy")
                : "",
            },
          },
        ]}
        headers={[
          {
            id: "number_cycle",
            label: t("_labels.company_policies.number_cycle"),
          },
          {
            id: "inspection",
            label: t("_labels.company_policies.inspection"),
          },
          {
            id: "pressure_type_axle",
            label: t("_labels.company_policies.pressure_type_axle"),
          },
          {
            id: "tolerance_inflation_pressure",
            label: t("_labels.company_policies.tolerance_inflation_pressure"),
          },
          {
            id: "tolerance_mating_pressure",
            label: t("_labels.company_policies.tolerance_mating_pressure"),
          },
          {
            id: "tolerance_directional_mating_depth",
            label: t(
              "_labels.company_policies.mating_tolerance_directional_mating_depth"
            ),
          },
          {
            id: "tolerance_mating_depth",
            label: t("_labels.company_policies.mating_tolerance_mating_depth"),
          },
          {
            id: "helmet_policy",
            label: t("_labels.company_policies.helmet_policy"),
          },
          {
            id: "tire_rotation",
            label: t("_labels.company_policies.tire_rotation"),
          },
          {
            id: "directional_tire_rotation",
            label: t("_labels.company_policies.directional_tire_rotation"),
          },
          {
            id: "tire_condition_axle_type",
            label: t("_labels.company_policies.tire_condition_axle_type"),
          },
          {
            id: "show_alerts_different_assignment",
            label: t(
              "_labels.company_policies.show_alerts_different_assignment"
            ),
          },
          {
            id: "send_pressure_setting",
            label: t("_labels.company_policies.send_pressure_setting"),
          },
          {
            id: "use_checklist",
            label: t("_labels.company_policies.use_checklist"),
          },
          {
            id: "use_tpms",
            label: t("_labels.company_policies.use_tpms"),
          },
          {
            id: "operator_edit",
            label: t("_labels.company_policies.operator_edit"),
          },
          {
            id: "refection_review",
            label: t("_labels.company_policies.refection_review"),
          },
          {
            id: "no_tire",
            label: t("_labels.company_policies.no_tire"),
          },
          { id: "actions", label: "" },
        ]}
        onEdit={handleOpenForm}
      />

      <Form
        open={openForm}
        item={policy}
        id={subsidiaryId}
        name={"policy"}
        initialDefaultValues={{
          number_cycle: "",
          inspection: "",
          pressure_type_axle: false,
          tolerance_inflation_pressure: "",
          tolerance_mating_pressure: "",
          tolerance_directional_mating_depth: "",
          tolerance_mating_depth: "",
          helmet_policy: false,
          tire_rotation: "",
          directional_tire_rotation: "",
          tire_condition_axle_type: false,
          show_alerts_different_assignment: false,
          send_pressure_setting: false,
          use_checklist: false,
          use_tpms: false,
          operator_edit: false,
          refection_review: false,
          no_tire: false,
        }}
        fields={[
          {
            name: "number_cycle",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: 1,
                min: 0,
              },
            },
            label: t("_labels.company_policies.number_cycle"),
          },
          {
            name: "inspection",
            required: true,
            type: "number",
            label: t("_labels.company_policies.inspection"),
            inputProps: {
              inputProps: {
                min: 0,
                step: 1,
              },
            },
          },
          {
            name: "tolerance_inflation_pressure",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                min: 0,
                step: "any",
              },
            },
            label: t("_labels.company_policies.tolerance_inflation_pressure"),
          },
          {
            name: "tolerance_mating_pressure",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: "any",
                min: 0,
              },
            },
            label: t("_labels.company_policies.tolerance_mating_pressure"),
          },
          {
            name: "tolerance_directional_mating_depth",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: "any",
                min: 0,
              },
            },
            label: t(
              "_labels.company_policies.tolerance_directional_mating_depth"
            ),
          },
          {
            name: "tolerance_mating_depth",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: "any",
                min: 0,
              },
            },
            label: t("_labels.company_policies.tolerance_mating_depth"),
          },
          {
            name: "tire_rotation",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: 1,
                min: 0,
              },
            },
            label: t("_labels.company_policies.tire_rotation"),
          },
          {
            name: "directional_tire_rotation",
            required: true,
            type: "number",
            inputProps: {
              inputProps: {
                step: 1,
                min: 0,
              },
            },
            label: t("_labels.company_policies.directional_tire_rotation"),
          },
          {
            name: "pressure_type_axle",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.pressure_type_axle"),
          },
          {
            name: "helmet_policy",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.helmet_policy"),
          },
          {
            name: "tire_condition_axle_type",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.tire_condition_axle_type"),
          },
          {
            name: "show_alerts_different_assignment",
            required: false,
            component: "checkbox",
            label: t(
              "_labels.company_policies.show_alerts_different_assignment"
            ),
          },
          {
            name: "send_pressure_setting",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.send_pressure_setting"),
          },
          {
            name: "use_checklist",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.use_checklist"),
          },
          {
            name: "use_tpms",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.use_tpms"),
          },
          {
            name: "operator_edit",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.operator_edit"),
          },
          {
            name: "refection_review",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.refection_review"),
          },
          {
            name: "no_tire",
            required: false,
            component: "checkbox",
            label: t("_labels.company_policies.no_tire"),
          },
        ]}
        title={""}
        updateTitle={t("_titles.update.company_policy")}
        createdMessage={""}
        updatedMessage={t("_messages.updated.company_policy")}
        onClose={handleCloseForm}
        getAction={getSubsidiaryPolicy}
        addAction={() => {}}
        updateAction={async (...rest) => {
          try {
            await updateSubsidiaryPolicy(...rest);
            getSubsidiary(subsidiaryId);
          } catch (error) {
            throw error;
          }
        }}
        clearFiltersAction={() => {}}
      />
    </SubsidiaryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  policy: PropTypes.object.isRequired,
  openForm: PropTypes.bool.isRequired,
  updateSubsidiaryPolicyPermit: PropTypes.bool.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  getSubsidiaryPolicy: PropTypes.func.isRequired,
  updateSubsidiaryPolicy: PropTypes.func.isRequired,
  getSubsidiary: PropTypes.func.isRequired,
};

export default Page;
