import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "components/molecules/general/Button";
import Typography from "@material-ui/core/Typography";

import MenuIcon from "@material-ui/icons/Menu";

import css from "./index.module.scss";

function Page({ appBarRef, title, onClickMenu, options, children }) {
  return (
    <>
      <div
        style={
          appBarRef.current
            ? {
                marginBottom: `${appBarRef.current.offsetHeight}px`,
              }
            : {}
        }
        className={classnames(css.space, {
          [css.tabs]: children !== null && children !== undefined,
        })}
      ></div>
      <AppBar ref={appBarRef} position="fixed">
        <Toolbar>
          <Button
            color="inherit"
            classNameButton={classnames(css.mr8)}
            onClick={onClickMenu}
            variant="icon"
          >
            <MenuIcon />
          </Button>
          <Typography noWrap variant="h6">
            {title}
          </Typography>
          <div className={classnames(css.options)}>{options}</div>
        </Toolbar>
        {children}
      </AppBar>
    </>
  );
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  onClickMenu: PropTypes.func.isRequired,
  options: PropTypes.node,
  children: PropTypes.node,
  appBarRef: PropTypes.object,
};

export default Page;
