import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "resources/css/setenal.css";
import "resources/css/bootstrap.min.css";
import "resources/css/spaddings.css";
import "resources/css/sidebar.css";
import "./index.css";

import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "components/molecules/general/Button";
import ContainerTemplate from "components/templates/general/Container";
import FilterReviewPerformanceCompanyReport from "components/organisms/report/FilterReviewPerformanceCompanyReport";
import moment from "moment-timezone";
import VisibilityIcon from "@material-ui/icons/Visibility";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

function Page({
  t,
  data,
  filter,
  selectedFilter,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  handleGenerateReviewPerformanceExcel,
  handleClickVehicle,
  selectedVehiclesNoReview,
  handleClickVehiclesNoReview,
  selectedCreatedAt,
  setSelectedCreatedAt,
}) {
  function toggleTable(selector, key, type) {
    const elements = Array.from(
      document.querySelectorAll(
        `[data-id=${CSS.escape(removeCharacters(selector))}]`
      )
    );
    elements.forEach((element) => {
      if (element.dataset.type === type) {
        element.classList.toggle("collapse");
      }
    });

    elements
      .filter((item) => item.dataset.type === type)
      .forEach((element) => {
        if (element.classList.contains("collapse")) {
          Array.from(
            document.querySelectorAll(
              `[data-id*=${CSS.escape(removeCharacters(key))}]`
            )
          ).forEach((subelement) => {
            if (subelement.dataset.type === type && element != subelement) {
              subelement.classList.add("collapse");
            }
          });
        }
      });
  }

  return (
    <ContainerTemplate
      title={t("_titles.review_performance_company_report")}
      options={
        <Button
          classNameButton={classnames("bw")}
          variant={"icon"}
          onClick={handleOpenFilter}
        >
          <FilterListIcon />
        </Button>
      }
    >
      <div className="sidebar_font container-fluid pdl100">
        {Object.keys(filter).filter(
          (key) => !["sort_by", "order"].includes(key)
        ).length > 0 && (
          <div className="row px24 pt-3">
            <div className="col-md-6">
              <table className="table">
                <tbody>
                  {filter.corporate_id && (
                    <tr>
                      <td className="text-right sbgazul">Corporativo:</td>
                      <td>{selectedFilter.corporate}</td>
                    </tr>
                  )}
                  {filter.companies && (
                    <tr>
                      <td className="text-right sbgazul">Empresa:</td>
                      <td>{selectedFilter.companies}</td>
                    </tr>
                  )}

                  {filter.subsidiaries && (
                    <tr>
                      <td className="text-right sbgazul">Sucursal:</td>
                      <td>{selectedFilter.subsidiaries}</td>
                    </tr>
                  )}
                  {filter.date_from && filter.date_to && (
                    <>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.from")}:
                        </td>
                        <td>
                          {moment(selectedFilter.date_from).format("MMMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right sbgazul">
                          {t("_labels.date.to")}:
                        </td>
                        <td>
                          {moment(selectedFilter.date_to).format("MMMM YYYY")}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="row pdt12">
          <div className="col-md-12 text-center">
            <hr />
            <h1 className="sinmp">Reporte de Desempeño de Neumáticos</h1>
            <h5 className="sinmp">
              Este reporte muestra el desempeño de las revisiones realizadas a
              lo largo de un periodo mensual para indicar la cantidad de
              esfuerzo de trabajo realizado y todas las actividades que
              ocurrieron durante este tiempo
            </h5>
            <hr />
          </div>
        </div>

        <div className="row pb-2">
          <div className="col-md-12">
            <div className="container-fluid">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleGenerateReviewPerformanceExcel}
              >
                {t("_buttons.generate_excel")}
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="container-fluid">
              {Object.entries(data).map(([subsidiary, values]) => {
                return (
                  <React.Fragment key={subsidiary}>
                    <div className="row">
                      <div className="container-fluid">
                        <h4
                          className="text-center"
                          style={{
                            backgroundColor: "#002849",
                            color: "white",
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          {subsidiary}
                        </h4>
                        <div className="table-responsive">
                          <table className="table mb-5 text-center">
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "dimgrey",
                                  color: "white",
                                  height: 50,
                                  paddingTop: 10,
                                }}
                              >
                                <th scope="col" className="text-center">
                                  Mes
                                </th>
                                <th scope="col" className="text-center">
                                  Año
                                </th>
                                <th scope="col" className="text-center">
                                  Número de Daños
                                </th>
                                <th scope="col" className="text-center">
                                  Ponchaduras
                                </th>
                                <th scope="col" className="text-center">
                                  Neumáticos totales
                                </th>
                                <th scope="col" className="text-center">
                                  Número de Revitalizados
                                </th>
                                <th scope="col" className="text-center">
                                  Neumáticos Dados de Baja
                                </th>
                                <th scope="col" className="text-center">
                                  Neumáticos Revisados
                                </th>
                                <th scope="col" className="text-center">
                                  Número de Revisiones a Neumáticos
                                </th>
                                <th scope="col" className="text-center">
                                  Vehículos Revisados
                                </th>
                                <th scope="col" className="text-center">
                                  Vehículos Totales
                                </th>
                                <th scope="col" className="text-center">
                                  Vehículos Sin Revisar
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.map((row, index) => {
                                if (row.review_performance_id === "n") {
                                  return;
                                }
                                const numberVehiclesNoReview =
                                  row.number_vehicles_no_review;
                                return (
                                  <tr key={index}>
                                    <td className="text-center">
                                      {moment(row.created_at).format("MMMM")}
                                    </td>
                                    <td className="text-center">
                                      {new Date(row.created_at).getFullYear()}
                                    </td>
                                    <td className="text-center">
                                      {row.number_damage}
                                    </td>
                                    <td className="text-center">
                                      {row.punctures}
                                    </td>
                                    <td className="text-center">
                                      {row.number_tires ?? 0}
                                    </td>
                                    <td className="text-center">
                                      {row.number_revitalizations}
                                    </td>
                                    <td className="text-center">
                                      {row.number_tires_pile}
                                    </td>
                                    <td className="text-center">
                                      {row.number_tires_review}
                                    </td>
                                    <td className="text-center">
                                      {row.number_review}
                                    </td>
                                    <td className="text-center">
                                      {row.number_vehicles_review}
                                    </td>
                                    <td className="text-center">
                                      {row.number_vehicles}
                                    </td>
                                    <td className="text-center">
                                      {numberVehiclesNoReview === 0 ? (
                                        numberVehiclesNoReview
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            if (numberVehiclesNoReview > 0) {
                                              handleClickVehiclesNoReview(
                                                row.review_performance_company_id,
                                                row.company_id
                                              );
                                              setSelectedCreatedAt(
                                                row.created_at
                                              );
                                            }
                                          }}
                                        >
                                          {numberVehiclesNoReview}
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="container-fluid">
                        <table className="table table-condensed">
                          {Object.values(selectedVehiclesNoReview).length > 0 &&
                            Object.keys(selectedVehiclesNoReview).includes(
                              subsidiary
                            ) &&
                            Object.entries(
                              Object.values(selectedVehiclesNoReview)[0]
                            )
                              .filter((e) => typeof e[1] === "object")
                              .map(([division, divisionValues]) => (
                                <React.Fragment
                                  key={`${subsidiary}_${division}_${selectedCreatedAt}`}
                                >
                                  <thead className="text-center">
                                    <tr
                                      className="company"
                                      onClick={() =>
                                        toggleTable(
                                          `${division}_divisions`,
                                          division,
                                          "original"
                                        )
                                      }
                                    >
                                      <th className="text-left">{division}</th>
                                      <th></th>
                                      <th className="text-right col3"></th>
                                      <th className="text-right col3"></th>
                                      <th className="text-right col4"></th>
                                      <th className="text-right col5">
                                        {formatter.format(
                                          Object.values(
                                            selectedVehiclesNoReview
                                          )[0][division].statistics
                                        )}
                                      </th>
                                      <th className="text-right col6">
                                        {percentFormatter.format(
                                          Object.values(
                                            selectedVehiclesNoReview
                                          )[0][division].percent
                                        )}
                                        %
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(divisionValues)
                                      .filter((e) => typeof e[1] === "object")
                                      .map(
                                        ([vehicleType, vehicleTypeValues]) => (
                                          <React.Fragment key={vehicleType}>
                                            <tr
                                              data-type="original"
                                              data-id={removeCharacters(
                                                `${division}_divisions`
                                              )}
                                              className="division box collapse"
                                              onClick={() =>
                                                toggleTable(
                                                  `${division}_${vehicleType}_applications`,
                                                  vehicleType,
                                                  "original"
                                                )
                                              }
                                            >
                                              <td className="text-left">
                                                {vehicleType}
                                              </td>
                                              <td className="text-right col3"></td>
                                              <td className="text-right col3"></td>
                                              <td className="text-right col3"></td>
                                              <td className="text-right col4"></td>
                                              <td className="text-right col5">
                                                {formatter.format(
                                                  divisionValues[vehicleType]
                                                    .statistics
                                                )}
                                              </td>
                                              <td className="text-right col6">
                                                {percentFormatter.format(
                                                  divisionValues[vehicleType]
                                                    .percent
                                                )}
                                                %
                                              </td>
                                            </tr>
                                            {Object.entries(vehicleTypeValues)
                                              .filter(
                                                (e) => typeof e[1] === "object"
                                              )
                                              .map(([brand, brandValues]) => {
                                                return (
                                                  <React.Fragment key={brand}>
                                                    <tr
                                                      key={brand}
                                                      data-type="original"
                                                      data-id={removeCharacters(
                                                        `${division}_${vehicleType}_applications`
                                                      )}
                                                      className="application box collapse"
                                                      onClick={() => {
                                                        toggleTable(
                                                          `${division}_${vehicleType}_${brand}_sizes`,
                                                          brand,
                                                          "original"
                                                        );
                                                      }}
                                                    >
                                                      <td className="text-left">
                                                        {brand}
                                                      </td>
                                                      <td></td>
                                                      <td className="text-right col3"></td>
                                                      <td className="text-right col3"></td>
                                                      <td className="text-right col4"></td>
                                                      <td className="text-right col5">
                                                        {formatter.format(
                                                          vehicleTypeValues[
                                                            brand
                                                          ].statistics
                                                        )}
                                                      </td>
                                                      <td className="text-right col6">
                                                        {percentFormatter.format(
                                                          vehicleTypeValues[
                                                            brand
                                                          ].percent
                                                        )}
                                                        %
                                                      </td>
                                                    </tr>
                                                    {Object.keys(brandValues)
                                                      .filter((e) => !isNaN(e))
                                                      .map((vehicle) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              brandValues[
                                                                vehicle
                                                              ].vehicle_id
                                                            }
                                                            data-type="original"
                                                            data-id={removeCharacters(
                                                              `${division}_${vehicleType}_${brand}_sizes`
                                                            )}
                                                            className="box collapse"
                                                          >
                                                            <td className="text-left">
                                                              <Button
                                                                style={{
                                                                  color:
                                                                    "white",
                                                                }}
                                                                className="spanButton"
                                                                onClick={() => {
                                                                  handleClickVehicle(
                                                                    brandValues[
                                                                      vehicle
                                                                    ]
                                                                  );
                                                                }}
                                                              >
                                                                <VisibilityIcon className="mr-2" />
                                                                {
                                                                  brandValues[
                                                                    vehicle
                                                                  ]
                                                                    .economic_number
                                                                }
                                                              </Button>
                                                            </td>
                                                            <td></td>
                                                            <td className="text-right col3"></td>
                                                            <td className="text-right col3"></td>
                                                            <td className="text-right col4"></td>
                                                            <td className="text-right col5"></td>
                                                            <td className="text-right col6"></td>
                                                          </tr>
                                                        );
                                                      })}
                                                  </React.Fragment>
                                                );
                                              })}
                                          </React.Fragment>
                                        )
                                      )}
                                  </tbody>
                                </React.Fragment>
                              ))}
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <FilterReviewPerformanceCompanyReport
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </ContainerTemplate>
  );
}
Page.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  selectedFilter: PropTypes.object.isRequired,
  openFilter: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleGenerateReviewPerformanceExcel: PropTypes.func.isRequired,
  handleClickVehicle: PropTypes.func.isRequired,
  selectedVehiclesNoReview: PropTypes.object.isRequired,
  handleClickVehiclesNoReview: PropTypes.func.isRequired,
  selectedCreatedAt: PropTypes.string.isRequired,
  setSelectedCreatedAt: PropTypes.func.isRequired,
};

export default Page;

function removeCharacters(string) {
  return string.replaceAll(/[,\s\.\/]/g, "");
}
