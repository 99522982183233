import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireStatsReport } from "store/actions/reports/stats/getTireStatsReport";
import { actionClearTireStatsFilters } from "store/actions/reports/stats/clearTireStatsFilters";
import { actionGenerateTireStatsExcel } from "store/actions/reports/stats/generateTireStatsExcel";
import { actionSetSelectedStatsFilters } from "store/actions/reports/stats/setSelectedStatsFilters";
import { actionSetTireStatsFilters } from "store/actions/reports/stats/setTireStatsFilters";
import { actionShowProgress } from "store/actions/general/progress";

import { useLang } from "hooks/lang";
import { http } from "store/actions/http";

import Page from "./page";
import {
  getCompanyViaWorkArea,
  getCorporateViaWorkArea,
  getSubsidiaryViaWorkArea,
} from "utils/workArea";
import PropTypes from "prop-types";

const colors = {
  backgroundColor: [
    "rgba(255, 99, 132, 0.4)",
    "rgba(54, 162, 235, 0.4)",
    "rgba(255, 206, 86, 0.4)",
    "rgba(75, 192, 192, 0.4)",
    "rgba(153, 102, 255, 0.4)",
    "rgba(255, 159, 64, 0.4)",
  ],
  borderColor: [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ],
};

function TireStats({ current, ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [selectedKey, setSelectedKey] = useState("brand_name");
  const [hide, setHide] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [openTireDialog, setOpenTireDialog] = useState(false);
  const [loadingTireDialog, setLoadingTireDialog] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const {
    filter,
    account,
    workArea,
    selectedFilter,
    setNotification,
    getTireStatsReport,
    clearTireStatsFilters,
    generateTireStatsExcel,
    setSelectedFilters,
    setFilters,
    showProgress,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTireStatsReport({
          ...filter,
          group_by: selectedKey,
          current: current ? 1 : 0,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (Object.keys(filter).length > 0 && !firstRender) {
      fetchData();
    }
    if (firstRender) {
      setFirstRender(false);
    }
  }, [filter, selectedKey]);

  useEffect(() => {
    const corporate = getCorporateViaWorkArea(account, workArea);
    const company = getCompanyViaWorkArea(account, workArea)[0];
    const subsidiary = getSubsidiaryViaWorkArea(account, workArea)[0];
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    setFilters({
      corporate_id: corporate.corporate.corporate_id.toString(),
      company_id: company.company.company_id.toString(),
      subsidiary_id: subsidiary.subsidiary.subsidiary_id.toString(),
      date_from: !current ? startDate : undefined,
      date_to: !current ? endDate : undefined,
    });

    setSelectedFilters({
      corporate: corporate.corporate.name,
      companies: company.company.name,
      subsidiaries: subsidiary.subsidiary.name,
      date_from: !current ? startDate : undefined,
      date_to: !current ? endDate : undefined,
    });

    return () => {
      clearTireStatsFilters();
    };
  }, []);

  function handleClick(e, key) {
    e.preventDefault();
    setSelectedKey(key);
    setHide(true);
  }

  async function handleDetailsClick(search) {
    if (loadingTireDialog) {
      return;
    }
    const progress = showProgress();
    setLoadingTireDialog(true);

    const realFilters = { ...filter };
    if (filter.company_id) {
      realFilters.companies = filter.company_id;
      delete realFilters.company_id;
    }

    if (filter.subsidiary_id) {
      realFilters.subsidiaries = filter.subsidiary_id;
      delete realFilters.subsidiary_id;
    }
    try {
      const response = await http({
        method: "GET",
        path: `report/statistics/detail`,
        params: {
          movement: "MOUNT",
          ...realFilters,
          group_by: selectedKey,
          search_field: search,
          current: current ? 1 : 0,
        },
      });
      setDetailsData(response);
      setOpenTireDialog(true);
    } catch (error) {
      setNotification(error, true);
    } finally {
      showProgress(progress);
      setLoadingTireDialog(false);
    }
  }

  async function handleGenerateExcel() {
    try {
      await generateTireStatsExcel({ ...filter, current: current ? 1 : 0 });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      current={current}
      data={getChunkedData(data, selectedKey)}
      dataChart={getChartData(data, selectedKey, t)}
      hide={hide}
      selectedKey={selectedKey}
      openFilter={openFilter}
      handleClick={handleClick}
      handleDetailsClick={handleDetailsClick}
      setHide={setHide}
      filter={filter}
      selectedFilter={selectedFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
      detailsData={detailsData}
      openTireDialog={openTireDialog}
      setOpenTireDialog={setOpenTireDialog}
    />
  );
}
TireStats.propTypes = {
  current: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.Account.account,
  workArea: state.Account.workArea,
  filter: state.Reports.Stats.filter,
  selectedFilter: state.Reports.Stats.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getTireStatsReport: actionGetTireStatsReport(dispatch),
  clearTireStatsFilters: actionClearTireStatsFilters(dispatch),
  generateTireStatsExcel: actionGenerateTireStatsExcel(dispatch),
  setNotification: actionSetNotification(dispatch),
  setFilters: actionSetTireStatsFilters(dispatch),
  setSelectedFilters: actionSetSelectedStatsFilters(dispatch),
  showProgress: actionShowProgress(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireStats);

function getChunkedData(data, selectedKey) {
  if (data[selectedKey] && Object.keys(data).length > 0) {
    const likeArray =
      selectedKey !== "depth" && selectedKey !== "number_cycle"
        ? Object.entries(data[selectedKey]).sort((a, b) => b[1] - a[1])
        : Object.entries(data[selectedKey]).sort(
            (a, b) => a[0].split(" - ")[1] - b[0].split(" - ")[1]
          );
    if (likeArray.length > 3) {
      return splitToChunks([...likeArray], 2);
    }

    return splitToChunks([...likeArray], 1);
  }
  return Object.entries(data);
}

function splitToChunks(array, parts) {
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

function getChartData(data, selectedKey, t) {
  if (data[selectedKey] && Object.keys(data).length > 0) {
    const likeArray = Object.entries(data[selectedKey]);

    if (selectedKey === "depth") {
      likeArray.sort((a, b) => a[0].split(" - ")[1] - b[0].split(" - ")[1]);
      const labels = likeArray.map((data) => data[0]);
      const values = likeArray.map((data) => data[1]);
      const bgColors = [];
      const bColors = [];
      labels.forEach((label) => {
        if (label.split(" - ")[1] <= 4) {
          bgColors.push("rgba(255, 40, 40, 0.5)");
          bColors.push("rgba(255, 40 , 40, 1)");
        } else {
          bgColors.push("rgba(124, 181, 236, 0.6)");
          bColors.push("rgba(124, 181, 236, 1)");
        }
      });
      return {
        labels: labels,
        datasets: [
          {
            label: t("_labels.pile_stats_report.number_of_tires"),
            data: labels.map((_, index) => values[index]),
            backgroundColor: bgColors,
            borderColor: bColors,
            borderWidth: 1,
          },
        ],
      };
    }

    let counter = 0;
    const dataChart = likeArray.map((item) => {
      const bgColor = colors.backgroundColor[counter];
      const bColor = colors.borderColor[counter];
      if (counter === 5) {
        counter = 0;
      } else {
        counter++;
      }
      return {
        label: item[0],
        data: [item[1]],
        backgroundColor: bgColor,
        borderColor: bColor,
        borderWidth: 1,
      };
    });
    if (selectedKey === "number_cycle") {
      return dataChart;
    }
    return dataChart.sort((a, b) => b.data[0] - a.data[0]);
  }

  return Object.entries(data);
}
