import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetBlock } from "store/actions/block/getBlock";
import { actionClearBlock } from "store/actions/block/clearBlock";
import { actionGetBlocks } from "store/actions/block/getBlocks";
import { actionAddBlock } from "store/actions/block/addBlock";
import { actionUpdateBlock } from "store/actions/block/updateBlock";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function BlockForm({ open, onClose, blockId, ...rest }) {
  const t = useLang();
  const [isUpdate, setIsUpdate] = useState(blockId ? true : false);
  const {
    block,
    filter,
    setNotification,
    getBlocks,
    addBlock,
    updateBlock,
    getBlock,
    clearBlock,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        await getBlock(blockId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open && blockId) {
      fetchData();
    }

    return () => {
      clearBlock();
    };
  }, [open]);

  useEffect(() => {
    if (blockId && block.block_id) {
      setInitialValues({
        name: block.name,
        description: block.description,
      });
    }
  }, [block]);

  useEffect(() => {
    if (!open) {
      resetFormik();
    }

    setIsUpdate(blockId ? true : false);
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = values;

      if (isUpdate) {
        await updateBlock(fields, blockId);
        setNotification({
          message: t("_messages.updated.block"),
        });
      } else {
        await addBlock(fields);
        setNotification({
          message: t("_messages.created.block"),
        });
      }
      setSubmitting(false);
      resetForm();
      onClose();
      getBlocks({ ...filter });
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

BlockForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  blockId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  block: state.Block.block,
  filter: state.Block.filter_block,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getBlock: actionGetBlock(dispatch),
  clearBlock: actionClearBlock(dispatch),
  getBlocks: actionGetBlocks(dispatch),
  addBlock: actionAddBlock(dispatch),
  updateBlock: actionUpdateBlock(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockForm);
