import React from "react";
import RowPdf from "./RowPdf";
import { StyleSheet, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const TotalHeader = ({
  totalOriginal,
  priceOriginal,
  totalRevitalized,
  priceRevitalized,
}) => {
  return (
    <View style={styles.principal}>
      <View style={styles.grayRow} />
      <RowPdf col1={"Cantidad"} col2={"Monto"} />

      <View style={styles.grayRow} />
      <RowPdf
        titleText={"Total Originales"}
        col1={totalOriginal}
        col2={`$${formatter.format(priceOriginal)}`}
      />
      <RowPdf
        titleText={"Total Renovadas"}
        col1={totalRevitalized}
        col2={`$${formatter.format(priceRevitalized)}`}
      />
      <RowPdf
        titleText={"Total"}
        col1={totalOriginal + totalRevitalized}
        col2={`$${formatter.format(priceOriginal + priceRevitalized)}`}
      />

      <View style={styles.grayRow} />
    </View>
  );
};

TotalHeader.propTypes = {
  totalOriginal: PropTypes.number.isRequired,
  priceOriginal: PropTypes.number.isRequired,
  totalRevitalized: PropTypes.number.isRequired,
  priceRevitalized: PropTypes.number.isRequired,
};

export default TotalHeader;

const styles = StyleSheet.create({
  principal: {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  grayRow: {
    backgroundColor: "rgb(0, 40, 73)",
    height: "2px",
    width: "100%",
    borderRadius: "2px",
  },
});
