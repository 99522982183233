import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGenerateExcelFleetSemaphore =
  (dispatch) =>
  async ({
    corporate_id: corporateId,
    companies,
    subsidiaries,
    vehicle_types: vehicleTypes,
    only_empty_vehicles: onlyEmptyVehicles,
    search,
  }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: "report/fleet/semaphore/excel",
        params: {
          corporate_id: corporateId,
          companies,
          subsidiaries,
          vehicle_types: vehicleTypes,
          only_empty_vehicles: onlyEmptyVehicles,
          search,
        },
        download: true,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
