import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";

import Form from "./Form";

import css from "./index.module.scss";

function Page({
  open,
  isUpdate,
  commissionedVehicle,
  vehicles,
  initialErrors,
  initialValues,
  onClose,
  handleSubmit,
  handleValidate,
}) {
  return (
    <>
      <DialogMediaQuery maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>
          {!isUpdate
            ? `Vincular remolques al vehículo`
            : `Editar remolques al vehículo`}{" "}
          {commissionedVehicle?.economic_number}
        </DialogTitle>
        <DialogContent className={classnames(css.box)}>
          <Formik
            enableReinitialize
            initialErrors={initialErrors}
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={handleSubmit}
          >
            {(p) => (
              <Form
                isUpdate={isUpdate}
                vehicles={vehicles}
                onClose={onClose}
                {...p}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  commissionedVehicle: PropTypes.object,
  vehicles: PropTypes.array.isRequired,
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
};

export default Page;
