import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateTireSize =
  (dispatch) => async (fields, tireSizeId) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/size/${tireSizeId}`,
        data: fields,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
