import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import LabelAndText from "components/molecules/general/LabelAndText";
import LabelColorViewer from "components/molecules/general/LabelColorViewer";

import css from "./index.module.scss";

function Page({
  t,
  color,
  position,
  axleNumber,
  tireApplication,
  tireQuantityCount,
}) {
  return (
    <>
      <Typography display="block" variant="overline" color="textSecondary">
        {`${t("_labels.axle.singular")} ${axleNumber}`}
      </Typography>

      <div className={classnames(css.horizontal)}>
        <LabelAndText
          gutterBottom
          label={t("_labels.vehicle_type_axle_position.label")}
          text={position}
        />

        <LabelAndText
          gutterBottom
          text={tireQuantityCount.toString()}
          label={t("_labels.tire_quantity")}
        />

        <LabelColorViewer
          color={color}
          className={classnames(css.color_label)}
        />
      </div>

      <LabelAndText
        fullWidth
        gutterBottom
        text={tireApplication}
        label={t("_labels.tire_application.label.plural")}
      />

      <Divider className={classnames(css.w100)} />
    </>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  axleNumber: PropTypes.number.isRequired,
  tireApplication: PropTypes.string.isRequired,
  tireQuantityCount: PropTypes.number.isRequired,
};

export default Page;
