import React from "react";
import classnames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import css from "./index.module.scss";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

function Page({
  t,
  companyName,
  economicNumber,
  reviewType,
  isFinished,
  date,
  vehicleKm,
  userName,
  driverName,
  comment,
}) {
  return (
    <div className="table-responsive">
      <table className="table text-center">
        <thead style={{ backgroundColor: "#0061a4", color: "white" }}>
          <tr>
            <th colSpan={7}>
              {companyName}
              <br />
              {`${t("_labels.vehicle.singular")} ${economicNumber}`}
            </th>
          </tr>
          <tr>
            <th>{t("_labels.review_type.label")}</th>
            <th>{t("_labels.status")}</th>
            <th>{t("_labels.date.label")}</th>
            <th>{t("_general.vehicle_km")}</th>
            <th>{t("_labels.user.singular")}</th>
            <th>{t("_labels.driver.singular")}</th>
            <th>{t("_labels.comment.singular")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-bordered">
            <td className={classnames(css.table_cell)}>
              {t(
                `_labels.review_type.options.${reviewType
                  .toLowerCase()
                  .replace(/\s|\//g, "_")}`
              )}
            </td>
            <td
              className={classnames({
                [css.table_cell]: true,
                [css.error]: !isFinished,
              })}
            >
              {isFinished
                ? t("_labels.finalized")
                : t("_labels.inspection_in_process")}
            </td>
            <td className={classnames(css.table_cell)}>
              {moment(date).format("LL hh:mm:ss a")}
            </td>
            <td className={classnames(css.table_cell)}>
              {formatter.format(vehicleKm)} km
            </td>
            <td className={classnames(css.table_cell)}>{userName ?? "-"}</td>
            <td className={classnames(css.table_cell)}>{driverName ?? "-"}</td>
            <td className={classnames(css.table_cell)}>{comment ?? "-"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  economicNumber: PropTypes.string.isRequired,
  reviewType: PropTypes.string.isRequired,
  isFinished: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  vehicleKm: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  driverName: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

export default Page;
