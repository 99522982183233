import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTpmsAlert } from "store/actions/tpms/alert/getAlertByMount";
import { actionUpdateTpmsAlert } from "store/actions/tpms/alert/updateAlertByMount";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function UpdateTpmsAlertForm({
  open,
  onClose,
  alertId,
  vehicleTireId,
  ...rest
}) {
  const t = useLang();
  const { setNotification, getTpmsAlert, updateTpmsAlert } = rest;

  const initialDefaultValues = {
    comment: "",
  };
  const {
    initialValues,
    initialErrors,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: initialDefaultValues,
  });

  const [alert, setAlert] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getTpmsAlert(vehicleTireId, alertId);
        setAlert(data);
      } catch (error) {
        setNotification(error, true);
      }
    }
    if (alertId && open) {
      fetchData();
    }
  }, [alertId, open]);

  useEffect(() => {
    setInitialValues({
      comment: alert.comment || "",
    });
  }, [alert, alertId]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = values;
      await updateTpmsAlert(vehicleTireId, alertId, fields);
      setNotification({
        message: t("_messages.updated.alert"),
      });
      setSubmitting(false);
      resetForm();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }
  return (
    <Page
      t={t}
      open={open}
      alert={alert}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

UpdateTpmsAlertForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleTireId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alertId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = (state) => ({
  alert: state.MountAlerts.alert,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTpmsAlert: actionGetTpmsAlert(dispatch),
  updateTpmsAlert: actionUpdateTpmsAlert(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTpmsAlertForm);
