import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classnames from "classnames";
import Button from "components/molecules/general/Button";
import Home from "pages/general/Home";

import css from "./index.module.scss";

function Page({
  rfid,
  rfids,
  subsidiaries,
  createRfidPermit,
  updateRfidPermit,
  deleteRfidPermit,
  linkTirePermit,
  filter,
  t,
  handleOpenExcelForm,
  getRfid,
  addRfid,
  updateRfid,
  clearRfidFilters,
  setRfidFilters,
  deleteRfid,
  setRfidStatus,
  getRfids,
  clearRfids,
  handleUnlinkTire,
  openExcelForm,
  handleCloseExcelForm,
}) {
  return (
    <Home
      item={rfid}
      name="rfid"
      filter={filter}
      createTooltipPermit={!createRfidPermit ? t("_permits.create.rfid") : ""}
      currentPage={rfids.current_page}
      deleteConfirmationMessage={t("_messages.delete.rfid")}
      disableConfirmationMessage={t("_messages.change_status.rfid.disable")}
      enableConfirmationMessage={t("_messages.change_status.rfid.enable")}
      rows={rfids.data.map((rfid) => {
        return {
          id: rfid.rfid_id,
          key: rfid.rfid_id,
          status: {
            status: rfid.status,
            tooltipPermit: !updateRfidPermit ? t("_permits.update.rfid") : "",
          },
          subsidiary: rfid.subsidiary.name,
          device_code: rfid.device_code,
          code: rfid.tire.length > 0 ? rfid.tire[0].tire.code : "",
          created_at:
            moment(rfid.created_at).format("LL") +
            " " +
            moment(rfid.created_at).format("h:mm a"),
          edit: {
            tooltipPermit: !updateRfidPermit ? t("_permits.update.rfid") : "",
          },
          delete: {
            tooltipPermit: !deleteRfidPermit ? t("_permits.delete.rfid") : "",
          },
          link: {
            tooltipPermit: !linkTirePermit ? t("_permits.update.rfid") : "",
          },
          unlink: {
            tooltipPermit: !deleteRfidPermit ? t("_permits.delete_rfid") : "",
            onUnlink: () =>
              rfid.tire.length > 0
                ? handleUnlinkTire(rfid.rfid_id, rfid.tire[0].tire_id)
                : {},
          },
          tire: rfid.tire,
        };
      })}
      title={t("_general.rfid")}
      headers={[
        { id: "status", label: t("_labels.status") },
        { id: "subsidiary", label: t("_labels.subsidiary.singular") },
        { id: "device_code", label: t("_labels.device_code") },
        { id: "code", label: t("_general.tire") },
        { id: "created_at", label: t("_labels.created_at") },
        { id: "actions", label: "" },
      ]}
      lastPage={rfids.last_page}
      noItemsText={t("_general.no_rfid")}
      initialDefaultValues={{
        device_code: "",
        status: "",
        subsidiary_id: "",
      }}
      fields={[
        {
          name: "device_code",
          required: true,
          label: t("_labels.device_code"),
        },
        {
          name: "subsidiary_id",
          required: true,
          component: "select",
          disabled: "isUpdate",
          label: t("_labels.subsidiary_field.singular.label"),
          selectItems: subsidiaries.map((subsidiary) => ({
            value: subsidiary.subsidiary_id.toString(),
            name: subsidiary.name,
            disabled: !subsidiary.status,
          })),
        },
      ]}
      createFormTitle={t("_titles.new.rfid")}
      updateFormTitle={t("_titles.update.rfid")}
      filterFields={{
        status: {
          title: t("_titles.filter.status.rfid"),
        },
        date: true,
        subsidiaries: true,
      }}
      filterInitialDefaultValues={{
        status: "",
        subsidiaries: [],
        date_from: "",
        date_to: "",
      }}
      createdMessage={t("_messages.created.rfid")}
      updatedMessage={t("_messages.updated.rfid")}
      getItemAction={getRfid}
      setFiltersAction={setRfidFilters}
      clearFiltersAction={clearRfidFilters}
      deleteAction={deleteRfid}
      setStatusAction={setRfidStatus}
      getItemsAction={getRfids}
      clearItemsAction={clearRfids}
      addAction={addRfid}
      updateAction={updateRfid}
      withLinkTire={true}
      openExcelForm={openExcelForm}
      handleCloseExcelForm={handleCloseExcelForm}
    >
      <Button
        disabled={!createRfidPermit}
        tooltip={!createRfidPermit ? t("_permits.create.rfid") : ""}
        color="secondary"
        variant="contained"
        onClick={handleOpenExcelForm}
        className={classnames(css.excel_btn)}
      >
        {t("_buttons.register_by_excel")}
      </Button>
    </Home>
  );
}

Page.propTypes = {
  rfid: PropTypes.object.isRequired,
  rfids: PropTypes.object.isRequired,
  subsidiaries: PropTypes.array.isRequired,
  createRfidPermit: PropTypes.bool.isRequired,
  updateRfidPermit: PropTypes.bool.isRequired,
  deleteRfidPermit: PropTypes.bool.isRequired,
  linkTirePermit: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  getRfid: PropTypes.func.isRequired,
  addRfid: PropTypes.func.isRequired,
  updateRfid: PropTypes.func.isRequired,
  clearRfidFilters: PropTypes.func.isRequired,
  setRfidFilters: PropTypes.func.isRequired,
  deleteRfid: PropTypes.func.isRequired,
  setRfidStatus: PropTypes.func.isRequired,
  getRfids: PropTypes.func.isRequired,
  clearRfids: PropTypes.func.isRequired,
  handleUnlinkTire: PropTypes.func.isRequired,
  handleOpenExcelForm: PropTypes.func.isRequired,
  handleCloseExcelForm: PropTypes.func.isRequired,
  openExcelForm: PropTypes.bool.isRequired,
};

export default Page;
