import {
  GET_COMMISSIONED_VEHICLES,
  CLEAR_COMMISSIONED_VEHICLES,
  SET_COMMISSIONED_VEHICLE_STATUS,
  DELETE_COMMISSIONED_VEHICLE,
  GET_COMMISSIONED_VEHICLE,
  CLEAR_COMMISSIONED_VEHICLE,
  UPDATE_COMMISSIONED_VEHICLE,
  SET_COMMISSIONED_VEHICLE_FILTERS,
  CLEAR_COMMISSIONED_VEHICLE_FILTERS,
} from "store/actions";

const initialState = {
  vehicle: {
    commissioned_vehicle_id: 0,
    economic_number: "",
    enrollment: "",
    vehicle_id: "",
    vehicle_type_id: "",
    association_id: "",
    has_odometer: "",
  },
  vehicles: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSIONED_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };

    case CLEAR_COMMISSIONED_VEHICLES:
      return {
        ...state,
        vehicles: { ...initialState.vehicles },
      };

    case SET_COMMISSIONED_VEHICLE_STATUS:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.map((vehicle) =>
            vehicle.commissioned_vehicle_id !== action.payload
              ? vehicle
              : { ...vehicle, status: !vehicle.status }
          ),
        },
      };

    case DELETE_COMMISSIONED_VEHICLE:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.filter(
            (vehicle) => vehicle.commissioned_vehicle_id !== action.payload
          ),
        },
      };

    case UPDATE_COMMISSIONED_VEHICLE:
    case GET_COMMISSIONED_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
      };

    case CLEAR_COMMISSIONED_VEHICLE:
      return {
        ...state,
        vehicle: { ...initialState.vehicle },
      };

    case SET_COMMISSIONED_VEHICLE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_COMMISSIONED_VEHICLE_FILTERS:
      return {
        ...state,
        filter: { search: state.filter.search },
      };
    default:
      return state;
  }
}
