import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchInput from "components/molecules/general/SearchInput";
import AddIcon from "@material-ui/icons/Add";

import SubsidiaryTabs from "components/organisms/subsidiary/SubsidiaryTabs";
import TableMultiple from "components/organisms/general/TableMultiple";
import Pagination from "components/molecules/general/Pagination";
import DepthPolicyForm from "components/organisms/subsidiary/DepthPolicyForm";
import DepthPolicyFilterForm from "components/organisms/subsidiary/DepthPolicyFilterForm";
import MultipleDepthPolicyForm from "components/organisms/subsidiary/MultipleDepthPolicyForm";

import css from "./index.module.scss";

function Page({
  t,
  search,
  depthPoliciesData,
  subsidiaryId,
  vehicleId,
  policyId,
  vehicleIds,
  openForm,
  openFilter,
  openMultiple,
  createVehicleDepthPolicyPermit,
  updateVehicleDepthPolicyPermit,
  deleteVehicleDepthPolicyPermit,
  handleChangePage,
  handleOpenForm,
  handleCloseForm,
  handleOpenFilter,
  handleCloseFilter,
  handleOpenMultiple,
  handleCloseMultiple,
  handleSearch,
  handleEdit,
  handleDelete,
}) {
  return (
    <SubsidiaryTabs
      className={classnames({
        [css.empty]: depthPoliciesData.data.length === 0,
      })}
      optionsAppBar={
        <>
          <Button
            onClick={handleOpenFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search || ""} onChange={handleSearch} />
        </>
      }
    >
      {depthPoliciesData.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableMultiple
              headers={[
                { label: t("_general.vehicles"), colspan: 6 },
                { label: t("_labels.axle.plural"), colspan: 5 },
              ]}
              rows={depthPoliciesData.data.map((vehicle) => ({
                id: vehicle.vehicle_id,
                subsidiary: vehicle.subsidiary.name,
                division:
                  vehicle.division.length > 0 ? vehicle.division[0].name : "",
                brand: vehicle.vehicle_brand.name,
                economic_number: vehicle.economic_number,
                type_of_vehicle: vehicle.vehicle_type.name,
                add: (
                  <Button
                    variant="icon"
                    color="secondary"
                    disabled={!createVehicleDepthPolicyPermit}
                    tooltip={
                      !createVehicleDepthPolicyPermit
                        ? t("_permits.create.depth_policy")
                        : ""
                    }
                    onClick={() => handleOpenForm(vehicle.vehicle_id)}
                  >
                    <AddIcon />
                  </Button>
                ),
                multiple:
                  vehicle.vehicle_depth_policy.length > 0
                    ? vehicle.vehicle_depth_policy.map((policy) => ({
                        id: policy.vehicle_depth_policy_id,
                        axle: t(
                          `_labels.axle_field.options.${policy.axle_type.toLowerCase()}`
                        ),
                        // good_depth: `${policy.good_depth} mm`,
                        scheduled_withdrawal: `${policy.scheduled_withdrawal} mm`,
                        critical_withdrawal: `${policy.critical_withdrawal} mm`,
                        edit: {
                          tooltipPermit: !updateVehicleDepthPolicyPermit
                            ? t("_permits.update.depth_policy")
                            : "",
                          onEdit: () =>
                            handleEdit(
                              vehicle.vehicle_id,
                              policy.vehicle_depth_policy_id
                            ),
                        },
                        delete: {
                          tooltipPermit: !deleteVehicleDepthPolicyPermit
                            ? t("_permits.delete.depth_policy")
                            : "",
                          onDelete: () =>
                            handleDelete(
                              subsidiaryId,
                              vehicle.vehicle_id,
                              policy.vehicle_depth_policy_id
                            ),
                        },
                      }))
                    : [{ id: "", axle: "" }],
              }))}
              propertys={[
                "subsidiary",
                "division",
                "brand",
                "economic_number",
                "type_of_vehicle",
                "add",
                "multiple",
              ]}
              nestedPropertys={[
                "axle",
                // "good_depth",
                "scheduled_withdrawal",
                "critical_withdrawal",
              ]}
              nestedHeaders={[
                t("_labels.subsidiary.singular"),
                t("_labels.division.singular"),
                t("_labels.brand"),
                t("_labels.economic_number"),
                t("_labels.vehicle_type.label"),
                "",
                t("_labels.axle.singular"),
                // t("_labels.good_condition"),
                t("_labels.scheduled_withdrawal"),
                t("_labels.critical_withdrawal"),
                "",
              ]}
              multipleProperty={"multiple"}
              selectable={createVehicleDepthPolicyPermit}
              selectAction={handleOpenMultiple}
              selectActionButton={"Establecer"}
              totalRows={depthPoliciesData.total}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={depthPoliciesData.current_page}
              totalPages={depthPoliciesData.last_page}
              onChangePage={handleChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_depth_policies")} />
      )}

      <DepthPolicyForm
        open={openForm}
        subsidiaryId={subsidiaryId}
        vehicleId={vehicleId}
        depthPolicyId={policyId}
        onClose={handleCloseForm}
      />

      <DepthPolicyFilterForm open={openFilter} onClose={handleCloseFilter} />

      <MultipleDepthPolicyForm
        open={openMultiple}
        vehicleIds={vehicleIds}
        subsidiaryId={subsidiaryId}
        onClose={handleCloseMultiple}
      />
    </SubsidiaryTabs>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  search: PropTypes.string,
  depthPoliciesData: PropTypes.object.isRequired,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  policyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleIds: PropTypes.array,
  openForm: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  openMultiple: PropTypes.bool.isRequired,
  updateVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  deleteVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  createVehicleDepthPolicyPermit: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleOpenMultiple: PropTypes.func.isRequired,
  handleCloseMultiple: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Page;
