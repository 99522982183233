export default {
  title: "New revitalized tire model",
  update: "Update revitalized tire model",
  messages: {
    revitalized_tire_model_created:
      "Done, the revitalized tire model has been successfully created",
    revitalized_tire_model_updated:
      "Done, the revitalized tire model has been successfully updated",
    revitalized_tire_model_deleted:
      "Done, the revitalized tire model has been successfully deleted",
    delete: "Are you sure to delete the revitalized tire model?",
  },
  form: {
    labels: {
      brand_field: "Select a brand",
    },
    permits: {
      update: "No permissions to update revitalized tire models",
      delete: "No permissions to delete revitalized tire models",
    },
  },
};
