import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionClearCommissionedVehicleFilters } from "store/actions/commissioned/vehicle/clearCommissionedVehicleFilters";
import { actionGetCommissionedVehicles } from "store/actions/commissioned/vehicle/getCommissionedVehicles";
import { actionAddVehicleFull } from "store/actions/commissioned/vehicle_full/addVehicleFull";
import { actionGetVehicleFull } from "store/actions/commissioned/vehicle_full/getVehicleFull";
import { actionUpdateVehicleFull } from "store/actions/commissioned/vehicle_full/updateVehicleFull";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

export function VehicleFullForm({
  open,
  onClose,
  commissionedVehicle,
  ...rest
}) {
  const t = useLang();

  const {
    setNotification,
    getVehicles,
    addVehicleFull,
    getVehicleFull,
    updateVehicleFull,
    clearCommissionedVehicleFilters,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      vehicles: [null],
    },
  });

  const [isUpdate, setIsUpdate] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleFull, setVehicleFull] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getVehicles(
          {
            status: 1,
            structure_type: "DOLLY,BOX",
            scope: "commissioned_vehicle_id,economic_number,status",
          },
          false
        );

        setVehicles(data.sort(alphabeticalSort("economic_number")));
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open && isUpdate) {
      const vehicleIds = vehicles.map(
        (vehicle) => vehicle.commissioned_vehicle_id
      );
      const values = vehicleFull
        .filter((vehicle) =>
          vehicleIds.includes(vehicle.commissioned_vehicle_id)
        )
        .map((vehicle) => ({
          commissioned_vehicle_id: vehicle.commissioned_vehicle_id,
          economic_number: vehicle.commissioned_vehicle.economic_number,
        }));
      setInitialValues({
        vehicles: values.length > 0 ? values : [null],
      });
    }
  }, [open, vehicleFull, isUpdate]);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getVehicleFull(
          commissionedVehicle.commissioned_vehicle_id
        );
        setVehicleFull(result);

        if (result.length > 0) {
          setIsUpdate(true);
        } else {
          setIsUpdate(false);
        }
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      const fields = {
        vehicles: values.vehicles
          .filter((vehicle) => vehicle != null)
          .map((vehicle, index) => ({
            commissioned_vehicle_id: vehicle.commissioned_vehicle_id,
            position: index + 2,
          })),
      };

      if (isUpdate) {
        await updateVehicleFull(
          commissionedVehicle.commissioned_vehicle_id,
          fields
        );
        setNotification({
          message: t("_messages.updated.vehicle"),
        });
      } else {
        await addVehicleFull(
          commissionedVehicle.commissioned_vehicle_id,
          fields
        );
        setNotification({
          message: t("_messages.created.vehicle"),
        });
      }

      setSubmitting(false);
      resetForm();
      clearCommissionedVehicleFilters();
      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      isUpdate={isUpdate}
      vehicles={vehicles}
      commissionedVehicle={commissionedVehicle}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

VehicleFullForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  commissionedVehicle: PropTypes.object,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicles: actionGetCommissionedVehicles(dispatch),
  clearCommissionedVehicleFilters:
    actionClearCommissionedVehicleFilters(dispatch),
  addVehicleFull: actionAddVehicleFull(dispatch),
  getVehicleFull: actionGetVehicleFull(dispatch),
  updateVehicleFull: actionUpdateVehicleFull(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFullForm);
