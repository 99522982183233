import React from "react";
import PropTypes from "prop-types";
import "react-virtualized/styles.css";
import { AutoSizer, Column, Table } from "react-virtualized";

export function Page({ rows, columns, /* rowRenderer,  width, */ height }) {
  return (
    <div
      style={{
        width: "100%",
        height: rows.length > 6 ? height : (rows.length + 1) * 50,
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            headerStyle={{
              backgroundColor: "lightgray",
              color: "black",
            }}
            gridStyle={{
              backgroundColor: "white",
              color: "black",
            }}
          >
            {columns.map((column, index) => (
              <Column
                key={`${column.dataKey}_${index}`}
                width={
                  column.dataKey == "model"
                    ? 180
                    : column.dataKey == "location"
                    ? 155
                    : 130
                }
                flexGrow={1}
                dataKey={column.dataKey}
                label={column.label}
                style={{
                  // borderBottom: "1px solid #ddd",
                  margin: 0,
                }}
                cellRenderer={({ cellData }) => {
                  return (
                    <table className="table m-0">
                      <tbody>
                        <tr>
                          <td
                            className="text-muted"
                            title={
                              typeof cellData !== "object"
                                ? cellData
                                : undefined
                            }
                          >
                            {cellData}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                }}
                headerRenderer={({ label }) => {
                  return (
                    <table>
                      <tbody>
                        <tr>
                          <td
                            className="text-capitalize p-0 font-weight-normal"
                            style={{
                              border: "none",
                            }}
                          >
                            {label}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                }}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </div>
  );
}

Page.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
export default Page;
