import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Typography from "@material-ui/core/Typography";
import Button from "components/molecules/general/Button";
import LabelAndText from "components/molecules/general/LabelAndText";
import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import { arrayJoin } from "utils/parse";
import { getMinDepth } from "utils/tire";

import css from "./index.module.scss";

function Page({
  t,
  open,
  vehicleTire,
  onClose,
  onSend,
  onTireReview,
  onDamagesAndWear,
  onPhysicalDifference,
  onDamagesAndRepair,
  createTirePilePermit,
  createTireRepairPermit,
  createTireRevitalizationPermit,
  updateTireReviewPermit,
  isValidReview,
  isUpdateReview,
  policyAllowsEdit,
}) {
  return (
    <DialogMediaQuery maxWidth="sm" size={"xs"} open={open} onClose={onClose}>
      <DialogTitle>{t("_general.tire")}</DialogTitle>
      <DialogContent className={classnames(css.pt0)}>
        <div className={classnames(css.flex, css.content_info)}>
          <div className={classnames(css.horizontal)}>
            {vehicleTire.code && (
              <LabelAndText
                gutterBottom
                label={t("_labels.burn_code")}
                text={vehicleTire.code}
              />
            )}

            {vehicleTire.device_code && (
              <LabelAndText
                gutterBottom
                label={t("_labels.device_code")}
                text={vehicleTire.device_code}
              />
            )}
          </div>
          <div className={classnames(css.horizontal)}>
            <LabelAndText
              gutterBottom
              label={t("_labels.brand")}
              text={vehicleTire.cycle?.variation.tire_model.brand.name || "- -"}
            />

            <LabelAndText
              gutterBottom
              label={t("_labels.tire_model_field.label")}
              text={vehicleTire.cycle?.variation.tire_model.name || "- -"}
            />

            <LabelAndText
              gutterBottom
              label={t("_labels.size")}
              text={vehicleTire.cycle?.variation.tire_size.size || "- -"}
            />
          </div>

          {vehicleTire.cycle.revitalized && (
            <div className={classnames(css.horizontal)}>
              <LabelAndText
                gutterBottom
                label={t("_labels.revitalized_brand_field.label")}
                text={vehicleTire.cycle.revitalized.brand.name}
              />

              <LabelAndText
                gutterBottom
                label={t("_labels.revitalized_model_field.label")}
                text={vehicleTire.cycle.revitalized.name || "- -"}
              />
            </div>
          )}

          <div className={classnames(css.horizontal)}>
            <LabelAndText
              gutterBottom
              label={t("_labels.tire_model.use")}
              text={arrayJoin(
                vehicleTire.cycle.variation.tire_model_variation_use.map(
                  (use) =>
                    t(
                      `_labels.tire_use.options.${use.tire_use_id.toLowerCase()}`
                    )
                ),
                ", ",
                ` ${t("_general.text.and")} `
              )}
            />

            <LabelAndText
              gutterBottom
              label={t("_labels.tire_model.application")}
              text={
                vehicleTire.cycle.revitalized
                  ? t(
                      `_labels.tire_application.options.${vehicleTire.cycle.revitalized.tire_application_id.toLowerCase()}`
                    )
                  : t(
                      `_labels.tire_application.options.${vehicleTire.cycle.variation.tire_application_id.toLowerCase()}`
                    )
              }
            />
          </div>

          <div className={classnames(css.horizontal)}>
            <LabelAndText
              gutterBottom
              label={t("_labels.pressure")}
              text={`${
                vehicleTire.cycle.movement_tire.tire_review.length > 0
                  ? vehicleTire.cycle.movement_tire.tire_review[
                      vehicleTire.cycle.movement_tire.tire_review.length - 1
                    ].pressure
                  : "-"
              } psi`}
            />

            <LabelAndText
              gutterBottom
              label={t("_labels.depth")}
              text={
                vehicleTire.cycle.movement_tire.tire_review.length > 0 &&
                vehicleTire.cycle.movement_tire.tire_review[0]
                  .tire_review_depth_line
                  ? `${getMinDepth(
                      vehicleTire.cycle.movement_tire.tire_review[
                        vehicleTire.cycle.movement_tire.tire_review.length - 1
                      ].tire_review_depth_line
                    )} mm`
                  : ""
              }
            />
          </div>

          <Typography display="block" variant="overline" color="textSecondary">
            {t("_labels.axle_information")}
          </Typography>

          <div className={classnames(css.horizontal)}>
            <LabelAndText
              gutterBottom
              label={t("_labels.axle_position")}
              text={vehicleTire.cycle?.movement_tire.vehicle_tire[0].vehicle_type_axle_tire.vehicle_type_axle.axle_number.toString()}
            />

            <LabelAndText
              gutterBottom
              label={t("_labels.tire_position")}
              text={vehicleTire.cycle?.movement_tire.vehicle_tire[0].vehicle_type_axle_tire.position.toString()}
            />
          </div>

          <LabelAndText
            fullWidth
            gutterBottom
            label={t("_labels.axle_applications")}
            text={arrayJoin(
              vehicleTire.cycle.movement_tire.vehicle_tire[0].vehicle_type_axle_tire.vehicle_type_axle.tire_application.map(
                (application) =>
                  t(
                    `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
                  )
              ),
              ", ",
              ` ${t("_general.text.and")} `
            )}
          />
        </div>

        <Typography display="block" variant="overline" color="textSecondary">
          {t("_labels.tire_actions")}
        </Typography>

        <DialogActions className={classnames(css.actions)}>
          <div
            className={classnames(css.action, {
              [css.action_start]: !onSend,
            })}
          >
            {!!onSend ? (
              <>
                {isValidReview && (
                  <>
                    <Button
                      color="secondary"
                      component="button"
                      variant="contained"
                      disabled={!createTireRepairPermit}
                      tooltip={
                        !createTireRepairPermit
                          ? t("_permits.create.tire_repair")
                          : ""
                      }
                      onClick={() =>
                        onSend(
                          vehicleTire.cycle.movement_tire.movement_tire_id,
                          "REPAIR"
                        )
                      }
                    >
                      {t("_buttons.actions.repair")}
                    </Button>
                    <Button
                      color="secondary"
                      component="button"
                      variant="contained"
                      disabled={
                        !createTireRevitalizationPermit ||
                        vehicleTire.cycle.number_cycle + 1 >
                          vehicleTire.subsidiary.policy.number_cycle
                      }
                      tooltip={
                        !createTireRevitalizationPermit
                          ? t("_permits.create.tire_revitalization")
                          : vehicleTire.cycle.number_cycle + 1 >
                            vehicleTire.subsidiary.policy.number_cycle
                          ? t("_messages.cycle")
                          : ""
                      }
                      onClick={() =>
                        onSend(
                          vehicleTire.cycle.movement_tire.movement_tire_id,
                          "REVITALIZATION"
                        )
                      }
                    >
                      {t("_buttons.actions.revitalize")}
                    </Button>
                    <Button
                      color="secondary"
                      component="button"
                      variant="contained"
                      onClick={() =>
                        onSend(
                          vehicleTire.cycle.movement_tire.movement_tire_id,
                          "WAREHOUSE"
                        )
                      }
                    >
                      {t("_general.store")}
                    </Button>
                    <Button
                      color="secondary"
                      component="button"
                      variant="contained"
                      disabled={!createTirePilePermit}
                      tooltip={
                        !createTirePilePermit
                          ? t("_permits.create.tire_pile")
                          : ""
                      }
                      onClick={() =>
                        onSend(
                          vehicleTire.cycle.movement_tire.movement_tire_id,
                          "PILE"
                        )
                      }
                    >
                      {t("_buttons.actions.discard")}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  color="secondary"
                  component="button"
                  variant="contained"
                  onClick={() => {
                    onTireReview();
                    onClose();
                  }}
                  disabled={
                    isUpdateReview &&
                    (!policyAllowsEdit || !updateTireReviewPermit)
                  }
                  tooltip={
                    isUpdateReview
                      ? !policyAllowsEdit
                        ? t("_messages.update.tire_review_policy_denied")
                        : !updateTireReviewPermit
                        ? t("_permits.update.tire_review")
                        : ""
                      : ""
                  }
                >
                  {isUpdateReview
                    ? t("_buttons.update_tire_review")
                    : t("_buttons.review")}
                </Button>
                <Button
                  color="secondary"
                  component="button"
                  variant="contained"
                  onClick={() => {
                    onDamagesAndWear(true);
                    onClose();
                  }}
                >
                  {t("_buttons.damages")}
                </Button>
                <Button
                  color="secondary"
                  component="button"
                  variant="contained"
                  onClick={() => {
                    onDamagesAndWear(false);
                    onClose();
                  }}
                >
                  {t("_buttons.wear")}
                </Button>
                <Button
                  color="secondary"
                  component="button"
                  variant="contained"
                  onClick={() => {
                    onDamagesAndRepair(true);
                    onClose();
                  }}
                >
                  {t("_buttons.road_damage")}
                </Button>
              </>
            )}
          </div>
          <div
            className={classnames(css.action, {
              [css.action_end]: !!onSend,
              [css.ml0]: !onSend,
              [css.mt2]: !onSend,
            })}
          >
            {!onSend && (
              <Button
                color="secondary"
                className={classnames(css.ml0, css.mb8)}
                onClick={onPhysicalDifference}
              >
                {t("_buttons.actions.tire_mismatch")}
              </Button>
            )}

            <Button
              color="secondary"
              className={classnames(css.mr8, css.mb8)}
              onClick={onClose}
            >
              {t("_buttons.close")}
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </DialogMediaQuery>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  vehicleTire: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func,
  onTireReview: PropTypes.func,
  onDamagesAndWear: PropTypes.func,
  onPhysicalDifference: PropTypes.func.isRequired,
  onDamagesAndRepair: PropTypes.func,
  createTireRevitalizationPermit: PropTypes.bool.isRequired,
  createTireRepairPermit: PropTypes.bool.isRequired,
  createTirePilePermit: PropTypes.bool.isRequired,
  updateTireReviewPermit: PropTypes.bool.isRequired,
  isValidReview: PropTypes.bool.isRequired,
  review: PropTypes.object,
  isUpdateReview: PropTypes.bool.isRequired,
  policyAllowsEdit: PropTypes.bool.isRequired,
};

export default Page;
