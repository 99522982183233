import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionUpdateDepthTolerancePolicy } from "store/actions/subsidiary/policy/ToleranceDepthApplication/updateDepthTolerancePolicy";
import { actionGetDepthTolerancePolicies } from "store/actions/subsidiary/policy/ToleranceDepthApplication/getDepthTolerancePolicies";
import { actionClearDepthTolerancePolicies } from "store/actions/subsidiary/policy/ToleranceDepthApplication/clearDepthTolerancePolicies";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { UPDATE_DEPTH_TOLERANCE_POLICY } from "store/actions/account/permits";

import Page from "./page";

export function ApplicationTirePolicyTab({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const {
    permits,
    policies,
    updateDepthTolerancePolicy,
    getDepthTolerancePolicies,
    clearDepthTolerancePolicies,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [depthTolerancePolicyId, setDepthTolerancePolicyId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getDepthTolerancePolicies(id);
      } catch (error) {
        actionSetNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearDepthTolerancePolicies();
    };
  }, []);

  function handleOpenForm(id) {
    setDepthTolerancePolicyId(id);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setDepthTolerancePolicyId(null);
    setOpenForm(false);
  }

  return (
    <Page
      t={t}
      id={id}
      depthTolerancePolicyId={depthTolerancePolicyId}
      openForm={openForm}
      updateDepthTolerancePolicyPermit={permits.includes(
        UPDATE_DEPTH_TOLERANCE_POLICY
      )}
      getDepthTolerancePolicies={getDepthTolerancePolicies}
      policies={policies}
      updateDepthTolerancePolicy={updateDepthTolerancePolicy}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
    />
  );
}

ApplicationTirePolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  policies: state.Subsidiary.depthTolerancePolicy.Policy.policies,
});

const mapDispatchToProps = (dispatch) => ({
  getDepthTolerancePolicies: actionGetDepthTolerancePolicies(dispatch),
  updateDepthTolerancePolicy: actionUpdateDepthTolerancePolicy(dispatch),
  clearDepthTolerancePolicies: actionClearDepthTolerancePolicies(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationTirePolicyTab);
