import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "hooks/formik";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionUpdateVehicleReviewHistory } from "store/actions/vehicle/review/updateVehicleReviewHistory";
import { UPDATE_VEHICLE_REVIEW } from "store/actions/account/permits";

import Page from "./page";

const formatter = new Intl.NumberFormat("en", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: "unit",
  unit: "kilometer",
});

function UpdateReviewRowForm({ fetchData, ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    permits,
    vehicleReviewId,
    nextOdometer,
    previousOdometer,
    setNotification,
    updateVehicleReviewHistory,
  } = rest;
  const {
    initialValues,
    initialErrors,
    // resetFormik,
    handleFormikValidate,
    // setInitialValues,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      odometer: "",
    },
  });

  const formRef = useRef();
  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    try {
      await updateVehicleReviewHistory(vehicleId, vehicleReviewId, values);

      setNotification({ message: t("_messages.updated.vehicle_review") });
      resetForm();
      fetchData();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  function handleValidate(values) {
    let errors = handleFormikValidate(values);

    if (values.odometer === "" || values.odometer < 0) {
      return errors;
    }

    if (nextOdometer && values.odometer > nextOdometer) {
      errors.odometer = t("_errors.next_odometer", {
        value: formatter.format(nextOdometer),
      });
    }

    if (previousOdometer && values.odometer < previousOdometer) {
      errors.odometer = t("_errors.previous_odometer", {
        value: formatter.format(previousOdometer),
      });
    }

    return errors;
  }

  function confirmSubmit() {
    const { odometer } = formRef.current.values;

    formRef.current.setSubmitting(false);
    setNotification({
      textAction: t("_buttons.confirm"),
      onAction: () => {
        formRef.current.setSubmitting(true);
        handleSubmit(formRef.current.values, { ...formRef.current });
      },
      message: t("_messages.update.vehicle_review", {
        odometer: formatter.format(odometer),
      }),
    });
  }
  return (
    <Page
      t={t}
      initialValues={initialValues}
      initialErrors={initialErrors}
      handleValidate={handleValidate}
      handleSubmit={handleSubmit}
      confirmSubmit={confirmSubmit}
      formRef={formRef}
      updateVehicleReviewPermit={permits.includes(UPDATE_VEHICLE_REVIEW)}
    />
  );
}

UpdateReviewRowForm.propTypes = {
  vehicleReviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  nextOdometer: PropTypes.number,
  previousOdometer: PropTypes.number,
  fetchData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  updateVehicleReviewHistory: actionUpdateVehicleReviewHistory(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateReviewRowForm);
