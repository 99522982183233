import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetWarehouses } from "store/actions/warehouse/getWarehouses";
import { actionMountTire } from "store/actions/vehicle/mount/mountTire";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireSizes } from "store/actions/tire/size/getTireSizes";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

function TireMountForm({ ...rest }) {
  const t = useLang();
  const { id: vehicleId } = useParams();
  const {
    open,
    axleTireInfo,
    onClose,
    setNotification,
    getWarehouses,
    getBrands,
    getTireSizes,
    mountTire,
    getVehicleAxles,
  } = rest;

  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    setInitialValues,
    handleFormikValidate,
  } = useFormik({
    initialValues: {
      warehouse_id: "",
      brand_id: "",
      revitalized_brand_id: "",
      tire_model_id: "",
      revitalized_tire_model_id: "",
      condition: "",
      tire_size: "",
      depth_min: "",
      depth_max: "",
      tire: {},
    },
  });

  const [warehouses, setWarehouses] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tireSizes, setTireSizes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];
        data = await getWarehouses(
          {
            scope: "warehouse_id,name,status",
            status: 1,
          },
          false
        );
        setWarehouses(data.sort(alphabeticalSort("name")));

        data = await getBrands(
          {
            scope: "brand_type,brand_id,name",
            brandType: "TIRE,RETREAD",
            status: 1,
          },
          false
        );

        setBrands(data.sort(alphabeticalSort("name")));

        data = await getTireSizes({
          scope: "tire_size_id,size",
          status: 1,
        });

        setTireSizes(
          data
            .map((size) => ({
              tire_size_id: size.tire_size_id,
              name: size.size,
            }))
            .sort(alphabeticalSort("name"))
        );
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (open) {
      fetchData();
    } else {
      resetFormik();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setInitialValues({
        warehouse_id: "",
        brand_id: "",
        revitalized_brand_id: "",
        tire_model_id: "",
        revitalized_tire_model_id: "",
        condition: "",
        tire_size: "",
        depth_min: "",
        depth_max: "",
        tire: {},
      });
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      const fields = {
        vehicle_type_axle_tire_id: axleTireInfo.vehicle_type_axle_tire_id,
      };

      const movementTireId = values.tire.cycle.movement_tire.movement_tire_id;
      await mountTire(vehicleId, movementTireId, fields);

      setNotification({
        message: t("_messages.mounted"),
      });
      onClose();
      getVehicleAxles(vehicleId);
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      warehouses={warehouses}
      brands={brands}
      tireSizes={tireSizes}
      position={axleTireInfo.position}
      vehicleTypeAxleTireId={axleTireInfo.vehicle_type_axle_tire_id}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

TireMountForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  axleTireInfo: PropTypes.shape({
    position: PropTypes.number.isRequired,
    vehicle_type_axle_tire_id: PropTypes.number,
  }).isRequired,
};

TireMountForm.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getWarehouses: actionGetWarehouses(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireSizes: actionGetTireSizes(dispatch),
  mountTire: actionMountTire(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireMountForm);
