import { SET_TIRE_SIZE_APPROVAL_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetTireSizeApprovalStatus =
  (dispatch) => async (tireSizeId, approved) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "PUT",
        path: `tire/size/${tireSizeId}/approved`,
        data: { approved },
      });

      dispatch({
        type: SET_TIRE_SIZE_APPROVAL_STATUS,
        payload: tireSizeId,
      });
      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
