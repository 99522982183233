import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireModelVariations } from "store/actions/tire/model/variation/getTireModelVariations";
import { actionClearTireModelVariations } from "store/actions/tire/model/variation/clearTireModelVariations";

import { useLang } from "hooks/lang";

import { CREATE_TIRE_MODEL_VARIATION } from "store/actions/account/permits";

export const NAMESPACE = "pages/tire/model/TireModelVariationTab";

import Page from "./page";

export function TireModelVariationTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const {
    permits,
    variations,
    setNotification,
    getTireModelVariations,
    clearTireModelVariations,
  } = rest;
  const { id: tireModelId } = useParams();
  const [formOpen, setFormOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tireModelVariationId, setTireModelVariationId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireModelVariations({ page: 1, tireModelId });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTireModelVariations();
    };
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [variations.data]);

  function handleOpenForm() {
    setFormOpen(true);
    setDialogOpen(false);
  }

  function handleCloseForm() {
    setFormOpen(false);
    setTireModelVariationId(null);
  }

  function handleOpenDialog(id) {
    setTireModelVariationId(id);
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
    setTireModelVariationId(null);
  }

  async function handleChangePage(page) {
    try {
      await getTireModelVariations({ page, tireModelId });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      formOpen={formOpen}
      dialogOpen={dialogOpen}
      variations={variations}
      tireModelId={tireModelId}
      tireModelVariationId={tireModelVariationId}
      createTireModelVariationPermit={permits.includes(
        CREATE_TIRE_MODEL_VARIATION
      )}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
      handleChangePage={handleChangePage}
    />
  );
}

TireModelVariationTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  variations: state.Tire.Model.Variation.tire_model_variations,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireModelVariations: actionGetTireModelVariations(dispatch),
  clearTireModelVariations: actionClearTireModelVariations(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TireModelVariationTab);
