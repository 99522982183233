export default {
  languages: {
    es: "Español",
    en: "Inglés",
  },
  text: {
    and: "y",
    or: "o",
  },
  home: "Inicio",
  role: {
    label: "Rol",
    root: "Root",
    manager: "Gerente",
    chief_operations: "Jefe de operaciones",
    operator: "Operador",
    warehouse: "Almacén",
    reports: "Reportes",
    checklist_operator: "Operador Checklist",
    distributor: "Distribuidor",
    tpms: "Tpms",
  },
  mounting: "Montajes",
  companies: "Empresas",
  no_companies: "Sin empresas",
  warehouses: "Almacenes",
  no_warehouses: "Sin almacenes",
  alerts: "Alertas",
  no_alerts: "Sin Alertas",
  corporates: "Corporativos",
  no_corporates: "Sin corporativos",
  subsidiaries: "Sucursales",
  no_subsidiaries: "Sin sucursales",
  associations: "Transportistas",
  no_associations: "Sin transportistas",
  drivers: "Conductores",
  no_drivers: "Sin conductores",
  vehicles: "Vehículos",
  vehicle_review: "Revisiones de Vehículo",
  no_vehicles: "Sin vehículos",
  no_vehicle_type_axles: "Sin ejes del tipo de vehículo",
  users: "Usuarios",
  no_users: "Sin usuarios",
  brands: "Marcas",
  no_brands: "Sin marcas",
  size: "Medida",
  sizes: "Medidas",
  no_sizes: "Sin medidas",
  tire: "Neumático",
  tires: "Neumáticos",
  no_tires: "Sin neumáticos",
  providers: "Proveedores",
  no_providers: "Sin proveedores",
  wears: "Desgastes",
  no_wears: "Sin desgastes",
  damages: "Tipos de daños",
  no_damages: "Sin tipos de daños",
  no_tire_damages: "Sin daños",
  roles: "Roles",
  subsidiary: "Sucursal",
  association: "Transportista",
  warehouse: "Almacén",
  driver: "Conductor",
  no_drivers: "Sin conductores",
  vehicle: "Vehículo",
  gps: "Gps",
  no_gps: "Sin gps",
  division: "División",
  divisions: "Divisiones",
  no_divisions: "Sin divisiones",
  retirement_causes: "Causas de retiro",
  no_retirement_causes: "Sin causas de retiro",
  history_user: "Historial de usuario del",
  no_image: "Sin imagen",
  tire_models: "Modelos de Neumáticos",
  no_tire_models: "Sin modelos de neumáticos",

  revitalized_tire_models: "Modelos Revitalizados de Neumáticos",
  no_revitalized_tire_models: "Sin Modelos Revitalizados de Neumáticos",
  structure_types: {
    truck_tractor: "Tracto Camión",
    truck: "Camión",
    box: "Caja",
    dolly: "Dolly",
    van: "Camioneta",
  },
  vehicles_types: "Tipos de vehículos",
  no_vehicles_types: "Sin tipos de vehículos",
  damage: "Daño",
  tire_damage: "Daño en el neumático",
  update_tire_damage: "Actualizar daño del neumático",
  tire_wear: "Desgaste en el neumático",
  information: "Información",
  filter: "Filtro",

  approved: {
    singular: {
      male: "Aprobado",
      female: "Aprobada",
    },
    plural: {
      male: "Aprobados",
      female: "Aprobadas",
    },
  },

  not_approved: {
    singular: {
      male: "No aprobado",
      female: "No aprobada",
    },
    plural: {
      male: "No aprobados",
      female: "No aprobadas",
    },
  },

  enabled: {
    singular: {
      male: "Habilitado",
      female: "Habilitada",
    },
    plural: {
      male: "Habilitados",
      female: "Habilitadas",
    },
  },

  disabled: {
    singular: {
      male: "Deshabilitado",
      female: "Deshabilitada",
    },
    plural: {
      male: "Deshabilitados",
      female: "Deshabilitadas",
    },
  },

  approve: "Aprobar",
  disapprove: "Desaprobar",
  enable: "Habilitar",
  disable: "Deshabilitar",
  view: "Ver",
  link_vehicle: "Vincular vehículo",
  approval_status: "Estatus de aprobación",
  link_subsidiary: "Vincular sucursal",
  store: "Almacenar",
  cycle: "Ciclo",
  repair_tire: "Mandar a reparar neumático",
  rfid: "Rfid",
  no_rfid: "Sin rfid",
  gps_geofence: "Geocerca",
  no_gps_geofence: "Sin geocercas",
  inside: "Adentro",
  outside: "Afuera",
  link_rfid: "Vincular rfid",
  confirm: "Confirmar",
  disabled_user: "Usuario deshabilitado",
  company_policies: "Políticas de empresa",
  policies: "Políticas",
  policies_application: "Políticas por Aplicación",
  condition_policies: "Políticas de condición",
  pressure_policies: "Políticas de presión",
  no_pressure_policies: "Sin políticas de presión",
  depth_policies: "Políticas de profundidad",
  no_depth_policies: "Sin políticas de profundidad",
  application_tire_policies: "Políticas de aplicación",
  model_variation_policies: "Políticas de presión por modelo",
  no_model_variation_policies: "Sin políticas de presión por modelo",
  tire_history: "Historial de movimientos",
  tire_review_history: "Historial de revisiones",
  damage_list: "Lista de daños",
  wear_list: "Lista de desgastes",
  brand: "Marca",
  tire_model: "Modelo",
  revitalized_brand: "Marca revitalizado",
  revitalized_model: "Modelo revitalizado",
  selected_filters: "Filtros:",
  selected_tire: "Neumático seleccionado:",
  position: "Posición",
  depth_min: "Profundidad mín",
  depth_max: "Profundidad máx",
  no_choice: "Sin elección",
  reports: "Reportes",
  set: "Establecer",
  responsibles: "Responsables",
  vehicle_km: "Kilómetraje del vehículo",
  tire_reviews: "Revisiones de neumáticos",
  support: "Contactar a soporte",
  alerts_panel: "Panel de Alertas",
  quantity_tire: "Cantidad de neumáticos",
  average_days: "Días aprox. que tardó el revitalizado",
  average_days_repair: "Días aprox. que tardó la reparación",
  days: "Días",
  helmet_values: "Valor de cascos",
  no_model_helmet_values: "Sin valores de casco definidos por modelo",
  commissioned_drivers: "Conductores del transportista",
  commissioned_vehicles: "Vehículos del transportista",
  checklist: "Checklist",
  no_checklists: "Sin checklists",
  favorites: "Favoritos",
  favorite_reports: "Reportes Favoritos",
  concepts: "Conceptos",
  concept: "Concepto",
  no_concepts: "Sin conceptos",
  blocks: "Bloques",
  no_blocks: "Sin bloques",
  block: "Bloque",
  disposal: "Desecho",
  loading: "Cargando...",
  notifications: "Notificaciones",
  tpms: "Tpms",
  no_tpms: "Sin sensores tpms",
  temperature_policy: "Políticas de temperatura",
  no_temperature_policy: "Sin políticas de temperatura",
  no_chart_tpms_data: "Sin información de tpms",
  tutorials: "Tutoriales",
  tpms_link: "Vincular Tpms",
  administration: "Administración",
  areas: "Áreas",
  catalogs: "Catálogos",
};
