import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import { actionSetNotification } from "store/actions/general/notification";

import { actionGetTireWarehouse } from "store/actions/tire/warehouse/getTireWarehouse";
import { actionClearTireWarehouse } from "store/actions/tire/warehouse/clearTireWarehouse";
import { actionAssignTireToWarehouse } from "store/actions/warehouse/tire/assignTireToWarehouse";
import { actionDeleteLinkTireWithWarehouse } from "store/actions/warehouse/tire/deleteLinkTireWithWarehouse";
import { actionGetTireWarehousesHistory } from "store/actions/tire/warehouse/history/getTireWarehousesHistory";

import {
  SHOW_TIRE,
  SHOW_LINK_TIRE_WITH_WAREHOUSE,
  LINK_TIRE_WITH_WAREHOUSE,
  DELETE_LINK_TIRE_WITH_WAREHOUSE,
  LIST_LINK_WAREHOUSE_WITH_SUBSIDIARY,
  LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE,
  LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE,
} from "store/actions/account/permits";

import Page from "./page";

export const NAMESPACE =
  "components/organisms/tire/warehouse/LinkTireWarehouseForm";

export function LinkTireWarehouseForm({ className, tireId, ...rest }) {
  const t = useLang(NAMESPACE);
  const history = useHistory();
  const {
    permits,
    warehouse,
    subsidiary,
    setNotification,
    getTireWarehouse,
    clearTireWarehouse,
    assignTireToWarehouse,
    deleteLinkTireWithWarehouse,
    getTireWarehousesHistory,
  } = rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    NAMESPACE,
    initialValues: {
      warehouse_id: "",
    },
  });
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const showTirePermit = permits.includes(SHOW_TIRE);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireWarehouse(tireId);
      } catch (error) {
        setNotification(error, true);
      }
    }

    if (showTirePermit) {
      fetchData();
    }

    return () => {
      clearTireWarehouse();
    };
  }, [tireId]);

  function handleLink() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    resetFormik();
  }

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function handleHistory() {
    history.push(`/tire/${tireId}/warehouse/history`);
  }

  async function deleteLinkWithWarehouse() {
    try {
      await deleteLinkTireWithWarehouse({
        tireId,
        warehouseId: warehouse.warehouse_id,
      });

      if (permits.includes(LIST_LINK_HISTORY_WAREHOUSE_WITH_TIRE)) {
        await getTireWarehousesHistory({
          tireId,
          page: 1,
        });
      }

      setNotification({
        message: t("_messages.deleted.link_tire_with_warehouse"),
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleUnlink() {
    setNotification({
      message: t("_messages.delete.link_tire_with_warehouse"),
      onAction: () => {
        deleteLinkWithWarehouse();
      },
      textAction: t("_messages.confirm"),
    });
  }

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    try {
      await assignTireToWarehouse({
        tireId,
        warehouseId: values.warehouse_id,
      });

      handleClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }

    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      dialogOpen={dialogOpen}
      className={className}
      warehouse={warehouse}
      tireId={tireId}
      subsidiary={subsidiary}
      initialValues={initialValues}
      initialErrors={initialErrors}
      showTirePermit={showTirePermit}
      deleteLinkTireWithWarehousePermit={permits.includes(
        DELETE_LINK_TIRE_WITH_WAREHOUSE
      )}
      showLinkTireWarehousePermit={permits.includes(
        SHOW_LINK_TIRE_WITH_WAREHOUSE
      )}
      listLinkWarehouseWithSubsidiaryPermit={permits.includes(
        LIST_LINK_WAREHOUSE_WITH_SUBSIDIARY
      )}
      listLinkHistoryTireWithWarehousePermit={permits.includes(
        LIST_LINK_HISTORY_TIRE_WITH_WAREHOUSE
      )}
      linkTireWithWarehousePermit={permits.includes(LINK_TIRE_WITH_WAREHOUSE)}
      handleLink={handleLink}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleUnlink={handleUnlink}
      handleHistory={handleHistory}
      handleValidate={handleFormikValidate}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
    />
  );
}

LinkTireWarehouseForm.propTypes = {
  className: PropTypes.string,
  tireId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  subsidiary: state.Tire.Subsidiary.subsidiary,
  warehouse: state.Tire.Warehouse.Warehouse.warehouse,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getTireWarehouse: actionGetTireWarehouse(dispatch),
  clearTireWarehouse: actionClearTireWarehouse(dispatch),
  assignTireToWarehouse: actionAssignTireToWarehouse(dispatch),
  deleteLinkTireWithWarehouse: actionDeleteLinkTireWithWarehouse(dispatch),
  getTireWarehousesHistory: actionGetTireWarehousesHistory(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkTireWarehouseForm);
