export { alertCauseTranslation };

function alertCauseTranslation(t, key) {
  if (!key) {
    return "";
  }
  const params = key.split("&");
  if (params.length === 1) {
    return t(`_alert.cause.${key}`);
  }
  const [translationKey, ...translationParams] = params;
  let translatedText = t(`_alert.cause.${translationKey}`);
  for (let i = 0; i < translationParams.length; i += 1) {
    let translationParam = translationParams[i];
    if (translationParam.startsWith("t-")) {
      translatedText = translatedText.replace(
        `\${${i}}`,
        t(`_alert.dynamic.${translationParam.substring(2).toLowerCase()}`)
      );
    } else {
      translatedText = translatedText.replace(
        `\${${i}}`,
        translationParam.substring(2)
      );
    }
  }
  return translatedText;
}
