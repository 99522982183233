import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/molecules/general/Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import ContainerTemplate from "components/templates/general/Container";
import FixedButton from "components/molecules/general/FixedButton";
import SearchInput from "components/molecules/general/SearchInput";
import Pagination from "components/molecules/general/Pagination";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import TableResponsive from "components/organisms/general/TableResponsive";

import Form from "components/organisms/general/Form";
import FilterForm from "components/organisms/general/FilterForm";
import ImageDialog from "components/organisms/general/ImageDialog";
import VehicleRegisterExcelForm from "components/organisms/vehicle/VehicleRegisterExcelForm";
import RfidRegisterExcelForm from "components/organisms/rfid/RfidRegisterExcelForm";

import LinkTireForm from "components/organisms/rfid/LinkTireForm";

import css from "./index.module.scss";
import LinkVehicleForm from "components/organisms/gps/vehicle/LinkVehicleForm";

function Page({
  t,
  item,
  rows,
  createTooltipPermit,
  open,
  openFilter,
  filter,
  id,
  title,
  headers,
  currentPage,
  lastPage,
  noItemsText,
  name,
  initialDefaultValues,
  fields,
  createFormTitle,
  updateFormTitle,
  filterFields,
  filterInitialDefaultValues,
  createdMessage,
  updatedMessage,

  onChangePage,
  handleDialog,
  handleClose,
  handleDialogFilter,
  handleCloseFilter,
  handleSearch,
  onEdit,
  onDelete,
  onChangeStatus,
  onChangeApproved,
  getItemAction,
  addAction,
  updateAction,
  clearFiltersAction,
  setFiltersAction,
  onReset,
  onResetDiff,

  withImageDialog,
  imageDialogTitle,
  image,
  openImage,
  onOpenImage,
  handleCloseImage,

  children,
  openExcelForm,
  handleCloseExcelForm,
  withLinkTire,
  withLinkVehicle,
  openLinkForm,
  handleLinkTire,
  handleCloseLinkTire,

  selectedFilter,
  setSelectedFilters,
}) {
  return (
    <ContainerTemplate
      className={classnames({
        [css.empty]: rows.length === 0,
      })}
      title={title}
      options={
        <>
          <Button
            onClick={handleDialogFilter}
            classNameButton={classnames(css.filter)}
            variant="icon"
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={filter.search || ""} onChange={handleSearch} />
        </>
      }
    >
      {children}
      {selectedFilter &&
        Object.values(selectedFilter).filter((e) => e.value != undefined)
          .length > 0 && (
          <>
            <div className={classnames(css.filter_title)}>
              <Typography variant="overline">
                {t("_general.selected_filters")}
              </Typography>
            </div>
            <div className={classnames(css.filters)}>
              {Object.entries(selectedFilter ?? {}).map(([key, value]) => {
                if (value.value === undefined) {
                  return undefined;
                }
                return (
                  <Chip
                    key={key}
                    label={`${value.label}: ${value.value}`}
                    variant="outlined"
                  />
                );
              })}
            </div>
          </>
        )}
      {rows.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <TableResponsive
              headers={headers}
              rows={rows}
              onChangeStatus={onChangeStatus}
              onChangeApproved={onChangeApproved}
              onEdit={onEdit}
              onDelete={onDelete}
              onImage={onOpenImage}
              onReset={onReset}
              onResetDiff={onResetDiff}
              onLink={handleLinkTire}
            />
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              page={currentPage}
              totalPages={lastPage}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={noItemsText} />
      )}

      {!!addAction && (
        <FixedButton
          disabled={!!createTooltipPermit}
          tooltip={createTooltipPermit}
          onClick={handleDialog}
        />
      )}

      <Form
        open={open}
        item={item}
        id={id}
        name={name}
        initialDefaultValues={initialDefaultValues}
        fields={fields}
        title={createFormTitle}
        updateTitle={updateFormTitle}
        createdMessage={createdMessage}
        updatedMessage={updatedMessage}
        onClose={handleClose}
        getAction={getItemAction}
        addAction={addAction}
        updateAction={updateAction}
        clearFiltersAction={clearFiltersAction}
      />
      <FilterForm
        fields={filterFields}
        filter={filter}
        initialDefaultValues={filterInitialDefaultValues}
        setFiltersAction={setFiltersAction}
        clearFiltersAction={clearFiltersAction}
        setSelectedFilters={setSelectedFilters}
        onClose={handleCloseFilter}
        open={openFilter}
      />

      {!!withImageDialog && (
        <ImageDialog
          open={openImage}
          onClose={handleCloseImage}
          title={imageDialogTitle}
          image={image}
        />
      )}

      {!!handleCloseExcelForm && name == "vehicle" && (
        <VehicleRegisterExcelForm
          open={openExcelForm}
          onClose={handleCloseExcelForm}
        />
      )}

      {!!handleCloseExcelForm && name == "rfid" && (
        <RfidRegisterExcelForm
          open={openExcelForm}
          onClose={handleCloseExcelForm}
        />
      )}

      {!!withLinkTire && (
        <LinkTireForm
          id={id}
          open={openLinkForm}
          onClose={handleCloseLinkTire}
        />
      )}
      {!!withLinkVehicle && (
        <LinkVehicleForm
          id={id}
          open={openLinkForm}
          onClose={handleCloseLinkTire}
        />
      )}
    </ContainerTemplate>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openFilter: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  id: PropTypes.number,
  item: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  createTooltipPermit: PropTypes.string,
  title: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  noItemsText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initialDefaultValues: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  createFormTitle: PropTypes.string.isRequired,
  updateFormTitle: PropTypes.string.isRequired,
  filterFields: PropTypes.object.isRequired,
  filterInitialDefaultValues: PropTypes.object.isRequired,
  createdMessage: PropTypes.string,
  updatedMessage: PropTypes.string.isRequired,

  handleDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDialogFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeApproved: PropTypes.func.isRequired,
  getItemAction: PropTypes.func.isRequired,
  addAction: PropTypes.func,
  updateAction: PropTypes.func.isRequired,
  clearFiltersAction: PropTypes.func.isRequired,
  setFiltersAction: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onResetDiff: PropTypes.func,

  withImageDialog: PropTypes.bool,
  imageDialogTitle: PropTypes.string,
  image: PropTypes.string,
  openImage: PropTypes.bool.isRequired,
  onOpenImage: PropTypes.func.isRequired,
  handleCloseImage: PropTypes.func.isRequired,

  children: PropTypes.node,
  openExcelForm: PropTypes.bool,
  handleCloseExcelForm: PropTypes.func,

  withLinkTire: PropTypes.bool,
  withLinkVehicle: PropTypes.bool,
  openLinkForm: PropTypes.bool,
  handleLinkTire: PropTypes.func,
  handleCloseLinkTire: PropTypes.func,

  selectedFilter: PropTypes.object,
  setSelectedFilters: PropTypes.func,
};

export default Page;
