import React from "react";
import RowPdf from "./RowPdf";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const PdfTireList = ({
  tireSizeList,
  title,
  titleBgColor = "rgba(255,255,255,255)",
  totalPrice,
}) => {
  const styles = StyleSheet.create({
    header: {
      width: "100%",
      paddingTop: "2px",
      marginTop: "5px",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      width: "100%",
      alignContent: "center",
      alignItems: "center",
      fontSize: "12px",
      textAlign: "center",
      color: "rgb(0,40,73)",
      padding: "2px 10px 2px 10px",
    },
    underline: {
      backgroundColor: titleBgColor,
      height: "2px",
      width: "100%",
      borderRadius: "2px",
    },
    totales: {
      padding: "10px 0px 10px 0px",
    },
    grayCol: {
      width: "16%",
      height: "100%",
      padding: "2px 10px 2px 10px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "rgb(0, 40, 73)",
    },
    grayColTitle: {
      width: "20%",
      height: "100%",
      padding: "2px 10px 2px 10px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "white",
      backgroundColor: "rgb(0, 40, 73)",
    },
  });

  var totalDirectional = 0;
  var totalAllPosition = 0;
  var totalTraction = 0;
  var totalRemolque = 0;
  tireSizeList.forEach((tireSize) => {
    totalDirectional += tireSize.directional;
    totalAllPosition += tireSize.allPosition;
    totalTraction += tireSize.traction;
    totalRemolque += tireSize.remolque;
  });
  return (
    <View>
      <View style={styles.header}>
        <Text style={styles.title}> {title} </Text>
        <View style={styles.underline}></View>
      </View>
      <RowPdf
        col1={"Toda Posición"}
        col2={"Direccionales"}
        col3={"Tracción"}
        col4={"Remolque"}
        col5={"Total - Medida"}
      />
      <View style={styles.underline}></View>
      {
        // eslint-disable-next-line react/prop-types
        tireSizeList.map((x) => (
          <RowPdf
            key={x.size}
            titleText={x.size}
            col1={x.allPosition}
            col2={x.directional}
            col3={x.traction}
            col4={x.remolque}
            col5={x.directional + x.allPosition + x.traction + x.remolque}
          />
        ))
      }
      <View style={styles.underline}></View>
      <View style={styles.totales}>
        <RowPdf
          titleText={"Total - Aplicación"}
          col1={totalAllPosition}
          col2={totalDirectional}
          col3={totalTraction}
          col4={totalRemolque}
          titleStyle={styles.grayColTitle}
          col1Style={styles.grayCol}
          col2Style={styles.grayCol}
          col3Style={styles.grayCol}
          col4Style={styles.grayCol}
        />
        <RowPdf
          col4={"Total Llantas"}
          col5={
            totalDirectional + totalAllPosition + totalTraction + totalRemolque
          }
          col4Style={styles.grayCol}
          col5Style={styles.grayCol}
        />
        <RowPdf
          col4="Costo Total"
          col5={`$${formatter.format(totalPrice)}`}
          col4Style={styles.grayCol}
          col5Style={styles.grayCol}
        />
      </View>
    </View>
  );
};

PdfTireList.propTypes = {
  tireSizeList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleBgColor: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

export default PdfTireList;
