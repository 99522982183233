import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetTireStatsReport } from "store/actions/reports/stats/getTireStatsReport";
import { actionClearTireStatsFilters } from "store/actions/reports/stats/clearTireStatsFilters";
import { actionGenerateTireStatsExcel } from "store/actions/reports/stats/generateTireStatsExcel";

import { alphabeticalSort } from "utils/sort";
import { useLang } from "hooks/lang";

import Page from "./page";

const colors = {
  backgroundColor: [
    "rgba(255, 99, 132, 0.4)",
    "rgba(54, 162, 235, 0.4)",
    "rgba(255, 206, 86, 0.4)",
    "rgba(75, 192, 192, 0.4)",
    "rgba(153, 102, 255, 0.4)",
    "rgba(255, 159, 64, 0.4)",
  ],
  borderColor: [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ],
};

function PileStats({ ...rest }) {
  const t = useLang();
  const [data, setData] = useState({});
  const [selectedKey, setSelectedKey] = useState("brand_name");
  const [hide, setHide] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const {
    filter,
    selectedFilter,
    setNotification,
    getTireStatsReport,
    clearTireStatsFilters,
    generateTireStatsExcel,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTireStatsReport({
          movement: "PILE",
          ...filter,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireStatsFilters();
    };
  }, []);

  function handleClick(e, key) {
    e.preventDefault();
    setSelectedKey(key);
    setHide(true);
  }

  async function handleGenerateExcel() {
    try {
      await generateTireStatsExcel({
        movement: "PILE",
        ...filter,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      data={getChunkedData(data, selectedKey)}
      dataChart={getChartData(data, selectedKey, t)}
      hide={hide}
      selectedKey={selectedKey}
      openFilter={openFilter}
      handleClick={handleClick}
      setHide={setHide}
      filter={filter}
      selectedFilter={selectedFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleGenerateExcel={handleGenerateExcel}
    />
  );
}
PileStats.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.Stats.filter,
  selectedFilter: state.Reports.Stats.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getTireStatsReport: actionGetTireStatsReport(dispatch),
  clearTireStatsFilters: actionClearTireStatsFilters(dispatch),
  generateTireStatsExcel: actionGenerateTireStatsExcel(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PileStats);

function getChunkedData(data, selectedKey) {
  if (data[selectedKey] && Object.keys(data).length > 0) {
    const likeArray =
      selectedKey != "depth"
        ? Object.entries(data[selectedKey]).sort(alphabeticalSort("0"))
        : Object.entries(data[selectedKey]).sort(
            (a, b) => a[0].split(" - ")[1] - b[0].split(" - ")[1]
          );
    if (likeArray.length > 3) {
      return splitToChunks([...likeArray], 2);
    }

    return splitToChunks([...likeArray], 1);
  }
  return Object.entries(data);
}

function splitToChunks(array, parts) {
  let result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

function getChartData(data, selectedKey, t) {
  if (data[selectedKey] && Object.keys(data).length > 0) {
    const likeArray = Object.entries(data[selectedKey]);

    if (selectedKey == "depth") {
      likeArray.sort((a, b) => a[0].split(" - ")[1] - b[0].split(" - ")[1]);
      const labels = likeArray.map((data) => data[0]);
      const values = likeArray.map((data) => data[1]);
      const bgColors = [];
      const bColors = [];
      labels.forEach((label) => {
        if (label.split(" - ")[1] <= 4) {
          bgColors.push("rgba(255, 40, 40, 0.5)");
          bColors.push("rgba(255, 40 , 40, 1)");
        } else {
          bgColors.push("rgba(124, 181, 236, 0.6)");
          bColors.push("rgba(124, 181, 236, 1)");
        }
      });
      return {
        labels: labels,
        datasets: [
          {
            label: t("_labels.pile_stats_report.number_of_tires"),
            data: labels.map((_, index) => values[index]),
            backgroundColor: bgColors,
            borderColor: bColors,
            borderWidth: 1,
          },
        ],
      };
    }

    let counter = 0;
    const dataChart = likeArray.map((item) => {
      const bgColor = colors.backgroundColor[counter];
      const bColor = colors.borderColor[counter];
      if (counter === 5) {
        counter = 0;
      } else {
        counter++;
      }
      return {
        label: item[0],
        data: [item[1]],
        backgroundColor: bgColor,
        borderColor: bColor,
        borderWidth: 1,
      };
    });

    return dataChart.sort(alphabeticalSort("label"));
  }

  return Object.entries(data);
}
