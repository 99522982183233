import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useLang } from "hooks/lang";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetPressurePolicies } from "store/actions/subsidiary/policy/getPressurePolicies";
import { actionClearPressurePolicies } from "store/actions/subsidiary/policy/clearPressurePolicies";
import { actionDeletePressurePolicy } from "store/actions/subsidiary/policy/deletePressurePolicy";
import { actionSetPressurePoliciesFilters } from "store/actions/subsidiary/policy/setPressurePoliciesFilters";
import { actionClearPressurePoliciesFilters } from "store/actions/subsidiary/policy/clearPressurePoliciesFilters";

import {
  CREATE_VEHICLE_PRESSURE_POLICY,
  UPDATE_VEHICLE_PRESSURE_POLICY,
  DELETE_VEHICLE_PRESSURE_POLICY,
} from "store/actions/account/permits";

import Page from "./page";

export function PressurePolicyTab({ ...rest }) {
  const t = useLang();
  const { id: subsidiaryId } = useParams();
  const {
    permits,
    filter,
    pressurePolicies,
    getPressurePolicies,
    clearPressurePolicies,
    setPressurePoliciesFilters,
    clearPressurePoliciesFilters,
    deletePressurePolicy,
    setNotification,
  } = rest;
  const [openForm, setOpenForm] = useState(false);
  const [openVehicleTypeForm, setOpenVehicleTypeForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [vehicleId, setVehicleId] = useState(0);
  const [policyId, setPolicyId] = useState(0);
  const [openMultipleForm, setOpenMultipleForm] = useState(false);
  const [vehicleIds, setVehicleIds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        await getPressurePolicies({ page: 1, subsidiaryId, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
    return () => {
      clearPressurePolicies();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearPressurePoliciesFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getPressurePolicies({ page, subsidiaryId, ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenForm(vehicleId) {
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  function handleCloseForm() {
    setPolicyId(null);
    setOpenForm(false);
    setVehicleId(0);
  }

  function handleOpenVehicleTypeForm() {
    setOpenVehicleTypeForm(true);
  }

  function handleCloseVehicleTypeForm() {
    setOpenVehicleTypeForm(false);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setPressurePoliciesFilters({ search });
  }

  function handleEdit(vehicleId, policyId) {
    setPolicyId(policyId);
    setVehicleId(vehicleId);
    setOpenForm(true);
  }

  async function onDeletePressurePolicy(
    subsidiaryId,
    vehicleId,
    pressurePolicyId
  ) {
    try {
      await deletePressurePolicy(subsidiaryId, vehicleId, pressurePolicyId);
      setNotification({
        message: t("_messages.deleted.pressure_policy"),
      });
      clearPressurePoliciesFilters();
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleDelete(subsidiaryId, vehicleId, pressurePolicyId) {
    setNotification({
      onAction: () => {
        onDeletePressurePolicy(subsidiaryId, vehicleId, pressurePolicyId);
      },
      message: t("_messages.delete.pressure_policy"),
      textAction: t("_buttons.confirm"),
    });
  }

  function handleOpenMultiple(vehicleIds) {
    setVehicleIds(vehicleIds);
    setOpenMultipleForm(true);
  }

  function handleCloseMultiple() {
    setOpenMultipleForm(false);
  }

  return (
    <Page
      t={t}
      subsidiaryId={subsidiaryId}
      pressurePoliciesData={pressurePolicies}
      filter={filter}
      vehicleId={vehicleId}
      policyId={policyId}
      openFilter={openFilter}
      openForm={openForm}
      openVehicleTypeForm={openVehicleTypeForm}
      createVehiclePressurePolicyPermit={permits.includes(
        CREATE_VEHICLE_PRESSURE_POLICY
      )}
      updateVehiclePressurePolicyPermit={permits.includes(
        UPDATE_VEHICLE_PRESSURE_POLICY
      )}
      deleteVehiclePressurePolicyPermit={permits.includes(
        DELETE_VEHICLE_PRESSURE_POLICY
      )}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      handleSearch={handleSearch}
      handleCloseFilter={handleCloseFilter}
      handleOpenFilter={handleOpenFilter}
      handleOpenForm={handleOpenForm}
      handleOpenVehicleTypeForm={handleOpenVehicleTypeForm}
      handleCloseVehicleTypeForm={handleCloseVehicleTypeForm}
      handleCloseForm={handleCloseForm}
      handleChangePage={handleChangePage}
      openMultiple={openMultipleForm}
      vehicleIds={vehicleIds}
      handleOpenMultiple={handleOpenMultiple}
      handleCloseMultiple={handleCloseMultiple}
    />
  );
}

PressurePolicyTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  filter: state.Subsidiary.Policy.filter,
  pressurePolicies: state.Subsidiary.Policy.pressure_policies,
});

const mapDispatchToProps = (dispatch) => ({
  getPressurePolicies: actionGetPressurePolicies(dispatch),
  clearPressurePolicies: actionClearPressurePolicies(dispatch),
  deletePressurePolicy: actionDeletePressurePolicy(dispatch),
  setNotification: actionSetNotification(dispatch),
  setPressurePoliciesFilters: actionSetPressurePoliciesFilters(dispatch),
  clearPressurePoliciesFilters: actionClearPressurePoliciesFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PressurePolicyTab);
