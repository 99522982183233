import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSetNotification } from "store/actions/general/notification";
import { actionGetSummaryReport } from "store/actions/reports/summary/getSummaryReport";
import { actionClearTireSummaryFilters } from "store/actions/reports/summary/clearTireSummaryFilters";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import Page from "./page";

const depthOrder = ["Buen estado", "Retiro programado", "Retiro crítico"];
const pressureOrder = ["Sin presión", "Normal", "Alta", "Baja"];
function PileSummary({ ...rest }) {
  const t = useLang();

  const {
    filter,
    selectedFilter,
    setNotification,
    getSummaryReport,
    clearTireSummaryFilters,
  } = rest;
  const [data, setData] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSummaryReport({
          movement: "PILE",
          ...filter,
        });
        setData(response);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireSummaryFilters();
    };
  }, []);

  function handleViewAlerts(alerts) {
    setAlerts(alerts);
    setOpenDialog(true);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    setAlerts([]);
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  return (
    <Page
      t={t}
      data={getData(data)}
      dataChart={getDataChart(t, data)}
      alerts={alerts}
      openDialog={openDialog}
      handleViewAlerts={handleViewAlerts}
      handleCloseDialog={handleCloseDialog}
      filter={filter}
      selectedFilter={selectedFilter}
      openFilter={openFilter}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
    />
  );
}
PileSummary.propTypes = {};

const mapStateToProps = (state) => ({
  filter: state.Reports.Summary.filter,
  selectedFilter: state.Reports.Summary.selected_filter,
});

const mapDispatchToProps = (dispatch) => ({
  getSummaryReport: actionGetSummaryReport(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearTireSummaryFilters: actionClearTireSummaryFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PileSummary);

function getData(data) {
  const order = ["NO PRESSURE", "NORMAL", "HIGH", "LOW"];
  const depthOrder = [
    "GOOD CONDITION",
    "SCHEDULED WITHDRAWAL",
    "CRITICAL WITHDRAWAL",
  ];

  if (data.pressure) {
    Object.keys(data.pressure)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.pressure[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            Object.keys(data.pressure[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.pressure[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    let aux = [];
                    Object.keys(
                      data.pressure[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        aux.push(condition);
                      });
                    data.pressure[corporate][company][subsidiary][division][
                      "labels"
                    ] = aux.sort((a, b) => order.indexOf(a) - order.indexOf(b));
                  });
              });
          });
      });
  }

  if (data.depth) {
    Object.keys(data.depth)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.depth[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            Object.keys(data.depth[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.depth[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    let aux = [];
                    Object.keys(
                      data.depth[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        aux.push(condition);
                      });
                    data.depth[corporate][company][subsidiary][division][
                      "labels"
                    ] = aux.sort(
                      (a, b) => depthOrder.indexOf(a) - depthOrder.indexOf(b)
                    );
                  });
              });
          });
      });
  }

  return data;
}

function getDataChart(t, data) {
  const result = {
    pressure: {},
    depth: {},
    alert: {},
  };
  if (data.pressure && Object.keys(data.pressure).length > 0) {
    const pressure = {};
    Object.keys(data.pressure)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.pressure[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            pressure[corporate] = {};
            Object.keys(data.pressure[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.pressure[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.pressure[corporate][company][subsidiary][division]
                    )
                      .filter(
                        (key) =>
                          !["statistics", "percent", "labels"].includes(key)
                      )
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.summary_report.pressure_condition.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );
                        info.push(
                          data.pressure[corporate][company][subsidiary][
                            division
                          ][condition]["percent"]
                        );
                        if (condition.includes("LOW")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("HIGH")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedInfo,
                      sortedLabels,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({
                      chart,
                      info,
                      colors,
                      borderColors,
                      priorityArray: pressureOrder,
                    });
                    chart.labels = sortedLabels;
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    pressure[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.pressure = pressure;
  }

  if (data.depth && Object.keys(data.depth).length > 0) {
    const depth = {};
    Object.keys(data.depth)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.depth[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            depth[corporate] = {};
            Object.keys(data.depth[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.depth[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.depth[corporate][company][subsidiary][division]
                    )
                      .filter(
                        (key) =>
                          !["statistics", "percent", "labels"].includes(key)
                      )
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.summary_report.depth_condition.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );
                        info.push(
                          data.depth[corporate][company][subsidiary][division][
                            condition
                          ]["percent"]
                        );
                        if (condition.includes("CRITICAL")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("SCHEDULED")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedInfo,
                      sortedLabels,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({
                      chart,
                      info,
                      colors,
                      borderColors,
                      priorityArray: depthOrder,
                    });
                    chart.labels = sortedLabels;
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    depth[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.depth = depth;
  }

  if (data.alert && Object.keys(data.alert).length > 0) {
    const alert = {};
    Object.keys(data.alert)
      .filter((key) => !["statistics", "percent"].includes(key))
      .forEach((corporate) => {
        Object.keys(data.alert[corporate])
          .filter((key) => !["statistics", "percent"].includes(key))
          .forEach((company) => {
            alert[corporate] = {};
            Object.keys(data.alert[corporate][company])
              .filter((key) => !["statistics", "percent"].includes(key))
              .forEach((subsidiary) => {
                Object.keys(data.alert[corporate][company][subsidiary])
                  .filter((key) => !["statistics", "percent"].includes(key))
                  .forEach((division) => {
                    const chart = {};
                    const info = [];
                    const colors = [];
                    const borderColors = [];
                    chart.labels = [];
                    chart.datasets = [];
                    Object.keys(
                      data.alert[corporate][company][subsidiary][division]
                    )
                      .filter((key) => !["statistics", "percent"].includes(key))
                      .forEach((condition) => {
                        chart.labels.push(
                          t(
                            `_labels.priority.options.${condition
                              .replaceAll(/\s/g, "_")
                              .toLowerCase()}`
                          )
                        );
                        info.push(
                          data.alert[corporate][company][subsidiary][division][
                            condition
                          ]["percent"]
                        );
                        if (condition.includes("HIGH")) {
                          colors.push("rgb(250, 180, 180)");
                          borderColors.push("rgb(255, 80, 80)");
                        } else if (condition.includes("LOW")) {
                          colors.push("rgb(255, 235, 120)");
                          borderColors.push("rgb(235, 215, 80)");
                        } else {
                          colors.push("rgb(190, 255, 200)");
                          borderColors.push("rgb(50, 255, 105)");
                        }
                      });

                    const {
                      sortedInfo,
                      sortedLabels,
                      sortedColors,
                      sortedBorderColors,
                    } = getSortedInfo({ chart, info, colors, borderColors });
                    chart.labels = sortedLabels;
                    chart.datasets.push({
                      data: sortedInfo,
                      backgroundColor: sortedColors,
                      borderColor: sortedBorderColors,
                      borderWidth: 1,
                    });

                    alert[corporate][
                      division === "NO DIVISION"
                        ? t("_labels.no_division")
                        : division
                    ] = chart;
                  });
              });
          });
      });

    result.alert = alert;
  }
  return result;
}

function getSortedInfo({ chart, info, colors, borderColors, priorityArray }) {
  const auxArray = [];
  info.forEach((element, index) =>
    auxArray.push({
      value: element,
      label: chart.labels[index],
      color: colors[index],
      borderColor: borderColors[index],
    })
  );

  if (!priorityArray) {
    auxArray.sort(alphabeticalSort("label"));
  } else {
    auxArray.sort(
      (a, b) => priorityArray.indexOf(a.label) - priorityArray.indexOf(b.label)
    );
  }
  const sortedInfo = [];
  const sortedLabels = [];
  const sortedColors = [];
  const sortedBorderColors = [];
  auxArray.forEach((element) => {
    sortedInfo.push(element.value);
    sortedLabels.push(element.label);
    sortedColors.push(element.color);
    sortedBorderColors.push(element.borderColor);
  });

  return { sortedInfo, sortedLabels, sortedColors, sortedBorderColors };
}
