import { SET_WEAR_STATUS } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionSetWearStatus = (dispatch) => async (wearId, status) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: "PUT",
      path: `wear/${wearId}/status`,
      data: { status },
    });
    dispatch({
      type: SET_WEAR_STATUS,
      payload: wearId,
    });
    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
