export const VEHICLES = "VEHICLES";
export const TPMS = "TPMS";
export const SUBSIDIARY = "SUBSIDIARY";
export const ADMINISTRATION = "ADMINISTRATION";
export const AREAS = "AREAS";
export const CATALOGS = "CATALOGS";

export const VIDEOS = {
  [VEHICLES]: {
    es: [
      {
        id: 1,
        name: "_general.vehicles",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/vehiculos/vehiculos.mp4",
      },
      {
        id: 2,
        name: "_general.mounting",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/vehiculos/montaje.mp4",
      },
      {
        id: 3,
        name: "_general.tires",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/vehiculos/neumaticos.mp4",
      },
    ],
    en: [
      {
        id: 4,
        name: "_general.vehicles",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/vehicles/Vehicles.mp4",
      },
      {
        id: 5,
        name: "_general.mounting",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/vehicles/mountingTire.mp4",
      },
      {
        id: 6,
        name: "_general.tires",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/vehicles/Tires.mp4",
      },
    ],
  },
  [ADMINISTRATION]: {
    es: [
      {
        id: 7,
        name: "_general.tpms",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/administracion/tpms.mp4",
      },
      {
        id: 8,
        name: "_general.tpms_link",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/administracion/tpmsvincular.mp4",
      },
      {
        id: 9,
        name: "_general.users",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/administracion/usuarios.mp4",
      },
    ],
    en: [
      {
        id: 10,
        name: "_general.tpms",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/administration/Tpms.mp4",
      },
      {
        id: 11,
        name: "_general.tpms_link",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/administration/TPMSLinkTire.mp4",
      },
      {
        id: 12,
        name: "_general.users",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/administration/Users.mp4",
      },
    ],
  },
  [AREAS]: {
    es: [
      {
        id: 13,
        name: "_titles.new.subsidiary",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/areas/sucursales.mp4",
      },
      {
        id: 14,
        name: "_general.policies",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/areas/sucursalesPoliticas.mp4",
      },
    ],
    en: [
      {
        id: 15,
        name: "_titles.new.subsidiary",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/areas/subsidiariesCreate.mp4",
      },
      {
        id: 16,
        name: "_general.policies",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/areas/subsidiariesPolicies.mp4",
      },
    ],
  },
  [CATALOGS]: {
    es: [
      {
        id: 17,
        name: "_general.brands",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/catalogos/marcas.mp4",
      },
      {
        id: 18,
        name: "_general.sizes",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/catalogos/medidas.mp4",
      },
      {
        id: 19,
        name: "_labels.models",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/espanol/catalogos/modelosOriginales.mp4",
      },
    ],
    en: [
      {
        id: 20,
        name: "_general.brands",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/catalogs/Brands.mp4",
      },
      {
        id: 21,
        name: "_general.sizes",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/catalogs/Sizes.mp4",
      },
      {
        id: 22,
        name: "_labels.models",
        url: "https://filesc.nyc3.cdn.digitaloceanspaces.com/ingles/catalogs/originalModels.mp4",
      },
    ],
  },
};
