export default {
  traveled: "Recorrido",
  permits: {
    update: "Sin permisos para actualizar recorridos de vehículos",
    reset: "Sin permisos para reiniciar odómetros",
  },
  buttons: {
    reset_odometer: "Reiniciar odómetro",
  },
  messages: {
    reset_confirmation: "¿Estas seguro de reiniciar el odómetro?",
    vehicle_travel_restarted: "El odómetro fue reiniciado satisfactoriamente",
  },
};
