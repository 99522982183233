import React from "react";
import { connect } from "react-redux";

import { UPDATE_DAMAGE, DELETE_DAMAGE } from "store/actions/account/permits";

import { useLang } from "hooks/lang";

import { actionGetDamages } from "store/actions/damage/getDamages";
import { actionClearDamages } from "store/actions/damage/clearDamages";
import { actionSetDamageFilters } from "store/actions/damage/setDamageFilters";
import { actionClearDamageFilters } from "store/actions/damage/clearDamageFilters";
import { actionDeleteDamage } from "store/actions/damage/deleteDamage";
import { actionSetDamageStatus } from "store/actions/damage/setDamageStatus";
import { actionGetDamage } from "store/actions/damage/getDamage";
import { actionUpdateDamage } from "store/actions/damage/updateDamage";

import Page from "./page";

export function HomeDamage({ ...rest }) {
  const {
    permits,
    damage,
    damages,
    filter,
    deleteDamage,
    setDamageStatus,
    getDamage,
    updateDamage,
    getDamages,
    clearDamages,
    setDamageFilters,
    clearDamageFilters,
  } = rest;
  const t = useLang();

  return (
    <Page
      damages={damages}
      damage={damage}
      filter={filter}
      updateDamagePermit={permits.includes(UPDATE_DAMAGE)}
      deleteDamagePermit={permits.includes(DELETE_DAMAGE)}
      t={t}
      getDamage={getDamage}
      updateDamage={updateDamage}
      clearDamageFilters={clearDamageFilters}
      setDamageFilters={setDamageFilters}
      deleteDamage={deleteDamage}
      setDamageStatus={setDamageStatus}
      getDamages={getDamages}
      clearDamages={clearDamages}
    />
  );
}

HomeDamage.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  damages: state.Damage.Damage.damages,
  damage: state.Damage.Damage.damage,
  filter: state.Damage.Damage.filter_damage,
});
const mapDispatchToProps = (dispatch) => ({
  getDamages: actionGetDamages(dispatch),
  clearDamages: actionClearDamages(dispatch),
  setDamageFilters: actionSetDamageFilters(dispatch),
  clearDamageFilters: actionClearDamageFilters(dispatch),
  deleteDamage: actionDeleteDamage(dispatch),
  setDamageStatus: actionSetDamageStatus(dispatch),
  getDamage: actionGetDamage(dispatch),
  updateDamage: actionUpdateDamage(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeDamage);
