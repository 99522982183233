import {
  GET_HELMET_VALUES,
  CLEAR_HELMET_VALUES,
  REGISTER_HELMET_VALUE,
  GET_HELMET_VALUE,
  UPDATE_HELMET_VALUE,
  CLEAR_HELMET_VALUE,
  DELETE_HELMET_VALUE,
  SET_HELMET_VALUE_FILTERS,
  CLEAR_HELMET_VALUE_FILTERS,
} from "store/actions";

const initialState = {
  helmet_value: {
    subsidiary_id: 0,
    tire_model_variation_helmet_id: 0,
    helmet_value_original: 0,
    helmet_value_revitalized: 0,
    tire_model_variation: {
      tire_model_variation_id: 0,
      tire_model: {
        name: "",
      },
      tire_size: {
        size: "",
      },
      number_layers: 0,
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
      last_name_2: "",
    },
    updated_at: "0001-01-01T00:00:00.000Z",
    updated_by: null,
  },
  helmet_values: {
    current_pages: 1,
    data: [],
    last_page: 1,
  },
  filter: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HELMET_VALUE:
    case UPDATE_HELMET_VALUE:
    case REGISTER_HELMET_VALUE:
      return {
        ...state,
        helmet_value: action.payload,
      };

    case CLEAR_HELMET_VALUE:
      return {
        ...state,
        helmet_value: { ...initialState.helmet_value },
      };

    case GET_HELMET_VALUES:
      return {
        ...state,
        helmet_values: action.payload,
      };

    case CLEAR_HELMET_VALUES:
      return {
        ...state,
        helmet_values: { ...initialState.helmet_values },
      };

    case DELETE_HELMET_VALUE:
      return {
        ...state,
        helmet_values: {
          ...state.helmet_values,
          data: state.helmet_values.data.filter(
            (helmetValue) =>
              helmetValue.tire_model_variation_helmet_id !== action.payload
          ),
        },
      };

    case SET_HELMET_VALUE_FILTERS:
      const filter = {
        ...state.filter,
        ...action.payload,
      };
      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }
      return {
        ...state,
        filter: { ...filter },
      };

    case CLEAR_HELMET_VALUE_FILTERS:
      return {
        ...state,
        filter: { ...initialState.filter },
      };
    default:
      return state;
  }
}
