export default {
  title: "New gps",
  update_title: "Update gps",
  messages: {
    gps_created: "Done, the gps has been successfully created",
    gps_updated: "Done, the gps has been successfully updated",
    gps_deleted: "Done, the gps has been successfully deleted",
    delete: "Are you sure to delete the gps?",
  },
  form: {
    permits: {
      update: "No permissions to update gps",
      delete: "No permissions to delete gps",
    },
  },
};
