import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "components/molecules/general/Button";
import SelectField from "components/molecules/general/SelectField";

import css from "./index.module.scss";

function Page({
  t,
  values,
  isValid,
  isUpdate,
  tireApplicationTypes,
  updateDepthTolerancePermit,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        margin="normal"
        variant="filled"
        name="tire_application_id"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        component={SelectField}
        className={classnames(css.field)}
        label={t("_labels.tire_application.label.singular")}
        disabled={isUpdate}
        defaultText={t("_labels.tire_application.default.singular")}
        defaultValue={
          tireApplicationTypes.some(
            (application) =>
              application.tire_application_id === values.tire_application_id
          )
            ? null
            : values.tire_application_id
        }
      >
        {tireApplicationTypes.map((application, index) => {
          return (
            <MenuItem
              key={index}
              value={application.tire_application_id}
              disabled={!application.status}
            >
              {t(
                `_labels.tire_application.options.${application.tire_application_id.toLowerCase()}`
              )}
            </MenuItem>
          );
        })}
      </Field>

      <Field
        margin="normal"
        variant="filled"
        name="maximum_number_patches"
        component={TextField}
        label={t("_labels.maximum_number_patches")}
        className={classnames(css.medium_field)}
        disabled={isUpdate && !updateDepthTolerancePermit}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      <Field
        margin="normal"
        variant="filled"
        name="critical_number_patches"
        component={TextField}
        label={t("_labels.critical_number_patches")}
        className={classnames(css.medium_field)}
        disabled={isUpdate && !updateDepthTolerancePermit}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
      />

      {/* <Field
        required
        margin="normal"
        variant="filled"
        component={TextField}
        name="good_condition"
        label={t("_labels.good_condition")}
        className={classnames(css.field)}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="end"
            >
              mm
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
          },
        }}
        disabled={isUpdate && !updateDepthTolerancePermit}
      /> */}

      <Field
        required
        margin="normal"
        variant="filled"
        component={TextField}
        name="scheduled_withdrawal"
        label={t("_labels.scheduled_withdrawal")}
        className={classnames(css.medium_field)}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="end"
            >
              mm
            </InputAdornment>
          ),
        }}
        disabled={isUpdate && !updateDepthTolerancePermit}
      />

      <Field
        required
        margin="normal"
        variant="filled"
        component={TextField}
        name="critical_withdrawal"
        label={t("_labels.critical_withdrawal")}
        className={classnames(css.medium_field)}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
          },
          endAdornment: (
            <InputAdornment
              className={classnames(css.adornment)}
              position="end"
            >
              mm
            </InputAdornment>
          ),
        }}
        disabled={isUpdate && !updateDepthTolerancePermit}
      />

      <DialogActions className={classnames(css.action)}>
        <div className={classnames(css.delete)}></div>
        <div className={classnames(css.buttons_right)}>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isSubmitting}
          >
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={
              isSubmitting ||
              !isValid ||
              (isUpdate && !updateDepthTolerancePermit)
            }
            tooltip={
              isUpdate && !updateDepthTolerancePermit ? t("permits.update") : ""
            }
          >
            {!isUpdate ? t("_buttons.save") : t("_buttons.update")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateDepthTolerancePermit: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  tireApplicationTypes: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Page;
