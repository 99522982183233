import React from "react";
import Page from "./page";
import PropTypes from "prop-types";

function TirePerformanceChasisTable(props) {
  return <Page {...props} />;
}

TirePerformanceChasisTable.propTypes = {
  t: PropTypes.func.isRequired,
  vehicleTypeAxles: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string.isRequired,
      tireCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  tires: PropTypes.arrayOf(
    PropTypes.shape({
      tireId: PropTypes.number,
      economicNumber: PropTypes.string,
      deviceCode: PropTypes.string,
      position: PropTypes.number,
      createdAt: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      size: PropTypes.string,
      depthVariation: PropTypes.number,
      depthCondition: PropTypes.string,
      depth: PropTypes.number,
      dualDifference: PropTypes.number,
      accumulatedMileage: PropTypes.number,
      monthsActive: PropTypes.number,
      monthlyWear: PropTypes.number,
      wearPercentage: PropTypes.number,
      hasAlerts: PropTypes.bool,
      handleOpenAlertDialog: PropTypes.func,
    })
  ).isRequired,
};

export default TirePerformanceChasisTable;
