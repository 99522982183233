import { http } from "store/actions/http";
import { UPDATE_TIRE_MODEL } from "store/actions";

import { actionShowProgress } from "store/actions/general/progress";

export const actionUpdateTireModel =
  (dispatch) => async (fields, tireModelId, dataWithFiles) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "POST",
        path: `tire/model/${tireModelId}`,
        data: fields,
        dataWithFiles,
      });

      dispatch({
        type: UPDATE_TIRE_MODEL,
        payload: response,
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
