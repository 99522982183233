import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useLang } from "hooks/lang";
import { useHistory } from "react-router-dom";

import { actionGetTireAlerts } from "store/actions/alert/tires/getTireAlerts";
import { actionClearTireAlerts } from "store/actions/alert/tires/clearTireAlerts";
import { actionSetNotification } from "store/actions/general/notification";
import { actionSetTireAlertsFilters } from "store/actions/alert/tires/setTireAlertsFilters";

import Page from "./page";

export function TireAlerts({ ...rest }) {
  const t = useLang();
  const history = useHistory();
  const {
    tires,
    getTireAlerts,
    setTireAlertsFilters,
    clearTireAlerts,
    clearTireAlertsFilters,
    setNotification,
    filter,
  } = rest;
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        await getTireAlerts({ page: 1, ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearTireAlerts();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearTireAlertsFilters();
    };
  }, []);

  async function onChangePage(page) {
    try {
      await getTireAlerts({
        page,
        ...filter,
      });
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setOpenFilter(true);
  }

  function handleCloseFilter() {
    setOpenFilter(false);
  }

  function handleSearch(search) {
    setTireAlertsFilters({ search });
  }

  function handleViewAlerts(tireId) {
    history.push(`/tire/${tireId}/alerts`);
  }

  return (
    <Page
      t={t}
      tires={tires}
      openFilter={openFilter}
      search={filter.search}
      onChangePage={onChangePage}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleSearch={handleSearch}
      handleViewAlerts={handleViewAlerts}
    />
  );
}

TireAlerts.propTypes = {};

const mapStateToProps = (state) => ({
  tires: state.Alert.TireAlerts.tires,
  filter: state.Alert.TireAlerts.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getTireAlerts: actionGetTireAlerts(dispatch),
  clearTireAlerts: actionClearTireAlerts(dispatch),
  setTireAlertsFilters: actionSetTireAlertsFilters(dispatch),
  clearTireAlertsFilters: actionClearTireAlerts(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TireAlerts);
