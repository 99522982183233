import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetGpsGeofences } from "store/actions/gps/geofence/getGpsGeofences";
import { actionClearGpsGeofences } from "store/actions/gps/geofence/clearGpsGeofences";
import { actionClearGpsGeofenceFilters } from "store/actions/gps/geofence/clearGpsGeofenceFilter";

import { useLang } from "hooks/lang";

import Page from "./page";

import { SHOW_GPS_GEOFENCE } from "store/actions/account/permits";

export const NAMESPACE = "pages/gps/GpsGeofenceTab";

export function GpsGeofenceTab({ ...rest }) {
  const t = useLang(NAMESPACE);
  const { id } = useParams();
  const [filterOpen, setFilterOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [gpsGeofenceId, setGpsGeofenceId] = useState(null);
  const {
    permits,
    filter,
    gpsGeofenceList,
    setNotification,
    getGpsGeofences,
    clearGpsGeofences,
    clearGpsGeofenceFilters,
  } = rest;

  useEffect(() => {
    async function fetchData() {
      try {
        await getGpsGeofences({ page: 1, ...filter }, id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();
    return () => {
      clearGpsGeofences();
    };
  }, [filter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [gpsGeofenceList.data]);

  useEffect(() => {
    return () => {
      clearGpsGeofenceFilters();
    };
  }, []);

  async function handleChangePage(page) {
    try {
      await getGpsGeofences({ page, ...filter }, id);
    } catch (error) {
      setNotification(error, true);
    }
  }

  function handleOpenFilter() {
    setFilterOpen(true);
  }

  function handleCloseFilter() {
    setFilterOpen(false);
  }

  function handleOpenDialog(id) {
    setOpenDialog(true);
    setGpsGeofenceId(id);
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    setGpsGeofenceId(null);
  }

  return (
    <Page
      t={t}
      gpsGeofenceList={gpsGeofenceList}
      openDialog={openDialog}
      gpsId={id}
      gpsGeofenceId={gpsGeofenceId}
      showGpsGeofencePermit={permits.includes(SHOW_GPS_GEOFENCE)}
      handleChangePage={handleChangePage}
      filterOpen={filterOpen}
      handleOpenFilter={handleOpenFilter}
      handleCloseFilter={handleCloseFilter}
      handleOpenDialog={handleOpenDialog}
      handleCloseDialog={handleCloseDialog}
    />
  );
}

GpsGeofenceTab.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  gpsGeofenceList: state.Gps.GpsGeofence.gpsGeofenceList,
  filter: state.Gps.GpsGeofence.filter,
});
const mapDispatchToProps = (dispatch) => ({
  getGpsGeofences: actionGetGpsGeofences(dispatch),
  clearGpsGeofences: actionClearGpsGeofences(dispatch),
  setNotification: actionSetNotification(dispatch),
  clearGpsGeofenceFilters: actionClearGpsGeofenceFilters(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GpsGeofenceTab);
