import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Page({
  t,
  editorState,
  handleEditorChange,
  isValid,
  isSubmitting,
  onClose,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames(css.form)}>
      <Field
        required
        variant="filled"
        margin="normal"
        name="title"
        component={TextField}
        className={classnames(css.field)}
        label={t("_labels.title")}
      />

      <div className={classnames(css.field)}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list"],
            inline: { options: ["bold", "italic", "underline"] },
          }}
          wrapperClassName={classnames(css.editor, css.wrapper)}
          toolbarClassName={classnames(css.toolbar)}
          editorClassName={classnames(css.ml8)}
        />
      </div>

      <DialogActions className={classnames(css.action, css.mt32)}>
        <Button
          type="button"
          onClick={onClose}
          color="secondary"
          disabled={isSubmitting}
        >
          {t("_buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="secondary"
          disabled={
            isSubmitting ||
            !isValid ||
            convertToRaw(editorState.getCurrentContent())
              .blocks.map((block) => block.text)
              .join("\n")
              .trim() === ""
          }
        >
          {t("_buttons.save")}
        </Button>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editorState: PropTypes.object,
  handleEditorChange: PropTypes.func,
};

export default Page;
