import CorporateForm from "./CorporateForm";
import FilterCorporateForm from "./FilterCorporateForm";
import InfoCorporateCard from "./InfoCorporateCard";
import CorporateTabs from "./CorporateTabs";

export default {
  corporate_tabs: CorporateTabs,
  corporate_form: CorporateForm,
  filter_corporate_form: FilterCorporateForm,
  info_corporate_card: InfoCorporateCard,
};
