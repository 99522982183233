export default {
  stored_tires: "Neumáticos almacenados",
  no_stored_tires: "Sin neumáticos almacenados",
  store_tire: "Almacenar neumático",
  form: {
    buttons: {
      store: "Almacenar",
    },
  },
  permits: {
    link: {
      tire_with_warehouse:
        "Sin permisos para vincular neumáticos con almacenes",
    },
    list: {
      link: {
        tire_with_warehouse:
          "Sin permisos para listar neumáticos vinculados con almacenes",
      },
    },
  },
  messages: {
    tire_with_warehouse_linked: "Listo, el neumático fue almacenado con éxito",
  },
};
