import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLang } from "hooks/lang";
import { alphabeticalSort } from "utils/sort";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetRevitalizedTireModel } from "store/actions/revitalized/tire/model/getRevitalizedTireModel";
import { actionGetRevitalizedTireModels } from "store/actions/revitalized/tire/model/getRevitalizedTireModels";
import { actionSetRevitalizedTireModelFilters } from "store/actions/revitalized/tire/model/setRevitalizedTireModelFilters";
import { actionClearRevitalizedTireModelFilters } from "store/actions/revitalized/tire/model/clearRevitalizedTireModelFilters";
import { actionClearRevitalizedTireModels } from "store/actions/revitalized/tire/model/clearRevitalizedTireModels";
import { actionSetRevitalizedTireModelStatus } from "store/actions/revitalized/tire/model/setRevitalizedTireModelStatus";
import { actionAddRevitalizedTireModel } from "store/actions/revitalized/tire/model/addRevitalizedTireModel";
import { actionUpdateRevitalizedTireModel } from "store/actions/revitalized/tire/model/updateRevitalizedTireModel";
import { actionDeleteRevitalizedTireModel } from "store/actions/revitalized/tire/model/deleteRevitalizedTireModel";
import { actionGetBrands } from "store/actions/brand/getBrands";
import { actionGetTireApplicationTypes } from "store/actions/tire/application/getTireApplicationTypes";
import { actionSetRevitalizedTireModelApprovalStatus } from "store/actions/revitalized/tire/model/setRevitalizedTireModelApprovalStatus";

import {
  CREATE_REVITALIZED_TIRE_MODEL,
  UPDATE_REVITALIZED_TIRE_MODEL,
  DELETE_REVITALIZED_TIRE_MODEL,
} from "store/actions/account/permits";
import Page from "./page";

export function HomeRevitalizedTireModel({ ...rest }) {
  const t = useLang();
  const {
    filter,
    permits,
    setNotification,
    revitalizedTireModel,
    revitalizedTireModels,
    getRevitalizedTireModel,
    getRevitalizedTireModels,
    setRevitalizedTireModelFilters,
    clearRevitalizedTireModelFilters,
    clearRevitalizedTireModels,
    setRevitalizedTireModelStatus,
    setRevitalizedTireModelApprovalStatus,
    addRevitalizedTireModel,
    updateRevitalizedTireModel,
    deleteRevitalizedTireModel,
    getBrands,
    getTireApplicationTypes,
  } = rest;
  const [brands, setTireBrands] = useState([]);
  const [tireApplicationTypes, setTireApplicationTypes] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        let data = [];

        data = await getTireApplicationTypes(
          { scope: "tire_application_id,status" },
          false
        );

        setTireApplicationTypes(
          data.sort(alphabeticalSort("tire_application_id"))
        );

        data = await getBrands(
          { scope: "brand_id,name,status,approved", brandType: "RETREAD" },
          false
        );
        setTireBrands(data.sort(alphabeticalSort("name")));
      } catch (error) {
        setNotification(error, true);
      }
    }
    fetchData();
  }, []);

  return (
    <Page
      t={t}
      filter={filter}
      revitalizedTireModel={revitalizedTireModel}
      revitalizedTireModels={revitalizedTireModels}
      getRevitalizedTireModel={getRevitalizedTireModel}
      getRevitalizedTireModels={getRevitalizedTireModels}
      addRevitalizedTireModel={addRevitalizedTireModel}
      updateRevitalizedTireModel={updateRevitalizedTireModel}
      deleteRevitalizedTireModel={deleteRevitalizedTireModel}
      setRevitalizedTireModelFilters={setRevitalizedTireModelFilters}
      clearRevitalizedTireModelFilters={clearRevitalizedTireModelFilters}
      clearRevitalizedTireModels={clearRevitalizedTireModels}
      setRevitalizedTireModelStatus={setRevitalizedTireModelStatus}
      setRevitalizedTireModelApprovalStatus={
        setRevitalizedTireModelApprovalStatus
      }
      brands={brands}
      tireApplicationTypes={tireApplicationTypes}
      createRevitalizedTireModelPermit={permits.includes(
        CREATE_REVITALIZED_TIRE_MODEL
      )}
      updateRevitalizedTireModelPermit={permits.includes(
        UPDATE_REVITALIZED_TIRE_MODEL
      )}
      deleteRevitalizedTireModelPermit={permits.includes(
        DELETE_REVITALIZED_TIRE_MODEL
      )}
    />
  );
}

HomeRevitalizedTireModel.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  revitalizedTireModels: state.Revitalized.Tire.Model.revitalized_models,
  revitalizedTireModel: state.Revitalized.Tire.Model.revitalized_model,
  filter: state.Revitalized.Tire.Model.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getRevitalizedTireModels: actionGetRevitalizedTireModels(dispatch),
  getRevitalizedTireModel: actionGetRevitalizedTireModel(dispatch),
  clearRevitalizedTireModels: actionClearRevitalizedTireModels(dispatch),
  setRevitalizedTireModelFilters:
    actionSetRevitalizedTireModelFilters(dispatch),
  clearRevitalizedTireModelFilters:
    actionClearRevitalizedTireModelFilters(dispatch),
  addRevitalizedTireModel: actionAddRevitalizedTireModel(dispatch),
  updateRevitalizedTireModel: actionUpdateRevitalizedTireModel(dispatch),
  deleteRevitalizedTireModel: actionDeleteRevitalizedTireModel(dispatch),
  setRevitalizedTireModelStatus: actionSetRevitalizedTireModelStatus(dispatch),
  getBrands: actionGetBrands(dispatch),
  getTireApplicationTypes: actionGetTireApplicationTypes(dispatch),
  setRevitalizedTireModelApprovalStatus:
    actionSetRevitalizedTireModelApprovalStatus(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeRevitalizedTireModel);
