import {
  CREATE_TIRE_MODEL,
  UPDATE_TIRE_MODEL,
  GET_TIRE_MODEL,
  CLEAR_TIRE_MODEL,
  GET_TIRE_MODELS,
  CLEAR_TIRE_MODELS,
  SET_TIRE_MODEL_FILTERS,
  CLEAR_TIRE_MODEL_FILTERS,
  GET_TIRE_VARIATION_MODEL,
  SET_TIRE_MODEL_APPROVAL_STATUS,
  SET_TIRE_MODEL_STATUS,
  DELETE_TIRE_MODEL,
} from "store/actions";

const initialState = {
  model: {
    name: "",
    brand: {
      name: "",
    },
    status: 0,
    approved: 0,
    data_sheet: "",
    revitalized: 0,
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
    tire_model_use: [],
    tire_model_application: [],
    variation: [],
  },
  tire_models: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
  filter_tire_model: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_TIRE_MODEL:
    case UPDATE_TIRE_MODEL:
    case GET_TIRE_MODEL:
      return {
        ...state,
        model: action.payload,
      };

    case CLEAR_TIRE_MODEL:
      return {
        ...state,
        model: { ...initialState.model },
      };

    case GET_TIRE_MODELS:
      return {
        ...state,
        tire_models: action.payload,
      };

    case CLEAR_TIRE_MODELS:
      return {
        ...state,
        tire_models: { ...initialState.tire_models },
      };

    case SET_TIRE_MODEL_FILTERS:
      const filter = {
        ...state.filter_tire_model,
        ...action.payload,
      };

      for (let key in filter) {
        if (
          filter[key] === undefined ||
          filter[key] === null ||
          filter[key] === ""
        ) {
          delete filter[key];
        }
      }

      return {
        ...state,
        filter_tire_model: filter,
      };

    case CLEAR_TIRE_MODEL_FILTERS:
      return {
        ...state,
        filter_tire_model: { search: state.filter_tire_model.search },
      };

    case GET_TIRE_VARIATION_MODEL:
      return {
        ...state,
      };

    case SET_TIRE_MODEL_APPROVAL_STATUS:
      return {
        ...state,
        tire_models: {
          ...state.tire_models,
          data: state.tire_models.data.map((tireModel) =>
            tireModel.tire_model_variation_id != action.payload
              ? tireModel
              : { ...tireModel, approved: !tireModel.approved }
          ),
        },
      };

    case SET_TIRE_MODEL_STATUS:
      return {
        ...state,
        tire_models: {
          ...state.tire_models,
          data: state.tire_models.data.map((tireModel) =>
            tireModel.tire_model_variation_id != action.payload
              ? tireModel
              : { ...tireModel, status: !tireModel.status }
          ),
        },
      };

    case DELETE_TIRE_MODEL:
      return {
        ...state,
        tire_models: {
          ...state.tire_models,
          data: state.tire_models.data.filter(
            (tireModel) => tireModel.tire_model_variation_id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
}
