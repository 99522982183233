import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Formik } from "formik";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogMediaQuery from "components/atoms/general/DialogMediaQuery";
import LinkedItemPanel from "components/molecules/general/LinkedItemPanel";
import WarehouseTireInfoDialog from "components/organisms/warehouse/tire/WarehouseTireInfoDialog";

import Form from "./Form";

function Page({
  t,
  open,
  dialogOpen,
  tireId,
  className,
  warehouse,
  subsidiary,
  initialValues,
  initialErrors,
  showTirePermit,
  showLinkTireWarehousePermit,
  linkTireWithWarehousePermit,
  deleteLinkTireWithWarehousePermit,
  listLinkWarehouseWithSubsidiaryPermit,
  listLinkHistoryTireWithWarehousePermit,
  handleLink,
  handleClose,
  handleUnlink,
  handleSubmit,
  handleHistory,
  handleValidate,
  handleOpenDialog,
  handleCloseDialog,
}) {
  return (
    <>
      <LinkedItemPanel
        label={t("_labels.warehouse_name")}
        title={t("_general.warehouse")}
        className={classnames(className)}
        text={
          !showTirePermit
            ? t("permits.show.tire_warehouse")
            : !warehouse
            ? t("not_any_warehouse")
            : warehouse.warehouse.name
        }
        actionButtonName={
          !!warehouse ? t("buttons.take_out") : t("_general.store")
        }
        actionButtonTooltip={
          !subsidiary
            ? t("_labels.unassigned_subsidiary")
            : !!warehouse && !deleteLinkTireWithWarehousePermit
            ? t("_permits.delete_link.tire_with_warehouse")
            : !warehouse
            ? !linkTireWithWarehousePermit
              ? t("_permits.link.tire_with_warehouse")
              : !listLinkWarehouseWithSubsidiaryPermit
              ? t("_permits.list_link.warehouse_with_subsidiary")
              : ""
            : ""
        }
        viewButtonTooltip={
          !showLinkTireWarehousePermit
            ? t("_permits.show.link.tire_with_warehouse")
            : ""
        }
        actionButtonDisabled={
          !subsidiary ||
          !showTirePermit ||
          (!!warehouse && !deleteLinkTireWithWarehousePermit) ||
          (!warehouse &&
            (!linkTireWithWarehousePermit ||
              !listLinkWarehouseWithSubsidiaryPermit))
        }
        viewButtonDisabled={
          !showTirePermit || !warehouse || !showLinkTireWarehousePermit
        }
        onHistory={handleHistory}
        historyTooltipPermit={
          !listLinkHistoryTireWithWarehousePermit
            ? t("_permits.list_link.history.warehouse_with_tire")
            : ""
        }
        onView={handleOpenDialog}
        onAction={!!warehouse ? handleUnlink : handleLink}
      />

      <DialogMediaQuery
        size={"sm"}
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>{t("_general.store")}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            onSubmit={handleSubmit}
            validate={handleValidate}
          >
            {(p) => (
              <Form
                {...p}
                subsidiaryId={subsidiary?.subsidiary_id}
                onClose={handleClose}
              />
            )}
          </Formik>
        </DialogContent>
      </DialogMediaQuery>

      {!!warehouse && (
        <WarehouseTireInfoDialog
          tireId={tireId}
          open={dialogOpen}
          warehouseId={warehouse.warehouse_id}
          onClose={handleCloseDialog}
          viewButtonName={t("buttons.view_warehouse")}
        />
      )}
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  tireId: PropTypes.string.isRequired,
  warehouse: PropTypes.object,
  subsidiary: PropTypes.object,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialErrors: PropTypes.object.isRequired,
  showTirePermit: PropTypes.bool.isRequired,
  showLinkTireWarehousePermit: PropTypes.bool.isRequired,
  linkTireWithWarehousePermit: PropTypes.bool.isRequired,
  deleteLinkTireWithWarehousePermit: PropTypes.bool.isRequired,
  listLinkWarehouseWithSubsidiaryPermit: PropTypes.bool.isRequired,
  listLinkHistoryTireWithWarehousePermit: PropTypes.bool.isRequired,
  handleLink: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUnlink: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleHistory: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default Page;
