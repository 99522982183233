import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionResetVehicleReview } from "store/actions/vehicle/resetReview";
import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function ResetOdometerForm({ open, vehicleId, onClose, ...rest }) {
  const t = useLang();

  const { setNotification, resetVehicleReview } = rest;

  const initialDefaultValues = {
    odometer: 0,
  };
  const {
    initialValues,
    initialErrors,
    resetFormik,
    setFormikErrors,
    handleFormikValidate,
  } = useFormik({
    initialValues: initialDefaultValues,
  });

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, ...rest }) {
    const fields = {
      odometer: values.odometer,
    };

    try {
      const response = await resetVehicleReview(vehicleId, fields);
      setNotification({
        message: response.message,
      });

      onClose();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
    setSubmitting(false);
  }

  return (
    <Page
      t={t}
      open={open}
      initialValues={initialValues}
      initialErrors={initialErrors}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

ResetOdometerForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  resetVehicleReview: actionResetVehicleReview(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetOdometerForm);
