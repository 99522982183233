import { DELETE_LINK_TPMS_WITH_TIRE } from "store/actions";
import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionDeleteLinkTpmsWithTire = (dispatch) => async (tpmsId) => {
  const showProgress = actionShowProgress(dispatch);
  const progress = showProgress();

  try {
    const response = await http({
      method: `DELETE`,
      path: `tpgps/${tpmsId}/tire`,
    });
    dispatch({
      type: DELETE_LINK_TPMS_WITH_TIRE,
      payload: tpmsId,
    });

    return response;
  } catch (error) {
    throw error;
  } finally {
    showProgress(progress);
  }
};
