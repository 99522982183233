import { http } from "store/actions/http";

import { actionShowProgress } from "store/actions/general/progress";

export const actionGetDisposalYearly =
  (dispatch) =>
  async ({ date_from: dateFrom, date_to: dateTo, subsidiaries, companies }) => {
    const showProgress = actionShowProgress(dispatch);
    const progress = showProgress();

    try {
      const response = await http({
        method: "GET",
        path: `report/pile/depth/disposal/year`,
        params: {
          subsidiaries,
          companies,
          date_from: dateFrom,
          date_to: dateTo,
        },
      });

      return response;
    } catch (error) {
      throw error;
    } finally {
      showProgress(progress);
    }
  };
