import {
  GET_TIRE_MODEL_VARIATION,
  CLEAR_TIRE_MODEL_VARIATION,
  CREATE_TIRE_MODEL_VARIATION,
  UPDATE_TIRE_MODEL_VARIATION,
  DELETE_TIRE_MODEL_VARIATION,
  GET_TIRE_MODEL_VARIATIONS,
  CLEAR_TIRE_MODEL_VARIATIONS,
  SET_TIRE_MODEL_VARIATION_STATUS,
  SET_TIRE_MODEL_VARIATION_APPROVED,
} from "store/actions";

const initialState = {
  variation: {
    tire_model_variation_id: 0,
    status: 0,
    approved: 0,
    depth: 0,
    number_layers: 0,
    tire_size: {
      size: "",
    },
    created_at: "0001-01-01T00:00:00.000Z",
    created_by: {
      name: "",
      last_name_1: "",
    },
  },
  tire_model_variations: {
    current_page: 1,
    data: [],
    last_page: 1,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TIRE_MODEL_VARIATION:
      return {
        ...state,
        variation: action.payload,
      };

    case CLEAR_TIRE_MODEL_VARIATION:
      return {
        ...state,
        variation: { ...initialState.variation },
      };

    case CREATE_TIRE_MODEL_VARIATION:
      return {
        ...state,
        tire_model_variations: {
          ...state.tire_model_variations,
          data: [action.payload, ...state.tire_model_variations.data],
        },
      };

    case UPDATE_TIRE_MODEL_VARIATION:
      return {
        ...state,
        tire_model_variations: {
          ...state.tire_model_variations,
          data: state.tire_model_variations.data.map((variation) =>
            variation.tire_model_variation_id !==
              action.payload.tire_model_variation_id ||
            variation.tire_model_id !== action.payload.tire_model_id
              ? variation
              : action.payload
          ),
        },
      };

    case DELETE_TIRE_MODEL_VARIATION:
      return {
        ...state,
        tire_model_variations: {
          ...state.tire_model_variations,
          data: state.tire_model_variations.data.filter(
            (variation) =>
              variation.tire_model_variation_id !=
                action.payload.tireModelVariationId ||
              variation.tire_model_id != action.payload.tireModelId
          ),
        },
      };

    case GET_TIRE_MODEL_VARIATIONS:
      return {
        ...state,
        tire_model_variations: action.payload,
      };

    case CLEAR_TIRE_MODEL_VARIATIONS:
      return {
        ...state,
        tire_model_variations: { ...initialState.tire_model_variations },
      };

    case SET_TIRE_MODEL_VARIATION_STATUS:
      return {
        ...state,
        tire_model_variations: {
          ...state.tire_model_variations,
          data: state.tire_model_variations.data.map((variation) =>
            variation.tire_model_id === parseInt(action.payload.tireModelId) &&
            variation.tire_model_variation_id ===
              parseInt(action.payload.tireModelVariationId)
              ? (variation = { ...variation, status: !variation.status })
              : variation
          ),
        },
      };

    case SET_TIRE_MODEL_VARIATION_APPROVED:
      return {
        ...state,
        tire_model_variations: {
          ...state.tire_model_variations,
          data: state.tire_model_variations.data.map((variation) =>
            variation.tire_model_id === parseInt(action.payload.tireModelId) &&
            variation.tire_model_variation_id ===
              parseInt(action.payload.tireModelVariationId)
              ? (variation = { ...variation, approved: !variation.approved })
              : variation
          ),
        },
      };

    default:
      return state;
  }
}
