import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import { Zoom } from "@material-ui/core";

import tiresIcon from "resources/img/icons/rueda.png";
import css from "./index.module.scss";

const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const colors = {
  WAREHOUSE: "bg-success",
  MOUNT: "bg-primary",
  PILE: "bg-danger",
  REVITALIZATION: "bg-info",
  REPAIR: "bg-secondary",
  TOTAL: "bg-dark",
};

const bgProgressColor = {
  WAREHOUSE: "#c3e6cb",
  MOUNT: "#b8daff",
  PILE: "#f5c6cb",
  REVITALIZATION: "#bee5eb",
  REPAIR: "#d6d8db",
  TOTAL: "#c6c8ca",
};

const textColors = {
  WAREHOUSE: "text-success",
  MOUNT: "text-primary",
  PILE: "text-danger",
  REVITALIZATION: "text-info",
  REPAIR: "text-secondary",
  TOTAL: "text-dark",
};

function Page({ t, data, total, pageKey }) {
  return (
    <div className="card">
      {data.length > 0 && (
        <div className="card-header sMarcoInfoFondoAzul">
          <p className="m-0 h5">{pageKey}</p>
        </div>
      )}
      <div className="card-body px-5">
        <div
          className={classnames({
            [css.container]: true,
          })}
        >
          <Zoom in timeout={500}>
            <div
              className="d-flex flex-column justify-content-start align-items-center text-center"
              style={{ width: "100%" }}
            >
              <p
                className="m-0 p-0 h5 d-flex justify-content-center align-items-center text-uppercase"
                style={{ gap: "4px" }}
              >
                <img src={tiresIcon} width={36} />
                {t("_labels.tires_by_location")}
              </p>

              <div className="row justify-content-center mt-3">
                {total && (
                  <div className="col-md-12 mb-4">
                    <div
                      className={classnames("card shadow border-0", css.card)}
                      style={{ borderRadius: "1rem" }}
                    >
                      <Card
                        t={t}
                        title={total.movement}
                        movement={total.movement}
                        tireCount={total.tire_count}
                        percent={total.percent}
                      />
                    </div>
                  </div>
                )}

                {data.slice(0, 2).map((item) => (
                  <div className="col-md-6 mb-4" key={item.movement}>
                    <Card
                      t={t}
                      title={t(
                        `_labels.location.options.${item.movement.toLowerCase()}`
                      )}
                      movement={item.movement}
                      tireCount={item.tire_count}
                      percent={item.percent}
                    />
                  </div>
                ))}

                {data.slice(2, 5).map((item) => (
                  <div className="col-md-4 mb-4" key={item.movement}>
                    <div
                      className={classnames("card shadow border-0", css.card)}
                      style={{ borderRadius: "1rem" }}
                    >
                      <Card
                        t={t}
                        title={t(
                          `_labels.location.options.${item.movement.toLowerCase()}`
                        )}
                        movement={item.movement}
                        tireCount={item.tire_count}
                        percent={item.percent}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.object,
  pageKey: PropTypes.string,
};

export default Page;

function Card({ t, title, movement, tireCount, percent }) {
  return (
    <div
      className={classnames("card shadow border-0", css.card)}
      style={{
        borderRadius: "1rem",
        "--bgColor": `${bgProgressColor[movement]}80`,
      }}
    >
      <div
        className={`card-header text-white border-bottom-0 ${colors[movement]}`}
        style={{ borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem" }}
      >
        {title}
      </div>
      <div className="card-body px-4">
        <p className="card-title h5">
          {formatter.format(tireCount)}{" "}
          {tireCount == 1
            ? t("_labels.tire.singular")
            : t("_labels.tire.plural")}
        </p>
        <div
          className="progress mb-2"
          style={{ backgroundColor: bgProgressColor[movement] }}
        >
          <div
            className={`progress-bar ${colors[movement]}`}
            role="progressbar"
            style={{ width: `${percent}%` }}
            aria-valuenow={percent}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <p className={`card-text font-weight-bold ${textColors[movement]}`}>
          {percentFormatter.format(percent)}%
        </p>
      </div>
    </div>
  );
}

Card.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
  movement: PropTypes.string,
  tireCount: PropTypes.number,
  percent: PropTypes.number,
};
