import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

function Page(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path d="M38.5 15.5q-.85 0-1.425-.575-.575-.575-.575-1.425 0-.4.175-.775t.425-.625q.6-.6 2.75-1.35L42 10q-.4 1.15-.75 2.15-.3.85-.675 1.65-.375.8-.675 1.1-.25.25-.625.425t-.775.175ZM7 42q-1.25 0-2.125-.875T4 39V9q0-1.25.875-2.125T7 6h13.5q1.25 0 2.125.875T23.5 9v18.05q.7-.25 1.35-.4.65-.15 1.15-.15 2.3 0 3.9 1.6t1.6 3.9v4.25q0 1 .875 1.875T34.3 39q1 0 1.85-.875.85-.875.85-1.875V25h-2v-2.7q-2.7-1.15-4.35-3.5Q29 16.45 29 13.5q0-3.95 2.8-6.725Q34.6 4 38.55 4q3.9 0 6.675 2.775T48 13.5q0 2.95-1.65 5.3-1.65 2.35-4.35 3.5V25h-2v11.25q0 2.25-1.725 4T34.3 42q-2.3 0-4.05-1.75t-1.75-4V32q0-1.05-.725-1.775Q27.05 29.5 26 29.5q-1 0-1.75.725T23.5 32v7q0 1.25-.875 2.125T20.5 42Zm31.5-22q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6Q41.2 7 38.5 7q-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6 0 2.7 1.9 4.6 1.9 1.9 4.6 1.9ZM11 16.3v-4.25L7.95 9H7v3.3Zm5.5 0 4-4V9h-.95l-3.05 3.05ZM11 26.8v-4.25l-4-4v4.25Zm5.5 0 4-4v-4.25l-4 4Zm-5.5 10v-4.25l-4-4v4.25Zm5.5 0 4-4v-4.25l-4 4Z" />
    </SvgIcon>
  );
}

export default Page;
