import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actionAddConditionPolicy } from "store/actions/subsidiary/policy/addConditionPolicy";
import { actionClearConditionPoliciesFilters } from "store/actions/subsidiary/policy/clearConditionPoliciesFilters";
import { actionSetNotification } from "store/actions/general/notification";

import { useLang } from "hooks/lang";
import { useFormik } from "hooks/formik";

import Page from "./page";

export function MultipleConditionPolicyForm({
  open,
  onClose,
  subsidiaryId,
  vehicleIds,
  ...rest
}) {
  const t = useLang();
  const { addConditionPolicy, clearConditionPoliciesFilters, setNotification } =
    rest;
  const {
    initialValues,
    initialErrors,
    resetFormik,
    handleFormikValidate,
    setFormikErrors,
  } = useFormik({
    initialValues: {
      axle_policy: [
        {
          axle_type: "",
          condition: "",
        },
      ],
    },
  });

  useEffect(() => {
    if (!open) {
      resetFormik();
    }
  }, [open]);

  async function handleSubmit(values, { setSubmitting, resetForm, ...rest }) {
    const fields = {
      vehicles: vehicleIds.map((vehicleId) => ({ vehicle_id: vehicleId })),
      axle_policy: values.axle_policy,
    };

    try {
      await addConditionPolicy(subsidiaryId, fields);
      resetForm();
      setNotification({
        message: t("_messages.created.policy"),
      });

      setSubmitting(false);
      onClose();
      clearConditionPoliciesFilters();
    } catch (error) {
      setFormikErrors(error, values, rest);
    }
  }

  return (
    <Page
      t={t}
      open={open}
      initialErrors={initialErrors}
      initialValues={initialValues}
      onClose={onClose}
      handleSubmit={handleSubmit}
      handleValidate={handleFormikValidate}
    />
  );
}

MultipleConditionPolicyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  subsidiaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vehicleIds: PropTypes.array,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addConditionPolicy: actionAddConditionPolicy(dispatch),
  clearConditionPoliciesFilters: actionClearConditionPoliciesFilters(dispatch),
  setNotification: actionSetNotification(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleConditionPolicyForm);
