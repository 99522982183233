import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetBlocks } from "store/actions/block/getBlocks";
import { actionClearBlocks } from "store/actions/block/clearBlocks";
import { actionSetBlockFilters } from "store/actions/block/setBlockFilters";
import { actionClearBlockFilters } from "store/actions/block/clearBlockFilters";
import { actionDeleteBlock } from "store/actions/block/deleteBlock";
import { useLang } from "hooks/lang";

import {
  CREATE_BLOCK,
  UPDATE_BLOCK,
  DELETE_BLOCK,
} from "store/actions/account/permits";
import Page from "./page";

function HomeBlock({ ...rest }) {
  const t = useLang();
  const {
    blocks,
    filter,
    permits,
    setNotification,
    getBlocks,
    clearBlocks,
    setBlockFilters,
    clearBlockFilters,
    deleteBlock,
  } = rest;

  const [openForm, setOpenForm] = useState(false);
  const [blockId, setBlockId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await getBlocks({ ...filter });
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearBlocks();
    };
  }, [filter]);

  useEffect(() => {
    return () => {
      clearBlockFilters();
    };
  }, []);

  function handleChangePage(page) {
    setBlockFilters({ ...filter, page });
  }

  function handleSearch(search) {
    setBlockFilters({ ...filter, page: null, search });
  }

  function handleOpenForm() {
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
    setBlockId(null);
  }

  function handleEdit(blockId) {
    setBlockId(blockId);
    setOpenForm(true);
  }

  function handleDelete(blockId) {
    setNotification({
      message: t("_messages.delete.block"),
      textAction: t("_messages.confirm"),
      onAction: () => onDeleteBlock(blockId),
    });
  }

  async function onDeleteBlock(blockId) {
    try {
      const response = await deleteBlock(blockId);
      setNotification({
        message: response.message,
      });
      getBlocks({ ...filter });
    } catch (error) {
      setNotification(error, true);
    }
  }

  return (
    <Page
      t={t}
      blocks={blocks}
      search={filter.search}
      blockId={blockId}
      openForm={openForm}
      handleSearch={handleSearch}
      handleChangePage={handleChangePage}
      handleOpenForm={handleOpenForm}
      handleCloseForm={handleCloseForm}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      createBlockPermit={permits.includes(CREATE_BLOCK)}
      updateBlockPermit={permits.includes(UPDATE_BLOCK)}
      deleteBlockPermit={permits.includes(DELETE_BLOCK)}
    />
  );
}

const mapStateToProps = (state) => ({
  blocks: state.Block.blocks,
  filter: state.Block.filter_block,
  permits: state.Account.permits,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getBlocks: actionGetBlocks(dispatch),
  clearBlocks: actionClearBlocks(dispatch),
  setBlockFilters: actionSetBlockFilters(dispatch),
  clearBlockFilters: actionClearBlockFilters(dispatch),
  deleteBlock: actionDeleteBlock(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeBlock);
