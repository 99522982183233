import React from "react";

import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useLang } from "hooks/lang";

const styles = StyleSheet.create({
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  subTitle: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "rgb(0, 40, 73)",
    padding: "5px 0px 5px 0px",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableRowHeader: {
    backgroundColor: "#0061a4",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  tableRowChasisHeader: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    backgroundColor: "#212529",
    color: "white",
  },
  tableRowHeader2: {
    backgroundColor: "#212529",
    fontWeight: "bold",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  row1: {
    backgroundColor: "rgb(240, 240, 240)",
  },
  row2: {
    backgroundColor: "rgb(220, 220, 220)",
  },
  tableColHeader: {
    width: "100%",
  },
  tableColChasisHeader: {
    width: "100%",
  },
  tableCol: {
    width: "16.66%",
  },
  tableCol2: {
    width: "6.66%",
  },
  tableCol3: {
    width: "25%",
    border: "2px solid black",
    borderLeft: "none",
    padding: "4px",
  },
  tableCol3Left: {
    borderLeft: "2px solid black",
  },
  tableCell: {
    // margin: "auto",
    fontSize: 7,
    padding: "2px",
    display: "flex",
    alignSelf: "center",
  },
});

const formatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});
const kmFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});
const percentFormatter = new Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const ReviewHistoryPdf = (props) => {
  const t = useLang();

  function getAxleOffset(axleNumber) {
    let offset = 0;
    for (let i = 0; i < axleNumber; i++) {
      offset += props.vehicleTypeAxles[i].tireCount;
    }
    return offset;
  }

  return (
    <Document key={props.uuid}>
      <Page
        size={"LETTER"}
        style={{ padding: "20px 20px 20px 20px" }}
        orientation={"landscape"}
      >
        <View style={styles.title}>
          <Text>Desempeño de Neumáticos</Text>
        </View>
        <View
          style={{
            height: "20px",
          }}
        />
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}>
                {props.reviewInfo.companyName}
              </Text>
              <Text style={styles.tableCell}>
                Vehículo {props.reviewInfo.economicNumber}
              </Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tipo de Revisión</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Estatus</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Fecha</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Kilometraje del Vehículo</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Usuario</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Conductor</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Comentario</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.row1]} wrap={false}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {t(
                  `_labels.review_type.options.${props.reviewInfo.reviewType
                    .toLowerCase()
                    .replace(/\s|\//g, "_")}`
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.reviewInfo.isFinished
                  ? t("_labels.finalized")
                  : t("_labels.inspection_in_process")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(props.reviewInfo.date).format("LL hh:mm:ss a")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {formatter.format(props.reviewInfo.vehicleKm)} km
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.reviewInfo.userName ?? "-"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.reviewInfo.driverName ?? "-"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.reviewInfo.comment ?? "-"}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            height: "20px",
          }}
        />
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableRowHeader]} wrap={false}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCell}>Revisiones de neumáticos</Text>
            </View>
          </View>
          <View style={[styles.tableRow, styles.tableRowHeader2]} wrap={false}>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Código</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Posición</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Fecha{"\n"}Instalación</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Marca</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Modelo</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Medida</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Huella{"\n"}Modelo</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Huella{"\n"}Actual</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Diferencia{"\n"}Dual</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Km</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Meses{"\n"}Activo</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Desgaste{"\n"}Mensual</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>%{"\n"}Desgastado</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Presión</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>Proyección{"\n"}Km</Text>
            </View>
          </View>
          {props.tires.map((tire, i) => {
            return (
              <View
                style={[styles.tableRow, i % 2 ? styles.row1 : styles.row2]}
                key={tire.tireId}
                wrap={false}
              >
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.economicNumber ?? "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{tire.position ?? "-"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.createdAt
                      ? moment(tire.createdAt).format("DD/MM/YY")
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{tire.brand ?? "-"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{tire.model ?? "-"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>{tire.size ?? "-"}</Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.depthVariation !== undefined
                      ? tire.depthVariation
                      : "-"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol2,
                    {
                      fontFamily: "Helvetica-Bold",
                      color: "black",
                      backgroundColor:
                        tire.depthCondition === "CRITICAL WITHDRAWAL"
                          ? "#ffb0b0"
                          : tire.depthCondition === "SCHEDULED WITHDRAWAL"
                          ? "#ffefbd"
                          : "#c2ffc2",
                    },
                  ]}
                >
                  <Text style={[styles.tableCell]}>
                    {tire.depth !== undefined
                      ? formatter.format(tire.depth)
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.dualDifference !== undefined
                      ? formatter.format(tire.dualDifference)
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.accumulatedMileage !== undefined
                      ? kmFormatter.format(tire.accumulatedMileage)
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.monthsActive !== undefined ? tire.monthsActive : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.monthlyWear !== undefined
                      ? formatter.format(tire.monthlyWear)
                      : "-"}
                  </Text>
                </View>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.wearPercentage !== undefined
                      ? `${percentFormatter.format(tire.wearPercentage)}%`
                      : "-"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCol2,
                    {
                      fontFamily: "Helvetica-Bold",
                      color: "black",
                      backgroundColor: tire.pressureCondition?.includes("LOW")
                        ? "#ffb0b0"
                        : tire.pressureCondition?.includes("HIGH")
                        ? "#ffb0b0"
                        : "#c2ffc2",
                    },
                  ]}
                >
                  <Text style={styles.tableCell}>
                    {tire.pressure ? formatter.format(tire.pressure) : "-"}
                  </Text>
                </View>

                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>
                    {tire.projection
                      ? kmFormatter.format(tire.projection)
                      : "-"}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
      <Page
        size={"LETTER"}
        style={{ padding: "20px 20px 20px 20px" }}
        orientation={"landscape"}
      >
        <View style={styles.table}>
          {props.vehicleTypeAxles.map((axle, index) => {
            return (
              <View key={index} wrap={false}>
                <View
                  style={[styles.tableRow, styles.tableRowChasisHeader]}
                  wrap={false}
                >
                  <View style={styles.tableColChasisHeader}>
                    <Text style={styles.tableCell}>
                      {axle.position === "REFECTION"
                        ? `REFACCIÓN `
                        : `Eje ${index + 1} `}
                      {`(${axle.tireCount} neumáticos)`}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableRow]} wrap={false}>
                  {axle.tireCount < 3 && (
                    <View
                      key={index}
                      style={[styles.tableCol3, styles.tableCol3Left]}
                    ></View>
                  )}
                  {[...Array(axle.tireCount)].map((e, i) => {
                    const offset = getAxleOffset(index);
                    const tire = props.tires.find(
                      (t) => t.position === i + 1 + offset
                    );
                    if (!tire) {
                      return (
                        <View
                          key={i}
                          style={[
                            styles.tableCol3,
                            i === 0 && axle.tireCount >= 3
                              ? styles.tableCol3Left
                              : {},
                          ]}
                        >
                          <Text style={[styles.tableCell, { color: "red" }]}>
                            S/N
                          </Text>
                        </View>
                      );
                    }
                    return (
                      <View
                        key={i}
                        style={[
                          styles.tableCol3,
                          i === 0 && axle.tireCount >= 3
                            ? styles.tableCol3Left
                            : {},
                        ]}
                      >
                        <View>
                          <Text style={styles.tableCell}>
                            {axle.position === "REFECTION"
                              ? "R"
                              : `Posición ${tire.position ?? i + 1 + offset}`}
                            {tire.depthCondition && ": "}
                            {tire.depthCondition && (
                              <Text
                                style={[
                                  styles.tableCell,
                                  {
                                    color:
                                      tire.depthCondition ===
                                      "CRITICAL WITHDRAWAL"
                                        ? "red"
                                        : tire.depthCondition ===
                                          "SCHEDULED WITHDRAWAL"
                                        ? "#8d6c06"
                                        : "green",
                                  },
                                ]}
                              >
                                {t(
                                  `_labels.depth_tolerance_policies.${tire.depthCondition
                                    .replace(" ", "_")
                                    .toLowerCase()}`
                                )}
                              </Text>
                            )}
                          </Text>
                        </View>
                        <View>
                          {tire.economicNumber && (
                            <Text
                              style={styles.tableCell}
                            >{`Código: ${tire.economicNumber}`}</Text>
                          )}
                          {tire.brand && (
                            <Text style={styles.tableCell}>{tire.brand}</Text>
                          )}
                          {tire.model && (
                            <Text style={styles.tableCell}>{tire.model}</Text>
                          )}
                          {tire.size && (
                            <Text
                              style={styles.tableCell}
                            >{`LDD: ${tire.size}`}</Text>
                          )}
                          {tire.depth !== undefined && (
                            <Text style={styles.tableCell}>{`${formatter.format(
                              tire.depth
                            )} mm`}</Text>
                          )}
                          {tire.pressure !== undefined && (
                            <Text style={styles.tableCell}>
                              {`PSI: ${formatter.format(tire.pressure)}`}
                            </Text>
                          )}
                        </View>
                      </View>
                    );
                  })}
                  {axle.tireCount < 3 && (
                    <View key={index} style={[styles.tableCol3]}></View>
                  )}
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

ReviewHistoryPdf.propTypes = {
  uuid: PropTypes.string.isRequired,
  reviewInfo: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    economicNumber: PropTypes.string.isRequired,
    reviewType: PropTypes.string.isRequired,
    isFinished: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
    vehicleKm: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    driverName: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
  vehicleTypeAxles: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string.isRequired,
      tireCount: PropTypes.number.isRequired,
    })
  ).isRequired,
  tires: PropTypes.arrayOf(
    PropTypes.shape({
      tireId: PropTypes.number,
      economicNumber: PropTypes.string,
      deviceCode: PropTypes.string,
      position: PropTypes.number,
      createdAt: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      size: PropTypes.string,
      depthVariation: PropTypes.number,
      depthCondition: PropTypes.string,
      depth: PropTypes.number,
      dualDifference: PropTypes.number,
      accumulatedMileage: PropTypes.number,
      monthsActive: PropTypes.number,
      monthlyWear: PropTypes.number,
      wearPercentage: PropTypes.number,
      pressure: PropTypes.number,
      pressureCondition: PropTypes.string,
      projection: PropTypes.number,
      hasAlerts: PropTypes.bool,
      handleOpenAlertDialog: PropTypes.func,
    }).isRequired
  ),
};

export default ReviewHistoryPdf;
