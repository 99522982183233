import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import Container from "components/templates/general/Container";
import EmptyListIcon from "components/molecules/general/EmptyListIcon";
import Pagination from "components/molecules/general/Pagination";
import FixedButton from "components/molecules/general/FixedButton";
import CommissionedDriverForm from "components/organisms/commissioned/commissioned_driver/CommissionedDriverForm";
import Button from "components/molecules/general/Button";
import SearchInput from "components/molecules/general/SearchInput";
import FilterCommissionedDriverForm from "components/organisms/commissioned/commissioned_driver/FilterCommissionedDriverForm";

import css from "./index.module.scss";

function Page({
  t,
  drivers,
  search,
  commissionedDriverId,
  openForm,
  onChangePage,
  handleChangeStatus,
  handleDelete,
  handleEdit,
  handleOpenForm,
  handleCloseForm,
  handleSearch,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
}) {
  return (
    <Container
      title={t("_general.commissioned_drivers")}
      className={classnames({
        [css.empty]: drivers.data.length === 0,
      })}
      options={
        <>
          <Button
            classNameButton={classnames(css.filter)}
            variant={"icon"}
            onClick={handleOpenFilter}
          >
            <FilterListIcon />
          </Button>
          <SearchInput search={search || ""} onChange={handleSearch} />
        </>
      }
    >
      {drivers.data.length > 0 ? (
        <>
          <div className={classnames(css.container)}>
            <div
              className={classnames({
                "table-responsive": true,
                [css.table_wrapper]: true,
              })}
            >
              <table
                className={classnames({
                  [css.table]: true,
                  table: true,
                })}
              >
                <thead className={classnames(css.table_head)}>
                  <tr>
                    <th className="text-center">{t("_labels.status")}</th>
                    <th>{t("_general.association")}</th>
                    <th>{t("_labels.name.singular")}</th>
                    <th>{t("_labels.driver_code")}</th>
                    <th>{t("_labels.license")}</th>
                    <th>{t("_labels.course")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className={classnames(css.table_body)}>
                  {drivers.data.map((driver) => (
                    <tr key={driver.commissioned_driver_id}>
                      <td className="text-center">
                        {driver.status ? (
                          <button
                            className="btn btn-sm btn-success mx-auto d-flex justify-content-center"
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() =>
                              handleChangeStatus(
                                driver.commissioned_driver_id,
                                driver.status
                              )
                            }
                          >
                            <DoneIcon fontSize="small" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-danger mx-auto d-flex justify-content-center"
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() =>
                              handleChangeStatus(
                                driver.commissioned_driver_id,
                                driver.status
                              )
                            }
                          >
                            <CloseIcon fontSize="small" />
                          </button>
                        )}
                      </td>
                      <td>{driver.association.name}</td>
                      <td>{driver.name}</td>
                      <td>{driver.driver_code || "-"}</td>
                      <td></td>
                      <td></td>
                      <td>
                        <div
                          className="d-flex justify-content-start"
                          style={{ gap: "6px" }}
                        >
                          <button
                            className="btn btn-sm btn-primary d-flex justify-content-center"
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() =>
                              handleEdit(driver.commissioned_driver_id)
                            }
                          >
                            <EditIcon fontSize="small" />
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex justify-content-center"
                            style={{
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                            }}
                            onClick={() =>
                              handleDelete(driver.commissioned_driver_id)
                            }
                          >
                            <DeleteIcon fontSize="small" />
                          </button>
                          {/* <button className="btn btn-sm btn-info" style={{ minWidth: "fit-content" }}>Actions</button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={classnames(css.pagination)}>
            <Pagination
              color="primary"
              page={drivers.current_page}
              totalPages={drivers.last_page}
              onChangePage={onChangePage}
            />
          </div>
        </>
      ) : (
        <EmptyListIcon text={t("_general.no_drivers")} />
      )}

      <FixedButton color="primary" onClick={handleOpenForm} />

      <CommissionedDriverForm
        commissionedDriverId={commissionedDriverId}
        open={openForm}
        onClose={handleCloseForm}
      />

      <FilterCommissionedDriverForm
        open={openFilter}
        onClose={handleCloseFilter}
      />
    </Container>
  );
}

Page.propTypes = {
  t: PropTypes.func.isRequired,
  drivers: PropTypes.object.isRequired,
  search: PropTypes.string,
  commissionedDriverId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChangePage: PropTypes.func,
  openForm: PropTypes.bool,
  openFilter: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleOpenForm: PropTypes.func,
  handleCloseForm: PropTypes.func,
  handleOpenFilter: PropTypes.func,
  handleCloseFilter: PropTypes.func,
  handleSearch: PropTypes.func,
};

export default Page;
