import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Field, Form } from "formik";

import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import SelectField from "components/molecules/general/SelectField";
import RadioField from "components/molecules/general/RadioField";
import RadioInput from "components/atoms/general/RadioInput";
import Button from "components/molecules/general/Button";

import css from "./index.module.scss";

function Page({
  t,
  handleSubmit,
  handleClean,
  onClose,
  isSubmitting,
  isValid,
  associations,
}) {
  return (
    <Form onSubmit={handleSubmit} className={classnames({ [css.form]: false })}>
      <Typography
        variant="overline"
        color="textSecondary"
        display="block"
        className={classnames(css.field)}
      >
        {t("_labels.status")}
      </Typography>
      <Field
        required
        name="status"
        component={RadioField}
        classNameControl={classnames(css.ml8, css.mr8)}
      >
        <RadioInput value="enabled">
          {t("_general.enabled.plural.male")}
        </RadioInput>
        <RadioInput value="disabled">
          {t("_general.disabled.plural.male")}
        </RadioInput>
      </Field>

      <Field
        fullWidth
        variant="filled"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        }}
        name="association_id"
        component={SelectField}
        label={t("_general.association")}
        className={classnames(css.field)}
      >
        {associations.map((association, index) => {
          return (
            <MenuItem
              key={index}
              value={association.association_id.toString()}
              disabled={!association.status}
            >
              {association.name}
            </MenuItem>
          );
        })}
      </Field>

      <DialogActions className={classnames(css.actions)}>
        <div className={classnames(css.actions__clean)}>
          <Button
            color="secondary"
            disabled={isSubmitting}
            onClick={handleClean}
          >
            {t("_buttons.clean_filters")}
          </Button>
        </div>
        <div>
          <Button color="secondary" disabled={isSubmitting} onClick={onClose}>
            {t("_buttons.cancel")}
          </Button>
          <Button
            type="submit"
            color="secondary"
            disabled={isSubmitting || !isValid}
          >
            {t("_buttons.filter")}
          </Button>
        </div>
      </DialogActions>
    </Form>
  );
}

Page.propTypes = {
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleClean: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  associations: PropTypes.array.isRequired,
};

export default Page;
