import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { actionSetNotification } from "store/actions/general/notification";
import { actionGetVehicleAxles } from "store/actions/vehicle/mount/getVehicleAxles";
import { actionClearVehicleAxles } from "store/actions/vehicle/mount/clearVehicleAxles";
import { actionSetRouter } from "store/actions/general/setRouter";
import { actionGetVehicles } from "store/actions/vehicle/getVehicles";

import { useLang } from "hooks/lang";
import { useParams, useHistory } from "react-router-dom";

import Page from "./page";

const defaultAxleTireInfo = {
  position: 0,
  vehicle_type_axle_tire_id: 0,
};

export function Mount({ ...rest }) {
  const t = useLang();
  const { id } = useParams();
  const history = useHistory();

  const {
    vehicle,
    review,
    getVehicleAxles,
    clearVehicleAxles,
    setNotification,
    setRouter,
    getVehicles,
  } = rest;

  const [openMountForm, setOpenMountForm] = useState(false);
  const [axleTireInfo, setAxleTireInfo] = useState(defaultAxleTireInfo);
  const [openMountedDialog, setOpenMountedDialog] = useState(false);
  const [openSendForm, setOpenSendForm] = useState(false);
  const [to, setTo] = useState("");
  const [movementTireId, setMovementTireId] = useState(null);

  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [openRotationForm, setOpenRotationForm] = useState(false);
  const [openVehicleReviewForm, setOpenVehicleReviewForm] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (
      review.vehicle_review_id &&
      ["COMPLETE", "DAMAGE AND WEAR"].includes(review.review_type) &&
      !review.end_time
    ) {
      setRouter({ noPermits: true });
      history.replace("/");
    }
  }, [review]);

  useEffect(() => {
    async function fetchData() {
      try {
        setSearch("");
        getVehicles(
          {
            status: 1,
            scope: "vehicle_id,economic_number,status",
          },
          false
        )
          .then((res) => {
            setVehicles(res);
          })
          .catch((error) => setNotification(error, true));

        await getVehicleAxles(id);
      } catch (error) {
        setNotification(error, true);
      }
    }

    fetchData();

    return () => {
      clearVehicleAxles();
    };
  }, [id]);

  function handleClickTire(axleTire) {
    setAxleTireInfo(axleTire);
    if (axleTire.vehicle_tire.length === 0) {
      if (moment().diff(moment(review.created_at), "hours") < 24) {
        handleOpenMountForm(true);
      }
    } else {
      handleOpenMountedDialog(true);
    }
  }

  function handleOpenMountForm() {
    setOpenMountForm(true);
  }

  function handleCloseMountForm() {
    setOpenMountForm(false);
    setAxleTireInfo(defaultAxleTireInfo);
  }

  function handleOpenMountedDialog() {
    setOpenMountedDialog(true);
  }

  function handleCloseMountedDialog() {
    setOpenMountedDialog(false);
  }

  function handleOpenSendForm(movementTireId, to) {
    setTo(to);
    setMovementTireId(movementTireId);
    setOpenSendForm(true);
    handleCloseMountedDialog(false);
  }

  function handleCloseSendForm() {
    setTo("");
    setMovementTireId(null);
    setOpenSendForm(false);
  }

  function handleRotation(origin, destination) {
    setOrigin(origin);
    setDestination(destination);
    setOpenRotationForm(true);
  }

  function handleCloseRotationForm() {
    setOpenRotationForm(false);
    setOrigin(null);
    setDestination(null);
  }

  function handleOpenVehicleReviewForm() {
    setOpenVehicleReviewForm(true);
  }

  function handleCloseVehicleReviewForm() {
    setOpenVehicleReviewForm(false);
  }

  function handleSearch(search) {
    setSearch(search);
    if (search) {
      const vehicleFound = vehicles.find(
        (vehicle) =>
          vehicle.economic_number.toLowerCase() === search.trim().toLowerCase()
      );
      if (vehicleFound) {
        if (!vehicleFound.vehicle_review[0].end_time) {
          setNotification({
            message: t("_messages.vehicle_in_process", { vehicle: search }),
          });
          history.replace(`/vehicle/review/${vehicleFound.vehicle_id}`);
        } else if (vehicleFound.vehicle_id != id) {
          history.replace(`/vehicle/${vehicleFound.vehicle_id}/mount`);
        }
      } else {
        setNotification({
          message: t("_messages.vehicle_not_found", { vehicle: search }),
        });
      }
    }
  }

  return (
    <Page
      t={t}
      to={to}
      movementTireId={movementTireId}
      openSendForm={openSendForm}
      openMountForm={openMountForm}
      openMountedDialog={openMountedDialog}
      vehicle={vehicle}
      axleTireInfo={axleTireInfo}
      handleClickTire={handleClickTire}
      handleCloseMountForm={handleCloseMountForm}
      handleCloseMountedDialog={handleCloseMountedDialog}
      handleOpenSendForm={handleOpenSendForm}
      handleCloseSendForm={handleCloseSendForm}
      openRotationForm={openRotationForm}
      handleRotation={handleRotation}
      handleCloseRotationForm={handleCloseRotationForm}
      origin={origin}
      destination={destination}
      isValidReview={moment().diff(moment(review.created_at), "hours") < 24}
      review={review}
      openVehicleReviewForm={openVehicleReviewForm}
      handleOpenVehicleReviewForm={handleOpenVehicleReviewForm}
      handleCloseVehicleReviewForm={handleCloseVehicleReviewForm}
      search={search}
      handleSearch={handleSearch}
    />
  );
}

Mount.propTypes = {};

const mapStateToProps = (state) => ({
  vehicle: state.Vehicle.Mount.vehicle_axles,
  review: state.Vehicle.Review.vehicle_review,
});
const mapDispatchToProps = (dispatch) => ({
  setNotification: actionSetNotification(dispatch),
  getVehicleAxles: actionGetVehicleAxles(dispatch),
  clearVehicleAxles: actionClearVehicleAxles(dispatch),
  setRouter: actionSetRouter(dispatch),
  getVehicles: actionGetVehicles(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mount);
