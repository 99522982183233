import React from "react";
import { connect } from "react-redux";

import { actionGetVehiclesTypes } from "store/actions/vehicle/type/getVehiclesTypes";
import { actionClearVehiclesTypes } from "store/actions/vehicle/type/clearVehiclesTypes";
import { actionSetVehicleTypeFilters } from "store/actions/vehicle/type/setVehicleTypeFilters";
import { actionClearVehicleTypeFilters } from "store/actions/vehicle/type/clearVehicleTypeFilters";
import { actionDeleteVehicleType } from "store/actions/vehicle/type/deleteVehicleType";
import { actionSetVehicleTypeStatus } from "store/actions/vehicle/type/setVehicleTypeStatus";
import { actionGetVehicleType } from "store/actions/vehicle/type/getVehicleType";
import { actionAddVehicleType } from "store/actions/vehicle/type/addVehicleType";
import { actionUpdateVehicleType } from "store/actions/vehicle/type/updateVehicleType";

import { useLang } from "hooks/lang";

import {
  CREATE_VEHICLE_TYPE,
  UPDATE_VEHICLE_TYPE,
  DELETE_VEHICLE_TYPE,
} from "store/actions/account/permits";

import Page from "./page";

export function HomeVehicleType({ ...rest }) {
  const t = useLang();
  const {
    permits,
    vehicleType,
    vehiclesTypes,
    filter,
    deleteVehicleType,
    setVehicleTypeStatus,
    getVehicleType,
    addVehicleType,
    updateVehicleType,
    getVehiclesTypes,
    clearVehiclesTypes,
    setVehicleTypeFilters,
    clearVehicleTypeFilters,
  } = rest;

  return (
    <Page
      vehiclesTypes={vehiclesTypes}
      vehicleType={vehicleType}
      filter={filter}
      createVehicleTypePermit={permits.includes(CREATE_VEHICLE_TYPE)}
      updateVehicleTypePermit={permits.includes(UPDATE_VEHICLE_TYPE)}
      deleteVehicleTypePermit={permits.includes(DELETE_VEHICLE_TYPE)}
      t={t}
      getVehicleType={getVehicleType}
      addVehicleType={addVehicleType}
      updateVehicleType={updateVehicleType}
      clearVehicleTypeFilters={clearVehicleTypeFilters}
      setVehicleTypeFilters={setVehicleTypeFilters}
      deleteVehicleType={deleteVehicleType}
      setVehicleTypeStatus={setVehicleTypeStatus}
      getVehiclesTypes={getVehiclesTypes}
      clearVehiclesTypes={clearVehiclesTypes}
    />
  );
}

HomeVehicleType.propTypes = {};

const mapStateToProps = (state) => ({
  permits: state.Account.permits,
  vehiclesTypes: state.Vehicle.Type.Type.vehicles_types,
  filter: state.Vehicle.Type.Type.filter_vehicle_type,
  vehicleType: state.Vehicle.Type.Type.vehicle_type,
});
const mapDispatchToProps = (dispatch) => ({
  getVehiclesTypes: actionGetVehiclesTypes(dispatch),
  clearVehiclesTypes: actionClearVehiclesTypes(dispatch),
  setVehicleTypeFilters: actionSetVehicleTypeFilters(dispatch),
  clearVehicleTypeFilters: actionClearVehicleTypeFilters(dispatch),
  deleteVehicleType: actionDeleteVehicleType(dispatch),
  setVehicleTypeStatus: actionSetVehicleTypeStatus(dispatch),
  getVehicleType: actionGetVehicleType(dispatch),
  addVehicleType: actionAddVehicleType(dispatch),
  updateVehicleType: actionUpdateVehicleType(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeVehicleType);
